import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import PreparedMenuItem from './PreparedMenuItem';

import { AccountContext } from '../../../../../context/AccountContext';
import { DialogContext } from '../../../../../context/DialogContext';
import { ColumnContext } from '../../../../../context/ColumnContext';
import { InterfaceContext } from '../../../../../context/InterfaceContext';

import { fieldArray, defaultFieldSets, defaultTaskFieldSets } from './fields';

import { useLazyQuery } from '@apollo/client';
import { customFieldSetsQuery } from '../../../../../graphql/queries';

// import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

export default function FieldMenu({
  item,
  listOfCustomFields,
  setListOfCustomFields
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  const { fieldDialogOpen, setFieldDialogOpen } = useContext(DialogContext);
  // const { columns, setColumns, columnData, refetchColumns } =
  //   useContext(ColumnContext);

  // const [fieldData, setFieldData] = useState([]);
  const {
    fields,
    setFields,
    setOverrideDefaultFields,
    refetchFieldSetsTrigger
  } = useContext(InterfaceContext);

  //############################

  const variables = {
    customFieldSetsInput: {
      managerAccountId: currentManagerAccount._id,
      accountId: item.accountId,
      nexusDocumentId: item._id
    }
  };

  const [
    loadFieldSets,
    { called, loading, data: fieldSetsData, refetch: refetchFieldSets }
  ] = useLazyQuery(customFieldSetsQuery, {
    variables,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    refetchFieldSets();
  }, [refetchFieldSetsTrigger]);

  useEffect(() => {
    if (currentManagerAccount._id) {
      loadFieldSets();
    }
  }, [currentManagerAccount]);
  //############################

  useEffect(() => {
    setListOfCustomFields(fieldSetsData?.customFieldSets || []);
  }, [fieldSetsData]);

  const currentManagerAccountId = currentManagerAccount?._id;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const [listOfCustomFields, setListOfCustomFields] = useState([]);
  const [
    listOfManagerAccountFieldSetsMenuItems,
    setListOfManagerAccountFieldSetsMenuItems
  ] = useState([]);

  const [listOfAccountFieldSetsMenuItems, setListOfAccountFieldSetsMenuItems] =
    useState([]);
  const [listOfUserFieldSetsMenuItems, setListOfUserFieldSetsMenuItems] =
    useState([]);

  const [
    listOfDocumentFieldSetsMenuItems,
    setListOfDocumentFieldSetsMenuItems
  ] = useState([]);

  const [listOfFields, setListOfFields] = useState([]);

  useEffect(() => {
    switch (item.type) {
      case 'task':
      case 'subTask':
        setListOfFields(defaultTaskFieldSets);
        break;
      default:
        setListOfFields(defaultFieldSets);
    }
  }, [item.type]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    const right = [...listOfFields, ...listOfCustomFields].filter(
      (field) => field._id === event.target.id
    );
    if (right[0]?.fields) {
      setFields(right[0].fields);
      setOverrideDefaultFields(true);
    }

    setAnchorEl(null);
  };

  // useEffect(() => {

  //   if (fieldData && fieldData.customFieldSets) {
  //     setListOfCustomFields(fieldData.customFieldSets);
  //   }
  // }, [fieldData]);

  useEffect(() => {
    // Manager account

    const listOfManagerAccountFieldSets = listOfCustomFields.filter(
      (obj) => obj.savedTo === 'managerAccount'
    );

    const listOfManagerAccountFieldSetsMenuItems_ =
      listOfManagerAccountFieldSets.map((obj) => {
        return (
          <PreparedMenuItem
            key={obj._id}
            id={obj._id}
            name={obj.name}
            currentManagerAccountId={currentManagerAccountId}
            savedTo={obj.savedTo}
            handleClose={handleClose}
            item={item}
            refetchFieldSets={refetchFieldSets}
          />
        );
      });

    setListOfManagerAccountFieldSetsMenuItems(
      listOfManagerAccountFieldSetsMenuItems_
    );

    // Account

    const listOfAccountFieldSets = listOfCustomFields.filter(
      (obj) => obj.savedTo === 'account'
    );

    const listOfAccountFieldSetsMenuItems_ = listOfAccountFieldSets.map(
      (obj) => {
        return (
          <PreparedMenuItem
            key={obj._id}
            id={obj._id}
            name={obj.name}
            currentManagerAccountId={currentManagerAccountId}
            savedTo={obj.savedTo}
            handleClose={handleClose}
            item={item}
            refetchFieldSets={refetchFieldSets}
          />
        );
      }
    );

    setListOfAccountFieldSetsMenuItems(listOfAccountFieldSetsMenuItems_);

    // User

    const listOfUserFieldSets = listOfCustomFields.filter(
      (obj) => obj.savedTo === 'user'
    );

    const listOfUserFieldSetsMenuItems_ = listOfUserFieldSets.map((obj) => {
      return (
        <PreparedMenuItem
          key={obj._id}
          id={obj._id}
          name={obj.name}
          currentManagerAccountId={currentManagerAccountId}
          savedTo={obj.savedTo}
          handleClose={handleClose}
          item={item}
          refetchFieldSets={refetchFieldSets}
        />
      );
    });

    setListOfUserFieldSetsMenuItems(listOfUserFieldSetsMenuItems_);

    const listOfDocumentFieldSets = listOfCustomFields.filter(
      (obj) => obj.savedTo === 'nexusDocument'
    );

    const listOfDocumentFieldSetsMenuItems_ = listOfDocumentFieldSets.map(
      (obj) => {
        return (
          <PreparedMenuItem
            key={obj._id}
            id={obj._id}
            name={obj.name}
            currentManagerAccountId={currentManagerAccountId}
            savedTo={obj.savedTo}
            handleClose={handleClose}
            item={item}
            refetchFieldSets={refetchFieldSets}
          />
        );
      }
    );

    setListOfDocumentFieldSetsMenuItems(listOfDocumentFieldSetsMenuItems_);
  }, [listOfCustomFields, item]);

  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Button
        variant="standard"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          minHeight: 0,
          minWidth: 0
        }}
        onClick={handleClick}
      >
        <DynamicFormIcon />
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Typography
          // className={classes.dividerFullWidth}
          style={{
            marginTop: '10px',
            paddingLeft: '15px',
            textAlign: 'left',
            fontSize: '10px'
          }}
          color="textSecondary"
          display="block"
          variant="caption"
        >
          Generic
        </Typography>
        {listOfFields.map((obj) => (
          <MenuItem
            style={{ minWidth: '200px' }}
            id={obj._id}
            onClick={handleClose}
          >
            {obj.name}
          </MenuItem>
        ))}

        {listOfManagerAccountFieldSetsMenuItems.length > 0 && (
          <div>
            <Divider />
            <Typography
              // className={classes.dividerFullWidth}
              style={{
                marginTop: '10px',
                paddingLeft: '15px',
                textAlign: 'left',
                fontSize: '10px'
              }}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              Manager Account
            </Typography>
          </div>
        )}
        {listOfManagerAccountFieldSetsMenuItems}

        {listOfAccountFieldSetsMenuItems.length > 0 && (
          <div>
            <Divider />
            <Typography
              // className={classes.dividerFullWidth}
              style={{
                marginTop: '10px',
                paddingLeft: '15px',
                textAlign: 'left',
                fontSize: '10px'
              }}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              Account
            </Typography>
          </div>
        )}
        {listOfAccountFieldSetsMenuItems}

        {listOfUserFieldSetsMenuItems.length > 0 && (
          <div>
            <Divider />
            <Typography
              // className={classes.dividerFullWidth}
              style={{
                marginTop: '10px',
                paddingLeft: '15px',
                textAlign: 'left',
                fontSize: '10px'
              }}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              Personal
            </Typography>
          </div>
        )}
        {listOfUserFieldSetsMenuItems}

        {listOfDocumentFieldSetsMenuItems.length > 0 && (
          <div>
            <Divider />
            <Typography
              // className={classes.dividerFullWidth}
              style={{
                marginTop: '10px',
                paddingLeft: '15px',
                textAlign: 'left',
                fontSize: '10px'
              }}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              Document
            </Typography>
          </div>
        )}
        {listOfDocumentFieldSetsMenuItems}

        <Divider />
        <MenuItem
          id="customize_fields"
          onClick={() => {
            // setOpenSimpleModal(true);
            setFieldDialogOpen(true);

            setAnchorEl(null);
            // handleClose
          }}
        >
          Customize Fields
        </MenuItem>
      </Menu>
    </div>
  );
}
