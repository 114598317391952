import React, { useContext } from 'react';
import { MenuItem } from '@mui/material';

import html2canvas from 'html2canvas';

import { AccountContext } from '../../../..//context/AccountContext';

import { Button } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';

import { useSnackbar } from 'notistack';

const saveAs = (blob, fileName) => {
  var elem = window.document.createElement('a');
  elem.href = blob;
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

function ExportIMG({ item }) {
  const { currentManagerAccount, currentAccounts } = useContext(AccountContext);

  const { enqueueSnackbar } = useSnackbar();

  const saveIMG = () => {
    // if (currentAccounts.length > 1) {
    //   enqueueSnackbar(
    //     'Please select ONE account in the account menu above before exporting.',
    //     {
    //       variant: 'error'
    //     }
    //   );

    //   return;
    // }

    const logoUrl =
      currentManagerAccount?.logo ||
      'https://nexustable.wpengine.com/wp-content/uploads/2022/04/nexuslogo.png';

    const domElement = document.getElementById('nexustable-info-modal');
    domElement.style.width = '950px';
    domElement.style.maxWidth = '950px';
    domElement.style.minWidth = '950px';
    domElement.style.position = 'fixed';
    domElement.style.padding = '50px';

    // const gridContainerDomElement = document.getElementById('gridContainer');

    // gridContainerDomElement.style.height = 'unset';
    // var headline2 = document.createElement('div');
    // headline2.innerText = item.path;
    // headline2.style.marginLeft = '25px';
    // headline2.style.marginBottom = '25px';
    // headline2.style.fontSize = '20px';
    // headline2.style.fontWeight = 'bold';
    // domElement.insertBefore(headline2, domElement.firstChild);

    var headline1 = document.createElement('div');
    headline1.style.margin = '0px 25px';
    headline1.style.marginBottom = '40px';

    headline1.style.display = 'flex';
    headline1.style.flexDirection = 'row';
    headline1.style.justifyContent = 'space-between';
    headline1.style.alignItems = 'center';

    var headline1Text = document.createElement('div');
    headline1Text.innerText = item.path;
    headline1Text.style.fontWeight = 'bold';
    headline1Text.style.fontSize = '14px';

    var headline1DateSpan = document.createElement('div');
    headline1DateSpan.innerText = '2022-01-01 - 2022-01-15';
    headline1DateSpan.style.fontSize = '14px';

    headline1.insertBefore(headline1DateSpan, headline1.firstChild);
    headline1.insertBefore(headline1Text, headline1.firstChild);

    domElement.insertBefore(headline1, domElement.firstChild);

    var managerAccountLogo_IMG = document.createElement('img');
    managerAccountLogo_IMG.src = logoUrl;

    //remove bottom shadow

    // const bottomShadowElement = document.getElementById('bottomShadow');
    // if (bottomShadowElement) {
    //   bottomShadowElement.style.visibility = 'hidden';
    // }

    managerAccountLogo_IMG.style.width = '300px';
    managerAccountLogo_IMG.style.height = 'auto';
    managerAccountLogo_IMG.style.margin = '10px 0px 30px 0px';
    // domElement.appendChild(DOM_img);
    domElement.insertBefore(managerAccountLogo_IMG, domElement.firstChild);

    var NexusTableLogo_IMG = document.createElement('img');
    var NexusTableLogo_DIV = document.createElement('div');
    NexusTableLogo_DIV.style.display = 'flex';
    NexusTableLogo_DIV.style.marginTop = '10px';
    NexusTableLogo_DIV.style.alignItems = 'center';
    NexusTableLogo_DIV.style.fontSize = '12px';
    NexusTableLogo_DIV.style.color = 'black';
    NexusTableLogo_DIV.innerText = 'Powered by ';

    NexusTableLogo_IMG.src =
      'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

    NexusTableLogo_IMG.style.width = '90px';
    NexusTableLogo_IMG.style.height = 'auto';
    NexusTableLogo_IMG.style.marginLeft = '10px';

    NexusTableLogo_DIV.appendChild(NexusTableLogo_IMG);
    domElement.appendChild(NexusTableLogo_DIV);

    html2canvas(domElement, {
      scale: 2,
      logging: true,
      letterRendering: 1,
      // allowTaint: false,
      useCORS: true
    }).then((canvas) => {
      var image = canvas.toDataURL('image/png', 1.0);
      // var fileName = currentExport.split('Export')[0] + '.png';
      const fileName = 'NexusTable_export.png';
      saveAs(image, fileName);
    });
    domElement.style.position = 'unset';
    domElement.style.padding = '0';
    managerAccountLogo_IMG.remove();
    // NexusTableLogo_IMG.remove();
    NexusTableLogo_DIV.remove();
    headline1.remove();
    // headline2.remove();
    // gridContainerDomElement.style.height = 'calc(100vh - 140px)';

    domElement.style.width = null;
    domElement.style.maxWidth = null;
    domElement.style.minWidth = null;
    // if (bottomShadowElement) {
    //   bottomShadowElement.style.visibility = 'visible';
    // }
  };

  return (
    <Button
      style={{ width: '40px', maxWidth: '40px', minWidth: '40px' }}
      onClick={saveIMG}
    >
      <DownloadIcon />
    </Button>
  );
}

export default ExportIMG;
