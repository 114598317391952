import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import TextField from '@mui/material/TextField';

import { fieldDictionary } from './fields';

import { ColumnContext } from '../../../../../context/ColumnContext';

import { fieldArray } from './fields';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     margin: 'auto'
//   },
//   paper: {
//     width: 200,
//     height: 230,
//     overflow: 'auto',
//     backgroundColor: 'rgba(0,0,0,0)'
//   },
//   button: {
//     margin: theme.spacing(0.5, 0)
//   }
// }));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const generateName = (item) => {
  let nameToReturn = '';
  switch (item.string) {
    case 'LINECHART':
      nameToReturn = 'Line Chart:';
      for (let field of item.metrics) {
        nameToReturn = nameToReturn + ' ' + fieldDictionary[field.dataKey];
      }
      // for (let field of item.rightYAxisMetrics) {
      //   nameToReturn = nameToReturn + ' ' + fieldDictionary[field.string];
      // }
      return nameToReturn;

    default:
      return fieldDictionary[item.string];
  }
};

export default function TransferList({ left, setLeft, right, setRight }) {
  // const newLeft = left.map((itemKey, index) => ({
  //   string: itemKey,
  //   name: fieldDictionary[itemKey],
  //   index
  // }));
  // const newRight = right.map((itemKey, index) => ({
  //   string: itemKey,
  //   name: fieldDictionary[itemKey],
  //   index
  // }));
  const { columns, setColumns } = useContext(ColumnContext);

  // const classes = useStyles();
  const [checked, setChecked] = useState([]);
  // const [left, setLeft] = useState([columnArray]);
  // const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // useEffect(() => {

  //   if (columns) {
  //     setRight([...columns].sort());
  //     setLeft(columnArray.filter((col) => !columns.includes(col)).sort());
  //   }
  // }, [columns]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  //   const handleAllRight = () => {
  //     setRight(right.concat(left));
  //     setLeft([]);
  //   };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked).sort());
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  //   const handleAllLeft = () => {
  //     setLeft(left.concat(right));
  //     setRight([]);
  //   };

  const dragStart = (e, value) => {
    const target = e.target;
    e.dataTransfer.setData('droppedItem', JSON.stringify(value));
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const drop = (e, value, landingItemlist) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const {
      droppedItem,
      list: droppedItemList,
      index: indexOfDroppedItem
    } = JSON.parse(droppedItemString);

    const newLeft = [...left];
    const newRight = [...right];
    // let newRight = right.filter((col) => col !== droppedItem);
    // let newLeft = left.filter((col) => col !== droppedItem);
    let indexOfLandingItem = -1;

    console.log('TransferList>droppedItemList: ', droppedItemList);
    console.log('TransferList>landingItemlist: ', landingItemlist);

    console.log('TransferList>droppedItem: ', droppedItem);
    console.log('TransferList>value: ', value);
    if (value) {
      if (landingItemlist === 'right' && droppedItemList === 'right') {
        console.log('TransferList>value: ', value);
        newRight.splice(indexOfDroppedItem, 1);
        indexOfLandingItem = newRight.findIndex(
          (obj) => obj.string === value.string
        );
        console.log('indexOfLandingItem: ', indexOfLandingItem);

        newRight.splice(indexOfLandingItem, 0, droppedItem);
      }

      if (landingItemlist === 'right' && droppedItemList === 'left') {
        console.log('TransferList>value: ', value);
        indexOfLandingItem = newRight.findIndex(
          (obj) => obj.string === value.string
        );
        console.log('indexOfLandingItem: ', indexOfLandingItem);

        newRight.splice(indexOfLandingItem, 0, droppedItem);
      }

      if (landingItemlist === 'left' && droppedItemList === 'right') {
        console.log('remove item');
        // indexOfLandingItem = newLeft.findIndex(
        //   (obj) => obj.string === value.string
        // );
        // console.log('TransferList>indexOfLandingItem: ', indexOfLandingItem);
        newRight.splice(indexOfDroppedItem, 1);
      }
    } else {
      // if (landingItemlist === 'right') {
      //   newRight.push(droppedItem);
      // }
      // if (landingItemlist === 'left') {
      //   newLeft.push(droppedItem);
      // }
    }

    setRight(newRight);
    setLeft(newLeft);

    const droppedItemElement = document.getElementById(droppedItem);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items, list) => (
    <Paper
      onDrop={(e) => drop(e, null, list)}
      onDragOver={(e) => dragOver(e, null)}
      // onDragStart={(e) => dragStart(e, null)}
      // onDragEnd={(e) => dragEnd(e, null)}
      // className={classes.paper}
      style={{
        width: '100%',
        height: '100%',
        border: '1px solid rgba(255,255,255,0.1)'
      }}
    >
      <List dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              style={{ cursor: 'grab' }}
              id={value}
              key={value}
              value={value}
              role="listitem"
              onDrop={(e) => drop(e, value, list)}
              onDragOver={(e) => dragOver(e, value)}
              draggable={true}
              onDragStart={(e) =>
                dragStart(e, { droppedItem: value, list, index })
              }
              onDragEnd={(e) => dragEnd(e, value)}
            >
              <ListItemIcon onClick={handleToggle(value)}>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${generateName(value)}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  const filteredLeft = [...left];
  // const [search, setSearch] = useState('');
  // const onChangeSearch = (e) => {
  //   setSearch(e.target.value);
  // };

  // const filteredLeft = left.filter((value) =>
  //   (fieldDictionary[value] || '').toLowerCase().includes(search.toLowerCase())
  // );

  return (
    <>
      <div style={{ display: 'grid', gridTemplateRows: '75px 525px' }}>
        <div style={{ gridRow: 1 }}>
          {/* <div style={{ marginLeft: '25px' }}>
            <TextField
              variant="standard"
              value={search}
              onChange={onChangeSearch}
              id="standard-basic"
              label="Search"
            />
          </div> */}
        </div>
        <div style={{ gridRow: 2 }}>
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            // className={classes.root}
            style={{ height: '95%' }}
          >
            <Grid
              item
              style={{
                width: '43%',
                height: '100%'
                // height: '40vw'
              }}
            >
              {customList(filteredLeft, 'left')}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  // className={classes.button}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  // className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  // className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  // className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                width: '43%',
                height: '100%'
                // height: '40vw'
              }}
            >
              {customList([...right], 'right')}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
