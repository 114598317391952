import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_amazon_dsp_order_data = (
  amazonDspOrder,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2,
  startDateForLastDaysData,
  startDateForLastDaysDataMinusOneDay,
  tomorrow,
  dayAfterTomorrow
) => {
  const action01DataSource = amazonDspOrder?.action01?.dataSource;
  const action02DataSource = amazonDspOrder?.action02?.dataSource;
  const action03DataSource = amazonDspOrder?.action03?.dataSource;

  const action01DataSourceIsAmazonDsp = action01DataSource === 'amazonDsp';
  const action02DataSourceIsAmazonDsp = action02DataSource === 'amazonDsp';
  const action03DataSourceIsAmazonDsp = action03DataSource === 'amazonDsp';

  const action01Type = amazonDspOrder?.action01?.amazonDsp?.type;

  const action02Type = amazonDspOrder?.action02?.amazonDsp?.type;

  const action03Type = amazonDspOrder?.action03?.amazonDsp?.type;

  // const action01Name = amazonDspOrder?.action01?.amazonDsp?.name;

  // const action02Name = amazonDspOrder?.action02?.amazonDsp?.name;

  // const action03Name = amazonDspOrder?.action03?.amazonDsp?.name;

  const action01AttributionType =
    amazonDspOrder?.action01?.amazonDsp?.attributionType;
  const action02AttributionType =
    amazonDspOrder?.action02?.amazonDsp?.attributionType;
  const action03AttributionType =
    amazonDspOrder?.action03?.amazonDsp?.attributionType;

  const aggregatedObject = {
    date_start: startDateForLastDaysDataMinusOneDay.getTime(),
    cost: null,
    impressions: null,
    clicks: null,
    action01Count: null,
    action01Value: null,
    action02Count: null,
    action02Value: null,
    action03Count: null,
    action03Value: null
  };

  const campaign = {
    ...amazonDspOrder,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'amazon_dsp_order'
  };

  // campaign.action01.name = action01Name;
  // campaign.action02.name = action02Name;
  // campaign.action03.name = action03Name;

  const runCostFormula = campaign.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (amazonDspOrder?.amazondspadreportdata?.length > 0) {
    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adgroup_object of amazonDspOrder.amazondspadreportdata) {
      // console.log('heeeeeej');
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      let outbound_clicks = null;

      if (action01DataSourceIsAmazonDsp) {
        action01Count =
          action01Count + (parseFloat(adgroup_object[action01Type]) || null);

        if (['purchase'].includes(action01Type)) {
          action01Value =
            action01Value +
            (parseFloat(adgroup_object['total_' + action01Type + '_value']) ||
              null);
        }
        // action01Value =
        //   action01Value +
        //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
        //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      }

      if (action02DataSourceIsAmazonDsp) {
        action02Count =
          action02Count + (parseFloat(adgroup_object[action02Type]) || null);

        if (['purchase'].includes(action02Type)) {
          action02Value =
            action02Value +
            (parseFloat(adgroup_object['total_' + action02Type + '_value']) ||
              null);
        }
        // action01Value =
        //   action01Value +
        //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
        //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      }

      if (action03DataSourceIsAmazonDsp) {
        action03Count =
          action03Count + (parseFloat(adgroup_object[action03Type]) || null);

        if (['purchase'].includes(action03Type)) {
          action03Value =
            action03Value +
            (parseFloat(adgroup_object['total_' + action03Type + '_value']) ||
              null);
        }

        // action01Value =
        //   action01Value +
        //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
        //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      }

      // if (action02DataSourceIsDV360) {
      //   switch (action02Type) {
      //     case 'conversions':
      //       action02Count =
      //         action02Count + (adgroup_object.metrics.conversions || null);
      //       action02Value =
      //         action02Value + (adgroup_object.metrics.conversionsValue || null);
      //       break;

      //     default:
      //       if (snapchatAdsCampaign && snapchatAdsCampaign.dv360conversions) {
      //         for (let adgroup_conversion_object of snapchatAdsCampaign.dv360conversions) {
      //           if (
      //             adgroup_conversion_object?.floodlightActivityId &&
      //             adgroup_conversion_object?.floodlightActivityId ===
      //               action02ActivityId &&
      //             adgroup_conversion_object.date === adgroup_object.date
      //           ) {
      //             switch (action02AttributionType) {
      //               case 'default':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.totalConversions || null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null) +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //               case 'view':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.postViewConversions || null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null);
      //                 break;
      //               case 'click':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.postClickConversions ||
      //                     null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //             }
      //           }
      //         }
      //       }
      //   }
      // }

      // if (action03DataSourceIsDV360) {
      //   switch (action03Type) {
      //     case 'conversions':
      //       action03Count =
      //         action03Count + (adgroup_object.metrics.conversions || null);
      //       action03Value =
      //         action03Value + (adgroup_object.metrics.conversionsValue || null);
      //       break;

      //     default:
      //       if (snapchatAdsCampaign && snapchatAdsCampaign.dv360conversions) {
      //         for (let adgroup_conversion_object of snapchatAdsCampaign.dv360conversions) {
      //           if (
      //             adgroup_conversion_object?.floodlightActivityId &&
      //             adgroup_conversion_object?.floodlightActivityId ===
      //               action03ActivityId &&
      //             adgroup_conversion_object.date === adgroup_object.date
      //           ) {
      //             switch (action03AttributionType) {
      //               case 'default':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.totalConversions || null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null) +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //               case 'view':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.postViewConversions || null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null);
      //                 break;
      //               case 'click':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.postClickConversions ||
      //                     null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //             }
      //           }
      //         }
      //       }
      //   }
      // }

      // console.log('adgroup_object.start_time: ', adgroup_object.start_time);

      // const date_start = new Date(parseInt(adgroup_object.start_time));

      // const dateStartAsUTCString = date_start.toUTCString();

      // var clearUTCDate = moment(date_start).utcOffset(0, true).unix() * 1000;
      // console.log('clearUTCDate: ', clearUTCDate);

      // console.log('adgroup_object.dateString: ', adgroup_object.dateString);

      if (
        new Date(+adgroup_object.date).toISOString().split('T')[0] >=
        new Date(startDateForLastDaysData).toISOString().split('T')[0]
      ) {
        campaign.total_list_of_propagation_data.push({
          cost: adgroup_object.cost || null,
          impressions: +adgroup_object.impressions || null,
          viewableImpressions: +adgroup_object.viewableImpressions || null,
          measurableImpressions: +adgroup_object.measurableImpressions || null,

          clicks: +adgroup_object.clicks || null,

          date_start: new Date(+adgroup_object.date).getTime(),
          date_stop: new Date(+adgroup_object.date).getTime(),
          action01Type: action01Type,

          action01Count: action01Count,

          action01Value: action01Value,

          action02Type: action02Type,
          action02Count: action02Count,
          action02Value: action02Value,

          action03Type: action03Type,
          action03Count: action03Count,
          action03Value: action03Value
        });
      } else {
        aggregatedObject.cost =
          aggregatedObject.cost +
          (adgroup_object.cost ? adgroup_object.cost : null);
        aggregatedObject.impressions =
          aggregatedObject.impressions +
          (adgroup_object.impressions ? adgroup_object.impressions : null);

        aggregatedObject.viewableImpressions =
          aggregatedObject.viewableImpressions +
          (adgroup_object.viewableImpressions
            ? adgroup_object.viewableImpressions
            : null);

        aggregatedObject.measurableImpressions =
          aggregatedObject.measurableImpressions +
          (adgroup_object.measurableImpressions
            ? adgroup_object.measurableImpressions
            : null);

        aggregatedObject.clicks =
          aggregatedObject.clicks + (+adgroup_object.clicks || null);

        // aggregatedObject.date_start = adgroup_object.date_start;
        // aggregatedObject.date_stop = adgroup_object.date_stop;
        aggregatedObject.action01Type = action01Type;
        aggregatedObject.action01Count =
          aggregatedObject.action01Count + action01Count;
        aggregatedObject.action01Value =
          aggregatedObject.action01Value + action01Value;

        aggregatedObject.action02Type = action02Type;
        aggregatedObject.action02Count =
          aggregatedObject.action02Count + action02Count;
        aggregatedObject.action02Value =
          aggregatedObject.action02Value + action02Value;

        aggregatedObject.action03Type = action03Type;
        aggregatedObject.action03Count =
          aggregatedObject.action03Count + action03Count;
        aggregatedObject.action03Value =
          aggregatedObject.action03Value + action03Value;
      }

      // listOfPropagationData.push({
      //   ...adgroup_object,
      //   // ...calculatedFields,
      //   date_start: new Date(adgroup_object.dateString).getTime(),
      //   // date_stop: adgroup_object.end_time,
      //   cost: adgroup_object.spend ? parseFloat(adgroup_object.spend) : null,
      //   impressions: parseInt(adgroup_object.impressions) || null,
      //   clicks: parseInt(adgroup_object.clicks) || null,
      //   action01Type,
      //   action01Count,
      //   action01Value,

      //   action02Type,
      //   action02Count,
      //   action02Value,

      //   action03Type,
      //   action03Count,
      //   action03Value,

      //   accountId: campaignBaseValues.accountId
      // });
    }
  }

  campaign.total_list_of_propagation_data.push(aggregatedObject);

  return {
    prepared_campaign: campaign
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_amazon_dsp_order_data;
