import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import GetAppIcon from '@mui/icons-material/GetApp';

// import ExportExcel from './ExportExcel';
import ExportPDF from './ExportPDF';
import ExportIMG from './ExportIMG';

import ExportPPT from './ExportPPT';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { Tooltip } from '@mui/material';

export default function BasicMenu({
  zoom,
  setZoom
  // range2 = { range2 }
}) {
  const { setExportMode } = useContext(InterfaceContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setExportMode(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // setExportMode(false);
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Export">
        <Button
          style={{
            // width: '20px',
            // minWidth: '20px',
            // height: '20px',
            // minHeight: '20px'
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20px',
            minHeight: '20px',
            width: '20px',
            minWidth: '20px'
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <GetAppIcon style={{ fontSize: '18px' }} />
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <ExportExcel
          handleClose={handleClose}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          summaryRow={summaryRow}
          range2={range2}
          arrayOfData={arrayOfData}
        /> */}
        {/* <ExportIMG handleClose={handleClose} zoom={zoom} />
        <ExportPDF handleClose={handleClose} zoom={zoom} /> */}
        <ExportPPT handleClose={handleClose} zoom={zoom} />
      </Menu>
    </div>
  );
}
