import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { ClickAwayListener } from '@mui/base';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles } from '@mui/styles';

import { addDocumentMutation } from '../../../../../../graphql/queries';

import { useMutation } from '@apollo/client';

import SelectTemplateType from './SelectTemplateType';

import { MutationContext } from '../../../../../../context/MutationContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex'
//   },
//   paper: {
//     marginRight: theme.spacing(2)
//   }
// }));

export default function MenuListComposition({
  children,
  typeItemAnchorRef,
  setTypeItemAnchorRef,
  typeItemOpen,
  setTypeItemOpen,
  open,
  setOpen,
  typeItemToggledItem,
  setTypeItemToggledItem,
  currentManagerAccountId
}) {
  // const classes = useStyles();
  //   const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [templateType, setTemplateType] = useState(null);
  // const [addDocument] = useMutation(addDocumentMutation);

  const { addDocument } = useContext(MutationContext);

  const onSaveHandler = async () => {
    // let addDocumentInput = {
    //   action: 'CREATE_DOCUMENT',
    //   managerAccountId: currentManagerAccountId,
    //   // documentType: 'container',
    //   documentType: templateType?.documentType,
    //   newDocumentAccountAccessLevel: 1,
    //   checkedDocumentId: typeItemToggledItem._id
    //   // document: {}
    // };

    let name = 'new document';
    switch (templateType?.documentType) {
      case 'folder':
        name = 'New Folder Template';
        break;
      case 'manualDataEntry':
        name = 'New Manual Data Entry Template';
        break;
      case 'task':
        name = 'New Task Template';
        break;
      case 'subTask':
        name = 'New subtask';
        break;

      case 'taxonomy':
        name = 'New Taxonomy Template';
        break;
    }

    // addDocumentInput = {
    //   ...addDocumentInput,
    //   level: typeItemToggledItem.level,
    //   accountId: typeItemToggledItem.accountId,
    //   document: {
    //     name,
    //     type: templateType?.documentType,
    //     template: true
    //   }
    // };

    let addDocumentInput = {
      managerAccountId: currentManagerAccountId,
      accountId: typeItemToggledItem.accountId,
      checkedDocumentId: typeItemToggledItem._id,
      level: typeItemToggledItem.level,
      newDocuments: [
        { documentType: templateType?.documentType, name, template: true }
      ]
    };

    addDocument({ variables: { input: { ...addDocumentInput } } });

    //close

    setTypeItemToggledItem(null);
    setTypeItemAnchorRef(null);

    setOpen(false);
  };

  const handleToggle = () => {
    return;
    console.log('toggle');
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setTypeItemToggledItem(null);
    setTypeItemAnchorRef(null);

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  //   return children;

  return (
    <div
    // className={classes.root}
    >
      <div ref={anchorRef} onClick={handleToggle}>
        <Popper
          style={{ zIndex: '5000' }}
          open={open}
          anchorEl={typeItemAnchorRef?.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div style={{ padding: '10px' }}>
                    <SelectTemplateType
                      item={typeItemToggledItem}
                      templateType={templateType}
                      setTemplateType={setTemplateType}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        width: '100%',
                        marginTop: '15px'
                      }}
                    >
                      <Button size="small" onClick={onSaveHandler}>
                        Save
                      </Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
