import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { reportPagesQuery } from './../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';

import { Button } from '@mui/material';

import { useTheme } from '@emotion/react';

import ReportPage from './ReportPage';

import SpinnerSmall from '../../../layout/SpinnerSmall';

function ReportPages({
  selectedReport,
  setSelectedReport,
  selectedReportPage,
  setSelectedReportPage,
  refetchReportPagesCounter,
  setRefetchReportPagesCounter
}) {
  const theme = useTheme();

  const { currentAccountIds, currentManagerAccountId } =
    useContext(AccountContext);

  const [reportPages, setReportPages] = useState();
  // const [selectedReportPage, setSelectedReportPage] = useState();
  // console.log('selectedReport: ', selectedReport);

  // console.log('window.innerWidth: ', window.innerWidth);

  const [previewImageFile, setPreviewImageFile] = useState();
  // 'previewImageXSmallFileId',
  // 'previewImageSmallFileId',
  // 'previewImageMediumFileId',
  // 'previewImageLargeFileId'

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    switch (true) {
      case window.innerWidth > 4000:
        setPreviewImageFile('previewImageFileId');
        break;
      case window.innerWidth > 3000:
        setPreviewImageFile('previewImageMediumFileId');
        break;
      case window.innerWidth > 1200:
        setPreviewImageFile('previewImageSmallFileId');
        break;

      default:
        setPreviewImageFile('previewImageXSmallFileId');
    }
  }, [window.innerWidth]);

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds,
      reportId: selectedReport?._id,
      reportType: selectedReport?.type,
      previewImageFile
    }
  };

  const [loadReportPages, { called, loading, data, refetch }] = useLazyQuery(
    reportPagesQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
      // skip: !currentManagerAccountId && skip
    }
  );

  useEffect(() => {
    if (refetchReportPagesCounter) {
      refetch();
    }
  }, [refetchReportPagesCounter]);

  useEffect(() => {
    if (
      currentManagerAccountId &&
      currentAccountIds &&
      selectedReport &&
      previewImageFile
    ) {
      loadReportPages();
    }
  }, [
    currentManagerAccountId,
    currentAccountIds,
    selectedReport,
    previewImageFile
  ]);

  useEffect(() => {
    if (data?.reportPages && called) {
      setReportPages(data?.reportPages);
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <SpinnerSmall />
      </div>
    );
  }
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        displ: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          height: '100%',
          width: '100%',

          // minWidth: '410px',
          // maxWidth: '660px',
          // background: 'red',
          // display: 'flex',
          // flexDirection: 'row',
          // margin: '0px 10px',
          padding: '10px',
          borderRadius: '5px',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(150,150,150,0.2)',
          background:
            theme?.palette?.mode === 'dark'
              ? 'rgba(0,0,0,0.1)'
              : 'rgba(255,255,255,0.3)',
          overflowY: 'scroll'
        }}
      >
        {(reportPages || []).map((reportPage) => (
          <ReportPage
            reportPage={reportPage}
            selectedReportPage={selectedReportPage}
            setSelectedReportPage={setSelectedReportPage}
          />
        ))}
      </div>
    </div>
  );
}

export default ReportPages;
