import React, { useState, useContext } from 'react';

import GraphDialog from './../../report/graph-dialog/GraphDialog';
import TimelineIcon from '@mui/icons-material/Timeline';

// import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';

import { DialogContext } from '../../../../context/DialogContext';

function NameFoot({
  aggregatedMode,
  item,
  itemKey,
  index,
  stylesStoredInRedux,
  arrayOfData,
  summaryRowBottomShadow,
  range,
  range2,
  columns,
  viewDialogOpen,
  setViewDialogOpen
}) {
  const [openDataGraphModal, setOpenDataGraphModal] = useState(false);

  const { setItemKey } = useContext(DialogContext);

  return (
    <div
      key={index}
      style={{
        // backgroundImage:
        //   'linear-gradient(to bottom, white, #f9fafa)',
        backgroundImage:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .background,
        color:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .color,
        borderStyle: 'solid',
        borderWidth: '1px 1px 1px 0px',
        // borderColor: 'rgba(200, 200, 200, 0.5)',
        borderColor:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .borderColor,
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottomLeftRadius: '5px',
        fontSize: '12px',
        gridRowStart: arrayOfData.length + 2,
        gridRowEnd: arrayOfData.length + 2,
        position: 'sticky',
        // left: '92px',
        left: columns.includes('type') ? '92px' : '46px',
        bottom: '0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1100',
        maxHeight: '46px',
        height: '100%',
        minHeight: '46px',
        // minWidth: '200px',
        width: '100%',
        padding: '10px 20px',
        fontWeight: '800'
      }}
    >
      {/* <p>Summary</p> */}
      {/* <p style={{ visibility: 'hidden' }}> test</p> */}
      {
        // !aggregatedMode
        true && (
          <TimelineIcon
            className="hover-light-grey"
            style={{
              fontSize: '18px',
              cursor: 'pointer',
              marginRight: '4px'
            }}
            onClick={() => {
              setItemKey('costSparkline');
              setOpenDataGraphModal(true);
            }}
          />
        )
      }
      {/* <ViewKanbanOutlinedIcon
        className="hover-light-grey"
        style={{
          fontSize: '18px',
          cursor: 'pointer',
          marginRight: '4px'
        }}
        onClick={() => setViewDialogOpen(true)}
      /> */}

      {summaryRowBottomShadow}
      {openDataGraphModal && (
        <GraphDialog
          open={openDataGraphModal}
          setOpen={setOpenDataGraphModal}
          // itemKey={'costSparkline'}
          item={item}
          range2={range2}
          columns={columns}
        />
      )}
    </div>
  );
}

export default NameFoot;
