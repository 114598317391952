import React, { useContext, useState } from 'react';

import Column from './Column';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

import { getDayAsString } from './../../../roitable_functions/date_functions';

function getWeekNumber(d) {
  // const d = new Date(date);

  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

function Columns({
  // columns,
  filteredArrayOfData,
  isExpanded,
  rawArrayOfData,
  account,
  setAccount,
  hideSelected
}) {
  const [columns, setColumns] = useState([]);
  const { range2 } = useContext(InterfaceContext);

  useState(() => {
    const newColumns = range2.map((date) => {
      const dateString = date;

      return {
        name: dateString,
        string: dateString,
        dayOfWeek: getDayAsString(new Date(date)),
        weekNumber: getWeekNumber(new Date(date))[1]
      };
    });
    setColumns(newColumns);
    // console.log('newColumns: ', newColumns);
  }, [range2]);

  const renderColumns = columns.map((column) => {
    const filteredColumns = filteredArrayOfData.filter(
      (row) =>
        row.startDate === column.string ||
        (row.endDate === column.string &&
          !hideSelected.includes(row.documentType) &&
          !row.template &&
          (row.personal && !hideSelected.includes('personal')
            ? true
            : !row.personal) &&
          ([
            'folder',
            'manualDataEntry',
            'customForm',
            'facebook_ads_campaign',
            'google_ads_campaign',
            'cm360_campaign',
            'dv360_insertion_order',
            'snapchat_ads_campaign',
            'tiktok_ads_campaign',
            'pinterest_ads_campaign',
            'readpeak_campaign',
            'strossle_campaign',
            'amazon_dsp_order',
            'taboola_ads_campaign',
            'microsoft_advertising_campaign',
            'microsoft_invest_insertion_order',
            'apple_search_ads_campaign',
            'adform_order',
            'linkedin_ads_campaign_group',
            'task',
            'taxonomy'
          ].includes(row.type) ||
            row.isSet))
    );
    return (
      <Column
        isExpanded={isExpanded}
        column={column}
        filteredColumns={filteredColumns}
        rawArrayOfData={rawArrayOfData}
        account={account}
        setAccount={setAccount}
        hideSelected={hideSelected}
      />
    );
  });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 20px',
        // overflowX: 'scroll',
        height: isExpanded ? 'calc(100vh - 125px)' : 'calc(90vh - 125px)',
        maxHeight: isExpanded ? 'calc(100vh - 125px)' : 'calc(90vh - 125px)'
      }}
    >
      {renderColumns}
    </div>
  );
}

export default Columns;
