/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function FreeSolo({
  width,
  label,
  margin,
  value,
  action,
  setAction,
  options,
  inputs,
  setInputs,
  parameterKey
}) {
  // console.log('action: ', action);
  const googleAnalytics4 = action?.googleAnalytics4
    ? action?.googleAnalytics4
    : {};

  const googleAnalyticsKeyObject = googleAnalytics4[parameterKey]
    ? googleAnalytics4[parameterKey]
    : {};

  const handleChange = (newValue) => {
    const actionObject = {
      ...action,
      googleAnalytics4: {
        ...googleAnalytics4,
        [parameterKey]: {
          ...googleAnalyticsKeyObject,
          operator: googleAnalyticsKeyObject.operator
            ? googleAnalyticsKeyObject.operator
            : 'equals',
          value: newValue
        }
      }
    };
    if (parameterKey === 'eventName') {
      actionObject.googleAnalytics4.name = newValue;
    }

    setAction(actionObject);

    // setValue(newValue);
  };

  return (
    <div style={{ width: '95%', margin: '0 0 0 5%' }}>
      <Autocomplete
        size="small"
        id="free-solo-demo"
        freeSolo
        autoSelect
        options={options?.map((option) => option)}
        value={googleAnalyticsKeyObject.value}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            // label={label}
            //margin="normal"
            // variant="outlined"
          />
        )}
      />
    </div>
  );
}
