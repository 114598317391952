import React, { useContext } from 'react';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { getDataSourceOAuthUrlMutation } from '../../../../../graphql/queries';

import { Button } from '@mui/material';

import { AccountContext } from '../../../../../context/AccountContext';

function CalendarButton() {
  const { currentManagerAccount } = useContext(AccountContext);
  const [addDataSource, { called }] = useMutation(
    getDataSourceOAuthUrlMutation
  );

  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const dataSource = 'microsoft_calendar';
  const onClickHandler = async (e, dataSource) => {
    const addDataSourceInput = {
      managerAccountId: currentManagerAccount._id,
      dataSource
    };

    const res = await addDataSource({
      variables: { input: addDataSourceInput }
    });

    if (
      res &&
      res.data &&
      res.data.getDataSourceOAuthUrl &&
      res.data.getDataSourceOAuthUrl.response &&
      res.data.getDataSourceOAuthUrl.url
    ) {
      window.location.replace(res.data.getDataSourceOAuthUrl.url);
    }
  };
  return (
    <Button
      variant="contained"
      size="small"
      onClick={(e) => onClickHandler(e, dataSource)}
    >
      Connect Microsoft calendar
    </Button>
  );
}

export default CalendarButton;
