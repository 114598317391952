import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SnackbarProvider, useSnackbar } from 'notistack';

const AlertSnackbar = ({ alerts }) => {
  const { enqueueSnackbar } = useSnackbar();

  if (alerts !== null && alerts.length > 0) {
    for (let alert of alerts) {
      enqueueSnackbar(alert.msg, {
        variant: 'error'
        // persist: true
      });
    }
  }

  // return (
  //   alerts !== null &&
  //   alerts.length > 0 &&
  //   alerts.map((alert) => (
  //     <div key={alert.id} className={`alert alert-${alert.alertType}`}>
  //       {alert.msg}
  //     </div>
  //   ))
  // );
  return <React.Fragment></React.Fragment>;
};

AlertSnackbar.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(AlertSnackbar);
