import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function VariantButtonGroup({
  componentIndex,
  metricIndex,
  field,
  fields,
  setFields
}) {
  const copyOfFields = [...fields];
  const copyOfCurrentField = { ...fields[componentIndex] };

  const copyOfMetric = { ...copyOfCurrentField.metrics[metricIndex] };
  const onClickHandler = (value) => {
    copyOfMetric.yAxisOrientation = value;
    copyOfCurrentField.metrics.splice(metricIndex, 1, copyOfMetric);

    copyOfFields.splice(componentIndex, 1, copyOfCurrentField);

    setFields(copyOfFields);

    // if()
  };

  return (
    <ButtonGroup
      size="small"
      variant="standard"
      aria-label="outlined button group"
    >
      <Button
        style={{
          fontSize: '10px',
          fontWeight: copyOfMetric.yAxisOrientation === 'left' && 'bold',
          textDecoration:
            copyOfMetric.yAxisOrientation === 'left' && 'underline',
          padding: '3px'
        }}
        onClick={() => onClickHandler('left')}
      >
        Left
      </Button>
      <Button
        style={{
          fontSize: '10px',
          padding: '3px',
          fontWeight: copyOfMetric.yAxisOrientation === 'right' && 'bold',
          textDecoration:
            copyOfMetric.yAxisOrientation === 'right' && 'underline'
        }}
        onClick={() => onClickHandler('right')}
      >
        Right
      </Button>
    </ButtonGroup>
  );
}
