import formatDateYYYYMMDD from './formatDateYYYYMMDD';

const getFastViewDataStartDate = (
  selectedDateStart,
  selectedDateStop,
  days
) => {
  // const days = 15;
  var date = new Date();
  if (
    selectedDateStart &&
    selectedDateStop &&
    (new Date(selectedDateStart) > date || new Date(selectedDateStop) < date)
  ) {
    date = new Date(selectedDateStop);
  }
  date.setDate(date.getDate() - days);
  // const offset = date.getTimezoneOffset();
  // date = new Date(date.getTime() - offset * 60 * 1000);

  date = formatDateYYYYMMDD(date);
  // return date.toISOString().split('T')[0];
  return date;
};

export default getFastViewDataStartDate;
