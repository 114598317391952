import React, { useState, useContext } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

import EmojiEmotionsTwoToneIcon from '@mui/icons-material/EmojiEmotionsTwoTone';
import CommentIcon from '@mui/icons-material/Comment';

import { addCommentMutation } from '../../graphql/queries';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { AccountContext } from '../../context/AccountContext';

import { TextField } from '@mui/material';

import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

function ChildCommentField({ item, commentObj, toggleShowTextField }) {
  const [value, setValue] = useState('');

  const { currentManagerAccountId } = useContext(AccountContext);

  const [addComment, { called }] = useMutation(addCommentMutation);

  const [menuExpanded, toggleMenuExpanded] = useState(false);

  const onMouseEnter = () => {
    toggleMenuExpanded(true);
  };

  const onMouseLeave = () => {
    toggleMenuExpanded(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onClickSubmit = (e, reaction) => {
    const finalInput = {
      action: 'add_child_comment',
      managerAccountId: currentManagerAccountId,
      accountId: item.accountId,
      documentId: item._id,
      level: item.level,
      documentType: item.documentType,
      // comment: {
      //   documentId: item._id,
      //   text: input ? input : ' '
      // },
      commentId: commentObj._id,
      newsFeedItemDocumentType: commentObj.type,
      commentType: 'child',
      comment: {
        documentId: item._id,
        text: value
      }
      //   reaction
    };

    setValue('');

    // action: 'add_comment',
    // managerAccountId: currentManagerAccountId,
    // accountId: item.accountId,
    // documentId: item._id,
    // level: item.level,

    // documentType: item.documentType,
    // newsFeedItemDocumentType: 'comment',
    // commentType: 'child',
    // comment: {
    //   documentId: item._id,
    //   text: input
    // }

    // if (input !== commentObj.text[commentObj.text.length - 1].text) {
    addComment({ variables: { addCommentInput: finalInput } });

    toggleShowTextField(false);
    // }
  };

  return (
    <div
      style={{
        padding: '10px 0px 0px 60px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px'
      }}
    >
      <TextField
        size="small"
        id="outlined-multiline-flexible"
        label="Multiline"
        multiline
        maxRows={4}
        value={value}
        onChange={handleChange}
        style={{ width: '100%' }}
      />
      <Button size="small" onClick={onClickSubmit}>
        <SendIcon />
      </Button>
    </div>
  );
}

export default ChildCommentField;
