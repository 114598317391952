import React, { useState, useContext, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';

// import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './../../../reactDateRange.css';

import { useSnackbar } from 'notistack';

import { useNavigate, useSearchParams } from 'react-router-dom';
// import { sv } from 'date-fns/locale';

import { defaultStaticRanges } from './ReactDateRangeDefaultRanges';

import formatDateYYYYMMDD from './../../../roitable_functions/formatDateYYYYMMDD';

import DateRangeIcon from '@mui/icons-material/DateRange';

import { MutationContext } from '../../../../../context/MutationContext';

import { AccountContext } from '../../../../../context/AccountContext';

import SpinnerSmall from '../../../../layout/SpinnerSmall';

import { AuthContext } from '../../../../../context/AuthContext';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  setDate
} from 'date-fns';

const getISOStringWithTimeZoneOffset = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split('T')[0];
};

const moment = require('moment');

// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(2)
//   }
// }));

const differnceInDays = (start, stop) => {
  var date1 = new Date(start);
  var date2 = new Date(stop);
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
};

const SimplePopover = ({ stylesStoredInRedux, item }) => {
  const { currentManagerAccount } = useContext(AccountContext);
  const { updateDocument } = useContext(MutationContext);

  const { user } = useContext(AuthContext);
  //######################

  const { enqueueSnackbar } = useSnackbar();

  // const end = moment(selectedDateStop, 'YYYY-MM-DD');

  const [view, setView] = useState(
    item?.dateType?.includes('DYNAMIC') ? 'DYNAMIC' : 'STATIC'
  );
  const [dateType, setDateType] = useState(
    item?.dateType?.includes('DYNAMIC') ? 'DYNAMIC' : 'STATIC'
  );
  // console.log('item.dateType: ', item.dateType);
  // console.log('dateType: ', dateType);

  useEffect(() => {
    switch (true) {
      case view === 'STATIC':
        setDateType('STATIC');
        break;
      case view === 'DYNAMIC':
        setDateType('');
        break;
    }
  }, [view]);

  useEffect(() => {
    setDateType(item?.dateType || 'STATIC');
  }, [item?.dateType]);

  const [startDate, setStartDate] = useState(
    item.startDate ? new Date(item.startDate) : new Date()
  );
  const [endDate, setEndDate] = useState(
    item.endDate ? new Date(item.endDate) : new Date()
  );

  const [previewDatePreset, setPreviewDatePreset] = useState('');

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setStartDate(item.startDate ? new Date(item.startDate) : new Date());
    setEndDate(item.endDate ? new Date(item.endDate) : new Date());
  }, [item.startDate, item.endDate]);

  // ################## WIP ##############

  const handleSelect = (ranges) => {
    console.log('ranges: ', ranges);

    console.log('defaultStaticRanges: ', defaultStaticRanges);

    let selectedDatePreset = 'custom';
    for (let obj of defaultStaticRanges) {
      const defaultStaticRange = obj.range();
      if (
        defaultStaticRange.startDate === ranges.selection.startDate &&
        defaultStaticRange.endDate === ranges.selection.endDate
      ) {
        selectedDatePreset = obj.string;
      }
    }

    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const selectionRange = {
    stylesStoredInRedux,
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
    showMonthAndYearPickers: true
    // color: 'rgba(255,255,255,0.3)',
    // rangeColors: 'rgba(255,255,255,1)'
  };

  let dateRangePickerClassName;

  switch (stylesStoredInRedux.styleName) {
    case 'light':
      dateRangePickerClassName = 'light-background';
      // buttonStyle = { backgroundColor: '#FFFFFF', color: 'black' };
      break;
    case 'dark':
      dateRangePickerClassName = 'dark-background';
      // buttonStyle = { backgroundColor: '#333', color: 'white' };
      selectionRange.color = 'rgba(255,255,255,0.3)';
      selectionRange.rangeColors = 'rgba(255,255,255,1)';
      break;
  }

  //######################

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setStartDate(new Date(item.startDate));
    setEndDate(new Date(item.endDate));
    setDateType(item?.dateType || 'STATIC');
    setView(item?.dateType?.includes('DYNAMIC') ? 'DYNAMIC' : 'STATIC');
    setAnchorEl(null);
  };

  const onClickApply = async (startDate, endDate) => {
    if (!dateType) {
      enqueueSnackbar('Please select dynamic option', {
        variant: 'error'
      });
      return;
    }
    setShowSpinner(true);

    if (view === 'DYNAMIC' && !dateType) {
      enqueueSnackbar('Please select dynamic option', {
        variant: 'error'
      });
      return;
    }

    // document update

    const documentInput = {
      managerAccountId: currentManagerAccount._id,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          //   [itemKey]: inputValue ? inputValue : null,
          startDate: formatDateYYYYMMDD(startDate),
          endDate: formatDateYYYYMMDD(endDate),
          dateType,
          // template: document.template,
          templateDescendant: item.templateDescendant
        }
      ],
      updateKeys: ['startDate', 'endDate', 'dateType']
    };

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });

      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }

    setShowSpinner(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="dark">
      <Button
        style={{
          maxWidth: '30px',
          minWidth: '30px',
          maxHeight: '30px',
          minHeight: '30px'
        }}
        aria-describedby={id}
        variant="standard"
        // color="primary"
        onClick={handleClick}
      >
        <DateRangeIcon style={{ fontSize: '14px' }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {['system_admin'].includes(user.role) && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              onClick={() => setView('STATIC')}
              style={{
                width: '100%',
                background: view === 'STATIC' && '#a62783',
                color: view === 'STATIC' && 'white'
              }}
            >
              STATIC
            </Button>
            <Button
              onClick={() => setView('DYNAMIC')}
              style={{
                width: '100%',
                background: view === 'DYNAMIC' && '#a62783',
                color: view === 'DYNAMIC' && 'white'
              }}
            >
              DYNAMIC
            </Button>
          </div>
        )}
        {/* <Typography className={classes.typography}>
          The content of the Popover.
        </Typography> */}
        {view === 'DYNAMIC' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '40px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div style={{ margin: '40px 0px', width: '250px' }}>
              <span>
                Dynamic dates dynamically adjust to the current start and end
                date of current week or month.
              </span>
            </div>
            <Button
              onClick={() => setDateType('DYNAMIC_WEEK_MONDAY_SUNDAY')}
              style={{
                width: '250px',
                margin: '5px',
                background:
                  dateType === 'DYNAMIC_WEEK_MONDAY_SUNDAY' && '#a62783',
                color: dateType === 'DYNAMIC_WEEK_MONDAY_SUNDAY' && 'white'
              }}
              variant="outlined"
            >
              Week monday-sunday
            </Button>
            <Button
              onClick={() => setDateType('DYNAMIC_WEEK_SUNDAY_SATURDAY')}
              style={{
                width: '250px',
                margin: '5px',
                background:
                  dateType === 'DYNAMIC_WEEK_SUNDAY_SATURDAY' && '#a62783',
                color: dateType === 'DYNAMIC_WEEK_SUNDAY_SATURDAY' && 'white'
              }}
              variant="outlined"
            >
              Week sunday-saturday
            </Button>
            <Button
              onClick={() => setDateType('DYNAMIC_MONTH')}
              style={{
                width: '250px',
                margin: '5px',
                background: dateType === 'DYNAMIC_MONTH' && '#a62783',
                color: dateType === 'DYNAMIC_MONTH' && 'white'
              }}
              variant="outlined"
            >
              Month
            </Button>
          </div>
        ) : (
          <DateRangePicker
            // locale={sv}
            // color="#9953BD"
            // rangeColors="#9953BD"
            color="#a72683"
            rangeColors={['#a72683']}
            // scroll={{ enabled: true }}
            staticRanges={defaultStaticRanges}
            className={dateRangePickerClassName}
            ranges={[selectionRange]}
            onChange={handleSelect}
            weekStartsOn={1}
          />
        )}

        <div
          style={{
            padding: '10px 20px',
            textAlign: 'right',
            minHeight: '51px'
            // backgroundColor: 'red'
          }}
        >
          {showSpinner ? (
            <SpinnerSmall size={20} />
          ) : (
            <>
              <Button
                // variant="contained"
                size="small"
                color="primary"
                // className={classes.margin}
                onClick={handleClose}
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                // variant="contained"
                size="small"
                color="primary"
                // className={classes.margin}
                onClick={() => onClickApply(startDate, endDate)}
              >
                Apply
              </Button>
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(SimplePopover);
