import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorageIcon from '@mui/icons-material/Storage';

import { getDataSourceOAuthUrlMutation } from './../../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

// import GoogleAdsButton from './googleAdsButton/GoogleAdsButton';
import FacebookAdsLoginButton from './FacebookAdsLoginButton';

// import googleAdsLogo from './ads-logo-horizontal-white.png';

import { AccountContext } from '../../../../../../context/AccountContext';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ height: '500px', width: '700px' }} />
    </Draggable>
  );
}

export default function DraggableDialog({
  open,
  setOpen,
  refetchAccountList
  // currentManagerAccount
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  //   const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [addDataSource, { called }] = useMutation(
    getDataSourceOAuthUrlMutation
  );

  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const onClickHandler = async (e, dataSource) => {
    const addDataSourceInput = {
      managerAccountId: currentManagerAccount._id,
      dataSource
    };

    const res = await addDataSource({
      variables: { input: addDataSourceInput }
    });

    if (
      res &&
      res.data &&
      res.data.getDataSourceOAuthUrl &&
      res.data.getDataSourceOAuthUrl.response &&
      res.data.getDataSourceOAuthUrl.url
    ) {
      window.location.replace(res.data.getDataSourceOAuthUrl.url);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const content = (
    <div>
      <form noValidate autoComplete="off">
        <div style={{ display: 'grid', gridTemplateRows: 'Auto 60px' }}>
          <div
            style={{
              display: 'grid',
              gridRowStart: 1,
              gridRowEnd: 1,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              justifyContent: 'left',
              marginTop: '20px'
            }}
          >
            <p>
              {' '}
              <strong>
                By logging into Facebook, with the button below, you let
                NexusTable download your ad performance data and store it in our
                database.
              </strong>
            </p>
            <br />
            <p>
              {' '}
              When you click the button "Login with Facebook" you will be
              redirected to Facebook where you will be able to login and accept
              that NexusTable get access to your ads data.
            </p>
            <br />
            <p>
              Once you accept the connection you will be redirected back to
              NexusTable where you will be able to choose which Facebook Ads
              accounts you wish us to add as a connection. The accounts'
              performance data will be downloaded frequently and stored in our
              database.
            </p>
          </div>

          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              justifyContent: 'center',
              marginTop: '70px'
            }}
          >
            <FacebookAdsLoginButton onClickHandler={onClickHandler} />
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <StorageIcon
            style={{
              verticalAlign: 'text-bottom',
              paddingBottom: '1px',
              fontSize: '24px'
            }}
          />{' '}
          <span style={{ marginLeft: '3px' }}>Connect Facebook Ad Account</span>
        </DialogTitle>
        <DialogContent>
          {content}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
