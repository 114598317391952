import React, { useContext, useState } from 'react';
import { PhoneInput } from 'react-international-phone';

// import { MuiPhone } from './MuiPhone';

import TextField from '@mui/material/TextField';

import { Button } from '@mui/material';

import { useMutation } from '@apollo/client';

import { VerifyUserPhoneNumberMutation } from '../../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../../context/AccountContext';
import { AuthContext } from '../../../../../../context/AuthContext';

import VerificationInput from './VerificationInput';

function VerifyPhoneNumber({
  userInfoAndAccounts,
  view,
  setView,
  handleClose,
  refetchUserInfo
}) {
  const { user: currentUser } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { currentManagerAccount, refetchAccounts } = useContext(AccountContext);
  // const classes = useStyles();

  const [verifyUserPhoneNumber, { called }] = useMutation(
    VerifyUserPhoneNumberMutation
  );

  const [numberOfCodesSent, setNumberOfCodesSent] = useState(0);
  const [code, setCode] = useState('');

  const onClickHandler = async (e) => {
    if (numberOfCodesSent > 5) {
      enqueueSnackbar('Too many tries, please contact support.', {
        variant: 'error'
      });
    }
    const input = {
      managerAccountId: currentManagerAccount?._id,
      phoneNumberVerificationCode: code
    };

    let res;
    try {
      res = await verifyUserPhoneNumber({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(
          'Verification failed, please try again or contact support.',
          {
            variant: 'error'
          }
        );
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.verifyUserPhoneNumber?.response === 'Success') {
      enqueueSnackbar('Phone number successfully verified', {
        variant: 'success'
      });
      refetchUserInfo();
      handleClose();
      // setView('VERIFY_PHONE_NUMBER');
      //   refetchUserInfo();
      //   refetchAccounts();

      if (userInfoAndAccounts?._id === currentUser?._id) {
        // refetchUsersAccountsList();
      }
    }
    setNumberOfCodesSent(numberOfCodesSent + 1);
  };
  return (
    <div
      style={{
        padding: '10px',
        width: '500px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      {/* <TextField
        // defaultCountry="ua"
        value={phone}
        onChange={(phone) => setPhone(phone)}
      /> */}
      <VerificationInput code={code} setCode={setCode} />
      <Button
        variant="outlined"
        onClick={onClickHandler}
        style={{
          background: code?.length === 4 && 'green',
          color: code?.length === 4 && 'white'
        }}
      >
        Verify
      </Button>
    </div>
  );
}

export default VerifyPhoneNumber;
