import React, { useContext, useEffect, useState } from 'react';
// import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import { useMutation } from '@apollo/client';

import { AddDataSourceAlertRuleMutation } from '../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../context/AccountContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       borderRadius: '5px',
//       height: '20px',
//       width: '37px',
//       backgroundColor: 'rgba(0, 181, 8, 1)',
//       '&:hover': {
//         background: 'rgba(20, 201, 28, 1)'
//       }
//     }
//   },
//   extendedIcon: {
//     color: 'white',
//     marginRight: theme.spacing(1)
//   }
// }));

export default function FloatingActionButtons({ dataSourceInfo, refetch }) {
  const { currentManagerAccount } = useContext(AccountContext);
  // const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [addRule, { called }] = useMutation(AddDataSourceAlertRuleMutation);

  const onClickAddHandler = async () => {
    const input = {
      managerAccountId: currentManagerAccount._id,
      dataSourceId: dataSourceInfo._id,
      dataSourceRef: dataSourceInfo.dataSourceRef,
      type: 'custom',
      field: 'cost',
      operator: 'lte',
      value: 0
    };

    let res;
    try {
      res = await addRule({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.addDataSourceAlertRule.response === 'Success') {
      enqueueSnackbar('New rule created', {
        variant: 'success'
      });
      refetch();
      // refetchDataSourceInfo();
    }
  };

  // console.log('input: ', input);
  return (
    <div
      // className={classes.root}
      onClick={onClickAddHandler}
    >
      <Fab
        color="primary"
        aria-label="add"
        style={{
          color: 'white',
          // margin: theme.spacing(1),
          borderRadius: '5px',
          height: '20px',
          width: '37px',
          backgroundColor: 'rgba(0, 181, 8, 1)'
        }}
      >
        <AddIcon style={{ color: 'white' }} />
      </Fab>
      {/* <Fab aria-label="edit">
        <EditIcon style={{color: 'white'}} />
      </Fab> */}
      {/* <Fab variant="extended">
        <NavigationIcon className={classes.extendedIcon} />
        Navigate
      </Fab>
      <Fab disabled aria-label="like">
        <FavoriteIcon />
      </Fab> */}
    </div>
  );
}
