import React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ChipsInput({ input, setInput }) {
  const onChangeHandler = (e, value) => {
    console.log('debug101>value: ', value);
    setInput({ ...input, values: value });
  };
  console.log('debug91>input.values: ', input?.values);
  return (
    <Autocomplete
      style={{ width: '450px' }}
      multiple
      id="tags-filled"
      options={[]}
      freeSolo
      value={[...(input.values || [])]}
      onChange={onChangeHandler}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Keywords"
          placeholder="Add keyword"
        />
      )}
    />
  );
}
