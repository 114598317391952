import React, { Fragment } from 'react';

const NotFound = () => {
  console.log('render NotFound');
  return (
    <Fragment>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1500
        }}
      >
        <h2 className="x-large text-primary">
          <i className="fas fa-exclamation-triangle" /> Page Not Found
        </h2>
        <p className="large">Sorry, this page does not exist</p>
      </div>
    </Fragment>
  );
};

export default NotFound;
