import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { ClickAwayListener } from '@mui/base';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import { SketchPicker } from 'react-color';

import PopContent from './PopContent';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

import SettingsIcon from '@mui/icons-material/Settings';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     // width: 500
//   },
//   typography: {
//     padding: theme.spacing(2)
//   }
// }));

export default function PositionedPopper({
  currentManagerAccountId,
  updateDocument,
  item,
  itemStyle,
  rowNumber,
  itemKey,
  checked,
  popperPosition
}) {
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  // const classes = useStyles();

  const [update, toggleUpdate] = useState(false);

  const itemValue = item[itemKey];

  const [inputValue, setInputValue] = useState('');

  const handleClick = (newPlacement) => (event) => {
    if (open) {
      return;
    }
    setAnchorEl(event.currentTarget);
    // if (open) {
    //   const documentInput = {
    //     checkedDocumentId: item._id,
    //     document: { [itemKey]: inputValue },
    //     updateKey: itemKey,
    //     action: 'UPDATE_DOCUMENT',
    //     managerAccountId: currentManagerAccountId,
    //     documentType: item.type,
    //     level: item.level,
    //     level_1_container_id: item.level_1_container_id,
    //     level_2_container_id: item.level_2_container_id,
    //     level_3_container_id: item.level_3_container_id,
    //     level_4_container_id: item.level_4_container_id,
    //     level_5_container_id: item.level_5_container_id,
    //     level_6_container_id: item.level_6_container_id,
    //     level_7_container_id: item.level_7_container_id
    //   };

    //   if (update) {
    //     addDocument({ variables: { input: { ...documentInput } } });
    //     toggleUpdate(false);
    //   }
    // }

    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChange = (color) => {
    // setColor({ ...color.rgb });
    toggleUpdate(true);
  };

  const handleClickAway = () => {};

  //##################################
  // select conversion type

  // select conversion window

  //##################################

  const popper = (
    <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper style={{ border: '1px solid rgba(255,255,255,0.15)' }}>
            {open && (
              // <ClickAwayListener onClickAway={handleClickAway}>
              <PopContent
                setOpen={setOpen}
                item={item}
                itemKey={itemKey}
                currentManagerAccountId={currentManagerAccountId}
              />
              // </ClickAwayListener>
            )}

            {/* <SketchPicker
              color={color}
              onChange={handleChange}
              onClick={handleClick}
            /> */}
            {/* <Typography className={classes.typography}>
            The content of the Popper.
          </Typography> */}
          </Paper>
        </Fade>
      )}
    </Popper>
  );

  return (
    <div
      ref={ref}
      // className={classes.root}
      style={{
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 0px',
        borderColor: itemStyle['borderColor'],
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        left: '53px',
        display: 'grid',
        height: '100%',
        width: '100%',
        //   padding: '10px 7px',
        padding: '10px 14px',
        gridTemplateColumns: 'auto 15px',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis'
        cursor: 'pointer'
      }}
      onClick={handleClick(popperPosition)}
    >
      {(inView || exportMode) && (
        <>
          <div
            style={{
              gridColumn: 1,
              gridRow: 1,
              width: '100%',
              overflow: 'hidden'
            }}
          >
            <p
              style={{
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <span>
                {itemValue?.name || (
                  <SettingsIcon
                    style={{
                      marginLeft: '26px',
                      color: 'rgba(150,150,150,0.5)'
                    }}
                  />
                )}
              </span>

              <p
                style={{
                  fontSize: '8px',
                  color: '#bcbcbc'
                  // visibility: 'hidden'
                }}
              >
                {' '}
                {/* {subValue} */}
              </p>
            </p>
          </div>
          {/* <div style={{ gridColumn: 2, gridRow: 1, textAlign: 'end' }}>
            <span style={{ display: 'inline' }}>
              <i
                className="fas fa-pen hover-grey"
                style={{
                  fontSize: '8px',
                  // color: 'grey',
                  padding: '4px 0 4px 2px',
                  cursor: 'pointer'
                }}
                // onClick={handleClick(popperPosition)}
              ></i>
            </span>
          </div> */}

          {open && popper}
        </>
      )}

      {/* <Button onClick={handleClick('bottom')}>bottom</Button> */}
    </div>
  );
}
