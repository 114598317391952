import React from 'react';

import ActionTypeItem from '../../bulk-edit-menu/bulk-edit-action-settings/ActionTypeItem';

function ActionSettings({
  currentManagerAccountId,
  item,
  itemKey,
  document,
  setDocument,
  disabled
}) {
  return (
    <div style={{ fontSize: '12px' }}>
      {/* <BulkEditActionSettings
        item={item}
        itemKey={itemKey}
        document={document}
        setDocument={setDocument}
        disabled={disabled}
      /> */}

      <ActionTypeItem
        disabled={disabled}
        currentManagerAccountId={currentManagerAccountId}
        // updateDocument={updateDocument}
        item={item}
        // itemStyle={itemStyle}
        // keyForMap={item._id + '_' + itemKey + '_' + index}
        // index={index}
        itemKey={itemKey}
        itemValue={item[itemKey]}
        // rowNumber={rowNumber}
        textAlignRight={true}
        // checked={checked}
        popperPosition={'right'}
        document={document}
        setDocument={setDocument}
      />
    </div>
  );
}

export default ActionSettings;
