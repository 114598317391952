import React, { useState, useEffect, useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';

import TaxonomyTable from './TaxonomyTable';

import TaxonomyWidgetList from './TaxonomyWidgetList';

import CloseIcon from '@mui/icons-material/Close';

import { Button } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import Table from './Table';

import { styled, useTheme } from '@mui/material/styles';

import QuickCreate from './QuickCreate';

function Taxonomy() {
  const theme = useTheme();
  const [editOpen, setEditOpen] = useState(false);

  const [displayMode, setDisplayMode] = useState('VALUE');

  const [deleted, setDeleted] = useState(null);

  const [arrayOfData, setArrayOfData] = useState([]);
  const [columns, setColumns] = useState([
    // 'account',
    'warnings',
    'status',
    'target',
    'taxonomy',
    'taxonomy'
  ]);
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  // const dragEnd = (e, value) => {
  //   console.log('dragEnd');
  //   const target = e.target;

  //   setTimeout(() => {
  //     target.style.display = 'flex';
  //   }, 0);
  // };

  useEffect(() => {
    setArrayOfData([
      { _id: 'alksdmafalsd', name: 'test', widgets: [], target: 'ALL' },
      {
        _id: 'alksdfmfalsd',
        name: 'test',
        widgets: [],
        target: 'FACEBOOK_ADS_CAMPAIGN'
      },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' },
      { _id: 'alksdmfaelsd', name: 'test', widgets: [], target: 'ALL' }
    ]);
  }, []);

  // ##############################################################################################
  // ################################### START OF HANDLE DATA #####################################
  // ##############################################################################################

  // ##############################################################################################
  // ################################### END OF HANDLE DATA #######################################
  // ##############################################################################################

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dropRow = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const droppedItem = JSON.parse(droppedItemString);
    console.log('onDropDelete>droppedItem: ', droppedItem);

    setDeleted(droppedItem);

    // let copyOfWidgets = [...widgets];

    // console.log('droppedItem.origin: ', droppedItem.origin);
    // if (droppedItem.origin === 'taxonomy_row') {
    //   console.log('droppedItem.index: ', droppedItem.originIndex);
    //   copyOfWidgets.splice(droppedItem.originIndex, 1);

    //   console.log('copyOfWidgets: ', copyOfWidgets);
    // }
    // setWidgets([...copyOfWidgets, droppedItem.value]);

    // const droppedItemElement = document.getElementById(droppedItem);
  };

  return (
    <div
      style={{
        padding: '0 15px 0 15px'
        // width: '100%',
        // height: 'calc(100vh - 64px)',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center'
      }}
    >
      {/* <div
        style={{
          width: '90%',
          // height: '600px',
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          // alignItems: 'center',
          background: 'rgba(255,255,255,0.3)',
          backdropFilter: 'blur(10px)',
          borderRadius: '5px',
          overflow: 'hidden'
        }}
      > */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '60px',
          marginTop: '5px',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            // marginBottom: '40px',
            fontWeight: 'bold'
            // padding: '10px',
            // color: '#2a52a3'
          }}
        >
          <QuickCreate
            checkedBoxes={checkedBoxes}
            setCheckedBoxes={setCheckedBoxes}
          />
        </span>
        <div
          style={{
            // padding: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '200px'
          }}
        >
          <div
            style={{ padding: '3px' }}
            // onDrop={(e, value) => {
            //   const droppedItemString =
            //     e.dataTransfer.getData('droppedItem');
            //   const droppedItem = JSON.parse(droppedItemString);
            //   setDeleted(droppedItem);
            // }}
            onDrop={(e) => dropRow(e)}
            onDragOver={(e) => dragOver(e)}
            // draggable={true}
            // onDragStart={(e) => dragStart(e, row, 'taxonomy_table', index)}
            // onDragEnd={(e) => dragEnd(e)}
          >
            <DeleteIcon />
          </div>
          <Button
            size="small"
            variant="text"
            style={{
              height: '30px',
              background: 'rgba(255,255,255,0.3)'
            }}
            onClick={() =>
              setDisplayMode(displayMode === 'EXAMPLE' ? 'WIDGETS' : 'EXAMPLE')
            }
          >
            Display {displayMode === 'EXAMPLE' ? 'WIDGETS' : 'EXAMPLE'}
          </Button>
        </div>
        {/* {!editOpen ? (
          <EditIcon
            style={{ padding: '5px', cursor: 'pointer' }}
            onClick={() => setEditOpen(!editOpen)}
          />
        ) : (
          <div></div>
        )} */}
      </div>
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'row'
          // display: 'grid',
          // gridTemplateColumns: editOpen ? 'calc(98vh - 400px), 400px' : '98vw',
          width: editOpen ? 'calc(100% - 280px)' : 'calc(100% - 20px)'
        }}
      >
        {/* <div
          style={{
            // gridRow: 0,
            // gridColumn: 0,
            borderRadius: '5px'
            // overflowX: 'scroll',
            // width: editOpen ? 'calc(100vw - 310px)' : 'calc(100vw - 50px)'
          }}
        > */}
        <Table
          arrayOfData={arrayOfData}
          setArrayOfData={setArrayOfData}
          columns={columns}
          setColumns={setColumns}
          checkedBoxes={checkedBoxes}
          setCheckedBoxes={setCheckedBoxes}
        />
        {/* </div> */}
        {/* {true && ( */}
        <div
          style={{
            top: '65px',
            marginTop: '5px',
            position: 'absolute',

            right: editOpen ? '-140px' : '-400px',
            display: 'flex',
            flexDirection: 'row',
            background:
              theme?.palette?.mode === 'dark'
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(255,255,255,0.6)',
            borderRadius: '5px 0 0 5px',
            overflow: 'hidden',
            backdropFilter: 'blur(10px)',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
            zIndex: '1000'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '20px',
              // height: '600px',
              // background: 'rgba(255,255,255,0.6)'
              background:
                theme?.palette?.mode === 'dark'
                  ? 'rgba(255,255,255,0.1)'
                  : 'rgba(255,255,255,0.6)',
              cursor: 'pointer'
            }}
            onClick={() => setEditOpen(!editOpen)}
          >
            <span style={{ cursor: 'pointer', padding: '5px' }}>
              {editOpen ? (
                <CloseIcon
                  style={{ fontSize: '15px' }}
                  // onClick={() => setEditOpen(false)}
                />
              ) : (
                <EditIcon
                  style={{ padding: '5px', cursor: 'pointer' }}
                  // onClick={() => setEditOpen(!editOpen)}
                />
              )}
            </span>
            <span
              style={{
                fontSize: '12px',
                padding: '5px',
                transform: 'rotate(-90deg)'
              }}
            >
              Widgets
            </span>
            <span></span>
          </div>
          <div
            style={{
              // height: '600px',
              width: '400px'
              // gridRow: 0,
              // gridColumn: 1

              // position: 'absolute'
            }}
          >
            {/* <div
                style={{
                  width: '100%',
                  height: '27px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  background: 'rgba(150,150,150,0.3)'
                }}
              >
                <span
                  style={{ cursor: 'pointer', padding: '5px' }}
                  onClick={() => setEditOpen(false)}
                >
                  <CloseIcon style={{ fontSize: '15px' }} />
                </span>
                <div
                  style={{
                    fontSize: '12px',
                    padding: '5px',
                    transform: 'rotate(-90deg)'
                  }}
                >
                  Widgets
                </div>
              </div> */}
            <div>
              {/* <div>Search</div> */}
              <TaxonomyWidgetList />
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
      {/* </div> */}
    </div>
  );
}

export default Taxonomy;
