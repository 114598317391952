const numberWidget = async (widget, pres, slide, colorPalette) => {
  if (widget.isShowingCard) {
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
      fill: {
        color: 'FFFFFF',
        transparency: (widget.opacity - 1) * -100 //5
      },
      border: { pt: '1', color: 'f1f1f1', transparency: 10 },
      w: +widget.width.split('px')[0] / 72 / 2,
      h: +widget.height.split('px')[0] / 72 / 2,
      x: +widget.left.split('px')[0] / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2,

      rectRadius: 0.05,
      shadow: {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }
    });
  }

  slide.addText(widget.value, {
    w: +widget.width.split('px')[0] / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,

    margin: 0,
    fontFace: 'Roboto', // 'Arial',
    fontSize: 8,
    color: '000000',
    bold: true,
    // underline: true,
    isTextBox: true,
    align: 'center'
    // fill: { color: 'F1F1F1' }
    // highlight: '#bcbcbc'
  });

  let titleWidth = Math.round(widget.title.length * 9 + 10);
  // if (titleWidth < 120) {
  //   titleWidth = 120;
  // }

  slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
    fill: {
      color: 'ababab',
      transparency: 90
    },
    border: { pt: '1', color: 'f1f1f1', transparency: 10 },
    w: (titleWidth + 20) / 72 / 2,
    h: 25 / 72 / 2,
    x:
      +widget.left.split('px')[0] / 72 / 2 +
      widget.width.split('px')[0] / 72 / 2 -
      (titleWidth + 20) / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2 + 0 / 72 / 2,

    rectRadius: 0.01
    // shadow: {
    //   type: 'outer',
    //   blur: 6,
    //   offset: 2,
    //   angle: 45,
    //   color: 'bcbcbc',
    //   opacity: 0.2
    // }
  });

  slide.addText(widget.title, {
    w: titleWidth / 72 / 2,
    h: 20 / 72 / 2,
    x:
      +widget.left.split('px')[0] / 72 / 2 +
      widget.width.split('px')[0] / 72 / 2 -
      (titleWidth + 10) / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2 + 2 / 72 / 2,

    margin: 0,
    fontFace: 'Roboto', // 'Arial',
    fontSize: 8,
    color: '000000',
    bold: true,
    // underline: true,
    isTextBox: true,
    align: 'left'
    // fill: { color: 'F1F1F1' }
    // highlight: '#bcbcbc'
  });
};

export default numberWidget;
