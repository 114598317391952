import React, { Fragment, useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { styled, useTheme } from '@mui/material/styles';

import AssessmentIcon from '@mui/icons-material/Assessment';

import ImageIcon from '@mui/icons-material/Image';

// icons

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { DataContext } from '../../../../context/DataContext';
import { FilterContext } from '../../../../context/FilterContext';
import { DialogContext } from '../../../../context/DialogContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import backgroundImagePattern from './../../../../img/bg-container-purple.png';
import octagonal_background from './../../../../img/hexagonal_background.jpg';

import backgroundBeachImage from './../../../../img/beachimg.jpg';

import formatDateYYYYMMDD from '../../roitable_functions/formatDateYYYYMMDD';

import ReportBuilderAssetDialogContent from './ReportBuilderDialogAssetContent';

function PaperComponent(props) {
  const isExpanded = false;
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ReportBuilderAssetDialog = (
  {
    // open,
    // setOpen,
    // selectedDateStartTimebox,
    // selectedDateStopTimebox
  }
) => {
  const {
    reportBuilderAssetDialogOpen: open,
    setReportBuilderAssetDialogOpen: setOpen
  } = useContext(DialogContext);
  // const {
  //   //     reportBuilderDialogOpen: open,
  //   //     setReportBuilderDialogOpen: setOpen,
  // } = useContext(InterfaceContext);
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  const [displayView, setDisplayView] = useState('stats');

  // const [selectedDateStartTimeboxReport, setSelectedDateStartTimeboxReport] =
  //   useState(new Date(selectedDateStartTimebox));

  // const [selectedDateStopTimeboxReport, setSelectedDateStopTimeboxReport] =
  //   useState(new Date(selectedDateStopTimebox));

  // const { viewDialogView, setViewDialogView } = useContext(DialogContext);
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let headerText = 'Asset Library';
  let Icon = ImageIcon;

  // switch (displayView) {
  //   case 'kanban':
  //     headerText = 'Kanban';
  //     break;
  //   case 'list':
  //     headerText = 'List';
  //     Icon = TableRowsIcon;
  //     break;
  //   case 'calendar':
  //     headerText = 'Calendar';
  //     Icon = CalendarViewWeekIcon;
  //     break;
  //   case 'charts':
  //     headerText = 'Charts';
  //     Icon = DonutLargeIcon;
  //     break;
  // }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullScreen={isExpanded}
      // paperFullWidth={true}
      // paper={{ height: '90hw', width: '1000px' }}
      // maxWidth="lg"
      maxWidth="80%"
      // PaperProps={{
      //   style: {
      //     minWidth: '80%',
      //     // backgroundImage:
      //     //   theme.palette.mode === 'dark'
      //     //     ? // ? `url(${backgroundImagePattern})`
      //     //       `url(${octagonal_background})`
      //     //     : `url(${backgroundBeachImage})`,
      //     // backgroundSize: 'cover',
      //     // backgroundAttachment: 'fixed'
      //     background:
      //       theme?.palette?.mode === 'dark'
      //         ? 'rgba(255,255,255,0.1)'
      //         : 'rgba(255,255,255,0.3)',
      //     backdropFilter: 'blur(10px)'
      //   }
      // }}
      PaperProps={{
        style: {
          minWidth: '80%',
          backgroundImage:
            theme.palette.mode === 'dark'
              ? // ? `url(${backgroundImagePattern})`
                `url(${octagonal_background})`
              : `url(${backgroundBeachImage})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed'
        }
      }}
    >
      <div
        style={
          {
            // background: `
            // linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
            // linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%)
            // `
            //     background: `
            // linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
            // linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
            // linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)
            // `
          }
        }
      >
        <div
          style={
            {
              // backgroundImage: `url(${backgroundImagePattern})`,
              // backgroundImage: `url(${backgroundBeachImage})`,
              // backgroundImage:
              //   theme.palette.mode === 'dark'
              //     ? // ? `url(${backgroundImagePattern})`
              //       `url(${octagonal_background})`
              //     : `url(${backgroundBeachImage})`,
              // backgroundSize: 'cover',
              // backgroundAttachment: 'fixed'
            }
          }
        >
          <DialogTitle
            style={{ cursor: 'move', padding: '12px 12px 0px 12px' }}
            id="draggable-dialog-title"
          >
            <div
              style={{
                // display: 'grid',
                // gridTemplateColumns: 'auto 40px 40px 30px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 1,
                  gridColumnEnd: 1
                }}
              >
                <span style={{ paddingLeft: '7px' }}>
                  <Icon
                    style={{
                      verticalAlign: 'text-bottom',
                      paddingRight: '5px'
                    }}
                  />{' '}
                  {headerText}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '14px',
                  alignItems: 'center'
                }}
              >
                {/* <span>
                    {formatDateYYYYMMDD(selectedDateStartTimeboxReport)}
                  </span>
                  <span> - </span>{' '}
                  <span>
                    {formatDateYYYYMMDD(selectedDateStopTimeboxReport)}
                  </span> */}
                {/* <DateRangePicker
                  selectedDateStartTimeboxReport={
                    selectedDateStartTimeboxReport
                  }
                  setSelectedDateStartTimeboxReport={
                    setSelectedDateStartTimeboxReport
                  }
                  selectedDateStopTimeboxReport={selectedDateStopTimeboxReport}
                  setSelectedDateStopTimeboxReport={
                    setSelectedDateStopTimeboxReport
                  }
                /> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '65px'
                }}
              >
                <div
                  style={{
                    display: 'grid',
                    gridColumnStart: 2,
                    gridColumnEnd: 2,
                    alignItems: 'end'
                  }}
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      right: '0px',
                      textAlign: 'right'
                      // paddingRight: '25px'
                    }}
                  >
                    <span
                      style={{ width: '50px' }}
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {isExpanded ? (
                        <FullscreenExitIcon className="hover-light-grey" />
                      ) : (
                        <FullscreenIcon className="hover-light-grey" />
                      )}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridColumnStart: 3,
                    gridColumnEnd: 3,
                    alignItems: 'end'
                  }}
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      right: '0px',
                      textAlign: 'right'
                      // paddingRight: '25px'
                    }}
                  >
                    <span style={{ width: '50px' }} onClick={handleClose}>
                      <CloseIcon className="hover-light-grey" />
                    </span>
                  </div>
                </div>
              </div>
              {/* <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1,
                gridRowStart: 2,
                gridRowEnd: 2
              }}
            >
              <span style={{ paddingLeft: '50px', fontSize: '12px' }}>
                {item?.path}
              </span>
            </div> */}
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
            <ReportBuilderAssetDialogContent isExpanded={isExpanded} />
          </DialogContent>
        </div>
      </div>
    </Dialog>
  );
};

export default ReportBuilderAssetDialog;
