import React, { useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { alpha, styled } from '@mui/material/styles';

import { useSnackbar } from 'notistack';

export default function SwitchesSize({
  dataLevel,
  setDataLevel,
  currentAccountIds
}) {
  const { enqueueSnackbar } = useSnackbar();

  const PurpleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#a72683',
      '&:hover': {
        backgroundColor: alpha('#a72683', theme.palette.action.hoverOpacity)
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#a72683'
    }
  }));

  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (currentAccountIds.length > 1 && !dataLevel) {
      setDataLevel('campaign');
      setChecked(false);
    }
  }, [currentAccountIds]);

  const toggleChecked = () => {
    if (checked) {
      setDataLevel('campaign');
      setChecked(false);
    } else {
      if (currentAccountIds.length > 1) {
        enqueueSnackbar('Please select only one account.', {
          variant: 'error'
        });
        return;
      }
      setDataLevel('adset');
      setChecked(true);
    }
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <PurpleSwitch
            // size="small"
            checked={checked}
            onChange={toggleChecked}
            // color="blue"
            {...label}
          />
        }
        label="Ad sets"
      />
    </FormGroup>
  );
}
