import React, { useContext, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Button } from '@mui/material';

import { useLazyQuery, useMutation } from '@apollo/client';

import { MutationContext } from '../../../../../context/MutationContext';

import {
  accountLabelsQuery,
  updateDocumentMutation
} from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';

import { useSnackbar } from 'notistack';

// import EditIcon from '@mui/icons-material/Edit';
// import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import CancelIcon from '@mui/icons-material/Cancel';

import CreateLabel from './CreateLabel';

import ColorPicker from './ColorPicker';

import EditName from './EditName';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({
  item,
  handleClose = { handleClose }
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [accountLabels, setAccountLabels] = useState([]);
  const [edit, setEdit] = useState(false);
  const [tempLabelList, setTempLabelList] = React.useState([]);

  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // const [updateDocument] = useMutation(updateDocumentMutation);

  const { updateDocument } = useContext(MutationContext);

  const variables = {
    accountsLabelsInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccount._id,
      accountIds: [item.accountId]
    }
  };

  const [loadLabels, { called, loading, data, refetch }] = useLazyQuery(
    accountLabelsQuery,
    { variables, fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    const filteredLabels =
      accountLabels?.filter((obj) => {
        for (let label of item?.labels || []) {
          if (label._id === obj._id) {
            return true;
          }
        }
      }) || [];

    if (tempLabelList) {
      const key = '_id';
      const array = [...tempLabelList, ...filteredLabels];
      const arrayUniqueByKey = [
        ...new Map(array.map((item) => [item[key], item])).values()
      ];
      setTempLabelList(arrayUniqueByKey);
    } else {
      setTempLabelList(filteredLabels || []);
    }
  }, [item?.labels, accountLabels]);

  useEffect(() => {
    loadLabels();
  }, [item]);

  useEffect(() => {
    const sortedAccountLabels = [...(data?.accountLabels || [])];

    sortedAccountLabels.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setAccountLabels(sortedAccountLabels || []);
  }, [data?.accountLabels]);

  const onCancelHandler = () => {
    const filteredLabels =
      accountLabels?.filter((obj) => {
        for (let label of item?.labels || []) {
          if (label._id === obj._id) {
            return true;
          }
        }
      }) || [];

    setTempLabelList(filteredLabels);
    setEdit(false);
    handleClose();
  };

  const onSaveHandler = async () => {
    const labelIds = tempLabelList.map((obj) => obj._id);

    const documentInput = {
      managerAccountId: currentManagerAccount._id,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          labels: labelIds
        }
      ],
      updateKeys: ['labels']
    };

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });

      const response = res?.data?.updateDocument?.response;
      // const success = true;

      if (response === 'Success') {
        enqueueSnackbar('Saved', {
          variant: 'success'
        });
        setEdit(false);
        handleClose();
      } else {
        enqueueSnackbar('Error', {
          variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar('Error', {
        variant: 'error'
      });
    }
  };

  const handleRemoveLabel = (id) => {
    setTempLabelList(tempLabelList.filter((obj) => obj._id !== id));
  };

  return (
    <div
      style={{
        margin: '0px 0px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '150px'
      }}
    >
      <div style={{ marginBottom: '20px' }}>Edit Labels</div>
      <div>
        <Autocomplete
          open={open}
          ListboxProps={{ onMouseLeave: () => setOpen(false) }}
          value={tempLabelList}
          onChange={(event, newValue) => {
            setEdit(true);
            setTempLabelList(newValue);
          }}
          multiple
          size="small"
          id="checkboxes-tags-demo"
          options={accountLabels}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              onClick={() => setOpen(!open)}
              {...params}
              label="Labels"
              placeholder="+ Add labels"
            />
          )}
        />
      </div>
      <div
        style={{
          margin: '10px 0px',
          padding: '0px 5px',
          border: '1px solid rgba(150,150,150,0.3)',
          borderRadius: '5px'
        }}
      >
        {tempLabelList.map((label) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              className="nexustable-menu-label"
              style={{
                background: label.color || '#21285a',
                borderColor: label.color || '#21285a'
                // padding: '5px',
                // margin: '5px 0px',
                // borderRadius: '5px'
              }}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                {label.name}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              <div
                className="hover-grey"
                style={{ margin: '0px 5px', cursor: 'pointer' }}
              >
                <ColorPicker label={label} refetchLabels={refetch} />
                {/* <FormatColorFillIcon
                  style={{ position: 'relative', top: '2px', width: '20px' }}
                /> */}
              </div>
              <div
                className="hover-grey"
                style={{ margin: '0px 5px', cursor: 'pointer' }}
              >
                <EditName label={label} refetchLabels={refetch} />
                {/* <EditIcon
                  style={{ position: 'relative', top: '2px', width: '20px' }}
                /> */}
              </div>
              <div style={{ margin: '0px 5px' }}>
                <CancelIcon
                  className="hover-grey"
                  style={{
                    position: 'relative',
                    top: '2px',
                    width: '20px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleRemoveLabel(label._id)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <CreateLabel
            item={item}
            tempLabelList={tempLabelList}
            setTempLabelList={setTempLabelList}
            refetchLabels={refetch}
          />
          {/* <Button>Create Label</Button> */}
        </div>
        {true && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              flexDirection: 'row',
              fontSize: '14px'
              // height: '30px'
            }}
          >
            <Button
              size="small"
              variant="outlined"
              // className="hover-grey"
              style={{ margin: '5px', cursor: 'pointer' }}
              onClick={onCancelHandler}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              // className="hover-grey"
              style={{ margin: '5px', cursor: 'pointer' }}
              onClick={onSaveHandler}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
