import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// import { addUserMutation } from './../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import CheckList from './CheckList';

import { addAppleSearchAdsDataSourceMutation } from '../../../../../../../graphql/queries';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import { refetchUsersAccountsList } from './../../../../../actions/account';

import { AccountContext } from '../../../../../../../context/AccountContext';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxHeight: '620px', width: '570px' }} />
    </Draggable>
  );
}

const DraggableDialog = ({
  open,
  setOpen,
  refetchAccountInfo,
  // currentManagerAccount,
  selectedAccountId,
  refetechDataSources,
  // userId,
  setDataSource,
  code,
  setCode
}) => {
  const { currentManagerAccount } = useContext(AccountContext);
  //   const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [permissions, setPermissions] = useState('rw');
  const [filterSearch, setFilterSearch] = useState('');
  const [isLoading, toggleIsLoading] = useState(true);

  const [checked, setChecked] = useState([]);

  const [addDataSource, { called }] = useMutation(
    addAppleSearchAdsDataSourceMutation
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = async () => {
    if (!checked.length > 0) {
      enqueueSnackbar('Select account or press cancel.', {
        variant: 'error'
      });
      return;
    }

    let normalizedPermissions = [];

    switch (permissions) {
      case 'r':
        normalizedPermissions = ['read'];
        break;
      case 'rw':
        normalizedPermissions = ['read', 'write'];
        break;
    }

    const input = {
      managerAccountId: currentManagerAccount._id,
      accountIds: checked
    };

    let res;
    try {
      toggleIsLoading(true);
      res = await addDataSource({ variables: { input } });
      toggleIsLoading(false);
    } catch (error) {
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.addAppleSearchAdsDataSource?.response === 'success') {
      enqueueSnackbar('Data Source(s) successfully added', {
        variant: 'success'
      });

      refetechDataSources();

      // if(checked.includes(userId)){
      //   refetchUsersAccountsList();
      // };
      // refetchAccountInfo();
      setCode(null);
      setOpen(false);
    }
  };

  const handleClose = () => {
    setDataSource('');
    setOpen(false);
  };

  const onChangeHandler = (e, field) => {
    switch (field) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'filterSearch':
        setFilterSearch(e.target.value);
        break;
    }
  };

  const content = (
    <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
      <form noValidate autoComplete="off">
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'auto',
            gridRowStart: 1,
            gridRowEnd: 1
          }}
        >
          <div style={{ display: 'grid', margin: '10px auto 20px auto' }}>
            <TextField
              style={{ width: '500px' }}
              variant="standard"
              id="standard-basic"
              label="Search"
              value={filterSearch}
              onChange={(e) => onChangeHandler(e, 'filterSearch')}
            />
          </div>
          <div
            style={{
              display: 'grid',
              paddingTop: '5px',
              margin: '0px auto',
              width: '500px'
            }}
          >
            <CheckList
              filterSearch={filterSearch}
              currentManagerAccount={currentManagerAccount}
              checked={checked}
              setChecked={setChecked}
              code={code}
              isLoading={isLoading}
              toggleIsLoading={toggleIsLoading}
            />
          </div>

          {/* <div
            style={{
              gridRowStart: 3,
              gridRowEnd: 3,
              paddingTop: '30px',
              margin: '0px auto'
            }}
          >
            <FormControl style={{ width: '500px' }}>
              <InputLabel id="demo-simple-select-label">Permissions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={permissions}
                onChange={(e) => setPermissions(e.target.value)}
              >
                <MenuItem value={'r'}>Read</MenuItem>
                <MenuItem value={'rw'}>Read and Write</MenuItem>


              </Select>
            </FormControl>
          </div> */}
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <AccountBalanceIcon
            style={{
              verticalAlign: 'text-bottom',
              paddingBottom: '1px',
              fontSize: '24px'
            }}
          />{' '}
          <span style={{ marginLeft: '3px' }}>
            Add Apple Search Ads Data Sources
          </span>
        </DialogTitle>
        <DialogContent>
          {content}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DraggableDialog.propTypes = {
  // userId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  // userId: state.auth.user._id
});

export default connect(mapStateToProps, null)(DraggableDialog);
