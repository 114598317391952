import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { useSnackbar } from 'notistack';

import CheckList from './CheckList';

import { addManagerAccountToAccountRelationMutation } from './../../../../../graphql/queries';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { refetchUsersAccountsList } from './../../../../../actions/account';

import { AuthContext } from '../../../../../context/AuthContext';
import { AccountContext } from '../../../../../context/AccountContext';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxHeight: '620px', width: '570px' }} />
    </Draggable>
  );
}

const DraggableDialog = ({
  open,
  setOpen,
  refetchAccountInfo,
  selectedAccountId,
  refetchUsersAccountsList,
  userId
}) => {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount, refetchAccounts } = useContext(AccountContext);
  //   const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [permissions, setPermissions] = useState('admin');

  const [managerAccountIdToAdd, setManagerAccountIdToAdd] = useState('');

  const [addManagerAccountToAccountRelation, { called }] = useMutation(
    addManagerAccountToAccountRelationMutation
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = async () => {
    // if (!input.length > 0) {
    //   enqueueSnackbar('Error', {
    //     variant: 'error'
    //   });
    //   return;
    // }

    // let normalizedPermissions = [];

    // switch (permissions) {
    //   case 'r':
    //     normalizedPermissions = ['read'];
    //     break;
    //   case 'rw':
    //     normalizedPermissions = ['read', 'write'];
    //     break;
    // }

    const input = {
      managerAccountId: currentManagerAccount._id,
      managerAccountIdToAdd,
      accountId: selectedAccountId,
      permissions: ['read', 'write'],
      role: 'admin'
    };

    let res;
    try {
      res = await addManagerAccountToAccountRelation({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar('Fail, contact support', {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.addManagerAccountToAccountRelation?.response === 'success') {
      refetchAccounts();
      enqueueSnackbar('Account access successfully added', {
        variant: 'success'
      });

      refetchAccountInfo();
    }

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeHandler = (e, field) => {};

  const content = (
    <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
      <form noValidate autoComplete="off">
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'auto',
            gridRowStart: 1,
            gridRowEnd: 1
          }}
        >
          <div style={{ display: 'grid', margin: '10px auto 20px auto' }}>
            <TextField
              style={{ width: '500px' }}
              id="standard-basic"
              label="Manager Account Id"
              variant="standard"
              value={managerAccountIdToAdd}
              onChange={(e) => setManagerAccountIdToAdd(e.target.value)}
            />
          </div>

          <div
            style={{
              gridRowStart: 3,
              gridRowEnd: 3,
              paddingTop: '30px',
              margin: '0px auto'
            }}
          >
            <FormControl variant="standard" style={{ width: '500px' }}>
              <InputLabel id="demo-simple-select-label">Permissions</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={permissions}
                onChange={(e) => setPermissions(e.target.value)}
              >
                {/* <MenuItem value={'r'}>Read</MenuItem> */}
                <MenuItem value={'admin'}>Admin</MenuItem>

                {/* <MenuItem value={'user'}>User</MenuItem> */}
              </Select>
            </FormControl>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <AccountBalanceIcon
            style={{
              verticalAlign: 'text-bottom',
              paddingBottom: '1px',
              fontSize: '24px'
            }}
          />{' '}
          <span style={{ marginLeft: '3px' }}>Add Manager Account Access</span>
        </DialogTitle>
        <DialogContent>
          {content}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DraggableDialog.propTypes = {
  // userId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  // userId: state.auth.user._id
});

export default connect(mapStateToProps, { refetchUsersAccountsList })(
  DraggableDialog
);
