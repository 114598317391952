import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import GraphModal from './sparklineItem/GraphModal';
import TimelineIcon from '@mui/icons-material/Timeline';
import HistoryIcon from '@mui/icons-material/History';

import CommentIcon from '@mui/icons-material/Comment';

// import CommentDraggableDialog from './comment/CommentDraggableDialog';
import DescriptionIcon from '@mui/icons-material/Description';

import { useSnackbar } from 'notistack';

import SimpleToolTipWithChildren from './SimpleTootipWithChildren';
import ReportGraphModal from './../../report/ReportGraphModal';

import TemplateIcon from './template-icon/TemplateIcon';

import InfoModalIcon from './InfoModalIcon';

import { DialogContext } from '../../../../context/DialogContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { useInView } from 'react-intersection-observer';

import { AuthContext } from '../../../../context/AuthContext';

import Hyperlink from './Hyperlink';

import { FilterContext } from '../../../../context/FilterContext';

import SetFilterInfo from './SetFilterInfo';

import { MutationContext } from '../../../../context/MutationContext';

import Tooltip from '@mui/material/Tooltip';

import InlineTypeItem from './typeItem/InlineTypeItem';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function NameItem({
  aggregatedMode,
  // selectedDateStart,
  // selectedDateStop,
  dataLevel,
  // updateDocument,
  currentManagerAccountId,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  showAllSubItemsHandler,
  openRows,
  checked,
  range2,
  columns,
  rawArrayOfData
}) {
  const grabIndend = 0; //10;
  const { exportMode } = useContext(InterfaceContext);
  const { filters } = useContext(FilterContext);
  const { updateDocument } = useContext(MutationContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { enqueueSnackbar } = useSnackbar();

  // ################### update #######################

  const { user } = useContext(AuthContext);

  const {
    graphDialogOpen,
    setGraphDialogOpen,
    newsFeedDialogOpen,
    setNewsFeedDialogOpen,
    setItemId,
    setItemKey
  } = useContext(DialogContext);

  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = () => {
    if (showTextField) {
      return;
    }
    if (
      ([
        'account',
        // 'totalActionsDocument',
        'folder',
        'task',
        'subTask',
        'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(item.type) &&
        !item.isSetChild) ||
      item.isSet
    ) {
      toggleShowTextField(true);
    }
  };

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(item.name);
  }, [showTextField, toggleShowTextField]);

  // const [openDataGraphModal, setOpenDataGraphModal] = useState(false);

  const [openCommentModal, setOpenCommentModal] = useState(false);

  const [openReportGraphModal, setOpenReportGraphModal] = useState(false);

  let fiberManualRecordIconColor;

  // if (
  //   [
  //     'folder',
  //     'task',
  //     'subTask',
  //     'taxonomy',
  //     'facebook_ads_campaign',
  //     'google_ads_campaign',
  //     'cm360_campaign',
  //     'dv360_insertion_order',
  //     'tiktok_ads_campaign',
  //     'pinterest_ads_campaign',
  //     'readpeak_campaign',
  //     'strossle_campaign',
  //     'amazon_dsp_order',
  //     'taboola_ads_campaign',
  //     'microsoft_advertising_campaign',
  //     'microsoft_invest_insertion_order',
  //     'apple_search_ads_campaign',
  //     'adform_order',
  //     'linkedin_ads_campaign_group'
  //   ].includes(item.type)
  // ) {
  //   switch (item.status) {
  //     case 'ACTIVE':
  //       // case 'DONE':
  //       fiberManualRecordIconColor = 'green';
  //       break;
  //     case 'DONE':
  //     case 'COMPLETED':
  //       fiberManualRecordIconColor = 'rgba(0,128,0,0.4)';
  //       break;
  //     case 'REMOVED':
  //       fiberManualRecordIconColor = 'red';
  //       break;

  //     case 'DRAFT':
  //       fiberManualRecordIconColor = '#bcbcbc';
  //       break;
  //     case 'PAUSED':
  //       fiberManualRecordIconColor = 'rgb(62, 142, 208)';
  //       break;
  //     default:
  //       fiberManualRecordIconColor = 'orange';
  //       break;
  //   }
  // }
  // ##############################################

  // const input = {
  //   managerAccountId: currentManagerAccountId,
  //   accountId: item.accountId ? item.accountId : '',
  //   planId: item.planId ? item.planId : '',
  //   categoryId: item.categoryId ? item.categoryId : '',
  //   campaignId: item.campaignId ? item.campaignId : '',
  //   name: inputValue
  // };

  let indent = 0;
  // switch (item.level) {
  //   case 1:
  //     indent = 0;
  //     break;
  //   case 2:
  //     indent = 7;
  //     break;
  //   case 3:
  //     indent = 14;
  //     break;
  //   case 4:
  //     indent = 21;
  //     break;
  //   case 5:
  //     indent = 28;
  //     break;
  //   case 6:
  //     indent = 35;
  //     break;
  //   case 7:
  //     indent = 42;
  //     break;
  //   case 8:
  //     indent = 49;
  //     break;
  //   case 9:
  //     indent = 56;
  //     break;
  //   case 10:
  //     indent = 63;
  //     break;

  //   // case 'adAccountData':
  //   //   input.actionType = 'update_adAccountData_name';
  //   //   break;
  // }
  indent = item.level * 15 - 15;
  // switch (item.level) {
  //   case 1:
  //     indent = 0;
  //     break;
  //   case 2:
  //     indent = 10;
  //     break;
  //   case 3:
  //     indent = 20;
  //     break;
  //   case 4:
  //     indent = 30;
  //     break;
  //   case 5:
  //     indent = 40;
  //     break;
  //   case 6:
  //     indent = 50;
  //     break;
  //   case 7:
  //     indent = 60;
  //     break;
  //   case 8:
  //     indent = 70;
  //     break;
  //   case 9:
  //     indent = 80;
  //     break;
  //   case 10:
  //     indent = 90;
  //     break;
  //   case 11:
  //     indent = 100;
  //     break;
  //   case 12:
  //     indent = 110;
  //     break;
  //   case 13:
  //     indent = 120;
  //     break;
  //   case 14:
  //     indent = 130;
  //     break;

  //   // case 'adAccountData':
  //   //   input.actionType = 'update_adAccountData_name';
  //   //   break;
  // }

  if (filters?.includes('filter_by_data_source')) {
    indent = 0;
  }

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const regExName = new RegExp(
      '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ -_.,+-/\\|*()=:#%&:]*$'
    );
    const regExNameResult = regExName.exec(inputValue);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØúÚ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    if (
      inputValue.length < 2 &&
      ![
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(item.type)
    ) {
      enqueueSnackbar(
        'Name too short. Minimum length 2 characters. ' +
          inputValue.length +
          '/100 used',
        {
          variant: 'error'
        }
      );

      return console.log('name too short.');
    }

    if (inputValue.length > 200) {
      enqueueSnackbar('Name too long. ' + inputValue.length + '/200 used', {
        variant: 'error'
      });

      return console.log('name too long.');
    }

    if (inputValue === item.name) {
      toggleShowTextField(false);
      return '';
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          name: inputValue
        }
      ],
      updateKeys: ['name']
    };

    // updateDocument({ variables: { input: { ...documentInput } } });
    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });
      console.log('res: ', res);
      console.log(
        'res?.data?.updateDocument?.response: ',
        res?.data?.updateDocument?.response
      );
      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }

      // setSubscriptionCallStack([
      //   ...subscriptionCallStack,
      //   {
      //     subscriptionType: 'documentUpdatedSubscription',
      //     subscriptionData: res.data.updateDocument.documentsUpdated
      //   }
      // ]);
      toggleShowTextField(false);
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const textField = (
    <form
      id={item?._id + '_' + itemKey}
      onSubmit={onSubmitHandler}
      onBlur={onSubmitHandler}
      // className={classes.root}
      noValidate
      autoComplete="off"
    >
      <TextField
        style={{ width: '100%' }}
        autoFocus
        variant="standard"
        id="standard-basic"
        value={inputValue}
        inputProps={{
          min: 0,
          style: { padding: '0px', fontSize: '12px' }
        }}
        // onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
      />
      {/* <TextField id="filled-basic" label="Filled" variant="filled" />
    <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </form>
  );

  // ##################################################

  const [showItems, toggleShowItems] = useState(false);

  const toggleShowItemsHandler = (e) => {
    toggleShowItems(!showItems);
    showItemsHandler(e, item, showItems);
  };

  const toggleShowAllSubItemsHandler = (e) => {
    e.preventDefault();
    // console.log('toggleShowAllSubItemsHandler>item: ', item);

    // toggleShowItems(!showItems);
    showAllSubItemsHandler(e, item, showItems);
  };

  useEffect(() => {
    const open = openRows.filter((row) => {
      return row._id === item._id;
    });
    if (open.length > 0) {
      toggleShowItems(true);
    } else {
      toggleShowItems(false);
    }
  }, [openRows]);

  const handleClose = () => {
    setOpenCommentModal(false);
  };

  let showCaret = true;

  if (
    ![
      'account',
      'folder',
      'globalTasks',
      'task',
      // 'taxonomy',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'microsoft_invest_insertion_order',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group'
    ].includes(item.type) ||
    (['account', 'folder', 'globalTasks', 'task'].includes(item.type) &&
      item?.nexusDocuments?.length === 0)
  ) {
    showCaret = false;
  }

  if (
    dataLevel === 'campaign' &&
    [
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'microsoft_invest_insertion_order',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group'
    ].includes(item.type)
  ) {
    showCaret = false;
  }

  if (item.isSet) {
    showCaret = true;
  }

  return (
    <div
      // draggable
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        zIndex: '1000',
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 0px',
        // borderColor: 'rgba(200, 200, 200, 0.5)',
        borderColor: itemStyle['borderColor'],
        // borderBottomColor: checked ? '#00adb5' : itemStyle['borderColor'],
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        position: 'sticky',
        // left: '92px',
        left: columns.includes('type') ? '92px' : '46px',
        // display: 'grid',
        display: 'flex',
        height: '100%',
        minHeight: '46px',
        width: '100%',
        // minWidth: '200px',
        // whiteSpace: 'nowrap',
        // overflowX: 'hidden',
        // padding: '10px 20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: grabIndend + indent + 'px'
      }}
    >
      {inView || exportMode ? (
        <>
          <span
            style={{
              // margin: 'auto'
              // margin: 'auto auto auto 40px'
              // width: '100%',
              width: 'calc(100% - 13px)',
              padding: '9px 8px 0px 75px'
            }}
          >
            {showTextField ? (
              textField
            ) : (
              <p
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: item?.isSetChild ? 'default' : 'text'
                }}
                onClick={onClickHandler}
              >
                {/* <SimpleToolTipWithChildren> */}
                <Tooltip followCursor title={item.name || ''}>
                  {item['name'] || ''}
                </Tooltip>
              </p>
            )}
            {item['name'] ? (
              <div
                style={{
                  fontSize: '8px',
                  // color: '#bcbcbc'
                  // color: 'rgba(255,255,255,0.2)'
                  color: 'rgba(150,150,150,0.5)'
                }}
              >
                {([
                  'account',
                  'folder',
                  'facebook_ads_campaign',
                  'facebook_ads_ad_set',
                  'google_ads_campaign',
                  'google_ads_ad_group',
                  'cm360_campaign',
                  'dv360_insertion_order',
                  'dv360_line_item',
                  'snapchat_ads_campaign',
                  'snapchat_ads_ad_squad',
                  'tiktok_ads_campaign',
                  'tiktok_ads_ad_group',
                  'pinterest_ads_campaign',
                  'pinterest_ads_ad_group',
                  'readpeak_campaign',
                  'readpeak_ad_group',
                  'strossle_campaign',
                  'strossle_ad_set',
                  'amazon_dsp_order',
                  'amazon_dsp_line_item',
                  'taboola_ads_campaign',
                  'microsoft_advertising_campaign',
                  'microsoft_advertising_ad_group',
                  'microsoft_invest_insertion_order',
                  'microsoft_invest_line_item',
                  'apple_search_ads_campaign',
                  'apple_search_ads_ad_group',
                  'adform_order',
                  'adform_line_item',
                  'linkedin_ads_campaign_group',
                  'linkedin_ads_campaign',
                  'manualDataEntry'
                ].includes(item.type) ||
                  item.isSet) && (
                  <React.Fragment>
                    <Tooltip followCursor title="Graph">
                      <TimelineIcon
                        className="hover-light-grey"
                        style={{
                          fontSize: '12px',
                          cursor: 'pointer',
                          marginRight: '4px'
                        }}
                        onClick={() => {
                          setItemKey('cost');
                          setItemId(item._id);
                          setGraphDialogOpen(true);
                        }}
                      />
                    </Tooltip>
                  </React.Fragment>
                )}

                {([
                  'folder',
                  'facebook_ads_campaign',
                  'google_ads_campaign',
                  'cm360_campaign',
                  'dv360_insertion_order',
                  'snapchat_ads_campaign',
                  'tiktok_ads_campaign',
                  'pinterest_ads_campaign',
                  'readpeak_campaign',
                  'strossle_campaign',
                  'amazon_dsp_order',
                  'taboola_ads_campaign',
                  'microsoft_advertising_campaign',
                  'microsoft_invest_insertion_order',
                  'apple_search_ads_campaign',
                  'adform_order',
                  'linkedin_ads_campaign_group',
                  'manualDataEntry',
                  'task',
                  'taxonomy'
                ].includes(item.type) ||
                  item.isSet) &&
                  !item.isSetChild && (
                    <React.Fragment>
                      <InfoModalIcon
                        currentManagerAccountId={currentManagerAccountId}
                        item={item}
                        itemKey={itemKey}

                        // onClick={()=>setOpenCommentModal(true)}
                      />
                    </React.Fragment>
                  )}
                {([
                  'folder',
                  'facebook_ads_campaign',
                  'google_ads_campaign',
                  'cm360_campaign',
                  'dv360_insertion_order',
                  'snapchat_ads_campaign',
                  'tiktok_ads_campaign',
                  'pinterest_ads_campaign',
                  'readpeak_campaign',
                  'strossle_campaign',
                  'amazon_dsp_order',
                  'taboola_ads_campaign',
                  'microsoft_advertising_campaign',
                  'microsoft_invest_insertion_order',
                  'apple_search_ads_campaign',
                  'adform_order',
                  'linkedin_ads_campaign_group',
                  'manualDataEntry',
                  'customForm',
                  'task',
                  'subTask',
                  'taxonomy'
                ].includes(item.type) ||
                  item.isSet) &&
                  !item.isSetChild && (
                    <React.Fragment>
                      <Tooltip followCursor title="Comments">
                        <div
                          className="hover-light-grey"
                          style={{
                            display: 'inline-block',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setItemId(item._id);
                            setNewsFeedDialogOpen(true);
                          }}
                        >
                          <CommentIcon
                            className="hover-light-grey"
                            style={{
                              marginLeft: '4px',
                              fontSize: '12px'
                              // color: 'blue'
                            }}
                            // onClick={()=>setOpenCommentModal(true)}
                          />
                          <span
                            style={{
                              fontSize: '12px',
                              marginLeft: '1px',
                              padding: '0px',
                              visibility:
                                item.comments && parseInt(item.comments) > 0
                                  ? 'visible'
                                  : 'hidden'
                            }}
                          >
                            {item.comments &&
                            (parseInt(item.comments) || item.comments) > 0
                              ? item.comments
                              : 0}
                          </span>
                        </div>
                      </Tooltip>
                      {/* {['system_admin'].includes(user.role) && (

                    )} */}
                    </React.Fragment>
                  )}
                {([
                  'facebook_ads_campaign',
                  'google_ads_campaign',
                  'cm360_campaign',
                  'dv360_insertion_order',
                  'snapchat_ads_campaign',
                  'tiktok_ads_campaign',
                  'pinterest_ads_campaign',
                  'readpeak_campaign',
                  'strossle_campaign',
                  'amazon_dsp_order',
                  'taboola_ads_campaign',
                  'microsoft_advertising_campaign',
                  'microsoft_invest_insertion_order',
                  'apple_search_ads_campaign',
                  'adform_order',
                  'linkedin_ads_campaign_group'
                ].includes(item.type) ||
                  (['googleAds'].includes(item.documentType) &&
                    item.isSet)) && (
                  <Tooltip
                    followCursor
                    title="Link to activity in data source platform."
                  >
                    <div
                      style={{
                        display: 'inline-block'
                        // backgroundColor: 'red',
                        // width: '10px',
                        // height: '10px'
                      }}
                    >
                      <Hyperlink item={item} fontSize="14px" />
                    </div>
                  </Tooltip>
                )}
                {item.isSet && <SetFilterInfo item={item} />}

                {/* <i className="fas fa-pen"></i> Kanban{' '} */}
                {/* <i className="fas fa-pen"></i> */}
              </div>
            ) : (
              <span
                style={{ fontStyle: 'italic', color: 'grey', fontSize: '10px' }}
              >
                Go to date range for name
              </span>
            )}
          </span>

          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              // width: '40px',
              // background: 'rgb(255, 255, 255)',
              // backgroundColor: itemStyle['backgroundColor'],
              top: '0px',
              left: '0px',
              // left: '-10px',
              width: '20px',
              position: 'absolute',
              //   textAlign: 'center',
              // paddingLeft: '5px',
              verticalAlign: 'middle',
              // paddingTop: '12px',
              zIndex: '6000',
              cursor: 'pointer',
              marginLeft: 0 + 'px'
              // display: showCaret ? 'inline-block' : 'none'
            }}
          >
            <DragIndicatorIcon style={{ color: 'rgba(150,150,150,0.3)' }} />
          </div> */}
          <div
            onClick={toggleShowItemsHandler}
            onContextMenu={toggleShowAllSubItemsHandler}
            style={{
              height: '100%',
              // width: '40px',
              // background: 'rgb(255, 255, 255)',
              // backgroundColor: itemStyle['backgroundColor'],
              top: '0px',
              left: '0px',
              width: '40px',
              position: 'absolute',
              //   textAlign: 'center',
              // paddingLeft: '5px',
              verticalAlign: 'middle',
              paddingTop: '12px',
              zIndex: '6000',
              cursor: 'pointer',
              paddingLeft: 17 + grabIndend + indent + 'px',
              paddingRight: '17px',
              display: showCaret ? 'inline-block' : 'none'
            }}
          >
            <span
              // onClick={toggleShowItemsHandler}
              style={{
                margin: 'auto',
                verticalAlign: 'middle',
                fontSize: '14px',
                // paddingTop: '50px',
                // color: 'rgba(0, 0, 0, 0.5)'
                color: itemStyle['color']
                // cursor: 'pointer'
              }}
            >
              {item.type !== 'adAccountData' &&
                (showItems ? (
                  // <i className="fas fa-arrow-down"></i>
                  // <div
                  //   style={{
                  //     display: 'absolute',
                  //     flexDirection: 'column',
                  //     alignItems: 'center',
                  //     justifyContent: 'center'
                  //   }}
                  // >
                  //   {/* <div style={{ height: '10px' }}></div> */}
                  //   <i className="fas fa-caret-down"></i>
                  //   <div
                  //     style={{
                  //       width: '1px',
                  //       height: '10px',
                  //       border: '1px dotted rgba(48,48,48,0.3)',
                  //       position: 'fixed'
                  //     }}
                  //   ></div>
                  // </div>
                  <i className="fas fa-caret-down"></i>
                ) : (
                  // <i className="fas fa-arrow-right"></i>
                  <i className="fas fa-caret-right"></i>
                ))}
            </span>
          </div>
          <div
            style={{
              height: '100%',
              // width: '40px',
              // background: 'rgb(255, 255, 255)',
              // backgroundColor: itemStyle['backgroundColor'],
              top: '0px',
              left: '32px',
              width: '40px',
              position: 'absolute',
              //   textAlign: 'center',
              // paddingLeft: '5px',
              verticalAlign: 'middle',
              // paddingTop: '12px',
              zIndex: '5000',
              cursor: 'pointer',
              marginLeft: grabIndend + indent + 'px'
              // display: showCaret ? 'inline-block' : 'none'
            }}
          >
            <InlineTypeItem
              currentManagerAccountId={currentManagerAccountId}
              item={item}
              itemStyle={itemStyle}
              // keyForMap={item._id + '_' + itemKey + '_' + index}
              // index={index}
              itemValue={item[itemKey]}
              rowNumber={rowNumber}
              checked={checked}
              // typeItemAnchorRef={typeItemAnchorRef}
              // setTypeItemAnchorRef={setTypeItemAnchorRef}
              // open={typeItemOpen}
              // setOpen={setTypeItemOpen}
              // typeItemToggledItem={typeItemToggledItem}
              // setTypeItemToggledItem={setTypeItemToggledItem}
            />
          </div>

          <div style={{ display: 'grid', alignItems: 'center' }}>
            <span
              style={{
                backgroundColor: item?.statusColor || 'grey',
                height: '3px',
                width: '3px',
                borderRadius: '15px 15px 15px 15px',
                marginRight: '10px'
              }}
            ></span>
          </div>
        </>
      ) : (
        <span
          style={{
            paddingTop: '9px',
            paddingLeft: 30 + indent + 'px',
            color: checked
              ? itemStyle['checkedBackgroundColor']
              : itemStyle['backgroundColor']
          }}
        >
          {item.name}
        </span>
      )}
    </div>
  );
}

export default React.memo(NameItem);
