import React, { useState, useContext, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useLazyQuery } from '@apollo/client';
import { managerAccountUsersQuery } from '../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';

export default function ComboBox({ selectedUser, setSelectedUser }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [inputValue, setInputValue] = useState('');

  const variables = {
    managerAccountUsersInput: {
      managerAccountId: currentManagerAccount._id
    }
  };

  const [loadUsers, { called, loading, data, refetch }] = useLazyQuery(
    managerAccountUsersQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
    }
  );

  console.log('UserAutoComplete>data: ', data);

  useEffect(() => {
    loadUsers();
  }, []);

  const onChangeHandler = (event, newValue) => {
    // console.log('newValue: ', newValue);
    setSelectedUser(newValue);
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data?.managerAccountUsers?.length) {
      const orderedOptions = data?.managerAccountUsers;

      orderedOptions.sort(function (a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      setOptions(orderedOptions);
    }
  }, [data?.managerAccountUsers]);

  return (
    <Autocomplete
      open={true}
      autoHighlight
      blurOnSelect={true}
      style={{ width: '200px' }}
      size="small"
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        option?.name + option?._id + option?.type + option?.allwaysShow
      }
      renderInput={(params) => (
        <TextField
          autoFocus
          placeholder="Select user"
          variant="standard"
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          // label="Movie"
        />
      )}
      // onInputChange={(event, newInputValue, reason) => {
      //   if (reason !== 'reset') {
      //     console.log('newInputValue: ', newInputValue);
      //     setInputValue(newInputValue);
      //   }
      // }}

      onChange={(event, newValue) => {
        onChangeHandler(event, newValue);
      }}
      renderOption={(props, option, { selected }) => (
        <li
          key={option._id}
          {...props}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            {option?.name}
          </div>
        </li>
      )}
    />
  );
}
