import React, { useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import { TextField } from '@mui/material';

import Container from '@mui/material/Container';
import dayjs from 'dayjs';

function round(x) {
  return Math.round(x / 5) * 5;
}
export default function BasicDateTimePicker({
  setContextMenuAnchorPosition,
  onClickMenuItem,
  timebox
}) {
  const [value, setValue] = useState();

  const onChangeHandler = (newValue) => {
    const date = new Date(newValue['$d']);

    date.setMinutes(round(date.getMinutes()));

    // console.log('test66>date: ', date);
    // console.log('Object.keys(newValue): ', Object.keys(newValue));
    // console.log('newValue: ', newValue);

    // // setValue({
    // //   ...newValue,
    // //   M: { $m: round(newValue['M']['$m']) }
    // // });

    // const newVal = { ...newValue, $m: round(newValue['$m']) };
    // console.log('newVal: ', newVal);

    // setValue({ ...newValue, $m: round(newValue['$m']) });

    // // setValue(new Date(newValue['$d']))
    setValue(dayjs(date));
  };

  console.log('value: ', value);

  // $D
  // :
  // 14
  // $H
  // :
  // 3
  // $L
  // :
  // "en"
  // $M
  // :
  // 1
  // $W
  // :
  // 3
  // $d
  // :
  // Wed Feb 14 2024 03:31:00 GMT+0100 (Central European Standard Time) {}
  // $isDayjsObject
  // :
  // true
  // $m
  // :
  // 31
  // $ms
  // :
  // 0
  // $s
  // :
  // 0
  // $u
  // :
  // undefined
  // $x
  // :
  // {}
  // $y
  // :
  // 2024

  return (
    <div
      style={{
        // transform: 'scale(0.8)',
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        justifyItems: 'center'
        // left: '-30px',
        // overflow: 'hidden'
        // width: '250px'
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '330px',
            transform: 'scale(0.8)',
            left: '-50px',
            position: 'relative'
          }}
          components={['DateTimePicker']}
        >
          <DateTimePicker
            // defaultValue={timebox.deadline}
            // value={timebox?.deadline}
            // onChange={(newValue) =>
            //   onClickMenuItem(null, 'UPDATE_DEADLINE', newValue)
            // }
            onAccept={() => console.log('onAccept')}
            onClose={() => console.log('onAccept')}
            // timeStep.minutes = 8
            sx={{ width: '100% ' }}
            timeSteps={{ minutes: 15 }}
            value={value}
            onChange={(newValue) => onChangeHandler(newValue)}
            slotProps={{
              field: { clearable: true },
              clearIcon: {
                onClick: () => {
                  console.log('clearButton>onClick');
                }
              },
              textField: {
                variant: 'standard',
                onKeyDown: () => console.log('on key down')
              }
            }}
            label="Deadline"
          />
          <Button
            style={{
              marginTop: '12px',
              marginLeft: '10px',
              minWidth: '40px',
              width: '40px',
              minHeight: '40px',
              height: '40px',
              color: 'green'
            }}
            onClick={() =>
              onClickMenuItem(null, 'UPDATE_DEADLINE', value?.['$d'] || null)
            }
          >
            <SaveIcon style={{ fontSize: '24px' }} />
          </Button>
        </Container>
      </LocalizationProvider>
    </div>
  );
}
