import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_microsoft_advertising_campaign_data = (
  microsoftAdvertisingCampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = microsoftAdvertisingCampaign?.action01?.dataSource;
  const action02DataSource = microsoftAdvertisingCampaign?.action02?.dataSource;
  const action03DataSource = microsoftAdvertisingCampaign?.action03?.dataSource;

  const action01DataSourceIsMicrosoftAdvertising =
    action01DataSource === 'microsoftAdvertising';
  const action02DataSourceIsMicrosoftAdvertising =
    action02DataSource === 'microsoftAdvertising';
  const action03DataSourceIsMicrosoftAdvertising =
    action03DataSource === 'microsoftAdvertising';

  const action01Type =
    microsoftAdvertisingCampaign?.action01?.microsoftAdvertising?.type;

  const action02Type =
    microsoftAdvertisingCampaign?.action02?.microsoftAdvertising?.type;

  const action03Type =
    microsoftAdvertisingCampaign?.action03?.microsoftAdvertising?.type;

  const action01Name =
    microsoftAdvertisingCampaign?.action01?.microsoftAdvertising?.name;

  const action02Name =
    microsoftAdvertisingCampaign?.action02?.microsoftAdvertising?.name;

  const action03Name =
    microsoftAdvertisingCampaign?.action03?.microsoftAdvertising?.name;

  const action01AttributionType =
    microsoftAdvertisingCampaign?.action01?.microsoftAdvertising
      ?.attributionType;
  const action02AttributionType =
    microsoftAdvertisingCampaign?.action02?.microsoftAdvertising
      ?.attributionType;
  const action03AttributionType =
    microsoftAdvertisingCampaign?.action03?.microsoftAdvertising
      ?.attributionType;

  const campaign = {
    ...microsoftAdvertisingCampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'microsoft_advertising_campaign'
  };

  campaign.action01.name = action01Name;
  campaign.action02.name = action02Name;
  campaign.action03.name = action03Name;

  const runCostFormula = campaign.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata?.length > 0) {
    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adgroup_object of microsoftAdvertisingCampaign.microsoftadvertisingbasicdata) {
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      // let outbound_clicks = null;

      // if (action01DataSourceIsMicrosoftAdvertising) {
      //   action01Count =
      //     action01Count + (parseFloat(adgroup_object[action01Type]) || null);

      //   if (['purchase'].includes(action01Type)) {
      //     action01Value =
      //       action01Value +
      //       (parseFloat(adgroup_object['total_' + action01Type + '_value']) ||
      //         null);
      //   }
      //   // action01Value =
      //   //   action01Value +
      //   //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
      //   //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      // }

      // if (action02DataSourceIsMicrosoftAdvertising) {
      //   action02Count =
      //     action02Count + (parseFloat(adgroup_object[action02Type]) || null);

      //   if (['purchase'].includes(action02Type)) {
      //     action02Value =
      //       action02Value +
      //       (parseFloat(adgroup_object['total_' + action02Type + '_value']) ||
      //         null);
      //   }
      //   // action01Value =
      //   //   action01Value +
      //   //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
      //   //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      // }

      // if (action03DataSourceIsMicrosoftAdvertising) {
      //   action03Count =
      //     action03Count + (parseFloat(adgroup_object[action03Type]) || null);

      //   if (['purchase'].includes(action03Type)) {
      //     action03Value =
      //       action03Value +
      //       (parseFloat(adgroup_object['total_' + action03Type + '_value']) ||
      //         null);
      //   }

      //   // action01Value =
      //   //   action01Value +
      //   //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
      //   //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      // }

      // if (action02DataSourceIsDV360) {
      //   switch (action02Type) {
      //     case 'conversions':
      //       action02Count =
      //         action02Count + (adgroup_object.metrics.conversions || null);
      //       action02Value =
      //         action02Value + (adgroup_object.metrics.conversionsValue || null);
      //       break;

      //     default:
      //       if (snapchatAdsCampaign && snapchatAdsCampaign.dv360conversions) {
      //         for (let adgroup_conversion_object of snapchatAdsCampaign.dv360conversions) {
      //           if (
      //             adgroup_conversion_object?.floodlightActivityId &&
      //             adgroup_conversion_object?.floodlightActivityId ===
      //               action02ActivityId &&
      //             adgroup_conversion_object.date === adgroup_object.date
      //           ) {
      //             switch (action02AttributionType) {
      //               case 'default':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.totalConversions || null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null) +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //               case 'view':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.postViewConversions || null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null);
      //                 break;
      //               case 'click':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.postClickConversions ||
      //                     null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //             }
      //           }
      //         }
      //       }
      //   }
      // }

      // if (action03DataSourceIsDV360) {
      //   switch (action03Type) {
      //     case 'conversions':
      //       action03Count =
      //         action03Count + (adgroup_object.metrics.conversions || null);
      //       action03Value =
      //         action03Value + (adgroup_object.metrics.conversionsValue || null);
      //       break;

      //     default:
      //       if (snapchatAdsCampaign && snapchatAdsCampaign.dv360conversions) {
      //         for (let adgroup_conversion_object of snapchatAdsCampaign.dv360conversions) {
      //           if (
      //             adgroup_conversion_object?.floodlightActivityId &&
      //             adgroup_conversion_object?.floodlightActivityId ===
      //               action03ActivityId &&
      //             adgroup_conversion_object.date === adgroup_object.date
      //           ) {
      //             switch (action03AttributionType) {
      //               case 'default':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.totalConversions || null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null) +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //               case 'view':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.postViewConversions || null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null);
      //                 break;
      //               case 'click':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.postClickConversions ||
      //                     null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //             }
      //           }
      //         }
      //       }
      //   }
      // }

      // console.log('adgroup_object.start_time: ', adgroup_object.start_time);

      // const date_start = new Date(parseInt(adgroup_object.start_time));

      // const dateStartAsUTCString = date_start.toUTCString();

      // var clearUTCDate = moment(date_start).utcOffset(0, true).unix() * 1000;
      // console.log('clearUTCDate: ', clearUTCDate);

      // console.log('adgroup_object.dateString: ', adgroup_object.dateString);
      listOfPropagationData.push({
        ...adgroup_object,
        // ...calculatedFields,
        cost: adgroup_object.spend || null,
        impressions: +adgroup_object.impressions || null,
        // measurableImpressions: +adgroup_object.impressions || null,
        // viewableImpressions: +adgroup_object.visible_impressions || null,

        clicks: +adgroup_object.clicks || null,

        date_start: new Date(+adgroup_object.timePeriod).getTime(),
        date_stop: new Date(+adgroup_object.timePeriod).getTime(),
        action01Type,
        action01Count,
        action01Value,

        action02Type,
        action02Count,
        action02Value,

        action03Type,
        action03Count,
        action03Value,

        accountId: campaignBaseValues.accountId
      });
    }
  }

  campaign.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaign
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_microsoft_advertising_campaign_data;
