import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { addCommentMutation } from '../../../../../graphql/queries';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import Avatar from '@mui/material/Avatar';

import Spinner from '../../../../layout/SpinnerSmall';

import { useSnackbar } from 'notistack';

import { AuthContext } from '../../../../../context/AuthContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: '25ch'
//     }
//   }
// }));

const createNameAbb = (name) => {
  let nameAbb = '';

  const nameParts = name.split(' ');

  for (let i = 0; i < nameParts.length; i++) {
    nameAbb = nameAbb + nameParts[i][0];
  }
  return nameAbb;
};

function Comments({ comments, item, currentManagerAccountId }) {
  const { user } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();
  //   const classes = useStyles();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  };

  const [input, setInput] = useState('');

  const onChangeHandler = (e) => {
    setInput(e.target.value);
    scrollToBottom();
  };

  const onKeyUpHandler = (e) => {
    if (e.keyCode === 13) {
    }
  };

  const [addComment, { called }] = useMutation(addCommentMutation);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (!input) {
      console.log('empty input');
      return;
    }

    // const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ\n _,.-]*$');
    // const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ\n _.,+-/\\*()=:]*$');
    const regExName = new RegExp(
      '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ\n\t -_.,+-/\\*()=:#%&:]*$'
    );
    const regExNameResult = regExName.exec(input);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ _.,+-/*()=:#%&: are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    console.log('item._members: ', item._members);
    const finalInput = {
      action: 'add_comment',
      managerAccountId: currentManagerAccountId,
      accountId: item.accountId,
      documentId: item._id,
      level: item.level,

      documentType: item.documentType,
      newsFeedItemDocumentType: 'comment',
      commentType: 'child',
      comment: {
        documentId: item._id,
        text: input
      }
    };

    addComment({ variables: { addCommentInput: finalInput } });
    setInput('');
  };

  useEffect(scrollToBottom, [comments]);

  const nameAbb = user?.name ? createNameAbb(user?.name) : '';

  if (!comments) {
    return (
      <DialogContent
        ref={messagesEndRef}
        style={{
          maxHeight: '100%',
          height: '100%',
          overflow: 'scroll',
          paddingTop: '100px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Spinner />
      </DialogContent>
    );
  }
  return (
    <DialogContent
      ref={messagesEndRef}
      style={{
        maxHeight: '100%',
        overflow: 'scroll',
        paddingBottom: '0',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px'
      }}
    >
      {comments}
      {/* <div /> */}
      {/* <TextField
        id="outlined-multiline-static"
        label="Multiline"
        multiline
        rows={4}
        defaultValue="Default Value"
        variant="outlined"
      /> */}
      <div
        style={{
          // borderRadius: '5px',
          // border: '1px solid grey',
          // margin: '0px 20px 20px 20px',
          // padding: '10px',
          display: 'grid',
          gridTemplateColumns: '30px auto'
        }}
      >
        <div style={{ display: 'grid', gridColumnStart: 1, gridColumnEnd: 1 }}>
          <Avatar
            style={{
              height: '35px',
              width: '35px',
              fontSize: '12px',
              color: 'white',
              background: `
    linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
    linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
    linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)
    `
            }}
          >
            {nameAbb}
          </Avatar>
        </div>
        <div
          style={{
            display: 'grid',
            gridColumnStart: 2,
            gridColumnEnd: 2,
            borderRadius: '5px',
            // border: '1px solid grey',
            margin: '10px 20px 20px 20px'
            // padding: '10px'
          }}
        >
          {/* <div
            style={{
              borderRadius: '5px',
              //   border: '1px solid grey',
              margin: '20px'
              //   padding: '10px'
            }}
          > */}
          <form onSubmit={(e) => onSubmitHandler(e)}>
            <TextField
              onKeyUp={(e) => onKeyUpHandler(e)}
              onChange={(e) => onChangeHandler(e)}
              style={{ width: '100%' }}
              id="outlined-multiline-static"
              label="Multiline - type here!"
              multiline
              value={input}
              //   rows={4}
              //   defaultValue="Default Value"
              default={input}
              variant="outlined"
            />
            <div style={{ width: '100%', textAlign: 'right' }}>
              <Button
                size="small"
                style={{ marginTop: '20px' }}
                type="submit"
                variant="outlined"
              >
                Submit
              </Button>
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
      {/* <div
        style={{
          borderRadius: '5px',
          //   border: '1px solid grey',
          margin: '20px'
          //   padding: '10px'
        }}
      >
        <form onSubmit={(e) => onSubmitHandler(e)}>
          <TextField
            onChange={(e) => onChangeHandler(e)}
            style={{ width: '100%' }}
            id="outlined-multiline-static"
            label="Multiline - type here!"
            multiline
            //   rows={4}
            //   defaultValue="Default Value"
            default={input}
            variant="outlined"
          />
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Button
              style={{ marginTop: '20px' }}
              type="submit"
              variant="outlined"
            >
              Submit
            </Button>
          </div>
        </form>
      </div> */}

      {/* <div
        style={{
          borderRadius: '5px',
          //   border: '1px solid grey',
          margin: '20px'
          //   padding: '10px'
        }}
      >
        <TextField
          style={{ width: '100%' }}
          id="filled-multiline-static"
          // label="Multiline"
          multiline
          rows={4}
          // defaultValue="Default Value"
          variant="filled"
        />
      </div> */}
    </DialogContent>
  );
}

// export default Comments;

Comments.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired,
  // style: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  // stylesStoredInRedux: state.style,
  user: state.auth.user
});

export default connect(mapStateToProps, {})(Comments);
