import React, { useContext, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Checkbox from '@mui/material/Checkbox';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { AccountContext } from '../../../../context/AccountContext';

import Tooltip from '@mui/material/Tooltip';

import ZoomInIcon from '@mui/icons-material/ZoomIn';

import SaveAsIcon from '@mui/icons-material/SaveAs';

import TextField from '@mui/material/TextField';

import { createReportColorPaletteMutation } from './../../../../graphql/queries';

import { DataContext } from '../../../../context/DataContext';

import { useMutation } from '@apollo/client';

import { useSnackbar } from 'notistack';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import SelectAccountMenu from './../utils/SelectAccountMenu';

import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';

import SpinnerSmall from '../../../layout/SpinnerSmall';

import Resizer from 'react-image-file-resizer';

export default function BasicPopover({ colors }) {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [isAllowingManagerAccountAccess, setIsAllowingManagerAccountAccess] =
    useState(false);

  const [isAllowingAccountAccess, setIsAllowingAccountAccess] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);

  const { currentManagerAccount } = useContext(AccountContext);
  const { listOfData } = useContext(DataContext);

  const {
    reportPage,
    zoom,
    setZoom,
    setSelectedWidgetIds,
    isExportMode,
    setIsExportMode
  } = useContext(ReportBuilderContext);

  const [name, setName] = React.useState('');
  // const { daysToGraph, setDaysToGraph } = useContext(InterfaceContext);

  // console.log('daysToGraph: ', daysToGraph);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [createReportColorPalette, { called }] = useMutation(
    createReportColorPaletteMutation
  );

  const [previousZoom, setPreviousZoom] = useState();

  const handleSave = async () => {
    setSelectedWidgetIds([]);
    if (!name) {
      enqueueSnackbar('Please fill in a name', {
        variant: 'error'
      });
      return;
    }
    setIsLoading(true);

    if (
      !isAllowingManagerAccountAccess &&
      (!isAllowingAccountAccess || !selectedAccountIds?.length)
    ) {
      enqueueSnackbar('Please choose access', {
        variant: 'error'
      });
      return;
    }

    // 1. get signed uploadurl

    const input = {
      // managerAccountId: currentManagerAccount._id,
      // name,
      managerAccountId: currentManagerAccount._id,
      isAllowingManagerAccountAccess,
      accountIds: isAllowingAccountAccess ? selectedAccountIds : [],
      colors,
      name
      // action: String,
      // updateKeys: [String],
    };

    // console.log('SaveTemplatePopper>input: ', input);

    const res = await createReportColorPalette({
      variables: { input }
    });

    if (res?.data?.createReportColorPalette?.response === 'success') {
      enqueueSnackbar('Template created', {
        variant: 'success'
      });
      setIsAllowingManagerAccountAccess(false);
      setIsAllowingAccountAccess(false);
      setName('');
      setAnchorEl(null);
    }
    // setZoom(previousZoom || zoom);

    setIsLoading(false);
    // setIsExportMode(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Tooltip title="Save as template">
        <Button
          size="small"
          aria-describedby={id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '40px',
            // width: '40px',
            minHeight: '40px',
            height: '40px'
            // padding: '10px'
          }}
          onClick={handleClick}
        >
          <SaveAsIcon style={{ fontSize: '16px', marginRight: '5px' }} />
          <span>Save</span>
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div
          style={{
            padding: '20px 20px 5px 20px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          Save as template
        </div>
        <div
          style={{
            padding: '0px 20px 20px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            rowGap: '10px'
          }}
        >
          {/* <div
            style={{ width: '160px', fontSize: '12px', textAlign: 'center' }}
          >
            The number of days back NexusTable will visualize graphs. NexusTable
            runs slower the higher number you enter.
          </div> */}
          {/* {value < 15 && (
            <div
              style={{
                width: '160px',
                fontSize: '12px',
                textAlign: 'center',
                color: value < 15 && 'rgb(255, 107, 107)'
              }}
            >
              If you have lower than 15 days the orange performance warnings
              won't work.
            </div>
          )} */}

          <div style={{ marginBottom: '10px' }}>
            <TextField
              style={{ width: '290px' }}
              size="small"
              variant="standard"
              id="outlined-controlled"
              label="Template Name"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div style={{ width: '300px' }}>
            <div>Access:</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <div>
                <Checkbox
                  size="small"
                  checked={isAllowingManagerAccountAccess}
                  onChange={() => {
                    setIsAllowingManagerAccountAccess(
                      !isAllowingManagerAccountAccess
                    );
                    setIsAllowingAccountAccess(false);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div
                onClick={() => {
                  setIsAllowingManagerAccountAccess(
                    !isAllowingManagerAccountAccess
                  );
                  setIsAllowingAccountAccess(false);
                }}
                style={{ cursor: 'pointer' }}
              >
                Current Manager Account
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <div>
                <Checkbox
                  size="small"
                  checked={isAllowingAccountAccess}
                  onChange={() => {
                    setIsAllowingAccountAccess(!isAllowingAccountAccess);
                    setIsAllowingManagerAccountAccess(false);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div>
                <SelectAccountMenu
                  isDisabled={!isAllowingAccountAccess}
                  selectedAccountIds={selectedAccountIds}
                  setSelectedAccountIds={setSelectedAccountIds}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '10px',
              width: '100%',
              justifyContent: 'end'
            }}
          >
            {isLoading ? (
              <SpinnerSmall />
            ) : (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    // setAnchorEl(null);
                    handleSave();
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
}
