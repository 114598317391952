import React from 'react';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       borderRadius: '5px',
//       height: '20px',
//       width: '37px',
//       backgroundColor: 'rgba(0, 181, 8, 1)',
//       '&:hover': {
//         background: 'rgba(20, 201, 28, 1)'
//       }
//     }
//   },
//   extendedIcon: {
//     color: 'white',
//     marginRight: theme.spacing(1)
//   }
// }));

export default function FloatingActionButtons({ setOpen }) {
  // const classes = useStyles();

  return (
    <div
      // className={classes.root}
      onClick={() => setOpen(true)}
    >
      <Fab
        color="primary"
        aria-label="add"
        style={{
          color: 'white',

          // margin: theme.spacing(1),
          borderRadius: '5px',
          height: '20px',
          width: '37px',
          backgroundColor: 'rgba(0, 181, 8, 1)'
        }}
      >
        <AddIcon style={{ color: 'white' }} />
      </Fab>
      {/* <Fab aria-label="edit">
        <EditIcon style={{color: 'white'}} />
      </Fab> */}
      {/* <Fab variant="extended">
        <NavigationIcon className={classes.extendedIcon} />
        Navigate
      </Fab>
      <Fab disabled aria-label="like">
        <FavoriteIcon />
      </Fab> */}
    </div>
  );
}
