import Button from '@mui/material/Button';
import react, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

const GOOGLE_CLIENT_ID =
  '973308791560-qsirfedp9fp22kooou4agdjvp6542unq.apps.googleusercontent.com';

export default function GoogleSignin() {
  const { login, user } = useContext(AuthContext);

  const handleGoogleSignIn = async (res) => {
    if (!res.clientId || !res.credential) return;

    await login(res.credential);
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleGoogleSignIn
    });

    google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      theme: 'outline',
      size: 'large'
    });
  }, []);

  return <div id="signInDiv"></div>;
}
