import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';

import BalanceIcon from '@mui/icons-material/Balance';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';

import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

import Stats from './Stats';

// import './kanban.css';

function ViewDialogContent({ isExpanded, displayView, setDisplayView }) {
  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '50px',
          marginLeft: '30px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
          // backgroundColor:
          //   stylesStoredInRedux.styleName === 'light'
          //     ? 'red'
          //     : 'rgba(255,255,255,0)'
        }}
      >
        {/* Kanban */}
        {/* <div style={{ marginRight: '20px' }}>
          <CreateTodoButton />
        </div> */}

        {/* <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              minHeight: '36px',
              minWidth: 0,
              cursor: 'pointer'
              // backgroundColor: '#3e8ed0'
            }}
            onClick={() => setEmailPokeDialogOpen(true)}
          >
            <i
              style={{ fontSize: '20px' }}
              className="fas fa-hand-point-right"
            ></i>
          </Button>
        </div>
        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              minWidth: '40px',
              width: '40px',
              backgroundColor:
                viewDialogView === 'kanban' ? 'rgba(255,255,255,0.1)' : null
            }}
            onClick={() => onClickCharthandler('kanban')}
          >
            <ViewKanbanOutlinedIcon />
          </Button>
        </div>

        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              minWidth: '40px',
              width: '40px',
              backgroundColor:
                viewDialogView === 'calendar' ? 'rgba(255,255,255,0.1)' : null
            }}
            onClick={() => onClickCharthandler('calendar')}
          >
            <CalendarViewWeekIcon />
          </Button>
        </div>
        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              minWidth: '40px',
              width: '40px',
              backgroundColor:
                viewDialogView === 'list' ? 'rgba(255,255,255,0.1)' : null
            }}
            onClick={() => onClickCharthandler('list')}
          >
            <TableRowsIcon />
          </Button>
        </div>
        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              minWidth: '40px',
              width: '40px',
              backgroundColor:
                viewDialogView === 'charts' ? 'rgba(255,255,255,0.1)' : null
            }}
            onClick={() => onClickCharthandler('charts')}
          >
            <DonutLargeIcon />
          </Button>
        </div> */}

        {/* {isAdvancedModeOn && (
          <>
            <div style={{ marginRight: '5px' }}>
              <DisplayMenu
              // selected={hideSelected}
              // setSelected={setHideSelected}
              />
            </div>
          </>
        )} */}
      </div>
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'row',
          // padding: '10px 20px',
          // overflowX: 'scroll',
          overflowX: 'scroll',
          height: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)',
          maxHeight: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)'
        }}
      >
        {displayView === 'stats' && <Stats />}
        {/* {renderView} */}
        {/* {viewDialogView === 'kanban' && (
          <Kanban
            columns={columns}
            filteredArrayOfData={filteredArrayOfData}
            isExpanded={isExpanded}
            rawArrayOfData={rawArrayOfData}
            account={account}
            setAccount={setAccount}
            hideSelected={hideSelected}
          />
        )}

        {viewDialogView === 'list' && (
          <List
            columns={columns}
            filteredArrayOfData={filteredArrayOfData}
            isExpanded={isExpanded}
            rawArrayOfData={rawArrayOfData}
            account={account}
            setAccount={setAccount}
            hideSelected={hideSelected}
          />
        )}
        {viewDialogView === 'calendar' && (
          <Calendar
            columns={columns}
            filteredArrayOfData={filteredArrayOfData}
            isExpanded={isExpanded}
            rawArrayOfData={rawArrayOfData}
            account={account}
            setAccount={setAccount}
            hideSelected={hideSelected}
          />
        )}
        {viewDialogView === 'charts' && (
          <Charts
            columns={columns}
            filteredArrayOfData={filteredArrayOfData}
            isExpanded={isExpanded}
            rawArrayOfData={rawArrayOfData}
            account={account}
            setAccount={setAccount}
            hideSelected={hideSelected}
          />
        )} */}
        {/* {viewDialogView === 'charts' && <div style={{ width: '95vw' }}>Hej</div>} */}
        {/* {renderColumns} */}
        {/* <Column name="Draft" /> */}
        {/* <Column name="To Do" />
        <Column name="Doing" />
        <Column name="Done" />
        <Column name="Block" /> */}
      </div>
    </div>
  );
}

// export default Kanban;

ViewDialogContent.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(ViewDialogContent);
