import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Chip from '@mui/material/Chip';

import ReceiptIcon from '@mui/icons-material/Receipt';

import Checkbox from '@mui/material/Checkbox';

import { AccountContext } from '../../../context/AccountContext';

import Divider from '@mui/material/Divider';

import { FilterContext } from '../../../context/FilterContext';

export default function BasicMenu({
  setFilterBySelection,
  checkedBoxes,
  setCheckedBoxes,
  setFilters,
  filters,
  enqueueSnackbar,
  arrayOfDataSources,
  filterByStatus,
  setFilterByStatus,
  filter
}) {
  const { filterByStatusExclusionArray, setFilterByStatusExclusionArray } =
    useContext(FilterContext);
  const { nexusDocumentStatuses, currentManagerAccountId } =
    useContext(AccountContext);
  const [filterMenuLoading, setFilterMenuLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [options, setOptions] = useState([]);

  const [defaultOptions, setDefaultOptions] = useState([]);
  useEffect(() => {
    const defaultOptions_ =
      [
        ...(nexusDocumentStatuses || [])
        // { name: 'Exclude folders', value: 'exclude_folders', checked: true }
      ]?.map((obj) => ({
        name: obj.name,
        value: obj.string,
        checked: true
      })) || [];
    setDefaultOptions(defaultOptions_);
  }, [nexusDocumentStatuses]);

  useEffect(() => {
    const newOptions = [];

    for (let defaultOption of defaultOptions) {
      const filterByStatusEmpty =
        !Boolean(filterByStatus?.length) &&
        filters.includes('filter_by_status');

      const existingOption = filterByStatus.filter(
        (option) => option.value === defaultOption.value
      )[0];

      if (false) {
        newOptions.push({
          ...defaultOption,
          checked: options.length === filterByStatus.length
        });
      } else {
        // console.log('existingOption: ', existingOption);

        newOptions.push({
          ...defaultOption,
          checked: existingOption
            ? existingOption.checked
            : filterByStatusEmpty && filterMenuLoading
        });
      }
    }

    // if (defaultOptions.length === options.length) {
    newOptions.unshift({
      name: 'All',
      value: 'all',
      checked: defaultOptions.length === filterByStatus.length
    });
    // }
    setOptions(newOptions);
    setFilterMenuLoading(false);
    // console.log('newOptions: ', newOptions);
  }, [filterByStatus, defaultOptions]);

  useEffect(() => {
    if (!filterByStatus.length) {
      setFilterByStatus([...defaultOptions]);
    }
  }, [defaultOptions]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
    // setFilterBySelection([]);

    const filteredFilter = filters.filter((filter_) => {
      return filter_ !== filter;
    });

    setFilters(filteredFilter);
    setFilterByStatus([]);
    setCheckedBoxes([]);
  };

  const handleMenuItemClick = (e, option) => {
    if (option.exclude) {
      const copyOfFilterByStatusExclusionArray = [
        ...(filterByStatusExclusionArray || [])
      ];

      if (filterByStatusExclusionArray?.includes(option.value)) {
        const indexOfValue = copyOfFilterByStatusExclusionArray.findIndex(
          (obj) => obj === option.value
        );

        copyOfFilterByStatusExclusionArray.splice(indexOfValue, 1);
        setFilterByStatusExclusionArray(copyOfFilterByStatusExclusionArray);
      } else {
        setFilterByStatusExclusionArray([
          ...copyOfFilterByStatusExclusionArray,
          option.value
        ]);
      }

      // filterByStatusExclusionArray, setFilterByStatusExclusionArray;
      return;
    }

    const copyOfOptions = [...options];

    if (option.value === 'all') {
      const checkstatus = option.checked;
      for (let i = 0; i < copyOfOptions.length; i++) {
        copyOfOptions[i].checked = !checkstatus;
      }
    } else {
      const dataSourceIndex = options.findIndex((obj) => {
        return obj.value === option.value;
      });

      const copyOfOptions = [...options];

      copyOfOptions[dataSourceIndex].checked =
        !options[dataSourceIndex].checked;
    }

    setOptions(copyOfOptions);

    const checkedSources = copyOfOptions.filter((option) => {
      return option.checked && option.value !== 'all';
    });

    setFilterByStatus(checkedSources);
    // // setFilterByStatus
  };

  const label = <div>Status Filter</div>;
  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Chip
        icon={<ReceiptIcon />}
        // ref={anchorRef}
        label={label}
        onClick={handleClick}
        onDelete={handleDelete}
      />
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Divider>Status</Divider>
        {options.map((option) => {
          return (
            <MenuItem
              style={{ height: '36px', padding: '0 20px 0 0' }}
              data-id={option._id}
              data-name={option.name}
              value={option}
              key={option._id}
              // selected={option.name === 'All Accounts'}
              onClick={(e) => handleMenuItemClick(e, option)}
            >
              <Checkbox
                style={{
                  transform: 'scale(0.7)'
                }}
                checked={option.checked}
                // defaultChecked
                color="default"
                inputProps={{
                  'aria-label': 'checkbox with default color'
                }}
              />
              {option.name}
            </MenuItem>
          );
        })}
        <Divider>Exclude </Divider>
        {[
          {
            name: 'Folders',
            value: 'folder',
            checked: filterByStatusExclusionArray?.includes('folder'),
            exclude: true
          }
        ].map((option) => {
          return (
            <MenuItem
              style={{ height: '36px', padding: '0 20px 0 0' }}
              data-id={option._id}
              data-name={option.name}
              value={option}
              key={option._id}
              // selected={option.name === 'All Accounts'}
              onClick={(e) => handleMenuItemClick(e, option)}
            >
              <Checkbox
                style={{
                  transform: 'scale(0.7)'
                }}
                checked={option.checked}
                // defaultChecked
                color="default"
                inputProps={{
                  'aria-label': 'checkbox with default color'
                }}
              />
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
