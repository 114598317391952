import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula2';

// calc sparkline/graph data

const calcFields2 = (
  propDataToCalculate,
  calculatedPropList,
  compiledExpressions,
  fields,
  calculatedFields,
  fieldsNotDoneCalculating,
  generateScope,
  rowObj,
  fieldsToBeManuallyCalculated,
  calcRounds,
  warnings
) => {
  calcRounds = calcRounds - 1;

  const newObj = {};

  for (let field of fields) {
    let formulaField =
      rowObj.isSet && !fieldsToBeManuallyCalculated.includes(field)
        ? `=${field}`
        : rowObj[field + 'Formula'];
    if (fieldsToBeManuallyCalculated?.includes(field)) {
      fieldsNotDoneCalculating.splice(
        fieldsNotDoneCalculating.indexOf(field),
        1
      );
      continue;
    }
    if (!fieldsNotDoneCalculating.includes(field)) {
      continue;
    }
    const copyOfFormulaFields = [...fields];
    const fieldIndex = copyOfFormulaFields.indexOf(field);
    copyOfFormulaFields.splice(fieldIndex, 1);

    const filteredCopyOfFormulaFields = copyOfFormulaFields.filter((field) => {
      return (
        formulaField?.includes(field) &&
        fieldsNotDoneCalculating.includes(field)
      );
    });

    if (filteredCopyOfFormulaFields.length > 0) {
      continue;
    }

    // console.log('mon1tor>field: ', field);

    // console.log(
    //   'compiledExpressions[field].evaluate: ',
    //   compiledExpressions[field].evaluate(
    //     generateScope(field, calculatedFields)
    //   )
    // );

    if (!compiledExpressions[field]) {
      // for (let row of rowObj.total_list_of_propagation_data) {
      //   row[field] = compiledExpressions[field].evaluate(
      //     generateScope(field, row)
      //   );
      //   // evaluateFormula(
      //   //   compiledExpressions[field].evaluate,
      //   //   formulaField,
      //   //   generateScope(field, calculatedFields)
      //   // );
      // }

      const formula = formulaField;
      // console.log('hejhopp123: ', formula);
      if (!formula) {
        for (let row of propDataToCalculate) {
          row[field] = 0;
        }
      }
    } else {
      for (let row of propDataToCalculate) {
        try {
          const evalRes = compiledExpressions[field].evaluate(
            generateScope(field, row)
          );
          row[field] = evalRes !== Infinity ? evalRes : 0;
        } catch (error) {
          row[field] = 0;
        }

        // evaluateFormula(
        //   compiledExpressions[field].evaluate,
        //   formulaField,
        //   generateScope(field, calculatedFields)
        // );
      }
    }

    // for (let row of rowObj.total_list_of_propagation_data) {
    //   row[field] = compiledExpressions[field].evaluate(
    //     generateScope(field, row)
    //   );

    //   // evaluateFormula(
    //   //   compiledExpressions[field].evaluate,
    //   //   formulaField,
    //   //   generateScope(field, calculatedFields)
    //   // );
    // }
    // calculatedFields[field] = evaluateFormula(
    //   compiledExpressions[field],
    //   formulaField,
    //   generateScope(field, calculatedFields)
    // );

    fieldsNotDoneCalculating.splice(fieldsNotDoneCalculating.indexOf(field), 1);
  }
  // console.log('calcRounds: ', calcRounds);

  if (calcRounds === 0) {
    warnings.stackOverFlow = true;
  }

  if (fieldsNotDoneCalculating.length !== 0 && calcRounds > 0) {
    calcFields2(
      propDataToCalculate,
      calculatedPropList,
      compiledExpressions,
      fields,
      calculatedFields,
      fieldsNotDoneCalculating,
      generateScope,
      rowObj,
      fieldsToBeManuallyCalculated,
      calcRounds,
      warnings
    );
    //   calcRounds = calcRounds - 1;
  }
};

const calcFields = (
  propDataToCalculate,
  calculatedPropList,
  compiledExpressions,
  fields,
  calculatedFields,
  fieldsNotDoneCalculating,
  generateScope,
  rowObj,
  fieldsToBeManuallyCalculated,
  warnings
) => {
  let calcRounds = 10;
  //   let warnForStackOverFlow = false;
  //   const warnings = { stackOverFlow: false };

  calcFields2(
    propDataToCalculate,
    calculatedPropList,
    compiledExpressions,
    fields,
    calculatedFields,
    fieldsNotDoneCalculating,
    generateScope,
    rowObj,
    fieldsToBeManuallyCalculated,
    calcRounds,
    warnings
  );

  if (warnings.stackOverFlow) {
    console.log('warning stack overflow');
  }
};

export default calcFields;
