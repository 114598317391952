import React, { useState, useContext } from 'react';
import { set } from 'mongoose';
import ColorPickerV2 from './ColorPickerV2';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../context/InterfaceContext';

function ColorPicker({
  currentManagerAccountId,
  // updateDocument,
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  item,
  checked
}) {
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const [barColor, setBarColor] = useState({
    red: 50,
    green: 50,
    blue: 50,
    alpha: 1
  });

  function hexToRGB(hex) {
    var red = parseInt(hex.slice(1, 3), 16),
      green = parseInt(hex.slice(3, 5), 16),
      blue = parseInt(hex.slice(5, 7), 16);

    return { red, green, blue };
  }

  const onKeyUpHandler = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      // Cancel the default action, if needed
      e.preventDefault();
      // Trigger the button element with a click

      const rgbObject = hexToRGB(e.target.value);
      setBarColor({ ...barColor, ...rgbObject });
    }
  };

  if (
    ([
      'account',
      'folder',
      'manualDataEntry',
      'customForm',
      'facebookAds',
      'googleAds',
      'cm360',
      'dv360',
      'snapchatAds',
      'tikTokAds',
      'pinterestAds',
      'readpeak',
      'strossle',
      'amazonDsp',
      'taboolaAds',
      'microsoftAdvertising',
      'microsoftInvest',
      'appleSearchAds',
      'adform',
      'linkedInAds',
      'task',
      'subTask',
      'taxonomy'
    ].includes(item.documentType) ||
      item.isSet) &&
    !item.isSetChild
  ) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '12px 15px',
          whiteSpace: 'nowrap'
        }}
      >
        {(inView || exportMode) && (
          <span
            style={{
              margin: 'auto'
            }}
          >
            {/* <p>{itemValue}</p> */}
            {/* <form onSubmit={onSubmitHandler}> */}
            {/* <input
            onBlur={onBlurHandler}
            onKeyUp={onKeyUpHandler}
            style={{ width: '20px', height: '20px', borderRadius: '5px' }}
            type="color"
            id="favcolor"
            name="favcolor"
            //   value="#ff0000"
            //   value="rgba(90, 241, 248, 0.9)"
          ></input> */}
            {([
              'account',
              'container',
              'folder',
              'manualDataEntry',
              'customForm',
              'facebook_ads_campaign',
              'google_ads_campaign',
              'cm360_campaign',
              'dv360_insertion_order',
              'snapchat_ads_campaign',
              'tiktok_ads_campaign',
              'pinterest_ads_campaign',
              'readpeak_campaign',
              'strossle_campaign',
              'amazon_dsp_order',
              'taboola_ads_campaign',
              'microsoft_advertising_campaign',
              'microsoft_invest_insertion_order',
              'apple_search_ads_campaign',
              'adform_order',
              'linkedin_ads_campaign_group',
              'task',
              'subTask',
              'taxonomy'
            ].includes(item.type) ||
              item.isSet) &&
              !item.isSetChild && (
                <ColorPickerV2
                  // updateDocument={updateDocument}
                  currentManagerAccountId={currentManagerAccountId}
                  item={item}
                />
              )}
            {/* </form> */}
            <p
              style={{
                fontSize: '8px',
                color: '#bcbcbc',
                visibility: 'hidden'
              }}
            >
              {' '}
              test
            </p>
          </span>
        )}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'grid',
        height: '100%',
        width: '100%',
        padding: '12px 15px',
        whiteSpace: 'nowrap'
      }}
    ></div>
  );
}

export default ColorPicker;
