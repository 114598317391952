import React, { useContext, useState, useEffect, useRef } from 'react';
import { DialogContext } from '../../../context/DialogContext';

import { AuthContext } from '../../../context/AuthContext';
import { AccountContext } from '../../../context/AccountContext';
import { MutationContext } from '../../../context/MutationContext';
import { InterfaceContext } from '../../../context/InterfaceContext';

import { DataContext } from '../../../context/DataContext';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Button } from '@mui/material';

import Timebox from './Timebox';

import { v4 as uuidv4 } from 'uuid';

import { useTheme } from '@emotion/react';

import formatDateYYYYMMDD from '../roitable_functions/formatDateYYYYMMDD';

import TimeboxerMenu from './menu/TimeboxerMenu';

import AssessmentIcon from '@mui/icons-material/Assessment';

import QueueIcon from '@mui/icons-material/Queue';

import TimeboxerViewDialog from './report/TimeboxerViewDialog';

import UserSelectPopover from './user-select-menu/UserSelectPopover';
import TimeboxContextMenu from './context-menu/TimeboxContextMenu';

import TimeboxList from './timebox-list/TimeboxList';

import TimeboxHead from './TimeboxHead';
import TimeboxerDay from './TimeboxerDay';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import { timeboxesQuery } from '../../../graphql/queries';

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

function Timeboxer() {
  const pollTime = 5 * 60 * 1000;
  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] =
    React.useState(null);

  const [
    timeboxIdThatTriggeredContextMenu,
    setTimeboxIdThatTriggeredContextMenu
  ] = useState(null);

  // const [isTimeboxViewDialogOpen, setIsTimeboxViewDialogOpen] = useState(false);
  const ref = useRef();

  const [isDragging, setIsDragging] = useState(false);
  // const [selectedUser, setSelectedUser] = useState(null);

  const timeboxerRef = useRef();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const {
    isTimeboxerOpen,
    isTimeboxListOpen,
    setIsTimeboxListOpen,
    isTimeboxViewDialogOpen,
    setIsTimeboxViewDialogOpen,
    timeboxViewDialogType,
    setTimeboxViewDialogType
  } = useContext(DialogContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    selectedDateStartTimebox,
    setSelectedDateStartTimebox,
    selectedDateStopTimebox,
    setSelectedDateStopTimebox,
    selectedUserTimeboxer: selectedUser,
    setSelectedUserTimeboxer: setSelectedUser,
    timeboxRange: dates
  } = useContext(InterfaceContext);
  // console.log('dates: ', dates);

  const [isTimeboxesMoved, setIsTimeboxesMoved] = useState(false);

  const [displayTimebox, setDisplayTimebox] = useState({});

  const {
    addTimeboxes,
    updateTimeboxes,
    refetchTimeboxesCounter,
    setRefetchTimeboxesCounter
  } = useContext(MutationContext);

  const { isLoading, setIsLoading, timeboxes, setTimeboxes } =
    useContext(DataContext);

  // const [timeboxes, setTimeboxes] = useState([]);

  const [refetchTimeboxesCounter2, setRefetchTimeboxesCounter2] = useState(0);

  const [mouseDownTimeboxIds, setMouseDownTimeboxIds] = useState([]);

  useEffect(() => {
    if (!selectedUser) {
      setSelectedUser(user);
    }
  }, [user]);

  // ################## data #######################
  // console.log('selectedDateStartTimebox: ', selectedDateStartTimebox);

  let startDateTime = new Date(selectedDateStartTimebox);
  const offset = startDateTime.getTimezoneOffset();
  startDateTime = new Date(startDateTime.getTime() + offset * 60 * 1000);
  let endDateTime = new Date(selectedDateStopTimebox);
  endDateTime = addDays(
    new Date(endDateTime.getTime() + offset * 60 * 1000),
    1
  );
  const variables = {
    input: {
      managerAccountId: currentManagerAccount._id,
      userIds: [selectedUser?._id],
      selectedDateStartTimebox: String(startDateTime.getTime()),
      selectedDateStopTimebox: String(endDateTime.getTime())
    }
  };

  // console.log('debug901>startDateTime: ', startDateTime);
  // console.log('debug901>endDateTime: ', endDateTime);
  // console.log('variables: ', variables);
  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(timeboxesQuery, {
    variables,

    fetchPolicy: 'no-cache',

    onCompleted: () => {
      console.log('RoiTable>Query completed.');
    },
    onError: (error) => {
      console.log('error: ', error);
    }
  });

  useEffect(() => {
    // console.log('data: ', data);
  }, [data]);

  useEffect(() => {
    if (refetchTimeboxesCounter) {
      refetch();
    }
  }, [refetchTimeboxesCounter]);

  useEffect(() => {
    if (currentManagerAccount._id && isTimeboxerOpen && selectedUser) {
      getData();
      startPolling(pollTime);
    }
  }, [currentManagerAccount._id, selectedUser, isTimeboxerOpen]);

  useEffect(() => {
    // console.log('debug49>test>data: ', data);
    if (data?.timeboxes && selectedUser) {
      // console.log('debug49>test1');
      const timeboxes_ = data?.timeboxes.map((timebox) => ({
        ...timebox,
        startDateTime: new Date(+timebox.startDateTime),
        endDateTime: new Date(+timebox.endDateTime),
        deadline: timebox.deadline && new Date(+timebox.deadline),
        duration: (timebox?.endDateTime - timebox?.startDateTime) / 60000
      }));
      setTimeboxes(timeboxes_);
      // setBackupOfTimeboxes_(
      //   timeboxes_.map((timebox) => ({
      //     ...timebox
      //   }))
      // );
      // console.log('Timeboxer>timeboxes>data: ', data);
    }
  }, [data, refetchTimeboxesCounter2, selectedUser]);
  // console.log('timeboxes: ', timeboxes);
  // console.log('Timeboxer>timeboxes: ', timeboxes);
  // ###############################################

  let zoom = 2.5;

  let timeSteps = 5;
  let minimumDuration = 5;

  const today = new Date();

  // const [dates, setDates] = useState([]);

  // useEffect(() => {
  //   const start = moment(selectedDateStartTimebox, 'YYYY-MM-DD');
  //   const end = moment(selectedDateStopTimebox, 'YYYY-MM-DD');
  //   const range = moment.range(start, end);
  //   setDates(range);
  // }, [selectedDateStartTimebox, selectedDateStopTimebox]);

  useEffect(() => {
    if (!isTimeboxerOpen || isLoading) {
      return;
    }
    // console.log('ref.current.scrollTop: ', ref.current.scrollTop);
    const now = new Date();
    ref.current.scrollTop =
      (Number(now.getHours()) * 60 + Number(now.getMinutes())) * zoom - 300;
  }, [isTimeboxerOpen, isLoading]);

  // const dates = [
  //   new Date('2024-02-25'),
  //   new Date('2024-02-26'),
  //   new Date('2024-02-27'),
  //   new Date('2024-02-28'),
  //   new Date('2024-02-29')
  // ];

  const numDays = dates?.length || 0;

  const [timeboxerWidth, setTimeboxerWidth] = useState(312);

  const [timeboxListRight, setTimeboxListRight] = useState(0);
  useEffect(() => {
    let width =
      12 + numDays * 300 > (window?.innerWidth || 0) - 900
        ? (window?.innerWidth || 0) - 900
        : 12 + numDays * 300;

    if (width > 1509) {
      width = 1509;
    }
    setTimeboxListRight(width);
    setTimeboxerWidth(width);
  }, [numDays]);
  // console.log('timeboxListRight: ', timeboxListRight);

  var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

  document.addEventListener('mousemove', function (e) {
    //adjust warning column
    // document.querySelector('#timeboxlist') &&
    //   setTimeboxListRight(document.querySelector('#timeboxlist')?.offsetWidth);

    //resize column
    // console.log('Timeboxer>curCol: ', curCol);
    if (curCol) {
      var diffX = pageX - e.pageX;

      if (curColWidth + diffX < numDays * 300 + 12) {
        curCol.style.width = curColWidth + diffX + 'px';
        setTimeboxerWidth(curColWidth + diffX);
        setTimeboxListRight(curColWidth + diffX);
      }
    }
  });

  document.addEventListener('mouseup', function (e) {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  });

  const colHandler = (e) => {
    curCol = e.target.parentElement;
    nxtCol = curCol.nextElementSibling;
    pageX = e.pageX;
    curColWidth = curCol?.offsetWidth;
    // if (!curCol.style.minWidth) {
    //   curCol.style.minWidth = curColWidth + 2 + 'px';
    // }

    if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
  };
  const colHandlerOnRelease = (e) => {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {!isLoading && (
        <>
          <TimeboxList
            selectedUser={selectedUser}
            numDays={numDays}
            right={timeboxListRight}
          />
          <div
            // onMouseDown={(e) => colHandler(e)}

            id="timeboxer"
            className={
              isTimeboxerOpen ? 'isTimeboxerOpenTrue' : 'isTimeboxerOpenFalse'
            }
            onContextMenu={(e) => e.preventDefault()}
            ref={timeboxerRef}
            style={{
              // display: 'flex',
              flexDirection: 'column',
              // visibility: isTimeboxerOpen ? 'visible' : 'hidden',
              border:
                theme?.palette?.mode === 'dark' &&
                '1px solid rgba(255,255,255,0.05)',
              width: timeboxerWidth ? timeboxerWidth + 'px' : 'auto',
              // width: 9 + numDays * 300 + 'px',
              // maxWidth: (window?.innerWidth || 0) - 600 + 'px',
              height: '100%',
              // background:
              //   theme?.palette?.mode === 'dark'
              //     ? 'rgb(32 32 41 / 60%)' // 'rgba(255,255,255,0.1)'
              //     : 'rgba(255,255,255,0.3)',
              backdropFilter: 'blur(10px)',
              // background: 'red',
              position: 'absolute',
              // right: '0px',
              // top: '-700px',
              top: '0px',
              zIndex: '15000',
              borderRadius: '5px',
              // overflowX: 'scroll',
              // padding: '10px',
              boxShadow:
                theme?.palette?.mode === 'dark'
                  ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' // '0px 3px 1px -2px rgb(127 127 255 / 20%), 0px 2px 2px 0px rgb(127 127 255 / 14%), 0px 1px 5px 0px rgb(127 127 255 / 12%)' //'0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
                  : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'

              // boxShadow:
              //   '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
            }}
            // onMouseMove={updateMousePosition}
            // //   onMouseDown={onMouseDownHandler}
            // onMouseDown={(e) => {
            //   // e.preventDefault();
            //   e.stopPropagation();
            //   onMouseDownHandler(e, null, 'create');
            // }}
            // onMouseUp={(e) => {
            //   e.stopPropagation();
            //   // e.preventDefault();
            //   onMouseUpHandler(e, 'timeboxer', null);
            // }}
          >
            <div
              onMouseDown={colHandler}
              onMouseUp={(e) => colHandlerOnRelease(e)}
              className="hover-col-resize"
              style={{
                width: '5px',
                height: '100%',
                // background: 'red',
                position: 'absolute',
                left: '0px',
                cursor: 'col-resize',
                zIndex: '22000'
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: '30px',
                background:
                  theme?.palette?.mode === 'dark'
                    ? 'rgb(28 28 41 / 40%)' // 'rgba(255,255,255,0.1)'
                    : 'rgba(255,255,255,0.5)',
                zIndex: '20000'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'absolute'
                }}
              >
                <Button
                  style={{
                    minWidth: '20px',
                    width: '20px',
                    minHeight: '20px',
                    height: '20px'
                  }}
                >
                  <QueueIcon
                    style={{ fontSize: '12px' }}
                    onClick={() => setIsTimeboxListOpen(!isTimeboxListOpen)}
                  />
                </Button>
                <Button
                  style={{
                    minWidth: '20px',
                    width: '20px',
                    minHeight: '20px',
                    height: '20px'
                  }}
                >
                  <AssessmentIcon
                    style={{ fontSize: '12px' }}
                    onClick={() => {
                      setTimeboxViewDialogType('USERS');
                      setIsTimeboxViewDialogOpen(true);
                    }}
                  />
                </Button>
              </div>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                  // marginTop: '5px'
                }}
              >
                <UserSelectPopover
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'absolute',
                  right: '0px'
                }}
              >
                <TimeboxerMenu calendarUserId={selectedUser?._id} />
                <Button
                  style={{
                    minWidth: '20px',
                    width: '20px',
                    minHeight: '20px',
                    height: '20px',
                    visibility: 'hidden'
                  }}
                ></Button>
              </div>
            </div>

            <div
              ref={ref}
              style={{
                overflowX: 'scroll',
                height: '100%',
                background:
                  theme?.palette?.mode === 'dark'
                    ? 'rgb(28 28 41 / 40%)' // 'rgba(255,255,255,0.1)'
                    : 'rgba(255,255,255,0.3)'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: numDays * 300 + 'px',
                  position: 'sticky',
                  top: '0px',
                  zIndex: '20000'
                }}
              >
                {dates?.map((date) => (
                  <TimeboxHead date={date} />
                ))}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  // top: '50px',
                  position: 'relative'
                  // overflow: 'scroll'
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    borderTop:
                      theme?.palette?.mode === 'dark'
                        ? '5px dotted rgba(255,255,255,0.2)'
                        : '5px dotted rgba(48,48,48,0.3)',
                    // borderStyle: 'dotted',
                    height: '1px',
                    // width: '100%',
                    width: (dates?.length || 0) * 300 + 'px',
                    top:
                      (new Date().getHours() * 60 + new Date().getMinutes()) *
                        zoom +
                      'px'
                  }}
                ></div>
                {dates?.map((date) => (
                  <TimeboxerDay
                    scrollElementRef={ref}
                    date={date}
                    timeboxIdThatTriggeredContextMenu={
                      timeboxIdThatTriggeredContextMenu
                    }
                    setTimeboxIdThatTriggeredContextMenu={
                      setTimeboxIdThatTriggeredContextMenu
                    }
                  />
                ))}
              </div>
              {/* <TimeboxList selectedUser={selectedUser} numDays={numDays} /> */}
              <TimeboxContextMenu
                mouseDownTimeboxIds={mouseDownTimeboxIds}
                contextMenuAnchorPosition={contextMenuAnchorPosition}
                setContextMenuAnchorPosition={setContextMenuAnchorPosition}
                timeboxIdThatTriggeredContextMenu={
                  timeboxIdThatTriggeredContextMenu
                }
                setTimeboxIdThatTriggeredContextMenu={
                  setTimeboxIdThatTriggeredContextMenu
                }
                timeboxes={timeboxes}
                selectedUser={selectedUser}
              />
              {/* <TimeboxerViewDialog /> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Timeboxer;
