import React, { useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useSnackbar } from 'notistack';

import FilterListIcon from '@mui/icons-material/FilterList';

import { FilterContext } from '../../context/FilterContext';

import { Tooltip } from '@mui/material';

import { useNavigate, useSearchParams } from 'react-router-dom';

const FilterMenu = ({
  // setFilterBySelection,
  checkedBoxes,
  setCheckedBoxes
  // setFilters,
  // filters,
  // enqueueSnackbar
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setFilterBySelection, filters, setFilters } =
    useContext(FilterContext);

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event, action) => {
    switch (action) {
      case 'Filter by selection':
        if (checkedBoxes.length > 0) {
          const filteredDocIds = checkedBoxes.map((obj) => obj._id);
          setFilterBySelection(filteredDocIds);

          // setSearchParams({
          //   ...(searchParams.entries() || []),
          //   account_ids: searchParams.get('account_ids'),
          //   manager_account_id: searchParams.get('manager_account_id'),
          //   start_date: searchParams.get('start_date'),
          //   end_date: searchParams.get('end_date'),
          //   document_ids: JSON.stringify(filteredDocIds)
          // });

          // let newSearchParams = {
          //   ...(searchParams.entries() || [])
          // };

          // let searchParamKeys = [
          //   'manager_account_id',
          //   'account_ids',
          //   'start_date',
          //   'end_date'
          // ];
          // for (let key of searchParamKeys) {
          //   if (searchParams.get(key)) {
          //     newSearchParams[key] = searchParams.get(key);
          //   }
          // }
          // if (filteredDocIds.length) {
          //   newSearchParams.document_ids = JSON.stringify(filteredDocIds);
          // }

          // setSearchParams(newSearchParams);
          setCheckedBoxes([]);

          if (!filters.includes('filter_by_selection')) {
            setFilters([...filters, 'filter_by_selection']);
          }
        } else {
          enqueueSnackbar(
            'Please check atleast one checkbox in order to filter by selection.',
            {
              variant: 'error'
            }
          );
        }

        break;

      case 'Filter by team':
        // setFilter('filter_by_team');

        if (!filters.includes('filter_by_team')) {
          setFilters([...filters, 'filter_by_team']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by label':
        // setFilter('filter_by_team');

        if (!filters.includes('filter_by_label')) {
          setFilters([...filters, 'filter_by_label']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by label name':
        // setFilter('filter_by_team');

        if (!filters.includes('filter_by_label_name')) {
          setFilters([...filters, 'filter_by_label_name']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by name':
        // setFilter('filter_by_team');

        if (!filters.includes('filter_by_name')) {
          setFilters([...filters, 'filter_by_name']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by data source':
        if (!filters.includes('filter_by_data_source')) {
          setFilters([...filters, 'filter_by_data_source']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by status':
        if (!filters.includes('filter_by_status')) {
          setFilters([...filters, 'filter_by_status']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by invoice status':
        if (!filters.includes('filter_by_invoice_status')) {
          setFilters([...filters, 'filter_by_invoice_status']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by invoice order ID':
        if (!filters.includes('filter_by_invoice_order_id')) {
          setFilters([...filters, 'filter_by_invoice_order_id']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by data source name':
        // setFilter('filter_by_team');

        if (!filters.includes('filter_by_data_source_name')) {
          setFilters([...filters, 'filter_by_data_source_name']);
        }
        setCheckedBoxes([]);

        break;
      case 'Filter by warnings':
        if (!filters.includes('filter_by_warnings')) {
          setFilters([...filters, 'filter_by_warnings']);
        }
        setCheckedBoxes([]);

        break;

      case 'Filter by pace':
        if (!filters.includes('filter_by_pace')) {
          setFilters([...filters, 'filter_by_pace']);
        }
        setCheckedBoxes([]);

        break;

      case 'Clear filter':
        setFilterBySelection([]);
        setFilters([]);
        setCheckedBoxes([]);
        break;
    }

    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Filter menu">
        <Button
          style={{ minWidth: '40px' }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FilterListIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={(e) => handleClose(e, 'Filter by selection')}>
          Filter by selection
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by data source')}>
          Filter by data source
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by status')}>
          Filter by status
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by invoice status')}>
          Filter by invoice status
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by invoice order ID')}>
          Filter by invoice order ID
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by team')}>
          Filter by member
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by label')}>
          Filter by label
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by label name')}>
          Filter by label name
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by name')}>
          Filter by name
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by data source name')}>
          {'Filter by data source name'}
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by warnings')}>
          {'Filter by warnings'}
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Filter by pace')}>
          {'Filter by pace (beta)'}
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'Clear filter')}>
          Clear filter
        </MenuItem>
      </Menu>
    </div>
  );
};
export default FilterMenu;
