import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { useInView } from 'react-intersection-observer';

import { AuthContext } from '../../../../context/AuthContext';

import { MutationContext } from '../../../../context/MutationContext';

// import Hyperlink from './Hyperlink';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function NameItem({
  // updateDocument,
  currentManagerAccountId,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  checked
}) {
  const { updateDocument } = useContext(MutationContext);
  console.log('itemKey: ', itemKey);
  const { exportMode } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { enqueueSnackbar } = useSnackbar();

  // ################### update #######################

  const { user } = useContext(AuthContext);

  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = () => {
    toggleShowTextField(true);
  };

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(item.name);
  }, [showTextField, toggleShowTextField]);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    const regExName = new RegExp(
      '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ -_.,+-/\\|*()=:#%&:]*$'
    );
    const regExNameResult = regExName.exec(inputValue);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØúÚ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    if (inputValue.length < 2) {
      enqueueSnackbar(
        'Name too short. Minimum length 2 characters. ' +
          inputValue.length +
          '/100 used',
        {
          variant: 'error'
        }
      );

      return console.log('name too short.');
    }

    if (inputValue.length > 200) {
      enqueueSnackbar('Name too long. ' + inputValue.length + '/200 used', {
        variant: 'error'
      });

      return console.log('name too long.');
    }

    if (inputValue === item.name) {
      toggleShowTextField(false);
      return '';
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          name: inputValue
        }
      ],
      updateKeys: ['name']
    };

    updateDocument({ variables: { input: { ...documentInput } } });

    toggleShowTextField(false);
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const textField = (
    <form
      onSubmit={onSubmitHandler}
      onBlur={onSubmitHandler}
      // className={classes.root}
      noValidate
      autoComplete="off"
    >
      <TextField
        autoFocus
        variant="standard"
        id="standard-basic"
        value={inputValue}
        inputProps={{
          min: 0,
          style: { padding: '0px', fontSize: '12px' }
        }}
        // onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
      />
      {/* <TextField id="filled-basic" label="Filled" variant="filled" />
    <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </form>
  );

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        zIndex: '1000',

        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 0px',

        borderColor: itemStyle['borderColor'],

        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        position: 'sticky',
        left: '92px',

        display: 'flex',
        height: '100%',
        minHeight: '46px',
        width: '100%',

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        verticalAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '15px'
        // paddingLeft: indent + 'px'
      }}
    >
      {item[itemKey]}
    </div>
  );
}

export default React.memo(NameItem);
