import React, { useState, useContext, useEffect } from 'react';
// import GraphModal from './../../../report/graph-dialog/GraphDialog';

import { DialogContext } from '../../../../../context/DialogContext';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../../context/InterfaceContext';
import { styled, useTheme } from '@mui/material/styles';

// ################## imports regarding sparklines ################################

// import {
//   Sparklines,
//   SparklinesBars,
//   SparklinesLine,
//   SparklinesCurve,
//   SparklinesNormalBand,
//   SparklinesReferenceLine,
//   SparklinesSpots
// } from 'react-sparklines';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

// #############################################################

function SparklineItem({
  itemStyle,
  selectedDateStart,
  selectedDateStop,
  itemDateStart,
  itemDateStop,
  keyForMap,
  itemKey,
  itemValue,
  rowNumber,
  checked,
  item,
  range2,
  columns
}) {
  const theme = useTheme();
  const { exportMode, sparklineDateRange, displayColumnsAsBarSparkline } =
    useContext(InterfaceContext);

  const [isBarPlotType, setIsBarPlotType] = useState(false);

  useEffect(() => {
    setIsBarPlotType(displayColumnsAsBarSparkline.includes(itemKey));
  }, [displayColumnsAsBarSparkline]);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const [sparklineData, setSparklineData] = useState([]);
  useEffect(() => {
    // setSparklineData((itemValue || []).map((value) => Math.round(value)));
    if (
      [
        'ctr',
        'viewableRate',
        'vcr',
        'searchImpressionShare',
        'action01Cvr',
        'action02Cvr',
        'action03Cvr'
      ].includes(itemKey?.split('Sparkline')?.[0])
    ) {
      setSparklineData(
        (itemValue || []).map(
          (value) => Math.round((value * 100 + Number.EPSILON) * 100) / 100
        )
      );
    } else {
      setSparklineData(
        (itemValue || []).map(
          (value) => Math.round((value + Number.EPSILON) * 100) / 100
        )
      );
    }

    // Math.round((itemValue + Number.EPSILON) * 100) / 100
  }, [itemValue]);
  // const [openDataGraphModal, setOpenDataGraphModal] = useState(false);

  const { graphDialogOpen, setGraphDialogOpen, setItemId, setItemKey } =
    useContext(DialogContext);

  // let showSparkline = false;

  const arrSum = (arr) =>
    arr.reduce((a, b) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b), 0);
  let arrSumItemValue = 0;

  const [showSparkline, setShowSparkline] = useState(false);

  useEffect(() => {
    if (
      item?.[itemKey?.split('Sparkline')?.[0]] &&
      item[itemKey]?.some((val) => Boolean(val))
    ) {
      setShowSparkline(true);
    }
  }, [item[itemKey]]);
  // if (Array.isArray(itemValue)) {
  //   arrSumItemValue = arrSum(itemValue);

  //   if (arrSumItemValue > 0) {
  //     showSparkline = true;
  //   }
  //   // arrSumItemValue > 0 && (showSparkline = true);
  // }

  // if (new Date(selectedDateStart) <= new Date(itemDateStop)) {
  //   showSparkline = true;
  // }

  // if (new Date(selectedDateStop) <= new Date(itemDateStart)) {
  //   showSparkline = true;
  // }

  const onClickHandler = () => {
    setItemKey(itemKey);
    setItemId(item._id);
    setGraphDialogOpen(true);
  };

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        // backgroundColor: itemStyle['backgroundColor'],
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'grid',
        height: '100%',
        width: '100%',
        // padding: '12px 15px',
        // padding: '5px',
        whiteSpace: 'nowrap'
      }}
      // onClick={(e) =>onClickHandler(e)}
    >
      {(inView || exportMode) && (
        <div onClick={(e) => onClickHandler(e)}>
          {showSparkline && (
            <SparkLineChart
              // area={true}
              colors={[
                theme?.palette?.mode === 'dark'
                  ? isBarPlotType
                    ? 'rgba(255,255,255,0.8)'
                    : 'rgb(190,190,190)'
                  : '#1784a6'
              ]}
              plotType={isBarPlotType ? 'bar' : 'line'}
              data={sparklineData}
              height={45}
              showTooltip
              showHighlight
              // xAxis={{
              //   scaleType: 'band',
              //   data: sparklineDateRange,
              //   valueFormatter: (value) => value
              // }}
            />
            // <Sparklines
            //   data={itemValue}
            //   style={{ height: '37px', width: '99.5%' }}
            // >
            //   <SparklinesLine
            //     // color="#00adb5"
            //     // color="#1784a6"
            //     color={theme?.palette?.mode === 'dark' ? 'white' : '#1784a6'}
            //     // color="#b24393"
            //     // color="#4bacfd"
            //   />
            //   <SparklinesNormalBand
            //     style={{ fill: '#2991c8', fillOpacity: 0.1 }}
            //   />
            //   <SparklinesReferenceLine
            //     type="mean"
            //     color="#ffddcc"
            //     style={{
            //       stroke: 'grey',
            //       strokeOpacity: 0.75,
            //       strokeDasharray: '2, 2'
            //     }}
            //   />
            // </Sparklines>
          )}
        </div>
      )}

      {/* {openDataGraphModal && (
        <GraphModal
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          open={openDataGraphModal}
          setOpen={setOpenDataGraphModal}
          itemKey={itemKey}
          item={item}
          range2={range2}
          columns={columns}
        />
      )} */}
    </div>
  );
}

export default React.memo(SparklineItem);
