import React, { useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import { AccountContext } from '../../../context/AccountContext';
// const useStyles = makeStyles((theme) => ({
//   button: {
//     display: 'block',
//     marginTop: theme.spacing(2)
//   },
//   formControl: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//     minWidth: 120
//   }
// }));

export default function ControlledOpenSelect({
  overrideStatus,
  document,
  setDocument
}) {
  const { nexusDocumentInvoiceStatuses, currentManagerAccountId } =
    useContext(AccountContext);
  // const classes = useStyles();
  //   const [status, setStatus] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    // copyOfDocument.status =
    // setStatus(event.target.value);
    setDocument({ ...document, invoiceStatus: event.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {/* <Button className={classes.button} onClick={handleOpen}>
        Open the select
      </Button> */}
      <FormControl
        variant="standard"
        sx={{ m: 0, minWidth: 120 }}
        disabled={!overrideStatus}
      >
        {/* <FormControl className={classes.formControl} disabled={!overrideStatus}> */}
        <InputLabel id="demo-simple-select-label">Invoice Status</InputLabel>
        <Select
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={document?.invoiceStatus}
          onChange={handleChange}
        >
          {nexusDocumentInvoiceStatuses.map((status) => (
            <MenuItem value={status.string}>{status.name}</MenuItem>
          ))}
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {/* <MenuItem value={'DRAFT'}>Draft</MenuItem>
          <MenuItem value={'PLANNED'}>Planned</MenuItem>
          <MenuItem value={'BUDDYCHECK'}>Buddy-check</MenuItem>
          <MenuItem value={'READY'}>Ready</MenuItem>
          <MenuItem value={'DOUBLECHECK'}>Double-check</MenuItem>
          <MenuItem value={'ACTIVE'}>Active</MenuItem>
          <MenuItem value={'PAUSED'}>Paused</MenuItem>
          <MenuItem value={'COMPLETED'}>Completed</MenuItem>
          <MenuItem value={'REMOVED'}>Removed</MenuItem> */}
        </Select>
      </FormControl>
    </div>
  );
}
