import React, { useState } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import WarningIcon from '@mui/icons-material/Warning';

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 600,
//     maxHeight: '50vw',
//     // overflow: 'scroll',
//     overflow: 'hidden',
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//     padding: '5px 5px 0px 5px'
//   }
// }))(Tooltip);

export default function CustomizedTooltips({
  warnings,
  subWarnings,
  stylesStoredInRedux,
  textIcon
}) {
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      // backgroundColor: '#f5f5f9',
      backgroundImage:
        stylesStoredInRedux.styleName === 'light'
          ? 'linear-gradient(to top, rgb(255, 255, 255), rgb(255, 255, 255))'
          : 'linear-gradient(to top, rgb(66, 66, 66), rgb(85, 85, 85))',
      // color: 'rgba(0, 0, 0, 0.87)',
      color: stylesStoredInRedux.styleName === 'light' ? 'black' : 'white',
      maxWidth: 600,
      maxHeight: '50vw',
      // overflow: 'scroll',
      overflow: 'hidden',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      borderColor:
        stylesStoredInRedux.styleName === 'light'
          ? '1px solid #bcbcbc'
          : 'rgba(255, 255, 255, 0.12)',
      padding: '5px 5px 0px 5px'
    }
  }))(Tooltip);

  warnings.sort((a, b) =>
    a.highestWarningLevel < b.highestWarningLevel
      ? 1
      : b.highestWarningLevel < a.highestWarningLevel
      ? -1
      : 0
  );
  subWarnings.sort((a, b) =>
    a.highestWarningLevel < b.highestWarningLevel
      ? 1
      : b.highestWarningLevel < a.highestWarningLevel
      ? -1
      : 0
  );

  const toolTips = warnings.map((warning, index) => {
    return (
      <div
        key={index}
        style={{
          padding: '10px',
          marginBottom: '5px',
          border: '1px solid #bcbcbc',
          borderColor:
            stylesStoredInRedux.styleName === 'light'
              ? '1px solid #bcbcbc'
              : 'rgba(255, 255, 255, 0.12)',
          borderRadius: '5px',
          fontSize: '12px'
        }}
      >
        {/* <b>{warning.headline}</b>
        <br /> */}
        {'path: ' + warning.path}
        <br />
        {'name: '} {warning.name}
        <br />
        {/* {'msg: '} */}
        <ul>
          {warning.messages.map((message) => {
            return <li> - {message.message}</li>;
          })}
        </ul>
        {/* <br />
        {'-----------------'} */}
        {/* <br /> */}
      </div>
    );
  });

  const subWarningToolTips = subWarnings.map((warning, index) => {
    return (
      <div
        key={index}
        style={{
          padding: '10px',
          marginBottom: '5px',
          border: '1px solid #bcbcbc',
          borderColor:
            stylesStoredInRedux.styleName === 'light'
              ? '1px solid #bcbcbc'
              : 'rgba(255, 255, 255, 0.12)',
          borderRadius: '5px',
          fontSize: '12px'
        }}
      >
        {/* <b>{warning.headline}</b>
        <br /> */}
        {'path: ' + warning.path}
        <br />
        {'name: '} {warning.name}
        <br />
        {/* {'msg: '} */}
        <ul>
          {warning.messages.map((message) => {
            return <li> - {message.message}</li>;
          })}
        </ul>
        {/* <br />
        {'-----------------'} */}
        {/* <br /> */}
      </div>
    );
  });

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(!open);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        // padding: '10px',
        textAlign: 'center'
      }}
    >
      <HtmlTooltip
        // disableFocusListener
        // disableHoverListener
        // disableTouchListener
        // onBlur={handleTooltipClose}
        // onClose={handleTooltipClose}
        // open={open}
        placement="right"
        title={<React.Fragment>{toolTips}</React.Fragment>}
      >
        <div
          style={{
            fontSize: '10px',
            color: warnings.length > 0 ? '#D0021B' : 'rgba(255,255,255,0.1)',
            visibility: warnings.length > 0 ? 'visible' : 'hidden',
            // display: 'flex',
            // flexDirection: 'row',
            // alignItems: 'center',
            // justifyContent: 'center',
            position: 'relative',
            bottom: '-1px',
            cursor: 'pointer'
          }}
          //   onClick={handleTooltipOpen}
        >
          {textIcon ? (
            <span style={{ color: 'red', padding: '0px 3px 0px 2px' }}>!</span>
          ) : (
            <WarningIcon style={{ height: '12px', width: '12px' }} />
          )}
          {/* <i className="fas fa-circle" style={{ paddingRight: '3px' }}></i>( */}
          {/* ({warnings.length}) */}
        </div>
      </HtmlTooltip>
      {/* <HtmlTooltip
        // disableFocusListener
        // disableHoverListener
        // disableTouchListener
        // onBlur={handleTooltipClose}
        // onClose={handleTooltipClose}
        // open={open}
        placement="right"
        title={<React.Fragment>{subWarningToolTips}</React.Fragment>}
      >
        <div
          style={{
            fontSize: '9px',
            color:
              subWarnings.length > 0
                ? 'rgba(209,2,27,0.7)'
                : 'rgba(255,255,255,0.1)',
            visibility: subWarnings.length > 0 ? 'visible' : 'hidden',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          //   onClick={handleTooltipOpen}
        >
          <WarningIcon style={{ height: '10px', width: '10px' }} />(
          {subWarnings.length})
        </div>
      </HtmlTooltip> */}
    </div>
  );
}
