import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useMutation } from '@apollo/client';

import { updateReportAssetsMutation } from './../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import Tooltip from '@mui/material/Tooltip';
import { AccountContext } from '../../../../context/AccountContext';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { MutationContext } from '../../../../context/MutationContext';

export default function BasicMenu({
  reportAsset,
  refetchReportAssetsCounter,
  setRefetchReportAssetsCounter,
  onClickCloseHandler,
  refetch
}) {
  const { currentManagerAccountId } = useContext(AccountContext);

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let nexusDocumentStatuses = [
    { name: 'ACTIVE', string: 'ACTIVE', color: 'green' },
    { name: 'REMOVED', string: 'REMOVED', color: 'red' }
  ];

  // const [updateDocument] = useMutation(updateDocumentMutation);

  //   const { updateDocument } = useContext(MutationContext);

  const [updateReportAssets, { called }] = useMutation(
    updateReportAssetsMutation
  );

  const handleClose = async (e, value) => {
    if (value === 'backdropClick') {
      setAnchorEl(null);
      return;
    }
    if (value === reportAsset?.status) {
      console.log('same status');
      setAnchorEl(null);
      return;
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      reportAssets: [{ _id: reportAsset._id, status: value }],
      updateKeys: ['status']
    };

    // updateDocument({ variables: { input: { ...documentInput } } });

    try {
      const res = await updateReportAssets({
        variables: { input: { ...documentInput } }
      });

      if (res?.data?.updateReportAssets?.response !== 'success') {
        throw new Error();
      }
      if (value === 'REMOVED') {
        onClickCloseHandler();
      } else {
        refetch();
      }
      setRefetchReportAssetsCounter(refetchReportAssetsCounter + 1);
      setAnchorEl(null);
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }

    // setAnchorEl(null);
  };

  let fiberManualRecordIconColor = '#bcbcbc';
  let statusName = reportAsset?.status;

  //   switch (item.status) {
  //     // case 'ACTIVE':
  //     //   // case 'DONE':
  //     //   fiberManualRecordIconColor = 'green';
  //     //   break;
  //     // case 'DONE':
  //     // case 'COMPLETED':
  //     //   fiberManualRecordIconColor = 'rgba(0,128,0,0.4)';
  //     //   break;
  //     // case 'REMOVED':
  //     //   fiberManualRecordIconColor = 'red';
  //     //   break;

  //     // case 'DRAFT':
  //     //   fiberManualRecordIconColor = '#bcbcbc';
  //     //   break;
  //     // case 'PAUSED':
  //     //   fiberManualRecordIconColor = 'rgb(62, 142, 208)';
  //     //   break;
  //     default:
  //       // fiberManualRecordIconColor = 'orange';
  //       const status = nexusDocumentStatuses.filter(
  //         (status) => status.string === item.status
  //       )[0];
  //       if (status) {
  //         fiberManualRecordIconColor = status.color;
  //         statusName = status.name;
  //       }

  //       break;
  //   }
  const menuItemStyle = { fontSize: '12px' };
  let showText = true;
  let iconStyle = {};
  return (
    <div>
      {showText ? (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            width: '100%',
            textAlign: 'right',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: fiberManualRecordIconColor,
            color: fiberManualRecordIconColor,
            padding: '3px 6px',
            borderRadius: '15px',
            minWidth: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          {/* <FiberManualRecordIcon
            style={{
              fontSize: '10px',
              verticalAlign: 'middle',
              color: fiberManualRecordIconColor
            }}
          /> */}
          <span
            style={{ fontSize: '10px', marginLeft: '3px', marginRight: '3px' }}
          >
            {statusName}
          </span>
        </Button>
      ) : (
        // <Tooltip
        //   title={
        //     <span style={{ fontSize: '13px' }}>
        //       {item['status']
        //         ? item['status'].charAt(0).toUpperCase() +
        //           item['status'].slice(1).toLowerCase()
        //         : ' '}
        //     </span>
        //   }
        // >
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            width: '18px',
            minWidth: '18px',
            textAlign: 'right',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: fiberManualRecordIconColor,
            color: fiberManualRecordIconColor,
            padding: '3px 6px',
            borderRadius: '15px',
            // minWidth: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            ...(iconStyle || {})
          }}
        >
          <FiberManualRecordIcon
            style={{
              fontSize: '10px',
              verticalAlign: 'middle',
              color: fiberManualRecordIconColor
            }}
          />
          {/* <span
            style={{ fontSize: '10px', marginLeft: '3px', marginRight: '3px' }}
          >
            {item['status']
              ? item['status'].charAt(0).toUpperCase() +
                item['status'].slice(1).toLowerCase()
              : ' '}
          </span> */}
        </Button>
        // </Tooltip>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {nexusDocumentStatuses.map((status) => (
          <MenuItem
            style={menuItemStyle}
            onClick={(e) => handleClose(e, status.string)}
          >
            {status.name}
          </MenuItem>
        ))}
        {/* <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'DRAFT')}
        >
          Draft
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'PLANNED')}
        >
          Planned
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'READY')}
        >
          Ready
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'ACTIVE')}
        >
          Active
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'PAUSED')}
        >
          Paused
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'CANCELED')}
        >
          Canceled
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'COMPLETED')}
        >
          Completed
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'REMOVED')}
        >
          Removed
        </MenuItem> */}
      </Menu>
    </div>
  );
}
