import React from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     display: 'block',
//     marginTop: theme.spacing(2)
//   },
//   formControl: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//     minWidth: 120
//   }
// }));

export default function ControlledOpenSelect({
  // overrideStatus,
  campaignFilter,
  setCampaignFilter
}) {
  // const classes = useStyles();
  //   const [status, setStatus] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setCampaignFilter(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {/* <Button className={classes.button} onClick={handleOpen}>
        Open the select
      </Button> */}
      <FormControl
      // className={classes.formControl}
      // disabled={!overrideStatus}
      >
        <InputLabel id="demo-controlled-open-select-label">Filter</InputLabel>
        <Select
          variant="standard"
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={campaignFilter}
          onChange={handleChange}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          <MenuItem value={'ALL'}>ALL</MenuItem>
          <MenuItem value={'REAL_TIME_BIDDING'}>
            ONLY REAL TIME BIDDING
          </MenuItem>
          <MenuItem value={'EXCLUDE_REAL_TIME_BIDDING'}>
            EXCLUDE REAL TIME BIDDING
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
