import React, { Fragment, useContext, useEffect, useLayoutEffect } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import LoginDialog from './LoginDialog';
import Button from '@mui/material/Button';
import GoogleLogin from './../auth/GoogleLogin';

// import Footer from './Footer';

import { AuthContext } from '../../context/AuthContext';
import { AccountContext } from '../../context/AccountContext';
import ManualLogin from './ManualLogin';

import { styled, useTheme } from '@mui/material/styles';

import backgroundImagePattern from './../../img/bg-container-purple.png';

const Login = () => {
  const theme = useTheme();
  const { isAuthenticated } = useContext(AuthContext);

  const { setCurrentManagerAccountId } = useContext(AccountContext);

  const { state } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      console.log('Landing render');
      const currentQuery = new URLSearchParams(state?.search);
      const managerAccountId = currentQuery.get('manager_account_id');
      // console.log('Landing>managerAccountId: ', managerAccountId);
      if (managerAccountId) {
        setCurrentManagerAccountId(managerAccountId);
        const url = (state?.path || '') + (state?.search || '');

        navigate(url || '/choose-manager-account');
      } else {
        navigate('/choose-manager-account');
      }

      // return <Navigate to={url || '/choose-manager-account'} />;

      // <Navigate to="/choose-manager-account" />;
    }
  }, [isAuthenticated]);

  return (
    <Fragment>
      <section
        className="landing"
        style={{
          background:
            theme.palette.mode === 'dark'
              ? 'linear-gradient(17deg, rgba(54, 82, 156, 0.8), rgba(54, 82, 156, 0) 70.71%), linear-gradient(336deg, rgba(167, 38, 131, 0.8), rgba(167, 38, 131, 0) 70.71%)'
              : '#e9e9e9'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            // height: '800px'
            backgroundImage:
              theme.palette.mode === 'dark'
                ? `url(${backgroundImagePattern})`
                : `url(${backgroundImagePattern})`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            display: ' flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <div style={{ height: '22%', minHeight: '50px' }}></div> */}
          <div
            // className="landing-inner"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '50px 100px',
              borderRadius: '15px',
              background:
                theme?.palette?.mode === 'dark'
                  ? 'rgba(150,150,159,.1)'
                  : 'rgb(245, 245, 245)'
              // gap: '30px'
            }}
          >
            <h1
              className="x-large"
              style={{
                // color: 'white'
                color: '#a72683'
              }}
            >
              Welcome
            </h1>
            <p
              className="lead"
              style={{
                color: theme?.palette?.mode === 'dark' ? 'white' : '#21285a'
              }}
            >
              Please sign in
            </p>
            <div
              className="buttons"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
                // marginTop: '30px'
              }}
            >
              <div style={{ margin: '23px 0px' }}>
                <ManualLogin />
              </div>
              <div
                style={{
                  margin: '10px 0px 30px 0px',
                  color: theme?.palette?.mode === 'dark' ? 'white' : '#21285a'
                }}
              >
                or
              </div>
              <GoogleLogin />
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Login);
