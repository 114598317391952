import React from 'react';
import Slider from './Slider';

function DefaultFoot({
  index,
  itemKey,
  arrayOfDataLength,
  stylesStoredInRedux,
  ganttWidth,
  setGanttWidth,
  sliderColor
}) {
  let footTitle = itemKey;

  return (
    <div
      className="footer-cell"
      key={index}
      // draggable
      style={{
        maxHeight: '46px',
        backgroundImage:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .backgroundImage,
        color:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .color,
        // backgroundImage:
        //   'linear-gradient(to bottom, white, #f9fafa)',
        borderRight:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .border,
        borderTop:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .border,
        borderBottom:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .border,
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: arrayOfDataLength + 2,
        gridRowEnd: arrayOfDataLength + 2,
        position: 'sticky',
        bottom: '0',
        display: 'grid',
        zIndex: '1000',
        height: '100%',
        width: '100%',
        padding: '7px 20px',
        fontWeight: '800'
      }}
    >
      <span
        style={
          {
            // paddingTop: '2px',
            // paddingLeft: '20px'
            //   margin: 'auto'
          }
        }
      >
        <div>
          <Slider
            ganttWidth={ganttWidth}
            setGanttWidth={setGanttWidth}
            sliderColor={sliderColor}
          />
        </div>
        <p
          style={{
            fontSize: '8px',
            color: '#bcbcbc',
            visibility: 'hidden'
          }}
        >
          {' '}
          test
        </p>
      </span>
      <div
            style={{
              top: '45px',
              // right: '0px',
              height: '5px',
              position: 'absolute',
              background:
                '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
              // cursor: 'col-resize',
              // backgroundColor: 'red',
              userSelect: 'none',
              width: 'calc(100% - 3px)',
              zIndex: '198',
              borderBottomRightRadius: '5px'
            }}
          ></div>
    </div>
  );
}

export default DefaultFoot;
