import React from 'react';

import { useInView } from 'react-intersection-observer';

import { Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

function RemoveButton() {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  return (
    <div style={{ height: '15px' }} ref={ref}>
      {inView && (
        <Button
          style={{
            height: '15px',
            maxHeight: '15px',
            minHeight: '15px',
            width: '15px',
            maxWidth: '15px',
            minWidth: '15px'
          }}
        >
          <CloseIcon style={{ fontSize: '10px' }} />
        </Button>
      )}
    </div>
  );
}

export default RemoveButton;
