import React from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ErrorIcon from '@mui/icons-material/Error';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

// const useStylesBootstrap = makeStyles((theme) => ({
//   arrow: {
//     color: theme.palette.common.black
//   },
//   tooltip: {
//     backgroundColor: theme.palette.common.black
//   }
// }));

function BootstrapTooltip(props) {
  // const classes = useStylesBootstrap();

  return (
    <Tooltip
      arrow
      // classes={classes}
      {...props}
    />
  );
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

export default function CustomizedTooltips() {
  return (
    <div style={{ display: 'inline' }}>
      {/* <LightTooltip title="Add">
        <Button>Light</Button>
      </LightTooltip>
      <BootstrapTooltip title="Add">
        <Button>Bootstrap</Button>
      </BootstrapTooltip> */}
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Unavailable</Typography>
            <em>{'Please adjust date picker to cover the date range'}</em>.
          </React.Fragment>
        }
      >
        <Button
          className=""
          style={{
            width: '3px',
            minWidth: '0px',
            fontSize: '8px',
            padding: '0px',
            marginLeft: '4px'
          }}
        >
          {/* <i className="fas fa-info-circle"></i> */}
          <ErrorIcon
            style={{
              width: 15,
              height: 15,
              //   backgroundColor: 'grey',
              color: 'grey'
            }}
          />
        </Button>
      </HtmlTooltip>
    </div>
  );
}
