import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { useQuery } from '@apollo/client';

import Spinner from './../../../../../../layout/SpinnerSmall';

import { snapchatAdsAccountsQuery } from './../../../../../../../graphql/queries';

import { AccountContext } from '../../../../../../../context/AccountContext';

function CheckList({
  filterSearch,
  // currentManagerAccount,
  checked,
  setChecked,
  code,
  isLoading,
  toggleIsLoading
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  // const [isLoading, toggleIsLoading] = useState(true);

  const [skip, toggleSkip] = useState(true);

  useEffect(() => {
    if (currentManagerAccount && currentManagerAccount._id) {
      toggleSkip(false);
    }
  }, [currentManagerAccount]);

  const variables = {
    snapchatAdsAccountsInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccount._id
      //   accountId
      // code
    }
  };

  const { data, refetch } = useQuery(snapchatAdsAccountsQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip
  });

  useEffect(() => {
    if (data) {
      toggleSkip(true);
    }
  }, [data]);

  //   useEffect(()=>{
  //       if(data){
  //           toggleSkip(true);
  //       }
  //   },[data])
  const [listOfData, setListOfData] = useState([]);

  useEffect(() => {
    if (data && data.snapchatAdsAccounts) {
      setListOfData(data.snapchatAdsAccounts);
      toggleIsLoading(false);
    }
  }, [data]);
  // let listOfData = data && data.googleAdsAccounts ? data.googleAdsAccounts : [];

  //   let listOfData = dummyList;
  const filterField = 'Al';

  let listOfData2 = listOfData.filter((obj) => {
    if (filterSearch) {
      if (
        (obj.name &&
          obj.name.toLowerCase().includes(filterSearch.toLowerCase())) ||
        (obj.id && obj.id.toLowerCase().includes(filterSearch.toLowerCase()))
      ) {
        return true;
      }
    } else {
      return true;
    }
  });

  // const [checked, setChecked] = useState([]);

  const onClickHandler = (e, id) => {
    if (checked.includes(id)) {
      const filteredChecked = checked.filter((checkedId) => checkedId !== id);
      setChecked(filteredChecked);
    } else {
      setChecked([...checked, id]);
    }
  };

  // const handleChange = (e)=>{
  //     console.log('handle change');

  // }

  let i = 0;
  const accountList = listOfData2.map((obj, index) => {
    let permissions = '';

    // for (let i=0; i<obj.permissions.length; i++){
    //     if(i===0){
    //         permissions = obj.permissions[i];
    //     } else {
    //         permissions = permissions + ' & ' + obj.permissions[i];
    //     }

    // }

    i++;
    return (
      <Button
        className="text-transform-unset"
        value={obj.id}
        key={index}
        style={{
          gridRowStart: i,
          gridRowEnd: i,
          height: '100%',
          justifyContent: 'flex-start',
          margin: '2px 0px',
          background: checked.includes(obj.id) && 'rgba(255,255,255,0.07)',
          borderBottom: '1px solid rgba(255,255,255,0.05)',
          marginBottom: '5px'
        }}
        // color="primary"
        onClick={(e) => onClickHandler(e, obj.id)}
      >
        <Checkbox
          checked={checked.includes(obj.id)}
          // onChange={(e)=>handleChange(e)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <div style={{ display: 'grid' }}>
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.name}
          </div>
          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              fontSize: '10px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.id}
          </div>
        </div>
      </Button>
    );
  });

  return (
    <div
      style={{
        display: 'grid',
        gridRowStart: 2,
        gridRowEnd: 2,
        gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
        overflowY: 'scroll',
        maxHeight: '400px'
      }}
    >
      {isLoading ? (
        <div style={{ margin: '150px auto' }}>
          <Spinner />
        </div>
      ) : (
        accountList
      )}
    </div>
  );
}

export default CheckList;
