import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';

import { MutationContext } from '../../../../context/MutationContext';

import { AccountContext } from '../../../../context/AccountContext';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { DialogContext } from '../../../../context/DialogContext';

import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';

import DeleteIcon from '@mui/icons-material/Delete';

import CropDinIcon from '@mui/icons-material/CropDin';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Crop75Icon from '@mui/icons-material/Crop75';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import DateTimePicker from './DateTimePicker';

import SliderMenuItem from './SliderMenuItem';

export default function DenseMenu({
  mouseDownWidgetIds,
  // selectedUser,
  // widgetIdThatTriggeredContextMenu,
  // setWidgetIdThatTriggeredContextMenu,
  // widgets,
  setContextMenuAnchorPosition,
  handleClose
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    widgets,
    setWidgets,
    widgetIdThatTriggeredContextMenu,
    updateWidget,
    isAxisMenuOpen,
    toggleIsAxisSettingsOpen,
    inputImageTriggerCounter,
    setInputImageTriggerCounter
  } = useContext(ReportBuilderContext);

  const { reportBuilderAssetDialogOpen, setReportBuilderAssetDialogOpen } =
    useContext(DialogContext);

  // console.log('test4467>widgets: ', widgets);
  // console.log(
  //   'test4467>widgetIdThatTriggeredContextMenu: ',
  //   widgetIdThatTriggeredContextMenu
  // );

  const widget = widgets.filter(
    (widget) => widget.id === widgetIdThatTriggeredContextMenu
  )[0];
  const {
    addWidgets,
    updateWidgets,
    refetchWidgetsCounter,
    setRefetchWidgetsCounter
  } = useContext(MutationContext);
  let statuses = [];

  switch (true) {
    case widget?.status === 'TIMEBOX_LIST':
      statuses = [{ name: 'Removed', value: 'REMOVED' }];
      break;
    case [
      'UNDEFINED',
      'DRAFT',
      'ACTIVE',
      'COMPLETED',
      'NOT_COMPLETED'
    ].includes(widget?.status):
      statuses = [
        { name: 'Undefined', value: 'UNDEFINED' },
        // { name: 'WidgetList', value: 'TIMEBOX_LIST' },
        // { name: 'Draft', value: 'DRAFT' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Completed', value: 'COMPLETED' },
        // { name: 'Not completed', value: 'NOT_COMPLETED' },
        { name: 'Removed', value: 'REMOVED' }
      ];
      break;
  }

  const onClickMenuItem = async (e, action, value) => {
    switch (action) {
      // case 'CHANGE_STATUS':
      //   var input = {
      //     managerAccountId: currentManagerAccount._id,
      //     // selectedUserId: selectedUser?._id,
      //     updateKeys: ['status'],
      //     // widgets: [{ _id: widgetIdThatTriggeredContextMenu, status: value }]
      //     widgets: mouseDownWidgetIds.length
      //       ? mouseDownWidgetIds.map((widgetId) => ({
      //           _id: widgetId,
      //           status: value
      //         }))
      //       : [{ _id: widgetIdThatTriggeredContextMenu, status: value }]
      //   };

      //   console.log('update!!!');

      //   await updateWidgets({ variables: { input } });
      //   setContextMenuAnchorPosition(null);
      //   break;

      // case 'DELETE':
      // var input = {
      //   managerAccountId: currentManagerAccount._id,
      //   // selectedUserId: selectedUser?._id,
      //   updateKeys: ['nexusDocumentIds', 'accountId'],

      //   widgets: mouseDownWidgetIds.length
      //     ? mouseDownWidgetIds.map((widgetId) => ({
      //         _id: widgetId,
      //         accountId: null,
      //         accountIds: [],
      //         nexusDocumentIds: []
      //       }))
      //     : [
      //         {
      //           _id: widgetIdThatTriggeredContextMenu,
      //           accountId: null,
      //           accountIds: [],
      //           nexusDocumentIds: []
      //         }
      //       ]
      // };

      // console.log('update!!!');

      // await updateWidgets({ variables: { input } });
      // setContextMenuAnchorPosition(null);

      case 'ASSET_LIBRARY':
        setReportBuilderAssetDialogOpen(true);
        handleClose();
        break;

      case 'UPLOAD_IMAGE':
        setInputImageTriggerCounter(inputImageTriggerCounter + 1);

        handleClose();
        break;

      case 'TOGGLE_CARD':
        var copyOfWidgets = [...widgets];
        var index = copyOfWidgets.findIndex(
          (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
        );
        updateWidget(
          { ...widget, isShowingCard: !widget.isShowingCard },
          index
        );
        handleClose();
        break;
      case 'TOGGLE_FREEZE_DATA':
        var copyOfWidgets = [...widgets];
        var index = copyOfWidgets.findIndex(
          (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
        );
        updateWidget({ ...widget, isDataFrozen: !widget.isDataFrozen }, index);
        handleClose();
        break;
      case 'TOGGLE_AXIS_SETTINGS':
        toggleIsAxisSettingsOpen(widget.id);
        handleClose();
        break;

      case 'BRING_TO_FRONT':
        var copyOfWidgets = [...widgets];
        var index = copyOfWidgets.findIndex(
          (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
        );

        console.log('index: ', index);
        copyOfWidgets.splice(index, 1);
        copyOfWidgets.push(widget);
        setWidgets(copyOfWidgets);
        handleClose();
        break;
      case 'SEND_TO_BACK':
        var copyOfWidgets = [...widgets];
        var index = copyOfWidgets.findIndex(
          (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
        );

        console.log('index: ', index);
        copyOfWidgets.splice(index, 1);
        copyOfWidgets.unshift(widget);
        setWidgets(copyOfWidgets);
        handleClose();
        break;
      case 'DELETE':
        var copyOfWidgets = [...widgets];
        var index = copyOfWidgets.findIndex(
          (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
        );

        console.log('index: ', index);
        copyOfWidgets.splice(index, 1);
        setWidgets(copyOfWidgets);
        handleClose();
        break;
    }
  };
  if (!widget) {
    return <></>;
  }
  return (
    <Paper sx={{ width: 200 }}>
      <MenuList dense>
        {[
          'IMAGE_WIDGET',
          'MOCKUP_WIDGET'
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          // 'LINE_CHART_WIDGET',
          // 'COMBO_CHART_WIDGET'
          // 'PIE_CHART_WIDGET'
        ].includes(widget.type) && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  marginRight: '5px',
                  height: '1px',
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0.12)'
                }}
              ></div>
              <span style={{ fontSize: '12px' }}>Assets</span>
              <div
                style={{
                  marginLeft: '5px',
                  height: '1px',
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0.12)'
                }}
              ></div>
              {/* <Divider /> */}
            </div>
            {/* <Divider /> */}
            <MenuItem
              onClick={(e) => onClickMenuItem(e, 'ASSET_LIBRARY')}
              // style={{ background: widget.isDataFrozen && 'rgba(150,150,150,0.2)' }}
            >
              <ListItemIcon>
                <PhotoLibraryIcon />
              </ListItemIcon>
              <ListItemText>Asset Library</ListItemText>

              {/* {widget.isDataFrozen ? (
              <CheckBoxIcon style={{ fontSize: '16px' }} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
            )} */}
            </MenuItem>
            <MenuItem
              onClick={(e) => onClickMenuItem(e, 'UPLOAD_IMAGE')}
              // style={{ background: widget.isDataFrozen && 'rgba(150,150,150,0.2)' }}
            >
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText>Upload Image</ListItemText>

              {/* {widget.isDataFrozen ? (
              <CheckBoxIcon style={{ fontSize: '16px' }} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
            )} */}
            </MenuItem>
          </>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              marginRight: '5px',
              height: '1px',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.12)'
            }}
          ></div>
          <span style={{ fontSize: '12px' }}>Position</span>
          <div
            style={{
              marginLeft: '5px',
              height: '1px',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.12)'
            }}
          ></div>
          {/* <Divider /> */}
        </div>
        <MenuItem onClick={(e) => onClickMenuItem(e, 'BRING_TO_FRONT')}>
          <ListItemIcon>
            <FlipToFrontIcon />
          </ListItemIcon>
          <ListItemText>Bring to front</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => onClickMenuItem(e, 'SEND_TO_BACK')}>
          <ListItemIcon>
            <FlipToBackIcon />
          </ListItemIcon>
          <ListItemText>Send to back</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => onClickMenuItem(e, 'DELETE')}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={(e) => onClickMenuItem(e, 'TOGGLE_CARD')}
          style={
            {
              // background: widget.isShowingCard && 'rgba(150,150,150,0.2)'
            }
          }
        >
          <ListItemIcon>
            <Crop75Icon />
          </ListItemIcon>
          <ListItemText>Show card</ListItemText>
          {/* <ListItemIcon> */}
          {widget.isShowingCard ? (
            <CheckBoxIcon style={{ fontSize: '16px' }} />
          ) : (
            <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
          )}
          {/* </ListItemIcon> */}
        </MenuItem>

        <MenuItem
          onClick={(e) => onClickMenuItem(e, 'TOGGLE_FREEZE_DATA')}
          // style={{ background: widget.isDataFrozen && 'rgba(150,150,150,0.2)' }}
        >
          <ListItemIcon>
            <AcUnitIcon />
          </ListItemIcon>
          <ListItemText>Freeze data</ListItemText>
          {/* <ListItemIcon> */}
          {widget.isDataFrozen ? (
            <CheckBoxIcon style={{ fontSize: '16px' }} />
          ) : (
            <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
          )}
          {/* </ListItemIcon> */}
        </MenuItem>
        {[
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET'
          // 'PIE_CHART_WIDGET'
        ].includes(widget.type) && (
          <>
            <Divider />
            <MenuItem
              onClick={(e) => onClickMenuItem(e, 'TOGGLE_AXIS_SETTINGS')}
              // style={{ background: widget.isDataFrozen && 'rgba(150,150,150,0.2)' }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>Axis settings</ListItemText>

              {/* {widget.isDataFrozen ? (
              <CheckBoxIcon style={{ fontSize: '16px' }} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
            )} */}
            </MenuItem>
          </>
        )}

        {[
          'IMAGE_WIDGET',
          'MOCKUP_WIDGET',
          'MANAGER_ACCOUNT_LOGO_WIDGET',
          'TEXT_WIDGET',
          'NUMBER_WIDGET',
          'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET',
          'PIE_CHART_WIDGET'
        ].includes(widget.type) && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  marginRight: '5px',
                  height: '1px',
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0.12)'
                }}
              ></div>
              <span style={{ fontSize: '12px' }}>Opacity</span>
              <div
                style={{
                  marginLeft: '5px',
                  height: '1px',
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0.12)'
                }}
              ></div>
              {/* <Divider /> */}
            </div>
            <MenuItem>
              <SliderMenuItem
                widget={widget}
                styleKey={'opacity'}
                min={0}
                max={1}
                step={0.1}
              />
            </MenuItem>
          </>
        )}

        {/* <MenuItem>
          <ListItemText>Changelog</ListItemText>
        </MenuItem> */}
        {/* <MenuItem>
          <ListItemText>Add space after paragraph</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText>Custom spacing...</ListItemText>
        </MenuItem> */}
      </MenuList>
    </Paper>
  );
}
