export const period_on_period = (
  key,
  prepared_object,
  previous_prepared_object
) => {
  const pop_cost = previous_prepared_object[key]
    ? (
        (prepared_object[key] / previous_prepared_object[key]) * 100 -
        100
      ).toFixed(1)
    : 0;
  const pop_value = (pop_cost > 0 ? '+' : '') + pop_cost + '%';
  return pop_cost;
};

// export default period_on_period;
