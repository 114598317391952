import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { AccountContext } from '../../../context/AccountContext';
import { DialogContext } from '../../../context/DialogContext';

import CloseIcon from '@mui/icons-material/Close';

import EmailPokeDialogContent from './EmailPokeDialogContent';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        style={{ width: '700px', maxWidth: '100vw', maxHeight: '90%' }}
        {...props}
      />
    </Draggable>
  );
}

const EditModal = ({ rawArrayOfData, stylesStoredInRedux, checkedBoxes }) => {
  const { currentManagerAccount } = useContext(AccountContext);

  const [scrollTop, setScrollTop] = useState(0);
  const { emailPokeDialogOpen: open, setEmailPokeDialogOpen: setOpen } =
    useContext(DialogContext);

  const handleClose = () => {
    setOpen(false);
  };

  // const body = <div style={{ display: 'flex', flexDirection: 'column' }}></div>;

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <i
                style={{ fontSize: '20px', marginRight: '10px' }}
                className="fas fa-hand-point-right"
              ></i>
              <span>Email Poke</span>
            </div>

            <div
              style={{
                cursor: 'pointer',
                right: '0px',
                textAlign: 'right'
                // paddingRight: '25px'
              }}
            >
              <span style={{ width: '50px' }} onClick={handleClose}>
                <CloseIcon className="hover-light-grey" />
              </span>
            </div>
          </div>
          <div style={{ fontSize: '12px', padding: '0px 5px' }}></div>
        </DialogTitle>
        <DialogContent>
          <EmailPokeDialogContent
            open={open}
            setOpen={setOpen}
            checkedBoxes={checkedBoxes}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

EditModal.propTypes = {
  // style: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  stylesStoredInRedux: state.style
});

export default connect(mapStateToProps, {})(EditModal);
