import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import React, { useState, Fragment, useContext } from 'react';
import Button from '@mui/material/Button';
import { ClickAwayListener } from '@mui/base';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

// import { makeStyles } from '@mui/styles';

import { SnackbarProvider, useSnackbar } from 'notistack';

import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import FolderIcon from '@mui/icons-material/Folder';
import LinkIcon from '@mui/icons-material/Link';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import StorageIcon from '@mui/icons-material/Storage';

import PowerInputIcon from '@mui/icons-material/PowerInput';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import PublicIcon from '@mui/icons-material/Public';

import DataSourceModal from './data-source-menu/DataSourceModal';

import { AuthContext } from '../../context/AuthContext';
import { DialogContext } from '../../context/DialogContext';

import { InterfaceContext } from '../../context/InterfaceContext';

import { MutationContext } from '../../context/MutationContext';

import UploadCustomExcelDialog from './customexcel/UploadCustomExcelDialog';

export default function QuickCreateMenu({
  // addDocument,
  currentManagerAccountId,
  checkedBoxes,
  selectedDateStart,
  selectedDateStop,
  default_date_start_first_date_as_string,
  default_date_stop_first_date_as_string
}) {
  const { user } = useContext(AuthContext);

  const {
    uploadCustomExcelDialogOpen,
    setUploadCustomExcelDialogOpen,
    openDataSourceModal,
    setOpenDataSourceModal
  } = useContext(DialogContext);

  const { addDocument } = useContext(MutationContext);

  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     '& > *': {
  //       // margin: theme.spacing(1),
  //       borderRadius: '5px',
  //       // height: '20px',
  //       // width: '37px',
  //       // backgroundColor: "rgba(0, 181, 8, 1)",
  //       backgroundColor:
  //         checkedBoxes.length === 1 &&
  //         ['account', 'globalTasks', 'folder', 'task'].includes(
  //           checkedBoxes[0].type
  //         )
  //           ? 'rgba(187, 58, 151, 1)'
  //           : 'rgba(167, 38, 141, 0.3)',
  //       // ? 'rgba(178, 67, 147, 0.9)'
  //       // : 'rgba(178, 67, 147, 0.3)',
  //       // ? 'rgba(0, 181, 8, 0.9)'
  //       // : 'rgba(0, 181, 8, 0.3)',
  //       '&:hover': {
  //         backgroundColor:
  //           checkedBoxes.length === 1 &&
  //           ['account', 'globalTasks', 'folder', 'task'].includes(
  //             checkedBoxes[0].type
  //           )
  //             ? 'rgba(207, 78, 171, 1)'
  //             : 'rgba(167, 38, 141, 0.3)'
  //         // ? 'rgba(20, 201, 28, 1)'
  //         // : 'rgba(0, 181, 8, 0.3)'
  //         // background: "rgba(20, 201, 28, 1)"
  //       }
  //     }
  //   },
  //   extendedIcon: {
  //     color: 'white',
  //     marginRight: theme.spacing(1)
  //   }
  // }));

  // const [addDocument] = useMutation(addDocumentMutation);

  const { enqueueSnackbar } = useSnackbar();

  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    if (checkedBoxes.length > 1) {
      enqueueSnackbar('please check one box at the time', {
        variant: 'error'
      });

      return;
    }
    if (checkedBoxes.length === 0) {
      enqueueSnackbar('You need to check atleast one checkbox', {
        variant: 'error'
      });

      return;
    }

    if (
      !['account', 'globalTasks', 'folder', 'task'].includes(
        checkedBoxes[0].type
      )
    ) {
      enqueueSnackbar('Please select Folder or account', {
        variant: 'error'
      });
      return;
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, documentType) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    switch (documentType) {
      case 'source_connected_document':
        setOpenDataSourceModal(true);

        break;

      case 'upload_custom_excel':
        setUploadCustomExcelDialogOpen(true);
        break;
    }

    setOpen(false);
  };

  const handleCloseQuickCreate = async (event, documentType, type) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (checkedBoxes[0].level >= 9) {
      enqueueSnackbar('Max level container reached.', {
        variant: 'error'
      });

      return;
    }

    if (
      !['account', 'globalTasks', 'folder', 'task'].includes(
        checkedBoxes[0].type
      )
    ) {
      enqueueSnackbar('Please select a Folder.', {
        variant: 'error'
      });

      return;
    }

    let name = 'new document';
    switch (type) {
      case 'folder':
        name = 'New Folder';
        break;
      case 'manualDataEntry':
        name = 'New Manual Input';
        break;
      case 'customForm':
        name = 'Custom Form';
        break;
      case 'task':
        name = 'New task';
        break;
      case 'subTask':
        name = 'New subtask';
        break;
      case 'taxonomy':
        name = 'New taxonomy';
        break;
    }

    let addDocumentInput = {
      managerAccountId: currentManagerAccountId,
      accountId: checkedBoxes[0].accountId,
      checkedDocumentId: checkedBoxes[0]._id,
      level: checkedBoxes[0].level,
      newDocuments: [{ documentType, name }]
    };

    try {
      const res = await addDocument({
        variables: { input: { ...addDocumentInput } }
      });

      if (res?.data?.addDocument?.response !== 'Success') {
        throw new Error();
      }
      setOpen(false);
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  const rowType =
    checkedBoxes && checkedBoxes[0] && checkedBoxes[0].type
      ? checkedBoxes[0].type
      : '';
  const rowLevel =
    checkedBoxes && checkedBoxes[0] && checkedBoxes[0].level
      ? checkedBoxes[0].level
      : null;
  let buttons = (
    <Fragment>
      {['account'].includes(rowType) && rowLevel < 9 && (
        <>
          {['system_admin'].includes(user.role) && (
            <MenuItem
              onClick={(e) =>
                handleCloseQuickCreate(
                  e,
                  'totalActionsDocument',
                  'totalActionsDocument'
                )
              }
            >
              <PublicIcon style={{ paddingRight: '5px' }} />
              Total Actions Document
            </MenuItem>
          )}
          <MenuItem
            onClick={(e) => handleCloseQuickCreate(e, 'folder', 'folder')}
          >
            <FolderIcon style={{ paddingRight: '5px' }} />
            Folder
          </MenuItem>
        </>
      )}

      {['folder'].includes(rowType) && rowLevel < 9 && (
        <Fragment>
          {/* {rowLevel < 8 && ( */}
          <MenuItem
            onClick={(e) => handleCloseQuickCreate(e, 'folder', 'folder')}
          >
            <FolderIcon style={{ paddingRight: '5px' }} />
            Folder
          </MenuItem>
          {/* )} */}
          <MenuItem
            onClick={(e) => handleCloseQuickCreate(e, 'manualDataEntry')}
          >
            <KeyboardReturnOutlinedIcon style={{ paddingRight: '5px' }} />{' '}
            Manual Data Entry
          </MenuItem>

          {/* <MenuItem onClick={(e) => handleCloseQuickCreate(e, 'customForm')}>
            <DynamicFormIcon style={{ paddingRight: '5px' }} /> Custom Form
          </MenuItem> */}

          <MenuItem
            onClick={(e) => handleClose(e, 'source_connected_document')}
          >
            {/* <LinkIcon style={{ paddingRight: '5px' }} /> */}
            <StorageIcon style={{ paddingRight: '5px' }} />
            {/* <i class="fas fa-database" style={{ paddingRight: '5px' }}></i> */}
            Data Source
          </MenuItem>

          {['system_admin'].includes(user.role) && (
            <MenuItem onClick={(e) => handleClose(e, 'upload_custom_excel')}>
              {/* <LinkIcon style={{ paddingRight: '5px' }} /> */}
              <UploadFileIcon style={{ paddingRight: '5px' }} />
              {/* <i class="fas fa-database" style={{ paddingRight: '5px' }}></i> */}
              Upload Excel
            </MenuItem>
          )}
          {/* <MenuItem onClick={(e) => handleClose(e, 'message_board')}>
          <QuestionAnswerIcon style={{ paddingRight: '5px' }} />{' '}
          Message Board
          </MenuItem> */}
          <MenuItem onClick={(e) => handleCloseQuickCreate(e, 'task', 'task')}>
            <ListAltIcon style={{ paddingRight: '5px' }} /> Task
          </MenuItem>

          {/* <MenuItem onClick={(e) => handleCloseQuickCreate(e, 'subTask')}>
            <ListIcon style={{ paddingRight: '5px' }} /> Task
          </MenuItem> */}
          {['system_admin'].includes(user.role) && (
            <MenuItem
              onClick={(e) => handleCloseQuickCreate(e, 'taxonomy', 'taxonomy')}
            >
              <PowerInputIcon
                style={{ paddingLeft: '5px', transform: 'rotate(180deg)' }}
              />{' '}
              Taxonomy
            </MenuItem>
          )}
        </Fragment>
      )}

      {['globalTasks'].includes(rowType) && rowLevel < 9 && (
        <Fragment>
          {/* {rowLevel < 8 && ( */}

          <MenuItem onClick={(e) => handleCloseQuickCreate(e, 'task', 'task')}>
            <ListAltIcon style={{ paddingRight: '5px' }} /> Task
          </MenuItem>

          {/* <MenuItem onClick={(e) => handleCloseQuickCreate(e, 'subTask')}>
            <ListIcon style={{ paddingRight: '5px' }} /> Task
          </MenuItem> */}
        </Fragment>
      )}
      {['task'].includes(rowType) && rowLevel < 9 && (
        <Fragment>
          <MenuItem
            onClick={(e) => handleCloseQuickCreate(e, 'subTask', 'subTask')}
          >
            <ListIcon style={{ paddingRight: '5px' }} /> Subtask
          </MenuItem>
        </Fragment>
      )}
    </Fragment>
  );

  return (
    <div
    // className={classes.root}
    >
      <div>
        <div
        // className={classes.root}
        >
          <Fab
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            aria-label="add"
            style={{
              background:
                checkedBoxes.length === 1 &&
                ['account', 'globalTasks', 'folder', 'task'].includes(
                  checkedBoxes[0].type
                )
                  ? 'rgba(187, 58, 151, 1)'
                  : 'rgba(167, 38, 141, 0.3)',
              // ? 'rgba(178, 67, 147, 0.9)'
              // : 'rgba(178, 67, 147, 0.3)',
              // ? 'rgba(0, 181, 8, 0.9)'
              // : 'rgba(0, 181, 8, 0.3)'
              borderRadius: '5px',
              // height: '32px',
              height: '36px',
              minHeight: '32px',
              // width: '32px',
              width: '40px',
              minWidth: '32px',
              // backgroundColor:
              //   checkedBoxes.length === 1 &&
              //   ['Account', 'container', 'Folder', 'to_do_list'].includes(
              //     checkedBoxes[0].type
              //   )
              //     ? 'rgba(0, 181, 8, 0.9)'
              //     : 'rgba(0, 181, 8, 0.3)',
              color: 'white',
              fontSize: '12px'
            }}
            onClick={handleToggle}
          >
            <AddIcon />
          </Fab>
        </div>
        <Popper
          placement="bottom-start"
          style={{ zIndex: '6000' }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          onKeyDown={onKeyDownHandler}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: '0 0 0'
                // placement === 'bottom' ? 'center top' : 'center bottom'
                // placement === 'bottom-start'
                // placement === 'bottom-start' ? 'start top' : 'start bottom'
              }}
            >
              <Paper style={{ border: '1px solid rgba(255,255,255,0.3)' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {buttons}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <DataSourceModal
        open={openDataSourceModal}
        setOpen={setOpenDataSourceModal}
        checkedBoxes={checkedBoxes}
        currentManagerAccountId={currentManagerAccountId}
      />
      <UploadCustomExcelDialog />
    </div>
  );
}
