import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { useMutation } from '@apollo/client';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { createAccountMutation } from './../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../context/AccountContext';

import SelectTimeZone from './SelectTimeZone';

import SelectWeekType from './SelectWeekType';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        //  style={{ height: '400px', width: '400px' }}
      />
    </Draggable>
  );
}

export default function DraggableDialog({
  open,
  setOpen,
  refetchAccountList
  // currentManagerAccount
}) {
  const { currentManagerAccount, refetchAccounts } = useContext(AccountContext);
  //   const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('admin');

  const [timeZone, setTimeZone] = React.useState('Europe/Stockholm');
  const [weekType, setWeekType] = React.useState('monday-sunday');

  const [addUser, { called }] = useMutation(createAccountMutation);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = async () => {
    if (!name) {
      enqueueSnackbar('Name missing', {
        variant: 'error'
      });
      return;
    }
    const createAccountInput = {
      managerAccountId: currentManagerAccount._id,
      name,
      timeZone,
      weekType
    };

    const res = await addUser({ variables: { input: createAccountInput } });

    if (res?.data?.createAccount?.response === 'success') {
      enqueueSnackbar('Account created', {
        variant: 'success'
      });
      refetchAccounts();
      refetchAccountList();
    }
    setName('');
    setOpen(false);
  };

  const handleClose = () => {
    setName('');
    setOpen(false);
  };

  const onChangeHandler = (e, field) => {
    switch (field) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
    }
  };

  const content = (
    <div>
      <form noValidate autoComplete="off">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px'
          }}
        >
          <div style={{ padding: '10px' }}>
            <TextField
              style={{ minWidth: '220px' }}
              value={name}
              onChange={(e) => onChangeHandler(e, 'name')}
              variant="standard"
              id="standard-basic"
              label="Name"
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <SelectTimeZone timeZone={timeZone} setTimeZone={setTimeZone} />
          </div>
          <div style={{ marginTop: '20px' }}>
            <SelectWeekType weekType={weekType} setWeekType={setWeekType} />
          </div>
          {/* <div style={{display: 'grid', gridRowStart: 2, gridRowEnd: 2}}>
                    <TextField value={email} onChange={(e)=>onChangeHandler(e, 'email')} id="standard-basic" label="Email" />
                </div>
                <div style={{display: 'grid', gridRowStart: 3, gridRowEnd: 3}}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={role}
                            onChange={setRole}
                            >
                            <MenuItem value={'admin'}>Admin</MenuItem>

                        </Select>
                    </FormControl>
                </div> */}
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <AccountBalanceIcon
            style={{
              verticalAlign: 'text-bottom',
              paddingBottom: '1px',
              fontSize: '24px'
            }}
          />{' '}
          <span style={{ marginLeft: '3px' }}>Create New Account</span>
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          {content}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
