import React, { useContext } from 'react';

import StatusMenu from '../../../../item-components/body/StatusMenu';

import { AccountContext } from '../../../../../../context/AccountContext';

import Tooltip from '@mui/material/Tooltip';

import WarningTooltip from '../../WarningTooltip';

function SubTasks({
  item,
  rawArrayOfData,
  currentManagerAccountId,
  stylesStoredInRedux
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const subTasks = rawArrayOfData.filter(
    (obj) =>
      obj[`level_${item.level}_container_id`] === item._id &&
      obj.documentType === 'subTask'
  );

  if (subTasks.length === 0) {
    return <></>;
  }

  return (
    <div style={{ margin: '10px 0' }}>
      {subTasks.map((subTask) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: '12px',
            margin: '7px 0'
          }}
        >
          <div
            style={{
              width: '180px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              alignSelf: 'center'
            }}
          >
            <span>
              {
                subTask?.warnings?.length > 0 ? (
                  <div
                    style={{
                      display: 'inline-block',
                      padding: '0px 2px 0px 0px'
                    }}
                  >
                    <WarningTooltip
                      textIcon={true}
                      stylesStoredInRedux={stylesStoredInRedux}
                      warnings={subTask.warnings}
                      subWarnings={subTask.subWarnings}
                    />
                  </div>
                ) : subTask.status !== 'COMPLETED' ? (
                  <span style={{ padding: '0px 4px 0px 2px' }}>•</span>
                ) : (
                  <span style={{ padding: '0px 1px 0px 0px', color: 'green' }}>
                    ✓
                  </span>
                )
                // subTask.status !== "COMPLETED" ?(<span style={{ padding: '0 2px': color: 'red' }}>•</span>) : (<span style={{ padding: '0 2px' }}>✓</span>)
              }
            </span>
            <Tooltip
              title={<span style={{ fontSize: '14px' }}>{subTask.name}</span>}
            >
              <span>{subTask.name}</span>
            </Tooltip>
          </div>

          <div>
            <StatusMenu
              currentManagerAccountId={currentManagerAccount._id}
              item={subTask}
              text={false}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default SubTasks;
