import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import Button from '@mui/material/Button';

import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';

import {
  ListGoogleAdsCampaignsQuery,
  ListFacebookAdsCampaignsQuery,
  ListLinkedInAdsCampaignGroupsQuery,
  ListSnapchatAdsCampaignsQuery,
  ListTikTokAdsCampaignsQuery,
  ListPinterestAdsCampaignsQuery,
  ListAmazonDspOrdersQuery,
  ListTaboolaAdsCampaignsQuery,
  ListMicrosoftAdvertisingCampaignsQuery,
  ListDV360InsertionOrdersQuery,
  ListCM360CampaignsQuery,
  addDataSourceRelationMutation,
  listAddedDataSourceIdsQuery,
  ListAdformCampaignsQuery,
  ListAdformOrdersQuery,
  AccountToDataSourceSettingsQuery
} from './../../../../graphql/queries';

import { MutationContext } from '../../../../context/MutationContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

// import { makeStyles } from '@mui/styles';

import Checklist from './../CheckList';

import { useSnackbar } from 'notistack';

import SelectStatus from '../show-more-options/SelectStatus';

import BasicDatePicker from '../../utils/BasicDatePicker';

import SelectTemplate from './../SelectTemplate';

import SelectFilter from './../SelectFilter';

import ShowMoreOptions from '../show-more-options/ShowMoreOptions';

function Adform({
  dataSource,
  currentManagerAccountId,
  checkedBoxes,
  setOpen,
  setDataSource,
  default_date_start_first_date_of_month_as_string,
  default_date_stop_last_date_of_month_as_string,
  documentType
}) {
  const [showMore, setShowMore] = useState(false);
  console.log('ListAdformCampaignsQuery: ', ListAdformCampaignsQuery);
  const [templateDocument, setTemplateDocument] = useState({});
  const [listOfCampaigns, setListOfCampaigns] = useState([]);
  const [listOfOrders, setListOfOrders] = useState([]);
  const [listOfCampaignsExAdded, setListOfCampaignsExAdded] = useState([]);
  const [campaignFilter, setCampaignFilter] = useState('');

  const [campaign, setCampaign] = useState({});

  const [datePickerDisabled, toggleDatePickerDisabled] = useState(true);

  const [isSet, toggleIsSet] = useState(false);

  // const [startDate, setStartDate] = useState(
  //   // new Date().toISOString().split('T')[0]
  //   default_date_start_first_date_of_month_as_string
  // );

  // const [endDate, setEndDate] = useState(
  //   default_date_stop_last_date_of_month_as_string
  // );

  const [overrideFields, setOverrideFields] = useState([
    // 'allowImpressionsBeforeStartDate',
    // 'allowImpressionsAfterEndDate',
    // 'allowMissingBudget',
    // 'allowMissingClicks',
    // 'allowAbnormallyHighCtr',
    // 'allowMissingImpressionsYesterday'
  ]);
  // const [overrideValues, updateOverrideValues] = useState({
  //   status: 'READY',
  //   startDate: default_date_start_first_date_of_month_as_string,
  //   endDate: default_date_stop_last_date_of_month_as_string
  // });

  const essentialDefaultOverrideValues = {
    status: 'READY',
    startDate: default_date_start_first_date_of_month_as_string,
    endDate: default_date_stop_last_date_of_month_as_string
  };
  const [overrideValues, updateOverrideValues] = useState(
    essentialDefaultOverrideValues
  );

  const setOverrideValues = (newState) => {
    const copyOfOverrideValues = { ...newState };

    for (let itemKey of ['action01', 'action02', 'action03']) {
      delete copyOfOverrideValues[itemKey];
      // if (!document[itemKey]?.dataSource) {
      //   delete copyOfOverrideValues[itemKey];
      // }
      if (newState[itemKey]?.dataSource) {
        copyOfOverrideValues[itemKey] = {
          dataSource: newState[itemKey]?.dataSource,
          dataSourceRef: newState[itemKey]?.dataSourceRef,
          [newState[itemKey]?.dataSource]:
            newState[itemKey]?.[newState[itemKey]?.dataSource],
          // name: String,
          dataSourceId: newState[itemKey]?.dataSourceId,
          dataSourceAccountId: newState[itemKey]?.dataSourceAccountId,

          name: newState[itemKey]?.name
        };
      }
    }
    updateOverrideValues(copyOfOverrideValues);
  };

  const [isLoading, toggleIsLoading] = useState(true);
  const [checked, setChecked] = useState([]);
  const [checkedCampaignIds, setCheckedCampaignIds] = useState([]);
  const [checkedOrderIds, setCheckedOrderIds] = useState([]);

  const [overrideStatus, setOverrideStatus] = useState(false);
  const [status, setStatus] = useState('DRAFT');
  const [limitDates, setLimitDates] = useState(false);

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { setTypeItemToggledItem, setTypeItemAnchorRef } =
    useContext(InterfaceContext);
  // const useStyles = makeStyles((theme) => ({
  //   paper: {
  //     position: 'absolute',
  //     width: 760,
  //     height: '85%',
  //     border: '1px solid grey',
  //     backgroundColor: theme.palette.background.paper,
  //     outline: 0
  //   },
  //   root: {
  //     '& > *': {
  //       margin: theme.spacing(1)
  //     }
  //   }
  // }));

  // const classes = useStyles();

  // const [addDataSourcerelation, { called }] = useMutation(
  //   addDataSourceRelationMutation
  // );

  const { addDocument } = useContext(MutationContext);

  // useEffect(() => {
  //   setCheckedOrderIds([]);
  // }, [checkedCampaignIds]);

  useEffect(() => {
    setCheckedCampaignIds([]);
    setCheckedOrderIds([]);
  }, [dataSource]);

  useEffect(() => {
    campaign && campaign.campaign_id
      ? toggleDatePickerDisabled(false)
      : toggleDatePickerDisabled(true);
  }, [campaign]);

  const onSaveHandler = async () => {
    // const documentType = 'googleAds';

    if (templateDocument?.string === 'MISSING') {
      enqueueSnackbar('Template document missing', {
        variant: 'error'
      });
      return;
    }

    let dataSourceObjects;

    // console.log('dataSourceObjects: ', dataSourceObjects);
    if (!isSet) {
      dataSourceObjects = listOfOrders.filter((obj) =>
        checkedOrderIds.includes(obj.id)
      );
      dataSourceObjects = dataSourceObjects.map((obj) => ({
        documentType,
        dataSourceAccountId: dataSource.dataSourceAccountId,
        dataSourceCampaignId: obj.campaignId,
        dataSourceInsertionOrderId: obj.id,
        // dataSourceFilter: campaignFilter,
        startDate: obj.startDate,
        endDate: obj.endDate,
        // status: obj.status === 'ENTITY_STATUS_ACTIVE' ? 'ACTIVE' : 'DRAFT'
        status: 'DRAFT'
      }));
    } else {
      dataSourceObjects = [
        {
          documentType,
          dataSourceAccountId: dataSource.dataSourceAccountId,
          // dataSourceCampaignId: obj.id,
          // dataSourceInsertionOrderId: obj.insertionOrderId,
          // startDate: obj.startDate,
          // endDate: obj.endDate,
          isSet,
          // status: obj.status === 'ENTITY_STATUS_ACTIVE' ? 'ACTIVE' : 'DRAFT'
          status: 'DRAFT'
          // dataSourceFilter: campaignFilter
        }
      ];
    }

    if (!dataSourceObjects.length) {
      return;
    }
    // console.log('dataSourceObjects: ', dataSourceObjects);

    for (let field of overrideFields) {
      switch (true) {
        case field === 'dates':
          for (let obj of dataSourceObjects) {
            if (overrideValues['startDate']) {
              if (
                (obj.startDate &&
                  new Date(obj.startDate) <
                    new Date(overrideValues['startDate'])) ||
                (!obj.startDate && overrideValues['startDate'])
              ) {
                obj.startDate = overrideValues['startDate'];
              }
            }
            if (overrideValues['endDate']) {
              if (
                (obj.endDate &&
                  new Date(obj.endDate) >
                    new Date(overrideValues['endDate'])) ||
                (!obj.endDate && overrideValues['endDate'])
              ) {
                obj.endDate = overrideValues['endDate'];
              }
            }
          }
          break;

        case field === 'status':
          for (let obj of dataSourceObjects) {
            obj.status = overrideValues['status'];
          }
          break;
        case [
          'allowImpressionsBeforeStartDate',
          'allowImpressionsAfterEndDate',
          'allowFrontloadedDelivery',
          'allowMissingBudget',
          'allowMissingClicks',
          'allowAbnormallyHighCtr',
          'allowMissingImpressionsYesterday'
        ].includes(field):
          for (let obj of dataSourceObjects) {
            obj[field] = Boolean(overrideValues[field]);
          }
          break;
        case ['action01', 'action02', 'action03'].includes(field):
          for (let obj of dataSourceObjects) {
            obj[field] = overrideValues[field];
          }
          break;
      }
    }

    let addDocumentInput = {
      managerAccountId: currentManagerAccountId,
      accountId: checkedBoxes?.[0]?.accountId,
      checkedDocumentId: checkedBoxes[0]?._id,
      level: checkedBoxes?.[0]?.level,
      newDocuments: dataSourceObjects,
      templateDescendant: checkedBoxes?.[0]?.templateDescendant
    };
    if (templateDocument?._id) {
      addDocumentInput.nexusDocumentTemplateId = templateDocument?._id;
    }

    // for (let obj of dataSourceObjects) {
    //   if (limitDates && startDate) {
    //     if (
    //       (obj.startDate && new Date(obj.startDate) < new Date(startDate)) ||
    //       (!obj.startDate && startDate)
    //     ) {
    //       obj.startDate = startDate;
    //     }
    //   }
    //   if (limitDates && endDate) {
    //     if (
    //       (obj.endDate && new Date(obj.endDate) > new Date(endDate)) ||
    //       (!obj.endDate && endDate)
    //     ) {
    //       obj.endDate = endDate;
    //     }
    //   }

    //   if (overrideStatus) {
    //     obj.status = status;
    //   }
    // }

    if (checkedOrderIds.length === 0 && !isSet) {
      enqueueSnackbar('Please select atleast one order.', {
        variant: 'error'
      });
      console.log('list too short');
      return;
    }

    if (
      limitDates &&
      overrideValues['startDate'] &&
      overrideValues['endDate'] &&
      new Date(overrideValues['startDate']) >
        new Date(overrideValues['endDate'])
    ) {
      enqueueSnackbar('End date must be later than or equal to start date', {
        variant: 'error'
      });
      console.log('End date must be later than or equal to start date');
      return;
    }

    // addDataSourcerelation({ variables: { input: addDocumentInput } });

    // handleClose();

    try {
      const res = await addDocument({
        variables: { input: addDocumentInput }
      });
      if (res?.data?.addDocument?.response === 'Success') {
        handleClose();
      } else {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar('Error, please contact support.', {
        variant: 'error'
      });
    }
  };

  const handleClose = () => {
    setOverrideValues({});
    setOverrideFields([]);
    setOpen(false);
    setDataSource();
    setCampaign({});
    setCheckedOrderIds([]);
    // setStartDate(new Date().toISOString().split('T')[0]);
    // setEndDate(new Date().toISOString().split('T')[0]);
    toggleDatePickerDisabled(true);
    setTypeItemToggledItem(null);
    setTypeItemAnchorRef(null);
  };

  const accountId =
    checkedBoxes && checkedBoxes[0] && checkedBoxes[0]?.accountId;

  const variables = {
    dataSourceInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountId,
      dataSourceId: dataSource?._id,
      showMore
    }
  };

  const [skip, toggleSkip] = useState(false);

  let query = '';

  let dataKey = '';
  console.log('Debug77>documentType: ', documentType);

  switch (documentType) {
    case 'googleAds':
      query = ListGoogleAdsCampaignsQuery;
      dataKey = 'listgoogleadscampaigns';
      break;
    case 'facebookAds':
      query = ListFacebookAdsCampaignsQuery;
      dataKey = 'listfacebookadscampaigns';
      break;
    case 'linkedInAds':
      query = ListLinkedInAdsCampaignGroupsQuery;
      dataKey = 'listlinkedinadscampaigngroups';
      break;
    case 'snapchatAds':
      query = ListSnapchatAdsCampaignsQuery;
      dataKey = 'listsnapchatadscampaigns';
      break;
    case 'tikTokAds':
      query = ListTikTokAdsCampaignsQuery;
      dataKey = 'listtiktokadscampaigns';
      break;
    case 'pinterestAds':
      query = ListPinterestAdsCampaignsQuery;
      dataKey = 'listpinterestadscampaigns';
      break;

    case 'amazonDsp':
      query = ListAmazonDspOrdersQuery;
      dataKey = 'listamazondsporders';
      break;

    case 'dv360':
      query = ListDV360InsertionOrdersQuery;
      dataKey = 'listdv360insertionorders';
      break;

    case 'cm360':
      query = ListCM360CampaignsQuery;
      dataKey = 'listcm360campaigns';
      break;

    case 'adform':
      query = ListAdformCampaignsQuery;
      dataKey = 'listadformcampaigns';
      break;
  }

  const { data, refetch, error } = useQuery(query, {
    variables,
    fetchPolicy: 'no-cache',
    skip
  });

  const listAddedDataSouceVariables = {
    input: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountId: checkedBoxes[0]?.accountId,
      dataSourceAccountId: dataSource?.dataSourceAccountId,
      dataSourceType: documentType
    }
  };

  // const [
  //   loadAddedDataSourceIds,
  //   { called: addedDataSourceIdsCalled, loading, data: addedDataSourceIdsData }
  // ] = useLazyQuery(listAddedDataSourceIdsQuery, {
  //   variables: listAddedDataSouceVariables,
  //   fetchPolicy: 'no-cache'
  // });

  console.log('Debug77>SelectSelector>data: ', data);
  console.log('Debug77>dataSource: ', dataSource);
  useEffect(() => {
    try {
      const mappedListOfDataCampaigns = data[dataKey]?.map((obj) => {
        return {
          ...obj
          //   id: obj.campaignId,
          //   name: `${obj.campaignName}`,
          //   startDate: obj.startDate ? obj.startDate : null,
          //   endDate: obj.endDate ? obj.endDate : null
        };
      });

      mappedListOfDataCampaigns.sort(function (a, b) {
        var nameA = new Date(a.startDate); // ignore upper and lowercase
        var nameB = new Date(b.startDate); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      // setListOfCampaigns(mappedListOfDataCampaigns);

      if (mappedListOfDataCampaigns.length > 0) {
        setListOfCampaigns(mappedListOfDataCampaigns);

        // toggleSkip(true);
      } else {
        setListOfCampaigns([]);
      }
    } catch (error) {}
  }, [data, dataSource]);

  const clickShowMoreOptionsHandler = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const [unAddedDataSources30Days, setUnAddedDataSources30Days] =
    useState(true);

  // useEffect(() => {
  //   loadAddedDataSourceIds();
  // }, [dataSource?.dataSourceAccountId]);

  // ############# list orders #################
  const listOrdersVariables = {
    input: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountId: checkedBoxes[0].accountId,
      dataSourceId: dataSource?._id,
      campaignIds: checkedCampaignIds
      // dataSourceType: documentType
    }
  };

  const [
    loadOrders,
    {
      called: listOrdersCalled,
      loading: listOrdersLoading,
      data: listOrdersData
    }
  ] = useLazyQuery(ListAdformOrdersQuery, {
    variables: listOrdersVariables,
    fetchPolicy: 'no-cache'
  });

  const loadListOfOrders = () => {
    if (checkedCampaignIds.length > 40) {
      enqueueSnackbar('Please select max 40 campaigns.', {
        variant: 'error'
      });
      return;
    }
    loadOrders();
  };

  useEffect(() => {
    try {
      console.log('listOrdersData: ', listOrdersData);
      const mappedListOfDataOrders = (
        listOrdersData?.listadformorders || []
      )?.map((obj) => {
        return {
          ...obj
          //   id: obj.campaignId,
          //   name: `${obj.campaignName}`,
          //   startDate: obj.startDate ? obj.startDate : null,
          //   endDate: obj.endDate ? obj.endDate : null
        };
      });

      mappedListOfDataOrders.sort(function (a, b) {
        var nameA = new Date(a.startDate); // ignore upper and lowercase
        var nameB = new Date(b.startDate); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      // setListOfOrders(mappedListOfDataCampaigns);

      if (mappedListOfDataOrders.length > 0) {
        setListOfOrders(mappedListOfDataOrders);

        // toggleSkip(true);
      } else {
        setListOfOrders([]);
      }
    } catch (error) {
      console.log('error: ', error);
      setListOfOrders([]);
    }
  }, [checkedCampaignIds, dataSource, listOrdersData]);
  console.log('listOfOrders: ', listOfOrders);

  // #######################

  useEffect(() => {
    if (
      // unAddedDataSources30Days &&
      dataSource &&
      listOfCampaigns
      // !addedDataSourceIdsCalled
    ) {
      // loadAddedDataSourceIds();

      if (
        listOfCampaigns
        //  && addedDataSourceIdsData?.listAddedDataSourceIds
      ) {
        var sixtyDaysAgo = new Date();
        sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
        console.log('sixtyDaysAgo: ', sixtyDaysAgo);

        setListOfCampaignsExAdded(
          listOfCampaigns.filter(
            (campaign) =>
              // !addedDataSourceIdsData?.listAddedDataSourceIds.includes(
              //   campaign.id
              // ) &&
              new Date(campaign.startDate) >= new Date(sixtyDaysAgo)
          )
        );
      }
      // console.log('addedDataSourceIdsData: ', addedDataSourceIdsData);
    } else if (
      // !unAddedDataSources30Days &&
      Boolean(listOfCampaignsExAdded.length)
    ) {
      setListOfCampaignsExAdded([]);
    }
  }, [
    // unAddedDataSources30Days,
    dataSource,
    // addedDataSourceIdsData?.listAddedDataSourceIds,
    listOfCampaigns
  ]);

  const [
    loadAccountToDataSourceSettings,
    {
      called: accountToDataSourceSettingsCalled,
      loading: loadingAccountToDataSourceSettings,
      data: accountToDataSourceSettingsData
    }
  ] = useLazyQuery(AccountToDataSourceSettingsQuery, {
    variables: {
      input: {
        managerAccountId: currentManagerAccountId,
        accountId: checkedBoxes?.[0]?.accountId,
        accountToDataSourceRelationId:
          dataSource?.accountToDataSourceRelationId,
        dataSourceId: dataSource?._id
      }
    },
    fetchPolicy: 'no-cache'
  });

  // const [acconuntToDataSourceSettings, setAccountToDataSourceSettings] =
  //   useState({});

  useEffect(() => {
    if (dataSource.accountToDataSourceRelationId) {
      loadAccountToDataSourceSettings();
    }
  }, [dataSource?.accountToDataSourceRelationId]);

  useEffect(() => {
    if (dataSource.accountToDataSourceRelationId) {
      // setAccountToDataSourceSettings(
      //   accountToDataSourceSettingsData?.accountToDataSourceSettings
      // );
      // setBudgetTypes(
      //   accountToDataSourceSettingsData?.accountToDataSourceSettings
      //     ?.defaultScanSettings?.budgetTypes
      // );
      // setCountries(
      //   accountToDataSourceSettingsData?.accountToDataSourceSettings
      //     ?.defaultScanSettings?.countries
      // );

      // ######################

      const defaultNexusDocumentValues =
        accountToDataSourceSettingsData?.accountToDataSourceSettings
          ?.defaultNexusDocumentValues;
      const fields =
        defaultNexusDocumentValues && Object.keys(defaultNexusDocumentValues);
      if (fields?.length) {
        const newOverrideFields = [];
        const newOverrideValues = {};
        for (let field of fields) {
          if (defaultNexusDocumentValues?.[field]) {
            newOverrideFields.push(field);
            newOverrideValues[field] = defaultNexusDocumentValues[field];
          }
        }
        setOverrideFields(newOverrideFields);
        setOverrideValues({
          ...essentialDefaultOverrideValues,
          ...newOverrideValues
        });
      }
    }
  }, [accountToDataSourceSettingsData, dataSource]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
        // gridTemplateRows: '500px auto 100px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
          // gridRowStart: 1,
          // gridRowEnd: 1
        }}
      >
        {['googleAds'].includes(documentType) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '42px',
              alignItems: 'center'
            }}
          >
            <Checkbox
              checked={isSet}
              onChange={() => toggleIsSet(!isSet)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span>
              Import a set of ALL campaigns from the selected data source.
            </span>
          </div>
        )}
        {!isSet && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                alignItems: 'center'
              }}
            >
              <Checkbox
                checked={unAddedDataSources30Days}
                onChange={() =>
                  setUnAddedDataSources30Days(!unAddedDataSources30Days)
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>
                Only show campaigns with start date less than 60 days ago.
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                alignItems: 'center'
              }}
            >
              <Checkbox
                checked={showMore}
                onChange={() => setShowMore(!showMore)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>Show more campaigns.</span>
            </div>
          </>
        )}

        {!isSet && dataSource && dataSource._id && (
          <div>
            <div
              style={{
                // border: '1px solid grey',
                marginTop: '20px',
                padding: '20px',
                borderRadius: '15px',
                background: 'rgba(200,200,200,0.25)'
              }}
            >
              <div>Select campaign</div>
              <Checklist
                list={
                  unAddedDataSources30Days
                    ? listOfCampaignsExAdded
                    : listOfCampaigns
                }
                checked={checkedCampaignIds}
                setChecked={setCheckedCampaignIds}
                isLoading={isLoading}
                toggleIsLoading={toggleIsLoading}
              />
            </div>
            <div
              style={{
                // border: '1px solid grey',
                marginTop: '20px',
                padding: '20px',
                borderRadius: '15px',
                background: 'rgba(200,200,200,0.25)'
              }}
            >
              <div>Select Orders</div>
              <div style={{ marginTop: '10px' }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={loadListOfOrders}
                >
                  Load orders
                </Button>
              </div>

              <Checklist
                list={listOfOrders}
                checked={checkedOrderIds}
                setChecked={setCheckedOrderIds}
                isLoading={isLoading}
                toggleIsLoading={toggleIsLoading}
              />
            </div>
          </div>
        )}

        {/* {['adform'].includes(documentType) && (
          <div style={{ marginTop: '40px' }}>
            <SelectFilter
              campaignFilter={campaignFilter}
              setCampaignFilter={setCampaignFilter}
            />
          </div>
        )} */}
      </div>
      <div style={{ gridRow: 2, minHeight: '90px' }}>
        <div style={{ width: '100%', margin: '50px 0px 0px 0px' }}>
          <SelectTemplate
            managerAccountId={currentManagerAccountId}
            accountId={checkedBoxes[0].accountId}
            value={templateDocument}
            setValue={setTemplateDocument}
            dataSource={dataSource}
          />
        </div>
        <ShowMoreOptions
          overrideFields={overrideFields}
          setOverrideFields={setOverrideFields}
          overrideValues={overrideValues}
          setOverrideValues={setOverrideValues}
          managerAccountId={currentManagerAccountId}
          accountId={checkedBoxes?.[0]?.accountId}
          dataSource={dataSource}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'end',
          // gridTemplateColumns: '50px 200px 200px 310px',
          gridRowStart: 3,
          gridRowEnd: 3,
          paddingTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <div
          // className={classes.root}
          style={{ gridColumn: 4, textAlign: 'center' }}
        >
          <Button
            variant="outlined"
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ minWidth: '91px' }}
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Adform;
