import React, { useContext, useEffect } from 'react';

import { AccountContext } from '../../context/AccountContext';
import { FilterContext } from '../../context/FilterContext';
import { InterfaceContext } from '../../context/InterfaceContext';
import { useNavigate, useSearchParams } from 'react-router-dom';

function SearchParametersHandler() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentManagerAccountId, currentAccountIds } =
    useContext(AccountContext);
  const { filterBySelection } = useContext(FilterContext);

  const { selectedDateStart, selectedDateStop, datePreset } =
    useContext(InterfaceContext);

  useEffect(() => {
    let newSearchParams = {
      ...(searchParams.entries() || []),
      //   account_ids: JSON.stringify(currentAccountIds),
      manager_account_id: currentManagerAccountId
      //   document_ids: JSON.stringify(filterBySelection)

      // start_date: searchParams.get('start_date'),
      // end_date: searchParams.get('end_date')
    };
    if (currentAccountIds?.length) {
      newSearchParams.account_ids = encodeURIComponent(currentAccountIds);
    }
    if (filterBySelection?.length) {
      // console.log('filterBySelection: ', filterBySelection);
      // console.log('filterBySelection?.length: ', filterBySelection?.length);
      newSearchParams.document_ids = encodeURIComponent(filterBySelection);
    }
    if (selectedDateStart) {
      newSearchParams.start_date = selectedDateStart;
    }
    if (selectedDateStop) {
      newSearchParams.end_date = selectedDateStop;
    }

    if (datePreset) {
      newSearchParams.date_preset = datePreset;
    }

    // console.log('SearchParametersHandler>datePreset: ', datePreset);

    // let searchParamKeys = [
    //   // 'manager_account_id',
    //   'start_date',
    //   'end_date'
    // ];
    // for (let key of searchParamKeys) {
    //   if (searchParams.get(key)) {
    //     newSearchParams[key] = searchParams.get(key);
    //   }
    // }
    // if (currentAccountIds.length) {
    //   newSearchParams.account_ids = JSON.stringify(currentAccountIds);
    // }
    // console.log('searchParams.toString(): ', searchParams.toString());

    setSearchParams(newSearchParams);
  }, [
    currentAccountIds,
    filterBySelection,
    selectedDateStart,
    selectedDateStop
  ]);
  return <div></div>;
}

export default SearchParametersHandler;
