import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import LabelIcon from '@mui/icons-material/Label';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

import { accountLabelsQuery } from './../../../graphql/queries';

import { useQuery } from '@apollo/client';

import { AuthContext } from '../../../context/AuthContext';
import { AccountContext } from '../../../context/AccountContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'center',
//     flexWrap: 'wrap',
//     '& > *': {
//       margin: theme.spacing(0.5)
//     }
//   }
// }));

export default function Chips({
  filterByLabelId,
  setFilterBySelection,
  setFilters,
  setCheckedBoxes,
  currentManagerAccountId,
  // user,
  filters,
  setFilterByLabelId
}) {
  const { user } = useContext(AuthContext);

  const { currentAccountIds, accounts } = useContext(AccountContext);

  // const classes = useStyles();

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
    // setFilterBySelection([]);

    const filteredFilter = filters.filter((filter) => {
      return filter !== 'filter_by_label';
    });
    setFilters(filteredFilter);
    setCheckedBoxes([]);
  };

  const variables = {
    accountsLabelsInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds
    }
  };

  const { data } = useQuery(accountLabelsQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: false
  });

  // const handleClick = () => {
  //   console.info('You clicked the Chip.');
  // };

  // ############################ autocomplete #####################################

  const [showAutoComplete, toggleShowAutoComplete] = useState(false);

  const [accountLabels, setAccountLabels] = useState([]);

  useEffect(() => {
    if (data && data.accountLabels) {
      const labelList = [...data.accountLabels];
      labelList.sort(function (a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      const labelListWithAccountNameInLabelName = labelList.map((label) => {
        let accountName = '?';
        const account = accounts.filter((acc) => acc._id === label.accountId);
        accountName = account[0]?.name;

        const newName = `${label?.name} (${accountName})`;
        return { ...label, name: newName };
      });

      setAccountLabels(labelListWithAccountNameInLabelName);
    }
  }, [data]);

  // const [value, setValue] = React.useState(null);
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    if (accountLabels && filterByLabelId) {
      const filteredLabel = accountLabels.filter(
        (obj) => obj._id === filterByLabelId
      )[0];
      if (filteredLabel && filteredLabel._id !== value?._id) {
        setValue(filteredLabel);
      }
    } else if (!value && !filterByLabelId) {
      // setFilterByLabelId(user._id);
      // setValue(user);
    }
  }, [accountLabels, filterByLabelId]);

  const defaultProps = {
    options: accountLabels,
    getOptionLabel: (option) => option.name
  };

  const onChangeHandler = (event, newValue) => {
    setValue(newValue);
    if (newValue && newValue._id) {
      setFilterByLabelId(newValue._id);
    }
  };

  const autoComplete = (
    <Stack spacing={0} sx={{ width: 200 }}>
      <Autocomplete
        {...defaultProps}
        id="controlled-demo"
        className="filter-by-label"
        value={value}
        onChange={(event, newValue) => {
          onChangeHandler(event, newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="controlled"
            variant="standard"
          />
        )}
      />
    </Stack>
  );

  // ##################################################################################

  return (
    <div
      // className={classes.root}
      style={{ gridRow: 1 }}
    >
      <Chip
        icon={<LabelIcon />}
        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
        // label="Me"
        label={autoComplete}
        onDelete={handleDelete}
        // label="Primary clickable"
        // clickable
        // deleteIcon={<DoneIcon />}
      />
    </div>
  );
}
