import React, { useState, useContext, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useMutation } from '@apollo/client';

import { UpdateUserMutation } from '../../../../graphql/queries';

import { AuthContext } from '../../../../context/AuthContext';

import { AccountContext } from '../../../../context/AccountContext';
import { useSnackbar } from 'notistack';

import BedtimeIcon from '@mui/icons-material/Bedtime';

export default function IsSmsWarningsDelayedOnWeekendsSwitch({
  userInfoAndAccounts,
  refetchUserInfo
}) {
  const { user } = useContext(AuthContext);

  const { currentManagerAccount } = useContext(AccountContext);

  const [isSmsWarningsDelayedOnWeekends, setIsSmsWarningsDelayedOnWeekends] =
    useState(false);

  useEffect(() => {
    setIsSmsWarningsDelayedOnWeekends(
      userInfoAndAccounts?.isSmsWarningsDelayedOnWeekends
    );
  }, [userInfoAndAccounts]);

  // const classes = useStyles();

  const [updateUser, { called }] = useMutation(UpdateUserMutation);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (e) => {
    if (userInfoAndAccounts?._id !== user?._id) {
      enqueueSnackbar('You can only change this value for yourself', {
        variant: 'error'
      });
      return;
    }
    const input = {
      managerAccountId: currentManagerAccount._id,
      updateKeys: ['isSmsWarningsDelayedOnWeekends'],
      updateValues: {
        isSmsWarningsDelayedOnWeekends: !isSmsWarningsDelayedOnWeekends
      }
    };

    let res;
    try {
      res = await updateUser({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.updateUser?.response === 'success') {
      enqueueSnackbar('Saved', {
        variant: 'success'
      });
      refetchUserInfo();
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px'
      }}
    >
      <FormControlLabel
        control={
          <Switch
            disabled={userInfoAndAccounts?._id !== user?._id}
            checked={isSmsWarningsDelayedOnWeekends}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={
          <span>
            SMS warnings delayed on weekends
            <BedtimeIcon style={{ marginLeft: '10px', fontSize: '14px' }} />
          </span>
        }
      />
    </div>
  );
}
