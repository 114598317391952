import React, { useContext, useEffect, useState } from 'react';

import { Button } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import { ReportBuilderContext } from '../../../../../../context/ReportBuilderContext';

function RemoveMetricButton({ widget, metric }) {
  const { updateWidget } = useContext(ReportBuilderContext);

  const onClickHandler = () => {
    const copyOfWidget = { ...widget };

    let copyOfMetrics = [...(widget?.metrics || [])];

    copyOfMetrics = copyOfMetrics.filter(
      (metricObj) => metricObj.metric !== metric
    );
    copyOfWidget.metrics = copyOfMetrics;

    updateWidget(copyOfWidget);
  };
  return (
    <Button
      onClick={onClickHandler}
      style={{
        minWidth: '40px',
        width: '40px',
        minHeight: '40px',
        height: '40px'
      }}
    >
      <ClearIcon />
    </Button>
  );
}

export default RemoveMetricButton;
