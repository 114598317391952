import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import CommentIcon from '@mui/icons-material/Comment';

function ReactionBar({ reactions }) {
  const hearts = reactions?.filter((obj) => obj.reaction === 'HEART');
  const thumbUps = reactions?.filter((obj) => obj.reaction === 'THUMBUP');

  return (
    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
      {Boolean(hearts.length) && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={
              <ul>
                {hearts.map((reaction) => (
                  <li>
                    {reaction?.userId?.firstName +
                      ' ' +
                      reaction?.userId?.lastName}
                  </li>
                ))}
              </ul>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '3px'
              }}
            >
              <span style={{ fontSize: '12px' }}>{hearts.length}</span>
              <FavoriteIcon style={{ color: 'red', fontSize: '16px' }} />
            </div>
          </Tooltip>
        </div>
      )}
      {Boolean(thumbUps.length) && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={
              <ul>
                {thumbUps.map((reaction) => (
                  <li>
                    {reaction?.userId?.firstName +
                      ' ' +
                      reaction?.userId?.lastName}
                  </li>
                ))}
              </ul>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '3px'
              }}
            >
              <span style={{ fontSize: '12px' }}>{thumbUps.length}</span>
              <ThumbUpIcon style={{ fontSize: '16px' }} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default ReactionBar;
