import { documentTypeToShortNameDict } from './../../components/tableV4/report-builder-2/report-builder-content/dictionary';

import formatDateYYYYMMDD from '../../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../components/tableV4/fields';

import { prepDataObject } from '../../components/tableV4/report-builder-2/report-builder-content/widgets/prepDataObject';

export const tableChart = (
  widget,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData
) => {
  let widgetGroupByKey = '';

  switch (widget.dimensions[0]) {
    case 'documentType':
      widgetGroupByKey = 'documentType';
      break;
    case 'name':
      widgetGroupByKey = 'name';
      break;
  }
  const testGroupBy = Object.groupBy(
    listOfFilteredDataSourceRows,
    (row) => row[widgetGroupByKey]
  );

  // console.log('PieChartWidget>testGroupBy: ', testGroupBy);

  const dimensionKeys = Object.keys(testGroupBy);

  const newSeriesData = [];
  for (let dimensionKey of dimensionKeys) {
    let dimensionsValueObject = {};

    for (let row of testGroupBy[dimensionKey]) {
      for (let metric of [...sumEvalFields, ...plannedEvalFields]) {
        if (nonSumEvalFields.includes(metric)) {
          continue;
        }

        dimensionsValueObject[metric] =
          (dimensionsValueObject[metric] || null) + (row[metric] || null);
      }
    }
    let seriesData = {
      // label: documentTypeToNameDict[dimensionKey],
      ...dimensionsValueObject
    };

    switch (widgetGroupByKey) {
      case 'documentType':
        seriesData.label = documentTypeToShortNameDict?.[dimensionKey];
        break;

      case 'name':
        seriesData.label = dimensionKey;
        break;
    }

    let addSeries = false;
    for (let metric of [...sumEvalFields, ...plannedEvalFields] || []) {
      if (seriesData[metric]) {
        addSeries = true;
      }
      // if (!nonSumEvalFields.includes(metric)) {
      //   continue;
      // }
      // switch (true) {
      //   case ['cpc', 'cpv', 'cpa', 'roas'].includes(metric):
      //     seriesData[metric] = Math.round(seriesData[metric]);
      //     break;
      //   // case ['ctr'].includes(metric):
      //   //   break;
      //   default:
      //     seriesData[metric] = Math.round(seriesData[metric]);
      // }
    }
    if (addSeries) {
      // seriesData.cpm =
      //   seriesData.impressions &&
      //   (seriesData.cost || 0) / (seriesData.impressions / 1000);

      // seriesData.cpc =
      //   seriesData.clicks && (seriesData.cost || 0) / seriesData.clicks;

      seriesData = prepDataObject(seriesData);

      newSeriesData.push(seriesData);
    }
  }
  // console.log('newSeriesData: ', newSeriesData);
  // let totalValueObject = 0;
  // for (let row of newSeriesData) {
  //   for (let metric of widget.metrics) {
  //     totalValueObject[metric] += row?.[metric] || null;
  //   }
  // }

  // for (let row of newSeriesData) {
  //   // row.share = Math.round((row.value / totalValue) * 100);
  //   row.value = Math.round(row.value);
  // }
  // console.log('totalValueObject: ', totalValueObject);
  const fontSize = 14;
  const columnWidths = {};
  for (let dataObj of newSeriesData) {
    for (let key of Object.keys(dataObj)) {
      console.log(
        'String(columnWidths[key]).length * fontSize: ',
        String(columnWidths[key]).length * fontSize
      );
      columnWidths[key] =
        (String(columnWidths[key]).length * fontSize || key.length * fontSize) <
        String(dataObj[key]).length * fontSize
          ? String(dataObj[key]).length * fontSize
          : String(columnWidths[key]).length * fontSize ||
            key.length * fontSize;
    }
  }
  console.log('columnWidths: ', columnWidths);
  let copyOfWidget = { ...widget };
  copyOfWidget = {
    ...copyOfWidget,
    data: newSeriesData,
    columnWidths
  };

  // updateWidget(copyOfWidget, widgetIndex);

  console.log('copyOfWidget: ', copyOfWidget);

  // console.log('PieChartWidget>widget: ', widget);
  // setSeriesData([...newSeriesData]);
  // setArrayOfDataInCronologicalOrder([...newSeriesData]);
  return copyOfWidget;
};
