import React from 'react';

import { Avatar } from '@mui/material';

import ChildComment from './ChildComment';

const createNameAbb = (name) => {
  let nameAbb = '';

  const nameParts = name.split(' ');

  for (let i = 0; i < nameParts.length; i++) {
    nameAbb = nameAbb + nameParts[i][0];
  }
  return nameAbb;
};

function ChildComments({ commentObj }) {
  return (
    <div
      className="child-comment-wrapper"
      style={{
        width: '100%',
        // padding: '5px 0px 0px 60px',
        // padding: '5px',
        // borderRadius: '5px',
        fontSize: '12px'
      }}
    >
      {commentObj.childComments.map((comment) => {
        // console.log('ChildComments>comment: ', comment);
        return (
          <div
            key={comment._id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyItems: 'center'
            }}
          >
            <ChildComment commentObj={comment} />
          </div>
        );
      })}
    </div>
  );
}

export default ChildComments;
