import React from 'react';

function DefaultItem({
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  checked,
  item
}) {

  const percentage1 = item[itemKey]; 

  return (
    <div
      key={keyForMap}
      style={{
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'grid',
        height: '100%',
        width: '100%',
        // padding: '12px 15px',
        // padding: '1px',
        whiteSpace: 'nowrap'
      }}
    >
      <div style={{
        // backgroundColor: 'green',
        // border: '1.5px solid #BD10E0',
        // width: percentage1+'%',
        height: '16px',
        margin: '4.5px 4.5px 3px'
        // borderRadius: '4px'
      }}>
        <div style={{fontSize: '10px', position: 'absolute', padding: '0px 3px'}}>budget {percentage1 + "%"} </div>
        <div style={{
          width: '100%',
          height: '100%',
          background: '#4A90E2',
          // border: '1.5px solid #BD10E0',
          width: percentage1+'%',
          borderRadius: '2px'
        }}></div>

      </div>
      <div style={{
        // backgroundColor: 'green',
        // border: '1.5px solid #BD10E0',
        // width: percentage1-10+'%',
        height: '16px',
        margin: '0px 4.5px 3px',
        // borderRadius: '4px'
      }}>
        <div style={{fontSize: '10px', position: 'absolute', padding: '0px 3px'}}><span style={{margin: 'auto'}}>budget spent</span></div>
        <div style={{
          width: '100%',
          height: '100%',
          background: '#4A90E2',
          // border: '1.5px solid #BD10E0',
          width: percentage1+'%',
          borderRadius: '2px'
        }}></div>
        

      </div>
    </div>
  );
}

export default DefaultItem;
