import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { AccountContext } from './../../../context/AccountContext';
import { DialogContext } from './../../../context/DialogContext';

import CloseIcon from '@mui/icons-material/Close';

import CopyDialogContent from './CopyDialogContent';

import { MutationContext } from '../../../context/MutationContext';

import { FilterContext } from '../../../context/FilterContext';

import { InterfaceContext } from '../../../context/InterfaceContext';

import { useTheme } from '@emotion/react';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        style={{ width: '700px', maxWidth: '100vw', maxHeight: '90%' }}
        {...props}
      />
    </Draggable>
  );
}

const CopyModal = (
  {
    // checkedBoxes,
    // copiedDocumentIds,
    // setCopiedDocumentIds,
    // copiedDocumentsAccountId,
    // setCopiedDocumentsAccountId
  }
) => {
  const theme = useTheme();
  const { currentManagerAccount } = useContext(AccountContext);

  const { checkedBoxes } = useContext(FilterContext);

  const {
    copiedDocumentIds,
    setCopiedDocumentIds,
    copiedDocumentsAccountId,
    setCopiedDocumentsAccountId
  } = useContext(InterfaceContext);

  const { copyDocument, copyOrDuplicateAction, setCopyOrDuplicateAction } =
    useContext(MutationContext);

  const [scrollTop, setScrollTop] = useState(0);
  const { copyDialogOpen: open, setCopyDialogOpen: setOpen } =
    useContext(DialogContext);

  const handleClose = () => {
    setOpen(false);
  };

  // const body = <div style={{ display: 'flex', flexDirection: 'column' }}></div>;

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            width: '700px',
            maxWidth: '100vw',
            maxHeight: '90%',
            backgroundImage:
              theme?.palette?.mode === 'dark' &&
              'linear-gradient(to top, rgb(55, 55, 66), rgb(75, 75, 86))'
          }
        }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>
              {copyOrDuplicateAction === 'COPY'
                ? 'Copy and Paste'
                : 'Duplicate'}
            </div>

            <div
              style={{
                cursor: 'pointer',
                right: '0px',
                textAlign: 'right'
                // paddingRight: '25px'
              }}
            >
              <span style={{ width: '50px' }} onClick={handleClose}>
                <CloseIcon className="hover-light-grey" />
              </span>
            </div>
          </div>
          <div style={{ fontSize: '12px', padding: '0px 5px' }}></div>
        </DialogTitle>
        <DialogContent>
          <CopyDialogContent
            open={open}
            setOpen={setOpen}
            checkedBoxes={checkedBoxes}
            copiedDocumentIds={copiedDocumentIds}
            setCopiedDocumentIds={setCopiedDocumentIds}
            copiedDocumentsAccountId={copiedDocumentsAccountId}
            setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

CopyModal.propTypes = {
  // style: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  stylesStoredInRedux: state.style
});

export default connect(mapStateToProps, {})(CopyModal);
