import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorageIcon from '@mui/icons-material/Storage';

import { addCustomDataSourceMutation } from './../../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import CreateCustomDataSourceButton from './CreateCustomDataSourceButton';

// import googleAdsLogo from './ads-logo-horizontal-white.png';

import { AccountContext } from '../../../../../../context/AccountContext';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ height: '500px', width: '700px' }} />
    </Draggable>
  );
}

export default function DraggableDialog({
  open,
  setOpen,
  refetchAccountList
  // currentManagerAccount
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [name, setName] = useState('');
  //   const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [addCustomDataSource, { called }] = useMutation(
    addCustomDataSourceMutation
  );

  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const onClickHandler = async (e, dataSource) => {
    const addCustomDataSourceInput = {
      managerAccountId: currentManagerAccount._id,
      name
    };

    const res = await addCustomDataSource({
      variables: { input: addCustomDataSourceInput }
    });

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const content = (
    <div>
      <form noValidate autoComplete="off">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '30px',
            justifyContent: 'space-between',
            height: '400px'
          }}
        >
          <div
            style={{
              // display: 'grid',
              // gridRowStart: 1,
              // gridRowEnd: 1,
              // gridColumnStart: 1,
              // gridColumnEnd: 1,
              justifyContent: 'left',
              marginTop: '20px'
            }}
          >
            <p>
              {' '}
              <strong>
                Create a custom data source to upload your data with csv or
                excel.
              </strong>
            </p>
            <br />
            {/* <p>
              {' '}
              When you click the button "Login with LinkedIn" you will be
              redirected to LinkedIn where you will be able to login and accept
              that NexusTable get access to your ads data.
            </p>
            <br />
            <p>
              Once you accept the connection you will be redirected back to
              NexusTable where you will be able to choose which LinkedIn Ads
              accounts you wish us to add as a connection. The accounts'
              performance data will be downloaded frequently and stored in our
              database.
            </p> */}
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}
          >
            <div>
              <TextField
                style={{ width: '350px' }}
                id="outlined-name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'grid',
                gridRowStart: 2,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 1,
                justifyContent: 'center'
                // marginTop: '70px'
              }}
            >
              <CreateCustomDataSourceButton onClickHandler={onClickHandler} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <StorageIcon
            style={{
              verticalAlign: 'text-bottom',
              paddingBottom: '1px',
              fontSize: '24px'
            }}
          />{' '}
          <span style={{ marginLeft: '3px' }}>Create a Custom Data Source</span>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </div>
  );
}
