import React, { useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TimeboxContextMenuContent from './TimeboxContextMenuContent';

export default function BasicPopover({
  contextMenuAnchorPosition: contextMenuAnchorPosition,
  setContextMenuAnchorPosition: setContextMenuAnchorPosition,
  mouseDownTimeboxIds,
  selectedUser,
  timeboxIdThatTriggeredContextMenu,
  setTimeboxIdThatTriggeredContextMenu,
  timeboxes
}) {
  //   const [anchorEl, setAnchorEl] = React.useState(null);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  const handleClose = () => {
    setContextMenuAnchorPosition(null);
  };

  const open = Boolean(contextMenuAnchorPosition);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!open) {
      setTimeboxIdThatTriggeredContextMenu(null);
    }
  }, [open]);

  return (
    <div>
      <Popover
        id={id}
        open={open}
        // anchorEl={anchorEl}
        anchorPosition={contextMenuAnchorPosition}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <TimeboxContextMenuContent
          mouseDownTimeboxIds={mouseDownTimeboxIds}
          selectedUser={selectedUser}
          timeboxIdThatTriggeredContextMenu={timeboxIdThatTriggeredContextMenu}
          setTimeboxIdThatTriggeredContextMenu={
            setTimeboxIdThatTriggeredContextMenu
          }
          timeboxes={timeboxes}
          setContextMenuAnchorPosition={setContextMenuAnchorPosition}
        />
      </Popover>
    </div>
  );
}
