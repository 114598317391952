import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectVariants({ index, column, columns, setColumns }) {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    const copyOfColumns = [...columns];
    copyOfColumns[index] = event.target.value;
    setColumns(copyOfColumns);
  };

  return (
    <div
      style={{
        width: '100%',
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={columns[index]}
          onChange={handleChange}
          label="Age"
          size="small"
          style={{ width: '100%', minWidth: '100%' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'campaignName'}>Campaign Name</MenuItem>
          <MenuItem value={'campaignId'}>Campaign ID</MenuItem>
          <MenuItem value={'date'}>Date</MenuItem>
          <MenuItem value={'cost'}>Cost</MenuItem>
          <MenuItem value={'impressions'}>Impressions</MenuItem>
          <MenuItem value={'clicks'}>Clicks</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
