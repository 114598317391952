import React, { useState, useEffect, useContext } from 'react';

import PopContent from './PopContent';

import { useInView } from 'react-intersection-observer';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';

export default function BasicPopover({
  currentManagerAccountId,
  updateDocument,
  item,
  // itemStyle,

  itemKey,
  checked,
  popperPosition,
  document,
  setDocument,
  disabled
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setOpen = (open) => {
    if (!open) {
      setAnchorEl(null);
    }
  };

  const itemValue = item[itemKey];

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        disabled={disabled}
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        style={{ maxWidth: '40px', minWidth: '40px' }}
      >
        <SettingsIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <PopContent
          setOpen={setOpen}
          item={item}
          itemKey={itemKey}
          currentManagerAccountId={currentManagerAccountId}
          document={document}
          setDocument={setDocument}
        />
      </Popover>
    </div>
  );
}
