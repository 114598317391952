
const Joi = require('@hapi/joi');

const dateSchema = Joi.object({
    date: Joi.string()
      .pattern(
        new RegExp('^([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))$')
      )
      .required()
  });

module.exports = DateSchema = dateSchema;