import { minHeight } from '@mui/system';
import React, { useEffect, useState } from 'react';

import SelectColumn from './SelectColumn';
import { Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import RemoveButton from './RemoveButton';

import parseExcel from './parseExcel';

function DataEditor({
  excelData,
  setExcelData,
  newNexusDocuments,
  setNewNexusDocuments
}) {
  const [sheetName, setSheetName] = useState('');

  const [columns, setColumns] = useState([]);
  // const [newNexusDocuments, setNewNexusDocuments] = useState([]);

  const [headers, setHeaders] = useState([]);

  const [excelDataParsedToTableFormat, setExcelDataParsedToTableFormat] =
    useState([]);
  useEffect(() => {
    if (!columns.length && excelData && excelData[sheetName]) {
      const cols = excelData[sheetName][0]?.map((cell) => null);
      setColumns(cols);
    }
  }, [excelData, sheetName]);
  //   console.log('columns: ', columns);

  //   console.log('DataEditor>excelData: ', excelData);

  //   console.log('excelData[sheetName]: ', excelData[sheetName]);

  useState([]);
  console.log('sheetName: ', sheetName);

  useEffect(async () => {
    if (excelData && sheetName && excelData[sheetName].length) {
      if (!newNexusDocuments.length) {
        const nexusDocs = await parseExcel(excelData[sheetName]);
        console.log('nexusDocs: ', nexusDocs);
        setNewNexusDocuments(nexusDocs);
      }
    }
  }, [excelData, sheetName]);
  useEffect(async () => {
    let nexusDocuments = [];
    const getDocuments = async (documents) => {
      console.log('documents123: ', documents);
      for (let document of documents) {
        nexusDocuments.push(document);
        if (document?.nexusDocuments?.length) {
          await getDocuments(document.nexusDocuments);
        }
      }

      // nexusDocuments = [...nexusDocuments, ...documents];
    };

    await getDocuments([newNexusDocuments]);
    console.log('nexusDocuments row 59: ', nexusDocuments);
    const headers_ = ['name'];
    for (let doc of nexusDocuments) {
      const copyOfDoc = { ...doc };
      delete copyOfDoc.nexusDocuments;
      for (let key in copyOfDoc) {
        if (!headers_.includes(key) && key !== 'nexusDocuments') {
          headers_.push(key);
        }
      }
    }

    setHeaders(headers_);
    console.log('nexusDocuments: ', nexusDocuments);
    setExcelDataParsedToTableFormat(nexusDocuments);
  }, [newNexusDocuments]);

  console.log('newNexusDocuments: ', newNexusDocuments);

  console.log('headers: ', headers);

  // const onRemoveColumn = (index) => {
  //   console.log('onRemoveColumn');
  //   const copyOfExcelData = { ...excelData };
  //   const copyOfSheet = [...excelData[sheetName]];
  //   for (let row of copyOfSheet) {
  //     console.log('row[index]: ', row[index]);
  //     row.splice(index, 1);
  //   }
  //   const copyOfColumns = [...columns];
  //   copyOfColumns.splice(index, 1);
  //   setColumns(copyOfColumns);
  //   copyOfExcelData[sheetName] = copyOfSheet;
  //   setExcelData(copyOfExcelData);
  // };

  // const onRemoveRow = (index) => {
  //   const copyOfExcelData = { ...excelData };
  //   const copyOfSheet = [...excelData[sheetName]];

  //   copyOfSheet.splice(index, 1);
  //   copyOfExcelData[sheetName] = copyOfSheet;
  //   setExcelData(copyOfExcelData);
  // };

  console.log('excelDataParsedToTableFormat: ', excelDataParsedToTableFormat);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '400px',
        minHeight: '400px'
      }}
    >
      {!sheetName && (
        <div>
          <div style={{ marginBottom: '20px', marginLeft: '13px' }}>
            Please select sheet:{' '}
          </div>
          {Object.keys(excelData).map((sheetName) => (
            <span
              style={{ cursor: 'pointer', padding: '5px' }}
              onClick={() => setSheetName(sheetName)}
            >
              <Button>{sheetName}</Button>
            </span>
          ))}
        </div>
      )}
      <div style={{ fontSize: '10px', marginTop: '50px' }}>
        {excelData[sheetName] && (
          <table
            style={{
              borderCollapse: 'collapse'
              // border: '1px solid black'
            }}
          >
            {/* <tr>
              <td></td>
              {columns.map((col, index) => (
                <td
                  style={{ cursor: 'pointer' }}
                  //   onClick={() => onRemoveColumn(index)}
                >
                  <SelectColumn
                    index={index}
                    column={col}
                    columns={columns}
                    setColumns={setColumns}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td></td>
              {excelData[sheetName][0].map((col, index) => (
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => onRemoveColumn(index)}
                >
                  <RemoveButton />
                </td>
              ))}
            </tr> */}

            <tr>
              {/* <td onClick={() => onRemoveRow(index)}>
                      <RemoveButton />
                    </td> */}
              {(headers || []).map((header) => (
                <td
                  style={{
                    border: '1px solid #bcbcbc',
                    // borderTop: '1px solid #bcbcbc',
                    // borderRight: '1px solid #bcbcbc',
                    lineBreak: 'strict',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    padding: '7px'
                  }}
                >
                  {String(header)}
                </td>
              ))}
            </tr>
            {(excelDataParsedToTableFormat || []).map((doc, index) => (
              <tr>
                {(headers || []).map((header) => (
                  <td
                    style={{
                      border: '1px solid #bcbcbc',
                      // borderTop: '1px solid #bcbcbc',
                      // borderRight: '1px solid #bcbcbc',
                      lineBreak: 'strict',
                      whiteSpace: 'nowrap',
                      padding: '5px',
                      textAlign: typeof doc[header] === 'number' && 'end'
                    }}
                  >
                    {doc[header]}
                  </td>
                ))}
              </tr>
            ))}
          </table>
        )}
      </div>
    </div>
  );
}

export default DataEditor;
