import React, { useState, useEffect, useContext } from 'react';
import Linkify from 'react-linkify';

import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import EditIcon from '@mui/icons-material/Edit';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { addCommentMutation } from './../../../../../graphql/queries';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import CommentToolTip from './CommentToolTip';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../context/AccountContext';
import { AuthContext } from '../../../../../context/AuthContext';

import { FilterContext } from '../../../../../context/FilterContext';

import { styled, useTheme } from '@mui/material/styles';

//##############################################

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

// #####################################################

const createNameAbb = (name) => {
  let nameAbb = '';

  const nameParts = name.split(' ');

  for (let i = 0; i < nameParts.length; i++) {
    nameAbb = nameAbb + nameParts[i][0];
  }
  return nameAbb;
};

function Comment({ item, commentObj, onClickPathHandler }) {
  const { currentManagerAccount, currentManagerAccountId } =
    useContext(AccountContext);
  const { user } = useContext(AuthContext);
  // const { filters, setFilters, filterBySelection, setFilterBySelection } =
  //   useContext(FilterContext);
  // console.log('commentObj: ', commentObj);

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  // user._id === commentObj.user.userId

  const [edit, toggleEdit] = useState(false);
  const [input, setInput] = useState(
    commentObj.text[commentObj.text.length - 1].text
  );

  useEffect(() => {
    setInput(commentObj.text[commentObj.text.length - 1].text);
  }, [commentObj]);

  const onClickEditHandler = () => {
    toggleEdit(true);
  };

  const onChangeHandler = (e) => {
    setInput(e.target.value);
    // scrollToBottom();
  };

  const onKeyUpHandler = (e) => {
    if (e.keyCode === 13) {
      // console.log('onEnter: ', e.target.value);
    }
  };

  const [addComment, { called }] = useMutation(addCommentMutation);

  // const onClickPathHandler = (obj) => {
  //   console.log('Card>obj: ', obj);
  //   // console.log('filters: ', filters);
  //   setFilterBySelection([obj]);
  //   if (!filters.includes('filter_by_selection')) {
  //     setFilters([...filters, 'filter_by_selection']);
  //   }
  // };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    // const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ\n _,.-]*$');
    // const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ\n _.,+-/\\*()=:]*$');
    const regExName = new RegExp(
      '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ\n\t -_.,+-/\\*()=:#%&:]*$'
    );

    const regExNameResult = regExName.exec(input);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ _.,+-/*()=: are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    const finalInput = {
      action: 'edit_comment',
      managerAccountId: currentManagerAccount?._id,
      accountId: item.accountId,
      documentId: item._id,
      level: item.level,
      documentType: item.documentType,
      newsFeedItemDocumentType: 'comment',
      comment: {
        documentId: item._id,
        text: input ? input : ' '
      },
      commentId: commentObj._id
    };

    if (input !== commentObj.text[commentObj.text.length - 1].text) {
      addComment({ variables: { addCommentInput: finalInput } });
    }

    // setInput('');
    toggleEdit(false);
  };

  const onClickCancelHandler = () => {
    toggleEdit(false);
    setInput(commentObj.text[commentObj.text.length - 1].text);
  };

  const textField = (
    <form onSubmit={(e) => onSubmitHandler(e)}>
      <TextField
        autoFocus
        onKeyUp={(e) => onKeyUpHandler(e)}
        onChange={(e) => onChangeHandler(e)}
        style={{ width: '100%', fontSize: '14px' }}
        id="outlined-multiline-static"
        // label="Multiline - type here!"
        multiline
        value={input}
        //   rows={4}
        //   defaultValue="Default Value"
        default={input}
        variant="outlined"
        inputProps={{ style: { fontSize: 16 } }}
        InputLabelProps={{ style: { fontSize: 16 } }}
      />
      <div style={{ width: '100%', textAlign: 'right' }}>
        <Button
          size="small"
          style={{ marginTop: '20px', marginRight: '5px' }}
          variant="outlined"
          onClick={onClickCancelHandler}
        >
          Cancel
        </Button>
        <Button
          size="small"
          style={{ marginTop: '20px' }}
          type="submit"
          variant="outlined"
        >
          Submit
        </Button>
      </div>
    </form>
  );

  return (
    <div
      key={commentObj._id}
      style={{
        // borderRadius: '5px',
        // border: '1px solid grey',
        // margin: '0px 20px 20px 20px',
        // padding: '10px',
        display: 'grid',
        gridTemplateColumns: '30px auto',
        gridTemplateRows: '20px auto auto'
      }}
    >
      <div
        style={{
          display: 'grid',
          gridColumnStart: 1,
          gridColumnEnd: 1,
          gridRowStart: 1,
          gridRowEnd: 2
        }}
      >
        <Avatar
          style={{
            height: '35px',
            width: '35px',
            fontSize: '12px',
            color: 'white',
            background: `
    linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
    linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
    linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)
    `
          }}
        >
          {commentObj.user &&
            commentObj.user.name &&
            createNameAbb(commentObj.user.name)}
        </Avatar>
      </div>
      <div
        style={{
          // display: 'grid',
          gridColumnStart: 2,
          gridColumnEnd: 2,
          gridRowStart: 1,
          gridRowEnd: 1,
          margin: '0px 0px 0px 20px',
          padding: '0px 0px 0px 10px'
        }}
      >
        <span>
          {commentObj.user && commentObj.user.name && commentObj.user.name}
        </span>
        <span
          style={{
            marginLeft: '15px',
            fontSize: '10px',
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,1)'
          }}
        >
          {commentObj.date &&
            new Date(parseInt(commentObj.date)).toLocaleString()}
        </span>
        <span
          className="hide-in-mobile"
          style={{
            marginLeft: '15px',
            // fontSize: '10px',
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,1)'
          }}
        >
          {/* <ChangeHistoryIcon style={{ fontSize: '10px' }} /> */}
          <CommentToolTip commentVersions={commentObj.text} />
        </span>
        <span
          style={{
            marginLeft: '15px',
            // fontSize: '10px',
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,1)'
          }}
        >
          {user._id === commentObj.user._id && (
            <EditIcon
              className="hover-grey hide-in-mobile"
              style={{ fontSize: '10px', cursor: 'pointer' }}
              onClick={onClickEditHandler}
            />
          )}
        </span>
      </div>
      <div
        style={{
          // display: 'grid',
          gridColumnStart: 2,
          gridColumnEnd: 2,
          gridRowStart: 2,
          gridRowEnd: 2,
          margin: '0px 0px 0px 20px',
          padding: '0px 0px 0px 10px'
        }}
      >
        <span
          style={{
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,1)',
            fontSize: '12px'
          }}
        >
          <span> / </span>
          {(item?.interactivePath || []).map((obj, index) => {
            return (
              <>
                <a
                  href={`${window.location.origin}/fast-table/?manager_account_id=${currentManagerAccountId}&account_ids=${commentObj.accountId}&document_ids=${obj._id}&start_date=${commentObj.startDate}&end_date=${commentObj.endDate}`}
                >
                  <span
                    key={index + '_1'}
                    className="hover-grey"
                    style={{
                      cursor: 'pointer'
                      // textDecoration: 'underline'
                    }}
                    // onClick={() => onClickHandler(obj)}
                  >
                    {obj.name}
                  </span>
                </a>
                <span key={index + '_2'}> / </span>
              </>
            );
          })}
        </span>
        {/* <span
          style={{
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,1)',
            fontSize: '12px'
          }}
        >
          {commentObj.path}
        </span> */}
        {/* <span
          style={{
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,1)',
            fontSize: '12px'
          }}
        >
          <span> / </span>
          {(commentObj?.interactivePath || []).map((obj) => {
            return (
              <>
                <span
                  className="hover-grey"
                  style={{
                    cursor: 'pointer'
                    // textDecoration: 'underline'
                  }}
                  onClick={() => onClickPathHandler(obj, commentObj.accountId)}
                >
                  {obj.name}
                </span>
                <span> / </span>
              </>
            );
          })}
        </span> */}
      </div>
      {edit ? (
        <div
          style={{
            display: 'grid',
            gridColumnStart: 2,
            gridColumnEnd: 2,
            gridRowStart: 3,
            gridRowEnd: 3,
            borderRadius: '5px',
            // border: '1px solid grey',
            margin: '0px 20px 0px 20px'
            // padding: '10px'
          }}
        >
          {textField}
        </div>
      ) : (
        <div
          style={{
            display: 'grid',
            gridColumnStart: 2,
            gridColumnEnd: 2,
            gridRowStart: 3,
            gridRowEnd: 3,
            borderRadius: '5px',
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.04)'
                : 'rgba(54,82,156,0.05)',
            // border: '1px solid grey',
            margin: '0px 20px 0px 20px',
            padding: '10px',
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,1)'

            // color:
            //   theme.palette.mode === 'dark'
            //     ? 'rgba(255,255,255,1)'
            //     : 'rgba(33,40,90,1)'
            // width: '100%'
          }}
        >
          <p style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
            <Linkify componentDecorator={componentDecorator}>
              <span
                style={
                  {
                    // lineBreak: 'anywhere'
                  }
                }
              >
                {commentObj.text[commentObj.text.length - 1].text}
              </span>
            </Linkify>
          </p>
        </div>
      )}
    </div>
  );
}

// export default Comment;

Comment.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired,
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // stylesStoredInRedux: state.style,
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(Comment);
