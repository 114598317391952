import React, { useState, useContext, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';

// import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './reactDateRange.css';

import { useNavigate, useSearchParams } from 'react-router-dom';
// import { sv } from 'date-fns/locale';

import { defaultStaticRanges } from './ReactDateRangeDefaultRanges';

import { InterfaceContext } from '../../context/InterfaceContext';

import formatDateYYYYMMDD from './roitable_functions/formatDateYYYYMMDD';

import { AuthContext } from '../../context/AuthContext';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from 'date-fns';

const getISOStringWithTimeZoneOffset = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split('T')[0];
};

const moment = require('moment');

// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(2)
//   }
// }));

const differnceInDays = (start, stop) => {
  var date1 = new Date(start);
  var date2 = new Date(stop);
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
};

const SimplePopover = ({
  stylesStoredInRedux,
  selectedDateStart,
  selectedDateStop,
  setSelectedDateStart,
  setSelectedDateStop,
  currentAccountIds,
  enqueueSnackbar,
  setShowSpinner
}) => {
  const { user } = useContext(AuthContext);

  const { datePreset, setDatePreset } = useContext(InterfaceContext);
  //######################
  const [searchParams, setSearchParams] = useSearchParams();

  // const end = moment(selectedDateStop, 'YYYY-MM-DD');

  const [startDate, setStartDate] = useState(new Date(selectedDateStart));
  const [endDate, setEndDate] = useState(new Date(selectedDateStop));

  const urlStartDate = searchParams.get('start_date');

  const urlEndDate = searchParams.get('end_date');

  // const [datePreset, setDatePreset] = useState('CUSTOM');

  const [previewDatePreset, setPreviewDatePreset] = useState('');

  // ################## WIP ##############

  let urlDatePreset = searchParams.get('date_preset');

  if (
    ![
      'today',
      'yesterday',
      'last_7_days',
      'last_30_days',
      'this_month',
      'last_month',
      'this_quarter',
      'next_month',
      'next_90_fays',
      'this_year',
      'next_year',
      'daily_sync',
      'custom'
    ].includes(urlDatePreset)
  ) {
    urlDatePreset = null;
  }

  // ################## WIP ##############

  useEffect(() => {
    var urlStartDateADate = moment(urlStartDate, 'YYYY-MM-DD', true);
    var urlStartDateIsValid = urlStartDateADate.isValid();

    var urlEndDateADate = moment(urlEndDate, 'YYYY-MM-DD', true);
    var urlEndDateIsValid = urlEndDateADate.isValid();

    const localStorageDatePreset = localStorage.getItem('datePreset');
    // console.log('debug99>localStorageDatePreset: ', localStorageDatePreset);
    // console.log(
    //   'debug99>Boolean(localStorageDatePreset): ',
    //   Boolean(localStorageDatePreset)
    // );
    // console.log(
    //   'debug99>defaultStaticRanges.filter((obj) => obj.string === localStorageDatePreset).length: ',
    //   defaultStaticRanges.filter((obj) => obj.string === localStorageDatePreset)
    //     .length
    // );
    // console.log('urlDatePreset: ', urlDatePreset);

    if (selectedDateStart && selectedDateStop) {
      return;
    }
    switch (true) {
      case urlDatePreset === 'custom' &&
        urlStartDateIsValid &&
        urlEndDateIsValid &&
        !selectedDateStart &&
        !selectedDateStop:
        setStartDate(new Date(urlStartDate));
        setEndDate(new Date(urlEndDate));
        setSelectedDateStart(urlStartDate);
        setSelectedDateStop(urlEndDate);
        break;

      case Boolean(urlDatePreset) &&
        Boolean(
          defaultStaticRanges.filter(
            (obj) => obj?.string && obj?.string === urlDatePreset
          ).length
        ):
        // console.log('debug99>urlDatePreset');
        const defaultStaticRange1 = defaultStaticRanges.filter(
          (obj) => obj?.string === localStorageDatePreset
        )?.[0];

        if (!defaultStaticRange1) {
          console.log('do something');
        }

        setDatePreset(defaultStaticRange1?.string);

        let range1 = defaultStaticRange1?.range();

        if (!range1) {
          console.log('no range');
          range1 = {
            startDate: startOfMonth(addMonths(new Date(), -3)),
            endDate: endOfMonth(addMonths(new Date(), 3))
          };
        }

        console.log('reactDateRange>range1: ', range1);
        setStartDate(new Date(range1.startDate));
        setEndDate(new Date(range1.endDate));
        setSelectedDateStart(getISOStringWithTimeZoneOffset(range1.startDate));
        setSelectedDateStop(getISOStringWithTimeZoneOffset(range1.endDate));
        break;

      case Boolean(localStorageDatePreset) &&
        Boolean(
          defaultStaticRanges.filter(
            (obj) => obj?.string === localStorageDatePreset
          ).length
        ):
        // console.log('debug99>localStorageDatePreset');
        const defaultStaticRange2 = defaultStaticRanges.filter(
          (obj) => obj?.string === localStorageDatePreset
        )?.[0];

        const range2 = defaultStaticRange2.range();

        setDatePreset(defaultStaticRange2.string);
        setStartDate(new Date(range2.startDate));
        setEndDate(new Date(range2.startDate));
        setSelectedDateStart(getISOStringWithTimeZoneOffset(range2.startDate));
        setSelectedDateStop(getISOStringWithTimeZoneOffset(range2.endDate));
        break;

      default:
        // console.log('debug99>default');
        const default_date_start_as_string = formatDateYYYYMMDD(
          startOfMonth(addMonths(new Date(), -3))
        );

        const default_date_stop_as_string = formatDateYYYYMMDD(
          endOfMonth(addMonths(new Date(), 3))
        );

        setStartDate(new Date(default_date_start_as_string));
        setEndDate(new Date(default_date_stop_as_string));
        setSelectedDateStart(default_date_start_as_string);
        setSelectedDateStop(default_date_stop_as_string);
    }
    // if (
    //   urlStartDateIsValid &&
    //   urlEndDateIsValid &&
    //   !selectedDateStart &&
    //   !selectedDateStop
    // ) {
    //   setStartDate(new Date(urlStartDate));
    //   setEndDate(new Date(urlEndDate));
    //   setSelectedDateStart(urlStartDate);
    //   setSelectedDateStop(urlEndDate);
    // } else if (
    //   (!urlStartDateIsValid && !selectedDateStart) ||
    //   (!urlEndDateIsValid && !selectedDateStop)
    // ) {
    //   // var todaysDate = new Date();
    //   // var firstDayOfMonth = new Date(
    //   //   todaysDate.getFullYear(),
    //   //   todaysDate.getMonth(),
    //   //   1
    //   // );
    //   // firstDayOfMonth.setHours(5);

    //   // var lastDayOfMonth = new Date(
    //   //   todaysDate.getFullYear(),
    //   //   todaysDate.getMonth() + 1,
    //   //   0
    //   // );
    //   // lastDayOfMonth.setHours(5);

    //   const default_date_start_as_string = formatDateYYYYMMDD(
    //     startOfMonth(addMonths(new Date(), -3))
    //   );

    //   const default_date_stop_as_string = formatDateYYYYMMDD(
    //     endOfMonth(addMonths(new Date(), 3))
    //   );

    //   // setSearchParams({
    //   //   ...searchParams.entries(),
    //   //   manager_account_id: searchParams.get('manager_account_id'),
    //   //   accountids: searchParams.get('account_ids'),
    //   //   start_date: default_date_start_first_date_as_string,
    //   //   end_date: default_date_stop_last_date_of_month_as_string
    //   // });
    //   // let newSearchParams = {
    //   //   ...(searchParams.entries() || []),
    //   //   start_date: default_date_start_first_date_as_string,
    //   //   end_date: default_date_stop_last_date_of_month_as_string
    //   // };

    //   // let searchParamKeys = [
    //   //   'manager_account_id',
    //   //   'account_ids',
    //   //   // 'start_date',
    //   //   // 'end_date',
    //   //   'document_ids'
    //   // ];
    //   // for (let key of searchParamKeys) {
    //   //   if (searchParams.get(key)) {
    //   //     newSearchParams[key] = searchParams.get(key);
    //   //   }
    //   // }

    //   // setSearchParams(newSearchParams);

    //   // setSearchParams({
    //   //   end_date: default_date_stop_last_date_of_month_as_string
    //   // });

    //   setStartDate(new Date(default_date_start_as_string));
    //   setEndDate(new Date(default_date_stop_as_string));
    //   setSelectedDateStart(default_date_start_as_string);
    //   setSelectedDateStop(default_date_stop_as_string);
    // }
  }, [urlStartDate, urlEndDate, urlDatePreset]);

  const handleSelect = (ranges) => {
    console.log('ranges: ', ranges);

    console.log('defaultStaticRanges: ', defaultStaticRanges);

    let selectedDatePreset = 'custom';
    for (let obj of defaultStaticRanges) {
      const defaultStaticRange = obj.range();
      if (
        defaultStaticRange.startDate === ranges.selection.startDate &&
        defaultStaticRange.endDate === ranges.selection.endDate
      ) {
        selectedDatePreset = obj.string;
      }
    }
    //check if accounts.length<0 then check if date picker is more than 90 days.
    //if more than 90 days abort and send an error message.

    const diff_in_days = differnceInDays(
      ranges.selection.startDate,
      ranges.selection.endDate
    );
    if (diff_in_days > 400 && currentAccountIds.length > 20) {
      enqueueSnackbar(
        'Date range over 400 days are only available for max 20 accounts at the time',
        {
          variant: 'error'
        }
      );

      return;
    }

    if (diff_in_days > 400 && !['system_admin'].includes(user.role)) {
      enqueueSnackbar('Max date range of 400 days supported.', {
        variant: 'error'
      });

      return;
    }

    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    setPreviewDatePreset(selectedDatePreset);
    // onClickApply(ranges.selection.startDate, ranges.selection.endDate);
  };

  const selectionRange = {
    stylesStoredInRedux,
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
    showMonthAndYearPickers: true
    // color: 'rgba(255,255,255,0.3)',
    // rangeColors: 'rgba(255,255,255,1)'
  };

  let dateRangePickerClassName;
  let buttonStyle = {
    // background: '#FFFFFF'
  };

  switch (stylesStoredInRedux.styleName) {
    case 'light':
      dateRangePickerClassName = 'light-background';
      // buttonStyle = { backgroundColor: '#FFFFFF', color: 'black' };
      break;
    case 'dark':
      dateRangePickerClassName = 'dark-background';
      // buttonStyle = { backgroundColor: '#333', color: 'white' };
      selectionRange.color = 'rgba(255,255,255,0.3)';
      selectionRange.rangeColors = 'rgba(255,255,255,1)';
      break;
  }

  //######################

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setStartDate(new Date(selectedDateStart));
    setEndDate(new Date(selectedDateStop));
    setAnchorEl(null);
  };

  const onClickApply = (startDate, endDate) => {
    const date_start_plus_1 = new Date(startDate.getTime());
    // date_start_plus_1.setHours(5);

    date_start_plus_1.setDate(date_start_plus_1.getDate());
    const newSelectedDateStart = formatDateYYYYMMDD(date_start_plus_1);
    setSelectedDateStart(newSelectedDateStart);

    const date_stop_plus_1 = new Date(endDate.getTime());

    // date_stop_plus_1.setHours(5);
    date_stop_plus_1.setDate(date_stop_plus_1.getDate());
    const newSelectedDateStop = formatDateYYYYMMDD(date_stop_plus_1);
    setSelectedDateStop(newSelectedDateStop);

    localStorage.setItem('datePreset', previewDatePreset);
    setDatePreset(previewDatePreset);

    // if (
    //   newSelectedDateStop !== selectedDateStop ||
    //   newSelectedDateStart !== selectedDateStart
    // ) {
    // setSelectedDateStart(newSelectedDateStart);
    // setSelectedDateStop(newSelectedDateStop);

    // setSearchParams({
    //   ...searchParams.entries(),
    //   manager_account_id: searchParams.get('manager_account_id'),
    //   accountids: searchParams.get('account_ids'),
    //   start_date: newSelectedDateStart,
    //   end_date: newSelectedDateStop
    // });

    let newSearchParams = {
      ...(searchParams.entries() || []),
      start_date: newSelectedDateStart,
      end_date: newSelectedDateStop,
      date_preset: previewDatePreset
    };

    let searchParamKeys = [
      'manager_account_id',
      'account_ids',
      'start_date',
      'end_date',
      'document_ids',
      'date_preset'
    ];
    for (let key of searchParamKeys) {
      if (searchParams.get(key)) {
        newSearchParams[key] = searchParams.get(key);
      }
    }

    if (
      !(
        newSelectedDateStart === selectedDateStart &&
        newSelectedDateStop === selectedDateStop
      )
    ) {
      setShowSpinner(true);
    }

    // }

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="dark">
      <Button
        style={buttonStyle}
        aria-describedby={id}
        variant="standard"
        // color="primary"
        onClick={handleClick}
      >
        {selectedDateStart + ' - ' + selectedDateStop}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {/* <Typography className={classes.typography}>
          The content of the Popover.
        </Typography> */}
        <DateRangePicker
          // locale={sv}
          // color="#9953BD"
          // rangeColors="#9953BD"
          color="#a72683"
          rangeColors={['#a72683']}
          // scroll={{ enabled: true }}
          staticRanges={defaultStaticRanges}
          className={dateRangePickerClassName}
          ranges={[selectionRange]}
          onChange={handleSelect}
          weekStartsOn={1}
        />
        <div
          style={{
            padding: '10px 20px',
            textAlign: 'right'
            // backgroundColor: 'red'
          }}
        >
          <Button
            // variant="contained"
            size="small"
            color="primary"
            // className={classes.margin}
            onClick={handleClose}
            style={{ marginRight: '10px' }}
          >
            Cancel
          </Button>
          <Button
            // variant="contained"
            size="small"
            color="primary"
            // className={classes.margin}
            onClick={() => onClickApply(startDate, endDate)}
          >
            Apply
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(SimplePopover);
