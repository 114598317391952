import { SET_STYLE } from '../actions/types';

const initialState = {
  styleName: 'dark',
  RoiTable: {},
  body: { backgroundColor: 'rgb(5,5,5)' },

  MiniDrawer: {
    AppBar: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundColor: '#424242',
      color: 'white'
    },
    Drawer: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundColor: '#424242',
      color: 'white'
    },
    main: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      color: 'white'
    }
    // AppBar: { backgroundColor: 'rgba(57, 62, 70, 0.9)' }
  },
  ManagerAccount: {
    ChooseManagerAccount: {
      card: {
        // backgroundImage: 'linear-gradient(to top, black, #111111)',
        backgroundImage: 'linear-gradient(to top, #424242, #555555)',
        color: '#FFFFFF',
        border: '1px solid rgba(255,255,255,0.1)'
      }
    }
  },
  Login: {
    card: {
      // backgroundImage: 'linear-gradient(to top, black, #111111)',
      backgroundImage: 'linear-gradient(to top, #424242, #555555)',
      color: '#FFFFFF',
      border: '1px solid rgba(255,255,255,0.1)',
      loginButton: {
        backgroundImage: 'linear-gradient(to top, black, #111111)',
        border: '1px solid rgba(255,255,255,0.1)'
      }
    }
  },

  RoiTable: {
    datePicker: {
      color: 'white'
    },
    Table: {
      default: {
        border: '1px solid rgba(255, 255, 255, 0.12)'
      },
      headStyle: {
        defaultHead: {
          // backgroundImage: 'linear-gradient(to top, black, #111111)',
          backgroundImage: 'linear-gradient(to top, #424242, #555555)',
          // backgroundImage: 'linear-gradient(to bottom, black, #111111)',
          // color: 'rgba(255, 255, 255, 0.87);'
          color: 'white',
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderColor: 'rgba(255, 255, 255, 0.12)'
        },
        ganttHead: {
          default: {
            backgroundImage: 'linear-gradient(to top, #424242, #555555)',
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          },
          weekend: {
            backgroundImage: 'linear-gradient(to top, #424242, #6f6f6f)',
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          },
          today: {
            backgroundImage: 'linear-gradient(to top, #999999, #999999)',
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          }
          // backgroundColor: 'rgb(3, 3, 3)',
          // backgroundImage: 'linear-gradient(to top, #424242, #555555)',
          // color: 'white',
          // border: '1px solid rgba(255, 255, 255, 0.12)',
          // borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      },
      itemStyle: {
        rowStyle: {
          account: {
            // backgroundColor: 'rgb(0, 0, 0)',
            backgroundColor: '#424242',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          },
          plan: {
            // backgroundColor: 'rgb(3, 3, 3)',
            backgroundColor: '#424242',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          },
          campaign: {
            // backgroundColor: 'rgb(6, 6, 6)',
            backgroundColor: '#424242',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          },
          adAccountCampaign: {
            // backgroundColor: 'rgb(9, 9, 9)',
            backgroundColor: '#424242',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          }
        }
      },
      summaryStyle: {
        defaultSummary: {
          // backgroundImage: 'linear-gradient(to top, black, #111111)',
          backgroundImage: 'linear-gradient(to top, #424242, #555555)',
          // backgroundImage: 'linear-gradient(to bottom, black, #111111)',
          // color: 'rgba(255, 255, 255, 0.87);'
          color: 'white',
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      }
    }
  },
  ManagerAccountSettings:{
    cardBackgroundColor: 'rgb(66, 66, 66)',
    cardTopBackgroundColor: 'rgba(255, 255, 255, 0.03)',
    subBackgroundColor: 'rgba(255, 255, 255, 0.05)',
    color:'white'
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_STYLE:
      return { ...state, ...payload };
    // case REMOVE_ALERT:
    //   return state.filter((alert) => alert.id !== payload);
    default:
      return state;
  }
}
