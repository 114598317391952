import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useMutation } from '@apollo/client';

import { updateDocumentMutation } from './../../../../graphql/queries';

import Tooltip from '@mui/material/Tooltip';

import { MutationContext } from '../../../../context/MutationContext';

export default function BasicMenu({ currentManagerAccountId, item, showText }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const [updateDocument] = useMutation(updateDocumentMutation);

  const { updateDocument } = useContext(MutationContext);

  const handleClose = (e, value) => {
    if (!['NONE', 'STANDARD', 'CHILDREN'].includes(value)) {
      setAnchorEl(null);
      return;
    }
    if (value === item.ganttBarDisplaySetting) {
      console.log('same gantt setting');
      setAnchorEl(null);
      return;
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          ganttBarDisplaySetting: value
        }
      ],
      updateKeys: ['ganttBarDisplaySetting']
    };

    updateDocument({ variables: { input: { ...documentInput } } });

    setAnchorEl(null);
  };

  let fiberManualRecordIconColor;

  switch (item.ganttBarDisplaySetting) {
    case 'ACTIVE':
      // case 'DONE':
      fiberManualRecordIconColor = 'green';
      break;
    case 'DONE':
    case 'COMPLETED':
      fiberManualRecordIconColor = 'rgba(0,128,0,0.4)';
      break;
    case 'REMOVED':
      fiberManualRecordIconColor = 'red';
      break;

    case 'DRAFT':
      fiberManualRecordIconColor = '#bcbcbc';
      break;
    case 'PAUSED':
      fiberManualRecordIconColor = 'rgb(62, 142, 208)';
      break;
    default:
      fiberManualRecordIconColor = 'grey';
      break;
  }
  const menuItemStyle = { fontSize: '12px' };

  return (
    <div>
      {showText ? (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            width: '100%',
            textAlign: 'right',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: fiberManualRecordIconColor,
            color: fiberManualRecordIconColor,
            padding: '3px 6px',
            borderRadius: '5px',
            minWidth: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          {/* <FiberManualRecordIcon
            style={{
              fontSize: '10px',
              verticalAlign: 'middle',
              color: fiberManualRecordIconColor
            }}
          /> */}
          <span
            style={{ fontSize: '10px', marginLeft: '3px', marginRight: '3px' }}
          >
            {item['ganttBarDisplaySetting']
              ? item['ganttBarDisplaySetting'].charAt(0).toUpperCase() +
                item['ganttBarDisplaySetting'].slice(1).toLowerCase()
              : 'Standard'}
          </span>
        </Button>
      ) : (
        <Tooltip
          title={
            <span style={{ fontSize: '13px' }}>
              {item['ganttBarDisplaySetting']
                ? item['ganttBarDisplaySetting'].charAt(0).toUpperCase() +
                  item['ganttBarDisplaySetting'].slice(1).toLowerCase()
                : ' '}
            </span>
          }
        >
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{
              cursor: 'pointer',
              width: '18px',
              minWidth: '18px',
              textAlign: 'right',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: fiberManualRecordIconColor,
              color: fiberManualRecordIconColor,
              padding: '3px 6px',
              // borderRadius: '5px',
              // minWidth: '70px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            {/* <FiberManualRecordIcon
              style={{
                fontSize: '10px',
                verticalAlign: 'middle',
                color: fiberManualRecordIconColor
              }}
            /> */}
          </Button>
        </Tooltip>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem style={menuItemStyle} onClick={(e) => handleClose(e, 'NONE')}>
          None
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'STANDARD')}
        >
          Standard
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'CHILDREN')}
        >
          Children
        </MenuItem>
      </Menu>
    </div>
  );
}
