import React from 'react';

import './style.css';

import readXlsxFile from 'read-excel-file';
import { readSheetNames } from 'read-excel-file';

// drag drop file component

function DragDropFile({ excelData, setExcelData, toggleFileUploaded }) {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  const parseFile = async (files) => {
    //   var files = e.target.files,
    var f = files[0];
    var sheets = {};

    const sheetNames = await readSheetNames(f);
    for (let sheetName of sheetNames) {
      const rows = await readXlsxFile(f, { sheet: sheetName });
      sheets[sheetName] = rows;
    }
    // await readSheetNames(f).then((sheetNames) => {
    //   console.log('sheetNames: ', sheetNames);
    //   for (let sheetName of sheetNames) {
    //     readXlsxFile(f, { sheet: sheetName }).then((rows) => {
    //       console.log('rows: ', rows);
    //       // `rows` is an array of rows
    //       // each row being an array of cells.
    //       sheets[sheetName] = rows;
    //     });
    //   }

    //   // sheetNames === ['Sheet1', 'Sheet2']
    // });
    console.log('sheets: ', sheets);
    setExcelData(sheets);
    toggleFileUploaded(true);
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  console.log('excelData: ', excelData);

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = e.dataTransfer.files;
      parseFile(files);
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const files = e.target.files;
      // handleFiles(e.target.files);

      parseFile(files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? 'drag-active' : ''}
      >
        <div>
          <p>Drag and drop your file here or</p>
          <button className="upload-button" onClick={onButtonClick}>
            Upload a file
          </button>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
export default DragDropFile;
