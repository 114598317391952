import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

var weekTypes = ['monday-sunday', 'sunday-saturday'];

export default function BasicSelect({ weekType, setWeekType }) {
  //   const [timeZone, setTimeZone] = React.useState('Europe/Stockholm');

  const handleChange = (event) => {
    setWeekType(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 220 }}>
      <FormControl fullWidth>
        <InputLabel
          style={{
            left: '-15px',
            top: '5px',
            display: 'flex',
            position: 'absolute'
          }}
          id="demo-simple-select-label"
        >
          Week Type
        </InputLabel>
        <Select
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={weekType}
          label="Week Type"
          onChange={handleChange}
        >
          {weekTypes.map((week) => (
            <MenuItem value={week}>{week}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
