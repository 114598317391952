import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';

import { Button } from '@mui/material';

function RemoveFilterButton({ sFilters, setSFilters, index }) {
  const onClickHandler = () => {
    const copyOfSFilters = [...sFilters];
    copyOfSFilters.splice(index, 1);

    setSFilters(copyOfSFilters);
  };
  return (
    <Button
      onClick={onClickHandler}
      size="small"
      style={{
        backgroundColor: 'red',
        width: '30px',
        minWidth: '30px',
        maxWidth: '30px',
        height: '30px',
        marginLeft: '10px'
      }}
    >
      <DeleteIcon style={{ color: 'white' }} />
    </Button>
  );
}

export default RemoveFilterButton;
