import React from 'react';

import WarningMenuPopover from '../item-components/body/warning-item/warning-menu/WarningMenuPopover';

function WarningTooltipContent({
  warnings,
  subWarnings,
  stylesStoredInRedux,
  item
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const onContext = (e) => {
    e.preventDefault();
    if (
      ([
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group',
        'manualDataEntry'
      ].includes(item.type) &&
        !item.isSetChild) ||
      item.isSet
    ) {
      setAnchorEl(e.currentTarget);
    }
  };

  const toolTips = warnings.map((warning) => {
    return (
      <div
        style={{
          padding: '10px',
          //   marginBottom: '5px',
          //   border: '1px solid red',
          borderColor:
            stylesStoredInRedux.styleName === 'light'
              ? '1px solid #bcbcbc'
              : 'rgba(255, 255, 255, 0.12)',
          borderRadius: '5px',
          fontSize: '11px'
        }}
      >
        {/* <b>{warning.headline}</b>
            <br /> */}
        {/* {'path: ' + warning.path}
        <br />
        {'name: '} {warning.name}
        <br /> */}
        {/* {'msg: '} */}
        <ul>
          {warning.messages.map((message) => {
            return <li> - {message.message}</li>;
          })}
        </ul>
        {/* <br />
            {'-----------------'} */}
        {/* <br /> */}
      </div>
    );
  });

  //   const subWarningToolTips = subWarnings.map((warning) => {
  //     return (
  //       <div
  //         style={{
  //           padding: '10px',
  //           marginBottom: '5px',
  //           border: '1px solid #bcbcbc',
  //           borderColor:
  //             stylesStoredInRedux.styleName === 'light'
  //               ? '1px solid #bcbcbc'
  //               : 'rgba(255, 255, 255, 0.12)',
  //           borderRadius: '5px',
  //           fontSize: '12px'
  //         }}
  //       >
  //         {/* <b>{warning.headline}</b>
  //             <br /> */}
  //         {'path: ' + warning.path}
  //         <br />
  //         {'name: '} {warning.name}
  //         <br />
  //         {/* {'msg: '} */}
  //         <ul>
  //           {warning.messages.map((message) => {
  //             return <li> - {message.message}</li>;
  //           })}
  //         </ul>
  //         {/* <br />
  //             {'-----------------'} */}
  //         {/* <br /> */}
  //       </div>
  //     );
  //   });
  return (
    <div
      onContextMenu={onContext}
      style={{ minHeight: '20px', minWidth: '20px' }}
    >
      {toolTips}
      <WarningMenuPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        item={item}
      />
    </div>
  );
}

export default WarningTooltipContent;
