import React from 'react';
import Fab from '@mui/material/Fab';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import { makeStyles } from '@mui/styles';

import { Tooltip } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//     root: {
//       "& > *": {
//         margin: theme.spacing(1),
//         borderRadius: "5px",
//         height: '20px',
//         width: '37px',
//         backgroundColor: "rgba(0, 181, 8, 1)",
//         "&:hover": {
//           background: "rgba(20, 201, 28, 1)"
//         }
//       }
//     },
//     extendedIcon: {
//       color: 'white',
//       marginRight: theme.spacing(1)
//     }
//   }));

function AddUserButton({ setOpen }) {
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     '& > *': {
  //       // margin: theme.spacing(1),
  //       borderRadius: '5px',
  //       height: '20px',
  //       width: '37px',
  //       backgroundColor: 'rgba(0, 181, 8, 1)',
  //       '&:hover': {
  //         background: 'rgba(20, 201, 28, 1)'
  //       }
  //     }
  //   },
  //   extendedIcon: {
  //     color: 'white',
  //     marginRight: theme.spacing(1)
  //   }
  // }));

  // const classes = useStyles();

  const onClickHanlder = () => {
    setOpen(true);
  };

  return (
    <div
    // className={classes.root}
    >
      <Tooltip
        enterDelay={500}
        // leaveDelay={200}
        title="Invite new user to NexusTable"
        placement="bottom"
        arrow
      >
        <Fab
          // ref={anchorRef}
          // aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          aria-label="add"
          style={{
            borderRadius: '5px',
            height: '32px',
            minHeight: '32px',
            width: '80px',
            // minWidth: '32px',
            backgroundColor: true
              ? 'rgba(0, 181, 8, 0.9)'
              : 'rgba(0, 181, 8, 0.3)',
            color: 'white',
            fontSize: '12px'
          }}
          onClick={onClickHanlder}
        >
          <div
            style={{
              width: '45px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '12px',
                alignItems: 'start'
              }}
            >
              <div>Invite</div>
              <div style={{}}>User</div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <PersonAddIcon style={{ fontSize: '22px' }} />
          </div>
        </Fab>
      </Tooltip>
    </div>
  );
}

export default AddUserButton;
