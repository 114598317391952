import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
// import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// import WarningIcon from '@mui/icons-material/Warning';

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 600,
//     maxHeight: '50vw',
//     // overflow: 'scroll',
//     overflow: 'hidden',
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//     padding: '5px 5px 0px 5px'
//   }
// }))(Tooltip);

export default function CustomizedTooltips({
  item,
  itemKey,
  stylesStoredInRedux
}) {
  const theme = useTheme();
  const [warnings, setWarnings] = useState([]);
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      // backgroundColor: '#f5f5f9',
      backgroundImage:
        theme.palette.mode === 'light'
          ? 'linear-gradient(to top, rgb(255, 255, 255), rgb(255, 255, 255))'
          : 'linear-gradient(to top, rgb(55, 55, 66), rgb(75, 75, 86))', // 'linear-gradient(to top, rgb(66, 66, 66), rgb(85, 85, 85))',
      // color: 'rgba(0, 0, 0, 0.87)',
      color: theme.palette.mode === 'light' ? 'black' : 'white',
      maxWidth: 600,
      maxHeight: '50vw',
      // overflow: 'scroll',
      overflow: 'hidden',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      borderColor:
        theme.palette.mode === 'light'
          ? '1px solid #bcbcbc'
          : 'rgba(255, 255, 255, 0.12)',
      padding: '5px 5px 0px 5px'
      // overflowY: 'scroll'
    }
  }))(Tooltip);

  // warnings.sort((a, b) =>
  //   a.highestWarningLevel < b.highestWarningLevel
  //     ? 1
  //     : b.highestWarningLevel < a.highestWarningLevel
  //     ? -1
  //     : 0
  // );
  // subWarnings.sort((a, b) =>
  //   a.highestWarningLevel < b.highestWarningLevel
  //     ? 1
  //     : b.highestWarningLevel < a.highestWarningLevel
  //     ? -1
  //     : 0
  // );

  const toolTips = warnings.map((warning, index) => {
    return (
      <div
        key={index}
        style={{
          padding: '10px',
          marginBottom: '5px',
          border: '1px solid #bcbcbc',
          borderColor:
            theme.palette.mode === 'light'
              ? '1px solid #bcbcbc'
              : 'rgba(255, 255, 255, 0.12)',
          borderRadius: '5px',
          fontSize: '12px'
        }}
      >
        {/* <b>{warning.headline}</b>
        <br /> */}
        {/* {'path: ' + warning.path} */}
        {/* <br /> */}
        {/* {'name: '} {warning.name} */}
        {/* <br /> */}
        {/* {'msg: '} */}
        <ul>
          {warning.messages.map((message) => {
            return <li> - {message.message}</li>;
          })}
        </ul>
        {/* <br />
        {'-----------------'} */}
        {/* <br /> */}
      </div>
    );
  });

  // const subWarningToolTips = subWarnings.map((warning, index) => {
  //   return (
  //     <div
  //       key={index}
  //       style={{
  //         padding: '10px',
  //         marginBottom: '5px',
  //         border: '1px solid #bcbcbc',
  //         borderColor:
  //           stylesStoredInRedux.styleName === 'light'
  //             ? '1px solid #bcbcbc'
  //             : 'rgba(255, 255, 255, 0.12)',
  //         borderRadius: '5px',
  //         fontSize: '12px'
  //       }}
  //     >
  //       {/* <b>{warning.headline}</b>
  //       <br /> */}
  //       {'path: ' + warning.path}
  //       <br />
  //       {'name: '} {warning.name}
  //       <br />
  //       {/* {'msg: '} */}
  //       <ul>
  //         {warning.messages.map((message, index) => {
  //           return <li key={index}> - {message.message}</li>;
  //         })}
  //       </ul>
  //       {/* <br />
  //       {'-----------------'} */}
  //       {/* <br /> */}
  //     </div>
  //   );
  // });

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(!open);
  };

  const [color, setColor] = useState(
    item?.warningFieldColorDictionary?.[itemKey] || 'grey'
  );

  // const handleTooltipOpen = () => {
  //   setWarnings(
  //     item?.warningMessages?.filter((warningMessage) =>
  //       warningMessage?.relatedFields?.includes(itemKey)
  //     ) || []
  //   );
  //   // setOpen(!open);
  // };

  const [isOpen, toggleIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (item?.relatedWarningFields?.includes(itemKey)) {
      // console.log('WarningDotToolTip>warnings: ', warnings);
      const warnings_ = [...(item?.warnings || [])];
      warnings_.sort((a, b) =>
        a.highestWarningLevel < b.highestWarningLevel
          ? 1
          : b.highestWarningLevel < a.highestWarningLevel
          ? -1
          : 0
      );

      const filteredWarnings = [];
      for (let warning of warnings_) {
        let pushWarning = false;
        const newWarning = { ...warning };
        let newMessages = [];
        for (let message of warning.messages || []) {
          if (message?.relatedFields?.includes(itemKey)) {
            pushWarning = true;
            newMessages.push({ ...message });
          }
        }
        if (pushWarning) {
          newWarning.messages = newMessages;
          filteredWarnings.push(newWarning);
        }
      }

      // console.log('WarningDotToolTip>warnings_: ', warnings_);

      setWarnings(
        filteredWarnings
        // warnings_?.filter((warning) =>
        //   Boolean(
        //     warning?.messages?.filter((message) =>
        //       message.relatedFields?.includes(itemKey)
        //     ).length
        //   )
        // ) || []
      );

      // setColor(filteredWarnings[0]?.messages[0].warningColor || 'grey');
    }
  }, [isOpen, item?.warnings]);

  //   const handlePopoverOpen = (event) => {
  //     setWarnings(
  //       item?.warningMessages?.filter((warningMessage) =>
  //         warningMessage?.relatedFields?.includes(itemKey)
  //       ) || []
  //     );
  //     setAnchorEl(event.currentTarget);
  //   };

  // if (!item?.relatedWarningFields?.includes(itemKey)) {
  //   return <div></div>;
  // }
  // console.log(
  //   'item?.relatedWarningFields?.includes(itemKey): ',
  //   item?.relatedWarningFields?.includes(itemKey)
  // );

  if (!item?.relatedWarningFields?.includes(itemKey)) {
    return <div style={{ width: '29px', height: '13px' }}></div>;
  }
  // console.log('open status: ', isOpen);
  return (
    // <div style={{ padding: '5px', textAlign: 'center' }}>
    <HtmlTooltip
      // disableFocusListener
      // disableHoverListener
      // disableTouchListener
      // onBlur={handleTooltipClose}
      onClose={() => {
        toggleIsOpen(false);
      }}
      // open={open}
      onOpen={() => {
        console.log('open!');
        toggleIsOpen(true);
      }}
      placement="bottom"
      title={<React.Fragment>{toolTips}</React.Fragment>}
    >
      <div
        style={{
          fontSize: '10px',
          // color: warnings.length > 0 ? '#D0021B' : 'rgba(255,255,255,0.1)',
          visibility: item?.relatedWarningFields?.includes(itemKey)
            ? 'visible'
            : 'hidden',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          padding: '5px'
        }}
        //   onClick={handleTooltipOpen}
      >
        <div style={{ padding: '5px 5px 5px 10px ' }}>
          <span
            style={{
              display: 'flex',
              backgroundColor: color,
              height: '4px',
              width: '4px',
              borderRadius: '15px 15px 15px 15px'

              // marginRight: '10px'
            }}
          ></span>
        </div>
        {/* <WarningIcon style={{ height: '12px', width: '12px' }} /> */}
        {/* <i className="fas fa-circle" style={{ paddingRight: '3px' }}></i>( */}
        {/* ({warnings.length}) */}
      </div>
    </HtmlTooltip>
    // </div>
  );
}

// import React from 'react';
// import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
// // import { makeStyles } from '@mui/styles';

// // const useStyles = makeStyles((theme) => ({
// //   popover: {
// //     pointerEvents: 'none'
// //   },
// //   paper: {
// //     padding: theme.spacing(1)
// //   }
// // }));

// export default function WarningPopover({ item, itemKey }) {
//   // const classes = useStyles();
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [warnings, setWarnings] = useState([]);

//   const handlePopoverOpen = (event) => {
//     setWarnings(
//       item?.warningMessages?.filter((warningMessage) =>
//         warningMessage?.relatedFields?.includes(itemKey)
//       ) || []
//     );
//     setAnchorEl(event.currentTarget);
//   };

//   const handlePopoverClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);

//   return (
//     <div>
//       <div
//         style={{ padding: '20px 20px 20px 15px', color: '#f44336' }}
//         aria-owns={open ? 'mouse-over-popover' : undefined}
//         aria-haspopup="true"
//         onMouseEnter={handlePopoverOpen}
//         onMouseLeave={handlePopoverClose}
//       >
//         {/* <i className="fas fa-circle"></i>({warnings.length}) */}
//         <span
//           style={{
//             display: 'flex',
//             backgroundColor,
//             height: '3px',
//             width: '3px',
//             borderRadius: '15px 15px 15px 15px'

//             // marginRight: '10px'
//           }}
//         ></span>
//       </div>
//       <Popover
//         id="mouse-over-popover"
//         // className={classes.popover}
//         // classes={{
//         //   paper: classes.paper
//         // }}
//         open={open}
//         anchorEl={anchorEl}
//         anchorOrigin={{
//           vertical: 'center',
//           horizontal: 'right'
//         }}
//         transformOrigin={{
//           vertical: 'center',
//           horizontal: 'left'
//         }}
//         onClose={handlePopoverClose}
//         disableRestoreFocus
//       >
//         {/* <Typography>I use Popover.</Typography> */}
//         <ul>
//           {/* <li>test</li> */}
//           {warnings.map((warning, index) => {
//             return <li key={index}>• {warning}</li>;
//           })}
//         </ul>
//       </Popover>
//     </div>
//   );
// }
