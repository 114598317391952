import React, { useState } from 'react';

import btn_google_signin_light_normal_web from './btn_google_signin_light_normal_web.png';
import btn_google_signin_light_focus_web from './btn_google_signin_light_focus_web.png';
import btn_google_signin_light_pressed_web from './btn_google_signin_light_pressed_web.png';

// const [onHover]

function GoogleLoginButton({ onClickHandler, dataSource }) {
  const [buttonImageState, setButtonImageState] = useState('normal');

  let buttonImage;

  switch (buttonImageState) {
    case 'normal':
      buttonImage = btn_google_signin_light_normal_web;
      break;
    case 'pressed':
      buttonImage = btn_google_signin_light_pressed_web;
      break;
    case 'focus':
      buttonImage = btn_google_signin_light_focus_web;
      break;
  }

  return (
    <div style={{ cursor: 'pointer' }}>
      <img
        src={buttonImage}
        alt="Logo"
        onClick={(e) => onClickHandler(e, dataSource)}
        // onMouseOver={e => (e.currentTarget.src = btn_google_signin_light_focus_web)}
        onMouseDown={(e) => setButtonImageState('pressed')}
        onMouseUp={(e) => setButtonImageState('normal')}
        onMouseEnter={(e) => setButtonImageState('focus')}
        onMouseLeave={(e) => setButtonImageState('normal')}
        // style={{ width: '20px' }}
      />
    </div>
  );
}

export default GoogleLoginButton;
