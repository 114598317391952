import React, { useState, useContext, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';

// import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './../../reactDateRange.css';

import { useSnackbar } from 'notistack';

import { useNavigate, useSearchParams } from 'react-router-dom';
// import { sv } from 'date-fns/locale';

import { defaultStaticRanges } from './ReactDateRangeDefaultRanges';

import formatDateYYYYMMDD from '../../roitable_functions/formatDateYYYYMMDD';

import DateRangeIcon from '@mui/icons-material/DateRange';

import { MutationContext } from '../../../../context/MutationContext';

import { AccountContext } from '../../../../context/AccountContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import SpinnerSmall from '../../../layout/SpinnerSmall';

import { connect } from 'react-redux';
import './calendarPicker.css';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from 'date-fns';

const getISOStringWithTimeZoneOffset = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split('T')[0];
};

const moment = require('moment');

// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(2)
//   }
// }));

const differnceInDays = (start, stop) => {
  var date1 = new Date(start);
  var date2 = new Date(stop);
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
};

const SimplePopover = ({
  date,
  stylesStoredInRedux
  // selectedDateStartTimebox: selectedDateRangeStart,
  // setSelectedDateStartTimebox: setSelectedDateRangeStart,
  // selectedDateStopTimebox: selectedDateRangeStop,
  // setSelectedDateStopTimebox: setSelectedDateRangeStop
}) => {
  const today = formatDateYYYYMMDD(new Date());

  const isCurrentDay = date === today;

  const {
    selectedDateStartTimebox: selectedDateRangeStart,
    setSelectedDateStartTimebox: setSelectedDateRangeStart,
    selectedDateStopTimebox: selectedDateRangeStop,
    setSelectedDateStopTimebox: setSelectedDateRangeStop
    // selectedUserTimeboxer: selectedUser,
    // setSelectedUserTimeboxer: setSelectedUser
  } = useContext(InterfaceContext);
  // console.log('DaterangePicker>date: ', date);
  const { currentManagerAccount } = useContext(AccountContext);
  const { updateDocument } = useContext(MutationContext);
  //######################

  // console.log('selectedDateRangeStart: ', selectedDateRangeStart);
  const { enqueueSnackbar } = useSnackbar();

  // const end = moment(selectedDateStop, 'YYYY-MM-DD');

  const [startDate, setStartDate] = useState(
    selectedDateRangeStart ? new Date(selectedDateRangeStart) : new Date()
  );
  const [endDate, setEndDate] = useState(
    selectedDateRangeStop ? new Date(selectedDateRangeStop) : new Date()
  );

  // console.log('selectedDateRangeStop: ', new Date(selectedDateRangeStop));

  const [previewDatePreset, setPreviewDatePreset] = useState('');

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (selectedDateRangeStart && selectedDateRangeStop) {
      setStartDate(new Date(selectedDateRangeStart));
      setEndDate(new Date(selectedDateRangeStop));
    }
  }, [selectedDateRangeStart, selectedDateRangeStop]);

  // ################## WIP ##############

  const handleSelect = (ranges) => {
    console.log('ranges: ', ranges);

    console.log('defaultStaticRanges: ', defaultStaticRanges);

    let selectedDatePreset = 'custom';
    for (let obj of defaultStaticRanges) {
      const defaultStaticRange = obj.range();
      if (
        defaultStaticRange.startDate === ranges.selection.startDate &&
        defaultStaticRange.endDate === ranges.selection.endDate
      ) {
        selectedDatePreset = obj.string;
      }
    }

    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const selectionRange = {
    stylesStoredInRedux,
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
    showMonthAndYearPickers: true
    // color: 'rgba(255,255,255,0.3)',
    // rangeColors: 'rgba(255,255,255,1)'
  };

  let dateRangePickerClassName;

  switch (stylesStoredInRedux?.styleName) {
    case 'light':
      dateRangePickerClassName = 'light-background';
      // buttonStyle = { backgroundColor: '#FFFFFF', color: 'black' };
      break;
    case 'dark':
      dateRangePickerClassName = 'dark-background';
      // buttonStyle = { backgroundColor: '#333', color: 'white' };
      selectionRange.color = 'rgba(255,255,255,0.3)';
      selectionRange.rangeColors = 'rgba(255,255,255,1)';
      break;
  }
  // console.log('debug74>startDate: ', startDate);
  // console.log('debug74>endDate: ', endDate);
  //######################

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // setStartDate(new Date(item.startDate));
    // setEndDate(new Date(item.endDate));
    setAnchorEl(null);
  };

  const onClickApply = async (startDate, endDate) => {
    // setShowSpinner(true);

    setSelectedDateRangeStart(startDate);
    setSelectedDateRangeStop(endDate);

    // setShowSpinner(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="dark">
      <Button
        style={{
          // maxWidth: '30px',
          // minWidth: '30px',
          // width: '500px',
          maxHeight: '30px',
          minHeight: '30px',
          width: '120px'
        }}
        aria-describedby={id}
        variant="standard"
        // color="primary"
        onClick={handleClick}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '14px',
            borderBottom: isCurrentDay && '1px solid #a72683'
          }}
        >
          <span
            style={{
              // lineBreak: 'unset'
              fontSize: '12px'

              // marginRight: '10px'
            }}
          >
            {Boolean(date) && formatDateYYYYMMDD(date)}
          </span>
          {/* <span> - </span> */}
          {/* <span style={{ marginLeft: '10px' }}>
            {formatDateYYYYMMDD(selectedDateRangeStop)}
          </span> */}
          {/* <DateRangeIcon style={{ fontSize: '14px', marginLeft: '15px' }} /> */}
        </div>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {/* <Typography className={classes.typography}>
          The content of the Popover.
        </Typography> */}
        <DateRangePicker
          // locale={sv}
          // color="#9953BD"
          // rangeColors="#9953BD"
          color="#a72683"
          rangeColors={['#a72683']}
          // scroll={{ enabled: true }}
          staticRanges={defaultStaticRanges}
          className={dateRangePickerClassName}
          ranges={[selectionRange]}
          onChange={handleSelect}
          weekStartsOn={1}
        />

        <div
          style={{
            padding: '10px 20px',
            textAlign: 'right',
            minHeight: '51px'
            // backgroundColor: 'red'
          }}
        >
          {showSpinner ? (
            <SpinnerSmall size={20} />
          ) : (
            <>
              <Button
                // variant="contained"
                size="small"
                color="primary"
                // className={classes.margin}
                onClick={handleClose}
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                // variant="contained"
                size="small"
                color="primary"
                // className={classes.margin}
                onClick={() => onClickApply(startDate, endDate)}
              >
                Apply
              </Button>
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

SimplePopover.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(React.memo(SimplePopover));
