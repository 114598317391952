import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function StatusFilterMenu({ statusFilter, setStatusFilter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, status) => {
    switch (status) {
      case 'All but removed':
      case 'All but completed and removed':
      case 'All':
        setStatusFilter(status);
        break;
    }

    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {statusFilter}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={(e) => handleClose(e, 'All but removed')}>
          All but removed
        </MenuItem>
        <MenuItem
          onClick={(e) => handleClose(e, 'All but completed and removed')}
        >
          All but completed & removed
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, 'All')}>All</MenuItem>
      </Menu>
    </div>
  );
}
