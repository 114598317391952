import React, { useState } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 900,
    maxHeight: '50vw',
    // overflow: 'scroll',
    overflow: 'hidden',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: '5px 5px 0px 5px'
  }
}))(Tooltip);

export default function CustomizedTooltips({ commentVersions }) {
  const toolTips = commentVersions.map((commentVersion) => {
    return (
      <div
        style={{
          padding: '10px',
          marginBottom: '5px',
          border: '1px solid #bcbcbc',
          borderRadius: '5px',
          fontSize: '12px',
          whiteSpace: 'pre-line'
        }}
      >
        <p style={{ fontSize: '12px' }}>
          {new Date(parseInt(commentVersion.date)).toLocaleString()}
        </p>

        <br />
        <p>{commentVersion.text}</p>
      </div>
    );
  });

  //   const subWarningToolTips = subWarnings.map((warning) => {
  //     return (
  //       <div
  //         style={{
  //           padding: '10px',
  //           marginBottom: '5px',
  //           border: '1px solid #bcbcbc',
  //           borderRadius: '5px',
  //           fontSize: '12px'
  //         }}
  //       >
  //         {/* <b>{warning.headline}</b>
  //         <br /> */}
  //         {'path: ' + warning.path}
  //         <br />
  //         {'name: '} {warning.name}
  //         <br />
  //         {/* {'msg: '} */}
  //         <ul>
  //           {warning.messages.map((message) => {
  //             return <li> - {message.message}</li>;
  //           })}
  //         </ul>
  //         {/* <br />
  //         {'-----------------'} */}
  //         {/* <br /> */}
  //       </div>
  //     );
  //   });

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(!open);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        padding: '10px',
        textAlign: 'center',
        display: 'inline',
        width: '20px'
      }}
    >
      <HtmlTooltip
        // disableFocusListener
        // disableHoverListener
        // disableTouchListener
        // onBlur={handleTooltipClose}
        // onClose={handleTooltipClose}
        // open={open}
        // placement="right"
        title={<React.Fragment>{toolTips}</React.Fragment>}
      >
        <div
          className="hover-grey"
          style={{
            display: 'inline',
            fontSize: '10px',
            cursor: 'pointer'
            // verticalAlign: 'sub'
          }}
          //   style={{
          //     fontSize: '10px',
          //     color:
          //       commentVersions.length > 0 ? '#D0021B' : 'rgba(255,255,255,0.1)',
          //     visibility: commentVersions.length > 0 ? 'visible' : 'hidden'
          //   }}
          //   onClick={handleTooltipOpen}
        >
          {/* <ChangeHistoryIcon
            style={{ paddingRight: '3px', fontSize: '16px' }}
          /> */}
          <i
            className="far fa-clock"
            style={{ paddingRight: '3px', fontSize: '12px' }}
          ></i>
          ({commentVersions.length})
        </div>
      </HtmlTooltip>
      {/* <HtmlTooltip
        // disableFocusListener
        // disableHoverListener
        // disableTouchListener
        // onBlur={handleTooltipClose}
        // onClose={handleTooltipClose}
        // open={open}
        placement="right"
        title={<React.Fragment>{subWarningToolTips}</React.Fragment>}
      >
        <div
          style={{
            fontSize: '9px',
            color:
              subWarnings.length > 0
                ? 'rgba(209,2,27,0.7)'
                : 'rgba(255,255,255,0.1)',
            visibility: subWarnings.length > 0 ? 'visible' : 'hidden'
          }}
          //   onClick={handleTooltipOpen}
        >
          <i className="fas fa-circle" style={{ paddingRight: '3px' }}></i>(
          {subWarnings.length})
        </div>
      </HtmlTooltip> */}
    </div>
  );
}
