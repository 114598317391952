import React, { useState, useContext } from 'react';

// import PersonIcon from '@mui/icons-material/Person';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorageIcon from '@mui/icons-material/Storage';

import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { dataSourceInfoQuery } from '../../../../graphql/queries';
import Button from '@mui/material/Button';

import AddButton from './AddButton';
import EditButton from './EditButton';
import RemoveButton from './RemoveButton';
// import AddAccountUserRelationModal from './add-account-to-data-source-relation-modal/AddAccountUserRelationModal';

// import AccountList from './AccountList';
// import UserList from './UserList';
import AccountList from './AccountList';

import OcidInput from './OcidInput';
import DeactivateButton from './DeactivateButton';

import UseSuperDataSourceSwitch from './UseSuperDataSourceSwitch';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AccountContext } from '../../../../context/AccountContext';
import CustomDataSourceDialog from './custom-data-source/CustomDataSourceDialog';

import DataSourceAlertRules from './data-source-alert-rules/DataSourceAlertRules';

import { AuthContext } from '../../../../context/AuthContext';

function DataSource({
  selectedDataSourceId,
  stylesStoredInReduxManagerAccountSettings
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const { user: currentUser } = useContext(AuthContext);

  const [skip, toggleSkip] = useState('false');

  const variables = {
    dataSourceInfoInput: {
      managerAccountId: currentManagerAccount._id,
      selectedDataSourceId: selectedDataSourceId
    }
  };

  const { data, refetch: refetchDataSourceInfo } = useQuery(
    dataSourceInfoQuery,
    {
      variables,
      fetchPolicy: 'no-cache',
      skip: false
    }
  );
  console.log('data.dataSourceInfo: ', data?.dataSourceInfo);
  const dataSourceRef = data?.dataSourceInfo.dataSourceRef;
  console.log('dataSourceRef: ', dataSourceRef);

  const name = data?.dataSourceInfo?.name;
  const id = data?.dataSourceInfo?.id;
  const _id = data?.dataSourceInfo?._id;
  const user = data?.dataSourceInfo?.user;
  const userName =
    data?.dataSourceInfo?.user?.firstName +
    ' ' +
    data?.dataSourceInfo?.user?.lastName;

  const dataSourceInfo = data?.dataSourceInfo;
  // data &&
  // data.dataSourceInfo &&
  // data.dataSourceInfo.user &&
  // data.dataSourceInfo.user.name;
  const userEmail = data?.dataSourceInfo?.user?.email;
  const role = data?.dataSourceInfo?.role;

  const status = data && data.dataSourceInfo && data.dataSourceInfo.status;

  const accounts = data?.dataSourceInfo?.accounts || [];
  // const selectedDataSourceId = selectedDataSourceId ? selectedDataSourceId : "";

  accounts.sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const statusStyle = {};

  if (status && status.includes('error')) {
    statusStyle.color = 'red';
  }
  if (status && status === 'ok') {
    statusStyle.color = 'green';
  }

  if (!id) {
    return (
      <div
        style={{
          display: 'grid',
          gridColumnStart: 2,
          gridColumnEnd: 2,
          gridTemplateRows: '50px auto',
          height: 'calc(100vh - 143.78px)'
        }}
      >
        <div
          style={{
            gridRowStart: 1,
            gridRowEnd: 1,
            fontSize: '16px',
            padding: '15px 10px'
          }}
        >
          {/* <SettingsIcon style={{verticalAlign: 'bottom'}} /> Settings */}
        </div>

        <div
          style={{
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridRowStart: 2,
            gridRowEnd: 3,
            gridTemplateRows: '70px auto',
            margin: '4px',
            borderRadius: '5px',
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
          }}
        >
          <div
            style={{
              display: 'grid',
              gridColumnStart: 1,
              gridColumnEnd: 1,
              gridRowStart: 1,
              gridRowEnd: 1,
              gridTemplateColumns: 'auto 100px',
              gridTemplateRows: '50px 20px',
              height: '100%',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
              backdropFilter: 'blur(10px)',
              borderRadius: '5px 5px 0px 0px'
            }}
          >
            <div
              style={{
                gridRowStart: 1,
                gridRowEnd: 1,
                gridColumnStart: 1,
                gridColumnEnd: 1,
                height: '100%',
                justifyContent: 'flex-start',
                backgroundColor:
                  stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
                borderRadius: '5px 0px 0px 0px'
              }}
            >
              <div style={{ padding: '25px 30px', fontSize: '16px' }}>
                <StorageIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ marginLeft: '10px' }}>{name}</span>
              </div>
            </div>
            <div
              style={{
                gridRowStart: 2,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 1,
                height: '100%',
                justifyContent: 'flex-start',
                backgroundColor:
                  stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor
              }}
            ></div>
            <div
              style={{
                gridRowStart: 1,
                gridRowEnd: 3,
                gridColumnStart: 2,
                gridColumnEnd: 2,
                height: '100%',
                justifyContent: 'flex-end',
                backgroundColor:
                  stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
                paddingTop: '9px'
              }}
            >
              {/* <EditButton /> */}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
              // gridColumnStart: 1,
              // gridColumnEnd: 1,
              gridRowStart: 2,
              gridRowEnd: 2,
              // gridTemplateRows: '70px repeat(auto-fill, minmax(60px, 60px))',
              height: 'calc(100vh - 213.78px)',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
              backdropFilter: 'blur(10px)',
              borderRadius: '0px 0px 5px 5px',
              overflowY: 'scroll'
              // width: '100%'
            }}
          ></div>
        </div>
      </div>
    );
  }

  return (
    // <div style={{display: 'grid', gridColumnStart: 2, gridColumnEnd: 2, gridTemplateRows: '50px repeat(auto-fill, minmax(46px, 46px))'}}>
    <div
      style={{
        display: 'grid',
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridTemplateRows: '50px auto'
        // height: 'calc(100vh - 143.78px)'
      }}
    >
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 1,
          fontSize: '16px',
          padding: '15px 10px'
        }}
      >
        {/* <SettingsIcon style={{verticalAlign: 'bottom'}} /> Settings */}
      </div>

      <div
        style={{
          height: 'calc(100vh - 143.78px)',
          overflow: 'hidden',
          display: 'grid',
          gridColumnStart: 1,
          gridColumnEnd: 1,
          gridRowStart: 2,
          gridRowEnd: 3,
          gridTemplateRows: '70px auto',
          margin: '4px',
          borderRadius: '5px',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridRowStart: 1,
            gridRowEnd: 1,
            gridTemplateColumns: 'auto 170px',
            gridTemplateRows: '50px 20px',
            height: '100%',
            backgroundColor:
              stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
            backdropFilter: 'blur(10px)',
            borderRadius: '5px 5px 0px 0px'
          }}
        >
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              height: '100%',
              justifyContent: 'flex-start',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
              borderRadius: '5px 0px 0px 0px'
            }}
          >
            <div style={{ padding: '25px 30px', fontSize: '16px' }}>
              <StorageIcon style={{ verticalAlign: 'bottom' }} />
              <span style={{ marginLeft: '10px' }}>{name}</span>
            </div>
          </div>
          <div
            style={{
              gridRowStart: 2,
              gridRowEnd: 2,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              height: '100%',
              justifyContent: 'flex-start',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor
            }}
          >
            <div style={{ fontSize: '10px' }}>
              <span style={{ paddingLeft: '55px', marginLeft: '10px' }}>
                Role: {role}
              </span>{' '}
              <span style={{ paddingLeft: '5px', marginLeft: '10px' }}>
                Account Id: {id}
              </span>
              {/* <span style={{ paddingLeft: '5px', marginLeft: '10px' }}>
                Connected by: {userName} ({userEmail})
              </span> */}
              <span style={{ paddingLeft: '5px', marginLeft: '10px' }}>
                Status: <span style={statusStyle}>{status}</span>
              </span>
              <span style={{ paddingLeft: '5px', marginLeft: '10px' }}>
                Connected by: <span style={{}}>{userName}</span>
              </span>
            </div>
          </div>
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 3,
              gridColumnStart: 2,
              gridColumnEnd: 2,
              height: '100%',
              justifyContent: 'flex-end',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
              paddingTop: '9px'
            }}
          >
            <div
              style={{
                height: '100%',
                width: '140px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <DeactivateButton
                dataSourceInfo={dataSourceInfo}
                refetchDataSourceInfo={refetchDataSourceInfo}
              />
            </div>
            {/* <EditButton /> */}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            // gridColumnStart: 1,
            // gridColumnEnd: 1,
            gridRowStart: 2,
            gridRowEnd: 2,
            // gridTemplateRows: '70px repeat(auto-fill, minmax(60px, 60px))',
            height: 'calc(100vh - 213.78px)',
            backgroundColor:
              stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
            backdropFilter: 'blur(10px)',
            borderRadius: '0px 0px 5px 5px',
            overflowY: 'scroll'
            // width: '100%'
          }}
        >
          {dataSourceRef === 'GoogleAdsDataSource' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                marginTop: '50px',
                padding: '20px 50px',
                width: '100%'
              }}
            >
              <div style={{ paddingLeft: '5px' }}>Settings</div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <OcidInput
                  dataSourceInfo={dataSourceInfo}
                  refetchDataSourceInfo={refetchDataSourceInfo}
                />
              </div>
            </div>
          )}
          {dataSourceRef === 'AdformDataSource' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                marginTop: '50px',
                padding: '20px 50px',
                width: '100%'
              }}
            >
              <div style={{ paddingLeft: '5px' }}>Settings</div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <UseSuperDataSourceSwitch
                  dataSourceInfo={dataSourceInfo}
                  refetchDataSourceInfo={refetchDataSourceInfo}
                />
              </div>
            </div>
          )}
          {dataSourceRef === 'CustomDataSource' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '100px'
              }}
            >
              <CustomDataSourceDialog />
            </div>
          )}
          {/* <UserList users={users} currentManagerAccount={currentManagerAccount} selectedAccountId={selectedAccountId} refetchAccountInfo={refetchAccountInfo} /> */}
          <AccountList
            accounts={accounts}
            selectedDataSourceId={selectedDataSourceId}
            refetchDataSourceInfo={refetchDataSourceInfo}
            stylesStoredInReduxManagerAccountSettings={
              stylesStoredInReduxManagerAccountSettings
            }
          />

          {['system_admin'].includes(currentUser.role) &&
            dataSourceRef === 'GoogleAdsDataSource' && (
              <DataSourceAlertRules dataSourceInfo={dataSourceInfo} />
            )}
        </div>
      </div>
    </div>
  );
}

// export default DataSource

DataSource.propTypes = {
  // currentManagerAccount: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired,
  stylesStoredInReduxManagerAccountSettings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // user: state.auth.user,
  stylesStoredInReduxManagerAccountSettings: state.style.ManagerAccountSettings
});

export default connect(mapStateToProps, {})(DataSource);
