import React, { useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { accountUsersQuery } from './../../../../graphql/queries';

import { useQuery } from '@apollo/client';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     margin: 'auto'
//   },
//   paper: {
//     width: 200,
//     height: 230,
//     overflow: 'auto',
//     backgroundColor: 'rgba(0,0,0,0)'
//   },
//   button: {
//     margin: theme.spacing(0.5, 0)
//   }
// }));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({
  currentManagerAccountId,
  accountId,
  left,
  setLeft,
  right,
  setRight,
  setListOfUsers
}) {
  // ################## get list of account users ##################

  const variables = {
    accountsUsersInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountId
    }
  };

  const { data } = useQuery(accountUsersQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: false
  });

  useEffect(() => {
    let usersList = [];
    const listOfTransflerListItems = [];

    if (data && data.accountUsers) {
      for (let i = 0; i < data.accountUsers.length; i++) {
        let abb = '';
        let name = '';
        let email = '';

        let transflerListItem = '';
        if (
          data.accountUsers[i] &&
          data.accountUsers[i].name &&
          data.accountUsers[i].email
        ) {
          const nameSplit = data.accountUsers[i].name.split(' ');

          for (let x = 0; x < nameSplit.length; x++) {
            abb = abb + nameSplit[x][0];
          }

          name = data.accountUsers[i].name;
          email = data.accountUsers[i].email;

          transflerListItem = name + ' (' + email + ')';

          transflerListItem = name + ' (' + email + ')';
          listOfTransflerListItems.push(transflerListItem);
        } else {
          name = 'unknown user';
          email = '';
          abb = '?';
          transflerListItem = name;
          listOfTransflerListItems.push(transflerListItem);
        }

        usersList.push({
          ...data.accountUsers[i],
          name,
          abb,
          email,
          transflerListItem
        });
      }
    }

    const listOfTransflerListItems2 = listOfTransflerListItems.filter((obj) => {
      return !right.includes(obj);
    });

    // sort userList

    listOfTransflerListItems2.sort();

    usersList.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setListOfUsers(usersList);
    setLeft(listOfTransflerListItems2);
  }, [data]);

  // ###############################################################

  // const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  //   const [left, setLeft] = React.useState([0, 1, 2, 3]);
  //   const [right, setRight] = React.useState([4, 5, 6, 7]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  //   const handleAllRight = () => {
  //     setRight(right.concat(left));
  //     setLeft([]);
  //   };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  //   const handleAllLeft = () => {
  //     setLeft(left.concat(right));
  //     setRight([]);
  //   };

  const customList = (items) => (
    <Paper
      // className={classes.paper}
      style={{
        width: '100%',
        height: '100%',
        border: '1px solid rgba(255,255,255,0.1)',
        overflow: 'scroll'
      }}
    >
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={value !== 'name' && handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  style={{
                    visibility: value == 'name' ? 'hidden' : 'inline'
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      // className={classes.root}
      style={{
        height: '92%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      <Grid
        item
        style={{
          width: '43%',
          height: '100%'
          // height: '40vw'
        }}
      >
        {customList(left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          {/* <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button> */}
          <Button
            variant="outlined"
            size="small"
            // className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            // className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          {/* <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button> */}
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          width: '43%',
          height: '100%'
          // height: '40vw'
        }}
      >
        {customList(right)}
      </Grid>
    </Grid>
  );
}
