import React from 'react';
// import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'center',
//     flexWrap: 'wrap',
//     '& > *': {
//       margin: theme.spacing(0.5)
//     }
//   }
// }));

export default function Chips({ setFilters, filters, setCheckedBoxes }) {
  // const classes = useStyles();

  const handleDelete = () => {
    console.info('You clicked the delete icon.');

    const filteredFilter = filters.filter((filter) => {
      return filter !== 'filter_by_warnings';
    });
    setFilters(filteredFilter);

    setCheckedBoxes([]);
  };

  // const handleClick = () => {
  //   console.info('You clicked the Chip.');
  // };

  return (
    <div
      // className={classes.root}
      style={{ gridRow: 1 }}
    >
      <Chip
        icon={<CheckCircleIcon />}
        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
        label="Filter by Warnings"
        onDelete={handleDelete}
        // label="Primary clickable"
        // clickable
        // deleteIcon={<DoneIcon />}
      />
    </div>
  );
}
