import React, { useState } from 'react';
import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AccountList from './accountlist/AccountList';

import Account from './account/Account';

function Users({ user }) {
  const [selectedAccountId, setSelectedAccountId] = useState('');

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '300px auto',
        gridColumnStart: 2,
        gridColumnEnd: 2
      }}
    >
      <AccountList
        selectedAccountId={selectedAccountId}
        setSelectedAccountId={setSelectedAccountId}
      />
      <Account
        selectedAccountId={selectedAccountId}
        setSelectedAccount={setSelectedAccountId}
      />
    </div>
  );
}

export default Users;
