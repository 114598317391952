import React, { useState } from 'react';

// import PersonIcon from '@mui/icons-material/Person';

// import { userInfoAndAccountsQuery } from '../../../../graphql/queries';
// import Button from '@mui/material/Button';

import AddButton from './AddButton';
import EditButton from './EditButton';
import RemoveButton from './RemoveButton';
import AddAccountUserRelationModal from './add-account-to-data-source-relation-modal/AddAccountToDataSourceRelationModal';

function AccountList({
  accounts,
  selectedDataSourceId,
  refetchDataSourceInfo,
  stylesStoredInReduxManagerAccountSettings
}) {
  const [addAccountUserRelationModalOpen, setAddAccountUserRelationModalOpen] =
    useState(false);

  const accountList = accounts.map((obj) => {
    let permissions = '';
    if (obj && obj.permissions) {
      for (let i = 0; i < obj.permissions.length; i++) {
        if (i === 0) {
          permissions = obj.permissions[i];
        } else {
          permissions = permissions + ' & ' + obj.permissions[i];
        }
      }
    }

    return (
      <div
        key={obj._id}
        style={{
          display: 'grid',
          borderRadius: '5px',
          // height: '100%',
          gridTemplateColumns: 'auto 50px 50px',
          marginTop: '20px',
          // marginLeft: '50px',
          // background: 'rgba(255,255,255,0.05)',
          padding: '15px',
          //   border: '1px solid rgba(255,255,255,0.1)',
          border:
            stylesStoredInReduxManagerAccountSettings.childCardChildBorderColor,
          gridTemplateRows: '30px 20px'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridRowStart: 1,
            gridRowEnd: 1
          }}
        >
          <span>{obj.name}</span>
        </div>
        <div>
          <span
            style={{
              fontSize: '12px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            Permission: {permissions} · account id: {obj._id}
          </span>
        </div>
        <div
          style={{
            display: 'grid',
            gridColumnStart: 2,
            gridColumnEnd: 2,
            justifyContent: 'flex-end',
            gridRowStart: 1,
            gridRowEnd: 1
          }}
        >
          {/* <Button style={{height: '30px'}} variant="outlined" size="small" color="primary">
                      Edit
                  </Button> */}
          {/* <EditButton /> */}
        </div>
        <div
          style={{
            display: 'grid',
            gridColumnStart: 3,
            gridColumnEnd: 3,
            justifyContent: 'flex-end',
            gridRowStart: 1,
            gridRowEnd: 1
          }}
        >
          {/* <Button style={{height: '30px'}} variant="outlined" size="small" color="primary">
                      Remove
                  </Button> */}
          <RemoveButton
            accountId={obj._id}
            selectedDataSourceId={selectedDataSourceId}
            refetchDataSourceInfo={refetchDataSourceInfo}
          />
        </div>
      </div>
    );
  });

  var clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let maxWidth = '';
  let minWidth = '';

  switch (clientWidth) {
    case clientWidth < 1600:
      maxWidth = '90%';
      minWidth = '800px';
      break;
    case clientWidth > 1600:
      maxWidth = '760px';
      minWidth = '800px';
      break;
    default:
      maxWidth = '760px';
  }

  return (
    <div
      style={{
        // display:'grid',
        // gridTemplateRows: '70px auto',
        margin: '3vw auto',
        width: '95%',
        maxWidth: maxWidth,
        minWidth: minWidth
        // maxWidth: '720px',
        // minWidth: '90%'
      }}
    >
      <div
        style={{
          // gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
          // display: 'grid',
          // gridRowStart: 1,
          // gridRowEnd: 5,
          // minWidth: '600px',
          width: '100%'
          // height: '100%',
          // display: 'inline-block'
          // justifyContent: "flex-start"
        }}
      >
        <div
          style={{
            padding: '25px 30px 25px 30px',
            fontSize: '16px',
            // minWidth: '700px',
            // width: '100%',
            // margin:'50px 30px 0px 30px'
            // margin: '100px auto',
            display: 'grid',
            // gridTemplateRows: '45px repeat(auto-fill, minmax(45x, 45px))',
            // background: 'rgba(255,255,255,0.05',
            background:
              stylesStoredInReduxManagerAccountSettings.childCardBackgroundColor,
            borderRadius: '5px',
            height: 'fit-content'
          }}
        >
          <div
            style={{
              display: 'grid',
              gridRowStart: 1,
              gridRowEnd: 1,
              gridTemplateColumns: '50% 50%',
              borderRadius: '5px',
              height: '100%'
            }}
          >
            <div
              style={{ display: 'grid', gridColumnStart: 1, gridColumnEnd: 1 }}
            >
              <span style={{ padding: '10px', fontSize: '20px' }}>
                NexusTable accounts with access to this data connection:
              </span>
            </div>
            <div
              style={{
                display: 'grid',
                gridColumnStart: 2,
                gridColumnEnd: 2,
                justifyContent: 'flex-end',
                paddingRight: '16px'
              }}
            >
              {/* <span style={{marginLeft: '10px'}}>Add account access</span> */}

              {/* <Button variant="outlined" size="small" color="primary">
                                    Add +
                                </Button> */}
              <AddButton setOpen={setAddAccountUserRelationModalOpen} />
            </div>
          </div>
          {accounts.length > 0 && (
            <div
              style={{ display: 'grid', borderRadius: '5px', height: '100%' }}
            >
              {accountList}
            </div>
          )}
        </div>
      </div>
      {addAccountUserRelationModalOpen && (
        <AddAccountUserRelationModal
          open={addAccountUserRelationModalOpen}
          setOpen={setAddAccountUserRelationModalOpen}
          // currentManagerAccount={currentManagerAccount}
          selectedDataSourceId={selectedDataSourceId}
          refetchDataSourceInfo={refetchDataSourceInfo}
        />
      )}
    </div>
  );
}

export default AccountList;
