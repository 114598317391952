import React, { useContext, useEffect, useState } from 'react';
import { InterfaceContext } from '../../../../context/InterfaceContext';
import { AuthContext } from '../../../../context/AuthContext';

import { AccountContext } from '../../../../context/AccountContext';

import { DialogContext } from '../../../../context/DialogContext';

import { FilterContext } from '../../../../context/FilterContext';

import SpinnerSmall from './../../../layout/SpinnerSmall';

import formatDateYYYYMMDD from './../../roitable_functions/formatDateYYYYMMDD';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import {
  timeboxesQuery,
  nexusDocumentTimeboxesQuery
} from '../../../../graphql/queries';

import Table from './Table';

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const convertMinsToHrsMins = (mins) => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${h}:${m}`;
};

function Stats({ isTimeboxerReportOpen }) {
  const { checkedBoxes } = useContext(FilterContext);
  const {
    selectedDateStartTimeboxReport,
    setSelectedDateStartTimeboxReport,
    selectedDateStopTimeboxReport,
    setSelectedDateStopTimeboxReport,
    selectedUserTimeboxer: selectedUser,
    setSelectedUserTimeboxer: setSelectedUser
  } = useContext(InterfaceContext);

  const {
    isTimeboxViewDialogOpen,
    setIsTimeboxViewDialogOpen,
    timeboxViewDialogType
  } = useContext(DialogContext);
  console.log('timeboxViewDialogType: ', timeboxViewDialogType);

  const { currentManagerAccount } = useContext(AccountContext);
  const pollTime = 600000;
  const [refetchTimeboxesCounter, setRefetchTimeboxesCounter] = useState(0);
  const [refetchTimeboxesCounter2, setRefetchTimeboxesCounter2] = useState(0);

  const { user } = useContext(AuthContext);

  // const [selectedUser, setSelectedUser] = useState(user);

  const [timeboxes, setTimeboxes] = useState([]);

  const [filteredTimeBoxIds, setFilteredTimeboxIds] = useState([]);

  useEffect(() => {
    if (!selectedUser) {
      setSelectedUser(user);
    }
  }, [user]);

  // ################## data #######################
  // console.log('selectedDateStartTimebox: ', selectedDateStartTimebox);

  let startDateTime = new Date(selectedDateStartTimeboxReport);
  const offset = startDateTime.getTimezoneOffset();
  // console.log('debug309>offset: ', offset);
  startDateTime = new Date(startDateTime.getTime() - offset * 60 * 1000);
  // console.log('debug309>startDateTime: ', startDateTime);
  let endDateTime = new Date(selectedDateStopTimeboxReport);
  endDateTime = new Date(endDateTime.getTime() - offset * 60 * 1000);
  let startDateTimeSOD = new Date(formatDateYYYYMMDD(startDateTime));
  let endDateTimeEOD = new Date(addDays(endDateTime, 1) - 1000);

  let variables = {};
  let query = '';
  // console.log('variables: ', variables);

  let isReadyToGetData = false;
  const nexusDocumentIds = checkedBoxes.map((obj) => String(obj._id));

  switch (true) {
    case timeboxViewDialogType === 'USERS':
      query = timeboxesQuery;
      variables = {
        input: {
          managerAccountId: currentManagerAccount._id,
          userIds: [selectedUser?._id],
          selectedDateStartTimebox: String(startDateTimeSOD.getTime()),
          selectedDateStopTimebox: String(endDateTimeEOD.getTime())
        }
      };
      if (selectedUser) {
        isReadyToGetData = true;
      }
      break;

    case timeboxViewDialogType === 'NEXUSDOCUMENTS':
      query = nexusDocumentTimeboxesQuery;
      variables = {
        input: {
          managerAccountId: currentManagerAccount._id,
          nexusDocumentIds: nexusDocumentIds,
          selectedDateStartTimebox: String(startDateTimeSOD.getTime()),
          selectedDateStopTimebox: String(endDateTimeEOD.getTime())
        }
      };
      if (nexusDocumentIds.length) {
        isReadyToGetData = true;
      }
      break;
  }
  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(query, {
    variables,

    fetchPolicy: 'no-cache',

    onCompleted: () => {
      console.log('RoiTable>Query completed.');
    },
    onError: (error) => {
      console.log('error: ', error);
    }
  });

  useEffect(() => {
    // console.log('data: ', data);
  }, [data]);

  useEffect(() => {
    if (refetchTimeboxesCounter) {
      refetch();
    }
  }, [refetchTimeboxesCounter]);

  useEffect(() => {
    if (
      currentManagerAccount._id &&
      isTimeboxViewDialogOpen &&
      isReadyToGetData &&
      timeboxViewDialogType
    ) {
      getData();
      startPolling(pollTime);
    }
  }, [
    currentManagerAccount._id,
    selectedUser,
    isTimeboxViewDialogOpen,
    isReadyToGetData
  ]);

  const dataKeyDict = {
    USERS: 'timeboxes',
    NEXUSDOCUMENTS: 'nexusDocumentsTimeboxes'
  };
  useEffect(() => {
    if (data?.[dataKeyDict[timeboxViewDialogType]]) {
      const timeboxes_ = data?.[dataKeyDict[timeboxViewDialogType]]?.map(
        (timebox) => ({
          ...timebox,
          startDateTime:
            timebox.status !== 'TIMEBOX_LIST'
              ? new Date(+timebox.startDateTime)
              : undefined,
          endDateTime:
            timebox.status !== 'TIMEBOX_LIST'
              ? new Date(+timebox.endDateTime)
              : undefined,
          deadline: timebox.deadline && new Date(+timebox.deadline),
          duration: (timebox?.endDateTime - timebox?.startDateTime) / 60000,
          assignedTo:
            timebox?.status === 'TIMEBOX_LIST'
              ? timebox?.assignedToTimeboxList?.name
              : timebox?.assignedToUser?.email
        })
      );
      setTimeboxes(timeboxes_);
    }
  }, [data, refetchTimeboxesCounter2, selectedUser]);

  console.log('Stats>timeboxes: ', timeboxes);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
      >
        <SpinnerSmall />
      </div>
    );
  }

  if (!timeboxes?.length) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
      >
        <span>No data</span>
      </div>
    );
  }

  const filteredTimeboxes = timeboxes.filter((timebox) =>
    filteredTimeBoxIds.includes(timebox._id)
  );

  let totalMinutes = 0;
  for (let timebox of filteredTimeboxes) {
    totalMinutes += timebox.duration;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div
        style={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <span>
          {totalMinutes} minutes{' '}
          {'(' + convertMinsToHrsMins(totalMinutes) + ')'}
        </span>
      </div>
      <Table
        timeboxes={timeboxes}
        timeboxViewDialogType={timeboxViewDialogType}
        filteredTimeBoxIds={filteredTimeBoxIds}
        setFilteredTimeboxIds={setFilteredTimeboxIds}
      />
      {/* <div></div> */}
      {/* <div>
        <table style={{ border: '1px solid grey', borderRadius: '5px' }}>
          <tr style={{ textAlign: 'left' }}>
            <th>Date span</th>
            <th>Deadline</th>
            <th>Status</th>
            <th>Text</th>
            <th>Duration</th>
          </tr>
          {timeboxes?.map((timebox) => {
            console.log('Stats>timebox: ', timebox);
            return (
              <tr style={{ textAlign: 'left', border: '1px solid grey' }}>
                <td
                  style={{
                    border: '1px solid grey',
                    margin: '0px',
                    padding: '0px'
                  }}
                >
                  {String(timebox.startDateTime).split(' GMT')[0] +
                    ' - ' +
                    String(timebox.endDateTime).split(' GMT')[0]}
                </td>
                <td>
                  {timebox?.deadline
                    ? String(timebox?.deadline).split(' GMT')[0]
                    : ''}
                </td>
                <td>{timebox.status}</td>
                <td>{timebox.text}</td>
                <td>{timebox.duration}</td>
              </tr>
            );
          })}
        </table>
      </div> */}
    </div>
  );
}

export default Stats;
