import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useQuery, useMutation } from '@apollo/client';

import {
  DV360ActionTypesQuery
  // dataSourcesQuery
} from '../../../../../../graphql/queries';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     width: '93%'
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

export default function SimpleSelect({
  label,
  // value,
  // setValue,
  // default_value,
  currentManagerAccountId,
  item,
  action,
  setAction
}) {
  // const classes = useStyles();

  const [skip, setSkip] = useState(false);

  const defaultValue = action?.dv360?.activityId || null;

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      accountId: item.accountId,
      dataSourceAccountId: item.dataSourceAdvertiserId
    }
  };

  const { data } = useQuery(DV360ActionTypesQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: false
  });

  useEffect(() => {
    if (data && !skip) {
      setSkip(true);
    }
  }, [data]);

  const menuItems = data?.dv360actiontypes || [];

  menuItems.sort(function (a, b) {
    var nameA = a.activityName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.activityName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const [input, setInput] = useState('');

  const handleChange = (event) => {
    let selectedItem = menuItems.filter(
      (obj) => event.target.value === obj.activityId
    )[0];

    setInput(selectedItem?.id);
    // setValue(selectedItem);

    const dv360Options = action?.dv360 || {};

    setAction({
      ...action,
      // name: selectedItem?.name,
      dv360: {
        ...dv360Options,
        advertiserId: selectedItem?.advertiserId,
        activityName: selectedItem?.activityName,
        activityId: selectedItem?.activityId,
        activityGroupId: selectedItem?.activityGroupId,
        activityGroupName: selectedItem?.activityGroupName,
        attributionType: dv360Options.attributionType || 'default'
      }
    });
  };

  useEffect(() => {
    if (!input) {
      // let selectedItem = menuItems.filter(
      //   (obj) => default_value === obj.type
      // )[0];

      setInput(defaultValue);
      // setValue(selectedItem);
    }
  }, [defaultValue, menuItems]);

  return (
    <div>
      <FormControl
        style={{
          margin: '10px',
          minWidth: 120,
          width: '93%'
        }}
        // className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          variant="standard"
          style={{ fontSize: '14px' }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={input}
          onChange={handleChange}
        >
          <MenuItem style={{ fontSize: '14px' }} value="">
            <em>None</em>
          </MenuItem>
          {menuItems.map((menuItem, i) => (
            <MenuItem
              key={i}
              style={{ fontSize: '14px' }}
              value={menuItem.activityId}
            >
              {menuItem.activityName}
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Some important helper text</FormHelperText> */}
      </FormControl>
    </div>
  );
}
