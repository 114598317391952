import html2canvas from 'html2canvas';

// import { jsPDF } from 'jspdf';

const mockupWidget = async (widget, pres, slide, colorPalette) => {
  // case widget.type === 'TABLE_CHART_WIDGET':
  // case widget.type === 'DATE_PICKER_WIDGET':
  // case widget.type === 'TEXT_WIDGET':
  // case widget.type === 'NUMBER_WIDGET':
  // case widget.type === 'LINE_CHART_WIDGET':
  // case widget.type === 'PIE_CHART_WIDGET':

  // const domElement = document.getElementById(widget.id);

  // ######################################################################################
  // ######################################################################################
  // ######################################################################################

  var wrapperElement = document.createElement('div');

  const styleObject = {};
  for (let styleKey of [
    'height',
    'width',
    'top',
    'left',
    'boxShadow',
    'opacity'
  ]) {
    if (widget[styleKey]) {
      styleObject[styleKey] = widget[styleKey];
    }
  }

  const style = {
    position: 'absolute',

    // left: widget.offsetX + 'px',
    // top: widget.offsetY + 'px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    // margin: '0px 0.5px',
    transform: 'translate(0, 0)',
    // overflow: 'hidden',
    lineBreak: 'anywhere',

    // boxShadow:
    //   !isDraggingThisWidget && 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
    ...styleObject,
    left: '-10000px'
  };

  delete style.opacity;

  // wrapperElement.style = style;

  for (let key of Object.keys(style)) {
    wrapperElement.style[key] = style[key];
  }

  var divElementParent = document.createElement('div');

  divElementParent.style.width = '100%';
  divElementParent.style.height = '100%';
  divElementParent.style.display = 'flex';
  divElementParent.style.flexDirection = 'column';
  divElementParent.style.justifyContent = 'center';
  divElementParent.style.alignItems = 'center';

  var divElement = document.createElement('div');
  // console.log('divElement: ', divElement);
  divElement.style.backgroundImage = `url(${
    widget.mockupImageDataUri || widget.imageUrl
  })`;

  divElement.style.height = '100%';
  divElement.style.width = '100%';
  divElement.style.backgroundSize = 'cover';

  divElement.style.margin = '0 auto';
  divElement.style.overflow = 'hidden';
  divElement.style.display = 'flex';
  divElement.style.alignItems = 'center';
  divElement.style.justifyContent = 'center';
  divElement.style.position = 'relative';

  divElementParent.appendChild(divElement);
  wrapperElement.appendChild(divElementParent);

  document.body.appendChild(wrapperElement);

  // ######################################################################################
  // ######################################################################################
  // ######################################################################################

  // console.log('mockupWidget>domElement: ', domElement);
  const screenDomElement = document.getElementById('screen_' + widget.id);

  // console.log('mockupWidget>screenDomElement: ', screenDomElement);

  // const oldOpacity = domElement.style.opacity;
  // domElement.style.opacity = 1;

  await html2canvas(wrapperElement, {
    backgroundColor: null,
    scale: 1.6,
    // scale: window.devicePixelRatio,
    logging: true,
    letterRendering: 2,
    allowTaint: false,
    useCORS: true
  }).then((canvas) => {
    // var image = canvas.toDataURL('image/png', 1.0);
    var wid;
    var hgt;
    var image = canvas.toDataURL(
      'image/png',
      (wid = canvas.width),
      (hgt = canvas.height)
    );

    // var doc = new jsPDF('l', 'px', [wid, hgt], true);

    const fileName = 'NexusTable_export.pdf';
    // saveAs(image, fileName);

    // var doc = new jsPDF('l', 'px', 'a4');
    // const padding = 0;
    // var hratio = hgt / wid;
    // var width = doc.internal.pageSize.width - padding * 2;
    // var height = width * hratio;

    const imageData = image?.split('data:')?.[1];

    const addImageObj = {
      data: imageData, //
      // data: imageData, //'image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=='
      w: +widget.width.split('px')[0] / 72 / 2,
      h: +widget.height.split('px')[0] / 72 / 2,
      x: +widget.left.split('px')[0] / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2,
      transparency: (widget.opacity - 1) * -100,

      // shadow: widget.type !== 'MOCKUP_WIDGET' &&
      // widget.isShowingCard && {
      //   type: 'outer',
      //   blur: 6,
      //   offset: 2,
      //   angle: 45,
      //   color: '000000',
      //   opacity: 0.2
      // }
      shadow: {
        type: 'outer',
        blur: 4,
        offset: 3,
        angle: 45,
        color: '000000',
        opacity: 0.4
      }
    };

    // if (widget.type === 'MOCKUP_WIDGET') {
    //   // ############################

    //   function dataURLtoFile(dataurl, filename) {
    //     var arr = dataurl.split(','),
    //       mime = arr[0].match(/:(.*?);/)[1],
    //       bstr = atob(arr[arr.length - 1]),
    //       n = bstr.length,
    //       u8arr = new Uint8Array(n);
    //     while (n--) {
    //       u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    //   }

    //   const file = dataURLtoFile(widget.mockupImageDataUri, 'test');

    //   // ############################
    //   delete addImageObj.data;
    //   addImageObj.data = widget.mockupImageDataUri.split('data:')[1];
    //   addImageObj.shadow = {
    //     type: 'outer',
    //     blur: 4,
    //     offset: 3,
    //     angle: 45,
    //     color: '000000',
    //     opacity: 0.4
    //   };
    // }

    slide.addImage(addImageObj);

    // doc.addImage(image, 'PNG', padding, padding, width, height);
    // doc.save('NexusTable_export.pdf');
  });

  if (widget.type === 'MOCKUP_WIDGET' && widget.imageDataUri) {
    var divElementParent4 = document.createElement('div');

    divElementParent4.style.position = 'absolute';
    divElementParent4.style.display = 'flex';
    divElementParent4.style.justifyContent = 'center';
    divElementParent4.style.alignItems = 'center';

    var divElementParent3 = document.createElement('div');

    divElementParent3.height = '100%';
    divElementParent3.width = '100%';
    divElementParent3.position = 'absolute';

    divElementParent3.overflow = 'hidden';
    // background: 'green',
    divElementParent3.display = 'flex';
    divElementParent3.justifyContent = 'center';
    divElementParent3.alignItems = 'center';

    var divElementParent2 = document.createElement('div');

    divElementParent2.style.background = 'white';
    // 'linear-gradient(335deg,rgb(245,245,245), rgb(255,255,255))';

    divElementParent2.style.width = widget?.width.split('px')[0] * 0.95 + 'px';
    divElementParent2.style.height =
      widget?.height.split('px')[0] * 0.86 + 'px';
    // divElementParent2.style.marginBottom = '4px';
    // position: 'absolute',
    // borderRadius: '50px',

    divElementParent2.style.overflow = 'hidden';
    // background: 'red',
    divElementParent2.style.display = 'flex';
    divElementParent2.style.justifyContent = 'center';
    divElementParent2.style.alignItems = 'center';
    // zIndex: 100

    var divElement2 = document.createElement('div');

    divElement2.style.backgroundImage = `url(${
      widget.imageDataUri || widget.imageUrl
    })`;

    divElement2.style.aspectRatio = widget?.imageNaturalAspectRatio; //`${widget.imageNaturalWidth} / ${widget.imageNaturalHeight}`,

    divElement2.style.width = '100%';

    divElement2.style.backgroundSize = 'cover';
    divElement2.style.backgroundPosition = 'center';
    divElement2.style.backgroundRepeat = 'no-repeat';
    divElement2.style.backgroundAttachment = 'fixed';

    divElement2.style.display = 'flex';
    divElement2.style.alignItems = 'center';
    divElement2.style.justifyContent = 'center';
    divElement2.style.position = 'relative';

    // divElement2.style.height = '100%';

    divElementParent2.appendChild(divElement2);

    divElementParent3.appendChild(divElementParent2);
    divElementParent4.appendChild(divElementParent3);
    document.body.appendChild(divElementParent4);

    await html2canvas(divElementParent4, {
      backgroundColor: null,
      scale: 2,
      // scale: window.devicePixelRatio,
      logging: true,
      letterRendering: 2,
      allowTaint: false,
      useCORS: true
    }).then((canvas) => {
      // var image = canvas.toDataURL('image/png', 1.0);
      var wid;
      var hgt;
      var image = canvas.toDataURL(
        'image/png',
        (wid = canvas.width),
        (hgt = canvas.height)
      );

      let imageData = image.split('data:')[1];

      const safeSide = (+widget.width.split('px')[0] * (1 - 0.95)) / 2;
      const safeTop = (+widget.height.split('px')[0] * (1 - 0.86)) / 2;

      const addImageObj = {
        data: imageData, //'image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=='
        w: (+widget.width.split('px')[0] / 72 / 2) * 0.95,
        h: (+widget.height.split('px')[0] / 72 / 2) * 0.86,
        x: (+widget.left.split('px')[0] + safeSide) / 72 / 2,
        y: (+widget.top.split('px')[0] + safeTop) / 72 / 2,
        transparency: (widget.opacity - 1) * -100
      };

      slide.addImage(addImageObj);

      divElement2.remove();
      divElementParent2.remove();
      divElementParent3.remove();
      divElementParent4.remove();

      // doc.addImage(image, 'PNG', padding, padding, width, height);
      // doc.save('NexusTable_export.pdf');
    });
  }

  // domElement.style.opacity = oldOpacity;
};

export default mockupWidget;
