import React from 'react';

import RoasPercentSwitch from './components/RoasPercentSwitch';

import { styled, useTheme } from '@mui/material/styles';

function RightClickColumnMenu() {
  const theme = useTheme();
  return (
    <div
      style={{
        width: '150px',
        height: '50px',
        // background: 'red',
        borderRadius: '5px',
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgba(58, 58, 66,0.9)'
            : 'rgba(255,255,255,0.9)',
        backdropFilter: 'blur(5px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <RoasPercentSwitch />
    </div>
  );
}

export default RightClickColumnMenu;
