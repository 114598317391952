import React, { useEffect, useState } from 'react';

// import { useLazyQuery } from '@apollo/client';
// import { managerAccountUsersQuery } from '../../../../graphql/queries';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function SelectUsers({
  dataSourceAlertRuleInput,
  setDataSourceAlertRuleInput,
  users,
  disabled
}) {
  console.log(
    'dataSourceAlertRuleInput?.alertUserIds: ',
    dataSourceAlertRuleInput?.alertUserIds
  );
  useEffect(() => {
    if (dataSourceAlertRuleInput?.alertUserIds?.length && users.length) {
      const userIds = dataSourceAlertRuleInput?.alertUserIds;
      const alertUsers = users.filter((user) => userIds.includes(user._id));
      setValue(alertUsers);
    }
  }, [dataSourceAlertRuleInput, users]);
  const [value, setValue] = useState([]);
  const onChangeHandler = (event, newValue) => {
    console.log('newValue: ', newValue);
    setDataSourceAlertRuleInput({
      ...dataSourceAlertRuleInput,
      alertUserIds: newValue.map((obj) => obj._id)
    });
    setValue(newValue);
  };
  return (
    <Autocomplete
      disabled={disabled}
      size="small"
      multiple
      id="tags-outlined"
      options={users || []}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      value={value}
      onChange={(event, newValue) => {
        onChangeHandler(event, newValue);
      }}
      // defaultValue={[null]}
      filterSelectedOptions
      // filterOptions={(options, state) =>
      //   options.filter(({ _id }) => _id === state.inputValue.toLowerCase())
      // }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Users to alert"
          placeholder="Select user"
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
    />
  );
}

// const options = users || [];

export default SelectUsers;
