import React, { useContext } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// function PrivateRoute({ children, ...rest }) {
//   let auth = useAuth();
//   return (
//     <Route
//       {...rest}
//       render={() => (auth ? children : <Redirect to="/login" />)}
//     />
//   );
// }

import { AuthContext } from '../../context/AuthContext';

const PrivateRoute = ({
  children,
  // location,
  // component: Component,
  // auth: { isAuthenticated, loading },
  ...rest
}) => {
  // location.state = { from: location };
  const { isAuthenticated } = useContext(AuthContext);

  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    // <Navigate
    //   to={{
    //     pathname: '/login',
    //     state={{ path: location.pathname }}
    //     // state: { from: location }
    //   }}
    // />
    <Navigate
      to="/login"
      replace
      state={{ path: location?.pathname, search: location?.search }}
    />
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          children
        ) : (
          <Navigate
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

// {{
//   pathname: '/login',
//   state: { from: location }
// }}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
