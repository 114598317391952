import uuid from 'uuid';
import { SET_STYLE } from './types';

import { lightStyle, darkStyle, datePickerStyleDarkMode } from './../styles';

let active_style = {};
export const setStyle = (style_type) => async (dispatch) => {
  switch (style_type) {
    case 'dark':
      active_style = darkStyle;
      // datePickerStyleDarkMode();
      // return active_style;
      break;
    case 'light':
      active_style = lightStyle;
      // datePickerStyleDarkMode();
      // return active_style;
      break;
  }

  // document.body.style.backgroundColor = active_style.body.backgroundColor;

  dispatch({
    type: SET_STYLE,
    payload: active_style
  });
};
