import React, { useContext } from 'react';
import { Button } from '@mui/material';

import AssessmentIcon from '@mui/icons-material/Assessment';

import { InterfaceContext } from '../../../context/InterfaceContext';

import { DialogContext } from '../../../context/DialogContext';

import { useTheme } from '@emotion/react';

import Tooltip from '@mui/material/Tooltip';

function TimeboxerButton() {
  const theme = useTheme();
  const { isReportBuilderOpen, setIsReportBuilderOpen } =
    useContext(DialogContext);

  const buttonSize = '60px';
  const size = '40px';
  return (
    <>
      <Tooltip
        // followCursor
        // PopperProps={{
        //   style: {
        //     visibility: isDragging ? 'hidden' : 'visible'
        //   }
        // }}
        title={'Report Builder'}
        placement="top"
        arrow
        // enterDelay={200}
        // leaveDelay={0}
      >
        <Button
          style={{
            borderRadius: '30px',
            width: buttonSize,
            minWidth: buttonSize,
            height: buttonSize,
            minHeight: buttonSize
            // backdropFilter: 'blur(5px)'
            // margin: '10px'
          }}
          onClick={() => setIsReportBuilderOpen(!isReportBuilderOpen)}
        >
          <div
            style={{
              borderRadius: '30px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: size,
              minWidth: size,
              height: size,
              minHeight: size,
              backdropFilter: theme?.palette?.mode !== 'dark' && 'blur(5px)'
              // margin: '10px'
            }}
          >
            <AssessmentIcon
              style={{
                fontSize: '40px',
                color: theme?.palette?.mode === 'dark' ? 'white' : '#a72683'
              }}
            />
          </div>
        </Button>
      </Tooltip>
    </>
  );
}

export default TimeboxerButton;
