import { pieChart } from './pieChart';
import { comboChart } from './comboChart';
import { lineChart } from './lineChart';
import { numberChart } from './numberChart';
import { tableChart } from './tableChart';
import { mockupWidget } from './mockupWidget';

export const calcWidget = (
  isReportBuilderOpen,
  reportPage,
  widget,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData
) => {
  if (
    !isReportBuilderOpen ||
    reportPage?.isDataFrozen ||
    widget?.isDataFrozen
  ) {
    return widget;
  }
  let copyOfWidget = null;
  switch (widget.type) {
    case 'PIE_CHART_WIDGET':
      copyOfWidget = pieChart(
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      );
      break;
    case 'LINE_CHART_WIDGET':
      copyOfWidget = lineChart(
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      );
      break;
    case 'COMBO_CHART_WIDGET':
      copyOfWidget = comboChart(
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      );
      break;
    case 'NUMBER_WIDGET':
      copyOfWidget = numberChart(
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      );
      break;
    case 'TABLE_CHART_WIDGET':
      copyOfWidget = tableChart(
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      );
      break;
    case 'MOCKUP_WIDGET':
      copyOfWidget = mockupWidget(widget);
      break;
    default:
      return widget;
  }

  return copyOfWidget;
};
