// const Joi = require('@hapi/joi');

const UserToManagerAccountRelation_mongoose = require('../../models/UserToManagerAccountRelation');

const UserToAccountRelation_mongoose = require('../../models/UserToAccountRelation');

const User_mongoose = require('../../models/User');

const Account_mongoose = require('../../models/Account');

const ReportAsset_mongoose = require('../../models/ReportAsset');

const S3BucketFile_mongoose = require('../../models/S3BucketFile');

const requireAuth = require('../requireAuth');
const requireUserRole = require('../requireUserRole');

const idSchema = require('../../functions/inputValidations/JoiSchemas/idSchema');
const stringSchema = require('../../functions/inputValidations/JoiSchemas/stringSchema');
const numberSchema = require('../../functions/inputValidations/JoiSchemas/numberSchema');
const dateSchema = require('../../functions/inputValidations/JoiSchemas/dateSchema');

const reportAssetInfo = async (
  root,
  { input: { managerAccountId, accountIds, reportAssetId } },
  { userId, userRole }
) => {
  requireAuth(userId);
  requireUserRole(userRole, ['system_admin', 'user', 'nexus_assistant']);

  try {
    const value = await idSchema.validateAsync({
      id: managerAccountId
    });
  } catch (err) {
    throw new Error('Input validation error error 1');
  }

  const userToManagerAccountRelation = (
    await UserToManagerAccountRelation_mongoose.find({
      managerAccountId,
      userId
      // role: 'admin'
    })
  )[0];

  if (!userToManagerAccountRelation) {
    throw new Error('Access denied');
  }

  // for (let accountId of accountIds) {
  //   try {
  //     const value = await idSchema.validateAsync({
  //       id: accountId
  //     });
  //   } catch (err) {
  //     throw new Error('Input validation error error 1');
  //   }

  //   const userToAccountRelation = (
  //     await UserToAccountRelation_mongoose.find({
  //       accountId,
  //       userId
  //       // role: 'admin'
  //     })
  //   )[0];

  //   if (!userToAccountRelation) {
  //     throw new Error('Access denied');
  //   }
  // }

  const reportAsset = (
    await ReportAsset_mongoose.find({ _id: reportAssetId })
      .populate('imageFileId')
      .select([
        '_id',
        'id',
        'name',
        'status',
        // 'imageDataUri',
        'imageNaturalHeight',
        'imageNaturalWidth',
        'imageNaturalAspectRatio',
        'managerAccountIds',
        'accountIds',
        'key',
        'bucket',
        'type'
      ])
  )[0];
  // console.log('reportAsset: ', reportAsset);
  // console.log('reportAsset?.imageFileId: ', reportAsset?.imageFileId);

  if (!reportAsset || !reportAsset?.imageFileId) {
    throw new Error('jshfdjkls');
  }

  let hasAccess = false;
  // console.log('reportAsset: ', reportAsset);
  // console.log(
  //   'reportAsset?.managerAccountIds:',
  //   reportAsset?.managerAccountIds.length
  // );

  switch (true) {
    case Boolean(reportAsset?.managerAccountIds?.length):
      // console.log(' case reportAsset?.managerAccountIds?.length:');

      const userToManagerAccountRelation_ = (
        await UserToManagerAccountRelation_mongoose.find({
          managerAccountId: { $in: reportAsset?.managerAccountIds },
          userId
          // role: 'admin'
        })
      )[0];

      if (userToManagerAccountRelation_) {
        hasAccess = true;
      }
      break;

    case Boolean(reportAsset?.accountIds?.length):
      const userToAccountRelation_ = (
        await UserToAccountRelation_mongoose.find({
          accountId: { $in: reportAsset?.accountIds },
          userId
          // role: 'admin'
        })
      )[0];
      if (userToAccountRelation_) {
        hasAccess = true;
      }
      break;
  }

  if (!hasAccess) {
    throw new Error('asdklfmsldksl');
  }

  // console.log('reportAsset: ', reportAsset);
  const imageDataUri = await reportAsset?.imageFileId?.imageDataUri;
  // console.log('imageDataUri: ', imageDataUri);

  const copyOfReportAsset = { ...reportAsset.toObject() };
  copyOfReportAsset.imageDataUri = imageDataUri;

  return copyOfReportAsset;
};

module.exports = ReportAssetInfo = reportAssetInfo;
