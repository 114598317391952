import React from 'react';
import Fab from '@mui/material/Fab';

// import { makeStyles } from '@mui/styles';

import amazonAdsLogo from './amazon_ads_logo.png';

function AmazonAdsButton({ onClickHandler }) {
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     '& > *': {
  //       // margin: theme.spacing(1),
  //       borderRadius: '5px',
  //       height: '20px',
  //       width: '37px',
  //       // backgroundColor: '#1877F2',
  //       backgroundColor: 'white',
  //       '&:hover': {
  //         // backgroundColor: '#1877F2',
  //         backgroundColor: 'white',
  //         //   background: 'rgba(105,109,114,1)'
  //         boxShadow:
  //           '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.32)'
  //       }
  //     }
  //   },
  //   extendedIcon: {
  //     color: 'white',
  //     marginRight: theme.spacing(1)
  //   }
  // }));

  // const classes = useStyles();

  return (
    <div
    // className={classes.root}
    >
      <Fab
        className="text-transform-unset"
        // ref={anchorRef}
        // aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="add"
        style={{
          borderRadius: '5px',
          height: '60px',
          minHeight: '60px',
          width: '250px',
          minWidth: '250px',
          // backgroundColor: true
          //     ? 'rgba(0, 181, 8, 0.9)'
          //     : 'rgba(0, 181, 8, 0.3)',
          color: 'black',
          fontSize: '14px'
          // fontWeight: '800'
        }}
        onClick={(e) => onClickHandler(e, 'amazon_ads')}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center'
            // padding: '1px',
            // background: 'yellow',
            // borderRadius: '4px',
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            src={amazonAdsLogo}
            alt="Logo"
            style={{
              width: '40%',
              // alignSelf: 'baseline',
              margin: '0px 5px'
            }}
          />
        </div>
        {/* <span style={{ marginLeft: '7px' }}>Amazon Ads</span> */}
      </Fab>
    </div>
  );
}

export default AmazonAdsButton;
