import React, { useState } from 'react';

import { TextField } from '@mui/material';

function TaxonomyWidgetList() {
  const [search, setSearch] = React.useState('');
  const widgets = [
    { name: '-', type: 'STRING', value: '-', defaultValue: '' },
    { name: '_', type: 'STRING', value: '_', defaultValue: '' },
    { name: '|', type: 'STRING', value: '|', defaultValue: '' },
    { name: '#', type: 'STRING', value: '#', defaultValue: '' },
    { name: '(', type: 'STRING', value: '(', defaultValue: '' },
    { name: ')', type: 'STRING', value: ')', defaultValue: '' },
    { name: '[', type: 'STRING', value: '[', defaultValue: '' },
    { name: ']', type: 'STRING', value: ']', defaultValue: '' },
    { name: '/', type: 'STRING', value: '/', defaultValue: '' },
    {
      name: 'Free text',
      type: 'FREETEXT',
      value: 'Lorem ipsum dolor sit amet',
      defaultValue: ''
    },
    {
      name: 'Static text',
      type: 'STATICTEXT',
      value: 'STATIC TEXT',
      defaultValue: ''
    },

    { name: 'List', type: 'LIST', value: '', defaultValue: '' },
    {
      name: 'This year YYYY',
      type: 'THISYEAR',
      value: new Date().getFullYear(),
      defaultValue: ''
    },
    {
      name: 'This month M',
      type: 'THISMONTH',
      value: new Date().getMonth() + 1,
      defaultValue: ''
    },
    {
      name: 'This month MM',
      type: 'THISMONTH',
      value:
        (new Date().getMonth() + 1).length !== 2
          ? '0' + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1,
      defaultValue: ''
    },
    {
      name: 'Today D',
      type: 'TODAY',
      value: new Date().getDate(),
      defaultValue: ''
    },
    {
      name: 'Today DD',
      type: 'TODAY',
      value:
        new Date().getDate().toString().length !== 2
          ? '0' + new Date().getDate()
          : new Date().getDate(),
      defaultValue: ''
    }
  ];

  //   const dragStart = (e, value) => {
  //     const target = e.target;
  //     console.log('value: ', value);
  //     e.dataTransfer.setData('droppedItem', JSON.stringify(value));
  //     setTimeout(() => {
  //       target.style.display = 'none';
  //     }, 0);
  //   };

  const dragStart = (e, value, origin, originIndex) => {
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({ value, origin, originIndex })
    );
    console.log('{ value, origin, originIndex }: ', {
      value,
      origin,
      originIndex
    });
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <div style={{}}>
      <div
        style={{
          padding: '0 0 10px 10px'
          // background: 'rgba(255,255,255,0.6)'
        }}
      >
        <TextField
          size="small"
          variant="standard"
          id="outlined-controlled"
          label="Search"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: '100%',
          //   height: '100%',
          // background: 'rgba(255,255,255,0.6)',
          padding: '10px',
          rowGap: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          //   maxHeight: 'calc(71vh - 64px)',
          overflowY: 'scroll',
          // height: '100%'
          height: 'calc(85vh - 78px)'
          // height: '80vh'
          //   maxHeight: 'calc(100vh - 64px)'
        }}
      >
        {widgets.map((widget, index) => (
          <div
            onDragOver={(e) => dragOver(e, widget)}
            draggable={true}
            onDragStart={(e) =>
              dragStart(e, widget, 'taxonomy_widget_list', index)
            }
            onDragEnd={(e) => dragEnd(e, widget)}
            style={{
              background: 'lightgrey',
              // margin: '10px',
              padding: '5px',
              borderRadius: '10px',
              transform: 'translate(0, 0)',
              color: 'black',
              fontSize: '14px',
              display: 'inline-block'
            }}
          >
            {widget.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TaxonomyWidgetList;
