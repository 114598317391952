import React, { useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { userTokensAndConnectionsQuery } from '../../../../graphql/queries';

import TokenList from './token-list/TokenList';
import ConnectionList from './connection-list/ConnectionList';

import { AuthContext } from '../../../../context/AuthContext';
import { AccountContext } from '../../../../context/AccountContext';

function TokensAndConnections({
  selectedUserId,
  stylesStoredInReduxManagerAccountSettings
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  const { user: currentUser } = useContext(AuthContext);

  const variables = {
    userTokensAndConnectionsInput: {
      managerAccountId: currentManagerAccount?._id,
      selectedUserId: selectedUserId
    }
  };

  const [
    loadTokensAndConnections,
    { called, loading, data, refetch: refetchTokensAndConnections }
  ] = useLazyQuery(userTokensAndConnectionsQuery, {
    variables,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    loadTokensAndConnections();
  }, [currentManagerAccount, selectedUserId]);

  const tokens = data?.userTokensAndConnections?.tokens || [];
  //   const tokens = [];
  const connections = data?.userTokensAndConnections?.dataConnections || [];
  // console.log('connections: ', connections);
  return (
    <div>
      <TokenList
        tokens={tokens}
        selectedUserId={selectedUserId}
        refetchUserInfo={refetchTokensAndConnections}
        stylesStoredInReduxManagerAccountSettings={
          stylesStoredInReduxManagerAccountSettings
        }
      />
      <ConnectionList
        connections={connections}
        selectedUserId={selectedUserId}
        refetchUserInfo={refetchTokensAndConnections}
        stylesStoredInReduxManagerAccountSettings={
          stylesStoredInReduxManagerAccountSettings
        }
      />
    </div>
  );
}

export default TokensAndConnections;
