import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';

import { Button } from '@mui/material';

import { useInView } from 'react-intersection-observer';
import { InterfaceContext } from '../../../../context/InterfaceContext';
import { MutationContext } from '../../../../context/MutationContext';

const differenceInDaysFloor = (start, stop) => {
  // var date1 = new Date(start);
  // var date2 = new Date(stop);
  // var Difference_In_Time = date2.getTime() - date1.getTime();
  // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(start);
  const secondDate = new Date(stop);

  const diffDays = Math.floor((secondDate - firstDate) / oneDay);

  return diffDays;
};

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function CheckedAtItem({
  currentManagerAccountId,
  // updateDocument,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  openRows,
  checked
}) {
  const { updateDocument } = useContext(MutationContext);
  const { enqueueSnackbar } = useSnackbar();

  const { exportMode, isAdvancedModeOn } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  // ################### update #######################

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const timeDiff = new Date() - new Date(item.checkedAt);

    if (timeDiff < 36000) {
      enqueueSnackbar('The button was recently clicked. Wait a bit.', {
        variant: 'error'
      });
      return;
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          checkedAt: new Date()
        }
      ],
      updateKeys: [itemKey]
    };

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });

      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };

  // ##################################################

  let spanStyle = {
    margin: 'auto'
  };
  if (true) {
    spanStyle = { textAlign: 'right' };
  }
  const label = { inputProps: { 'aria-label': 'Template' } };

  let buttonColor = null;
  let buttonBorderColor = null;
  let color = 'white';

  const daysSinceCheckedAt = differenceInDaysFloor(
    new Date(+item.checkedAt),
    new Date()
  );

  switch (true) {
    case ['COMPLETED', 'PAUSED', 'REMOVED'].includes(item.status):
      break;
    case daysSinceCheckedAt === 0:
      buttonColor = 'rgba(67, 222, 67, 1)';
      buttonBorderColor = 'rgba(67, 222, 67, 0.5)';
      break;
    case daysSinceCheckedAt === 1:
      // buttonColor = 'yellow';
      buttonColor = 'rgba(255, 255, 0, 1)';
      buttonBorderColor = 'rgba(255, 255, 0, 0.5)';
      color = 'black';
      break;
    case daysSinceCheckedAt === 2:
      buttonColor = 'rgba(255, 165, 0, 1)';
      buttonBorderColor = 'rgba(255, 165, 0, 0.5)';
      break;
    case daysSinceCheckedAt >= 3:
      buttonColor = 'rgba(208, 2, 27,1)';
      buttonBorderColor = 'rgba(208, 2, 27, 0.5)';
      break;
  }

  // console.log('item.daysSinceCheckedAt: ', item.daysSinceCheckedAt);

  if (
    ([
      'folder',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'microsoft_invest_insertion_order',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group',
      'manualDataEntry'
      // 'task'
    ].includes(item.type) ||
      item.isSet) &&
    !item.isSetChild &&
    item.status === 'ACTIVE'
  ) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          borderStyle: 'solid',
          borderWidth: '0px 1px 1px 0px',
          borderColor: itemStyle['borderColor'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          left: '53px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          //   padding: '10px 7px',
          // padding: '10px 14px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {(inView || exportMode) && (
          <div
            style={{ maxHeight: '46px', maxWidth: '46px' }}
            // onClick={(e) => onDivClick(e)}
          >
            {/* <CheckBox
            size="small"
            style={{ maxHeight: '46px', maxWidth: '46px' }}
            // checked={item[itemKey]}
         
            onClick={onSubmitHandler}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          /> */}
            <Button
              size="small"
              style={{
                maxWidth: '32px',
                minWidth: '32px',
                width: '32px',
                backgroundColor: buttonColor,
                border: buttonBorderColor && '1px solid ' + buttonBorderColor,
                color
              }}
              onClick={onSubmitHandler}
            >
              {daysSinceCheckedAt || 0}
            </Button>
            {/* <Checkbox
            {...label}
            checked={item[itemKey]}
            onChange={onSubmitHandler}
            size="small"
          /> */}
            {/* <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        name="checkedI"
      /> */}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '12px 15px',
          whiteSpace: 'nowrap'
        }}
      >
        <span style={spanStyle}>
          <p>
            {/* {itemValue && parseFloat(itemValue.toFixed(2)).toLocaleString()} */}
            {/* {itemValue && itemValue} */}
          </p>
          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </span>
      </div>
    );
  }
}

export default React.memo(CheckedAtItem);
