import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import WarningMenu from './WarningMenu';

export default function WarningMenuPopover({
  anchorEl,
  setAnchorEl,
  item,
  positionRight
}) {
  //   const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Popover
        followCursor
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          positionRight && {
            vertical: 'top',
            horizontal: 'right'
          }
        }
        transformOrigin={
          positionRight && {
            vertical: 'top',
            horizontal: 'left'
          }
        }
      >
        <WarningMenu item={item} />
      </Popover>
    </div>
  );
}
