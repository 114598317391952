import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

const parse_google_ads_data = (
  googleadscampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = googleadscampaign?.action01?.dataSource;
  const action02DataSource = googleadscampaign?.action02?.dataSource;
  const action03DataSource = googleadscampaign?.action03?.dataSource;

  const action01DataSourceIsGoogleAds = action01DataSource === 'googleAds';
  const action02DataSourceIsGoogleAds = action02DataSource === 'googleAds';
  const action03DataSourceIsGoogleAds = action03DataSource === 'googleAds';

  const action01Type = googleadscampaign?.action01?.googleAds?.type;

  const action02Type = googleadscampaign?.action02?.googleAds?.type;

  const action03Type = googleadscampaign?.action03?.googleAds?.type;

  const action01Name = googleadscampaign?.action01?.name;

  const action02Name = googleadscampaign?.action02?.name;

  const action03Name = googleadscampaign?.action03?.name;

  const campaign = {
    ...googleadscampaign,
    ...campaignBaseValues,
    // dataSourceData: true,
    documentType: 'googleAds',
    type: 'google_ads_campaign'
  };

  const runCostFormula = campaign.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (
    googleadscampaign &&
    googleadscampaign.googleadsadgroups &&
    googleadscampaign.googleadsadgroups.length > 0
  ) {
    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adgroup_object of googleadscampaign.googleadsadgroups) {
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      let outbound_clicks = null;

      if (action01DataSourceIsGoogleAds) {
        switch (action01Type) {
          case 'conversions':
            action01Count =
              action01Count + (adgroup_object.metrics.conversions || null);
            action01Value =
              action01Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (
              googleadscampaign &&
              googleadscampaign.googleadsadgroupconversions
            ) {
              for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                if (
                  adgroup_conversion_object &&
                  adgroup_conversion_object.segments &&
                  adgroup_conversion_object.segments &&
                  adgroup_conversion_object.segments.conversionAction ===
                    action01Type &&
                  adgroup_conversion_object.adGroup &&
                  adgroup_conversion_object.adGroup.id ===
                    adgroup_object.adGroup.id &&
                  adgroup_conversion_object.segments.date ===
                    adgroup_object.segments.date
                ) {
                  action01Count =
                    action01Count +
                    (adgroup_conversion_object.metrics.allConversions || null);
                  action01Value =
                    action01Value +
                    (adgroup_conversion_object.metrics.allConversionsValue ||
                      null);
                }
              }
            }
        }
      }

      if (action02DataSourceIsGoogleAds) {
        switch (action02Type) {
          case 'conversions':
            action02Count =
              action02Count + (adgroup_object.metrics.conversions || null);
            action02Value =
              action02Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (
              googleadscampaign &&
              googleadscampaign.googleadsadgroupconversions
            ) {
              for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                if (
                  adgroup_conversion_object &&
                  adgroup_conversion_object.segments &&
                  adgroup_conversion_object.segments &&
                  adgroup_conversion_object.segments.conversionAction ===
                    action02Type &&
                  adgroup_conversion_object.adGroup &&
                  adgroup_conversion_object.adGroup.id ===
                    adgroup_object.adGroup.id &&
                  adgroup_conversion_object.segments.date ===
                    adgroup_object.segments.date
                ) {
                  action02Count =
                    action02Count +
                    (adgroup_conversion_object.metrics.allConversions || null);
                  action02Value =
                    action02Value +
                    (adgroup_conversion_object.metrics.allConversionsValue ||
                      null);
                }
              }
            }
        }
      }

      if (action03DataSourceIsGoogleAds) {
        switch (action03Type) {
          case 'conversions':
            action03Count =
              action03Count + (adgroup_object.metrics.conversions || null);
            action03Value =
              action03Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (
              googleadscampaign &&
              googleadscampaign.googleadsadgroupconversions
            ) {
              for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                if (
                  adgroup_conversion_object &&
                  adgroup_conversion_object.segments &&
                  adgroup_conversion_object.segments &&
                  adgroup_conversion_object.segments.conversionAction ===
                    action03Type &&
                  adgroup_conversion_object.adGroup &&
                  adgroup_conversion_object.adGroup.id ===
                    adgroup_object.adGroup.id &&
                  adgroup_conversion_object.segments.date ===
                    adgroup_object.segments.date
                ) {
                  action03Count =
                    action03Count +
                    (adgroup_conversion_object.metrics.allConversions || null);
                  action03Value =
                    action03Value +
                    (adgroup_conversion_object.metrics.allConversionsValue ||
                      null);
                }
              }
            }
        }
      }

      // const costScope = {
      //   cost: parseFloat(adgroup_object.metrics.costMicros) / 1000000 || 0
      // };

      // const cost = runCostFormula
      //   ? evaluateFormula(campaign.costFormula, costScope)
      //   : parseFloat(adgroup_object.metrics.costMicros) / 1000000 || null;

      const formulaFields = [
        // 'plannedGross',
        // 'plannedNet',
        // 'plannedNetNet',
        // 'plannedCtc',

        // 'plannedTrp',
        // 'plannedTechFee',
        // 'plannedAdOpsFee',
        // 'plannedAdServingCost',
        // 'plannedFeePercent',

        // 'budget',
        // 'kpi',
        // 'plannedImpressions',
        // 'plannedClicks',
        // 'plannedCtr',
        // 'plannedCpc',
        // 'plannedReach',
        // 'plannedCpm',
        // 'plannedFrequency',
        // 'plannedActionCount',
        // 'plannedActionValue',
        // 'plannedActionRoas',
        // 'plannedCpa',
        'reach',
        'cost',
        'impressions',
        'clicks',
        'action01Count',
        'action02Count',
        'action03Count',
        'action01Value',
        'action02Value',
        'action03Value'
      ];

      const generateScope = (formulaField, calcObject) => {
        switch (formulaField) {
          case 'cost':
            return {
              cost: parseFloat(calcObject.metrics.costMicros) / 1000000 || 0
            };

          case 'impressions':
            return {
              impressions: parseInt(adgroup_object.metrics.impressions) || 0
            };
          case 'clicks':
            return {
              clicks: parseInt(adgroup_object.metrics.clicks) || 0
            };
          case 'action01Count':
          case 'action02Count':
          case 'action03Count':
          case 'action01Value':
          case 'action02Value':
          case 'action03Value':
            return {
              [formulaField]: calcObject[formulaField] || 0
            };

          default:
            return {};
        }
      };

      const calculatedFields = {};

      // for (let formulaField of formulaFields) {
      //   calculatedFields[formulaField] = evaluateFormula(
      //     campaign[formulaField + 'Formula'],
      //     generateScope(formulaField, adgroup_object)
      //   );
      // }

      for (let formulaField of formulaFields) {
        calculatedFields[formulaField] = evaluateFormula(
          '=' + formulaField,
          generateScope(formulaField, adgroup_object)
        );
      }

      listOfPropagationData.push({
        ...adgroup_object,
        ...calculatedFields,
        date_start: adgroup_object.segments.date,
        date_stop: adgroup_object.segments.date,
        // cost: cost,
        // impressions: parseInt(adgroup_object.metrics.impressions) || null,
        // clicks: parseInt(adgroup_object.metrics.clicks) || null,
        action01Type,
        action01Count,
        action01Value,

        action02Type,
        action02Count,
        action02Value,

        action03Type,
        action03Count,
        action03Value,
        // outbound_clicks,
        // clicks: parseInt(adgroup_object.metrics.clicks),
        accountId: campaignBaseValues.accountId
      });

      // push adset_id to then make unique later and loop
      listOfAdSetIds.push(adgroup_object.adGroup.id);
    }

    const uniqueListOfAdSetIds = [...new Set(listOfAdSetIds)];

    // parse adset data

    for (let adset_id of uniqueListOfAdSetIds) {
      const adGroup = {
        ...adsetBaseValues,
        dataSourceData: true,
        warnings: [],
        subWarnings: [],
        snackbarWarnings: [],
        documentType: 'googleAds',
        type: 'google_ads_ad_group',
        source: 'google_ads'
      };

      adGroup._id = adset_id + '(' + googleadscampaign._id + ')';

      adGroup['level_' + adGroup.level + '_container_id'] = adGroup._id;

      adGroup.cost = null;
      adGroup.impressions = null;
      adGroup.clicks = null;
      adGroup.costSparkline = [];
      adGroup.impressionsSparkline = [];
      adGroup.clicksSparkline = [];
      adGroup.ctrSparkline = [];
      adGroup.action01CvrSparkline = [];
      adGroup.action02CvrSparkline = [];
      adGroup.action03CvrSparkline = [];

      adGroup.cpcSparkline = [];

      adGroup.action01CountSparkline = [];
      adGroup.action01CpaSparkline = [];
      adGroup.action01RoasSparkline = [];
      adGroup.action01VpaSparkline = [];

      adGroup.action02CountSparkline = [];
      adGroup.action02CpaSparkline = [];
      adGroup.action02RoasSparkline = [];
      adGroup.action02VpaSparkline = [];

      adGroup.action03CountSparkline = [];
      adGroup.action03CpaSparkline = [];
      adGroup.action03RoasSparkline = [];
      adGroup.action03VpaSparkline = [];

      adGroup.total_list_of_propagation_data = [];

      adGroup.action01ValueSparkline = [];
      adGroup.action01ActionRoas = [];

      adGroup.action02ValueSparkline = [];
      adGroup.action02ActionRoas = [];

      adGroup.action03ValueSparkline = [];
      adGroup.action03ActionRoas = [];

      adGroup.cost_yesterday = null;

      adGroup.cpmSparkline = [];

      adGroup.action01Count = null;
      adGroup.action01Value = null;

      adGroup.action02Count = null;
      adGroup.action02Value = null;

      adGroup.action03Count = null;
      adGroup.action03Value = null;

      adGroup.costToday = null;
      adGroup.impressionsToday = null;
      adGroup.clicksToday = null;
      adGroup.action03ActionRoasToday = null;
      adGroup.cpmSparklineToday = [];
      adGroup.action01CountToday = null;
      adGroup.action01ValueToday = null;
      adGroup.action01ActionRoasToday = null;
      adGroup.action02CountToday = null;
      adGroup.action02ValueToday = null;
      adGroup.action02ActionRoasToday = null;
      adGroup.action03CountToday = null;
      adGroup.action03ValueToday = null;

      adGroup.costYesterday = null;
      adGroup.impressionsYesterday = null;
      adGroup.clicksYesterday = null;
      adGroup.action03ActionRoasYesterday = null;
      adGroup.cpmSparklineYesterday = [];
      adGroup.action01CountYesterday = null;
      adGroup.action01ValueYesterday = null;
      adGroup.action01ActionRoasYesterday = null;
      adGroup.action02CountYesterday = null;
      adGroup.action02ValueYesterday = null;
      adGroup.action02ActionRoasYesterday = null;
      adGroup.action03CountYesterday = null;
      adGroup.action03ValueYesterday = null;

      const current_adgroups = [];

      for (let adgroup_object of listOfPropagationData) {
        if (adgroup_object.adGroup.id === adset_id) {
          // get values for each adset

          current_adgroups.push(adgroup_object);
        }

        current_adgroups.sort(function (a, b) {
          var x = parseInt(a.date_start);
          var y = parseInt(b.date_start);
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }

      // get total metric value for adset

      // for (let adgroup_object of current_adgroups) {
      //   adGroup.name = adgroup_object.adGroup.name;
      // }

      adGroup.name =
        current_adgroups &&
        current_adgroups[0] &&
        current_adgroups[0].adGroup &&
        current_adgroups[0].adGroup.name
          ? current_adgroups[0].adGroup.name
          : '[Ad group]';
      adGroup.path = adGroup.path + adGroup.name;

      // create value array for sparkline that fills in the dates without value with 0.

      for (let date of range2) {
        let cost = null;
        let impressions = null;
        let clicks = null;
        let action01Count = null;
        let action02Count = null;
        let action03Count = null;
        let action01Value = null;
        let action02Value = null;
        let action03Value = null;

        for (let adgroup_object of current_adgroups) {
          if (
            new Date(parseInt(adgroup_object.segments.date))
              .toISOString()
              .split('T')[0] === date
          ) {
            // console.log('adgroup_object.segments.date: ', adgroup_object.segments.date);
            clicks = clicks + adgroup_object.clicks;
            cost = cost + adgroup_object.cost;
            impressions = impressions + adgroup_object.impressions;

            if (action01DataSourceIsGoogleAds) {
              switch (action01Type) {
                case 'conversions':
                  action01Count =
                    action01Count +
                    (adgroup_object.metrics.conversions || null);
                  action01Value =
                    action01Value +
                    (adgroup_object.metrics.conversionsValue || null);
                  break;

                default:
                  if (
                    googleadscampaign &&
                    googleadscampaign.googleadsadgroupconversions
                  ) {
                    for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                      if (
                        adgroup_conversion_object &&
                        adgroup_conversion_object.segments &&
                        adgroup_conversion_object.segments &&
                        adgroup_conversion_object.segments.conversionAction ===
                          action01Type &&
                        adgroup_conversion_object.adGroup &&
                        adgroup_conversion_object.adGroup.id ===
                          adgroup_object.adGroup.id &&
                        adgroup_conversion_object.segments.date ===
                          adgroup_object.segments.date
                      ) {
                        action01Count =
                          action01Count +
                          (adgroup_conversion_object.metrics.allConversions ||
                            null);
                        action01Value =
                          action01Value +
                          (adgroup_conversion_object.metrics
                            .allConversionsValue || null);
                      }
                    }
                  }
              }
            }

            if (action02DataSourceIsGoogleAds) {
              switch (action02Type) {
                case 'conversions':
                  action02Count =
                    action02Count +
                    (adgroup_object.metrics.conversions || null);
                  action02Value =
                    action02Value +
                    (adgroup_object.metrics.conversionsValue || null);
                  break;

                default:
                  if (
                    googleadscampaign &&
                    googleadscampaign.googleadsadgroupconversions
                  ) {
                    for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                      if (
                        adgroup_conversion_object &&
                        adgroup_conversion_object.segments &&
                        adgroup_conversion_object.segments &&
                        adgroup_conversion_object.segments.conversionAction ===
                          action02Type &&
                        adgroup_conversion_object.adGroup &&
                        adgroup_conversion_object.adGroup.id ===
                          adgroup_object.adGroup.id &&
                        adgroup_conversion_object.segments.date ===
                          adgroup_object.segments.date
                      ) {
                        action02Count =
                          action02Count +
                          (adgroup_conversion_object.metrics.allConversions ||
                            null);
                        action02Value =
                          action02Value +
                          (adgroup_conversion_object.metrics
                            .allConversionsValue || null);
                      }
                    }
                  }
              }
            }

            if (action03DataSourceIsGoogleAds) {
              switch (action03Type) {
                case 'conversions':
                  action03Count =
                    action03Count +
                    (adgroup_object.metrics.conversions || null);
                  action03Value =
                    action03Value +
                    (adgroup_object.metrics.conversionsValue || null);
                  break;

                default:
                  if (
                    googleadscampaign &&
                    googleadscampaign.googleadsadgroupconversions
                  ) {
                    for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                      if (
                        adgroup_conversion_object &&
                        adgroup_conversion_object.segments &&
                        adgroup_conversion_object.segments &&
                        adgroup_conversion_object.segments.conversionAction ===
                          action03Type &&
                        adgroup_conversion_object.adGroup &&
                        adgroup_conversion_object.adGroup.id ===
                          adgroup_object.adGroup.id &&
                        adgroup_conversion_object.segments.date ===
                          adgroup_object.segments.date
                      ) {
                        action03Count =
                          action03Count +
                          (adgroup_conversion_object.metrics.allConversions ||
                            null);
                        action03Value =
                          action03Value +
                          (adgroup_conversion_object.metrics
                            .allConversionsValue || null);
                      }
                    }
                  }
              }
            }
          }
        }

        // cost =
        //   cost && campaign.costFactor !== null
        //     ? cost * campaign.costFactor
        //     : cost;

        adGroup.total_list_of_propagation_data.push({
          // ...adgroup_object,
          date_start: new Date(date).getTime(),
          date_stop: new Date(date).getTime(),
          cost: cost,
          impressions: impressions,
          clicks,
          action01Type,
          action01Count: action01Count,
          action01Value: action01Value,

          action02Type,
          action02Count: action02Count,
          action02Value: action02Value,

          action03Type,
          action03Count: action03Count,
          action03Value: action03Value
        });

        adGroup.costSparkline.push(cost);
        adGroup.impressionsSparkline.push(impressions);
        adGroup.clicksSparkline.push(clicks);
        adGroup.ctrSparkline.push(
          clicks && impressions ? clicks / impressions : 0
        );
        adGroup.cpcSparkline.push(clicks && cost ? cost / clicks : 0);

        if (action01DataSourceIsGoogleAds) {
          adGroup.action01CvrSparkline.push(
            clicks && action01Count ? action01Count / clicks : 0
          );

          adGroup.action01VpaSparkline.push(
            action01Count && action01Value && action01Value / action01Count
              ? action01Value / action01Count
              : 0
          );

          adGroup.action01Count = adGroup.action01Count + action01Count;

          adGroup.action01Value = adGroup.action01Value + action01Value;
          adGroup.action01CountSparkline.push(action01Count);
          adGroup.action01ValueSparkline.push(action01Value);
          adGroup.action01RoasSparkline.push(cost ? action01Value / cost : 0);
          adGroup.action01CpaSparkline.push(
            action01Count ? cost / action01Count : 0
          );
        }

        if (action02DataSourceIsGoogleAds) {
          adGroup.action02CvrSparkline.push(
            clicks && action02Count ? action02Count / clicks : 0
          );

          adGroup.action02VpaSparkline.push(
            action02Count && action02Value && action02Value / action02Count
              ? action02Value / action02Count
              : 0
          );

          adGroup.action02Count = adGroup.action02Count + action02Count;

          adGroup.action02Value = adGroup.action02Value + action02Value;
          adGroup.action02CountSparkline.push(action02Count);
          adGroup.action02ValueSparkline.push(action02Value);
          adGroup.action02RoasSparkline.push(cost ? action02Value / cost : 0);
          adGroup.action02CpaSparkline.push(
            action02Count ? cost / action02Count : 0
          );
        }

        if (action03DataSourceIsGoogleAds) {
          adGroup.action03CvrSparkline.push(
            clicks && action03Count ? action03Count / clicks : 0
          );

          adGroup.action03VpaSparkline.push(
            action03Count && action03Value && action03Value / action03Count
              ? action03Value / action03Count
              : 0
          );

          adGroup.action03Count = adGroup.action03Count + action03Count;

          adGroup.action03Value = adGroup.action03Value + action03Value;
          adGroup.action03CountSparkline.push(action03Count);
          adGroup.action03ValueSparkline.push(action03Value);
          adGroup.action03RoasSparkline.push(cost ? action03Value / cost : 0);
          adGroup.action03CpaSparkline.push(
            action03Count ? cost / action03Count : 0
          );
        }

        adGroup.cost = adGroup.cost + cost;
        adGroup.impressions = adGroup.impressions + impressions;
        adGroup.clicks = adGroup.clicks + clicks;
        adGroup.cpmSparkline.push(
          impressions ? cost / (impressions / 1000) : 0
        );
      }

      // adset calc
      adGroup.ctr =
        adGroup.impressions && adGroup.clicks
          ? adGroup.clicks / adGroup.impressions
          : null;

      if (action01DataSourceIsGoogleAds) {
        adGroup.action01Cvr =
          adGroup.clicks && adGroup.action01Count
            ? adGroup.action01Count / adGroup.clicks
            : null;

        adGroup.action01Roas = adGroup.cost
          ? (adGroup.action01Value || null) / adGroup.cost
          : null;

        adGroup.action01Cpa =
          adGroup.cost && adGroup.action01Count
            ? adGroup.cost / adGroup.action01Count
            : null;

        adGroup.action01Vpa =
          adGroup.action01Value && adGroup.action01Count
            ? adGroup.action01Value / adGroup.action01Count
            : null;
      }

      if (action02DataSourceIsGoogleAds) {
        adGroup.action02Cvr =
          adGroup.clicks && adGroup.action02Count
            ? adGroup.action02Count / adGroup.clicks
            : null;

        adGroup.action02Roas = adGroup.cost
          ? (adGroup.action02Value || null) / adGroup.cost
          : null;

        adGroup.action02Cpa =
          adGroup.cost && adGroup.action02Count
            ? adGroup.cost / adGroup.action02Count
            : null;

        adGroup.action02Vpa =
          adGroup.action02Value && adGroup.action02Count
            ? adGroup.action02Value / adGroup.action02Count
            : null;
      }

      if (action03DataSourceIsGoogleAds) {
        adGroup.action03Cvr =
          adGroup.clicks && adGroup.action03Count
            ? adGroup.action03Count / adGroup.clicks
            : null;

        adGroup.action03Roas = adGroup.cost
          ? (adGroup.action03Value || null) / adGroup.cost
          : null;

        adGroup.action03Cpa =
          adGroup.cost && adGroup.action03Count
            ? adGroup.cost / adGroup.action03Count
            : null;

        adGroup.action03Vpa =
          adGroup.action03Value && adGroup.action03Count
            ? adGroup.action03Value / adGroup.action03Count
            : null;
      }

      adGroup.cpc =
        adGroup.clicks && adGroup.cost ? adGroup.cost / adGroup.clicks : null;

      adGroup.cpm = adGroup.impressions
        ? (adGroup.cost || null) / (adGroup.impressions / 1000)
        : null;

      listOfAdsets.push(adGroup);
    }
  }

  campaign.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaign,
    prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_google_ads_data;
