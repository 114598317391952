import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_strossle_campaign_data_aggregated = (
  strossleCampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2,
  startDateForLastDaysData,
  startDateForLastDaysDataMinusOneDay,
  tomorrow,
  dayAfterTomorrow
) => {
  console.log('strossleCampaign?.action01: ', strossleCampaign?.action01);
  const action01DataSource = strossleCampaign?.action01?.dataSource;
  const action02DataSource = strossleCampaign?.action02?.dataSource;
  const action03DataSource = strossleCampaign?.action03?.dataSource;

  const action01DataSourceIsStrossle = action01DataSource === 'strossle';
  const action02DataSourceIsStrossle = action02DataSource === 'strossle';
  const action03DataSourceIsStrossle = action03DataSource === 'strossle';

  const action01Type = strossleCampaign?.action01?.strossle?.type;

  const action02Type = strossleCampaign?.action02?.strossle?.type;

  const action03Type = strossleCampaign?.action03?.strossle?.type;

  const action01Id = strossleCampaign?.action01?.strossle?.id;

  const action02Id = strossleCampaign?.action02?.strossle?.id;

  const action03Id = strossleCampaign?.action03?.strossle?.id;

  // const action01Name = strossleCampaign?.action01?.strossle?.name;

  // const action02Name = strossleCampaign?.action02?.strossle?.name;

  // const action03Name = strossleCampaign?.action03?.strossle?.name;

  // const actionWindowDict = {
  //   default: 'externalWebsiteConversions',
  //   postClick: 'externalWebsitePostClickConversions',
  //   postView: 'externalWebsitePostViewConversions'
  // };

  // const action01AdInteraction =
  //   strossleCampaign?.action01?.strossle?.adInteraction;
  // const action02AdInteraction =
  //   strossleCampaign?.action02?.strossle?.adInteraction;
  // const action03AdInteraction =
  //   strossleCampaign?.action03?.strossle?.adInteraction;

  // console.log('action01AdInteraction: ', action01AdInteraction);

  const campaignGroup = {
    ...strossleCampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'strossle_campaign'
  };

  // campaignGroup.action01.name = action01Name;
  // campaignGroup.action02.name = action02Name;
  // campaignGroup.action03.name = action03Name;

  const runCostFormula = campaignGroup.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  let action01ConversionData = [];

  let objectToPush = {
    date_start: startDateForLastDaysDataMinusOneDay.getTime()
  };
  let pushObj = false;
  for (let date of range2) {
    // let pushObj = false;
    let dailyPushObject = false;
    let dailyObjectToPush = {};

    if (new Date(date) >= new Date(startDateForLastDaysData)) {
      if (strossleCampaign?.strossleadreportbasicdata?.length > 0) {
        for (let strossleBasicDataObj of strossleCampaign?.strossleadreportbasicdata) {
          const campaignGroupDate = new Date(+strossleBasicDataObj.date)
            .toISOString()
            .split('T')[0];
          if (
            campaignGroupDate === new Date(date).toISOString().split('T')[0]
          ) {
            dailyPushObject = true;
            dailyObjectToPush = {
              ...dailyObjectToPush,
              cost: strossleBasicDataObj.cost || null,
              impressions: strossleBasicDataObj.impressions || null,
              measurableImpressions: strossleBasicDataObj.impressions || null,
              viewableImpressions:
                strossleBasicDataObj.visible_impressions || null,

              clicks: +strossleBasicDataObj.clicks || null,

              date_start: new Date(+strossleBasicDataObj.date).getTime(),
              date_stop: new Date(+strossleBasicDataObj.date).getTime(),

              accountId: campaignBaseValues.accountId
            };
          }
        }
      }

      for (let actionNumber of ['01', '02', '03']) {
        if (
          strossleCampaign?.['action' + actionNumber]?.dataSource === 'strossle'
        ) {
          switch (action01Type) {
            case 'conversions':
              if (strossleCampaign?.strossleadreportbasicdata?.length > 0) {
                for (let strossleBasicDataObj of strossleCampaign?.strossleadreportbasicdata) {
                  const campaignGroupDate = new Date(+strossleBasicDataObj.date)
                    .toISOString()
                    .split('T')[0];
                  if (
                    campaignGroupDate ===
                    new Date(date).toISOString().split('T')[0]
                  ) {
                    dailyObjectToPush[`action${actionNumber}Count`] =
                      (dailyObjectToPush?.[`action${actionNumber}Count`] || 0) +
                      (strossleBasicDataObj.conversions || null);
                    dailyObjectToPush[`action${actionNumber}Value`] =
                      (dailyObjectToPush?.[`action${actionNumber}Value`] || 0) +
                      (strossleBasicDataObj.revenue || null);
                  }
                }
              }

              break;

            default:
              if (strossleCampaign?.strossleadreportactiondata?.length > 0) {
                for (let conversionObj of strossleCampaign?.strossleadreportactiondata) {
                  if (
                    // conversionObj?.segments?.conversionAction === action01Type &&
                    conversionObj.goalId ===
                      strossleCampaign?.['action' + actionNumber]?.strossle
                        ?.id &&
                    new Date(+conversionObj.date)
                      .toISOString()
                      .split('T')[0] === date
                  ) {
                    dailyObjectToPush[`action${actionNumber}Count`] =
                      (dailyObjectToPush?.[`action${actionNumber}Count`] || 0) +
                      (conversionObj.conversions || null);
                    dailyObjectToPush[`action${actionNumber}Value`] =
                      (dailyObjectToPush?.[`action${actionNumber}Value`] || 0) +
                      (conversionObj.revenue || null);
                  }
                }
              }
          }
        }
      }

      if (dailyPushObject) {
        listOfPropagationData.push(dailyObjectToPush);
      }
    } else {
      if (strossleCampaign?.strossleadreportbasicdata?.length > 0) {
        for (let strossleBasicDataObj of strossleCampaign?.strossleadreportbasicdata) {
          const campaignGroupDate = new Date(+strossleBasicDataObj.date)
            .toISOString()
            .split('T')[0];
          if (campaignGroupDate === date) {
            pushObj = true;
            objectToPush = {
              ...objectToPush,

              cost:
                (objectToPush.cost || 0) + strossleBasicDataObj.cost || null,
              impressions:
                (objectToPush.impressions || 0) +
                  strossleBasicDataObj.impressions || null,
              measurableImpressions:
                (objectToPush.measurableImpressions || 0) +
                  strossleBasicDataObj.impressions || null,
              viewableImpressions:
                (objectToPush.viewableImpressions || 0) +
                  strossleBasicDataObj.visible_impressions || null,

              clicks:
                (objectToPush.clicks || 0) + strossleBasicDataObj.clicks ||
                null,

              accountId: campaignBaseValues.accountId
            };
          }
        }
      }

      for (let actionNumber of ['01', '02', '03']) {
        if (
          strossleCampaign?.['action' + actionNumber]?.dataSource === 'strossle'
        ) {
          switch (action01Type) {
            case 'conversions':
              if (strossleCampaign?.strossleadreportbasicdata?.length > 0) {
                for (let strossleBasicDataObj of strossleCampaign?.strossleadreportbasicdata) {
                  const campaignGroupDate = new Date(+strossleBasicDataObj.date)
                    .toISOString()
                    .split('T')[0];
                  if (
                    campaignGroupDate ===
                    new Date(date).toISOString().split('T')[0]
                  ) {
                    objectToPush[`action${actionNumber}Count`] =
                      (objectToPush?.[`action${actionNumber}Count`] || 0) +
                      (strossleBasicDataObj.conversions || null);
                    objectToPush[`action${actionNumber}Value`] =
                      (objectToPush?.[`action${actionNumber}Value`] || 0) +
                      (strossleBasicDataObj.revenue || null);
                  }
                }
              }

              break;

            default:
              if (strossleCampaign?.strossleadreportactiondata?.length > 0) {
                for (let conversionObj of strossleCampaign?.strossleadreportactiondata) {
                  if (
                    // conversionObj?.segments?.conversionAction === action01Type &&
                    conversionObj.goalId ===
                      strossleCampaign?.['action' + actionNumber]?.strossle
                        ?.id &&
                    new Date(+conversionObj.date)
                      .toISOString()
                      .split('T')[0] === date
                  ) {
                    objectToPush[`action${actionNumber}Count`] =
                      (objectToPush?.[`action${actionNumber}Count`] || 0) +
                      (conversionObj.conversions || null);
                    objectToPush[`action${actionNumber}Value`] =
                      (objectToPush?.[`action${actionNumber}Value`] || 0) +
                      (conversionObj.revenue || null);
                  }
                }
              }
          }
        }
      }
    }
  }

  if (pushObj) {
    listOfPropagationData.push(objectToPush);
  }

  campaignGroup.total_list_of_aggregated_propagation_data = [
    {
      costLifetime: strossleCampaign?.totaldata?.cost,
      impressionsLifetime: strossleCampaign?.totaldata?.impressions
    }
  ];

  campaignGroup.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaignGroup
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_strossle_campaign_data_aggregated;
