import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     width: '93%'
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

export default function SimpleSelect({
  label,
  value,
  setValue,
  // default_value,
  currentManagerAccountId,
  item,
  itemKey,
  action,
  setAction
}) {
  // const classes = useStyles();

  const defaultValue = item[itemKey]?.snapchatAds?.type;

  const menuItems = [
    // { name: 'Conversion', type: 'conversion' },
    {
      name: 'Add To Cart',
      type: 'conversion_add_cart'
    },
    {
      name: 'View Content',
      type: 'conversion_view_content'
    },
    { name: 'Add Billing', type: 'conversion_add_billing' },
    { name: 'Sign Ups', type: 'conversion_sign_ups' },
    { name: 'Searches', type: 'conversion_searches' },
    { name: 'Level Completes', type: 'conversion_level_completes' },
    { name: 'App Opens', type: 'conversion_app_opens' },
    { name: 'Page Views', type: 'conversion_page_views' },
    { name: 'Subscribes', type: 'conversion_subscribe' },
    { name: 'Custom Event 1', type: 'custom_event_1' },
    { name: 'Custom Event 2', type: 'custom_event_2' },
    { name: 'Custom Event 3', type: 'custom_event_3' },
    { name: 'Custom Event 4', type: 'custom_event_4' },
    { name: 'Custom Event 5', type: 'custom_event_5' },
    { name: 'Purchases', type: 'conversion_purchases' }
  ];

  menuItems.sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const [input, setInput] = useState('');

  const handleChange = (event) => {
    let selectedItem = menuItems.filter(
      (obj) => event.target.value === obj.type
    )[0];

    setInput(selectedItem?.type);
    // setValue(selectedItem);
    setAction({
      ...action,
      // name: selectedItem?.name,
      snapchatAds: {
        name: selectedItem?.name,
        type: selectedItem?.type
      }
    });
  };

  useEffect(() => {
    if (!value) {
      // let selectedItem = menuItems.filter(
      //   (obj) => defaultValue === obj.type
      // )[0];

      setInput(defaultValue);
      // setValue(selectedItem);
    }
  }, [defaultValue, menuItems]);

  return (
    <div>
      <FormControl
        style={{
          margin: '10px',
          minWidth: 120,
          width: '93%'
        }}
        // className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          variant="standard"
          style={{ fontSize: '14px' }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={action?.snapchatAds?.type}
          onChange={handleChange}
        >
          <MenuItem style={{ fontSize: '14px' }} value="">
            <em>None</em>
          </MenuItem>
          {menuItems.map((menuItem, i) => (
            <MenuItem
              key={i}
              style={{ fontSize: '14px' }}
              value={menuItem.type}
            >
              {menuItem.name}
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Some important helper text</FormHelperText> */}
      </FormControl>
    </div>
  );
}
