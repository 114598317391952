import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { AccountContext } from './../../../context/AccountContext';
import { DialogContext } from './../../../context/DialogContext';

import CloseIcon from '@mui/icons-material/Close';

import EditSetFiltersDialogContent from './EditSetFiltersDialogContent';

import { MutationContext } from '../../../context/MutationContext';

import { FilterContext } from '../../../context/FilterContext';

import { InterfaceContext } from '../../../context/InterfaceContext';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        style={{ width: '850px', maxWidth: '100vw', maxHeight: '90%' }}
        {...props}
      />
    </Draggable>
  );
}

const EditSetFiltersDialog = ({}) => {
  const { currentManagerAccount } = useContext(AccountContext);

  const { checkedBoxes } = useContext(FilterContext);

  const [scrollTop, setScrollTop] = useState(0);
  const {
    editSetFiltersDialogOpen: open,
    setEditSetFiltersDialogOpen: setOpen
  } = useContext(DialogContext);

  const { setEditSetFilterMenuItem } = useContext(InterfaceContext);

  const handleClose = () => {
    setEditSetFilterMenuItem({});
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>Edit Set Filters</div>

            <div
              style={{
                cursor: 'pointer',
                right: '0px',
                textAlign: 'right'
                // paddingRight: '25px'
              }}
            >
              <span style={{ width: '50px' }} onClick={handleClose}>
                <CloseIcon className="hover-light-grey" />
              </span>
            </div>
          </div>
          <div style={{ fontSize: '12px', padding: '0px 5px' }}></div>
        </DialogTitle>
        <DialogContent>
          <EditSetFiltersDialogContent
            open={open}
            setOpen={setOpen}
            // checkedBoxes={checkedBoxes}
            // copiedDocumentIds={copiedDocumentIds}
            // setCopiedDocumentIds={setCopiedDocumentIds}
            // copiedDocumentsAccountId={copiedDocumentsAccountId}
            // setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

EditSetFiltersDialog.propTypes = {
  // style: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  stylesStoredInRedux: state.style
});

export default connect(mapStateToProps, {})(EditSetFiltersDialog);
