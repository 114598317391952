import React, { useState, useContext } from 'react';

// import GraphDialog from './../../report/graph-dialog/GraphDialog';
import TimelineIcon from '@mui/icons-material/Timeline';

// import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';

import { DialogContext } from '../../../../context/DialogContext';

function NameFoot({
  aggregatedMode,
  item,
  itemKey,
  index,
  stylesStoredInRedux,
  arrayOfData,
  summaryRowBottomShadow,

  // columns,
  // viewDialogOpen,
  setViewDialogOpen
}) {
  // const [openDataGraphModal, setOpenDataGraphModal] = useState(false);

  const { setItemKey } = useContext(DialogContext);

  return (
    <div
      key={index}
      style={{
        maxHeight: '46px',
        // backgroundImage:
        //   'linear-gradient(to bottom, white, #f9fafa)',
        backgroundImage:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .background,
        color:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .color,
        borderStyle: 'solid',
        borderWidth: '1px 1px 1px 0px',
        // borderColor: 'rgba(200, 200, 200, 0.5)',
        borderColor:
          stylesStoredInRedux.RoiTable.Table.summaryStyle['defaultSummary']
            .borderColor,
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottomLeftRadius: '5px',
        fontSize: '12px',
        gridRowStart: arrayOfData.length + 2,
        gridRowEnd: arrayOfData.length + 2,
        position: 'sticky',
        left: '92px',
        bottom: '0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1100',
        height: '100%',
        // minWidth: '200px',
        width: '100%',
        padding: '10px 20px',
        fontWeight: '800'
      }}
    >
      {summaryRowBottomShadow}
      {/* {openDataGraphModal && (
        <GraphDialog
          open={openDataGraphModal}
          setOpen={setOpenDataGraphModal}
          // itemKey={'costSparkline'}
          item={item}

        />
      )} */}
    </div>
  );
}

export default NameFoot;
