import React, { useState, useContext, useEffect } from 'react';

import TaxonomyWidgetList from './TaxonomyWidgetList';
import { styled, useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import TaxonomyField from './TaxonomyField';

import ForwardIcon from '@mui/icons-material/Forward';

import { Button } from '@mui/material';

import { DataContext } from '../../../context/DataContext';
import { DialogContext } from '../../../context/DialogContext';

function TaxonomyDialogContent() {
  // const [editMode, setEditMode] = useState(false);

  const { listOfData } = useContext(DataContext);
  const { itemId } = useContext(DialogContext);

  const item = listOfData.filter((obj) => obj._id === itemId)[0];

  console.log('TaxonomyDialogContent>item: ', item);

  const [taxonomyFieldIdsInEditMode, setTaxonomyFieldIdsInEditMode] = useState(
    []
  );
  const theme = useTheme();
  const [widgetListOpen, setWidgetListOpen] = useState(false);
  const [deleted, setDeleted] = useState(null);

  const [isDraggingWidget, setIsDraggingWidget] = useState(false);

  const [taxonomyString, setTaxonomyString] = useState('');

  useEffect(() => {
    if (!taxonomyFieldIdsInEditMode.length && widgetListOpen) {
      setWidgetListOpen(false);
    }
    if (taxonomyFieldIdsInEditMode.length && !widgetListOpen) {
      setWidgetListOpen(true);
    }
  }, [taxonomyFieldIdsInEditMode]);

  // const [grabbing, toggleGrabbing] = useState(false);

  return (
    <div
      style={{
        width: '80vw',
        height: '80vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: !widgetListOpen ? 'center' : 'space-between',
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
          paddingBottom: '90px'
        }}
      >
        {/* <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end'
          }}
        >
          {!editMode ? (
            <div style={{ height: '27px' }}>
              <Button
                onClick={() => {
                  setEditMode(!editMode);
                  // setWidgetListOpen(!widgetListOpen);
                }}
              >
                Edit taxonomy settings
              </Button>
            </div>
          ) : (
            <div
              style={{
                height: '27px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '140px'
              }}
            >
              <Button
                // disableRipple
                size="small"
                style={{
                  // width: '100%',
                  fontSize: '10px'
                  // position: 'inherit'
                }}
                variant="outlined"
                onClick={() => {
                  setEditMode(false);
                  setWidgetListOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                // disableRipple
                size="small"
                style={{
                  // width: '100%',
                  background: 'rgba(0,255,0,0.7)',
                  fontSize: '10px',
                  // position: 'inherit',
                  color: 'black'
                }}
                variant="contained"
              >
                Save
              </Button>
            </div>
          )}
        </div> */}
        <TaxonomyField
          item={item}
          // editMode={editMode}
          taxonomyFieldIdsInEditMode={taxonomyFieldIdsInEditMode}
          setTaxonomyFieldIdsInEditMode={setTaxonomyFieldIdsInEditMode}
          deleted={deleted}
          setDeleted={setDeleted}
          isDraggingWidget={isDraggingWidget}
          setIsDraggingWidget={setIsDraggingWidget}
          setTaxonomyString={setTaxonomyString}
          // grabbing={grabbing}
          // toggleGrabbing={toggleGrabbing}
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div
            style={{
              // visibility: isDraggingWidget && 'hidden',
              borderTop: '30px solid white',
              width: '0',
              height: '0',
              margin: '50px auto',
              borderLeft: '18px solid transparent',
              borderRight: '18px solid transparent',
              borderTopColor: isDraggingWidget
                ? theme?.palette?.mode === 'dark'
                  ? 'rgba(255,255,255,0.05)'
                  : 'rgba(255,255,255,0.3)'
                : theme?.palette?.mode === 'dark'
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(255,255,255,0.9)'
              // borderRadius: '5px'
            }}
          ></div>
          {/* <ForwardIcon
            style={{
              transform: 'rotate(90deg)',
              fontSize: '60px',
              color: 'white'
              // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
            }}
          /> */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            maxHeight: '46px',

            fontSize: '12px',

            height: '46px',
            width: '100%',
            minWidth: '58vw',
            padding: '12px 15px',
            whiteSpace: 'nowrap',
            // background:
            //   theme?.palette?.mode === 'dark'
            //     ? 'rgba(255,255,255,0.1)'
            //     : 'rgba(255,255,255,0.6)',
            // visibility: isDraggingWidget && 'hidden',
            background: isDraggingWidget
              ? theme?.palette?.mode === 'dark'
                ? 'rgba(255,255,255,0.05)'
                : 'rgba(255,255,255,0.3)'
              : theme?.palette?.mode === 'dark'
              ? 'rgba(255,255,255,0.1)'
              : 'rgba(255,255,255,0.6)',
            borderRadius: '5px',
            overflow: 'hidden',
            backdropFilter: !isDraggingWidget && 'blur(10px)',
            boxShadow: !isDraggingWidget && 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
          }}
        >
          {taxonomyString}
        </div>
      </div>
      {/* <div>
        <TaxonomyWidgetList />
      </div> */}
      {Boolean(taxonomyFieldIdsInEditMode.length) && (
        <div
          style={{
            top: '65px',
            marginTop: '5px',
            position: 'absolute',
            width: widgetListOpen ? '300px' : '20px',
            right: '0px',
            height: 'calc(80vh - 10px)',
            // right: widgetListOpen ? '-140px' : '-400px',
            display: 'flex',
            flexDirection: 'row',
            background:
              theme?.palette?.mode === 'dark'
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(255,255,255,0.6)',
            borderRadius: '5px 0 0 5px',
            overflow: 'hidden',
            backdropFilter: 'blur(10px)',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
            zIndex: '1000'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '20px',
              // height: '600px',
              // background: 'rgba(255,255,255,0.6)'
              background:
                theme?.palette?.mode === 'dark'
                  ? 'rgba(255,255,255,0.1)'
                  : 'rgba(255,255,255,0.6)',
              cursor: 'pointer'
            }}
            onClick={() => setWidgetListOpen(!widgetListOpen)}
          >
            <span style={{ cursor: 'pointer', padding: '5px' }}>
              {widgetListOpen ? (
                <CloseIcon
                  style={{ fontSize: '15px' }}
                  // onClick={() => setWidgetListOpen(false)}
                />
              ) : (
                <MenuIcon
                  style={{ padding: '5px', cursor: 'pointer' }}
                  // onClick={() => setWidgetListOpen(!widgetListOpen)}
                />
              )}
            </span>
            <span
              style={{
                fontSize: '12px',
                padding: '5px',
                transform: 'rotate(-90deg)'
              }}
            >
              Widgets
            </span>
            <span></span>
          </div>
          <div
            style={{
              // height: '600px',
              width: '400px'
              // gridRow: 0,
              // gridColumn: 1

              // position: 'absolute'
            }}
          >
            <div>
              <TaxonomyWidgetList
                setIsDraggingWidget={setIsDraggingWidget}
                isDraggingWidget={isDraggingWidget}
              />
            </div>
          </div>
        </div>
      )}
      {/* )} */}
    </div>
  );
}

export default TaxonomyDialogContent;
