import React, { useState, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { addCustomColumnSetsMutation } from './../../../graphql/queries';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { AccountContext } from '../../../context/AccountContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       width: '25ch'
//     }
//   }
// }));

export default function BasicTextFields({
  // currentManagerAccountId,
  right,
  setOpen
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  // const classes = useStyles();

  const [customColumnSetName, setCustomColumnSetName] = useState('');
  const [saveTo, setSaveTo] = useState('managerAccount');

  const [addCustomColumnSet, { called }] = useMutation(
    addCustomColumnSetsMutation
  );

  const handleChange = (event) => {
    setSaveTo(event.target.value);
  };

  const onClickSaveHandler = () => {
    if (!customColumnSetName) {
      console.log('Please name the custom column set.');
      return;
    }

    if (!saveTo) {
      console.log('please choose save to field');
      return;
    }

    const input = {
      managerAccountId: currentManagerAccount._id,
      name: customColumnSetName,
      columns: right,
      saveTo: saveTo
    };

    addCustomColumnSet({ variables: { input } });
    console.log('Saved!');
    setOpen(false);
  };

  return (
    // <form className={classes.root} noValidate autoComplete="off">
    <div
      style={{
        marginLeft: '25px',
        display: 'grid',
        gridTemplateColumns: '250px 180px auto'
      }}
    >
      <TextField
        id="standard-basic"
        label="Save custom column set"
        variant="standard"
        size="small"
        style={{
          display: 'grid',
          gridRow: 1,
          width: '90%'
        }}
        value={customColumnSetName}
        onChange={(e) => {
          setCustomColumnSetName(e.target.value);
        }}
      />

      <FormControl variant="standard" style={{ display: 'grid', gridRow: 1 }}>
        <InputLabel id="demo-simple-select-label">Save to</InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={saveTo}
          onChange={handleChange}
        >
          <MenuItem value={'managerAccount'}>Manager Account</MenuItem>
          <MenuItem value={'user'}>Me</MenuItem>

          {/* <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
      <Button
        onClick={onClickSaveHandler}
        style={{
          width: '89px',
          height: '35px',
          gridRow: 1,
          marginTop: '12px'
        }}
        // variant="contained"
        color="primary"
        size="small"
        // className={classes.button}
        startIcon={<SaveIcon />}
      >
        Save
      </Button>
    </div>
    // </form>
  );
}
