import React, { useState } from 'react';

import { TextField } from '@mui/material';
import { set } from 'mongoose';

function TaxonomyWidgetList({ isDraggingWidget, setIsDraggingWidget }) {
  const [isDraggingThisWidgetType, setIsDraggingThisWidgetType] =
    useState(false);
  const [search, setSearch] = React.useState('');

  // const [grabbing, toggleGrabbing] = useState(false);
  const widgets = [
    // { name: '-', type: 'STRING', value: '-', defaultValue: '' },
    // { name: '_', type: 'STRING', value: '_', defaultValue: '' },
    // { name: '|', type: 'STRING', value: '|', defaultValue: '' },
    // { name: '#', type: 'STRING', value: '#', defaultValue: '' },
    // { name: '(', type: 'STRING', value: '(', defaultValue: '' },
    // { name: ')', type: 'STRING', value: ')', defaultValue: '' },
    // { name: '[', type: 'STRING', value: '[', defaultValue: '' },
    // { name: ']', type: 'STRING', value: ']', defaultValue: '' },
    // { name: '/', type: 'STRING', value: '/', defaultValue: '' },
    {
      name: 'Free text',
      type: 'FREETEXT',
      value: 'FREE TEXT'
      // defaultValue: ''
    },
    {
      name: 'Static text',
      type: 'STATICTEXT',
      value: 'STATIC TEXT'
      // defaultValue: ''
    },

    {
      name: 'List',
      type: 'LIST',
      value: 'LIST',
      // defaultValue: '',
      options: []
    },
    // {
    //   name: 'Dynamic List',
    //   type: 'DYNAMICLIST',
    //   value: 'DYNAMIC LIST',
    //   // defaultValue: '',
    //   options: []
    // },
    {
      name: 'Year YYYY',
      type: 'YEARYYYY',
      value: () => new Date().getFullYear()
      // defaultValue: ''
    },

    {
      name: 'Year YY',
      type: 'YEARYY',
      value: () => String(String(new Date().getFullYear()).slice(2))
      // defaultValue: ''
    },
    // {
    //   name: 'Month M',
    //   type: 'THISMONTH',
    //   value: () => new Date().getMonth() + 1
    //   // defaultValue: ''
    // },
    {
      name: 'Month MM',
      type: 'MONTHMM',
      value: () =>
        (new Date().getMonth() + 1).length !== 2
          ? '0' + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1
      // defaultValue: ''
    },
    // {
    //   name: 'Today D',
    //   type: 'TODAY',
    //   value: new Date().getDate()
    //   // defaultValue: ''
    // },
    {
      name: 'Day DD',
      type: 'DAYDD',
      value: () =>
        new Date().getDate().toString().length !== 2
          ? '0' + new Date().getDate()
          : new Date().getDate()
      // defaultValue: ''
    }
  ];

  //   const dragStart = (e, value) => {
  //     const target = e.target;
  //     console.log('value: ', value);
  //     e.dataTransfer.setData('droppedItem', JSON.stringify(value));
  //     setTimeout(() => {
  //       target.style.display = 'none';
  //     }, 0);
  //   };

  const dragStart = (e, value, origin, originIndex) => {
    setIsDraggingThisWidgetType(value.type);
    setIsDraggingWidget(true);
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({ value, origin, originIndex })
    );
    console.log('{ value, origin, originIndex }: ', {
      value,
      origin,
      originIndex
    });
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    setIsDraggingThisWidgetType(null);
    setIsDraggingWidget(false);
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
    // toggleGrabbing(false);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <div style={{}}>
      <div
        style={{
          padding: '0 0 10px 10px'
          // background: 'rgba(255,255,255,0.6)'
        }}
      >
        <TextField
          size="small"
          variant="standard"
          id="outlined-controlled"
          label="Search"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: '100%',
          //   height: '100%',
          // background: 'rgba(255,255,255,0.6)',
          padding: '10px',
          rowGap: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          //   maxHeight: 'calc(71vh - 64px)',
          overflowY: 'scroll',
          // height: '100%'
          height: 'calc(85vh - 78px)'
          // height: '80vh'
          //   maxHeight: 'calc(100vh - 64px)'
        }}
      >
        {widgets.map((widget, index) => (
          <div
            key={index}
            onDragOver={(e) => dragOver(e, widget)}
            draggable={true}
            onDragStart={(e) =>
              dragStart(e, widget, 'taxonomy_widget_list', index)
            }
            onDragEnd={(e) => dragEnd(e, widget)}
            style={{
              background: 'white',
              // margin: '10px',
              padding: '4px',
              borderRadius: '5px',
              transform: 'translate(0, 0)',
              color: 'black',
              fontSize: '12px',
              display: 'inline-block',
              boxShadow:
                isDraggingThisWidgetType !== widget.type &&
                'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
              cursor: isDraggingWidget ? 'grabbing' : 'grab'
            }}
            onMouseDown={() => setIsDraggingWidget(true)}
            onMouseUp={() => setIsDraggingWidget(false)}
          >
            {widget.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TaxonomyWidgetList;
