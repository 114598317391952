import React, { useState, useEffect, useContext } from 'react';

import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';

import CalendarPicker from './CalendarPicker';
// import { makeStyles } from '@mui/styles';

import { AccountContext } from '../../../../../context/AccountContext';

// import { updateDocumentMutation } from '../../../../../graphql/queries';
import { MutationContext } from '../../../../../context/MutationContext';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function DateItemContent({ item, itemKey }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const { enqueueSnackbar } = useSnackbar();
  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = () => {
    toggleShowTextField(true);
  };

  // const [updateDocument] = useMutation(updateDocumentMutation);
  const { updateDocument } = useContext(MutationContext);

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(item[itemKey]);
  }, [showTextField, toggleShowTextField, item[itemKey]]);

  let valueAsFloatOrNull = null;

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    // if (e) {
    //   e.preventDefault();
    // }

    if (typeof inputValue === 'string' && inputValue) {
      const regExBudget = new RegExp(
        '^([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))$'
      );
      const regExBudgetResult = regExBudget.exec(inputValue);
      if (!regExBudgetResult) {
        enqueueSnackbar('Invalid format. Please use format YYYY-MM-DD.', {
          variant: 'error'
        });
        return;
      }
    }

    if (inputValue === item[itemKey]) {
      toggleShowTextField(false);
      return '';
    }

    // const documentInput = {
    //   accountId: item.accountId,
    //   documentId: item._id,
    //   // checkedDocumentId: item._id,
    //   document: { [itemKey]: inputValue ? inputValue : null },
    //   updateKey: itemKey,
    //   action: 'UPDATE_DOCUMENT',
    //   managerAccountId: currentManagerAccountId,
    //   documentType: item.documentType,
    //   level: item.level
    //   // level_1_container_id: item.level_1_container_id,
    //   // level_2_container_id: item.level_2_container_id,
    //   // level_3_container_id: item.level_3_container_id,
    //   // level_4_container_id: item.level_4_container_id,
    //   // level_5_container_id: item.level_5_container_id,
    //   // level_6_container_id: item.level_6_container_id,
    //   // level_7_container_id: item.level_7_container_id
    // };
    const documentInput = {
      managerAccountId: currentManagerAccount._id,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: inputValue ? inputValue : null,
          // template: document.template,
          templateDescendant: item.templateDescendant
        }
      ],
      updateKeys: [itemKey]
    };

    // updateDocument({ variables: { input: { ...documentInput } } });

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });
      // console.log('res: ', res);
      // console.log(
      //   'res?.data?.updateDocument?.response: ',
      //   res?.data?.updateDocument?.response
      // );
      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
      toggleShowTextField(false);
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const textField = (
    <form
      style={{ display: 'inline', maxWidth: '200px' }}
      onSubmit={onSubmitHandler}
      // className={classes.root}
      noValidate
      autoComplete="off"
    >
      <TextField
        variant="standard"
        autoFocus
        style={{ maxWidth: '70px' }}
        id="standard-basic"
        value={inputValue}
        inputProps={{
          min: 0,
          style: { textAlign: 'right', padding: '0px', fontSize: '12px' }
        }}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
      />
      {/* <TextField id="filled-basic" label="Filled" variant="filled" />
        <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </form>
  );
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        {showTextField ? (
          textField
        ) : (
          <div
            style={{
              //   display: 'inline',
              minWidth: '65px',
              //   minWidth: '75px',
              height: '20px',
              textAlign: 'right',
              cursor: 'text'
              //   background: 'red'
            }}
            onClick={onClickHandler}
          >
            {item[itemKey]}{' '}
            {/* <i
                className="fas fa-pen"
                style={{
                  fontSize: '8px',
                  // color: 'rgba(255,255,255,0.3)',
                  color: 'grey',
                  padding: '4px 0 4px 2px'
                }}
                onClick={onClickHandler}
              ></i> */}
          </div>
        )}
        <CalendarPicker
          item={item}
          itemKey={itemKey}
          // setSelectedDate={}
          inputValue={
            inputValue ? inputValue : new Date().toISOString().split('T')[0]
          }
          setInputValue={setInputValue}
          onSubmitHandler={onSubmitHandler}
        />
        <p
          style={{
            fontSize: '8px',
            color: '#bcbcbc'
            // visibility: 'hidden'
          }}
        >
          {' '}
          {/* {child_sum} */}
        </p>
      </div>
    </>
  );
}

export default DateItemContent;
