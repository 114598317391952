import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import { columnArray, defaultColumnSets } from './columns';

import { DialogContext } from '../../../context/DialogContext';
import { ColumnContext } from '../../../context/ColumnContext';

import TransferList from './TransferList';
import CustomColumnSetInput from './CustomColumnSetInput';

import { useTheme } from '@emotion/react';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        // style={{
        //   maxWidth: '1200px',
        //   width: '1000px',
        //   height: '90%',
        //   maxHeight: '800px'
        //   // maxHeight: '800px'
        // }}
        {...props}
      />
    </Draggable>
  );
}

export default function ColumnDialog() {
  const { columnDialogOpen, setColumnDialogOpen } = useContext(DialogContext);
  const { columns, setColumns } = useContext(ColumnContext);
  // const [open, setOpen] = React.useState(false);

  const [category, setCategory] = useState('ALL');

  const [left, setLeft] = useState([columnArray]);
  const [right, setRight] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    if (columns) {
      setRight(columns);
      setLeft(columnArray.filter((col) => !columns.includes(col)).sort());
    }
  }, [columns]);

  const handleClickOpen = () => {
    setColumnDialogOpen(true);
  };

  const handleClickDone = () => {
    setColumns(right);
    setColumnDialogOpen(false);
  };

  const handleClose = () => {
    // setColumns(right);
    setColumnDialogOpen(false);
  };

  const content = (
    <div style={{ display: 'flex', flexDirection: ' row' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '200px',
          // height: '800px',
          // background: 'red',
          alignItems: 'start',
          paddingTop: '80px'
        }}
      >
        <div style={{ padding: '7px' }}>Categories</div>
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'ALL' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('ALL')}
          >
            All
          </Button>
        </div>
        {/* <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'BUDGET' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('BUDGET')}
          >
            Budget
          </Button>
        </div> */}
        {/* <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'DELIVERY' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('DELIVERY')}
          >
            Delivery
          </Button>
        </div> */}
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background:
                category === 'BUDGETANDPACE' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('BUDGETANDPACE')}
          >
            Budget & Pace
          </Button>
        </div>
        {/* <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'PACE' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('PACE')}
          >
            Pace
          </Button>
        </div> */}
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'PERFORMANCE' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('PERFORMANCE')}
          >
            Performance
          </Button>
        </div>
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'VIEWABILITY' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('VIEWABILITY')}
          >
            Viewability
          </Button>
        </div>
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'ACTIONS' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('ACTIONS')}
          >
            Actions
          </Button>
        </div>
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'COMPETITIVE' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('COMPETITIVE')}
          >
            Competitive (SEM)
          </Button>
        </div>
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'DESCRIPTIONS' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('DESCRIPTIONS')}
          >
            Descriptions
          </Button>
        </div>
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'PLANNED' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('PLANNED')}
          >
            Planned
          </Button>
        </div>
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background: category === 'WARNINGS' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('WARNINGS')}
          >
            Warnings
          </Button>
        </div>
        <div>
          <Button
            style={{
              justifyContent: 'flex-start',
              background:
                category === 'MISCELLANEOUS' && 'rgba(150,150,150,0.3)'
            }}
            onClick={() => setCategory('MISCELLANEOUS')}
          >
            Miscellaneous
          </Button>
        </div>
      </div>
      <div
        style={{
          width: '800px',
          // height: '400px',
          // height: '75vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <TransferList
          category={category}
          left={left}
          setLeft={setLeft}
          right={right}
          setRight={setRight}
        />
        <CustomColumnSetInput right={right} setOpen={setColumnDialogOpen} />
      </div>
    </div>
  );

  return (
    <div>
      <Dialog
        open={columnDialogOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            maxWidth: '1200px',
            width: '1000px',
            height: '90%',
            maxHeight: '800px',
            backgroundImage:
              theme?.palette?.mode === 'dark' &&
              'linear-gradient(to top, rgb(55, 55, 66), rgb(75, 75, 86))'
          }
        }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ViewColumnIcon />
            </div>
            <div style={{ marginLeft: '10px' }}>Columns</div>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: '0px 20px 0px 20px' }}>
          {content}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClickDone}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
