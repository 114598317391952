import React from 'react';
// import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { deleteCustomColumnSetsMutation } from './../../../graphql/queries';

import { useMutation } from '@apollo/client';

// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(1)
//   }
// }));

export default function SimplePopover({
  _id,
  currentManagerAccountId,
  savedTo
}) {
  const [deleteCustomColumnSet, { called }] = useMutation(
    deleteCustomColumnSetsMutation
  );

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickDeleteHandler = () => {
    const input = {
      _id,
      managerAccountId: currentManagerAccountId,
      savedTo
    };

    deleteCustomColumnSet({ variables: { input } });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        Open Popover
      </Button> */}
      <RemoveCircleIcon onClick={handleClick} style={{ paddingTop: '10px' }} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Typography
          onClick={onClickDeleteHandler}
          // className={classes.typography}
          style={{ cursor: 'pointer' }}
        >
          Delete
        </Typography>
      </Popover>
    </div>
  );
}
