import React, { useEffect, useContext } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';

import { FilterContext } from '../../../../context/FilterContext';

export default function Checkboxes({ item, maxHeight }) {
  const { checkedBoxes, setCheckedBoxes } = useContext(FilterContext);
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    const currentBoxInCheckedBoxes = checkedBoxes.filter((obj) => {
      return obj._id === item._id;
    });
    if (currentBoxInCheckedBoxes.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
    const currentBoxInCheckedBoxes = checkedBoxes.filter((obj) => {
      return obj._id === item._id;
    });
    if (currentBoxInCheckedBoxes.length < 1) {
      setCheckedBoxes([...checkedBoxes, item]);
    }

    if (currentBoxInCheckedBoxes.length > 0) {
      setCheckedBoxes(
        checkedBoxes.filter((obj) => {
          return obj._id !== item._id;
        })
      );
    }
  };

  const onDivClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      style={{ maxHeight, maxWidth: maxHeight }}
      onClick={(e) => onDivClick(e)}
    >
      <Checkbox
        size="small"
        style={{ maxHeight, maxWidth: maxHeight }}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      {/* <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        name="checkedI"
      /> */}
    </div>
  );
}
