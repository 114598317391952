import React, { useContext, useState } from 'react';
import { PhoneInput } from 'react-international-phone';

import { MuiPhone } from './MuiPhone';

import { Button } from '@mui/material';

import { useMutation } from '@apollo/client';

import { UpdateUserPhoneNumberMutation } from './../../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../../context/AccountContext';
import { AuthContext } from '../../../../../../context/AuthContext';

import Fab from '@mui/material/Fab';

import SendIcon from '@mui/icons-material/Send';

function PhoneNumberInput({ userInfoAndAccounts, view, setView }) {
  const { user: currentUser } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { currentManagerAccount, refetchAccounts } = useContext(AccountContext);
  // const classes = useStyles();

  const [updateUserPhoneNumber, { called }] = useMutation(
    UpdateUserPhoneNumberMutation
  );

  const [numberOfCodesSent, setNumberOfCodesSent] = useState(0);
  const [phone, setPhone] = useState('');

  const onClickHandler = async (e) => {
    if (numberOfCodesSent > 5) {
      enqueueSnackbar('Too many tries, please contact support.', {
        variant: 'error'
      });
    }
    const input = {
      managerAccountId: currentManagerAccount?._id,
      phoneNumber: phone
    };

    let res;
    try {
      res = await updateUserPhoneNumber({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
    console.log('res?.data: ', res?.data);
    if (res?.data?.updateUserPhoneNumber?.response === 'Success') {
      enqueueSnackbar('Verification code sent', {
        variant: 'success'
      });
      setView('VERIFY_PHONE_NUMBER');
      //   refetchUserInfo();
      //   refetchAccounts();

      if (userInfoAndAccounts?._id === currentUser?._id) {
        // refetchUsersAccountsList();
      }
    }
    setNumberOfCodesSent(numberOfCodesSent + 1);
  };
  return (
    <div
      style={{
        padding: '10px',
        width: '500px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <MuiPhone
        // defaultCountry="ua"
        value={phone}
        onChange={(phone) => setPhone(phone)}
      />
      {/* <Button variant="outlined" onClick={onClickHandler}>
        Send verification code
      </Button> */}
      <Fab
        onClick={onClickHandler}
        aria-label="edit"
        style={{
          marginLeft: '20px',
          color: 'white',

          // margin: theme.spacing(1),
          borderRadius: '5px',
          height: '56px',
          width: '220px',
          backgroundColor: 'rgb(0, 181, 8)',
          fontSize: '12px'
        }}
      >
        <SendIcon style={{ color: 'white', marginRight: '10px' }} />
        <span>Send verification code</span>
      </Fab>
    </div>
  );
}

export default PhoneNumberInput;
