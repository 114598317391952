import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { ClickAwayListener } from '@mui/base';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import { SketchPicker } from 'react-color';

import { MutationContext } from '../../../../context/MutationContext';

import DeleteIcon from '@mui/icons-material/Delete';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     // width: 500
//   },
//   typography: {
//     padding: theme.spacing(2)
//   }
// }));

const PositionedPopper = ({
  //   currentManagerAccountId,
  //   // updateDocument,
  //   item
  color,
  setColor,
  removeColor,
  index
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const [oldColor, setOldColor] = useState([]);

  useEffect(() => {
    setOldColor(color);
  }, [open]);
  console.log('oldColor: ', oldColor);
  // const classes = useStyles();

  //   const { updateDocument } = useContext(MutationContext);

  //   const [color, setColor] = useState('rgba(178, 67, 147,1)');

  //   const [update, toggleUpdate] = useState(false);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    //   if (open) {
    //     // const documentInput = {
    //     //   accountId: item.accountId,
    //     //   documentId: item._id,
    //     //   document: {
    //     //     color
    //     //   },
    //     //   updateKey: 'styleGanttBarColor',
    //     //   action: 'UPDATE_DOCUMENT',
    //     //   managerAccountId: currentManagerAccountId,
    //     //   documentType: item.type,
    //     //   level: item.level
    //     // };

    //     const documentInput = {
    //       managerAccountId: currentManagerAccountId,
    //       documents: [
    //         {
    //           accountId: item.accountId,
    //           documentId: item._id,
    //           documentType: item.documentType,
    //           color
    //         }
    //       ],
    //       updateKeys: ['styleGanttBarColor']
    //     };
    //     if (update) {
    //       updateDocument({ variables: { input: { ...documentInput } } });
    //       toggleUpdate(false);
    //     }
    //   }

    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChange = (color) => {
    console.log('color: ', color);
    setColor(color.hex, index);
    // toggleUpdate(true);
  };

  const handleClickAway = (e) => {
    setColor(oldColor, index);
    e.preventDefault();
    e.stopPropagation();
    // const documentInput = {
    //   accountId: item.accountId,
    //   documentId: item._id,
    //   document: { color },
    //   updateKey: 'styleGanttBarColor',
    //   action: 'UPDATE_DOCUMENT',
    //   managerAccountId: currentManagerAccountId,
    //   documentType: item.documentType,
    //   level: item.level
    // };

    // const documentInput = {
    //   managerAccountId: currentManagerAccountId,
    //   documents: [
    //     {
    //       accountId: item.accountId,
    //       documentId: item._id,
    //       documentType: item.documentType,
    //       styleGanttBarColor: color
    //     }
    //   ],
    //   updateKeys: ['styleGanttBarColor']
    // };
    // if (update) {
    //   updateDocument({ variables: { input: { ...documentInput } } });
    //   toggleUpdate(false);
    // }

    setOpen(false);
  };

  return (
    <>
      <Popper
        style={{ zIndex: 25000, boxShadow: 'none' }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <SketchPicker
                    color={color}
                    onChange={handleChange}
                    onClick={handleClick}
                  />
                  <div
                    style={{
                      height: '30px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: '30000',
                      //   top: '-5px',
                      width: '101%',
                      position: 'absolute',
                      bottom: '-25px',
                      //   border: '1px solid red',
                      borderWidth: '0px 1px 1px 1px',
                      borderColor: 'rgba(120,120,120,0.7)',
                      borderStyle: 'solid',
                      borderRadius: '0px 0px 5px 5px',
                      background: '#ffffff',
                      boxShadow:
                        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }}
                  >
                    <Button
                      style={{
                        fontSize: '10px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: 'black'
                      }}
                      size="small"
                      onClick={() => {
                        removeColor(index);
                        setOpen(false);
                      }}
                    >
                      <DeleteIcon style={{ fontSize: '14px', color: 'red' }} />
                      <span
                        style={{
                          marginTop: '2px',
                          marginLeft: '3px',
                          paddingRight: '5px'
                        }}
                      >
                        Remove
                      </span>
                    </Button>
                    <Button
                      style={{
                        fontSize: '10px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: 'black'
                      }}
                      size="small"
                      onClick={() => {
                        setColor(oldColor, index);
                        setOpen(false);
                      }}
                    >
                      <CancelIcon style={{ fontSize: '14px', color: 'grey' }} />
                      <span
                        style={{
                          marginTop: '2px',
                          marginLeft: '3px',
                          paddingRight: '5px'
                        }}
                      >
                        Cancel
                      </span>
                    </Button>
                    <Button
                      style={{
                        fontSize: '10px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: 'black'
                      }}
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <SaveIcon style={{ fontSize: '14px', color: 'green' }} />
                      <span
                        style={{
                          marginTop: '2px',
                          marginLeft: '3px',
                          paddingRight: '5px'
                        }}
                      >
                        Apply
                      </span>
                    </Button>
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <div
        // draggable
        style={{
          margin: '5px',
          display: 'inline-block',
          width: '40px',
          minWidth: '40px',
          height: '40px',
          border: '1px solid rgba(255,255,255,0.3)',
          borderRadius: '5px',
          backgroundColor: color
          //   boxShadow:
          //     '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 1px 3px 0px rgba(0,0,0,0.06)'
        }}
        onClick={handleClick('bottom')}
      >
        {' '}
      </div>
      {/* <Button onClick={handleClick('bottom')}>bottom</Button> */}
    </>
  );
};

export default React.memo(PositionedPopper);
