import React, { useState } from 'react';

import AddToken from './AddToken';
import TokenList from './TokenList';

function TokenScreen({ selectedTokenId, setSelectedTokenId, setShowScreen }) {
  const [refetchCounter, setRefetchCounter] = useState(0);
  const refetchTokenList = () => {
    setRefetchCounter(refetchCounter + 1);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <AddToken refetchTokenList={refetchTokenList} />
      </div>
      <div>
        <TokenList
          refetchCounter={refetchCounter}
          selectedTokenId={selectedTokenId}
          setSelectedTokenId={setSelectedTokenId}
          setShowScreen={setShowScreen}
        />
      </div>
    </div>
  );
}

export default TokenScreen;
