import React, { useContext } from 'react';

import { AuthContext } from '../../../../../../context/AuthContext';

import EditPhoneNumberDialog from './EditPhoneNumberDialog';

import RemovePhoneNumberDialog from './RemovePhoneNumberDialog';

function PhoneNumber({ userInfoAndAccounts, refetchUserInfo }) {
  const { user: currentUser } = useContext(AuthContext);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '36px'
      }}
    >
      <div>
        <span>Phone number: </span>
        <span style={{ marginLeft: '20px' }}>
          {userInfoAndAccounts?.phoneNumber}
        </span>
      </div>
      {currentUser?._id === userInfoAndAccounts?._id && (
        <div>
          <EditPhoneNumberDialog
            userInfoAndAccounts={userInfoAndAccounts}
            refetchUserInfo={refetchUserInfo}
          />
          {userInfoAndAccounts?.phoneNumber && (
            <RemovePhoneNumberDialog
              userInfoAndAccounts={userInfoAndAccounts}
              refetchUserInfo={refetchUserInfo}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default PhoneNumber;
