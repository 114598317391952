import axios from 'axios';
import { setAlert } from './alert';

import { UPDATE_ACCOUNT, REFETCH_USERS_ACCOUNT_LIST } from './types';

export const setCurrentAccountIds = (accounts) => async (dispatch) => {
  dispatch({
    type: UPDATE_ACCOUNT,
    payload: accounts
  });
};

export const refetchUsersAccountsList = () => async (dispatch) => {
  dispatch({
    type: REFETCH_USERS_ACCOUNT_LIST,
    payload: null
  });
};

// Get current manager account
// export const getCurrentAccount = (managerAccountId, history) => async (
//   dispatch
// ) => {
//   // dispatch({ type: CLEAR_MANAGER_ACCOUNT });

//   // try {
//   //   const res = await axios.get('/api/manageraccounts/select');

//   //   const currentAccount = res.data.filter(
//   //     managerAccount =>
//   //       managerAccount.managerAccount._id === '5e7671e6ceecbd5ea51a994c'
//   //   )[0];

//   //   dispatch({
//   //     type: GET_MANAGER_ACCOUNT,
//   //     payload: currentAccount
//   //   });

//   try {
//     if (managerAccountId) {
//       const res = await axios.get(
//         `/api/manageraccounts/?manager_account_id=${managerAccountId}`
//       );

//       if (!res.data) {
//         history.push('/choose-manager-account');
//       }
//       dispatch({
//         type: GET_MANAGER_ACCOUNT,
//         payload: res.data
//       });
//     } else {
//       setTimeout(() => history.push('/choose-manager-account'), 10);
//     }
//   } catch (err) {
//     dispatch({
//       type: MANAGER_ACCOUNT_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//     history.push('/choose-manager-account');
//   }
// };

// // Get all manager accounts
// export const getManagerAccounts = () => async (dispatch) => {
//   //   dispatch({ type: CLEAR_MANAGER_ACCOUNT });

//   try {
//     const res = await axios.get('/api/manageraccounts/select');

//     dispatch({
//       type: GET_MANAGER_ACCOUNTS,
//       payload: res.data
//     });
//   } catch (err) {
//     dispatch({
//       type: MANAGER_ACCOUNT_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// // Get manager account by ID
// export const getManagerAccountById = (managerAccountId) => async (dispatch) => {
//   try {
//     const res = await axios.get(
//       `/api/manageraccounts/?manager_account_id=${managerAccountId}`
//     );

//     dispatch({
//       type: GET_MANAGER_ACCOUNT,
//       payload: res.data
//     });
//   } catch (err) {
//     dispatch({
//       type: MANAGER_ACCOUNT_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// // ----------------------------------------------------------------------

// // Create or update manager account
// export const createManagerAccount = (formData, history, edit = false) => async (
//   dispatch
// ) => {
//   try {
//     const config = {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     };

//     const res = await axios.post('/api/manageraccounts', formData, config);

//     dispatch({
//       type: GET_MANAGER_ACCOUNT,
//       payload: res.data
//     });

//     dispatch(
//       setAlert(
//         edit ? 'Manager Account Updated' : 'Manager Account Created',
//         'success'
//       )
//     );

//     dispatch(getManagerAccounts());

//     if (!edit) {
//       history.push('/dashboard');
//     }
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
//     }

//     dispatch({
//       type: MANAGER_ACCOUNT_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };
