import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import React, { useState, Fragment, useContext } from 'react';
import Button from '@mui/material/Button';
import { ClickAwayListener } from '@mui/base';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles } from '@mui/styles';

import { SnackbarProvider, useSnackbar } from 'notistack';

import FolderIcon from '@mui/icons-material/Folder';

import PublicIcon from '@mui/icons-material/Public';

import { AuthContext } from '../../context/AuthContext';

import { AccountContext } from '../../context/AccountContext';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';

import { addTaxonomyDocumentMutation } from '../../graphql/queries';

export default function MenuListComposition({ checkedBoxes }) {
  const { user } = useContext(AuthContext);
  const { currentManagerAccountId } = useContext(AccountContext);

  const [addTaxonomyDocument] = useMutation(addTaxonomyDocumentMutation);

  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     '& > *': {
  //       // margin: theme.spacing(1),
  //       borderRadius: '5px',
  //       // height: '20px',
  //       // width: '37px',
  //       // backgroundColor: "rgba(0, 181, 8, 1)",
  //       backgroundColor: 'rgba(167, 38, 141, 1)',
  //       // checkedBoxes.length === 1 &&
  //       // ['account', 'globalTasks', 'folder', 'task'].includes(
  //       //   checkedBoxes[0].type
  //       // )
  //       //   ? 'rgba(187, 58, 151, 1)'
  //       //   : 'rgba(167, 38, 141, 0.3)',
  //       // ? 'rgba(178, 67, 147, 0.9)'
  //       // : 'rgba(178, 67, 147, 0.3)',
  //       // ? 'rgba(0, 181, 8, 0.9)'
  //       // : 'rgba(0, 181, 8, 0.3)',
  //       '&:hover': {
  //         backgroundColor: 'rgba(207, 78, 171, 1)'
  //         // checkedBoxes.length === 1 &&
  //         // ['account', 'globalTasks', 'folder', 'task'].includes(
  //         //   checkedBoxes[0].type
  //         // )
  //         //   ? 'rgba(207, 78, 171, 1)'
  //         //   : 'rgba(167, 38, 141, 0.3)'
  //         // ? 'rgba(20, 201, 28, 1)'
  //         // : 'rgba(0, 181, 8, 0.3)'
  //         // background: "rgba(20, 201, 28, 1)"
  //       }
  //     }
  //   },
  //   extendedIcon: {
  //     color: 'white',
  //     marginRight: theme.spacing(1)
  //   }
  // }));

  // const [addDocument] = useMutation(addDocumentMutation);

  const { enqueueSnackbar } = useSnackbar();

  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    // if (checkedBoxes.length > 1) {
    //   enqueueSnackbar('please check one box at the time', {
    //     variant: 'error'
    //   });

    //   return;
    // }
    // if (checkedBoxes.length === 0) {
    //   enqueueSnackbar('You need to check atleast one checkbox', {
    //     variant: 'error'
    //   });

    //   return;
    // }

    // if (
    //   !['account', 'globalTasks', 'folder', 'task'].includes(
    //     checkedBoxes[0].type
    //   )
    // ) {
    //   enqueueSnackbar('Please select Folder or account', {
    //     variant: 'error'
    //   });
    //   return;
    // }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, documentType) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseQuickCreate = (event, documentType, type) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    // if (checkedBoxes[0].level >= 9) {
    //   enqueueSnackbar('Max level container reached.', {
    //     variant: 'error'
    //   });

    //   return;
    // }

    // if (
    //   !['account', 'globalTasks', 'folder', 'task'].includes(
    //     checkedBoxes[0].type
    //   )
    // ) {
    //   enqueueSnackbar('Please select a Folder.', {
    //     variant: 'error'
    //   });

    //   return;
    // }

    let name = 'new document';
    switch (type) {
      case 'nexusTaxonomy':
        name = 'New Nexus Taxonomy';
        break;
    }

    let addTaxonomyDocumentInput = {
      // managerAccountId: currentManagerAccountId,
      // accountId: checkedBoxes[0].accountId,
      // checkedDocumentId: checkedBoxes[0]._id,
      // // level: checkedBoxes[0].level,
      // newDocuments: [{ documentType, name }]
    };

    addTaxonomyDocument({
      variables: { input: { ...addTaxonomyDocumentInput } }
    });

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  const rowType =
    checkedBoxes && checkedBoxes[0] && checkedBoxes[0].type
      ? checkedBoxes[0].type
      : '';
  const rowLevel =
    checkedBoxes && checkedBoxes[0] && checkedBoxes[0].level
      ? checkedBoxes[0].level
      : null;
  let buttons = (
    <Fragment>
      {/* {['account'].includes(rowType) && rowLevel < 9 && (
        <>
          {['system_admin'].includes(user.role) && (
            <MenuItem
              onClick={(e) =>
                handleCloseQuickCreate(
                  e,
                  'totalActionsDocument',
                  'totalActionsDocument'
                )
              }
            >
              <PublicIcon style={{ paddingRight: '5px' }} />
              Total Actions Document
            </MenuItem>
          )}
          <MenuItem
            onClick={(e) => handleCloseQuickCreate(e, 'folder', 'folder')}
          >
            <FolderIcon style={{ paddingRight: '5px' }} />
            Folder
          </MenuItem>
        </>
      )} */}

      {/* {['taxonomyDocument'].includes(rowType) && ( */}
      <Fragment>
        <MenuItem
          onClick={(e) => handleCloseQuickCreate(e, 'folder', 'folder')}
        >
          <FolderIcon style={{ paddingRight: '5px' }} />
          Folder
        </MenuItem>
      </Fragment>
      {/* )} */}
    </Fragment>
  );

  return (
    <div
    // className={classes.root}
    >
      <div>
        <div
        // className={classes.root}
        >
          <Fab
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            aria-label="add"
            style={{
              borderRadius: '5px',
              // height: '32px',
              height: '36px',
              minHeight: '32px',
              // width: '32px',
              width: '40px',
              minWidth: '32px',
              // backgroundColor:
              //   checkedBoxes.length === 1 &&
              //   ['Account', 'container', 'Folder', 'to_do_list'].includes(
              //     checkedBoxes[0].type
              //   )
              //     ? 'rgba(0, 181, 8, 0.9)'
              //     : 'rgba(0, 181, 8, 0.3)',
              color: 'white',
              fontSize: '12px'
            }}
            onClick={handleToggle}
          >
            <AddIcon />
          </Fab>
        </div>
        <Popper
          placement="bottom-start"
          style={{ zIndex: '6000' }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          onKeyDown={onKeyDownHandler}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: '0 0 0'
                // placement === 'bottom' ? 'center top' : 'center bottom'
                // placement === 'bottom-start'
                // placement === 'bottom-start' ? 'start top' : 'start bottom'
              }}
            >
              <Paper style={{ border: '1px solid rgba(255,255,255,0.3)' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {buttons}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
