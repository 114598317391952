import React from 'react';

import StringItem from '../StringItemV2';
import DefaultItem from '../DefaultItem';
// import ActionTypeItem_facebook_ads from './facebook_ads_campaign/ActionTypeItem_facebook_ads';
// import ActionTypeItem_google_ads from './google_ads_campaign/ActionTypeItem';

import ActionTypeItem from './ActionTypeItem';
import ActionTypeItemTextField from './ActionTypeItemTextField';

function ActionTypeItemSwitch({
  currentManagerAccountId,
  updateDocument,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  openRows,
  checked,
  index
  // stylesStoredInRedux
}) {
  if (item.isSet) {
    return (
      <ActionTypeItem
        currentManagerAccountId={currentManagerAccountId}
        updateDocument={updateDocument}
        item={item}
        itemStyle={itemStyle}
        keyForMap={item._id + '_' + itemKey + '_' + index}
        index={index}
        itemKey={itemKey}
        itemValue={item[itemKey]}
        rowNumber={rowNumber}
        textAlignRight={true}
        checked={checked}
        popperPosition={'right'}
      />
    );
  }

  if (item.isSetChild) {
    return (
      <DefaultItem
        currentManagerAccountId={currentManagerAccountId}
        updateDocument={updateDocument}
        item={item}
        itemStyle={itemStyle}
        keyForMap={item._id + '_' + itemKey + '_' + index}
        index={index}
        itemKey={itemKey}
        itemValue={item[itemKey] && item[itemKey].name}
        rowNumber={rowNumber}
        textAlignRight={true}
        checked={checked}
      />
    );
  }
  switch (item.type) {
    case 'totalActionsDocument':
      if (
        ['totalAction01', 'totalAction02', 'totalAction03'].includes(itemKey)
      ) {
        return (
          <ActionTypeItem
            currentManagerAccountId={currentManagerAccountId}
            updateDocument={updateDocument}
            item={item}
            itemStyle={itemStyle}
            keyForMap={item._id + '_' + itemKey + '_' + index}
            index={index}
            itemKey={itemKey}
            itemValue={item[itemKey]}
            rowNumber={rowNumber}
            textAlignRight={true}
            checked={checked}
            popperPosition={'bottom'}
          />
        );
        break;
      } else {
        return (
          <DefaultItem
            currentManagerAccountId={currentManagerAccountId}
            updateDocument={updateDocument}
            item={item}
            itemStyle={itemStyle}
            keyForMap={item._id + '_' + itemKey + '_' + index}
            index={index}
            itemKey={itemKey}
            itemValue={item[itemKey] && item[itemKey].name}
            rowNumber={rowNumber}
            textAlignRight={true}
            checked={checked}
          />
        );
      }
      break;

    case 'facebook_ads_campaign':
    case 'snapchat_ads_campaign':
    case 'tiktok_ads_campaign':
    case 'pinterest_ads_campaign':
    case 'readpeak_campaign':
    case 'strossle_campaign':
    case 'amazon_dsp_order':
    case 'taboola_ads_campaign':
    case 'microsoft_advertising_campaign':
    case 'microsoft_invest_insertion_order':
    // case 'apple_search_ads_campaign':
    case 'google_ads_campaign':
    case 'cm360_campaign':
    case 'dv360_insertion_order':
    case 'adform_order':
    case 'linkedin_ads_campaign_group':
    case 'manualDataEntry':
      if (['action01', 'action02', 'action03'].includes(itemKey)) {
        return (
          <ActionTypeItem
            currentManagerAccountId={currentManagerAccountId}
            updateDocument={updateDocument}
            item={item}
            itemStyle={itemStyle}
            keyForMap={item._id + '_' + itemKey + '_' + index}
            index={index}
            itemKey={itemKey}
            itemValue={item[itemKey]}
            rowNumber={rowNumber}
            textAlignRight={true}
            checked={checked}
            popperPosition={'right'}
          />
        );
        break;
      } else {
        return (
          <DefaultItem
            currentManagerAccountId={currentManagerAccountId}
            updateDocument={updateDocument}
            item={item}
            itemStyle={itemStyle}
            keyForMap={item._id + '_' + itemKey + '_' + index}
            index={index}
            itemKey={itemKey}
            itemValue={item[itemKey] && item[itemKey].name}
            rowNumber={rowNumber}
            textAlignRight={true}
            checked={checked}
          />
        );
      }
      break;
    // case 'facebook_ads_ad_set':
    // case 'google_ads_ad_group':
    //   return (
    //     <DefaultItem
    //       currentManagerAccountId={currentManagerAccountId}
    //       updateDocument={updateDocument}
    //       item={item}
    //       itemStyle={itemStyle}
    //       keyForMap={item._id + '_' + itemKey + '_' + index}
    //       index={index}
    //       itemKey={itemKey}
    //       itemValue={item[itemKey]}
    //       rowNumber={rowNumber}
    //       textAlignRight={true}
    //       checked={checked}
    //     />
    //   );
    //   break;
    // case 'account':
    case 'account':
      if (
        [
          'action01',
          'action02',
          'action03',
          'totalAction01',
          'totalAction02',
          'totalAction03'
        ].includes(itemKey)
      ) {
        return (
          <ActionTypeItemTextField
            currentManagerAccountId={currentManagerAccountId}
            updateDocument={updateDocument}
            item={item}
            itemStyle={itemStyle}
            keyForMap={item._id + '_' + itemKey + '_' + index}
            index={index}
            itemKey={itemKey}
            itemValue={item[itemKey]}
            rowNumber={rowNumber}
            textAlignRight={true}
            checked={checked}
          />
        );
        break;
      } else {
        return (
          <DefaultItem
            currentManagerAccountId={currentManagerAccountId}
            updateDocument={updateDocument}
            item={item}
            itemStyle={itemStyle}
            keyForMap={item._id + '_' + itemKey + '_' + index}
            index={index}
            itemKey={itemKey}
            itemValue={item[itemKey] && item[itemKey].name}
            rowNumber={rowNumber}
            textAlignRight={true}
            checked={checked}
          />
        );
      }

      break;

    case 'folder':
      if (
        [
          'action01',
          'action02',
          'action03'
          // 'totalAction01',
          // 'totalAction02',
          // 'totalAction03'
        ].includes(itemKey)
      ) {
        return (
          <ActionTypeItemTextField
            currentManagerAccountId={currentManagerAccountId}
            updateDocument={updateDocument}
            item={item}
            itemStyle={itemStyle}
            keyForMap={item._id + '_' + itemKey + '_' + index}
            index={index}
            itemKey={itemKey}
            itemValue={item[itemKey]}
            rowNumber={rowNumber}
            textAlignRight={true}
            checked={checked}
          />
        );
        break;
      } else {
        return (
          <DefaultItem
            currentManagerAccountId={currentManagerAccountId}
            updateDocument={updateDocument}
            item={item}
            itemStyle={itemStyle}
            keyForMap={item._id + '_' + itemKey + '_' + index}
            index={index}
            itemKey={itemKey}
            itemValue={item[itemKey] && item[itemKey].name}
            rowNumber={rowNumber}
            textAlignRight={true}
            checked={checked}
          />
        );
      }

    default:
      return (
        <DefaultItem
          currentManagerAccountId={currentManagerAccountId}
          updateDocument={updateDocument}
          item={item}
          itemStyle={itemStyle}
          keyForMap={item._id + '_' + itemKey + '_' + index}
          index={index}
          itemKey={itemKey}
          itemValue={item[itemKey] && item[itemKey].name}
          rowNumber={rowNumber}
          textAlignRight={true}
          checked={checked}
        />
      );
  }

  return <div></div>;
}

export default ActionTypeItemSwitch;
