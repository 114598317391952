import React, { useContext } from 'react';

import { DialogContext } from '../../../../../context/DialogContext';

// import TaxonomyItemContent from './TaxonomyItemContent';

function TaxonomyItem({
  itemKey,
  itemValue,
  item,
  rowNumber,
  keyForMap,
  itemStyle,
  checked
}) {
  const { setItemId, setTaxonomyDialogOpen } = useContext(DialogContext);

  const onClickHandler = () => {
    console.log('click!!!');
    setItemId(item._id);
    setTaxonomyDialogOpen(true);
  };

  if (item.documentType === 'taxonomy') {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
          padding: '10px 15px',
          whiteSpace: 'nowrap'
        }}
        onClick={onClickHandler}
      >
        {item.taxonomyWidgets.map((widget) => (
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              color: 'black',
              padding: '4px',
              background: 'white',
              borderRadius: '5px',
              margin: '0px 2px',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
            }}
          >
            {widget.value}
          </span>
        ))}
      </div>
    );
  } else {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '12px 15px',
          whiteSpace: 'nowrap'
        }}
        // onClick={onClickHandler}
      >
        <span
          style={{
            margin: 'auto'
          }}
        >
          {/* <p>{itemValue}</p> */}

          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </span>
      </div>
    );
  }
}

export default TaxonomyItem;
