import React from 'react';
// import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { deleteCustomFieldSetsMutation } from '../../../../../graphql/queries';

import { useMutation } from '@apollo/client';

// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(1)
//   }
// }));

export default function SimplePopover({
  _id,
  currentManagerAccountId,
  savedTo,
  item,
  refetchFieldSets
}) {
  const [deleteCustomFieldSet, { called }] = useMutation(
    deleteCustomFieldSetsMutation
  );

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickDeleteHandler = async () => {
    const input = {
      _id,
      managerAccountId: currentManagerAccountId,
      accountId: item.accountId,
      savedTo
    };

    const res = await deleteCustomFieldSet({ variables: { input } });

    refetchFieldSets();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Button
        style={{
          minWidth: '10px',
          width: '20px',
          minHeight: '10px',
          height: '20px'
        }}
      >
        <span>
          <DeleteIcon onClick={handleClick} style={{ paddingTop: '10px' }} />
        </span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Button
          onClick={onClickDeleteHandler}
          // className={classes.typography}
          style={{ cursor: 'pointer', userSelect: 'none' }}
        >
          Delete
        </Button>
      </Popover>
    </div>
  );
}
