import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//       width: '25ch'
//     }
//   }
// }));

export default function FormPropsTextFields({ action, setAction }) {
  // const classes = useStyles();

  const [name, setName] = React.useState(
    action?.manualDataEntry?.name ? action?.manualDataEntry?.name : ''
  );
  const handleChange = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };

  useEffect(() => {
    setAction({
      ...action,
      name,
      manualDataEntry: {
        name
      }
    });
  }, [name]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
      // className={classes.root}
      style={{ width: '95%' }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          variant="standard"
          required
          id="standard-required"
          label="Name"
          // defaultValue="Hello World"
          value={name}
          onChange={handleChange}
        />
      </div>
    </form>
  );
}
