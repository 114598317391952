import React, { useState, useContext } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

import EmojiEmotionsTwoToneIcon from '@mui/icons-material/EmojiEmotionsTwoTone';
import CommentIcon from '@mui/icons-material/Comment';

import { addCommentMutation } from './../../graphql/queries';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { AccountContext } from '../../context/AccountContext';

function CardMenu({ item, commentObj, showTextField, toggleShowTextField }) {
  const { currentManagerAccountId } = useContext(AccountContext);

  const [addComment, { called }] = useMutation(addCommentMutation);

  const [menuExpanded, toggleMenuExpanded] = useState(false);

  const onMouseEnter = () => {
    toggleMenuExpanded(true);
  };

  const onMouseLeave = () => {
    toggleMenuExpanded(false);
  };

  const onClickReaction = (e, reaction) => {
    const finalInput = {
      action: 'reaction',
      managerAccountId: currentManagerAccountId,
      accountId: item.accountId,
      documentId: item._id,
      level: item.level,
      documentType: item.documentType,
      // comment: {
      //   documentId: item._id,
      //   text: input ? input : ' '
      // },
      commentId: commentObj._id,
      newsFeedItemDocumentType: commentObj.type,
      reaction
    };

    // if (input !== commentObj.text[commentObj.text.length - 1].text) {
    addComment({ variables: { addCommentInput: finalInput } });
    // }
  };

  return (
    <div
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      style={{
        // width: menuExpanded ? 'auto' : '20px',
        overflowX: 'hidden',
        overflow: 'hidden',
        height: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        columnGap: '5px'
        // transition: 'width 2s'
      }}
      className="news-feed-menu"
    >
      <FavoriteIcon
        className="news-feed-action"
        onClick={(e) => onClickReaction(e, 'HEART')}
        style={{ color: 'red' }}
      />
      <ThumbUpIcon
        className="news-feed-action"
        onClick={(e) => onClickReaction(e, 'THUMBUP')}
      />
      {/* <EmojiEmotionsTwoToneIcon
        className="news-feed-action"
        onClick={(e) => onClickReaction(e, 'SMILE')}
        // style={{ color: 'yellow' }}
      /> */}
      {commentObj.type !== 'poke' && (
        <CommentIcon
          className="news-feed-action"
          onClick={() => toggleShowTextField(!showTextField)}
        />
      )}
      <MoreHorizIcon className="news-feed-action" />
      {/* {menuExpanded ? (
        <div
          style={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }}
        >
          <FavoriteIcon className="news-feed-action" />{' '}
          <ThumbUpIcon className="news-feed-action" />
          <EmojiEmotionsIcon className="news-feed-action" />
        </div>
      ) : (
        <MoreVertIcon />
      )} */}
    </div>
  );
}

export default CardMenu;
