import React, { useContext, useEffect, useState } from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import { ReportBuilderContext } from '../../../../../../context/ReportBuilderContext';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import Tooltip from '@mui/material/Tooltip';

import { useSnackbar } from 'notistack';

// import ListItemIcon from '@mui/material/ListItemIcon';
// import Typography from '@mui/material/Typography';

function GroupMetricButton({ widget, metricObj }) {
  const { enqueueSnackbar } = useSnackbar();
  const { updateWidget } = useContext(ReportBuilderContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickHandler = (e, action) => {
    e.preventDefault();
    e.stopPropagation();

    // console.log('debug41>action: ', action);
    // console.log('debug41>metricObj: ', metricObj);
    if (['standard_bar', 'stacked_bar'].includes(action)) {
      const widgetMetricsGroupedByAxis = Object.groupBy(
        widget.metrics,
        (row) => row['axis']
      );

      const oppositeAxis =
        metricObj.axis === 'leftAxisId' ? 'rightAxisId' : 'leftAxisId';

      if (
        widgetMetricsGroupedByAxis[oppositeAxis]?.filter((metric) =>
          ['bar'].includes(metric.chartType)
        ).length
      ) {
        enqueueSnackbar(
          'Max ONE axis can use the bar option. Please use the line option.',
          {
            variant: 'error'
          }
        );
        return;
      }
    }

    if (['stacked_bar', 'stacked_line'].includes(action)) {
      // const widgetMetricsGroupedByAxis = Object.groupBy(
      //   widget.metrics,
      //   (row) => row['axis']
      // );

      // const oppositeAxis =
      //   metricObj.axis === 'leftAxisId' ? 'rightAxisId' : 'leftAxisId';

      // if (
      //   widgetMetricsGroupedByAxis[oppositeAxis].filter((metric) =>
      //     ['stacked'].includes(metric.grouping)
      //   ).length
      // ) {
      //   enqueueSnackbar('Max ONE stacked metric per axis.', {
      //     variant: 'error'
      //   });
      //   return;
      // }

      const widgetMetricsGroupedByAxis = Object.groupBy(
        widget.metrics,
        (row) => row['axis']
      );

      if (widgetMetricsGroupedByAxis[metricObj.axis].length > 1) {
        enqueueSnackbar(
          'Max ONE metric on the same axis using stacked bar/line',
          {
            variant: 'error'
          }
        );
        return;
      }
    }

    const copyOfWidget = { ...widget };

    let copyOfMetrics = [...(widget?.metrics || [])];

    const copyOfMetricObj = { ...metricObj };

    const metricObjIndex = copyOfMetrics.findIndex(
      (obj) => obj.metric === metricObj.metric
    );

    let update = {};
    switch (action) {
      case 'standard_bar':
        update = {
          grouping: 'standard',
          chartType: 'bar',
          groupingChartType: 'standard_bar'
        };
        break;
      case 'stacked_bar':
        update = {
          grouping: 'stacked',
          chartType: 'bar',
          groupingChartType: 'stacked_bar'
        };
        break;
      case 'standard_line':
        update = {
          grouping: 'standard',
          chartType: 'line',
          groupingChartType: 'standard_line'
        };
        break;
      case 'stacked_line':
        update = {
          grouping: 'stacked',
          chartType: 'line',
          groupingChartType: 'stacked_line'
        };
        break;
    }
    copyOfMetrics[metricObjIndex] = {
      ...copyOfMetrics[metricObjIndex],
      ...update
    };

    copyOfWidget.metrics = copyOfMetrics;

    // console.log(
    //   'copyOfMetrics[metricObjIndex]: ',
    //   copyOfMetrics[metricObjIndex]
    // );

    // console.log('debug11>Grouping>copyOfWidget: ', copyOfWidget);
    updateWidget(copyOfWidget);
    handleClose();
  };

  // console.log('widget33:', widget);

  let icon = <div></div>;
  let selectedOption = null;
  switch (true) {
    case metricObj?.grouping === 'standard' && metricObj?.chartType === 'bar':
      icon = <BarChartIcon />;
      selectedOption = 'standard_bar';
      break;
    case metricObj?.grouping === 'stacked' && metricObj?.chartType === 'bar':
      icon = <StackedBarChartIcon />;
      selectedOption = 'stacked_bar';
      break;
    case metricObj?.grouping === 'standard' && metricObj?.chartType === 'line':
      icon = <ShowChartIcon />;
      selectedOption = 'standard_line';
      break;
    case metricObj?.grouping === 'stacked' && metricObj?.chartType === 'line':
      icon = <StackedLineChartIcon />;
      selectedOption = 'stacked_line';
      break;
  }

  // console.log('metricObj.grouping: ', metricObj.grouping);
  return (
    <>
      <Tooltip title={`${metricObj?.grouping} ${metricObj?.chartType}`}>
        <Button
          onClick={handleClick}
          style={{
            minWidth: '40px',
            width: '40px',
            minHeight: '40px',
            height: '40px'
          }}
        >
          {icon}
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 140, maxWidth: '100%', fontSize: '12px' }}>
          <MenuList>
            <MenuItem
              style={{
                background:
                  selectedOption === 'standard_bar' && 'rgba(150,150,150,0.2)'
              }}
              onClick={(e) => onClickHandler(e, 'standard_bar')}
            >
              <BarChartIcon
                style={{
                  fontSize: '14px'
                }}
              />
              <ListItemText>
                <span style={{ fontSize: '14px', marginLeft: '5px' }}>
                  Standard Bar
                </span>
              </ListItemText>
            </MenuItem>
            <MenuItem
              style={{
                background:
                  selectedOption === 'stacked_bar' && 'rgba(150,150,150,0.2)'
              }}
              onClick={(e) => onClickHandler(e, 'stacked_bar')}
            >
              <StackedBarChartIcon style={{ fontSize: '14px' }} />
              <ListItemText>
                <span style={{ fontSize: '14px', marginLeft: '5px' }}>
                  Stacked Bar
                </span>
              </ListItemText>
            </MenuItem>
            <MenuItem
              style={{
                background:
                  selectedOption === 'standard_line' && 'rgba(150,150,150,0.2)'
              }}
              onClick={(e) => onClickHandler(e, 'standard_line')}
            >
              <ShowChartIcon style={{ fontSize: '14px' }} />
              <ListItemText>
                <span style={{ fontSize: '14px', marginLeft: '5px' }}>
                  Standard Line
                </span>
              </ListItemText>
            </MenuItem>
            <MenuItem
              style={{
                background:
                  selectedOption === 'stacked_line' && 'rgba(150,150,150,0.2)'
              }}
              onClick={(e) => onClickHandler(e, 'stacked_line')}
            >
              <StackedLineChartIcon style={{ fontSize: '14px' }} />
              <ListItemText>
                <span style={{ fontSize: '14px', marginLeft: '5px' }}>
                  Stacked Line
                </span>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}

export default GroupMetricButton;
