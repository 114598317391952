import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { ClickAwayListener } from '@mui/base';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import { SketchPicker } from 'react-color';

import { MutationContext } from '../../../../context/MutationContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     // width: 500
//   },
//   typography: {
//     padding: theme.spacing(2)
//   }
// }));

const PositionedPopper = ({
  currentManagerAccountId,
  // updateDocument,
  item
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  // const classes = useStyles();

  const { updateDocument } = useContext(MutationContext);

  const [color, setColor] = useState('rgba(178, 67, 147,1)');

  const [update, toggleUpdate] = useState(false);

  const backgroundColor =
    item &&
    item.style &&
    item.style.gantt &&
    item.style.gantt.bar.backgroundColor;
  useEffect(() => {
    if (
      item &&
      item.style &&
      item.style.gantt &&
      item.style.gantt.bar.backgroundColor
    ) {
      setColor(item.style.gantt.bar.backgroundColor);
    }
  }, [backgroundColor]);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    if (open) {
      // const documentInput = {
      //   accountId: item.accountId,
      //   documentId: item._id,
      //   document: {
      //     color
      //   },
      //   updateKey: 'styleGanttBarColor',
      //   action: 'UPDATE_DOCUMENT',
      //   managerAccountId: currentManagerAccountId,
      //   documentType: item.type,
      //   level: item.level
      // };

      const documentInput = {
        managerAccountId: currentManagerAccountId,
        documents: [
          {
            accountId: item.accountId,
            documentId: item._id,
            documentType: item.documentType,
            color
          }
        ],
        updateKeys: ['styleGanttBarColor']
      };
      if (update) {
        updateDocument({ variables: { input: { ...documentInput } } });
        toggleUpdate(false);
      }
    }

    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChange = (color) => {
    setColor(
      `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    );
    toggleUpdate(true);
  };

  const handleClickAway = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // const documentInput = {
    //   accountId: item.accountId,
    //   documentId: item._id,
    //   document: { color },
    //   updateKey: 'styleGanttBarColor',
    //   action: 'UPDATE_DOCUMENT',
    //   managerAccountId: currentManagerAccountId,
    //   documentType: item.documentType,
    //   level: item.level
    // };

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          styleGanttBarColor: color
        }
      ],
      updateKeys: ['styleGanttBarColor']
    };
    if (update) {
      updateDocument({ variables: { input: { ...documentInput } } });
      toggleUpdate(false);
    }

    setOpen(false);
  };

  return (
    <div
    // className={classes.root}
    >
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                <div>
                  <SketchPicker
                    color={color}
                    onChange={handleChange}
                    onClick={handleClick}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <div
        style={{
          display: 'inline-block',
          width: '20px',
          height: '20px',
          border: '1px solid rgba(255,255,255,0.3)',
          borderRadius: '5px',
          backgroundColor: color
        }}
        onClick={handleClick('bottom')}
      >
        {' '}
      </div>
      {/* <Button onClick={handleClick('bottom')}>bottom</Button> */}
    </div>
  );
};

export default React.memo(PositionedPopper);
