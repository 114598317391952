import React, { useState, useContext, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// import { useLazyQuery } from '@apollo/client';
// import { userTimeboxListsQuery } from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';

import { DataContext } from '../../../../../context/DataContext';

export default function ComboBox(
  {
    // selectedTimeboxList,
    // setSelectedTimeboxList
  }
) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [inputValue, setInputValue] = useState('');

  const {
    selectedTimeboxList,
    setSelectedTimeboxList,
    userTimeboxLists,
    setUserTimeboxLists
  } = useContext(DataContext);

  // const variables = {
  //   input: {
  //     managerAccountId: currentManagerAccount._id
  //   }
  // };

  // const [loadTimeboxLists, { called, loading, data, refetch }] = useLazyQuery(
  //   userTimeboxListsQuery,
  //   {
  //     variables,
  //     fetchPolicy: 'no-cache'
  //   }
  // );

  // console.log('TimeboxListAutoComplete>data: ', data);

  // useEffect(() => {
  //   loadTimeboxLists();
  // }, []);

  const options = userTimeboxLists || [];

  // useEffect(() => {
  //   if (options.length > 0 && !selectedTimeboxList) {
  //     setSelectedTimeboxList(options[0]);
  //   }
  // }, [options]);

  const onChangeHandler = (event, newValue) => {
    // console.log('newValue: ', newValue);
    setSelectedTimeboxList(newValue);
  };
  return (
    <Autocomplete
      open={true}
      autoHighlight
      blurOnSelect={true}
      style={{ width: '200px' }}
      size="small"
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        option?.name + option?._id + option?.type + option?.allwaysShow
      }
      renderInput={(params) => (
        <TextField
          autoFocus
          placeholder="Select user"
          variant="standard"
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          // label="Movie"
        />
      )}
      // onInputChange={(event, newInputValue, reason) => {
      //   if (reason !== 'reset') {
      //     console.log('newInputValue: ', newInputValue);
      //     setInputValue(newInputValue);
      //   }
      // }}

      onChange={(event, newValue) => {
        onChangeHandler(event, newValue);
      }}
      renderOption={(props, option, { selected }) => (
        <li
          key={option._id}
          {...props}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            {option?.name}
          </div>
        </li>
      )}
    />
  );
}
