const mongoose = require('mongoose');
const Schema = mongoose.Schema;

const { encrypt, decrypt } = require('../functions/crypto/crypto');

const imageToBase64 = require('image-to-base64');

const { GetObjectCommand, PutObjectCommand } = require('@aws-sdk/client-s3');
const { S3Client } = require('@aws-sdk/client-s3');
const { getSignedUrl } = require('@aws-sdk/s3-request-presigner');

const awsBucketName = process.env.AWS_BUCKET_NAME;
const awsBucketRegion = process.env.AWS_BUCKET_REGION;

const s3Client = new S3Client({ region: awsBucketRegion });
const bucket = awsBucketName;

async function getSignedDownloadUrl(path) {
  let command = new GetObjectCommand({ Bucket: bucket, Key: path });
  return await getSignedUrl(s3Client, command, { expiresIn: 3600 });
}

async function getSignedUploadUrl(path) {
  let command = new PutObjectCommand({ Bucket: bucket, Key: path });
  return await getSignedUrl(s3Client, command, { expiresIn: 3600 });
}

const S3BucketFileSchema = new Schema(
  {
    id: String,
    managerAccountIds: [
      {
        type: mongoose.Schema.Types.ObjectId,
        ref: 'ManagerAccount'
      }
    ],
    accountIds: [
      {
        type: mongoose.Schema.Types.ObjectId,
        ref: 'Account'
      }
    ],

    status: String,

    category: String,

    name: String,
    fileName: String,
    key: String,
    region: String,
    bucket: String,

    // thumbnailImageDataUri: String,
    // previewImageDataUri: String,

    imageNaturalHeight: Number,
    imageNaturalWidth: Number,
    // imageDataUri: String,
    imageNaturalAspectRatio: Number,

    // lastModified: Date,

    // lastModifiedDate: Date,

    // name: String,
    // fileName: String,

    size: Number,
    type: String,
    xAmzId2: String,
    xAmzRequestId: String,
    eTag: String,
    date: Date,
    isFileUploadCompleted: Boolean,

    // type: {
    //   type: String
    // },
    signedUploadUrlCreatedAt: Date,
    signedUploadUrlExpiresAt: Date,

    createdByUserId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    createdByManagerAccountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'ManagerAccount'
    },
    lastUpdatedByUserId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    lastUpdatedByManagerAccountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'ManagerAccount'
    },

    removedAt: Date,
    removedByUserId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    removedByManagerAccountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'ManagerAccount'
    },
    signedDownloadUrlExpiresAt: Date,
    signedDownloadUrl: {
      iv: String,
      encryptedData: String
    },
    signedUploadUrlExpiresAt: Date,
    signedUploadUrl: {
      iv: String,
      encryptedData: String
    }
  },
  { timestamps: true }
);

// S3BucketFileSchema.virtual('imageDataUri').get(async function () {
//   const s3Client = new S3Client({ region: awsBucketRegion });
//   console.log('running imageDataUri');

//   // Get the object from S3
//   try {
//     const command = new GetObjectCommand({
//       Bucket: this.bucket,
//       Key: this.key
//     });
//     // console.log('GetObjectCommandInput: ', {
//     //   Bucket: awsBucketName,
//     //   Key: this.key
//     // });
//     const item = await s3Client.send(command);
//     console.log('item: ', item);
//   } catch (error) {
//     console.log('error: ', error);
//   }

//   // console.log('imageDataUri>res: ', res);
// });

S3BucketFileSchema.virtual('imageDataUri').get(async function () {
  // console.log('running imageDataUri');
  if (this.signedDownloadUrlExpiresAt > new Date()) {
    const signedDownloadUrl = decrypt(this.signedDownloadUrl);

    return signedDownloadUrl;
  }

  let downloadURL = await getSignedDownloadUrl(this.key);

  this.signedDownloadUrl = encrypt(downloadURL);

  this.signedUploadUrlCreatedAt = new Date();

  const expires_in = 3600;
  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + expires_in);
  this.signedDownloadUrlExpiresAt = expirationDate;

  await this.save();

  const res = await imageToBase64(downloadURL); // Image URL
  // console.log('res: ', res);

  const dataUri = `data:${this.type};base64,${res}`;
  return dataUri;
});

S3BucketFileSchema.virtual('getSignedUploadUrl').get(async function () {
  // S3BucketFileSchema.virtual('getSignedUploadUrl').get(async function () {
  if (this.signedUploadUrlExpiresAt > new Date()) {
    // console.log(
    //   'this.signedUploadUrlExpiresAt > new Date(): ',
    //   this.signedUploadUrlExpiresAt > new Date()
    // );
    const signedUploadUrl = decrypt(this.signedUploadUrl);

    return signedUploadUrl;
  }

  let uploadURL = await getSignedUploadUrl(this.key);

  this.signedUploadUrl = encrypt(uploadURL);

  this.signedUploadUrlCreatedAt = new Date();

  const expires_in = 3600;
  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + expires_in);
  this.signedUploadUrlExpiresAt = expirationDate;

  await this.save();

  return uploadURL;
});

S3BucketFileSchema.virtual('getSignedDownloadUrl').get(async function () {
  // S3BucketFileSchema.virtual('getSignedDownloadUrl').get(async function () {
  if (this.signedDownloadUrlExpiresAt > new Date()) {
    const signedDownloadUrl = decrypt(this.signedDownloadUrl);

    return signedDownloadUrl;
  }

  let downloadURL = await getSignedDownloadUrl(this.key);

  this.signedDownloadUrl = encrypt(downloadURL);

  this.signedDownloadUrlCreatedAt = new Date();

  const expires_in = 3600;
  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + expires_in);
  this.signedDownloadUrlExpiresAt = expirationDate;

  await this.save();

  return downloadURL;
});

module.exports = S3BucketFile = mongoose.model(
  'S3BucketFile',
  S3BucketFileSchema
);
