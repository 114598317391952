import React from 'react';

import { styled, useTheme } from '@mui/material/styles';

import NexusTableChangelogPost from './NexusTableChangelogPost';

import NexusTableChangelogInput from './NexusTableChangelogInput';

function NexusTableChangelogs() {
  const theme = useTheme();

  const logs = [
    {
      date: '2024-07-28',
      headline: `Edit pens replaced by click`,
      text: `Edit pens have been removed in the table view. Moving forward, simply click on the field you want to edit.`,
      requiredAction: ``
    },
    {
      date: '2024-07-28',
      headline: `New name column design`,
      text: `Trying out a new name column design.`,
      requiredAction: ``
    },
    {
      date: '2024-07-28',
      headline: `Right click on the open caret (arrow) to recursively open the folder and subfolders.`,
      text: ``,
      requiredAction: ``
    },
    {
      date: '2024-07-26',
      headline: `New filter!`,
      text: `A new filter called "Filter by pace (Beta)" is being tested. It is suposed to filter rows with pacing.`,
      requiredAction: ``
    }
    // {
    //   date: '2024-04-28',
    //   headline: `Nexus Assistant update`,
    //   text: `Nexus Assistant now emails and changes status to DOUBLECHECK when detecting impressions before start date or missing impressions yesterday (on data source level). You can turn off these behaviours in the context menu you access by right clicking the warning column on data source level.`,
    //   requiredAction: ``
    // }
    // {
    //   date: '2023-10-08',
    //   headline: `New dark mode design`,
    //   text: ``,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-10-02',
    //   headline: `NexusAssistant 2.0 released!`,
    //   text: `The new NexusAssistant scans data from one year back to one year ahead. You no longer need to chop longer activities into pieces of three months (even though it is still recommended due to loading speed). Activities need to be chopped into pieces of max one year if the activities exceed one year. NexusAssistant now supports delay for sms warnings during night time. Contact support if you wish to delay your sms warnings. During rollout of NexusAssistant 2.0 you might receive warnings that are old due to the new longer date range.`,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-09-28',
    //   headline: `New currency column`,
    //   text: `The new column is called "Data Source Currency" and displays the account currency that NexusTable received when the data source was connected. "DS Currency" will be displayed in the table in order to keep the column as slim as possible. Currency for CM360 and Adform is not available at the moment.`,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-09-13',
    //   headline: `Overspend warning threshold changed from budget to budget + 10.`,
    //   text: ``,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-08-30',
    //   headline: `Pinterest ads connector released!`,
    //   text: ``,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-08-30',
    //   headline: `Calendar view released!`,
    //   text: `Calendar view focuses on when activities start and end. The Calendar view button is located next to the Kanban view button.`,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-06-28',
    //   headline: `Change of calculated pace yesterday and pace yesterday warning thresholds!`,
    //   text: `Upper threshold is changed from 130% to 150% and lower from 70% to 50%.`,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-06-26',
    //   headline: `Kanban button moved`,
    //   text: `The Kanban button is now located next to the column menu button.`,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-06-20',
    //   headline: `Direct links in email pokes!`,
    //   text: `Pokes now support links to activities in both NexusTable and respective data source platform.`,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-06-18',
    //   headline: `Change of calculated pace yesterday and pace yesterday warning thresholds!`,
    //   text: `Upper threshold is changed from 120% to 130% and lower from 80% to 70%.`,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-06-18',
    //   headline: `News feed on home screen!`,
    //   text: `Please make sure to read this once in a while to keep you up to speed with the latest news and changes in NexusTable.`,
    //   requiredAction: ``
    // },
    // {
    //   date: '2023-06-14',
    //   headline: `Scan upon import - Facebook Campaigns`,
    //   text: `A new feature were NexusTable scans campaigns for DAILY vs LIFETIME budgets and COUNTRIES upon import. The feature can be set to mandatory or optional on Manager Account level.`,
    //   requiredAction: `` //`No action required`
    // }
  ];
  return (
    <div
      style={{
        // background: 'rgba(255,255,255,0.5)',
        borderRadius: '5px',
        // height: '400px',
        width: '600px',
        padding: '10px 2px 10px 10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '10px',
        overflowY: 'scroll'
      }}
    >
      <div
        style={{
          fontWeight: 'bold',
          color:
            theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.9)'
              : 'rgba(33,40,90,0.5)'
        }}
      >
        News
      </div>
      {/* <NexusTableChangelogInput /> */}
      {logs.map((log) => (
        <NexusTableChangelogPost log={log} />
      ))}
    </div>
  );
}

export default NexusTableChangelogs;
