import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from 'date-fns';

const today = new Date();
const quarter = Math.floor(today.getMonth() / 3);

const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
const endFullQuarter = new Date(
  startFullQuarter.getFullYear(),
  startFullQuarter.getMonth() + 3,
  0
);

const aYearFromNow = new Date();
aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

const defineds = {
  startOfDailySync: startOfMonth(addMonths(new Date(), -3)),
  endOfDailySync: endOfMonth(addMonths(new Date(), 3)),
  startOfWeekSunSat: startOfWeek(new Date()),
  endOfWeekSunSat: endOfWeek(new Date()),
  startOfWeekMonSun: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endOfWeekMonSun: endOfWeek(new Date(), { weekStartsOn: 1 }),
  startOfLastWeekSunSat: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeekSunSat: endOfWeek(addDays(new Date(), -7)),
  startOfLastWeekMonSun: startOfWeek(addDays(new Date(), -7), {
    weekStartsOn: 1
  }),
  endOfLastWeekMonSun: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),

  startOfLast3Days: addDays(new Date(), -3),
  startOfLast7Days: addDays(new Date(), -7),
  startOfLast30Days: addDays(new Date(), -30),
  endOfNext90Days: addDays(new Date(), 90),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfNextYear: startOfYear(aYearFromNow),
  endOfNextYear: endOfYear(aYearFromNow)
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  }
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    string: 'today',
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday
    })
  },
  {
    string: 'yesterday',
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday
    })
  },
  {
    string: 'last_3_days',
    label: 'Last 3 days',
    range: () => ({
      startDate: defineds.startOfLast3Days,
      endDate: defineds.endOfYesterday
    })
  },
  {
    string: 'last_7_days',
    label: 'Last 7 days',
    range: () => ({
      startDate: defineds.startOfLast7Days,
      endDate: defineds.endOfYesterday
    })
  },

  // {
  //   label: 'This Week Sun-Sat',
  //   range: () => ({
  //     startDate: defineds.startOfWeekSunSat,
  //     endDate: defineds.endOfWeekSunSat
  //   })
  // },
  // {
  //   label: 'This Week Mon-Sun',
  //   range: () => ({
  //     startDate: defineds.startOfWeekMonSun,
  //     endDate: defineds.endOfWeekMonSun
  //   })
  // },
  // {
  //   label: 'Last Week Sun-Sat',
  //   range: () => ({
  //     startDate: defineds.startOfLastWeekSunSat,
  //     endDate: defineds.endOfLastWeekSunSat
  //   })
  // },
  // {
  //   label: 'Last Week Mon-Sun',
  //   range: () => ({
  //     startDate: defineds.startOfLastWeekMonSun,
  //     endDate: defineds.endOfLastWeekMonSun
  //   })
  // },
  {
    string: 'last_30_days',
    label: 'Last 30 days',
    range: () => ({
      startDate: defineds.startOfLast30Days,
      endDate: defineds.endOfYesterday
    })
  },
  {
    string: 'last_month',
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth
    })
  },
  {
    string: 'this_month',
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth
    })
  },

  {
    string: 'this_quarter',
    label: 'This Quarter',
    range: () => ({
      startDate: startFullQuarter,
      endDate: endFullQuarter
    })
  },

  {
    string: 'next_month',
    label: 'Next Month',
    range: () => ({
      startDate: defineds.startOfNextMonth,
      endDate: defineds.endOfNextMonth
    })
  },
  {
    string: 'next_90_days',
    label: 'Next 90 days',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfNext90Days
    })
  },
  {
    string: 'year_until_yesterday',
    label: 'Year until yesterday',
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfYesterday
    })
  },
  {
    string: 'this_year',
    label: 'This year',
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfYear
    })
  },
  {
    string: 'next_year',
    label: 'Next year',
    range: () => ({
      startDate: defineds.startOfNextYear,
      endDate: defineds.endOfNextYear
    })
  },
  {
    string: 'daily_sync',
    label: 'Daily sync',
    range: () => ({
      startDate: defineds.startOfDailySync,
      endDate: defineds.endOfDailySync
    })
  }
]);

export const defaultInputRanges = [
  {
    string: 'days_up_to_today',
    label: 'days up to today',
    range(value) {
      return {
        startDate: addDays(
          defineds.startOfToday,
          (Math.max(Number(value), 1) - 1) * -1
        ),
        endDate: defineds.endOfToday
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
      if (!range.startDate) return '∞';
      return differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1;
    }
  },
  {
    string: 'days_starting_today',
    label: 'days starting today',
    range(value) {
      const today = new Date();
      return {
        startDate: today,
        endDate: addDays(today, Math.max(Number(value), 1) - 1)
      };
    },
    getCurrentValue(range) {
      if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
      if (!range.endDate) return '∞';
      return differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1;
    }
  }
];
