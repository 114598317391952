import React, { useState, useContext, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';

// import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './../../reactDateRange.css';

import { useSnackbar } from 'notistack';

import { defaultStaticRanges } from './ReactDateRangeDefaultRanges';

import formatDateYYYYMMDD from './../../roitable_functions/formatDateYYYYMMDD';

import DateRangeIcon from '@mui/icons-material/DateRange';

import { connect } from 'react-redux';

// import { MutationContext } from '../../../../../context/MutationContext';

// import { AccountContext } from '../../../../../context/AccountContext';

// import SpinnerSmall from '../../../../layout/SpinnerSmall';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from 'date-fns';

const getISOStringWithTimeZoneOffset = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split('T')[0];
};

const moment = require('moment');

// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(2)
//   }
// }));

const differnceInDays = (start, stop) => {
  var date1 = new Date(start);
  var date2 = new Date(stop);
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
};

const SimplePopover = ({ stylesStoredInRedux, value, onSetValueHandler }) => {
  //######################

  const { enqueueSnackbar } = useSnackbar();

  // const end = moment(selectedDateStop, 'YYYY-MM-DD');

  const [startDate, setStartDate] = useState(new Date(value.startDate));
  const [endDate, setEndDate] = useState(new Date(value.endDate));

  const [previewDatePreset, setPreviewDatePreset] = useState('');

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setStartDate(new Date(value.startDate));
    setEndDate(new Date(value.endDate));
  }, [value.startDate, value.endDate]);
  // ################## WIP ##############

  const handleSelect = (ranges) => {
    console.log('ranges: ', ranges);

    console.log('defaultStaticRanges: ', defaultStaticRanges);

    let selectedDatePreset = 'custom';
    for (let obj of defaultStaticRanges) {
      const defaultStaticRange = obj.range();
      if (
        defaultStaticRange.startDate === ranges.selection.startDate &&
        defaultStaticRange.endDate === ranges.selection.endDate
      ) {
        selectedDatePreset = obj.string;
      }
    }
    //check if accounts.length<0 then check if date picker is more than 90 days.
    //if more than 90 days abort and send an error message.

    const diff_in_days = differnceInDays(
      ranges.selection.startDate,
      ranges.selection.endDate
    );
    // if (diff_in_days > 400 && currentAccountIds.length > 20) {
    //   enqueueSnackbar(
    //     'Date range over 400 days are only available for max 20 accounts at the time',
    //     {
    //       variant: 'error'
    //     }
    //   );

    //   return;
    // }

    // if (diff_in_days > 400 && !['system_admin'].includes(user.role)) {
    //   enqueueSnackbar('Max date range of 400 days supported.', {
    //     variant: 'error'
    //   });

    //   return;
    // }

    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    // setPreviewDatePreset(selectedDatePreset);
    // onClickApply(ranges.selection.startDate, ranges.selection.endDate);
  };

  const selectionRange = {
    stylesStoredInRedux,
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
    showMonthAndYearPickers: true
    // color: 'rgba(255,255,255,0.3)',
    // rangeColors: 'rgba(255,255,255,1)'
  };

  let dateRangePickerClassName;

  switch (stylesStoredInRedux.styleName) {
    case 'light':
      dateRangePickerClassName = 'light-background';
      // buttonStyle = { backgroundColor: '#FFFFFF', color: 'black' };
      break;
    case 'dark':
      dateRangePickerClassName = 'dark-background';
      // buttonStyle = { backgroundColor: '#333', color: 'white' };
      selectionRange.color = 'rgba(255,255,255,0.3)';
      selectionRange.rangeColors = 'rgba(255,255,255,1)';
      break;
  }
  // console.log('debug74>startDate: ', startDate);
  // console.log('debug74>endDate: ', endDate);
  //######################

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setStartDate(new Date(value.startDate));
    setEndDate(new Date(value.endDate));
    setAnchorEl(null);
  };

  const onClickApply = async (startDate, endDate) => {
    onSetValueHandler('startDate', formatDateYYYYMMDD(startDate));
    onSetValueHandler('endDate', formatDateYYYYMMDD(endDate));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="dark">
      <Button
        style={{
          maxWidth: '40px',
          minWidth: '40px',
          maxHeight: '40px',
          minHeight: '40px'
        }}
        aria-describedby={id}
        variant="standard"
        // color="primary"
        onClick={handleClick}
      >
        <DateRangeIcon style={{ fontSize: '20px' }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {/* <Typography className={classes.typography}>
          The content of the Popover.
        </Typography> */}
        <DateRangePicker
          // locale={sv}
          // color="#9953BD"
          // rangeColors="#9953BD"
          color="#a72683"
          rangeColors={['#a72683']}
          // scroll={{ enabled: true }}
          staticRanges={defaultStaticRanges}
          className={dateRangePickerClassName}
          ranges={[selectionRange]}
          onChange={handleSelect}
          weekStartsOn={1}
        />

        <div
          style={{
            padding: '10px 20px',
            textAlign: 'right',
            minHeight: '51px'
            // backgroundColor: 'red'
          }}
        >
          <>
            <Button
              // variant="contained"
              size="small"
              color="primary"
              // className={classes.margin}
              onClick={handleClose}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
            <Button
              // variant="contained"
              size="small"
              color="primary"
              // className={classes.margin}
              onClick={() => onClickApply(startDate, endDate)}
            >
              Apply
            </Button>
          </>
        </div>
      </Popover>
    </div>
  );
};

// export default React.memo(SimplePopover);

SimplePopover.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired,
  // style: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(React.memo(SimplePopover));
