import React, { useState, useContext, useEffect } from 'react';

import { Button } from '@mui/material';

import { useTheme } from '@emotion/react';

import { DialogContext } from '../../../../../context/DialogContext';

function UploadAssetsButton() {
  const theme = useTheme();

  const { uploadReportAssetsDialogOpen, setUploadReportAssetsDialogOpen } =
    useContext(DialogContext);

  return (
    <Button
      style={{
        padding: '0px',
        margin: '10px 10px',
        height: '200px',
        // aspectRatio: aspectRatio,
        boxShadow:
          theme?.palette?.mode === 'dark'
            ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' //'0px 3px 1px -2px rgb(75 75 150 / 20%), 0px 2px 2px 0px rgb(75 75 150 / 14%), 0px 1px 5px 0px rgb(75 75 150 / 12%)' //'0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
            : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        // gridRowStart: 2,
        // gridRowEnd: 2,
        // height: '40px',
        // justifyContent: 'flex-start'
        // background:
        //   (selectedReport.type === report.type ||
        //     selectedReport._id === report._id) &&
        //   stylesStoredInReduxManagerAccountSettings.selectedListButton
      }}
      onClick={() => setUploadReportAssetsDialogOpen(true)}
    >
      <div
        style={{
          //   margin: '5px',
          borderRadius: '5px',
          height: '200px',
          width: '200px',
          //   aspectRatio: aspectRatio,
          // width: '400px',
          //   backgroundSize: 'cover',
          // width: '170px',

          //   backgroundImage: `url(${reportAsset.thumbnailImageDataUri})`,
          background: 'rgba(255,255,255,0.3)',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          //   border:
          //     reportAsset?._id === selectedReportAsset?._id
          //       ? theme?.palette?.mode === 'dark'
          //         ? '1px solid rgba(255,255,255,1)'
          //         : 'red'
          //       : '1px solid rgba(255,255,255,0)'
        }}
      >
        <div
          style={{
            // position: 'absolute',
            // top: '1px',
            // right: '1px',
            // padding: '1px 7px',
            // background: '#a7268344',
            borderRadius: '0px 5px 0px 5px',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          UPLOAD ASSETS +
        </div>
      </div>
      {/* <img src={`${reportAsset.thumbnailImageDataUri}`} /> */}
      {/* {reportAsset?.name} */}
    </Button>
  );
}

export default UploadAssetsButton;
