import React, { useState, useEffect, useContext } from 'react';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import {
  documentNewsFeedQuery,
  commentAddedSubscription
} from './../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';
import { DataContext } from '../../../../context/DataContext';
import { DialogContext } from '../../../../context/DialogContext';

import NewsFeed from './NewsFeed';

function NewsFeedDialogContent({ includeHistory, limit }) {
  const { currentManagerAccountId } = useContext(AccountContext);
  const { listOfData } = useContext(DataContext);
  const { itemId } = useContext(DialogContext);

  const item = listOfData.filter((obj) => obj._id === itemId)[0];

  // console.log(
  //   'NewsFeedDialogContent>currentManagerAccountId: ',
  //   currentManagerAccountId
  // );
  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      includeHistory: includeHistory,
      // limit,
      docs: [
        {
          accountId: item?.accountId,
          documentId: item?._id
        }
      ]
    }
  };

  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(documentNewsFeedQuery, {
    variables,

    fetchPolicy: 'no-cache',

    onCompleted: () => {
      console.log('RoiTable>Query completed.');
    },
    onError: (error) => {
      console.log('error: ', error);
    }
  });
  console.log('NewsFeedDialogContent>data: ', data);

  const [rerender, setRerender] = useState(0);

  useSubscription(commentAddedSubscription, {
    variables: {
      input: { screen: 'COMMENTDIALOG', accountIds: [item?.accountId] }
    },
    // skip: subscriptionSkip,
    onSubscriptionData: async ({ client, subscriptionData }) => {
      let scrollToBot = false;

      // if (
      //   messagesEndRef.current.scrollTop >
      //   messagesEndRef?.current?.scrollHeight - 1000
      // ) {
      //   scrollToBot = true;
      // }
      // console.log('subscriptionData: ', subscriptionData);
      let newComments;
      const copyOfData = [...data.documentNewsFeedItems];
      // const copyOfComments = [...data.comments]

      const commentObj = subscriptionData.data.commentAdded.comment;

      //   console.log(
      //     'messagesEndRef.current.scrollTop: ',
      //     messagesEndRef.current.scrollTop
      //   );
      //   console.log(
      //     'messagesEndRef?.current?.scrollHeight: ',
      //     messagesEndRef?.current?.scrollHeight
      //   );
      //   console.log('commentAddedSubscription>commentObj: ', commentObj);

      // const rowInRawArrayOfData = rawArrayOfData.filter(
      //   (rawRow) => rawRow._id === commentObj.documentId
      // );

      // if (rowInRawArrayOfData && rowInRawArrayOfData[0]) {
      //   commentObj.path = rowInRawArrayOfData[0].path;
      //   commentObj.interactivePath = rowInRawArrayOfData[0].interactivePath;
      // }

      switch (subscriptionData.data.commentAdded.action) {
        case 'add_comment':

        case 'add_history_event':
          newComments = [
            ...data.documentNewsFeedItems,
            commentObj
            // subscriptionData.data.commentAdded.comment
          ];
          break;

        case 'add_child_comment':
          const parentCommentIndex = data.documentNewsFeedItems.findIndex(
            (comment) => {
              return (
                // comment._id === subscriptionData.data.commentAdded.comment._id
                comment._id === commentObj.parentDocumentId
              );
            }
          );

          const copyOfObject = {
            ...data.documentNewsFeedItems[parentCommentIndex],
            childComments:
              data.documentNewsFeedItems[parentCommentIndex]?.childComments ||
              []
          };
          copyOfObject?.childComments?.push(commentObj);

          // console.log('parentCommentIndex: ', parentCommentIndex);

          copyOfData.splice(
            parentCommentIndex,
            1,
            copyOfObject
            // subscriptionData.data.commentAdded.comment
          );

          newComments = copyOfData;

          // console.log('newComments: ', newComments);
          break;

        case 'edit_comment':
          // case 'reaction':
          const commentIndex = data.documentNewsFeedItems.findIndex(
            (comment) => {
              return (
                // comment._id === subscriptionData.data.commentAdded.comment._id
                comment._id === commentObj._id
              );
            }
          );

          copyOfData.splice(
            commentIndex,
            1,
            commentObj
            // subscriptionData.data.commentAdded.comment
          );

          newComments = copyOfData;

          break;

        case 'reaction':
          // console.log('commentObj: ', commentObj);
          const reactionIndex = data.documentNewsFeedItems.findIndex(
            (comment) => {
              return (
                // comment._id === subscriptionData.data.commentAdded.comment._id
                comment._id === commentObj._id
              );
            }
          );

          copyOfData.splice(
            reactionIndex,
            1,
            {
              ...data.documentNewsFeedItems[reactionIndex],
              reactions: commentObj.reactions
            }
            // subscriptionData.data.commentAdded.comment
          );

          newComments = copyOfData;

          break;
      }

      data.documentNewsFeedItems = newComments;

      setRerender(rerender + 1);
      // if (scrollToBot) {
      //   scrollToBottom();
      // }
    }
  });

  useEffect(() => {
    if (currentManagerAccountId) {
      console.log('running get data 65555');
      const { data, loading } = getData();

      // console.log('running get data>data: ', data);
    }
  }, []);
  return (
    <div style={{ padding: '0px 40px', maxWidth: '900px' }}>
      <NewsFeed
        loading={loading}
        newsFeedItems={data?.documentNewsFeedItems}
        item={item}
      />
    </div>
  );
}

export default NewsFeedDialogContent;
