import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { Button } from '@mui/material';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { useSnackbar } from 'notistack';

import { addMicrosoftInvestTokenMutation } from '../../../../../../../graphql/queries';

import { AccountContext } from '../../../../../../../context/AccountContext';

export default function MicrosoftInvest({
  microsoftInvestUsers,
  setMicrosoftInvestUsers,
  setShowScreen
}) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const [microsoftInvestUserId, setMicrosoftInvestUserId] = useState();

  const { currentManagerAccount } = useContext(AccountContext);

  const [addMicrosoftInvestToken, { called }] = useMutation(
    addMicrosoftInvestTokenMutation
  );

  const onSubmit = async (e, microsoftInvestUserId) => {
    console.log('debug9954>onsubmit');
    console.log('microsoftInvestUserId: ', microsoftInvestUserId);
    if (!microsoftInvestUserId) {
      return;
    }
    const input = {
      managerAccountId: currentManagerAccount._id,
      microsoftInvestUserId
    };
    console.log('debug9954>AddToken>input: ', input);
    console.log('debug9954>row34');

    let res;
    try {
      console.log('debug9954>row 37');
      res = await addMicrosoftInvestToken({ variables: { input } });
      console.log('debug9954>res: ', res);
    } catch (error) {
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
    console.log('res?.data: ', res?.data);
    if (res?.data?.addMicrosoftInvestToken?.response === 'success') {
      enqueueSnackbar('Success', {
        variant: 'success'
      });
      setMicrosoftInvestUsers([]);
      setShowScreen('token_list_screen');
    }
  };

  // if (!open) {
  //   return (
  //     <Button
  //       style={{
  //         // padding: '10px',
  //         padding: '5px 10px',
  //         margin: '10px 0px',
  //         cursor: 'pointer',
  //         background: 'rgba(0, 181, 8, 0.9)',
  //         color: 'white'
  //       }}
  //       onClick={() => setOpen(true)}
  //     >
  //       + Add new token
  //     </Button>
  //   );
  // }
  return (
    <div style={{ marginTop: '100px' }}>
      <div style={{ marginBottom: '20px' }}>SELECT USER ID</div>
      <div
        style={{
          padding: '20px',
          border: '1px solid rgba(150,150,150,0.3)',
          borderRadius: '5px',
          height: '100%'
        }}
      >
        {microsoftInvestUsers.map((microsoftInvestUser, index) => (
          <Button
            key={microsoftInvestUser.id}
            variant="contained"
            onClick={(e) => onSubmit(e, microsoftInvestUser.id)}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start'
              }}
            >
              <div>
                {microsoftInvestUser.username} - {microsoftInvestUser.id}
              </div>
              <div>{microsoftInvestUser.entity_name}</div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
}
