import React from 'react';

import SubTask from './SubTask';

import AddSubTaskButton from './AddTaskButton';

import Checkbox from './Checkbox';

function SubTasks({ currentManagerAccountId, item, rawArrayOfData }) {
  const subTasks = rawArrayOfData.filter(
    (obj) =>
      obj[`level_${item.level}_container_id`] === item._id &&
      obj.documentType === 'subTask'
  );

  return (
    <div
      style={{
        width: '100%',
        //   height: '400px',
        //   padding: '10px',
        padding: '0px 25px 50px 25px'
        //   borderRadius: '5px',
        //   backgroundColor: 'rgba(150, 150, 150, 0.1)'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ padding: '10px 0px', fontWeight: 'bold' }}>Subtasks</div>
        <Checkbox
          currentManagerAccountId={currentManagerAccountId}
          item={item}
        />
      </div>
      <div>
        {subTasks.map((subTask) => (
          <SubTask
            currentManagerAccountId={currentManagerAccountId}
            item={subTask}
          />
        ))}
        <AddSubTaskButton item={item} />
      </div>
    </div>
  );
}

export default SubTasks;
