import React, { useEffect, useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import { dataDashboardQuery } from '../../graphql/queries';

import { AuthContext } from '../../context/AuthContext';
import { AccountContext } from '../../context/AccountContext';
import { ColumnContext } from '../../context/ColumnContext';

import SimpleLineChart from './charts/SimpleLineChart';

import SpinnerSmall from '../layout/SpinnerSmall';

import TotalWidget from './charts/TotalWidget';

import MediaWidget from './charts/MediaWidget';

const getDates = (startDate, endDate) => {
  let dates = [];

  //to avoid modifying the original date
  const theDate = new Date(startDate);
  const theEndDate = new Date(endDate);
  theDate.setHours(5);
  theEndDate.setHours(5);
  while (theDate <= theEndDate) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }

  // if(dates[dates.length-1] !== theEndDate){
  //   dates.push(theEndDate);
  // }

  return dates;
};

function DataDashboard() {
  const { currentAccountIds, currentManagerAccountId } =
    useContext(AccountContext);

  const [showMetricLine, setShowMetricLine] = useState([
    'cost',
    'impressions',
    'cpm',
    'vCpm'
  ]);

  const [media, setMedia] = useState([
    'facebookAds',
    'snapchatAds',
    'tikTokAds'
  ]);

  const allMetrics = ['cost', 'impressions', 'clicks'];

  const [showMediaLine, setShowMediaLine] = useState([]);

  const theme = useTheme();
  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds,
      media: media,
      objective: [],
      selectedDateStart: '2023-01-01',
      selectedDateStop: '2023-04-05'
    }
  };
  const { data, loading } = useQuery(dataDashboardQuery, {
    variables
  });

  const [parsedData, setParsedData] = useState([]);
  const [total, setTotal] = useState({});
  const [showLine, setShowLine] = useState([]);

  useEffect(() => {
    const newShowLine = [];
    for (let metric of showMetricLine) {
      if (showMediaLine.length) {
        for (let med of showMediaLine) {
          newShowLine.push(med + ' ' + metric);
        }
      } else {
        newShowLine.push(metric);
      }
    }
    // if (showMediaLine.length) {
    //   setShowLine();
    // }
    setShowLine(newShowLine);
  }, [showMediaLine, media, showMetricLine]);

  useEffect(() => {
    const dateRange = getDates(
      '2023-01-01',
      new Date().toISOString().split('T')[0]
    );

    const newData = [];
    for (let date of dateRange) {
      //   newDataObject = {};
      let cost = 0;
      let impressions = 0;
      let clicks = 0;
      const dataPerDateObj = {
        date: new Date(date).toISOString().split('T')[0]
      };
      for (let metric of allMetrics) {
        dataPerDateObj[metric] = 0;

        for (let med of media) {
          console.log("med + ' ' + metric: ", med + ' ' + metric);
          dataPerDateObj[med + ' ' + metric] = 0;
        }
      }
      console.log('dataPerDateObj: ', dataPerDateObj);
      for (let dataObj of data?.dashboardData || []) {
        if (
          new Date(dataObj.date).toISOString().split('T')[0] ===
          new Date(date).toISOString().split('T')[0]
        ) {
          dataPerDateObj['cost'] =
            (dataPerDateObj['cost'] || 0) + (dataObj.cost || 0);
          dataPerDateObj['impressions'] =
            (dataPerDateObj['impressions'] || 0) + (dataObj.impressions || 0);
          dataPerDateObj['clicks'] =
            (dataPerDateObj['clicks'] || 0) + (dataObj.clicks || 0);

          for (let med of media) {
            if (dataObj.media === med) {
              dataPerDateObj[med + ' ' + 'cost'] =
                (dataPerDateObj[med + ' ' + 'cost'] || 0) + (dataObj.cost || 0);
              dataPerDateObj[med + ' ' + 'impressions'] =
                (dataPerDateObj[med + ' ' + 'impressions'] || 0) +
                (dataObj.impressions || 0);
              dataPerDateObj[med + ' ' + 'clicks'] =
                (dataPerDateObj[med + ' ' + 'clicks'] || 0) +
                (dataObj.clicks || 0);
            }
          }
        }
      }

      for (let med of media) {
        dataPerDateObj[med + ' ' + 'cost'] =
          Math.round(
            (dataPerDateObj[med + ' ' + 'cost'] + Number.EPSILON) * 100
          ) / 100;
        dataPerDateObj[med + ' ' + 'cpm'] =
          Math.round(
            (dataPerDateObj[med + ' ' + 'cost'] /
              (dataPerDateObj[med + ' ' + 'impressions'] / 1000) +
              Number.EPSILON) *
              100
          ) / 100;
        dataPerDateObj[med + ' ' + 'cpc'] =
          Math.round(
            (dataPerDateObj[med + 'cost'] / dataPerDateObj[med + 'clicks'] +
              Number.EPSILON) *
              100
          ) / 100;
      }

      dataPerDateObj['cost'] =
        Math.round((dataPerDateObj['cost'] + Number.EPSILON) * 100) / 100;
      dataPerDateObj['cpm'] =
        Math.round(
          (dataPerDateObj['cost'] / (dataPerDateObj['impressions'] / 1000) +
            Number.EPSILON) *
            100
        ) / 100;

      dataPerDateObj['cpc'] =
        Math.round(
          (dataPerDateObj['cost'] / dataPerDateObj['clicks'] + Number.EPSILON) *
            100
        ) / 100;

      newData.push(dataPerDateObj);
      // newData.push({
      //   // date: new Date(date).toISOString().split('T')[0],
      //   // cost: Math.round((cost + Number.EPSILON) * 100) / 100,
      //   // impressions,
      //   // cpm:
      //   //   Math.round((cost / (impressions / 1000) + Number.EPSILON) * 100) /
      //   //   100,

      //   // clicks,
      //   cpc: Math.round((cost / clicks + Number.EPSILON) * 100) / 100
      // });
    }

    const aggregatedData = {};
    for (let obj of newData) {
      aggregatedData.cost = (aggregatedData.cost || 0) + (obj.cost || 0);
      aggregatedData.impressions =
        (aggregatedData.impressions || 0) + (obj.impressions || 0);
      aggregatedData.clicks = (aggregatedData.clicks || 0) + (obj.clicks || 0);
    }

    aggregatedData.cpm =
      Math.round(
        (aggregatedData.cost / (aggregatedData.impressions / 1000) +
          Number.EPSILON) *
          100
      ) / 100;

    aggregatedData.cpc =
      Math.round(
        (aggregatedData.cost / aggregatedData.clicks + Number.EPSILON) * 100
      ) / 100;

    aggregatedData.cost =
      Math.round((aggregatedData.cost + Number.EPSILON) * 100) / 100;

    setTotal(aggregatedData);
    setParsedData(newData);

    console.log('dateRange: ', dateRange);
  }, [data]);
  // console.log('parsedData: ', parsedData);

  // console.log('data: ', data);

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <SpinnerSmall />
      </div>
    );
  }
  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background:
          theme.palette.mode === 'dark'
            ? 'rgba(0,0,0,0.5)'
            : 'rgba(255,255,255,0.90)',
        // margin: '50px',
        paddingTop: '50px',
        // borderRadius: '15px',
        overflowY: 'scroll'
      }}
    >
      <div>DataDashboard</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly'
        }}
      >
        <MediaWidget
          itemKey={'facebookAds'}
          showMetricLine={showMediaLine}
          setShowMetricLine={setShowMediaLine}
        />
        <MediaWidget
          itemKey={'snapchatAds'}
          showMetricLine={showMediaLine}
          setShowMetricLine={setShowMediaLine}
        />
        <MediaWidget
          itemKey={'tikTokAds'}
          showMetricLine={showMediaLine}
          setShowMetricLine={setShowMediaLine}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly'
        }}
      >
        {/* <div
          style={{
            padding: '30px',
            background: 'rgba(150,150,150,0.1)',
            borderRadius: '15px',
            margin: '10px'
          }}
        >
          <div
            style={{ fontWeight: 'bold', fontSize: '12px', minWidth: '80px' }}
          >
            Cost:
          </div>
          <div>{total.cost.toLocaleString()}</div>
        </div> */}
        <TotalWidget
          title={'Cost'}
          itemKey={'cost'}
          itemValue={total.cost}
          showMetricLine={showMetricLine}
          setShowMetricLine={setShowMetricLine}
        />
        <TotalWidget
          title={'Impressions'}
          itemKey={'impressions'}
          itemValue={total.impressions}
          showMetricLine={showMetricLine}
          setShowMetricLine={setShowMetricLine}
        />
        <TotalWidget
          title={'CPM'}
          itemKey={'cpm'}
          itemValue={total.cpm}
          showMetricLine={showMetricLine}
          setShowMetricLine={setShowMetricLine}
        />
        <TotalWidget
          title={'Clicks'}
          itemKey={'clicks'}
          itemValue={total.clicks}
          showMetricLine={showMetricLine}
          setShowMetricLine={setShowMetricLine}
        />
        <TotalWidget
          title={'CPC'}
          itemKey={'cpc'}
          itemValue={total.cpc}
          showMetricLine={showMetricLine}
          setShowMetricLine={setShowMetricLine}
        />
        {/* <div
          style={{
            padding: '30px',
            background: 'rgba(150,150,150,0.1)',
            borderRadius: '15px',
            margin: '10px'
          }}
        >
          <div
            style={{ fontWeight: 'bold', fontSize: '12px', minWidth: '80px' }}
          >
            Impressions:
          </div>
          <div>{total.impressions.toLocaleString()}</div>
        </div> */}
        {/* <div
          style={{
            padding: '30px',
            background: 'rgba(150,150,150,0.1)',
            borderRadius: '15px',
            margin: '10px'
          }}
        >
          <div
            style={{ fontWeight: 'bold', fontSize: '12px', minWidth: '80px' }}
          >
            CPM:
          </div>
          <div>{total.cpm.toLocaleString()}</div>
        </div> */}
        {/* <div
          style={{
            padding: '30px',
            background: 'rgba(150,150,150,0.1)',
            borderRadius: '15px',
            margin: '10px'
          }}
        >
          <div
            style={{ fontWeight: 'bold', fontSize: '12px', minWidth: '80px' }}
          >
            Clicks:
          </div>
          <div>{total.clicks.toLocaleString()}</div>
        </div>
        <div
          style={{
            padding: '30px',
            background: 'rgba(150,150,150,0.1)',
            borderRadius: '15px',
            margin: '10px'
          }}
        >
          <div
            style={{ fontWeight: 'bold', fontSize: '12px', minWidth: '80px' }}
          >
            CPC:
          </div>
          <div>{total.cpc.toLocaleString()}</div>
        </div> */}
      </div>
      <div style={{ width: '80%', height: '600px' }}>
        <SimpleLineChart data={parsedData} showMetricLine={showLine} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '50px' }}>
        <div
          style={{
            width: '200px',
            height: '150px',
            borderRadius: '15px',
            margin: '20px',
            padding: '20px',
            background:
              theme.palette.mode === 'dark'
                ? 'rgba(150,150,150,0.1)'
                : 'rgba(150,150,150,0.1)'
          }}
        >
          {/* hej */}
        </div>
        <div
          style={{
            width: '200px',
            height: '150px',
            borderRadius: '15px',
            margin: '20px',
            padding: '20px',
            background:
              theme.palette.mode === 'dark'
                ? 'rgba(150,150,150,0.1)'
                : 'rgba(150,150,150,0.1)'
          }}
        >
          {/* hej */}
        </div>
        <div
          style={{
            width: '200px',
            height: '150px',
            borderRadius: '15px',
            margin: '20px',
            padding: '20px',
            background:
              theme.palette.mode === 'dark'
                ? 'rgba(150,150,150,0.1)'
                : 'rgba(150,150,150,0.1)'
          }}
        >
          {/* hej */}
        </div>
        <div
          style={{
            width: '200px',
            height: '150px',
            borderRadius: '15px',
            margin: '20px',
            padding: '20px',
            background:
              theme.palette.mode === 'dark'
                ? 'rgba(150,150,150,0.1)'
                : 'rgba(150,150,150,0.1)'
          }}
        >
          {/* hej */}
        </div>
      </div>
    </div>
  );
}

export default DataDashboard;
