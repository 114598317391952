import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useMutation } from '@apollo/client';

import { updateDocumentMutation } from '../../../../graphql/queries';

import { MutationContext } from '../../../../context/MutationContext';

import { useSnackbar } from 'notistack';

import Tooltip from '@mui/material/Tooltip';
import { AccountContext } from '../../../../context/AccountContext';

import WarningIcon from '@mui/icons-material/Warning';

export default function BasicMenu({ item, showText, iconStyle }) {
  const { updateDocument } = useContext(MutationContext);

  const { nexusDocumentInvoiceStatuses, currentManagerAccountId } =
    useContext(AccountContext);

  const [invoiceStatus, setInvoiceStatus] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const [updateDocument] = useMutation(updateDocumentMutation);

  const handleClose = async (e, value) => {
    // if (['backdropClick', 'escapeKeyDown'].includes(value)) {
    //   setAnchorEl(null);
    //   return;
    // }

    if (
      !nexusDocumentInvoiceStatuses
        .map((status) => status.string)
        .includes(value)
    ) {
      setAnchorEl(null);
      return;
    }

    if (value === item.invoiceStatus) {
      console.log('same invoice status');
      setAnchorEl(null);
      return;
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          invoiceStatus: value
        }
      ],
      updateKeys: ['invoiceStatus']
    };

    // updateDocument({ variables: { input: { ...documentInput } } });

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });
      console.log('res: ', res);
      console.log(
        'res?.data?.updateDocument?.response: ',
        res?.data?.updateDocument?.response
      );
      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
      setAnchorEl(null);
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }

    // setAnchorEl(null);
  };

  let fiberManualRecordIconColor = invoiceStatus?.color;
  let statusName = item.invoiceStatus;

  useEffect(() => {
    switch (item.status) {
      // case 'ACTIVE':
      //   // case 'DONE':
      //   fiberManualRecordIconColor = 'green';
      //   break;
      // case 'DONE':
      // case 'COMPLETED':
      //   fiberManualRecordIconColor = 'rgba(0,128,0,0.4)';
      //   break;
      // case 'REMOVED':
      //   fiberManualRecordIconColor = 'red';
      //   break;

      // case 'DRAFT':
      //   fiberManualRecordIconColor = '#bcbcbc';
      //   break;
      // case 'PAUSED':
      //   fiberManualRecordIconColor = 'rgb(62, 142, 208)';
      //   break;
      default:
        // fiberManualRecordIconColor = 'orange';
        const status_ = nexusDocumentInvoiceStatuses.filter(
          (status) => status.string === item.invoiceStatus
        )[0];

        setInvoiceStatus(status_);
        // if (status_) {
        //   fiberManualRecordIconColor = status.color;
        //   // statusName = status.name;
        //   setStatusName(status.name);
        // }

        break;
    }
  }, [item.invoiceStatus, nexusDocumentInvoiceStatuses]);

  const menuItemStyle = { fontSize: '12px' };

  return (
    <div>
      {showText ? (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            minHeight: '24.5px',
            cursor: 'pointer',
            width: '100%',
            textAlign: 'right',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: fiberManualRecordIconColor,
            color: fiberManualRecordIconColor,
            padding: '3px 6px',
            // borderRadius: '15px',
            borderRadius: '5px',
            minWidth: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          {/* <FiberManualRecordIcon
            style={{
              fontSize: '10px',
              verticalAlign: 'middle',
              color: fiberManualRecordIconColor
            }}
          /> */}
          <span
            style={{ fontSize: '10px', marginLeft: '3px', marginRight: '3px' }}
          >
            {invoiceStatus?.name || item.invoiceStatus || ''}
          </span>
          {item.showInvoiceButtonWarning && (
            <span>
              <WarningIcon
                style={{ color: 'red', fontSize: '12px', marginLeft: '3px' }}
              />
            </span>
          )}
        </Button>
      ) : (
        // <Tooltip
        //   title={
        //     <span style={{ fontSize: '13px' }}>
        //       {item['status']
        //         ? item['status'].charAt(0).toUpperCase() +
        //           item['status'].slice(1).toLowerCase()
        //         : ' '}
        //     </span>
        //   }
        // >
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            // minHeight: '17.5px',
            cursor: 'pointer',
            width: '18px',
            minWidth: '18px',
            textAlign: 'right',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: fiberManualRecordIconColor,
            color: fiberManualRecordIconColor,
            padding: '3px 6px',
            // borderRadius: '15px',
            borderRadius: '5px',
            // minWidth: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            ...(iconStyle || {})
          }}
        >
          <FiberManualRecordIcon
            style={{
              fontSize: '10px',
              verticalAlign: 'middle',
              color: fiberManualRecordIconColor
            }}
          />
          {/* <span
            style={{ fontSize: '10px', marginLeft: '3px', marginRight: '3px' }}
          >
            {item['status']
              ? item['status'].charAt(0).toUpperCase() +
                item['status'].slice(1).toLowerCase()
              : ' '}
          </span> */}
        </Button>
        // </Tooltip>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {[
          // {
          //   status: 'ACTIVE',
          //   number: 1,
          //   name: 'UNDEFINED_',
          //   string: 'UNDEFINED',
          //   color: '#bcbcbc',
          //   savedTo: 'managerAccount'
          // },
          ...nexusDocumentInvoiceStatuses
        ].map((status) => (
          <MenuItem
            style={menuItemStyle}
            onClick={(e) => handleClose(e, status.string)}
          >
            {status.name}
          </MenuItem>
        ))}
        {/* <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'DRAFT')}
        >
          Draft
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'PLANNED')}
        >
          Planned
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'READY')}
        >
          Ready
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'ACTIVE')}
        >
          Active
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'PAUSED')}
        >
          Paused
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'CANCELED')}
        >
          Canceled
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'COMPLETED')}
        >
          Completed
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'REMOVED')}
        >
          Removed
        </MenuItem> */}
      </Menu>
    </div>
  );
}
