import React, { useState } from 'react';
import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UserList from './userlist/UserList';

import User from './user/User';

function Users({ currentManagerAccount, user }) {
  const [selectedUser, setSelectedUser] = useState(
    user && user._id ? user._id : ''
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '300px auto',
        gridColumnStart: 2,
        gridColumnEnd: 2
      }}
    >
      <UserList
        currentManagerAccount={currentManagerAccount}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <User
        currentManagerAccount={currentManagerAccount}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </div>
  );
}

export default Users;
