import React, { useState } from 'react';

import ArrayFilter from './ArrayFilter';
import StringFilter from './StringFilter';
import StringArrayFilter from './StringArrayFilter';

import { Button } from '@mui/material';

import AddFilterMenu from './AddFilterMenu';

import DeleteIcon from '@mui/icons-material/Delete';

import RemoveFilterButton from './RemoveFilterButton';

import SelectOperator from './SelectOperator';
import SelectConjunction from './SelectConjunction';

import IsCaseSensitiveCheckbox from './IsCaseSensitiveCheckbox';

function SetFiltersComponent({ documentType, sFilters, setSFilters }) {
  //   let filters = [];

  // const [filters, setSFilters] = useState([]);
  // console.log('sFilters: ', sFilters);

  const addFilterHandler = (field) => {
    switch (field) {
      case 'campaignAdvertisingChannelType':
      case 'campaignAdvertisingChannelSubType':
      case 'campaignType':
      case 'subType':
      case 'campaignName':
        setSFilters([
          ...sFilters,
          {
            type: 'ARRAY',
            field, //'campaignAdvertisingChannelType',
            operator: 'CONTAINS',
            conjunction: 'ANY',
            values: [],
            isCaseSensitive: false
            // string: ''
          }
        ]);

        break;

        // setSFilters([
        //   ...sFilters,
        //   {
        //     type: 'ARRAY',
        //     field: 'campaignAdvertisingChannelSubType',
        //     operator: 'IN',
        //     array: [],
        //     string: ''
        //   }
        // ]);

        break;

      // case 'campaignName':
      //   setSFilters([
      //     ...sFilters,
      //     {
      //       type: 'STRING',
      //       field: 'campaignName',
      //       operator: 'CONTAINS',
      //       array: [],
      //       string: '',
      //       isCaseSensitive: false
      //     }
      //   ]);
      //   break;
    }
  };

  //   switch (true) {
  //     case documentType === 'googleAds':
  //       sFilters = [
  //         {
  //           type: 'ARRAY',
  //           field: 'campaignAdvertisingChannelType',
  //           options: [
  //             'DISCOVERY',
  //             'DISPLAY',
  //             'HOTEL',
  //             'LOCAL',
  //             'LOCAL_SERVICES',
  //             'MULTI_CHANNEL',
  //             'PERFORMANCE_MAX',
  //             'SEARCH',
  //             'SHOPPING',
  //             'SMART',
  //             'TRAVEL',
  //             'UNKNOWN',
  //             'UNSPECIFIED',
  //             'VIDEO'
  //           ]
  //         }
  //       ];
  //   }
  console.log('sFilters: ', sFilters);
  return (
    <div
      style={{
        // marginTop: '10px', marginLeft: '40px',
        width: '700px'
      }}
    >
      {Boolean(sFilters.length) && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span
            style={{
              margin: '10px 5px'
              // fontWeight: 'bold'
            }}
          >
            Filters:{' '}
          </span>
        </div>
      )}
      <div style={{}}>
        {sFilters?.map((filter, index) => {
          const updateSFilter = (newFilter) => {
            const copyOfFilter = { ...filter, ...newFilter };

            const copyOfSFilters = [...sFilters];
            copyOfSFilters.splice(index, 1, copyOfFilter);

            setSFilters(copyOfSFilters);
          };

          switch (true) {
            case filter.type === 'ARRAY' && filter.field === 'campaignName':
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                    margin: '15px 0px'
                  }}
                >
                  <div style={{ marginLeft: '5px' }}>
                    <SelectOperator
                      filter={filter}
                      updateSFilter={updateSFilter}
                      documentType={documentType}
                    />
                  </div>
                  <div style={{ marginLeft: '5px' }}>
                    <SelectConjunction
                      filter={filter}
                      updateSFilter={updateSFilter}
                      documentType={documentType}
                    />
                  </div>
                  <div style={{ marginLeft: '5px' }}>
                    <StringArrayFilter
                      filter={filter}
                      updateSFilter={updateSFilter}
                      documentType={documentType}
                    />
                  </div>
                  <div style={{ marginLeft: '5px' }}>
                    <RemoveFilterButton
                      sFilters={sFilters}
                      setSFilters={setSFilters}
                      index={index}
                    />
                  </div>
                </div>
              );
            case filter.type === 'ARRAY':
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <div style={{ marginLeft: '5px' }}>
                    <SelectOperator
                      filter={filter}
                      updateSFilter={updateSFilter}
                      documentType={documentType}
                    />
                  </div>
                  <div style={{ marginLeft: '5px' }}>
                    <SelectConjunction
                      filter={filter}
                      updateSFilter={updateSFilter}
                      documentType={documentType}
                    />
                  </div>
                  <div style={{ marginLeft: '5px' }}>
                    <ArrayFilter
                      filter={filter}
                      updateSFilter={updateSFilter}
                      documentType={documentType}
                    />
                  </div>
                  <div style={{ marginLeft: '5px' }}>
                    <RemoveFilterButton
                      sFilters={sFilters}
                      setSFilters={setSFilters}
                      index={index}
                    />
                  </div>
                </div>
              );
            // case filter.type === 'STRING':
            //   return (
            //     <div>
            //       <div
            //         style={{
            //           display: 'flex',
            //           flexDirection: 'row',
            //           alignItems: 'center'
            //         }}
            //       >
            //         <SelectOperator
            //           filter={filter}
            //           updateSFilter={updateSFilter}
            //           documentType={documentType}
            //         />
            //         <StringFilter
            //           filter={filter}
            //           updateSFilter={updateSFilter}
            //           documentType={documentType}
            //         />
            //         <RemoveFilterButton
            //           sFilters={sFilters}
            //           setSFilters={setSFilters}
            //           index={index}
            //         />
            //       </div>
            //       <div
            //         style={{
            //           display: 'flex',
            //           flexDirection: 'row',
            //           alignItems: 'center'
            //           // position: 'absolute'
            //         }}
            //       >
            //         <div style={{ width: '125px' }}></div>
            //         <IsCaseSensitiveCheckbox
            //           filter={filter}
            //           updateSFilter={updateSFilter}
            //           documentType={documentType}
            //         />
            //       </div>
            //     </div>
            //   );
          }
        })}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <AddFilterMenu
          documentType={documentType}
          addFilterHandler={addFilterHandler}
        />
      </div>
    </div>
  );
}

export default SetFiltersComponent;
