import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

const parse_google_ads_campaign_data = (
  googleadscampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = googleadscampaign?.action01?.dataSource;
  const action02DataSource = googleadscampaign?.action02?.dataSource;
  const action03DataSource = googleadscampaign?.action03?.dataSource;

  const action01DataSourceIsGoogleAds = action01DataSource === 'googleAds';
  const action02DataSourceIsGoogleAds = action02DataSource === 'googleAds';
  const action03DataSourceIsGoogleAds = action03DataSource === 'googleAds';

  const action01Type = googleadscampaign?.action01?.googleAds?.type;

  const action02Type = googleadscampaign?.action02?.googleAds?.type;

  const action03Type = googleadscampaign?.action03?.googleAds?.type;

  const action01Name = googleadscampaign?.action01?.name;

  const action02Name = googleadscampaign?.action02?.name;

  const action03Name = googleadscampaign?.action03?.name;

  const campaign = {
    ...googleadscampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    documentType: 'googleAds',
    type: 'google_ads_campaign'
  };

  const runCostFormula = campaign.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (
    googleadscampaign &&
    googleadscampaign.googleadsadgroups &&
    googleadscampaign.googleadsadgroups.length > 0
  ) {
    // campaign.name = googleadscampaign.googleadsadgroups[0].campaign.name;

    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adgroup_object of googleadscampaign.googleadsadgroups) {
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      let outbound_clicks = null;

      if (action01DataSourceIsGoogleAds) {
        switch (action01Type) {
          case 'conversions':
            action01Count =
              action01Count + (adgroup_object.metrics.conversions || null);
            action01Value =
              action01Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (
              googleadscampaign &&
              googleadscampaign.googleadsadgroupconversions
            ) {
              for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                if (
                  adgroup_conversion_object?.segments?.conversionAction ===
                    action01Type &&
                  adgroup_object.campaign.id ===
                    adgroup_conversion_object.campaign.id &&
                  adgroup_conversion_object.segments.date ===
                    adgroup_object.segments.date
                ) {
                  action01Count =
                    action01Count +
                    (adgroup_conversion_object.metrics.allConversions || null);
                  action01Value =
                    action01Value +
                    (adgroup_conversion_object.metrics.allConversionsValue ||
                      null);
                }
              }
            }
        }
      }

      if (action02DataSourceIsGoogleAds) {
        switch (action02Type) {
          case 'conversions':
            action02Count =
              action02Count + (adgroup_object.metrics.conversions || null);
            action02Value =
              action02Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (
              googleadscampaign &&
              googleadscampaign.googleadsadgroupconversions
            ) {
              for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                if (
                  adgroup_conversion_object?.segments?.conversionAction ===
                    action02Type &&
                  adgroup_object.campaign.id ===
                    adgroup_conversion_object.campaign.id &&
                  adgroup_conversion_object.segments.date ===
                    adgroup_object.segments.date
                ) {
                  action02Count =
                    action02Count +
                    (adgroup_conversion_object.metrics.allConversions || null);
                  action02Value =
                    action02Value +
                    (adgroup_conversion_object.metrics.allConversionsValue ||
                      null);
                }
              }
            }
        }
      }

      if (action03DataSourceIsGoogleAds) {
        switch (action03Type) {
          case 'conversions':
            action03Count =
              action03Count + (adgroup_object.metrics.conversions || null);
            action03Value =
              action03Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (
              googleadscampaign &&
              googleadscampaign.googleadsadgroupconversions
            ) {
              for (let adgroup_conversion_object of googleadscampaign.googleadsadgroupconversions) {
                if (
                  adgroup_conversion_object?.segments?.conversionAction ===
                    action03Type &&
                  adgroup_object.campaign.id ===
                    adgroup_conversion_object.campaign.id &&
                  adgroup_conversion_object.segments.date ===
                    adgroup_object.segments.date
                ) {
                  action03Count =
                    action03Count +
                    (adgroup_conversion_object.metrics.allConversions || null);
                  action03Value =
                    action03Value +
                    (adgroup_conversion_object.metrics.allConversionsValue ||
                      null);
                }
              }
            }
        }
      }

      // const costScope = {
      //   cost: parseFloat(adgroup_object.metrics.costMicros) / 1000000 || 0
      // };

      // const cost = runCostFormula
      //   ? evaluateFormula(campaign.costFormula, costScope)
      //   : parseFloat(adgroup_object.metrics.costMicros) / 1000000 || null;

      const formulaFields = [
        // 'plannedGross',
        // 'plannedNet',
        // 'plannedNetNet',
        // 'plannedCtc',

        // 'plannedTrp',
        // 'plannedTechFee',
        // 'plannedAdOpsFee',
        // 'plannedAdServingCost',
        // 'plannedFeePercent',

        // 'budget',
        // 'kpi',
        // 'plannedImpressions',
        // 'plannedClicks',
        // 'plannedCtr',
        // 'plannedCpc',
        // 'plannedReach',
        // 'plannedCpm',
        // 'plannedFrequency',
        // 'plannedActionCount',
        // 'plannedActionValue',
        // 'plannedActionRoas',
        // 'plannedCpa',
        'reach',
        'cost',
        'impressions',
        'clicks',
        'action01Count',
        'action02Count',
        'action03Count',
        'action01Value',
        'action02Value',
        'action03Value'
      ];

      // const generateScope = (formulaField, calcObject) => {
      //   switch (formulaField) {
      //     case 'cost':
      //       return {
      //         cost: parseFloat(calcObject.metrics.costMicros) / 1000000 || 0
      //       };

      //     case 'impressions':
      //       return {
      //         impressions: parseInt(adgroup_object.metrics.impressions) || 0
      //       };
      //     case 'clicks':
      //       return {
      //         clicks: parseInt(adgroup_object.metrics.clicks) || 0
      //       };
      //     case 'action01Count':
      //     case 'action02Count':
      //     case 'action03Count':
      //     case 'action01Value':
      //     case 'action02Value':
      //     case 'action03Value':
      //       return {
      //         [formulaField]: calcObject[formulaField] || 0
      //       };

      //     default:
      //       return {};
      //   }
      // };

      // const calculatedFields = {};

      // // for (let formulaField of formulaFields) {
      // //   calculatedFields[formulaField] = evaluateFormula(
      // //     campaign[formulaField + 'Formula'],
      // //     generateScope(formulaField, adgroup_object)
      // //   );
      // // }

      // for (let formulaField of formulaFields) {
      //   calculatedFields[formulaField] = evaluateFormula(
      //     '=' + formulaField,
      //     generateScope(formulaField, adgroup_object)
      //   );
      // }

      listOfPropagationData.push({
        ...adgroup_object,
        // ...calculatedFields,
        date_start: adgroup_object.segments.date,
        date_stop: adgroup_object.segments.date,
        cost: parseFloat(adgroup_object.metrics.costMicros) / 1000000 || 0,
        impressions: parseInt(adgroup_object.metrics.impressions) || null,
        eligibleImpressions:
          parseInt(adgroup_object?.metrics?.impressions) /
          parseFloat(adgroup_object?.metrics?.searchImpressionShare || null),
        clicks: parseInt(adgroup_object.metrics.clicks) || null,
        action01Type,
        action01Count,
        action01Value,

        action02Type,
        action02Count,
        action02Value,

        action03Type,
        action03Count,
        action03Value,
        // outbound_clicks,
        // clicks: parseInt(adgroup_object.metrics.clicks),
        accountId: campaignBaseValues.accountId
      });
    }
  }

  campaign.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaign
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_google_ads_campaign_data;
