import React, { useState, useEffect } from 'react';
import TextWidget from './widgets/TextWidget';

function TaxonomyItem({
  displayMode,
  row,
  rowIndex,
  deleted,
  setDeleted,
  itemStyle,
  checked,
  rowNumber
}) {
  const [taxonomyRowObj, setTaxonomyRowObj] = useState({});
  useState(() => {
    setTaxonomyRowObj({ ...row });
  }, [row]);
  //   const [widgets, setWidgets] = useState(row.widgets || []);

  const setWidgets = (widgets) => {
    setTaxonomyRowObj({ ...taxonomyRowObj, widgets });
  };

  const widgets = taxonomyRowObj.widgets || [];

  const updateWidget = (widget, index) => {
    const copyOfWidgets = [...widgets];
    copyOfWidgets.splice(index, 1, widget);
    setWidgets(copyOfWidgets);
  };

  useEffect(() => {
    if (!deleted) {
      return;
    }

    if (deleted.originRowIndex === rowIndex) {
      if (deleted.origin === 'taxonomy_row') {
        let copyOfWidgets = [...widgets];

        copyOfWidgets.splice(deleted.originIndex, 1);

        setWidgets(copyOfWidgets);
      }
    }
    setDeleted(null);
  }, [deleted]);

  const dragStart = (e, value, origin, originIndex) => {
    e.stopPropagation();
    console.log('origin: ', origin);
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({ value, origin, originIndex, originRowIndex: rowIndex })
    );
    console.log('{ value, origin, originIndex }: ', {
      value,
      origin,
      originIndex
    });
    // console.log(
    //   'JSON.stringify({ value, origin, originIndex }): ',
    //   JSON.stringify({ value, origin, originIndex })
    // );
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dropRow = (e, value) => {
    console.log('value: ', value);
    e.preventDefault();
    e.stopPropagation();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const droppedItem = JSON.parse(droppedItemString);
    // console.log('debug>droppedItem: ', droppedItem);
    // console.log(
    //   'debug>droppedItem.originRowIndex:',
    //   droppedItem.originRowIndex
    // );
    // console.log('debug>rowIndex: ', rowIndex);
    // console.log(
    //   'debug>droppedItem.originRowIndex !== 0: ',
    //   droppedItem.originRowIndex !== 0
    // );
    if (
      (droppedItem.originRowIndex || droppedItem.originRowIndex === 0) &&
      droppedItem.originRowIndex !== rowIndex
    ) {
      return;
    }
    let copyOfWidgets = [...widgets];

    console.log('droppedItem.origin: ', droppedItem.origin);
    if (droppedItem.origin === 'taxonomy_row') {
      console.log('droppedItem.index: ', droppedItem.originIndex);
      copyOfWidgets.splice(droppedItem.originIndex, 1);

      console.log('copyOfWidgets: ', copyOfWidgets);
    }
    setWidgets([...copyOfWidgets, droppedItem.value]);

    const droppedItemElement = document.getElementById(droppedItem);
  };
  const dropWidget = (e, value, index) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const droppedItem = JSON.parse(droppedItemString);

    if (
      (droppedItem.originRowIndex || droppedItem.originRowIndex === 0) &&
      droppedItem.originRowIndex !== rowIndex
    ) {
      return;
    }
    let copyOfWidgets = [...widgets];

    if (droppedItem.origin === 'taxonomy_row') {
      console.log('droppedItem.index: ', droppedItem.originIndex);
      copyOfWidgets.splice(droppedItem.originIndex, 1);

      console.log('copyOfWidgets: ', copyOfWidgets);
    }
    copyOfWidgets.splice(index, 0, droppedItem.value);

    setWidgets(copyOfWidgets);
    console.log('droppedItem1: ', droppedItem);

    const droppedItemElement = document.getElementById(droppedItem);
  };
  return (
    <div
      onDrop={(e) => dropRow(e, row, rowIndex)}
      onDragOver={(e) => dragOver(e, row, rowIndex)}
      // draggable={true}
      // onDragStart={(e) => dragStart(e, row, 'taxonomy_table', rowIndex)}
      // onDragEnd={(e) => dragEnd(e, row, rowIndex)}
      style={{
        // width: '100%',
        // height: '40px',
        // borderRadius: '0 5px 5px 0',
        // background: 'white',

        // fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // paddingLeft: '10px',
        // margin: '0 0.5px'
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        // display: 'grid',
        height: '100%',
        width: '100%',
        minWidth: '58vw',
        padding: '12px 15px',
        whiteSpace: 'nowrap'
      }}
    >
      {widgets.map((widget, index) => (
        <div
          onDrop={(e) => dropWidget(e, widget, index)}
          onDragOver={(e) => dragOver(e, widget, index)}
          draggable={true}
          onDragStart={(e) => dragStart(e, widget, 'taxonomy_row', index)}
          onDragEnd={(e) => dragEnd(e, widget, index)}
          style={{
            padding: '3px',
            color: 'black',
            background: 'lightgrey',
            // border: '1px solid lightgrey',
            display: 'inline-block',
            borderRadius: '5px',
            margin: '0px 0.5px',
            transform: 'translate(0, 0)'
          }}
        >
          <TextWidget
            widget={widget}
            index={index}
            widgets={widgets}
            setWidgets={setWidgets}
            updateWidget={updateWidget}
            displayMode={displayMode}
          />
          {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
        </div>
      ))}
    </div>
  );
}

export default TaxonomyItem;
