import { minHeight } from '@mui/system';
import React, { useEffect, useState } from 'react';

import SelectColumn from './SelectColumn';
import { Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import RemoveButton from './RemoveButton';

function DataEditor({ excelData, setExcelData }) {
  const [sheetName, setSheetName] = useState('');

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (!columns.length && excelData && excelData[sheetName]) {
      const cols = excelData[sheetName][0]?.map((cell) => null);
      setColumns(cols);
    }
  }, [excelData, sheetName]);

  const onRemoveColumn = (index) => {
    console.log('onRemoveColumn');
    const copyOfExcelData = { ...excelData };
    const copyOfSheet = [...excelData[sheetName]];
    for (let row of copyOfSheet) {
      row.splice(index, 1);
    }
    const copyOfColumns = [...columns];
    copyOfColumns.splice(index, 1);
    setColumns(copyOfColumns);
    copyOfExcelData[sheetName] = copyOfSheet;
    setExcelData(copyOfExcelData);
  };

  const onRemoveRow = (index) => {
    const copyOfExcelData = { ...excelData };
    const copyOfSheet = [...excelData[sheetName]];

    copyOfSheet.splice(index, 1);
    copyOfExcelData[sheetName] = copyOfSheet;
    setExcelData(copyOfExcelData);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '400px',
        minHeight: '400px'
      }}
    >
      <div>
        {Object.keys(excelData).map((sheetName) => (
          <span
            style={{ cursor: 'pointer', padding: '5px' }}
            onClick={() => setSheetName(sheetName)}
          >
            <Button>{sheetName}</Button>
          </span>
        ))}
      </div>
      <div style={{ fontSize: '10px' }}>
        {excelData[sheetName] && (
          <table
            style={
              {
                // border: '1px solid black'
              }
            }
          >
            <tr>
              <td></td>
              {columns.map((col, index) => (
                <td
                  style={{ cursor: 'pointer' }}
                  //   onClick={() => onRemoveColumn(index)}
                >
                  <SelectColumn
                    index={index}
                    column={col}
                    columns={columns}
                    setColumns={setColumns}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td></td>
              {excelData[sheetName][0].map((col, index) => (
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => onRemoveColumn(index)}
                >
                  <RemoveButton />
                  {/* <Button
                    style={{
                      height: '15px',
                      maxHeight: '15px',
                      minHeight: '15px',
                      width: '15px',
                      maxWidth: '15px',
                      minWidth: '15px'
                    }}
                  >
                    <CloseIcon style={{ fontSize: '10px' }} />
                  </Button> */}
                </td>
              ))}
            </tr>
            {excelData[sheetName].map((row, index) => {
              try {
                return (
                  <tr style={{ border: '1px solid #bcbcbc' }}>
                    <td onClick={() => onRemoveRow(index)}>
                      <RemoveButton />
                      {/* <Button
                        style={{
                          height: '15px',
                          maxHeight: '15px',
                          minHeight: '15px',
                          width: '15px',
                          maxWidth: '15px',
                          minWidth: '15px'
                        }}
                      >
                        <CloseIcon style={{ fontSize: '10px' }} />
                      </Button> */}
                    </td>
                    {row.map((cell) => (
                      <td
                        style={{
                          borderTop: '1px solid #bcbcbc',
                          borderRight: '1px solid #bcbcbc',
                          lineBreak: 'strict',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {String(cell)}
                      </td>
                    ))}
                  </tr>
                );
              } catch (error) {}
            })}
          </table>
        )}
      </div>
    </div>
  );
}

export default DataEditor;
