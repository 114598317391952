import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { UpdateAccountToDataSourceSettingsMutation } from '../../../../graphql/queries';

import { SnackbarProvider, useSnackbar } from 'notistack';

import { Button } from '@mui/material';

import Select from './Select';

import AutoComleteCountries from './AutoCompleteCountries';
import AutoComleteBudgetTypes from './AutoCompleteBudgetTypes';

function ScanDocument({
  dataSource,
  managerAccountId,
  accountId,
  checkedDocumentId,
  newDocuments,
  scanResponse,
  countries,
  setCountries,
  budgetTypes,
  setBudgetTypes
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateAccountToDataSourceSettingsMutation] = useMutation(
    UpdateAccountToDataSourceSettingsMutation
  );

  // const [budgetTypes, setBudgetTypes] = useState([]);
  // const [countries, setCountries] = useState([]);
  const [borderColor, setBorderColor] = useState(
    '1px solid rgba(150,150,150,0.3)'
  );

  const onClickSaveAsDefaultHandler = async () => {
    const input = {
      managerAccountId,
      accountId,
      // templateDocumentId: value._id,
      accountToDataSourceRelationId: dataSource.accountToDataSourceRelationId,
      updateKeys: ['defaultScanSettings'],
      defaultScanSettings: {
        budgetTypes,
        countries
      }
    };

    try {
      const res = await updateAccountToDataSourceSettingsMutation({
        variables: { input }
      });

      console.log('res: ', res);
      if (res.data.updateAccountToDataSourceSettings.response === 'success') {
        enqueueSnackbar('Saved', {
          // variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar('Error', {
        variant: 'error'
      });
    }
  };
  // const [scanResponse, setScanResponse] = useState({});

  // const onClickScan = async () => {
  //   if (!newDocuments?.length) {
  //     enqueueSnackbar('Please select data sources to import.', {
  //       variant: 'default'
  //       // persist: true
  //     });
  //     return;
  //   }

  //   if (!budgetTypes?.length) {
  //     enqueueSnackbar('Please select atleast one budget type.', {
  //       variant: 'default'
  //       // persist: true
  //     });
  //     return;
  //   }

  //   if (!countries?.length) {
  //     enqueueSnackbar('Please select atleast one country.', {
  //       variant: 'default'
  //       // persist: true
  //     });
  //     return;
  //   }

  //   const scanDocumentInput = {
  //     managerAccountId,
  //     accountId,
  //     checkedDocumentId,
  //     newDocuments,
  //     scanSettings: {
  //       countries,
  //       budgetTypes
  //     }
  //   };

  //   const res = await scanDocument({ variables: { input: scanDocumentInput } });
  //   console.log('res: ', res);
  //   console.log('res?.data?.scanDocument: ', res?.data?.scanDocument);

  //   setScanResponse(res?.data?.scanDocument);
  // };

  // const onClickSaveAsDefault = async () => {};

  useEffect(() => {
    if (!scanResponse?.response) {
      return;
    }

    switch (scanResponse?.response) {
      case 'ok':
        setBorderColor('green');
        break;
      case 'fail':
        setBorderColor('red');
        break;

      default:
        console.log('default>scanResponse:', scanResponse);
        console.log('default>scanResponse?.response:', scanResponse?.response);
        setBorderColor('red');
      // console.log('scanResponse?.response: ', scanResponse?.response);
      // setBorderColor('1px solid rgba(150,150,150,0.3)');
    }
  }, [scanResponse.response]);

  console.log('borderColor: ', borderColor);
  console.log('scanResponse: ', scanResponse);

  return (
    <div
      style={{
        border: '1px solid ' + borderColor,
        borderRadius: '5px',
        padding: '20px 5px',
        marginTop: '10px'
      }}
    >
      <div style={{ padding: '10px', fontWeight: 'bold', fontSize: '10px' }}>
        Scan Settings (BETA)
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
          // border: '1px solid rgba(150,150,150,0.3)',
          // borderRadius: '5px',
          // padding: '5px'
        }}
      >
        <AutoComleteBudgetTypes
          label={'Allowed budget types'}
          value={budgetTypes}
          setValue={setBudgetTypes}
          // options={['DAILY', 'LIFETIME']}
        />
        {/* <Select
          label={'Allowed countries'}
          input={countries}
          setInput={setCountries}
          options={['SE', 'NO', 'DK', 'DE']}
        /> */}
        <AutoComleteCountries value={countries} setValue={setCountries} />
        <Button size="small" onClick={onClickSaveAsDefaultHandler}>
          Save as default
        </Button>
      </div>
      <div
        style={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {scanResponse.response && (
          <div
            style={{ color: borderColor, fontWeight: 'bold', padding: '10px' }}
          >
            {scanResponse?.response?.toUpperCase()}!
          </div>
        )}

        {scanResponse.response && (
          <table cellSpacing={10} style={{ fontSize: '12px', width: '100%' }}>
            <tr>
              <th>Name</th>
              <th>Id</th>
              <th>Budget types</th>
              <th>Countries</th>
            </tr>
            {scanResponse?.scanResults?.map((row) => (
              <tr style={{ textAlign: 'center' }}>
                <td>{row.name}</td>
                <td>{row.id}</td>
                <td>
                  {row.budgetTypes.map((budgetType) => (
                    <div style={{ color: !row?.budgetTypesPass && 'red' }}>
                      {budgetType}
                    </div>
                  ))}
                </td>
                <td>
                  {row.countries.map((country) => (
                    <div style={{ color: !row?.countriesPass && 'red' }}>
                      {country}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </table>
        )}
      </div>
    </div>
  );
}

export default ScanDocument;
