import React from 'react';

import { useTheme } from '@emotion/react';

function DropHere({ widget, isBlurOff }) {
  const theme = useTheme();
  return (
    <div
      onDrop={(e) => {
        e.preventDefault();
        // e.stopPropagation();
      }}
      style={{
        position: 'absolute',
        borderRadius: '5px',
        width: '100%',
        height: '100%',
        background:
          theme.palette.mode === 'dark'
            ? 'rgba(255,255,255,0.1)'
            : 'rgba(255,255,255,0.7)',
        backdropFilter: !isBlurOff && 'blur(1px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          fontSize: '34px',
          fontWeight: 'bold',
          color: theme.palette.mode ? 'white' : '#a72683'
        }}
      >
        +
      </div>
    </div>
  );
}

export default DropHere;
