import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula2';

// calc aggregated data

const calcFields2 = (
  compiledExpressions,
  fields,
  calculatedFields,
  fieldsNotDoneCalculating,
  generateScope,
  rowObj,
  fieldsToBeManuallyCalculated,
  calcRounds,
  warnings
) => {
  calcRounds = calcRounds - 1;

  for (let field of fields) {
    let formulaField =
      rowObj.isSet && !fieldsToBeManuallyCalculated.includes(field)
        ? `=${field}`
        : rowObj[field + 'Formula'];

    // if (fieldsToBeManuallyCalculated?.includes(field)) {
    //   fieldsNotDoneCalculating.splice(
    //     fieldsNotDoneCalculating.indexOf(field),
    //     1
    //   );
    //   continue;
    // }
    if (!fieldsNotDoneCalculating.includes(field)) {
      // console.log('fieldsNotDoneCalculating:', fieldsNotDoneCalculating);
      continue;
    }
    const copyOfFormulaFields = [...fields];
    const fieldIndex = copyOfFormulaFields.indexOf(field);
    copyOfFormulaFields.splice(fieldIndex, 1);

    const filteredCopyOfFormulaFields = copyOfFormulaFields.filter((field) => {
      return (
        formulaField?.includes(field) &&
        fieldsNotDoneCalculating.includes(field)
      );
    });
    // console.log('filteredCopyOfFormulaFields: ', filteredCopyOfFormulaFields);

    if (filteredCopyOfFormulaFields.length > 0) {
      continue;
    }

    // console.log('mon1tor>field: ', field);

    if (!compiledExpressions[field]) {
      if (formulaField !== '=' + field) {
        try {
          const formula = formulaField;
          if (formula) {
            let regExpression = '^[0-9.]*$';
            const regEx = new RegExp(regExpression);
            const regExResult = regEx.exec(formula);
            if (regExResult) {
              const formulaAsFloat = parseFloat(formula);
              calculatedFields[field] = isNaN(formulaAsFloat)
                ? formula
                : formulaAsFloat;
            } else {
              let regExpression2 = '[/*,+-]';
              const regEx2 = new RegExp(regExpression2);
              const regExResult2 = regEx2.exec(formula);
              if (regExResult2 && formula && !formula.trim().includes(' '))
                warnings.formulaErrors.push(field);
              calculatedFields[field] = formula;
            }
          } else {
            calculatedFields[field] = null;
          }

          // console.log('hejhopp: ', calculatedFields[field]);
        } catch (error) {
          console.log('error: ', error);
        }
      }
    } else {
      try {
        const evaluateResult = compiledExpressions[field].evaluate(
          generateScope(field, calculatedFields)
        );

        calculatedFields[field] =
          isNaN(evaluateResult) || evaluateResult === Infinity
            ? 0
            : evaluateResult;
      } catch (error) {
        warnings.formulaErrors.push(field);
        calculatedFields[field] = error.message;
      }
    }

    // calculatedFields[field] = compiledExpressions[field].evaluate(
    //   generateScope(field, calculatedFields)
    // );

    // evaluateFormula(
    //   compiledExpressions[field],
    //   formulaField,
    //   generateScope(field, calculatedFields)
    // );
    // console.log('mon1tor>row 266');

    fieldsNotDoneCalculating.splice(fieldsNotDoneCalculating.indexOf(field), 1);
  }
  // console.log('calcRounds: ', calcRounds);

  if (calcRounds === 0) {
    warnings.stackOverFlow = true;
  }

  if (fieldsNotDoneCalculating.length !== 0 && calcRounds > 0) {
    calcFields2(
      compiledExpressions,
      fields,
      calculatedFields,
      fieldsNotDoneCalculating,
      generateScope,
      rowObj,
      fieldsToBeManuallyCalculated,
      calcRounds,
      warnings
    );
    //   calcRounds = calcRounds - 1;
  }
};

const calcFields = (
  compiledExpressions,
  fields,
  calculatedFields,
  fieldsNotDoneCalculating,
  generateScope,
  rowObj,
  fieldsToBeManuallyCalculated,
  warnings
) => {
  let calcRounds = 10;
  //   let warnForStackOverFlow = false;
  //   const warnings = { stackOverFlow: false };

  calcFields2(
    compiledExpressions,
    fields,
    calculatedFields,
    fieldsNotDoneCalculating,
    generateScope,
    rowObj,
    fieldsToBeManuallyCalculated,
    calcRounds,
    warnings
  );

  if (warnings.stackOverFlow) {
    console.log('warning stack overflow');
  }
};

export default calcFields;
