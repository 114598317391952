import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { FilterContext } from '../../../context/FilterContext';

import { useNavigate, useSearchParams } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'center',
//     flexWrap: 'wrap',
//     '& > *': {
//       margin: theme.spacing(0.5)
//     }
//   }
// }));

export default function Chips({
  // setFilterBySelection,
  setFilters,
  // filters,
  setCheckedBoxes
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filters, setFilterBySelection } = useContext(FilterContext);

  // const classes = useStyles();

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
    setFilterBySelection([]);
    // setSearchParams({
    //   ...(searchParams.entries() || []),
    //   account_ids: searchParams.get('account_ids'),
    //   manager_account_id: searchParams.get('manager_account_id'),
    //   start_date: searchParams.get('start_date'),
    //   end_date: searchParams.get('end_date')
    //   // document_ids: undefined
    // });
    // let newSearchParams = {
    //   ...(searchParams.entries() || [])
    // };

    // let searchParamKeys = [
    //   'manager_account_id',
    //   'account_ids',
    //   'start_date',
    //   'end_date'
    // ];
    // for (let key of searchParamKeys) {
    //   if (searchParams.get(key)) {
    //     newSearchParams[key] = searchParams.get(key);
    //   }
    // }
    // setSearchParams(newSearchParams);

    const filteredFilter = filters.filter((filter) => {
      return filter !== 'filter_by_selection';
    });

    setFilters(filteredFilter);

    setCheckedBoxes([]);
  };

  // const handleClick = () => {
  //   console.info('You clicked the Chip.');
  // };

  return (
    <div
      // className={classes.root}
      style={{ gridRow: 1 }}
    >
      <Chip
        icon={<CheckCircleIcon />}
        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
        label="Filter by Selection"
        onDelete={handleDelete}
        // label="Primary clickable"
        // clickable
        // deleteIcon={<DoneIcon />}
      />
    </div>
  );
}
