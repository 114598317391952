import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({
  state,
  setState,
  stateKey,
  options,
  width,
  label,
  disabled
}) {
  const handleChange = (event) => {
    setState({ ...state, [stateKey]: event.target.value });
    setInput(event.target.value);
    // setAge(event.target.value);
  };

  const [input, setInput] = useState('');

  useEffect(() => {
    setInput(state[stateKey]);
  }, [state]);

  console.log('state: ', state);
  console.log('options: ', options);
  console.log('state[stateKey]: ', state[stateKey]);
  if (disabled) {
    return (
      <Box sx={{ minWidth: width || 120, maxWidth: width || 120 }}>
        <FormControl fullWidth disabled>
          <InputLabel size="small" id="demo-simple-select-label">
            {label}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input}
            label={label}
            onChange={handleChange}
          >
            {options.map((option) => (
              <MenuItem value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  return (
    <Box sx={{ minWidth: width || 120, maxWidth: width || 120 }}>
      <FormControl fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          {label}
        </InputLabel>
        <Select
          size="small"
          disabled={disabled}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state[stateKey]}
          label={label}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem value={option.value}>{option.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
