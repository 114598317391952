import React, { Fragment, useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { styled, useTheme } from '@mui/material/styles';

import NewsFeedDialogContent from './NewsFeedDialogContent';

// icons

import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { DataContext } from '../../../../context/DataContext';
import { FilterContext } from './../../../../context/FilterContext';

import { DialogContext } from '../../../../context/DialogContext';

import backgroundImagePattern from './../../../../img/bg-container-purple.png';

import backgroundBeachImage from './../../../../img/beachimg.jpg';
import octagonal_background from './../../../../img/hexagonal_background.jpg';

import HistorySwitch from './../../../news-feed/HistorySwitch';
import LimitMenu from './LimitMenu';

function PaperComponent(props) {
  const isExpanded = false;
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ overflowY: 'hidden' }} />
    </Draggable>
  );
}

const NewsFeedDialog = ({}) => {
  const [includeHistory, setIncludeHistory] = useState(false);
  const [limit, setLimit] = useState(100);

  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);
  const { newsFeedDialogOpen: open, setNewsFeedDialogOpen: setOpen } =
    useContext(DialogContext);

  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={isExpanded}
        // paperFullWidth={true}
        // paper={{ height: '90hw', width: '1000px' }}
        // maxWidth="lg"
        maxWidth="80%"
      >
        <div
          style={{
            background: `
            linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
            linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%)

            `
            //     background: `
            // linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
            // linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
            // linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)

            // `
          }}
        >
          <div
            style={{
              // backgroundImage: `url(${backgroundImagePattern})`,
              // backgroundImage: `url(${backgroundBeachImage})`,
              backgroundImage:
                theme.palette.mode === 'dark'
                  ? // ? `url(${backgroundImagePattern})`
                    `url(${octagonal_background})`
                  : `url(${backgroundBeachImage})`,
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed'
            }}
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              <div
                style={{
                  // display: 'grid',
                  // gridTemplateColumns: 'auto 40px 40px 30px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    display: 'grid',
                    gridColumnStart: 1,
                    gridColumnEnd: 1
                  }}
                >
                  <span style={{ paddingLeft: '7px' }}>
                    {/* <Icon
                      style={{
                        verticalAlign: 'text-bottom',
                        paddingRight: '5px'
                      }}
                    />{' '} */}
                    {/* {headerText} */}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '200px'
                  }}
                >
                  {/* <div>
                    <LimitMenu limit={limit} setLimit={setLimit} />
                  </div> */}
                  <div>
                    <HistorySwitch
                      includeHistory={includeHistory}
                      setIncludeHistory={setIncludeHistory}
                    />
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridColumnStart: 2,
                      gridColumnEnd: 2,
                      alignItems: 'end'
                    }}
                  >
                    {/* <div
                      style={{
                        cursor: 'pointer',
                        right: '0px',
                        textAlign: 'right'
                        // paddingRight: '25px'
                      }}
                    >
                      <span
                        style={{ width: '50px' }}
                        onClick={() => setIsExpanded(!isExpanded)}
                      >
                        {isExpanded ? (
                          <FullscreenExitIcon className="hover-light-grey" />
                        ) : (
                          <FullscreenIcon className="hover-light-grey" />
                        )}
                      </span>
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridColumnStart: 3,
                      gridColumnEnd: 3,
                      alignItems: 'end'
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        right: '0px',
                        textAlign: 'right'
                        // paddingRight: '25px'
                      }}
                    >
                      <span style={{ width: '50px' }} onClick={handleClose}>
                        <CloseIcon className="hover-light-grey" />
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1,
                gridRowStart: 2,
                gridRowEnd: 2
              }}
            >
              <span style={{ paddingLeft: '50px', fontSize: '12px' }}>
                {item?.path}
              </span>
            </div> */}
              </div>
            </DialogTitle>
            <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
              <NewsFeedDialogContent
                isExpanded={isExpanded}
                includeHistory={includeHistory}
                limit={limit}
              />
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default NewsFeedDialog;
