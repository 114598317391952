import React from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     display: 'block',
//     marginTop: theme.spacing(2)
//   },
//   formControl: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//     minWidth: 120
//   }
// }));

export default function ControlledOpenSelect({
  overrideStatus,
  status,
  setStatus
}) {
  // const classes = useStyles();
  //   const [status, setStatus] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {/* <Button className={classes.button} onClick={handleOpen}>
        Open the select
      </Button> */}
      <FormControl
        // className={classes.formControl}
        disabled={!overrideStatus}
      >
        <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
        <Select
          style={{ minWidth: '120px' }}
          variant="standard"
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={status}
          onChange={handleChange}
        >
          {/* <MenuItem value="">'DOUBLECHECK', 'BUDDYCHECK'
            <em>None</em>
          </MenuItem> */}
          <MenuItem value={'DRAFT'}>Draft</MenuItem>
          <MenuItem value={'BUDDYCHECK'}>Buddy Check</MenuItem>
          <MenuItem value={'DOUBLECHECK'}>Double Check</MenuItem>
          <MenuItem value={'READY'}>Ready</MenuItem>
          <MenuItem value={'ACTIVE'}>Active</MenuItem>
          <MenuItem value={'PAUSED'}>Paused</MenuItem>
          <MenuItem value={'COMPLETED'}>Completed</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
