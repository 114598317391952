import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import AutoComplete from './AutoComplete';

import CancelIcon from '@mui/icons-material/Cancel';

import YAxisOrientationButtonGroup from './YAxisOrientationButtonGroup';

import HideYAxisButton from './HideYAxisButton';

import TimeIncrementButtonGroup from './TimeIncrementButtonGroup';

export default function BasicPopover({
  anchorPosition,
  setAnchorPosition,
  field,
  fields,
  setFields,
  componentIndex,
  columnDictionary
}) {
  //   const [anchorEl, setAnchorEl] = React.useState(null);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  const handleClose = () => {
    setAnchorPosition({ top: null, left: null });
  };

  const open = Boolean(anchorPosition?.top);
  const id = open ? 'simple-popover' : undefined;

  const handleRemoveMetric = () => {};

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Popover
        id={id}
        open={open}
        // anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}

        <div style={{ height: '400px', padding: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <AutoComplete
                componentIndex={componentIndex}
                fields={fields}
                setFields={setFields}
                // axis={'leftYAxisMetrics'}
                axisTitle={'Left Y Axis Metrics'}
                columnDictionary={columnDictionary}
              />
            </div>

            {/* <div>
              <AutoComplete
                index={index}
                fields={fields}
                setFields={setFields}
                axis={'rightYAxisMetrics'}
                axisTitle={'Right Y Axis Metrics'}
              />
            </div> */}
          </div>
          <div
            style={{
              margin: '10px 0px',
              padding: '0px 5px',
              border: '1px solid rgba(150,150,150,0.3)',
              borderRadius: '5px'
            }}
          >
            {field.metrics.map((metric, metricIndex) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  // className="nexustable-menu-label"
                  style={{
                    background: metric.color || '#21285a',
                    borderColor: metric.color || '#21285a',
                    padding: '5px 10px',
                    margin: '5px 0px',
                    borderRadius: '5px'
                  }}
                >
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: '14px'
                    }}
                  >
                    {metric.name}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <HideYAxisButton
                      componentIndex={componentIndex}
                      metricIndex={metricIndex}
                      field={field}
                      fields={fields}
                      setFields={setFields}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <YAxisOrientationButtonGroup
                      componentIndex={componentIndex}
                      metricIndex={metricIndex}
                      field={field}
                      fields={fields}
                      setFields={setFields}
                    />
                  </div>
                  {/* <div
                    className="hover-grey"
                    style={{ margin: '0px 5px', cursor: 'pointer' }}
                  >
                    <ColorPicker label={label} refetchLabels={refetch} />
                  </div>
                  <div
                    className="hover-grey"
                    style={{ margin: '0px 5px', cursor: 'pointer' }}
                  >
                    <EditName label={label} refetchLabels={refetch} />
                  </div> */}
                  <div style={{ margin: '0px 5px' }}>
                    <CancelIcon
                      className="hover-grey"
                      style={{
                        position: 'relative',
                        top: '2px',
                        width: '20px',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleRemoveMetric(metric._id)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div style={{ fontSize: '12px' }}>Time Incement</div>
            <TimeIncrementButtonGroup
              componentIndex={componentIndex}
              field={field}
              fields={fields}
              setFields={setFields}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
}
