import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';

function NotificationsItem({
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  checked
}) {
  return (
    <div
      key={keyForMap}
      style={{
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'grid',
        height: '100%',
        width: '100%',
        padding: '12px 15px',
        whiteSpace: 'nowrap'
      }}
    >
      <span
        style={{
          margin: 'auto'
        }}
      >
        {/* <p>{itemValue}</p> */}
        <p>
          6 <NotificationsIcon style={{ fontSize: '10px' }} />
        </p>
        <p
          style={{
            fontSize: '8px',
            color: '#bcbcbc',
            visibility: 'hidden'
          }}
        >
          {' '}
          test
        </p>
      </span>
    </div>
  );
}

export default NotificationsItem;
