import React, { useContext, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Button } from '@mui/material';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
  accountUsersQuery,
  updateDocumentMutation
} from './../../../graphql/queries';

import { MutationContext } from '../../../context/MutationContext';

import { AccountContext } from './../../../context/AccountContext';

import { useSnackbar } from 'notistack';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({
  accountId,
  selectedUsers,
  setSelectedUsers
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [accountUsers, setAccountUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  // const [value, setValue] = React.useState([]);
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // const [updateDocument] = useMutation(updateDocumentMutation);
  const { updateDocument } = useContext(MutationContext);

  const variables = {
    accountsUsersInput: {
      managerAccountId: currentManagerAccount._id,
      accountId: accountId
    }
  };

  const [loadMembers, { called, loading, data }] = useLazyQuery(
    accountUsersQuery,
    { variables, fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    loadMembers();
  }, [accountId]);

  useEffect(() => {
    const sortedAccountUsers = [...(data?.accountUsers || [])];

    sortedAccountUsers.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setAccountUsers(sortedAccountUsers || []);
  }, [data?.accountUsers]);

  return (
    <div
      style={{
        margin: '0px 0px 0px 0px',
        // minHeight: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <Autocomplete
          onBlur={() => setOpen(false)}
          open={open}
          ListboxProps={{
            onMouseLeave: () => setOpen(false)
          }}
          value={selectedUsers}
          onChange={(event, newValue) => {
            setEdit(true);
            setSelectedUsers(newValue);
          }}
          multiple
          id="checkboxes-tags-demo"
          options={accountUsers}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              onClick={() => setOpen(!open)}
              {...params}
              label="Members"
              placeholder="+ Add members"
            />
          )}
        />
      </div>
    </div>
  );
}
