import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function StringItem({
  currentManagerAccountId,
  updateDocument,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  openRows,
  checked
}) {
  const { enqueueSnackbar } = useSnackbar();

  // ################### update #######################

  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = () => {
    if (showTextField) {
      return;
    }
    if (
      ([
        'account',
        // 'totalActionsDocument',
        'folder',
        'task',
        'subTask',
        'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(item.type) &&
        !item.isSetChild) ||
      item.isSet
    ) {
      toggleShowTextField(true);
    }
  };

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    // if (item[itemKey] && item[itemKey].name) {
    setInputValue(item[itemKey]?.name || '');
    // }
  }, [showTextField, toggleShowTextField]);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ _,.-]*$');
    const regExNameResult = regExName.exec(inputValue);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    // if (inputValue.length < 2) {
    //   enqueueSnackbar(
    //     'Name too short. Minimum length 2 characters. ' +
    //       inputValue.length +
    //       '/100 used',
    //     {
    //       variant: 'error'
    //     }
    //   );

    //   return console.log('name too short.');
    // }

    if (inputValue.length > 100) {
      enqueueSnackbar('Name too long. ' + inputValue.length + '/100 used', {
        variant: 'error'
      });

      return console.log('name too long.');
    }

    if (item[itemKey] && inputValue === item[itemKey].name) {
      toggleShowTextField(false);
      return '';
    }

    // const documentInput = {
    //   accountId: item.accountId,
    //   documentId: item._id,
    //   document: { [itemKey]: { name: inputValue } },
    //   updateKey: itemKey,
    //   action: 'UPDATE_DOCUMENT',
    //   managerAccountId: currentManagerAccountId,
    //   documentType: item.documentType,
    //   level: item.level
    // };

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: { name: inputValue }
        }
      ],
      updateKeys: [itemKey]
    };

    updateDocument({ variables: { input: { ...documentInput } } });
    toggleShowTextField(false);
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const textField = (
    <form
      //   style={{ padding: '0px 14px' }}
      onSubmit={onSubmitHandler}
      onBlur={onSubmitHandler}
      // className={classes.root}
      noValidate
      autoComplete="off"
    >
      <TextField
        variant="standard"
        autoFocus
        id="standard-basic"
        value={inputValue}
        inputProps={{
          min: 0,
          style: { textAlign: 'left', padding: '0px', fontSize: '12px' }
        }}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
      />
      {/* <TextField id="filled-basic" label="Filled" variant="filled" />
    <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </form>
  );

  let subValue = '';

  switch (itemKey) {
    default:
      subValue = '';
      break;
  }

  if (
    item.sum_child_budgets &&
    item.sum_child_budgets !== 0 &&
    item.sum_child_budgets !== undefined
  ) {
    // child_sum =
    //   'sum. child.' + parseInt(item.sum_child_budgets).toLocaleString();
  }

  // ##################################################

  let spanStyle = {
    margin: 'auto'
  };
  if (true) {
    spanStyle = { textAlign: 'right' };
  }

  if (['account', 'folder', 'manualDataEntry'].includes(item.documentType)) {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          borderStyle: 'solid',
          borderWidth: '0px 1px 1px 0px',
          borderColor: itemStyle['borderColor'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          left: '53px',
          display: 'grid',
          height: '100%',
          width: '100%',
          //   padding: '10px 7px',
          padding: '10px 14px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          gridTemplateColumns: 'auto 15px',
          cursor: item?.isSetChild ? 'default' : 'text'
        }}
        onClick={onClickHandler}
      >
        {showTextField ? (
          textField
        ) : (
          <>
            <div
              style={{
                gridColumn: 1,
                gridRow: 1,
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <p
                style={{
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <span>{itemValue && itemValue.name} </span>

                <p
                  style={{
                    fontSize: '8px',
                    color: '#bcbcbc'
                    // visibility: 'hidden'
                  }}
                >
                  {' '}
                  {/* {subValue} */}
                </p>
              </p>
            </div>
            {/* <div style={{ gridColumn: 2, gridRow: 1, textAlign: 'end' }}>
              <span style={{ display: 'inline' }}>
                <i
                  className="fas fa-pen hover-grey"
                  style={{
                    fontSize: '8px',
                    // color: 'grey',
                    padding: '4px 0 4px 2px',
                    cursor: 'pointer'
                  }}
                  onClick={onClickHandler}
                ></i>
              </span>
            </div> */}
          </>
        )}
        <p
          style={{
            fontSize: '8px',
            color: '#bcbcbc'
            // visibility: 'hidden'
          }}
        >
          {' '}
          {subValue}
        </p>
      </div>
    );
  } else {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '12px 15px',
          whiteSpace: 'nowrap'
        }}
      >
        <span style={spanStyle}>
          <p>
            {/* {itemValue && parseFloat(itemValue.toFixed(2)).toLocaleString()} */}
            {/* {itemValue && itemValue} */}
          </p>
          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </span>
      </div>
    );
  }
}

export default StringItem;
