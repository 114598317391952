export function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

export const generateWeeks = function (dateRange) {
  const weeks = [];
  const uniqueWeeks = [];
  let weeksObj = {};
  for (let i = 0; i < dateRange.length; i++) {
    var weekNumberAndYear = getWeekNumber(new Date(dateRange[i]));
    const year = weekNumberAndYear[0];
    const weekNumber = weekNumberAndYear[1];

    let newWeeksObjectData = {};

    if (
      weeksObj[year + '-' + weekNumber] &&
      weeksObj[year + '-' + weekNumber].count
    ) {
      newWeeksObjectData = {
        [year + '-' + weekNumber]: {
          week: weekNumber,
          count: weeksObj[year + '-' + weekNumber]['count']
            ? weeksObj[year + '-' + weekNumber]['count'] + 1
            : 1
        }
      };
    } else {
      newWeeksObjectData = {
        [year + '-' + weekNumber]: {
          week: weekNumber,
          count: 1
        }
      };
    }

    weeksObj = {
      ...weeksObj,
      ...newWeeksObjectData
    };
  }

  return weeksObj;
};

export const getDayAsString = (date) => {
  //
  const date_ = new Date(date);
  date_.setHours(5);
  const dayNumber = date_.getDay();

  var weekday = new Array(7);
  weekday[0] = 'Sunday';
  weekday[1] = 'Monday';
  weekday[2] = 'Tuesday';
  weekday[3] = 'Wednesday';
  weekday[4] = 'Thursday';
  weekday[5] = 'Friday';
  weekday[6] = 'Saturday';

  return weekday[dayNumber];
};

export const generateMonths = function (dateRange) {
  const months = [];
  const uniqueMonths = [];
  let monthsObj = {};
  for (let i = 0; i < dateRange.length; i++) {
    const dateString = new Date(dateRange[i]).toISOString().split('T')[0];
    const dateStringArray = dateString.split('-');
    const year = dateStringArray[0];
    const monthNumber = dateStringArray[1];
    // var year = new Date(dateRange[i]).getFullYear();
    // var monthNumber = new Date(dateRange[i]).getMonth();

    let newMonthsObjectData = {};

    if (
      monthsObj[year + '-' + monthNumber] &&
      monthsObj[year + '-' + monthNumber].count
    ) {
      newMonthsObjectData = {
        [year + '-' + monthNumber]: {
          month: monthNumber,
          count: monthsObj[year + '-' + monthNumber]['count']
            ? monthsObj[year + '-' + monthNumber]['count'] + 1
            : 1
        }
      };
    } else {
      newMonthsObjectData = {
        [year + '-' + monthNumber]: {
          month: monthNumber,
          count: 1
        }
      };
    }

    monthsObj = {
      ...monthsObj,
      ...newMonthsObjectData
    };

    // monthsObj = {
    //   ...monthsObj,
    //   [monthNumber]: monthsObj[monthNumber] ? monthsObj[monthNumber] + 1 : 1
    // };

    // if (!uniqueWeeks.includes(weekNumber)) {
    //   uniqueWeeks.push(weekNumber);
    // }
  }

  return monthsObj;
};
