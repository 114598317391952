export const MONS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const comboChartWidget = async (widget, pres, slide, colorPalette) => {
  const pptx = pres;

  if (widget.isShowingCard) {
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
      fill: {
        color: 'FFFFFF',
        transparency: (widget.opacity - 1) * -100 //5
      },
      border: { pt: '1', color: 'f1f1f1', transparency: 10 },
      w: +widget.width.split('px')[0] / 72 / 2,
      h: +widget.height.split('px')[0] / 72 / 2,
      x: +widget.left.split('px')[0] / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2,

      rectRadius: 0.05,
      shadow: {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }
    });
  }

  // let dataChartObj = {
  //   name: widget.title,
  //   labels: [], // widget.data.map((obj) => obj.label),
  //   values: [] // widget.data.map((obj) => obj.value)
  // };

  // let totalValue = 0;

  // for (let obj of widget.data) {
  //   totalValue = totalValue + (obj.value || null);
  // }

  // for (let obj of widget.data) {
  //   if (obj.value / totalValue >= 0.005) {
  //     dataChartObj.labels.push(obj.label);
  //     dataChartObj.values.push(obj.value);
  //   }
  // }
  // let dataChartPieStat = [dataChartObj];

  // console.log('dataChartPieStat: ', dataChartPieStat);
  // {
  //   name: 'Project Status',
  //   labels: ['Red', 'Yellow', 'Green', 'Complete', 'Cancelled', 'Unknown'],
  //   values: [25, 5, 5, 5, 5, 5]
  // }

  // ##################################################################################################

  let MAXVAL = 20000;
  // console.log('widget.data: ', widget.data);

  let highestValues = [0, 0];
  let isPlotAreaFilled = [];
  let numberOfLegends = 0;

  let sliceLabelNumber = 0;
  let arrDataTimelineLabels = [];
  switch (widget.interval) {
    case 'month':
      // sliceLabelNumber = 2;
      arrDataTimelineLabels = widget.dates.map(
        (date) => MONS[new Date(date).getMonth()]
      );

      break;

    case 'date':
      // sliceLabelNumber = 5;
      arrDataTimelineLabels = widget.dates.map((date) => date.slice(5));
      // console.log('arrDataTimelineLabels: ', arrDataTimelineLabels);
      break;

    default:
      arrDataTimelineLabels = widget.dates.map((date) => date);
  }
  // console.log('debug9465>widget.data: ', widget.data);

  const data = widget.data.map((obj) => {
    let stack = '';
    // let isPlotAreaFilled = false;
    switch (true) {
      case obj.chartType === 'bar' &&
        obj.grouping === 'stacked' &&
        obj.dimension:
        stack = obj.chartType + '_' + obj.grouping + '_' + obj.dimension;
        break;
      case obj.chartType === 'bar' &&
        obj.grouping === 'standard' &&
        obj.dimension !== 'default':
        stack = obj.chartType + '_' + obj.grouping;
        break;
      case obj.chartType === 'bar' &&
        obj.grouping === 'standard' &&
        obj.dimension === 'default':
        stack = obj.chartType + '_' + obj.grouping;
        break;

      case obj.chartType === 'line' &&
        obj.grouping === 'stacked' &&
        obj.dimension:
        stack = obj.chartType + '_' + obj.grouping + '_' + obj.dimension;
        // isPlotAreaFilled = true;
        break;
      case obj.chartType === 'line' &&
        obj.grouping === 'standard' &&
        obj.dimension !== 'default':
        stack = obj.chartType + '_' + obj.grouping;
        break;
      case obj.chartType === 'line' &&
        obj.grouping === 'standard' &&
        obj.dimension === 'default':
        stack = obj.chartType + '_' + obj.grouping;
        break;
    }

    return { ...obj, stack, isPlotAreaFilled };
  });

  const groupedByAxis = Object.groupBy(data, (obj) => obj.yAxisId);
  const groupedAxisKeys = Object.keys(groupedByAxis);

  // console.log('debug9465>groupedByAxis: ', groupedByAxis);
  // console.log('debug9465>groupedAxisKeys: ', groupedAxisKeys);
  // console.log('debug9465>arrDataTimelineLabels: ', arrDataTimelineLabels);

  const chartTypes = [];
  let axisIndex = 0;
  for (let groupedAxisKey of groupedAxisKeys) {
    const axisIndex =
      groupedAxisKeys.length > 1 && groupedAxisKey === 'rightAxisId' ? 1 : 0;
    // let highestValue = 0;
    // console.log(
    //   'debug9465>groupedByAxis[groupedAxisKey]: ',
    //   groupedByAxis[groupedAxisKey]
    // );
    const groupedStack = Object.groupBy(
      groupedByAxis[groupedAxisKey],
      (obj) => obj.stack
    );
    const groupedStackKeys = Object.keys(groupedStack);

    for (let groupedStackKey of groupedStackKeys) {
      let arrDataTimeline = [];
      numberOfLegends = numberOfLegends + 1;

      // const axisIndex =
      //   groupedAxisKeys.length > 1 &&
      //   groupedStack?.[groupedStackKey]?.[0]?.yAxisId === 'rightAxisId'
      //     ? 1
      //     : 0;
      // console.log('debug9465>groupedStackKey: ', groupedStackKey);
      // console.log(
      //   'debug9465>groupedStack[groupedStackKey]: ',
      //   groupedStack[groupedStackKey]
      // );

      // // console.log('debug9465>groupedStack: ', groupedStack);

      // // console.log('debug9465>groupedStackKeys: ', groupedStackKeys);
      // // for (let groupedStackKey of groupedStackKeys) {
      // //   for (let groupedDataStack of groupedStack[groupedStackKey]) {
      // // console.log('dataObj: ', dataObj);

      for (let groupedStackObj of groupedStack[groupedStackKey]) {
        const highestValueIndex = axisIndex;
        // groupedStackObj.yAxisId === 'leftAxisId' ? 0 : 1;

        // console.log('highestValueIndex: ', highestValueIndex);
        let totalValues = [0, 0];
        for (let value of groupedStackObj.data || []) {
          totalValues[highestValueIndex] =
            totalValues[highestValueIndex] + value;
        }

        if (highestValues[highestValueIndex] < totalValues[highestValueIndex]) {
          highestValues[highestValueIndex] = totalValues[highestValueIndex];
        }

        arrDataTimeline.push({
          name: groupedStackObj.label || widget.metrics[0],
          labels: arrDataTimelineLabels.map((str) => str), //arrDataTimelineLabels,
          values: groupedStackObj?.data.map((val) => val) //Math.round(val))
          // totalValue
        });
        // console.log('highestValues: ', highestValues);
      }

      const allColors = [...colorPalette];
      const colors =
        axisIndex === 0 ? [...allColors] : [...allColors].reverse();
      chartTypes.push({
        type:
          groupedStack?.[groupedStackKey]?.[0]?.chartType === 'bar'
            ? pptx.charts.BAR
            : pptx.charts.LINE,
        data: arrDataTimeline,
        options: {
          // chartColors: ['F38940', '000000'],
          chartColors: colors.slice(0, arrDataTimeline.length),
          // barGrouping: 'stacked',
          barGrouping: groupedStack[groupedStackKey][0].grouping, // 'stacked', // 'standard',
          secondaryValAxis: axisIndex,
          secondaryCatAxis: axisIndex
        }
      });
    }

    // console.log('debug9465>arrDataTimeline: ', arrDataTimeline);

    // highestValues[axisIndex] = highestValue;
    // axisIndex++;
    //   }
    // }
  }

  // console.log('groupedData: ', groupedData);

  // console.log('debug9465>chartTypes: ', chartTypes);

  // console.log('arrDataTimeline: ', arrDataTimeline);

  // for (let idx = 0; idx < 15; idx++) {
  //   let tmpObj = {
  //     name: `Series ${idx}`,
  //     labels: MONS,
  //     values: []
  //   };

  //   for (let idy = 0; idy < MONS.length; idy++) {
  //     tmpObj.values.push(Math.floor(Math.random() * MAXVAL) + 1);
  //   }

  //   // arrDataTimeline.push(tmpObj);
  // }
  let showLegend = false;

  switch (true) {
    case widget?.dimensions?.length > 0 &&
      widget?.dimensions[0] === 'documentType':
    case widget?.dimensions?.length > 0 && widget?.dimensions?.length < 3:
      showLegend = true;
      break;
  }

  // let valAxisMajorUnit = undefined;
  // let valAxisDisplayUnit = undefined;
  // let valAxisLabelFormatCode = undefined;

  const valAxisMajorUnit = (highestValue) => {
    let averageValuePerDay = highestValue / widget.dates.length;
    switch (true) {
      // case averageValuePerDay >= 10000:
      //   valAxisDisplayUnit = 'thousands';
      //   valAxisLabelFormatCode = '#,##0 k';
      //   break;

      case averageValuePerDay < 0.1:
        return 0.05;
        break;
      case averageValuePerDay < 1:
        return 0.1;
        break;

      default:
        return undefined;
    }
  };

  const valAxisDisplayUnit = (highestValue) => {
    let averageValuePerDay = highestValue / widget.dates.length;
    switch (true) {
      case averageValuePerDay >= 1000000:
        return 'millions';
        // valAxisLabelFormatCode = '#,##0 k';
        break;
      case averageValuePerDay >= 10000:
        return 'thousands';
        // valAxisLabelFormatCode = '#,##0 k';
        break;

      // case averageValuePerDay < 0.5:
      //   return (valAxisMajorUnit = 0.01);
      //   break;
      // case averageValuePerDay < 1:
      //   return (valAxisMajorUnit = 0.1);
      //   break;

      default:
        // return (valAxisLabelFormatCode = '#,##0');
        return undefined;
    }
  };

  const valAxisLabelFormatCode = (highestValue) => {
    let averageValuePerDay = highestValue / widget.dates.length;
    switch (true) {
      case averageValuePerDay >= 1000000:
        // return valAxisDisplayUnit = 'thousands';
        return '#,##0 "M"';
        break;
      case averageValuePerDay >= 10000:
        // return valAxisDisplayUnit = 'thousands';
        return '#,##0 k';
        break;

      // case averageValuePerDay < 0.5:
      //   return valAxisMajorUnit = 0.01;
      //   break;
      // case averageValuePerDay < 1:
      //   return valAxisMajorUnit = 0.1;
      //   break;

      default:
        // return '#,##0';
        return undefined;
    }
  };
  // let showKFormatting = averageValuePerDay >= 10000;
  // console.log(
  //   'highestValue / widget.dates.length: ',
  //   highestValue / widget.dates.length
  // );
  // FULL SLIDE:
  // let optsChartLine1 = {
  //   chartArea: {
  //     fill: {
  //       color: 'FFFFFF',
  //       transparency: 100
  //     },
  //     // border: { pt: '1', color: 'f1f1f1', transparency: 100 },
  //     roundedCorners: true

  //     // shadow: {
  //     //   type: 'outer',
  //     //   blur: 10,
  //     //   offset: 5,
  //     //   angle: 45,
  //     //   color: '000000',
  //     //   opacity: 1
  //     // }
  //   },

  //   plotArea: {
  //     fill: { color: '00FFFF', transparency: 100 },
  //     border: { pt: '1', color: 'f1f1f1' }
  //   },
  //   // x: 0.5,
  //   // y: 0.6,
  //   // w: '95%',
  //   // h: '85%',
  //   // barDir: 'col',
  //   // barGrouping: 'stacked',
  //   chartColors: colorPalette.slice(0, arrDataTimeline.length), //COLORS_RYGU,['#FF0099'],
  //   // invertedColors: ['C0504D'],
  //   w: +widget.width.split('px')[0] / 72 / 2 + 0 / 72 / 2,
  //   h: +widget.height.split('px')[0] / 72 / 2,
  //   x: +widget.left.split('px')[0] / 72 / 2,
  //   y: +widget.top.split('px')[0] / 72 / 2,
  //   // plotArea: { fill: { color: 'F2F9FC' } },
  //   // valAxisMaxVal: MAXVAL,
  //   showLegend: showLegend,
  //   legendPos: 'r',
  //   catAxisLabelFontSize: 7,
  //   valAxisLabelFontSize: 7,
  //   valAxisMajorUnit: valAxisMajorUnit && valAxisMajorUnit,
  //   valAxisDisplayUnit: valAxisDisplayUnit && valAxisDisplayUnit, //showKFormatting && 'thousands',
  //   valAxisLabelFormatCode: valAxisLabelFormatCode, // showKFormatting ? '#,##0 k' : '#,##0',
  //   legendFontSize: 8,
  //   legendFontFace: 'Roboto',

  //   // layout: { x: 0.03, y: 0.35, w: 0.8, h: 0.65 },
  //   // layout: showLegend && { x: 0.01, y: 0, w: 0.54, h: 1 },
  //   // layout: showLegend && { x: 0.01, y: 0, w: '10%', h: 1 },
  //   catAxisLineSize: 1,
  //   valAxisLineSize: 1,
  //   // valAxisMajorTickMark: 'none',
  //   // valAxisMinorTickMark: 'none',
  //   // catAxisMajorTickMark: 'none',
  //   // catAxisMinorTickMark: 'none',
  //   radarStyle: 'filled',
  //   catAxisBaseTimeUnit: 'days',
  //   catAxisMajorTimeUnit: 'days',
  //   catAxisLabelRotate: '90',
  //   // catAxisLabelFrequency: 5,
  //   // catAxisLineStyle: 'solid',
  //   lineDataSymbol: 'none',
  //   valAxisLineShow: true,
  //   catGridLine: { style: 'none' },
  //   valGridLine: {
  //     // style: 'none',
  //     color: 'F5F5F5'
  //   },
  //   lineSize: 1.5
  //   // lineDash: 'dot',
  //   // lineCap: 'round'
  //   // lineDataSymbol: 'dot'
  // };
  // slide.addChart(pres.charts.BAR, arrDataTimeline, optsChartLine1);

  // ####################################################################################################
  let opts = {
    // x: 0.6,
    // y: 0.6,
    // w: 6.0,
    // h: 3.0,
    w: +widget.width.split('px')[0] / 72 / 2 + 0 / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,
    barDir: 'col',
    catAxisLabelColor: '666666',
    catAxisLabelFontFace: 'Arial',
    catAxisLabelFontSize: 12,
    catAxisOrientation: 'minMax',
    showLegend: true, //numberOfLegends > 2,
    legendFontSize: 6,
    legendFontFace: 'Roboto',
    legendPos: 't',
    showTitle: false,

    catAxisLabelFontSize: 7,
    valAxisLabelFontSize: 7,
    lineDataSymbol: widget.dates.length > 7 && 'none',
    // valAxisMajorUnit: valAxisMajorUnit && valAxisMajorUnit,
    // valAxisDisplayUnit: valAxisDisplayUnit && valAxisDisplayUnit, //showKFormatting && 'thousands',
    // valAxisLabelFormatCode: valAxisLabelFormatCode, // showKFormatting ? '#,##0 k' : '#,##0',

    // valAxisMaxVal: 100,
    // valAxisMajorUnit: 10,
    lineSize: 1.5,

    valGridLine: {
      // style: 'none',
      color: 'F5F5F5'
    },

    valAxes: [],

    catAxes: []
  };
  // console.log('debug9465>highestValues: ', highestValues);

  if (groupedAxisKeys.includes('leftAxisId')) {
    opts.valAxes.push({
      showValAxisTitle: false,
      valAxisTitle: 'Primary Value Axis',
      // valAxisMajorUnit: valAxisMajorUnit(highestValues[0]),
      valAxisDisplayUnit: valAxisDisplayUnit(highestValues[0]), //showKFormatting && 'thousands',
      valAxisLabelFormatCode: valAxisLabelFormatCode(highestValues[0]), // showKFormatting ? '#,##0 k' : '#,##0',
      valAxisMinVal: 0
    });

    opts.catAxes.push({
      catAxisTitle: 'Primary Category Axis'
    });
  }

  if (groupedAxisKeys.includes('rightAxisId')) {
    opts.valAxes.push({
      showValAxisTitle: false,
      valAxisTitle: 'Secondary Value Axis',
      valGridLine: { style: 'none' },
      // valAxisMajorUnit: valAxisMajorUnit(highestValues[1]),
      valAxisDisplayUnit: valAxisDisplayUnit(highestValues[1]), //showKFormatting && 'thousands',
      valAxisLabelFormatCode: valAxisLabelFormatCode(highestValues[1]), // showKFormatting ? '#,##0 k' : '#,##0',
      valAxisMinVal: 0
    });

    opts.catAxes.push({
      catAxisHidden: groupedAxisKeys.length > 1
    });
  }

  // console.log(
  //   'debug4582>valAxisMajorUnit(highestValues[1]) ',
  //   valAxisMajorUnit(highestValues[1])
  // );

  // const comboTypes = [];

  // const comboTypes = [
  //   {
  //     type: pptx.charts.BAR,
  //     data: CHART_DATA.EvSales_Vol.chartData,
  //     options: { chartColors: COLORS_SPECTRUM, barGrouping: 'stacked' }
  //   },
  //   {
  //     type: pptx.charts.LINE,
  //     data: CHART_DATA.EvSales_Pct.chartData,
  //     options: {
  //       chartColors: ['F38940'],
  //       secondaryValAxis: true,
  //       secondaryCatAxis: true
  //     }
  //   }
  // ];

  slide.addChart(chartTypes, opts);

  // ##################################################################################################

  let titleWidth = Math.round(widget.title.length * 9 + 10);
  // if (titleWidth < 120) {
  //   titleWidth = 120;
  // }

  if (
    // numberOfLegends < 3
    false
  ) {
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
      fill: {
        color: 'ababab',
        transparency: 90
      },
      border: { pt: '1', color: 'f1f1f1', transparency: 10 },
      w: (titleWidth + 20) / 72 / 2,
      h: 25 / 72 / 2,
      x:
        +widget.left.split('px')[0] / 72 / 2 +
        widget.width.split('px')[0] / 72 / 2 -
        (titleWidth + 20) / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2 + 0 / 72 / 2,

      rectRadius: 0.01
      // shadow: {
      //   type: 'outer',
      //   blur: 6,
      //   offset: 2,
      //   angle: 45,
      //   color: 'bcbcbc',
      //   opacity: 0.2
      // }
    });

    slide.addText(widget.title, {
      w: titleWidth / 72 / 2,
      h: 20 / 72 / 2,
      x:
        +widget.left.split('px')[0] / 72 / 2 +
        widget.width.split('px')[0] / 72 / 2 -
        (titleWidth + 10) / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2 + 2 / 72 / 2,

      margin: 0,
      fontFace: 'Roboto', // 'Arial',
      fontSize: 8,
      color: '000000',
      bold: true,
      // underline: true,
      isTextBox: true,
      align: 'left'
      // fill: { color: 'F1F1F1' }
      // highlight: '#bcbcbc'
    });
  }
};

export default comboChartWidget;
