import React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ChipsInput({ filter, updateSFilter, documentType }) {
  const onChangeHandler = (e, value) => {
    updateSFilter({ ...filter, values: value });
  };

  return (
    <Autocomplete
      autoSelect
      style={{ width: '380px' }}
      multiple
      id="tags-filled"
      options={[]}
      freeSolo
      value={[...(filter.values || [])]}
      onChange={onChangeHandler}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Keywords"
          placeholder="Add keyword"
        />
      )}
    />
  );
}
