import React, { Fragment, useEffect, useState, useContext } from 'react';

import axios from 'axios';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/SpinnerSmall';

// import { makeStyles } from '@mui/styles';

import AcUnitIcon from '@mui/icons-material/AcUnit';

import CloseIcon from '@mui/icons-material/Close';

import Button from '@mui/material/Button';

// uncomment

import Table from './Table';

import ExportMenu from './export/ExportMenu';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import ColumnMenu from './column-menu/ColumnMenu';

// import arrayOfData from './dummyData';

import ReactDateRange from './ReactDateRange';

import EditDialog from './bulk-edit-menu/EditDialog';
import CopyDialog from './copy-paste-menu/CopyDialog';

import EmailPokeDialog from './email-poke-menu/EmailPokeDialog';

import AlertDialog from './AlertDialog';

import EmailIcon from '@mui/icons-material/Email';

import EditMenu from './edit-menu/EditMenu';

import { MutationContext } from '../../context/MutationContext';

import {
  allEvalFields,
  sumEvalFields,
  nonSumEvalFields,
  plannedEvalFields,
  allEvalFieldsExceptTotal
} from './fields';

// import Dialogs from './Dialogs';

// end of uncomment

// ################## imports regarding data ################################
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import {
  tableDataQuery2,
  documentBasicAddedSubscription,
  documentBasicUpdatedSubscription,
  documentAddedSubscription,
  documentUpdatedSubscription,
  refetchDataSubscription,
  addDocumentMutation,
  updateDocumentMutation,
  // tableDataQuery
  customColumnSetsQuery
} from '../../graphql/queries';

import { AuthContext } from '../../context/AuthContext';
import { AccountContext } from '../../context/AccountContext';
import { ColumnContext } from '../../context/ColumnContext';

import { DataContext } from '../../context/DataContext';
import { InterfaceContext } from '../../context/InterfaceContext';

import { SnackbarProvider, useSnackbar } from 'notistack';

import evaluateFormula from './roitable_functions/evaluateFormula';

import calcFields from './roitable_functions/calcFields';
import calcFields2 from './roitable_functions/calcFields2';

import DataLevelSwitch from './DataLevelSwitch';

import EditIcon from '@mui/icons-material/Edit';

// Context

import { FilterContext } from '../../context/FilterContext';

import QuickCreate from './QuickCreate';

import StatusFilterMenu from './StatusFilterMenu';
import FilterMenu from './FilterMenu';

import DisplayMenu from './report/views/DisplayMenu';

// RoiTable functions

import parse_fb_campaign_data from './roitable_functions/parse_fb_campaign_data';
import parse_fb_data from './roitable_functions/parse_fb_data';

import parse_google_ads_campaign_data from './roitable_functions/parse_google_ads_campaign_data';
import parse_google_ads_data from './roitable_functions/parse_google_ads_data';

import parse_cm360_campaign_data from './roitable_functions/parse_cm360_campaign_data';

import parse_dv360_insertion_order_data from './roitable_functions/parse_dv360_insertion_order_data';
import parse_dv360_line_item_data from './roitable_functions/parse_dv360_line_item_data';

import parse_snapchat_ads_campaign_data from './roitable_functions/parse_snapchat_ads_campaign_data';
import parse_snapchat_ads_ad_squad_data from './roitable_functions/parse_snapchat_ads_ad_squad_data';

import parse_tiktok_ads_campaign_data from './roitable_functions/parse_tiktok_ads_campaign_data';

import formatDate from './roitable_functions/formatDate';
import ColumnDialog from './column-menu/ColumnDialog';
import FieldDialog from './report/info/field-menu/FieldDialog';

import { DialogContext } from '../../context/DialogContext';
import FilterComponent from './FilterComponent';

import { columnArray as allColumns } from './column-menu/columns';

import {
  // atan2,
  // chain,
  // derivative,
  // e,
  // evaluate,
  // log,
  // pi,
  // pow,
  // round,
  // sqrt
  compile
} from 'mathjs';

// const abc = {};
// abc['hej'] = compile('cost');
// console.log('abc23423: ', abc['hej'].evaluate({ cost: 5000 }));

// ######################################################################################

// const useStyles = makeStyles((theme) => ({
//   button: {
//     margin: theme.spacing(1)
//   }
// }));

const getDates = (startDate, endDate) => {
  let dates = [];

  //to avoid modifying the original date
  const theDate = new Date(startDate);
  const theEndDate = new Date(endDate);
  theDate.setHours(5);
  theEndDate.setHours(5);
  while (theDate <= theEndDate) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }

  // if(dates[dates.length-1] !== theEndDate){
  //   dates.push(theEndDate);
  // }

  return dates;
};

// first and last date

// var todaysDate = new Date();
// var firstDayOfMonth = new Date(
//   todaysDate.getFullYear(),
//   todaysDate.getMonth(),
//   1
// );
// firstDayOfMonth.setHours(5);

// var lastDayOfMonth = new Date(
//   todaysDate.getFullYear(),
//   todaysDate.getMonth() + 1,
//   0
// );
// lastDayOfMonth.setHours(5);

// const default_date_start_first_date_as_string = firstDayOfMonth
//   .toISOString()
//   .split('T')[0];

// const default_date_stop_last_date_of_month_as_string = lastDayOfMonth
//   .toISOString()
//   .split('T')[0];

// const default_date_start = new Date();
// default_date_start.setDate(default_date_start.getDate() - 35);
// const default_date_start_as_string = default_date_start
//   .toISOString()
//   .split('T')[0];

// const default_date_stop = new Date();
// const default_date_stop = new Date();
// default_date_stop.setDate(default_date_stop.getDate() + 14);
// const default_date_stop_as_string = default_date_stop
//   .toISOString()
//   .split('T')[0];

const rowBaseKV = (document, documentType, source) => {
  const formulaObjectToSpread = {};

  for (let formulaKey of allEvalFields) {
    formulaObjectToSpread[formulaKey + 'Formula'] = document[formulaKey];
    formulaObjectToSpread[formulaKey + 'Sparkline'] = [];
    formulaObjectToSpread[formulaKey] = null;
    formulaObjectToSpread[formulaKey + 'Today'] = null;
    formulaObjectToSpread[formulaKey + 'Yesterday'] = null;
    formulaObjectToSpread[formulaKey + 'SumChild'] = null;
  }

  const spreadObject = {};
  for (let key of Object.keys(document)) {
    if (!allEvalFields.includes(key)) {
      spreadObject[key] = document[key];
    } else {
      spreadObject[key] = null;
    }
  }

  return {
    // ...document,
    ...spreadObject,
    ...formulaObjectToSpread,

    // _id: document._id,

    childDocumentIds: [],

    warnings: [],
    subWarnings: [],
    snackbarWarnings: [],
    highestWarningLevel: null,

    startDate: document.startDate,
    endDate: document.endDate,
    type: document.type ? document.type : documentType,

    budgetUntillNow: null,

    source,
    interactivePath: [],
    childRows: [],
    total_list_of_propagation_data: [],
    totalData: [],
    total_list_of_propagation_data_row: [],
    _members: document._members || [],
    members: document.members || [],
    _labels: document._labels || [],
    labels: document.labels || []
  };
};

const RoiTable = ({
  productPath,
  // currentManagerAccount,
  // currentAccountIds,
  stylesStoredInRedux
  // user
}) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const {
    currentManagerAccount,
    currentAccountIds,
    currentAccounts,
    subscriptionSkip,
    setSubscriptionSkip
  } = useContext(AccountContext);

  const {
    selectedDateStart,
    setSelectedDateStart,
    selectedDateStop,
    setSelectedDateStop,
    range,
    setRange,
    range2,
    setRange2,
    isAdvancedModeOn: isAdvancedModeOnFromContext,
    copiedDocumentIds,
    setCopiedDocumentIds,
    copiedDocumentsAccountId,
    setCopiedDocumentsAccountId
  } = useContext(InterfaceContext);

  const [previousSelectedDateStart, setPreviousSelectedDateStart] =
    useState('');
  const [previousSelectedDateStop, setPreviousSelectedDateStop] = useState('');

  useEffect(() => {
    const today = new Date();

    const start = new Date(today.getFullYear(), today.getMonth() - 3, 1)
      .toISOString()
      .split('T')[0];

    const stop = new Date(today.getFullYear(), today.getMonth() + 4, 1)
      .toISOString()
      .split('T')[0];

    setPreviousSelectedDateStart(selectedDateStart);
    setPreviousSelectedDateStop(selectedDateStop);

    setSelectedDateStart(start);

    setSelectedDateStop(stop);
    return () => {
      setSelectedDateStart(previousSelectedDateStart);
      setSelectedDateStop(previousSelectedDateStop);
    };
  }, []);

  const isAdvancedModeOn = false;
  const {
    statusFilter,
    setStatusFilter,
    // filterBySelection,
    setFilterBySelection,
    filters,
    setFilters,
    // filterByMemberId,
    // setFilterByMemberId,

    // filterByLabelId,
    // setFilterByLabelId,

    // filterByString,
    // setFilterByString,
    // filterByName,
    // setFilterByName,
    // filterByDataSourceName,
    // setFilterByDataSourceName,
    // filterByDataSource,
    // setFilterByDataSource,
    checkedBoxes,
    setCheckedBoxes
  } = useContext(FilterContext);

  const { refetchColumns } = useContext(ColumnContext);

  const {
    columnDialogOpen,
    editDialogOpen,
    setEditDialogOpen,
    emailPokeDialogOpen,
    setEmailPokeDialogOpen,
    fieldDialogOpen,
    copyDialogOpen
  } = useContext(DialogContext);

  const { listOfData, setListOfData } = useContext(DataContext);

  const [dataLevel, setDataLevel] = useState('campaign');

  const [visColumns, setVisColumns] = useState([]);
  const [arrayOfData, setArrayOfData] = useState([]);

  const [names, setNames] = useState([]);

  // const [subscriptionSkip, setSubscriptionSkip] = useState(true);

  const [allSnackbarWarnings, setAllSnackbarWarnings] = useState([]);

  // const [copiedDocumentIds, setCopiedDocumentIds] = useState([]);
  // const [copiedDocumentsAccountId, setCopiedDocumentsAccountId] = useState([]);

  const [loading, setLoading] = useState(true);
  const [data, setData] = [];

  const refetch = () => {
    console.log('refetch');
  };

  const action = (key) => (
    <>
      {/* <button onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
        Alert
      </button> */}
      <Button
        style={{ maxWidth: '40px', minWidth: '40px', width: '40px' }}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon />
      </Button>
    </>
  );

  useEffect(() => {
    for (let snackbarWarning of allSnackbarWarnings) {
      enqueueSnackbar(snackbarWarning.message, {
        variant: snackbarWarning.variant,
        action
        // persist: true
      });
    }
  }, [allSnackbarWarnings]);

  // useEffect(() => {
  //   if (
  //     currentManagerAccount?._id &&
  //     currentAccountIds?.length > 0 &&
  //     user?._id
  //   ) {
  //     setSubscriptionSkip(false);
  //   }
  // }, [currentManagerAccount, currentAccountIds]);

  const [summaryRow, setSummaryRow] = useState({
    cost: null,
    impressions: null,
    trp: null,
    spots: null
  });

  const [totalListOfPropagationData, setTotalListOfPropagationData] = useState(
    []
  );

  const [totalListOfPropagationBasicData, setTotalListOfPropagationBasicData] =
    useState([]);

  const [subscriptionUpdatesFrozen, setSubscriptionUpdatesFrozen] =
    useState(false);

  // #############################################################

  const [variables, setVariables] = useState({});
  useEffect(() => {
    setSubscriptionCallStack([]);

    let variables = {
      accountsInput: {
        managerAccountId: currentManagerAccount._id,
        accountId: currentAccountIds || []
      },
      nexusDocumentsQueryInput: {
        selectedDateStart,
        selectedDateStop,
        statusFilter: statusFilter,
        dataLevel
      },
      adAccountCampaignsInput: { selectedDateStart, selectedDateStop }
    };

    if (!variables?.accountsInput.managerAccountId) {
      return;
    }

    if (['system_admin', 'debug'].includes(user.role)) {
      console.log(
        'debug>RoiTable>row 242>currentAccountIds: ',
        currentAccountIds
      );
      console.log('debug>RoiTable>row 243>variables 65555: ', variables);
    }

    setVariables(variables);
  }, [
    currentManagerAccount?._id,
    currentAccountIds,
    selectedDateStart,
    selectedDateStop,
    dataLevel,
    statusFilter
  ]);

  const [skip, toggleSkip] = useState(false);

  useEffect(() => {
    setLoading(true);
    // const getData = async () => {
    //   var data_ = JSON.stringify({
    //     manager_account_id: '604a98cd77edfd3c39444eda',
    //     accountIds: ['604a99d704ba1b001648df7a']
    //   });

    //   var config = {
    //     method: 'post',
    //     url: 'http://localhost:5000/api/cachedtabledata',
    //     // headers: {
    //     //   'x-auth-token':
    //     //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNWU5NDJlNmZhNGYyMzY3NjVlYmZiNGVhIiwibmFtZSI6IkFsZXhhbmRlciBIb2xtc3Ryw7ZtIiwiY2xpZW50X2lwIjoiOjoxIn0sImlhdCI6MTY3MjA0Mzc3MiwiZXhwIjoxNjcyMDc2MTcyfQ.6ha2WQNG7WIsl3-QGpd5K01AZnPZ2Al9UVULMWMxvx0',
    //     //   'Content-Type': 'application/json'
    //     // },
    //     data: data_
    //   };

    //   const response = await axios(config);
    //   setData(response.data);
    //   console.log('response.data: ', response.data);
    // };
    // console.log('before running script');
    // getData();

    var data = JSON.stringify({
      manager_account_id: currentManagerAccount._id,
      accountIds: currentAccountIds,
      statusFilter,
      loadGraphs: productPath === 'cachedtable' ? true : false
    });

    let url = 'https://api.nexustable.com/api/cachedtabledata/';

    if (window.location.host === 'localhost:3000') {
      url = 'https://localhost:5000/api/cachedtabledata/';
    }

    var config = {
      method: 'post',
      url,
      headers: {
        'x-auth-token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    };

    if (currentManagerAccount?._id && currentAccountIds) {
      axios(config)
        .then(function (response) {
          const data = response.data;
          setListOfData(
            data.map((obj) => ({
              ...obj
              // childRows: []
            }))
          );
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [currentManagerAccount, currentAccountIds, productPath]);

  // const [
  //   getData,
  //   { loading, error, data, refetch, client, startPolling, stopPolling }
  // ] = useLazyQuery(tableDataQuery2, {
  //   variables,

  //   fetchPolicy: 'no-cache',

  //   onCompleted: () => {
  //     console.log('RoiTable>Query completed.');
  //   },
  //   onError: (error) => {
  //     console.log('error: ', error);
  //   }
  // });

  // useEffect(() => {
  //   if (
  //     variables?.accountsInput?.managerAccountId &&
  //     selectedDateStart &&
  //     selectedDateStop
  //     // variables?.accountsInput?.accountId?.length > 0
  //   ) {
  //     console.log('running get data 65555');
  //     const data = getData();
  //     // console.log('running get data>data: ', data);
  //   }
  // }, [variables]);

  // console.log(' after running get data>data 65555: ', data);
  // useEffect(() => {
  //   console.log('65555: data: ', data);
  // }, [data]);

  // const { data: columnData, refetch: refetchColumns } = useQuery(
  //   customColumnSetsQuery,
  //   {
  //     variables: {
  //       customColumnSetsInput: { managerAccountId: currentManagerAccount._id }
  //     },
  //     fetchPolicy: 'no-cache',
  //     // skip: false
  //     // skip: currentManagerAccount._id ? skip : true
  //     skip:
  //       (currentAccountIds.length > 1 && dataLevel === 'campaign') ||
  //       currentManagerAccount._id
  //         ? skip
  //         : true
  //   }
  // );

  if (['system_admin', 'debug'].includes(user.role)) {
    console.log('debug>RoiTable>row 293>data: ', data);
  }

  // const [addDocument] = useMutation(addDocumentMutation);
  // const [updateDocument] = useMutation(updateDocumentMutation);

  const { addDocument, updateDocument } = useContext(MutationContext);

  const [rerender, setRerender] = useState(0);
  const [updateAccountId, setUpdateAccountId] = useState('');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  if (window.WSDisconnectWarning && !loading) {
    enqueueSnackbar(window.WSDisconnectWarning, {
      variant: 'error',
      persist: true,
      preventDuplicate: true,
      action
    });

    // return console.log('Unallowed characters were used.');
  }

  // ################################################################

  useSubscription(refetchDataSubscription, {
    variables: {
      refetchDataInput: {
        currentManagerAccountId: currentManagerAccount._id,
        currentAccountIds,
        subscriptionUpdatesFrozen
      }
    },
    // skip: subscriptionSkip,
    onSubscriptionData: async ({ client, subscriptionData }) => {
      if (subscriptionUpdatesFrozen) {
        return;
      }
      //clear subscription callstacks

      const refetchAction =
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.refetchData &&
        subscriptionData.data.refetchData.action;

      switch (refetchAction) {
        case 'REFETCH_CUSTOM_COLUMN_SET':
          refetchColumns();
          break;
        default:
          setSubscriptionCallStack([]);
          refetch();
      }
    }
  });

  // #################################################################

  useSubscription(documentBasicAddedSubscription, {
    variables: {
      documentUpdatedInput: {
        currentAccountIds: currentAccountIds,
        selectedDateStart,
        selectedDateStop,
        dataLevel,
        subscriptionUpdatesFrozen
      }
    },
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    // skip: subscriptionSkip,

    onSubscriptionData: async ({ client, subscriptionData }) => {
      if (subscriptionUpdatesFrozen) {
        return;
      }
      setSubscriptionCallStack([
        ...subscriptionCallStack,
        { subscriptionType: 'documentAddedSubscription', subscriptionData }
      ]);

      if (['system_admin', 'debug'].includes(user.role)) {
        console.log(
          'debug>RoiTable>row 400>documentAddedSubscription>subscriptionData: ',
          subscriptionData
        );
      }
    }
  });

  const documentAdded = (subscriptionData) => {
    try {
      // if (data.accounts.length <= 0) {
      //   return;
      // }

      const documentAdded = subscriptionData.data.documentAdded;

      if (documentAdded) {
        enqueueSnackbar(
          'Document added. You will not see the document in cached view immediately.',
          {
            variant: 'success'
          }
        );
      }
      return;
      const {
        checkedDocumentId,
        level,
        newNexusDocument,
        documentType,
        updateKey,
        updatedContainer,
        action
      } = subscriptionData.data.documentAdded;

      let preparedDocument = {
        ...newNexusDocument,
        nexusDocuments: [],
        childDocumentIds: []
      };

      const parentFolder = listOfData.filter(
        (obj) => obj._id == checkedDocumentId
      );
      if (!parentFolder) {
        return;
      }

      const {
        level_1_container_id: level1ContainerId,
        level_2_container_id: level2ContainerId,
        level_3_container_id: level3ContainerId,
        level_4_container_id: level4ContainerId,
        level_5_container_id: level5ContainerId,
        level_6_container_id: level6ContainerId,
        level_7_container_id: level7ContainerId,
        level_8_container_id: level8ContainerId,
        level_9_container_id: level9ContainerId,
        level_10_container_id: level10ContainerId
      } = parentFolder[0];

      // const copyOfAccounts = [...data.accounts];

      const accounts = data.accounts;

      // console.log('subscriptionData123: ', subscriptionData);

      let level_1_container;
      let level_2_container;
      let level_3_container;
      let level_4_container;
      let level_5_container;
      let level_6_container;
      let level_7_container;
      let level_8_container;
      let level_9_container;
      let level_10_container;

      let level_1_container_index;
      let level_2_container_index;
      let level_3_container_index;
      let level_4_container_index;
      let level_5_container_index;
      let level_6_container_index;
      let level_7_container_index;
      let level_8_container_index;
      let level_9_container_index;
      let level_10_container_index;
      let level_1_container_updated;

      let snackbarMessage;
      let snackbarVariant = 'success';

      const getContainerToBeChanged = (
        parentNexusDocument,
        childNexusDocumentId
      ) => {
        const nexusDocumentToBeChanged =
          parentNexusDocument.nexusDocuments.filter((obj) => {
            return obj._id === childNexusDocumentId;
          })[0];
        return nexusDocumentToBeChanged;
      };

      const getContainertoBeChangedIndex = (
        parentNexusDocument,
        childNexusDocumentId
      ) => {
        const nexusDocumentToBeChangedIndex =
          parentNexusDocument.nexusDocuments.findIndex((obj) => {
            return obj._id === childNexusDocumentId;
          });
        return nexusDocumentToBeChangedIndex;
      };

      if (level >= 1) {
        level_1_container = accounts.filter((obj) => {
          return obj._id === level1ContainerId;
        })[0];
        level_1_container_index = accounts.findIndex((obj) => {
          return obj._id === level1ContainerId;
        });
        if (level_1_container_index === -1) {
          console.log('level_1_container_index===-1');
          return;
        }
      }

      if (level >= 2) {
        level_2_container = getContainerToBeChanged(
          level_1_container,
          level2ContainerId
        );

        level_2_container_index = getContainertoBeChangedIndex(
          level_1_container,
          level2ContainerId
        );
        if (level_2_container_index === -1) {
          console.log('level_2_container_index===-1');
          return;
        }
      }

      if (level >= 3) {
        level_3_container = getContainerToBeChanged(
          level_2_container,
          level3ContainerId
        );

        level_3_container_index = getContainertoBeChangedIndex(
          level_2_container,
          level3ContainerId
        );
        if (level_3_container_index === -1) {
          console.log('level_3_container_index===-1');
          return;
        }
      }

      if (level >= 4) {
        level_4_container = getContainerToBeChanged(
          level_3_container,
          level4ContainerId
        );

        level_4_container_index = getContainertoBeChangedIndex(
          level_3_container,
          level4ContainerId
        );
        if (level_4_container_index === -1) {
          console.log('level_4_container_index===-1');
          return;
        }
      }

      if (level >= 5) {
        level_5_container = getContainerToBeChanged(
          level_4_container,
          level5ContainerId
        );

        level_5_container_index = getContainertoBeChangedIndex(
          level_4_container,
          level5ContainerId
        );

        if (level_5_container_index === -1) {
          console.log('level_5_container_index===-1');
          return;
        }
      }

      if (level >= 6) {
        level_6_container = getContainerToBeChanged(
          level_5_container,
          level6ContainerId
        );

        level_6_container_index = getContainertoBeChangedIndex(
          level_5_container,
          level6ContainerId
        );

        if (level_6_container_index === -1) {
          console.log('level_6_container_index===-1');
          return;
        }
      }

      if (level >= 7) {
        level_7_container = getContainerToBeChanged(
          level_6_container,
          level7ContainerId
        );

        level_7_container_index = getContainertoBeChangedIndex(
          level_6_container,
          level7ContainerId
        );

        if (level_7_container_index === -1) {
          console.log('level_7_container_index===-1');
          return;
        }
      }

      if (level >= 8) {
        level_8_container = getContainerToBeChanged(
          level_7_container,
          level8ContainerId
        );

        level_8_container_index = getContainertoBeChangedIndex(
          level_7_container,
          level8ContainerId
        );

        if (level_8_container_index === -1) {
          console.log('level_8_container_index===-1');
          return;
        }
      }

      if (level >= 9) {
        level_9_container = getContainerToBeChanged(
          level_8_container,
          level9ContainerId
        );

        level_9_container_index = getContainertoBeChangedIndex(
          level_8_container,
          level9ContainerId
        );

        if (level_9_container_index === -1) {
          console.log('level_9_container_index===-1');
          return;
        }
      }

      if (level >= 10) {
        level_10_container = getContainerToBeChanged(
          level_9_container,
          level10ContainerId
        );

        level_10_container_index = getContainertoBeChangedIndex(
          level_9_container,
          level10ContainerId
        );

        if (level_10_container_index === -1) {
          console.log('level_10_container_index===-1');
          return;
        }
      }

      let containerToEdit;

      switch (level) {
        case 1:
          containerToEdit = level_1_container;
          break;
        case 2:
          containerToEdit = level_2_container;
          break;
        case 3:
          containerToEdit = level_3_container;
          break;
        case 4:
          containerToEdit = level_4_container;
          break;
        case 5:
          containerToEdit = level_5_container;
          break;
        case 6:
          containerToEdit = level_6_container;
          break;
        case 7:
          containerToEdit = level_7_container;
          break;
        case 8:
          containerToEdit = level_8_container;
          break;
        case 9:
          containerToEdit = level_9_container;
          break;
        case 10:
          containerToEdit = level_10_container;
          break;
      }

      const duplicates = containerToEdit.nexusDocuments.filter((obj) => {
        return obj._id === preparedDocument._id;
      });

      if (duplicates.length === 0) {
        containerToEdit.nexusDocuments.push(preparedDocument);
      } else {
        return;
      }

      switch (documentType) {
        case 'folder':
          snackbarMessage = 'New Folder Created';
          break;
        case 'task':
          snackbarMessage = 'New Task Created';
          break;
        case 'subTask':
          snackbarMessage = 'New subtask Created';
          break;
        case 'taxonomy':
          snackbarMessage = 'New Task Created';
          break;
        default:
          snackbarMessage = 'New document';
      }

      if (level >= 10) {
        level_9_container.nexusDocuments.splice(
          level_10_container_index,
          1,
          level_10_container
        );
      }

      if (level >= 9) {
        level_8_container.nexusDocuments.splice(
          level_9_container_index,
          1,
          level_9_container
        );
      }

      if (level >= 8) {
        level_7_container.nexusDocuments.splice(
          level_8_container_index,
          1,
          level_8_container
        );
      }

      if (level >= 7) {
        level_6_container.nexusDocuments.splice(
          level_7_container_index,
          1,
          level_7_container
        );
      }

      if (level >= 6) {
        level_5_container.nexusDocuments.splice(
          level_6_container_index,
          1,
          level_6_container
        );
      }

      if (level >= 5) {
        level_4_container.nexusDocuments.splice(
          level_5_container_index,
          1,
          level_5_container
        );
      }

      if (level >= 4) {
        level_3_container.nexusDocuments.splice(
          level_4_container_index,
          1,
          level_4_container
        );
      }

      if (level >= 3) {
        level_2_container.nexusDocuments.splice(
          level_3_container_index,
          1,
          level_3_container
        );
      }
      if (level >= 2) {
        level_1_container.nexusDocuments.splice(
          level_2_container_index,
          1,
          level_2_container
        );
      }

      // const copyOfData = { ...data, accounts: accounts };

      // const copyOfData = { ...data };

      if (level >= 1) {
        data.accounts.splice(level_1_container_index, 1, level_1_container);
        // }
      }

      // ################### test ########################

      // const copyOfData = { ...data, accounts: accounts };

      // console.log('documentAdded>copyOfData: ', copyOfData);

      // client.writeQuery({
      //   query: tableDataQuery2,
      //   data: copyOfData,
      //   variables
      // });

      // ###################### test ######################

      // setRerender(rerender + 1);

      // // ##################################

      const addToParentChildRows = (rowToAdd, rowToEdit) => {
        const parentRowIndex = copyOfListOfData.findIndex((row) => {
          return (
            row._id ===
            rowToEdit[
              'level_' + (rowToEdit.level - 1).toString() + '_container_id'
            ]
          );
        });

        const parentRow = copyOfListOfData.filter((row) => {
          return (
            row._id ===
            rowToEdit[
              'level_' + (rowToEdit.level - 1).toString() + '_container_id'
            ]
          );
        })[0];

        // console.log('RoiTable123>parentRow: ', parentRow);
        // console.log('RoiTable123>newRow: ', rowToAdd);
        if (parentRow.ganttBarDisplaySetting === 'CHILDREN') {
          // const childIndex = parentRow.childRows.findIndex(
          //   (childRow) => childRow._id === updatedRow._id
          // );
          parentRow.childRows.push(rowToAdd);

          addToParentChildRows(rowToAdd, parentRow);

          // console.log('RoiTable123>childIndex: ', childIndex);

          // updateParentChildRows(updatedRow, parentRow);
        }
      };
      const copyOfListOfData = [...listOfData];
      // console.log('copyOfListOfData: ', copyOfListOfData);

      // console.log('preparedDocument: ', preparedDocument);

      const spreadDoc = rowBaseKV(preparedDocument, documentType, '');

      switch (documentType) {
        case 'folder':
        case 'manualDataEntry':
        case 'customForm':
          // case 'googleAds':
          const newRow = {
            ...spreadDoc,
            // ...rowBaseKVs,
            isSet: preparedDocument.isSet,
            template: preparedDocument.template,
            checkedAt: preparedDocument.checkedAt,
            personal: preparedDocument.personal,
            fields: preparedDocument.fields,
            _id: preparedDocument._id,
            childDocumentIds: [],
            name: preparedDocument.name,
            status: preparedDocument.status,
            statusUpdatedAt: preparedDocument.statusUpdatedAt,
            type: documentType,
            level: level + 1,
            accountAccessLevel: 1,
            accountId: level1ContainerId,
            level_1_container_id: level1ContainerId,
            level_2_container_id:
              level + 1 === 2 ? preparedDocument._id : level2ContainerId,
            level_3_container_id:
              level + 1 === 3 ? preparedDocument._id : level3ContainerId,
            level_4_container_id:
              level + 1 === 4 ? preparedDocument._id : level4ContainerId,
            level_5_container_id:
              level + 1 === 5 ? preparedDocument._id : level5ContainerId,
            level_6_container_id:
              level + 1 === 6 ? preparedDocument._id : level6ContainerId,
            level_7_container_id:
              level + 1 === 7 ? preparedDocument._id : level7ContainerId,
            level_8_container_id:
              level + 1 === 8 ? preparedDocument._id : level8ContainerId,
            level_9_container_id:
              level + 1 === 9 ? preparedDocument._id : level9ContainerId,
            level_10_container_id:
              level + 1 === 10 ? preparedDocument._id : level10ContainerId,
            warnings: [],
            subWarnings: [],
            snackbarWarnings: [],
            childRows: [],
            documentType,
            members: preparedDocument.members,
            labels: [],
            totalData: [],
            total_list_of_propagation_data: [],
            total_list_of_propagation_data_row: [],
            style: preparedDocument.style,
            path: parentFolder[0].path + preparedDocument.name + ' / ',
            // interactivePath: [...parentFolder[0].path, {name: preparedDocument.name, _id: preparedDocument.name}]
            interactivePath: [
              ...parentFolder[0].interactivePath,
              { _id: preparedDocument._id, name: preparedDocument.name }
            ],
            plannedGrossFormula: preparedDocument.plannedGross,
            plannedNetFormula: preparedDocument.plannedNet,
            plannedNetNetFormula: preparedDocument.plannedNetNet,
            plannedCtcFormula: preparedDocument.plannedCtc,

            plannedTrpFormula: preparedDocument.plannedTrp,
            plannedCppFormula: preparedDocument.plannedCpp,
            plannedTechFeeFormula: preparedDocument.plannedTechFee,
            plannedAdOpsFeeFormula: preparedDocument.plannedAdOpsFee,
            plannedAdServingCostFormula: preparedDocument.plannedAdServingCost,
            plannedFeePercentFormula: preparedDocument.plannedFeePercent,

            budgetFormula: preparedDocument.budget,
            costFormula: preparedDocument.cost,
            impressionsFormula: preparedDocument.impressions,
            viewableImpressionsFormula: preparedDocument.viewableImpressions,
            measurableImpressionsFormula:
              preparedDocument.measurableImpressions,
            eligibleImpressionsFormula: preparedDocument.eligibleImpressions,

            clicksFormula: preparedDocument.clicks
          };

          copyOfListOfData.push(newRow);
          addToParentChildRows(newRow, newRow);
          setListOfData(copyOfListOfData);
          break;
        default:
          setUpdateAccountId(level1ContainerId);
          setRerender(rerender + 1);
      }

      // // ##################################

      enqueueSnackbar(snackbarMessage, {
        variant: snackbarVariant
      });
    } catch (error) {}
  };

  const [subscriptionCallStack, setSubscriptionCallStack] = useState([]);

  if (['system_admin', 'debug'].includes(user.role)) {
    console.log(
      'debug>RoiTable>row 989>subscriptionCallStack: ',
      subscriptionCallStack
    );
  }
  // useEffect(() => {
  //   if (!subscriptionSkip) {
  //     // console.log('subscriptionSkip: ', subscriptionSkip);
  //     console.log('documentUpdated>variables: ', {
  //       documentUpdatedInput: {
  //         currentAccountIds: () => currentAccounts.map((obj) => obj._id),
  //         selectedDateStart,
  //         selectedDateStop,
  //         dataLevel,
  //         subscriptionUpdatesFrozen,
  //         isAuthenticated,
  //         subscriptionSkip
  //       }
  //     });
  //   }
  // }, [subscriptionSkip]);

  const {
    data: documentUpdatedData,
    loading: documentUpdatedLoading,
    error: documentUpdatedError
  } = useSubscription(documentBasicUpdatedSubscription, {
    variables: {
      documentUpdatedInput: {
        currentAccountIds: currentAccountIds,
        selectedDateStart,
        selectedDateStop,
        dataLevel,
        subscriptionUpdatesFrozen
      }
    }, //currentAccountIds, selectedDateStart, selectedDateStop.
    shouldResubscribe: true,
    // skip: currentAccountIds.length > 0,
    // skip: subscriptionSkip,
    fetchPolicy: 'no-cache',
    // currentManagerAccount?._id && currentAccountIds.length > 0 ? false : true,
    onSubscriptionData: async ({ client, subscriptionData }) => {
      if (['system_admin', 'debug'].includes(user.role)) {
        console.log(
          'debug>RoiTable>row 898>documentUpdatedSubscription>subscriptionData: ',
          subscriptionData
        );
      }

      if (subscriptionUpdatesFrozen) {
        return;
      }

      setSubscriptionCallStack([
        ...subscriptionCallStack,
        { subscriptionType: 'documentUpdatedSubscription', subscriptionData }
      ]);
    }
  });

  const [doneCalculating, toggleDoneCalculating] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    // client

    if (!loading && subscriptionCallStack.length > 0) {
      // loop changes

      for (let i = 0; i < subscriptionCallStack.length; i++) {
        // if (doneCalculating) {
        switch (subscriptionCallStack[i].subscriptionType) {
          case 'documentAddedSubscription':
            documentAdded(subscriptionCallStack[i].subscriptionData);
            break;
          case 'documentUpdatedSubscription':
            documentUpdated(subscriptionCallStack[i].subscriptionData);
            break;
        }

        const copyOfSubscriptionCallStack = [...subscriptionCallStack];
        copyOfSubscriptionCallStack.splice(i, 1);
        setSubscriptionCallStack(copyOfSubscriptionCallStack);
        // }
      }
    }
  }, [loading, subscriptionCallStack, doneCalculating]);

  const documentUpdated = async (subscriptionData) => {
    try {
      // let tableDataBeforeUpdate = [...tableData];
      // let updatedTableData = [...tableData];

      // const documentUpdatedData = subscriptionData.data.documentUpdated;

      const {
        accountId,
        // level,
        documentId,
        // newDocument,
        // documentType,

        updateKey,
        updateKeys,
        updatedNexusDocument,
        action
      } = subscriptionData.data.documentUpdated;

      if (action === 'show_new_version_available') {
        enqueueSnackbar(
          'Please reload the page. Data out of sync. A new version is available.',
          {
            variant: 'error',
            persist: true
          }
        );
      }

      const copyOfListOfData = [...listOfData];
      const updatedRowIndex = copyOfListOfData.findIndex(
        (obj) => obj._id === updatedNexusDocument._id
      );

      const rowToBeChanged = copyOfListOfData[updatedRowIndex];
      const copyOfRowToBeChanged = { ...rowToBeChanged };

      for (let key of updateKeys) {
        switch (true) {
          case ['name', 'status', 'startDate', 'endDate', 'members'].includes(
            key
          ):
            copyOfRowToBeChanged[key] = updatedNexusDocument[key];
            break;
          case key === 'checkedAt':
            //calc days
            break;
        }
      }

      if (
        updateKeys.includes('status') &&
        updatedNexusDocument.status === 'REMOVED' &&
        statusFilter === 'All but removed'
      ) {
        copyOfListOfData.splice(updatedRowIndex, 1);
      } else {
        copyOfListOfData.splice(updatedRowIndex, 1, copyOfRowToBeChanged);
      }

      setListOfData(copyOfListOfData);
      enqueueSnackbar(
        'Value updated. Remember that this value might not show up in this cached view.',
        {
          variant: 'success'
        }
      );
      setRerender(rerender + 1);
      return '';
    } catch (error) {}
  };

  // #################################################################
  // #################################################################
  // #################################################################

  const differenceInDays = (start, stop) => {
    var date1 = new Date(start);
    var date2 = new Date(stop);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days;
  };

  const differenceInDaysFloor = (start, stop) => {
    // var date1 = new Date(start);
    // var date2 = new Date(stop);
    // var Difference_In_Time = date2.getTime() - date1.getTime();
    // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(start);
    const secondDate = new Date(stop);

    const diffDays = Math.floor((secondDate - firstDate) / oneDay);

    return diffDays;
  };

  const pace = (item, start, stop, budget, totalCost) => {
    var date1 = new Date(start);
    var date2 = new Date(stop);
    date2.setHours(23, 59, 59, 0);
    var total_difference_in_time = date2.getTime() - date1.getTime();
    var totalNumberOfDays = total_difference_in_time / (1000 * 3600 * 24);

    var date1_ = new Date(start);
    var date2_ = new Date();

    if (['cm360', 'dv360'].includes(item.documentType)) {
      date2_.setDate(date2_.getDate() - 1);
    }
    var time_since_start = date2_.getTime() - date1_.getTime();
    var days_since_start = time_since_start / (1000 * 3600 * 24);

    const unitBudget = budget / total_difference_in_time;

    const should_have_spent =
      unitBudget *
      (time_since_start >= total_difference_in_time
        ? total_difference_in_time
        : time_since_start);
    const pace = totalCost / should_have_spent;

    return Math.round(pace * 100);
    // return Math.round((pace * 100 + Number.EPSILON) * 100) / 100;
  };

  const numberOfDaysLeft = (today, endDate) => {
    const numDaysLeft =
      differenceInDays(new Date().toISOString().split('T')[0], endDate) + 1;
    return numDaysLeft > 0 ? numDaysLeft : 0;
  };

  const calcDailyBudget = (
    budget,
    totalCost,
    // numberOfDays,
    numberOfDaysLeft
  ) => {
    if (!budget || budget <= 0) {
      return '';
    }

    const cost = totalCost ? totalCost : null;
    // const daysLeft =
    //   numberOfDaysLeft > numberOfDays ? numberOfDays : numberOfDaysLeft;

    if (numberOfDaysLeft <= 0) {
      return '';
    }
    const dailyBudgetLeft = (budget - cost) / numberOfDaysLeft;

    return dailyBudgetLeft;
  };

  // ######################################## checkboxes ########################################################

  // const [checkedBoxes, setCheckedBoxes] = useState([]);

  useEffect(() => {
    setCheckedBoxes([]);
  }, [currentAccountIds]);

  // ######################################### v2 ################################################################

  // const [listOfData, setListOfData] = useState([]);

  function unique(arr) {
    var u = {},
      a = [];
    for (var i = 0, l = arr.length; i < l; ++i) {
      if (!u.hasOwnProperty(arr[i])) {
        a.push(arr[i]);
        u[arr[i]] = 1;
      }
    }
    return a;
  }
  const [dataSourceNames, setDataSourceNames] = useState([]);

  useEffect(() => {
    // names
    const allNames = listOfData.map((row) => row.name);
    const uniqueNames = unique(allNames);
    setNames(uniqueNames);

    //dataSourceNames

    // const allDataSourceNames = listOfData.map(row=>{row.name});

    var reduced = listOfData.reduce(function (filtered, row) {
      if (
        [
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'microsoft_invest_insertion_order',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group'
        ].includes(row.type)
      ) {
        filtered.push(row.name);
      }
      return filtered;
    }, []);

    const uniqueDataSourceNames = unique(reduced);
    setDataSourceNames(uniqueDataSourceNames);
  }, [listOfData]);

  // let total_list_of_propagation_data = [];

  // ###################################################################################################

  // #################################################################################################

  // #############################################################

  // const classes = useStyles();

  const [openSimpleModal, setOpenSimpleModal] = React.useState(false);

  const [right, setRight] = React.useState([
    // 'type',
    // 'name',
    'warnings',
    'status',
    // 'notifications',
    'path',
    'comments',
    'members',
    'labels',
    'startDate',
    'endDate',
    'numberOfDays',
    // 'plannedGross',
    // 'plannedNet',
    // 'plannedNetNet',
    // 'plannedCtc',
    // 'plannedTrp',
    // 'plannedTechFee',
    // 'plannedAdOpsFee',
    // 'plannedAdServingCost',
    // 'plannedFeePercent',
    'generalDescription',
    // "buyingTypeDescription",
    // "creativeDescription",
    // "formatDescription",
    // "channelDescription",
    // "audienceDataDescription",
    // 'targetGroupDescription',
    // 'placementDescription',
    'goalDescription',
    'budget',
    'budgetCurrency',
    'calcDailyBudget',
    'cost',
    // 'costYesterday',
    'pace',
    // 'pace_bar',
    // 'trp',
    'impressions',
    'viewableImpressions',
    'measurableImpressions',
    'eligibleImpressions',
    'videoCompletions',
    'cpm',
    'vCpm',
    'clicks',
    'ctr',
    'viewableRate',
    'vcr',
    'searchImpressionShare',
    'cpcv',
    'cpc',
    'action01',
    'action01Count',
    'action01Cvr',
    'action01Cpa',
    'action01Value',
    'action01Roas',
    'action01Vpa',

    // 'action02',
    // 'action02Count',
    // 'action02Cvr',
    // 'action02Cpa',
    // 'action02Value',
    // 'action02Roas',
    // 'action02Vpa',

    // 'action03',
    // 'action03Count',
    // 'action03Cvr',
    // 'action03Cpa',
    // 'action03Value',
    // 'action03Roas',
    // 'action03Vpa',

    'ganttBarColor',
    'gantt'
  ]);

  const [left, setLeft] = React.useState(
    allColumns.filter((col) => !right.includes(col)).sort()
  );

  const onClickRefreshHandler = () => {
    console.log('refetch');
    refetch();
  };

  const onClickFreezeHandler = () => {
    console.log('refetch');

    if (subscriptionUpdatesFrozen) {
      enqueueSnackbar('Unfreeze and refetching data', {
        variant: 'default'
        // persist: true
      });
      console.log('unfreeze andrefetch');
      refetch();
    } else {
      enqueueSnackbar('Live updates frozen', {
        variant: 'default'
        // persist: true
      });
    }
    setSubscriptionUpdatesFrozen(!subscriptionUpdatesFrozen);
  };

  const onClickEditHandler = (e) => {
    setEditDialogOpen(true);
  };

  // if (!data) {
  //   return (
  //     <div
  //       style={{ position: 'absolut', textAlign: 'center', marginTop: '15%' }}
  //     >
  //       <div style={{ padding: '50px' }}>
  //         <Spinner />
  //       </div>
  //       <span>Loading Data...</span>
  //     </div>
  //   );
  // }
  // if (!doneCalculating) {
  //   return (
  //     <div
  //       style={{ position: 'absolut', textAlign: 'center', marginTop: '15%' }}
  //     >
  //       <div style={{ padding: '50px' }}>
  //         <Spinner />
  //       </div>
  //       <span>Processing Data...</span>
  //     </div>
  //   );
  // }

  // if (!data || !doneCalculating) {
  //   setShowSpinner(true);
  // }

  // console.log('filterBySelection: ', filterBySelection);

  if (['system_admin', 'debug'].includes(user.role)) {
    console.log(
      'debug>RoiTable>row5901>listOfData.length: ',
      listOfData.length
    );
    console.log('debug>RoiTable>row 5953>listOfData: ', listOfData);
  }

  return (
    <div
      style={{
        padding: '0 15px 0 15px'
      }}
    >
      <div
        // className="underlined"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',

          borderRadius: '5px'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gridColumnStart: 1,
              gridColumnEnd: 1,
              // backgroundColor: 'blue',
              padding: '1rem 0px',
              marginRight: '15px'
            }}
          >
            <QuickCreate
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              addDocument={addDocument}
              checkedBoxes={checkedBoxes}
              currentManagerAccountId={currentManagerAccount._id}
            />
          </div>
          {isAdvancedModeOn && (
            <>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 2,
                  gridColumnEnd: 2,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  // marginLeft: '15px',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <EditMenu
                  setEditDialogOpen={setEditDialogOpen}
                  copiedDocumentIds={copiedDocumentIds}
                  setCopiedDocumentIds={setCopiedDocumentIds}
                  copiedDocumentsAccountId={copiedDocumentsAccountId}
                  setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
                  enqueueSnackbar={enqueueSnackbar}
                  checkedBoxes={checkedBoxes}
                  selectedDateStart={selectedDateStart}
                  selectedDateStop={selectedDateStop}
                />
                {/* <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: 0,
                    minWidth: 0
                    // backgroundColor: '#3e8ed0'
                  }}
                  onClick={onClickEditHandler}
                >
                  <EditIcon
                  // style={{ color: '#FFFFFF' }}
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  />
                </Button> */}

                {editDialogOpen && (
                  <EditDialog
                    checkedBoxes={checkedBoxes}
                    currentManagerAccountId={currentManagerAccount._id}
                    // open={editModalOpen}
                    // setOpen={(e) => setEditModalOpen(e)}
                  />
                )}

                {copyDialogOpen && (
                  <CopyDialog
                    checkedBoxes={checkedBoxes}
                    currentManagerAccountId={currentManagerAccount._id}
                    copiedDocumentIds={copiedDocumentIds}
                    setCopiedDocumentIds={setCopiedDocumentIds}
                    copiedDocumentsAccountId={copiedDocumentsAccountId}
                    setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
                    // open={editModalOpen}
                    // setOpen={(e) => setEditModalOpen(e)}
                  />
                )}
              </div>

              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 2,
                  gridColumnEnd: 2,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  // marginLeft: '15px',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: '36px',
                    minWidth: 0,
                    cursor: 'pointer'
                    // backgroundColor: '#3e8ed0'
                  }}
                  onClick={() => setEmailPokeDialogOpen(true)}
                >
                  <i
                    style={{ fontSize: '20px' }}
                    className="fas fa-hand-point-right"
                  ></i>
                  {/* <EmailIcon
                  // style={{ color: '#FFFFFF' }}
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  /> */}
                </Button>

                {emailPokeDialogOpen && (
                  <EmailPokeDialog
                    checkedBoxes={checkedBoxes}
                    currentManagerAccountId={currentManagerAccount._id}
                    // open={editModalOpen}
                    // setOpen={(e) => setEditModalOpen(e)}
                  />
                )}
              </div>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  gridColumnStart: 3,
                  gridColumnEnd: 3,
                  // backgroundColor: 'blue',
                  textAlign: 'left',
                  padding: '1rem 0px'
                }}
              >
                <StatusFilterMenu
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  gridColumnStart: 4,
                  gridColumnEnd: 4,
                  // backgroundColor: 'blue',
                  textAlign: 'left',
                  padding: '1rem 0px'
                }}
              >
                <DisplayMenu view="gantt" />
              </div>
            </>
          )}{' '}
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gridColumnStart: 5,
              gridColumnEnd: 5,
              // backgroundColor: 'blue',
              textAlign: 'left',
              padding: '1rem 0px'
            }}
          >
            <FilterMenu
              setFilterBySelection={setFilterBySelection}
              checkedBoxes={checkedBoxes}
              setCheckedBoxes={setCheckedBoxes}
              setFilters={setFilters}
              filters={filters}
              enqueueSnackbar={enqueueSnackbar}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gridColumnStart: 6,
              gridColumnEnd: 6,
              // backgroundColor: 'blue',
              textAlign: 'left',
              padding: '0.91rem',
              justifyContent: 'left'
            }}
          >
            <FilterComponent
              setCheckedBoxes={setCheckedBoxes}
              // setFilters={setFilters}
              // setFilterBySelection={setFilterBySelection}
              // setFilterByDataSource={setFilterByDataSource}
              // filterByDataSource={filterByDataSource}
              names={names}
              dataSourceNames={dataSourceNames}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {isAdvancedModeOn && (
            <div
              style={{
                display: 'grid',
                //   border: '1px solid rgba(200, 200, 200, 0.5)',
                //   height: '53px',
                borderRadius: '5px',
                // width: '200px',
                gridColumnStart: 8,
                gridColumnEnd: 8,

                textAlign: 'right',
                // padding: '1.4rem 1rem 1rem 1rem',
                marginTop: 'auto',
                marginBottom: 'auto',
                justifyContent: 'center'
                // backgroundColor: 'blue'
              }}
            >
              <DataLevelSwitch
                dataLevel={dataLevel}
                setDataLevel={setDataLevel}
                currentAccountIds={currentAccountIds}
              />
            </div>
          )}
          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 9,
              gridColumnEnd: 9,

              textAlign: 'right',
              // padding: '1.4rem 1rem 1rem 1rem',
              marginTop: 'auto',
              marginBottom: 'auto',
              justifyContent: 'center'
              // backgroundColor: 'blue'
            }}
          >
            {/* uncomment */}
            <ExportMenu
              className="hover-grey"
              columns={right}
              arrayOfData={arrayOfData}
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              summaryRow={summaryRow}
              setSummaryRow={setSummaryRow}
              range2={range2}
              // style={{ cursor: 'pointer' }}
              // onClick={onClickRefreshHandler}
            />
          </div>
          {isAdvancedModeOn && (
            <>
              <div
                style={{
                  display: 'grid',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 10,
                  gridColumnEnd: 10,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: 0,
                    minWidth: 0,
                    backgroundColor: subscriptionUpdatesFrozen
                      ? '#3e8ed0'
                      : null
                  }}
                  onClick={onClickFreezeHandler}
                >
                  <AcUnitIcon
                    style={{
                      color: subscriptionUpdatesFrozen ? '#FFFFFF' : null
                    }}
                    // className="hover-grey"
                    // style={{ cursor: 'pointer' }}
                    // onClick={onClickRefreshHandler}
                  />
                </Button>
              </div>
              <div
                style={{
                  display: 'grid',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 11,
                  gridColumnEnd: 11,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: 0,
                    minWidth: 0
                  }}
                  onClick={onClickRefreshHandler}
                >
                  <RefreshRoundedIcon
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  />
                </Button>
              </div>
            </>
          )}

          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 12,
              gridColumnEnd: 12,

              textAlign: 'right',
              // padding: '1rem',
              marginTop: 'auto',
              marginBottom: 'auto'
              // backgroundColor: 'blue'
            }}
          >
            {/* uncomment */}
            <ColumnMenu
              currentManagerAccountId={currentManagerAccount._id}
              left={left}
              setLeft={setLeft}
              right={right}
              setRight={setRight}
              setOpenSimpleModal={setOpenSimpleModal}
              user={user}
              // columnData={columnData}
            />
            {columnDialogOpen && <ColumnDialog />}

            {/* uncomment */}
            {/* <DraggableDialog
            currentManagerAccountId={currentManagerAccount._id}
            open={openSimpleModal}
            setOpen={setOpenSimpleModal}
            style={{ zIndex: '2000' }}
            left={left}
            setLeft={setLeft}
            right={right}
            setRight={setRight}
          /> */}
          </div>
          {/* <div
          style={{
            display: 'grid',
            //   border: '1px solid rgba(200, 200, 200, 0.5)',
            //   height: '53px',
            borderRadius: '5px',
            // width: '200px',
            gridColumnStart: 22,
            gridColumnEnd: 23,

            textAlign: 'right',
            // padding: '1rem',
            marginTop: 'auto',
            marginBottom: 'auto'
            // backgroundColor: 'blue'
          }}
        >
          <DatePicker
            stylesStoredInRedux={{ stylesStoredInRedux }}
            selectedDate={selectedDateStart}
            handleDateChange={handleDateStartChange}
          />
        </div> */}
          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 13,
              gridColumnEnd: 13,

              textAlign: 'center',
              // padding: '1rem',
              marginTop: 'auto',
              marginBottom: 'auto'
              // backgroundColor: 'blue'
            }}
          >
            {/* <ReactDateRange
              stylesStoredInRedux={stylesStoredInRedux}
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              setSelectedDateStart={setSelectedDateStart}
              setSelectedDateStop={setSelectedDateStop}
              currentAccountIds={currentAccountIds}
              enqueueSnackbar={enqueueSnackbar}
              setShowSpinner={setShowSpinner}
            /> */}
            <div
              style={{
                height: '36.5px',
                width: '200px',
                fontSize: '14px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <span>
                {selectedDateStart} - {selectedDateStop}
              </span>
            </div>
            {/* <DatePicker
            stylesStoredInRedux={{ stylesStoredInRedux }}
            selectedDate={selectedDateStop}
            handleDateChange={handleDateStopChange}
          /> */}
          </div>
        </div>
      </div>

      {/* uncomment */}

      <Table
        aggregatedMode={false}
        dataLevel={dataLevel}
        summaryRow={summaryRow}
        totalListOfPropagationData={totalListOfPropagationData}
        totalListOfPropagationBasicData={totalListOfPropagationBasicData}
        setSummaryRow={setSummaryRow}
        arrayOfData={arrayOfData}
        setArrayOfData={setArrayOfData}
        visColumns={visColumns}
        setVisColumns={setVisColumns}
        enqueueSnackbar={enqueueSnackbar}
        // filterBySelection={filterBySelection}
        // setFilterBySelection={setFilterBySelection}
        addDocument={addDocument}
        updateDocument={updateDocument}
        currentManagerAccountId={currentManagerAccount._id}
        // changeTableData={changeTableData}
        stylesStoredInRedux={stylesStoredInRedux}
        // openRows={openRows}
        // setOpenRows={setOpenRows}
        range={range}
        range2={range2}
        rawArrayOfData={listOfData}
        columns={right}
        setColumns={setRight}
        selectedDateStart={selectedDateStart}
        selectedDateStop={selectedDateStop}
        // checkedBoxes={checkedBoxes}
        // setCheckedBoxes={setCheckedBoxes}
        // filters={filters}
        // setFilters={setFilters}
        // filterByMemberId={filterByMemberId}
        // filterByName={filterByName}
        // filterByDataSourceName={filterByDataSourceName}
        // filterByDataSource={filterByDataSource}
        refetch={refetch}
        setSubscriptionCallStack={setSubscriptionCallStack}
        currentAccountIds={currentAccountIds}
        names={names}
        dataSourceNames={dataSourceNames}
        subscriptionSkip={subscriptionSkip}
        copiedDocumentIds={copiedDocumentIds}
        setCopiedDocumentIds={setCopiedDocumentIds}
        copiedDocumentsAccountId={copiedDocumentsAccountId}
        setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
      />
      {/* <AlertDialog /> */}
      {(loading || showSpinner) && (
        <div
          style={{
            display: 'block',
            position: 'fixed',
            // backgroundColor: 'red',
            height: '100px',
            width: '100px',
            zIndex: 3000,
            bottom: 0,
            right: '30px'
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

RoiTable.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired,
  // style: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(RoiTable);
