import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { AccountContext } from '../../../../../context/AccountContext';

const getMeta = (url, cb) => {
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};

function LogoWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  const theme = useTheme();

  const logoUrl =
    currentManagerAccount?.logo ||
    'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

  // console.log('logoUrl: ', logoUrl);

  // console.log('testlogo: ', widget);
  useEffect(() => {
    let copyOfWidget = { ...widget };
    getMeta(logoUrl, (err, img) => {
      copyOfWidget = {
        ...copyOfWidget,
        width:
          Math.round(
            (copyOfWidget.height.split('px')[1] || 60) *
              (img.naturalWidth / img.naturalHeight)
          ) + 'px',

        imageNaturalHeight: img.naturalHeight,
        imageNaturalWidth: img.naturalWidth,
        imageNaturalAspectRatio: img.naturalWidth / img.naturalHeight
      };

      updateWidget(copyOfWidget, widgetIndex);
    });

    // copyOfWidget = {
    //   ...copyOfWidget,
    //   imageUrl: binaryStr
    // };
  }, []);

  return (
    <div
      onMouseUpHandler={onMouseUpHandler}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        // onDragEnter={handleDrag}
        // onDrop={(e) => onDropHandler(e)}
        draggable={false}
        style={{
          height: '100%',
          width: '100%',
          backgroundSize: 'contain',
          // border-radius: 50%;
          // border: solid 1px #e6e6e6;
          margin: '0 auto',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          // img: {
          //   height: '100%',
          //   width: '100%'
          // }
          height: '100%',
          backgroundImage: `url(${widget.imageDataUri || widget.imageUrl})`,
          backgroundRepeat: 'no-repeat'
        }}
      ></div>
      {/* <img
        draggable={false}
        src={logoUrl}
        alt="Logo"
        style={{
          width: '100%'
          // alignSelf: 'baseline',
          // margin: '0px 3px',
          // marginTop: '12px'
        }}
      /> */}
    </div>
  );
}

export default React.memo(LogoWidget);
