import React, { useEffect, useState } from 'react';
// import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import DoneIcon from '@mui/icons-material/Done';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

import { managerAccountUsersQuery } from './../../../graphql/queries';

import { useQuery } from '@apollo/client';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'center',
//     flexWrap: 'wrap',
//     '& > *': {
//       margin: theme.spacing(0.5)
//     }
//   }
// }));

export default function Chips({
  setFilters,
  setCheckedBoxes,
  currentManagerAccountId,
  user,
  filters,
  filterByString,
  setFilterByString,
  filter,
  arrayOfOptions,
  name
}) {
  // const classes = useStyles();

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
    // setFilterBySelection([]);

    const filteredFilter = filters.filter((filter_) => {
      return filter_ !== filter;
    });
    setFilters(filteredFilter);
    setFilterByString('');
    setCheckedBoxes([]);
  };

  const variables = {
    managerAccountUsersInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId
      //   accountId
    }
  };

  // ############################ autocomplete #####################################

  const [showAutoComplete, toggleShowAutoComplete] = useState(false);

  // let managerAccountUsers;

  const [managerAccountUsers, setManagerAccountUsers] = useState([]);

  const [value, setValue] = React.useState('');

  // const defaultProps = {
  //   options: arrayOfOptions,
  //   getOptionLabel: (option) => option
  // };

  const onChangeHandler = (event, newValue) => {
    setValue(newValue);
    setFilterByString(newValue);
  };

  const defaultProps = {
    options: arrayOfOptions,
    getOptionLabel: (option) => option
  };

  // const autoComplete = (
  //   <Autocomplete
  //     style={{ width: '200px' }}
  //     className="filter-by-team-member"
  //     id="free-solo-demo"
  //     freeSolo
  //     onChange={(event, newValue) => onChangeHandler(event, newValue)}
  //     value={value}
  //     options={arrayOfOptions}
  //     renderInput={(params) => (
  //       <TextField {...params} margin="normal" margin="none" />
  //     )}
  //   />
  // );

  const autoComplete = (
    <Stack spacing={0} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        {...defaultProps}
        id="controlled-demo"
        className="filter-by-team-member"
        value={value}
        onChange={(event, newValue) => {
          onChangeHandler(event, newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="controlled"
            variant="standard"
          />
        )}
      />
    </Stack>
  );
  // ##################################################################################

  return (
    <div
      // className={classes.root}
      style={{ gridRow: 1 }}
    >
      <Chip
        icon={
          <span style={{ padding: '0px 10px', fontSize: '15px' }}>{name} </span>
        }
        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
        // label="Me"
        label={autoComplete}
        onDelete={handleDelete}
        // label="Primary clickable"
        // clickable
        // deleteIcon={<DoneIcon />}
      />
    </div>
  );
}
