import {
  atan2,
  chain,
  derivative,
  e,
  evaluate,
  log,
  pi,
  pow,
  round,
  sqrt
} from 'mathjs';

const evaluateFormula = (formula, scope) => {
  if (formula && formula[0] === '=') {
    try {
      const formulaWithoutEqualSign = formula.slice(1) || '';
      return evaluate(formulaWithoutEqualSign, scope); // 12
    } catch (err) {
      return err.message;
    }
  } else {
    // return formula;
    try {
      const parsedValue = parseFloat(formula);
      if (parsedValue) {
        return parsedValue;
      } else {
        return formula;
      }
    } catch (error) {
      return formula;
    }
  }
};

export default evaluateFormula;
