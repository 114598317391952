const mongoose = require('mongoose');

const UserToAccountRelationSchema = new mongoose.Schema(
  {
    userId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    managerAccountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'ManagerAccount'
    },
    accountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'Account'
    },
    role: {
      type: String
    },
    permissions: [
      {
        type: String
      }
    ],
    accountAccessLevel: {
      type: Number
    },
    createdBy: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    isFavorite: Boolean
    // date: {
    //   type: Date,
    //   default: Date.now
    // }
  },
  { collection: 'usertoaccountrelations', timestamps: true }
);

UserToAccountRelationSchema.virtual('account', {
  ref: 'Account',
  localField: 'accountId',
  foreignField: '_id',
  justOne: true
});

// AccountUserAccessSchema.set('toObject', { virtuals: true });
// AccountUserAccessSchema.set('toJSON', { virtuals: true });

module.exports = UserToAccountRelation = mongoose.model(
  'usertoaccountrelation',
  UserToAccountRelationSchema
);
