import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100
//   }
// ];

function SimpleLineChart({ data, showMetricLine }) {
  //   static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  console.log('showMetricLine: ', showMetricLine);
  return (
    <div style={{ width: '100%', height: '600px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data || []}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          {/* <YAxis /> */}
          {showMetricLine?.filter((str) => str.includes('cost')).length && (
            <YAxis yAxisId="left" />
          )}
          {showMetricLine?.filter((str) => str.includes('impressions'))
            .length && <YAxis yAxisId="left2" orientation="left" />}
          {showMetricLine?.filter((str) => str.includes('cpm')).length && (
            <YAxis yAxisId="right" orientation="right" />
          )}
          {showMetricLine.filter((str) => str.includes('clicks')).length && (
            <YAxis yAxisId="right2" orientation="right" />
          )}
          {showMetricLine?.filter((str) => str.includes('cpc')).length && (
            <YAxis yAxisId="right3" orientation="right" />
          )}

          <Tooltip />
          <Legend />
          {showMetricLine.map((metricKey) => {
            switch (true) {
              case metricKey.includes('cost'):
                return (
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey={metricKey}
                    stroke="#a72683"
                    activeDot={{ r: 8 }}
                  />
                );
              case metricKey.includes('impressions'):
                return (
                  <Line
                    yAxisId="left2"
                    type="monotone"
                    dataKey={metricKey}
                    stroke="#a72683"
                    activeDot={{ r: 8 }}
                  />
                );
              case metricKey.includes('cpm'):
                return (
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey={metricKey}
                    stroke="#a72683"
                    activeDot={{ r: 8 }}
                  />
                );
              case metricKey.includes('clicks'):
                return (
                  <Line
                    yAxisId="right2"
                    type="monotone"
                    dataKey={metricKey}
                    stroke="#a72683"
                    activeDot={{ r: 8 }}
                  />
                );
              case metricKey.includes('cpc'):
                return (
                  <Line
                    yAxisId="right3"
                    type="monotone"
                    dataKey={metricKey}
                    stroke="#a72683"
                    activeDot={{ r: 8 }}
                  />
                );
            }
          })}

          {/* {showMetricLine?.includes('cost') && (
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="cost"
              stroke="#a72683"
              activeDot={{ r: 8 }}
            />
          )} */}
          {/* {showMetricLine?.includes('impressions') && (
            <Line
              yAxisId="left2"
              type="monotone"
              dataKey="impressions"
              stroke="#3150a1"
            />
          )}

          {showMetricLine?.includes('cpm') && (
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="cpm"
              stroke="#1784a6"
            />
          )}
          {showMetricLine?.includes('clicks') && (
            <Line
              yAxisId="right2"
              type="monotone"
              dataKey="clicks"
              stroke="#1784a6"
            />
          )}

          {showMetricLine?.includes('cpc') && (
            <Line
              yAxisId="right3"
              type="monotone"
              dataKey="cpc"
              stroke="#8a256e"
            />
          )} */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SimpleLineChart;
