import React, { Fragment, useContext, useEffect } from 'react';
import { Link, Redirect, useNavigate } from 'react-router-dom';
import { withRouter } from './../../hooks/withRouter';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ManagerAccountButton from './ManagerAccountButton';

import { AccountContext } from '../../context/AccountContext';

// import Footer from './Footer';

const ChooseManagerAccount = ({
  isAuthenticated,
  // managerAccount,
  history,
  // profile,
  stylesStoredInRedux
}) => {
  const navigate = useNavigate();
  const {
    managerAccounts,
    setCurrentManagerAccount,
    setCurrentManagerAccountId
  } = useContext(AccountContext);
  const onClickOnNavManagerAccountHandler = (e, managerAccount) => {
    setCurrentManagerAccount(managerAccount);
    setCurrentManagerAccountId(managerAccount._id);
    navigate(`/dashboard?manager_account_id=${managerAccount._id}`);
    // window.location.assign(`/dashboard?manager_account_id=${id}`);
    // history.push(`/dashboard?manager_account_id=${id}`);
    // window.location.reload(true);
    // return <Redirect push to="/dashboard" />;
  };

  useEffect(() => {
    if (managerAccounts?.length === 1) {
      setCurrentManagerAccount(managerAccounts[0]);
      setCurrentManagerAccountId(managerAccounts[0]?._id);
      navigate(`/dashboard?manager_account_id=${managerAccounts[0]?._id}`);
    }
  }, [managerAccounts]);

  // if (!profile.profile && !profile.loading) {
  //   return <Redirect to="/profile-dashboard" />;
  // }

  return (
    <Fragment>
      <div style={{ height: 'calc(100vh - 64px)', overflowY: 'scroll' }}>
        <div
          className="col-4"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: managerAccounts?.length < 6 && 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            height: 'calc(100% - 64px)',
            // height: '100%',
            // margin: '7% auto',
            marginTop: managerAccounts?.length && '50px',
            textAlign: 'center'
            // overflowY: 'scroll'
          }}
        >
          <h1 style={{ marginBottom: '10px' }}>Select Manager Account</h1>
          {managerAccounts.map((managerAccount) => (
            <Link
              key={managerAccount._id}
              to={`/dashboard?manager_account_id=${managerAccount._id}`}
            >
              <ManagerAccountButton
                key={managerAccount._id}
                managerAccount={managerAccount}
                onClickOnNavManagerAccountHandler={
                  onClickOnNavManagerAccountHandler
                }
                cardBackgroundImage={
                  stylesStoredInRedux.ManagerAccount.ChooseManagerAccount.card
                    .backgroundImage
                }
                color={
                  stylesStoredInRedux.ManagerAccount.ChooseManagerAccount.card
                    .color
                }
                text={managerAccount.name}
              />
            </Link>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

ChooseManagerAccount.propTypes = {
  isAuthenticated: PropTypes.bool,
  managerAccount: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  managerAccount: state.managerAccount,
  // profile: state.profile,
  stylesStoredInRedux: state.style
});

export default connect(mapStateToProps)(withRouter(ChooseManagerAccount));
