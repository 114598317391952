import React, { useContext, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';

import { UpdateDataSourceAlertRuleMutation } from '../../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../../context/AccountContext';

import { Button } from '@mui/material';

export default function FloatingActionButtons({
  toggleIsEditMode,
  dataSourceInfo,
  dataSourceAlertRule,
  dataSourceAlertRuleInput,
  setDataSourceAlertRuleInput,
  refetch
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const { enqueueSnackbar } = useSnackbar();

  const [updateRule, { called }] = useMutation(
    UpdateDataSourceAlertRuleMutation
  );

  const onClickSaveHandler = async () => {
    const input = {
      managerAccountId: currentManagerAccount._id,
      ...dataSourceAlertRuleInput
    };

    let res;
    try {
      res = await updateRule({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.updateDataSourceAlertRule?.response === 'Success') {
      enqueueSnackbar('Rule deleted', {
        variant: 'success'
      });
      toggleIsEditMode(false);
      refetch();
      // refetchDataSourceInfo();
    }
  };

  // console.log('input: ', input);
  return (
    <Button
      size="small"
      variant="contained"
      style={{
        fontSize: '12px',
        // visibility: isEditMode && 'hidden',
        width: '40px',
        maxWidth: '60px',
        minWidth: '60px',
        height: '30px',
        padding: '5px'
      }}
      onClick={() => onClickSaveHandler()}
    >
      Save
    </Button>
  );
}
