import React from 'react';
import Typography from '@mui/material/Typography';

const CONTACT_EMAIL = 'contact@nexustable.com';

function PrivacyPolicy() {
  return (
    <div
      style={{
        width: '100%',
        height: '8000px',
        minHeight: '1200px',
        backgroundColor: 'white'
      }}
    >
      <div style={{ height: '4%' }}></div>
      <div
        className="col-10"
        style={{
          maxWidth: '1200px',
          color: 'black',
          margin: '0 auto',
          padding: '5%',
          border: '1px solid #ebebeb',
          backgroundColor: '#ffffff',
          boxShadow:
            '0 0.25px 0.5px 0 rgba(0, 0, 0, 0.2), 0 0.38px 1.25px 0 rgba(0, 0, 0, 0.19)',
          textAlign: 'justify',
          textOverflow: 'scroll',
          height: '3000px'
          // overflow: 'scroll'
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontSize: '2rem',
            marginBottom: '1.5rem',
            marginTop: '1.5rem'
          }}
        >
          Privacy Policy
        </Typography>
        <ol style={{ fontSize: '1rem' }}>
          <li>
            <Typography paragraph>
              <strong>WHAT IS THIS POLICY?</strong>
              <br />
              We process personal data as part of our business and this policy
              explains how such processing is conducted. The service NexusTable
              is provided by RoiCraft AB (”RoiCraft” or “us”/”we”), We wish to
              safeguard the privacy of all the parties we establish contact with
              and all of our customer. All processing of personal data is done
              in accordance with this policy and in line with the requirements
              of the General Data Protection Regulation (“GDPR”). In case you
              have any questions regarding our processing of personal data, feel
              free to contact us at{' '}
              <a href={'mailto:' + CONTACT_EMAIL}>{CONTACT_EMAIL}</a>.
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              <strong>WHO IS THE CONTROLLER OF PERSONAL DATA?</strong>
              <br />
              The controller of personal data is RoiCraft AB, a company
              incorporated under the laws of Sweden having reg. no. 559100-5490
              and the address Swedenborgsgatan 25, 118 27 Stockholm, Sweden.
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              <strong>
                WHAT KIND OF PROCESSING IS CONDUCTED AND FOR HOW LONG?
              </strong>
              <br />
              Our processing of personal data can be summarised to pertain to
              the following situations.
            </Typography>
            <Typography paragraph>
              <u>To enable access to and maintain NexusTable:</u>
              <br />
              We collect data to maintain the security of and access to our
              service NexusTable. For instance, we use usernames and passwords
              to enable access including several digital identifiers to provide
              you with a smooth user experience. Furthermore, content uploaded
              by user, payment information, and logged data is kept in order to
              register the options used in the service. The data is kept for as
              long as the user is registered or subscribed to our service.
            </Typography>
            <Typography paragraph>
              <u>To conduct marketing and solicit customers:</u>
              <br />
              We collect data when we communicate with prospective customers.
              The data is used in order for us to market our services, analyse
              our marketing performance and otherwise establish business
              relationships with our customers. The concerned personal data
              relates to the following categories: Names, position held at a
              certain company, contact details, and similar information that is
              submitted to us. Contact details concerning our business partners
              and suppliers are processed for as long as the relationship with
              us persists. Prospective customer data is as a rule deleted after
              one year.
            </Typography>
            <Typography paragraph>
              <u>To administer our business:</u>
              <br />
              We collect data when we communicate with our customers and provide
              support to them. Furthermore, we collect data for the
              administration of invoices, for our bookkeeping, and to comply
              with other applicable legal requirements. The data is used in
              order for us to conduct our business and to comply with our legal
              obligations. The concerned personal data relates to the following
              categories: Names, position at a certain company, contact details,
              invoice information, and similar information that is submitted to
              us. The personal data is stored for as long as necessary and
              bookkeeping will be kept for seven years pursuant to Swedish law.
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              <strong>WHAT KIND OF LEGAL BASIS DO WE RELY ON?</strong>
              <br />
              According to the GDPR, we must rely our processing of personal
              data on at least one predetermined legal basis.
            </Typography>
            <Typography paragraph>
              <u>To enable access to and maintain NexusTable:</u>
              <br />
              The processing falls within our{' '}
              <strong>legitimate interests</strong> to provide our services.
            </Typography>
            <Typography paragraph>
              <u>To conduct marketing and solicit customers:</u>
              <br />
              The processing falls within on our{' '}
              <strong>legitimate interests</strong> to market and evaluate our
              services.
            </Typography>
            <Typography paragraph>
              <u>To administer our business:</u>
              <br />
              The processing falls within our on our{' '}
              <strong>legitimate interests</strong> to maintain proper
              administration of our company and our{' '}
              <strong>legal obligation</strong> to comply with applicable law.
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              <strong>DO WE SHARE YOUR PERSONAL DATA?</strong>
              <br />
              We do not sell any personal data to third parties. Some data may
              however be stored by our IT-suppliers who provide us with
              platforms for email, backup systems, and similar. Our hired
              external processors of personal data have entered into data
              processing agreements to ensure that the processing of your
              personal data is done in a correct and secure manner. We may
              additionally be obliged to share certain information with public
              authorities, for instance the Swedish Tax Agency. Furthermore, in
              order to <strong>conduct marketing</strong> and analyse our
              marketing performance (as described above), we use analytics
              implemented at our website provided by below third parties. You
              can read more about their processing personal data by clicking the
              links hereunder.
            </Typography>
            <Typography paragraph>
              <u>Facebook:</u>
              <br />
              Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour,
              Dublin 2, IRELAND <br />
              Read more about Facebook’s processing of personal data here:{' '}
              <u>
                <a href="https://www.facebook.com/policy.php">
                  https://www.facebook.com/policy.php
                </a>
              </u>
            </Typography>
            <Typography paragraph>
              <u>Google:</u>
              <br />
              Google Ireland Ltd, Gordon House, Barrow Street, Dublin 4, IRELAND{' '}
              <br />
              Read more about Google’s processing of personal data here:{' '}
              <u>
                <a href="https://policies.google.com/privacy?hl=en">
                  https://policies.google.com/privacy
                </a>
              </u>
            </Typography>
            <Typography paragraph>
              <u>LinkedIn:</u>
              <br />
              LinkedIn Ireland Unlimited Company, Attn: Legal Dept. (Privacy
              Policy and User Agreement), Wilton Plaza, Wilton Place, Dublin 2,
              IRELAND <br />
              Read more about LinkedIns’s processing of personal data here:{' '}
              <u>
                <a href="https://www.linkedin.com/legal/privacy-policy">
                  https://www.linkedin.com/legal/privacy-policy
                </a>
              </u>
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              <strong>WHERE IS THE PROCESSING CONDUCTED?</strong>
              <br />
              All processing of personal data is mainly conducted within the
              European Economic Area. Nonetheless, if any data leaves the
              European Economic Area, we will secure the processing by using
              specific data protection agreements and/or data processing
              agreements with any appointed external processors of personal
              data. These will primarily relate to decisions from the European
              Commission concerning adequate levels of protection for processing
              outside the European Economic Area.
            </Typography>
            <Typography paragraph>
              <strong>DO WE USE AUTOMATED DECISION-MAKING?</strong>
              <br />
              We do not implement automated decision-making including profiling.
            </Typography>
            <Typography paragraph>
              <strong>WHAT ABOUT MY RIGHTS?</strong>
              <br />
              Pursuant to the GDPR, you may as a registered person exercise the
              below rights. A request to exercise any of the rights below may
              preferably be submitted in writing and sent to us by email:{' '}
              <a href={'mailto:' + CONTACT_EMAIL}>{CONTACT_EMAIL}</a>. We
              reserve to right to take reasonable precautions in order to verify
              the identity of the person making the request.
            </Typography>
            <Typography paragraph>
              <u>Right to access:</u>
              <br />
              As a registered person, you have the right to request access to
              your stored personal data free of charge.
            </Typography>
            <Typography paragraph>
              <u>Right to rectification:</u>
              <br />
              If any data relating to you is incorrect or incomplete, you have
              the right request that your data is either rectified or
              supplemented.
            </Typography>
            <Typography paragraph>
              <u>Right to data portability:</u>
              <br />
              You have the right to demand that your personal data is disclosed
              in a format readable by machine or that it shall be moved to
              another controller of personal data.
            </Typography>
            <Typography paragraph>
              <u>Right to restrict processing:</u>
              <br />
              You may require that the processing of your personal data is
              limited.
            </Typography>
            <Typography paragraph>
              <u>Right to object:</u>
              <br />
              You may object to your personal data being processed by us.
            </Typography>
            <Typography paragraph>
              <u>Right to erasure:</u>
              <br />
              You have the right to request that your personal is erased.
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              <strong>WHERE CAN I COMPLAIN?</strong>
              <br />
              If you wish to file a complaint regarding our processing of
              personal data, the contact details of the Swedish data protection
              authority follow below:
            </Typography>
            <Typography paragraph>
              <strong>Datainspektionen</strong>
              <br />
              Website:{' '}
              <a href={'www.datainspektionen.se'}>
                www.datainspektionen.se
              </a>{' '}
              <br />
              Email:{' '}
              <a href={'mailto:datainspektionen@datainspektionen.se'}>
                datainspektionen@datainspektionen.se
              </a>{' '}
              <br />
              Telephone: (+46)8 657 61 00 <br />
              Postal Address: Box 8114, 104 20 Stockholm, Sweden
            </Typography>
          </li>
          <li>
            <Typography paragraph>
              <strong>CAN THIS POLICY CHANGE?</strong>
              <br />
              As our services and applicable legal recommendations may change,
              this policy may be revised. We reserve the right to update and
              change this privacy policy without any prior notification other
              than keeping an updated version of the policy available at our
              website. Nevertheless, in case of any substantial changes, our
              customers will be notified prior to the new policy entering into
              force.
            </Typography>
          </li>
        </ol>

        <div style={{ marginTop: '100px' }}>
          <em>VERSION ENACTED ON 2020-12-05</em>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
