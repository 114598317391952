import React, { useContext } from 'react';
// import { Route, Switch } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Landing from './../layout/Landing';
import Register from '../auth/Register';
import Login from '../auth/Login';
import TestLogin from '../auth/TestLogin';
import Alert from '../layout/Alert';
import Dashboard from '../dashboard/Dashboard';
import ProfileDashboard from '../profile-dashboard/ProfileDashboard';
// import CreateProfile from '../profile-forms/CreateProfile';
// import EditProfile from '../profile-forms/EditProfile';
// import AddExperience from '../profile-forms/AddExperience';
// import AddEducation from '../profile-forms/AddEducation';
// import Profiles from '../profiles/Profiles';
// import Profile from '../profile/Profile';
// import Posts from '../posts/Posts';
// import Post from '../post/Post';

import ManagerAccountSettings from '../manager-account-settings/ManagerAccountSettings';

// import RoiTableV3 from '../tableV3/RoiTable';

// import RoiTable_state_test from '../tableV3/RoiTable_state_test';

import NotFound from '../layout/NotFound';

import Deprecated from '../layout/Deprecated';

import PrivateRoute from '../routing/PrivateRoute';
import ChooseManagerAccount from './../manager-account/ChooseManagerAccount';
// import CreateManagerAccount from './../manager-account-forms/CreateManagerAccount';
// import Chat from './../chat/Chat';

import PrivacyPolicy from './../privacy-policy/PrivacyPolicy';

import { AuthContext } from '../../context/AuthContext';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TableV3 from './../tableV4/RoiTable';
import TableV6 from './../tableV4/RoiTableV6';
import AggregatedTable from './../tableV4/RoiTableAggregated';
import CachedTable from './../tableV4/RoiTableCached';

import NewsFeed from './../news-feed/NewsFeed';
import NewsFeedItem from './../news-feed/NewsFeedItem';

import DataDashboard from './../data-dashboard/DataDashboard';

import DataSourceHealthDashboard from './../data-source-health-dashboard/DataSourceHealthDashboard';

import Taxonomy from '../taxonomy/Taxonomy';

const RoutesList = ({
  // auth: { isAuthenticated, loading },
  children,
  ...rest
}) => {
  const { isAuthenticated } = useContext(AuthContext);

  console.log('Routes>isAuthenticated: ', isAuthenticated);

  // if (!isAuthenticated) {
  //   return (
  //     <Routes>
  //       <Route exact path="/" element={<Landing />} />
  //       <Route
  //         // component={NotFound}
  //         element={NotFound}
  //       />
  //     </Routes>
  //   );
  // }
  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      {/* <Route exact path="/register" component={Register} /> */}
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/test-login" element={<TestLogin />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      {/* <PrivateRoute exact path="/profiles" element={<Profiles />} />
            <PrivateRoute exact path="/profile/:id" element={<Profile />} /> */}
      <Route
        exact
        path="/choose-manager-account"
        element={
          <PrivateRoute>
            <ChooseManagerAccount />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/table"
        // element={
        //   <PrivateRoute>
        //     <TableV3 />
        //   </PrivateRoute>
        // }
        element={<Deprecated />}
      />
      <Route
        exact
        path="/tablev6"
        element={
          <PrivateRoute>
            <TableV6 />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/fast-table"
        element={
          <PrivateRoute>
            <AggregatedTable productPath="fast-table" />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/cachedtable"
        element={
          <PrivateRoute>
            <CachedTable productPath="cachedtable" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/cachedtablenograph"
        element={
          <PrivateRoute>
            <CachedTable productPath="cachedtablenograph" />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/basictable"
        element={
          <PrivateRoute>
            <AggregatedTable productPath="basictable" />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/taxonomy"
        element={
          <PrivateRoute>
            <Taxonomy productPath="taxonomy" />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/newsfeed"
        element={
          <PrivateRoute>
            <NewsFeed />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/newsfeeditem"
        element={
          <PrivateRoute>
            <NewsFeedItem />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/datadashboard"
        element={
          <PrivateRoute>
            <DataDashboard />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/datasourcehealthdashboard"
        element={
          <PrivateRoute>
            <DataSourceHealthDashboard />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/manager-account-settings/:setting"
        // component={ManagerAccountSettings}
        element={
          <PrivateRoute>
            <ManagerAccountSettings />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/manager-account-settings/:setting/:dialog"
        // component={ManagerAccountSettings}
        element={
          <PrivateRoute>
            <ManagerAccountSettings />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/manager-account-settings/:setting/:datasource/:code"
        // component={ManagerAccountSettings}
        element={
          <PrivateRoute>
            <ManagerAccountSettings />
          </PrivateRoute>
        }
      />

      <Route
        // component={NotFound}
        element={NotFound}
      />
    </Routes>
  );
};

// export default RoutesList;
RoutesList.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(RoutesList);
