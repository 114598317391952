import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     width: '93%'
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

const adInteractionOptions = [
  { type: 'total', name: 'Total' },
  { type: 'post_view', name: 'Post View' },
  // { type: '7d_view', name: '7d view' },
  // { type: '28d_view', name: '28d view' },
  { type: 'post_click', name: 'Post Click' }
];

export default function SimpleSelect({
  label,
  // menuItems,
  // value,
  // setValue,
  item,
  itemKey,
  action,
  setAction
  // default_value
}) {
  // const classes = useStyles();

  const menuItems = adInteractionOptions;

  const default_value =
    item[itemKey]?.microsoftInvest?.adInteraction || 'total';

  let defaultAdInteraction = adInteractionOptions.filter(
    (obj) => default_value === obj.type
  )[0] || { type: 'total', name: 'Total' };

  console.log('defaultAdInteraction: ', defaultAdInteraction);

  const defaultAdInteractionName = defaultAdInteraction?.name || 'Total';

  const [input, setInput] = useState('');

  const handleChange = (event) => {
    let selectedItem = menuItems.filter(
      (obj) => event.target.value === obj.name
    )[0];

    setInput(selectedItem && selectedItem.name ? selectedItem.name : undefined);
    // setValue(selectedItem);

    const microsoftInvestOptions = action.microsoftInvest
      ? action.microsoftInvest
      : {};

    setAction({
      ...action,
      microsoftInvest: {
        ...microsoftInvestOptions,
        adInteraction: selectedItem?.type
      }
    });
  };

  useEffect(() => {
    if (!input) {
      // let selectedItem = menuItems.filter(
      //   (obj) => default_value === obj.name
      // )[0];

      setInput(defaultAdInteractionName);
      // setValue(selectedItem);
    }
  }, [default_value, defaultAdInteractionName, menuItems]);
  // console.log('input1: ', input);
  return (
    <div>
      <FormControl
        style={{
          margin: '10px',
          minWidth: 120,
          width: '93%'
        }}
        // className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          variant="standard"
          style={{ fontSize: '14px' }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={input}
          onChange={handleChange}
        >
          {/* <MenuItem style={{ fontSize: '14px' }} value="">
            <em>None</em>
          </MenuItem> */}
          {menuItems.map((menuItem, i) => (
            <MenuItem
              key={i}
              style={{ fontSize: '14px' }}
              value={menuItem.name}
            >
              {menuItem.name}
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Some important helper text</FormHelperText> */}
      </FormControl>
    </div>
  );
}
