import React, { useState, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { SketchPicker } from 'react-color';

import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

import { useMutation } from '@apollo/client';
import { updateLabelMutation } from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';

export default function BasicPopover({ label, refetchLabels }) {
  const { currentManagerAccount } = useContext(AccountContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [color, setColor] = useState(label.color);

  const [updateLabel] = useMutation(updateLabelMutation);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    if (color === label.color) {
      setAnchorEl(null);
      return;
    }
    const input = {
      managerAccountId: currentManagerAccount._id,
      accountId: label.accountId,
      labelId: label._id,
      color
    };

    try {
      const res = await updateLabel({ variables: { input } });
      if (res?.data?.updateLabel?.response === 'Success') {
        refetchLabels();
        setAnchorEl(null);
      }
    } catch (error) {
      console.log('CreateLabel>error');
    }
  };

  const onChangeHandler = (color_) => {
    setColor(color_.hex);
  };

  const onChangeCompleteHandler = () => {
    console.log('complete');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <FormatColorFillIcon
        style={{ position: 'relative', top: '2px', width: '20px' }}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SketchPicker
          color={color}
          onChange={onChangeHandler}
          onChangeComplete={onChangeCompleteHandler}
          disableAlpha={true}
        />
      </Popover>
    </div>
  );
}
