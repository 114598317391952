import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_linkedin_ads_campaign_group_data = (
  linkedInAdsCampaignGroup,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = linkedInAdsCampaignGroup?.action01?.dataSource;
  const action02DataSource = linkedInAdsCampaignGroup?.action02?.dataSource;
  const action03DataSource = linkedInAdsCampaignGroup?.action03?.dataSource;

  const action01DataSourceIsLinkedInAds = action01DataSource === 'linkedInAds';
  const action02DataSourceIsLinkedInAds = action02DataSource === 'linkedInAds';
  const action03DataSourceIsLinkedInAds = action03DataSource === 'linkedInAds';

  const action01Type = linkedInAdsCampaignGroup?.action01?.linkedInAds?.type;

  const action02Type = linkedInAdsCampaignGroup?.action02?.linkedInAds?.type;

  const action03Type = linkedInAdsCampaignGroup?.action03?.linkedInAds?.type;

  const action01Id = linkedInAdsCampaignGroup?.action01?.linkedInAds?.id;

  const action02Id = linkedInAdsCampaignGroup?.action02?.linkedInAds?.id;

  const action03Id = linkedInAdsCampaignGroup?.action03?.linkedInAds?.id;

  const action01Name = linkedInAdsCampaignGroup?.action01?.linkedInAds?.name;

  const action02Name = linkedInAdsCampaignGroup?.action02?.linkedInAds?.name;

  const action03Name = linkedInAdsCampaignGroup?.action03?.linkedInAds?.name;

  // const actionWindowDict = {
  //   default: 'externalWebsiteConversions',
  //   postClick: 'externalWebsitePostClickConversions',
  //   postView: 'externalWebsitePostViewConversions'
  // };

  const action01AdInteraction =
    linkedInAdsCampaignGroup?.action01?.linkedInAds?.adInteraction;
  const action02AdInteraction =
    linkedInAdsCampaignGroup?.action02?.linkedInAds?.adInteraction;
  const action03AdInteraction =
    linkedInAdsCampaignGroup?.action03?.linkedInAds?.adInteraction;

  // console.log('action01AdInteraction: ', action01AdInteraction);

  const campaignGroup = {
    ...linkedInAdsCampaignGroup,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'linkedin_ads_campaign_group'
  };

  campaignGroup.action01.name = action01Name;
  campaignGroup.action02.name = action02Name;
  campaignGroup.action03.name = action03Name;

  const runCostFormula = campaignGroup.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  let action01ConversionData = [];

  // if (action01DataSourceIsAdform) {
  //   action01ConversionData = campaignGroup?.linkedinadsconversiondata?.filter(
  //     (obj) => obj.nexusActionType === 'action01'
  //   );
  // }

  // let action02ConversionData = [];

  // if (action02DataSourceIsAdform) {
  //   action02ConversionData = campaignGroup?.linkedinadsconversiondata?.filter(
  //     (obj) => obj.nexusActionType === 'action02'
  //   );
  // }

  // let action03ConversionData = [];

  // if (action03DataSourceIsAdform) {
  //   action03ConversionData = campaignGroup?.linkedinadsconversiondata?.filter(
  //     (obj) => obj.nexusActionType === 'action03'
  //   );
  // }

  for (let date of range2) {
    let objectToPush = {};

    let pushObj = false;

    // for (let adgroup_object of adformCampaign.adformbasicdata) {

    //   // metrics total

    //   let action01Count = null;
    //   let action02Count = null;
    //   let action03Count = null;

    //   let action01Value = null;
    //   let action02Value = null;
    //   let action03Value = null;

    //   let outbound_clicks = null;
    // }

    // console.log('date: ', date);
    // console.log(
    //   'linkedInAdsCampaignGroup?.linkedinadsbasicdata: ',
    //   linkedInAdsCampaignGroup?.linkedinadsbasicdata
    // );

    if (linkedInAdsCampaignGroup?.linkedinadsbasicdata?.length > 0) {
      for (let linkedInAdsBasicDataObj of linkedInAdsCampaignGroup?.linkedinadsbasicdata) {
        const campaignGroupDate = new Date(+linkedInAdsBasicDataObj.startDate)
          .toISOString()
          .split('T')[0];
        if (campaignGroupDate === new Date(date).toISOString().split('T')[0]) {
          pushObj = true;
          objectToPush = {
            ...objectToPush,
            date_start: new Date(campaignGroupDate).getTime(),
            // date_stop: adgroup_object.end_time,
            cost:
              (objectToPush.cost || 0) +
                +linkedInAdsBasicDataObj.costInLocalCurrency || null,
            impressions:
              (objectToPush.impressions || 0) +
                linkedInAdsBasicDataObj.impressions || null,
            clicks:
              (objectToPush.clicks || 0) + linkedInAdsBasicDataObj.clicks ||
              null,
            // action01Type,
            // action01Count,
            // action01Value,

            // action02Type,
            // action02Count,
            // action02Value,

            // action03Type,
            // action03Count,
            // action03Value,

            accountId: campaignBaseValues.accountId
          };
        }
      }
    }

    if (linkedInAdsCampaignGroup?.linkedinadsconversiondata?.length > 0) {
      if (action01DataSourceIsLinkedInAds) {
      }
      for (let conversionObj of linkedInAdsCampaignGroup?.linkedinadsconversiondata) {
        console.log('conversionObj: ', conversionObj);
        console.log('action01Id: ', action01Id);
        if (
          new Date(+conversionObj.startDate).toISOString().split('T')[0] ===
          date
        ) {
          objectToPush = {
            ...objectToPush,
            date_start: new Date(
              new Date(+conversionObj.startDate).toISOString().split('T')[0]
            ).getTime(),
            accountId: campaignBaseValues.accountId
          };

          if (conversionObj.conversionId === action01Id) {
            pushObj = true;
            objectToPush = {
              ...objectToPush,
              action01Count:
                (objectToPush.action01Count || 0) +
                (conversionObj[action01AdInteraction] || 0),
              action01Value:
                (objectToPush.action01Value || 0) +
                (+conversionObj.conversionValueInLocalCurrency || 0)
            };
          }
          if (conversionObj.conversionId === action02Id) {
            pushObj = true;
            objectToPush = {
              ...objectToPush,
              action02Count:
                (objectToPush.action02Count || 0) +
                (conversionObj[action02AdInteraction] || 0),
              action02Value:
                (objectToPush.action02Value || 0) +
                (+conversionObj.conversionValueInLocalCurrency || 0)
            };
          }

          if (conversionObj.conversionId === action03Id) {
            pushObj = true;
            objectToPush = {
              ...objectToPush,
              action03Count:
                (objectToPush.action03Count || 0) +
                (conversionObj[action03AdInteraction] || 0),
              action03Value:
                (objectToPush.action03Value || 0) +
                (+conversionObj.conversionValueInLocalCurrency || 0)
            };
          }
        }
      }
    }

    // if (action02ConversionData?.length > 0) {
    //   for (let adformConversionDataObj of action02ConversionData) {
    //     if (
    //       adformConversionDataObj.date.split('T')[0] ===
    //       new Date(date).toISOString().split('T')[0]
    //     ) {
    //       pushObj = true;
    //       objectToPush = {
    //         ...objectToPush,
    //         date_start: new Date(
    //           adformConversionDataObj.date.split('T')[0]
    //         ).getTime(),
    //         action02Count:
    //           (objectToPush.action02Count || 0) +
    //           (adformConversionDataObj[action02Type] || 0),
    //         action02Value:
    //           (objectToPush.action02Value || 0) +
    //           (adformConversionDataObj[actionTypeDict[action02Type]] || 0),

    //         accountId: campaignBaseValues.accountId
    //       };
    //     }
    //   }
    // }

    // if (action03ConversionData?.length > 0) {
    //   for (let adformConversionDataObj of action03ConversionData) {
    //     if (
    //       adformConversionDataObj.date.split('T')[0] ===
    //       new Date(date).toISOString().split('T')[0]
    //     ) {
    //       pushObj = true;
    //       objectToPush = {
    //         ...objectToPush,
    //         date_start: new Date(
    //           adformConversionDataObj.date.split('T')[0]
    //         ).getTime(),
    //         action03Count:
    //           (objectToPush.action03Count || 0) +
    //           (adformConversionDataObj[action03Type] || 0),
    //         action03Value:
    //           (objectToPush.action03Value || 0) +
    //           (adformConversionDataObj[actionTypeDict[action03Type]] || 0),

    //         accountId: campaignBaseValues.accountId
    //       };
    //     }
    //   }
    // }

    if (pushObj) {
      // console.log('objectToPush: ', objectToPush);
      listOfPropagationData.push(objectToPush);
    }
  }

  // if (action01DataSourceIsAdform) {
  //   for (let action01ConversionObj of action01ConversionData) {
  //     console.log(
  //       "14433>action01ConversionObj.date.split('T')[0]: ",
  //       action01ConversionObj.date.split('T')[0]
  //     );
  //     console.log(
  //       "14433>new Date(adgroup_object.date).toISOString().split('T')[0]: ",
  //       new Date(adgroup_object.date).toISOString().split('T')[0]
  //     );
  //     if (
  //       action01ConversionObj.date.split('T')[0] ===
  //       new Date(adgroup_object.date).toISOString().split('T')[0]
  //     ) {
  //       action01Count =
  //         action01Count + action01ConversionObj[action01Type];
  //       action01Value =
  //         action01Value +
  //         action01ConversionObj[actionTypeDict[action01Type]];
  //     }
  //   }
  // }

  // console.log('adgroup_object: ', adgroup_object);
  // listOfPropagationData.push({
  //   ...adgroup_object,
  //   // ...calculatedFields,
  //   date_start: new Date(adgroup_object.date.split('T')[0]).getTime(),
  //   // date_stop: adgroup_object.end_time,
  //   cost: adgroup_object.cost || null,
  //   impressions: adgroup_object.impressions || null,
  //   clicks: adgroup_object.clicks || null,
  //   action01Type,
  //   action01Count,
  //   action01Value,

  //   action02Type,
  //   action02Count,
  //   action02Value,

  //   action03Type,
  //   action03Count,
  //   action03Value,

  //   accountId: campaignBaseValues.accountId
  // });

  campaignGroup.total_list_of_aggregated_propagation_data = [
    {
      costLifetime: linkedInAdsCampaignGroup?.totaldata?.cost,
      impressionsLifetime: linkedInAdsCampaignGroup?.totaldata?.impressions
    }
  ];

  campaignGroup.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaignGroup
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_linkedin_ads_campaign_group_data;
