import React, { useContext, useState } from 'react';
import { PhoneInput } from 'react-international-phone';

// import { MuiPhone } from './MuiPhone';

import TextField from '@mui/material/TextField';

import { Button } from '@mui/material';

import { useMutation } from '@apollo/client';

import { RemoveUserPhoneNumberMutation } from '../../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../../context/AccountContext';
import { AuthContext } from '../../../../../../context/AuthContext';

import VerificationInput from './VerificationInput';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';

function VerifyPhoneNumber({
  userInfoAndAccounts,
  handleClose,
  refetchUserInfo
}) {
  const { user: currentUser } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { currentManagerAccount, refetchAccounts } = useContext(AccountContext);
  // const classes = useStyles();

  const [removeUserPhoneNumber, { called }] = useMutation(
    RemoveUserPhoneNumberMutation
  );

  const [numberOfCodesSent, setNumberOfCodesSent] = useState(0);
  const [code, setCode] = useState('');

  const onClickHandler = async (e) => {
    if (numberOfCodesSent > 5) {
      enqueueSnackbar('Too many tries, please contact support.', {
        variant: 'error'
      });
    }
    const input = {
      managerAccountId: currentManagerAccount?._id
      // phoneNumberVerificationCode: code
    };

    let res;
    try {
      res = await removeUserPhoneNumber({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(
          'Remove phone number failed, please try again or contact support.',
          {
            variant: 'error'
          }
        );
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.removeUserPhoneNumber?.response === 'Success') {
      enqueueSnackbar('Phone number successfully removed', {
        variant: 'success'
      });
      refetchUserInfo();
      handleClose();
      // setView('VERIFY_PHONE_NUMBER');
      //   refetchUserInfo();
      //   refetchAccounts();

      if (userInfoAndAccounts?._id === currentUser?._id) {
        // refetchUsersAccountsList();
      }
    }
    setNumberOfCodesSent(numberOfCodesSent + 1);
  };
  return (
    // <div
    //   style={{
    //     padding: '10px',
    //     width: '500px',
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'space-between'
    //   }}
    // >
    //   {/* <TextField
    //     // defaultCountry="ua"
    //     value={phone}
    //     onChange={(phone) => setPhone(phone)}
    //   /> */}

    //   <Button
    //     variant="outlined"
    //     onClick={onClickHandler}
    //     style={{
    //       background: code?.length === 4 && 'green',
    //       color: code?.length === 4 && 'white'
    //     }}
    //   >
    //     Verify
    //   </Button>
    // </div>
    <Fab
      onClick={onClickHandler}
      aria-label="edit"
      style={{
        color: 'white',

        // margin: theme.spacing(1),
        borderRadius: '5px',
        height: '40px',
        width: '120px',
        backgroundColor: 'rgba(181, 0, 8, 1)'
      }}
    >
      <DeleteIcon style={{ color: 'white' }} /> Remove
    </Fab>
  );
}

export default VerifyPhoneNumber;
