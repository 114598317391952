const pieChartWidget = async (widget, pres, slide, colorPalette) => {
  if (widget.isShowingCard) {
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
      fill: {
        color: 'FFFFFF',
        transparency: (widget.opacity - 1) * -100 // 5
      },
      border: { pt: '1', color: 'f1f1f1', transparency: 10 },
      w: +widget.width.split('px')[0] / 72 / 2,
      h: +widget.height.split('px')[0] / 72 / 2,
      x: +widget.left.split('px')[0] / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2,

      rectRadius: 0.05,
      shadow: {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }
    });
  }

  let dataChartObj = {
    name: widget.title,
    labels: [], // widget.data.map((obj) => obj.label),
    values: [] // widget.data.map((obj) => obj.value)
  };

  let totalValue = 0;

  for (let obj of widget.data) {
    totalValue = totalValue + (obj.value || null);
  }

  for (let obj of widget.data) {
    if (obj.value / totalValue >= 0.005) {
      dataChartObj.labels.push(obj.label);
      dataChartObj.values.push(obj.value);
    }
  }
  let dataChartPieStat = [dataChartObj];

  // console.log('dataChartPieStat: ', dataChartPieStat);
  // {
  //   name: 'Project Status',
  //   labels: ['Red', 'Yellow', 'Green', 'Complete', 'Cancelled', 'Unknown'],
  //   values: [25, 5, 5, 5, 5, 5]
  // }

  slide.addChart(pres.charts.PIE, [dataChartObj], {
    // x: 0.5,
    // y: 0.6,
    // w: 4.0,
    // h: 3.2,
    w: +widget.width.split('px')[0] / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,
    chartArea: {
      fill: { color: 'F1F1F1', transparency: 100 },
      // border: { pt: '1', color: 'f1f1f1', transparency: 100 },
      roundedCorners: true

      // shadow: {
      //   type: 'outer',
      //   blur: 10,
      //   offset: 5,
      //   angle: 45,
      //   color: '000000',
      //   opacity: 1
      // }
    },
    chartColors: colorPalette, //COLORS_RYGU,
    dataBorder: { pt: 1, color: 'F1F1F1' },

    //
    showLegend: true,
    legendPos: 'r',
    legendFontFace: 'Roboto', //'Arial', //'Courier New',
    legendFontSize: 8,

    //

    showLeaderLines: true,
    showPercent: true,
    // showValue: true,
    dataLabelColor: 'FFFFFF',
    dataLabelFontSize: 7,
    // dataLabelPosition: 'bestFit', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
    dataLabelPosition: 'inEnd',
    //title
    showTitle: false,
    title: widget.title,
    // titleAlign: 'right',
    titlePos: { x: 55, y: 5 },
    firstSliceAng: 0,
    titleColor: '000000',
    titleFontSize: 8,
    layout: { x: 0.03, y: 0.03, w: 0.56, h: 0.94 },
    // layout: { x: 0.04, y: 0.04, w: 0.56, h: 0.94 }

    // layout: { x: 200, y: 0, w: 0, h: 0 }

    // shadow: {
    //   type: 'outer',
    //   blur: 4,
    //   offset: 3,
    //   angle: 45,
    //   color: '000000',
    //   opacity: 0.4
    // }

    shadow: {
      type: 'outer',
      blur: 6,
      offset: 2,
      angle: 45,
      color: '000000',
      opacity: 0.2
    }

    // shadow: {
    //   type: 'outer',
    //   blur: 6,
    //   offset: 2,
    //   angle: 45,
    //   color: '000000',
    //   opacity: 0.3
    // }
  });

  let titleWidth = Math.round(widget.title.length * 9 + 10);
  // if (titleWidth < 120) {
  //   titleWidth = 120;
  // }

  slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
    fill: {
      color: 'ababab',
      transparency: 90
    },
    border: { pt: '1', color: 'f1f1f1', transparency: 10 },
    w: (titleWidth + 20) / 72 / 2,
    h: 25 / 72 / 2,
    x:
      +widget.left.split('px')[0] / 72 / 2 +
      widget.width.split('px')[0] / 72 / 2 -
      (titleWidth + 20) / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2 + 0 / 72 / 2,

    rectRadius: 0.01
    // shadow: {
    //   type: 'outer',
    //   blur: 6,
    //   offset: 2,
    //   angle: 45,
    //   color: 'bcbcbc',
    //   opacity: 0.2
    // }
  });

  slide.addText(widget.title, {
    w: titleWidth / 72 / 2,
    h: 20 / 72 / 2,
    x:
      +widget.left.split('px')[0] / 72 / 2 +
      widget.width.split('px')[0] / 72 / 2 -
      (titleWidth + 10) / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2 + 2 / 72 / 2,

    margin: 0,
    fontFace: 'Roboto', // 'Arial',
    fontSize: 8,
    color: '000000',
    bold: true,
    // underline: true,
    isTextBox: true,
    align: 'left'
    // fill: { color: 'F1F1F1' }
    // highlight: '#bcbcbc'
  });
};

export default pieChartWidget;
