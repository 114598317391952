import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_microsoft_advertising_campaign_data_aggregated = (
  microsoftAdvertisingCampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2,
  startDateForLastDaysData,
  startDateForLastDaysDataMinusOneDay,
  tomorrow,
  dayAfterTomorrow
) => {
  console.log(
    'microsoftAdvertisingCampaign?.action01: ',
    microsoftAdvertisingCampaign?.action01
  );
  const action01DataSource = microsoftAdvertisingCampaign?.action01?.dataSource;
  const action02DataSource = microsoftAdvertisingCampaign?.action02?.dataSource;
  const action03DataSource = microsoftAdvertisingCampaign?.action03?.dataSource;

  const action01DataSourceIsMicrosoftAdvertising =
    action01DataSource === 'microsoftAdvertising';
  const action02DataSourceIsMicrosoftAdvertising =
    action02DataSource === 'microsoftAdvertising';
  const action03DataSourceIsMicrosoftAdvertising =
    action03DataSource === 'microsoftAdvertising';

  const action01Type =
    microsoftAdvertisingCampaign?.action01?.microsoftAdvertising?.type;

  const action02Type =
    microsoftAdvertisingCampaign?.action02?.microsoftAdvertising?.type;

  const action03Type =
    microsoftAdvertisingCampaign?.action03?.microsoftAdvertising?.type;

  const action01Id =
    microsoftAdvertisingCampaign?.action01?.microsoftAdvertising?.id;

  const action02Id =
    microsoftAdvertisingCampaign?.action02?.microsoftAdvertising?.id;

  const action03Id =
    microsoftAdvertisingCampaign?.action03?.microsoftAdvertising?.id;

  // const action01Name =
  //   microsoftAdvertisingCampaign?.action01?.microsoftAdvertising?.name;

  // const action02Name =
  //   microsoftAdvertisingCampaign?.action02?.microsoftAdvertising?.name;

  // const action03Name =
  //   microsoftAdvertisingCampaign?.action03?.microsoftAdvertising?.name;

  // const actionWindowDict = {
  //   default: 'externalWebsiteConversions',
  //   postClick: 'externalWebsitePostClickConversions',
  //   postView: 'externalWebsitePostViewConversions'
  // };

  // const action01AdInteraction =
  //   microsoftAdvertisingCampaign?.action01?.microsoftAdvertising?.adInteraction;
  // const action02AdInteraction =
  //   microsoftAdvertisingCampaign?.action02?.microsoftAdvertising?.adInteraction;
  // const action03AdInteraction =
  //   microsoftAdvertisingCampaign?.action03?.microsoftAdvertising?.adInteraction;

  // console.log('action01AdInteraction: ', action01AdInteraction);

  const campaignGroup = {
    ...microsoftAdvertisingCampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'microsoft_advertising_campaign'
  };

  // campaignGroup.action01.name = action01Name;
  // campaignGroup.action02.name = action02Name;
  // campaignGroup.action03.name = action03Name;

  const runCostFormula = campaignGroup.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  let action01ConversionData = [];

  let objectToPush = {
    date_start: startDateForLastDaysDataMinusOneDay.getTime()
  };
  let pushObj = false;
  for (let date of range2) {
    // let pushObj = false;
    let dailyPushObject = false;
    let dailyObjectToPush = {};

    if (new Date(date) >= new Date(startDateForLastDaysData)) {
      if (
        microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata?.length > 0
      ) {
        for (let microsoftAdvertisingBasicDataObj of microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata) {
          const campaignGroupDate = new Date(
            +microsoftAdvertisingBasicDataObj.timePeriod
          )
            .toISOString()
            .split('T')[0];
          if (
            campaignGroupDate === new Date(date).toISOString().split('T')[0]
          ) {
            dailyPushObject = true;
            dailyObjectToPush = {
              ...dailyObjectToPush,
              cost: microsoftAdvertisingBasicDataObj.spend || null,
              impressions: microsoftAdvertisingBasicDataObj.impressions || null,
              measurableImpressions:
                microsoftAdvertisingBasicDataObj.impressions || null,
              viewableImpressions:
                microsoftAdvertisingBasicDataObj.visible_impressions || null,

              clicks: +microsoftAdvertisingBasicDataObj.clicks || null,

              date_start: new Date(
                +microsoftAdvertisingBasicDataObj.timePeriod
              ).getTime(),
              date_stop: new Date(
                +microsoftAdvertisingBasicDataObj.timePeriod
              ).getTime(),

              accountId: campaignBaseValues.accountId
            };
          }
        }
      }

      for (let actionNumber of ['01', '02', '03']) {
        if (
          microsoftAdvertisingCampaign?.['action' + actionNumber]
            ?.dataSource === 'microsoftAdvertising'
        ) {
          switch (action01Type) {
            case 'conversions':
              if (
                microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata
                  ?.length > 0
              ) {
                for (let microsoftAdvertisingBasicDataObj of microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata) {
                  const campaignGroupDate = new Date(
                    +microsoftAdvertisingBasicDataObj.timePeriod
                  )
                    .toISOString()
                    .split('T')[0];
                  if (
                    campaignGroupDate ===
                    new Date(date).toISOString().split('T')[0]
                  ) {
                    dailyObjectToPush[`action${actionNumber}Count`] =
                      (dailyObjectToPush?.[`action${actionNumber}Count`] || 0) +
                      (microsoftAdvertisingBasicDataObj.conversions || null);
                    dailyObjectToPush[`action${actionNumber}Value`] =
                      (dailyObjectToPush?.[`action${actionNumber}Value`] || 0) +
                      (microsoftAdvertisingBasicDataObj.revenue || null);
                  }
                }
              }

              break;

            default:
              if (
                microsoftAdvertisingCampaign?.microsoftadvertisingactiondata
                  ?.length > 0
              ) {
                for (let conversionObj of microsoftAdvertisingCampaign?.microsoftadvertisingactiondata) {
                  if (
                    // conversionObj?.segments?.conversionAction === action01Type &&
                    conversionObj.goalId ===
                      microsoftAdvertisingCampaign?.['action' + actionNumber]
                        ?.microsoftAdvertising?.id &&
                    new Date(+conversionObj.timePeriod)
                      .toISOString()
                      .split('T')[0] === date
                  ) {
                    dailyObjectToPush[`action${actionNumber}Count`] =
                      (dailyObjectToPush?.[`action${actionNumber}Count`] || 0) +
                      (conversionObj.conversions || null);
                    dailyObjectToPush[`action${actionNumber}Value`] =
                      (dailyObjectToPush?.[`action${actionNumber}Value`] || 0) +
                      (conversionObj.revenue || null);
                  }
                }
              }
          }
        }
      }

      if (dailyPushObject) {
        listOfPropagationData.push(dailyObjectToPush);
      }
    } else {
      if (
        microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata?.length > 0
      ) {
        for (let microsoftAdvertisingBasicDataObj of microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata) {
          const campaignGroupDate = new Date(
            +microsoftAdvertisingBasicDataObj.timePeriod
          )
            .toISOString()
            .split('T')[0];
          if (campaignGroupDate === date) {
            pushObj = true;
            objectToPush = {
              ...objectToPush,

              cost:
                (objectToPush.cost || 0) +
                  microsoftAdvertisingBasicDataObj.spend || null,
              impressions:
                (objectToPush.impressions || 0) +
                  microsoftAdvertisingBasicDataObj.impressions || null,
              measurableImpressions:
                (objectToPush.measurableImpressions || 0) +
                  microsoftAdvertisingBasicDataObj.impressions || null,
              viewableImpressions:
                (objectToPush.viewableImpressions || 0) +
                  microsoftAdvertisingBasicDataObj.visible_impressions || null,

              clicks:
                (objectToPush.clicks || 0) +
                  microsoftAdvertisingBasicDataObj.clicks || null,

              accountId: campaignBaseValues.accountId
            };
          }
        }
      }

      for (let actionNumber of ['01', '02', '03']) {
        if (
          microsoftAdvertisingCampaign?.['action' + actionNumber]
            ?.dataSource === 'microsoftAdvertising'
        ) {
          switch (action01Type) {
            case 'conversions':
              if (
                microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata
                  ?.length > 0
              ) {
                for (let microsoftAdvertisingBasicDataObj of microsoftAdvertisingCampaign?.microsoftadvertisingbasicdata) {
                  const campaignGroupDate = new Date(
                    +microsoftAdvertisingBasicDataObj.timePeriod
                  )
                    .toISOString()
                    .split('T')[0];
                  if (
                    campaignGroupDate ===
                    new Date(date).toISOString().split('T')[0]
                  ) {
                    objectToPush[`action${actionNumber}Count`] =
                      (objectToPush?.[`action${actionNumber}Count`] || 0) +
                      (microsoftAdvertisingBasicDataObj.conversions || null);
                    objectToPush[`action${actionNumber}Value`] =
                      (objectToPush?.[`action${actionNumber}Value`] || 0) +
                      (microsoftAdvertisingBasicDataObj.revenue || null);
                  }
                }
              }

              break;

            default:
              if (
                microsoftAdvertisingCampaign?.microsoftadvertisingactiondata
                  ?.length > 0
              ) {
                for (let conversionObj of microsoftAdvertisingCampaign?.microsoftadvertisingactiondata) {
                  if (
                    // conversionObj?.segments?.conversionAction === action01Type &&
                    conversionObj.goalId ===
                      microsoftAdvertisingCampaign?.['action' + actionNumber]
                        ?.microsoftAdvertising?.id &&
                    new Date(+conversionObj.timePeriod)
                      .toISOString()
                      .split('T')[0] === date
                  ) {
                    objectToPush[`action${actionNumber}Count`] =
                      (objectToPush?.[`action${actionNumber}Count`] || 0) +
                      (conversionObj.conversions || null);
                    objectToPush[`action${actionNumber}Value`] =
                      (objectToPush?.[`action${actionNumber}Value`] || 0) +
                      (conversionObj.revenue || null);
                  }
                }
              }
          }
        }
      }
    }
  }

  if (pushObj) {
    listOfPropagationData.push(objectToPush);
  }

  campaignGroup.total_list_of_aggregated_propagation_data = [
    {
      costLifetime: microsoftAdvertisingCampaign?.totaldata?.cost,
      impressionsLifetime: microsoftAdvertisingCampaign?.totaldata?.impressions
    }
  ];

  campaignGroup.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaignGroup
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_microsoft_advertising_campaign_data_aggregated;
