import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import { useMutation } from '@apollo/client';

import { updateDataSourceMutation } from './../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';
import { useSnackbar } from 'notistack';

export default function DeactivateButton({
  dataSourceInfo,
  refetchDataSourceInfo
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  // const classes = useStyles();

  const [updateDataSource, { called }] = useMutation(updateDataSourceMutation);

  const { enqueueSnackbar } = useSnackbar();

  const onSaveHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const input = {
      managerAccountId: currentManagerAccount._id,
      updateKeys: ['status'],
      documents: [
        {
          _id: dataSourceInfo._id,
          dataSourceRef: dataSourceInfo.dataSourceRef,
          status: 'DEACTIVATED'
        }
      ]
    };

    let res;
    try {
      res = await updateDataSource({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.updateDataSource?.response === 'Success') {
      enqueueSnackbar('Saved', {
        variant: 'success'
      });
      refetchDataSourceInfo();
    }
  };

  if (dataSourceInfo?.status === 'DEACTIVATED') {
    return (
      <Button
        size="small"
        variant="outlined"
        style={{
          width: '110px',
          height: '30px',
          marginLeft: '20px',
          color: 'grey',
          borderColor: 'grey',
          cursor: 'not-allowed'
        }}
        // onClick={onSaveHandler}
      >
        DEACTIVATED
      </Button>
    );
  }
  return (
    <div style={{ paddingTop: '10px' }}>
      <Button
        size="small"
        variant="outlined"
        style={{
          width: '110px',
          height: '30px',
          marginLeft: '20px',
          color: 'red',
          borderColor: 'red'
        }}
        onClick={onSaveHandler}
      >
        DEACTIVATE
      </Button>
    </div>
  );
}
