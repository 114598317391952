import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

const parse_fb_data = (
  facebookadscampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = facebookadscampaign?.action01?.dataSource;
  const action02DataSource = facebookadscampaign?.action02?.dataSource;
  const action03DataSource = facebookadscampaign?.action03?.dataSource;

  const action01DataSourceIsFacebookAds = action01DataSource === 'facebookAds';
  const action02DataSourceIsFacebookAds = action02DataSource === 'facebookAds';
  const action03DataSourceIsFacebookAds = action03DataSource === 'facebookAds';

  const action01Type = facebookadscampaign?.action01?.facebookAds?.type;
  const action02Type = facebookadscampaign?.action02?.facebookAds?.type;

  const action03Type = facebookadscampaign?.action03?.facebookAds?.type;

  const action01Name = facebookadscampaign?.action01?.name;

  const action02Name = facebookadscampaign?.action02?.name;
  const action03Name = facebookadscampaign?.action03?.name;

  const action01AttributionWindow =
    facebookadscampaign?.action01?.facebookAds?.attributionWindow;

  const action02AttributionWindow =
    facebookadscampaign?.action02?.facebookAds?.attributionWindow;

  const action03AttributionWindow =
    facebookadscampaign?.action03?.facebookAds?.attributionWindow;

  const translateAttributionWindow = (attributionWindow) => {
    switch (attributionWindow) {
      case '1d_view':
        return '_1d_view';

      case '1d_click':
        return '_1d_click';

      case '7d_click':
        return '_7d_click';

      case '28d_click':
        return '_28d_click';

      default:
        return 'value';
    }
  };

  const action01ConversionWindow = translateAttributionWindow(
    action01AttributionWindow
  );
  const action02ConversionWindow = translateAttributionWindow(
    action02AttributionWindow
  );
  const action03ConversionWindow = translateAttributionWindow(
    action03AttributionWindow
  );

  const campaign = {
    ...facebookadscampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'facebook_ads_campaign'
  };

  const runCostFormula = campaign.costFormula !== '=cost';

  if (
    !campaign.name &&
    facebookadscampaign &&
    facebookadscampaign.facebookadsadsets[0] &&
    facebookadscampaign.facebookadsadsets[0].campaign_name
  ) {
    campaign.name = facebookadscampaign.facebookadsadsets[0].campaign_name;
  }

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (facebookadscampaign.facebookadsadsets.length > 0) {
    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adset_object of facebookadscampaign.facebookadsadsets) {
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      let outbound_clicks = null;

      for (let action of adset_object.actions) {
        if (
          action01DataSourceIsFacebookAds &&
          action.action_type === action01Type
        ) {
          action01Count = action[action01ConversionWindow];
        }
        if (
          action02DataSourceIsFacebookAds &&
          action.action_type === action02Type
        ) {
          action02Count = action[action02ConversionWindow];
        }
        if (
          action03DataSourceIsFacebookAds &&
          action.action_type === action03Type
        ) {
          action03Count = action[action03ConversionWindow];
        }
      }

      for (let action_value of adset_object.action_values) {
        if (
          action01DataSourceIsFacebookAds &&
          action_value.action_type === action01Type
        ) {
          action01Value = action_value[action01ConversionWindow];
        }
        if (
          action02DataSourceIsFacebookAds &&
          action_value.action_type === action02Type
        ) {
          action02Value = action_value[action02ConversionWindow];
        }
        if (
          action03DataSourceIsFacebookAds &&
          action_value.action_type === action03Type
        ) {
          action03Value = action_value[action03ConversionWindow];
        }
      }

      for (let outbound_click of adset_object.outbound_clicks) {
        switch (outbound_click.action_type) {
          case 'outbound_click':
            outbound_clicks = outbound_click['value'];
        }
      }

      // const costScope = {
      //   cost: adset_object.spend || 0
      // };

      // const cost = runCostFormula
      //   ? evaluateFormula(campaign.costFormula, costScope)
      //   : adset_object.spend || null;

      // const formulaFields = [
      //   // 'plannedGross',
      //   // 'plannedNet',
      //   // 'plannedNetNet',
      //   // 'plannedCtc',

      //   // 'plannedTrp',
      //   // 'plannedTechFee',
      //   // 'plannedAdOpsFee',
      //   // 'plannedAdServingCost',
      //   // 'plannedFeePercent',

      //   // 'budget',
      //   // 'kpi',
      //   // 'plannedImpressions',
      //   // 'plannedClicks',
      //   // 'plannedCtr',
      //   // 'plannedCpc',
      //   // 'plannedReach',
      //   // 'plannedCpm',
      //   // 'plannedFrequency',
      //   // 'plannedActionCount',
      //   // 'plannedActionValue',
      //   // 'plannedActionRoas',
      //   // 'plannedCpa',
      //   'reach',
      //   'cost',
      //   'impressions',
      //   'clicks',
      //   'action01Count',
      //   'action02Count',
      //   'action03Count',
      //   'action01Value',
      //   'action02Value',
      //   'action03Value'
      // ];

      // const generateScope = (formulaField, calcObject) => {
      //   switch (formulaField) {
      //     case 'cost':
      //       return {
      //         cost: calcObject.spend || 0
      //       };

      //     case 'impressions':
      //       return {
      //         impressions: calcObject.impressions || 0
      //       };
      //     case 'clicks':
      //       return {
      //         clicks: outbound_clicks || 0
      //       };
      //     case 'action01Count':
      //     case 'action02Count':
      //     case 'action03Count':
      //     case 'action01Value':
      //     case 'action02Value':
      //     case 'action03Value':
      //       return {
      //         [formulaField]: calcObject[formulaField] || 0
      //       };

      //     default:
      //       return {};
      //   }
      // };

      // const calculatedFields = {};

      // for (let formulaField of formulaFields) {
      //   calculatedFields[formulaField] = evaluateFormula(
      //     campaign[formulaField + 'Formula'],
      //     generateScope(formulaField, adset_object)
      //   );
      // }

      // for (let formulaField of formulaFields) {
      //   calculatedFields[formulaField] = evaluateFormula(
      //     '=' + formulaField,
      //     generateScope(formulaField, adset_object)
      //   );
      // }

      listOfPropagationData.push({
        ...adset_object,
        // ...calculatedFields,
        date_start: adset_object.date_start,
        date_stop: adset_object.date_stop,
        // cost: adset_object.cost,
        cost: adset_object.spend || 0,
        impressions: adset_object.impressions,
        action01Type,
        action01Count,
        action01Value,

        action02Type,
        action02Count,
        action02Value,

        action03Type,
        action03Count,
        action03Value,
        // outbound_clicks,
        clicks: outbound_clicks,
        accountId: campaignBaseValues.accountId
      });
    }
  }

  // console.log('listOfPropagationData123: ', listOfPropagationData);

  campaign.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaign
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_fb_data;
