import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataSourceList from './datasourcelist/DataSourceList';

import DataSource from './datasource/DataSource';

import { AuthContext } from '../../../context/AuthContext';

function DataSources({
  // user,
  code,
  setCode,
  dataSource,
  setDataSource
}) {
  const { user } = useContext(AuthContext);

  const [selectedDataSourceId, setSelectedDataSourceId] = useState('');

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '300px auto',
        gridColumnStart: 2,
        gridColumnEnd: 2
      }}
    >
      <DataSourceList
        selectedDataSourceId={selectedDataSourceId}
        setSelectedDataSourceId={setSelectedDataSourceId}
        code={code}
        setCode={setCode}
        dataSource={dataSource}
        setDataSource={setDataSource}
      />
      <DataSource
        selectedDataSourceId={selectedDataSourceId}
        setSelectedDataSourceId={setSelectedDataSourceId}
      />
    </div>
  );
}

// export default DataSources

DataSources.propTypes = {
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  // stylesStoredInRedux: state.style,
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(DataSources);
