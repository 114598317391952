import React from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

// const useStylesBootstrap = makeStyles((theme) => ({
//   arrow: {
//     color: theme.palette.common.black
//   },
//   tooltip: {
//     backgroundColor: theme.palette.common.black
//   }
// }));

function BootstrapTooltip(props) {
  // const classes = useStylesBootstrap();

  return (
    <Tooltip
      arrow
      // classes={classes}
      {...props}
    />
  );
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

export default function CustomizedTooltips({ itemKey }) {
  let showTooltip = false;

  if (
    [
      'members',
      'labels',
      'pace',
      'paceYesterday',
      'calcPaceYesterday',
      'calcDailyBudget',
      'allowMissingBudget',
      'allowMissingClicks',
      'allowAbnormallyHighCtr',
      'allowMissingImpressionsYesterday',
      'allowFrontloadedDelivery'
      // 'allowImpressionsBeforeStartDate',
      // 'allowImpressionsAfterEndDate'
    ].includes(itemKey)
  ) {
    showTooltip = true;
  }

  let title = (
    <React.Fragment>
      <Typography color="inherit">Tooltip with HTML</Typography>
      <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
      {"It's very engaging. Right?"}
    </React.Fragment>
  );

  switch (itemKey) {
    case 'members':
      title = (
        <React.Fragment>
          <Typography color="inherit">Members</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {'Add Members in order to filter by member.'}
        </React.Fragment>
      );

      break;
    case 'labels':
      title = (
        <React.Fragment>
          <Typography color="inherit">Labels</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {'Add labels in order to filter by label.'}
        </React.Fragment>
      );

      break;
    case 'pace':
      title = (
        <React.Fragment>
          <Typography color="inherit">Total Pace</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'The date picker needs to cover the whole date span in order for the total pace to be calculated.'
            // 'Cost / (Budget / (Total number of days) * Days since start). The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;

    case 'paceYesterday':
      title = (
        <React.Fragment>
          <Typography color="inherit">Pace Yesterday</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'Cost Yesterday / Daily Budget Yesterday. The date picker needs to cover the whole date span in order for the total pace to be calculated.'
            // 'Cost / (Budget / (Total number of days) * Days since start). The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;

    case 'calcPaceYesterday':
      title = (
        <React.Fragment>
          <Typography color="inherit">Calculated Pace Yesterday</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'Cost Yesterday / Calculated Daily Budget Yesterday. The date picker needs to cover the whole date span in order for the total pace to be calculated.'
            // 'Cost / (Budget / (Total number of days) * Days since start). The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;

    case 'calcDailyBudget':
      title = (
        <React.Fragment>
          <Typography color="inherit">Calculated Daily Budget</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            '(Budget - Cost)/Number of days left. The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;
    case 'allowMissingBudget':
      title = (
        <React.Fragment>
          <Typography color="inherit">Allow missing budget</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to data sources without budget and to prevent NexusAssistant to take actions on those warnings.'
          }
        </React.Fragment>
      );

      break;
    case 'allowMissingClicks':
      title = (
        <React.Fragment>
          <Typography color="inherit">Allow missing clicks</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to data sources without clicks.'
          }
        </React.Fragment>
      );

      break;

    case 'allowAbnormallyHighCtr':
      title = (
        <React.Fragment>
          <Typography color="inherit">Allow abnormally high ctr</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {'This is purely to stop warnings related to abnormally high ctr.'}
        </React.Fragment>
      );

      break;
    case 'allowMissingImpressionsYesterday':
      title = (
        <React.Fragment>
          <Typography color="inherit">
            Allow missing impressions yesterday
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to data sources without impressions yesterday.'
          }
        </React.Fragment>
      );

      break;
    case 'allowImpressionsBeforeStartDate':
      title = (
        <React.Fragment>
          <Typography color="inherit">
            Allow impressions before start date
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to impressions before start date and to prevent NexusAssistant to take actions on those warnings.'
          }
        </React.Fragment>
      );

      break;
    case 'allowImpressionsAfterEndDate':
      title = (
        <React.Fragment>
          <Typography color="inherit">
            Allow impressions after end date
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to impressions after end date and to prevent NexusAssistant to take actions on those warnings.'
          }
        </React.Fragment>
      );

      break;

    case 'allowFrontloadedDelivery':
      title = (
        <React.Fragment>
          <Typography color="inherit">Allow front-loaded delivery</Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {'Allows pace up to 150% first 30% of the delivery period.'}
        </React.Fragment>
      );

      break;

    default:
      title = (
        <React.Fragment>
          <Typography color="inherit">Tooltip with HTML</Typography>
          <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
          {"It's very engaging. Right?"}
        </React.Fragment>
      );
  }

  if (!showTooltip) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <div style={{ display: 'inline' }}>
      {/* <LightTooltip title="Add">
        <Button>Light</Button>
      </LightTooltip>
      <BootstrapTooltip title="Add">
        <Button>Bootstrap</Button>
      </BootstrapTooltip> */}
      <HtmlTooltip title={title}>
        <Button
          className=""
          style={{
            width: '3px',
            minWidth: '0px',
            fontSize: '8px',
            padding: '0px',
            marginLeft: '4px'
          }}
        >
          <i className="fas fa-info-circle"></i>
        </Button>
      </HtmlTooltip>
    </div>
  );
}
