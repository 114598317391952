import React from 'react';

import { MenuItem } from '@mui/material';
import DeleteColumnSetButton from './DeleteColumnSetButton';

function PreparedMenuItem({
  currentManagerAccountId,
  name,
  id,
  savedTo,
  handleClose
}) {
  return (
    <MenuItem
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
      id={id}
      onClick={handleClose}
    >
      {name}
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <DeleteColumnSetButton
          _id={id}
          currentManagerAccountId={currentManagerAccountId}
          savedTo={savedTo}
        />
        {/* <CloseIcon style={{ paddingTop: '10px' }} /> */}
      </span>
    </MenuItem>
  );
}

export default PreparedMenuItem;
