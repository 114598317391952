import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/SpinnerSmall';
// import PostItem from './PostItem';
// import PostForm from './PostForm';
import { getPosts } from '../../actions/post';
import ForwardIcon from '@mui/icons-material/Forward';
// import ComboBox from '../comobobox/ComboBox';

import DefaultHead from './item-components/head/DefaultHead';
import GanttHead from './item-components/head/GanttHeadV3';

import WarningItem from './item-components/body/warning-item/WarningItem';
import NotificationsItem from './item-components/body/NotificationsItem';

import DateItem from './item-components/body/dateItem/DateItemV4';
import DateRangeItem from './item-components/body/dateRangeItem/DateRangeItem';
import DefaultItem from './item-components/body/DefaultItem';

import NAItem from './item-components/body/NAItem';
import EmptyItem from './item-components/body/EmptyItem';

import TeamItem from './item-components/body/TeamItem';

import LabelItem from './item-components/body/labelItem/LabelItem';

import PathItem from './item-components/body/PathItem';

// import BudgetItem from './item-components/body/BudgetItemV4';
import IntItem from './item-components/body/IntItem';

import FractionBarItem from './item-components/body/FractionBarItem';

import FractionItem from './item-components/body/FractionItem';
// import FloatItem from './item-components/body/FloatItem';
import FloatItemV3 from './item-components/body/FloatItemV3';
// import StringItem from './item-components/body/StringItemV2';
import TextItem from './item-components/body/TextItem';
import CpmItem from './item-components/body/CpmItem';
import CtrItem from './item-components/body/CtrItem';
import GanttItem from './item-components/body/ganttItem/GanttItemV8';
import GanttSettingsItem from './item-components/body/GanttSettingsItem';
import NameItem from './item-components/body/NameItemV9';
import StatusItem from './item-components/body/StatusItem';
import InvoiceStatusItem from './item-components/body/InvoiceStatusItem';
import TypeItem from './item-components/body/typeItem/TypeItem';
import CheckedAtItem from './item-components/body/CheckedAtItem';

import BooleanCheckboxItem from './item-components/body/BooleanCheckboxItem';

import MathEvalItem from './item-components/body/mathEvalItem/MathEvalItem';
import MathEvalPercentItem from './item-components/body/mathEvalItem/MathEvalPercentItem';
// import ConversionCount from './item-components/body/ConversionCount';

import TaxonomyWidgetsItem from './item-components/body/taxonomyWidgetsItem/TaxonomyWidgetsItem';

import DualBarsItem from './item-components/body/DualBarsItem';

import PaceItem from './item-components/body/PaceItem';

import IconHead from './item-components/head/IconHead';
import CommentsItem from './item-components/body/comment/CommentsItem';

import CheckboxHead from './item-components/head/CheckboxHead';
import CheckboxItem from './item-components/body/CheckboxItem';

import ActionTypeItemSwitch from './item-components/body/actionTypeItem/ActionTypeItemSwitch';
// import ActionTypeItem from './item-components/body/actionTypeItem/ActionTypeItem';

// import SparklineItem from './item-components/body/SparklineItem';
import SparklineItem from './item-components/body/sparklineItem/SparklineItem';

import DefaultFoot from './item-components/foot/DefaultFoot';
import NameFoot from './item-components/foot/NameFoot';
import FloatFoot from './item-components/foot/FloatFoot';
import GanttFoot from './item-components/foot/GanttFoot';
import ColorPicker from './item-components/body/ColorPicker';

import { useSubscription, useMutation } from '@apollo/client';

import {
  documentMovedSubscription,
  copyDocumentMutation
} from './../../graphql/queries';

import TypeItemMenuPopper from './item-components/body/typeItem/TypeItemMenuPopper';
import TypeItemTemplateMenuPopper from './item-components/body/typeItem/template-menu/TypeItemTemplateMenuPopper';

import ViewDialog from './report/views/ViewDialog';
import NewsFeedDialog from './report/newsfeed/NewsFeedDialog';

import TaxonomyDialog from './taxonomy/TaxonomyDialog';

import GraphDialog from './report/graph-dialog/GraphDialog';

import EditDialog from './bulk-edit-menu/EditDialog';
import CopyDialog from './copy-paste-menu/CopyDialog';

import EditSetFiltersDialog from './set-filter-menu/EditSetFiltersDialog';

import { FilterContext } from '../../context/FilterContext';
import { InterfaceContext } from '../../context/InterfaceContext';

import { DialogContext } from '../../context/DialogContext';

import InfoModal from './report/info/InfoModal';

import FieldDialog from './report/info/field-menu/FieldDialog';

import { ColumnContext } from './../../context/ColumnContext';

import { AuthContext } from '../../context/AuthContext';
import { DataContext } from '../../context/DataContext';
import { boolean } from 'mathjs';
import { AccountContext } from '../../context/AccountContext';

import { StyleContext } from '../../context/StyleContext';

import SearchParametersHandler from './SearchParametersHandler';

import RightClickMenuPopper from './right-click-menu/RightClickMenuPopper';

import { useTheme } from '@emotion/react';

// import { InterfaceContext } from '../../context/InterfaceContext';

import {
  allEvalFields,
  sumEvalFields,
  nonSumEvalFields,
  plannedEvalFields,
  allEvalFieldsExceptTotal,
  aggregatedFields
} from './fields';
// import ReportBuilderDialog from './report-builder/ReportBuilderDialog';

function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

const Table = ({
  aggregatedMode,
  dataLevel,
  enqueueSnackbar,

  // addDocument,
  updateDocument,
  currentManagerAccountId,
  changeTableData,
  stylesStoredInRedux,
  range,
  // rawArrayOfData,
  // columns,
  // setColumns,
  selectedDateStart,
  selectedDateStop,
  // checkedBoxes,
  // setCheckedBoxes,

  // user,

  range2,

  refetch,
  setSubscriptionCallStack,
  currentAccountIds,

  // arrayOfData,
  // setArrayOfData,
  // summaryRow,
  setSummaryRow,
  totalListOfPropagationData,
  totalListOfPropagationBasicData,

  names,
  dataSourceNames,

  // copiedDocumentIds,
  // setCopiedDocumentIds,
  copiedDocumentsAccountId,
  setCopiedDocumentsAccountId
}) => {
  // const { copiedDocumentIds, setCopiedDocumentIds, isTableShifted, setIsTableShifted } =
  //   useContext(InterfaceContext);

  const [copyDocument] = useMutation(copyDocumentMutation);

  const tableEl = useRef(null);

  const { subscriptionSkip, currentManagerAccount } =
    useContext(AccountContext);
  const { columns, setColumns } = useContext(ColumnContext);

  const { user } = useContext(AuthContext);
  const {
    hideSelected,
    displayColumnsAsPercent,
    clientId,
    copiedDocumentIds,
    setCopiedDocumentIds,
    isTableShifted,
    setIsTableShifted,
    typeItemAnchorRef,
    setTypeItemAnchorRef,
    typeItemToggledItem,
    setTypeItemToggledItem,
    exportMode,
    openRows,
    setOpenRows,
    setIsOpenRowsOutdated,
    typeItemTemplateMenuOpen,
    setTypeItemTemplateMenuOpen
  } = useContext(InterfaceContext);
  const { customStyleSelected, setCustomStyleSelected } =
    useContext(StyleContext);

  const {
    statusFilter,
    setStatusFilter,
    filterBySelection,
    setFilterBySelection,
    filters,
    setFilters,
    filterByMemberIds,
    setFilterByMemberId,

    filterByLabelId,
    setFilterByLabelId,

    filterByLabelName,
    setFilterByLabelName,

    filterByString,
    setFilterByString,
    filterByName,
    setFilterByName,
    filterByDataSourceName,
    setFilterByDataSourceName,
    filterByDataSource,
    setFilterByDataSource,
    filterByStatus,
    filterByStatusExclusionArray,
    filterByInvoiceStatus,
    filterByInvoiceOrderId,
    // viewDialogOpen,
    // setViewDialogOpen,
    checkedBoxes,
    setCheckedBoxes

    // subscriptionSkip
  } = useContext(FilterContext);

  const {
    infoModalDocumentOpen,
    fieldDialogOpen,
    setInfoModalDocumentOpen,
    graphDialogOpen,
    setGraphDialogOpen,
    // reportBuilderDialogOpen,
    // setReportBuilderDialogOpen,
    setCopyDialogOpen,
    editSetFiltersDialogOpen,
    newsFeedDialogOpen,
    viewDialogOpen,
    setViewDialogOpen,
    taxonomyDialogOpen,
    editDialogOpen,
    copyDialogOpen,
    isTimeboxerOpen,
    setIsTimeboxerOpen
  } = useContext(DialogContext);

  const {
    listOfData: rawArrayOfData,
    arrayOfData,
    setArrayOfData,
    arrayOfDataSourceData,
    setArrayOfDataSourceData
  } = useContext(DataContext);
  // const [copiedDocumentIds, setCopiedDocumentIds] = useState([]);
  // const [copiedDocumentsAccountId, setCopiedDocumentsAccountId] = useState([]);
  // const [openRows, setOpenRows] = useState([]);
  const [previousOpenRows, setPreviousOpenRows] = useState([]);

  const [visColumns, setVisColumns] = useState([]);
  const [sortedColumn, setSortedColumn] = useState({
    column: null,
    order: null
  });

  const [arrayOfDataInCronologicalOrder, setArrayOfDataInCronologicalOrder] =
    useState([]);

  const theme = useTheme();

  useEffect(() => {
    if (isTableShifted && !isTimeboxerOpen) {
      setIsTableShifted(false);
    }
  }, [isTimeboxerOpen]);

  useSubscription(documentMovedSubscription, {
    variables: {
      documentMovedInput: {
        currentAccountIds,
        clientId
      }
    },
    // skip: subscriptionSkip,
    onSubscriptionData: async ({ client, subscriptionData }) => {
      //clear subscription callstacks

      setSubscriptionCallStack([]);

      setIsOpenRowsOutdated(true);

      // const documentMovedId =
      //   subscriptionData &&
      //   subscriptionData.data &&
      //   subscriptionData.data.documentMoved &&
      //   subscriptionData.data.documentMoved.documentMovedId;

      // console.log('documentMovedId: ', documentMovedId);

      // if (documentMovedId) {
      //   // const copyOfOpenRows = [...openRows];
      //   setOpenRows(openRows.filter((row) => row._id !== documentMovedId));
      // }

      refetch();
    }
  });

  useEffect(() => {
    const columnsWithWarningColumn = [...columns];

    setVisColumns([
      'checkbox',
      // 'type',
      'name',
      // 'warning',
      ...columnsWithWarningColumn
    ]);
  }, [columns]);

  // copy documents
  const handleKeyDown = (event) => {
    return;
    // event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (
      ((event.ctrlKey && event.metaKey) || (event.ctrlKey && event.altKey)) &&
      charCode === 's'
    ) {
      console.log('ctr+s+shift');
      // alert("CTRL+S Pressed");
    } else if (
      ((event.ctrlKey && event.metaKey) || (event.ctrlKey && event.altKey)) &&
      charCode === 'c'
    ) {
      event.preventDefault();
      let accountId = null;
      for (let checkedBox of checkedBoxes) {
        if (!accountId) {
          accountId = checkedBox.accountId;
        } else if (accountId !== checkedBox.accountId) {
          enqueueSnackbar(
            'You can only copy documents from the same account.',
            {
              variant: 'default'
              // persist: true
            }
          );
          return;
        }
        if (!accountId) {
          enqueueSnackbar('Issue with accountId.', {
            variant: 'default'
            // persist: true
          });
          return;
        }

        if (
          ![
            'folder',
            'task',
            'subTask',
            'taxonomy',
            'manualDataEntry',
            'facebook_ads_campaign',
            'google_ads_campaign',
            'cm360_campaign',
            'dv360_insertion_order',
            'snapchat_ads_campaign',
            'tiktok_ads_campaign',
            'pinterest_ads_campaign',
            'readpeak_campaign',
            'strossle_campaign',
            'amazon_dsp_order',
            'taboola_ads_campaign',
            'microsoft_advertising_campaign',
            'microsoft_invest_insertion_order',
            'apple_search_ads_campaign',
            'adform_order',
            'linkedin_ads_campaign_group',
            'googleAds'
          ].includes(checkedBox.type)
        ) {
          enqueueSnackbar(`Copy ${checkedBox.type} is not supported.`, {
            variant: 'default'
            // persist: true
          });
          return;
        }
      }

      const docIds = [...checkedBoxes.map((obj) => obj._id)];
      if (hasDuplicates(docIds)) {
        enqueueSnackbar('Error: Includes duplicates', {
          variant: 'default'
          // persist: true
        });
        return;
      }
      setCopiedDocumentsAccountId(accountId);

      setCopiedDocumentIds(docIds);
      enqueueSnackbar('Documents Copied', {
        variant: 'default'
        // persist: true
      });
      // alert('CTRL+C Pressed');
    } else if (
      ((event.ctrlKey && event.metaKey) || (event.ctrlKey && event.altKey)) &&
      charCode === 'v'
    ) {
      event.preventDefault();

      if (checkedBoxes.length !== 1) {
        enqueueSnackbar('Please select ONE folder in order to paste', {
          variant: 'default'
          // persist: true
        });
        return;
      }

      if (checkedBoxes[0].accountId !== copiedDocumentsAccountId) {
        enqueueSnackbar('You cannot paste documents into a different account', {
          variant: 'default'
          // persist: true
        });
        return;
      }

      if (copiedDocumentIds.includes(checkedBoxes[0]._id)) {
        enqueueSnackbar('You cannot paste a document into itself', {
          variant: 'default'
          // persist: true
        });
        return;
      }

      if (!['account', 'folder'].includes(checkedBoxes[0].documentType)) {
        enqueueSnackbar(
          'You can only paste documents into an account or a folder',
          {
            variant: 'default'
            // persist: true
          }
        );
        return;
      }

      if (hasDuplicates(copiedDocumentIds)) {
        enqueueSnackbar('Error: Includes duplicates', {
          variant: 'default'
          // persist: true
        });
        return;
      }

      const documentsToBeCopied = rawArrayOfData.filter((obj) =>
        copiedDocumentIds.includes(obj._id)
      );
      const documentsToBeCopiedTypes = documentsToBeCopied.map(
        (obj) => obj.type
      );
      console.log('documentsToBeCopiedTypes: ', documentsToBeCopiedTypes);
      if (
        checkedBoxes[0].documentType !== 'folder' &&
        documentsToBeCopiedTypes.filter((str) => str !== 'folder')?.length
      ) {
        enqueueSnackbar('You can only paste folders into an account.', {
          variant: 'default'
          // persist: true
        });
        return;
      }

      setCopyDialogOpen(true);

      return;

      // const copyDocumentInput = {
      //   managerAccountId: currentManagerAccountId,
      //   accountId: copiedDocumentsAccountId,
      //   checkedDocumentId: checkedBoxes[0]._id,
      //   documentIds: copiedDocumentIds,
      //   level: checkedBoxes[0].level,
      //   startDate: selectedDateStart,
      //   endDate: selectedDateStop
      // };
      // copyDocument({ variables: { input: { ...copyDocumentInput } } });
      // enqueueSnackbar('Pasting documents', {
      //   variant: 'default'
      //   // persist: true
      // });
      // alert('CTRL+V Pressed');
    }
  };

  // pase documents

  const showItemsHandler = (e, item, open) => {
    // console.log('follow>row222>showItemsHandler');
    if (!open) {
      const newItem = { _id: item._id, accountId: item.accountId, childOf: [] };
      for (let i = item.level; i > 0; i--) {
        newItem[`level_${i}_container_id`] = item[`level_${i}_container_id`];
        newItem?.childOf?.push(item[`level_${i}_container_id`]);
      }
      newItem[`level_${item.level}_container_id`] = item._id;
      return setOpenRows([...openRows, newItem]);

      // switch (item.level) {
      //   case 1:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item._id,
      //         level_1_container_id: item._id
      //       }
      //     ]);

      //     break;
      //   case 2:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 3:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 4:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 5:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item.level_4_container_id,
      //         level_5_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 6:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item.level_4_container_id,
      //         level_5_container_id: item.level_5_container_id,
      //         level_6_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 7:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item.level_4_container_id,
      //         level_5_container_id: item.level_5_container_id,
      //         level_6_container_id: item.level_6_container_id,
      //         level_7_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 8:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item.level_4_container_id,
      //         level_5_container_id: item.level_5_container_id,
      //         level_6_container_id: item.level_6_container_id,
      //         level_7_container_id: item.level_7_container_id,
      //         level_8_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 9:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item.level_4_container_id,
      //         level_5_container_id: item.level_5_container_id,
      //         level_6_container_id: item.level_6_container_id,
      //         level_7_container_id: item.level_7_container_id,
      //         level_8_container_id: item.level_8_container_id,
      //         level_9_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 10:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item.level_4_container_id,
      //         level_5_container_id: item.level_5_container_id,
      //         level_6_container_id: item.level_6_container_id,
      //         level_7_container_id: item.level_7_container_id,
      //         level_8_container_id: item.level_8_container_id,
      //         level_9_container_id: item.level_9_container_id,
      //         level_10_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 11:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item.level_4_container_id,
      //         level_5_container_id: item.level_5_container_id,
      //         level_6_container_id: item.level_6_container_id,
      //         level_7_container_id: item.level_7_container_id,
      //         level_8_container_id: item.level_8_container_id,
      //         level_9_container_id: item.level_9_container_id,
      //         level_10_container_id: item.level_10_container_id,
      //         level_11_container_id: item._id
      //       }
      //     ]);
      //     break;
      //   case 12:
      //     return setOpenRows([
      //       ...openRows,
      //       {
      //         _id: item._id,
      //         accountId: item.accountId,
      //         level_1_container_id: item.level_1_container_id,
      //         level_2_container_id: item.level_2_container_id,
      //         level_3_container_id: item.level_3_container_id,
      //         level_4_container_id: item.level_4_container_id,
      //         level_5_container_id: item.level_5_container_id,
      //         level_6_container_id: item.level_6_container_id,
      //         level_7_container_id: item.level_7_container_id,
      //         level_8_container_id: item.level_8_container_id,
      //         level_9_container_id: item.level_9_container_id,
      //         level_10_container_id: item.level_10_container_id,
      //         level_11_container_id: item.level_11_container_id,
      //         level_12_container_id: item._id
      //       }
      //     ]);
      //     break;
      // }
      // setOpenRows([...openRows, item._id]);
    } else {
      // console.log('showItemsHandler>!open: ', open);
      // console.log('openRows: ', openRows);
      // const openRowsIds = openRows.map((obj) => obj._id);
      // const latestOpenRows = rawArrayOfData
      //   .filter((obj) => openRowsIds.includes(obj._id))
      //   .map((item) => {
      //     const newItem = { _id: item._id, accountId: item.accountId };
      //     for (let i = item.level; i > 0; i--) {
      //       newItem[`level_${i}_container_id`] =
      //         item[`level_${i}_container_id`];
      //     }
      //     newItem[`level_${item.level}_container_id`] = item._id;
      //     return newItem;
      //   });
      // console.log('openRows: ', openRows);
      setOpenRows(
        openRows.filter((openRow) => {
          // return openRow[`level_${item.level}_container_id`] !== item._id;
          return !openRow?.childOf?.includes(item._id);
        })
      );
      // switch (item.level) {
      //   case 1:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_1_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 2:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_2_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 3:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_3_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 4:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_4_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 5:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_5_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 6:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_6_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 7:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_7_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 8:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_8_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 9:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_9_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 10:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_10_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 11:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_11_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      //   case 12:
      //     setOpenRows(
      //       openRows.filter((openRow) => {
      //         return openRow['level_12_container_id'] !== item._id;
      //       })
      //     );
      //     break;
      // }
    }
  };

  const showAllSubItemsHandler = async (e, item, open) => {
    if (!open) {
      let itemsToOpen = rawArrayOfData?.filter(
        (row) =>
          (['account', 'folder']?.includes(row.type) || row?.isSet) &&
          item?.childDocumentIds?.includes(row?._id)
      );
      itemsToOpen?.unshift(item);
      // console.log('itemsToOpen: ', itemsToOpen);

      let newOpenRows = [...openRows];
      for (let item of itemsToOpen || []) {
        // await showItemsHandler({}, item, false);

        const newItem = {
          _id: item?._id,
          accountId: item?.accountId,
          childOf: []
        };
        for (let i = item.level; i > 0; i--) {
          newItem[`level_${i}_container_id`] =
            item?.[`level_${i}_container_id`];
          newItem?.childOf?.push(item?.[`level_${i}_container_id`]);
        }
        newItem[`level_${item.level}_container_id`] = item?._id;
        // return setOpenRows([...openRows, newItem]);
        newOpenRows?.push(newItem);
      }

      return setOpenRows(newOpenRows);
    } else {
      showItemsHandler({}, item, true);
    }
  };

  // ######## clear open rows on clear filter #############

  useEffect(() => {
    if (filters.length === 0 && rawArrayOfData) {
      setOpenRows([...previousOpenRows]);
      setPreviousOpenRows([]);
    } else if (filters.length !== 0 && rawArrayOfData) {
      setPreviousOpenRows([...openRows]);
    }
  }, [filters]);

  // #############################################

  useEffect(() => {
    if (
      (filters.includes('filter_by_team') ||
        filters.includes('filter_by_label') ||
        filters.includes('filter_by_label_name') ||
        filters.includes('filter_by_name') ||
        filters.includes('filter_by_data_source_name') ||
        filters.includes('filter_by_data_source') ||
        filters.includes('filter_by_status') ||
        filters.includes('filter_by_invoice_status') ||
        filters.includes('filter_by_invoice_order_id') ||
        filters.includes('filter_by_warnings') ||
        filters.includes('filter_by_pace') ||
        (filters.includes('filter_by_selection') &&
          !displayArrayOfData.length) || // ??????
        (filters.includes('filter_by_selection') && viewDialogOpen)) &&
      rawArrayOfData.length
    ) {
      const copyOfRawArrayOfData = [...rawArrayOfData];

      let filteredArraytoLoad3 = copyOfRawArrayOfData;

      if (filters.includes('filter_by_selection')) {
        //##################
        // const filterBySelectionIds = filterBySelection.map((obj) => obj._id);
        const filterBySelectionIds = filterBySelection;

        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          const rowLevelIds = [];
          for (let i = 1; i < 11; i++) {
            if (row['level_' + i + '_container_id'])
              rowLevelIds.push(row['level_' + i + '_container_id']);
          }

          const filteredArray = rowLevelIds.filter((value) =>
            filterBySelectionIds.includes(value)
          );
          return filteredArray.length > 0;
        });

        //#########################################
      }

      if (filters.includes('filter_by_team')) {
        const membersKey = filters.length > 1 ? '_members' : 'members';

        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (row[membersKey]) {
            return row[membersKey].some((teamUser) =>
              filterByMemberIds?.includes(teamUser._id)
            );
          }
        });
      }

      if (filters.includes('filter_by_label')) {
        const labelsKey = filters.length > 1 ? '_labels' : 'labels';

        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (row[labelsKey]) {
            return row[labelsKey].some(
              (label) => label._id === filterByLabelId
            );
          }
        });
      }

      if (filters.includes('filter_by_label_name')) {
        const labelsKey = filters.length > 1 ? '_labels' : 'labels';

        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (row[labelsKey]) {
            return row[labelsKey].some(
              (label) => label.name === filterByLabelName
            );
          }
        });
      }

      if (filters.includes('filter_by_name')) {
        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (row.name) {
            // if(filterByName){

            return row.name
              .toLowerCase()
              .includes(filterByName && filterByName.toLowerCase());
            // }
          }
        });
      }

      if (filters.includes('filter_by_invoice_order_id')) {
        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (row.invoiceOrderId) {
            // if(filterByName){

            return row.invoiceOrderId
              .toLowerCase()
              .includes(filterByInvoiceOrderId?.toLowerCase());
            // }
          }
        });
      }

      if (filters.includes('filter_by_data_source_name')) {
        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (row.name) {
            return (
              row.name
                .toLowerCase()
                .includes(
                  filterByDataSourceName && filterByDataSourceName.toLowerCase()
                ) &&
              [
                'facebook_ads_campaign',
                'google_ads_campaign',
                'cm360_campaign',
                'dv360_insertion_order',
                'snapchat_ads_campaign',
                'tiktok_ads_campaign',
                'pinterest_ads_campaign',
                'readpeak_campaign',
                'strossle_campaign',
                'amazon_dsp_order',
                'taboola_ads_campaign',
                'microsoft_advertising_campaign',
                'microsoft_invest_insertion_order',
                'apple_search_ads_campaign',
                'adform_order',
                'linkedin_ads_campaign_group'
              ].includes(row.type)
            );
          }
        });
      }

      if (filters.includes('filter_by_data_source')) {
        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (row.type && !row.isSetChild) {
            if (row.isSet) {
              return filterByDataSource.some(
                (dataSource) => row.type === dataSource.value + '_set'
              );
            } else {
              return filterByDataSource.some(
                (dataSource) => row.type === dataSource.value
              );
            }

            // }
          }
        });
      }

      if (filters.includes('filter_by_status')) {
        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (
            true
            // row.invoiceStatus
          ) {
            return (
              filterByStatus.some((option) => row.status === option.value) &&
              !filterByStatusExclusionArray.includes(row.type) &&
              !['account'].includes(row.documentType)
            );
            // }
          }
        });
      }

      if (filters.includes('filter_by_invoice_status')) {
        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          if (
            true
            // row.invoiceStatus
          ) {
            return (
              filterByInvoiceStatus.some(
                (option) => row.invoiceStatus === option.value
              ) &&
              (([
                'facebook_ads_campaign',
                'google_ads_campaign',
                'cm360_campaign',
                'dv360_insertion_order',
                'snapchat_ads_campaign',
                'tiktok_ads_campaign',
                'pinterest_ads_campaign',
                'readpeak_campaign',
                'strossle_campaign',
                'amazon_dsp_order',
                'taboola_ads_campaign',
                'microsoft_advertising_campaign',
                'microsoft_invest_insertion_order',
                'apple_search_ads_campaign',
                'adform_order',
                'linkedin_ads_campaign_group',
                'manualDataEntry'
              ].includes(row.type) &&
                !row.isSetChild) ||
                row.isSet)
            );
            // }
          }
        });
      }

      if (filters.includes('filter_by_warnings')) {
        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          return row && row.warnings && row.warnings.length > 0;
        });
      }

      if (filters.includes('filter_by_pace')) {
        filteredArraytoLoad3 = filteredArraytoLoad3.filter((row) => {
          return (
            row &&
            !row.isSetChild &&
            row.pace !== null &&
            (Boolean(row.pace) || typeof row.pace === 'number') &&
            Boolean(row.budgetUntillNow)
          );
        });
      }
      // console.log('filteredArraytoLoad3: ', filteredArraytoLoad3);
      const newOpenRows = [];
      for (let i = 0; i < filteredArraytoLoad3.length; i++) {
        const item = filteredArraytoLoad3[i];
        // console.log('debug93>item: ', item);
        let newItem = {
          _id: item._id,
          accountId: item.accountId,
          childOf: [],
          level: item.level
        };
        for (let y = 0; y < item.level; y++) {
          newItem[`level_${i}_container_id`] = item[`level_${y}_container_id`];
          if (item[`level_${y}_container_id`]) {
            newItem?.childOf?.push(item[`level_${y}_container_id`]);
          }
        }
        // console.log('newItem: ', newItem);
        // console.log('debug93>newItem: ', newItem);
        // // newItem[`level_${item.level}_container_id`] = item._id;
        // const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

        // for (let level of levels) {
        //   let newItem2_ = { ...newItem };
        //   console.log('debug93>level: ', level);

        //   if (newItem2_.level > level) {
        //     newOpenRows.push({
        //       ...newItem,
        //       _id: item['level_' + level + '_container_id']
        //       // level_1_container_id:
        //       //   filteredArraytoLoad3[i]['level_1_container_id'],
        //       // level_2_container_id:
        //       //   filteredArraytoLoad3[i]['level_2_container_id']
        //     });

        //     // console.log('test55>newItem_row1141: ', newItem2_);
        //     // console.log('level: ', level);

        //     // for (let x of levels.filter(
        //     //   (number) => number <= newItem2_.level
        //     // )) {
        //     //   newItem2_._id = item['level_' + level + '_container_id'];
        //     //   newItem2_['level_' + x + '_container_id'] =
        //     //     item['level_' + x + '_container_id'];
        //     // }
        //     // console.log('test55>newItem_row1147: ', newItem2_);
        //     // console.log('debug95>newItem_: ', newItem2_);
        //     // newOpenRows.push({
        //     //   ...newItem2_
        //     // });
        //   }
        // }
        // console.log('newOpenRows: ', newOpenRows);

        // newOpenRows.push(newItem);

        if (filteredArraytoLoad3[i].level > 1) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 1),
            _id: filteredArraytoLoad3[i]['level_' + 1 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_' + 1 + '_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 2) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 2),
            _id: filteredArraytoLoad3[i]['level_' + 2 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 3) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 3),
            _id: filteredArraytoLoad3[i]['level_' + 3 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 4) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 4),
            _id: filteredArraytoLoad3[i]['level_' + 4 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 5) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 5),
            _id: filteredArraytoLoad3[i]['level_' + 5 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id'],
            level_5_container_id:
              filteredArraytoLoad3[i]['level_5_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 6) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 6),
            _id: filteredArraytoLoad3[i]['level_' + 6 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id'],
            level_5_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_6_container_id:
              filteredArraytoLoad3[i]['level_6_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 7) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 7),
            _id: filteredArraytoLoad3[i]['level_' + 7 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id'],
            level_5_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_6_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_7_container_id:
              filteredArraytoLoad3[i]['level_7_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 8) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 8),
            _id: filteredArraytoLoad3[i]['level_' + 8 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id'],
            level_5_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_6_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_7_container_id:
              filteredArraytoLoad3[i]['level_7_container_id'],
            level_8_container_id:
              filteredArraytoLoad3[i]['level_8_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 9) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 9),
            _id: filteredArraytoLoad3[i]['level_' + 9 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id'],
            level_5_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_6_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_7_container_id:
              filteredArraytoLoad3[i]['level_7_container_id'],
            level_8_container_id:
              filteredArraytoLoad3[i]['level_8_container_id'],
            level_9_container_id:
              filteredArraytoLoad3[i]['level_9_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 10) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 10),
            _id: filteredArraytoLoad3[i]['level_' + 10 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id'],
            level_5_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_6_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_7_container_id:
              filteredArraytoLoad3[i]['level_7_container_id'],
            level_8_container_id:
              filteredArraytoLoad3[i]['level_8_container_id'],
            level_9_container_id:
              filteredArraytoLoad3[i]['level_9_container_id'],
            level_10_container_id:
              filteredArraytoLoad3[i]['level_10_container_id']
          });
        }

        if (filteredArraytoLoad3[i].level > 11) {
          newOpenRows.push({
            childOf: newItem.childOf.slice(0, 11),
            _id: filteredArraytoLoad3[i]['level_' + 11 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id'],
            level_5_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_6_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_7_container_id:
              filteredArraytoLoad3[i]['level_7_container_id'],
            level_8_container_id:
              filteredArraytoLoad3[i]['level_8_container_id'],
            level_9_container_id:
              filteredArraytoLoad3[i]['level_9_container_id'],
            level_10_container_id:
              filteredArraytoLoad3[i]['level_10_container_id'],
            level_11_container_id:
              filteredArraytoLoad3[i]['level_11_container_id']
          });
        }
        if (filteredArraytoLoad3[i].level > 12) {
          newOpenRows.push({
            childOf: newItem.childOf,
            _id: filteredArraytoLoad3[i]['level_' + 12 + '_container_id'],
            level_1_container_id:
              filteredArraytoLoad3[i]['level_1_container_id'],
            level_2_container_id:
              filteredArraytoLoad3[i]['level_2_container_id'],
            level_3_container_id:
              filteredArraytoLoad3[i]['level_3_container_id'],
            level_4_container_id:
              filteredArraytoLoad3[i]['level_4_container_id'],
            level_5_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_6_container_id:
              filteredArraytoLoad3[i]['level_5_container_id'],
            level_7_container_id:
              filteredArraytoLoad3[i]['level_7_container_id'],
            level_8_container_id:
              filteredArraytoLoad3[i]['level_8_container_id'],
            level_9_container_id:
              filteredArraytoLoad3[i]['level_9_container_id'],
            level_10_container_id:
              filteredArraytoLoad3[i]['level_10_container_id'],
            level_11_container_id:
              filteredArraytoLoad3[i]['level_11_container_id'],
            level_12_container_id:
              filteredArraytoLoad3[i]['level_12_container_id']
          });
        }

        // switch (filteredArraytoLoad3[i].level) {
        //   case 2:
        //     newOpenRows.push({
        //       _id: filteredArraytoLoad3[i][
        //         'level_' + filteredArraytoLoad3[i].level + '_contaienr_id'
        //       ],
        //       level_2_container_id: filteredArraytoLoad3[i]
        //     });
        // }
      }

      const newOpenRows2 = [...openRows, ...newOpenRows];

      const uniquefilteredNewOpenRows = newOpenRows2.filter(
        (v, i, a) => a.findIndex((t) => t._id === v._id) === i
      );
      // setPreviousOpenRows([...openRows]);
      setOpenRows(uniquefilteredNewOpenRows);
    }
  }, [
    rawArrayOfData,
    filters,
    // teamRows,
    filterByMemberIds,
    filterByLabelId,
    filterByLabelName,
    filterByName,
    filterByDataSourceName,
    filterByDataSource,
    filterByStatus,
    filterByStatusExclusionArray,
    filterByInvoiceStatus,
    filterByInvoiceOrderId
  ]);
  // console.log('debug93>openRows: ', openRows);
  useEffect(() => {
    // const sortedRawArrayOfData = rawArrayOfData;

    const sortedRawArrayOfData = [];

    const level_1 = rawArrayOfData.filter((obj) => {
      return obj.level === 1;
    });
    for (let a = 0; a < level_1.length; a++) {
      sortedRawArrayOfData.push(level_1[a]);
      const level_2 = rawArrayOfData.filter((obj) => {
        return obj.level === 2 && obj.level_1_container_id === level_1[a]._id;
      });

      for (let b = 0; b < level_2.length; b++) {
        sortedRawArrayOfData.push(level_2[b]);
        const level_3 = rawArrayOfData.filter((obj) => {
          return obj.level === 3 && obj.level_2_container_id === level_2[b]._id;
        });

        // ###### push level 3 and filter level 4 #############
        for (let c = 0; c < level_3.length; c++) {
          sortedRawArrayOfData.push(level_3[c]);
          const level_4 = rawArrayOfData.filter((obj) => {
            return (
              obj.level === 4 && obj.level_3_container_id === level_3[c]._id
            );
          });

          // ###### push level 4 and filter level 5 #############
          for (let d = 0; d < level_4.length; d++) {
            sortedRawArrayOfData.push(level_4[d]);
            const level_5 = rawArrayOfData.filter((obj) => {
              return (
                obj.level === 5 && obj.level_4_container_id === level_4[d]._id
              );
            });

            // ###### push level 5 and filter level 6 #############
            for (let e = 0; e < level_5.length; e++) {
              sortedRawArrayOfData.push(level_5[e]);
              const level_6 = rawArrayOfData.filter((obj) => {
                return (
                  obj.level === 6 && obj.level_5_container_id === level_5[e]._id
                );
              });

              // ###### push level 6 and filter level 7 #############
              for (let f = 0; f < level_6.length; f++) {
                sortedRawArrayOfData.push(level_6[f]);
                const level_7 = rawArrayOfData.filter((obj) => {
                  return (
                    obj.level === 7 &&
                    obj.level_6_container_id === level_6[f]._id
                  );
                });

                // ###### push level 7 and filter level 8 #############
                for (let g = 0; g < level_7.length; g++) {
                  sortedRawArrayOfData.push(level_7[g]);
                  const level_8 = rawArrayOfData.filter((obj) => {
                    return (
                      obj.level === 8 &&
                      obj.level_7_container_id === level_7[g]._id
                    );
                  });

                  // ###### push level 8 and filter level 9 #############
                  for (let h = 0; h < level_8.length; h++) {
                    sortedRawArrayOfData.push(level_8[h]);
                    const level_9 = rawArrayOfData.filter((obj) => {
                      return (
                        obj.level === 9 &&
                        obj.level_8_container_id === level_8[h]._id
                      );
                    });

                    // ###### push level 9 and filter level 10 #############
                    for (let i = 0; i < level_9.length; i++) {
                      sortedRawArrayOfData.push(level_9[i]);
                      const level_10 = rawArrayOfData.filter((obj) => {
                        return (
                          obj.level === 10 &&
                          obj.level_9_container_id === level_9[i]._id
                        );
                      });

                      // ###### push level 10 and filter level 11 #############
                      for (let j = 0; j < level_10.length; j++) {
                        sortedRawArrayOfData.push(level_10[j]);
                        const level_11 = rawArrayOfData.filter((obj) => {
                          return (
                            obj.level === 11 &&
                            obj.level_10_container_id === level_10[j]._id
                          );
                        });

                        for (let k = 0; k < level_11.length; k++) {
                          sortedRawArrayOfData.push(level_11[k]);
                          const level_12 = rawArrayOfData.filter((obj) => {
                            return (
                              obj.level === 12 &&
                              obj.level_11_container_id === level_11[k]._id
                            );
                          });

                          for (let l = 0; l < level_12.length; l++) {
                            sortedRawArrayOfData.push(level_12[l]);
                            const level_13 = rawArrayOfData.filter((obj) => {
                              return (
                                obj.level === 13 &&
                                obj.level_12_container_id === level_12[l]._id
                              );
                            });

                            // add next level.
                          }
                          // add next level.
                        }
                      }

                      // add next level.
                    }

                    // add next level.
                  }
                }
              }
            }
          }
        }

        //   //################
      }
    }

    // const sortedRawArrayOfData = rawArrayOfData.sort(compareValues('name'));
    const initial_array = sortedRawArrayOfData.filter((obj) => {
      return obj.visible === true;
    });

    let arrayToLoad = [];

    arrayToLoad = [...arrayToLoad, ...initial_array];
    for (let i = 0; i < sortedRawArrayOfData.length; i++) {
      if (
        sortedRawArrayOfData &&
        sortedRawArrayOfData[i] &&
        openRows.findIndex(
          (openRow) => openRow['_id'] === sortedRawArrayOfData[i]._id
        ) !== -1
      ) {
        const filteredItems = sortedRawArrayOfData.filter((obj) => {
          switch (sortedRawArrayOfData[i].level) {
            case 1:
              return (
                obj['level_1_container_id'] ===
                  sortedRawArrayOfData[i].level_1_container_id &&
                obj['level'] === 2
              );
              break;
            case 2:
              return (
                obj['level_2_container_id'] ===
                  sortedRawArrayOfData[i].level_2_container_id &&
                obj['level'] === 3
              );
              break;
            case 3:
              return (
                obj['level_3_container_id'] ===
                  sortedRawArrayOfData[i].level_3_container_id &&
                obj['level'] === 4
              );
              break;
            case 4:
              return (
                obj['level_4_container_id'] ===
                  sortedRawArrayOfData[i].level_4_container_id &&
                obj['level'] === 5
              );
              break;
            case 5:
              return (
                obj['level_5_container_id'] ===
                  sortedRawArrayOfData[i].level_5_container_id &&
                obj['level'] === 6
              );
              break;
            case 6:
              return (
                obj['level_6_container_id'] ===
                  sortedRawArrayOfData[i].level_6_container_id &&
                obj['level'] === 7
              );
              break;
            case 7:
              return (
                obj['level_7_container_id'] ===
                  sortedRawArrayOfData[i].level_7_container_id &&
                obj['level'] === 8
              );
              break;
            case 8:
              return (
                obj['level_8_container_id'] ===
                  sortedRawArrayOfData[i].level_8_container_id &&
                obj['level'] === 9
              );
              break;
            case 9:
              return (
                obj['level_9_container_id'] ===
                  sortedRawArrayOfData[i].level_9_container_id &&
                obj['level'] === 10
              );
              break;
            case 10:
              return (
                obj['level_10_container_id'] ===
                  sortedRawArrayOfData[i].level_10_container_id &&
                obj['level'] === 11
              );
              break;

            case 11:
              return (
                obj['level_11_container_id'] ===
                  sortedRawArrayOfData[i].level_11_container_id &&
                obj['level'] === 12
              );
              break;

            case 12:
              return (
                obj['level_12_container_id'] ===
                  sortedRawArrayOfData[i].level_12_container_id &&
                obj['level'] === 13
              );
              break;
          }
        });

        const indexOf = arrayToLoad.findIndex(
          (item) => item._id == sortedRawArrayOfData[i]._id
        );

        arrayToLoad.splice(indexOf + 1, 0, ...filteredItems);
      }
    }

    // ############## filter by team #####################
    let filteredArrayOfDataFinal = [];

    let filteredArrayOfData = [...rawArrayOfData];

    let arrayOfArraysOfFilteredIds = [];
    let arrayOfArraysOfFilteredArrays = [];

    if (filters.includes('filter_by_team') && arrayToLoad) {
      // const copyOfRawArrayOfData = [...rawArrayOfData];

      const membersKey = filters.length > 1 ? '_members' : 'members';
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((row) => {
          if (row[membersKey]) {
            return row[membersKey].some((teamUser) =>
              filterByMemberIds?.includes(teamUser._id)
            );
          }
        })
      );
    }

    // ############# label id ###########

    if (filters.includes('filter_by_label') && arrayToLoad?.length) {
      const labelsKey = filters.length > 1 ? '_labels' : 'labels';
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((row) => {
          if (row[labelsKey]) {
            return row[labelsKey].some(
              (label) => label._id === filterByLabelId
            );
          }
        })
      );
    }

    // ############# label name ###########

    if (filters.includes('filter_by_label_name') && arrayToLoad) {
      // const copyOfRawArrayOfData = [...rawArrayOfData];

      const labelsKey = filters.length > 1 ? '_labels' : 'labels';
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((row) => {
          if (row[labelsKey]) {
            return row[labelsKey].some(
              (label) => label.name === filterByLabelName
            );
          }
        })
      );
    }

    if (filters.includes('filter_by_name')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((obj) => {
          if (filterByName) {
            return obj['name']
              .toLowerCase()
              .includes(filterByName && filterByName.toLowerCase());
          }
        })
      );
    }

    if (filters.includes('filter_by_invoice_order_id')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((obj) => {
          if (filterByInvoiceOrderId) {
            return obj['invoiceOrderId']
              ?.toLowerCase()
              .includes(filterByInvoiceOrderId?.toLowerCase());
          }
        })
      );
    }

    if (filters.includes('filter_by_data_source_name')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((obj) => {
          if (filterByDataSourceName) {
            return (
              obj['name']
                .toLowerCase()
                .includes(
                  filterByDataSourceName && filterByDataSourceName.toLowerCase()
                ) &&
              [
                'facebook_ads_campaign',
                'google_ads_campaign',
                'cm360_campaign',
                'dv360_insertion_order',
                'snapchat_ads_campaign',
                'tiktok_ads_campaign',
                'pinterest_ads_campaign',
                'readpeak_campaign',
                'strossle_campaign',
                'amazon_dsp_order',
                'taboola_ads_campaign',
                'microsoft_advertising_campaign',
                'microsoft_invest_insertion_order',
                'apple_search_ads_campaign',
                'adform_order',
                'linkedin_ads_campaign_group'
              ].includes(obj['type'])
            );
          }
        })
      );
    }

    if (filters.includes('filter_by_data_source')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((row) => {
          // return filterByDataSource.some(
          //   (dataSource) => row.type === dataSource.value
          // );

          if (row.type && !row.isSetChild) {
            if (row.isSet) {
              return filterByDataSource.some(
                (dataSource) => row.type === dataSource.value + '_set'
              );
            } else {
              return filterByDataSource.some(
                (dataSource) => row.type === dataSource.value
              );
            }

            // }
          }
        })
      );
    }

    if (filters.includes('filter_by_status')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((row) => {
          return (
            filterByStatus.some((option) => row.status === option.value) &&
            !filterByStatusExclusionArray.includes(row.type) &&
            !['account'].includes(row.documentType)
          );
        })
      );
    }

    if (filters.includes('filter_by_invoice_status')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((row) => {
          return (
            filterByInvoiceStatus.some(
              (option) => row.invoiceStatus === option.value
            ) &&
            (([
              'facebook_ads_campaign',
              'google_ads_campaign',
              'cm360_campaign',
              'dv360_insertion_order',
              'snapchat_ads_campaign',
              'tiktok_ads_campaign',
              'pinterest_ads_campaign',
              'readpeak_campaign',
              'strossle_campaign',
              'amazon_dsp_order',
              'taboola_ads_campaign',
              'microsoft_advertising_campaign',
              'microsoft_invest_insertion_order',
              'apple_search_ads_campaign',
              'adform_order',
              'linkedin_ads_campaign_group',
              'manualDataEntry'
            ].includes(row.type) &&
              !row.isSetChild) ||
              row.isSet)
          );
        })
      );
    }

    // filterByName

    if (filters.includes('filter_by_warnings')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((obj) => {
          return obj && obj.warnings && obj.warnings.length > 0;
        })
      );
    }

    if (filters.includes('filter_by_pace')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((obj) => {
          return (
            obj &&
            !obj.isSetChild &&
            obj.pace !== null &&
            (Boolean(obj.pace) || typeof obj.pace === 'number') &&
            Boolean(obj.budgetUntillNow)
          );
        })
      );
    }

    if (filters.includes('filter_by_selection')) {
      arrayOfArraysOfFilteredArrays.push(
        filteredArrayOfData.filter((obj) => filterBySelection.includes(obj._id))
      );
    }

    arrayOfArraysOfFilteredIds = [];
    for (let filteredArray of arrayOfArraysOfFilteredArrays) {
      let newArr = [];
      for (let row of filteredArray) {
        // console.log('row1: ', row);
        newArr = [
          ...newArr,
          ...filteredArrayOfData
            .filter(
              (obj) => obj['level_' + row.level + '_container_id'] === row._id
            )
            .map((obj) => obj._id)
        ];
      }
      arrayOfArraysOfFilteredIds.push(newArr);
    }

    let intersectionArray = arrayOfArraysOfFilteredIds?.pop() || [];
    for (let arr of arrayOfArraysOfFilteredIds) {
      intersectionArray = intersectionArray.filter((value) =>
        arr.includes(value)
      );
    }

    filteredArrayOfDataFinal = filteredArrayOfData.filter((obj) =>
      intersectionArray?.includes(obj._id)
    );

    // setOpenRows(rawArrayOfData);

    // ############## filter by selection ################
    if (
      filters.includes('filter_by_selection') ||
      filters.includes('filter_by_team') ||
      filters.includes('filter_by_label') ||
      filters.includes('filter_by_label_name') ||
      filters.includes('filter_by_name') ||
      filters.includes('filter_by_data_source_name') ||
      filters.includes('filter_by_data_source') ||
      filters.includes('filter_by_status') ||
      filters.includes('filter_by_invoice_status') ||
      filters.includes('filter_by_invoice_order_id') ||
      filters.includes('filter_by_warnings') ||
      filters.includes('filter_by_pace')
    ) {
      let filteredArrayToLoad = [];
      for (let i = 0; i < arrayToLoad.length; i++) {
        for (let x = 0; x < filteredArrayOfDataFinal.length; x++) {
          if (
            arrayToLoad[i][
              'level_' + filteredArrayOfDataFinal[x].level + '_container_id'
            ] === filteredArrayOfDataFinal[x]._id
          ) {
            filteredArrayToLoad.push(arrayToLoad[i]);
          } else if (
            !['folder', 'to_do_list'].includes(
              filteredArrayOfDataFinal[x].type
            ) &&
            filteredArrayOfDataFinal[x]._id === arrayToLoad[i]._id
          ) {
            filteredArrayToLoad.push(arrayToLoad[i]);
          }
        }
      }

      const uniquefilteredArrayToLoad = filteredArrayToLoad.filter(
        (v, i, a) => a.findIndex((t) => t._id === v._id) === i
      );

      arrayToLoad = uniquefilteredArrayToLoad;
    }

    // ###########################################

    if (
      filters?.length &&
      filters?.includes('filter_by_pace') &&
      filters?.includes('filter_by_data_source')
    ) {
      arrayToLoad = arrayToLoad.filter(
        (row) =>
          row &&
          !row.isSetChild &&
          row.pace !== null &&
          (Boolean(row.pace) || typeof row.pace === 'number') &&
          Boolean(row.budgetUntillNow)
      );
    }
    setArrayOfDataInCronologicalOrder([...arrayToLoad]);
    // setArrayOfData([...arrayToLoad]);

    switch (true) {
      case !Boolean(sortedColumn?.column):
        setArrayOfData([...(arrayToLoad || [])]);
        break;

      case sortedColumn?.column === 'dateRange':
        setArrayOfData([
          ...(arrayToLoad?.sort(
            compareValues('startDate', sortedColumn?.order)
          ) || [])
        ]);
        break;

      default:
        setArrayOfData([
          ...(arrayToLoad?.sort(
            compareValues(sortedColumn?.column, sortedColumn?.order)
          ) || [])
        ]);
    }

    // // ###### populate array of data to get child sources #######

    // const dataSourceDataList = [];

    // for (let dataObj of arrayOfData){
    //   for (let rawDataObj of rawArrayOfData){
    //     if(['facebook_ads_ad_set', 'google_ads_ad_group', 'ManualDataEntry'].includes(rawDataObj.type)){
    //       if(rawDataObj['level_' + dataObj.level + '_container_id'] === dataObj._id){
    //         dataSourceDataList.push(rawDataObj);
    //       }
    //     }
    //   }
    // }

    // const uniquedataSourceDataList = dataSourceDataList.filter(
    //   (v, i, a) => a.findIndex((t) => t._id === v._id) === i
    // );

    // console.log('uniquedataSourceDataList: ', uniquedataSourceDataList);

    // // ###########################################################
  }, [
    rawArrayOfData,
    selectedDateStart,
    selectedDateStop,
    // setOpenRows,
    openRows,
    filterByMemberIds,
    filterByLabelId,
    filterByLabelName,
    filterBySelection,
    filterByName,
    filterByDataSourceName,
    filterByDataSource,
    filterByStatus,
    filterByStatusExclusionArray,
    filterByInvoiceStatus,
    filterByInvoiceOrderId,
    filters
  ]);

  // remove items that arent shown from checkedBoxes list.

  useEffect(() => {
    const filteredCheckedBoxes = checkedBoxes.filter(
      (checkedBox) =>
        arrayOfData.filter((row) => row._id === checkedBox._id).length
    );
    setCheckedBoxes(filteredCheckedBoxes);
    // openRows
    // checkedBoxes
    // setCheckedBoxes
  }, [arrayOfData]);

  // const [arrayOfDataSourceData, setArrayOfDataSourceData] = useState([]);

  useEffect(() => {
    // ###### populate array of data to get child sources #######

    if (filters.length === 0) {
      setArrayOfDataSourceData(
        rawArrayOfData.filter((obj) => obj.type === 'account')
      );
    } else {
      let dataSourceDataList = [];

      for (let dataObj of arrayOfData) {
        for (let rawDataObj of rawArrayOfData) {
          if (
            rawDataObj['level_' + dataObj.level + '_container_id'] ===
            dataObj._id
          ) {
            dataSourceDataList.push(rawDataObj);
          }
        }
      }

      const uniquedataSourceDataList = dataSourceDataList.filter(
        (v, i, a) => a.findIndex((t) => t._id === v._id) === i
      );

      let copyOfDataSourceDataList = [];
      for (let row of uniquedataSourceDataList) {
        const copyOfRow = { ...row, fieldsToNull: [] };
        copyOfRow.total_list_of_propagation_data_row = (
          copyOfRow.total_list_of_propagation_data_row || []
        ).map((obj) => ({
          ...obj
        }));
        copyOfDataSourceDataList.push(copyOfRow);
      }
      const rowLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      for (let rowLevel of rowLevels) {
        // const newLevelDataSourceDataList = [];
        for (let row of copyOfDataSourceDataList) {
          if (row.level === rowLevel) {
            let i = 0;
            copyOfDataSourceDataList = copyOfDataSourceDataList.filter(
              (dataRow) =>
                dataRow._id === row._id ||
                row._id !== dataRow['level_' + rowLevel + '_container_id']
            );
          }
        }
      }

      setArrayOfDataSourceData(copyOfDataSourceDataList);
    }

    // ###########################################################
  }, [arrayOfData]);

  const [formattedSummaryRow, setFormattedSummaryRow] = useState({
    // cost: null,
    // impressions: null,
    // trp: null,
    // spots: null
  });

  useEffect(() => {
    const summaryObject = {
      name: 'Summary',
      type: 'summary',
      total_list_of_propagation_data: []
    };

    let summary_total_list_of_propagation_data = [];
    let listOfFilteredAccountIds = [];
    if (
      true
      // filters.includes('filter_by_data_source_name') ||
      // filters.includes('filter_by_data_source') ||
      // arrayOfData.filter((row) => row.type === 'folder').length === 0
    ) {
      for (let row of arrayOfDataSourceData) {
        // ############################## CALCULATE ################################################
        if (
          true
          // [
          //   'facebook_ads_campaign',
          //   'google_ads_campaign',
          //   'manualDataEntry'
          // ].includes(row.type)
        ) {
          listOfFilteredAccountIds.push(row.accountId);

          summary_total_list_of_propagation_data = [
            ...(summary_total_list_of_propagation_data || []),
            ...(row.total_list_of_propagation_data_row || [])
          ];

          for (let field of [...sumEvalFields, 'budget']) {
            summaryObject[field] = row[field]
              ? (summaryObject[field] || 0) + row[field]
              : summaryObject[field];
          }
          // summaryObject.cost = row.cost
          //   ? (summaryObject.cost || 0) + row.cost
          //   : summaryObject.cost;

          // summaryObject.trp = row.trp
          //   ? (summaryObject.trp || 0) + row.trp
          //   : summaryObject.trp;

          // summaryObject.spots = row.spots
          //   ? (summaryObject.spots || 0) + row.spots
          //   : summaryObject.spots;

          // summaryObject.impressions = row.impressions
          //   ? (summaryObject.impressions || 0) + row.impressions
          //   : summaryObject.impressions;

          // summaryObject.clicks = row.clicks
          //   ? (summaryObject.clicks || 0) + row.clicks
          //   : summaryObject.clicks;

          // // summaryObject.action01Count
          // summaryObject.action01Count = row.action01Count
          //   ? (summaryObject.action01Count || 0) + (row.action01Count || 0)
          //   : summaryObject.action01Count;

          // summaryObject.action02Count = row.action02Count
          //   ? (summaryObject.action02Count || 0) + (row.action02Count || 0)
          //   : summaryObject.action02Count;

          // summaryObject.action03Count = row.action03Count
          //   ? (summaryObject.action03Count || 0) + (row.action03Count || 0)
          //   : summaryObject.action03Count;

          // // summaryObject.action01Value
          // summaryObject.action01Value = row.action01Value
          //   ? (summaryObject.action01Value || 0) + (row.action01Value || 0)
          //   : summaryObject.action01Value;

          // summaryObject.action02Value = row.action02Value
          //   ? (summaryObject.action02Value || 0) + (row.action02Value || 0)
          //   : summaryObject.action02Value;

          // summaryObject.action03Value = row.action03Value
          //   ? (summaryObject.action03Value || 0) + (row.action03Value || 0)
          //   : summaryObject.action03Value;
        }
      }

      summaryObject.budgetLeft =
        typeof summaryObject.budget === 'number' ||
        typeof summaryObject.cost === 'number'
          ? (summaryObject.budget || 0) - (summaryObject.cost || 0)
          : null;

      summaryObject.budgetSpent =
        typeof summaryObject.budget === 'number' ||
        typeof summaryObject.cost === 'number'
          ? ((summaryObject.cost || 0) / (summaryObject.budget || 0)) * 100
          : null;

      summaryObject.clicks = summaryObject.clicks
        ? parseInt(summaryObject.clicks)
        : summaryObject.clicks;

      summaryObject.cpm =
        summaryObject.cost && summaryObject.impressions
          ? summaryObject.cost / (summaryObject.impressions / 1000)
          : null;

      summaryObject.vCpm =
        summaryObject.cost && summaryObject.viewableImpressions
          ? summaryObject.cost / (summaryObject.viewableImpressions / 1000)
          : null;

      summaryObject.ctr =
        summaryObject.clicks && summaryObject.impressions
          ? (summaryObject.clicks / summaryObject.impressions) * 100
          : null;

      summaryObject.viewableRate =
        summaryObject.viewableImpressions && summaryObject.measurableImpressions
          ? (summaryObject.viewableImpressions /
              summaryObject.measurableImpressions) *
            100
          : null;

      summaryObject.vcr =
        summaryObject.videoCompletions && summaryObject.impressions
          ? (summaryObject.videoCompletions / summaryObject.impressions) * 100
          : null;

      summaryObject.searchImpressionShare =
        summaryObject.impressions && summaryObject.eligibleImpressions
          ? (summaryObject.impressions / summaryObject.eligibleImpressions) *
            100
          : null;

      summaryObject.vcr =
        summaryObject.impressions && summaryObject.videoCompletions
          ? (summaryObject.videoCompletions / summaryObject.impressions) * 100
          : null;

      summaryObject.cpcv =
        summaryObject.videoCompletions && summaryObject.cost
          ? summaryObject.cost / summaryObject.videoCompletions
          : null;

      summaryObject.cpc =
        summaryObject.clicks && summaryObject.cost
          ? summaryObject.cost / summaryObject.clicks
          : null;

      // ############################################### ROUND ################################################################

      // summaryObject.action01Cpa
      summaryObject.action01Cpa =
        summaryObject.action01Count && summaryObject.cost
          ? Math.round(
              (summaryObject.cost / summaryObject.action01Count +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      summaryObject.action02Cpa =
        summaryObject.action02Count && summaryObject.cost
          ? Math.round(
              (summaryObject.cost / summaryObject.action02Count +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      summaryObject.action03Cpa =
        summaryObject.action03Count && summaryObject.cost
          ? Math.round(
              (summaryObject.cost / summaryObject.action03Count +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      // summaryObject.action01Cvr
      summaryObject.action01Cvr =
        summaryObject.action01Count && summaryObject.clicks
          ? (summaryObject.action01Count / summaryObject.clicks) * 100
          : null;

      summaryObject.action02Cvr =
        summaryObject.action02Count && summaryObject.clicks
          ? (summaryObject.action02Count / summaryObject.clicks) * 100
          : null;

      summaryObject.action03Cvr =
        summaryObject.action03Count && summaryObject.clicks
          ? (summaryObject.action03Count / summaryObject.clicks) * 100
          : null;

      //summaryObject.action01Roas =
      summaryObject.action01Roas =
        summaryObject.cost && summaryObject.action01Value
          ? Math.round(
              (summaryObject.action01Value / summaryObject.cost +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      summaryObject.action02Roas =
        summaryObject.cost && summaryObject.action02Value
          ? Math.round(
              (summaryObject.action02Value / summaryObject.cost +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      summaryObject.action03Roas =
        summaryObject.cost && summaryObject.action03Value
          ? Math.round(
              (summaryObject.action03Value / summaryObject.cost +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      // summaryObject.action01Vpa

      summaryObject.action01Vpa =
        summaryObject.action01Count && summaryObject.action01Value
          ? Math.round(
              (summaryObject.action01Value / summaryObject.action01Count +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      summaryObject.action02Vpa =
        summaryObject.action02Count && summaryObject.action02Value
          ? Math.round(
              (summaryObject.action02Value / summaryObject.action02Count +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      summaryObject.action03Vpa =
        summaryObject.action03Count && summaryObject.action03Value
          ? Math.round(
              (summaryObject.action03Value / summaryObject.action03Count +
                Number.EPSILON) *
                100
            ) / 100
          : null;

      // summaryObject.action01Cvr

      summaryObject.action01Cvr =
        summaryObject.action01Cvr &&
        Math.round((summaryObject.action01Cvr + Number.EPSILON) * 100) / 100;

      summaryObject.action02Cvr =
        summaryObject.action02Cvr &&
        Math.round((summaryObject.action02Cvr + Number.EPSILON) * 100) / 100;

      summaryObject.action03Cvr =
        summaryObject.action03Cvr &&
        Math.round((summaryObject.action03Cvr + Number.EPSILON) * 100) / 100;

      // summaryObject.action02Cvr =
      //   summaryObject.action02Cvr &&
      //   summaryObject.action02Cvr.toFixed(2) + '%';
      // summaryObject.action03Cvr =
      //   summaryObject.action03Cvr &&
      //   summaryObject.action03Cvr.toFixed(2) + '%';

      summaryObject.budgetLeft =
        summaryObject.budgetLeft &&
        Math.round((summaryObject.budgetLeft + Number.EPSILON) * 100) / 100;

      summaryObject.budgetSpent =
        summaryObject.budgetSpent &&
        Math.round((summaryObject.budgetSpent + Number.EPSILON) * 100) / 100;

      summaryObject.cost =
        summaryObject.cost &&
        Math.round((summaryObject.cost + Number.EPSILON) * 100) / 100;

      summaryObject.trp = summaryObject.trp && summaryObject.trp;

      summaryObject.spots = summaryObject.spots && summaryObject.spots;

      summaryObject.impressions =
        summaryObject.impressions && summaryObject.impressions;

      summaryObject.viewableImpressions =
        summaryObject.viewableImpressions && summaryObject.viewableImpressions;

      summaryObject.measurableImpressions =
        summaryObject.measurableImpressions &&
        summaryObject.measurableImpressions;

      summaryObject.eligibleImpressions =
        summaryObject.eligibleImpressions && summaryObject.eligibleImpressions;

      summaryObject.videoCompletions =
        summaryObject.videoCompletions && summaryObject.videoCompletions;

      // summaryObject.action01Count
      summaryObject.action01Count =
        summaryObject.action01Count &&
        Math.round((summaryObject.action01Count + Number.EPSILON) * 100) / 100;

      summaryObject.action02Count =
        summaryObject.action02Count &&
        Math.round((summaryObject.action02Count + Number.EPSILON) * 100) / 100;

      summaryObject.action03Count =
        summaryObject.action03Count &&
        Math.round((summaryObject.action03Count + Number.EPSILON) * 100) / 100;

      // summaryObject.action01Value
      summaryObject.action01Value =
        summaryObject.action01Value &&
        Math.round((summaryObject.action01Value + Number.EPSILON) * 100) / 100;

      summaryObject.action02Value =
        summaryObject.action02Value &&
        Math.round((summaryObject.action02Value + Number.EPSILON) * 100) / 100;

      summaryObject.action03Value =
        summaryObject.action03Value &&
        Math.round((summaryObject.action03Value + Number.EPSILON) * 100) / 100;

      summaryObject.cpm =
        summaryObject.cpm &&
        Math.round((summaryObject.cpm + Number.EPSILON) * 100) / 100;

      summaryObject.vCpm =
        summaryObject.vCpm &&
        Math.round((summaryObject.vCpm + Number.EPSILON) * 100) / 100;

      summaryObject.cpcv =
        summaryObject.cpcv &&
        Math.round((summaryObject.cpcv + Number.EPSILON) * 100) / 100;

      summaryObject.cpc =
        summaryObject.cpc &&
        Math.round((summaryObject.cpc + Number.EPSILON) * 100) / 100;

      summaryObject.ctr =
        summaryObject.ctr &&
        Math.round((summaryObject.ctr + Number.EPSILON) * 100) / 100;

      summaryObject.viewableRate =
        summaryObject.viewableRate &&
        Math.round((summaryObject.viewableRate + Number.EPSILON) * 100) / 100;

      summaryObject.vcr =
        summaryObject.vcr &&
        Math.round((summaryObject.vcr + Number.EPSILON) * 100) / 100;

      summaryObject.searchImpressionShare =
        summaryObject.searchImpressionShare &&
        Math.round(
          (summaryObject.searchImpressionShare + Number.EPSILON) * 100
        ) / 100;
    }

    setSummaryRow({ ...summaryObject });

    // ############################### FORMAT ############################################

    summaryObject.action01Cpa = summaryObject.action01Cpa
      ? summaryObject.action01Cpa.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action02Cpa = summaryObject.action02Cpa
      ? summaryObject.action02Cpa.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action03Cpa = summaryObject.action03Cpa
      ? summaryObject.action03Cpa.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action01Roas = summaryObject.action01Roas
      ? summaryObject.action01Roas.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action02Roas = summaryObject.action02Roas
      ? summaryObject.action02Roas.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action03Roas = summaryObject.action03Roas
      ? summaryObject.action03Roas.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action01Vpa = summaryObject.action01Vpa
      ? summaryObject.action01Vpa.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action02Vpa = summaryObject.action02Vpa
      ? summaryObject.action02Vpa.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action03Vpa = summaryObject.action03Vpa
      ? summaryObject.action03Vpa.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.budget = summaryObject.budget
      ? summaryObject.budget.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.budgetLeft = summaryObject.budgetLeft
      ? summaryObject.budgetLeft.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.budgetSpent = summaryObject.budgetSpent
      ? summaryObject.budgetSpent.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.cost = summaryObject.cost
      ? summaryObject.cost.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.trp = summaryObject.trp
      ? summaryObject.trp.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null;

    summaryObject.spots = summaryObject.spots
      ? summaryObject.spots.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null;

    summaryObject.impressions = summaryObject.impressions
      ? summaryObject.impressions.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null;

    summaryObject.viewableImpressions = summaryObject.viewableImpressions
      ? summaryObject.viewableImpressions.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null;

    summaryObject.measurableImpressions = summaryObject.measurableImpressions
      ? summaryObject.measurableImpressions.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null;

    summaryObject.eligibleImpressions = summaryObject.eligibleImpressions
      ? summaryObject.eligibleImpressions.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null;

    summaryObject.videoCompletions = summaryObject.videoCompletions
      ? summaryObject?.videoCompletions?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null;

    summaryObject.clicks = summaryObject.clicks
      ? summaryObject.clicks.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null;

    summaryObject.action01Count = summaryObject.action01Count
      ? summaryObject.action01Count.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action02Count = summaryObject.action02Count
      ? summaryObject.action02Count.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action03Count = summaryObject.action03Count
      ? summaryObject.action03Count.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action01Value = summaryObject.action01Value
      ? summaryObject.action01Value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action02Value = summaryObject.action02Value
      ? summaryObject.action02Value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.action03Value = summaryObject.action03Value
      ? summaryObject.action03Value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.cpm = summaryObject.cpm
      ? summaryObject.cpm.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.vCpm = summaryObject.vCpm
      ? summaryObject.vCpm.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.cpcv = summaryObject.cpcv
      ? summaryObject.cpcv.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.cpc = summaryObject.cpc
      ? summaryObject.cpc.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : null;

    summaryObject.ctr = summaryObject.ctr
      ? summaryObject.ctr.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%'
      : null;

    summaryObject.viewableRate = summaryObject.viewableRate
      ? summaryObject.viewableRate.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%'
      : null;

    summaryObject.vcr = summaryObject.vcr
      ? summaryObject.vcr.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%'
      : null;

    summaryObject.searchImpressionShare = summaryObject.searchImpressionShare
      ? summaryObject.searchImpressionShare.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%'
      : null;

    summaryObject.action01Cvr = summaryObject.action01Cvr
      ? summaryObject.action01Cvr.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%'
      : null;

    summaryObject.action02Cvr = summaryObject.action02Cvr
      ? summaryObject.action02Cvr.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%'
      : null;
    summaryObject.action03Cvr = summaryObject.action03Cvr
      ? summaryObject.action03Cvr.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%'
      : null;
    // ##############################################

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const listOfFilteredUniqueAccountIds =
      listOfFilteredAccountIds.filter(onlyUnique);

    // summaryObject.total_list_of_propagation_data = [
    //   ...summary_total_list_of_propagation_data.filter((obj) =>
    //     listOfFilteredUniqueAccountIds.includes(obj.accountId)
    //   ),
    //   ...totalListOfPropagationData.filter((obj) =>
    //     listOfFilteredUniqueAccountIds.includes(obj.accountId)
    //   )
    // ];
    summaryObject.accountIds = listOfFilteredUniqueAccountIds;
    summaryObject.total_list_of_propagation_data = [
      ...summary_total_list_of_propagation_data,
      ...totalListOfPropagationData,
      ...totalListOfPropagationBasicData
    ];

    setFormattedSummaryRow(summaryObject);
  }, [arrayOfDataSourceData]);

  function compareValues(key, order = 'asc') {
    // console.log('order: ', order);
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      // let varA = a[key];

      // switch(true){
      //   case typeof a[key] === 'string':
      //   varA =a[key].toUpperCase();
      //   break;
      //   case typeof a[key] === 'number':
      //     break;

      // }

      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      switch (true) {
        // case Boolean(varA):
        //   comparison = -1;
        //   break;
        // case varA !== null &&
        //   varA !== undefined &&
        //   (varB === null || varB === undefined):
        //   comparison = -1;
        //   break;

        // case varB !== null &&
        //   varB !== undefined &&
        //   (varA === null || varA === undefined):
        //   comparison = -1;
        //   break;
        // // case varA !== null && varA !== undefined && varB === null:
        // //   comparison = 1;
        // //   break;
        case (varA === undefined ||
          varA === null ||
          // isNaN(varA) ||
          varA === '') &&
          (varB === undefined ||
            varB === null ||
            //  isNaN(varB) ||
            varB === ''):
          break;

        case varA === undefined ||
          varA === null ||
          // isNaN(varA) ||
          varA === '':
          comparison = order === 'desc' ? -1 : 1;
          break;

        case varB === undefined ||
          varB === null ||
          // isNaN(varB) ||
          varB === '':
          comparison = order === 'desc' ? 1 : -1;
          break;

        default:
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
      }
      // if (varA > varB && varB !== null && varB !== undefined) {
      //   comparison = 1;
      // } else if (varA < varB && varA !== null && varA !== undefined) {
      //   comparison = -1;
      // }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  const sortColumnHandler = (e, itemKey) => {
    var orderDict = {
      null: 'asc',
      asc: 'desc',
      desc: null
    };

    var order =
      itemKey === sortedColumn.column || !sortedColumn?.column
        ? orderDict[sortedColumn?.order]
        : sortedColumn.order;

    switch (true) {
      case !Boolean(orderDict[sortedColumn?.order]):
        setArrayOfData([...arrayOfDataInCronologicalOrder]);
        setSortedColumn({
          column: order ? itemKey : null,
          order: order
        });
        break;

      case itemKey === 'name' && itemKey !== sortedColumn.column:
        order = orderDict[null];
        setArrayOfData([...arrayOfData.sort(compareValues(itemKey, order))]);
        setSortedColumn({
          column: order ? itemKey : null,
          order: order
        });
        break;

      case itemKey === 'dateRange':
        setArrayOfData([
          ...arrayOfData.sort(compareValues('startDate', order))
        ]);
        setSortedColumn({
          column: order ? 'startDate' : null,
          order: order
        });
        break;

      case itemKey !== sortedColumn.column:
        setArrayOfData([...arrayOfData.sort(compareValues(itemKey, order))]);
        setSortedColumn({
          column: order ? itemKey : null,
          order: order
        });
        break;

      default:
        setArrayOfData([...arrayOfData.sort(compareValues(itemKey, order))]);
        setSortedColumn({
          column: order ? itemKey : null,
          order: order
        });
    }
    // console.log('debug2255>sortedColumn: ', sortedColumn);
    // if (itemKey === 'name' && itemKey !== sortedColumn.column) {
    //   setArrayOfData([...arrayOfData.sort(compareValues(itemKey, 'asc'))]);
    //   setSortedColumn({ column: itemKey, asc: false });
    // } else if (itemKey !== sortedColumn.column) {
    //   setArrayOfData([...arrayOfData.sort(compareValues(itemKey, 'desc'))]);
    //   setSortedColumn({ column: itemKey, asc: true });
    //   // if (itemKey !== sortedColumn.column) {
    //   //   setSortedColumn({ column: itemKey, asc: sortedColumn.asc });
    //   //   setArrayOfData([
    //   //     ...arrayOfData.sort(
    //   //       compareValues(itemKey, !sortedColumn.asc ? 'asc' : 'desc')
    //   //     )
    //   //   ]);
    // } else {
    //   setArrayOfData([
    //     ...arrayOfData.sort(
    //       compareValues(itemKey, sortedColumn.asc ? 'asc' : 'desc')
    //     )
    //   ]);
    //   setSortedColumn({ column: itemKey, asc: !sortedColumn.asc });
    // }
  };

  const [displayArrayOfData, setDisplayArrayOfData] = useState([]);
  useEffect(() => {
    const displayData = arrayOfData.filter((item) => {
      if (
        (!hideSelected.includes(item.documentType) ||
          ['account'].includes(item.documentType)) &&
        ((item.template && !hideSelected.includes('templates')) ||
          !item.template) &&
        !hideSelected.includes('STATUS_' + item.status)
      ) {
        return true;
      }
    });

    setDisplayArrayOfData(displayData);
  }, [arrayOfData, hideSelected]);

  document.addEventListener('mousemove', function (e) {
    //adjust warning column
    const warningColumnCssSelector = columns.includes('type')
      ? '#gridContainer > div:nth-child(3)'
      : '#gridContainer > div:nth-child(2)';
    document.querySelector(warningColumnCssSelector) &&
      setWarningColumnLeft(
        document.querySelector(warningColumnCssSelector)?.offsetWidth +
          (columns.includes('type') ? 92 : 46)
      );

    //resize column
    if (curCol) {
      var diffX = e.pageX - pageX;

      curCol.style.width = curColWidth + diffX + 'px';
    }
  });

  document.addEventListener('mouseup', function (e) {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  });
  var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

  const colHandlerOnRelease = (e, itemKey) => {
    // if (e.metaKey && e.shiftKey) {
    //   console.log('Command+Shift+Click');
    // } else {
    //   console.log('Other click');
    // }

    var isRightMB;
    var isLeftMB;
    e = e || window.event;

    if ('which' in e) {
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = e.which == 3;
      isLeftMB = e.which == 1;
    } else if ('button' in e) {
      // IE, Opera
      isRightMB = e.button == 2;
      isLeftMB = e.button == 1;
    }

    // alert('Right mouse button ' + (isRightMB ? '' : ' was not') + 'clicked!');

    if (isRightMB) {
      // alert('Right mouse button ' + (isRightMB ? '' : ' was not') + 'clicked!');
      // columns,

      if (
        itemKey.includes('Sparkline') ||
        itemKey.includes('Today') ||
        (itemKey.includes('Yesterday') && !itemKey.includes('allow'))
        // [
        //   'costSparkline',
        //   'trpSparkline',
        //   'spotsSparkline',
        //   'impressionsSparkline',
        //   'cpmSparkline',
        //   'clicksSparkline',
        //   'ctrSparkline',
        //   'cpcSparkline',
        //   'action01CountSparkline',
        //   'action02CountSparkline',
        //   'action03CountSparkline',
        //   'action01CvrSparkline',
        //   'action02CvrSparkline',
        //   'action03CvrSparkline',
        //   'action01CpaSparkline',
        //   'action02CpaSparkline',
        //   'action03CpaSparkline',
        //   'action01ValueSparkline',
        //   'action02ValueSparkline',
        //   'action03ValueSparkline',
        //   'action01RoasSparkline',
        //   'action02RoasSparkline',
        //   'action03RoasSparkline',
        //   'action01VpaSparkline',
        //   'action02VpaSparkline',
        //   'action03VpaSparkline',

        //   'costToday',
        //   'trpToday',
        //   'spotsToday',
        //   'impressionsToday',
        //   'cpmToday',
        //   'clicksToday',
        //   'ctrToday',
        //   'cpcToday',
        //   'action01CountToday',
        //   'action02CountToday',
        //   'action03CountToday',
        //   'action01CvrToday',
        //   'action02CvrToday',
        //   'action03CvrToday',
        //   'action01CpaToday',
        //   'action02CpaToday',
        //   'action03CpaToday',
        //   'action01ValueToday',
        //   'action02ValueToday',
        //   'action03ValueToday',
        //   'action01RoasToday',
        //   'action02RoasToday',
        //   'action03RoasToday',
        //   'action01VpaToday',
        //   'action02VpaToday',
        //   'action03VpaToday',

        //   'costYesterday',
        //   'trpYesterday',
        //   'spotsYesterday',
        //   'impressionsYesterday',
        //   'cpmYesterday',
        //   'clicksYesterday',
        //   'ctrYesterday',
        //   'cpcYesterday',
        //   'action01CountYesterday',
        //   'action02CountYesterday',
        //   'action03CountYesterday',
        //   'action01CvrYesterday',
        //   'action02CvrYesterday',
        //   'action03CvrYesterday',
        //   'action01CpaYesterday',
        //   'action02CpaYesterday',
        //   'action03CpaYesterday',
        //   'action01ValueYesterday',
        //   'action02ValueYesterday',
        //   'action03ValueYesterday',
        //   'action01RoasYesterday',
        //   'action02RoasYesterday',
        //   'action03RoasYesterday',
        //   'action01VpaYesterday',
        //   'action02VpaYesterday',
        //   'action03VpaYesterday'
        // ].includes(itemKey)
      ) {
        const index = columns.findIndex((col) => col === itemKey);
        const updatedColumns = [...columns];
        updatedColumns.splice(index, 1);
        setColumns(updatedColumns);

        return;
      }
    }
  };

  const nameColHandlerOnMouseUp = (e) => {
    curCol = e.target.parentElement;
    curColWidth = curCol?.offsetWidth;

    localStorage.setItem('nameColumnWidth', curColWidth);
  };

  const colHandler = (e, itemKey) => {
    // console.log('e123: ', e);

    // if (e.metaKey && e.shiftKey) {
    //   console.log('Command+Shift+Click');
    // } else {
    //   console.log('Other click');
    // }

    var isRightMB;
    var isLeftMB;
    e = e || window.event;

    if ('which' in e) {
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = e.which == 3;
      isLeftMB = e.which == 1;
    } else if ('button' in e) {
      // IE, Opera
      isRightMB = e.button == 2;
      isLeftMB = e.button == 0;
    }

    // alert('Right mouse button ' + (isRightMB ? '' : ' was not') + 'clicked!');

    if (isRightMB || e.shiftKey) {
      // alert('Right mouse button ' + (isRightMB ? '' : ' was not') + 'clicked!');
      // columns,

      if (
        itemKey.includes('Sparkline') ||
        itemKey.includes('Today') ||
        (itemKey.includes('Yesterday') && !itemKey.includes('allow'))
        // [
        //   'costSparkline',
        //   'trpSparkline',
        //   'spotsSparkline',
        //   'impressionsSparkline',
        //   'cpmSparkline',
        //   'clicksSparkline',
        //   'ctrSparkline',
        //   'cpcSparkline',
        //   'action01CountSparkline',
        //   'action02CountSparkline',
        //   'action03CountSparkline',
        //   'action01CvrSparkline',
        //   'action02CvrSparkline',
        //   'action03CvrSparkline',
        //   'action01CpaSparkline',
        //   'action02CpaSparkline',
        //   'action03CpaSparkline',
        //   'action01ValueSparkline',
        //   'action02ValueSparkline',
        //   'action03ValueSparkline',
        //   'action01RoasSparkline',
        //   'action02RoasSparkline',
        //   'action03RoasSparkline',
        //   'action01VpaSparkline',
        //   'action02VpaSparkline',
        //   'action03VpaSparkline',

        //   'costToday',
        //   'trpToday',
        //   'spotsToday',
        //   'impressionsToday',
        //   'cpmToday',
        //   'clicksToday',
        //   'ctrToday',
        //   'cpcToday',
        //   'action01CountToday',
        //   'action02CountToday',
        //   'action03CountToday',
        //   'action01CvrToday',
        //   'action02CvrToday',
        //   'action03CvrToday',
        //   'action01CpaToday',
        //   'action02CpaToday',
        //   'action03CpaToday',
        //   'action01ValueToday',
        //   'action02ValueToday',
        //   'action03ValueToday',
        //   'action01RoasToday',
        //   'action02RoasToday',
        //   'action03RoasToday',
        //   'action01VpaToday',
        //   'action02VpaToday',
        //   'action03VpaToday',

        //   'costYesterday',
        //   'trpYesterday',
        //   'spotsYesterday',
        //   'impressionsYesterday',
        //   'cpmYesterday',
        //   'clicksYesterday',
        //   'ctrYesterday',
        //   'cpcYesterday',
        //   'action01CountYesterday',
        //   'action02CountYesterday',
        //   'action03CountYesterday',
        //   'action01CvrYesterday',
        //   'action02CvrYesterday',
        //   'action03CvrYesterday',
        //   'action01CpaYesterday',
        //   'action02CpaYesterday',
        //   'action03CpaYesterday',
        //   'action01ValueYesterday',
        //   'action02ValueYesterday',
        //   'action03ValueYesterday',
        //   'action01RoasYesterday',
        //   'action02RoasYesterday',
        //   'action03RoasYesterday',
        //   'action01VpaYesterday',
        //   'action02VpaYesterday',
        //   'action03VpaYesterday'
        // ].includes(itemKey)
      ) {
        // const index = columns.findIndex((col) => col === itemKey);
        // const updatedColumns = [...columns];
        // updatedColumns.splice(index, 1);
        // setColumns(updatedColumns);

        return;
      }

      if (
        // ![
        //   'cost',
        //   'trp',
        //   'spots',
        //   'impressions',
        //   'cpm',
        //   'clicks',
        //   'ctr',
        //   'cpc',
        //   'action01Count',
        //   'action02Count',
        //   'action03Count',
        //   'action01Cvr',
        //   'action02Cvr',
        //   'action03Cvr',
        //   'action01Cpa',
        //   'action02Cpa',
        //   'action03Cpa',
        //   'action01Value',
        //   'action02Value',
        //   'action03Value',
        //   'action01Roas',
        //   'action02Roas',
        //   'action03Roas',
        //   'action01Vpa',
        //   'action02Vpa',
        //   'action03Vpa',

        //   'costToday',
        //   'trpToday',
        //   'spotsToday',
        //   'impressionsToday',
        //   'cpmToday',
        //   'clicksToday',
        //   'ctrToday',
        //   'cpcToday',
        //   'action01CountToday',
        //   'action02CountToday',
        //   'action03CountToday',
        //   'action01CvrToday',
        //   'action02CvrToday',
        //   'action03CvrToday',
        //   'action01CpaToday',
        //   'action02CpaToday',
        //   'action03CpaToday',
        //   'action01ValueToday',
        //   'action02ValueToday',
        //   'action03ValueToday',
        //   'action01RoasToday',
        //   'action02RoasToday',
        //   'action03RoasToday',
        //   'action01VpaToday',
        //   'action02VpaToday',
        //   'action03VpaToday',

        //   'costYesterday',
        //   'trpYesterday',
        //   'spotsYesterday',
        //   'impressionsYesterday',
        //   'cpmYesterday',
        //   'clicksYesterday',
        //   'ctrYesterday',
        //   'cpcYesterday',
        //   'action01CountYesterday',
        //   'action02CountYesterday',
        //   'action03CountYesterday',
        //   'action01CvrYesterday',
        //   'action02CvrYesterday',
        //   'action03CvrYesterday',
        //   'action01CpaYesterday',
        //   'action02CpaYesterday',
        //   'action03CpaYesterday',
        //   'action01ValueYesterday',
        //   'action02ValueYesterday',
        //   'action03ValueYesterday',
        //   'action01RoasYesterday',
        //   'action02RoasYesterday',
        //   'action03RoasYesterday',
        //   'action01VpaYesterday',
        //   'action02VpaYesterday',
        //   'action03VpaYesterday',

        //   'totalCost',
        //   'totaltrp',
        //   'totalSpots',
        //   'totalImpressions',
        //   'totalClicks',
        //   'totalAction01Count',
        //   'totalAction02Count',
        //   'totalAction03Count',
        //   // 'totalaction01Cvr',
        //   // 'totalaction02Cvr',
        //   // 'totalaction03Cvr',
        //   'totalAction01Cpa',
        //   'totalAction02Cpa',
        //   'totalAction03Cpa',
        //   'totalAction01Value',
        //   'totalAction02Value',
        //   'totalAction03Value',
        //   'totalAction01Roas',
        //   'totalAction02Roas',
        //   'totalAction03Roas',
        //   'totalAction01Vpa',
        //   'totalAction02Vpa',
        //   'totalAction03Vpa'
        // ].includes(itemKey)
        !(
          itemKey.includes('Today') ||
          (itemKey.includes('Yesterday') && !itemKey.includes('allow')) ||
          allEvalFields.includes(itemKey)
        ) &&
        (isRightMB || (isLeftMB && e.shiftKey))
      ) {
        return;
      }

      let addColumn = 'Sparkline';
      if (e.shiftKey && isLeftMB) {
        addColumn = 'Yesterday';
      }

      if (e.shiftKey && isRightMB) {
        addColumn = 'Today';
      }

      let index = 0;
      index = columns.findIndex((col) => col === itemKey + addColumn);

      if (index === -1) {
        index = columns.findIndex((col) => col === itemKey);
        const updatedColumns = [...columns];
        updatedColumns.splice(index + 1, 0, itemKey + addColumn);
        setColumns(updatedColumns);
      } else {
        index = columns.findIndex((col) => col === itemKey + addColumn);
        const updatedColumns = [...columns];
        updatedColumns.splice(index, 1);
        setColumns(updatedColumns);
      }
      // setColumns,
    } else {
      curCol = e.target.parentElement;
      nxtCol = curCol.nextElementSibling;
      pageX = e.pageX;
      curColWidth = curCol?.offsetWidth;
      if (!curCol.style.minWidth) {
        curCol.style.minWidth = curColWidth + 2 + 'px';
      }

      if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
    }
  };

  useEffect(() => {
    if (!displayArrayOfData.length) return;

    const scrollHeight = tableEl?.current?.scrollHeight;
    const offsetHeight = tableEl?.current?.offsetHeight;
    const scrollTop = tableEl?.current?.scrollTop;

    if (
      tableEl &&
      tableEl.current &&
      scrollTop >= scrollHeight - offsetHeight
    ) {
      if (bottomShadow) {
        toggleBottomShadow(false);
      }
    } else {
      if (!bottomShadow) {
        toggleBottomShadow(true);
      }
    }
  }, [displayArrayOfData]);

  const [topScrollThreshold, setTopScrollThreshhold] = useState();
  const [bottomScrollThreshold, setBottomScrollThreshhold] = useState();

  // useEffect(() => {

  // }, [topScrollThreshold]);

  // useEffect(() => {

  //   const topScrollInScreen = tableEl?.current?.scrollTop;
  //   const bottomScrollInScreen =
  //     tableEl?.current?.offsetHeight + tableEl?.current?.scrollTop;

  //   if (!topScrollThreshold) {
  //     setTopScrollThreshhold(topScrollInScreen - 46 * 3);
  //   }
  //   if (!bottomScrollThreshold) {
  //     setBottomScrollThreshhold(bottomScrollInScreen + 46 * 3);
  //   }

  //   if (topScrollInScreen < topScrollThreshold + 100) {
  //     setTopScrollThreshhold(topScrollInScreen - 46 * 3);
  //   }

  //   if (bottomScrollInScreen > bottomScrollThreshold - 100) {
  //     setBottomScrollThreshhold(bottomScrollInScreen + 46 * 3);
  //   }
  // }, [tableEl?.current?.scrollTop]);

  const [leftShadow, toggleLeftShadow] = useState(false);
  const [topShadow, toggleTopShadow] = useState(false);
  const [bottomShadow, toggleBottomShadow] = useState(false);

  const onScrollHandler = (e) => {
    document.querySelector('#gridContainer').scrollLeft !== 0
      ? toggleLeftShadow(true)
      : toggleLeftShadow(false);
    document.querySelector('#gridContainer').scrollTop !== 0
      ? toggleTopShadow(true)
      : toggleTopShadow(false);
    document.querySelector('#gridContainer').scrollTop >=
    document.querySelector('#gridContainer').scrollHeight -
      document.querySelector('#gridContainer').offsetHeight
      ? toggleBottomShadow(false)
      : toggleBottomShadow(true);

    // ###############################

    const topScrollInScreen = tableEl?.current?.scrollTop;
    const bottomScrollInScreen =
      tableEl?.current?.offsetHeight + tableEl?.current?.scrollTop;

    if (!topScrollThreshold) {
      setTopScrollThreshhold(topScrollInScreen);
    }
    // if (!bottomScrollThreshold) {
    //   setBottomScrollThreshhold(bottomScrollInScreen );
    // }

    if (
      topScrollInScreen < topScrollThreshold - 200 ||
      topScrollInScreen > topScrollThreshold + 200
    ) {
      setTopScrollThreshhold(topScrollInScreen);
    }
  };

  const gridContainer = {
    width: 'min-content',
    maxWidth: '-webkit-fill-available',
    // backgroundColor: '#FFFFFF',
    display: 'grid',
    // gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 100px))',
    // gridTemplateColumns:
    //   '(auto-fit, minmax(200px, 2fr), repeat(auto-fill, minmax(150px, 1fr))',
    // gridTemplateColumns:
    //   'minmax(200px, 1fr), repeat(auto-fill, minmax(100px, 1fr))',
    // gridTemplateColumns: '100px 100px 100px 100px 100px',
    gridTemplateColumns: columns.includes('type')
      ? '46px, 46px, 200px, 53px auto'
      : '46px, 200px, 53px auto',
    // gridTemplateRows: '55px, repeat(auto-fill, minmax(53px, 55px))',
    gridTemplateRows:
      'minmax(55px, 55px) repeat(auto-fill, minmax(46px, 46px))',
    // gridTemplateRows: '40px',
    // backgroundColor: '#2196F3',
    padding: '0px',
    overflowX: 'scroll',
    overflowY: 'scroll',
    position: 'relative',
    top: '0px',

    // height: '82vh',
    height: 'calc(100vh - 142px)',
    // border: '1px solid rgba(200, 200, 200, 0.5)',
    borderRadius: '5px'
    // boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
    // boxShadow: '0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24)' //working
  };

  const [warningColumnLeft, setWarningColumnLeft] = useState('');

  // useEffect(() => {

  //   if (document.querySelector('#gridContainer > div:nth-child(3)')) {
  //     setWarningColumnLeft(
  //       document.querySelector('#gridContainer > div:nth-child(3)')
  //         .offsetWidth + 92
  //     );
  //   }
  // });

  const [ganttWidth, setGanttWidth] = useState(21);

  const summaryRowBottomShadow = (
    <div
      style={{
        top: '45px',
        // right: '0px',
        height: '5px',
        position: 'absolute',
        // backgroundColor: stylesStoredInRedux.background.backgroundColor
        //   ? stylesStoredInRedux.background.backgroundColor
        //   : 'rgba(255,255,255,0)',
        // cursor: 'col-resize',
        // backgroundColor: 'red',
        userSelect: 'none',
        width: 'calc(100% + 1px)',
        zIndex: '1099'
      }}
    >
      <div
        style={{
          top: '45px',
          // right: '0px',
          height: '5px',
          // position: 'absolute',
          background:
            '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
          // cursor: 'col-resize',
          // backgroundColor: 'red',
          userSelect: 'none',
          width: 'calc(100% + 1px)',
          zIndex: '1100'
        }}
      ></div>
    </div>
  );

  const summaryRowCheckboxBottomShadow = (
    <div
      style={{
        top: '45px',
        // right: '0px',
        height: '5px',
        position: 'absolute',
        // backgroundColor: stylesStoredInRedux.background.backgroundColor
        //   ? stylesStoredInRedux.background.backgroundColor
        //   : 'rgba(255,255,255,0)',
        // cursor: 'col-resize',
        // backgroundColor: 'red',
        userSelect: 'none',
        left: '-1px',
        width: 'calc(100% + 1px)',
        zIndex: '1099'
      }}
    >
      <div
        style={{
          top: '45px',
          // right: '0px',
          height: '5px',
          // position: 'absolute',
          background:
            '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
          // cursor: 'col-resize',
          // backgroundColor: 'red',
          userSelect: 'none',
          width: 'calc(100% + 1px)',
          zIndex: '1100',
          marginLeft: '3px',
          borderBottomLeftRadius: '5px'
        }}
      ></div>
    </div>
  );

  const onCheckboxCornerClick = (e, item) => {
    setCheckedBoxes([item]);
  };

  // ################## TypeItem menu ###############################

  // const [typeItemAnchorRef, setTypeItemAnchorRef] = useState(null);

  const [typeItemOpen, setTypeItemOpen] = useState(false);

  // const [typeItemToggledItem, setTypeItemToggledItem] = useState(null);

  // const [typeItemTemplateMenuAnchorRef, setTypeItemTemplateMenuAnchorRef] =
  //   useState(null);

  // const [typeItemTemplateMenuOpen, setTypeItemTemplateMenuOpen] =
  //   useState(false);

  // const [typeItemToggledItemTemplateMenu, setTypeItemToggledItemTemplateMenu] =
  //   useState(null);

  // ################################################################

  const numberOfVisibleColumns = visColumns.length;
  if (!currentAccountIds.length) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 142px)'
        }}
      >
        PLEASE SELECT AT LEAST ONE ACCOUNT
      </div>
    );
  }
  // if (!displayArrayOfData?.length) {
  //   return <div></div>;
  // }

  return (
    <Fragment>
      <div
        onClick={(e) => {
          if (
            currentManagerAccount?.isTimeboxingOn ||
            ['system_admin', 'debug'].includes(user?.role) ||
            [
              '6167f2a56a44185b71fd0bcc',
              '61a917e9d86ba532fcee25a9',
              '63ea0160f4ab796775dbe0da',
              '640b425a1b1064af10bc2918',
              '640b3de89db4bd2b100bdf58'
            ].includes(user?._id)
          ) {
            if (!isTableShifted && !isTimeboxerOpen) {
              setIsTimeboxerOpen(true);
            } else if (isTableShifted && isTimeboxerOpen) {
              setIsTimeboxerOpen(false);
            }
            setIsTableShifted(!isTableShifted);
          }
        }}
        style={{
          height: 55 + 44 + 45 * displayArrayOfData?.length + 'px',
          maxHeight: '85%',
          width: '40%',
          // background: 'red',

          position: 'absolute',
          top: '72px',
          left: '0px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <ForwardIcon
          style={{
            transform: 'rotate(180deg)',
            color:
              theme?.palette?.mode === 'dark'
                ? 'rgba(200,200,255,0.3)'
                : '#a7268333',
            fontSize: '80px',
            visibility: !isTableShifted && 'hidden'
            // backdropFilter: 'blur(10px)'
          }}
        />
      </div>
      <div
        id="nexustable-table"
        onKeyDown={handleKeyDown}
        className={
          isTableShifted ? 'isTableShiftedTrue' : 'isTableShiftedFalse'
        }
        // style={{ marginLeft: isTableShifted && '40%' }}
      >
        {topShadow ? (
          <div
            id="topShadow"
            style={{
              top: '60px',
              // right: '0px',
              height: '5px',
              position: 'relative',
              background:
                '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
              // cursor: 'col-resize',
              // backgroundColor: 'red',
              userSelect: 'none',
              // width: '100%',
              // width: tableEl.current.offsetWidth - 7 + 'px',
              width: 'calc(100% - 8px)',
              maxWidth: tableEl?.current?.offsetWidth - 7 + 'px',
              zIndex: '1100'
            }}
          ></div>
        ) : (
          <div
            id="topShadow"
            style={{
              top: '60px',
              // right: '0px',
              height: '5px',
              position: 'relative',
              // background:
              //   '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
              // cursor: 'col-resize',
              // backgroundColor: 'red',
              userSelect: 'none',
              // width: '100%',
              // width: tableEl.current.offsetWidth - 7 + 'px',
              width: 'calc(100% - 8px)',
              // maxWidth: tableEl.current.offsetWidth - 7 + 'px',
              zIndex: '1100'
            }}
          ></div>
        )}

        {/* background: 0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y; */}
        <div
          ref={tableEl}
          id="gridContainer"
          style={gridContainer}
          onScroll={(e) => onScrollHandler(e)}
        >
          {visColumns.map((itemKey, index) => {
            const columnNumber = index;
            switch (itemKey) {
              case 'checkbox':
                return (
                  <div
                    index={index}
                    key={index}
                    style={{
                      borderRadius: '5px 0px 0px 0px',
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].color,

                      // border: '1px solid rgba(200, 200, 200, 0.5)',
                      border:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].border,
                      fontSize: '12px',
                      gridRowStart: 1,
                      gridRowEnd: 1,
                      position: 'sticky',
                      left: '0',
                      top: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '46px',
                      // padding: '10px 20px',
                      fontWeight: '800'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        cursor: 'pointer'
                      }}
                      // onClick={(e) => sortColumnHandler(e, itemKey)}
                    >
                      <CheckboxHead
                        displayArrayOfData={displayArrayOfData}
                        setCheckedBoxes={setCheckedBoxes}
                        rawArrayOfData={rawArrayOfData}
                        currentAccountIds={currentAccountIds}
                        arrayOfData={arrayOfData}
                        checkedBoxes={checkedBoxes}
                      />
                    </span>
                  </div>
                );
                break;

              case 'type':
                return (
                  <div
                    index={index}
                    key={index}
                    style={{
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].color,
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].borderColor,
                      fontSize: '12px',
                      gridRowStart: 1,
                      gridRowEnd: 1,
                      position: 'sticky',
                      left: '46px',
                      top: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '100%',
                      // padding: '10px 20px',
                      fontWeight: '800'
                      // minWidth: '200px'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        cursor: 'pointer'
                      }}
                      onClick={(e) => sortColumnHandler(e, itemKey)}
                    ></span>

                    {/* <div
                        style={{
                          top: '0px',
                          right: '0px',
                          width: '5px',
                          position: 'absolute',
                          cursor: 'col-resize',
  
                          userSelect: 'none',
                          height: '100%'
                        }}
                        onMouseDown={(e) => colHandler(e)}
                      ></div> */}
                  </div>
                );
                break;

              case 'name':
                return (
                  <div
                    index={index}
                    key={index}
                    style={{
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].color,
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].borderColor,
                      fontSize: '12px',
                      gridRowStart: 1,
                      gridRowEnd: 1,
                      position: 'sticky',
                      left: columns.includes('type') ? '92px' : '46px',
                      top: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '100%',
                      padding: '10px 20px',
                      fontWeight: '800',
                      width:
                        localStorage.getItem('nameColumnWidth') &&
                        localStorage.getItem('nameColumnWidth') + 'px',
                      minWidth: '200px'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        cursor: 'pointer'
                      }}
                      onClick={(e) => sortColumnHandler(e, itemKey)}
                    >
                      <p>
                        {'Name'}
                        <span>
                          {' '}
                          {sortedColumn.column === itemKey ? (
                            sortedColumn?.order === 'asc' ? (
                              <i className="fas fa-sort-down"></i>
                            ) : (
                              <i className="fas fa-sort-up"></i>
                            )
                          ) : (
                            <span style={{ visibility: 'hidden' }}>
                              <i className="fas fa-sort-up"></i>
                            </span>
                          )}
                        </span>
                      </p>
                      <p style={{ visibility: 'hidden' }}> test</p>
                    </span>

                    <div
                      className="hover-col-resize"
                      style={{
                        top: '0px',
                        right: '0px',
                        width: '5px',
                        position: 'absolute',
                        cursor: 'col-resize',

                        userSelect: 'none',
                        height: '100%'
                      }}
                      onMouseDown={(e) => colHandler(e)}
                      onMouseUp={nameColHandlerOnMouseUp}
                    ></div>
                  </div>
                );
                break;

              case 'warnings':
                return (
                  <div
                    index={index}
                    key={index}
                    style={{
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].color,
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].borderColor,
                      // border: '1px solid rgba(200, 200, 200, 0.5)',
                      fontSize: '12px',
                      gridRowStart: 1,
                      gridRowEnd: 1,
                      position: 'sticky',
                      left: warningColumnLeft + 'px',
                      top: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '46px',
                      // padding: '10px 20px',
                      paddingTop: '10px',
                      fontWeight: '800'
                      // color: 'rgba(0, 0, 0, 0.54)'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        cursor: 'pointer'
                      }}
                      onClick={(e) => sortColumnHandler(e, itemKey)}
                    >
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                    <span style={{ textAlign: 'center' }}>
                      {' '}
                      {sortedColumn.column === itemKey ? (
                        sortedColumn?.order === 'asc' ? (
                          <i className="fas fa-sort-down"></i>
                        ) : (
                          <i className="fas fa-sort-up"></i>
                        )
                      ) : (
                        <span style={{ visibility: 'hidden' }}>
                          <i className="fas fa-sort-up"></i>
                        </span>
                      )}
                    </span>
                    {/* <p>{itemKey}</p>
                      <p style={{ visibility: 'hidden' }}> test</p> */}

                    {leftShadow && (
                      <div
                        style={{
                          right: '-6px',
                          // right: '0px',
                          width: '5px',
                          position: 'absolute',
                          background:
                            '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y',
                          // cursor: 'col-resize',
                          // backgroundColor: 'red',
                          userSelect: 'none',
                          height: '100%',
                          zIndex: '4000'
                        }}
                      ></div>
                    )}
                  </div>
                );
                break;
              case 'gantt':
                return (
                  <GanttHead
                    index={index}
                    ganttHeadStyle={
                      stylesStoredInRedux.RoiTable.Table.headStyle['ganttHead']
                    }
                    keyForMap={itemKey + '_' + index}
                    range={range}
                    itemKey={itemKey}
                    sortColumnHandler={sortColumnHandler}
                    colHandler={colHandler}
                    sortedColumn={sortedColumn}
                    selectedDateStart={selectedDateStart}
                    selectedDateStop={selectedDateStop}
                    ganttWidth={ganttWidth}
                    numberOfItemRowsOpen={displayArrayOfData.length}
                    numberOfVisibleColumns={numberOfVisibleColumns}
                  />
                );
                break;
              // case 'comments':
              case 'notifications':
                return (
                  <IconHead
                    index={index}
                    headStyle={
                      stylesStoredInRedux.RoiTable.Table.headStyle[
                        'defaultHead'
                      ]
                    }
                    key={index}
                    keyForMap={index}
                    itemKey={itemKey}
                    sortColumnHandler={sortColumnHandler}
                    colHandler={colHandler}
                    sortedColumn={sortedColumn}
                  />
                );
                break;
              default:
                return (
                  <DefaultHead
                    index={index}
                    headStyle={
                      stylesStoredInRedux.RoiTable.Table.headStyle[
                        'defaultHead'
                      ]
                    }
                    key={index}
                    keyForMap={index}
                    itemKey={itemKey}
                    sortColumnHandler={sortColumnHandler}
                    colHandler={colHandler}
                    colHandlerOnRelease={colHandlerOnRelease}
                    sortedColumn={sortedColumn}
                    numberOfVisibleColumns={numberOfVisibleColumns}
                  />
                );
            }
          })}
          {displayArrayOfData.map((item, rowNumber) => {
            // ##############################################

            if (
              (rowNumber * 46 + 46 + 300 < tableEl?.current?.scrollTop ||
                rowNumber * 46 + 46 >
                  tableEl?.current?.offsetHeight +
                    tableEl?.current?.scrollTop +
                    300) &&
              !exportMode
            ) {
              return (
                <div
                  style={{
                    height: '46px',
                    gridColumnStart: 1,
                    gridColumnEnd: columns.length + 1,
                    width: '100%',
                    gridRow: rowNumber + 2,
                    background:
                      stylesStoredInRedux.RoiTable.Table.itemStyle.rowStyle[
                        item.level
                      ].backgroundColor
                    // backgroundColor: 'red'
                  }}
                  // ref={inViewRef}
                ></div>
              );
            }

            // ##############################################

            // const [checked, setChecked] = React.useState(false);
            const currentBoxInCheckedBoxes = checkedBoxes.filter((obj) => {
              return obj._id === item._id;
            });

            const checked = Boolean(currentBoxInCheckedBoxes.length);

            // ##############################################

            return (
              <Fragment key={item._id}>
                {visColumns.map((itemKey, index) => {
                  const columnNumber = index;
                  switch (true) {
                    case itemKey === 'checkbox':
                      return (
                        <div
                          key={item._id + '_' + itemKey + '_' + index}
                          style={{
                            zIndex: '1000',
                            // backgroundColor: 'rgba(255, 255, 255, 1)',
                            backgroundColor: checked
                              ? stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                  ?.rowStyle?.[item?.level]
                                  ?.checkedBackgroundColor
                              : stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                  ?.rowStyle?.[item?.level]?.backgroundColor,
                            borderRight:
                              stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                ?.rowStyle?.[item?.level]?.border,
                            borderBottom:
                              stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                ?.rowStyle?.[item?.level]?.border,
                            borderLeft:
                              stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                ?.rowStyle?.[item?.level]?.border,
                            // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                            // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                            // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
                            fontSize: '12px',
                            gridRowStart: rowNumber + 2,
                            gridRowEnd: rowNumber + 2,
                            position: 'sticky',
                            left: '0',
                            display: 'grid',
                            height: '100%',
                            width: '46px',
                            // minWidth: '200px',
                            whiteSpace: 'nowrap'
                            // overflowX: 'hidden',
                            // padding: '10px 20px'
                          }}
                          onClick={(e) => onCheckboxCornerClick(e, item)}
                        >
                          <div
                            style={{
                              height: '100%',
                              width: '2px',
                              position: 'absolute',
                              left: '0px',
                              backgroundColor: item.accountColor,
                              visibility:
                                customStyleSelected?.checkBoxLeftBorderColor ===
                                'accountColor'
                                  ? 'visible'
                                  : 'hidden'
                            }}
                          ></div>
                          <span
                            style={{
                              // margin: 'auto'
                              margin: 'auto'
                            }}
                          >
                            <CheckboxItem
                              item={item}
                              maxHeight="46px"
                              displayArrayOfData={displayArrayOfData}
                            />
                            {/* <p>{item[itemKey]}</p> */}
                          </span>
                        </div>
                      );
                    case itemKey === 'name':
                      return (
                        <NameItem
                          aggregatedMode={aggregatedMode}
                          selectedDateStart={selectedDateStart}
                          selectedDateStop={selectedDateStop}
                          dataLevel={dataLevel}
                          updateDocument={updateDocument}
                          currentManagerAccountId={currentManagerAccountId}
                          changeTableData={changeTableData}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          index={index}
                          item={item}
                          rowNumber={rowNumber}
                          showItemsHandler={showItemsHandler}
                          showAllSubItemsHandler={showAllSubItemsHandler}
                          openRows={openRows}
                          checked={checked}
                          range2={range2}
                          columns={columns}
                          rawArrayOfData={rawArrayOfData}
                        />
                      );
                      break;
                    case itemKey === 'warnings':
                      return (
                        <WarningItem
                          warningColumnLeft={warningColumnLeft}
                          item={item}
                          index={index}
                          stylesStoredInRedux={stylesStoredInRedux}
                          leftShadow={leftShadow}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                      break;
                    case itemKey === 'status':
                      return (
                        <StatusItem
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;

                    case itemKey === 'invoiceStatus':
                      return (
                        <InvoiceStatusItem
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;
                    case itemKey === 'checkedAt':
                      return (
                        <CheckedAtItem
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;
                    case itemKey === 'type':
                      return (
                        <TypeItem
                          currentManagerAccountId={currentManagerAccountId}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                          typeItemAnchorRef={typeItemAnchorRef}
                          setTypeItemAnchorRef={setTypeItemAnchorRef}
                          open={typeItemOpen}
                          setOpen={setTypeItemOpen}
                          typeItemToggledItem={typeItemToggledItem}
                          setTypeItemToggledItem={setTypeItemToggledItem}
                        />
                      );
                      break;

                    case itemKey === 'comments':
                      return (
                        <CommentsItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          currentManagerAccountId={currentManagerAccountId}
                          item={item}
                          checked={checked}
                          rawArrayOfData={rawArrayOfData}
                        />
                      );
                      break;
                    case itemKey === 'notifications':
                      return (
                        <NotificationsItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                      break;

                    case itemKey === 'startDate':
                    case itemKey === 'endDate':
                      return (
                        <DateItem
                          currentManagerAccountId={currentManagerAccountId}
                          stylesStoredInRedux={stylesStoredInRedux}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;
                    case itemKey === 'dateRange':
                      return (
                        <DateRangeItem
                          currentManagerAccountId={currentManagerAccountId}
                          stylesStoredInRedux={stylesStoredInRedux}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;

                    case itemKey === 'taxonomyWidgets':
                      return (
                        <TaxonomyWidgetsItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          item={item}
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                      break;
                    case [
                      'searchImpressionShare',
                      'searchImpressionShareSparkline',
                      'searchImpressionShareToday',
                      'searchImpressionShareYesterday',
                      'eligibleImpressions',
                      'eligibleImpressionsSparkline',
                      'eligibleImpressionsToday',
                      'eligibleImpressionsYesterday'
                    ].includes(itemKey) &&
                      !['googleAds'].includes(item.documentType):
                      return (
                        <EmptyItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                    // case 'budget':
                    //   return (
                    //     <BudgetItem
                    //       currentManagerAccountId={currentManagerAccountId}
                    //       updateDocument={updateDocument}
                    //       item={item}
                    //       itemStyle={
                    //         stylesStoredInRedux.RoiTable.Table.itemStyle
                    //           .rowStyle[item.level]
                    //       }
                    //       keyForMap={item._id + '_' + itemKey + '_' + index}
                    //       index={index}
                    //       itemKey={itemKey}
                    //       itemValue={item[itemKey]}
                    //       rowNumber={rowNumber}
                    //       textAlignRight={true}
                    //       checked={checked}
                    //       selectedDateStart={selectedDateStart}
                    //       selectedDateStop={selectedDateStop}
                    //     />
                    //   );
                    //   break;

                    // case itemKey === 'averageDailyBudget':
                    // case itemKey === 'calcDailyBudget':
                    case itemKey === 'numberOfDays':
                    case itemKey === 'numberOfDaysLeft':
                      // case itemKey === 'daysLeft':
                      return (
                        <IntItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={false}
                          checked={checked}
                        />
                      );
                      break;
                    // case itemKey === 'numberOfDaysLeft':
                    //   return (
                    //     <FractionItem
                    //       numerator={item?.numberOfDaysLeft}
                    //       denominator={item?.numberOfDays}
                    //       itemStyle={
                    //         stylesStoredInRedux.RoiTable.Table.itemStyle
                    //           .rowStyle[item.level]
                    //       }
                    //       keyForMap={item._id + '_' + itemKey + '_' + index}
                    //       index={index}
                    //       item={item}
                    //       itemKey={itemKey}
                    //       itemValue={item[itemKey]}
                    //       rowNumber={rowNumber}
                    //       textAlignRight={false}
                    //       checked={checked}
                    //     />
                    //   );
                    //   break;
                    case itemKey === 'daysLeft':
                      return (
                        <FractionBarItem
                          numerator={item?.daysLeft}
                          denominator={item?.numberOfDays}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          item={item}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={false}
                          checked={checked}
                        />
                      );
                      break;
                    case itemKey === 'budgetSpent':
                      return (
                        <FractionBarItem
                          numerator={item?.cost}
                          denominator={item?.budget}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          item={item}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={false}
                          checked={checked}
                        />
                      );
                      break;

                    case itemKey === 'timeSpent':
                      return (
                        <FractionBarItem
                          numerator={item?.daysSinceStartDate}
                          denominator={item?.numberOfDays}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          item={item}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={false}
                          checked={checked}
                        />
                      );
                      break;

                    case itemKey === 'template' &&
                      !item.isSetChild &&
                      !item.isSet:
                      // case itemKey === 'allowImpressionsAfterEndDate':
                      return (
                        <BooleanCheckboxItem
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;
                    case itemKey === 'allowMissingBudget':
                      // case itemKey === 'allowImpressionsBeforeStartDate':
                      // case itemKey === 'allowImpressionsAfterEndDate':
                      if (
                        ([
                          'facebook_ads_campaign',
                          'google_ads_campaign',
                          'cm360_campaign',
                          'dv360_insertion_order',
                          'snapchat_ads_campaign',
                          'tiktok_ads_campaign',
                          'pinterest_ads_campaign',
                          'readpeak_campaign',
                          'strossle_campaign',
                          'amazon_dsp_order',
                          'taboola_ads_campaign',
                          'microsoft_advertising_campaign',
                          'microsoft_invest_insertion_order',
                          'apple_search_ads_campaign',
                          'adform_order',
                          'linkedin_ads_campaign_group',
                          'manualDataEntry'
                        ].includes(item.type) &&
                          !item.isSetChild) ||
                        item.isSet
                      ) {
                        return (
                          <BooleanCheckboxItem
                            currentManagerAccountId={currentManagerAccountId}
                            updateDocument={updateDocument}
                            item={item}
                            itemStyle={
                              stylesStoredInRedux.RoiTable.Table.itemStyle
                                .rowStyle[item.level]
                            }
                            keyForMap={item._id + '_' + itemKey + '_' + index}
                            index={index}
                            itemKey={itemKey}
                            itemValue={item[itemKey]}
                            rowNumber={rowNumber}
                            textAlignRight={true}
                            checked={checked}
                          />
                        );
                      } else {
                        return (
                          <EmptyItem
                            itemStyle={
                              stylesStoredInRedux.RoiTable.Table.itemStyle
                                .rowStyle[item.level]
                            }
                            keyForMap={item._id + '_' + itemKey + '_' + index}
                            index={index}
                            itemValue={item[itemKey]}
                            rowNumber={rowNumber}
                            checked={checked}
                          />
                        );
                      }
                    // case itemKey === 'allowMissingBudget':
                    case itemKey === 'allowImpressionsBeforeStartDate':
                    case itemKey === 'allowImpressionsAfterEndDate':
                    case itemKey === 'allowFrontloadedDelivery':
                    case itemKey === 'allowMissingClicks':
                    case itemKey === 'allowAbnormallyHighCtr':
                    case itemKey === 'allowMissingImpressionsYesterday':
                      if (
                        ([
                          'facebook_ads_campaign',
                          'google_ads_campaign',
                          'cm360_campaign',
                          'dv360_insertion_order',
                          'snapchat_ads_campaign',
                          'tiktok_ads_campaign',
                          'pinterest_ads_campaign',
                          'readpeak_campaign',
                          'strossle_campaign',
                          'amazon_dsp_order',
                          'taboola_ads_campaign',
                          'microsoft_advertising_campaign',
                          'microsoft_invest_insertion_order',
                          'apple_search_ads_campaign',
                          'adform_order',
                          'linkedin_ads_campaign_group'
                          // 'manualDataEntry'
                        ].includes(item.type) &&
                          !item.isSetChild) ||
                        item.isSet
                      ) {
                        return (
                          <BooleanCheckboxItem
                            currentManagerAccountId={currentManagerAccountId}
                            updateDocument={updateDocument}
                            item={item}
                            itemStyle={
                              stylesStoredInRedux.RoiTable.Table.itemStyle
                                .rowStyle[item.level]
                            }
                            keyForMap={item._id + '_' + itemKey + '_' + index}
                            index={index}
                            itemKey={itemKey}
                            itemValue={item[itemKey]}
                            rowNumber={rowNumber}
                            textAlignRight={true}
                            checked={checked}
                          />
                        );
                      } else {
                        return (
                          <EmptyItem
                            itemStyle={
                              stylesStoredInRedux.RoiTable.Table.itemStyle
                                .rowStyle[item.level]
                            }
                            keyForMap={item._id + '_' + itemKey + '_' + index}
                            index={index}
                            itemValue={item[itemKey]}
                            rowNumber={rowNumber}
                            checked={checked}
                          />
                        );
                      }

                      break;

                    case itemKey === 'paceYesterday':
                      return (
                        <NAItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                      break;

                    // case 'costSparkline':
                    // case 'trpSparkline':
                    // case 'spotsSparkline':
                    // case 'impressionsSparkline':
                    // case 'cpmSparkline':
                    // case 'clicksSparkline':
                    // case 'ctrSparkline':
                    // case 'cpcSparkline':
                    // case 'action01CountSparkline':
                    // case 'action02CountSparkline':
                    // case 'action03CountSparkline':
                    // case 'action01ValueSparkline':
                    // case 'action02ValueSparkline':
                    // case 'action03ValueSparkline':
                    // case 'action01RoasSparkline':
                    // case 'action02RoasSparkline':
                    // case 'action03RoasSparkline':
                    // case 'action01CpaSparkline':
                    // case 'action02CpaSparkline':
                    // case 'action03CpaSparkline':
                    // case 'action01CvrSparkline':
                    // case 'action02CvrSparkline':
                    // case 'action03CvrSparkline':
                    // case 'action01VpaSparkline':
                    // case 'action02VpaSparkline':
                    // case 'action03VpaSparkline':

                    // case 'totalCostSparkline':
                    // case 'totaltrpSparkline':
                    // case 'totalSpotsSparkline':
                    // case 'totalImpressionsSparkline':
                    // case 'totalClicksSparkline':
                    // case 'totalAction01CountSparkline':
                    // case 'totalAction02CountSparkline':
                    // case 'totalAction03CountSparkline':
                    // case 'totalAction01ValueSparkline':
                    // case 'totalAction02ValueSparkline':
                    // case 'totalAction03ValueSparkline':
                    // case 'totalAction01RoasSparkline':
                    // case 'totalAction02RoasSparkline':
                    // case 'totalAction03RoasSparkline':
                    // case 'totalAction01CpaSparkline':
                    // case 'totalAction02CpaSparkline':
                    // case 'totalAction03CpaSparkline':
                    // case 'totalaction01CvrSparkline':
                    // case 'totalaction02CvrSparkline':
                    // case 'totalaction03CvrSparkline':
                    // case 'totalAction01VpaSparkline':
                    // case 'totalAction02VpaSparkline':
                    // case 'totalAction03VpaSparkline':
                    case itemKey.includes('Sparkline'):
                      if (
                        false
                        // aggregatedMode &&
                        // !['facebook_ads_campaign'].includes(item.type)
                      ) {
                        return (
                          <NAItem
                            itemStyle={
                              stylesStoredInRedux.RoiTable.Table.itemStyle
                                .rowStyle[item.level]
                            }
                            keyForMap={item._id + '_' + itemKey + '_' + index}
                            index={index}
                            itemValue={item[itemKey]}
                            rowNumber={rowNumber}
                            checked={checked}
                          />
                        );
                      }
                      return (
                        <SparklineItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          selectedDateStart={selectedDateStart}
                          selectedDateStop={selectedDateStop}
                          itemDateStart={item['startDate']}
                          itemDateStop={item['endDate']}
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                          item={item}
                          range2={range2}
                          columns={columns}
                        />
                      );
                      break;

                    case itemKey === 'action01':
                    case itemKey === 'action02':
                    case itemKey === 'action03':
                    case itemKey === 'totalAction01':
                    case itemKey === 'totalAction02':
                    case itemKey === 'totalAction03':
                      return (
                        <ActionTypeItemSwitch
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;

                    case [
                      'ctr',
                      'viewableRate',
                      'vcr',
                      'searchImpressionShare',
                      'action01Cvr',
                      'action02Cvr',
                      'action03Cvr'
                    ].includes(itemKey) ||
                      (displayColumnsAsPercent.includes(itemKey) &&
                        [
                          'action01Roas',
                          'action02Roas',
                          'action03Roas'
                        ].includes(itemKey)):
                      return (
                        <MathEvalPercentItem
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;

                    case itemKey === 'cost':
                    case itemKey === 'universe':
                    case itemKey === 'trp':
                    case itemKey === 'spots':
                    case itemKey === 'impressions':
                    case itemKey === 'viewableImpressions':
                    case itemKey === 'measurableImpressions':
                    case itemKey === 'eligibleImpressions':
                    case itemKey === 'videoCompletions':
                    case itemKey === 'reach':
                    case itemKey === 'clicks':

                    // case 'action01Count':
                    // case 'action02Count':
                    // case 'action03Count':

                    // case 'action01Value':
                    // case 'action02Value':
                    // case 'action03Value':

                    // case 'plannedGross':
                    // case 'plannedNet':
                    // case 'plannedNetNet':
                    // case 'plannedCtc':

                    // case 'plannedTrp':
                    // case 'plannedTechFee':
                    // case 'plannedAdOpsFee':
                    // case 'plannedAdServingCost':
                    // case 'plannedFeePercent':
                    // case 'kpi':
                    // case 'plannedImpressions':
                    // case 'plannedClicks':
                    // case 'plannedCtr':
                    // case 'plannedCpc':
                    // case 'plannedReach':
                    // case 'plannedCpm':
                    // case 'plannedFrequency':
                    // case 'plannedActionCount':
                    // case 'plannedActionValue':
                    // case 'plannedActionRoas':
                    // case 'plannedCpa':
                    // case 'budget':

                    case allEvalFieldsExceptTotal.includes(itemKey):
                    case aggregatedFields.includes(itemKey) &&
                      ['system_admin'].includes(user.role):
                      return (
                        <MathEvalItem
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );

                    case itemKey === 'ganttBarDisplaySetting':
                      return (
                        <GanttSettingsItem
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;

                    // case 'generalDescription':

                    // case 'buyingTypeDescription':
                    // case 'creativeDescription':
                    // case 'formatDescription':
                    // case 'channelDescription':
                    // case 'audienceDataDescription':

                    // case 'targetGroupDescription':
                    // case 'placementDescription':

                    // case 'goalDescription':
                    // case 'budgetCurrency':
                    case itemKey === 'invoiceOrderId' &&
                      (([
                        'facebook_ads_campaign',
                        'google_ads_campaign',
                        'cm360_campaign',
                        'dv360_insertion_order',
                        'snapchat_ads_campaign',
                        'tiktok_ads_campaign',
                        'pinterest_ads_campaign',
                        'readpeak_campaign',
                        'strossle_campaign',
                        'amazon_dsp_order',
                        'taboola_ads_campaign',
                        'microsoft_advertising_campaign',
                        'microsoft_invest_insertion_order',
                        'apple_search_ads_campaign',
                        'adform_order',
                        'linkedin_ads_campaign_group',
                        'manualDataEntry'
                      ].includes(item.type) &&
                        !item.isSetChild) ||
                        item.isSet):
                    case itemKey.includes('Description') ||
                      itemKey === 'budgetCurrency':
                      return (
                        <TextItem
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );

                    case itemKey === 'pace':
                    // case itemKey === 'paceYesterday':
                    case itemKey === 'calcPaceYesterday':
                      return (
                        <PaceItem
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          itemKey={itemKey}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );

                      break;
                    case itemKey.includes('Today'):
                    case itemKey.includes('Yesterday') &&
                      !itemKey.includes('allow'):
                    case itemKey === 'averageDailyBudget':
                    case itemKey === 'calcDailyBudget':
                    case itemKey === 'budgetLeft':
                      return (
                        <FloatItemV3
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemKey={itemKey}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          textAlignRight={true}
                          checked={checked}
                        />
                      );
                      break;

                    // case 'ctr':
                    // case 'action01Cvr':
                    // case 'action02Cvr':
                    // case 'action03Cvr':

                    // case 'ctrToday':
                    // case 'action01CvrToday':
                    // case 'action02CvrToday':
                    // case 'action03CvrToday':

                    // case 'ctrYesterday':
                    // case 'action01CvrYesterday':
                    // case 'action02CvrYesterday':
                    // case 'action03CvrYesterday':
                    //   return (
                    //     <CtrItem
                    //       itemStyle={
                    //         stylesStoredInRedux.RoiTable.Table.itemStyle
                    //           .rowStyle[item.level]
                    //       }
                    //       keyForMap={item._id + '_' + itemKey + '_' + index}
                    //       index={index}
                    //       itemKey={itemKey}
                    //       itemValue={item[itemKey]}
                    //       rowNumber={rowNumber}
                    //       checkedBoxes={checkedBoxes}
                    //       checked={checked}
                    //     />
                    //   );

                    // case 'cpm':
                    // case 'cpmToday':
                    // case 'cpmYesterday':
                    //   // case 'total_cpm':
                    //   return (
                    //     <CpmItem
                    //       itemStyle={
                    //         stylesStoredInRedux.RoiTable.Table.itemStyle
                    //           .rowStyle[item.level]
                    //       }
                    //       keyForMap={item._id + '_' + itemKey + '_' + index}
                    //       index={index}
                    //       itemKey={itemKey}
                    //       itemValue={item[itemKey]}
                    //       rowNumber={rowNumber}
                    //       checkedBoxes={checkedBoxes}
                    //       checked={checked}
                    //     />
                    //   );
                    //   break;
                    case itemKey === 'ganttBarColor':
                      return (
                        <ColorPicker
                          item={item}
                          currentManagerAccountId={currentManagerAccountId}
                          updateDocument={updateDocument}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                      break;
                    case itemKey === 'gantt':
                      return (
                        <GanttItem
                          enqueueSnackbar={enqueueSnackbar}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          // keyForMap={
                          //   item._id +
                          //   '_' +
                          //   itemKey +
                          //   '_' +
                          //   index +
                          //   ganttCounter()
                          // }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          range={range}
                          itemKey={itemKey}
                          selectedDateStart={selectedDateStart}
                          selectedDateStop={selectedDateStop}
                          itemDateStart={item['startDate']}
                          itemDateStop={item['endDate']}
                          itemValue={item[itemKey]}
                          columnNumber={columnNumber}
                          rowNumber={rowNumber}
                          item={item}
                          ganttWidth={ganttWidth}
                          updateDocument={updateDocument}
                          currentManagerAccountId={currentManagerAccountId}
                          checked={checked}
                          openRows={openRows}
                        />
                      );
                      break;

                    case itemKey === 'members':
                      return (
                        <TeamItem
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          updateDocument={updateDocument}
                          currentManagerAccountId={currentManagerAccountId}
                          checked={checked}
                        />
                      );

                    case itemKey === 'labels':
                      return (
                        <LabelItem
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          updateDocument={updateDocument}
                          currentManagerAccountId={currentManagerAccountId}
                          checked={checked}
                        />
                      );
                    case itemKey === 'path':
                      return (
                        <PathItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                          item={item}
                          setFilterBySelection={setFilterBySelection}
                          filters={filters}
                          setFilters={setFilters}
                        />
                      );
                      break;

                    case itemKey === 'pace_bar':
                      return (
                        <DualBarsItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          itemKey="pace"
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          item={item}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                    default:
                      return (
                        <DefaultItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                  }
                })}
              </Fragment>
            );
          })}

          {/*--------------------- Summary Row --------------------- */}

          {visColumns.map((itemKey, index) => {
            switch (itemKey) {
              case 'checkbox':
                return (
                  <div
                    key={index}
                    style={{
                      borderRadius: '0px 0px 0px 5px',
                      maxHeight: '46px',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].color,
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 1px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].borderColor,
                      // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
                      borderBottomLeftRadius: '5px',
                      fontSize: '12px',
                      gridRowStart: displayArrayOfData.length + 2,
                      gridRowEnd: displayArrayOfData.length + 2,
                      position: 'sticky',
                      left: '0',
                      bottom: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      // minWidth: '200px',
                      width: '100%',
                      minWidth: '46px',
                      // padding: '10px 20px',
                      fontWeight: '800'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {/* <p>Summary</p> */}
                      {/* <p style={{ visibility: 'hidden' }}> test</p> */}
                    </span>
                    {summaryRowCheckboxBottomShadow}
                  </div>
                );
                break;
              case 'type':
                return (
                  <div
                    key={index}
                    style={{
                      maxHeight: '46px',
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].color,
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].borderColor,
                      // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottomLeftRadius: '5px',
                      fontSize: '12px',
                      gridRowStart: displayArrayOfData.length + 2,
                      gridRowEnd: displayArrayOfData.length + 2,
                      position: 'sticky',
                      left: '46px',
                      bottom: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      // minWidth: '200px',
                      width: '100%',
                      minWidth: '46px',
                      // padding: '10px 20px',
                      fontWeight: '800'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {/* <p>Summary</p> */}
                      <p style={{ visibility: 'hidden' }}> test</p>
                    </span>
                    {summaryRowBottomShadow}
                  </div>
                );
                break;
              case 'name':
                return (
                  <NameFoot
                    aggregatedMode={aggregatedMode}
                    item={formattedSummaryRow}
                    itemKey={itemKey}
                    index={index}
                    stylesStoredInRedux={stylesStoredInRedux}
                    arrayOfData={displayArrayOfData}
                    summaryRowBottomShadow={summaryRowBottomShadow}
                    range={range}
                    range2={range2}
                    columns={columns}
                    viewDialogOpen={viewDialogOpen}
                    setViewDialogOpen={setViewDialogOpen}
                  />
                );
                break;
              case 'warnings':
                return (
                  <div
                    key={index}
                    style={{
                      maxHeight: '46px',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].color,
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].borderColor,
                      // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottomLeftRadius: '5px',
                      fontSize: '12px',
                      gridRowStart: displayArrayOfData.length + 2,
                      gridRowEnd: displayArrayOfData.length + 2,
                      position: 'sticky',
                      left: warningColumnLeft + 'px',
                      bottom: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '46px',
                      padding: '10px 20px',
                      fontWeight: '800'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {/* <p>Summary</p>
                      <p style={{ visibility: 'hidden' }}> test</p> */}
                    </span>
                    {leftShadow && (
                      <div
                        style={{
                          right: '-6px',
                          // right: '0px',
                          width: '5px',
                          position: 'absolute',
                          background:
                            '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y',
                          // cursor: 'col-resize',
                          // backgroundColor: 'red',
                          userSelect: 'none',
                          height: '100%',
                          zIndex: '4000'
                        }}
                      ></div>
                    )}
                    {summaryRowBottomShadow}
                  </div>
                );
                break;
              case 'gantt':
                return (
                  <GanttFoot
                    index={index}
                    itemKey={itemKey}
                    arrayOfDataLength={displayArrayOfData.length}
                    stylesStoredInRedux={stylesStoredInRedux}
                    ganttWidth={ganttWidth}
                    setGanttWidth={setGanttWidth}
                    sliderColor={
                      stylesStoredInRedux.RoiTable.Table.summaryStyle
                        .defaultSummary.color
                    }
                  />
                );
                break;

              case 'action01Count':
              case 'action02Count':
              case 'action03Count':
              case 'action01Cvr':
              case 'action02Cvr':
              case 'action03Cvr':
              case 'action01Cpa':
              case 'action02Cpa':
              case 'action03Cpa':
              case 'cost':
              case 'budget':
              case 'budgetLeft':
              case 'budgetSpent':
              case 'timeSpent':
              case 'trp':
              case 'spots':
              case 'impressions':
              case 'viewableImpressions':
              case 'measurableImpressions':
              case 'eligibleImpressions':
              case 'videoCompletions':
              case 'clicks':
              case 'cost':
              case 'action01Value':
              case 'action02Value':
              case 'action03Value':
              case 'action01Vpa':
              case 'action02Vpa':
              case 'action03Vpa':
              case 'action01Roas':
              case 'action02Roas':
              case 'action03Roas':
              case 'cpcv':
              case 'cpc':
                return (
                  <FloatFoot
                    summaryRow={formattedSummaryRow}
                    index={index}
                    itemKey={itemKey}
                    arrayOfDataLength={displayArrayOfData.length}
                    stylesStoredInRedux={stylesStoredInRedux}
                    textAlign="right"
                  />
                );
                break;
              case 'ctr':
              case 'viewableRate':
              case 'vcr':
              case 'searchImpressionShare':
              case 'cpm':
              case 'vCpm':
                return (
                  <FloatFoot
                    summaryRow={formattedSummaryRow}
                    index={index}
                    itemKey={itemKey}
                    arrayOfDataLength={displayArrayOfData.length}
                    stylesStoredInRedux={stylesStoredInRedux}
                    textAlign="center"
                  />
                );
                break;
              default:
                return (
                  <DefaultFoot
                    index={index}
                    itemKey={itemKey}
                    arrayOfDataLength={displayArrayOfData.length}
                    stylesStoredInRedux={stylesStoredInRedux}
                  />
                );
            }
          })}
        </div>
        {bottomShadow ? (
          <div
            id="bottomShadow"
            style={{
              bottom: '59px',
              // right: '0px',
              height: '5px',
              position: 'relative',
              background:
                '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAHElEQVQI12MwNjZmZdAT1+Nm0JDWEGZQk1GTBgAWkwIeAEp52AAAAABJRU5ErkJggg==) repeat-x',
              // cursor: 'col-resize',
              // backgroundColor: 'red',
              userSelect: 'none',
              width: 'calc(100% - 8px)',
              maxWidth:
                tableEl?.current?.offsetWidth &&
                tableEl?.current?.offsetWidth - 7 + 'px',
              // maxWidth: 'calc(100% - 36px)',
              // maxWidth: tableEl.current.offsetWidth - 7 + 'px',
              zIndex: '1500'
            }}
          ></div>
        ) : (
          <div
            id="bottomShadow"
            style={{
              bottom: '59px',
              // right: '0px',
              height: '5px',
              position: 'relative',
              // background:
              //   '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAHElEQVQI12MwNjZmZdAT1+Nm0JDWEGZQk1GTBgAWkwIeAEp52AAAAABJRU5ErkJggg==) repeat-x',
              // cursor: 'col-resize',
              // backgroundColor: 'red',
              userSelect: 'none',
              width: 'calc(100% - 8px)',
              // width: tableEl.current.offsetWidth - 7 + 'px',
              // maxWidth: 'calc(100% - 36px)',
              // maxWidth: tableEl.current.offsetWidth - 7 + 'px',
              zIndex: '1500'
            }}
          ></div>
        )}
      </div>
      <TypeItemMenuPopper
        currentManagerAccountId={currentManagerAccountId}
        typeItemAnchorRef={typeItemAnchorRef}
        setTypeItemAnchorRef={setTypeItemAnchorRef}
        open={typeItemOpen}
        setOpen={setTypeItemOpen}
        typeItemToggledItem={typeItemToggledItem}
        setTypeItemToggledItem={setTypeItemToggledItem}
        typeItemTemplateMenuOpen={typeItemTemplateMenuOpen}
        setTypeItemTemplateMenuOpen={setTypeItemTemplateMenuOpen}
      />
      <TypeItemTemplateMenuPopper
        currentManagerAccountId={currentManagerAccountId}
        typeItemAnchorRef={typeItemAnchorRef}
        setTypeItemAnchorRef={setTypeItemAnchorRef}
        open={typeItemTemplateMenuOpen}
        setOpen={setTypeItemTemplateMenuOpen}
        typeItemToggledItem={typeItemToggledItem}
        setTypeItemToggledItem={setTypeItemToggledItem}
      />
      <RightClickMenuPopper />
      {viewDialogOpen && (
        <ViewDialog
          arrayOfData={arrayOfData}
          rawArrayOfData={rawArrayOfData}
          open={viewDialogOpen}
          setOpen={setViewDialogOpen}
          currentManagerAccountId={currentManagerAccountId}
          checkedBoxes={checkedBoxes}
          setCheckedBoxes={setCheckedBoxes}
          names={names}
          dataSourceNames={dataSourceNames}
        />
      )}
      {taxonomyDialogOpen && <TaxonomyDialog />}
      {newsFeedDialogOpen && (
        <NewsFeedDialog
          currentManagerAccountId={currentManagerAccountId}
          checkedBoxes={checkedBoxes}
          setCheckedBoxes={setCheckedBoxes}
        />
      )}
      {infoModalDocumentOpen && (
        <InfoModal
          aggregatedMode={aggregatedMode}
          // item={item}
          open={infoModalDocumentOpen}
          setOpen={setInfoModalDocumentOpen}
          rawArrayOfData={rawArrayOfData}
        />
      )}
      {fieldDialogOpen && <FieldDialog rawArrayOfData={rawArrayOfData} />}
      {graphDialogOpen && (
        <GraphDialog
          open={graphDialogOpen}
          setOpen={setGraphDialogOpen}
          range2={range2}
          rawArrayOfData={rawArrayOfData}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
        />
      )}

      {/* {reportBuilderDialogOpen && (
        <ReportBuilderDialog
          open={graphDialogOpen}
          setOpen={setGraphDialogOpen}
          range2={range2}
          rawArrayOfData={rawArrayOfData}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
        />
      )} */}
      {editDialogOpen && (
        <EditDialog
          checkedBoxes={checkedBoxes}
          // currentManagerAccountId={currentManagerAccount._id}
          // open={editModalOpen}
          // setOpen={(e) => setEditModalOpen(e)}
        />
      )}

      {copyDialogOpen && (
        <CopyDialog
          checkedBoxes={checkedBoxes}
          // currentManagerAccountId={currentManagerAccount._id}
          copiedDocumentIds={copiedDocumentIds}
          setCopiedDocumentIds={setCopiedDocumentIds}
          copiedDocumentsAccountId={copiedDocumentsAccountId}
          setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
          // open={editModalOpen}
          // setOpen={(e) => setEditModalOpen(e)}
        />
      )}

      {editSetFiltersDialogOpen && (
        <EditSetFiltersDialog
          checkedBoxes={checkedBoxes}
          // currentManagerAccountId={currentManagerAccount._id}
          // copiedDocumentIds={copiedDocumentIds}
          // setCopiedDocumentIds={setCopiedDocumentIds}
          // copiedDocumentsAccountId={copiedDocumentsAccountId}
          // setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
          // open={editModalOpen}
          // setOpen={(e) => setEditModalOpen(e)}
        />
      )}
      <SearchParametersHandler />
    </Fragment>
  );
};

// Table.propTypes = {
//   currentAccount: PropTypes.string
// };

const mapStateToProps = (state) => ({
  // user: state.auth.user
});

export default connect(mapStateToProps, { getPosts })(Table);
