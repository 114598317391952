import React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
// import { makeStyles } from '@mui/styles';
// import { withStyles, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { deepOrange, deepPurple } from '@mui/material/colors';
// import LetterAvatar from './LetterAvatar';

import Tooltip from '@mui/material/Tooltip';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     '& > *': {
//       margin: theme.spacing(0)
//     }
//   },
//   orange: {
//     color: theme.palette.getContrastText(deepOrange[500]),
//     // backgroundColor: deepOrange[500],
//     // backgroundColor: 'grey',
//     // backgroundColor: 'rgba(177,48,141, 0.9)',
//     // background: `
//     // linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
//     // linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
//     // linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)

//     // `,
//     background: `
//     linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
//     linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
//     linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)
//     `,
//     // border: '1px solid rgba(207,78,171, 0.9)',
//     fontSize: '8px',
//     height: '25px',
//     width: '25px'
//   },
//   avatar: {
//     color: theme.palette.getContrastText(deepOrange[500]),
//     // backgroundColor: deepOrange[500],
//     // backgroundColor: 'grey',
//     // backgroundColor: 'rgba(177,48,141, 0.9)',
//     border: '1px solid rgba(207,78,171, 0.9)',
//     fontSize: '8px',
//     height: '25px',
//     width: '25px'
//   },
//   grey: {
//     color: theme.palette.getContrastText(deepOrange[500]),
//     // backgroundColor: deepOrange[500],
//     // backgroundColor: 'grey',
//     backgroundColor: 'rgba(150,150,150, 0.3)',
//     border: '1px solid rgba(207,78,171, 0.9)',
//     fontSize: '8px',
//     height: '25px',
//     width: '25px'
//   },
//   purple: {
//     color: theme.palette.getContrastText(deepPurple[500]),
//     backgroundColor: deepPurple[500]
//   }
// }));

function CardAvatar({ item, handleClick }) {
  // const classes = useStyles();
  const labels = item['labels'];
  if (!labels) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        width: '100%'
        // background: 'red'
      }}
      // onClick={handleClick}
    >
      {labels &&
        labels.map((label, index) => (
          <Tooltip title={label.name}>
            <div
              style={{
                width:
                  index !== labels.length - 1 &&
                  label?.name?.length &&
                  label.name.length * 7 + 'px'
              }}
            >
              <div
                className="nexustable-label"
                style={{
                  background: label.color || '#21285a',
                  borderColor: label.color || '#21285a',
                  border: '0px',
                  border: '1px solid white',
                  borderRadius: '5px'
                  // padding: '5px',
                  // margin: '5px 0px',
                  // borderRadius: '5px'
                }}
                // style={
                //   {
                //     // display: 'flex',
                //     // flexDirection: 'row',
                //     // justifyContent: 'center',
                //     // alignItems: 'center',
                //     // background: '#21285a',
                //     // fontSize: '12px',
                //     // width: 'fit-content',
                //     // height: '27px',
                //     // margin: '0px 3px',
                //     // padding: '3px 10px',
                //     // borderRadius: '50px',
                //     // cursor: 'pointer'
                //     // border: '1px solid white'
                //     // borderTop: '20px solid transparent',
                //     // borderRight: '40px solid blue',
                //     // borderBottom: '20px solid transparent'
                //   }
                // }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  {label.name}
                </div>
              </div>
              {/* <Avatar
              // style={{ border: '1px solid rgba(207,78,171, 0.9)' }}
              style={{ border: '1px solid rgba(255,255,255,1)' }}
              className={classes.orange}
              // onClick={(e) => handleClick(e)}
            >
              {obj.name}
            </Avatar> */}
            </div>
          </Tooltip>
        ))}
      {!labels?.length && (
        <div style={{ color: 'grey', padding: '3px', cursor: 'pointer' }}>
          {/* + */}
        </div>
      )}
      {/* <Avatar className={classes.orange}>+</Avatar> */}
    </div>
  );
}

export default CardAvatar;
