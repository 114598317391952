import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import LoginDialog from './LoginDialog';
import Button from '@mui/material/Button';
import GoogleLogin from './../auth/GoogleLogin';

// import Footer from './Footer';

import { AuthContext } from '../../context/AuthContext';
import { AccountContext } from '../../context/AccountContext';

import { TextField } from '@mui/material';

import { styled, useTheme } from '@mui/material/styles';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Login = () => {
  const theme = useTheme();
  const { isAuthenticated, updateToken, checkToken, user } =
    useContext(AuthContext);

  const { setCurrentManagerAccountId } = useContext(AccountContext);

  const { state } = useLocation();

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');

  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      console.log('Landing render');
      const currentQuery = new URLSearchParams(state?.search);
      const managerAccountId = currentQuery.get('manager_account_id');
      // console.log('Landing>managerAccountId: ', managerAccountId);
      if (managerAccountId) {
        setCurrentManagerAccountId(managerAccountId);
        const url = (state?.path || '') + (state?.search || '');

        navigate(url || '/choose-manager-account');
      } else {
        navigate('/choose-manager-account');
      }

      // return <Navigate to={url || '/choose-manager-account'} />;

      // <Navigate to="/choose-manager-account" />;
    }
  }, [isAuthenticated]);

  const onClickHandler = async () => {
    if (!validateEmail(email)) {
      setEmailError('Invalid email!');
    }

    if (password.length < 3) {
      setPasswordError('Password too short!');
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      email: email,
      password: password
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const url =
      window.location.hostname === 'localhost'
        ? 'https://localhost:5000/api/auth/password-auth'
        : 'https://api.nexustable.com/api/auth/password-auth';

    try {
      const response = await fetch(url, requestOptions);
      const token = await response.json();
      // console.log('token: ', token);
      await updateToken(token.token);
      await checkToken();
    } catch (error) {
      console.error(error);
    }

    // console.log(JSON.parse(await res).token);
    // .then((response) => response.text())
    // .then(async (result) => {

    //   await login(result.token);
    // })
    // .catch((error) => console.log('error', error));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClickHandler();
    }
  };

  return (
    <Fragment>
      <section
        className="landing"
        style={{
          background:
            theme?.palette?.mode === 'dark' ? 'rgba(0,0,0,1)' : '#e9e9e9'
        }}
      >
        {/* <div style={{ height: '22%', minHeight: '50px' }}></div> */}
        <div
          className="landing-inner"
          style={{
            background:
              theme?.palette?.mode === 'dark'
                ? 'rgba(150,150,159,.1)'
                : 'rgb(245, 245, 245)'
          }}
        >
          <h1
            className="x-large"
            style={{
              // color: 'white'
              color: '#a72683'
            }}
          >
            Welcome
          </h1>
          <p
            className="lead"
            style={{
              color: '#384497'
              // '#21285a'
            }}
          >
            Please sign in
          </p>
          <div
            className="buttons"
            style={{
              marginTop: '50px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}
          >
            <div style={{ width: '320px' }}>
              <TextField
                onKeyDown={handleKeyDown}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                style={{ width: '320px' }}
                required
                id="outlined-required"
                label="Email"
                // defaultValue="Email"
              />
              {emailError && (
                <div style={{ color: 'red', padding: '5px' }}>{emailError}</div>
              )}
            </div>
            <div style={{ width: '320px' }}>
              <TextField
                onKeyDown={handleKeyDown}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                style={{ width: '320px' }}
                required
                id="outlined-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
              />
              {passwordError && (
                <div style={{ color: 'red', padding: '5px' }}>
                  {passwordError}
                </div>
              )}
            </div>
            <div>
              <Button variant="outlined" onClick={onClickHandler}>
                Login
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </Fragment>
  );
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Login);
