import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Chip from '@mui/material/Chip';
import StorageIcon from '@mui/icons-material/Storage';

import Checkbox from '@mui/material/Checkbox';

export default function BasicMenu({
  setFilterBySelection,
  checkedBoxes,
  setCheckedBoxes,
  setFilters,
  filters,
  enqueueSnackbar,
  arrayOfDataSources,
  filterByDataSource,
  setFilterByDataSource,
  filter
}) {
  const [filterMenuLoading, setFilterMenuLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [options, setOptions] = useState([]);
  const defaultOptions = [
    // { name: 'All', value: 'all', checked: true },
    { name: 'Google Ads', value: 'google_ads_campaign', checked: true },
    { name: 'Facebook Ads', value: 'facebook_ads_campaign', checked: true },
    { name: 'CM360', value: 'cm360_campaign', checked: true },
    { name: 'DV360', value: 'dv360_insertion_order', checked: true },
    { name: 'Snapchat Ads', value: 'snapchat_ads_campaign', checked: true },
    { name: 'TikTok Ads', value: 'tiktok_ads_campaign', checked: true },
    { name: 'Pinterest Ads', value: 'pinterest_ads_campaign', checked: true },
    { name: 'Readpeak', value: 'readpeak_campaign', checked: true },
    { name: 'Strossle', value: 'strossle_campaign', checked: true },
    { name: 'Amazon DSP', value: 'amazon_dsp_order', checked: true },
    { name: 'Taboola Ads', value: 'taboola_ads_campaign', checked: true },
    {
      name: 'Microsoft Advertising',
      value: 'microsoft_advertising_campaign',
      checked: true
    },

    {
      name: 'Microsoft Invest',
      value: 'microsoft_invest_insertion_order',
      checked: true
    },
    {
      name: 'Apple Search Ads',
      value: 'apple_search_ads_campaign',
      checked: true
    },
    { name: 'Adform', value: 'adform_order', checked: true },
    {
      name: 'LinkedIn Ads',
      value: 'linkedin_ads_campaign_group',
      checked: true
    },
    {
      name: 'Manual Data Entries',
      value: 'manualDataEntry',
      checked: true
    }
  ];

  useEffect(() => {
    const newOptions = [];

    for (let defaultOption of defaultOptions) {
      const filterByDataSourceEmpty =
        !Boolean(filterByDataSource.length) &&
        filters.includes('filter_by_data_source');

      const existingOption = filterByDataSource.filter(
        (option) => option.value === defaultOption.value
      )[0];

      if (false) {
        newOptions.push({
          ...defaultOption,
          checked: options.length === filterByDataSource.length
        });
      } else {
        // console.log('existingOption: ', existingOption);

        newOptions.push({
          ...defaultOption,
          checked: existingOption
            ? existingOption.checked
            : filterByDataSourceEmpty && filterMenuLoading
        });
      }
    }

    function compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }

    newOptions?.sort(compare);

    // if (defaultOptions.length === options.length) {
    newOptions.unshift({
      name: 'All',
      value: 'all',
      checked: defaultOptions.length === filterByDataSource.length
    });
    // }
    setOptions(newOptions);
    setFilterMenuLoading(false);
    // console.log('newOptions: ', newOptions);
  }, [arrayOfDataSources, filterByDataSource]);

  useEffect(() => {
    if (!filterByDataSource.length) {
      setFilterByDataSource([
        // { name: 'All', value: 'all', checked: true },
        { name: 'Google Ads', value: 'google_ads_campaign', checked: true },
        { name: 'Facebook Ads', value: 'facebook_ads_campaign', checked: true },
        { name: 'CM360', value: 'cm360_campaign', checked: true },
        { name: 'DV360', value: 'dv360_insertion_order', checked: true },
        { name: 'Snapchat Ads', value: 'snapchat_ads_campaign', checked: true },
        { name: 'TikTok Ads', value: 'tiktok_ads_campaign', checked: true },
        {
          name: 'Pinterest Ads',
          value: 'pinterest_ads_campaign',
          checked: true
        },
        {
          name: 'Readpeak',
          value: 'readpeak_campaign',
          checked: true
        },
        {
          name: 'Strossle',
          value: 'strossle_campaign',
          checked: true
        },
        {
          name: 'Amazon DSP',
          value: 'amazon_dsp_order',
          checked: true
        },
        {
          name: 'Taboola Ads',
          value: 'taboola_ads_campaign',
          checked: true
        },
        {
          name: 'Microsoft Advertising',
          value: 'microsoft_advertising_campaign',
          checked: true
        },
        {
          name: 'Microsoft Invest',
          value: 'microsoft_invest_insertion_order',
          checked: true
        },
        {
          name: 'Apple Search Ads',
          value: 'apple_search_ads_campaign',
          checked: true
        },
        { name: 'Adform', value: 'adform_order', checked: true },
        {
          name: 'LinkedIn Ads',
          value: 'linkedin_ads_campaign_group',
          checked: true
        },
        {
          name: 'Manual Data Entries',
          value: 'manualDataEntry',
          checked: true
        }
      ]);
    }
  }, [arrayOfDataSources]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleToggle = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
    // setFilterBySelection([]);

    const filteredFilter = filters.filter((filter_) => {
      return filter_ !== filter;
    });

    setFilters(filteredFilter);
    setFilterByDataSource([]);
    setCheckedBoxes([]);
  };

  const handleMenuItemClick = (e, option) => {
    const copyOfOptions = [...options];

    if (option.value === 'all') {
      const checkstatus = option.checked;
      for (let i = 0; i < copyOfOptions.length; i++) {
        copyOfOptions[i].checked = !checkstatus;
      }
    } else {
      const dataSourceIndex = options.findIndex((obj) => {
        return obj.value === option.value;
      });

      const copyOfOptions = [...options];

      copyOfOptions[dataSourceIndex].checked =
        !options[dataSourceIndex].checked;
    }

    setOptions(copyOfOptions);

    const checkedSources = copyOfOptions.filter((option) => {
      return option.checked && option.value !== 'all';
    });

    setFilterByDataSource(checkedSources);
    // // setFilterByDataSource
  };

  const label = <div>Data Source Filter</div>;
  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Chip
        icon={<StorageIcon />}
        // ref={anchorRef}
        label={label}
        onClick={handleClick}
        onDelete={handleDelete}
      />
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              style={{ height: '36px', padding: '0 20px 0 0' }}
              data-id={option._id}
              data-name={option.name}
              value={option}
              key={option._id}
              // selected={option.name === 'All Accounts'}
              onClick={(e) => handleMenuItemClick(e, option)}
            >
              <Checkbox
                style={{
                  transform: 'scale(0.7)'
                }}
                checked={option.checked}
                // defaultChecked
                color="default"
                inputProps={{
                  'aria-label': 'checkbox with default color'
                }}
              />
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
