import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_MANAGER_ACCOUNT,
  GET_MANAGER_ACCOUNTS,
  MANAGER_ACCOUNT_ERROR,
  UPDATE_MANAGER_ACCOUNT,
  CLEAR_MANAGER_ACCOUNT,
  MANAGER_ACCOUNT_DELETED
} from './types';

let REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

if (window.location.host === 'nexustable-dev.herokuapp.com') {
  REACT_APP_BACKEND_URL = 'https://nexustable-dev.herokuapp.com/api';
}

// Get current manager account
export const getCurrentManagerAccount =
  (managerAccountId, history) => async (dispatch) => {
    // dispatch({ type: CLEAR_MANAGER_ACCOUNT });

    // try {
    //   const res = await axios.get('/api/manageraccounts/select');

    //   const currentAccount = res.data.filter(
    //     managerAccount =>
    //       managerAccount.managerAccount._id === '5e7671e6ceecbd5ea51a994c'
    //   )[0];

    //   dispatch({
    //     type: GET_MANAGER_ACCOUNT,
    //     payload: currentAccount
    //   });

    try {
      if (managerAccountId) {
        const res = await axios.get(
          REACT_APP_BACKEND_URL +
            `/manageraccounts/?manager_account_id=${managerAccountId}`
        );

        if (!res.data) {
          history.push('/choose-manager-account');
        }
        dispatch({
          type: GET_MANAGER_ACCOUNT,
          payload: res.data
        });
      } else {
        setTimeout(() => history.push('/choose-manager-account'), 10);
      }
    } catch (err) {
      dispatch({
        type: MANAGER_ACCOUNT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
      history.push('/choose-manager-account');
    }
  };

// Get all manager accounts
export const getManagerAccounts = (history) => async (dispatch) => {
  //   dispatch({ type: CLEAR_MANAGER_ACCOUNT });

  try {
    const res = await axios.get(
      REACT_APP_BACKEND_URL + '/manageraccounts/select'
    );

    if (!res || !res.data) {
      history.push('/');
      return;
    }

    dispatch({
      type: GET_MANAGER_ACCOUNTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MANAGER_ACCOUNT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get manager account by ID
export const getManagerAccountById = (managerAccountId) => async (dispatch) => {
  try {
    const res = await axios.get(
      REACT_APP_BACKEND_URL +
        `/manageraccounts/?manager_account_id=${managerAccountId}`
    );

    dispatch({
      type: GET_MANAGER_ACCOUNT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MANAGER_ACCOUNT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Github repos
// export const getGithubRepos = username => async dispatch => {
//   try {
//     const res = await axios.get(`/api/profile/github/${username}`);

//     dispatch({
//       type: GET_REPOS,
//       payload: res.data
//     });
//   } catch (err) {
//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// ----------------------------------------------------------------------

// Create or update manager account
export const createManagerAccount =
  (formData, history, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const res = await axios.post(
        REACT_APP_BACKEND_URL + '/manageraccounts',
        formData,
        config
      );

      dispatch({
        type: GET_MANAGER_ACCOUNT,
        payload: res.data
      });

      dispatch(
        setAlert(
          edit ? 'Manager Account Updated' : 'Manager Account Created',
          'success'
        )
      );

      dispatch(getManagerAccounts());

      if (!edit) {
        history.push('/dashboard');
      }
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: MANAGER_ACCOUNT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

// ----------------------------------------------------------------------------------------

// // Add Experience
// export const addExperience = (formData, history) => async dispatch => {
//   try {
//     const config = {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     };

//     const res = await axios.put('/api/profile/experience', formData, config);

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: res.data
//     });

//     dispatch(setAlert('Experience Added', 'success'));

//     history.push('/dashboard');
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
//     }

//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// // Add Education
// export const addEducation = (formData, history) => async dispatch => {
//   try {
//     const config = {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     };

//     const res = await axios.put('/api/profile/education', formData, config);

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: res.data
//     });

//     dispatch(setAlert('Education Added', 'success'));

//     history.push('/dashboard');
//   } catch (err) {
//     const errors = err.response.data.errors;

//     if (errors) {
//       errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
//     }

//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// // Delete experience
// export const deleteExperience = id => async dispatch => {
//   try {
//     const res = await axios.delete(`/api/profile/experience/${id}`);

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: res.data
//     });

//     dispatch(setAlert('Experience Removed', 'success'));
//   } catch (err) {
//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// // Delete education
// export const deleteEducation = id => async dispatch => {
//   try {
//     const res = await axios.delete(`/api/profile/education/${id}`);

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: res.data
//     });

//     dispatch(setAlert('Education Removed', 'success'));
//   } catch (err) {
//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// };

// // Delete account & profile
// export const deleteAccount = () => async dispatch => {
//   if (window.confirm('Are you sure? This can NOT be undone!')) {
//     try {
//       await axios.delete('/api/profile');

//       dispatch({ type: CLEAR_PROFILE });
//       dispatch({ type: ACCOUNT_DELETED });

//       dispatch(setAlert('Your account has been permanantly deleted'));
//     } catch (err) {
//       dispatch({
//         type: PROFILE_ERROR,
//         payload: { msg: err.response.statusText, status: err.response.status }
//       });
//     }
//   }
// };
