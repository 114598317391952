import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { useLazyQuery } from '@apollo/client';

import { managerAccountUsersQuery } from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';

function CheckList({ filterSearch, checked, setChecked }) {
  const { currentManagerAccount } = useContext(AccountContext);
  const variables = {
    managerAccountUsersInput: {
      managerAccountId: currentManagerAccount._id
    }
  };

  const [loadAccounts, { called, loading, data }] = useLazyQuery(
    managerAccountUsersQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (currentManagerAccount?._id) {
      loadAccounts();
    }
  }, [currentManagerAccount]);

  let listOfData =
    data && data.managerAccountUsers ? data.managerAccountUsers : [];

  //   let listOfData = dummyList;
  const filterField = 'Al';

  listOfData = listOfData.filter((obj) => {
    if (filterSearch) {
      if (
        (obj.name &&
          obj.name.toLowerCase().includes(filterSearch.toLowerCase())) ||
        (obj.email &&
          obj.email.toLowerCase().includes(filterSearch.toLowerCase())) ||
        (obj._id && obj._id.toLowerCase().includes(filterSearch.toLowerCase()))
      ) {
        return true;
      }
    } else {
      return true;
    }
  });

  // const [checked, setChecked] = useState([]);

  const onClickHandler = (e, id) => {
    if (checked.includes(id)) {
      const filteredChecked = checked.filter((checkedId) => checkedId !== id);
      setChecked(filteredChecked);
    } else {
      setChecked([...checked, id]);
    }
  };

  let i = 0;
  const accountList = listOfData.map((obj) => {
    let permissions = '';

    // for (let i=0; i<obj.permissions.length; i++){
    //     if(i===0){
    //         permissions = obj.permissions[i];
    //     } else {
    //         permissions = permissions + ' & ' + obj.permissions[i];
    //     }

    // }

    i++;
    return (
      <Button
        value={obj._id}
        style={{
          gridRowStart: i,
          gridRowEnd: i,
          height: '100%',
          justifyContent: 'flex-start',
          margin: '2px 0px',
          background: checked.includes(obj._id) && 'rgba(255,255,255,0.07)',
          borderBottom: '1px solid rgba(255,255,255,0.05)',
          marginBottom: '5px'
        }}
        // color="primary"
        onClick={(e) => onClickHandler(e, obj._id)}
      >
        <Checkbox
          checked={checked.includes(obj._id)}
          // onChange={(e)=>handleChange(e)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <div style={{ display: 'grid' }}>
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.name}
          </div>
          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              fontSize: '10px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.email}
          </div>
        </div>
      </Button>
    );
  });

  return (
    <div
      style={{
        display: 'grid',
        gridRowStart: 2,
        gridRowEnd: 2,
        gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
        overflowY: 'scroll',
        maxHeight: '300px'
      }}
    >
      {accountList}
    </div>
  );
}

export default CheckList;
