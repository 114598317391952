import React from 'react';

import Tooltip from './Tooltip';

import CommentIcon from '@mui/icons-material/Comment';
import NotificationsIcon from '@mui/icons-material/Notifications';

function IconHead({
  headStyle,
  keyForMap,
  itemKey,
  sortColumnHandler,
  colHandler,
  sortedColumn,
  tooltip,
  index
}) {
  let headTitle = itemKey;

  return (
    <div
      key={keyForMap}
      // draggable
      style={{
        gridColumn: index + 1,
        // backgroundImage: 'linear-gradient(to bottom, white, #f9fafa)',
        backgroundImage: headStyle.backgroundImage,
        color: headStyle.color,
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
        borderRight: headStyle['border'],
        borderBottom: headStyle['border'],
        borderTop: headStyle['border'],
        fontSize: '12px',
        gridRowStart: 1,
        gridRowEnd: 1,
        position: 'sticky',
        top: '0',
        // display: 'grid',
        display: 'inline-table',
        zIndex: '1000',
        height: '100%',
        width: '100%',
        padding: '10px 20px 5px 20px',
        fontWeight: '800'
      }}
    >
      <span
        style={{
          margin: 'auto',
          cursor: 'pointer'
        }}
        onClick={(e) => sortColumnHandler(e, itemKey)}
      >
        <p style={{ textAlign: 'center' }}>
          <span
            // style={{ marginLeft: '6px', whiteSpace: 'nowrap' }}
            style={{
              margin: 'auto',
              whiteSpace: 'nowrap'
            }}
          >
            {' '}
            {headTitle}{' '}
          </span>
        </p>
        <div
          style={{
            fontSize: '10px',
            // color: '#bcbcbc',
            color: headStyle.color,
            margin: 'auto',
            textAlign: 'center',
            whiteSpace: 'nowrap'
            //visibility: subTitle ? 'visible' : 'hidden'
          }}
        >
          {sortedColumn.column === itemKey ? (
            sortedColumn?.order === 'asc' ? (
              <i className="fas fa-sort-down"></i>
            ) : (
              <i className="fas fa-sort-up"></i>
            )
          ) : (
            <span style={{ visibility: 'hidden' }}>
              <i className="fas fa-sort-up"></i>
            </span>
          )}
        </div>
      </span>
      <div
        style={{
          top: '0px',
          right: '0px',
          width: '5px',
          position: 'absolute',
          cursor: 'col-resize',

          userSelect: 'none',
          height: '100%'
        }}
        onMouseDown={(e) => colHandler(e)}
      ></div>
    </div>
  );
}

export default IconHead;
