import React, { useContext, useEffect, useState } from 'react';
// import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';

import { useMutation } from '@apollo/client';

import { updateTimeboxAutoTaggingKeywordsMutation } from '../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../context/AccountContext';

export default function RemoveButton({ selectedAccountId, refetch, input }) {
  const { currentManagerAccount } = useContext(AccountContext);
  // const classes = useStyles();

  // console.log('debug90>input: ', input);
  const { enqueueSnackbar } = useSnackbar();

  const [updateTimeboxAutoTaggingKeywords, { called }] = useMutation(
    updateTimeboxAutoTaggingKeywordsMutation
  );

  const onClickAddHandler = async () => {
    // const input = {
    //   managerAccountId: currentManagerAccount._id,
    //   accountId: selectedAccountId,
    //   status: 'REMOVED'
    // };

    let res;
    try {
      res = await updateTimeboxAutoTaggingKeywords({
        variables: { input: { ...input, status: 'REMOVED' } }
      });
    } catch (error) {
      console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.updateTimeboxAutoTaggingKeywords?.response === 'Success') {
      enqueueSnackbar('Timebox Auto Tagging Keyword removed', {
        variant: 'success'
      });
      refetch();
      // refetchDataSourceInfo();
    }
  };

  // console.log('input: ', input);
  return (
    <div
      // className={classes.root}
      onClick={onClickAddHandler}
    >
      <Fab
        // color="primary"
        // aria-label="add"
        style={{
          backgroundColor: 'rgb(181, 0, 8)',
          minWidth: '40px',
          width: '40px',
          minHeight: '40px',
          height: '40px',
          borderRadius: '5px'
        }}
      >
        <DeleteIcon style={{ color: 'white' }} />
      </Fab>
    </div>
  );
}
