import React from 'react';

// Returns an array of dates between the two dates
var getDates = function (startDate, endDate1) {
  var dates = [];
  var currentDate = new Date(startDate);
  currentDate.setHours(5);
  const endDate = new Date(endDate1);
  endDate.setHours(5);
  const addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    const isoDate = currentDate.toISOString().split('T')[0];
    const short = isoDate.split('-')[1] + '-' + isoDate.split('-')[2];
    // const dayNumberOfMonth = isoDate.split('-')[2];
    const dayNumberOfMonth = currentDate.getDate();

    dates.push([short, isoDate, dayNumberOfMonth]);
    currentDate = addDays.call(currentDate, 1);
  }
  if (
    dates &&
    dates[dates.length] &&
    dates[dates.length - 1][1] !== endDate.toISOString().split('T')[0] &&
    dates.length > 0
  ) {
    const isoDate = endDate.toISOString().split('T')[0];
    const short = isoDate.split('-')[1] + '-' + isoDate.split('-')[2];
    const dayNumberOfMonth = isoDate.split('-')[2];

    dates.push([short, isoDate, dayNumberOfMonth]);
  }
  return dates;
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

var generateMonths = function (dateRange) {
  const months = [];
  const uniqueMonths = [];
  let monthsObj = {};
  for (let i = 0; i < dateRange.length; i++) {
    var year = new Date(dateRange[i][1]).getFullYear();
    var monthNumber = new Date(dateRange[i][1]).getMonth();

    let newMonthsObjectData = {};

    if (
      monthsObj[year + '-' + monthNumber] &&
      monthsObj[year + '-' + monthNumber].count
    ) {
      newMonthsObjectData = {
        [year + '-' + monthNumber]: {
          month: monthNumber,
          count: monthsObj[year + '-' + monthNumber]['count']
            ? monthsObj[year + '-' + monthNumber]['count'] + 1
            : 1
        }
      };
    } else {
      newMonthsObjectData = {
        [year + '-' + monthNumber]: {
          month: monthNumber,
          count: 1
        }
      };
    }

    monthsObj = {
      ...monthsObj,
      ...newMonthsObjectData
    };

    // monthsObj = {
    //   ...monthsObj,
    //   [monthNumber]: monthsObj[monthNumber] ? monthsObj[monthNumber] + 1 : 1
    // };

    // if (!uniqueWeeks.includes(weekNumber)) {
    //   uniqueWeeks.push(weekNumber);
    // }
  }

  return monthsObj;
};

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

var result = getWeekNumber(new Date());
// document.write("It's currently week " + result[1] + ' of ' + result[0]);

var generateWeeks = function (dateRange) {
  const weeks = [];
  const uniqueWeeks = [];
  let weeksObj = {};
  for (let i = 0; i < dateRange.length; i++) {
    var weekNumberAndYear = getWeekNumber(new Date(dateRange[i][1]));
    const year = weekNumberAndYear[0];
    const weekNumber = weekNumberAndYear[1];

    let newWeeksObjectData = {};

    if (
      weeksObj[year + '-' + weekNumber] &&
      weeksObj[year + '-' + weekNumber].count
    ) {
      newWeeksObjectData = {
        [year + '-' + weekNumber]: {
          week: weekNumber,
          count: weeksObj[year + '-' + weekNumber]['count']
            ? weeksObj[year + '-' + weekNumber]['count'] + 1
            : 1
        }
      };
    } else {
      newWeeksObjectData = {
        [year + '-' + weekNumber]: {
          week: weekNumber,
          count: 1
        }
      };
    }

    weeksObj = {
      ...weeksObj,
      ...newWeeksObjectData
    };

    // if (!uniqueWeeks.includes(weekNumber)) {
    //   uniqueWeeks.push(weekNumber);
    // }
  }

  return weeksObj;
};

function GanttHead({
  keyForMap,
  index: colIndex,
  range,
  itemKey,
  sortColumnHandler,
  colHandler,
  sortedColumn,
  selectedDateStart,
  selectedDateStop,
  ganttHeadStyle,
  ganttWidth,
  numberOfItemRowsOpen,
  numberOfVisibleColumns
}) {
  const dateRange = getDates(
    new Date(selectedDateStart),
    new Date(selectedDateStop)
  );

  const monthRange = generateMonths(dateRange);
  const weekRange = generateWeeks(dateRange);

  let monthGridTemplateColumns = '';
  const monthNumberDateHeads = Object.keys(monthRange).map((month, index) => {
    monthGridTemplateColumns =
      monthGridTemplateColumns + ` ${ganttWidth * monthRange[month].count}px`;
    return (
      <div
        className="header-cell"
        key={keyForMap + month + index}
        // draggable
        style={{
          width: '100%',
          overflow: 'hidden',
          //   backgroundColor: 'rgb(66, 66, 66)',
          //   backgroundImage: ganttHeadStyle.default['backgroundImage'],
          textAlign: 'center',
          //   backgroundImage: dateColor(day),
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: ganttHeadStyle.default['border'],
          // borderBottom: ganttHeadStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderTop: ganttHeadStyle.default['border'],
          borderBottom: ganttHeadStyle.default['border'],
          // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: 1,
          gridRowEnd: 1,
          // gridColumnStart: index + 1,
          // gridColumnEnd: index + 1,
          // position: 'sticky',
          // top: '0',
          // // display: 'grid',
          // display: 'inline-table',
          zIndex: '1002',
          // height: '100%',
          height: '19px',
          // width: '100%',
          // width: '20px',
          // padding: '10px 20px',
          fontWeight: '800',
          // paddingTop: '5px'
          //   padding: '14px 0px 0px 0px',
          lineHeight: '17px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          borderRadius:
            numberOfVisibleColumns === colIndex + 1 ? '0px 5px 0px 0px' : '0px'
        }}
      >
        <div
          style={{
            width: '100%',
            // paddingTop: '5px',
            // transform: 'rotate(90deg)',
            whiteSpace: 'nowrap',
            // width: '20px',
            // height: '5px',
            // padding: '5px 0px 0px 3px',
            // fontSize: '10px',
            fontSize: '80%',
            color: ganttHeadStyle.default.color,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
            // paddingLeft: '50px',
            // paddingRight: '50px'
          }}
        >
          {monthNames[monthRange[month].month]}
        </div>
      </div>
    );
  });

  let weekGridTemplateColumns = '';
  const weekNumberDateHeads = Object.keys(weekRange).map((week, index) => {
    weekGridTemplateColumns =
      weekGridTemplateColumns + ` ${ganttWidth * weekRange[week].count}px`;
    return (
      <div
        key={keyForMap + week + index}
        // draggable
        style={{
          width: '100%',
          overflow: 'hidden',
          //   backgroundColor: 'rgb(66, 66, 66)',
          //   backgroundImage: ganttHeadStyle.default['backgroundImage'],
          textAlign: 'center',
          //   backgroundImage: dateColor(day),
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: ganttHeadStyle.default['border'],
          // borderBottom: ganttHeadStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderBottom: ganttHeadStyle.default['border'],
          // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: 1,
          gridRowEnd: 1,
          // gridColumnStart: index + 1,
          // gridColumnEnd: index + 1,
          // position: 'sticky',
          // top: '0',
          // // display: 'grid',
          display: 'inline-table',
          zIndex: '1001',
          height: '100%',
          // width: '100%',
          // width: '20px',
          // padding: '10px 20px',
          fontWeight: '800',
          // paddingTop: '5px'
          //   padding: '14px 0px 0px 0px',
          lineHeight: '17px'
        }}
      >
        <div
          style={{
            // paddingTop: '5px',
            // transform: 'rotate(90deg)',
            whiteSpace: 'nowrap',
            // width: '20px',
            // height: '5px',
            // padding: '5px 0px 0px 3px',
            // fontSize: '10px',
            fontSize: '80%',
            color: ganttHeadStyle.default.color
          }}
        >
          {weekRange[week].week}
        </div>
      </div>
    );
  });

  const dayNumberDateHeads = dateRange.map((day, index) => {
    const dateColor = (day) => {
      // const today_parts = new Date().toISOString().split('T')[0].split('-');
      // const today_parts = new Date().toLocaleDateString().split('/');

      const today = thisMonth + '-' + thisDate;

      if (day[0] === today) {
        // return 'linear-gradient(to bottom, white, rgba(0,173,181,0.3))';
        // return 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,173,181,0.3))';
        return ganttHeadStyle.today['backgroundImage'];
      }
      const day_number = new Date(day[1]).getDay();
      if (day_number === 6 || day_number === 0) {
        // return 'linear-gradient(to bottom, white, rgba(239,240,240,1))';
        // return 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(239,240,240,0.1))';
        return ganttHeadStyle.weekend['backgroundImage'];
      }
      // return 'linear-gradient(to bottom, white, #f9fafa)';
      // return 'rgba(255,255,255,0)';
      //   return ganttHeadStyle.default['backgroundImage'];
      return '';
    };
    // const today_parts = new Date().toISOString().split('T')[0].split('-');
    // const today = today_parts[1] + '-' + today_parts[2];
    // const today_parts = new Date().toLocaleDateString().split('/');
    const todaysDate = new Date();
    const thisMonth = ('0' + (todaysDate.getMonth() + 1)).slice(-2);
    const thisDate = ('0' + todaysDate.getDate()).slice(-2);
    const today = thisMonth + '-' + thisDate;

    return (
      <div key={keyForMap + day[0] + index}>
        <div
          // draggable
          style={{
            width: '100%',
            overflow: 'hidden',
            backgroundImage: dateColor(day),
            // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
            borderRight: ganttHeadStyle.default['border'],
            // borderBottom: ganttHeadStyle['border'],
            // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
            borderBottom: ganttHeadStyle.default['border'],
            // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
            fontSize: '12px',
            gridRowStart: 1,
            gridRowEnd: 1,
            // gridColumnStart: index + 1,
            // gridColumnEnd: index + 1,
            // position: 'sticky',
            // top: '0',
            // // display: 'grid',
            // display: 'inline-table',
            zIndex: '1000',
            height: '100%',
            // width: '100%',
            // width: '20px',
            // padding: '10px 20px',
            fontWeight: '800',
            // paddingTop: '5px'
            //   padding: '14px 0px 0px 0px',
            lineHeight: '18px'
          }}
        >
          <div
            style={{
              textAlign: 'center',
              // paddingTop: '5px',
              // transform: 'rotate(90deg)',
              whiteSpace: 'nowrap',
              // width: '20px',
              // height: '5px',
              // padding: '5px 0px 0px 3px',
              // fontSize: '10px',
              fontSize: '80%',
              color: ganttHeadStyle.default.color
            }}
          >
            <span style={{ display: ganttWidth < 12 && 'none' }}>{day[2]}</span>
          </div>
        </div>
        {day[0] === today && (
          <div
            style={{
              pointerEvents: 'none',
              // backgroundColor: 'rgba(48,48,48,0.3)',
              position: 'absolute',
              // height: 'calc(100vh - 140px)',
              height: numberOfItemRowsOpen * 46 + 'px',
              width: '1px',
              borderLeft: '5px dotted rgba(48,48,48,0.3)',
              borderColor: ganttHeadStyle.today.timeLineColor,
              marginLeft: ganttWidth / 2 - 2.5 + 'px'
            }}
          ></div>
        )}
      </div>
    );
  });

  return (
    <div
      key={colIndex + 1}
      style={{
        gridColumn: colIndex + 1,
        // backgroundImage: 'linear-gradient(to bottom, white, #f9fafa)',
        backgroundImage: ganttHeadStyle.default['backgroundImage'],
        // backgroundColor: 'ganttHeadStyle.background',
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        borderRight: ganttHeadStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
        borderTop: ganttHeadStyle['border'],
        fontSize: '12px',
        // gridRowStart: 1,
        // gridRowEnd: 1,
        position: 'sticky',
        top: '0',
        // display: 'grid',
        // display: 'inline-table',
        zIndex: '1000',
        height: '100%',
        // width: '100%',
        width: dateRange.length * ganttWidth + 'px',
        // padding: '10px 20px',
        fontWeight: '800',
        // paddingTop: '5px'
        display: 'grid',
        // gridTemplateColumns: `repeat(auto-fit, minmax(${ganttWidth}px, ${ganttWidth}px))`
        gridTemplateRows: '18px 18px 17px',
        borderRadius:
          numberOfVisibleColumns === colIndex + 1 ? '0px 5px 0px 0px' : '0px'
      }}
    >
      <div
        style={{
          display: 'grid',
          gridRowStart: 1,
          gridRowEnd: 1,
          width: dateRange.length * ganttWidth + 'px',
          gridTemplateColumns: monthGridTemplateColumns
        }}
      >
        {monthNumberDateHeads}
      </div>
      <div
        style={{
          display: 'grid',
          gridRowStart: 2,
          gridRowEnd: 2,
          width: dateRange.length * ganttWidth + 'px',
          gridTemplateColumns: weekGridTemplateColumns
        }}
      >
        {weekNumberDateHeads}
      </div>
      <div
        style={{
          display: 'grid',
          gridRowStart: 3,
          gridRowEnd: 3,
          width: dateRange.length * ganttWidth + 'px',
          gridTemplateColumns: `repeat(auto-fit, minmax(${ganttWidth}px, ${ganttWidth}px))`
        }}
      >
        {dayNumberDateHeads}
      </div>
    </div>
  );
}

export default React.memo(GanttHead);
