import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';

import { MutationContext } from '../../../../context/MutationContext';

import { AccountContext } from '../../../../context/AccountContext';

import DateTimePicker from './DateTimePicker';

export default function DenseMenu({
  mouseDownTimeboxIds,
  selectedUser,
  timeboxIdThatTriggeredContextMenu,
  setTimeboxIdThatTriggeredContextMenu,
  timeboxes,
  setContextMenuAnchorPosition
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const timebox = timeboxes.filter(
    (timebox) => timebox._id === timeboxIdThatTriggeredContextMenu
  )[0];
  const {
    addTimeboxes,
    updateTimeboxes,
    refetchTimeboxesCounter,
    setRefetchTimeboxesCounter
  } = useContext(MutationContext);
  let statuses = [];

  switch (true) {
    case timebox?.status === 'TIMEBOX_LIST':
      statuses = [{ name: 'Removed', value: 'REMOVED' }];
      break;
    case [
      'UNDEFINED',
      'DRAFT',
      'ACTIVE',
      'COMPLETED',
      'NOT_COMPLETED'
    ].includes(timebox?.status):
      statuses = [
        { name: 'Undefined', value: 'UNDEFINED' },
        // { name: 'TimeboxList', value: 'TIMEBOX_LIST' },
        // { name: 'Draft', value: 'DRAFT' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Completed', value: 'COMPLETED' },
        // { name: 'Not completed', value: 'NOT_COMPLETED' },
        { name: 'Removed', value: 'REMOVED' }
      ];
      break;
  }

  const onClickMenuItem = async (e, action, value) => {
    switch (action) {
      case 'CHANGE_STATUS':
        console.log('selectedUser: ', selectedUser);
        var input = {
          managerAccountId: currentManagerAccount._id,
          selectedUserId: selectedUser?._id,
          updateKeys: ['status'],
          // timeboxes: [{ _id: timeboxIdThatTriggeredContextMenu, status: value }]
          timeboxes: mouseDownTimeboxIds.length
            ? mouseDownTimeboxIds.map((timeboxId) => ({
                _id: timeboxId,
                status: value
              }))
            : [{ _id: timeboxIdThatTriggeredContextMenu, status: value }]
        };

        console.log('update!!!');

        await updateTimeboxes({ variables: { input } });
        setContextMenuAnchorPosition(null);
        break;

      case 'CLEAR_DEPENDENCIES':
        console.log('selectedUser: ', selectedUser);
        var input = {
          managerAccountId: currentManagerAccount._id,
          selectedUserId: selectedUser?._id,
          updateKeys: ['nexusDocumentIds', 'accountId'],

          timeboxes: mouseDownTimeboxIds.length
            ? mouseDownTimeboxIds.map((timeboxId) => ({
                _id: timeboxId,
                accountId: null,
                accountIds: [],
                nexusDocumentIds: []
              }))
            : [
                {
                  _id: timeboxIdThatTriggeredContextMenu,
                  accountId: null,
                  accountIds: [],
                  nexusDocumentIds: []
                }
              ]
        };

        console.log('update!!!');

        await updateTimeboxes({ variables: { input } });
        setContextMenuAnchorPosition(null);
        break;
      case 'UPDATE_DEADLINE':
        var input = {
          managerAccountId: currentManagerAccount._id,
          selectedUserId: selectedUser?._id,
          updateKeys: ['deadline'],
          timeboxes: mouseDownTimeboxIds.length
            ? mouseDownTimeboxIds.map((timeboxId) => ({
                _id: timeboxId,
                deadline: value
              }))
            : [{ _id: timeboxIdThatTriggeredContextMenu, deadline: value }]
        };

        console.log('update!!!');

        await updateTimeboxes({ variables: { input } });
        setContextMenuAnchorPosition(null);
        break;
    }
  };
  if (!timebox) {
    return <></>;
  }
  return (
    <Paper sx={{ width: 250 }}>
      <MenuList dense>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              marginRight: '5px',
              height: '1px',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.12)'
            }}
          ></div>
          <span style={{ fontSize: '12px' }}>Status</span>
          <div
            style={{
              marginLeft: '5px',
              height: '1px',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.12)'
            }}
          ></div>
          {/* <Divider /> */}
        </div>
        {statuses.map((status) => (
          <MenuItem
            onClick={(e) => onClickMenuItem(e, 'CHANGE_STATUS', status.value)}
          >
            <ListItemIcon
              style={{
                visibility: timebox?.status !== status.value && 'hidden'
              }}
            >
              <Check />
            </ListItemIcon>

            {status.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={(e) => onClickMenuItem(e, 'CLEAR_DEPENDENCIES')}>
          <ListItemText>Clear dependencies</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem style={{ height: '50px' }}>
          <DateTimePicker
            onClickMenuItem={onClickMenuItem}
            setContextMenuAnchorPosition={setContextMenuAnchorPosition}
          />
        </MenuItem>
        {/* <MenuItem>
          <ListItemText>Changelog</ListItemText>
        </MenuItem> */}
        {/* <MenuItem>
          <ListItemText>Add space after paragraph</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText>Custom spacing...</ListItemText>
        </MenuItem> */}
      </MenuList>
    </Paper>
  );
}
