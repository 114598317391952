import React, { useState, useContext, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';

import { useSnackbar } from 'notistack';

import Spinner from './../../layout/SpinnerSmall';

// import DisabledDatePickerStart from './DisabledDatePickerStart';
// import DisabledDatePickerStop from './DisabledDatePickerStop';

import BasicDatePicker from './../utils/BasicDatePicker';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import Checkbox from '@mui/material/Checkbox';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '@mui/material/Button';

import { useQuery, useMutation } from '@apollo/client';
import {
  DocumentsToBeCopiedQuery,
  copyDocumentMutation
} from '../../../graphql/queries';

import { AccountContext } from '../../../context/AccountContext';

import { allEvalFieldsExceptTotal } from '../fields';

import { columnDictionary } from '../column-menu/columns';

import { DataContext } from './../../../context/DataContext';

import { InterfaceContext } from './../../../context/InterfaceContext';

import IconComponent from './../icon-component/IconComponent';

import { FilterContext } from '../../../context/FilterContext';

import { AuthContext } from '../../../context/AuthContext';

import SetFiltersComponent from './../data-source-menu/set-filter/SetFiltersComponent';

import { MutationContext } from '../../../context/MutationContext';

function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

function EditSetFiltersDialogContent({ open, setOpen }) {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount, currentManagerAccountId } =
    useContext(AccountContext);

  const {} = useContext(MutationContext);

  const { listOfData } = useContext(DataContext);
  const {
    selectedDateStart,
    selectedDateStop,
    copiedDocumentIds,
    setCopiedDocumentIds,
    editSetFilterMenuItem,
    setEditSetFilterMenuItem,
    documentIdsToBeDuplicated,
    setDocumentIdsToBeDuplicated,
    accountIdForDocumentsToBeDuplicated,
    setAccountIdForDocumentsToBeDuplicated
  } = useContext(InterfaceContext);

  const { checkedBoxes } = useContext(FilterContext);

  const { enqueueSnackbar } = useSnackbar();

  const [documentsToBeCopied, setDocumentsToBeCopied] = useState([]);

  const [awaitingResponse, setAwaitingResponse] = useState(false);

  const { updateDocument } = useContext(MutationContext);

  const [sFilters, setSFilters] = useState([]);

  useEffect(() => {
    setSFilters([...(editSetFilterMenuItem?.setFilters || [])]);
  }, [editSetFilterMenuItem?.setFilters]);

  const handleClose = () => {
    setEditSetFilterMenuItem({});
    setOpen(false);
  };

  const onClickApply = async () => {
    if (sFilters.length) {
      for (let sFilter of sFilters) {
        console.log('sFilter.type:', sFilter.type);
        if (sFilter.string && sFilter.type !== 'STRING') {
          enqueueSnackbar('Error, please reload page and try again.', {
            variant: 'error'
          });
          return;
        }
        if (!sFilter.string && sFilter.type === 'STRING') {
          enqueueSnackbar('Error, empty text field not allowed.', {
            variant: 'error'
          });
          return;
        }
        if (!sFilter?.values?.length && sFilter.type === 'ARRAY') {
          enqueueSnackbar('Error, please select atleast one option.', {
            variant: 'error'
          });
          return;
        }
        if (sFilter.string) {
          const regExName = new RegExp(
            '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ -_.,+-/\\|*()=:#%&:]*$'
          );
          const regExNameResult = regExName.exec(sFilter.string);
          if (!regExNameResult) {
            enqueueSnackbar(
              'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØúÚ _.- are allowed.',
              {
                variant: 'error'
              }
            );

            return console.log('Unallowed characters were used.');
          }
        }
      }
    }
    const item = editSetFilterMenuItem;
    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          setFilters: sFilters
        }
      ],
      updateKeys: ['setFilters']
    };
    setAwaitingResponse(true);
    const res = await updateDocument({
      variables: { input: { ...documentInput } }
    });
    // if (res?.data?.updateDocument?.response === 'Success') {
    // }
    // console.log('res: ', res);

    if (res?.data?.updateDocument?.response === 'Success') {
      enqueueSnackbar('Set filter updated.', {
        variant: 'success'
      });
      setAwaitingResponse(false);
    } else {
      setAwaitingResponse(false);
      enqueueSnackbar('Set filter update failed.', {
        variant: 'error'
      });
    }
    handleClose();
  };

  return (
    <>
      <div
        // className={classes.paper}
        style={{
          //   width: '100%',
          // height: '630px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px 0px'
        }}
      >
        <SetFiltersComponent
          documentType={editSetFilterMenuItem?.documentType}
          sFilters={sFilters}
          setSFilters={setSFilters}
        />

        <div
          style={{
            gridColumn: 4,
            textAlign: 'center',
            marginTop: '50px',
            alignSelf: 'end',
            paddingRight: '25px'
          }}
        >
          {awaitingResponse ? (
            <Spinner />
          ) : (
            <>
              <Button variant="outlined" size="small" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                style={{ minWidth: '91px', marginLeft: '20px' }}
                variant="contained"
                size="small"
                color="primary"
                onClick={onClickApply}
              >
                Apply
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EditSetFiltersDialogContent;
