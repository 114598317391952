import React, { useContext } from 'react';

// import PhoneNumber from './phone-number/PhoneNumber';

import EditEmergencyContactSettingsDialog from './EditEmergencyContactSettingsDialog';

import { AuthContext } from '../../../../../context/AuthContext';

function EmergencyContactSettings({
  refetchUserInfo,
  stylesStoredInReduxManagerAccountSettings,
  userInfoAndAccounts
}) {
  const { user: currentUser } = useContext(AuthContext);
  // console.log('userInfoAndAccounts: ', userInfoAndAccounts);
  var clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let maxWidth = '';
  let minWidth = '';

  switch (clientWidth) {
    case clientWidth < 1600:
      maxWidth = '90%';
      minWidth = '800px';
      break;
    case clientWidth > 1600:
      maxWidth = '760px';
      minWidth = '800px';
      break;
    default:
      maxWidth = '760px';
  }

  return (
    <div
      style={{
        margin: '3vw auto 0vw auto',
        width: '95%',
        maxWidth: maxWidth,
        minWidth: minWidth
      }}
    >
      <div
        style={{
          padding: '25px 30px 25px 30px',
          fontSize: '16px',
          // minWidth: '700px',
          // width: '100%',
          // margin:'50px 30px 0px 30px'
          // margin: '100px auto',
          display: 'grid',
          // gridTemplateRows: '45px repeat(auto-fill, minmax(45x, 45px))',
          // background: 'rgba(255,255,255,0.05',
          background:
            stylesStoredInReduxManagerAccountSettings.childCardBackgroundColor,
          borderRadius: '5px',
          height: 'fit-content'
          //   width: '720px'
        }}
      >
        <div
          //   key={obj._id}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '5px',
            // height: '100%',
            //   gridTemplateColumns: 'auto 50px 50px',
            //   marginTop: '20px',
            // marginLeft: '50px',
            // background: 'rgba(255,255,255,0.05)',
            padding: '10px',
            //   border: '1px solid rgba(255,255,255,0.1)',
            border:
              stylesStoredInReduxManagerAccountSettings.childCardChildBorderColor
            // gridTemplateRows: '30px 20px'
          }}
        >
          <div>
            <span>Emergency contact: </span>
            <span style={{ marginLeft: '30px' }}>
              {userInfoAndAccounts?.emergencyContact ? 'Yes' : 'No'}
            </span>
            <span>
              {Boolean(userInfoAndAccounts?.emergencyDocumentTypes?.length) &&
                ` (${userInfoAndAccounts?.emergencyDocumentTypes?.length} categories)`}
            </span>
          </div>

          <div>
            <EditEmergencyContactSettingsDialog
              userInfoAndAccounts={userInfoAndAccounts}
              refetchUserInfo={refetchUserInfo}
            />
          </div>

          {/* <PhoneNumber
            userInfoAndAccounts={userInfoAndAccounts}
            refetchUserInfo={refetchUserInfo}
          /> */}
        </div>
        {/* <div
          //   key={obj._id}
          style={{
            marginTop: '20px',
            display: 'grid',
            borderRadius: '5px',
            // height: '100%',
            //   gridTemplateColumns: 'auto 50px 50px',
            //   marginTop: '20px',
            // marginLeft: '50px',
            // background: 'rgba(255,255,255,0.05)',
            padding: '15px',
            //   border: '1px solid rgba(255,255,255,0.1)',
            border:
              stylesStoredInReduxManagerAccountSettings.childCardChildBorderColor
            // gridTemplateRows: '30px 20px'
          }}
        >
          <div>Email: </div>
        </div> */}
      </div>
    </div>
  );
}

export default EmergencyContactSettings;
