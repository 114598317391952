import {
  GET_MANAGER_ACCOUNT,
  GET_MANAGER_ACCOUNTS,
  MANAGER_ACCOUNT_ERROR,
  UPDATE_MANAGER_ACCOUNT,
  CLEAR_MANAGER_ACCOUNT,
  MANAGER_ACCOUNT_DELETED
} from '../actions/types';

const initialState = {
  managerAccounts: [],
  currentManagerAccount: {},
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MANAGER_ACCOUNT:
    case UPDATE_MANAGER_ACCOUNT:
      return {
        ...state,
        currentManagerAccount: payload,
        loading: false
      };
    case GET_MANAGER_ACCOUNTS:
      return {
        ...state,
        managerAccounts: payload,
        loading: false
      };
    // case PROFILE_ERROR:
    //   return {
    //     ...state,
    //     error: payload,
    //     loading: false,
    //     profile: null
    //   };
    case CLEAR_MANAGER_ACCOUNT:
      return {
        ...state,
        currentManagerAccount: {},
        managerAccounts: [],
        loading: false
      };
    // case GET_REPOS:
    //   return {
    //     ...state,
    //     repos: payload,
    //     loading: false
    //   };
    default:
      return state;
  }
}
