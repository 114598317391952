import React, { useContext, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
  accountLabelsQuery,
  updateDocumentMutation
} from '../../../../../../graphql/queries';

import { AccountContext } from '../../../../../../context/AccountContext';

import { useSnackbar } from 'notistack';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ accountId, labels, setLabels }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [accountLabels, setAccountLabels] = useState([]);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const variables = {
    accountsLabelsInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccount._id,
      accountId
    }
  };

  const [loadLabels, { called, loading, data }] = useLazyQuery(
    accountLabelsQuery,
    { variables, fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    const labelIds = value.map((obj) => obj._id);
    setLabels(labelIds);
  }, [value]);

  useEffect(() => {
    if (!accountId) {
      return;
    }
    if (accountId && currentManagerAccount) {
      loadLabels();
    }
  }, [accountId]);

  useEffect(() => {
    const sortedAccountLabels = [...(data?.accountLabels || [])];

    sortedAccountLabels.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setAccountLabels(sortedAccountLabels || []);
  }, [data?.accountLabels]);

  return (
    <div style={{ margin: '0px 0px 30px 0px' }}>
      <div>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setEdit(true);
            setValue(newValue);
          }}
          multiple
          id="checkboxes-tags-demo"
          options={accountLabels}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              // onClick={() => setEdit(true)}
              {...params}
              label="Members"
              placeholder="+ Add members"
            />
          )}
        />
      </div>
    </div>
  );
}
