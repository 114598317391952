import React from 'react';

function TotalWidget({
  itemKey,
  itemValue,
  showMetricLine,
  setShowMetricLine
}) {
  const onClickHandler = () => {
    if (showMetricLine?.includes(itemKey)) {
      setShowMetricLine([...showMetricLine].filter((str) => str !== itemKey));
    } else {
      setShowMetricLine([...showMetricLine, itemKey]);
    }
  };
  return (
    <div
      style={{
        padding: '30px',
        background: showMetricLine?.includes(itemKey)
          ? 'rgba(150,150,150,0.3)'
          : 'rgba(150,150,150,0.1)',
        borderRadius: '15px',
        margin: '10px'
      }}
      onClick={onClickHandler}
    >
      <div style={{ fontWeight: 'bold', fontSize: '12px', minWidth: '80px' }}>
        {itemKey}
      </div>
    </div>
  );
}

export default TotalWidget;
