const Joi = require('@hapi/joi');

const stringSchema = Joi.object({
  string: Joi.string()
    .min(0)
    .max(100)
    .pattern(new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ -_.,+-/\\|*()=:#%&:]*$'))
    .required()
});

module.exports = StringSchema = stringSchema;
