import React, { useState, useContext, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { AccountContext } from '../../../context/AccountContext';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { Button } from '@mui/material';

import { useMutation } from '@apollo/client';
import { toggleFavoriteAccountsMutation } from './../../../graphql/queries';

import { styled, useTheme } from '@mui/material/styles';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { InterfaceContext } from '../../../context/InterfaceContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags() {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    accounts,
    refetchAccounts,
    currentAccounts,
    setCurrentAccounts,
    currentAccountIds,
    setCurrentAccountIds,
    currentManagerAccountId,
    accountListLoading
  } = useContext(AccountContext);

  const { resetSelectedAccountOptions } = useContext(InterfaceContext);

  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allAccountsToggled, setAllAccountsToggled] = useState(false);
  const [allFilteredAccountsToggled, setAllFilteredAccountsToggled] =
    useState(false);

  const [initialSelectedOptionsSet, toggleInitialSelectedOptionsSet] =
    useState(false);

  const [inputValue, setInputValue] = useState('');

  const theme = useTheme();

  const [toggleFavoriteAccounts, { called }] = useMutation(
    toggleFavoriteAccountsMutation
  );

  useEffect(() => {
    if (
      !initialSelectedOptionsSet &&
      accounts.length &&
      !selectedOptions.length
    ) {
      setSelectedOptions(
        options.filter((option) => currentAccountIds?.includes(option._id))
      );
      toggleInitialSelectedOptionsSet(true);
    }
  }, [
    currentManagerAccountId,
    accounts,
    accountListLoading,
    currentAccountIds
  ]);

  useEffect(() => {
    if (resetSelectedAccountOptions) {
      setSelectedOptions(
        options.filter((option) => currentAccountIds?.includes(option._id))
      );
    }
  }, [resetSelectedAccountOptions]);

  // useEffect(() => {
  //   let accountIdsInSearchParams = searchParams.get('accountids');
  //
  //   if (accountIdsInSearchParams) {

  //     setCurrentAccountIds(JSON.parse(accountIdsInSearchParams) || []);
  //   }
  // }, [currentManagerAccountId]);

  const sortedAccounts = [...accounts];
  sortedAccounts.sort(function (a, b) {
    var nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
    var nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  sortedAccounts.sort(function (a, b) {
    var isFavoriteA = a?.isFavorite; // ignore upper and lowercase
    var isFavoriteB = b?.isFavorite; // ignore upper and lowercase
    if (isFavoriteA < isFavoriteB) {
      return 1;
    }
    if (isFavoriteA > isFavoriteB) {
      return -1;
    }

    // names must be equal
    return 0;
  });

  // const [options, setOptions]= useState([]);

  let menuOption = {
    _id: inputValue ? 'SELECT_FILTERED' : 'SELECT_ALL',
    type: inputValue ? 'SELECT_FILTERED' : 'SELECT_ALL',
    name: inputValue ? 'Select Filtered' : 'Select All',
    allwaysShow: inputValue
  };

  const optionsWithoutSelect = sortedAccounts;
  const selectedOptionsWithoutSelect = selectedOptions.filter(
    (option) =>
      ![
        'SELECT_FILTERED',
        'DESELECT_FILTERED',
        'SELECT_ALL',
        'DESELECT ALL'
      ].includes(option._id)
  );

  const filterdOptionsWithoutSelect = inputValue
    ? optionsWithoutSelect.filter((option) =>
        (option.name + option._id + option.type + option.allwaysShow)
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      )
    : optionsWithoutSelect;

  const selectedOptionsWithoutSelectIds = selectedOptionsWithoutSelect.map(
    (option) => option._id
  );
  const filterdOptionsWithoutSelectIds = filterdOptionsWithoutSelect.map(
    (option) => option._id
  );

  switch (true) {
    case inputValue &&
      !filterdOptionsWithoutSelect.every((option) =>
        selectedOptionsWithoutSelectIds.includes(option._id)
      ):
      menuOption = {
        _id: 'SELECT_FILTERED',
        type: 'SELECT_FILTERED',
        name: 'Select Filtered',
        allwaysShow: inputValue
      };
      break;
    case inputValue &&
      filterdOptionsWithoutSelect.every((option) =>
        selectedOptionsWithoutSelectIds.includes(option._id)
      ):
      menuOption = {
        _id: 'DESELECT_FILTERED',
        type: 'DESELECT_FILTERED',
        name: 'Deselect Filtered',
        allwaysShow: inputValue
      };
      break;

    case !inputValue &&
      !filterdOptionsWithoutSelect.every((option) =>
        selectedOptionsWithoutSelectIds.includes(option._id)
      ):
      menuOption = {
        _id: 'SELECT_ALL',
        type: 'SELECT_ALL',
        name: 'Select All',
        allwaysShow: inputValue
      };
      break;
    case !inputValue &&
      filterdOptionsWithoutSelect.every((option) =>
        selectedOptionsWithoutSelectIds.includes(option._id)
      ):
      menuOption = {
        _id: 'DESELECT_ALL',
        type: 'DESELECT_ALL',
        name: 'Deselect All',
        allwaysShow: inputValue
      };
      break;
  }

  const options = [menuOption, ...sortedAccounts];

  const onChangeHandler = (event, newValue) => {
    switch (true) {
      case newValue[0].type === 'SELECT_ALL':
        setAllAccountsToggled(true);
        setSelectedOptions([
          ...filterdOptionsWithoutSelect,
          {
            _id: 'DESELECT_ALL',
            type: 'DESELECT_ALL',
            name: 'Deselect All',
            allwaysShow: inputValue
          }
        ]);
        break;
      case newValue[0].type === 'DESELECT_ALL':
        setAllAccountsToggled(false);
        setSelectedOptions([]);
        break;

      case newValue[0].type === 'SELECT_FILTERED' &&
        !allFilteredAccountsToggled:
        setAllFilteredAccountsToggled(true);
        setSelectedOptions([
          ...optionsWithoutSelect.filter((option) =>
            [
              ...selectedOptionsWithoutSelectIds,
              ...filterdOptionsWithoutSelectIds
            ].includes(option._id)
          ),
          {
            _id: 'DESELECT_FILTERED',
            type: 'DESELECT_FILTERED',
            name: 'Deselect Filtered',
            allwaysShow: inputValue
          }
        ]);
        break;
      case newValue[0].type === 'DESELECT_FILTERED':
        setAllFilteredAccountsToggled(false);
        setSelectedOptions(
          selectedOptionsWithoutSelect.filter(
            (option) => !filterdOptionsWithoutSelectIds.includes(option._id)
          )
        ); // Fix
        break;

      default:
        const newValueId = newValue[0]._id;
        const index = selectedOptions.findIndex(
          (obj) => obj._id === newValueId
        );

        const copyOfSelectedOptions = [...selectedOptions];
        if (index >= 0) {
          copyOfSelectedOptions.splice(index, 1);
        } else {
          copyOfSelectedOptions.push(newValue[0]);
        }

        // if (allAccountsToggled) {
        //   setAllAccountsToggled(false);
        //   const allAccountsIndex = selectedOptions.findIndex(
        //     (obj) => obj.type === 'SELECT_ALL'
        //   );
        //   copyOfSelectedOptions.splice(allAccountsIndex, 1);
        // }

        // if (allFilteredAccountsToggled) {
        //   setAllFilteredAccountsToggled(false);
        //   const allAccountsIndex = selectedOptions.findIndex(
        //     (obj) => obj.type === 'SELECT_FILTERED'
        //   );
        //   copyOfSelectedOptions.splice(allAccountsIndex, 1);
        // }

        setSelectedOptions(copyOfSelectedOptions);
    }
  };
  const onCloseHandler = (event) => {
    const newAccountIds = selectedOptions
      .filter(
        (obj) =>
          ![
            'SELECT_ALL',
            'DESELECT_ALL',
            'SELECT_FILTERED',
            'DESELECT_FILTERED'
          ].includes(obj._id)
      )
      .map((obj) => obj._id);

    if (newAccountIds.sort().join(',') === currentAccountIds.sort().join(',')) {
      return;
    }

    setCurrentAccountIds(newAccountIds);

    // let newSearchParams = {
    //   ...(searchParams.entries() || [])
    //   // account_ids: JSON.stringify(newAccountIds),
    //   // manager_account_id: searchParams.get('manager_account_id'),
    //   // start_date: searchParams.get('start_date'),
    //   // end_date: searchParams.get('end_date')
    // };

    // let searchParamKeys = ['manager_account_id', 'start_date', 'end_date'];
    // for (let key of searchParamKeys) {
    //   if (searchParams.get(key)) {
    //     newSearchParams[key] = searchParams.get(key);
    //   }
    // }
    // if (newAccountIds.length) {
    //   newSearchParams.account_ids = JSON.stringify(newAccountIds);
    // }

    // setSearchParams(newSearchParams);
  };

  // useEffect(() => {
  //   let newSearchParams = {
  //     ...(searchParams.entries() || []),
  //     account_ids: JSON.stringify(currentAccountIds),
  //     manager_account_id: JSON.stringify(currentManagerAccountId)
  //     // start_date: searchParams.get('start_date'),
  //     // end_date: searchParams.get('end_date')
  //   };

  //   let searchParamKeys = [
  //     // 'manager_account_id',
  //     'start_date',
  //     'end_date'
  //   ];
  //   for (let key of searchParamKeys) {
  //     if (searchParams.get(key)) {
  //       newSearchParams[key] = searchParams.get(key);
  //     }
  //   }
  //   // if (currentAccountIds.length) {
  //   //   newSearchParams.account_ids = JSON.stringify(currentAccountIds);
  //   // }

  //   // setSearchParams(newSearchParams);
  // }, [currentAccountIds]);

  const onClickStarHandler = async (e, option) => {
    e.stopPropagation();

    if (option.type === 'SELECT_ALL') {
      setSelectedOptions(accounts.filter((account) => account.isFavorite));
    } else if (option.type === 'SELECT_FILTERED') {
      setSelectedOptions(
        accounts.filter(
          (account) =>
            account?.isFavorite &&
            account?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())
        )
      );
    } else {
      const toggleFavoriteAccountsInput = {
        managerAccountId: currentManagerAccountId,
        accountIds: [option._id]
      };

      const res = await toggleFavoriteAccounts({
        variables: { input: toggleFavoriteAccountsInput }
      });

      refetchAccounts();
    }
  };

  useEffect(() => {
    if (
      !inputValue &&
      allAccountsToggled &&
      !filterdOptionsWithoutSelect.every((option) =>
        selectedOptionsWithoutSelectIds.includes(option._id)
      )
    ) {
      setAllAccountsToggled(false);
      setSelectedOptions(
        selectedOptions.filter(
          (option) => !['SELECT_ALL', 'DESELECT_ALL'].includes(option._id)
        )
      );
    }

    if (
      !inputValue &&
      !allAccountsToggled &&
      initialSelectedOptionsSet &&
      filterdOptionsWithoutSelect.every((option) =>
        selectedOptionsWithoutSelectIds.includes(option._id)
      )
    ) {
      setAllAccountsToggled(true);
      setSelectedOptions([
        ...selectedOptionsWithoutSelect,
        {
          _id: 'SELECT_ALL',
          type: 'SELECT_ALL',
          name: 'Select All',
          allwaysShow: inputValue
        },
        {
          _id: 'DESELECT_ALL',
          type: 'DESELECT_ALL',
          name: 'Deselect ALL',
          allwaysShow: inputValue
        }
      ]);
    }

    if (
      inputValue &&
      allFilteredAccountsToggled &&
      !filterdOptionsWithoutSelect.every((option) =>
        selectedOptionsWithoutSelectIds.includes(option._id)
      )
    ) {
      setAllFilteredAccountsToggled(false);
      setSelectedOptions(
        selectedOptions.filter(
          (option) =>
            !['SELECT_FILTERED', 'DESELECT_FILTERED'].includes(option._id)
        )
      );
    }

    if (
      inputValue &&
      !allFilteredAccountsToggled &&
      initialSelectedOptionsSet &&
      filterdOptionsWithoutSelect.every((option) =>
        selectedOptionsWithoutSelectIds.includes(option._id)
      )
    ) {
      setAllFilteredAccountsToggled(true);
      setSelectedOptions([
        ...selectedOptionsWithoutSelect,
        {
          _id: 'SELECT_FILTERED',
          type: 'SELECT_FILTERED',
          name: 'Select Filtered',
          allwaysShow: inputValue
        },
        {
          _id: 'DESELECT_FILTERED',
          type: 'DESELECT_FILTERED',
          name: 'Deselect Filtered',
          allwaysShow: inputValue
        }
      ]);
    }
  }, [inputValue, selectedOptions, filterdOptionsWithoutSelect]);
  if (!currentManagerAccountId) {
    return <span></span>;
  }
  return (
    <Autocomplete
      onBlur={() => setInputValue('')}
      size="small"
      //renderTags={()=><span></span>}
      onChange={onChangeHandler}
      onClose={onCloseHandler}
      inputValue={inputValue}
      value={selectedAccounts}
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) =>
        option?.name + option?._id + option?.type + option?.allwaysShow
      }
      renderOption={(props, option, { selected }) => (
        <li
          key={option._id}
          {...props}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selectedOptions
                .map((obj) => obj._id)
                .includes(option._id)}
            />
            {option?.name}
          </div>
          <Button
            style={{ minWidth: '40px' }}
            onClick={(e) => onClickStarHandler(e, option)}
          >
            {option.isFavorite ? (
              <StarIcon
              // style={{
              //   color:
              //     theme.palette.mode === 'dark'
              //       ? 'rgba(255,255,255,0.3)'
              //       : 'rgba(33, 40, 90,0.3)'
              // }}
              />
            ) : (
              <StarBorderIcon
                style={{
                  color:
                    theme.palette.mode === 'dark'
                      ? 'rgba(255,255,255,0.3)'
                      : 'rgba(33, 40, 90,0.3)'
                }}
              />
            )}
          </Button>
        </li>
      )}
      style={{ width: 300, marginLeft: '50px' }}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          label=""
          placeholder={`Accounts selected (${
            selectedOptions
              .filter(
                (obj) =>
                  ![
                    'SELECT_ALL',
                    'DESELECT_ALL',
                    'SELECT_FILTERED',
                    'DESELECT_FILTERED'
                  ].includes(obj.type)
              )
              .map((obj) => obj._id).length
          })`}
        />
      )}
      onInputChange={(event, newInputValue, reason) => {
        if (reason !== 'reset') {
          setInputValue(newInputValue);
        }
      }}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { name: 'The Shawshank Redemption', year: 1994 },
  { name: 'The Godfather', year: 1972 }
];

//const accountList = [{ _id: "asmdflasd1", type: "ACCOUNT", name: "Cervera" }, { _id: "asmdflasd3", type: "ACCOUNT", name: "Royal" }, { _id: "asmdflasd4", type: "ACCOUNT", name: "Elgiganten" }]
