const mongoose = require('mongoose');
const Schema = mongoose.Schema;

const ReportAssetSchema = new Schema(
  {
    managerAccountIds: [
      {
        type: mongoose.Schema.Types.ObjectId,
        ref: 'ManagerAccount'
      }
    ],
    accountIds: [
      {
        type: mongoose.Schema.Types.ObjectId,
        ref: 'Account'
      }
    ],
    id: String,
    isTemplate: Boolean,

    status: String,
    // thumbnailImageDataUri: String,
    // previewImageDataUri: String,
    // previewImageFileId: {
    //   type: mongoose.Schema.Types.ObjectId,
    //   ref: 'S3BucketFile'
    // },
    previewImageStatus: String,
    previewImageXSmallFileId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'S3BucketFile'
    },
    previewImageSmallFileId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'S3BucketFile'
    },
    previewImageMediumFileId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'S3BucketFile'
    },
    previewImageLargeFileId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'S3BucketFile'
    },

    imageFileId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'S3BucketFile'
    },

    name: String,

    imageNaturalHeight: Number,
    imageNaturalWidth: Number,

    imageNaturalAspectRatio: Number,

    createdByUserId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    createdByManagerAccountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'ManagerAccount'
    },
    lastUpdatedByUserId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    lastUpdatedByManagerAccountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'ManagerAccount'
    },

    removedAt: Date,
    removedByUserId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    removedByManagerAccountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'ManagerAccount'
    }
  },
  { timestamps: true }
);

module.exports = ReportAsset = mongoose.model('ReportAsset', ReportAssetSchema);
