import React, { useContext, useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import { DataContext } from '../../../../../context/DataContext';
import { DialogContext } from '../../../../../context/DialogContext';
import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict
  // colorPalette
} from './../dictionary';

import { prepDataObject } from './prepDataObject';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from './../../../fields';

const desktopOS = [
  {
    label: 'Windows',
    value: 72.72
  },
  {
    label: 'OS X',
    value: 16.38
  },
  {
    label: 'Linux',
    value: 3.83
  },
  {
    label: 'Chrome OS',
    value: 2.42
  },
  {
    label: 'Other',
    value: 4.65
  }
];

const mobileOS = [
  {
    label: 'Android',
    value: 70.48
  },
  {
    label: 'iOS',
    value: 28.8
  },
  {
    label: 'Other',
    value: 0.71
  }
];

const platforms = [
  {
    label: 'Mobile',
    value: 59.12
  },
  {
    label: 'Desktop',
    value: 40.88
  }
];

function PieChartWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const key = widget.metrics?.[0].metric;
  const {
    listOfData,
    arrayOfData,

    listOfFilteredDataSourceRows
  } = useContext(DataContext);

  const { reportBuilderColorPalette: colorPalette } =
    useContext(ReportBuilderContext);

  const { isReportBuilderOpen } = useContext(DialogContext);

  // console.log('debug99>arrayOfData: ', arrayOfData);

  // console.log('PieChartWidget>listOfData: ', listOfData);

  // const [seriesData, setSeriesData] = useState([]);

  const valueFormatter = (item) =>
    `${key}: ${(
      Math.round((item.value + Number.EPSILON) * 100) / 100
    ).toLocaleString()} ( ${item.share}% )`;

  const size = {
    // width: 400,
    // height: 200
    width: widget?.width?.split('px')?.[0],
    height: widget?.height?.split('px')?.[0] * 0.9
  };

  // console.log('PieChartWidget>widget: ', widget);
  const data = {
    data: widget?.data || [], //seriesData,
    valueFormatter
  };

  return (
    <div
      key={widget.id}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center'
        // position: 'relative',
        // width: widget.width.split('px')[0] * 0.9,
        // height: widget.height.split('px')[0] * 0.8,
        // padding: '0px'
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '-1px',
          right: '-1px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '10px',
          // borderRadius: '0px 5px 0px 10px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {/* <div
        style={{
          borderRadius: '0px 0px 5px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px'
        }}
      > */}
        {widget.title}
        {/* </div> */}
      </div>
      <PieChart
        colors={colorPalette} // Use palette
        series={[
          {
            arcLabel: (item) => `${item.share}%`,
            arcLabelMinAngle: 35,
            arcLabelRadius: '60%',
            ...data
          }
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fontWeight: 'bold'
          }
        }}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            itemMarkWidth: 10,
            itemMarkHeight: 10,
            padding: 0,
            labelStyle: {
              fontSize: 12
              //fill: 'blue'
            }
          }
        }}
        {...size}
      />
    </div>
  );
}

export default PieChartWidget;
