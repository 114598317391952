import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { DataContext } from './DataContext';
import { SnackbarProvider, useSnackbar } from 'notistack';
// import { v4 as uuidv4 } from 'uuid';

import {
  addDocumentMutation,
  updateDocumentMutation,
  moveDocumentMutation,
  copyDocumentMutation,
  addTimeboxesMutation,
  updateTimeboxesMutation,
  fetchOutlookCalendarEventsMutation
} from '../graphql/queries';

import { InterfaceContext } from './InterfaceContext';

export const MutationContext = React.createContext();

export const MutationProvider = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    timeboxes,
    setTimeboxes,
    timeboxListTimeboxes,
    setTimeboxListTimeboxes
  } = useContext(DataContext);

  const {
    exportMode,
    selectedDateStart,
    selectedDateStop,
    selectedDateStartTimebox,
    selectedDateStopTimebox,
    dataLevel,
    clientId,
    setIsOpenRowsOutdated
  } = useContext(InterfaceContext);
  // const clientId = uuidv4();

  const [subscriptionCallStack, setSubscriptionCallStack] = useState([]);

  const [timeboxesMutationCallStack, setTimeboxesMutationCallStack] = useState(
    []
  );

  // const [timeboxSubscriptionCallStack, setTimeboxSubscriptionCallStack] =
  //   useState([]);

  const [addDocument_] = useMutation(addDocumentMutation);

  const [copyDocument_] = useMutation(copyDocumentMutation);

  const [updateDocument_] = useMutation(updateDocumentMutation);

  const [moveDocument_, { called }] = useMutation(moveDocumentMutation);

  const [addTimeboxes_] = useMutation(addTimeboxesMutation);
  const [updateTimeboxes_] = useMutation(updateTimeboxesMutation);
  const [fetchOutlookCalendarEvents_] = useMutation(
    fetchOutlookCalendarEventsMutation
  );
  const [refetchCounter, setRefetchCounter] = useState(0);

  const [refetchTimeboxesCounter, setRefetchTimeboxesCounter] = useState(0);

  const [copyOrDuplicateAction, setCopyOrDuplicateAction] =
    useState('DUPLICATE');

  const addDocument = async (parameterObject) => {
    try {
      const newParameterObject = {
        ...parameterObject,
        variables: {
          ...parameterObject.variables,
          input: {
            ...parameterObject.variables.input,
            clientId,
            selectedDateStart,
            selectedDateStop,
            dataLevel
          }
        }
      };
      console.log('newParameterObject: ', newParameterObject);
      const res = await addDocument_(newParameterObject);

      if (res.data.addDocument.action === 'REFETCH') {
        setRefetchCounter(refetchCounter + 1);
        return res;
      }
      setSubscriptionCallStack([
        ...subscriptionCallStack,
        {
          subscriptionType: 'documentAddedSubscription',
          subscriptionData: res.data.addDocument.documentsAdded
        }
      ]);
      // console.log('MutationContext>res: ', res);
      return res;
    } catch (error) {
      console.log('MutationContext>error: ', error);
    }
  };

  const updateDocument = async (parameterObject) => {
    try {
      const newParameterObject = {
        ...parameterObject,
        variables: {
          ...parameterObject.variables,
          input: {
            ...parameterObject.variables.input,
            clientId,
            selectedDateStart,
            selectedDateStop,
            dataLevel
          }
        }
      };
      console.log('newParameterObject: ', newParameterObject);
      const res = await updateDocument_(newParameterObject);

      if (res.data.updateDocument.action === 'REFETCH') {
        setRefetchCounter(refetchCounter + 1);
        return res;
      }
      setSubscriptionCallStack([
        ...subscriptionCallStack,
        {
          subscriptionType: 'documentUpdatedSubscription',
          subscriptionData: res.data.updateDocument.documentsUpdated
        }
      ]);
      // console.log('MutationContext>res: ', res);
      return res;
    } catch (error) {
      console.log('MutationContext>error: ', error);
    }
  };

  const moveDocument = async (parameterObject) => {
    try {
      const newParameterObject = {
        ...parameterObject,
        variables: {
          ...parameterObject.variables,
          input: {
            ...parameterObject.variables.input,
            clientId
            // selectedDateStart,
            // selectedDateStop,
            // dataLevel
          }
        }
      };
      console.log('newParameterObject: ', newParameterObject);
      const res = await moveDocument_(newParameterObject);

      if (res?.data?.moveDocument?.action === 'REFETCH') {
        setIsOpenRowsOutdated(true);
        setRefetchCounter(refetchCounter + 1);
        return res;
      }
      // setSubscriptionCallStack([
      //   ...subscriptionCallStack,
      //   {
      //     subscriptionType: 'documentUpdatedSubscription',
      //     subscriptionData: res.data.updateDocument.documentsUpdated
      //   }
      // ]);
      // console.log('MutationContext>res: ', res);
      return res;
    } catch (error) {
      console.log('MutationContext>error: ', error);
    }
  };

  const copyDocument = async (parameterObject) => {
    try {
      const newParameterObject = {
        ...parameterObject,
        variables: {
          ...parameterObject.variables,
          input: {
            ...parameterObject.variables.input,
            clientId,
            selectedDateStart,
            selectedDateStop,
            dataLevel
          }
        }
      };
      console.log('newParameterObject: ', newParameterObject);
      const res = await copyDocument_(newParameterObject);

      if (res.data.copyDocument.action === 'REFETCH') {
        setRefetchCounter(refetchCounter + 1);
        return res;
      }
      setSubscriptionCallStack([
        ...subscriptionCallStack,
        {
          subscriptionType: 'documentAddedSubscription',
          subscriptionData: res.data.copyDocument.documentsAdded
        }
      ]);
      // console.log('MutationContext>res: ', res);
      return res;
    } catch (error) {
      console.log('MutationContext>error: ', error);
    }
  };

  // ##################################################### TIME BOXES #########################################################

  const addTimeboxes = async (parameterObject) => {
    try {
      const newParameterObject = {
        ...parameterObject,
        variables: {
          ...parameterObject.variables,
          input: {
            ...parameterObject.variables.input,
            clientId,
            selectedDateStartTimebox,
            selectedDateStopTimebox
          }
        }
      };
      console.log('newParameterObject: ', newParameterObject);
      const res = await addTimeboxes_(newParameterObject);

      if (
        res?.data?.addTimesBoxes?.action === 'REFETCH'
        // true
      ) {
        setRefetchTimeboxesCounter(refetchTimeboxesCounter + 1);
        return res;
      }
      setTimeboxesMutationCallStack([
        ...timeboxesMutationCallStack,
        {
          action: 'ADD_TIME_BOX',
          data: res?.data?.addTimeboxes?.timeboxesAdded
        }
      ]);
      // console.log('MutationContext>res: ', res);
      return res;
    } catch (error) {
      enqueueSnackbar('Update failed. Please reload the page.', {
        variant: 'error',
        persist: true
      });
      console.log('MutationContext>error: ', error);
      setRefetchTimeboxesCounter(refetchTimeboxesCounter + 1);
    }
  };

  const updateTimeboxes = async (parameterObject) => {
    try {
      const newParameterObject = {
        ...parameterObject,
        variables: {
          ...parameterObject.variables,
          input: {
            ...parameterObject.variables.input,
            clientId,
            selectedDateStartTimebox,
            selectedDateStopTimebox
          }
        }
      };
      console.log('newParameterObject: ', newParameterObject);
      const res = await updateTimeboxes_(newParameterObject);

      if (
        // true
        res?.data?.updatetimeboxes?.action === 'REFETCH'
      ) {
        setRefetchTimeboxesCounter(refetchTimeboxesCounter + 1);
        return res;
      }
      setTimeboxesMutationCallStack([
        ...timeboxesMutationCallStack,
        {
          action: 'UPDATE_TIME_BOX',
          data: res?.data?.updateTimeboxes.timeboxesUpdated
        }
      ]);
      console.log('MutationContext>res: ', res);
      return res;
    } catch (error) {
      enqueueSnackbar('Update failed. Please reload the page.', {
        variant: 'error',
        persist: true
      });
      console.log('MutationContext>error: ', error);
      setRefetchTimeboxesCounter(refetchTimeboxesCounter + 1);
    }
  };

  const fetchOutlookCalendarEvents = async (parameterObject) => {
    try {
      const newParameterObject = {
        ...parameterObject,
        variables: {
          ...parameterObject.variables,
          input: {
            ...parameterObject.variables.input,
            clientId,
            selectedDateStartTimebox,
            selectedDateStopTimebox
          }
        }
      };
      console.log('newParameterObject: ', newParameterObject);
      const res = await fetchOutlookCalendarEvents_(newParameterObject);
      console.log('debug66>res: ', res);
      if (
        // res.data.updatetimeboxes.action === 'REFETCH'
        true
      ) {
        setRefetchTimeboxesCounter(refetchTimeboxesCounter + 1);
        return res;
      }
      setSubscriptionCallStack([
        ...subscriptionCallStack,
        {
          subscriptionType: 'timeboxesUpdatedSubscription',
          subscriptionData: res.data.updateDocument.documentsUpdated
        }
      ]);
      // console.log('MutationContext>res: ', res);
      return res;
    } catch (error) {
      console.log('MutationContext>error: ', error);
    }
  };

  useEffect(() => {
    // console.log('timeboxesMutationCallStack: ', timeboxesMutationCallStack);
    let copyOfTimeboxes = [...(timeboxes || [])];
    let copyOfTimeboxListTimeboxes = [...(timeboxListTimeboxes || [])];
    // console.log('copyOfTimeboxListTimeboxes: ', copyOfTimeboxListTimeboxes);

    // for (let timeboxesMutation of timeboxesMutationCallStack) {
    for (let i = 0; i < timeboxesMutationCallStack.length; i++) {
      const newOrUpdatedTimeboxes = timeboxesMutationCallStack[i]?.data.map(
        (timebox) => ({
          ...timebox,
          startDateTime: new Date(+timebox.startDateTime),
          endDateTime: new Date(+timebox.endDateTime),
          deadline: timebox?.deadline && new Date(+timebox?.deadline),
          duration: (timebox?.endDateTime - timebox?.startDateTime) / 60000
        })
      );
      console.log('newOrUpdatedTimeboxes: ', newOrUpdatedTimeboxes);
      switch (timeboxesMutationCallStack[i].action) {
        case 'ADD_TIME_BOX':
          const copyOfTimeboxIds = copyOfTimeboxes.map((timebox) =>
            String(timebox._id)
          );
          copyOfTimeboxes = [
            ...copyOfTimeboxes,
            ...newOrUpdatedTimeboxes.filter(
              (timebox) =>
                timebox.status !== 'TIMEBOX_LIST' &&
                !copyOfTimeboxIds.includes(String(timebox._id))
            )
          ];
          const copyOfTimeboxListTimeboxesIds = copyOfTimeboxListTimeboxes.map(
            (timebox) => String(timebox._id)
          );
          copyOfTimeboxListTimeboxes = [
            ...copyOfTimeboxListTimeboxes,
            ...newOrUpdatedTimeboxes.filter(
              (timebox) =>
                timebox.status === 'TIMEBOX_LIST' &&
                !copyOfTimeboxListTimeboxesIds.includes(String(timebox._id))
            )
          ];
          break;
        case 'UPDATE_TIME_BOX':
          // clean timebox list

          // timeboxListTimeboxes,setTimeboxListTimeboxes

          for (let updatedTimebox of newOrUpdatedTimeboxes) {
            const timeboxIndex = copyOfTimeboxes.findIndex(
              (tb) => String(tb._id) === String(updatedTimebox._id)
            );
            // console.log('debug106>timeboxIndex: ', timeboxIndex);
            const indexOfTimeboxesListTimeBox =
              copyOfTimeboxListTimeboxes.findIndex(
                (tb) => String(tb._id) === String(updatedTimebox._id)
              );
            // console.log(
            //   'debug106>indexOfTimeboxesListTimeBox: ',
            //   indexOfTimeboxesListTimeBox
            // );
            switch (updatedTimebox.status) {
              case 'TIMEBOX_LIST':
                if (timeboxIndex > -1) {
                  copyOfTimeboxes.splice(timeboxIndex, 1);
                }
                copyOfTimeboxListTimeboxes.splice(
                  indexOfTimeboxesListTimeBox > -1
                    ? indexOfTimeboxesListTimeBox
                    : copyOfTimeboxListTimeboxes.length,
                  indexOfTimeboxesListTimeBox > -1 ? 1 : 0,
                  updatedTimebox
                );

                break;

              case 'REMOVED':
                if (timeboxIndex > -1) {
                  copyOfTimeboxes.splice(timeboxIndex, 1);
                }
                if (indexOfTimeboxesListTimeBox > -1) {
                  copyOfTimeboxListTimeboxes.splice(
                    indexOfTimeboxesListTimeBox,
                    1
                  );
                }
                break;

              default:
                if (indexOfTimeboxesListTimeBox > -1) {
                  copyOfTimeboxListTimeboxes.splice(
                    indexOfTimeboxesListTimeBox,
                    1
                  );
                }
                copyOfTimeboxes.splice(
                  timeboxIndex > -1 ? timeboxIndex : copyOfTimeboxes.length - 1,
                  timeboxIndex > -1 ? 1 : 0,
                  updatedTimebox
                );
              // copyOfTimeboxes[timeboxIndex] = updatedTimebox;
            }
          }
          break;
      }

      const copyofTimeboxesMutationCallStack = [...timeboxesMutationCallStack];
      copyofTimeboxesMutationCallStack.splice(i, 1);
      setTimeboxesMutationCallStack(copyofTimeboxesMutationCallStack);
    }

    const copyOfTimeboxListTimeboxesWithDeadlines =
      copyOfTimeboxListTimeboxes?.filter((obj) => Boolean(obj.deadline));

    const copyOfTimeboxListTimeboxesWithoutDeadlines =
      copyOfTimeboxListTimeboxes?.filter((obj) => !Boolean(obj.deadline));

    copyOfTimeboxListTimeboxesWithDeadlines?.sort(function (a, b) {
      var deadlineA = a?.deadline;
      var deadlineB = b?.deadline;

      if (deadlineA < deadlineB) {
        return -1;
      }
      if (deadlineA > deadlineB) {
        return 1;
      }

      // deadlines must be equal
      return 0;
    });

    // console.log('sorted: ', sorted);

    setTimeboxListTimeboxes([
      ...copyOfTimeboxListTimeboxesWithDeadlines,
      ...copyOfTimeboxListTimeboxesWithoutDeadlines
    ]);
    // console.log('debug311>copyOfTimeboxes: ', copyOfTimeboxes);
    setTimeboxes(copyOfTimeboxes);
  }, [timeboxesMutationCallStack]);

  return (
    <MutationContext.Provider
      value={{
        subscriptionCallStack,
        setSubscriptionCallStack,
        addDocument,
        copyDocument,
        updateDocument,
        moveDocument,
        refetchCounter,
        setRefetchCounter,
        copyOrDuplicateAction,
        setCopyOrDuplicateAction,
        addTimeboxes,
        updateTimeboxes,
        fetchOutlookCalendarEvents,
        refetchTimeboxesCounter,
        setRefetchTimeboxesCounter
        // timeboxesMutationCallStack,
        // setTimeboxesMutationCallStack
      }}
    >
      {props.children}
    </MutationContext.Provider>
  );
};
