import React, { useEffect, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ReportPageListContextMenuContent from './ReportPageListContextMenuContent';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

export default function BasicPopover(
  {
    // contextMenuAnchorPosition: contextMenuAnchorPosition,
    // setContextMenuAnchorPosition: setContextMenuAnchorPosition,
    // mouseDownWidgetIds,
    // reportPageIdThatTriggeredContextMenu,
    // setReportPageIdThatTriggeredContextMenu
    // reportPages
  }
) {
  //   const [anchorEl, setAnchorEl] = React.useState(null);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  const {
    reportPageListContextMenuAnchorPosition: contextMenuAnchorPosition,
    setReportPageListContextMenuAnchorPosition: setContextMenuAnchorPosition,

    setReportPageIdThatTriggeredContextMenu
  } = useContext(ReportBuilderContext);

  // console.log('contextMenuAnchorPosition: ', contextMenuAnchorPosition);

  const handleClose = () => {
    setContextMenuAnchorPosition(null);
  };

  const open = Boolean(contextMenuAnchorPosition);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!open) {
      setReportPageIdThatTriggeredContextMenu(null);
    }
  }, [open]);

  return (
    <div>
      <Popover
        id={id}
        open={open}
        // anchorEl={anchorEl}
        anchorPosition={contextMenuAnchorPosition}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <ReportPageListContextMenuContent handleClose={handleClose} />
      </Popover>
    </div>
  );
}
