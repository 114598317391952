import React from 'react';
import LinkIcon from '@mui/icons-material/Link';

function Hyperlink({ item, fontSize }) {
  let url = '';
  // console.log('item.startDate: ', item.startDate);

  const startDate = item?.startDate;
  const endDate = item?.endDate;

  switch (true) {
    case item.type === 'facebook_ads_campaign':
      url = `https://business.facebook.com/adsmanager/manage/campaigns?act=${item.dataSourceAccountId}&nav_entry_point=am_local_scope_selector&filter_set=CAMPAIGN_GROUP_SELECTED-STRING_SET%1EIN%1E[%22${item.dataSourceCampaignId}%22]&is_reload_from_account_change`;
      break;
    case item.type === 'snapchat_ads_campaign':
      url = `https://ads.snapchat.com/${item.dataSourceAccountId}`;
      break;
    case item.type === 'tiktok_ads_campaign':
      url = `https://ads.tiktok.com/i18n/perf/campaign?keyword=${item.dataSourceCampaignId}&search_type=1&aadvid=${item.dataSourceAdvertiserId}&reset_cache=1`;
      break;
    case item.type === 'pinterest_ads_campaign':
      url = `https://ads.pinterest.com/advertiser/${item.dataSourceAccountId}/reporting/adgroups/?&campaignIds=[${item.dataSourceCampaignId}]`;
      break;

    case item.type === 'readpeak_campaign':
      url = `https://app.readpeak.com/ad_campaigns/view/${item.dataSourceCampaignId}`;
      break;

    case item.type === 'strossle_campaign':
      url = `https://insights.strossle.com/campaigns?view=overview&group=${item.dataSourceGroupId}&campaign=${item.dataSourceCampaignId}`;
      break;

    case item.type === 'amazon_dsp_order':
      url = `https://advertising.amazon.co.uk/dsp/${item.dataSourceProfileAccountId}/orders/${item.dataSourceInsertionOrderId}/line-items`;
      break;

    case item.type === 'taboola_ads_campaign':
      // url = `https://ads.taboola.com/campaigns?locale=en&accountId=1245141&campaignId=26272740&dimension=SPONSORED&reportId=creative&datePreset=SEVEN_DAYS&filters_active=true&reportViewName=All`
      url = `https://ads.taboola.com/campaigns?accountId=${item.dataSourceAccountNumber}&campaignId=${item.dataSourceCampaignId}&reportId=campaign`;

      if (startDate && endDate) {
        url =
          url + `&startDate=${startDate}&endDate=${endDate}&datePreset=CUSTOM`;
      }

      break;

    case item.type === 'microsoft_advertising_campaign':
      url = `https://ui.ads.microsoft.com/campaign/vnext/overview?aid=${item.dataSourceAccountId}&campaignId=${item.dataSourceCampaignId}`;

      break;

    case item.type === 'microsoft_invest_insertion_order':
      url = `https://invest.xandr.com/bmw/advertisers/${item.dataSourceAdvertiserId}/insertion-orders/in-progress/insertion-order/${item.dataSourceInsertionOrderId}?state=all`;

      break;

    case item.type === 'apple_search_ads_campaign':
      url = `https://app.searchads.apple.com/cm/app/${item.dataSourceAccountId}/report/campaign/${item.dataSourceCampaignId}`;

      break;

    case item.type === 'googleAds' && item.isSet:
      if (item.dataSourceOcid) {
        url = `https://ads.google.com/aw/overview?ocid=${item.dataSourceOcid}`;
      }
      break;
    case item.type === 'google_ads_campaign':
      if (item.dataSourceOcid) {
        url = `https://ads.google.com/aw/overview?campaignId=${item.dataSourceCampaignId}&ocid=${item.dataSourceOcid}`;
      }
      break;

    //   https://ads.google.com/aw/overview?campaignId=1492733021&ocid=117072545
    //   console.log('item.dataSourceCampaignId: ', item.dataSourceCampaignId);
    //   url = `https://ads.google.com/aw/overview?ocid=${item.dataSourceCampaignId}&__e=${item.dataSourceAccountId}`;
    //   break;
    case item.type === 'dv360_insertion_order':
      url = `https://displayvideo.google.com/ng_nav/p/${item.dataSourcePartnerId}/a/${item.dataSourceAdvertiserId}/c/${item.dataSourceCampaignId}/io/${item.dataSourceInsertionOrderId}/explorerlis`;
      break;
    case item.type === 'cm360_campaign':
      url = `https://campaignmanager.google.com/trafficking/#/accounts/${item.dataSourceAccountId}/campaigns/${item.dataSourceCampaignId}/explorer?statuses=0;2`;
      break;
    case item.type === 'adform_order':
      url = `https://flow.adform.com/campaign/${item.dataSourceCampaignId}/overview`;
      break;
    case item.type === 'linkedin_ads_campaign_group':
      url = `https://www.linkedin.com/campaignmanager/accounts/${item.dataSourceAccountId}/campaigns?campaignGroupIds=%5B${item.dataSourceCampaignGroupId}%5D`;
      break;
  }

  if (!url) {
    return <div></div>;
  }

  return (
    <div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <LinkIcon style={{ fontSize }} className="hover-light-grey" />
      </a>
    </div>
  );
}

export default Hyperlink;

// `https://displayvideo.google.com/ng_nav/p/${partnerId}/a/${advertiserId}/c/${campaignId}/io/${insertionOrderId}/explorerlis`
