import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  ReferenceDot,
  Tooltip,
  CartesianGrid,
  Legend,
  Brush,
  ErrorBar,
  AreaChart,
  Area,
  Label,
  LabelList
} from 'recharts';

import { getDayAsString } from './../../roitable_functions/date_functions';

function LineChart1({
  prepared_data,
  leftYAxisMetrics,
  rightYAxisMetrics,
  time,
  isExpanded,
  columnDictionary
}) {
  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  // class CustomizedAxisTick extends PureComponent {
  //     render() {
  //       const {
  //         x, y, stroke, payload,
  //       } = this.props;

  //       return (
  //         <g transform={`translate(${x},${y})`}>
  //           <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
  //         </g>
  //       );
  //     }
  //   }

  const data = prepared_data;

  const listOfColors = [
    '#1784a6',
    // '#00adb5',
    // '#b24393',
    // '#4bacfd',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#7ED321',
    '#BD10E0'
  ];

  function CustomTooltip({ payload, label, active }) {
    if (active) {
      switch (time) {
        case 'day':
          return (
            // <div className="custom-tooltip">
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,0.9',
                padding: '10px',
                borderRadius: '5px',
                color: 'black',
                fontSize: '14px'
              }}
            >
              <p className="label">{`${label}`}</p>
              <p className="intro">{getDayAsString(label)}</p>

              {payload &&
                payload.map((obj) => {
                  return (
                    <p className="label">
                      <span style={{ color: obj.stroke }}>- </span>
                      {`${obj.dataKey}: ${obj.value.toLocaleString()}`}
                    </p>
                  );
                })}

              {/* <p className="label">{`${payload[0].value.toLocaleString()}`}</p> */}

              {/* <p className="desc">Anything you want can be displayed here.</p> */}
            </div>
          );
        case 'week':
          return (
            // <div className="custom-tooltip">
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,0.9',
                padding: '10px',
                borderRadius: '5px',
                color: 'black',
                fontSize: '14px'
              }}
            >
              <p className="label">{`Week ${label.split('-')[1]}`}</p>
              {/* <p className="label">{`${payload[0].value.toLocaleString()}`}</p> */}
              {payload &&
                payload.map((obj) => {
                  return (
                    <p className="label">
                      <span style={{ color: obj.stroke }}>- </span>
                      {`${obj.dataKey}: ${obj.value.toLocaleString()}`}
                    </p>
                  );
                })}

              {/* <p className="desc">Anything you want can be displayed here.</p> */}
            </div>
          );
        case 'month':
          return (
            // <div className="custom-tooltip">
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,0.9',
                padding: '10px',
                borderRadius: '5px',
                color: 'black',
                fontSize: '14px'
              }}
            >
              <p className="label">{`${label}`}</p>
              {/* <p className="label">{`${payload[0].value.toLocaleString()}`}</p> */}
              {payload &&
                payload.map((obj) => {
                  return (
                    <p className="label">
                      <span style={{ color: obj.stroke }}>- </span>
                      {`${obj.dataKey}: ${obj.value.toLocaleString()}`}
                    </p>
                  );
                })}

              {/* <p className="desc">Anything you want can be displayed here.</p> */}
            </div>
          );
      }
    }

    return null;
  }
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  return (
    <div
      style={{
        color: 'grey',
        // height: isExpanded ? vh / 2 : 450
        marginBottom: '10px'
      }}
    >
      <LineChart
        width={isExpanded ? vw : 1260}
        height={isExpanded ? vh / 1.5 : 450}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 40 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="rgba(150,150,150,0.3)" />
        <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
        <YAxis yAxisId="left" scale="auto" />
        <YAxis yAxisId="right" scale="auto" orientation="right" />
        {/* <Tooltip /> */}
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" />
        {/* <Line yAxisId="left" type="monotone" dataKey="cost" stroke="#00adb5" /> */}
        {leftYAxisMetrics.map((metric) => {
          let color = listOfColors.shift();
          if (!color) {
            // color = '#00adb5';
            color = '#1784a6';
          }
          return (
            <Line
              yAxisId="left"
              type="monotone"
              // dataKey={metric}
              dataKey={columnDictionary[metric]}
              stroke={color}
            />
          );
        })}

        {rightYAxisMetrics.map((metric) => {
          let color = listOfColors.shift();
          if (!color) {
            // color = '#00adb5';
            color = '#1784a6';
          }
          return (
            <Line
              yAxisId="right"
              type="monotone"
              // dataKey={metric}
              dataKey={columnDictionary[metric]}
              stroke={color}
            />
          );
        })}
        {/* {rightYAxisMetrics.map((metric)=>(<Line yAxisId="right" type="monotone" dataKey={metric} stroke={listOfColors.shift()} />))} */}
        {/* <Line yAxisId="right" type="monotone" dataKey="cost" stroke="#00adb5" /> */}
      </LineChart>
    </div>
  );
}

export default LineChart1;
