// import './styles.css';
import React from 'react';
import { PieChart, Pie, Sector, Cell, Tooltip } from 'recharts';

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 }
// ];
// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const COLORS = ['rgba(255,255,255,0.7)'];

export default function App({ data01, data02, totalCount }) {
  console.log('totalCount: ', totalCount);
  let renderLabel = function (entry) {
    return `${entry.name} ${Math.round(entry.share * 100 * 100) / 100}%`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: 'rgba(255,255,255,0.7)',
            padding: '10px',
            borderRadius: '5px'
          }}
        >
          <p className="label">{`${payload[0].name}`}</p>
          <p className="label">{`${'Count'} : ${payload[0].value}`}</p>
          <p className="label">{`${'Share'} : ${
            Math.round((payload[0].value / totalCount) * 100 * 100) / 100
          }%`}</p>
        </div>
      );
    }

    return null;
  };

  console.log('data01: ', data01);
  return (
    <PieChart width={600} height={300}>
      <Tooltip content={<CustomTooltip />} />

      <Pie
        data={data01}
        dataKey="value"
        // cx={200}
        // cy={200}
        cx={'50%'}
        cy={'50%'}
        outerRadius={90}
        fill="rgba(255,255,255,0.7)"
        stroke="rgba(167,38,131,0.5)"
        paddingAngle={5}
        startAngle={356}
        endAngle={-4}
      />
      <Pie
        data={data02}
        dataKey="value"
        cx={'50%'}
        cy={'50%'}
        // innerRadius={70}
        // outerRadius={90}
        innerRadius={100}
        outerRadius={110}
        fill="rgba(255,255,255,0.7)"
        stroke="rgba(167,38,131,0.5)"
        // label
        paddingAngle={5}
        label={({
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          value,
          index
        }) => {
          console.log('handling label?');
          const RADIAN = Math.PI / 180;
          // eslint-disable-next-line
          const radius = 25 + innerRadius + (outerRadius - innerRadius);
          // eslint-disable-next-line
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          // eslint-disable-next-line
          const y = cy + radius * Math.sin(-midAngle * RADIAN);

          return (
            <text
              x={x}
              y={y}
              // fill="rgba(255,255,255,0.7)"
              // fill="rgba(167,38,131,0.7)"
              fill="rgba(33,40,90,1)"
              textAnchor={x > cx ? 'start' : 'end'}
              dominantBaseline="central"
            >
              {data02[index].name}
              {/* ({Math.round(data[index].share * 100 * 100) / 100}%) */}
            </text>
          );
        }}
      />
    </PieChart>
  );
}
