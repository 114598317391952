import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_snapchat_ads_campaign_data = (
  snapchatAdsCampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = snapchatAdsCampaign?.action01?.dataSource;
  const action02DataSource = snapchatAdsCampaign?.action02?.dataSource;
  const action03DataSource = snapchatAdsCampaign?.action03?.dataSource;

  const action01DataSourceIsSnapchatAds = action01DataSource === 'snapchatAds';
  const action02DataSourceIsSnapchatAds = action02DataSource === 'snapchatAds';
  const action03DataSourceIsSnapchatAds = action03DataSource === 'snapchatAds';

  const action01Type = snapchatAdsCampaign?.action01?.snapchatAds?.type;

  const action02Type = snapchatAdsCampaign?.action02?.snapchatAds?.type;

  const action03Type = snapchatAdsCampaign?.action03?.snapchatAds?.type;

  const action01Name = snapchatAdsCampaign?.action01?.snapchatAds?.name;

  const action02Name = snapchatAdsCampaign?.action02?.snapchatAds?.name;

  const action03Name = snapchatAdsCampaign?.action03?.snapchatAds?.name;

  const action01AttributionType =
    snapchatAdsCampaign?.action01?.snapchatAds?.attributionType;
  const action02AttributionType =
    snapchatAdsCampaign?.action02?.snapchatAds?.attributionType;
  const action03AttributionType =
    snapchatAdsCampaign?.action03?.snapchatAds?.attributionType;

  const campaign = {
    ...snapchatAdsCampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'snapchat_ads_campaign'
  };

  campaign.action01.name = action01Name;
  campaign.action02.name = action02Name;
  campaign.action03.name = action03Name;

  const runCostFormula = campaign.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (
    snapchatAdsCampaign &&
    snapchatAdsCampaign.snapchatadscampaigndata &&
    snapchatAdsCampaign.snapchatadscampaigndata.length > 0
  ) {
    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adgroup_object of snapchatAdsCampaign.snapchatadscampaigndata) {
      // console.log('heeeeeej');
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      let outbound_clicks = null;

      if (action01DataSourceIsSnapchatAds) {
        action01Count = action01Count + (adgroup_object[action01Type] || null);

        if (['conversion_purchases'].includes(action01Type)) {
          action01Value =
            action01Value +
            (adgroup_object['conversion_purchases_value'] / 1000000 || null);
        }
      }

      if (action02DataSourceIsSnapchatAds) {
        action02Count = action02Count + (adgroup_object[action02Type] || null);

        if (['conversion_purchases'].includes(action02Type)) {
          action02Value =
            action02Value +
            (adgroup_object['conversion_purchases_value'] / 1000000 || null);
        }
      }
      if (action03DataSourceIsSnapchatAds) {
        action03Count = action03Count + (adgroup_object[action03Type] || null);

        if (['conversion_purchases'].includes(action03Type)) {
          action03Value =
            action03Value +
            (adgroup_object['conversion_purchases_value'] / 1000000 || null);
        }
      }

      // if (action02DataSourceIsDV360) {
      //   switch (action02Type) {
      //     case 'conversions':
      //       action02Count =
      //         action02Count + (adgroup_object.metrics.conversions || null);
      //       action02Value =
      //         action02Value + (adgroup_object.metrics.conversionsValue || null);
      //       break;

      //     default:
      //       if (snapchatAdsCampaign && snapchatAdsCampaign.dv360conversions) {
      //         for (let adgroup_conversion_object of snapchatAdsCampaign.dv360conversions) {
      //           if (
      //             adgroup_conversion_object?.floodlightActivityId &&
      //             adgroup_conversion_object?.floodlightActivityId ===
      //               action02ActivityId &&
      //             adgroup_conversion_object.date === adgroup_object.date
      //           ) {
      //             switch (action02AttributionType) {
      //               case 'default':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.totalConversions || null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null) +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //               case 'view':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.postViewConversions || null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null);
      //                 break;
      //               case 'click':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.postClickConversions ||
      //                     null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //             }
      //           }
      //         }
      //       }
      //   }
      // }

      // if (action03DataSourceIsDV360) {
      //   switch (action03Type) {
      //     case 'conversions':
      //       action03Count =
      //         action03Count + (adgroup_object.metrics.conversions || null);
      //       action03Value =
      //         action03Value + (adgroup_object.metrics.conversionsValue || null);
      //       break;

      //     default:
      //       if (snapchatAdsCampaign && snapchatAdsCampaign.dv360conversions) {
      //         for (let adgroup_conversion_object of snapchatAdsCampaign.dv360conversions) {
      //           if (
      //             adgroup_conversion_object?.floodlightActivityId &&
      //             adgroup_conversion_object?.floodlightActivityId ===
      //               action03ActivityId &&
      //             adgroup_conversion_object.date === adgroup_object.date
      //           ) {
      //             switch (action03AttributionType) {
      //               case 'default':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.totalConversions || null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null) +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //               case 'view':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.postViewConversions || null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null);
      //                 break;
      //               case 'click':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.postClickConversions ||
      //                     null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //             }
      //           }
      //         }
      //       }
      //   }
      // }

      // const date_start = new Date(parseInt(adgroup_object.startDate));

      // const dateStartAsUTCString = date_start.toUTCString();

      // var clearUTCDate = moment(date_start).utcOffset(0, true).unix() * 1000;
      // console.log('clearUTCDate: ', clearUTCDate);

      listOfPropagationData.push({
        ...adgroup_object,
        // ...calculatedFields,
        date_start: new Date(+adgroup_object.startDate).getTime(),
        // date_stop: adgroup_object.end_time,
        cost: adgroup_object.spend ? adgroup_object.spend / 1000000 : 0,
        impressions: adgroup_object.impressions || null,
        clicks: adgroup_object.swipes || null,
        action01Type,
        action01Count,
        action01Value,

        action02Type,
        action02Count,
        action02Value,

        action03Type,
        action03Count,
        action03Value,

        accountId: campaignBaseValues.accountId
      });
    }
  }

  campaign.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaign
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_snapchat_ads_campaign_data;
