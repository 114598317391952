import React, { useContext, useState, useEffect, useRef } from 'react';
import { DialogContext } from '../../../context/DialogContext';

import { AuthContext } from '../../../context/AuthContext';
import { AccountContext } from '../../../context/AccountContext';
import { MutationContext } from '../../../context/MutationContext';
import { InterfaceContext } from '../../../context/InterfaceContext';

import { DataContext } from '../../../context/DataContext';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Button } from '@mui/material';

import { useTheme } from '@emotion/react';

import formatDateYYYYMMDD from '../roitable_functions/formatDateYYYYMMDD';

import TimeboxerMenu from './menu/TimeboxerMenu';

import AssessmentIcon from '@mui/icons-material/Assessment';

import QueueIcon from '@mui/icons-material/Queue';

import UserSelectPopover from './user-select-menu/UserSelectPopover';

import DateRangePicker from './datepicker/DateRangePicker';

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function TimeboxHead({ date }) {
  const ref = useRef();
  useEffect(() => {
    // console.log('columnRef: ', columnRef);
    if (date === formatDateYYYYMMDD(new Date())) {
      ref?.current.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, []);
  const theme = useTheme();

  const { user } = useContext(AuthContext);
  const {
    isTimeboxerOpen,
    isTimeboxListOpen,
    setIsTimeboxListOpen,
    isTimeboxViewDialogOpen,
    setIsTimeboxViewDialogOpen,
    timeboxViewDialogType,
    setTimeboxViewDialogType
  } = useContext(DialogContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    selectedDateStartTimebox,
    setSelectedDateStartTimebox,
    selectedDateStopTimebox,
    setSelectedDateStopTimebox,
    selectedUserTimeboxer: selectedUser,
    setSelectedUserTimeboxer: setSelectedUser
  } = useContext(InterfaceContext);

  const onPressDateArrowHandler = (days) => {
    let startDateTime = new Date(selectedDateStartTimebox);
    startDateTime = addDays(startDateTime, days);

    setSelectedDateStartTimebox(startDateTime);

    let endDateTime = new Date(selectedDateStopTimebox);
    endDateTime = addDays(endDateTime, days);

    setSelectedDateStopTimebox(endDateTime);
  };
  return (
    <div
      ref={ref}
      style={{
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgb(28 28 41 / 40%)' // 'rgba(255,255,255,0.1)'
            : 'rgba(255,255,255,0.1)',
        height: '25px',
        // width: '100%',
        width: '300px',
        maxWidth: '300px',
        position: 'relative',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        backdropFilter: 'blur(10px)'
      }}
    >
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute'
          }}
        >
          <Button
            style={{
              minWidth: '20px',
              width: '20px',
              minHeight: '20px',
              height: '20px'
            }}
          >
            <QueueIcon
              style={{ fontSize: '12px' }}
              onClick={() => setIsTimeboxListOpen(!isTimeboxListOpen)}
            />
          </Button>
          <Button
            style={{
              minWidth: '20px',
              width: '20px',
              minHeight: '20px',
              height: '20px'
            }}
          >
            <AssessmentIcon
              style={{ fontSize: '12px' }}
              onClick={() => {
                setTimeboxViewDialogType('USERS');
                setIsTimeboxViewDialogOpen(true);
              }}
            />
          </Button>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <UserSelectPopover
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            right: '0px'
          }}
        >
          <TimeboxerMenu calendarUserId={selectedUser?._id} />
          <Button
            style={{
              minWidth: '20px',
              width: '20px',
              minHeight: '20px',
              height: '20px',
              visibility: 'hidden'
            }}
          ></Button>
        </div>
      </div> */}
      <div
        style={{
          // width: '120px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Button
          style={{
            width: '20px',
            minWidth: '20px',
            height: '20px',
            minHeight: '20px',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={(e) => onPressDateArrowHandler(-1)}
        >
          <ArrowBackIosIcon
            style={{
              fontSize: '10px',
              position: 'relative',
              right: '-2px'
            }}
          />
        </Button>
        {/* <span>{formatDateYYYYMMDD(selectedDateStartTimebox)}</span> */}
        <DateRangePicker date={date} />
        <Button
          style={{
            width: '20px',
            minWidth: '20px',
            height: '20px',
            minHeight: '20px',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={(e) => onPressDateArrowHandler(1)}
        >
          <ArrowForwardIosIcon
            style={{
              fontSize: '10px',
              position: 'relative',
              right: '0px'
            }}
          />
        </Button>
      </div>
    </div>
  );
}

export default React.memo(TimeboxHead);
