import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { useLazyQuery } from '@apollo/client';

import Spinner from './../../../../../../layout/SpinnerSmall';

import { cm360AdvertisersQuery } from './../../../../../../../graphql/queries';
import { AccountContext } from '../../../../../../../context/AccountContext';

function CheckList({
  filterSearch,
  checked,
  setChecked,
  code,
  isLoading,
  toggleIsLoading,
  setAdvertisers,
  listOfData,
  setListOfData
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [skip, toggleSkip] = useState(true);

  useEffect(() => {
    if (currentManagerAccount && currentManagerAccount._id) {
      toggleSkip(false);
    }
  }, [currentManagerAccount]);

  const variables = {
    cm360AdvertisersInput: {
      managerAccountId: currentManagerAccount._id
    }
  };

  const [loadDataSources, { called, loading, data }] = useLazyQuery(
    cm360AdvertisersQuery,
    { variables, fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    if (currentManagerAccount?._id) {
      loadDataSources();
    }
  }, [currentManagerAccount]);

  // const { data, refetch } = useQuery(cm360AdvertisersQuery, {
  //   variables,
  //   fetchPolicy: 'no-cache',
  //   skip
  // });

  // useEffect(() => {
  //   if (data) {
  //     toggleSkip(true);
  //   }
  // }, [data]);

  //   useEffect(()=>{
  //       if(data){
  //           toggleSkip(true);
  //       }
  //   },[data])
  // const [listOfData, setListOfData] = useState([]);

  useEffect(() => {
    if (data && data.cm360Advertisers) {
      setListOfData(data.cm360Advertisers);

      toggleIsLoading(false);
    }
  }, [data]);
  // let listOfData = data && data.googleAdsAccounts ? data.googleAdsAccounts : [];

  //   let listOfData = dummyList;
  const filterField = 'Al';

  let listOfData2 = listOfData.filter((obj) => {
    if (filterSearch) {
      if (
        (obj.name &&
          obj.name.toLowerCase().includes(filterSearch.toLowerCase())) ||
        (obj.advertiserId &&
          obj.advertiserId.toLowerCase().includes(filterSearch.toLowerCase()))
      ) {
        return true;
      }
    } else {
      return true;
    }
  });

  // const [checked, setChecked] = useState([]);

  const onClickHandler = (e, id) => {
    if (checked.includes(id)) {
      const filteredChecked = checked.filter((checkedId) => checkedId !== id);
      setChecked(filteredChecked);
    } else {
      setChecked([...checked, id]);
    }
  };

  let i = 0;
  const accountList = listOfData2.map((obj, index) => {
    let permissions = '';

    // for (let i=0; i<obj.permissions.length; i++){
    //     if(i===0){
    //         permissions = obj.permissions[i];
    //     } else {
    //         permissions = permissions + ' & ' + obj.permissions[i];
    //     }

    // }

    i++;
    return (
      <Button
        className="text-transform-unset"
        value={obj.advertiserId}
        key={index}
        style={{
          gridRowStart: i,
          gridRowEnd: i,
          height: '100%',
          justifyContent: 'flex-start',
          margin: '2px 0px',
          background:
            checked.includes(obj.advertiserId) && 'rgba(255,255,255,0.07)',
          borderBottom: '1px solid rgba(255,255,255,0.05)',
          marginBottom: '5px'
        }}
        // color="primary"
        onClick={(e) => onClickHandler(e, obj.advertiserId)}
      >
        <Checkbox
          checked={checked.includes(obj.advertiserId)}
          // onChange={(e)=>handleChange(e)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <div style={{ display: 'grid' }}>
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.name}
          </div>
          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              fontSize: '10px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.advertiserId}
          </div>
        </div>
      </Button>
    );
  });

  return (
    <div
      style={{
        display: 'grid',
        gridRowStart: 2,
        gridRowEnd: 2,
        gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
        overflowY: 'scroll',
        maxHeight: '400px'
      }}
    >
      {isLoading ? (
        <div style={{ margin: '150px auto' }}>
          <Spinner />
        </div>
      ) : (
        accountList
      )}
    </div>
  );
}

export default CheckList;
