import React, { Fragment } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { useTheme } from '@emotion/react';

export default () => {
  const theme = useTheme();

  return (
    <CircularProgress
      sx={{
        color: theme?.palette?.mode === 'dark' ? 'white' : 'rgb(178, 67, 147)'
      }}
    />
    // <Fragment>
    //   <div className="loader"></div>
    //   {/* <img
    //     src={spinner}
    //     style={{ width: '200px', margin: 'auto', display: 'block' }}
    //     alt='Loading...'
    //   /> */}
    // </Fragment>
  );
};
