import React from 'react';
// import { makeStyles } from '@mui/styles';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// const useStyles = makeStyles((theme) => ({
//   fab: {
//     margin: theme.spacing(2)
//   },
//   absolute: {
//     position: 'absolute',
//     bottom: theme.spacing(2),
//     right: theme.spacing(3)
//   }
// }));

export default function SimpleTooltip({ tooltipMessage }) {
  // const classes = useStyles();

  return (
    // <div>
    <Tooltip title={tooltipMessage}>
      <span aria-label="delete">{tooltipMessage}</span>
    </Tooltip>
    // </div>
  );
}
