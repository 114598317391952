import React, { useState, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';

import { useMutation } from '@apollo/client';
import { createLabelMutation } from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';

export default function BasicPopover({
  item,
  tempLabelList,
  setTempLabelList,
  refetchLabels
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [value, setValue] = useState('');

  const [createLabel] = useMutation(createLabelMutation);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue('');
    setAnchorEl(null);
  };

  const handleSave = async () => {
    const input = {
      managerAccountId: currentManagerAccount._id,
      accountId: item.accountId,
      name: value
    };

    try {
      const res = await createLabel({ variables: { input } });
      if (res?.data?.createLabel?.response === 'Success') {
        console.log('success');
        const newLabel = res?.data?.createLabel.label;
        setTempLabelList([...tempLabelList, newLabel]);
        refetchLabels();
        handleClose();
      }
    } catch (error) {
      console.log('CreateLabel>error');
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="standard" onClick={handleClick}>
        Create Label
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <div
          style={{ padding: '15px', display: 'flex', flexDirection: 'column' }}
        >
          <div>
            <TextField
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{ width: '200px' }}
              id="standard-basic"
              label="Label name"
              variant="standard"
            />
          </div>
          <div
            style={{
              marginTop: '15px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={() => handleClose()} size="small">
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              variant="contained"
              size="small"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
