import React, { useState } from 'react';

function TextWidget({ widget, displayMode, updateWidget, index }) {
  const [edit, toggleEdit] = useState(false);

  const [textInput, setTextInput] = useState(widget.value);
  const onChangeHandler = (e) => {
    setTextInput(e.target.value);
  };

  const onClickHandler = () => {};

  const onSaveHandler = () => {
    updateWidget({ ...widget, value: textInput }, index);
    toggleEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('do validate');
      updateWidget({ ...widget, value: textInput }, index);
      toggleEdit(false);
    }
  };

  return (
    <div onClick={() => toggleEdit(true)} onBlur={(e) => onSaveHandler(e)}>
      {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
      {edit ? (
        <input
          autoFocus
          style={{ width: textInput.length + 'ch', minWidth: '3ch' }}
          value={textInput}
          onChange={onChangeHandler}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <span>{displayMode === 'EXAMPLE' ? widget.value : widget.name}</span>
      )}
    </div>
  );
}

export default TextWidget;
