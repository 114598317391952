import React, { useEffect } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';

export default function Checkboxes({
  setCheckedBoxes,
  rawArrayOfData,
  currentAccountIds,
  arrayOfData,
  checkedBoxes,
  index
}) {
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    setChecked(false);
  }, [currentAccountIds]);

  useEffect(() => {
    if (arrayOfData.length > checkedBoxes.length) {
      setChecked(false);
    }
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (!checked) {
      setCheckedBoxes([...arrayOfData]);
    } else {
      setCheckedBoxes([]);
    }
  };

  return (
    <div style={{ gridColumn: index + 1 }}>
      <Checkbox
        size="small"
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      {/* <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        name="checkedI"
      /> */}
    </div>
  );
}
