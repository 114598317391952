import { dimensionDict } from './../../report-builder-content/dictionary';
const tableChartWidget = async (
  widget,
  pres,
  slide,
  colorPalette,
  dummySlide
) => {
  // let dataChartObj = {
  //   name: widget.title,
  //   labels: [], // widget.data.map((obj) => obj.label),
  //   values: [] // widget.data.map((obj) => obj.value)
  // };

  // let totalValue = 0;

  // for (let obj of widget.data) {
  //   totalValue = totalValue + (obj.value || null);
  // }

  // for (let obj of widget.data) {
  //   if (obj.value / totalValue >= 0.005) {
  //     dataChartObj.labels.push(obj.label);
  //     dataChartObj.values.push(obj.value);
  //   }
  // }
  // let dataChartPieStat = [dataChartObj];

  //   let rows = [
  //     [
  //         { text: "Top Lft", options: { align: "left", fontFace: "Arial" } },
  //         { text: "Top Ctr", options: { align: "center", fontFace: "Verdana" } },
  //         { text: "Top Rgt", options: { align: "right", fontFace: "Courier" } },
  //     ],
  // ];

  const colWPx = [
    2,
    ...widget.metrics.map(
      (metric) => widget.columnWidths[metric.metric] * 1 + 10
    )
  ];

  let sumColWPx = 0;
  for (let val of colWPx) {
    sumColWPx += val;
  }
  colWPx[0] = +widget.width.split('px')[0] - (sumColWPx - colWPx[0]);
  // let maxNumberOfLetters = Math.round((colWPx[0] + 40) / 6);

  // console.log('maxNumberOfLetters: ', maxNumberOfLetters);

  const colW = colWPx.map((val) => val / 72 / 2);

  const rowWidthDictionary = {
    impressions: 2
  };

  const borderColor = 'EEEEEE';

  let rows = [
    [
      ...widget.metrics.map((metricObj, index) => {
        const metric = metricObj.metric;
        // let width = 1;

        // switch (metric) {
        //   case 'clicks':
        //     width = 1;
        //     break;
        //   case 'impressions':
        //     width = 2;
        //     break;
        // }
        return {
          text: metric[0].toUpperCase() + metric.slice(1),
          options: {
            align: 'right',
            fontFace: 'Arial',
            bold: true,
            w: rowWidthDictionary[metric] || 1,

            border: [
              {
                type: 'none',
                pt: '1',
                color: borderColor
              },
              {
                type: index === widget.metrics.length - 1 && 'none',
                pt: '1',
                color: borderColor
              },
              {
                // type: 'solid',
                pt: '1',
                color: borderColor
              },
              {
                // type: 'solid',
                pt: '1',
                color: borderColor
              }
            ]
          }
        };
      })
    ]
  ];

  if (widget.dimensions.length) {
    rows[0].unshift({
      text: dimensionDict[widget.dimensions[0]] || widget.dimensions[0],
      options: {
        align: 'left',
        fontFace: 'Arial',
        bold: true,

        border: [
          {
            type: 'none',
            pt: '1',
            color: 'BCBCBC'
          },
          {
            // type: 'solid',
            pt: '1',
            color: borderColor
          },
          {
            // type: 'solid',
            pt: '1',
            color: borderColor
          },
          {
            type: 'none',
            pt: '1',
            color: borderColor
          }
        ]
      }
    });
  }

  let numberOfDecimals = 2;
  let rowIndex = 0;
  for (let row of widget.data) {
    let maxNumberOfLetters = 0;
    let textWidth = 0;
    for (let letter of row['label']) {
      if (textWidth > colWPx[0]) {
        break;
      }
      console.log('letter: ', letter);
      switch (true) {
        case '[]()!|lj,.:1 '.includes(letter):
          textWidth = textWidth + 3;
          break;
        case 'ABCDEFGHIJKLMNOP'.includes(letter):
          textWidth = textWidth + 12;
          break;
        default:
          textWidth = textWidth + 8;
      }
      maxNumberOfLetters++;
    }

    let newRow = [];
    if (widget.dimensions.length) {
      newRow.unshift({
        text:
          row['label'].slice(0, maxNumberOfLetters) +
          (row['label'].length > maxNumberOfLetters ? '...' : ''),
        options: {
          align: 'left',
          fontFace: 'Arial',
          border: [
            {
              type: 'solid',
              pt: '1',
              color: borderColor
            },
            {
              type: 'solid',
              pt: '1',
              color: borderColor
            },
            {
              type: rowIndex === widget.data.length - 1 && 'none',
              pt: '1',
              color: borderColor
            },
            {
              type: 'none',
              pt: '1',
              color: borderColor
            }
          ]
        }
      });
    }
    let metricIndex = 0;
    for (let metricObj of widget.metrics) {
      const metric = metricObj.metric;

      switch (true) {
        case [
          'impressions',
          'viewableImpressions',
          'measurableImpressions',
          'eligibleImpressions',
          'videoCompletions',
          'clicks'
        ].includes(metric):
          numberOfDecimals = 0;
          break;
      }

      newRow.push({
        text: row[metric].toLocaleString(undefined, {
          minimumFractionDigits: numberOfDecimals,
          maximumFractionDigits: numberOfDecimals
        }),
        options: {
          align: 'right',
          fontFace: 'Arial',
          // bold: true,
          w: rowWidthDictionary[metric] || 1,

          border: [
            {
              type: 'solid',
              pt: '0',
              color: borderColor
            },
            {
              type: metricIndex === widget.metrics.length - 1 && 'none',
              pt: '0',
              color: borderColor
            },
            {
              type: rowIndex === widget.data.length - 1 && 'none',
              pt: '1',
              color: borderColor
            },
            {
              type: 'solid',
              pt: '0',
              color: borderColor
            }
          ]
        }
      });

      metricIndex++;
    }
    rows.push(newRow);

    rowIndex++;
  }
  // console.log('rows: ', rows);
  await dummySlide.addTable(rows, {
    // w: 9
    w: +widget.width.split('px')[0] / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,

    // rowH: 1,
    align: 'left',
    fontFace: 'Arial',
    fontSize: 6
    // border: {
    //   type: 'solid',
    //   pt: 1,
    //   color: borderColor
    // }
  });

  // console.log(
  //   'slide: ',
  //   slide._slideObjects[slide._slideObjects.length - 1].options.h
  // );

  const cardHeight =
    dummySlide._slideObjects[dummySlide._slideObjects.length - 1].options.h;
  const cardHWidth =
    dummySlide._slideObjects[dummySlide._slideObjects.length - 1].options.h;

  // console.log(
  //   'dummySlide._slideObjects[dummySlide._slideObjects.length - 1]: ',
  //   dummySlide._slideObjects[dummySlide._slideObjects.length - 1]
  // );
  // slide._slideObjects[slide._slideObjects.length - 2].options.h = cardHeight;

  // console.log('cardHeight: ', cardHeight);
  if (widget.isShowingCard) {
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
      fill: {
        color: 'FFFFFF',
        transparency: (widget.opacity - 1) * -100 //5
      },
      border: { pt: '1', color: 'f1f1f1', transparency: 10 },
      w: +widget.width.split('px')[0] / 72 / 2,
      // h: +widget.height.split('px')[0] / 72 / 2,
      h: Math.round(cardHeight * 1.0),
      // h: 1190625,
      x: +widget.left.split('px')[0] / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2,

      rectRadius: 0.05,
      shadow: {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }
    });
  }

  await slide.addTable(rows, {
    // w: 9
    w: +widget.width.split('px')[0] / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,

    // rowH: 1,
    align: 'left',
    fontFace: 'Arial',
    fontSize: 6,
    valign: 'middle',
    colW
    // border: {
    //   type: 'solid',
    //   pt: 1,
    //   color: borderColor
    // }
  });
};

export default tableChartWidget;
