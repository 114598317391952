import React from 'react';

import { styled, useTheme } from '@mui/material/styles';

import { useInView } from 'react-intersection-observer';

function NexusTableChangelog({ log }) {
  const theme = useTheme();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  return (
    <div
      ref={ref}
      style={{
        background:
          theme.palette.mode === 'dark'
            ? 'rgba(255,255,255,0.1)'
            : 'rgba(255,255,255,0.7)',
        borderRadius: '5px',
        padding: '10px',
        width: '100%',
        border:
          theme.palette.mode === 'dark'
            ? '1px solid rgba(255,255,255,0.1)'
            : '1px solid rgba(255,255,255,0.9)',
        backdropFilter: inView && 'blur(10px)'
        // boxShadow:
        //   '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
      }}
    >
      <div style={{ fontStyle: 'italic', fontSize: '10px' }}>{log.date}</div>
      <div style={{ fontWeight: 'bold', fontSize: '12px', marginTop: '5px' }}>
        {log.headline}
      </div>
      <div style={{ fontSize: '12px', marginTop: '5px' }}>{log.text}</div>
      <div style={{ fontSize: '12px', marginTop: '5px' }}>
        {log.requiredAction}
      </div>
    </div>
  );
}

export default NexusTableChangelog;
