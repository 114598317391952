import React, { useState, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import CloseIcon from '@mui/icons-material/Close';
// import RemoveIcon from '@mui/icons-material/Remove';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { styled, useTheme } from '@mui/material/styles';

function WidgetOption({
  widgetIndex,
  onClickRemoveOptionHandler,
  option,
  options,
  setOptions,
  optionIndex,
  textInput,
  setTextInput,
  setWidgetOption,
  inputRef,
  toggleEdit,
  editMode,
  widget,
  updateWidget,
  isDraggingOption,
  setIsDraggingOption
}) {
  const [isDraggingThisOption, setIsDraggingThisOption] = useState(false);
  const theme = useTheme();

  const [optionTextInput, setOptionTextInput] = useState(null);
  const [optionEdit, toggleOptionEdit] = useState(false);

  console.log('option: ', option);
  useEffect(() => {
    setOptionTextInput(option?.value);
  }, [option.value]);

  console.log('optionTextInput: ', optionTextInput);
  const onChangeHandler = (e) => {
    setOptionTextInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    // event.preventDefault();
    console.log('event: ', event);
    if (event.key === 'Enter') {
      console.log('do validate');
      console.log('{ ...option, value: optionTextInput }: ', {
        ...option,
        value: optionTextInput
      });
      setWidgetOption(event, optionIndex, {
        ...option,
        value: optionTextInput
      });
      //   updateWidget({ ...widget, value: textInput }, optionIndex);
      toggleOptionEdit(false);
      inputRef.current.focus();
      return;
    }

    // console.log('set input');
    // setTextInput(event.target.value);
  };

  const dragStart = (e, value, origin, originIndex) => {
    // toggleGrabbing(true);
    setIsDraggingOption(true);
    e.stopPropagation();
    console.log('origin: ', origin);
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({ value, origin, originIndex })
    );
    // console.log('{ value, origin, originIndex }: ', {
    //   value,
    //   origin,
    //   originIndex
    // });
    // console.log(
    //   'JSON.stringify({ value, origin, originIndex }): ',
    //   JSON.stringify({ value, origin, originIndex })
    // );
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    // setIsDraggingWidget(false);
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dropOnOption = (e, value, optionIndex) => {
    // toggleGrabbing(false);

    setIsDraggingOption(false);

    e.preventDefault();

    e.stopPropagation();

    const droppedItemString = e.dataTransfer.getData('droppedItem');

    console.log('debug44>droppedItemString: ', droppedItemString);
    const droppedItem = JSON.parse(droppedItemString);
    console.log('debug44>dropOnOption 6');
    // if (
    //   (droppedItem.originRowIndex || droppedItem.originRowIndex === 0) &&
    //   droppedItem.originRowIndex !== rowIndex
    // ) {
    //   return;
    // }
    let copyOfOptions = [...options];
    console.log('debug55>droppedItem.origin: ', droppedItem.origin);

    // if (droppedItem.origin === 'item_widgets') {
    console.log('droppedItem.index: ', droppedItem.originIndex);
    copyOfOptions.splice(droppedItem.originIndex, 1);

    console.log('copyOfOptions: ', copyOfOptions);
    // }
    let indexToSplice =
      droppedItem.originIndex < optionIndex ? optionIndex - 1 : optionIndex;
    copyOfOptions.splice(indexToSplice, 0, droppedItem.value);

    setOptions(copyOfOptions);
    console.log('droppedItem1: ', droppedItem);

    const droppedItemElement = document.getElementById(droppedItem);
  };

  let color = '';
  switch (true) {
    case option.value === textInput:
      color = 'white';
      break;

    case !option.value.includes(textInput) && !editMode:
      color = 'grey';
      break;

    default:
      color = theme?.palette?.mode === 'dark' ? 'white' : 'black';
  }

  let background = '';
  switch (true) {
    case option.value === textInput:
      background = 'rgba(167,38,131,.9)';
      break;

    case !option.value.includes(textInput):
      background =
        theme?.palette?.mode === 'dark'
          ? 'rgba(255,255,255,0.05)'
          : 'rgba(255,255,255,0.7)';
      break;

    default:
      background =
        theme?.palette?.mode === 'dark'
          ? 'rgba(255,255,255,0.05)'
          : 'rgba(255,255,255,0.7)';
  }

  return (
    <div
      style={{ paddingBottom: '3px' }}
      onDrop={(e) => dropOnOption(e, widget, optionIndex)}
    >
      <div
        draggable={editMode}
        onDragOver={(e) => dragOver(e, option, optionIndex)}
        onDragStart={(e) => {
          dragStart(e, option, 'widget_options', optionIndex);
          setIsDraggingThisOption(true);
        }}
        onDragEnd={(e) => {
          dragEnd(e, option, optionIndex);
          setIsDraggingThisOption(false);
        }}
        key={optionIndex}
        style={{
          borderRadius: '3px 3px 3px 3px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '25px',
          color,
          // option.value === textInput
          //   ? 'white'
          //   : theme?.palette?.mode === 'dark'
          //   ? 'white'
          //   : 'black',
          background,
          //   option.value === textInput
          //     ? `
          //   rgba(167,38,131,.9)

          // `
          //     : theme?.palette?.mode === 'dark'
          //     ? 'rgba(255,255,255,0.05)'
          //     : 'rgba(255,255,255,0.7)',
          backdropFilter: 'blur(10px)',
          padding: editMode ? '0px 5px 0px 0px' : '0px 5px'
        }}

        // tabIndex={index}
        // onSelect={() => {
        //   console.log('hej');
        // }}
      >
        {editMode && (
          <DragIndicatorIcon
            style={{
              color: 'lightgrey',
              padding: '0px 3px',
              fontSize: '20px',
              cursor: editMode
                ? isDraggingOption
                  ? 'grabbing'
                  : 'grab'
                : 'pointer'
            }}
          />
        )}

        {optionEdit ? (
          <input
            autoFocus
            value={optionTextInput}
            onKeyDown={handleKeyDown}
            onChange={onChangeHandler}
            // onClick={function () {
            //   this.select();
            // }}
          />
        ) : (
          <span
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            onClick={(e) => {
              if (!editMode) {
                e.stopPropagation();
                setTextInput(option.value);
                updateWidget({ ...widget, value: option.value }, widgetIndex);
                toggleEdit(false);
              }
            }}
          >
            {option.value}
          </span>
        )}
        {editMode && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '48px'
              //   paddingRight: '3px'
            }}
          >
            {optionEdit ? (
              <CloseIcon
                style={{
                  fontSize: '15px',
                  cursor: 'pointer',
                  padding: '0 2px'
                }}
                onClick={() => toggleOptionEdit(false)}
              />
            ) : (
              <EditIcon
                style={{
                  fontSize: '15px',
                  cursor: 'pointer',
                  padding: '0 2px'
                }}
                onClick={() => toggleOptionEdit(true)}
              />
            )}
            <StarBorderIcon
              style={{ fontSize: '15px', cursor: 'pointer', padding: '0 2px' }}
            />

            <RemoveCircleIcon
              style={{ fontSize: '15px', cursor: 'pointer', padding: '0 2px' }}
              onClick={(e) => onClickRemoveOptionHandler(e, optionIndex)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default WidgetOption;
