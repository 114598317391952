import React, { useContext } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useMutation } from '@apollo/client';

import { updateDocumentMutation } from './../../../../../graphql/queries';

import { MutationContext } from '../../../../../context/MutationContext';

export default function CheckboxLabels({ currentManagerAccountId, item }) {
  //   const [checked, setChecked] = React.useState(itemValue);

  // const [updateDocument, { called }] = useMutation(updateDocumentMutation);

  const { updateDocument } = useContext(MutationContext);

  const handleChange = (event) => {
    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          showSubTasksOnCard: !item.showSubTasksOnCard
        }
      ],
      updateKeys: ['showSubTasksOnCard']
    };

    console.log('documentInput: ', documentInput);

    updateDocument({ variables: { input: { ...documentInput } } });
    // setChecked(event.target.checked);
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={item.showSubTasksOnCard}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Show on Card"
      />
    </FormGroup>
  );
}
