import React, { useState, useContext, useEffect } from 'react';

import { AuthContext } from './AuthContext';

import { useLazyQuery } from '@apollo/client';

import { managerAccountsQuery, accountQuery } from './../graphql/queries';

export const AccountContext = React.createContext();

export const AccountProvider = (props) => {
  const { isAuthenticated, user } = useContext(AuthContext);

  const [managerAccounts, setManagerAccounts] = useState([]);
  const [currentManagerAccount, setCurrentManagerAccount] = useState({});
  const [currentManagerAccountId, setCurrentManagerAccountId] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [nexusDocumentStatuses, setNexusDocumentStatuses] = useState([]);
  const [nexusDocumentInvoiceStatuses, setNexusDocumentInvoiceStatuses] =
    useState([]);
  const [subscriptionSkip, setSubscriptionSkip] = useState(true);
  // const [currentAccountIds, updateCurrentAccountIds] = useState(
  //   localStorage.getItem(currentManagerAccountId + '_accountIds')
  //     ? JSON.parse(
  //         localStorage.getItem(currentManagerAccountId + '_accountIds')
  //       )
  //     : []
  // );

  let params = new URL(document.location).searchParams;
  let accIds = params.get('account_ids'); // is the string "Jonathan Smith".

  const [currentAccountIds, updateCurrentAccountIds] = useState(
    accIds && decodeURIComponent(accIds).split(',')?.length
      ? decodeURIComponent(accIds).split(',')
      : localStorage.getItem('accountIds')
      ? JSON.parse(localStorage.getItem('accountIds'))
      : []
  );

  // useEffect(() => {

  //   if (currentManagerAccountId) {
  //     updateCurrentAccountIds(
  //       localStorage.getItem(currentManagerAccountId + '_accountIds')
  //         ? JSON.parse(
  //             localStorage.getItem(currentManagerAccountId + '_accountIds')
  //           )
  //         : []
  //     );
  //   }
  // }, [currentManagerAccountId]);

  const [currentAccounts, setCurrentAccounts] = useState([]);

  // const setCurrentAccountIds = (accIds) => {
  //   updateCurrentAccountIds(accIds);
  //   localStorage.setItem(
  //     currentManagerAccountId + '_accountIds',
  //     JSON.stringify(accIds)
  //   );
  // };

  const setCurrentAccountIds = (accIds) => {
    updateCurrentAccountIds(accIds);
    localStorage.setItem('accountIds', JSON.stringify(accIds));
  };

  // check current manager account id

  const managerAccountIds = managerAccounts.map((obj) => obj._id);
  if (
    currentManagerAccountId &&
    managerAccountIds.length > 0 &&
    !managerAccountIds.includes(currentManagerAccountId)
  ) {
    window.location = '/choose-manager-account';
  }

  // ############## get managerAccounts ###################

  const [loadManagerAccounts, { called, loading, data: managerAccountsData }] =
    useLazyQuery(managerAccountsQuery, {
      variables: {}
    });

  const [
    loadAccounts,
    {
      called: managerAccountAccountListCalled,
      loading: accountListLoading,
      data: managerAccountAccountListData,
      refetch: refetchAccounts
    }
  ] = useLazyQuery(accountQuery, {
    variables: {
      accountsInput: {
        managerAccountId: currentManagerAccountId
      }
    }
  });

  useEffect(() => {
    const run1 = async () => {
      if (isAuthenticated && user?._id) {
        const res = await loadManagerAccounts();

        // if (res?.data?.managerAccounts.length > 0)
        //   setManagerAccounts(res?.data?.managerAccounts);
      }
    };
    run1();
  }, [isAuthenticated, user?._id]);

  useEffect(() => {
    // console.log('managerAccountsData: ', managerAccountsData);
    if (managerAccountsData?.managerAccounts) {
      // console.log(
      //   'managerAccountsData?.managerAccounts: ',
      //   managerAccountsData?.managerAccounts
      // );
      let sortedManagerAccounts =
        [...managerAccountsData?.managerAccounts] || [];

      // console.log('sortedManagerAccounts: ', sortedManagerAccounts);

      sortedManagerAccounts.sort(function (a, b) {
        var nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
        var nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      setManagerAccounts(sortedManagerAccounts);
    }
  }, [managerAccountsData?.managerAccounts]);

  // get accounts

  useEffect(() => {
    if (currentManagerAccountId) {
      loadAccounts();
    }
  }, [currentManagerAccountId]);

  useEffect(() => {
    // console.log(
    //   'managerAccountAccountListData: ',
    //   managerAccountAccountListData
    // );
    if (managerAccountAccountListData?.accounts?.length > 0) {
      setAccounts(managerAccountAccountListData?.accounts);
    }
  }, [managerAccountAccountListData?.accounts]);

  // useEffect(() => {
  //   if (currentManagerAccount?._id) {
  //     const run2 = async () => {
  //       const res = await loadAccounts();

  //       if (managerAccountAccountListData?.accounts?.length > 0) {
  //         setAccounts(res.data.accounts);
  //       } else {
  //         setAccounts([]);
  //       }
  //     };
  //     run2();
  //   }
  // }, [currentManagerAccount?._id]);

  // set current manager account

  useEffect(() => {
    if (currentManagerAccountId && managerAccounts.length > 0) {
      const managerAccount = managerAccounts.filter(
        (obj) => obj._id === currentManagerAccountId
      );

      if (managerAccount.length === 1) {
        setCurrentManagerAccount(managerAccount[0]);

        // statuses
        const statuses = [...managerAccount[0].nexusDocumentStatuses];
        statuses.sort(function (a, b) {
          var numberA = a.number; // ignore upper and lowercase
          var numberB = b.number; // ignore upper and lowercase
          if (numberA < numberB) {
            return -1;
          }
          if (numberA > numberB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        setNexusDocumentStatuses(statuses);

        // invoice statuses

        const invoiceStatuses = [
          ...managerAccount[0].nexusDocumentInvoiceStatuses
        ];
        invoiceStatuses.sort(function (a, b) {
          var numberA = a.number; // ignore upper and lowercase
          var numberB = b.number; // ignore upper and lowercase
          if (numberA < numberB) {
            return -1;
          }
          if (numberA > numberB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        setNexusDocumentInvoiceStatuses(invoiceStatuses);
      }
    }
  }, [managerAccounts, currentManagerAccountId]);

  useEffect(() => {
    const currentAccs = accounts.filter((acc) =>
      currentAccountIds.includes(acc._id)
    );
    setCurrentAccounts(currentAccs);
  }, [currentAccountIds, accounts]);

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     setManagerAccounts([]);
  //   }
  // }, [isAuthenticated]);

  // useEffect(() => {
  //   if (
  //     isAuthenticated &&
  //     currentAccountIds?.length > 0 &&
  //     currentManagerAccount?._id
  //   ) {
  //     setSubscriptionSkip(false);

  //   }
  // }, [
  //   isAuthenticated,
  //   currentAccountIds,
  //   currentManagerAccount,
  //   currentAccounts
  // ]);
  return (
    <AccountContext.Provider
      value={{
        managerAccounts,
        setManagerAccounts,
        currentManagerAccount,
        setCurrentManagerAccount,
        accounts,
        refetchAccounts,
        currentAccountIds,
        setCurrentAccountIds,
        currentManagerAccountId,
        setCurrentManagerAccountId,
        currentAccounts,
        setCurrentAccounts,
        nexusDocumentStatuses,
        nexusDocumentInvoiceStatuses,
        subscriptionSkip,
        setSubscriptionSkip
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
