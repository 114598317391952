import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useMutation } from '@apollo/client';

import { updateDocumentMutation } from './../../../../graphql/queries';

import Tooltip from '@mui/material/Tooltip';
import { AccountContext } from '../../../../context/AccountContext';

import { MutationContext } from '../../../../context/MutationContext';

export default function BasicMenu({ item, showText }) {
  const { nexusDocumentStatuses, currentManagerAccountId } =
    useContext(AccountContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const [updateDocument] = useMutation(updateDocumentMutation);
  const { updateDocument } = useContext(MutationContext);

  const handleClose = (e, value) => {
    // if (['backdropClick', 'escapeKeyDown'].includes(value)) {
    //   setAnchorEl(null);
    //   return;
    // }

    if (!nexusDocumentStatuses.map((status) => status.string).includes(value)) {
      setAnchorEl(null);
      return;
    }

    if (value === item.status) {
      console.log('same status');
      setAnchorEl(null);
      return;
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          status: value
        }
      ],
      updateKeys: ['status']
    };

    updateDocument({ variables: { input: { ...documentInput } } });

    setAnchorEl(null);
  };

  let fiberManualRecordIconColor = '#bcbcbc';
  let statusName = item.status;

  switch (item.status) {
    // case 'ACTIVE':
    //   // case 'DONE':
    //   fiberManualRecordIconColor = 'green';
    //   break;
    // case 'DONE':
    // case 'COMPLETED':
    //   fiberManualRecordIconColor = 'rgba(0,128,0,0.4)';
    //   break;
    // case 'REMOVED':
    //   fiberManualRecordIconColor = 'red';
    //   break;

    // case 'DRAFT':
    //   fiberManualRecordIconColor = '#bcbcbc';
    //   break;
    // case 'PAUSED':
    //   fiberManualRecordIconColor = 'rgb(62, 142, 208)';
    //   break;
    default:
      // fiberManualRecordIconColor = 'orange';
      const status = nexusDocumentStatuses.filter(
        (status) => status.string === item.status
      )[0];
      if (status) {
        fiberManualRecordIconColor = status.color;
        statusName = status.name;
      }

      break;
  }
  const menuItemStyle = { fontSize: '12px' };

  return (
    <div>
      {showText ? (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            width: '100%',
            textAlign: 'right',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: fiberManualRecordIconColor,
            color: fiberManualRecordIconColor,
            padding: '3px 6px',
            borderRadius: '15px',
            minWidth: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          <FiberManualRecordIcon
            style={{
              fontSize: '10px',
              verticalAlign: 'middle',
              color: fiberManualRecordIconColor
            }}
          />
          <span
            style={{ fontSize: '10px', marginLeft: '3px', marginRight: '3px' }}
          >
            {statusName}
          </span>
        </Button>
      ) : (
        // <Tooltip
        //   title={
        //     <span style={{ fontSize: '13px' }}>
        //       {item['status']
        //         ? item['status'].charAt(0).toUpperCase() +
        //           item['status'].slice(1).toLowerCase()
        //         : ' '}
        //     </span>
        //   }
        // >
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            width: '18px',
            minWidth: '18px',
            textAlign: 'right',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: fiberManualRecordIconColor,
            color: fiberManualRecordIconColor,
            padding: '3px 6px',
            borderRadius: '15px',
            // minWidth: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          <FiberManualRecordIcon
            style={{
              fontSize: '10px',
              verticalAlign: 'middle',
              color: fiberManualRecordIconColor
            }}
          />
          {/* <span
            style={{ fontSize: '10px', marginLeft: '3px', marginRight: '3px' }}
          >
            {item['status']
              ? item['status'].charAt(0).toUpperCase() +
                item['status'].slice(1).toLowerCase()
              : ' '}
          </span> */}
        </Button>
        // </Tooltip>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {nexusDocumentStatuses.map((status) => (
          <MenuItem
            style={menuItemStyle}
            onClick={(e) => handleClose(e, status.string)}
          >
            {status.name}
          </MenuItem>
        ))}
        {/* <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'DRAFT')}
        >
          Draft
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'PLANNED')}
        >
          Planned
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'READY')}
        >
          Ready
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'ACTIVE')}
        >
          Active
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'PAUSED')}
        >
          Paused
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'CANCELED')}
        >
          Canceled
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'COMPLETED')}
        >
          Completed
        </MenuItem>
        <MenuItem
          style={menuItemStyle}
          onClick={(e) => handleClose(e, 'REMOVED')}
        >
          Removed
        </MenuItem> */}
      </Menu>
    </div>
  );
}
