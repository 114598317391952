import React, { useState, useEffect } from 'react';

import SelectConjunction from './SelectConjunction';

import ChipsInput from './ChipsInput';

import { Button } from '@mui/material';

import RemoveButton from './RemoveButton';
import SaveButton from './SaveButton';

function TimeboxAutoTagKeyword({
  selectedAccountId,
  timeboxAutoTaggingKeyword,
  refetch,
  stylesStoredInReduxManagerAccountSettings
}) {
  function handleSelecetedTags(items) {
    console.log(items);
  }

  const [input, setInput] = useState({});

  useEffect(() => {
    setInput({ ...timeboxAutoTaggingKeyword });
  }, [timeboxAutoTaggingKeyword]);
  console.log('timeboxAutoTaggingKeyword: ', timeboxAutoTaggingKeyword);
  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
        // backgroundColor: 'red',
        width: '700px',
        justifyContent: 'space-between',
        alignItems: 'center',
        // border: '1px solid rgba(255, 255, 255, 0.1)',
        border:
          stylesStoredInReduxManagerAccountSettings.childCardChildBorderColor,
        borderRadius: '5px',
        padding: '15px'
      }}
    >
      <SelectConjunction input={input} setInput={setInput} />
      <ChipsInput input={input} setInput={setInput} />
      <RemoveButton input={input} refetch={refetch} />
      <SaveButton input={input} refetch={refetch} />
    </div>
  );
}

export default TimeboxAutoTagKeyword;
