import React from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     display: 'block',
//     marginTop: theme.spacing(2)
//   },
//   formControl: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//     minWidth: 100,
//     maxWidth: 100
//   }
// }));

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     // margin: theme.spacing(1),
//     minWidth: 150
//   },
//   selectEmpty: {
//     // marginTop: theme.spacing(2)
//   }
// }));
export default function ControlledOpenSelect({ state, setState }) {
  // const classes = useStyles();
  //   const [status, setStatus] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setState(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {/* <Button className={classes.button} onClick={handleOpen}>
        Open the select
      </Button> */}
      <FormControl
      // className={classes.formControl}
      >
        <InputLabel id="demo-controlled-open-select-label"></InputLabel>
        <Select
          variant="standard"
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={state}
          onChange={handleChange}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          <MenuItem value={'CONTAINS'}>Contains</MenuItem>
          <MenuItem value={'DOESNOTCONTAIN'}>Does not contain</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
