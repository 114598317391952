import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100
//   }
// ];

function SimpleBarChart({ data }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          // label={{ fill: 'red' }}
          // tickLine={{ stroke: '#EAF0F4' }}
          // stroke="rgba(255,255,255,0.7)"
          // stroke="rgba(167,38,131,0.7)"
          stroke="rgba(33,40,90,1)"
        />
        <YAxis
          // label={{ fill: 'red' }}
          // tickLine={{ stroke: '#EAF0F4' }}
          // stroke="rgba(255,255,255,0.7)"
          stroke="rgba(167,38,131,0.7)"
          // stroke="rgba(33,40,90,1)"
        />
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        <Bar
          dataKey="value"
          // fill="#8884d8"
          fill="rgba(255,255,255,0.7"
          // fill="rgba(167,38,131,0.7)"
          stroke="rgba(167,38,131,0.3)"
          label
        />
        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default SimpleBarChart;
