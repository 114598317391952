import React, { useContext, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
  accountUsersQuery,
  updateDocumentMutation
} from '../../../../../../graphql/queries';

import { AccountContext } from '../../../../../../context/AccountContext';

import { useSnackbar } from 'notistack';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ accountId, members, setMembers }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [accountUsers, setAccountUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = React.useState([]);

  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const variables = {
    accountsUsersInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccount._id,
      accountId
    }
  };

  const [loadMembers, { called, loading, data }] = useLazyQuery(
    accountUsersQuery,
    { variables, fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    setMembers([]);
    setValue([]);
  }, [accountId]);

  // useEffect(() => {
  //   const filteredMembers =
  //     accountUsers?.filter((obj) => {
  //       for (let member of members || []) {
  //         if (member._id === obj._id) {
  //           return true;
  //         }
  //       }
  //     }) || [];

  //   // filteredMembers.sort(function (a, b) {
  //   //   var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  //   //   var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  //   //   if (nameA < nameB) {
  //   //     return -1;
  //   //   }
  //   //   if (nameA > nameB) {
  //   //     return 1;
  //   //   }

  //   //   // names must be equal
  //   //   return 0;
  //   // });

  //   setValue(filteredMembers || []);
  // }, [members, accountUsers]);

  useEffect(() => {
    const memberIds = value.map((obj) => obj._id);
    setMembers(memberIds);
  }, [value]);

  useEffect(() => {
    if (!accountId) {
      return;
    }
    if (accountId && currentManagerAccount) {
      loadMembers();
    }
  }, [accountId]);

  useEffect(() => {
    const sortedAccountUsers = [...(data?.accountUsers || [])];

    sortedAccountUsers.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setAccountUsers(sortedAccountUsers || []);
  }, [data?.accountUsers]);

  // useEffect(() => {
  //   const memberIds = value.map((obj) => obj._id);
  //   setMembers(memberIds);
  // }, [value]);

  // const onCancelHandler = () => {
  //   const filteredMembers =
  //     accountUsers?.filter((obj) => {
  //       for (let member of members || []) {
  //         if (member._id === obj._id) {
  //           return true;
  //         }
  //       }
  //     }) || [];

  //   setValue(filteredMembers);
  //   setEdit(false);
  // };

  // const onSaveHandler = async () => {
  //   const memberIds = value.map((obj) => obj._id);
  //   setMembers(memberIds);
  //   setEdit(false);

  //   // const documentInput = {
  //   //   managerAccountId: currentManagerAccount._id,
  //   //   documents: [
  //   //     {
  //   //       accountId: item.accountId,
  //   //       documentId: item._id,
  //   //       documentType: item.documentType,
  //   //       members: memberIds
  //   //     }
  //   //   ],
  //   //   updateKeys: ['members']
  //   // };

  //   // try {
  //   //   const res = await updateDocument({
  //   //     variables: { input: { ...documentInput } }
  //   //   });

  //   //   const response = res?.data?.updateDocument?.response;
  //   //   // const success = true;

  //   //   if (response === 'Success') {
  //   //     enqueueSnackbar('Saved', {
  //   //       variant: 'success'
  //   //     });
  //   //     setEdit(false);
  //   //   } else {
  //   //     enqueueSnackbar('Error', {
  //   //       variant: 'error'
  //   //     });
  //   //   }
  //   // } catch (error) {
  //   //   enqueueSnackbar('Error', {
  //   //     variant: 'error'
  //   //   });
  //   // }
  // };

  return (
    <div style={{ margin: '0px 0px 0px 0px' }}>
      <div>
        <Autocomplete
          size="small"
          open={open}
          ListboxProps={{ onMouseLeave: () => setOpen(false) }}
          value={value}
          onChange={(event, newValue) => {
            setEdit(true);
            setValue(newValue);
          }}
          multiple
          id="checkboxes-tags-demo"
          options={accountUsers}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              onClick={() => setOpen(!open)}
              {...params}
              inputProps={{ ...params.inputProps, style: { fontSize: 12 } }} // font size of input text
              InputLabelProps={{
                ...params.InputLabelProps,
                style: { fontSize: 12 }
              }} // font size of input label
              label="Members"
              placeholder="+ Add members"
            />
          )}
        />
      </div>

      {/* {edit && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            flexDirection: 'row',
            fontSize: '14px'
            // height: '30px'
          }}
        >
          <div
            className="hover-grey"
            style={{ padding: '5px 10px', cursor: 'pointer' }}
            onClick={onCancelHandler}
          >
            Cancel
          </div>
          <div
            className="hover-grey"
            style={{ padding: '5px 10px', cursor: 'pointer' }}
            onClick={onSaveHandler}
          >
            Save
          </div>
        </div>
      )} */}
    </div>
  );
}
