import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

function DateWidget({
  widget,
  displayMode,
  updateWidget,
  widgetIndex,
  editMode,
  dropOnWidget,
  dragOver,
  dragStart,
  dragEnd,
  isDraggingWidget,
  setIsDraggingWidget
  // widgetType
}) {
  const [isWidgetDescriptionMenuOpen, setIsWidgetDescriptionMenuOpen] =
    useState(false);
  const [isDraggingThisWidget, setIsDraggingThisWidget] = useState(false);
  const [edit, toggleEdit] = useState(false);

  const [textInput, setTextInput] = useState(
    // widget.value ||
    ''
  );

  const toolTipTypeDict = {
    YEARYYYY: 'Year YYYY',
    YEARYY: 'Year YY',
    MONTHMM: 'Month MM',
    DAYDD: 'Day DD'
  };

  // ######################### test #########################

  useEffect(() => {
    // if (widget.value) {
    //   return;
    // }
    // console.log('widget.type: ', widget.type);

    let defaultValue = '';
    switch (true) {
      case widget.type === 'YEARYYYY':
        defaultValue = String(new Date().getFullYear());
        break;

      case widget.type === 'YEARYY':
        defaultValue = String(new Date().getFullYear()).slice(2);
        break;

      case widget.type === 'MONTHMM':
        defaultValue = String(
          (new Date().getMonth() + 1).length !== 2
            ? '0' + (new Date().getMonth() + 1)
            : new Date().getMonth() + 1
        );
        break;
      case widget.type === 'DAYDD':
        defaultValue = String(
          new Date().getDate().toString().length !== 2
            ? '0' + new Date().getDate()
            : new Date().getDate()
        );
        break;
    }
    console.log('defaultValue: ', defaultValue);
    updateWidget({ ...widget, value: defaultValue }, widgetIndex);
    setTextInput(defaultValue);
  }, [widget.value]);

  // ######################### test #########################

  const onChangeHandler = (e) => {
    setTextInput(e.target.value);
  };

  const onClickHandler = () => {};

  const onSaveHandler = () => {
    updateWidget({ ...widget, value: textInput }, widgetIndex);
    toggleEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('do validate');
      updateWidget({ ...widget, value: textInput }, widgetIndex);
      toggleEdit(false);
    }
  };

  const numUpper = (textInput?.match(/[A-Z]/g) || []).length;
  const numLarge = (textInput?.match(/[MmWw]/g) || []).length;
  const theRest = textInput?.length - numUpper;

  let cursor = 'pointer';

  switch (true) {
    case isDraggingWidget:
      cursor = 'grabbing';
      break;
    // case !isDraggingWidget:
    //   cursor = 'grab';
    //   break;
    case !editMode:
      cursor = 'text';
      break;

    case editMode:
      cursor = 'grab';
      break;
    default:
      cursor = 'not-allowed';
  }

  return (
    <div
      key={widgetIndex}
      onDrop={(e) => dropOnWidget(e, widget, widgetIndex)}
      onContextMenu={(e) => {
        e.preventDefault();
        console.log('on right click');
        setIsWidgetDescriptionMenuOpen(true);
        return false;
      }}
      style={{ paddingLeft: '3px' }}
    >
      <div
        style={{
          padding: '4px',

          background: 'white',
          // border: '1px solid lightgrey',
          display: 'inline-block',
          borderRadius: '5px',
          margin: '0px 0.5px',
          transform: 'translate(0, 0)',
          boxShadow:
            !isDraggingThisWidget && 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
        }}
        onDragOver={(e) => dragOver(e, widget, widgetIndex)}
        draggable={editMode}
        onDragStart={(e) => {
          dragStart(e, widget, 'item_widgets', widgetIndex);
          setIsDraggingThisWidget(true);
        }}
        onDragEnd={(e) => {
          dragEnd(e, widget, widgetIndex);
          setIsDraggingThisWidget(false);
        }}
      >
        <div
          onClick={() => {
            if (!editMode) {
              toggleEdit(true);
            }
          }}
          onBlur={(e) => onSaveHandler(e)}
        >
          {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
          {edit ? (
            <input
              autoFocus
              style={{
                fontFamily: 'Roboto, Raleway, sans-serif',
                lineHeight: '1.5',
                fontSize: '12px',
                letterSpacing: '0.2px',
                color: 'black',
                width:
                  numUpper * 0.35 +
                  numLarge * 1 +
                  textInput.length * 0.925 +
                  'ch',
                minWidth: '1ch',
                outline: 'none',
                border: 'none'
              }}
              value={textInput}
              onChange={onChangeHandler}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <Tooltip
              title={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    maxWidth: '200px'
                    // padding: '2px'
                  }}
                >
                  <span style={{ width: '100%', textAlign: 'center' }}>
                    {'*' + toolTipTypeDict[widget.type] + '*'}
                  </span>

                  <span
                    style={{
                      maxWidth: '190px',
                      whiteSpace: 'pre-line'
                      // paddingRight: '10px'
                      // padding: '5px'
                    }}
                  >
                    {widget.description}
                  </span>
                </div>
              }
            >
              <span
                style={{
                  color: 'black',
                  cursor
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
                {widget.value ? (
                  widget.value
                ) : (
                  <span style={{ visibility: 'hidden' }}>a</span>
                )}
              </span>
            </Tooltip>
          )}
          {isWidgetDescriptionMenuOpen && (
            <WidgetDescriptionMenu
              isWidgetDescriptionMenuOpen={isWidgetDescriptionMenuOpen}
              setIsWidgetDescriptionMenuOpen={setIsWidgetDescriptionMenuOpen}
              // inputRef={inputRef}
              // textInput={textInput}
              // setTextInput={setTextInput}
              widgetIndex={widgetIndex}
              // options={options}
              // setOptions={setOptions}
              editMode={editMode}
              widget={widget}
              updateWidget={updateWidget}
              toggleEdit={toggleEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DateWidget;
