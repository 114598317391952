const columnDictionary = {
  //   Media: 'media',
  //   Placement: 'placement',
  //   'Banner Type': 'bannerType',
  //   'Banner Size': 'bannerSize',
  //   'Cost model': 'plannedCostModel',
  //   Price: 'plannedPrice',
  //   Units: 'plannedUnits',
  Gross: 'plannedGross',
  //   Discount: 'plannedDiscount',
  Net: 'plannedNet',
  'Net net': 'plannedNetNet',
  'Cost to Client': 'plannedCtc'
};

const parseExcel = (data) => {
  //   console.log('parseExcelData: ', data);
  //   let rows = [...data];

  const dataAsString = JSON.stringify(data);
  let rows = JSON.parse(dataAsString);

  for (let row of rows) {
    row.shift();
  }

  let startDate = null;
  let endDate = null;
  let campaignName = null;
  let headers = [];
  let headerRow = null;

  for (let y = 0; y < rows.length; y++) {
    switch (true) {
      case rows[y][0] === 'Media':
        headers = rows[y];
        headerRow = y;
        break;

      default:
        for (let i = 0; i < rows[y].length; i++) {
          switch (true) {
            case rows[y][i] === 'Campaign':
              campaignName = rows[y][i + 1];
              break;
            case rows[y][i] === 'Period':
              const dates = rows[y][i + 1].split(' - ');
              startDate = dates[0];
              endDate = dates[1];
              break;
          }
        }
    }
  }

  const copyOfRows = [...rows];
  copyOfRows.splice(0, headerRow + 4);

  //   console.log(" copyOfRows: ",  copyOfRows);

  const nexusDocuments = [];

  for (let y = 0; y < copyOfRows.length; y++) {
    if (!copyOfRows[y][0] || copyOfRows[y][0].includes('Total')) {
      continue;
    }
    if (copyOfRows[y].filter((cell) => cell !== null).length === 1) {
      console.log(' copyOfRows[y]: ', copyOfRows[y]);
      nexusDocuments.push({
        documentType: 'folder',
        name: copyOfRows[y][0],
        startDate,
        endDate,
        nexusDocuments: []
      });
      continue;
    } else {
      const document = {
        documentType: 'folder',
        name: copyOfRows[y][0],
        startDate,
        endDate,
        nexusDocuments: []
      };

      for (let x = 0; x < headers.length; x++) {
        if (columnDictionary[headers[x]] && copyOfRows[y][x]) {
          document[columnDictionary[headers[x]]] = copyOfRows[y][x];
        }
      }
      if (document.plannedNet) {
        document.budget = document.plannedNet;
      }
      nexusDocuments[nexusDocuments.length - 1].nexusDocuments.push(document);
    }

    if (copyOfRows[y][0] === 'Total') {
      break;
    }
  }

  console.log('nexusDocuments: ', nexusDocuments);
  const topNexusDocument = {
    documentType: 'folder',
    name: campaignName,
    startDate,
    endDate,
    nexusDocuments: nexusDocuments
  };
  console.log('topNexusDocument: ', topNexusDocument);

  return topNexusDocument;
  //   return { campaignName, startDate, endDate, headers };
};

// exports.columnDictionary = columnDictionary;
// exports.parseExcel = parseExcel;

export default parseExcel;
