import React, { useContext, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Button } from '@mui/material';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
  accountUsersQuery,
  updateDocumentMutation
} from './../../../graphql/queries';

// import { MutationContext } from '../../../../context/MutationContext';

import { AccountContext } from './../../../context/AccountContext';

import { useSnackbar } from 'notistack';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({
  accountId,
  disabled,
  document,
  setDocument
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [accountUsers, setAccountUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = React.useState([]);
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // const [updateDocument] = useMutation(updateDocumentMutation);

  const variables = {
    accountsUsersInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccount._id,
      accountId: accountId
    }
  };

  const [loadMembers, { called, loading, data }] = useLazyQuery(
    accountUsersQuery,
    { variables, fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    // const memebersWithName = item.members.map((obj) => ({
    //   ...obj,
    //   name: obj.firstName + ' ' + obj.lastName
    // }));

    const filteredMembers = [];
    //   accountUsers?.filter((obj) => {
    //     for (let member of item?.members || []) {
    //       if (member._id === obj._id) {
    //         return true;
    //       }
    //     }
    //   }) || [];

    // filteredMembers.sort(function (a, b) {
    //   var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    //   var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }

    //   // names must be equal
    //   return 0;
    // });

    setValue(filteredMembers || []);
  }, [accountUsers]);

  useEffect(() => {
    loadMembers();
  }, [accountId]);

  useEffect(() => {
    const sortedAccountUsers = [...(data?.accountUsers || [])];

    sortedAccountUsers.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setAccountUsers(sortedAccountUsers || []);
  }, [data?.accountUsers]);

  const onCancelHandler = () => {
    // const filteredMembers =
    //   accountUsers?.filter((obj) => {
    //     for (let member of item?.members || []) {
    //       if (member._id === obj._id) {
    //         return true;
    //       }
    //     }
    //   }) || [];

    // setValue(filteredMembers);
    setValue([]);
    setEdit(false);
  };

  const onSaveHandler = async () => {
    const memberIds = value.map((obj) => obj._id);
  };

  return (
    <div
      style={{
        width: '400px',
        margin: '0px 0px 0px 0px',
        // minHeight: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <Autocomplete
          disabled={disabled}
          onBlur={() => setOpen(false)}
          open={open}
          ListboxProps={{
            onMouseLeave: () => setOpen(false)
          }}
          value={value}
          onChange={(event, newValue) => {
            setEdit(true);
            setValue(newValue);
            setDocument({
              ...document,
              members: newValue?.map((obj) => obj._id) || []
            });
          }}
          multiple
          id="checkboxes-tags-demo"
          options={accountUsers}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              onClick={() => setOpen(!disabled ? !open : false)}
              {...params}
              label="Members"
              placeholder="+ Add members"
            />
          )}
        />
      </div>

      {/* {edit && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            flexDirection: 'row',
            fontSize: '14px',
            marginTop: '5px'
            // height: '30px'
          }}
        >
          <Button
            size="small"
            variant="outlined"
            className="hover-grey"
            style={{ margin: '5px', cursor: 'pointer' }}
            onClick={onCancelHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            className="hover-grey"
            style={{ margin: '5px', cursor: 'pointer' }}
            onClick={onSaveHandler}
          >
            Save
          </Button>
        </div>
      )} */}
    </div>
  );
}
