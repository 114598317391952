import React, { useState, useContext, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';

import { useSnackbar } from 'notistack';

import Spinner from './../../layout/SpinnerSmall';

// import DisabledDatePickerStart from './DisabledDatePickerStart';
// import DisabledDatePickerStop from './DisabledDatePickerStop';

import BasicDatePicker from './../utils/BasicDatePicker';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import Checkbox from '@mui/material/Checkbox';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '@mui/material/Button';

import SelectStatus from './SelectStatus';
import { useQuery, useMutation } from '@apollo/client';
import {
  DocumentsToBeCopiedQuery,
  copyDocumentMutation
} from '../../../graphql/queries';

import { AccountContext } from '../../../context/AccountContext';

import SelectTemplate from './SelectTemplate';

import { allEvalFieldsExceptTotal } from '../fields';

import { columnDictionary } from '../column-menu/columns';

import { DataContext } from './../../../context/DataContext';

import { InterfaceContext } from './../../../context/InterfaceContext';

import IconComponent from './../icon-component/IconComponent';

import { FilterContext } from '../../../context/FilterContext';

import { AuthContext } from '../../../context/AuthContext';

import {
  MutationContext,
  copyOrDuplicateAction,
  setCopyOrDuplicateAction
} from '../../../context/MutationContext';

import DateRange from './DateRange';

const differenceInDaysRound = (start, stop) => {
  console.log('debug924>start: ', start);
  console.log('debug924>stop: ', stop);

  // var date1 = new Date(start);
  // var date2 = new Date(stop);
  // var Difference_In_Time = date2.getTime() - date1.getTime();
  // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(start);
  const secondDate = new Date(stop);

  const diffDays = Math.round((secondDate - firstDate) / oneDay);

  return diffDays;
};

function formatDateYYYYMMDD(yourDate1) {
  let yourDate = new Date(yourDate1);
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split('T')[0];
}

const addDays = function (_date, days) {
  var date = new Date(_date);
  date.setDate(date.getDate() + days);
  return formatDateYYYYMMDD(date);
};

function isLastDay(dt_) {
  const dt = new Date(dt_);
  var test = new Date(dt.getTime()),
    month = test.getMonth();

  test.setDate(test.getDate() + 1);
  return test.getMonth() !== month;
}

function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

function CopyDialogContent({
  open,
  setOpen,
  // checkedBoxes,
  // copiedDocumentIds,
  // setCopiedDocumentIds,
  copiedDocumentsAccountId,
  setCopiedDocumentsAccountId
}) {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount, currentManagerAccountId } =
    useContext(AccountContext);

  const { copyDocument, copyOrDuplicateAction, setCopyOrDuplicateAction } =
    useContext(MutationContext);

  const { listOfData } = useContext(DataContext);
  const {
    selectedDateStart,
    selectedDateStop,
    copiedDocumentIds,
    setCopiedDocumentIds,
    rightClickMenuToggledItem,
    documentIdsToBeDuplicated,
    setDocumentIdsToBeDuplicated,
    accountIdForDocumentsToBeDuplicated,
    setAccountIdForDocumentsToBeDuplicated
  } = useContext(InterfaceContext);

  const { checkedBoxes } = useContext(FilterContext);

  const { enqueueSnackbar } = useSnackbar();

  const [documentsToBeCopied, setDocumentsToBeCopied] = useState([]);

  // const [copyDocument] = useMutation(copyDocumentMutation);

  const [awaitingResponse, toggleAwaitingResponse] = useState(false);

  const [sudoRawCopyChecked, setSudoRawCopyChecked] = useState(false);

  const [isUsingDateRanges, setIsUsingDateRanges] = useState(false);

  const copiedDocuments = listOfData.filter((row) =>
    copiedDocumentIds.includes(row._id)
  );

  // let allCopiedDocuments = [];
  // console.log('copiedDocuments: ', copiedDocuments);

  // for (let copiedDocument of copiedDocuments) {
  //   const subDocs = listOfData.filter((row) => {
  //     console.log(
  //       '`level_${copiedDocument.level}_container_id`: ',
  //       `level_${copiedDocument.level}_container_id`
  //     );
  //     return (
  //       row[`level_${copiedDocument.level}_container_id`] === copiedDocument._id
  //     );
  //   });
  //   console.log('subDocs: ', subDocs);
  //   allCopiedDocuments = [...allCopiedDocuments, ...subDocs];
  // }

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      accountId:
        copyOrDuplicateAction === 'COPY'
          ? copiedDocumentsAccountId
          : accountIdForDocumentsToBeDuplicated,
      documentIds:
        copyOrDuplicateAction === 'COPY'
          ? copiedDocumentIds
          : documentIdsToBeDuplicated
    }
  };
  console.log('variables: ', variables);

  const { data, loading, error } = useQuery(DocumentsToBeCopiedQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: false
  });
  console.log('error: ', error?.message);
  useEffect(() => {
    if (error?.message) {
      enqueueSnackbar(error?.message, {
        variant: 'default'
        // persist: true
      });
    }
  }, [error]);
  console.log('CopyDialogContent>data: ', data);

  useEffect(() => {
    if (data?.documentsToBeCopied) {
      setDocumentsToBeCopied(data?.documentsToBeCopied);
    }
  }, [data]);

  const onClickRemoveHandler = (e, documentId) => {
    setDocumentsToBeCopied(
      documentsToBeCopied.filter(
        (doc) =>
          doc._id !== documentId && !doc.parentDocumentIds.includes(documentId)
      )
    );
  };

  // ############ calc first and last day of month:

  var todaysDate = new Date();
  var firstDayOfMonth = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth(),
    1
  );
  // firstDayOfMonth.setHours(5);
  const firstDayOfMonthAsString = formatDate(firstDayOfMonth);

  var lastDayOfMonth = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth() + 1,
    0
  );
  // lastDayOfMonth.setHours(5);

  const lastDayOfMonthAsString = formatDate(lastDayOfMonth);

  // const [templateDocument, setTemplateDocument] = useState(null);

  // const [document, setDocument] = useState({
  //   status: 'DRAFT',
  //   startDate: firstDayOfMonthAsString,
  //   endDate: lastDayOfMonthAsString
  // });

  // const [fields, setFields] = useState([]);

  const [dateRanges, setDateRanges] = useState([
    {
      status: 'READY',
      startDate: firstDayOfMonthAsString,
      endDate: lastDayOfMonthAsString
    }
  ]);

  console.log('dateRanges: ', dateRanges);

  const [status, setStatus] = useState('DRAFT');
  const [startDate, setStartDate] = useState(firstDayOfMonthAsString);
  const [endDate, setEndDate] = useState(lastDayOfMonthAsString);

  const handleClose = () => {
    // setFields([]);

    // setStatus('DRAFT');

    setOpen(false);
  };

  const onClickPaste = async () => {
    if (awaitingResponse) {
      return;
    }
    console.log('copiedDocumentIds: ', copiedDocumentIds);
    const documentsToBeCopiedIds = documentsToBeCopied.map((obj) =>
      String(obj._id)
    );
    if (hasDuplicates(documentsToBeCopiedIds)) {
      enqueueSnackbar('Error: Includes duplicates', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    console.log('checkedBoxes: ', checkedBoxes);
    if (checkedBoxes.length !== 1 && copyOrDuplicateAction === 'COPY') {
      enqueueSnackbar('Please select ONE folder in order to paste', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (
      copyOrDuplicateAction === 'COPY' &&
      checkedBoxes[0].accountId !== copiedDocumentsAccountId &&
      ((!['system_admin'].includes(user.role) && !sudoRawCopyChecked) ||
        (['system_admin'].includes(user.role) && !sudoRawCopyChecked))
    ) {
      enqueueSnackbar('You cannot paste documents into a different account', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (
      copyOrDuplicateAction === 'COPY' &&
      copiedDocumentIds.includes(checkedBoxes[0]._id)
    ) {
      enqueueSnackbar('You cannot paste a document into itself', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (
      copyOrDuplicateAction === 'COPY' &&
      !['account', 'folder'].includes(checkedBoxes[0].documentType)
    ) {
      enqueueSnackbar(
        'You can only paste documents into same account or folders from the same account',
        {
          variant: 'default'
          // persist: true
        }
      );
      return;
    }

    if (documentsToBeCopiedIds.length > 100) {
      enqueueSnackbar(
        'You can copy a maximum of 100 documents at the same time.',
        {
          variant: 'default'
          // persist: true
        }
      );
      return;
    }

    const rangesWithInvalidDates = dateRanges.filter(
      (range) => new Date(range.startDate) > new Date(range.endDate)
    );

    if (rangesWithInvalidDates.length > 0) {
      enqueueSnackbar('Start date is not allowed to be later than end date.', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    const copyDocumentInput = {
      managerAccountId: currentManagerAccountId,
      action: copyOrDuplicateAction,
      accountId:
        copyOrDuplicateAction === 'COPY'
          ? copiedDocumentsAccountId
          : accountIdForDocumentsToBeDuplicated,
      checkedDocumentId: checkedBoxes?.[0]?._id,
      documentIds:
        copyOrDuplicateAction === 'COPY'
          ? copiedDocumentIds
          : documentIdsToBeDuplicated,
      level: checkedBoxes?.[0]?.level,
      // startDate,
      // endDate,
      dateRanges,
      documentsToBeCopiedIds,
      status,
      isSudoRawCopy: sudoRawCopyChecked,
      isUsingDateRanges

      // endDate: lastDayOfMonthAsString
    };
    try {
      toggleAwaitingResponse(true);
      const copyDocumentRes = await copyDocument({
        variables: { input: { ...copyDocumentInput } }
      });
      const copyDocumentData = copyDocumentRes?.data;
      console.log('copyDocumentData: ', copyDocumentData);
      // console.log('res?.data?: ', res);
      // console.log('errors: ', errors);

      if (copyDocumentData?.copyDocument?.response === 'Success') {
        enqueueSnackbar('Pasting documents', {
          variant: 'default'
          // persist: true
        });
        toggleAwaitingResponse(false);
        handleClose();
      }
    } catch (error) {
      console.log('error123: ', error);
      toggleAwaitingResponse(false);
      enqueueSnackbar(error.message, {
        // variant: 'error'
        // persist: true
      });
    }
  };

  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '100%',
    margin: '12px 0px'
  };

  const checkboxDivStyle = {
    margin: '0px 20px 0px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  };

  const onClickAddDateRangeHandler = () => {
    if (dateRanges.length > 11) {
      enqueueSnackbar(
        'You can only add a maximum of 12 date ranges at the time',
        {
          variant: 'default'
          // persist: true
        }
      );
      return;
    }

    let startDate = firstDayOfMonthAsString;
    let endDate = lastDayOfMonthAsString;

    if (dateRanges.length) {
      const dateRange = dateRanges[dateRanges.length - 1];
      const differenceInDays =
        differenceInDaysRound(dateRange.startDate, dateRange.endDate) + 1;
      // console.log('differenceInDays :', differenceInDays);
      switch (true) {
        // case differenceInDays === 7:
        //   console.log('differenceInDays: ', differenceInDays);
        //   startDate = addDays(dateRange.startDate, 7);
        //   endDate = addDays(dateRange.endDate, 7);
        //   break;
        case differenceInDays > 27 &&
          new Date(dateRange.startDate).getDate() === 1 &&
          isLastDay(dateRange.endDate):
          startDate = new Date(dateRange.startDate);
          // console.log('debug977>row457>startDate: ', startDate);
          startDate.setMonth(startDate.getMonth() + 1);
          // console.log('debug977>row459>startDate: ', startDate);
          startDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            1
          );

          endDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            0
          );
          // console.log('debug977>startDate: ', startDate);

          // console.log('debug977>endDate: ', endDate);

          startDate = formatDateYYYYMMDD(startDate);
          endDate = formatDateYYYYMMDD(endDate);

          break;

        default:
          // console.log('differenceInDays: ', differenceInDays);
          startDate = addDays(dateRange.startDate, differenceInDays);
          endDate = addDays(dateRange.endDate, differenceInDays);
      }
    }

    setDateRanges([
      ...dateRanges,
      {
        status: dateRanges.length
          ? dateRanges[dateRanges.length - 1].status
          : 'READY',
        startDate,
        endDate
      }
    ]);
  };

  // const allFields = [
  //   'plannedGross',
  //   'plannedNet',
  //   'plannedNetNet',
  //   'plannedCtc',

  //   'plannedTrp',
  //   'plannedCpp',
  //   'plannedTechFee',
  //   'plannedAdOpsFee',
  //   'plannedAdServingCost',
  //   'plannedFeePercent',

  //   'budget',
  //   'cost',
  //   'universe',
  //   'trp',
  //   'spots',
  //   'impressions',
  //   'clicks'
  // ];

  const allFields = allEvalFieldsExceptTotal;

  let eligibleFields = [];
  let documentType;

  // useEffect(() => {
  //   setFields(
  //     templateDocument?.fields?.filter((field1) =>
  //       eligibleFields?.includes(field1)
  //     ) || []
  //   );
  //   setDocument({ ...document, ...templateDocument });
  // }, [templateDocument]);

  const allFieldsDict = columnDictionary;

  // const [isSpinnerShowing, setIsSpinnerShowing] = useState(false);

  console.log('loading: ', loading);
  if (loading) {
    return (
      <div
        style={{
          height: '400px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div
        // className={classes.paper}
        style={{
          //   width: '100%',
          // height: '630px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{ alignSelf: 'start', fontSize: '14px', marginBottom: '10px' }}
        >
          Paste rows:
        </div>
        <div
          style={{
            // width: '600px',
            width: '100%',
            height: 'auto',
            backgroundColor: 'rgba(170,170,170,0.1)',
            borderRadius: '5px',
            overflow: 'scroll',
            padding: '20px',
            marginBottom: '25px'
          }}
        >
          {documentsToBeCopied.map((row) => {
            let blankSpace = '';
            for (let i = 0; i < row.indentations; i++) {
              blankSpace = blankSpace + '         ';
            }
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    margin: '5px 0px',
                    whiteSpace: 'pre'
                  }}
                >
                  {/* <span>{blankSpace}</span> */}
                  <span
                    style={{
                      padding: '0',
                      fontSize: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <span
                      style={{
                        // fontSize: '12px',
                        padding: '6px',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        visibility: ['COPY', 'DUPLICATE'].includes(
                          copyOrDuplicateAction
                        ) // !== 'COPY' && 'hidden'
                      }}
                      onClick={(e) => onClickRemoveHandler(e, row._id)}
                    >
                      <RemoveCircleIcon
                        style={{
                          fontSize: '12px',
                          // padding: '2px',
                          cursor: 'pointer'
                        }}
                        className="hover-light-grey"
                      />
                    </span>
                    <span
                      style={{ width: (row.indentations - 1) * 50 + 'px' }}
                    ></span>
                    <IconComponent documentType={row.documentType} />
                    <span style={{ marginLeft: '5px' }}>{row.name}</span>
                  </span>

                  <span style={{ fontSize: '8px', fontStyle: 'italic' }}>
                    {/* {row.path} */}
                  </span>
                </div>
                <hr style={{ border: '1px solid grey' }} className="solid"></hr>
              </>
            );
          })}
        </div>
        {['system_admin'].includes(user.role) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              width: '100%',
              margin: '0px 0px 20px',
              paddingLeft: '20px'
            }}
          >
            <Button
              size="small"
              onClick={() => setSudoRawCopyChecked(!sudoRawCopyChecked)}
            >
              <Checkbox
                checked={sudoRawCopyChecked}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              {/* <span
                style={{
                  // marginLeft: '60px',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
              > */}
              SUDO RAW COPY
              {/* </span> */}
            </Button>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            width: '100%',
            margin: '0px 0px 20px',
            paddingLeft: '20px'
          }}
        >
          <Button
            size="small"
            style={{ paddingRight: '15px' }}
            onClick={() => {
              if (isUsingDateRanges) {
                setDateRanges([
                  {
                    status: 'READY',
                    startDate: firstDayOfMonthAsString,
                    endDate: lastDayOfMonthAsString
                  }
                ]);
              }

              setIsUsingDateRanges(!isUsingDateRanges);
            }}
          >
            <Checkbox
              checked={isUsingDateRanges}
              // onChange={() => {
              //   if (isUsingDateRanges) {
              //     setDateRanges([
              //       {
              //         startDate: firstDayOfMonthAsString,
              //         endDate: lastDayOfMonthAsString
              //       }
              //     ]);
              //   }

              //   setIsUsingDateRanges(!isUsingDateRanges);
              // }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {/* <span
              style={{
                // marginLeft: '60px',
                fontWeight: 'bold',
                fontSize: '14px'
              }}
            > */}
            Add as date ranges
            {/* </span> */}
          </Button>
        </div>
        {isUsingDateRanges && (
          <>
            {dateRanges.map((range, index) => (
              <DateRange
                index={index}
                sudoRawCopyChecked={sudoRawCopyChecked}
                dateRanges={dateRanges}
                setDateRanges={setDateRanges}
              />
            ))}
            <Button
              variant="outlined"
              size="small"
              style={{ width: '80%', marginTop: '35px' }}
              onClick={onClickAddDateRangeHandler}
            >
              Add date range
            </Button>
          </>
        )}

        <div
          style={{
            gridColumn: 4,
            textAlign: 'center',
            marginTop: '50px',
            alignSelf: 'end',
            paddingRight: '25px'
          }}
        >
          {awaitingResponse ? (
            <Spinner />
          ) : (
            <>
              <Button variant="outlined" size="small" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                style={{ minWidth: '91px', marginLeft: '20px' }}
                variant="contained"
                size="small"
                color="primary"
                onClick={onClickPaste}
              >
                {copyOrDuplicateAction === 'COPY' ? 'Paste' : 'Duplicate'}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CopyDialogContent;
