import React from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     // margin: theme.spacing(1),
//     minWidth: 100
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

export default function SimpleSelect({
  label,
  operator,
  setOperator,
  parameterKey,
  action,
  setAction
}) {
  // const classes = useStyles();
  const [age, setAge] = React.useState('contains');

  const googleAnalytics4 = action.googleAnalytics4
    ? action.googleAnalytics4
    : {};

  const googleAnalyticsKeyObject = googleAnalytics4[parameterKey]
    ? googleAnalytics4[parameterKey]
    : {};

  const handleChange = (event) => {
    setAction({
      ...action,
      googleAnalytics4: {
        ...googleAnalytics4,
        [parameterKey]: {
          ...googleAnalyticsKeyObject,
          operator: event.target.value
        }
      }
    });

    // setOperator(event.target.value);
  };

  return (
    <div>
      <FormControl
        style={{
          margin: '10px',
          minWidth: 120,
          width: '93%'
        }}
        // className={classes.formControl}
      >
        {/* <InputLabel id="demo-simple-select-label">{label}</InputLabel> */}
        <Select
          size="small"
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={
            googleAnalyticsKeyObject?.operator
              ? googleAnalyticsKeyObject.operator
              : 'equals'
          }
          onChange={handleChange}
        >
          {/* <MenuItem value={'contains'}>Contains</MenuItem> */}
          <MenuItem value={'equals'}>Equals</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
