// const checkedBackgroundColorLight = 'rgb(245,245,245)';
// const checkedBackgroundColorLight = 'rgb(250,240,255)';
const checkedBackgroundColorLight = 'rgb(233,235,244)';

export const lightStyle = {
  styleName: 'light',
  RoiTable: {},
  background: { backgroundColor: '#f5f5f5' },
  body: { backgroundColor: '#fafafa' },
  card: {
    backgroundColor: '#fafafa',
    backgroundImage: 'linear-gradient(to top, #fafafa, #fafafa)'
  },

  MiniDrawer: {
    AppBar: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      // backgroundColor: 'rgba(255, 255, 255, 0.7)',
      backgroundImage: 'linear-gradient(to bottom, white, #cccccc)',
      // color: 'rgba(0, 0, 0, 0.87)'
      // color: '#212121'
      color: '#21285a'
    },
    Drawer: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundImage: 'linear-gradient(to bottom, white, #cccccc)',
      // color: 'rgba(0, 0, 0, 0.87)'
      // color: '#212121'
      color: '#21285a'
    },
    main: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundImage: 'linear-gradient(to bottom, white, #cccccc)',
      // color: 'rgba(0, 0, 0, 0.87)'
      // color: '#212121'
      color: '#21285a'
    }
    // AppBar: { backgroundColor: 'rgba(57, 62, 70, 0.9)' }
  },
  ManagerAccount: {
    ChooseManagerAccount: {
      card: {
        // backgroundImage: 'linear-gradient(to top, black, #111111)',
        // backgroundColor: 'rgba(255, 255, 255, 0.7)',
        // backgroundColor: '#fafafa',
        backgroundImage: 'linear-gradient(to top, #fafafa, #ffffff)',
        // color: 'black',
        // color: '#212121',
        color: '#21285a',
        border: '1px solid rgba(255,255,255,0.1)'
      }
    }
  },
  Login: {
    card: {
      // backgroundImage: 'linear-gradient(to top, black, #111111)',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      // color: 'black',
      // color: '#212121',
      color: '#21285a',
      border: '1px solid rgba(255,255,255,0.1)',
      loginButton: {
        backgroundImage: 'linear-gradient(to top, black, #111111)',
        border: '1px solid rgba(255,255,255,0.1)'
      }
    }
  },

  RoiTable: {
    datePicker: {
      color: 'white'
    },
    Table: {
      default: {
        border: '1px solid rgba(0, 0, 0, 0.12)'
      },
      headStyle: {
        defaultHead: {
          // backgroundImage: 'linear-gradient(to bottom, white, #f9fafa)',
          backgroundImage:
            'linear-gradient(to bottom, rgba(255,255,255,0.9), rgba(255,255,255,1))',
          background:
            'linear-gradient(to bottom, rgb(250,250,250), rgba(255,255,255,1))', //frozen
          // color: 'rgba(0, 0, 0, 0.87)',
          // color: '#212121',
          color: '#21285a',
          // border: '1px solid rgba(0, 0, 0, 0.12)',
          border: '1px solid rgb(222,222,222)',
          borderColor: 'rgb(222,222,222)'
          // borderColor: 'rgba(0, 0, 0, 0.12)'
        },
        ganttHead: {
          default: {
            // backgroundImage: 'linear-gradient(to bottom, white, #f9fafa)',
            backgroundImage:
              'linear-gradient(to bottom, rgba(255,255,255,0.9), rgba(255,255,255,1))',
            // color: 'black',
            // color: '#212121',
            color: '#21285a',
            // border: '1px solid rgba(0, 0, 0, 0.12)',
            // border: '1px solid rgb(222,222,222)',
            // borderColor: 'rgba(0, 0, 0, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)'
          },
          weekend: {
            // backgroundImage: 'linear-gradient(to bottom, white, #cccccc)',
            backgroundImage: 'linear-gradient(white, rgb(230 230 230))',
            // color: 'black',
            // color: '#212121',
            color: '#21285a',
            // border: '1px solid rgba(0, 0, 0, 0.12)',
            // border: '1px solid rgb(222,222,222)',
            // borderColor: 'rgba(0, 0, 0, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)'
          },
          today: {
            backgroundImage: 'linear-gradient(to top, #999999, #999999)',
            color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            timeLineColor: 'rgba(0,0,0,0.1)'
          }
          // backgroundColor: 'rgb(3, 3, 3)',
          // backgroundImage: 'linear-gradient(to top, #424242, #555555)',
          // color: 'white',
          // border: '1px solid rgba(255, 255, 255, 0.12)',
          // borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      },
      itemStyle: {
        rowStyle: {
          1: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(255,255,255)',
            // color: 'rgba(0,0,0,0.9)',
            color: '#21285a',
            // border: '1px solid rgba(0, 0, 0, 0.12)',
            // borderColor: 'rgba(0, 0, 0, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          2: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(252,252,252)',
            // color: 'rgba(0,0,0,0.9)',
            color: '#21285a',
            // border: '1px solid rgba(0, 0, 0, 0.12)',
            // borderColor: 'rgba(0, 0, 0, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          3: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(249,249,249)',
            // color: 'rgba(0,0,0,0.9)',
            color: '#21285a',
            // border: '1px solid rgba(0, 0, 0, 0.12)',
            // borderColor: 'rgba(0, 0, 0, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },

          4: {
            // backgroundColor: 'rgb(6, 6, 6)',
            // backgroundColor: '#424242',
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(246,246,246)',
            // backgroundColor: '#545454',
            // color: 'rgba(0, 0, 0, 0.87);'
            // color: 'rgba(0,0,0,0.9)',
            color: '#21285a',
            // border: '1px solid rgba(0, 0, 0, 0.12)',
            // borderColor: 'rgba(0, 0, 0, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          5: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(243,243,243)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            // color: 'rgba(0,0,0,0.9)',
            color: '#21285a',
            // color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          6: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(240,240,240)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            // color: 'rgba(0, 0, 0, 0.87)',
            color: '#21285a',
            // color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          7: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(237,237,237)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            // color: 'rgba(0, 0, 0, 0.87)',
            color: '#21285a',
            // color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          8: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(234,234,234)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            // color: 'rgba(0, 0, 0, 0.87)',
            color: '#21285a',
            // color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          9: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(231,231,231)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            // color: 'rgba(0, 0, 0, 0.87)',
            color: '#21285a',
            // color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          10: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(228,228,228)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            // color: 'rgba(0, 0, 0, 0.87)',
            color: '#21285a',
            // color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          11: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(225,225,225)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            // color: 'rgba(0, 0, 0, 0.87)',
            color: '#21285a',
            // color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          },
          12: {
            // backgroundColor: '#fff',
            backgroundColor: 'rgb(222,222,222)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            // color: 'rgba(0, 0, 0, 0.87)',
            color: '#21285a',
            // color: 'white',
            // border: '1px solid rgba(255, 255, 255, 0.12)',
            // borderColor: 'rgba(255, 255, 255, 0.12)'
            border: '1px solid rgb(222,222,222)',
            borderColor: 'rgb(222,222,222)',
            checkedBackgroundColor: checkedBackgroundColorLight
          }
        }
      },
      summaryStyle: {
        defaultSummary: {
          // backgroundImage: 'linear-gradient(to bottom, white, #f9fafa)',
          backgroundImage:
            'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,1))',
          background:
            'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,1))', //frozen
          // color: 'grey',
          color: '#21285a',
          // border: '1px solid rgba(0, 0, 0, 0.12)',
          // borderColor: 'rgba(0, 0, 0, 0.12)'
          border: '1px solid rgb(222,222,222)',
          borderColor: 'rgb(222,222,222)'
        }
      }
    }
  },
  ManagerAccountSettings: {
    cardBackgroundColor: 'rgba(250, 250, 250,0.9)',
    cardTopBackgroundColor: 'rgba(247, 247, 247, 1)',
    childCardBackgroundColor: 'rgba(245, 245, 245, 1)',
    childCardChildBorderColor: '1px solid rgba(255, 255, 255, 1)',
    color: 'black',
    selectedListButton: 'rgba(245, 245, 245, 1)'
  }
};

const checkedBackgroundColor = 'rgb(75,75,83)';

export const darkStyle = {
  styleName: 'dark',
  RoiTable: {},
  background: { backgroundColor: '#303030' },
  body: { backgroundColor: 'rgb(5,5,5)' },
  card: {
    backgroundColor: 'rgb(5,5,5)',
    backgroundImage: 'linear-gradient(to top, #424242, #555555)'
  },

  MiniDrawer: {
    AppBar: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundColor: '#424242',
      color: 'white'
    },
    Drawer: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundColor: '#424242',
      color: 'white'
    },
    main: {
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      color: 'white'
    }
    // AppBar: { backgroundColor: 'rgba(57, 62, 70, 0.9)' }
  },
  ManagerAccount: {
    ChooseManagerAccount: {
      card: {
        // backgroundImage: 'linear-gradient(to top, black, #111111)',
        // backgroundImage: 'linear-gradient(to top, #424242, #555555)',
        backgroundImage:
          'linear-gradient(to top, rgba(55,55,66,1), rgba(75,75,86,1)',
        color: '#FFFFFF',
        border: '1px solid rgba(255,255,255,0.1)'
      }
    }
  },
  Login: {
    card: {
      // backgroundImage: 'linear-gradient(to top, black, #111111)',
      backgroundImage: 'linear-gradient(to top, #424242, #555555)',
      color: '#FFFFFF',
      border: '1px solid rgba(255,255,255,0.1)',
      loginButton: {
        backgroundImage: 'linear-gradient(to top, black, #111111)',
        border: '1px solid rgba(255,255,255,0.1)'
      }
    }
  },

  RoiTable: {
    datePicker: {
      color: 'white'
    },
    Table: {
      default: {
        border: '1px solid rgba(255, 255, 255, 0.12)'
      },
      headStyle: {
        defaultHead: {
          // backgroundImage: 'linear-gradient(to top, black, #111111)',
          backgroundImage:
            'linear-gradient(to top, rgba(55,55,66,1), rgba(75,75,86,1)',
          background:
            'linear-gradient(to top, rgba(55,55,66,1), rgba(75,75,86,1)',
          // backgroundImage: 'linear-gradient(to bottom, black, #111111)',
          // color: 'rgba(255, 255, 255, 0.87);'
          color: 'white',
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderColor: 'rgba(255, 255, 255, 0.12)'
        },
        ganttHead: {
          default: {
            // backgroundImage: 'linear-gradient(to top, #424242, #575557)',
            backgroundImage:
              'linear-gradient(to top, rgba(55,55,66,1), rgba(75,75,86,1)',
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          },
          weekend: {
            // backgroundImage: 'linear-gradient(to top, #424242, #6f6f6f)',
            backgroundImage: 'linear-gradient(to top, #424250, #6f6f6f)',
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)'
          },
          today: {
            backgroundImage: 'linear-gradient(to top, #999999, #999999)',
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            timeLineColor: 'rgba(255,255,255,0.1)'
          }
          // backgroundColor: 'rgb(3, 3, 3)',
          // backgroundImage: 'linear-gradient(to top, #424242, #555555)',
          // color: 'white',
          // border: '1px solid rgba(255, 255, 255, 0.12)',
          // borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      },
      itemStyle: {
        rowStyle: {
          1: {
            // backgroundColor: 'rgb(0, 0, 0)',
            backgroundColor: 'rgba(55,55,66,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          2: {
            // backgroundColor: 'rgb(3, 3, 3)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(63,63,63)',
            backgroundColor: 'rgba(52,52,63,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          3: {
            // backgroundColor: 'rgb(3, 3, 3)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(60,60,60)',
            backgroundColor: 'rgba(49,49,60,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },

          4: {
            // backgroundColor: 'rgb(6, 6, 6)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(57,57,57)',
            backgroundColor: 'rgba(46,46,57,1)',
            // backgroundColor: '#545454',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          5: {
            // backgroundColor: 'rgb(9, 9, 9)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(54,54,54)',
            backgroundColor: 'rgba(43,43,54,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          6: {
            // backgroundColor: 'rgb(9, 9, 9)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(51,51,51)',
            backgroundColor: 'rgba(40,40,51,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          7: {
            // backgroundColor: 'rgb(9, 9, 9)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(48,48,48)',
            backgroundColor: 'rgba(37,37,48,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          8: {
            // backgroundColor: 'rgb(9, 9, 9)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(48,48,48)',
            backgroundColor: 'rgba(34,34,45,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          9: {
            // backgroundColor: 'rgb(9, 9, 9)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(48,48,48)',
            backgroundColor: 'rgba(31,31,42,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          10: {
            // backgroundColor: 'rgb(9, 9, 9)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(48,48,48)',
            backgroundColor: 'rgba(28,28,39,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          11: {
            // backgroundColor: 'rgb(9, 9, 9)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(48,48,48)',
            backgroundColor: 'rgba(25,25,36,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          },
          12: {
            // backgroundColor: 'rgb(9, 9, 9)',
            // backgroundColor: '#424242',
            // backgroundColor: 'rgb(48,48,48)',
            backgroundColor: 'rgba(22,22,33,1)',
            // color: 'rgba(0, 0, 0, 0.87);'
            color: 'white',
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderColor: 'rgba(255, 255, 255, 0.12)',
            checkedBackgroundColor: checkedBackgroundColor
          }
        }
      },
      summaryStyle: {
        defaultSummary: {
          // backgroundImage: 'linear-gradient(to top, black, #111111)',
          // backgroundImage: 'linear-gradient(to top, #424242, #575557)',
          // background: 'linear-gradient(to top, #424242, #575557)',

          backgroundImage:
            'linear-gradient(to top, rgba(55,55,66,1), rgba(75,75,86,1)',
          background:
            'linear-gradient(to top, rgba(55,55,66,1), rgba(75,75,86,1)',
          // backgroundImage: 'linear-gradient(to bottom, black, #111111)',
          // color: 'rgba(255, 255, 255, 0.87);'
          color: 'white',
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      }
    }
  },
  ManagerAccountSettings: {
    cardBackgroundColor: 'rgba(58,58,66,0.9)', //'rgb(66, 66, 66)',
    cardTopBackgroundColor: 'rgba(255, 255, 255, 0.03)',
    childCardBackgroundColor: 'rgba(255, 255, 255, 0.05)',
    childCardChildBorderColor: '1px solid rgba(255, 255, 255, 0.1)',
    color: 'white',
    selectedListButton: 'rgba(255,255,255,0.07)'
  }
};

export const datePickerStyleDarkMode = () => {
  // ##############################################################################
  // ##############################################################################
  // Create our stylesheet
  var style = document.createElement('style');
  style.innerHTML =
    // 'div[role="presentation"] > .MuiInputBase-input, div[role="presentation"] > .MuiButtonBase-root, div[role="presentation"] > .MuiPickersDay-day, div[role="presentation"] > .MuiPickersDay-hidden {' +
    // 'color: white !important;' +
    // 'background-color: rgb(20,20,20) !important;' +
    // // 'border: 1px solid red !important;' +
    // '}' +
    '.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day {' +
    'color: white !important;' +
    'background-color: rgb(20,20,20) !important;' +
    // 'border: 1px solid red !important;' +
    '}' +
    '.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day:hover {' +
    'color: white !important;' +
    'background-color: rgb(150,150,150) !important;' +
    // 'border: 1px solid red !important;' +
    '}' +
    '.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {' +
    'color: white !important;' +
    'background-color: rgb(20,20,20) !important;' +
    'border: 1px solid rgba(255,255,255,0.1) !important;' +
    '}' +
    '.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-daySelected {' +
    'color: white !important;' +
    'background-color: rgba(200,200,200,0.3) !important;' +
    '}' +
    // MuiButtonBase-root MuiIconButton-root MuiPickersDay-day MuiPickersDay-daySelected
    '.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton:hover {' +
    'background: rgba(255,255,255,0.5) !important;' +
    '}' +
    '.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton {' +
    'color: rgba(255,255,255,1) !important;' +
    'background: rgb(20,20,20) !important;' +
    '}' +
    'MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day:focus {' +
    'background: rgba(255,255,255,0.7) !important;' +
    'color: black !important;' +
    'transition: background-color 0.1s;' +
    '}' +
    '.MuiPickersDay-day:active {' +
    'background: rgba(255,255,255,0.7) !important;' +
    'color: black !important;' +
    'transition: background-color 0.3s !important;' +
    '}' +
    '.MuiPickersBasePicker-container {' +
    'background: rgb(10,10,10) !important;' +
    'border: 1px solid rgba(255,255,255,0.1) !important;' +
    'color: white !important;' +
    '}' +
    '.makeStyles-paper-304 {' +
    'color: white !important;' +
    'background-color: rgb(20,20,20) !important;' +
    'border: 1px solid rgba(255,255,255,0.1) !important;' +
    '}' +
    '.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd, .MuiNativeSelect-root.MuiNativeSelect-select.MuiInputBase-input.MuiInput-input {' +
    'color: #FFFFFF !important;' +
    'border-bottom: 1px solid #FFFFFF !important;' +
    // 'background-color: rgb(20,20,20) !important;' +
    // 'border: 1px solid rgba(255,255,255,0.1) !important;' +
    '}' +
    '.MuiSvgIcon-root, .MuiButton-label {' +
    'color: #FFFFFF !important;' +
    '}' +
    '.MuiSvgIcon-root:hover, .MuiButton-label:hover, .MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {' +
    'color: rgba(255,255,255,0.7) !important;' +
    '}';

  // MuiInputBase-root MuiInput-root MuiInput-underline makeStyles-root-307 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedEnd

  // MuiGrid-root makeStyles-root-374 MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-align-items-xs-center MuiGrid-justify-xs-center

  // .MuiPickersBasePicker-container {
  //   background-color: black !important;
  //   color: white !important;
  // }

  // Get the first script tag
  var ref = document.querySelector('script');

  // Insert our new styles before the first script tag
  ref.parentNode.insertBefore(style, ref);

  // ##############################################################################
  // ##############################################################################
};
