import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_microsoft_invest_insertion_order_data_aggregated = (
  microsoftInvestInsertionOrder,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2,
  startDateForLastDaysData,
  startDateForLastDaysDataMinusOneDay,
  tomorrow,
  dayAfterTomorrow
) => {
  const action01DataSource =
    microsoftInvestInsertionOrder?.action01?.dataSource;
  const action02DataSource =
    microsoftInvestInsertionOrder?.action02?.dataSource;
  const action03DataSource =
    microsoftInvestInsertionOrder?.action03?.dataSource;

  const action01DataSourceIsMicrosoftInvest =
    action01DataSource === 'microsoftInvest';
  const action02DataSourceIsMicrosoftInvest =
    action02DataSource === 'microsoftInvest';
  const action03DataSourceIsMicrosoftInvest =
    action03DataSource === 'microsoftInvest';

  const action01Type =
    microsoftInvestInsertionOrder?.action01?.microsoftInvest?.type;

  const action02Type =
    microsoftInvestInsertionOrder?.action02?.microsoftInvest?.type;

  const action03Type =
    microsoftInvestInsertionOrder?.action03?.microsoftInvest?.type;

  const action01Id =
    microsoftInvestInsertionOrder?.action01?.microsoftInvest?.id;

  const action02Id =
    microsoftInvestInsertionOrder?.action02?.microsoftInvest?.id;

  const action03Id =
    microsoftInvestInsertionOrder?.action03?.microsoftInvest?.id;

  // const action01Name =
  //   microsoftInvestInsertionOrder?.action01?.microsoftInvest?.name;

  // const action02Name =
  //   microsoftInvestInsertionOrder?.action02?.microsoftInvest?.name;

  // const action03Name =
  //   microsoftInvestInsertionOrder?.action03?.microsoftInvest?.name;

  // const actionWindowDict = {
  //   default: 'externalWebsiteConversions',
  //   postClick: 'externalWebsitePostClickConversions',
  //   postView: 'externalWebsitePostViewConversions'
  // };

  const action01AdInteraction =
    microsoftInvestInsertionOrder?.action01?.microsoftInvest?.adInteraction;

  console.log('action01AdInteraction: ', action01AdInteraction);
  const action02AdInteraction =
    microsoftInvestInsertionOrder?.action02?.microsoftInvest?.adInteraction;
  const action03AdInteraction =
    microsoftInvestInsertionOrder?.action03?.microsoftInvest?.adInteraction;

  const adInteractionDict = {
    click: 'post_click',
    view: 'post_view'
  };

  // console.log('action01AdInteraction: ', action01AdInteraction);

  const insertionOrder = {
    ...microsoftInvestInsertionOrder,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'microsoft_invest_insertion_order'
  };

  // insertionOrder.action01.name = action01Name;
  // insertionOrder.action02.name = action02Name;
  // insertionOrder.action03.name = action03Name;

  const listOfPropagationData = [];

  let action01ConversionData = [];

  let objectToPush = {
    date_start: startDateForLastDaysDataMinusOneDay.getTime()
  };
  let pushObj = false;
  for (let date of range2) {
    // let pushObj = false;
    let dailyPushObject = false;
    let dailyObjectToPush = {};

    if (new Date(date) >= new Date(startDateForLastDaysData)) {
      if (microsoftInvestInsertionOrder?.microsoftinvestbasicdata?.length > 0) {
        for (let microsoftInvestBasicDataObj of microsoftInvestInsertionOrder?.microsoftinvestbasicdata) {
          const insertionOrderDate = new Date(+microsoftInvestBasicDataObj.day)
            .toISOString()
            .split('T')[0];
          if (
            insertionOrderDate === new Date(date).toISOString().split('T')[0]
          ) {
            dailyPushObject = true;
            dailyObjectToPush = {
              ...dailyObjectToPush,
              cost: microsoftInvestBasicDataObj.spend_adv_curr || null,
              impressions: microsoftInvestBasicDataObj.imps || null,
              measurableImpressions:
                microsoftInvestBasicDataObj.view_measured_imps || null,
              viewableImpressions:
                microsoftInvestBasicDataObj.imps_viewed || null,

              videoCompletions:
                microsoftInvestBasicDataObj.video_completions || null,

              clicks: +microsoftInvestBasicDataObj.clicks || null,

              date_start: new Date(+microsoftInvestBasicDataObj.day).getTime(),
              date_stop: new Date(+microsoftInvestBasicDataObj.day).getTime(),

              accountId: campaignBaseValues.accountId
            };
          }
        }
      }

      for (let actionNumber of ['01', '02', '03']) {
        if (
          microsoftInvestInsertionOrder?.['action' + actionNumber]
            ?.dataSource === 'microsoftInvest'
        ) {
          switch (action01Type) {
            case 'conversions':
              if (
                microsoftInvestInsertionOrder?.microsoftinvestbasicdata
                  ?.length > 0
              ) {
                for (let microsoftInvestBasicDataObj of microsoftInvestInsertionOrder?.microsoftinvestbasicdata) {
                  const insertionOrderDate = new Date(
                    +microsoftInvestBasicDataObj.day
                  )
                    .toISOString()
                    .split('T')[0];
                  if (
                    insertionOrderDate ===
                    new Date(date).toISOString().split('T')[0]
                  ) {
                    dailyObjectToPush[`action${actionNumber}Count`] =
                      (dailyObjectToPush?.[`action${actionNumber}Count`] || 0) +
                      (microsoftInvestBasicDataObj.conversions || null);
                    dailyObjectToPush[`action${actionNumber}Value`] =
                      (dailyObjectToPush?.[`action${actionNumber}Value`] || 0) +
                      (microsoftInvestBasicDataObj.revenue || null);
                  }
                }
              }

              break;

            default:
              if (
                microsoftInvestInsertionOrder?.microsoftinvestactiondata
                  ?.length > 0
              ) {
                for (let conversionObj of microsoftInvestInsertionOrder?.microsoftinvestactiondata) {
                  if (
                    // conversionObj?.segments?.conversionAction === action01Type &&
                    conversionObj.pixel_id ===
                      microsoftInvestInsertionOrder?.['action' + actionNumber]
                        ?.microsoftInvest?.id &&
                    new Date(+conversionObj.day).toISOString().split('T')[0] ===
                      date
                  ) {
                    dailyObjectToPush[`action${actionNumber}Count`] =
                      (dailyObjectToPush?.[`action${actionNumber}Count`] || 0) +
                      (conversionObj[
                        `${
                          microsoftInvestInsertionOrder?.[
                            'action' + actionNumber
                          ]?.microsoftInvest?.adInteraction
                        }_convs`
                      ] || null);
                    dailyObjectToPush[`action${actionNumber}Value`] =
                      (dailyObjectToPush?.[`action${actionNumber}Value`] || 0) +
                      (conversionObj[
                        `${
                          microsoftInvestInsertionOrder?.[
                            'action' + actionNumber
                          ]?.microsoftInvest?.adInteraction
                        }_revenue`
                      ] || null);
                  }
                }
              }
          }
        }
      }

      if (dailyPushObject) {
        listOfPropagationData.push(dailyObjectToPush);
      }
    } else {
      if (microsoftInvestInsertionOrder?.microsoftinvestbasicdata?.length > 0) {
        for (let microsoftInvestBasicDataObj of microsoftInvestInsertionOrder?.microsoftinvestbasicdata) {
          const insertionOrderDate = new Date(+microsoftInvestBasicDataObj.day)
            .toISOString()
            .split('T')[0];
          if (insertionOrderDate === date) {
            pushObj = true;
            objectToPush = {
              ...objectToPush,

              cost:
                (objectToPush.cost || 0) +
                  microsoftInvestBasicDataObj.spend_adv_curr || null,
              impressions:
                (objectToPush.impressions || 0) +
                  microsoftInvestBasicDataObj.imps || null,
              measurableImpressions:
                (objectToPush.measurableImpressions || 0) +
                  microsoftInvestBasicDataObj.view_measured_imps || null,
              viewableImpressions:
                (objectToPush.viewableImpressions || 0) +
                  microsoftInvestBasicDataObj.imps_viewed || null,

              videoCompletions:
                (objectToPush.videoCompletions || 0) +
                  microsoftInvestBasicDataObj.video_completions || null,

              clicks:
                (objectToPush.clicks || 0) +
                  microsoftInvestBasicDataObj.clicks || null,

              accountId: campaignBaseValues.accountId
            };
          }
        }
      }

      for (let actionNumber of ['01', '02', '03']) {
        if (
          microsoftInvestInsertionOrder?.['action' + actionNumber]
            ?.dataSource === 'microsoftInvest'
        ) {
          switch (action01Type) {
            case 'conversions':
              if (
                microsoftInvestInsertionOrder?.microsoftinvestbasicdata
                  ?.length > 0
              ) {
                for (let microsoftInvestBasicDataObj of microsoftInvestInsertionOrder?.microsoftinvestbasicdata) {
                  const insertionOrderDate = new Date(
                    +microsoftInvestBasicDataObj.day
                  )
                    .toISOString()
                    .split('T')[0];
                  if (
                    insertionOrderDate ===
                    new Date(date).toISOString().split('T')[0]
                  ) {
                    objectToPush[`action${actionNumber}Count`] =
                      (objectToPush?.[`action${actionNumber}Count`] || 0) +
                      (microsoftInvestBasicDataObj.conversions || null);
                    objectToPush[`action${actionNumber}Value`] =
                      (objectToPush?.[`action${actionNumber}Value`] || 0) +
                      (microsoftInvestBasicDataObj.revenue || null);
                  }
                }
              }

              break;

            default:
              if (
                microsoftInvestInsertionOrder?.microsoftinvestactiondata
                  ?.length > 0
              ) {
                for (let conversionObj of microsoftInvestInsertionOrder?.microsoftinvestactiondata) {
                  if (
                    // conversionObj?.segments?.conversionAction === action01Type &&
                    conversionObj.pixel_id ===
                      microsoftInvestInsertionOrder?.['action' + actionNumber]
                        ?.microsoftInvest?.id &&
                    new Date(+conversionObj.day).toISOString().split('T')[0] ===
                      date
                  ) {
                    objectToPush[`action${actionNumber}Count`] =
                      (objectToPush?.[`action${actionNumber}Count`] || 0) +
                      (conversionObj[
                        `${
                          microsoftInvestInsertionOrder?.[
                            'action' + actionNumber
                          ]?.microsoftInvest?.adInteraction
                        }_convs`
                      ] || null);
                    objectToPush[`action${actionNumber}Value`] =
                      (objectToPush?.[`action${actionNumber}Value`] || 0) +
                      (conversionObj[
                        `${
                          microsoftInvestInsertionOrder?.[
                            'action' + actionNumber
                          ]?.microsoftInvest?.adInteraction
                        }_revenue`
                      ] || null);
                  }
                }
              }
          }
        }
      }
    }
  }

  if (pushObj) {
    listOfPropagationData.push(objectToPush);
  }

  insertionOrder.total_list_of_aggregated_propagation_data = [
    {
      costLifetime: microsoftInvestInsertionOrder?.totaldata?.cost,
      impressionsLifetime: microsoftInvestInsertionOrder?.totaldata?.impressions
    }
  ];

  insertionOrder.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: insertionOrder
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_microsoft_invest_insertion_order_data_aggregated;
