import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { Button } from '@mui/material';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { useSnackbar } from 'notistack';

import { addMicrosoftInvestAuthTokenMutation } from '../../../../../../../graphql/queries';

import { AccountContext } from '../../../../../../../context/AccountContext';

export default function AddToken({
  microsoftInvestUsers,
  setMicrosoftInvestUsers,
  setShowScreen,
  setOpen
}) {
  // const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');

  const { currentManagerAccount } = useContext(AccountContext);

  const [addMicrosoftInvestAuthToken, { called }] = useMutation(
    addMicrosoftInvestAuthTokenMutation
  );

  const onSubmit = async () => {
    if (!userName || !password) {
      return;
    }
    const input = {
      managerAccountId: currentManagerAccount._id,
      userName,
      password
    };
    console.log('AddToken>input: ', input);

    let res;
    try {
      res = await addMicrosoftInvestAuthToken({ variables: { input } });
    } catch (error) {
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.addMicrosoftInvestAuthToken?.response === 'success') {
      enqueueSnackbar(':D', {
        variant: 'success'
      });

      setMicrosoftInvestUsers(
        res?.data?.addMicrosoftInvestAuthToken?.users || []
      );
      setShowScreen('select_microsoft_invest_user_id');
    }
  };

  // if (!open) {
  //   return (
  //     <Button
  //       style={{
  //         // padding: '10px',
  //         padding: '5px 10px',
  //         margin: '10px 0px',
  //         cursor: 'pointer',
  //         background: 'rgba(0, 181, 8, 0.9)',
  //         color: 'white'
  //       }}
  //       onClick={() => setOpen(true)}
  //     >
  //       + Add new token
  //     </Button>
  //   );
  // }
  return (
    <div
      style={{
        padding: '20px',
        border: '1px solid rgba(150,150,150,0.3)',
        borderRadius: '5px'
      }}
    >
      <div style={{ width: '100%', marginBottom: '20px' }}>
        <TextField
          variant="standard"
          style={{ width: '100%' }}
          //   id="outlined-controlled"
          size="small"
          label="User Name"
          value={userName}
          onChange={(event) => {
            setUserName(event.target.value);
          }}
        />
      </div>
      <div style={{ width: '100%', marginBottom: '40px' }}>
        <TextField
          type="password"
          variant="standard"
          style={{ width: '100%' }}
          size="small"
          //   id="outlined-controlled"
          label="Password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
      </div>
      <div>
        <Button size="small" variant="contained" onClick={onSubmit}>
          Submit
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          size="small"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
