import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import { useMutation } from '@apollo/client';

import { updateDataSourceMutation } from './../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';
import { useSnackbar } from 'notistack';

export default function BasicTextFields({
  dataSourceInfo,
  refetchDataSourceInfo
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [ocid, setOcid] = useState('');

  useEffect(() => {
    setOcid(dataSourceInfo.ocid);
  }, [dataSourceInfo]);

  // const classes = useStyles();

  const [updateDataSource, { called }] = useMutation(updateDataSourceMutation);

  const { enqueueSnackbar } = useSnackbar();

  const onSaveHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const input = {
      managerAccountId: currentManagerAccount._id,
      updateKeys: ['ocid'],
      documents: [
        {
          _id: dataSourceInfo._id,
          dataSourceRef: dataSourceInfo.dataSourceRef,
          ocid
        }
      ]
    };

    let res;
    try {
      res = await updateDataSource({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.updateDataSource?.response === 'Success') {
      enqueueSnackbar('Saved', {
        variant: 'success'
      });
      refetchDataSourceInfo();
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' }
        }}
        noValidate
        autoComplete="off"
      >
        {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      <TextField id="filled-basic" label="Filled" variant="filled" /> */}
        <TextField
          id="standard-basic"
          label="Ocid"
          variant="standard"
          value={ocid}
          onChange={(event) => {
            setOcid(event.target.value);
          }}
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
              }
            }
          }}
        />
      </Box>
      <div style={{ paddingTop: '10px' }}>
        <Button
          size="small"
          variant="outlined"
          style={{ height: '35px', marginLeft: '20px' }}
          onClick={onSaveHandler}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
