import React, { useState } from 'react';
import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TimeboxLists from './timebox-lists/TimeboxLists';

import TimeboxList from './timebox-list/TimeboxList';

function Users({ user }) {
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [selectedTimeboxListId, setSelectedTimeboxListId] = useState('');

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '300px auto',
        gridColumnStart: 2,
        gridColumnEnd: 2
      }}
    >
      <TimeboxLists
        selectedTimeboxListId={selectedTimeboxListId}
        setSelectedTimeboxListId={setSelectedTimeboxListId}
      />
      <TimeboxList
        selectedTimeboxListId={selectedTimeboxListId}
        setSelectedTimeboxListId={setSelectedTimeboxListId}
      />
    </div>
  );
}

export default Users;
