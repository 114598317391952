import React, { useContext, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import CommentIcon from '@mui/icons-material/Comment';

import { commentsQuery } from '../../../../../../graphql/queries';

import { useLazyQuery } from '@apollo/client';

import { AccountContext } from '../../../../../../context/AccountContext';

import { DataContext } from '../../../../../../context/DataContext';

export default function MouseOverPopover({ item }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const { listOfData } = useContext(DataContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const variables = {
    commentsInput: {
      documentIds: [item._id, ...item.childDocumentIds],
      accountId: item.accountId,
      managerAccountId: currentManagerAccount._id,
      documentType: item.documentType,
      level: item.level,
      includeHistory: false,
      limit: 3
    }
  };
  console.log('commentsInput>variables: ', variables);

  const [loadComments, { called, loading, data }] = useLazyQuery(
    commentsQuery,
    { variables, fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    if (open) {
      console.log('');
      loadComments();
    }
  }, [open]);

  useEffect(() => {
    if (open && data?.comments) {
      console.log('data?.comments: ', data?.comments);
    }
  });

  //   console.log('comments');

  console.log('Comments>data: ', data);

  const comments = data?.comments || [];

  return (
    <div>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <CommentIcon style={{ fontSize: '9px' }} />{' '}
        <span style={{ fontSize: '12px' }}>{item.comments}</span>
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'center'
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'center'
        // }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{ padding: '10px' }}>
          {/* <Typography sx={{ p: 1 }}>I use Popover.</Typography> */}
          <div
            style={{
              // padding: '10px 10px 0px 10px',
              fontSize: '10px',
              fontStyle: 'italic',
              color: 'grey'
            }}
          >
            Last 3 comments:
          </div>
          {comments.map((commentObj) => {
            const rowInRawArrayOfData = listOfData.filter(
              (rawRow) => rawRow._id === commentObj.documentId
            );

            if (rowInRawArrayOfData && rowInRawArrayOfData[0]) {
              commentObj.path = rowInRawArrayOfData[0].path;
              commentObj.interactivePath =
                rowInRawArrayOfData[0].interactivePath;
            }

            return (
              <div
                style={{
                  margin: '10px 0px',
                  maxWidth: '600px',
                  fontSize: '14px'
                }}
              >
                <div>
                  <span style={{ fontWeight: 'bold' }}>
                    {commentObj?.user?.name}
                    {': '}
                  </span>
                  <span style={{ color: 'grey', fontSize: '12px' }}>
                    {commentObj.date &&
                      new Date(parseInt(commentObj.date)).toLocaleString()}
                  </span>
                </div>

                <div>
                  <span style={{ color: 'grey', fontSize: '12px' }}>
                    {commentObj.path}
                  </span>
                </div>
                <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                  <span
                    style={
                      {
                        // lineBreak: 'anywhere'
                      }
                    }
                  >
                    {commentObj.text[commentObj.text.length - 1].text}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
}
