import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';

function ReportAssetName({
  reportAsset,
  reportAssetIndex,
  reportAssets,
  setReportAssets
}) {
  const [name, setName] = React.useState('');

  useEffect(() => {
    setName(reportAsset.name);
  }, [reportAsset?.name]);
  console.log('dbug7147>name: ', name);
  console.log('dbug7147>reportAssetIndex: ', reportAssetIndex);

  const [isEditMode, setIsEditMode] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('do validate');
      saveNameToReportAsset();
    }
  };

  const saveNameToReportAsset = () => {
    const copyOfReportAsset = { ...reportAsset };
    copyOfReportAsset.name = name;

    const copyOfReportAssets = [...reportAssets];
    copyOfReportAssets[reportAssetIndex] = copyOfReportAsset;

    setReportAssets(copyOfReportAssets);

    setIsEditMode(false);
  };
  console.log('dbug7147>reportAsset: ', reportAsset);
  return (
    <div style={{ width: '100%' }}>
      {isEditMode ? (
        <TextField
          autoFocus
          size="small"
          variant="standard"
          id="outlined-controlled"
          //   label="Controlled"
          value={name}
          onKeyDown={handleKeyDown}
          onBlur={() => saveNameToReportAsset()}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      ) : (
        <span onClick={() => setIsEditMode(true)}>{reportAsset.name}</span>
      )}
    </div>
  );
}

export default ReportAssetName;
