import React, { useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import DeleteIcon from '@mui/icons-material/Delete';

import { useMutation } from '@apollo/client';

import { removeAccountUserRelationMutation } from './../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { refetchUsersAccountsList } from './../../../../actions/account';

import { AuthContext } from '../../../../context/AuthContext';
import { AccountContext } from '../../../../context/AccountContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       borderRadius: '5px',
//       height: '20px',
//       width: '37px',
//       backgroundColor: 'rgba(181, 0, 8, 1)',
//       '&:hover': {
//         background: 'rgba(201, 20, 28, 1)'
//       }
//     }
//   },
//   extendedIcon: {
//     color: 'white',
//     marginRight: theme.spacing(1)
//   }
// }));

const FloatingActionButtons = ({
  // currentManagerAccount,
  userId,
  selectedAccountId,
  refetchAccountInfo,
  refetchUsersAccountsList
  // userId_
}) => {
  // const classes = useStyles();

  const { user } = useContext(AuthContext);
  const { currentManagerAccount, refetchAccounts } = useContext(AccountContext);

  const [removeAccountUserRelation, { called }] = useMutation(
    removeAccountUserRelationMutation
  );

  const { enqueueSnackbar } = useSnackbar();

  const onRemoveHandler = async (e) => {
    const removeAccountUserRelationInput = {
      managerAccountId: currentManagerAccount._id,
      accountIds: [selectedAccountId],
      userIds: [userId]
    };

    let res;
    try {
      res = await removeAccountUserRelation({
        variables: { input: removeAccountUserRelationInput }
      });
    } catch (error) {
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (
      res &&
      res.data &&
      res.data.removeAccountToUserRelation &&
      res.data.removeAccountToUserRelation.response === 'success'
    ) {
      enqueueSnackbar('Account access successfully removed', {
        variant: 'success'
      });
      refetchAccountInfo();
      if (userId === user?._id) {
        // refetchUsersAccountsList();
        refetchAccounts();
      }
    }
  };

  return (
    <div
    // className={classes.root}
    >
      {/* <Fab color="primary" aria-label="add">
        <AddIcon />
      </Fab> */}
      <Fab
        onClick={onRemoveHandler}
        aria-label="edit"
        style={{
          color: 'white',
          // margin: theme.spacing(1),
          borderRadius: '5px',
          height: '20px',
          width: '37px',
          backgroundColor: 'rgba(181, 0, 8, 1)'
        }}
      >
        <DeleteIcon style={{ color: 'white' }} />
      </Fab>
      {/* <Fab variant="extended">
        <NavigationIcon className={classes.extendedIcon} />
        Navigate
      </Fab>
      <Fab disabled aria-label="like">
        <FavoriteIcon />
      </Fab> */}
    </div>
  );
};

FloatingActionButtons.propTypes = {
  // userId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  // userId_: state.auth.user._id
});

export default connect(mapStateToProps, {
  // refetchUsersAccountsList
})(FloatingActionButtons);
