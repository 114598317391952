import React, { useState, useContext, useEffect } from 'react';

import Button from '@mui/material/Button';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import { microsoftInvestTokensQuery } from '../../../../../../../graphql/queries';

import { AccountContext } from '../../../../../../../context/AccountContext';

function TokenList({ refetchCounter, setSelectedTokenId, setShowScreen }) {
  const { currentManagerAccount } = useContext(AccountContext);
  const variables = {
    input: {
      managerAccountId: currentManagerAccount._id
    }
  };

  const { data, refetch } = useQuery(microsoftInvestTokensQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: !currentManagerAccount?._id
  });

  useEffect(() => {
    if (refetchCounter) {
      refetch();
    }
  }, [refetchCounter]);
  console.log('TokenList>data: ', data);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ margin: '10px 5px' }}>Select token:</div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {data?.microsoftInvestTokens?.map((obj) => (
          <div style={{ margin: '5px' }}>
            <Button
              onClick={() => {
                setSelectedTokenId(obj._id);
                setShowScreen('account_list');
              }}
              variant="contained"
            >{`${obj.microsoftInvestEntityName} - ${obj.microsoftInvestUserId}`}</Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TokenList;
