import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { makeStyles } from '@mui/styles';

import './draggableDialog.css';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          maxWidth: '860px',
          width: '860px',
          height: '67vw',
          maxHeight: '700px'
        }}
      />
    </Draggable>
  );
}

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     position: 'absolute',
//     width: 800,
//     // height: 800,
//     height: '85%',
//     // backgroundColor: theme.palette.background.paper,
//     // border: '2px solid #000',
//     // border: '1px solid rgba(200, 200, 200, 0.5)',
//     // boxShadow: theme.shadows[5],
//     // padding: theme.spacing(2, 4, 3),
//     // padding: theme.spacing(4),
//     // borderRadius: '5px',
//     outline: 0
//   }
// }));

export default function DraggableDialog({
  children,
  open,
  setOpen,
  handleClose
}) {
  // const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const body = (
    <div
      style={{
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        // padding: '30px',
        position: 'absolute',
        width: 810,
        // height: 800,
        height: '85%'
      }}
      // className={classes.paper}
    >
      {/* <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p> */}
      {children}
      {/* <SimpleModal /> */}
    </div>
  );

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        className="draggable-dialog"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move', paddingTop: '40px' }}
          id="draggable-dialog-title"
        >
          <span style={{ paddingLeft: '23px' }}>Members</span>
        </DialogTitle>
        <DialogContent>
          {body}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
