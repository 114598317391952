import * as React from 'react';
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from 'dayjs';

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export default function BasicDatePicker({
  value,
  setValue,
  label,
  disabled,
  size
}) {
  //   const [value, setValue] = React.useState(null);
  const textFieldSize = size || 'medium';

  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        size={size}
        disabled={disabled}
        format="YYYY-MM-DD"
        label={label}
        value={dayjs(value)}
        onChange={(newValue) => {
          setValue(formatDate(newValue));
        }}
        renderInput={(params) => (
          <TextField size={textFieldSize} {...params} variant="standard" />
        )}
      />
    </LocalizationProvider>
  );
}
