const mongoose = require('mongoose');

const UserToManagerAccountRelationSchema = new mongoose.Schema(
  {
    userId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    },
    managerAccountId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'ManagerAccount'
    },
    role: {
      type: String
    },
    isManager: {
      type: Boolean
    },
    // date: {
    //   type: Date,
    //   default: Date.now
    // }
    emergencyContact: Boolean,
    emergencyDocumentTypes: [{ type: String }],
    createdBy: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'User'
    }
  },
  { timestamps: true }
);

UserToManagerAccountRelationSchema.virtual('managerAccount', {
  ref: 'ManagerAccount',
  localField: 'managerAccountId',
  foreignField: '_id',
  justOne: true
});

module.exports = UserToManagerAccountRelation = mongoose.model(
  'usertomanageraccountrelation',
  UserToManagerAccountRelationSchema
);
