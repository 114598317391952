import React, { useEffect, useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';

import SaveIcon from '@mui/icons-material/Save';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import Checkbox from '@mui/material/Checkbox';

import { Button } from '@mui/material';

function WidgetDescriptionMenu({
  // setDisplaySaveButton,
  // inputRef,
  // textInput,
  // setTextInput,
  isWidgetDescriptionMenuOpen,
  setIsWidgetDescriptionMenuOpen,
  widgetIndex,
  options,
  setOptions,
  editMode,
  widget,
  updateWidget
  // toggleEdit
}) {
  const [textInput, setTextInput] = useState('');
  const [isDraggingOption, setIsDraggingOption] = useState(false);
  const theme = useTheme();

  const [displaySaveButton, setDisplaySaveButton] = useState(false);

  const [edit, toggleEdit] = useState(false);

  useEffect(() => {
    setTextInput(widget.description);
  }, [widget.description]);

  const onChangeHandler = (e) => {
    setTextInput(e.target.value);
  };

  const onSaveHandler = () => {
    updateWidget({ ...widget, description: textInput }, widgetIndex);
    // setTextInput('');

    // if (!textInput) {
    //   setValueIsEdited(false);
    // }
    toggleEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setTextInput(widget.value);
      toggleEdit(false);
      return;
    }

    // if (event.key === 'Enter') {
    //   console.log('do validate');
    //   updateWidget({ ...widget, description: textInput }, widgetIndex);
    //   // setTextInput('');

    //   // if (!textInput) {
    //   //   setValueIsEdited(false);
    //   // }

    //   toggleEdit(false);
    //   return;
    // }
  };

  // const onClickRemoveOptionHandler = (e, optionIndex) => {
  //   const copyOfOptions = [...options];
  //   copyOfOptions.splice(optionIndex, 1);
  //   // setOptions(copyOfOptions);
  //   updateWidget({ ...widget, options: copyOfOptions }, widgetIndex);
  // };

  return (
    <div
      onBlur={() => {
        console.log('blur');
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      // onBlur={(e) => {
      //   e.stopPropagation();
      // }}
      style={{
        marginTop: '8px',
        position: 'fixed',
        width: '200px',
        // height: displaySaveButton ? '225px' : '200px',
        height: '200px',
        // border: '1px solid white',
        overflow: 'hidden',

        zIndex: '1000',
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgba(102,95,134,1)'
            : 'rgba(255,255,255,0.8)',
        borderRadius: '5px',
        // overflow: 'hidden',
        backdropFilter: 'blur(10px)',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <div
          style={{
            width: '100%',
            height: '20px',
            // background: '#bcbcbc',
            background: `
      linear-gradient(17deg, rgba(54,82,156,.2), rgba(54,82,156,0) 70.71%),
      linear-gradient(336deg, rgba(167,38,131,.2), rgba(167,38,131,0) 70.71%)

      `,
            backdropFilter: 'blur(10px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 5px'
          }}
        >
          <span>Description</span>
          {/* <div>
            <span style={{ fontSize: '10px' }}>Dynamic</span>
            <Checkbox
              size="small"
              style={{
                fontSize: '10px',
                transform: 'scale(0.6)',
                left: '-8px'
              }}
            />
          </div>
          <div>
            <span style={{ fontSize: '10px' }}>Optional</span>
            <Checkbox
              size="small"
              style={{
                fontSize: '10px',
                transform: 'scale(0.6)',
                left: '-8px'
              }}
            />
          </div> */}
          <CloseIcon
            style={{ fontSize: '12px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setIsWidgetDescriptionMenuOpen(false);
              updateWidget(
                { ...widget, ['description']: textInput },
                widgetIndex
              );
              toggleEdit(false);
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // overflowY: 'scroll',
            maxHeight: '160px',
            color: theme?.palette?.mode === 'dark' ? 'white' : 'black'
          }}
        >
          {edit ? (
            <textarea
              value={textInput}
              onChange={onChangeHandler}
              onKeyDown={handleKeyDown}
              style={{
                fontFamily: 'Roboto, Raleway, sans-serif',
                lineHeight: '1.5',
                fontSize: '12px',
                resize: 'none',
                outline: 'none',
                border: 'none',
                padding: '5px',
                minHeight: '160px',
                background: 'rgba(255,255,255,0)',
                color: theme?.palette?.mode === 'dark' ? 'white' : 'black'
              }}
            />
          ) : (
            <span
              onClick={() => {
                if (editMode) {
                  toggleEdit(true);
                }
              }}
              style={{
                width: '200px',
                textWrap: 'wrap',
                padding: '5px',
                minHeight: '160px',
                whiteSpace: 'pre-line',
                cursor: editMode ? 'text' : 'not-allowed'
              }}
            >
              {widget.description}
            </span>
          )}
        </div>
      </div>

      {editMode && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {displaySaveButton && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button size="small" style={{ width: '100%', fontSize: '10px' }}>
                Cancel
              </Button>
              <Button
                size="small"
                style={{
                  width: '100%',
                  background: 'rgba(0,255,0,0.3)',
                  fontSize: '10px'
                }}
              >
                OK
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default WidgetDescriptionMenu;
