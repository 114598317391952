import * as React from 'react';
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import formatDateYYYYMMDD from './../roitable_functions/formatDateYYYYMMDD';

// function formatDate(date) {
//   var d = new Date(date),
//     month = '' + (d.getMonth() + 1),
//     day = '' + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2) month = '0' + month;
//   if (day.length < 2) day = '0' + day;

//   return [year, month, day].join('-');
// }

export default function BasicDatePicker({
  document,
  setDocument,
  label,
  disabled,
  size,
  documentKey
}) {
  //   const [value, setValue] = React.useState(null);
  const textFieldSize = size || 'medium';

  const handleChange = (newValue) => {
    // copyOfDocument.status =
    // setStatus(event.target.value);
    setDocument({ ...document, [documentKey]: newValue });
  };

  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        size={size}
        disabled={disabled}
        inputFormat="yyyy-MM-dd"
        label={label}
        format="YYYY-MM-DD"
        value={dayjs(document[documentKey])}
        onChange={(newValue) => {
          handleChange(formatDateYYYYMMDD(newValue));
        }}
        renderInput={(params) => (
          <TextField size={textFieldSize} {...params} variant="standard" />
        )}
      />
    </LocalizationProvider>
  );
}
