import * as React from 'react';
import Button from '@mui/material/Button';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function VariantButtonGroup({
  componentIndex,
  metricIndex,
  field,
  fields,
  setFields
}) {
  const copyOfFields = [...fields];
  const copyOfCurrentField = { ...fields[componentIndex] };

  const copyOfMetric = { ...copyOfCurrentField.metrics[metricIndex] };
  const onClickHandler = (value) => {
    copyOfMetric.hideYAxis = !copyOfMetric.hideYAxis;
    copyOfCurrentField.metrics.splice(metricIndex, 1, copyOfMetric);

    copyOfFields.splice(componentIndex, 1, copyOfCurrentField);

    setFields(copyOfFields);

    // if()
  };

  let Icon = copyOfMetric.hideYAxis ? VisibilityOffIcon : VisibilityIcon;

  return (
    <Icon
      className="hover-grey"
      style={{
        position: 'relative',
        // top: '2px',
        marginRight: '5px',
        width: '20px',
        cursor: 'pointer'
      }}
      onClick={(e) => onClickHandler(e)}
    />
  );
}
