import React from 'react';

import { MenuItem } from '@mui/material';
import DeleteFieldSetButton from './DeleteFieldSetButton';

import DefaultFieldSetButton from './DefaultFieldSetButton';

function PreparedMenuItem({
  currentManagerAccountId,
  name,
  id,
  savedTo,
  handleClose,
  item,
  refetchFieldSets
}) {
  return (
    <MenuItem
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
      id={id}
      onClick={handleClose}
    >
      {name}
      <span style={{ display: 'flex', flexDirection: 'row' }}>
        {savedTo == 'nexusDocument' && (
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <DefaultFieldSetButton
              _id={id}
              currentManagerAccountId={currentManagerAccountId}
              savedTo={savedTo}
              item={item}
              refetchFieldSets={refetchFieldSets}
            />
            {/* <CloseIcon style={{ paddingTop: '10px' }} /> */}
          </span>
        )}

        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <DeleteFieldSetButton
            _id={id}
            currentManagerAccountId={currentManagerAccountId}
            savedTo={savedTo}
            item={item}
            refetchFieldSets={refetchFieldSets}
          />
          {/* <CloseIcon style={{ paddingTop: '10px' }} /> */}
        </span>
      </span>
    </MenuItem>
  );
}

export default PreparedMenuItem;
