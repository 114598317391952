import React, { useState, useContext } from 'react';

// import AddToken from './AddToken';
import TokenList from './TokenList';
import { Button } from '@mui/material';

import { AuthContext } from '../../../../../../../context/AuthContext';

function TokenScreen({ selectedTokenId, setSelectedTokenId, setShowScreen }) {
  const { user } = useContext(AuthContext);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const refetchTokenList = () => {
    setRefetchCounter(refetchCounter + 1);
  };
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
    >
      {['system_admin', 'debug'].includes(user.role) && (
        <div style={{ marginBottom: '20px' }}>
          {/* <AddToken refetchTokenList={refetchTokenList} /> */}

          <Button onClick={() => setShowScreen('add_user_and_pass')}>
            Add new token +
          </Button>
        </div>
      )}
      <div>
        <TokenList
          refetchCounter={refetchCounter}
          selectedTokenId={selectedTokenId}
          setSelectedTokenId={setSelectedTokenId}
          setShowScreen={setShowScreen}
        />
      </div>
    </div>
  );
}

export default TokenScreen;
