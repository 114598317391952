import React, { useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ReportBuilderContext } from '../../../context/ReportBuilderContext';

export default function BasicSelect() {
  const { reportPage, setReportPage } = useContext(ReportBuilderContext);

  const handleChange = (event) => {
    switch (event.target.value) {
      case 'LAYOUT_WIDE':
        setReportPage({
          width: '1920px',
          height: '1080px',
          layout: 'LAYOUT_WIDE'
        });
        break;
      case 'CUSTOM':
        setReportPage({ layout: event.target.value });
        break;
    }
  };

  return (
    <Box
      draggable={false}
      sx={{
        minWidth: 120,
        height: '20px',
        transform: 'scale(0.6)'
        // paddingTop: '0px'
      }}
    >
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          draggable={false}
          size="small"
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={reportPage?.layout}
          //   label="Age"

          onChange={handleChange}
        >
          {[
            // 'LAYOUT_16x9',
            // 'LAYOUT_16x10',
            // 'LAYOUT_4x3',
            'LAYOUT_WIDE',
            'CUSTOM'
          ].map((format) => (
            <MenuItem value={format}>{format}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
