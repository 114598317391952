import React, { useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { updateDocumentMutation } from '../../../../../graphql/queries';

import { MutationContext } from '../../../../../context/MutationContext';

import { useMutation } from '@apollo/client';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useSnackbar } from 'notistack';

// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(1)
//   }
// }));

export default function SimplePopover({
  _id,
  currentManagerAccountId,
  savedTo,
  item,
  refetchFieldSets
}) {
  // const [updateDocument] = useMutation(updateDocumentMutation);

  const { updateDocument } = useContext(MutationContext);

  const { enqueueSnackbar } = useSnackbar();

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const onClickDefaultHandler = () => {
  //   const input = {
  //     _id,
  //     managerAccountId: currentManagerAccountId,
  //     accountId: item.accountId,
  //     savedTo
  //   };

  //   // deleteCustomFieldSet({ variables: { input } });

  //   refetchFieldSets();
  // };

  const handleSaveAsDefault = async () => {
    const itemKey = 'defaultNexusBoardId';
    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: _id
        }
      ],
      updateKeys: [itemKey]
    };

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });

      const response = res?.data?.updateDocument?.response;
      // const success = true;

      if (response === 'Success') {
        enqueueSnackbar('Saved', {
          variant: 'success'
        });
        refetchFieldSets();
        // setEdit(false);
      } else {
        enqueueSnackbar('Error', {
          variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar('Error', {
        variant: 'error'
      });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Button
        style={{
          minWidth: '10px',
          width: '20px',
          minHeight: '10px',
          height: '20px'
        }}
      >
        <span>
          {item.defaultNexusBoardId === _id ? (
            <FavoriteIcon
              onClick={handleClick}
              style={{ paddingTop: '10px' }}
            />
          ) : (
            <FavoriteBorderIcon
              onClick={handleClick}
              style={{ paddingTop: '10px' }}
            />
          )}
        </span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Button
          onClick={handleSaveAsDefault}
          // className={classes.typography}
          style={{ cursor: 'pointer', userSelect: 'none' }}
        >
          Make document default
        </Button>
      </Popover>
    </div>
  );
}
