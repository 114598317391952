import React, { useState, useContext } from 'react';

import IntervallSelect from './IntervallSelect';

import MultipleSelect from './MultipleSelect';

import LineChart from './LineChart';

import DataGrid from './DataGrid';
import { DialogContext } from '../../../../context/DialogContext';

import { columnDictionary } from './../../column-menu/columns';

import ExportMenu from './../../export/data-export/ExportMenu';

function TrendView({
  prepared_data,
  time,
  setTime,
  item,
  columns,
  range2,
  selectedDateStart,
  selectedDateStop,
  isExpanded
}) {
  const { itemId, itemKey } = useContext(DialogContext);

  let defaultKeyArray = [];
  if (itemKey.includes('Sparkline')) {
    defaultKeyArray = [itemKey.split('Sparkline')[0]];
  } else {
    defaultKeyArray = [itemKey];
  }

  const [leftYAxisMetrics, setLeftYAxisMetrics] = useState(defaultKeyArray);
  const [rightYAxisMetrics, setRightYAxisMetrics] = useState([]);

  return (
    <div
      className="sparkline-graph"
      style={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '40px'
        // gridTemplateRows: '60px auto 80px auto 100px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          // width: '100%',
          gridRowStart: 1,
          gridRowEnd: 1,
          marginTop: '20px'
        }}
      >
        <div
          style={{
            textAlign: 'center',
            paddingTop: '18px'
            // display: 'grid',
            // gridRowStart: 1,
            // gridRowEnd: 1,
            // paddingLeft: '75px',
            // paddingTop: '10px'
          }}
        >
          {/* daily/weekly/monthly menu */}
          <IntervallSelect time={time} setTime={setTime} />
          {/* <LineChart /> */}

          {/* metrics menu */}

          {/* <div style={{height:'500px'}}>test</div> */}
        </div>
        <div
          style={{
            // gridColumnStart: 1,
            // gridColumnEnd: 1,
            textAlign: 'center'
          }}
        >
          <MultipleSelect
            item={item}
            yAxisMetrics={leftYAxisMetrics}
            setYAxisMetrics={setLeftYAxisMetrics}
            tag={'Left YAxis Metrics'}
          />
        </div>
        <div
          style={{
            // gridColumnStart: 2,
            // gridColumnEnd: 2,
            textAlign: 'center'
          }}
        >
          <MultipleSelect
            item={item}
            yAxisMetrics={rightYAxisMetrics}
            setYAxisMetrics={setRightYAxisMetrics}
            tag={'Right YAxis Metrics'}
          />
        </div>
        <div
          style={{
            // gridColumnStart: 3,
            // gridColumnEnd: 3,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '18px',
            // justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ExportMenu
            className="hover-grey"
            columns={columns}
            arrayOfData={prepared_data}
            selectedDateStart={selectedDateStart}
            selectedDateStop={selectedDateStop}
            // summaryRow={summaryRow}
            // setSummaryRow={setSummaryRow}
            range2={range2}
            // style={{ cursor: 'pointer' }}
            // onClick={onClickRefreshHandler}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gridRowStart: 2,
          gridRowEnd: 2
        }}
      >
        <LineChart
          prepared_data={prepared_data}
          leftYAxisMetrics={leftYAxisMetrics}
          rightYAxisMetrics={rightYAxisMetrics}
          time={time}
          isExpanded={isExpanded}
          columnDictionary={columnDictionary}
        />
      </div>

      <div style={{ display: 'grid', gridRowStart: 4, gridRowEnd: 4 }}>
        <DataGrid rows={prepared_data} time={time} columns={columns} />
      </div>
    </div>
  );
}

export default TrendView;
