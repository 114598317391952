import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

import { allEvalFieldsExceptTotal } from '../../../fields';

export default function Playground({ onChangeHandler, itemKey }) {
  var variables = allEvalFieldsExceptTotal.map((field) => ({
    name: field,
    string: field
  }));

  // switch (itemKey) {
  //   case 'cost':
  //   case 'impressions':
  //     variables = [
  //       { name: 'cost', string: 'cost' },
  //       { name: 'impressions', string: 'impressions' }
  //     ];
  //     break;
  //   case 'budget':
  //   case 'plannedGross':
  //   case 'plannedNet':
  //   case 'plannedNetNet':
  //   case 'plannedCtc':
  //     variables = [
  //       { name: 'plannedGross', string: 'plannedGross' },
  //       { name: 'plannedNet', string: 'plannedNet' },
  //       { name: 'plannedNetNet', string: 'plannedNetNet' },
  //       { name: 'plannedCtc', string: 'plannedCtc' },
  //       { name: 'budget', string: 'budget' }
  //     ];
  //     break;
  //   // default:
  //   //   variables = [{ name: 'plannedNet', string: 'plannedNet' }];
  // }
  const defaultProps = {
    options: variables,
    getOptionLabel: (option) => option.name
  };

  const [value, setValue] = React.useState(null);

  return (
    <Stack spacing={1} sx={{ width: 300 }}>
      <Autocomplete
        {...defaultProps}
        id="controlled-demo"
        value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
        onChange={onChangeHandler}
        renderInput={(params) => (
          <TextField
            style={{ padding: '5px' }}
            {...params}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            placeholder="Select variable"
            //   label="controlled"
            variant="standard"
          />
        )}
      />
    </Stack>
  );
}
