import React, { useEffect, useState, useContext, useRef } from 'react';
import Button from '@mui/material/Button';
import { ClickAwayListener } from '@mui/base';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles } from '@mui/styles';

import { InterfaceContext } from '../../../context/InterfaceContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex'
//   },
//   paper: {
//     marginRight: theme.spacing(2)
//   }
// }));

export default function RightClickMenuTrigger({
  children,
  style,
  open,
  itemKey,
  // setOpen,
  // rightClickMenuAnchorRef,
  // setRightClickMenuAnchorRef,
  // rightClickMenuToggledItem,
  // setRightClickMenuToggledItem,
  contentComponentType,
  item
}) {
  // console.log('children: ', children);
  const {
    // rightClickMenuAnchorRef,
    // setRightClickMenuAnchorRef,
    // rightClickMenuOpen,
    // setRightClickMenuOpen,
    // rightClickMenuToggledItem,
    // setRightClickMenuToggledItem,
    // typeItemTemplateMenuOpen,
    // setTypeItemTemplateMenuOpen

    rightClickMenuAnchorRef,
    setRightClickMenuAnchorRef,
    rightClickMenuOpen,
    setRightClickMenuOpen,
    rightClickMenuToggledItem,
    setRightClickMenuToggledItem,
    rightClickMenuToggledItemKey,
    setRightClickMenuToggledItemKey,
    setRightClickMenuContentComponentType
  } = useContext(InterfaceContext);

  // console.log('rightClickMenuAnchorRef1: ', rightClickMenuAnchorRef);
  // const classes = useStyles();

  const anchorRef = React.useRef(null);

  // useEffect(() => {
  //   if (anchorRef) {
  //     setRightClickMenuAnchorRef(anchorRef);
  //   }
  // }, [anchorRef]);

  // const colHandlerOnRelease = (e, itemKey) => {
  //   if (!['account', 'folder'].includes(item.type)) {
  //     return;
  //   }

  //   var isRightMB;
  //   var isLeftMB;
  //   e = e || window.event;

  //   if ('which' in e) {
  //     // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
  //     isRightMB = e.which == 3;
  //     isLeftMB = e.which == 1;
  //   } else if ('button' in e) {
  //     // IE, Opera
  //     isRightMB = e.button == 2;
  //     isLeftMB = e.button == 1;
  //   }

  //   if (isRightMB) {
  //     if (!open) {
  //       setRightClickMenuToggledItem(item);
  //       setRightClickMenuAnchorRef(anchorRef);
  //     }
  //     setOpen((prevOpen) => !prevOpen);
  //   }
  // };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  //   return children;

  return (
    <div
      // className={classes.root}
      style={style}
    >
      <div
        ref={anchorRef}
        //   onClick={handleToggle}
        // onMouseUp={colHandlerOnRelease}
        onContextMenu={(e) => {
          console.log('open');
          if (itemKey) {
            setRightClickMenuToggledItemKey(itemKey);
          }
          if (contentComponentType) {
            console.log('contentComponentType: ', contentComponentType);
            setRightClickMenuContentComponentType(contentComponentType);
          }
          // console.log('rightClickMenuAnchorRef???anchorRef: ', anchorRef);
          setRightClickMenuAnchorRef(anchorRef);
          setRightClickMenuOpen(true);
          e.preventDefault();
          return false;
        }}
      >
        {children}
      </div>
    </div>
  );
}
