import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

import Spinner from '../../../../layout/SpinnerSmall';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { BarChart } from '@mui/x-charts/BarChart';

import { DataContext } from '../../../../../context/DataContext';

import { DialogContext } from '../../../../../context/DialogContext';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import AxisMenu from './axis-menu/AxisMenu';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../../fields';

import { prepDataObject } from './prepDataObject';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict,
  colorPalette,
  dayOfWeekAsString
} from '../dictionary';

import formatDateYYYYMMDD from '../../../roitable_functions/formatDateYYYYMMDD';
import { InterfaceContext } from '../../../../../context/InterfaceContext';

function ComboChartWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const { reportBuilderColorPalette: colorPalette } =
    useContext(ReportBuilderContext);

  const [xAxis, setXAxis] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // console.log('widget?.data: ', widget?.data);

  // console.log('ComboChartWidget>widget: ', widget);
  useLayoutEffect(() => {
    setIsLoading(true);
  }, [widget.interval]);
  useEffect(() => {
    let newXAxis = {};
    switch (widget.interval) {
      case 'month':
        // const monthNames = [
        //   'January',
        //   'February',
        //   'March',
        //   'April',
        //   'May',
        //   'June',
        //   'July',
        //   'August',
        //   'September',
        //   'October',
        //   'November',
        //   'December'
        // ];

        newXAxis = widget?.metrics?.map((metricObj) => ({
          yAxisId: metricObj.axis,
          label: 'Month',
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget?.dates || [], // xAxisData,
          scaleType: 'band' // 'band'
          // valueFormatter: (month) => {
          //   return month;
          // }
          // valueFormatter: (date) => {
          //   const formattedDate = formatDateYYYYMMDD(date);
          //   // console.log('formattedDate: ', formattedDate);
          //   return formattedDate;
          // } //(date) => dayjs(date).format('MMM D')
        }));
        break;

      case 'dayOfMonth':
        newXAxis = widget?.metrics?.map((metricObj) => ({
          yAxisId: metricObj.axis,
          label: 'Date of Month',
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget?.dates || [], // xAxisData,
          scaleType: 'band',
          valueFormatter: (date) => {
            // console.log('date: ', date);
            const formattedDate = date;
            // console.log('formattedDate: ', formattedDate);
            return formattedDate;
          } //(date) => dayjs(date).format('MMM D')
        }));
        break;
      // case 'dayOfWeek':
      //   xAxis = [
      //     {
      //       label: 'Date',
      //       data: widget?.dates || [], // xAxisData,
      //       tickInterval: widget.dates || [], // xAxisData,
      //       scaleType: 'band',
      //       valueFormatter: (date) => {
      //         // console.log('date: ', date);
      //         const formattedDate = dayOfWeekAsString(date)?.slice(0, 3);
      //         // console.log('formattedDate: ', formattedDate);
      //         return formattedDate;
      //       } //(date) => dayjs(date).format('MMM D')
      //     }
      //   ];
      //   break;
      case 'date':
        let dates_ = widget?.dates?.map((date) => new Date(date));
        // console.log('widget.dates: ', widget.dates);
        newXAxis = [
          {
            // yAxisId: metricObj.axis,
            label: 'Date',
            data: dates_ || [], // xAxisData,
            tickInterval: dates_ || [], // xAxisData,
            scaleType: 'band',
            valueFormatter: (date) => {
              // console.log('date: ', date);
              try {
                return formatDateYYYYMMDD(date);
              } catch (error) {
                return null;
              }

              // console.log('formattedDate: ', formattedDate);
              // return formattedDate;
            } //(date) => dayjs(date).format('MMM D')
          }
        ];
        break;
      default:
        newXAxis = widget?.metrics?.map((metricObj) => ({
          yAxisId: metricObj.axis,
          label: widget?.interval,
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget?.dates || [], // xAxisData,
          scaleType: 'band',
          valueFormatter: (date) => {
            // console.log('date: ', date);
            const formattedDate = date;
            // console.log('formattedDate: ', formattedDate);
            return formattedDate;
          } //(date) => dayjs(date).format('MMM D')
        }));
    }
    setXAxis(newXAxis);
    console.log('newXAxis: ', newXAxis);
    setIsLoading(false);

    setData(
      widget?.data?.map((obj) => {
        if (!obj?.grouping?.includes('stack')) {
          return {
            ...obj,
            stack: obj.stack + '_' + obj.dimension
          };
        } else {
          return obj;
        }
      })
    );
  }, [widget?.interval, widget?.dates, widget?.axis, widget.metrics]);
  // let xAxis = {};

  // console.log('widget?.data: ', widget?.data);

  // console.log('widget: ', widget);

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spinner />
      </div>
    );
  }
  if (!xAxis || !xAxis.length) {
    return <></>;
  }
  if (widget?.data?.[0]?.data?.length !== widget?.dates?.length) {
    // console.log('fail: widget?.data?.length: ', widget?.data?.data?.length);
    // console.log('fail: widget?.dates?.length: ', widget?.dates?.length);
    return <></>;
  }

  if (!widget?.dates?.length) {
    return <></>;
  }

  // console.log('ComboChartWidget>data: ', data);

  // // if (isLoading) {
  // //   return <div></div>;
  // // }

  // console.log('xAxis: ', xAxis);
  // console.log('widget?.data: ', widget?.data);

  // const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  // const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];

  // const xLabels = [
  //   'Page A',
  //   'Page B',
  //   'Page C',
  //   'Page D',
  //   'Page E',
  //   'Page F',
  //   'Page G'
  // ];

  // // if (['metrics'].includes(currentlyDraggingWidget?.category)) {
  // //   return (
  // //     <div
  // //       style={{
  // //         borderRadius: '5px',
  // //         width: '100%',
  // //         height: '100%',
  // //         background: 'red',
  // //         display: 'flex'
  // //       }}
  // //     ></div>
  // //   );
  // // }
  // console.log('widget.metrics: ', widget.metrics);
  return (
    // <div onMouseUpHandler={onMouseUpHandler}>

    // <div
    //   style={{
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     // justifyContent: 'space-between',
    //     justifyContent: 'space-between',
    //     alignItems: 'center'
    //     // position: 'relative',
    //     // width: widget.style.width.split('px')[0] * 0.9,
    //     // height: widget.style.height.split('px')[0] * 0.8,
    //     // padding: '0px'
    //   }}
    // >
    //   <div
    //     style={{
    //       width: '100%',
    //       display: 'flex',
    //       flexDirection: 'row',
    //       justifyContent: 'center',
    //       alignContent: 'center',
    //       fontSize: '14px',
    //       fontWeight: 'bold'
    //     }}
    //   >
    <div
      draggable={false}
      key={widget.id}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center'
        // position: 'relative',
        // width: widget.style.width.split('px')[0] * 0.9,
        // height: widget.style.height.split('px')[0] * 0.8,
        // padding: '0px'
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          top: '-1px',
          right: '-1px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '10px',
          // borderRadius: '0px 5px 0px 10px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {widget.title}
      </div> */}
      <div
        style={{
          display: 'flex'
          // padding: '0px 3px'
        }}
      >
        <BarChart
          draggable={false}
          onDrop={(e) => {
            const droppedItemString = e.dataTransfer.getData('droppedItem');
            // console.log('debug55>dropOnWidget 5');
            // console.log('debug55>droppedItemString: ', droppedItemString);
            const droppedItem = JSON.parse(droppedItemString);

            if (droppedItem?.value?.category !== 'widgets') {
              e.preventDefault();
              e.stopPropagation();
            }

            return;
          }}
          colors={colorPalette} // Use palette
          // xAxis={[
          //   new Date('2024-08-01'),
          //   new Date('2024-08-02'),
          //   new Date('2024-08-03'),
          //   new Date('2024-08-04'),
          //   new Date('2024-08-05'),
          //   new Date('2024-08-06'),
          //   new Date('2024-08-07')
          // ]}

          xAxis={xAxis}
          yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
          rightAxis="rightAxisId"
          series={
            // widget?.data
            data || []
          }
          margin={{
            left: 70,
            right: 70
            // right: 60
            // top: 80,
            // bottom: 80,
          }}
          slotProps={{
            legend: {
              hidden: (widget?.data || [])?.length > 10,
              // direction: 'column',
              // position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              padding: { top: 0, left: 10, right: 10 },
              labelStyle: {
                fontSize: 12
                //fill: 'blue'
              }
            }
          }}
          width={widget?.width.split('px')[0]}
          height={
            widget?.height.split('px')[0] -
            (widget?.dimensions?.length ? 20 : 0)
          }
        />
      </div>

      {/* <AxisMenu widget={widget} /> */}
    </div>
  );
}

export default React.memo(ComboChartWidget);
