import React, { useEffect, useContext } from 'react';

import { useInView } from 'react-intersection-observer';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import OutOfDateRangeTooltip from './OutOfDateRangeTooltip';

function IntItem({
  itemStyle,
  keyForMap,
  itemKey,
  itemValue,
  rowNumber,
  textAlignRight,
  checked
}) {
  const { exportMode, isAdvancedModeOn } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  let spanStyle = {
    margin: 'auto'
  };
  if (textAlignRight) {
    spanStyle = { textAlign: itemValue === '?' ? 'center' : 'right' };
  } else {
    spanStyle = { textAlign: itemValue === '?' ? 'center' : 'center' };
  }

  let renderValue = '';
  if (itemValue === '?') {
    renderValue = <OutOfDateRangeTooltip />;
  } else if (isNaN(itemValue) || itemValue === undefined || !itemValue) {
    renderValue = '';
  } else if (!isNaN(itemValue)) {
    renderValue = itemValue && parseInt(itemValue).toLocaleString();
  }

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'grid',
        height: '100%',
        width: '100%',
        padding: '12px 15px',
        whiteSpace: 'nowrap',
        cursor: 'default'
      }}
    >
      {(inView || exportMode) && (
        <div style={spanStyle}>
          {/* <p>{isNaN(renderValue) ? '-' : renderValue}</p> */}
          <p style={spanStyle}>{renderValue}</p>
          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </div>
      )}
    </div>
  );
}

export default IntItem;
