import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { useApolloClient } from '@apollo/client';

export const AuthContext = React.createContext();

let REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

if (window.location.host === 'nexustable-dev.herokuapp.com') {
  REACT_APP_BACKEND_URL = 'https://nexustable-dev.herokuapp.com/api';
}

export const AuthProvider = (props) => {
  const [token, setToken] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  //   const [email, setEmail] = useState('');

  const client = useApolloClient();

  const updateToken = (token) => {
    axios.defaults.headers.common['x-auth-token'] = token;
    localStorage.setItem('token', token);
    setToken(token);

    // window.wsClient && window.wsClient.close(true);
  };

  // useEffect(() => {
  //   if (token) {
  //     if (client) {
  //       client.stop();
  //     }
  //     client.onResetStore();
  //   }
  // }, [token]);

  const login = async (string) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ access_token: string });
    const res = await axios.post(REACT_APP_BACKEND_URL + '/auth', body, config);
    const resToken = res?.data?.token;

    if (resToken) {
      updateToken(resToken);
      // setIsAuthenticated(true);
    }
    await checkToken();

    // if (resToken) {
    //   updateToken(resToken);
    //   setIsAuthenticated(true);
    // }
  };

  const logout = () => {
    setUser({});
    updateToken('');
    setIsAuthenticated(false);
  };

  const checkToken = async () => {
    const currentToken = localStorage.getItem('token');

    if (currentToken) {
      axios.defaults.headers.common['x-auth-token'] = currentToken;
      try {
        const res = await axios.get(REACT_APP_BACKEND_URL + '/auth');

        if (res.data.email) {
          //   setEmail(res?.data?.email);
          setUser(res.data);
          updateToken(currentToken);
          setIsAuthenticated(true);
        }
      } catch (error) {
        logout();
      }
    }
  };
  useEffect(() => {
    if (!isAuthenticated) {
      checkToken();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        login,
        logout,
        user,
        updateToken,
        checkToken
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
