import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@emotion/react';

function formatDate(date) {
  let datePart = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    .map((n, i) => n.toString().padStart(i === 0 ? 4 : 2, '0'))
    .join('/');
  let timePart = [date.getHours(), date.getMinutes(), date.getSeconds()]
    .map((n, i) => n.toString().padStart(2, '0'))
    .join(':');
  return datePart + ' ' + timePart;
}

const PoP = {
  renderCell: ({ value }) => {
    if (value) {
      return (
        <span
          style={{
            width: '100%',
            textAlign: 'left',
            // color: value > 0 ? 'green' : value < 0 ? 'red' : 'grey',
            fontSize: '12px'
          }}
        >
          {String(value)}
          {/* {(value > 0 ? '+' : '') + value.toString() + '%'} */}
        </span>
      );
    } else {
      return <span></span>;
    }
  }
  // valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
};

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 }
// ];

export default function DataTable({
  timeboxes,
  timeboxViewDialogType,
  setFilteredTimeboxIds
}) {
  const columns = [
    //   { ...PoP, field: 'id', headerName: 'ID', width: 200 },
    {
      ...PoP,
      field: 'status',
      headerName: 'Status',
      width: 150,
      headerAlign: 'left',
      renderCell: ({ value }) => {
        if (value) {
          return (
            <span
              style={{
                width: '100%',
                textAlign: 'left',
                backgroundColor: value === 'COMPLETED' && 'rgb(0, 200, 0)',
                //   background: 'black',
                color: value === 'COMPLETED' && 'white',
                padding: '5px',
                borderRadius: '5px',
                // color: value > 0 ? 'green' : value < 0 ? 'red' : 'grey',
                fontSize: '12px'
              }}
            >
              {String(value)}
              {/* {(value > 0 ? '+' : '') + value.toString() + '%'} */}
            </span>
          );
        } else {
          return <span></span>;
        }
      }
    },

    {
      ...PoP,
      field: 'startDateTime',
      headerName: 'Start Date Time',
      width: 200,
      headerAlign: 'left'
    },
    {
      ...PoP,
      field: 'endDateTime',
      headerName: 'End Date Time',
      width: 200,
      headerAlign: 'left'
    },
    {
      ...PoP,
      field: 'deadline',
      headerName: 'Deadline',
      // type: 'number',
      width: 200,
      headerAlign: 'left'
    },
    {
      ...PoP,
      field: 'duration',
      headerName: 'Duration (min)',
      type: 'number',
      width: 150,
      headerAlign: 'left'
    },
    {
      ...PoP,
      field: 'accountName',
      headerName: 'Account Name',
      type: 'string',
      width: 200,
      // GridAlignment: 'left',
      headerAlign: 'left'
    },

    {
      ...PoP,
      field: 'text',
      headerName: 'Text',
      type: 'number',
      width: 2000,
      // GridAlignment: 'left',
      headerAlign: 'left'
    }
    //   {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //       `${params.row.firstName || ''} ${params.row.lastName || ''}`
    //   }
  ];

  if (timeboxViewDialogType === 'NEXUSDOCUMENTS') {
    columns.splice(1, 0, {
      ...PoP,
      field: 'assignedTo',
      headerName: 'Assigned To',
      type: 'string',
      width: 250,
      // GridAlignment: 'left',
      headerAlign: 'left'
    });
  }

  const theme = useTheme();
  const rows = timeboxes?.map((timebox) => ({
    ...timebox,
    startDateTime: timebox?.startDateTime
      ? formatDate(timebox?.startDateTime)
      : '',
    endDateTime: timebox?.endDateTime ? formatDate(timebox?.endDateTime) : '',
    deadline: Boolean(timebox?.deadline) ? formatDate(timebox?.deadline) : '',
    id: timebox._id
  }));

  return (
    <div
      style={{
        // height: 400,
        width: '100%',
        textAlign: 'left',
        padding: '10px',
        backdropFilter: 'blur(10px)',
        // background: 'rgba(255,255,255,0.3)'
        backgroundImage:
          theme?.palette?.mode === 'dark'
            ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
            : `linear-gradient(to top left, rgba(255,255,255,1), ${'rgba(255, 255, 255, 0.5)'})`
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 }
          }
        }}
        pageSizeOptions={[5, 10, 100]}
        checkboxSelection
        onSelectionModelChange={(props) => {
          console.log('debug310>props: ', props);
        }}
        onStateChange={(e) => {
          console.log(
            'debug310>e.pagination.rowCount: ',
            e.pagination.rowCount
          );
          console.log('debug310>e: ', e);

          const filteredIds = [];
          for (const [key, value] of Object.entries(
            e?.filter?.filteredRowsLookup || {}
          )) {
            console.log(`${key}: ${value}`);
            if (value) {
              filteredIds.push(key);
            }
          }

          setFilteredTimeboxIds(filteredIds);
        }}
      />
    </div>
  );
}
