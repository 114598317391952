const mongoose = require('mongoose');
const Schema = mongoose.Schema;

const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

const {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} = require('date-fns');

const { allEvalFields } = require('./../fields');

const action = {
  // dataSource: String,
  // dataSourceRef: {
  //   type: String
  //   // default: 'GoogleAdsAdGroupConversionData'
  // },
  // name: String,
  // dataSourceId: String,
  // dataSourceAccountId: String,

  name: {
    type: String
  }

  // customDateRange: {
  //   type: Boolean,
  //   default: false
  // },
  // manualDataEntry: {
  //   name: {
  //     type: String
  //   }
  // },
  // googleAds: {
  //   type: {
  //     type: String
  //   },
  //   id: {
  //     type: String
  //   },
  //   name: {
  //     type: String
  //   }
  // },
  // facebookAds: {
  //   type: {
  //     type: String
  //   },
  //   id: {
  //     type: String
  //   },
  //   name: {
  //     type: String
  //   },
  //   attributionWindow: {
  //     type: String
  //   },
  //   attributionWindowName: {
  //     type: String
  //   }
  // },
  // googleAnalytics4: {
  //   name: String,
  //   // attribution:
  //   propertyId: {
  //     type: String
  //   },
  //   eventName: {
  //     operator: {
  //       type: String
  //       // default: 'contains'
  //     },
  //     value: String
  //   },

  //   source: {
  //     operator: {
  //       type: String
  //       // default: 'contains'
  //     },
  //     value: String
  //   },
  //   medium: {
  //     operator: {
  //       type: String
  //       // default: 'contains'
  //     },
  //     value: String
  //   },
  //   campaignName: {
  //     operator: {
  //       type: String
  //       // default: 'contains'
  //     },
  //     value: String
  //   },
  //   campaignId: {
  //     operator: {
  //       type: String
  //       // default: 'contains'
  //     },
  //     value: String
  //   }
  // }
  // calculatedPaceYesterdayOverPaceThreshold: Number,
  // calculatedPaceYesterdayUnderPaceThreshold: Number
};

const evalFields = {};
for (let field of allEvalFields) {
  evalFields[field] = {
    type: String,
    name: field
  };
}

const AccountSchema = new Schema(
  {
    ...evalFields,
    name: {
      type: String,
      required: true
    },
    status: {
      type: String
    },
    logo: {
      type: String
    },
    timeZone: String,
    weekType: String,

    // date: {
    //   type: Date,
    //   default: Date.now
    // }
    documentType: String,

    members: [
      {
        type: mongoose.Schema.Types.ObjectId,
        ref: 'User'
      }
    ],

    action01: action,
    action02: action,
    action03: action,
    totalAction01: action,
    totalAction02: action,
    totalAction03: action,

    style: {
      gantt: {
        bar: {
          backgroundColor: {
            type: String
          }
        }
      }
    },

    nexusDocuments: [
      {
        type: mongoose.Schema.Types.ObjectId,
        ref: 'NexusDocument'
      }
    ],
    globalTasksId: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'NexusDocument'
    },
    cachedAt: Date,
    cacheOutdated: Boolean,
    lastInteractionAt: Date
  },
  { timestamps: true }
);

AccountSchema.virtual('timeZoneOffset').get(function () {
  return getOffset(this.timeZone || 'UTC');
});

AccountSchema.virtual('accountId').get(function () {
  return this._id;
});

AccountSchema.methods._startDate = function (timeZoneOffset) {
  if (!timeZoneOffset) {
    return this?.startDate?.toISOString().split('T')?.[0];
  }
  const today = new Date();
  today.setMinutes(today.getMinutes() + timeZoneOffset);

  switch (this.dateType) {
    case 'STATIC':
      return this.startDate?.toISOString().split('T')[0];
    case 'DYNAMIC_WEEK_MONDAY_SUNDAY':
      var newDate = startOfWeek(today, {
        weekStartsOn: 1
      });
      newDate.setMinutes(newDate.getMinutes() + timeZoneOffset);
      return newDate?.toISOString().split('T')[0];
    case 'DYNAMIC_WEEK_SUNDAY_SATURDAY':
      var newDate = startOfWeek(today);
      newDate.setMinutes(newDate.getMinutes() + timeZoneOffset);
      return newDate?.toISOString().split('T')[0];
    case 'DYNAMIC_MONTH':
      var newDate = startOfMonth(today);
      newDate.setMinutes(newDate.getMinutes() + timeZoneOffset);
      return newDate?.toISOString().split('T')[0];
    default:
      return this?.startDate?.toISOString().split('T')?.[0];
  }
};

AccountSchema.methods._endDate = function (timeZoneOffset) {
  if (!timeZoneOffset) {
    return this?.endDate?.toISOString()?.split('T')?.[0];
  }
  const today = new Date();
  today.setMinutes(today.getMinutes() + timeZoneOffset);

  switch (this.dateType) {
    case 'STATIC':
      return this?.endDate?.toISOString()?.split('T')[0];
    case 'DYNAMIC_WEEK_MONDAY_SUNDAY':
      var newDate = endOfWeek(today, {
        weekStartsOn: 1
      });
      newDate.setMinutes(newDate.getMinutes() + timeZoneOffset);
      return newDate?.toISOString().split('T')[0];
    case 'DYNAMIC_WEEK_SUNDAY_SATURDAY':
      var newDate = endOfWeek(today);
      newDate.setMinutes(newDate.getMinutes() + timeZoneOffset);
      return newDate?.toISOString().split('T')[0];
    case 'DYNAMIC_MONTH':
      var newDate = endOfMonth(today);
      newDate.setMinutes(newDate.getMinutes() + timeZoneOffset);
      return newDate?.toISOString().split('T')[0];
    default:
      return this?.endDate?.toISOString()?.split('T')?.[0];
  }
};

module.exports = Account = mongoose.model('Account', AccountSchema);
