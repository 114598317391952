import React, { useState, useContext, useEffect } from 'react';

import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { AccountContext } from '../../../../../../context/AccountContext';

import { useTheme } from '@mui/material';

import Select from './Select';

import SaveButton from './SaveButton';
import DeleteButton from './DeleteButton';

import SelectUsers from './SelectUsers';

function DataSourceAlertRule({
  dataSourceInfo,
  dataSourceAlertRule,
  refetch,
  users
}) {
  const theme = useTheme();
  const { currentManagerAccount } = useContext(AccountContext);

  const [dataSourceAlertRuleInput, setDataSourceAlertRuleInput] = useState({});

  const [isEditMode, toggleIsEditMode] = useState(false);

  useEffect(() => {
    setDataSourceAlertRuleInput({ ...dataSourceAlertRule });
  }, [dataSourceAlertRule]);

  // console.log('dataSourceAlertRuleInput: ', dataSourceAlertRuleInput);

  return (
    <div
      style={{
        marginBottom: '15px',
        width: '100%',
        border:
          theme?.palette?.mode === 'dark'
            ? '1px solid rgba(0,0,0,0.1'
            : '1px solid white',
        // color: 'white',
        background:
          theme?.palette?.mode === 'dark' ? 'rgba(0,0,0,0.1' : 'white',
        borderRadius: '5px',
        padding: '10px'
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '410px'
          }}
        >
          <Select
            state={dataSourceAlertRuleInput}
            setState={setDataSourceAlertRuleInput}
            stateKey={'type'}
            options={[{ value: 'custom', name: 'Custom' }]}
            width={'200px'}
            label={'Type'}
            disabled={!isEditMode}
          />

          <Select
            state={dataSourceAlertRuleInput}
            setState={setDataSourceAlertRuleInput}
            stateKey={'status'}
            options={[
              { value: 'ACTIVE', name: 'Active' },
              { value: 'INACTIVE', name: 'Inactive' }
            ]}
            width={'200px'}
            label={'Status'}
            disabled={!isEditMode}
          />
        </div>

        {isEditMode ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '170px'
            }}
          >
            <DeleteButton
              dataSourceAlertRule={dataSourceAlertRule}
              refetch={refetch}
            />
            <Button
              size="small"
              variant="outlined"
              style={{
                fontSize: '12px',
                // visibility: isEditMode && 'hidden',

                width: '40px',
                maxWidth: '60px',
                minWidth: '60px',
                height: '30px',
                padding: '5px'
              }}
              onClick={() => {
                setDataSourceAlertRuleInput({ ...dataSourceAlertRule });
                toggleIsEditMode(!isEditMode);
              }}
            >
              Cancel
            </Button>
            <SaveButton
              toggleIsEditMode={toggleIsEditMode}
              refetch={refetch}
              dataSourceInfo={dataSourceInfo}
              dataSourceAlertRule={dataSourceAlertRule}
              dataSourceAlertRuleInput={dataSourceAlertRuleInput}
              setDataSourceAlertRuleInput={setDataSourceAlertRuleInput}
            />
            {/* <Button
              size="small"
              variant="contained"
              style={{
                fontSize: '12px',
                // visibility: isEditMode && 'hidden',
                width: '40px',
                maxWidth: '60px',
                minWidth: '60px',
                height: '30px',
                padding: '5px'
              }}
              onClick={() => toggleIsEditMode(!isEditMode)}
            >
              Save
            </Button> */}
          </div>
        ) : (
          <Button
            size="small"
            style={{
              visibility: isEditMode && 'hidden',
              width: '30px',
              maxWidth: '30px',
              minWidth: '30px',
              height: '30px',
              padding: '5px'
            }}
            onClick={() => toggleIsEditMode(!isEditMode)}
          >
            <EditIcon style={{ fontSize: '16px' }} />
          </Button>
        )}
      </div>
      <div style={{ width: '100%', marginTop: '15px' }}>
        <SelectUsers
          users={users}
          dataSourceAlertRuleInput={dataSourceAlertRuleInput}
          setDataSourceAlertRuleInput={setDataSourceAlertRuleInput}
          disabled={!isEditMode}
        />
      </div>
    </div>
  );
}

export default DataSourceAlertRule;
