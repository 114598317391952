import React, { useEffect, useState } from 'react';

import SimpleSelectActionsGoogleAnalytics4 from './SimpleSelectActions';
import SimpleSelectContainsOrEquals from './SimpleSelectContainsOrEquals';
import FreeSolo from './FreeSolo';
import FreeSoloActionType from './FreeSoloActionType';

import { useQuery, useMutation } from '@apollo/client';

import { GoogleAnalytics4ParametersQuery } from './../../../../../../graphql/queries';

function GoogleAnalytics4Selects({
  label,
  value,
  setValue,
  default_value,
  currentManagerAccountId,
  item,
  itemKey,
  actionType,
  setActionType,
  eventName,
  setEventName,
  source,
  setSource,
  medium,
  setMedium,
  campaignName,
  setCampaignName,
  campaignId,
  setCampaignId,
  setInputValue,
  inputValue,
  action,
  setAction,
  dataSourceAccountId
}) {
  const { data } = useQuery(GoogleAnalytics4ParametersQuery, {
    variables: {
      googleAnalytics4ParametersInput: {
        managerAccountId: currentManagerAccountId,
        accountId: item.accountId,
        dataSourceAccountId
      }
    },
    fetchPolicy: 'no-cache',
    skip: false
  });

  const googleanalytics4parameters =
    data && data.googleanalytics4parameters
      ? data.googleanalytics4parameters
      : {
          eventNames: [],
          campaignNames: [],
          campaignIds: [],
          sources: [],
          mediums: []
        };

  // const { eventNames, campaignNames, campaignIds, sources, mediums } =
  //   googleanalytics4parameters;

  // const [firstUserCampaignNameOperator, setFirstUserCampaignNameOperator] =
  //   useState('equals');
  // const [firstUserMediumOperator, setFirstUserMediumOperator] =
  //   useState('equals');
  // const [firstUserSourceOperator, setFirstUserSourceOperator] =
  //   useState('equals');
  // const [sessionCampaignNameOperator, setSessionCampaignNameOperator] =
  //   useState('equals');
  // const [sessionMediumOperator, setSessionMediumOperator] = useState('equals');
  // const [sessionSourceOperator, setSessionSourceOperator] = useState('equals');
  // const [eventNameOperator, setEventNameOperator] = useState('equals');

  const [operators, setOperators] = useState({});

  const parameters = [
    'eventName',
    'firstUserCampaignName',
    'firstUserMedium',
    'firstUserSource',
    'sessionCampaignName',
    'sessionMedium',
    'sessionSource'
  ];

  useEffect(() => {
    const newOperators = {};
    for (let parameter of parameters) {
      newOperators[parameter] = 'EQUALS';
    }
    setOperators(newOperators);
  }, []);

  const [inputs, setInputs] = useState({});

  // useEffect(() => {
  //   const googleAnalytics4 = inputValue?.googleAnalytics4
  //     ? inputValue?.googleAnalytics4
  //     : {};

  //   setAction({
  //     ...action,
  //     name: eventName,
  //     googleAnalytics4: {
  //       ...googleAnalytics4,
  //       eventName: { operator: eventNameOperator, value: eventName },
  //       medium: { operator: mediumOperator, value: medium },
  //       source: { operator: sourceOperator, value: source },
  //       campaignId: { operator: campaignIdOperator, value: campaignId },
  //       campaignName: { operator: campaignNameOperator, value: campaignName }
  //     }
  //   });
  // }, [
  //   action
  //   // eventName,
  //   // source,
  //   // medium,
  //   // campaignId,
  //   // campaignName,
  //   // eventNameOperator,
  //   // sourceOperator,
  //   // mediumOperator,
  //   // campaignNameOperator,
  //   // campaignIdOperator
  // ]);

  return (
    <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
      {/* <SimpleSelectActionsGoogleAnalytics4
        currentManagerAccountId={currentManagerAccountId}
        item={item}
        label={'Action Type'}
        default_value={item[itemKey] && item[itemKey].type}
        // menuItems={action_types}
        value={actionType}
        setValue={setActionType}
      /> */}
      {/* <FreeSoloActionType
        label={'Event Name'}
        width={400}
        margin={'0px 8px'}
        options={eventNames}
        value={actionType}
        setValue={setActionType}
      /> */}

      {/* <FreeSolo
        label={'Event Name'}
        width={400}
        margin={'0px 8px'}
        options={eventNames}
        value={eventName}
        setValue={setEventName}
      /> */}
      {parameters.map((parameter) => (
        <div style={{ padding: '10px 16px 10px 8px' }}>
          <div
            style={{ fontSize: '12px', fontWeight: 'bold', marginLeft: '10px' }}
          >
            {parameter}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              // gridTemplateColumns: '120px auto',

              columnGap: '20px'
            }}
          >
            <div style={{ width: '120px' }}>
              <SimpleSelectContainsOrEquals
                parameterKey={parameter}
                label={parameter}
                operator={operators[parameter]}
                operators={operators}
                setOperator={setOperators}
                action={action}
                setAction={setAction}
              />
            </div>
            <div style={{ width: '100%' }}>
              <FreeSolo
                parameterKey={parameter}
                label={' '}
                // width={291}
                // margin={'0px 11px'}
                options={googleanalytics4parameters[parameter] || []}
                inputs={inputs}
                setInputs={setInputs}
                action={action}
                setAction={setAction}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default GoogleAnalytics4Selects;
