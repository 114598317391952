import React, { useState } from 'react';

import BasicDatePicker from './../utils/BasicDatePicker';

import { Button } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import DateRangePicker from './date-range/DateRangePicker';

import StatusMenu from './StatusMenu';

function DateRange({ index, dateRanges, setDateRanges, sudoRawCopyChecked }) {
  // const [rangeStatus, setRangeStatus] = useState('READY');

  const onSetValueHandler = (key, newValue) => {
    console.log('newValue: ', newValue);

    const copyOfDateRanges = [...dateRanges];
    // copyOfDateRanges.splice(index, 1);
    copyOfDateRanges[index][key] = newValue;

    // if (
    //   new Date(copyOfDateRanges[index].startDate) >
    //   new Date(copyOfDateRanges[index].endDate)
    // ) {
    //   return;
    // }

    console.log('copyOfDateRanges: ', copyOfDateRanges);
    setDateRanges(copyOfDateRanges);
  };

  const onClickRemoveDateRangeHandler = () => {
    if (dateRanges?.length > 1) {
      const copyOfDateRanges = [...dateRanges];
      copyOfDateRanges.splice(index, 1);
      console.log('copyOfDateRanges: ', copyOfDateRanges);
      setDateRanges(copyOfDateRanges);
    }
  };

  const rangeStatus = dateRanges[index]?.status;
  const setRangeStatus = (status) => {
    onSetValueHandler('status', status);
  };

  return (
    <div
      style={{
        margin: '8px 0px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%'
      }}
    >
      <div
        style={{
          minWidth: '120px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <div style={{ width: 'fit-content' }}>
          <StatusMenu
            showText={true}
            rangeStatus={rangeStatus}
            setRangeStatus={setRangeStatus}
          />
        </div>
      </div>
      <div style={{ transform: 'scale(0.8', width: '160px' }}>
        <BasicDatePicker
          size={'small'}
          disabled={sudoRawCopyChecked}
          //   value={startDate}
          value={dateRanges?.[index].startDate}
          //   setValue={setStartDate}
          setValue={(newValue) => onSetValueHandler('startDate', newValue)}
          label="Start date"
        />
      </div>
      <div style={{ transform: 'scale(0.8)', width: '160px' }}>
        <BasicDatePicker
          disabled={sudoRawCopyChecked}
          value={dateRanges?.[index].endDate}
          //   value={endDate}
          //   setValue={setEndDate}
          setValue={(newValue) => onSetValueHandler('endDate', newValue)}
          label="End date"
        />
      </div>
      <div style={{ paddingRight: '7px' }}>
        <DateRangePicker
          value={dateRanges?.[index]}
          //   setValue={setStartDate}
          onSetValueHandler={onSetValueHandler}
        />
      </div>
      <div>
        <Button
          style={{
            background: 'red',
            width: '40px',
            height: '40px',
            minWidth: '40px'
          }}
          onClick={onClickRemoveDateRangeHandler}
        >
          <DeleteIcon style={{ color: 'white' }} />
        </Button>
      </div>
    </div>
  );
}

export default DateRange;
