import React from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

export default function SimpleSelect({ time, setTime }) {
  // const classes = useStyles();
  //   const [time, setTime] = React.useState('');

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  return (
    <div>
      <FormControl
        style={{
          minWidth: 120
        }}
        // className={classes.formControl}
      >
        {/* <InputLabel id="demo-simple-select-label">Intervall</InputLabel> */}
        <Select
          size="small"
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={time}
          defaultValue={time}
          onChange={handleChange}
        >
          <MenuItem value={'day'}>Day</MenuItem>
          <MenuItem value={'week'}>Week (Mon - Sun)</MenuItem>
          <MenuItem value={'month'}>Month</MenuItem>
        </Select>
        {/* <FormHelperText>{time}</FormHelperText> */}
      </FormControl>
    </div>
  );
}
