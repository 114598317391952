import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import DoneIcon from '@mui/icons-material/Done';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

import { managerAccountUsersQuery } from './../../../graphql/queries';

import { useQuery } from '@apollo/client';

import { AuthContext } from '../../../context/AuthContext';

import Checkbox from '@mui/material/Checkbox';

import { FilterContext } from '../../../context/FilterContext';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'center',
//     flexWrap: 'wrap',
//     '& > *': {
//       margin: theme.spacing(0.5)
//     }
//   }
// }));

export default function Chips({
  // filterByMemberIds,
  setFilterBySelection,
  setFilters,
  setCheckedBoxes,
  currentManagerAccountId,
  // user,
  filters
  // setFilterByMemberIds
}) {
  const { filterByMemberIds, setFilterByMemberIds } = useContext(FilterContext);
  const { user } = useContext(AuthContext);
  // const classes = useStyles();

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
    // setFilterBySelection([]);

    const filteredFilter = filters.filter((filter) => {
      return filter !== 'filter_by_team';
    });
    setFilters(filteredFilter);
    setCheckedBoxes([]);
  };

  const variables = {
    managerAccountUsersInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId
      //   accountId
    }
  };

  const { data } = useQuery(managerAccountUsersQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: false
  });

  // const handleClick = () => {
  //   console.info('You clicked the Chip.');
  // };

  // ############################ autocomplete #####################################

  const [showAutoComplete, toggleShowAutoComplete] = useState(false);

  // let managerAccountUsers;

  const [managerAccountUsers, setManagerAccountUsers] = useState([]);

  useEffect(() => {
    if (data && data.managerAccountUsers) {
      // console.log('data.managerAccountUsers: ', data.managerAccountUsers);
      // managerAccountUsers = data.managerAccountUsers;
      const userList = [...data.managerAccountUsers];
      userList.sort(function (a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      setManagerAccountUsers(userList);
    }
  }, [data]);

  // const [value, setValue] = React.useState(null);
  const [value, setValue] = React.useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('debug922>row116');
    if (managerAccountUsers.length && filterByMemberIds?.length) {
      console.log('debug922>row117');
      const filteredUsers = managerAccountUsers.filter((obj) =>
        filterByMemberIds.includes(obj._id)
      );
      setValue(filteredUsers);
      // if (filteredUsers && filteredUsers._id !== value?._id) {
      //   setValue(filteredUsers);
      // }
    } else if (isLoading && !filterByMemberIds?.length) {
      console.log('debug922>row125');
      setFilterByMemberIds([user._id]);
      setValue([user]);
      setIsLoading(false);
    }
  }, [managerAccountUsers, filterByMemberIds]);

  // useEffect(() => {
  //   if (managerAccountUsers && filterByMemberId) {
  //     const filteredUser = managerAccountUsers.filter(
  //       (obj) => obj._id === filterByMemberId
  //     )[0];
  //     if (filteredUser && value && filteredUser._id !== value._id) {
  //       setValue(filteredUser);
  //     }
  //   }
  // }, [filterByMemberId]);

  const defaultProps = {
    options: managerAccountUsers,
    getOptionLabel: (option) => option.name
  };

  // useEffect(() => {
  //   setFilterByMemberId(user._id);
  // }, []);

  const onChangeHandler = (event, newValue) => {
    setValue(newValue);
    if (newValue) {
      setFilterByMemberIds(newValue.map((obj) => obj._id));
    }
  };

  // const autoComplete = (
  //   <Autocomplete
  //     className="filter-by-team-member"
  //     {...defaultProps}
  //     id="auto-highlight"
  //     autoHighlight
  //     value={value}
  //     onChange={(event, newValue) => onChangeHandler(event, newValue)}
  //     style={{ width: '200px', height: '30px' }}
  //     renderInput={(params) => <TextField {...params} margin="none" />}
  //   />
  // );

  let renderTag = '';
  switch (true) {
    case value?.length > 4:
      renderTag = `${value?.length} users selected`;
      break;
    // case value?.length > 3:
    //   renderTag = value?.map((obj) => obj?.name?.slice(0, 5)).join('... , ');
    //   break;
    case value?.length > 1:
      renderTag = value?.map((obj) => obj?.name?.split(' ')[0]).join(', ');
      break;
    case value?.length === 1:
      renderTag = value?.[0]?.name;
      break;
    // case value?.length === 0 || !value?.length:
    //   renderTag = 'Please select user';
    //   break;
  }
  const autoComplete = (
    <Stack
      spacing={0}
      sx={{ width: 300, height: 70, position: 'relative', bottom: '-22px' }}
    >
      <Autocomplete
        disableCloseOnSelect
        limitTags={1}
        size="small"
        multiple
        {...defaultProps}
        id="controlled-demo"
        className="filter-by-team-member"
        value={value}
        onChange={(event, newValue) => {
          onChangeHandler(event, newValue);
        }}
        renderTags={() => renderTag}
        ChipProps={{ style: { width: '100px' } }}
        getOptionLabel={(option) => option.name + ' ' + option.email}
        renderInput={(params) => (
          <TextField
            {...params}
            // style={{ fontSize: '4px' }}
            // label="controlled"
            variant="standard"
          />
        )}
        // renderOption={(props, option, { selected }) => (
        //   <div
        //     key={option._id}
        //     {...props}
        //     style={{
        //       display: 'flex',
        //       flexDirection: 'column',
        //       alignItems: 'start'
        //     }}
        //   >
        //     <span>{option.name}</span>
        //     <span style={{ fontSize: '11px' }}>{option.email}</span>
        //   </div>
        // )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.email}
          </li>
        )}
      />
    </Stack>
  );

  // ##################################################################################

  return (
    <div
      // className={classes.root}
      style={{ gridRow: 1 }}
    >
      <Chip
        icon={<FaceIcon />}
        // avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
        // label="Me"
        label={autoComplete}
        onDelete={handleDelete}
        // label="Primary clickable"
        // clickable
        // deleteIcon={<DoneIcon />}
      />
    </div>
  );
}
