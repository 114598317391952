import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     width: '93%'
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

export default function SimpleSelect({
  label,
  value,
  setValue,
  // default_value,
  currentManagerAccountId,
  item,
  itemKey,
  action,
  setAction
}) {
  // const classes = useStyles();

  const defaultValue = item[itemKey]?.strossle?.type;

  const menuItems = [
    // { name: 'Conversions', type: 'conversions', id: 'conversions' }
    // { name: 'Complete Payment', type: 'complete_payment' }
  ];

  menuItems.sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const [input, setInput] = useState('');

  const handleChange = (event) => {
    let selectedItem = menuItems.filter(
      (obj) => event.target.value === obj.type
    )[0];

    setInput(selectedItem?.type);
    // setValue(selectedItem);
    setAction({
      ...action,
      // name: selectedItem?.name,
      strossle: {
        name: selectedItem?.name,
        type: selectedItem?.type,
        id: selectedItem?.id
      }
    });
  };

  useEffect(() => {
    if (!value) {
      // let selectedItem = menuItems.filter(
      //   (obj) => defaultValue === obj.type
      // )[0];

      setInput(defaultValue);
      // setValue(selectedItem);
    }
  }, [defaultValue, menuItems]);

  return (
    <div>
      <FormControl
        style={{
          margin: '10px',
          minWidth: 120,
          width: '93%'
        }}
        // className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          variant="standard"
          style={{ fontSize: '14px' }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={action?.strossle?.type}
          onChange={handleChange}
        >
          <MenuItem style={{ fontSize: '14px' }} value="">
            <em>None</em>
          </MenuItem>
          {menuItems.map((menuItem, i) => (
            <MenuItem
              key={i}
              style={{ fontSize: '14px' }}
              value={menuItem.type}
            >
              {menuItem.name}
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Some important helper text</FormHelperText> */}
      </FormControl>
    </div>
  );
}
