import React, { useState } from 'react';

import MicrosoftInvestUserIdList from './MicrosoftInvestUserIdList.js';
// import TokenList from './TokenList';
import { Button } from '@mui/material';

function TokenScreen({
  selectedTokenId,
  setSelectedTokenId,
  setShowScreen,
  microsoftInvestUsers,
  setMicrosoftInvestUsers
}) {
  const [refetchCounter, setRefetchCounter] = useState(0);
  const refetchTokenList = () => {
    setRefetchCounter(refetchCounter + 1);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <MicrosoftInvestUserIdList
          refetchTokenList={refetchTokenList}
          setShowScreen={setShowScreen}
          microsoftInvestUsers={microsoftInvestUsers}
          setMicrosoftInvestUsers={setMicrosoftInvestUsers}
        />

        {/* <Button onClick={() => setShowScreen('add_token_screen')}>
          Add new token
        </Button> */}
      </div>
    </div>
  );
}

export default TokenScreen;
