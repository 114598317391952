import React, { useState, useEffect, useContext } from 'react';

import { useTheme } from '@emotion/react';

import { Switch } from '@mui/material';

import Slider from './Slider';

import Button from '@mui/material';

import AddButton from './AddButton';

import { useMutation, useLazyQuery } from '@apollo/client';

import {
  dataSourceAlertRulesQuery,
  managerAccountUsersQuery
} from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';

import DataSourceAlertRule from './data-source-alert-rule/DataSourceAlertRule';

function DataSourceAlertRules({ dataSourceInfo }) {
  const theme = useTheme();
  const { currentManagerAccount } = useContext(AccountContext);
  const [checked, setChecked] = useState(false);

  const [dataSourceAlertRules, setDataSourceAlertRules] = useState([]);
  const [users, setUsers] = useState([]);

  // ############## load data source alert rules #############

  const variables = {
    input: {
      managerAccountId: currentManagerAccount._id,
      dataSourceId: dataSourceInfo._id
    }
  };

  const [loadDataSourceAlertRules, { called, loading, data, refetch }] =
    useLazyQuery(dataSourceAlertRulesQuery, { variables });

  useEffect(() => {
    if (dataSourceInfo?._id) {
      loadDataSourceAlertRules();
    }
  }, [currentManagerAccount?._id, dataSourceInfo?._id]);

  useEffect(() => {
    if (data?.dataSourceAlertRules) {
      setDataSourceAlertRules(data?.dataSourceAlertRules);
    }
  }, [data]);
  // console.log('dataSourceAlertRules: ', dataSourceAlertRules);

  // ############### load users ####################

  const [loadUsers, { data: usersData, refetch: refetchUsers }] = useLazyQuery(
    managerAccountUsersQuery,
    {
      variables: {
        managerAccountUsersInput: {
          managerAccountId: currentManagerAccount._id
        }
      },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (dataSourceAlertRules?.length && !users?.length) {
      loadUsers();
    }
  }, [dataSourceAlertRules]);

  useEffect(() => {
    let listOfUsers = usersData?.managerAccountUsers || [];
    if (listOfUsers?.length) {
      setUsers(listOfUsers);
    }
  }, [usersData]);

  // console.log('DataSourceAlertRules>users: ', users);

  // ###############################################

  return (
    <div style={{ width: '100%', margin: '3vw auto', maxWidth: '760px' }}>
      <div
        style={{
          // height: '200px',
          width: '100%',
          background:
            theme?.palette?.mode === 'dark'
              ? 'rgba(245, 245, 245,.1)'
              : 'rgb(245, 245, 245)',
          borderRadius: '5px',
          padding: '25px 30px'
        }}
      >
        <div
          style={{
            // background: 'red',
            width: '100%',
            // height: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ fontSize: '18px' }}>Data Source Alerts</div>
          <AddButton dataSourceInfo={dataSourceInfo} refetch={refetch} />
        </div>
        <div style={{ marginTop: '15px' }}>
          {dataSourceAlertRules.map((dataSourceAlertRule) => (
            <DataSourceAlertRule
              users={users}
              dataSourceAlertRule={dataSourceAlertRule}
              dataSourceInfo={dataSourceInfo}
              refetch={refetch}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default DataSourceAlertRules;
