function requireUserRole(userRole, authorizedRoles) {
  if (!authorizedRoles.length || !userRole) {
    throw new Error('Unauthorized');
  }
  if (!authorizedRoles.includes(userRole)) {
    throw new Error('Unauthorized');
  }
}

module.exports = RequireUserRole = requireUserRole;
