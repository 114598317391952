import React, { useContext } from 'react';

import { v4 as uuidv4 } from 'uuid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';

import { MutationContext } from '../../../../../context/MutationContext';

import { AccountContext } from '../../../../../context/AccountContext';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';

import DeleteIcon from '@mui/icons-material/Delete';

import CropDinIcon from '@mui/icons-material/CropDin';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Crop75Icon from '@mui/icons-material/Crop75';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import NoteAddIcon from '@mui/icons-material/NoteAdd';

import FileCopyIcon from '@mui/icons-material/FileCopy';

import { SnackbarProvider, useSnackbar } from 'notistack';

// import DateTimePicker from './DateTimePicker';

// import SliderMenuItem from './SliderMenuItem';

export default function DenseMenu({
  mouseDownReportPageIds,
  // selectedUser,
  // reportPageIdThatTriggeredContextMenu,
  // setReportPageIdThatTriggeredContextMenu,
  // reportPages,
  setContextMenuAnchorPosition,
  handleClose
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    selectedPageId,
    reportPages,
    updateReportPage,
    setReportPages,
    reportPageIdThatTriggeredContextMenu,
    updateReportPages
  } = useContext(ReportBuilderContext);

  // console.log('test4467>reportPages: ', reportPages);
  // console.log(
  //   'test4467>reportPageIdThatTriggeredContextMenu: ',
  //   reportPageIdThatTriggeredContextMenu
  // );

  // console.log(
  //   'reportPageIdThatTriggeredContextMenu: ',
  //   reportPageIdThatTriggeredContextMenu
  // );
  // console.log('reportPages123: ', reportPages);

  const reportPage = reportPages.filter(
    (reportPage) => reportPage.id === reportPageIdThatTriggeredContextMenu
  )[0];
  // const {
  //   addReportPages,
  //   updateReportPages,
  //   refetchReportPagesCounter,
  //   setRefetchReportPagesCounter
  // } = useContext(MutationContext);
  let statuses = [];

  switch (true) {
    case reportPage?.status === 'TIMEBOX_LIST':
      statuses = [{ name: 'Removed', value: 'REMOVED' }];
      break;
    case [
      'UNDEFINED',
      'DRAFT',
      'ACTIVE',
      'COMPLETED',
      'NOT_COMPLETED'
    ].includes(reportPage?.status):
      statuses = [
        { name: 'Undefined', value: 'UNDEFINED' },
        // { name: 'ReportPageList', value: 'TIMEBOX_LIST' },
        // { name: 'Draft', value: 'DRAFT' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Completed', value: 'COMPLETED' },
        // { name: 'Not completed', value: 'NOT_COMPLETED' },
        { name: 'Removed', value: 'REMOVED' }
      ];
      break;
  }

  const onClickMenuItem = async (e, action, value) => {
    switch (action) {
      // case 'CHANGE_STATUS':
      //   var input = {
      //     managerAccountId: currentManagerAccount._id,
      //     // selectedUserId: selectedUser?._id,
      //     updateKeys: ['status'],
      //     // reportPages: [{ _id: reportPageIdThatTriggeredContextMenu, status: value }]
      //     reportPages: mouseDownReportPageIds.length
      //       ? mouseDownReportPageIds.map((reportPageId) => ({
      //           _id: reportPageId,
      //           status: value
      //         }))
      //       : [{ _id: reportPageIdThatTriggeredContextMenu, status: value }]
      //   };

      //   console.log('update!!!');

      //   await updateReportPages({ variables: { input } });
      //   setContextMenuAnchorPosition(null);
      //   break;

      // case 'DELETE':
      // var input = {
      //   managerAccountId: currentManagerAccount._id,
      //   // selectedUserId: selectedUser?._id,
      //   updateKeys: ['nexusDocumentIds', 'accountId'],

      //   reportPages: mouseDownReportPageIds.length
      //     ? mouseDownReportPageIds.map((reportPageId) => ({
      //         _id: reportPageId,
      //         accountId: null,
      //         accountIds: [],
      //         nexusDocumentIds: []
      //       }))
      //     : [
      //         {
      //           _id: reportPageIdThatTriggeredContextMenu,
      //           accountId: null,
      //           accountIds: [],
      //           nexusDocumentIds: []
      //         }
      //       ]
      // };

      // console.log('update!!!');

      // await updateReportPages({ variables: { input } });
      // setContextMenuAnchorPosition(null);

      // case 'TOGGLE_CARD':
      //   var copyOfReportPages = [...reportPages];
      //   var index = copyOfReportPages.findIndex(
      //     (reportPage_) =>
      //       reportPage_._id === reportPageIdThatTriggeredContextMenu
      //   );
      //   updateReportPage(
      //     { ...reportPage, isShowingCard: !reportPage.isShowingCard },
      //     index
      //   );
      //   handleClose();
      //   break;

      case 'TOGGLE_FREEZE_DATA':
        var copyOfReportPages = [...reportPages];
        var index = copyOfReportPages.findIndex(
          (reportPage_) =>
            reportPage_.id === reportPageIdThatTriggeredContextMenu
        );
        updateReportPage({
          ...reportPage,
          isDataFrozen: !reportPage.isDataFrozen
        });
        handleClose();
        break;
      // case 'BRING_TO_FRONT':
      //   var copyOfReportPages = [...reportPages];
      //   var index = copyOfReportPages.findIndex(
      //     (reportPage_) =>
      //       reportPage_.id === reportPageIdThatTriggeredContextMenu
      //   );

      //   console.log('index: ', index);
      //   copyOfReportPages.splice(index, 1);
      //   copyOfReportPages.push(reportPage);
      //   setReportPages(copyOfReportPages);
      //   handleClose();
      //   break;
      // case 'SEND_TO_BACK':
      //   var copyOfReportPages = [...reportPages];
      //   var index = copyOfReportPages.findIndex(
      //     (reportPage_) =>
      //       reportPage_.id === reportPageIdThatTriggeredContextMenu
      //   );

      //   console.log('index: ', index);
      //   copyOfReportPages.splice(index, 1);
      //   copyOfReportPages.unshift(reportPage);
      //   setReportPages(copyOfReportPages);
      //   handleClose();
      //   break;

      case 'CREATE_NEW_REPORT_PAGE':
        var copyOfReportPages = [...reportPages];
        var index = copyOfReportPages.findIndex(
          (reportPage_) =>
            reportPage_.id === reportPageIdThatTriggeredContextMenu
        );

        const newPage = {
          id: uuidv4(),
          status: 'ACTIVE',
          layout: 'LAYOUT_WIDE',
          width: '1920px',
          height: '1080px',
          backgroundColor: '#ffffff',
          widgets: []
        };
        copyOfReportPages.splice(index + 1, 0, newPage);
        setReportPages(copyOfReportPages);
        handleClose();
        break;

      case 'DUPLICATE_REPORT_PAGE':
        // JSON.parse(JSON.stringify(ingredientsList))

        var copyOfReportPages = [...reportPages];
        var index = copyOfReportPages.findIndex(
          (reportPage_) =>
            reportPage_.id === reportPageIdThatTriggeredContextMenu
        );

        const newCopyOfPage = JSON.parse(
          JSON.stringify(copyOfReportPages[index])
        );

        newCopyOfPage.id = uuidv4();

        for (let widget of newCopyOfPage?.widgets || []) {
          widget.id = uuidv4();
        }

        console.log('index: ', index);
        copyOfReportPages.splice(index + 1, 0, newCopyOfPage);
        setReportPages(copyOfReportPages);
        handleClose();
        break;

      case 'DELETE':
        if (reportPages?.length === 1) {
          enqueueSnackbar(
            'Please create a new page before deleting the last one',
            {
              variant: 'error'
            }
          );
          return;
        }
        var copyOfReportPages = [...reportPages];
        var index = copyOfReportPages.findIndex(
          (reportPage_) =>
            reportPage_.id === reportPageIdThatTriggeredContextMenu
        );

        console.log('index: ', index);
        copyOfReportPages.splice(index, 1);
        setReportPages(copyOfReportPages);
        handleClose();
        break;
    }
  };
  if (!reportPage) {
    return <></>;
  }
  return (
    <Paper sx={{ width: 200 }}>
      <MenuList dense>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              marginRight: '5px',
              height: '1px',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.12)'
            }}
          ></div>
          <span style={{ fontSize: '12px' }}>Edit</span>
          <div
            style={{
              marginLeft: '5px',
              height: '1px',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.12)'
            }}
          ></div>
          {/* <Divider /> */}
        </div>
        {/* <MenuItem onClick={(e) => onClickMenuItem(e, 'BRING_TO_FRONT')}>
          <ListItemIcon>
            <FlipToFrontIcon />
          </ListItemIcon>
          <ListItemText>Bring to front</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => onClickMenuItem(e, 'SEND_TO_BACK')}>
          <ListItemIcon>
            <FlipToBackIcon />
          </ListItemIcon>
          <ListItemText>Send to back</ListItemText>
        </MenuItem> */}
        {/* <Divider /> */}

        <MenuItem onClick={(e) => onClickMenuItem(e, 'CREATE_NEW_REPORT_PAGE')}>
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText>Create New Page</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => onClickMenuItem(e, 'DUPLICATE_REPORT_PAGE')}>
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => onClickMenuItem(e, 'DELETE')}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>

        {/* <MenuItem
          onClick={(e) => onClickMenuItem(e, 'TOGGLE_CARD')}
          style={
            {
              // background: reportPage.isShowingCard && 'rgba(150,150,150,0.2)'
            }
          }
        >
          <ListItemIcon>
            <Crop75Icon />
          </ListItemIcon>
          <ListItemText>Show card</ListItemText>
      
          {reportPage.isShowingCard ? (
            <CheckBoxIcon style={{ fontSize: '16px' }} />
          ) : (
            <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
          )}
 
        </MenuItem> */}

        {selectedPageId === reportPageIdThatTriggeredContextMenu && (
          <>
            <Divider />
            <MenuItem
              onClick={(e) => onClickMenuItem(e, 'TOGGLE_FREEZE_DATA')}
              // style={{ background: reportPage.isDataFrozen && 'rgba(150,150,150,0.2)' }}
            >
              <ListItemIcon>
                <AcUnitIcon />
              </ListItemIcon>
              <ListItemText>Freeze data</ListItemText>
              {/* <ListItemIcon> */}
              {reportPage.isDataFrozen ? (
                <CheckBoxIcon style={{ fontSize: '16px' }} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
              )}
              {/* </ListItemIcon> */}
            </MenuItem>
          </>
        )}

        {/* <MenuItem>
          <ListItemText>Changelog</ListItemText>
        </MenuItem> */}
        {/* <MenuItem>
          <ListItemText>Add space after paragraph</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText>Custom spacing...</ListItemText>
        </MenuItem> */}
      </MenuList>
    </Paper>
  );
}
