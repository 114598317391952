import React, { useState, useEffect } from 'react';

export const StyleContext = React.createContext();

export const StyleProvider = (props) => {
  const [kanbanStyle, updateKanbanStyle] = useState([]);

  const setKanbanStyle = (style) => {
    updateKanbanStyle(style);
  };

  const [customStyleSelected, setCustomStyleSelected] = useState({});

  useEffect(() => {
    const localStorageCustomStyleSelected = localStorage.getItem(
      'customStyleSelected'
    );

    if (localStorageCustomStyleSelected) {
      setCustomStyleSelected(
        JSON.parse(localStorage.getItem('customStyleSelected'))
      );
    } else {
      const newSelected = {
        checkBoxLeftBorderColor: 'classic', //'accountColor',
        iconColor: 'accountColor' // 'classic' // 'ganttBarColor'
      };
      localStorage.setItem('customStyleSelected', JSON.stringify(newSelected));
      setCustomStyleSelected(newSelected);
    }
  }, []);

  return (
    <StyleContext.Provider
      value={{
        kanbanStyle,
        setKanbanStyle,
        customStyleSelected,
        setCustomStyleSelected
      }}
    >
      {props.children}
    </StyleContext.Provider>
  );
};
