import React, { useState, useContext, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import TextField from '@mui/material/TextField';

import { columnDictionary } from './columns';

import { ColumnContext } from '../../../context/ColumnContext';

import { columnArray, categories } from './columns';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     margin: 'auto'
//   },
//   paper: {
//     width: 200,
//     height: 230,
//     overflow: 'auto',
//     backgroundColor: 'rgba(0,0,0,0)'
//   },
//   button: {
//     margin: theme.spacing(0.5, 0)
//   }
// }));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({
  category,
  left,
  setLeft,
  right,
  setRight
}) {
  const { columns, setColumns } = useContext(ColumnContext);

  // const classes = useStyles();
  const [checked, setChecked] = useState([]);
  // const [left, setLeft] = useState([columnArray]);
  // const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // useEffect(() => {

  //   if (columns) {
  //     setRight([...columns].sort());
  //     setLeft(columnArray.filter((col) => !columns.includes(col)).sort());
  //   }
  // }, [columns]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  //   const handleAllRight = () => {
  //     setRight(right.concat(left));
  //     setLeft([]);
  //   };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked).sort());
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  //   const handleAllLeft = () => {
  //     setLeft(left.concat(right));
  //     setRight([]);
  //   };

  const dragStart = (e, value) => {
    const target = e.target;
    e.dataTransfer.setData('droppedItem', value);
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const drop = (e, value, list) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const droppedItem = droppedItemString;
    let newRight = right.filter((col) => col !== droppedItem);
    let newLeft = left.filter((col) => col !== droppedItem);
    let indexOfLandingItem = -1;

    if (value) {
      if (list === 'right') {
        indexOfLandingItem = newRight.indexOf(value);

        newRight.splice(indexOfLandingItem, 0, droppedItem);
      }

      if (list === 'left') {
        indexOfLandingItem = newLeft.indexOf(value);

        newLeft.splice(indexOfLandingItem, 0, droppedItem);
      }
    } else {
      if (list === 'right') {
        newRight.push(droppedItem);
      }
      if (list === 'left') {
        newLeft.push(droppedItem);
      }
    }

    setRight(newRight);
    setLeft(newLeft);

    const droppedItemElement = document.getElementById(droppedItem);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items, list) => (
    <Paper
      onDrop={(e) => drop(e, null, list)}
      onDragOver={(e) => dragOver(e, null)}
      // onDragStart={(e) => dragStart(e, null)}
      // onDragEnd={(e) => dragEnd(e, null)}
      // className={classes.paper}
      style={{
        width: '100%',
        height: '100%',
        border: '1px solid rgba(255,255,255,0.1)',
        overflowY: 'scroll'
      }}
    >
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              style={{ cursor: 'grab' }}
              id={value}
              key={value}
              value={value}
              role="listitem"
              onDrop={(e) => drop(e, value, list)}
              onDragOver={(e) => dragOver(e, value)}
              draggable={true}
              onDragStart={(e) => dragStart(e, value)}
              onDragEnd={(e) => dragEnd(e, value)}
            >
              <ListItemIcon onClick={handleToggle(value)}>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${columnDictionary[value]}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  const [search, setSearch] = useState('');
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const leftAfterCatergory = left.filter((value) => {
    return categories[category].includes(value);
    console.log('categories[category]: ', categories[category]);
    // switch (category) {
    //   case 'ALL':
    //     return true;

    //   default:
    //     categories[category].includes(value);
    // }
  });
  // console.log('category: ', category);
  // console.log('categories[category]: ', categories[category]);

  // console.log('leftAfterCatergory: ', leftAfterCatergory);
  // console.log('left: ', left);

  const filteredLeft = leftAfterCatergory.filter((value) =>
    (columnDictionary[value] || '').toLowerCase().includes(search.toLowerCase())
  );

  const buttonStyle = {
    margin: '5px'
  };

  return (
    <>
      <div style={{ display: 'grid', gridTemplateRows: '75px 525px' }}>
        <div style={{ gridRow: 1 }}>
          <div style={{ marginLeft: '25px' }}>
            <TextField
              variant="standard"
              value={search}
              onChange={onChangeSearch}
              id="standard-basic"
              label="Search"
            />
          </div>
        </div>
        <div style={{ gridRow: 2 }}>
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            // className={classes.root}
            style={{ height: '95%' }}
          >
            <Grid
              item
              style={{
                width: '43%',
                height: '100%'
                // height: '40vw'
              }}
            >
              {customList(filteredLeft, 'left')}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  style={buttonStyle}
                  variant="outlined"
                  size="small"
                  // className={classes.button}
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  style={buttonStyle}
                  variant="outlined"
                  size="small"
                  // className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  style={buttonStyle}
                  variant="outlined"
                  size="small"
                  // className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  style={buttonStyle}
                  variant="outlined"
                  size="small"
                  // className={classes.button}
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                width: '43%',
                height: '100%'
                // height: '40vw'
              }}
            >
              {customList(right, 'right')}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
