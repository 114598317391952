import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import { useSnackbar } from 'notistack';

import { addCommentMutation } from './../../../../graphql/queries';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { AuthContext } from '../../../../context/AuthContext';

import { AccountContext } from '../../../../context/AccountContext';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import CommentIcon from '@mui/icons-material/Comment';

import { styled, useTheme } from '@mui/material/styles';

const createNameAbb = (name) => {
  let nameAbb = '';

  const nameParts = name.split(' ');

  for (let i = 0; i < nameParts.length; i++) {
    nameAbb = nameAbb + nameParts[i][0];
  }
  return nameAbb;
};

function AddComment({ scrollToBottom, item }) {
  const { user } = useContext(AuthContext);
  const { currentManagerAccountId } = useContext(AccountContext);
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const [input, setInput] = useState('');

  const onChangeHandler = (e) => {
    setInput(e.target.value);
    scrollToBottom();
  };

  const onKeyUpHandler = (e) => {
    if (e.keyCode === 13) {
    }
  };

  const [addComment, { called }] = useMutation(addCommentMutation);

  const nameAbb = user?.name ? createNameAbb(user?.name) : '';

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (!input) {
      console.log('empty input');
      return;
    }

    // const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ\n _,.-]*$');
    // const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ\n _.,+-/\\*()=:]*$');
    const regExName = new RegExp(
      '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ\n\t -_.,+-/\\*()=:#%&:]*$'
    );
    const regExNameResult = regExName.exec(input);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ _.,+-/*()=:#%&: are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    console.log('item._members: ', item._members);
    const finalInput = {
      action: 'add_comment',
      managerAccountId: currentManagerAccountId,
      accountId: item.accountId,
      documentId: item._id,
      level: item.level,

      documentType: item.documentType,
      newsFeedItemDocumentType: 'comment',
      commentType: 'child',
      comment: {
        documentId: item._id,
        text: input
      }
    };

    addComment({ variables: { addCommentInput: finalInput } });
    setInput('');
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginRight: '20px'
        }}
      >
        <CommentIcon
          style={{
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.3)'
                : '#36529c87'
          }}
        />
      </div>
      <div
        style={{
          //   display: 'flex',
          //   flexDirection: 'row',
          //   justifyContent: 'center',
          //   alignItems: 'center',
          background:
            theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.1)'
              : 'rgba(255,255,255,0.9)',
          backdropFilter: 'blur(10px)',
          padding: '5px 20px 25px 20px',
          borderRadius: '5px',
          width: '100%',
          margin: '3px',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              //   fontSize: '11px',
              backgroundColor: item.accountColor || '#a72683',
              //   color: 'white',
              //   padding: '3px 7px',
              //   // margin: '2px 0px 10px 0px',
              borderRadius: '5px',
              //   width: 'fit-content',
              //   maxWidth: '200px',
              //   textOverflow: 'ellipsis',
              display: 'flex',
              flexDirection: 'row',
              columnGap: '10px',

              position: 'relative',
              top: '-15px'
              // marginLeft: '60px'
              // fontWeight: 'bold'
            }}
          >
            <div
              style={{
                fontSize: '11px',
                // backgroundColor: commentObj.accountColor || '#a72683',
                color: 'white',
                padding: '3px 7px',
                // margin: '2px 0px 10px 0px',
                borderRadius: '5px',
                width: 'fit-content',
                maxWidth: '200px',
                height: 'fit-content',
                textOverflow: 'ellipsis'
              }}
            >
              <span>{item?.accountName}</span>
            </div>
            {/* {commentObj?.labels?.map((obj, index) => (
              <div
                key={index}
                style={{
                  fontSize: '11px',
                  backgroundColor: obj.color || '#a72683',
                  color: 'white',
                  padding: '3px 7px',
                  // margin: '2px 0px 10px 0px',
                  borderRadius: '5px',
                  width: 'fit-content',
                  maxWidth: '200px',
                  height: 'fit-content',
                  textOverflow: 'ellipsis'
                }}
              >
                <span>{obj.name}</span>
              </div>
            ))} */}
          </div>
          {/* <div
            style={{
              height: '22.5px',
              position: 'relative',
              right: '-10px'
            }}
          >
            <CardMenu
              commentObj={commentObj}
              item={{ ...commentObj, _id: commentObj.documentId }}
              showTextField={showTextField}
              toggleShowTextField={toggleShowTextField}
            />
          </div> */}
        </div>
        <div
          style={{
            // borderRadius: '5px',
            // border: '1px solid grey',
            // margin: '0px 20px 20px 20px',
            // padding: '10px',
            display: 'flex',
            flexDirection: 'column'
            // background: 'white'
            // width: '600px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
              //   alignItems: 'center'
            }}
          >
            <Avatar
              style={{
                // marginTop: '15px',
                height: '35px',
                width: '35px',
                fontSize: '12px',
                color: 'white',
                background: `
linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)
`
              }}
            >
              {nameAbb}
            </Avatar>
            <div style={{ padding: '0px 20px 20px 20px', width: '100%' }}>
              <div>{user.name}</div>
              <form
                style={{ width: '100%', marginTop: '10px' }}
                // onSubmit={(e) => onSubmitHandler(e)}
              >
                <TextField
                  onKeyUp={(e) => onKeyUpHandler(e)}
                  onChange={(e) => onChangeHandler(e)}
                  style={{ width: '100%' }}
                  id="outlined-multiline-static"
                  label="Multiline - type here!"
                  multiline
                  value={input}
                  //   rows={4}
                  //   defaultValue="Default Value"
                  default={input}
                  variant="outlined"
                />
              </form>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              width: '100%',

              borderRadius: '5px'
              // border: '1px solid grey',
              //   margin: '10px 20px 20px 20px'
              // padding: '10px'
            }}
          >
            {/* <div
        style={{
          borderRadius: '5px',
          //   border: '1px solid grey',
          margin: '20px'
          //   padding: '10px'
        }}
      > */}

            <div style={{ textAlign: 'right', marginRight: '20px' }}>
              <Button
                size="small"
                // style={{ marginTop: '20px' }}
                type="submit"
                variant="outlined"
                onClick={(e) => onSubmitHandler(e)}
              >
                Submit
              </Button>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddComment;
