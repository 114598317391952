import React from 'react';
import Fab from '@mui/material/Fab';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddIcon from '@mui/icons-material/Add';
// import { makeStyles } from '@mui/styles';

import dv360Logo from './dv360logo.svg';

// const useStyles = makeStyles((theme) => ({
//     root: {
//       "& > *": {
//         margin: theme.spacing(1),
//         borderRadius: "5px",
//         height: '20px',
//         width: '37px',
//         backgroundColor: "rgba(0, 181, 8, 1)",
//         "&:hover": {
//           background: "rgba(20, 201, 28, 1)"
//         }
//       }
//     },
//     extendedIcon: {
//       color: 'white',
//       marginRight: theme.spacing(1)
//     }
//   }));

function GoogleAdsButton({ onClickHandler }) {
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     '& > *': {
  //       // margin: theme.spacing(1),
  //       borderRadius: '5px',
  //       height: '20px',
  //       width: '37px',
  //       // backgroundColor: '#1877F2',
  //       backgroundColor: 'white',
  //       '&:hover': {
  //         // backgroundColor: '#1877F2',
  //         backgroundColor: 'white',
  //         //   background: 'rgba(105,109,114,1)'
  //         boxShadow:
  //           '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.32)'
  //       }
  //     }
  //   },
  //   extendedIcon: {
  //     color: 'white',
  //     marginRight: theme.spacing(1)
  //   }
  // }));

  // const classes = useStyles();

  return (
    <div
    // className={classes.root}
    >
      <Fab
        className="text-transform-unset"
        // ref={anchorRef}
        // aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="add"
        style={{
          borderRadius: '5px',
          height: '60px',
          minHeight: '60px',
          width: '250px',
          minWidth: '250px',
          // backgroundColor: true
          //     ? 'rgba(0, 181, 8, 0.9)'
          //     : 'rgba(0, 181, 8, 0.3)',
          color: 'black',
          fontSize: '14px'
          // fontWeight: '800'
        }}
        onClick={(e) => onClickHandler(e, 'dv360')}
      >
        <img src={dv360Logo} alt="Logo" style={{ width: '20px' }} />
        <span style={{ color: 'black', marginLeft: '7px' }}>
          Display Video 360
        </span>
      </Fab>
    </div>
  );
}

export default GoogleAdsButton;
