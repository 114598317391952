import React, { useContext } from 'react';
// import WarningPopover from './WarningPopover';
import WarningTooltip from './WarningTooltip';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../context/InterfaceContext';

function WarningItem({
  itemKey,
  rowNumber,
  item,
  warningColumnLeft,
  index,
  stylesStoredInRedux,
  leftShadow,
  checked
}) {
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });
  const itemLevel = 1;
  // ##############################################

  // const dummyWarnings = [
  //   {
  //     path: '/Adams Shirt Store/Plan 2020 Q4/Paid Social/Facebook/',
  //     name: 'Prospecting',
  //     headline: 'No Costing',
  //     message: 'No costing'
  //   },
  //   {
  //     path: '/Adams Shirt Store/Plan 2020 Q4/Paid Social/Facebook/',
  //     name: 'Prospecting',
  //     headline: 'No Costing',
  //     message: 'No costing'
  //   },
  // ];

  // const dummyWarnings = [
  //   {
  //     path: '/Adams Shirt Store/Plan 2020 Q4/Paid Social/Facebook/',
  //     name: 'Prospecting',
  //     headline: 'No Costing',
  //     messages: [{ message: 'No costing' }]
  //   },
  //   {
  //     path: '/Adams Shirt Store/Plan 2020 Q4/Paid Social/Facebook/',
  //     name: 'Prospecting',
  //     headline: 'No Costing',
  //     messages: [{ message: 'No costing' }]
  //   }
  // ];

  return (
    <div
      ref={ref}
      key={item._id + '_' + itemKey + '_' + index}
      style={{
        maxHeight: '46px',
        zIndex: '1000',
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: checked
          ? stylesStoredInRedux.RoiTable.Table.itemStyle.rowStyle[itemLevel]
              .checkedBackgroundColor
          : stylesStoredInRedux.RoiTable.Table.itemStyle.rowStyle[itemLevel]
              .backgroundColor,
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 0px',
        // borderColor: 'rgba(200, 200, 200, 0.5)',
        borderColor:
          stylesStoredInRedux.RoiTable.Table.itemStyle.rowStyle[itemLevel]
            .borderColor,
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        position: 'sticky',
        left: warningColumnLeft + 'px',
        display: 'grid',
        height: '100%',
        width: '46px',
        whiteSpace: 'nowrap'
        // padding: '10px 20px'
      }}
    >
      {(inView || exportMode) && (
        <>
          <span
            style={
              {
                // margin: 'auto'
                //   margin: 'auto auto auto 15px'
              }
            }
          >
            {/* {item['warning'] &&
          item['warning'].map((obj) => {
            return <div>{obj}</div>;
          })} */}
            {/* {item['warning'] && item['warning'].length > 0 && (
          // <WarningPopover warnings={item['warning']} />
          // <WarningTooltip warnings={item['warning']} />
          <WarningTooltip warnings={dummyWarnings} />
        )} */}
            <WarningTooltip
              warnings={item.warnings}
              subWarnings={item.subWarnings}
              stylesStoredInRedux={stylesStoredInRedux}
            />
          </span>
          {leftShadow && (
            <div
              style={{
                right: '-6px',
                // right: '0px',
                width: '5px',
                position: 'absolute',
                background:
                  '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y',
                // cursor: 'col-resize',
                // backgroundColor: 'red',
                userSelect: 'none',
                height: '100%',
                zIndex: '4000'
              }}
            ></div>
          )}
        </>
      )}
    </div>
  );
}

export default React.memo(WarningItem);
