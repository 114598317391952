import React, { useContext } from 'react';
import { Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { AuthContext } from '../../context/AuthContext';
import { AccountContext } from '../../context/AccountContext';

import AccountTreeIcon from '@mui/icons-material/AccountTree';

import Button from '@mui/material/Button';

import { useSnackbar } from 'notistack';

import IsAdvancedModeOnSwitch from './IsAdvancedModeOnSwitch';
import IsKPIWarningsOnSwitch from './IsKPIWarningsOnSwitch';
import IsInvoiceWarningsOnSwitch from './IsInvoiceWarningsOnSwitch';

import IsDemoModeOnSwitch from './IsDemoModeOnSwitch';

import { InterfaceContext } from '../../context/InterfaceContext';

export default function NavUserMenu() {
  const { resetSelectedAccountOptions, setResetSelectedAccountOptions } =
    useContext(InterfaceContext);
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // custom

  const { user, logout } = useContext(AuthContext);
  const {
    currentManagerAccount,
    setCurrentManagerAccount,
    setCurrentManagerAccountId,
    managerAccounts,
    setCurrentAccountIds
  } = useContext(AccountContext);

  let navigate = useNavigate();

  const onClickOnNavManagerAccountHandler = (e, managerAccount) => {
    setCurrentManagerAccountId(managerAccount._id);
    setCurrentManagerAccount(managerAccount);

    if (managerAccount?._id !== currentManagerAccount?._id) {
      setCurrentAccountIds([]);
      setResetSelectedAccountOptions(resetSelectedAccountOptions + 1);
    }
    navigate(`/dashboard?manager_account_id=${managerAccount._id}`);
    handleClose();
  };

  const logoutHandler = () => {
    logout();
    handleClose();
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        {/* <Tooltip title="Manager Accounts and Settings"> */}
        <Button
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <span
            style={{ textAlign: 'right', height: '37px' }}
            className="hide-sm"
          >
            {' '}
            <span style={{ verticalAlign: 'bottom', fontSize: '0.9rem' }}>
              {user ? user?.firstName : ''}
            </span>
            <div
              style={{
                fontSize: 8,
                verticalAlign: 'top'
              }}
            >
              {currentManagerAccount?.name}
            </div>
          </span>{' '}
          <Avatar
            sx={{
              width: 32,
              height: 32,
              marginLeft: '10px',
              fontSize: '12px',
              background: `
            linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
            linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
            linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)
            
            `,
              // color: '#f7f7f7',
              // border: '1px solid rgba(255,255,255,1)'
              border: '1px solid rgba(255,255,255,0.3)',
              color: 'white'
            }}
          >
            {user?.firstName[0] + user?.lastName[0]}
          </Avatar>
        </Button>
        {/* </Tooltip> */}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflowY: 'scroll',
            // overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}

        {managerAccounts.map((managerAccount) => (
          <MenuItem
            key={managerAccount._id}
            onClick={(e) =>
              onClickOnNavManagerAccountHandler(e, managerAccount)
            }
          >
            <ListItemIcon>
              <AccountTreeIcon fontSize="small" />
            </ListItemIcon>
            {managerAccount.name}
          </MenuItem>
        ))}

        <Divider />
        {/* <MenuItem> */}
        <IsAdvancedModeOnSwitch />
        <IsKPIWarningsOnSwitch />
        <IsInvoiceWarningsOnSwitch />
        {['system_admin'].includes(user.role) && <IsDemoModeOnSwitch />}

        {/* </MenuItem> */}

        <Divider />
        {['admin'].includes(currentManagerAccount.role) ? (
          <Link
            to={`${'/manager-account-settings/users'}?manager_account_id=${
              currentManagerAccount._id
            }`}
          >
            <MenuItem>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
          </Link>
        ) : (
          <MenuItem
            onClick={() => {
              enqueueSnackbar('Permission denied', {
                // variant: 'error'
              });
            }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
        )}

        <MenuItem onClick={logoutHandler}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
