import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StorageIcon from '@mui/icons-material/Storage';

import { getDataSourceOAuthUrlMutation } from '../../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import MicrosoftInvestLoginButton from './MicrosoftInvestLoginButton';

// import googleAdsLogo from './ads-logo-horizontal-white.png';

import { AccountContext } from '../../../../../../context/AccountContext';

import TermsAndConditions from './TermsAndConditions';

import TokenListScreen from './choose-token-screen/TokenListScreen';

import AddUserAndPassScreen from './add-user-and-pass-screen/AddUserAndPass';

import SelectMicrosoftInvestUserIdScreen from './select-microsoft-invest-user-id-screen/SelectMicrosoftInvestUserIdScreen';

import AccountListScreen from './account-list-screen/AccountListScreen';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ height: '620px', width: '700px' }} />
    </Draggable>
  );
}

export default function DraggableDialog({
  open,
  setOpen,
  // refetchAccountList,
  refetechDataSources
  // currentManagerAccount
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  //   const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [addDataSource, { called }] = useMutation(
    getDataSourceOAuthUrlMutation
  );
  const [selectedTokenId, setSelectedTokenId] = useState('');

  const [microsoftInvestUsers, setMicrosoftInvestUsers] = useState([]);

  console.log('microsoftInvestUsers: ', microsoftInvestUsers);
  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  // const onClickHandler = async (e, dataSource) => {
  //   const addDataSourceInput = {
  //     managerAccountId: currentManagerAccount._id,
  //     dataSource
  //   };

  //   const res = await addDataSource({
  //     variables: { input: addDataSourceInput }
  //   });

  //   if (
  //     res &&
  //     res.data &&
  //     res.data.getDataSourceOAuthUrl &&
  //     res.data.getDataSourceOAuthUrl.response &&
  //     res.data.getDataSourceOAuthUrl.url
  //   ) {
  //     window.location.replace(res.data.getDataSourceOAuthUrl.url);
  //   }
  // };

  const [showScreen, setShowScreen] = useState('terms_and_conditions');

  const onClickHandler = (screen) => {
    setShowScreen(screen);
  };

  const handleClose = () => {
    setShowScreen('terms_and_conditions');
    setOpen(false);
  };

  let content = <div></div>;
  switch (showScreen) {
    case 'terms_and_conditions':
      content = <TermsAndConditions setShowScreen={setShowScreen} />;
      break;

    case 'token_list_screen':
      content = (
        <TokenListScreen
          selectedTokenId={selectedTokenId}
          setSelectedTokenId={setSelectedTokenId}
          setShowScreen={setShowScreen}
        />
      );
      break;
    case 'add_user_and_pass':
      content = (
        <AddUserAndPassScreen
          selectedTokenId={selectedTokenId}
          setSelectedTokenId={setSelectedTokenId}
          setShowScreen={setShowScreen}
          microsoftInvestUsers={microsoftInvestUsers}
          setMicrosoftInvestUsers={setMicrosoftInvestUsers}
          setOpen={setOpen}
        />
      );
      break;
    case 'select_microsoft_invest_user_id':
      content = (
        <SelectMicrosoftInvestUserIdScreen
          selectedTokenId={selectedTokenId}
          setSelectedTokenId={setSelectedTokenId}
          setShowScreen={setShowScreen}
          microsoftInvestUsers={microsoftInvestUsers}
          setMicrosoftInvestUsers={setMicrosoftInvestUsers}
        />
      );
      break;
    case 'account_list':
      content = (
        <AccountListScreen
          selectedTokenId={selectedTokenId}
          refetechDataSources={refetechDataSources}
          setOpen={setOpen}
          setShowScreen={setShowScreen}
          setSelectedTokenId={setSelectedTokenId}
        />
      );
      break;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <StorageIcon
            style={{
              verticalAlign: 'text-bottom',
              paddingBottom: '1px',
              fontSize: '24px'
            }}
          />{' '}
          <span style={{ marginLeft: '3px' }}>
            Connect Microsoft Invest Advertisers
          </span>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </div>
  );
}
