import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
// import GraphModal from './sparklineItem/GraphModal';
import TimelineIcon from '@mui/icons-material/Timeline';
import HistoryIcon from '@mui/icons-material/History';

import CommentIcon from '@mui/icons-material/Comment';

// import CommentDraggableDialog from './comment/CommentDraggableDialog';
import DescriptionIcon from '@mui/icons-material/Description';

import { useSnackbar } from 'notistack';

// import SimpleToolTipWithChildren from './SimpleTootipWithChildren';
// import ReportGraphModal from './../../report/ReportGraphModal';

// import TemplateIcon from './template-icon/TemplateIcon';

// import InfoModalIcon from './InfoModalIcon';

import { DialogContext } from '../../../../context/DialogContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { useInView } from 'react-intersection-observer';

import { AuthContext } from '../../../../context/AuthContext';

import { MutationContext } from '../../../../context/MutationContext';

// import Hyperlink from './Hyperlink';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function NameItem({
  dataLevel,
  // updateDocument,
  currentManagerAccountId,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  openRows,
  checked
}) {
  const { exportMode } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { enqueueSnackbar } = useSnackbar();

  // ################### update #######################

  const { user } = useContext(AuthContext);

  const { updateDocument } = useContext(MutationContext);

  const {
    graphDialogOpen,
    setGraphDialogOpen,
    newsFeedDialogOpen,
    setNewsFeedDialogOpen,
    setItemId,
    setItemKey
  } = useContext(DialogContext);

  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = () => {
    toggleShowTextField(true);
  };

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(item.name);
  }, [showTextField, toggleShowTextField]);

  // const [openDataGraphModal, setOpenDataGraphModal] = useState(false);

  const [openCommentModal, setOpenCommentModal] = useState(false);

  const [openReportGraphModal, setOpenReportGraphModal] = useState(false);

  let fiberManualRecordIconColor;

  if (
    [
      'folder',
      'task',
      'subTask',
      'taxonomy',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'microsoft_invest_insertion_order',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group'
    ].includes(item.type)
  ) {
    switch (item.status) {
      case 'ACTIVE':
        // case 'DONE':
        fiberManualRecordIconColor = 'green';
        break;
      case 'DONE':
      case 'COMPLETED':
        fiberManualRecordIconColor = 'rgba(0,128,0,0.4)';
        break;
      case 'REMOVED':
        fiberManualRecordIconColor = 'red';
        break;

      case 'DRAFT':
        fiberManualRecordIconColor = '#bcbcbc';
        break;
      case 'PAUSED':
        fiberManualRecordIconColor = 'rgb(62, 142, 208)';
        break;
      default:
        fiberManualRecordIconColor = 'orange';
        break;
    }
  }
  // ##############################################

  // const input = {
  //   managerAccountId: currentManagerAccountId,
  //   accountId: item.accountId ? item.accountId : '',
  //   planId: item.planId ? item.planId : '',
  //   categoryId: item.categoryId ? item.categoryId : '',
  //   campaignId: item.campaignId ? item.campaignId : '',
  //   name: inputValue
  // };

  let indent = 0;
  // switch (item.level) {
  //   case 1:
  //     indent = 0;
  //     break;
  //   case 2:
  //     indent = 7;
  //     break;
  //   case 3:
  //     indent = 14;
  //     break;
  //   case 4:
  //     indent = 21;
  //     break;
  //   case 5:
  //     indent = 28;
  //     break;
  //   case 6:
  //     indent = 35;
  //     break;
  //   case 7:
  //     indent = 42;
  //     break;
  //   case 8:
  //     indent = 49;
  //     break;
  //   case 9:
  //     indent = 56;
  //     break;
  //   case 10:
  //     indent = 63;
  //     break;

  //   // case 'adAccountData':
  //   //   input.actionType = 'update_adAccountData_name';
  //   //   break;
  // }
  switch (item.level) {
    case 1:
      indent = 0;
      break;
    case 2:
      indent = 10;
      break;
    case 3:
      indent = 20;
      break;
    case 4:
      indent = 30;
      break;
    case 5:
      indent = 40;
      break;
    case 6:
      indent = 50;
      break;
    case 7:
      indent = 60;
      break;
    case 8:
      indent = 70;
      break;
    case 9:
      indent = 80;
      break;
    case 10:
      indent = 90;
      break;

    // case 'adAccountData':
    //   input.actionType = 'update_adAccountData_name';
    //   break;
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();

    const regExName = new RegExp(
      '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ -_.,+-/\\|*()=:#%&:]*$'
    );
    const regExNameResult = regExName.exec(inputValue);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØúÚ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    if (
      inputValue.length < 2 &&
      ![
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(item.type)
    ) {
      enqueueSnackbar(
        'Name too short. Minimum length 2 characters. ' +
          inputValue.length +
          '/100 used',
        {
          variant: 'error'
        }
      );

      return console.log('name too short.');
    }

    if (inputValue.length > 200) {
      enqueueSnackbar('Name too long. ' + inputValue.length + '/200 used', {
        variant: 'error'
      });

      return console.log('name too long.');
    }

    if (inputValue === item.name) {
      toggleShowTextField(false);
      return '';
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          name: inputValue
        }
      ],
      updateKeys: ['name']
    };

    updateDocument({ variables: { input: { ...documentInput } } });

    toggleShowTextField(false);
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const textField = (
    <form
      onSubmit={onSubmitHandler}
      onBlur={onSubmitHandler}
      // className={classes.root}
      noValidate
      autoComplete="off"
    >
      <TextField
        autoFocus
        variant="standard"
        id="standard-basic"
        value={inputValue}
        inputProps={{
          min: 0,
          style: { padding: '0px', fontSize: '12px' }
        }}
        // onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
      />
      {/* <TextField id="filled-basic" label="Filled" variant="filled" />
    <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </form>
  );

  // ##################################################

  const [showItems, toggleShowItems] = useState(false);

  const toggleShowItemsHandler = (e) => {
    toggleShowItems(!showItems);
    showItemsHandler(e, item, showItems);
  };

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        zIndex: '1000',
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 0px',
        // borderColor: 'rgba(200, 200, 200, 0.5)',
        borderColor: itemStyle['borderColor'],
        // borderBottomColor: checked ? '#00adb5' : itemStyle['borderColor'],
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        position: 'sticky',
        left: '92px',
        // display: 'grid',
        display: 'flex',
        height: '100%',
        minHeight: '46px',
        width: '100%',
        // minWidth: '200px',
        // whiteSpace: 'nowrap',
        // overflowX: 'hidden',
        // padding: '10px 20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // paddingLeft: indent + 'px',
        paddingLeft: '15px',
        // justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {item.name}
    </div>
  );
}

export default React.memo(NameItem);
