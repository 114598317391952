import { createContext } from 'react';
// import { split, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink
} from '@apollo/client';

import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

let httpUrl = 'https://api.nexustable.com/graphql';
let wsUrl = 'wss://api.nexustable.com/graphql';

if (window.location.host === 'nexustable-dev.herokuapp.com') {
  httpUrl = 'https://nexustable-dev.herokuapp.com/graphql';
  wsUrl = 'wss://nexustable-dev.herokuapp.com/graphql';
}

let localhostHttpUrl = process.env.REACT_APP_LOCAL_HOST_HTTP_URL;
let localhostWsUrl = process.env.REACT_APP_LOCAL_HOST_WS_URL;

if (window.location.host === 'nexustable-dev.herokuapp.com') {
  localhostHttpUrl = 'https://nexustable-dev.herokuapp.com/graphql';
  localhostWsUrl = 'wss://nexustable-dev.herokuapp.com/graphql';
}

export function getAccessToken() {
  return localStorage.getItem('token');
}

let restartRequestedBeforeConnected = false;
let gracefullyRestart = () => {
  restartRequestedBeforeConnected = true;
};

const httpLink = ApolloLink.from([
  new ApolloLink((operation, forward) => {
    const token = getAccessToken();
    if (token) {
      operation.setContext({ headers: { 'x-auth-token': token } });
      // operation.setContext({ headers: { authorization: `Bearer ${token}` } });
    }
    return forward(operation);
  }),
  new HttpLink({
    uri: window.location.hostname === 'localhost' ? localhostHttpUrl : httpUrl
  })
]);

const wsLink = new GraphQLWsLink(
  createClient({
    url: window.location.hostname === 'localhost' ? localhostWsUrl : wsUrl, //'wss://localhost:5000/graphql', //wsUrl

    lazy: true,
    reconnect: true,
    // retryAttempts: 100,
    connectionParams: async () => {
      console.log('proof things are happening!');
      const accessToken = await getAccessToken();
      // console.log('connectionParams>accessToken: ', accessToken);
      return {
        authentication: accessToken //await getAccessToken()
      };
    },

    on: {
      closed: () => {
        console.log('ws>closed');
        window.WSMessage('Disconnected');
      }, // connection rejected, probably not supported
      connected: (socket) => {
        gracefullyRestart = () => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.close(4205, 'Client Restart');
          }
        };

        // just in case you were eager to restart
        if (restartRequestedBeforeConnected) {
          restartRequestedBeforeConnected = false;
          gracefullyRestart();
        }
        console.log('ws>connected');
        window.WSMessage('Connected');
        // resolve(true); // connected = supported
        // client.dispose(); // dispose after check
      }
    }
    // connectionCallback: async (errors) => {
    //   console.log('running connectionCallback');
    //   // if (errors) {
    //   //   console.log('connectionCallback>errors: ', errors);
    //   //   // reject(errors);
    //   //   console.log('wsClient: ', wsClient);
    //   //   wsClient.close();
    //   //   // wsClient.tryConnect();
    //   //   // wsClient.connect();

    //   //   // Object.keys(wsClient.operations).forEach((id) => {
    //   //   //   wsClient.sendMessage(
    //   //   //     id,
    //   //   //     MessageTypes.GQL_START,
    //   //   //     wsClient.operations[id].options
    //   //   //   );
    //   //   // });
    //   // }
    // }

    // connectionParams: {
    //   authToken: user.authToken
    // }
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : null),
    addTypename: false
  })
});

// client.onResetStore(async () => {
//   console.log('running onResetStore');
//   client.setLink(link());
// });

export default client;
