import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/SpinnerSmall';

import DefaultHead from './item-components/head/DefaultHead';
import GanttHead from './item-components/head/GanttHeadV3';

import WarningItem from './item-components/body/WarningItem';
// import NotificationsItem from './item-components/body/NotificationsItem';

// import DateItem from './item-components/body/dateItem/DateItemV4';
import DefaultItem from './item-components/body/DefaultItem';

import NAItem from './item-components/body/NAItem';
import EmptyItem from './item-components/body/EmptyItem';

import NameItem from './item-components/body/NameItemV8';
// import StatusItem from './item-components/body/StatusItem';
import TypeItem from './item-components/body/typeItem/TypeItem';
// import CheckedAtItem from './item-components/body/CheckedAtItem';

import BooleanCheckboxItem from './item-components/body/BooleanCheckboxItem';

import IconHead from './item-components/head/IconHead';

import CheckboxHead from './item-components/head/CheckboxHead';
import CheckboxItem from './item-components/body/CheckboxItem';

import DefaultFoot from './item-components/foot/DefaultFoot';
import NameFoot from './item-components/foot/NameFoot';

import { AccountContext } from '../../context/AccountContext';

import { StyleContext } from '../../context/StyleContext';

import StorageIcon from '@mui/icons-material/Storage';

function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

const DataSourceHealthDashboardTable = ({
  stylesStoredInRedux,
  arrayOfData,
  setArrayOfData
}) => {
  const tableEl = useRef(null);
  const [columns, setColumns] = useState(['name']);
  const [visColumns, setvisColumns] = useState([
    'checkbox',
    'type',
    'name',
    'warnings',
    'status',
    'dataSourceId',
    'dataSourceAccountId',
    'dataSourceUpdatedAt',
    // 'dataSourceErrorCode',
    // 'dataSourceErrorMessage',
    // 'tokenId',
    'tokenUser',
    'tokenExpiresAt'
    // 'tokenErrorCode',
    // 'tokenErrorMessage',
    // 'lastDataFetchLongAt',
    // 'lastDataFetchShortAt'
  ]);

  const [checkedBoxes, setCheckedBoxes] = useState([]);
  // const [rawArrayOfData, setRawArrayOfData] = useState([]);
  // const [arrayOfData, setArrayOfData] = useState([{ name: 'hej', level: 1 }]);
  const [displayArrayOfData, setDisplayArrayOfData] = useState([]);

  const [sortedColumn, setSortedColumn] = useState([]);

  useEffect(() => {
    if (arrayOfData.length) setDisplayArrayOfData([...arrayOfData]);
  }, [arrayOfData]);

  const { currentAccountIds, currentManagerAccountId, currentManagerAccount } =
    useContext(AccountContext);

  function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
  const sortColumnHandler = (e, itemKey) => {
    if (itemKey === 'name' && itemKey !== sortedColumn.column) {
      setArrayOfData([...arrayOfData.sort(compareValues(itemKey, 'asc'))]);
      setSortedColumn({ column: itemKey, asc: false });
    } else if (itemKey !== sortedColumn.column) {
      setArrayOfData([...arrayOfData.sort(compareValues(itemKey, 'desc'))]);
      setSortedColumn({ column: itemKey, asc: true });
      // if (itemKey !== sortedColumn.column) {
      //   setSortedColumn({ column: itemKey, asc: sortedColumn.asc });
      //   setArrayOfData([
      //     ...arrayOfData.sort(
      //       compareValues(itemKey, !sortedColumn.asc ? 'asc' : 'desc')
      //     )
      //   ]);
    } else {
      setArrayOfData([
        ...arrayOfData.sort(
          compareValues(itemKey, sortedColumn.asc ? 'asc' : 'desc')
        )
      ]);
      setSortedColumn({ column: itemKey, asc: !sortedColumn.asc });
    }
  };

  document.addEventListener('mousemove', function (e) {
    //adjust warning column
    document.querySelector('#gridContainer > div:nth-child(3)') &&
      setWarningColumnLeft(
        document.querySelector('#gridContainer > div:nth-child(3)')
          .offsetWidth + 92
      );

    //resize column
    if (curCol) {
      var diffX = e.pageX - pageX;

      curCol.style.width = curColWidth + diffX + 'px';
    }
  });

  document.addEventListener('mouseup', function (e) {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  });
  var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

  const colHandlerOnRelease = (e, itemKey) => {
    var isRightMB;
    var isLeftMB;
    e = e || window.event;

    if ('which' in e) {
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = e.which == 3;
      isLeftMB = e.which == 1;
    } else if ('button' in e) {
      // IE, Opera
      isRightMB = e.button == 2;
      isLeftMB = e.button == 1;
    }

    if (isRightMB) {
      if (
        itemKey.includes('Sparkline') ||
        itemKey.includes('Today') ||
        itemKey.includes('Yesterday')
      ) {
        const index = columns.findIndex((col) => col === itemKey);
        const updatedColumns = [...columns];
        updatedColumns.splice(index, 1);
        setColumns(updatedColumns);

        return;
      }
    }
  };

  const nameColHandlerOnMouseUp = (e) => {
    curCol = e.target.parentElement;
    curColWidth = curCol.offsetWidth;

    localStorage.setItem('nameColumnWidth', curColWidth);
  };

  const colHandler = (e, itemKey) => {
    var isRightMB;
    var isLeftMB;
    e = e || window.event;

    if ('which' in e) {
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = e.which == 3;
      isLeftMB = e.which == 1;
    } else if ('button' in e) {
      // IE, Opera
      isRightMB = e.button == 2;
      isLeftMB = e.button == 0;
    }

    // alert('Right mouse button ' + (isRightMB ? '' : ' was not') + 'clicked!');

    if (isRightMB || e.shiftKey) {
      // alert('Right mouse button ' + (isRightMB ? '' : ' was not') + 'clicked!');
      // columns,

      let addColumn = 'Sparkline';
      if (e.shiftKey && isLeftMB) {
        addColumn = 'Yesterday';
      }

      if (e.shiftKey && isRightMB) {
        addColumn = 'Today';
      }

      let index = 0;
      index = columns.findIndex((col) => col === itemKey + addColumn);

      if (index === -1) {
        index = columns.findIndex((col) => col === itemKey);
        const updatedColumns = [...columns];
        updatedColumns.splice(index + 1, 0, itemKey + addColumn);
        setColumns(updatedColumns);
      } else {
        index = columns.findIndex((col) => col === itemKey + addColumn);
        const updatedColumns = [...columns];
        updatedColumns.splice(index, 1);
        setColumns(updatedColumns);
      }
      // setColumns,
    } else {
      curCol = e.target.parentElement;
      nxtCol = curCol.nextElementSibling;
      pageX = e.pageX;
      curColWidth = curCol.offsetWidth;
      if (!curCol.style.minWidth) {
        curCol.style.minWidth = curColWidth + 2 + 'px';
      }

      if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
    }
  };

  useEffect(() => {
    const scrollHeight = tableEl.current.scrollHeight;
    const offsetHeight = tableEl.current.offsetHeight;
    const scrollTop = tableEl.current.scrollTop;

    if (
      tableEl &&
      tableEl.current &&
      scrollTop >= scrollHeight - offsetHeight
    ) {
      if (bottomShadow) {
        toggleBottomShadow(false);
      }
    } else {
      if (!bottomShadow) {
        toggleBottomShadow(true);
      }
    }
  }, [displayArrayOfData]);

  const [leftShadow, toggleLeftShadow] = useState(false);
  const [topShadow, toggleTopShadow] = useState(false);
  const [bottomShadow, toggleBottomShadow] = useState(false);

  const onScrollHandler = (e) => {
    document.querySelector('#gridContainer').scrollLeft !== 0
      ? toggleLeftShadow(true)
      : toggleLeftShadow(false);
    document.querySelector('#gridContainer').scrollTop !== 0
      ? toggleTopShadow(true)
      : toggleTopShadow(false);
    document.querySelector('#gridContainer').scrollTop >=
    document.querySelector('#gridContainer').scrollHeight -
      document.querySelector('#gridContainer').offsetHeight
      ? toggleBottomShadow(false)
      : toggleBottomShadow(true);
  };

  const gridContainer = {
    width: 'min-content',
    maxWidth: '-webkit-fill-available',
    display: 'grid',
    gridTemplateColumns: '46px, 46px, 200px, 53px auto',
    gridTemplateRows:
      'minmax(55px, 55px) repeat(auto-fill, minmax(46px, 46px))',
    padding: '0px',
    overflowX: 'scroll',
    overflowY: 'scroll',
    position: 'relative',
    top: '0px',

    height: 'calc(100vh - 140px)',

    borderRadius: '5px'
  };

  const [warningColumnLeft, setWarningColumnLeft] = useState('');

  const [ganttWidth, setGanttWidth] = useState(21);

  const summaryRowBottomShadow = (
    <div
      style={{
        top: '45px',
        // right: '0px',
        height: '5px',
        position: 'absolute',
        // backgroundColor: stylesStoredInRedux.background.backgroundColor
        //   ? stylesStoredInRedux.background.backgroundColor
        //   : 'rgba(255,255,255,0)',
        // cursor: 'col-resize',
        // backgroundColor: 'red',
        userSelect: 'none',
        width: 'calc(100% + 1px)',
        zIndex: '1099'
      }}
    >
      <div
        style={{
          top: '45px',
          // right: '0px',
          height: '5px',
          // position: 'absolute',
          background:
            '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
          // cursor: 'col-resize',
          // backgroundColor: 'red',
          userSelect: 'none',
          width: 'calc(100% + 1px)',
          zIndex: '1100'
        }}
      ></div>
    </div>
  );

  const summaryRowCheckboxBottomShadow = (
    <div
      style={{
        top: '45px',
        // right: '0px',
        height: '5px',
        position: 'absolute',
        // backgroundColor: stylesStoredInRedux.background.backgroundColor
        //   ? stylesStoredInRedux.background.backgroundColor
        //   : 'rgba(255,255,255,0)',
        // cursor: 'col-resize',
        // backgroundColor: 'red',
        userSelect: 'none',
        left: '-1px',
        width: 'calc(100% + 1px)',
        zIndex: '1099'
      }}
    >
      <div
        style={{
          top: '45px',
          // right: '0px',
          height: '5px',
          // position: 'absolute',
          background:
            '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
          // cursor: 'col-resize',
          // backgroundColor: 'red',
          userSelect: 'none',
          width: 'calc(100% + 1px)',
          zIndex: '1100',
          marginLeft: '3px',
          borderBottomLeftRadius: '5px'
        }}
      ></div>
    </div>
  );

  const onCheckboxCornerClick = (e, item) => {
    setCheckedBoxes([item]);
  };

  // ################## TypeItem menu ###############################

  const [typeItemAnchorRef, setTypeItemAnchorRef] = useState(null);

  const [typeItemOpen, setTypeItemOpen] = useState(false);

  const [typeItemToggledItem, setTypeItemToggledItem] = useState(null);

  const [typeItemTemplateMenuAnchorRef, setTypeItemTemplateMenuAnchorRef] =
    useState(null);

  const [typeItemTemplateMenuOpen, setTypeItemTemplateMenuOpen] =
    useState(false);

  const [typeItemToggledItemTemplateMenu, setTypeItemToggledItemTemplateMenu] =
    useState(null);

  // ################################################################

  const numberOfVisibleColumns = visColumns.length;
  return (
    <Fragment>
      <div id="nexustable-table">
        {topShadow && (
          <div
            id="topShadow"
            style={{
              top: '123px',
              // right: '0px',
              height: '5px',
              position: 'absolute',
              background:
                '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
              // cursor: 'col-resize',
              // backgroundColor: 'red',
              userSelect: 'none',
              // width: '100%',
              width: tableEl.current.offsetWidth - 7 + 'px',
              // maxWidth: 'calc(100% - 36px)',
              maxWidth: tableEl.current.offsetWidth - 7 + 'px',
              zIndex: '1100'
            }}
          ></div>
        )}

        {bottomShadow && (
          <div
            id="bottomShadow"
            style={{
              bottom: '52px',
              // right: '0px',
              height: '5px',
              position: 'absolute',
              background:
                '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAHElEQVQI12MwNjZmZdAT1+Nm0JDWEGZQk1GTBgAWkwIeAEp52AAAAABJRU5ErkJggg==) repeat-x',
              // cursor: 'col-resize',
              // backgroundColor: 'red',
              userSelect: 'none',
              // width: '100%',
              width: tableEl.current.offsetWidth - 7 + 'px',
              // maxWidth: 'calc(100% - 36px)',
              maxWidth: tableEl.current.offsetWidth - 7 + 'px',
              zIndex: '1500'
            }}
          ></div>
        )}

        {/* background: 0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y; */}
        <div
          ref={tableEl}
          id="gridContainer"
          style={gridContainer}
          onScroll={(e) => onScrollHandler(e)}
        >
          {visColumns.map((itemKey, index) => {
            const columnNumber = index;
            switch (itemKey) {
              case 'checkbox':
                return (
                  <div
                    index={index}
                    key={index}
                    style={{
                      borderRadius: '5px 0px 0px 0px',
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      backgroundImage:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.background,
                      color:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.color,

                      // border: '1px solid rgba(200, 200, 200, 0.5)',
                      border:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.border,
                      fontSize: '12px',
                      gridRowStart: 1,
                      gridRowEnd: 1,
                      position: 'sticky',
                      left: '0',
                      top: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '46px',
                      // padding: '10px 20px',
                      fontWeight: '800'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        cursor: 'pointer'
                      }}
                      // onClick={(e) => sortColumnHandler(e, itemKey)}
                    >
                      <CheckboxHead
                        setCheckedBoxes={setCheckedBoxes}
                        // rawArrayOfData={rawArrayOfData}
                        currentAccountIds={currentAccountIds}
                        arrayOfData={arrayOfData}
                        checkedBoxes={checkedBoxes}
                      />
                    </span>
                  </div>
                );
                break;

              case 'type':
                return (
                  <div
                    index={index}
                    key={index}
                    style={{
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      backgroundImage:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].color,
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.borderColor,
                      fontSize: '12px',
                      gridRowStart: 1,
                      gridRowEnd: 1,
                      position: 'sticky',
                      left: '46px',
                      top: '0',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: '1100',
                      height: '100%',
                      width: '100%',
                      // padding: '10px 20px',
                      fontWeight: '800'
                      // minWidth: '200px',
                    }}
                  >
                    <div
                      style={{
                        // margin: 'auto',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      onClick={(e) => sortColumnHandler(e, itemKey)}
                    >
                      <StorageIcon style={{ height: '16px' }} />
                      <span style={{ position: 'absolute', top: '10px' }}>
                        {' '}
                        {sortedColumn.column === itemKey ? (
                          sortedColumn.asc ? (
                            <i className="fas fa-sort-down"></i>
                          ) : (
                            <i className="fas fa-sort-up"></i>
                          )
                        ) : (
                          <span style={{ visibility: 'hidden' }}>
                            <i className="fas fa-sort-up"></i>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                );
                break;

              case 'name':
                return (
                  <div
                    index={index}
                    key={index}
                    style={{
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      backgroundImage:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.background,
                      color:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.color,
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.borderColor,
                      fontSize: '12px',
                      gridRowStart: 1,
                      gridRowEnd: 1,
                      position: 'sticky',
                      left: '92px',
                      top: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '100%',
                      padding: '10px 20px',
                      fontWeight: '800',
                      width:
                        localStorage.getItem('nameColumnWidth') &&
                        localStorage.getItem('nameColumnWidth') + 'px',
                      minWidth: '200px'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        cursor: 'pointer'
                      }}
                      onClick={(e) => sortColumnHandler(e, itemKey)}
                    >
                      <p>
                        {'Name'}
                        <span>
                          {' '}
                          {sortedColumn.column === itemKey ? (
                            sortedColumn.asc ? (
                              <i className="fas fa-sort-down"></i>
                            ) : (
                              <i className="fas fa-sort-up"></i>
                            )
                          ) : (
                            <span style={{ visibility: 'hidden' }}>
                              <i className="fas fa-sort-up"></i>
                            </span>
                          )}
                        </span>
                      </p>
                      <p style={{ visibility: 'hidden' }}> test</p>
                    </span>

                    <div
                      className="hover-col-resize"
                      style={{
                        top: '0px',
                        right: '0px',
                        width: '5px',
                        position: 'absolute',
                        cursor: 'col-resize',

                        userSelect: 'none',
                        height: '100%'
                      }}
                      onMouseDown={(e) => colHandler(e)}
                      onMouseUp={nameColHandlerOnMouseUp}
                    ></div>
                  </div>
                );
                break;

              case 'warnings':
                return (
                  <div
                    index={index}
                    key={index}
                    style={{
                      backgroundImage:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.background,
                      color:
                        stylesStoredInRedux?.RoiTable?.Table?.headStyle[
                          'defaultHead'
                        ]?.color,
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.headStyle[
                          'defaultHead'
                        ].borderColor,
                      // border: '1px solid rgba(200, 200, 200, 0.5)',
                      fontSize: '12px',
                      gridRowStart: 1,
                      gridRowEnd: 1,
                      position: 'sticky',
                      left: warningColumnLeft + 'px',
                      top: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '46px',
                      // padding: '10px 20px',
                      paddingTop: '10px',
                      fontWeight: '800'
                      // color: 'rgba(0, 0, 0, 0.54)'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        cursor: 'pointer'
                      }}
                      onClick={(e) => sortColumnHandler(e, itemKey)}
                    >
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                    <span style={{ textAlign: 'center' }}>
                      {' '}
                      {sortedColumn.column === itemKey ? (
                        sortedColumn.asc ? (
                          <i className="fas fa-sort-down"></i>
                        ) : (
                          <i className="fas fa-sort-up"></i>
                        )
                      ) : (
                        <span style={{ visibility: 'hidden' }}>
                          <i className="fas fa-sort-up"></i>
                        </span>
                      )}
                    </span>
                    {/* <p>{itemKey}</p>
                      <p style={{ visibility: 'hidden' }}> test</p> */}

                    {leftShadow && (
                      <div
                        style={{
                          right: '-6px',
                          // right: '0px',
                          width: '5px',
                          position: 'absolute',
                          background:
                            '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y',
                          // cursor: 'col-resize',
                          // backgroundColor: 'red',
                          userSelect: 'none',
                          height: '100%',
                          zIndex: '4000'
                        }}
                      ></div>
                    )}
                  </div>
                );
                break;

              default:
                return (
                  <DefaultHead
                    index={index}
                    headStyle={
                      stylesStoredInRedux.RoiTable.Table.headStyle[
                        'defaultHead'
                      ]
                    }
                    key={index}
                    keyForMap={index}
                    itemKey={itemKey}
                    sortColumnHandler={sortColumnHandler}
                    colHandler={colHandler}
                    colHandlerOnRelease={colHandlerOnRelease}
                    sortedColumn={sortedColumn}
                    numberOfVisibleColumns={numberOfVisibleColumns}
                  />
                );
            }
          })}
          {displayArrayOfData.map((item, rowNumber) => {
            // ##############################################

            // const [checked, setChecked] = React.useState(false);
            const currentBoxInCheckedBoxes = checkedBoxes.filter((obj) => {
              return obj._id === item._id;
            });

            const checked = Boolean(currentBoxInCheckedBoxes.length);

            // ##############################################

            return (
              <Fragment key={item._id}>
                {visColumns.map((itemKey, index) => {
                  const columnNumber = index;
                  switch (true) {
                    case itemKey === 'checkbox':
                      return (
                        <div
                          key={item._id + '_' + itemKey + '_' + index}
                          style={{
                            zIndex: '1000',
                            // backgroundColor: 'rgba(255, 255, 255, 1)',
                            backgroundColor: checked
                              ? stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                  .rowStyle[item.level]?.checkedBackgroundColor
                              : stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                  ?.rowStyle[item.level]?.backgroundColor,
                            borderRight:
                              stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                ?.rowStyle[item.level]?.border,
                            borderBottom:
                              stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                ?.rowStyle[item.level]?.border,
                            borderLeft:
                              stylesStoredInRedux?.RoiTable?.Table?.itemStyle
                                ?.rowStyle[item.level]?.border,
                            // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                            // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                            // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
                            fontSize: '12px',
                            gridRowStart: rowNumber + 2,
                            gridRowEnd: rowNumber + 2,
                            position: 'sticky',
                            left: '0',
                            display: 'grid',
                            height: '100%',
                            width: '46px',
                            // minWidth: '200px',
                            whiteSpace: 'nowrap'
                            // overflowX: 'hidden',
                            // padding: '10px 20px'
                          }}
                          onClick={(e) => onCheckboxCornerClick(e, item)}
                        >
                          {/* <div
                            style={{
                              height: '100%',
                              width: '2px',
                              position: 'absolute',
                              left: '0px',
                              backgroundColor: item.accountColor,
                              visibility:
                                customStyleSelected?.checkBoxLeftBorderColor ===
                                'accountColor'
                                  ? 'visible'
                                  : 'hidden'
                            }}
                          ></div> */}
                          <span
                            style={{
                              // margin: 'auto'
                              margin: 'auto'
                            }}
                          >
                            <CheckboxItem item={item} maxHeight="46px" />
                            {/* <p>{item[itemKey]}</p> */}
                          </span>
                        </div>
                      );
                    case itemKey === 'name':
                      return (
                        // <div>{item.name}</div>
                        <NameItem
                          // aggregatedMode={aggregatedMode}
                          // selectedDateStart={selectedDateStart}
                          // selectedDateStop={selectedDateStop}
                          // dataLevel={dataLevel}
                          // updateDocument={updateDocument}
                          currentManagerAccountId={currentManagerAccountId}
                          // changeTableData={changeTableData}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          index={index}
                          item={item}
                          rowNumber={rowNumber}
                          // showItemsHandler={showItemsHandler}
                          // openRows={openRows}
                          checked={checked}

                          // rawArrayOfData={rawArrayOfData}
                        />
                      );
                      break;
                    case itemKey === 'warnings':
                      return (
                        <WarningItem
                          warningColumnLeft={warningColumnLeft}
                          item={item}
                          index={index}
                          stylesStoredInRedux={stylesStoredInRedux}
                          leftShadow={leftShadow}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                      break;
                    // case itemKey === 'status':
                    //   return (
                    //     <StatusItem
                    //       currentManagerAccountId={currentManagerAccountId}
                    //       // updateDocument={updateDocument}
                    //       item={item}
                    //       itemStyle={
                    //         stylesStoredInRedux.RoiTable.Table.itemStyle
                    //           .rowStyle[item.level]
                    //       }
                    //       keyForMap={item._id + '_' + itemKey + '_' + index}
                    //       index={index}
                    //       itemKey={itemKey}
                    //       itemValue={item[itemKey]}
                    //       rowNumber={rowNumber}
                    //       textAlignRight={true}
                    //       checked={checked}
                    //     />
                    //   );
                    //   break;

                    case itemKey === 'type':
                      return (
                        <TypeItem
                          currentManagerAccountId={currentManagerAccountId}
                          item={item}
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                          typeItemAnchorRef={typeItemAnchorRef}
                          setTypeItemAnchorRef={setTypeItemAnchorRef}
                          open={typeItemOpen}
                          setOpen={setTypeItemOpen}
                          typeItemToggledItem={typeItemToggledItem}
                          setTypeItemToggledItem={setTypeItemToggledItem}
                        />
                      );
                      break;

                    default:
                      return (
                        <DefaultItem
                          itemStyle={
                            stylesStoredInRedux.RoiTable.Table.itemStyle
                              .rowStyle[item.level]
                          }
                          keyForMap={item._id + '_' + itemKey + '_' + index}
                          index={index}
                          itemValue={item[itemKey]}
                          rowNumber={rowNumber}
                          checked={checked}
                        />
                      );
                  }
                })}
              </Fragment>
            );
          })}

          {/*--------------------- Summary Row --------------------- */}

          {visColumns.map((itemKey, index) => {
            switch (itemKey) {
              case 'checkbox':
                return (
                  <div
                    key={index}
                    style={{
                      borderRadius: '0px 0px 0px 5px',
                      maxHeight: '46px',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].color,
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 1px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].borderColor,
                      // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
                      borderBottomLeftRadius: '5px',
                      fontSize: '12px',
                      gridRowStart: displayArrayOfData.length + 2,
                      gridRowEnd: displayArrayOfData.length + 2,
                      position: 'sticky',
                      left: '0',
                      bottom: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      // minWidth: '200px',
                      width: '100%',
                      minWidth: '46px',
                      // padding: '10px 20px',
                      fontWeight: '800'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {/* <p>Summary</p> */}
                      {/* <p style={{ visibility: 'hidden' }}> test</p> */}
                    </span>
                    {summaryRowCheckboxBottomShadow}
                  </div>
                );
                break;
              case 'type':
                return (
                  <div
                    key={index}
                    style={{
                      maxHeight: '46px',
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].color,
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].borderColor,
                      // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottomLeftRadius: '5px',
                      fontSize: '12px',
                      gridRowStart: displayArrayOfData.length + 2,
                      gridRowEnd: displayArrayOfData.length + 2,
                      position: 'sticky',
                      left: '46px',
                      bottom: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      // minWidth: '200px',
                      width: '100%',
                      minWidth: '46px',
                      // padding: '10px 20px',
                      fontWeight: '800'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {/* <p>Summary</p> */}
                      <p style={{ visibility: 'hidden' }}> test</p>
                    </span>
                    {summaryRowBottomShadow}
                  </div>
                );
                break;
              case 'name':
                return (
                  <NameFoot
                    // aggregatedMode={aggregatedMode}
                    item={{}}
                    itemKey={itemKey}
                    index={index}
                    stylesStoredInRedux={stylesStoredInRedux}
                    arrayOfData={displayArrayOfData}
                    summaryRowBottomShadow={summaryRowBottomShadow}
                    columns={columns}
                  />
                );
                break;
              case 'warnings':
                return (
                  <div
                    key={index}
                    style={{
                      maxHeight: '46px',
                      minHeight: '46px',
                      backgroundImage:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].background,
                      color:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].color,
                      // backgroundImage:
                      //   'linear-gradient(to bottom, white, #f9fafa)',
                      borderStyle: 'solid',
                      borderWidth: '1px 1px 1px 0px',
                      // borderColor: 'rgba(200, 200, 200, 0.5)',
                      borderColor:
                        stylesStoredInRedux.RoiTable.Table.summaryStyle[
                          'defaultSummary'
                        ].borderColor,
                      // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderLeft: '1px solid rgba(200, 200, 200, 0.5)',
                      // borderBottomLeftRadius: '5px',
                      fontSize: '12px',
                      gridRowStart: displayArrayOfData.length + 2,
                      gridRowEnd: displayArrayOfData.length + 2,
                      position: 'sticky',
                      left: warningColumnLeft + 'px',
                      bottom: '0',
                      display: 'grid',
                      zIndex: '1100',
                      height: '100%',
                      width: '46px',
                      padding: '10px 20px',
                      fontWeight: '800'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto'
                      }}
                    >
                      {/* <p>Summary</p>
                      <p style={{ visibility: 'hidden' }}> test</p> */}
                    </span>
                    {leftShadow && (
                      <div
                        style={{
                          right: '-6px',
                          // right: '0px',
                          width: '5px',
                          position: 'absolute',
                          background:
                            '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==) repeat-y',
                          // cursor: 'col-resize',
                          // backgroundColor: 'red',
                          userSelect: 'none',
                          height: '100%',
                          zIndex: '4000'
                        }}
                      ></div>
                    )}
                    {summaryRowBottomShadow}
                  </div>
                );
                break;

              default:
                return (
                  <DefaultFoot
                    index={index}
                    itemKey={itemKey}
                    arrayOfDataLength={displayArrayOfData.length}
                    stylesStoredInRedux={stylesStoredInRedux}
                  />
                );
            }
          })}
        </div>
      </div>
    </Fragment>
  );
};

// DataSourceHealthDashboardTable.propTypes = {
//   currentAccount: PropTypes.string
// };

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {})(DataSourceHealthDashboardTable);
