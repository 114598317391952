import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import { fieldArray, defaultFieldSets } from './fields';

import { AccountContext } from '../../../../../context/AccountContext';
import { DialogContext } from '../../../../../context/DialogContext';
import { ColumnContext } from '../../../../../context/ColumnContext';
import { InterfaceContext } from '../../../../../context/InterfaceContext';

import { AuthContext } from '../../../../../context/AuthContext';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import TransferList from './TransferList';
import CustomColumnSetInput from './CustomFieldSetInput';

import { useLazyQuery, useMutation } from '@apollo/client';

import { updateDocumentMutation } from './../../../../../graphql/queries';

import { MutationContext } from '../../../../../context/MutationContext';

import { useSnackbar } from 'notistack';

import { fieldDictionary } from './fields';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        style={{
          maxWidth: '860px',
          width: '860px',
          height: '67vw',
          maxHeight: '800px'
        }}
        {...props}
      />
    </Draggable>
  );
}

export default function FieldDialog({ rawArrayOfData }) {
  console.log('render FieldDialog');
  const { user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  // const [updateDocument] = useMutation(updateDocumentMutation);

  const { updateDocument } = useContext(MutationContext);

  const { currentManagerAccount } = useContext(AccountContext);
  const { fieldDialogOpen, setFieldDialogOpen, itemId, setItemId } =
    useContext(DialogContext);

  const { fields, setFields, overrideDefaultFields, setOverrideDefaultFields } =
    useContext(InterfaceContext);

  // const [open, setOpen] = React.useState(false);

  const item = rawArrayOfData?.filter((obj) => obj._id === itemId)[0];

  const [left, setLeft] = useState(fieldArray);
  const [right, setRight] = useState([]);

  // const [overrideDefaultFields, setOverrideDefaultFields] = useState(false);

  useEffect(() => {
    if (overrideDefaultFields) {
      setRight(fields);
      setLeft(
        fieldArray.map((itemKey, index) => ({
          string: itemKey,
          name: fieldDictionary[itemKey],
          index
        }))
      );

      // setLeft(
      //   fieldArray
      //     .filter(
      //       (field) => !fields.map((field) => field.field).includes(field)
      //     )
      //     .sort()
      // );
    } else {
      let fallbackFields = [];

      switch (item.type) {
        case 'task':
          fallbackFields = [
            { name: 'generalDescription', string: 'generalDescription' },
            { name: 'status', string: 'status' },
            { name: 'dates', string: 'dates' },
            { name: 'labels', string: 'labels' },
            { name: 'members', string: 'members' },
            { name: 'isPersonal', string: 'isPersonal' },
            { name: 'subTasks', string: 'subTasks' },
            { name: 'comments', string: 'comments' }
          ];
          break;
        default:
          fallbackFields = [
            { name: 'generalDescription', string: 'generalDescription' },
            { name: 'status', string: 'status' },
            { name: 'dates', string: 'dates' },
            { name: 'labels', string: 'labels' },
            { name: 'members', string: 'members' },
            { name: 'comments', string: 'comments' }
          ];
      }
      const rightFieldArray = fallbackFields.map((itemKey, index) => ({
        string: itemKey,
        name: fieldDictionary[itemKey],
        index
      }));
      setRight(fallbackFields);

      setLeft(
        fieldArray.filter((field) => !rightFieldArray.includes(field)).sort()
      );
    }
  }, [item]);

  const handleClickOpen = () => {
    setFieldDialogOpen(true);
  };

  const handleClickDone = () => {
    const fieldsToSave = [];

    for (let rightField of right) {
      const rightFieldsInFields = fields.filter(
        (field) => field.string === rightField.string
      );
      if (rightFieldsInFields.length === 1) {
        fieldsToSave.push(rightFieldsInFields[0]);
      } else {
        if (rightField.string.includes('LineChart')) {
          const fieldString = rightField.string.split('LineChart')[0];
          fieldsToSave.push({
            name: rightField.name,
            string: rightField.string,
            leftYAxisMetrics: [{ name: fieldString, string: fieldString }],
            rightYAxisMetrics: []
          });
        } else {
          fieldsToSave.push({
            name: rightField.name,
            string: rightField.string
          });
        }
      }
    }

    setFields(fieldsToSave);
    // setFields(
    //   right.map((field) => ({
    //     name: field,
    //     string: field
    //   }))
    // );
    setOverrideDefaultFields(true);
    setFieldDialogOpen(false);
  };

  const handleClose = () => {
    // setColumns(right);
    setFieldDialogOpen(false);
    // setOverrideDefaultFields(false);
  };

  // const handleSaveAsDefault = async () => {
  //   const fieldsToSave = [];

  //   for (let rightField of right) {
  //     const rightFieldsInFields = fields.filter(
  //       (field) => field.string === rightField
  //     );
  //     if (rightFieldsInFields.length === 1) {
  //       fieldsToSave.push(rightFieldsInFields[0]);
  //     } else {

  //       if (rightField.includes('LineChart')) {
  //
  //         const fieldString = rightField.split('LineChart')[0];
  //         fieldsToSave.push({
  //           name: rightField,
  //           string: rightField,
  //           leftYAxisMetrics: [{ name: fieldString, string: fieldString }],
  //           rightYAxisMetrics: []
  //         });
  //       } else {
  //         fieldsToSave.push({
  //           name: rightField,
  //           string: rightField
  //         });
  //       }
  //     }
  //   }

  //   // return;
  //   // right.map((rightField) => {
  //   //   return fields.filter((field) => field.string === rightField)[0]

  //   const documentInput = {
  //     managerAccountId: currentManagerAccount._id,
  //     documents: [
  //       {
  //         accountId: item.accountId,
  //         documentId: item._id,
  //         documentType: item.documentType,
  //         defaultFields: fieldsToSave
  //       }
  //     ],
  //     updateKeys: ['defaultFields']
  //   };

  //   try {
  //     const res = await updateDocument({
  //       variables: { input: { ...documentInput } }
  //     });

  //     const response = res?.data?.updateDocument?.response;
  //     // const success = true;

  //     if (response === 'Success') {
  //       enqueueSnackbar('Saved', {
  //         variant: 'success'
  //       });
  //       // setEdit(false);
  //     } else {
  //       enqueueSnackbar('Error', {
  //         variant: 'error'
  //       });
  //     }
  //   } catch (error) {
  //     enqueueSnackbar('Error', {
  //       variant: 'error'
  //     });
  //   }
  // };

  const content = (
    <div>
      <TransferList
        left={left}
        setLeft={setLeft}
        right={right}
        setRight={setRight}
      />
      {/* <CustomColumnSetInput
        right={right}
        setOpen={setFieldDialogOpen}
        item={item}
      /> */}
    </div>
  );

  return (
    <div>
      <Dialog
        open={fieldDialogOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DynamicFormIcon />
            </div>
            <div style={{ marginLeft: '10px' }}>Fields</div>
          </div>
        </DialogTitle>
        <DialogContent>
          {content}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* {['system_admin'].includes(user.role) && (
            <Button autoFocus onClick={handleSaveAsDefault}>
              Save as default
            </Button>
          )} */}

          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClickDone}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
