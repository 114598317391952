import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';

import { MutationContext } from '../../../../context/MutationContext';

import { AccountContext } from '../../../../context/AccountContext';

// import nexusTableLogo from './../../../../img/nexustableicon.png';

import notifyMe from '../../roitable_functions/sendNotification';

export default function TimeboxerMenu({ calendarUserId }) {
  const { currentManagerAccount } = useContext(AccountContext);
  const { fetchOutlookCalendarEvents } = useContext(MutationContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (e, value) => {
    switch (value) {
      case 'fetchOutlookCalendarEvents':
        const input = {
          managerAccountId: currentManagerAccount._id,
          userId: calendarUserId
        };
        console.log('debug95>row68>input');

        await fetchOutlookCalendarEvents({ variables: { input } });

        break;

      case 'turnOnNotifications':
        notifyMe('Notifications are now on!');
        break;
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '20px',
          height: '20px',
          minHeight: 0,
          minWidth: 0
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <SettingsIcon style={{ fontSize: '18px' }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <ListItemIcon>
          <ContentCut fontSize="small" />
        </ListItemIcon> */}
        <MenuItem
          style={{ fontSize: '12px' }}
          onClick={(e) => handleClose(e, 'fetchOutlookCalendarEvents')}
        >
          Fetch Outlook Calendar Events
        </MenuItem>
        {/* <MenuItem
          style={{ fontSize: '12px' }}
          onClick={(e) => handleClose(e, 'turnOnNotifications')}
        >
          Turn on notifications
        </MenuItem> */}
      </Menu>
    </div>
  );
}
