import React, { useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
// import { makeStyles } from '@mui/styles';
import { withStyles, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { deepOrange, deepPurple } from '@mui/material/colors';
import LetterAvatar from './LetterAvatar';

import Tooltip from '@mui/material/Tooltip';

import DraggableDialog from './TeamItemDraggableDialog';

import TransferList from './TeamItemTransferList';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { MutationContext } from '../../../../context/MutationContext';

import { useSnackbar } from 'notistack';
import { styled, useTheme } from '@mui/material/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     '& > *': {
//       margin: theme.spacing(0)
//     }
//   },
//   orange: {
//     color: theme.palette.getContrastText(deepOrange[500]),
//     // backgroundColor: deepOrange[500],
//     // backgroundColor: 'grey',
//     // backgroundColor: 'rgba(177,48,141, 0.9)',
//     background: `
//     linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
//     linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
//     linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)

//     `,
//     border: '1px solid rgba(207,78,171, 0.9)',
//     fontSize: '10px',
//     height: '30px',
//     width: '30px'
//   },
//   purple: {
//     color: theme.palette.getContrastText(deepPurple[500]),
//     backgroundColor: deepPurple[500]
//   }
// }));

function TeamItem({
  item,
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  // updateDocument,
  currentManagerAccountId,
  checked
}) {
  const theme = useTheme();

  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { updateDocument } = useContext(MutationContext);
  // const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  //##################### tooltip #################################

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fafafa',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }))(Tooltip);

  //###############################################################

  let users = [];
  const listOfTransflerListItems = [];

  if (itemValue) {
    for (let i = 0; i < itemValue.length; i++) {
      let abb = '';
      let name = '';
      let email = '';
      let transflerListItem = '';
      if (itemValue[i].firstName && itemValue[i].lastName) {
        try {
          // const nameSplit = itemValue[i].name.split(' ');

          // for (let x = 0; x < nameSplit.length; x++) {
          //   abb = abb + nameSplit[x][0];
          // }
          abb = itemValue[i].firstName[0] + itemValue[i].lastName[0];
          name = itemValue[i].firstName + ' ' + itemValue[i].lastName;
          email = itemValue[i].email;

          transflerListItem = name + ' (' + email + ')';
          listOfTransflerListItems.push(transflerListItem);
        } catch (error) {}
      } else {
        name = 'unknown user';
        email = '';
        abb = '?';
      }

      users.push({ ...itemValue[i], name, abb, email, transflerListItem });
    }
  }

  const [open, setOpen] = useState(false);
  const onClickHandler = () => {
    if (open) {
      return;
    }

    if (
      ([
        'account',
        // 'totalActionsDocument',
        'folder',
        'task',
        'subTask',
        'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(item.type) &&
        !item.isSetChild) ||
      item.isSet
    ) {
      setOpen(!open);
    }
  };

  const [listOfUsers, setListOfUsers] = useState([]);

  const handleClose = () => {
    setLeft([]);
    setRight(listOfTransflerListItems);
    setOpen(false);
  };

  const handleClickApply = async () => {
    const copyOfListOfUsers = [...listOfUsers];
    const filteredCopyOfListOfUsers = copyOfListOfUsers.filter((obj) => {
      return right.includes(obj.transflerListItem);
    });

    const teamIds = [];
    for (let i = 0; i < filteredCopyOfListOfUsers.length; i++) {
      teamIds.push(filteredCopyOfListOfUsers[i]._id);
    }

    let updateDoc = false;

    let itemValueUserIds = itemValue || [];
    itemValueUserIds = itemValueUserIds.map((obj) => obj._id);

    let listOfUsersIds = filteredCopyOfListOfUsers || [];
    listOfUsersIds = listOfUsersIds.map((obj) => obj._id);

    let difference = itemValueUserIds.filter(
      (x) => !listOfUsersIds.includes(x)
    );

    if (
      itemValueUserIds.length !== listOfUsersIds.length ||
      difference.length > 0
    ) {
      updateDoc = true;
    }

    if (updateDoc) {
      // const documentInput = {
      //   accountId: item.accountId,
      //   documentId: item._id,
      //   document: { members: teamIds },
      //   updateKey: 'members',
      //   action: 'UPDATE_DOCUMENT',
      //   managerAccountId: currentManagerAccountId,
      //   documentType: item.documentType,
      //   level: item.level
      // };
      const documentInput = {
        managerAccountId: currentManagerAccountId,
        documents: [
          {
            accountId: item.accountId,
            documentId: item._id,
            documentType: item.documentType,
            members: teamIds
          }
        ],
        updateKeys: ['members']
      };

      // updateDocument({ variables: { input: { ...documentInput } } });

      try {
        const res = await updateDocument({
          variables: { input: { ...documentInput } }
        });
        // console.log('res: ', res);
        // console.log(
        //   'res?.data?.updateDocument?.response: ',
        //   res?.data?.updateDocument?.response
        // );
        if (res?.data?.updateDocument?.response !== 'Success') {
          throw new Error();
        }
        setOpen(false);
      } catch (error) {
        enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
          variant: 'error'
        });
      }
    }
  };

  // const transferListRight = users.map((user) => user.transflerListItem);

  const [left, setLeft] = useState([]);
  const [right, setRight] = useState(listOfTransflerListItems);
  if (
    ([
      'account',
      'folder',
      'task',
      'subTask',
      'taxonomy',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'microsoft_invest_insertion_order',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group',
      'manualDataEntry',
      'customForm'
    ].includes(item.type) ||
      item.isSet) &&
    !item.isSetChild
  ) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          // display: 'grid',
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '6px 10px 6px 15px',
          whiteSpace: 'nowrap',
          gridTemplateColumns: 'auto 20px',
          cursor: 'pointer'
        }}
        onClick={onClickHandler}
      >
        {(inView || exportMode) && (
          <>
            <div
              // style={{
              //   display: 'grid',
              //   gridRowStart: 0,
              //   gridRowEnd: 0,
              //   gridColumnStart: 0,
              //   gridColumnEnd: 0
              // }}
              style={{ display: 'flex' }}
            >
              <AvatarGroup max={4}>
                {users &&
                  users.map((obj) => (
                    <HtmlTooltip
                      key={obj._id}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">{obj.name}</Typography>
                          {/* <em>{"And here's"}</em> <b>{'some'}</b>{' '}
                      <u>{'amazing content'}</u>. {"It's very engaging. Right?"} */}
                          {obj.email}
                        </React.Fragment>
                      }
                    >
                      <Avatar
                        // style={{ border: '1px solid rgba(207,78,171, 0.9)' }}
                        style={{
                          // border: '1px solid rgba(255,255,255,1)',
                          background: `
                            linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
                            linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
                            linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)`,
                          // border: '1px solid rgba(207,78,171, 0.9)',
                          border:
                            theme?.palette?.mode === 'dark' &&
                            '1px solid rgba(255,255,255,0.3)',
                          color: 'white',
                          fontSize: '10px',
                          height: '30px',
                          width: '30px',
                          color: 'white'
                        }}
                        // className={classes.orange}
                      >
                        {obj.abb}
                      </Avatar>
                    </HtmlTooltip>
                  ))}
                {/* <Avatar className={classes.orange}>+</Avatar> */}
              </AvatarGroup>
            </div>
            {/* <div
              style={{
                display: 'grid',
                gridRowStart: 0,
                gridRowEnd: 0,
                gridColumnStart: 2,
                gridColumnEnd: 2
              }}
            >
              <i
                className="fas fa-pen"
                style={{
                  fontSize: '8px',
                  // color: 'rgba(255,255,255,0.3)',
                  // color: itemStyle['color'],
                  // color: 'grey',
                  padding: '10px 4px 4px 6px',
                  cursor: 'pointer'
                }}
                // onClick={onClickHandler}
              ></i>
            </div> */}

            <DraggableDialog
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
            >
              {open && (
                <TransferList
                  currentManagerAccountId={currentManagerAccountId}
                  accountId={item.accountId}
                  left={left}
                  setLeft={setLeft}
                  right={right}
                  setRight={setRight}
                  setListOfUsers={setListOfUsers}
                />
              )}
              <div
                style={{
                  padding: '10px 20px',
                  textAlign: 'right'
                  // backgroundColor: 'red'
                }}
              >
                <Button
                  // variant="contained"
                  size="small"
                  color="primary"
                  // className={classes.margin}
                  onClick={handleClose}
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Button>
                <Button
                  // variant="contained"
                  size="small"
                  color="primary"
                  // className={classes.margin}
                  onClick={handleClickApply}
                >
                  Apply
                </Button>
              </div>
            </DraggableDialog>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          // display: 'grid',
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '6px 10px 6px 15px',
          whiteSpace: 'nowrap',
          gridTemplateColumns: 'auto 20px'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridRowStart: 0,
            gridRowEnd: 0,
            gridColumnStart: 0,
            gridColumnEnd: 0
          }}
        ></div>
      </div>
    );
  }
}

export default React.memo(TeamItem);
