import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useQuery } from '@apollo/client';

import { actionDataSourcesQuery } from '../../../../../graphql/queries';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     width: '100%',
//     paddingRight: '16px'
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

export default function SimpleSelect({
  label,
  dataSource,
  // setDataSource,
  // default_value,
  // dataSource,
  currentManagerAccountId,
  item,
  itemKey,
  action,
  setAction
}) {
  // const classes = useStyles();

  const [skip, setSkip] = useState(false);

  let dataSourceAccountId_ = null;

  switch (item.documentType) {
    case 'strossle':
      dataSourceAccountId_ = item.dataSourceGroupId;
      break;
    case 'adform':
    case 'readpeak':
      dataSourceAccountId_ = item.dataSourceClientId;
      break;
    case 'dv360':
    case 'cm360':
    case 'tikTokAds':
    case 'microsoftInvest':
      dataSourceAccountId_ = item.dataSourceAdvertiserId;
      break;
    case 'snapchatAds':
    case 'facebookAds':
    case 'linkedInAds':
    case 'pinterestAds':

    case 'amazonDsp':
    case 'taboolaAds':
    case 'microsoftAdvertising':
    case 'appleSearchAds':
      dataSourceAccountId_ = item.dataSourceAccountId;
      break;
    case 'googleAds':
      dataSourceAccountId_ = item.dataSourceCustomerId;
      break;
  }

  const variables = {
    actionDataSourcesInput: {
      managerAccountId: currentManagerAccountId,
      accountId: item.accountId,
      dataSourceAccountId: dataSourceAccountId_,
      documentType: item.documentType
    }
  };

  console.log('SimpleSelectDataSources>variables: ', variables);

  const { data } = useQuery(actionDataSourcesQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: false
  });

  // console.log('SimpleSelectDataSources>data: ', data);

  useEffect(() => {
    if (data && !skip) {
      setSkip(true);
    }
  }, [data]);

  const menuItems =
    data && data.actionDataSources ? data.actionDataSources : [];

  const [input, setInput] = useState('');

  const handleChange = (event) => {
    switch (event.target.value) {
      case 'none':
        setInput('none');
        setAction({
          ...action,
          name: null,
          dataSource: null,
          dataSourceRef: null,
          dataSourceId: null,
          dataSourceAccountId: null
        });
        // setDataSource(null);
        break;
      case 'manualDataEntry':
        setInput('manualDataEntry');
        // setDataSource({ dataSourceRef: 'manualDataEntry' });

        setAction({
          ...action,
          name: action?.manualDataEntry?.name,
          dataSource: 'manualDataEntry',
          dataSourceRef: 'manualDataEntry',
          dataSourceId: null,
          dataSourceAccountId: null
        });
        break;
      default:
        let selectedItem = menuItems.filter(
          (obj) => event.target.value === obj.dataSourceAccountId
        )[0];

        setInput(
          selectedItem &&
            selectedItem.dataSourceAccountId &&
            selectedItem.dataSourceAccountId
        );

        setAction({
          ...action,
          // name: action?.googleAds?.name,
          name: selectedItem[selectedItem?.dataSource]?.name,
          dataSource: selectedItem?.dataSource,
          dataSourceRef: selectedItem?.dataSourceRef,
          dataSourceId: selectedItem?._id,
          dataSourceAccountId: selectedItem?.dataSourceAccountId
        });

      // setDataSource(selectedItem);
    }
  };

  const dataSourceRef = item[itemKey] && item[itemKey].dataSourceRef;
  const dataSourceAccountId =
    item[itemKey] && item[itemKey].dataSourceAccountId;

  useEffect(() => {
    if (!input) {
      switch (dataSource) {
        case 'manualDataEntry':
          setInput('manualDataEntry');
          break;
        default:
          let selectedItem = menuItems.filter(
            (obj) => action?.dataSourceAccountId === obj.dataSourceAccountId
          )[0];

          setInput(selectedItem?.dataSourceAccountId);
      }
    }
  });

  // useEffect(() => {
  //   if (!dataSource) {
  //
  //     let selectedItem = {};
  //     if (dataSourceRef === 'manualDataEntry') {
  //       setInput('manualDataEntry');
  //       selectedItem = {
  //         dataSource: 'manualDataEntry',
  //         dataSourceRef: 'manualDataEntry',
  //         dataSourceId: null,
  //         dataSourceAccountId: null
  //       };
  //       setDataSource(selectedItem);
  //     } else {
  //       selectedItem = menuItems.filter(
  //         (obj) => dataSourceAccountId === obj.dataSourceAccountId
  //       )[0];

  //       // setAction({
  //       //   ...action,
  //       //   ...selectedItem
  //       // });

  //       setInput(dataSourceAccountId);
  //       setDataSource(selectedItem);
  //     }
  //   }
  // }, [dataSourceAccountId, menuItems]);

  // useEffect(() => {
  //   setAction({
  //     ...action,
  //     ...dataSource
  //   });
  // }, [dataSource]);

  const dataSourceDictionary = {
    FacebookAdsDataSource: 'Facebook Ads',
    GoogleAdsDataSource: 'Google Ads',
    SnapchatAdsDataSource: 'Snapchat Ads',
    TikTokAdsDataSource: 'TikTok Ads',
    PinterestAdsDataSource: 'Pinterest Ads',
    ReadpeakDataSource: 'Readpeak',
    StrossleDataSource: 'Strossle',
    AmazonDspDataSource: 'Amazon DSP',
    TaboolaAdsDataSource: 'Taboola Ads',
    MicrosoftAdvertisingDataSource: 'Microsoft Advertising',
    MicrosoftInvestDataSource: 'Microsoft Invest',
    AppleSearchAdsDataSource: 'Apple Search Ads',
    GoogleAnalytics4DataSource: 'GA4',
    CM360DataSource: 'CM360',
    DV360DataSource: 'DV360',
    AdformDataSource: 'Adform'
  };

  return (
    <div>
      <FormControl
        style={{
          margin: '10px',
          minWidth: 120,
          width: '100%',
          paddingRight: '16px'
        }}
        // className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          variant="standard"
          style={{ fontSize: '14px' }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={input}
          onChange={handleChange}
        >
          <MenuItem style={{ fontSize: '14px' }} value="none">
            <em>None</em>
          </MenuItem>

          {/* {item.type === 'manualDataEntry' && (
            <MenuItem style={{ fontSize: '14px' }} value="manualDataEntry">
              Manual
            </MenuItem>
          )} */}
          {menuItems.map((menuItem, i) => (
            <MenuItem
              key={i}
              style={{ fontSize: '14px' }}
              value={menuItem.dataSourceAccountId}
            >
              {dataSourceDictionary[menuItem.dataSourceRef]} - {menuItem.name} (
              {menuItem.dataSourceAccountId})
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Some important helper text</FormHelperText> */}
      </FormControl>
    </div>
  );
}
