import React, { useState, useContext, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useMutation } from '@apollo/client';

import { updateDataSourceMutation } from './../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';
import { useSnackbar } from 'notistack';

export default function UseSuperDataSourceSwitch({
  dataSourceInfo,
  refetchDataSourceInfo
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [useSuperDataSource, setUseSuperDataSource] = useState(false);

  useEffect(() => {
    setUseSuperDataSource(dataSourceInfo.useSuperDataSource);
  }, [dataSourceInfo]);

  // const classes = useStyles();

  const [updateDataSource, { called }] = useMutation(updateDataSourceMutation);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (e) => {
    const input = {
      managerAccountId: currentManagerAccount._id,
      updateKeys: ['useSuperDataSource'],
      documents: [
        {
          _id: dataSourceInfo._id,
          dataSourceRef: dataSourceInfo.dataSourceRef,
          useSuperDataSource: !useSuperDataSource
        }
      ]
    };

    let res;
    try {
      res = await updateDataSource({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar(error.message.split(': ')[1], {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }

    if (res?.data?.updateDataSource?.response === 'Success') {
      enqueueSnackbar('Saved', {
        variant: 'success'
      });
      refetchDataSourceInfo();
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px'
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={useSuperDataSource}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Use Super Data Source"
      />
    </div>
  );
}
