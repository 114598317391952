import React, { useState, useContext, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';

import { useSnackbar } from 'notistack';

// import DisabledDatePickerStart from './DisabledDatePickerStart';
// import DisabledDatePickerStop from './DisabledDatePickerStop';

import BasicDatePicker from './BasicDatePicker';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import Checkbox from '@mui/material/Checkbox';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '@mui/material/Button';

import SelectStatus from './SelectStatus';
import SelectInvoiceStatus from './SelectInvoiceStatus';
// import { useMutation } from '@apollo/client';
// import { updateDocumentMutation } from './../../../graphql/queries';

import SelectTemplate from './SelectTemplate';

import { allEvalFieldsExceptTotal } from './../fields';

import { columnDictionary } from './../column-menu/columns';

import SelectMembers from './SelectMembers';

import { useTheme } from '@emotion/react';

import IconComponent from './../icon-component/IconComponent';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { FilterContext } from '../../../context/FilterContext';

import BulkEditActionSettings from './bulk-edit-action-settings/BulkEditActionSettings';

// import formatDateYYYYMMDD from './../roitable_functions/formatDateYYYYMMDD';

// function formatDate(yourDate1) {
//   let yourDate = new Date(yourDate1);
//   const offset = yourDate.getTimezoneOffset();
//   yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
//   return yourDate.toISOString().split('T')[0];
// }

// function formatDate(date) {
//   var d = new Date(date),
//     month = '' + (d.getMonth() + 1),
//     day = '' + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2) month = '0' + month;
//   if (day.length < 2) day = '0' + day;

//   return [year, month, day].join('-');
// }

function EditDialogContent({
  open,
  setOpen,
  // checkedBoxes,
  fields,
  setFields,
  document,
  setDocument,
  dataSourceAccountIds,
  checkedDocumentTypes,
  templateDocument,
  checkedTypes,
  currentManagerAccount,
  accountId,
  setTemplateDocument,
  handleClose,
  onSaveHandler,
  isActionBulkEditAllowed
}) {
  const { checkedBoxes, setCheckedBoxes } = useContext(FilterContext);
  const theme = useTheme();

  const onCheckboxChange = (field) => {
    const copyOfFields = [...fields];
    if (copyOfFields.includes(field)) {
      const indexOfField = copyOfFields.indexOf(field);
      copyOfFields.splice(indexOfField, 1);
      setFields(copyOfFields);
      const copyOfDocument = { ...document };
      delete copyOfDocument[field];
      setDocument(copyOfDocument);
    } else {
      if (
        [
          'allowImpressionsBeforeStartDate',
          'allowImpressionsAfterEndDate'
          // 'allowFrontloadedDelivery'
        ].includes(field)
      ) {
        setDocument({ ...document, [field]: false });
      }
      copyOfFields.push(field);
      setFields(copyOfFields);
    }
  };

  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'end',
    width: '100%',
    margin: '12px 0px'
    // minHeight: '46px'
  };

  const checkboxDivStyle = {
    margin: '0px 20px 0px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  };

  // const allFields = [
  //   'plannedGross',
  //   'plannedNet',
  //   'plannedNetNet',
  //   'plannedCtc',

  //   'plannedTrp',
  //   'plannedCpp',
  //   'plannedTechFee',
  //   'plannedAdOpsFee',
  //   'plannedAdServingCost',
  //   'plannedFeePercent',

  //   'budget',
  //   'cost',
  //   'universe',
  //   'trp',
  //   'spots',
  //   'impressions',
  //   'clicks'
  // ];

  const allFields = allEvalFieldsExceptTotal;

  let eligibleFields = [];
  let documentType;

  if (
    dataSourceAccountIds.length === 1 &&
    checkedDocumentTypes.length === 1 &&
    (templateDocument?.documentType
      ? checkedDocumentTypes[0] === templateDocument?.documentType
      : true)
  ) {
    documentType = checkedTypes[0];
  }

  switch (documentType) {
    // case 'manualDataEntry':
    //   eligibleFields = [
    //     'plannedGross',
    //     'plannedNet',
    //     'plannedNetNet',
    //     'plannedCtc',

    //     'plannedTrp',
    //     'plannedCpp',
    //     'plannedTechFee',
    //     'plannedAdOpsFee',
    //     'plannedAdServingCost',
    //     'plannedFeePercent',

    //     'budget',
    //     'cost',
    //     'universe',
    //     'trp',
    //     'spots',
    //     'impressions',
    //     'clicks'
    //   ];
    //   break;
    // case 'folder':
    //   eligibleFields = [
    //     'plannedGross',
    //     'plannedNet',
    //     'plannedNetNet',
    //     'plannedCtc',

    //     'plannedTrp',
    //     'plannedCpp',
    //     'plannedTechFee',
    //     'plannedAdOpsFee',
    //     'plannedAdServingCost',
    //     'plannedFeePercent',

    //     'budget',
    //     'universe'
    //   ];
    //   break;
    default:
      eligibleFields = [
        ...allEvalFieldsExceptTotal,
        'allowImpressionsBeforeStartDate',
        'allowImpressionsAfterEndDate',
        'allowFrontloadedDelivery',
        'allowMissingBudget',
        'allowMissingClicks',
        'allowAbnormallyHighCtr',
        'allowMissingImpressionsYesterday'

        // 'plannedGross',
        // 'plannedNet',
        // 'plannedNetNet',
        // 'plannedCtc',

        // 'plannedTrp',
        // 'plannedCpp',
        // 'plannedTechFee',
        // 'plannedAdOpsFee',
        // 'plannedAdServingCost',
        // 'plannedFeePercent',

        // 'budget',
        // 'universe'
      ];
  }

  useEffect(() => {
    // if (templateDocument && templateDocument?.fields) {
    //   const copyOfDocument = { ...document };

    //   for (let field of templateDocument?.fields) {
    //     switch (field) {
    //       case 'startDate':
    //       case 'endDate':
    //         copyOfDocument[field] = new Date(parseInt(templateDocument[field]));
    //         break;

    //       default:
    //         copyOfDocument[field] = templateDocument[field];
    //     }
    //   }
    //   setFields(templateDocument.fields);
    //   setDocument(copyOfDocument);
    // }
    setFields(
      templateDocument?.fields?.filter((field1) =>
        eligibleFields?.includes(field1)
      ) || []
    );
    setDocument({ ...document, ...templateDocument });
  }, [templateDocument]);

  // const allFieldsDict = {
  //   plannedGross: 'Planned Gross',
  //   plannedNet: 'Planned Net',
  //   plannedNetNet: 'Planned Net Net',
  //   plannedCtc: 'Planned Cost To Client',

  //   plannedTrp: 'Planned Target Rating Points',
  //   plannedCpp: 'Planned Cost Per Point',
  //   plannedTechFee: 'Planned Tech Fee',
  //   plannedAdOpsFee: 'Planned Ad Ops Fee',
  //   plannedAdServingCost: 'Planned Ad Serving Cost',
  //   plannedFeePercent: 'Planned Fee Percent',

  //   budget: 'Budget',
  //   cost: 'Cost',
  //   universe: 'Universe',
  //   trp: 'Target Rating Points',
  //   spots: 'Spots',
  //   impressions: 'Impressions',
  //   clicks: 'Clicks'
  // };

  const allFieldsDict = columnDictionary;

  const onClickRemoveHandler = (e, itemId) => {
    setCheckedBoxes(checkedBoxes?.filter((item) => item?._id !== itemId));
  };

  return (
    <>
      <div
        // className={classes.paper}
        style={{
          //   width: '100%',
          // height: '630px',
          display: 'flex',
          flexDirection: 'column'
          // alignItems: 'center'
        }}
      >
        <div
          style={{ alignSelf: 'start', fontSize: '14px', marginBottom: '10px' }}
        >
          Edit rows:
        </div>
        <div
          style={{
            // width: '600px',
            width: '100%',
            height: 'auto',
            backgroundColor: 'rgba(170,170,170,0.1)',
            borderRadius: '5px',
            overflow: 'scroll',
            padding: '20px',
            marginBottom: '25px'
          }}
        >
          {(checkedBoxes || [])?.map((row) => {
            let blankSpace = '';
            for (let i = 0; i < row.indentations; i++) {
              blankSpace = blankSpace + '         ';
            }
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    margin: '5px 0px',
                    whiteSpace: 'pre'
                  }}
                >
                  {/* <span>{blankSpace}</span> */}
                  <span
                    style={{
                      padding: '0',
                      fontSize: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <span
                      style={{
                        // fontSize: '12px',
                        padding: '6px',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                        // visibility: ['COPY', 'DUPLICATE'].includes(
                        //   copyOrDuplicateAction
                        // ) // !== 'COPY' && 'hidden'
                      }}
                      onClick={(e) => onClickRemoveHandler(e, row._id)}
                    >
                      <RemoveCircleIcon
                        style={{
                          fontSize: '12px',
                          // padding: '2px',
                          cursor: 'pointer'
                        }}
                        className="hover-light-grey"
                      />
                    </span>
                    <span
                      style={{ width: (row.indentations - 1) * 50 + 'px' }}
                    ></span>
                    <IconComponent documentType={row.documentType} />
                    <span style={{ marginLeft: '5px' }}>{row.name}</span>
                  </span>

                  <span style={{ fontSize: '8px', fontStyle: 'italic' }}>
                    {/* {row.path} */}
                  </span>
                </div>
                <hr style={{ border: '1px solid grey' }} className="solid"></hr>
              </>
            );
          })}
        </div>
        {/* <div style={{ width: '100%', margin: '10px 0px 50px 0px' }}>
          <SelectTemplate
            managerAccountId={currentManagerAccount?._id}
            accountId={accountId}
            value={templateDocument}
            setValue={setTemplateDocument}
          />
        </div> */}
        <div
          style={{
            border: '1px dotted rgba(150,150,150,0.7)',
            borderRadius: '5px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
          }}
        >
          <div
            style={{
              marginTop: '10px',
              marginLeft: '10px',
              marginBottom: '10px',
              fontWeight: 'bold',
              // color: 'rgba(150,150,150,0.7)'
              color:
                theme.palette.mode === 'dark'
                  ? 'rgba(177 ,177, 181, 0.6)'
                  : 'rgba(160,160,160,0.6)'
            }}
          >
            Most frequently used
          </div>

          <div style={rowStyle}>
            <div style={checkboxDivStyle}>
              <Checkbox
                size="small"
                checked={fields.includes('status')}
                onChange={() => {
                  onCheckboxChange('status');
                }}
                label="Edit Status"
              />
            </div>
            <div>
              <SelectStatus
                size="small"
                overrideStatus={fields.includes('status')}
                document={document}
                setDocument={setDocument}
              />
            </div>
          </div>
          <div style={rowStyle}>
            <div style={checkboxDivStyle}>
              <Checkbox
                size="small"
                checked={fields.includes('invoiceStatus')}
                onChange={() => {
                  onCheckboxChange('invoiceStatus');
                }}
                label="Edit Status"
              />
            </div>
            <div>
              <SelectInvoiceStatus
                size="small"
                overrideStatus={fields.includes('invoiceStatus')}
                document={document}
                setDocument={setDocument}
              />
            </div>
          </div>
          <div style={{ ...rowStyle, alignItems: 'center' }}>
            <div style={checkboxDivStyle}>
              <Checkbox
                size="small"
                checked={fields.includes('startDate')}
                onChange={() => {
                  onCheckboxChange('startDate');
                }}
                label="Start Date"
              />
            </div>
            <div>
              <BasicDatePicker
                disabled={!fields.includes('startDate')}
                document={document}
                setDocument={setDocument}
                documentKey={'startDate'}
                label="Start Date"
                size="small"
              />
            </div>
          </div>
          <div style={{ ...rowStyle, alignItems: 'center' }}>
            <div style={checkboxDivStyle}>
              <Checkbox
                size="small"
                checked={fields.includes('endDate')}
                onChange={() => {
                  onCheckboxChange('endDate');
                }}
                label="End Date"
              />
            </div>
            <div>
              <BasicDatePicker
                disabled={!fields.includes('endDate')}
                document={document}
                setDocument={setDocument}
                documentKey={'endDate'}
                label="End Date"
              />
            </div>
          </div>
          <div style={{ ...rowStyle, alignItems: 'center' }}>
            <div style={checkboxDivStyle}>
              <Checkbox
                size="small"
                checked={fields.includes('members')}
                onChange={() => {
                  onCheckboxChange('members');
                }}
                label="Members"
              />
            </div>
            <div>
              <SelectMembers
                accountId={accountId}
                disabled={!fields.includes('members')}
                document={document}
                setDocument={setDocument}
                documentKey={'members'}
                label="Members"
              />
            </div>
          </div>
          {['budget', 'cost'].map((field) => (
            <div style={rowStyle}>
              <div style={checkboxDivStyle}>
                <Checkbox
                  disabled={!eligibleFields.includes(field)}
                  size="small"
                  checked={fields.includes(field)}
                  onChange={() => {
                    onCheckboxChange(field);
                  }}
                  label={field}
                />
              </div>
              <div>
                <TextField
                  style={{ width: '300px' }}
                  variant="standard"
                  size="small"
                  disabled={!fields.includes(field)}
                  // document={document}
                  value={document[field] || ''}
                  onChange={(e) =>
                    setDocument({ ...document, [field]: e.target.value })
                  }
                  // documentKey={'endDate'}
                  label={allFieldsDict[field]}
                />
              </div>
            </div>
          ))}
          {[
            'allowImpressionsBeforeStartDate',
            'allowImpressionsAfterEndDate',
            'allowFrontloadedDelivery',
            'allowMissingBudget',
            'allowMissingClicks',
            'allowAbnormallyHighCtr',
            'allowMissingImpressionsYesterday'
          ].map((field) => (
            <div style={rowStyle}>
              <div style={checkboxDivStyle}>
                <Checkbox
                  disabled={!eligibleFields.includes(field)}
                  size="small"
                  checked={fields.includes(field)}
                  onChange={() => {
                    onCheckboxChange(field);
                  }}
                  label={field}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '400px'
                }}
              >
                <span
                  style={{
                    color: !fields.includes(field)
                      ? theme.palette.mode === 'dark'
                        ? 'rgb(177 177 181)'
                        : 'rgba(160,160,160,0.9)'
                      : undefined
                  }}
                >
                  {allFieldsDict[field]}
                </span>
                <Checkbox
                  disabled={!fields.includes(field)}
                  size="small"
                  checked={document[field] || false}
                  // value={document[field] || false}
                  onChange={(e) =>
                    setDocument({ ...document, [field]: !document[field] })
                  }
                  label={field}
                />
                {/* <TextField
                  style={{ width: '300px' }}
                  variant="standard"
                  size="small"
                  disabled={!fields.includes(field)}
                  // document={document}
                  value={document[field] || ''}
                  onChange={(e) =>
                    setDocument({ ...document, [field]: e.target.value })
                  }
                  // documentKey={'endDate'}
                  label={allFieldsDict[field]}
                /> */}
              </div>
            </div>
          ))}
          <div
            style={
              {
                // margin: '5px 0px 5px 0px'
              }
            }
          >
            {isActionBulkEditAllowed &&
              ['action01', 'action02', 'action03'].map((itemKey) => (
                <div
                  style={{
                    ...rowStyle,
                    marginTop: '20px',
                    marginBottom: '20px'
                  }}
                >
                  <div style={checkboxDivStyle}>
                    <Checkbox
                      size="small"
                      checked={fields.includes(itemKey)}
                      onChange={() => {
                        onCheckboxChange(itemKey);
                      }}
                      label={'Edit ' + itemKey}
                    />
                  </div>

                  <BulkEditActionSettings
                    item={checkedBoxes[0]}
                    itemKey={itemKey}
                    document={document}
                    setDocument={setDocument}
                    disabled={!fields.includes(itemKey)}
                  />
                </div>
              ))}
          </div>
        </div>
        <div
          style={{
            marginTop: '20px',
            border: '1px dotted rgba(150,150,150,0.7)',
            borderRadius: '5px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
          }}
        >
          <div
            style={{
              marginTop: '10px',
              marginLeft: '10px',
              marginBottom: '10px',
              fontWeight: 'bold',

              // color: 'rgba(150,150,150,0.7)'
              color:
                theme.palette.mode === 'dark'
                  ? 'rgba(177 ,177, 181, 0.6)'
                  : 'rgba(160,160,160,0.6)'
            }}
          >
            Less frequently used
          </div>
          {allFields
            .filter((field) => !['cost', 'budget'].includes(field))
            .map((field) => (
              <div style={rowStyle}>
                <div style={checkboxDivStyle}>
                  <Checkbox
                    disabled={!eligibleFields.includes(field)}
                    size="small"
                    checked={fields.includes(field)}
                    onChange={() => {
                      onCheckboxChange(field);
                    }}
                    label={field}
                  />
                </div>
                <div>
                  <TextField
                    style={{ width: '300px' }}
                    variant="standard"
                    size="small"
                    disabled={!fields.includes(field)}
                    // document={document}
                    value={document[field] || ''}
                    onChange={(e) =>
                      setDocument({ ...document, [field]: e.target.value })
                    }
                    // documentKey={'endDate'}
                    label={allFieldsDict[field]}
                  />
                </div>
              </div>
            ))}
        </div>
        {/* <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            margin: '12px 0px'
          }}
        >
          <div
            className="hover-grey"
            style={{ margin: '30px 0px 10px 0px', cursor: 'pointer' }}
            onClick={() => setSaveTemplateMenuOpen(!saveTemplateMenuOpen)}
          >
            Save as template
          </div>

          {saveTemplateMenuOpen && (
            <div>
              <TextField variant="standard" style={{ width: '400px' }} />
            </div>
          )}
        </div> */}

        {/* <div
          style={{
            gridColumn: 4,
            textAlign: 'center',
            marginTop: '50px',
            alignSelf: 'end',
            paddingRight: '25px'
          }}
        >
          <Button variant="outlined" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ minWidth: '91px', marginLeft: '20px' }}
            variant="contained"
            size="small"
            color="primary"
            onClick={onSaveHandler}
          >
            Apply
          </Button>
        </div> */}
      </div>
    </>
  );
}

export default EditDialogContent;
