import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import Icon from '@mui/material/Icon';
// import SaveIcon from '@mui/icons-material/Save';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     margin: theme.spacing(1),
//     height: '80px',
//     width: '300px'
//     // backgroundColor: '#fafafa'
//   }
// }));

export default function IconLabelButtons({
  text,
  onClickOnNavManagerAccountHandler,
  managerAccount,
  cardBackgroundImage,
  color
}) {
  // const classes = useStyles();

  return (
    <div key={managerAccount?._id}>
      <Button
        key={managerAccount?._id}
        style={{
          backgroundImage: cardBackgroundImage,
          color: color,
          margin: '10px',
          height: '80px',
          width: '300px'
        }}
        variant="contained"
        // color="default"
        // className={classes.button}
        startIcon={<AccountBalanceIcon />}
        onClick={(e) => onClickOnNavManagerAccountHandler(e, managerAccount)}
      >
        {text}
      </Button>
    </div>
  );
}
