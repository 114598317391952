import React, { useContext } from 'react';
import { MenuItem } from '@mui/material';

import html2canvas from 'html2canvas';

import { jsPDF } from 'jspdf';

import { AccountContext } from '../../../context/AccountContext';

import { InterfaceContext } from './../../../context/InterfaceContext';

const saveAs = (blob, fileName) => {
  var elem = window.document.createElement('a');
  elem.href = blob;
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

function ExportPDF({ handleClose }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const { setExportMode } = useContext(InterfaceContext);
  const savePDF = async () => {
    const logoUrl =
      currentManagerAccount?.logo ||
      'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

    const domElement = document.getElementById('nexustable-table');
    domElement.style.position = 'fixed';
    domElement.style.padding = '50px';

    const gridContainerDomElement = document.getElementById('gridContainer');

    gridContainerDomElement.style.height = 'unset';

    var managerAccountLogo_IMG = document.createElement('img');
    managerAccountLogo_IMG.src = logoUrl;

    //remove bottom shadow

    const bottomShadowElement = document.getElementById('bottomShadow');
    if (bottomShadowElement) {
      bottomShadowElement.style.visibility = 'hidden';
    }

    const topShadowElement = document.getElementById('topShadow');
    if (topShadowElement) {
      topShadowElement.style.visibility = 'hidden';
    }

    managerAccountLogo_IMG.style.width = 'auto';
    managerAccountLogo_IMG.style.height = '100px';
    managerAccountLogo_IMG.style.margin = '10px 0px 40px 0px';
    // domElement.appendChild(DOM_img);
    domElement.insertBefore(managerAccountLogo_IMG, domElement.firstChild);

    var NexusTableLogo_IMG = document.createElement('img');
    var NexusTableLogo_DIV = document.createElement('div');
    var NexusTableLogo_TEXT = document.createElement('div');
    NexusTableLogo_DIV.style.display = 'flex';
    NexusTableLogo_DIV.style.marginTop = '10px';
    NexusTableLogo_DIV.style.alignItems = 'center';
    NexusTableLogo_TEXT.style.fontSize = '8px';
    NexusTableLogo_TEXT.innerText = 'Powered by ';
    NexusTableLogo_TEXT.style.verticalAlign = 'center';
    NexusTableLogo_TEXT.style.padding = '0px 0px 2px 0px';

    NexusTableLogo_IMG.src =
      'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

    NexusTableLogo_IMG.style.width = '60px';
    NexusTableLogo_IMG.style.height = 'auto';
    NexusTableLogo_IMG.style.marginLeft = '10px';

    NexusTableLogo_DIV.style.display = 'flex';
    NexusTableLogo_DIV.style.flexDirection = 'row';

    NexusTableLogo_DIV.style.alignContent = 'center';
    NexusTableLogo_DIV.style.alignItems = 'center';

    NexusTableLogo_DIV.appendChild(NexusTableLogo_TEXT);
    NexusTableLogo_DIV.appendChild(NexusTableLogo_IMG);

    domElement.appendChild(NexusTableLogo_DIV);

    html2canvas(domElement, {
      scale: 2,
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true
    }).then((canvas) => {
      // var image = canvas.toDataURL('image/png', 1.0);
      var wid;
      var hgt;
      var image = canvas.toDataURL(
        'image/png',
        (wid = canvas.width),
        (hgt = canvas.height)
      );

      var doc = new jsPDF('l', 'px', [wid, hgt], true);
      console.log('ExprotPDF>wid: ', wid);
      console.log('ExprotPDF>hgt: ', hgt);

      // var fileName = currentExport.split('Export')[0] + '.png';
      const fileName = 'NexusTable_export.pdf';
      // saveAs(image, fileName);

      // var doc = new jsPDF('l', 'px', 'a4');
      const padding = 20;
      var hratio = hgt / wid;
      var width = doc.internal.pageSize.width - padding * 2;
      var height = width * hratio;
      doc.addImage(image, 'PNG', padding, padding, width, height);
      doc.save('NexusTable_export.pdf');
    });
    domElement.style.position = 'unset';
    domElement.style.padding = '0';
    managerAccountLogo_IMG.remove();
    // NexusTableLogo_IMG.remove();
    NexusTableLogo_DIV.remove();
    gridContainerDomElement.style.height = 'calc(100vh - 140px)';
    if (bottomShadowElement) {
      bottomShadowElement.style.visibility = 'visible';
    }
    if (topShadowElement) {
      topShadowElement.style.visibility = 'visible';
    }

    setExportMode(false);
    handleClose();
  };
  return <MenuItem onClick={savePDF}>PDF</MenuItem>;
}

export default ExportPDF;
