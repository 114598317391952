import React, { useState, useEffect, useContext } from 'react';
import SimpleTooltip from './SimpleTooltip';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../../context/InterfaceContext';
import { MutationContext } from '../../../../../context/MutationContext';

import formatDateYYYYMMDD from './../../../roitable_functions/formatDateYYYYMMDD';
// #################### imports regarding date range #################################
const Moment = require('moment');
const MomentRange = require('moment-range');

// ######################################################################################

// Returns an array of dates between the two dates

//

function GanttItem({
  enqueueSnackbar,
  itemStyle,
  keyForMap,
  itemKey,
  range,
  selectedDateStart,
  selectedDateStop,
  itemDateStart,
  itemDateStop,
  itemValue,
  rowNumber,
  columnNumber,
  item,
  ganttWidth,
  // updateDocument,
  currentManagerAccountId,
  checked,
  openRows
}) {
  const { updateDocument } = useContext(MutationContext);

  const open =
    openRows.filter((row) => {
      return row._id === item._id;
    }).length > 0;

  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const backgroundPosition =
    7 - (new Date(selectedDateStart).getDay() - 1) * ganttWidth - 8 + 'px';
  // const [rangeToStart] = useState([]);
  const differnceInDays = (start, stop) => {
    var date1 = new Date(start);
    var date2 = new Date(stop);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
  };

  const addDaysToDateString = (date_string, count) => {
    if (count === null) {
      var snackbarMessage = 'Not so fast';
      var snackbarVariant = 'error';
      enqueueSnackbar(snackbarMessage, {
        variant: snackbarVariant
      });
      return;
    }
    const updated_date = new Date(date_string);

    updated_date.setDate(updated_date.getDate() + count);

    // const date = updated_date.toISOString().split('T')[0];
    const date = formatDateYYYYMMDD(updated_date);
    return date;
  };

  let mouseDownPageX;
  let offsetLeft;
  let leftX;
  let rightX;
  let mouseUpPageX;
  let new_date_start_count;
  let new_date_stop_count;

  const handleMouseDownOutsideBar = (e) => {
    if (!item.startDate && !item.endDate) {
      e.stopPropagation();

      mouseDownPageX = e.pageX;

      leftX = e.currentTarget.getBoundingClientRect().left;
      let w = mouseDownPageX - leftX;
      let count = 0;
      while (w > 0) {
        w = w - ganttWidth;
        w > 0 && count++;
      }
      new_date_start_count = count;

      //   leftX = null;
    } else {
    }
  };

  const handleMouseDown = (e, keyToUpdate) => {
    mouseOn = true;
    leftX = e.currentTarget.getBoundingClientRect().left;
    rightX = e.currentTarget.getBoundingClientRect().right;

    mouseDownPageX = e.pageX;

    offsetLeft = e.currentTarget.offsetLeft;
    targetElementKey = keyToUpdate;
  };

  const sendToDataBase = async (date, itemKey) => {
    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: date
        }
      ],
      updateKeys: [itemKey]
    };

    // updateDocument({ variables: { input: { ...documentInput } } });

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });

      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };

  const sendToDataBase2 = async (dates, itemKey) => {
    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          ...dates,
          accountId: item.accountId,
          documentType: item.documentType,
          documentId: item._id
        }
      ],
      updateKeys: ['startDate', 'endDate']
    };

    // updateDocument({ variables: { input: { ...documentInput } } });

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });

      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };

  const handleMouseUp = (e) => {
    if (!item.startDate && !item.endDate) {
      //   e.stopPropagation();

      mouseDownPageX = e.pageX;

      leftX = e.currentTarget.getBoundingClientRect().left;
      let w = mouseDownPageX - leftX;
      let count = null;
      while (w > 0) {
        w = w - ganttWidth;
        w > 0 && count++;
      }
      new_date_stop_count = count;
      if (new_date_start_count === null) {
        var snackbarMessage = 'Not so fast';
        var snackbarVariant = 'error';
        enqueueSnackbar(snackbarMessage, {
          variant: snackbarVariant
        });
        return '';
      }

      //   leftX = null;
      const new_mouse_down_date = addDaysToDateString(
        selectedDateStart,
        new_date_start_count
      );
      const new_mouse_up_date = addDaysToDateString(
        selectedDateStart,
        new_date_stop_count
      );

      if (!new_mouse_down_date || !new_mouse_up_date) {
        return;
      }

      let new_start_date;
      let new_stop_date;
      if (new Date(new_mouse_down_date) < new Date(new_mouse_up_date)) {
        new_start_date = new_mouse_down_date;
        new_stop_date = new_mouse_up_date;
      } else {
        new_start_date = new_mouse_up_date;
        new_stop_date = new_mouse_down_date;
      }

      if (
        [
          'folder',
          'task',
          'subTask',
          'taxonomy',
          'manualDataEntry',
          'customForm'
          // 'manualDataEntry'
        ].includes(item.documentType)
      ) {
        sendToDataBase2(
          { startDate: new_start_date, endDate: new_stop_date },
          'startDate_endDate'
        );
      } else {
        sendToDataBase(new_stop_date, 'endDate');
        sendToDataBase(new_start_date, 'startDate');
      }
    } else {
      let firstDate = true;
      mouseUpPageX = e.pageX;
      let count = 0;

      if (
        !(showLeftBarResize || showRightBarResize) ||
        !mouseDownPageX ||
        !mouseOn
      ) {
        return;
      } else {
      }

      let firstDateWidth;

      if (targetElementKey === 'startDate') {
        let w = mouseUpPageX - leftX;
        firstDateWidth = mouseDownPageX - leftX;

        if (w > 0) {
          while (w > 0) {
            if (firstDate) {
              w = w - (ganttWidth - firstDateWidth);
              firstDate = false;
            } else {
              w = w - ganttWidth;
            }

            w > 0 && count++;
          }
        } else {
          while (w < 0) {
            if (firstDate) {
              w = w + firstDateWidth;
              firstDate = false;
            } else {
              w = w + ganttWidth;
            }

            w < 0 && count--;
          }
        }
      } else {
        let w = mouseUpPageX - rightX;
        firstDateWidth = (rightX - leftX) % ganttWidth;
        // firstDateWidth = rightX - mouseDownPageX;

        if (w > 0) {
          while (w > 0) {
            if (firstDate) {
              w = w - firstDateWidth;
              firstDate = false;
            } else {
              w = w - ganttWidth;
            }

            w > 0 && count++;
          }
        } else {
          while (w < 0) {
            if (firstDate) {
              w = w + (ganttWidth - firstDateWidth);
              firstDate = false;
            } else {
              w = w + ganttWidth;
            }

            w < 0 && count--;
          }
        }
      }
      if (count === 0) {
        mouseOn = false;
        return;
      }

      mouseDownPageX = '';
      let date;
      if (targetElementKey === 'startDate') {
        date = addDaysToDateString(item.startDate, count);
      } else {
        date = date = addDaysToDateString(item.endDate, count);
      }

      sendToDataBase(date, targetElementKey);
      targetElementKey = '';
    }
  };

  let mouseOn = false;
  const onMouseLeaveHandler = () => {
    mouseOn = false;
    targetElementKey = '';
  };

  let targetElementKey;

  // ############################### fix ##################

  if (
    [
      'account',
      'totalActionsDocument',
      'globalTasks',
      'facebook_ads_ad_set',
      'google_ads_ad_group',
      'dv360_line_item',
      'snapchat_ad_squad',
      'tiktok_ad_group',
      'adform_line_item'
    ].includes(item.type) ||
    (itemDateStop && new Date(selectedDateStart) > new Date(itemDateStop)) ||
    (itemDateStart && new Date(selectedDateStop) < new Date(itemDateStart)) ||
    (item.ganttBarDisplaySetting === 'CHILDREN' && open)
  ) {
    return (
      <div
        ref={ref}
        style={{
          maxHeight: '46px',
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          backgroundImage:
            'linear-gradient(90deg, rgba(120, 120, 120, 0.1) 1px, rgba(229, 229, 247,0) 1px)',
          backgroundSize: ganttWidth * 7 + 'px',
          backgroundPosition,
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          columnRow: columnNumber,
          gridRow: rowNumber + 2,
          // gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          // padding: '12px 15px',
          whiteSpace: 'nowrap',
          paddingTop: '5px',
          paddingBottom: '5px',
          userSelect: 'none'
        }}
      ></div>
    );
  }

  const ganttBars = (item.childRows || []).map((childItem, index) => {
    const range_start =
      new Date(selectedDateStart) < new Date(childItem.startDate)
        ? childItem.startDate
        : selectedDateStart;

    const range_stop =
      new Date(selectedDateStop) > new Date(childItem.endDate)
        ? childItem.endDate
        : selectedDateStop;

    const dateRange = [];
    for (let day of range.by('day')) {
      dateRange.push(day.format('MM-DD'));
    }

    const marginLeft =
      selectedDateStart && range_start && childItem.startDate
        ? differnceInDays(selectedDateStart, range_start) * ganttWidth + 'px'
        : '0px';
    const width =
      range_start && range_stop
        ? (differnceInDays(range_start, range_stop) + 1) * ganttWidth + 'px'
        : '0px';

    let borderRadius;

    const showLeftBarResize =
      new Date(selectedDateStart) <= new Date(childItem.startDate);
    const showRightBarResize =
      new Date(selectedDateStop) >= new Date(childItem.endDate);

    switch (childItem.type) {
      case 'account':
      case 'folder':
        if (showLeftBarResize && showRightBarResize) {
          borderRadius = '5px';
        } else if (showLeftBarResize) {
          borderRadius = '5px 0px 0px 5px';
        } else if (showRightBarResize) {
          borderRadius = '0px 5px 5px 0px';
        }

        break;
      case 'task':
        if (showLeftBarResize && showRightBarResize) {
          borderRadius = '0px 0px 0px 0px';
        } else if (showLeftBarResize) {
          borderRadius = '0px 0px 0px 0px';
        } else if (showRightBarResize) {
          borderRadius = '0px 0px 0px 0px';
        }
        break;
      case 'subTask':
        if (showLeftBarResize && showRightBarResize) {
          borderRadius = '0px 10px 0px 10px';
        } else if (showLeftBarResize) {
          borderRadius = '0px 0px 0px 10px';
        } else if (showRightBarResize) {
          borderRadius = '0px 10px 0px 0px';
        }
      case 'taxonomy':
        if (showLeftBarResize && showRightBarResize) {
          borderRadius = '0px 0px 0px 0px';
        } else if (showLeftBarResize) {
          borderRadius = '0px 0px 0px 0px';
        } else if (showRightBarResize) {
          borderRadius = '0px 0px 0px 0px';
        }
        break;
        // if (showLeftBarResize && showRightBarResize) {
        //   borderRadius = '10px 0px 10px 0px';
        // } else if (showLeftBarResize) {
        //   borderRadius = '10px 0px 0px 0px';
        // } else if (showRightBarResize) {
        //   borderRadius = '0px 0px 10px 0px';
        // }
        break;
      default:
        if (showLeftBarResize && showRightBarResize) {
          borderRadius = '20px';
        } else if (showLeftBarResize) {
          borderRadius = '20px 0px 0px 20px';
        } else if (showRightBarResize) {
          borderRadius = '0px 20px 20px 0px';
        }
    }

    let barStyle = {
      marginLeft: marginLeft,
      width: width,
      borderRadius: borderRadius,
      textAlign: 'center',
      overflow: 'hidden',
      backgroundColor:
        childItem?.style?.gantt?.bar?.backgroundColor || 'rgb(178, 67, 147)',
      gridRow: 1,
      gridColumnEnd: 1,
      border: '1px solid rgba(255,255,255,0.7)'
    };

    return (
      <div
        key={index}
        style={{
          ...barStyle,
          display: 'grid',
          gridTemplateColumns: '10px auto 10px'
        }}
      >
        <div
          // className="hover-transparent-white"
          style={{
            userSelect: 'none',
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 1,
            // position: 'absolute',
            visibility: showLeftBarResize ? 'visible' : 'hidden',
            backgroundColor: 'rgba(255,255,255,0)'
            // backgroundColor: 'blue',
            // width: '10px',
            // height: '43.8px',
            // cursor: 'col-resize'
          }}
          // onMouseDown={(e) => handleMouseDown(e, 'startDate')}
        ></div>
        <div
          style={{
            // paddingTop: '10px',
            // display: 'grid',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gridColumnStart: 2,
            gridColumnEnd: 2,
            color: 'white',
            fontWeight: '900',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            cursor: 'pointer'
            // backgroundColor: 'green'
          }}
        >
          <SimpleTooltip item={childItem} showDot={true}>
            {childItem['name']}
          </SimpleTooltip>
        </div>
        <div
          // className="hover-transparent-white"
          style={{
            gridColumnStart: 3,
            gridColumnEnd: 3,
            userSelect: 'none',
            display: 'grid',
            // position: 'absolute',
            visibility: showRightBarResize ? 'visible' : 'hidden',
            backgroundColor: 'rgba(255,255,255,0)',
            // backgroundColor: 'red',
            // width: '10px',
            // height: '43.8px',
            // cursor: 'col-resize',
            right: '0px'
          }}
          // onMouseDown={(e) => handleMouseDown(e, 'endDate')}
        ></div>
      </div>
    );
  });

  // ################################################################################################## TEST ######################################################################################################
  if (!open && item.ganttBarDisplaySetting === 'CHILDREN') {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          // backgroundColor: 'red',
          // backgroundColor: '#e5e5f7',
          // opacity: '0.8',
          // backgroundImage: 'linear-gradient(#444cf7 2px, transparent 2px), linear-gradient(90deg, #444cf7 2px, transparent 2px), linear-gradient(#444cf7 1px, transparent 1px), linear-gradient(90deg, #444cf7 1px, #e5e5f7 1px)',
          backgroundImage:
            'linear-gradient(90deg, rgba(120, 120, 120, 0.1) 1px, rgba(229, 229, 247,0) 1px)',
          backgroundSize: ganttWidth * 7 + 'px',
          backgroundPosition,
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          // backgroundColor: 'red',
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          // padding: '12px 15px',
          whiteSpace: 'nowrap',
          paddingTop: '5px',
          paddingBottom: '5px'
        }}
        onMouseDown={handleMouseDownOutsideBar}
        onMouseLeave={onMouseLeaveHandler}
        onMouseUp={(e) => handleMouseUp(e)}
      >
        {
          (inView || exportMode) && ganttBars
          // <div
          //   style={{
          //     ...barStyle,
          //     display: 'grid',
          //     gridTemplateColumns: '10px auto 10px'
          //   }}
          // >
          //   <div
          //     className="hover-transparent-white"
          //     style={{
          //       userSelect: 'none',
          //       display: 'grid',
          //       gridColumnStart: 1,
          //       gridColumnEnd: 1,
          //       // position: 'absolute',
          //       visibility: showLeftBarResize ? 'visible' : 'hidden',
          //       backgroundColor: 'rgba(255,255,255,0)',
          //       // backgroundColor: 'blue',
          //       // width: '10px',
          //       // height: '43.8px',
          //       cursor: 'col-resize'
          //     }}
          //     onMouseDown={(e) => handleMouseDown(e, 'startDate')}
          //     //   onMouseUp={handleMouseUp}
          //   ></div>
          //   <div
          //     style={{
          //       // paddingTop: '10px',
          //       // display: 'grid',
          //       display: 'flex',
          //       flexDirection: 'column',
          //       justifyContent: 'center',
          //       gridColumnStart: 2,
          //       gridColumnEnd: 2,
          //       color: 'white',
          //       fontWeight: '900',
          //       overflow: 'hidden',
          //       textOverflow: 'ellipsis',
          //       width: '100%',
          //       cursor: 'pointer'
          //       // backgroundColor: 'green'
          //     }}
          //   >
          //     <SimpleTooltip item={item}>{item['name']}</SimpleTooltip>
          //   </div>
          //   <div
          //     className="hover-transparent-white"
          //     style={{
          //       gridColumnStart: 3,
          //       gridColumnEnd: 3,
          //       userSelect: 'none',
          //       display: 'grid',
          //       // position: 'absolute',
          //       visibility: showRightBarResize ? 'visible' : 'hidden',
          //       backgroundColor: 'rgba(255,255,255,0)',
          //       // backgroundColor: 'red',
          //       // width: '10px',
          //       // height: '43.8px',
          //       cursor: 'col-resize',
          //       right: '0px'
          //     }}
          //     onMouseDown={(e) => handleMouseDown(e, 'endDate')}
          //     //   onMouseUp={handleMouseUp}
          //   ></div>
          // </div>
        }
      </div>
    );
  }

  const showLeftBarResize =
    itemDateStart && new Date(selectedDateStart) <= new Date(itemDateStart);
  const showRightBarResize =
    itemDateStop && new Date(selectedDateStop) >= new Date(itemDateStop);
  if (
    !(itemDateStart || itemDateStop)
    // || !itemDateStop
  ) {
    return (
      <div
        ref={ref}
        style={{
          maxHeight: '46px',
          userSelect: 'none',
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          backgroundImage:
            'linear-gradient(90deg, rgba(120, 120, 120, 0.1) 1px, rgba(229, 229, 247,0) 1px)',
          backgroundSize: ganttWidth * 7 + 'px',
          backgroundPosition,
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          // padding: '12px 15px',
          whiteSpace: 'nowrap',
          paddingTop: '5px',
          paddingBottom: '5px',
          cursor: 'col-resize'
        }}
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDownOutsideBar}
      ></div>
    );
  }

  // #########################################################

  const range_start =
    itemDateStart && new Date(selectedDateStart) < new Date(itemDateStart)
      ? itemDateStart
      : selectedDateStart;

  const range_stop =
    itemDateStop && new Date(selectedDateStop) > new Date(itemDateStop)
      ? itemDateStop
      : selectedDateStop;

  const dateRange = [];
  for (let day of range.by('day')) {
    dateRange.push(day.format('MM-DD'));
  }

  const marginLeft =
    selectedDateStart && range_start && itemDateStart
      ? differnceInDays(selectedDateStart, range_start) * ganttWidth + 'px'
      : '0px';
  const width =
    range_start && range_stop
      ? (differnceInDays(range_start, range_stop) + 1) * ganttWidth + 'px'
      : '0px';

  let borderRadius;

  switch (item.type) {
    case 'account':
    case 'folder':
      if (showLeftBarResize && showRightBarResize) {
        borderRadius = '5px';
      } else if (showLeftBarResize) {
        borderRadius = '5px 0px 0px 5px';
      } else if (showRightBarResize) {
        borderRadius = '0px 5px 5px 0px';
      }

      break;
    case 'task':
      if (showLeftBarResize && showRightBarResize) {
        borderRadius = '0px 0px 0px 0px';
      } else if (showLeftBarResize) {
        borderRadius = '0px 0px 0px 0px';
      } else if (showRightBarResize) {
        borderRadius = '0px 0px 0px 0px';
      }
      break;
    case 'subTask':
      if (showLeftBarResize && showRightBarResize) {
        borderRadius = '0px 10px 0px 10px';
      } else if (showLeftBarResize) {
        borderRadius = '0px 0px 0px 10px';
      } else if (showRightBarResize) {
        borderRadius = '0px 10px 0px 0px';
      }
    case 'taxonomy':
      if (showLeftBarResize && showRightBarResize) {
        borderRadius = '0px 0px 0px 0px';
      } else if (showLeftBarResize) {
        borderRadius = '0px 0px 0px 0px';
      } else if (showRightBarResize) {
        borderRadius = '0px 0px 0px 0px';
      }
      break;
      // if (showLeftBarResize && showRightBarResize) {
      //   borderRadius = '10px 0px 10px 0px';
      // } else if (showLeftBarResize) {
      //   borderRadius = '10px 0px 0px 0px';
      // } else if (showRightBarResize) {
      //   borderRadius = '0px 0px 10px 0px';
      // }
      break;
    default:
      if (showLeftBarResize && showRightBarResize) {
        borderRadius = '20px';
      } else if (showLeftBarResize) {
        borderRadius = '20px 0px 0px 20px';
      } else if (showRightBarResize) {
        borderRadius = '0px 20px 20px 0px';
      }
  }

  let barStyle = {
    marginLeft: marginLeft,
    width: width,
    borderRadius: borderRadius,
    textAlign: 'center',
    overflow: 'hidden',
    border: '1px solid rgba(255,255,255,0.7)'
  };
  if (!showLeftBarResize) barStyle.borderLeft = 'none';
  if (!showRightBarResize) barStyle.borderRight = 'none';

  if (item?.style?.gantt?.bar?.backgroundColor) {
    barStyle = {
      ...barStyle,
      backgroundColor: item.style.gantt.bar.backgroundColor
    };
  } else {
    barStyle = {
      ...barStyle,
      // backgroundColor: 'rgba(90,241,248,0.7'
      backgroundColor: 'rgb(178,67,147)'
    };
  }

  if (item.type !== 'plan' && item.type !== 'category') {
  }

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        // backgroundColor: 'red',
        // backgroundColor: '#e5e5f7',
        // opacity: '0.8',
        // backgroundImage: 'linear-gradient(#444cf7 2px, transparent 2px), linear-gradient(90deg, #444cf7 2px, transparent 2px), linear-gradient(#444cf7 1px, transparent 1px), linear-gradient(90deg, #444cf7 1px, #e5e5f7 1px)',
        backgroundImage:
          'linear-gradient(90deg, rgba(120, 120, 120, 0.1) 1px, rgba(229, 229, 247,0) 1px)',
        backgroundSize: ganttWidth * 7 + 'px',
        backgroundPosition,
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        // backgroundColor: 'red',
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'grid',
        height: '100%',
        width: '100%',
        // padding: '12px 15px',
        whiteSpace: 'nowrap',
        paddingTop: '5px',
        paddingBottom: '5px'
      }}
      onMouseDown={handleMouseDownOutsideBar}
      onMouseLeave={onMouseLeaveHandler}
      onMouseUp={(e) => handleMouseUp(e)}
    >
      {(inView || exportMode) && (
        <div
          style={{
            ...barStyle,
            display: 'grid',
            gridTemplateColumns: '10px auto 10px'
          }}
        >
          <div
            className="hover-transparent-white"
            style={{
              userSelect: 'none',
              display: 'grid',
              gridColumnStart: 1,
              gridColumnEnd: 1,
              // position: 'absolute',
              visibility: showLeftBarResize ? 'visible' : 'hidden',
              backgroundColor: 'rgba(255,255,255,0)',
              // backgroundColor: 'blue',
              // width: '10px',
              // height: '43.8px',
              cursor: 'col-resize'
            }}
            onMouseDown={(e) => handleMouseDown(e, 'startDate')}
            //   onMouseUp={handleMouseUp}
          ></div>
          <div
            style={{
              // paddingTop: '10px',
              // display: 'grid',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gridColumnStart: 2,
              gridColumnEnd: 2,
              color: 'white',
              fontWeight: '900',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
              cursor: 'pointer'
              // backgroundColor: 'green'
            }}
          >
            <SimpleTooltip item={item} showDot={true}>
              {item['name']}
            </SimpleTooltip>
          </div>
          <div
            className="hover-transparent-white"
            style={{
              gridColumnStart: 3,
              gridColumnEnd: 3,
              userSelect: 'none',
              display: 'grid',
              // position: 'absolute',
              visibility: showRightBarResize ? 'visible' : 'hidden',
              backgroundColor: 'rgba(255,255,255,0)',
              // backgroundColor: 'red',
              // width: '10px',
              // height: '43.8px',
              cursor: 'col-resize',
              right: '0px'
            }}
            onMouseDown={(e) => handleMouseDown(e, 'endDate')}
            //   onMouseUp={handleMouseUp}
          ></div>
        </div>
      )}
    </div>
  );
}

export default React.memo(GanttItem);
