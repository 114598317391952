import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';

import SimpleTooltip from '../SimpleTootip';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

import { MutationContext } from '../../../../../context/MutationContext';

import ClickAwayListener from '@mui/material/ClickAwayListener';

import Popover from './Popover';

import WarningDotToolTip from './../WarningDotToolTip';

import {
  atan2,
  chain,
  derivative,
  e,
  evaluate,
  log,
  pi,
  pow,
  round,
  sqrt
} from 'mathjs';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function MathEvalItem({
  currentManagerAccountId,
  // updateDocument,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  openRows,
  checked
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { exportMode, isAdvancedModeOn } = useContext(InterfaceContext);
  // console.log('exportMode: ', exportMode);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });
  // let scope = {
  //   budget: item.budget || 0,
  //   b: 4
  // };
  // const testEval = evaluate('budget * 2', scope); // 12

  const { enqueueSnackbar } = useSnackbar();

  const { updateDocument } = useContext(MutationContext);

  // ################### update #######################

  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = (e) => {
    if (showTextField) {
      toggleShowTextField(false);
      return;
    }
    if (
      ([
        'folder',
        // 'task',
        // 'subTask',
        // 'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(item.type) ||
        item.isSet) &&
      !item.isSetChild
    ) {
      toggleShowTextField(true);
    }
  };

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(item[itemKey + 'Formula']);
  }, [showTextField, toggleShowTextField]);

  const onSubmitHandler = async (e) => {
    console.log('onSubmitHandler>click away');
    e.preventDefault();

    // // let regExpression =
    // //   '[+]{1}[0-9]+|[0-9]+[-]{1}|[0-9]+[+]{1}|[-]{1}[0-9]+|^[0-9]+$|^[=]{1}[0-9]+$';
    // let regExpression =
    //   '([0-9]+)[+]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[+]{1}(.+)([0-9]+)|([0-9]+)[-]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[-]{1}(.+)([0-9]+)';
    // const regEx1 = new RegExp(regExpression);
    // const regEx1Result = regEx1.exec(inputValue);
    // console.log('regEx1Result: ', regEx1Result);
    // if (regEx1Result) {
    //   enqueueSnackbar(`Error`, {
    //     variant: 'error'
    //   });
    //   return;
    // }

    // let RegExpression = '^replace[a-zA-Z0-9åäöÅÄÖæøÆØ _,.+-/\\*()=]*$';
    // var re = new RegExp(RegExpression, 'g');

    // if (
    //   [
    //     'facebook_ads_campaign',
    //     // 'facebook_ads_ad_set'
    //     'google_ads_campaign',
    //     'cm360_campaign'
    //     // 'google_ads_ad_group',
    //     // 'folder'
    //   ].includes(item.type) &&
    //   [
    //     'cost',
    //     'impressions',
    //     'clicks',
    //     'action01Count',
    //     'action02Count',
    //     'action03Count',
    //     'action01Value',
    //     'action02Value',
    //     'action03Value'
    //   ].includes(itemKey)
    // ) {
    //   let regExpression = '^replace[/\\*()]?[0-9.]*$';
    //   const replacedReExpression = regExpression.replace(
    //     'replace',
    //     `=${itemKey}`
    //   );

    //   const regExName = new RegExp(replacedReExpression);
    //   const regExNameResult = regExName.exec(inputValue);
    //   if (!regExNameResult) {
    //     if (itemKey === 'cost' && inputValue.includes(`=impressions/1000*`)) {
    //     } else {
    //       enqueueSnackbar(
    //         `Only =${itemKey}, =${itemKey}*value and =${itemKey}/value are allowed.`,
    //         {
    //           variant: 'error'
    //         }
    //       );

    //       return console.log('Unallowed characters were used.');
    //     }
    //   }
    //   // RegExpression = RegExpression.
    // }

    const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ _,.+-/\\*()=]*$');
    const regExNameResult = regExName.exec(inputValue);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    // if (inputValue.length < 2) {
    //   enqueueSnackbar(
    //     'Name too short. Minimum length 2 characters. ' +
    //       inputValue.length +
    //       '/100 used',
    //     {
    //       variant: 'error'
    //     }
    //   );

    //   return console.log('name too short.');
    // }

    if (inputValue && inputValue.length > 100) {
      enqueueSnackbar('Name too long. ' + inputValue.length + '/100 used', {
        variant: 'error'
      });

      return console.log('name too long.');
    }

    if (inputValue === item[itemKey + 'Formula']) {
      toggleShowTextField(false);
      return '';
    }

    // const documentInput = {
    //   accountId: item.accountId,
    //   documentId: item._id,
    //   document: { [itemKey]: inputValue },
    //   updateKey: itemKey,
    //   action: 'UPDATE_DOCUMENT',
    //   managerAccountId: currentManagerAccountId,
    //   documentType: item.documentType,
    //   level: item.level
    // };

    const trimmedInputValue = inputValue.trim();
    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: trimmedInputValue
        }
      ],
      updateKeys: [itemKey]
    };

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });
      // console.log('res: ', res);
      // console.log(
      //   'res?.data?.updateDocument?.response: ',
      //   res?.data?.updateDocument?.response
      // );
      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
      toggleShowTextField(false);
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  let numberOfDecimals = 0;

  switch (itemKey) {
    case 'impressions':
    case 'viewableImpressions':
    case 'measurableImpressions':
    case 'eligibleImpressions':
    case 'videoCompletions':
    case 'clicks':
    case 'universe':
      numberOfDecimals = 0;
      break;
    default:
      numberOfDecimals = 2;
  }

  // const colHandlerOnRelease = (e, itemKey) => {
  //   console.log('212342>click');
  //   // if (!['account'].includes(item.type)) {
  //   //   return;
  //   // }

  //   var isRightMB;
  //   var isLeftMB;
  //   e = e || window.event;

  //   if ('which' in e) {
  //     // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
  //     isRightMB = e.which == 3;
  //     isLeftMB = e.which == 1;
  //   } else if ('button' in e) {
  //     // IE, Opera
  //     isRightMB = e.button == 2;
  //     isLeftMB = e.button == 1;
  //   }

  //   console.log('212342>isRightMB: ', isRightMB);
  //   if (isRightMB) {
  //     // if (!open) {
  //     //   setTypeItemToggledItem(item);
  //     //   setTypeItemAnchorRef(anchorRef);
  //     // }
  //     setAnchorEl(e.currentTarget);
  //     // setOpen((prevOpen) => !prevOpen);
  //   }
  // };

  const popoverAutoCompleteOnChangeHandler = (e, newValue) => {
    setInputValue(inputValue + newValue.name);
    setAnchorEl(null);
  };

  const textField = (
    <ClickAwayListener onClickAway={onSubmitHandler} mouseEvent={'onClick'}>
      <div style={{ widht: '100%' }}>
        <form
          id={item?._id + '_' + itemKey}
          style={{ width: '100%' }}
          //   style={{ padding: '0px 14px' }}
          onSubmit={onSubmitHandler}
          // onBlur={onSubmitHandler}
          // className={classes.root}
          noValidate
          autoComplete="off"
        >
          <TextField
            style={{ width: '100%' }}
            // onMouseUp={colHandlerOnRelease}
            onContextMenu={(e) => {
              e.preventDefault();
              console.log('on right click');

              setAnchorEl(e.currentTarget);
              return false;
            }}
            // onContextMenu={(e) => {
            //   e.preventDefault();
            //   return false;
            // }}
            variant="standard"
            autoFocus
            id="standard-basic"
            value={inputValue}
            inputProps={{
              min: 0,
              style: {
                textAlign: 'left',
                padding: '0px',
                fontSize: '12px'
              }
            }}
            // onBlur={onBlurHandler}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={onKeyDownHandler}
            onChange={onChangeHandler}
          />
          {/* <TextField id="filled-basic" label="Filled" variant="filled" />
    <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
        </form>
        <Popover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onChangeHandler={popoverAutoCompleteOnChangeHandler}
          itemKey={itemKey}
        />
      </div>
    </ClickAwayListener>
  );

  let subValue = '-';
  let showSubValue = false;

  switch (true) {
    case itemKey.includes('Roas'):
    case itemKey.includes('Cpa'):
    case itemKey.includes('cpcv'):
    case itemKey.includes('cpc'):
    case itemKey.includes('cpm'):
    case itemKey.includes('vCpm'):
    case itemKey.includes('Rate'):
      break;
    default:
      showSubValue = true;
      subValue = item[itemKey + 'SumChild'] ? item[itemKey + 'SumChild'] : '-';
      if (typeof subValue === 'number') {
        subValue = parseFloat(subValue).toLocaleString(undefined, {
          minimumFractionDigits: numberOfDecimals,
          maximumFractionDigits: numberOfDecimals
        });
      }

      break;
  }

  if (
    item.sum_child_budgets &&
    item.sum_child_budgets !== 0 &&
    item.sum_child_budgets !== undefined
  ) {
    // child_sum =
    //   'sum. child.' + parseInt(item.sum_child_budgets).toLocaleString();
  }

  // ##################################################

  let spanStyle = {
    margin: 'auto',
    width: '100%'
  };
  if (true) {
    spanStyle = { textAlign: 'right' };
  }

  let displayItemValue = null;
  let displayValueColor = null;

  if (
    itemValue === null ||
    itemValue === undefined ||
    itemValue === '' ||
    itemValue == '0'
  ) {
    displayItemValue = '-';
    displayValueColor = 'grey';
  } else if (isNaN(itemValue)) {
    displayItemValue = itemValue;
  } else if (typeof itemValue === 'number') {
    displayItemValue = (
      Math.round((itemValue + Number.EPSILON) * 100) / 100
    ).toLocaleString(undefined, {
      minimumFractionDigits: numberOfDecimals,
      maximumFractionDigits: numberOfDecimals
    });
  }

  if (
    ([
      'account',
      'manualDataEntry',
      'folder',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'microsoft_invest_insertion_order',
      'apple_search_ads_campaign',
      'adform_order',
      // 'adform_line_item',
      'linkedin_ads_campaign_group'
      // 'linkedin_ads_campaign'
    ].includes(item.type) ||
      item.isSet) &&
    !item.isSetChild
    // ([
    //   'manualDataEntry',
    //   // 'folder',
    //   'facebook_ads_campaign',
    //   'google_ads_campaign',
    //   'cm360_campaign'
    //   // 'task',
    //   // 'subTask'
    // ].includes(item.type) &&
    //   [
    //     'cost',
    //     'universe',
    //     'trp',
    //     'spots',
    //     'impressions',
    //     'reach',
    //     'clicks',
    //     'action01Count',
    //     'action02Count',
    //     'action03Count',

    //     // 'action01Value',
    //     // 'action02Value',
    //     // 'action03Value',

    //     'plannedGross',
    //     'plannedNet',
    //     'plannedNetNet',
    //     'plannedCtc',

    //     'plannedTrp',
    //     'plannedTechFee',
    //     'plannedAdOpsFee',
    //     'plannedAdServingCost',
    //     'plannedFeePercent',

    //     'kpi',
    //     'plannedImpressions',
    //     'plannedClicks',
    //     'plannedCtr',
    //     'plannedCpc',
    //     'plannedReach',
    //     'plannedCpm',
    //     'plannedFrequency',
    //     'plannedActionCount',
    //     'plannedActionValue',
    //     'plannedActionRoas',
    //     'plannedCpa',
    //     'budget'
    //   ].includes(itemKey)) ||
    // ([
    //   'manualDataEntry',
    //   'folder',
    //   // 'facebook_ads_campaign',
    //   // 'google_ads_campaign',
    //   // 'cm360_campaign',
    //   'task',
    //   'subTask'
    // ].includes(item.type) &&
    //   [
    //     'cost',
    //     'impressions',
    //     'reach',
    //     'clicks',
    //     'action01Count',
    //     'action02Count',
    //     'action03Count',

    //     'action01Value',
    //     'action02Value',
    //     'action03Value',
    //     'universe',
    //     'plannedGross',
    //     'plannedNet',
    //     'plannedNetNet',
    //     'plannedCtc',

    //     'plannedTrp',
    //     'plannedTechFee',
    //     'plannedAdOpsFee',
    //     'plannedAdServingCost',
    //     'plannedFeePercent',

    //     'kpi',
    //     'plannedImpressions',
    //     'plannedClicks',
    //     'plannedCtr',
    //     'plannedCpc',
    //     'plannedReach',
    //     'plannedCpm',
    //     'plannedFrequency',
    //     'plannedActionCount',
    //     'plannedActionValue',
    //     'plannedActionRoas',
    //     'plannedCpa',
    //     'budget'
    //   ].includes(itemKey))
  ) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          borderStyle: 'solid',
          borderWidth: '0px 1px 1px 0px',
          borderColor: itemStyle['borderColor'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          left: '53px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          //   padding: '10px 7px',
          // padding: '10px 14px 9px 14px',
          padding: '0px 14px 0px 0px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor:
            item?.isSetChild || ['account']?.includes(item.type)
              ? 'default'
              : 'text'
        }}
        onClick={onClickHandler}
      >
        {(inView || exportMode) && (
          <div
            style={{ display: 'flex', flexDirection: 'row' }}
            // onClick={onClickHandler}
          >
            {/* <div style={{ padding: '5px' }}> */}
            <WarningDotToolTip item={item} itemKey={itemKey} />
            {/* </div> */}
            <div
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {showTextField ? (
                <div style={{ textAlign: 'right', width: '100%' }}>
                  {textField}
                </div>
              ) : (
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    // minWidth: '200px',
                    width: '100%',
                    // cursor: 'text',
                    paddingRight: '5px'
                  }}
                  // onClick={onClickHandler}
                >
                  {/* <span
           style={{
             textAlign: isNaN(itemValue) ? 'left' : 'right',
             width: '100%'
           }}
         > */}
                  <span
                    style={{
                      textAlign: isNaN(itemValue) ? 'left' : 'right',
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: displayValueColor
                    }}
                  >
                    {displayItemValue}
                    {/* {isNaN(itemValue) ? itemValue : itemValue} */}
                    {/* </span> */}
                  </span>

                  {/* <span
                    style={
                      {
                        // visibility: 'hidden'
                      }
                    }
                  >
                    <i
                      className={
                        'fas fa-pen ' +
                        (true ? 'transparent-hover-grey' : 'hover-grey')
                      }
                      style={{
                        fontSize: '8px',
                        // color: 'grey',
                        padding: '4px 0 4px 2px',
                        cursor: 'pointer'
                      }}
                      // onClick={onClickHandler}
                    ></i>
                  </span> */}
                </p>
              )}
              <p
                style={{
                  fontSize: '8px',
                  color: '#bcbcbc',
                  textAlign: 'right',
                  marginRight: '6px',
                  visibility: showSubValue ? 'visible' : 'hidden'
                  // cursor: 'default'
                }}
              >
                {/* {' '} */}
                {subValue && <SimpleTooltip tooltipMessage={subValue} />}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          borderStyle: 'solid',
          borderWidth: '0px 1px 1px 0px',
          borderColor: itemStyle['borderColor'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          left: '53px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          //   padding: '10px 7px',
          // padding: '10px 14px',
          // padding: '0px 14px',
          padding: '0px 14px 0px 0px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {(inView || exportMode) && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* <div style={{ padding: '5px' }}> */}
            <WarningDotToolTip item={item} itemKey={itemKey} />
            <div
              style={{
                textAlign: isNaN(itemValue) ? 'left' : 'right',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <p
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  // minWidth: '200px',
                  width: '100%'
                }}
              >
                {/* <span
              style={{
                textAlign: isNaN(itemValue) ? 'left' : 'right',
                width: '100%'
              }}
            > */}
                <span
                  style={{
                    textAlign: isNaN(itemValue) ? 'left' : 'right',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: displayValueColor,
                    cursor: 'default'
                  }}
                >
                  {/* {itemValue && parseFloat(itemValue.toFixed(2)).toLocaleString()} */}
                  {[
                    'account',
                    'folder',
                    'facebook_ads_campaign',
                    'facebook_ads_ad_set',
                    'google_ads_campaign',
                    'google_ads_ad_group',
                    'cm360_campaign',
                    'dv360_insertion_order',
                    'dv360_line_item',
                    'snapchat_ads_campaign',
                    'snapchat_ads_ad_squad',
                    'tiktok_ads_campaign',
                    'pinterest_ads_campaign',
                    'pinterest_ads_ad_group',
                    'readpeak_campaign',
                    'readpeak_ad_group',
                    'strossle_campaign',
                    'strossle_ad_set',
                    'amazon_dsp_order',
                    'amazon_dsp_line_item',
                    'taboola_ads_campaign',
                    'microsoft_advertising_campaign',
                    'microsoft_advertising_ad_group',
                    'microsoft_invest_insertion_order',
                    'microsoft_invest_line_item',
                    'apple_search_ads_campaign',
                    'apple_search_ads_ad_group',
                    'tiktok_ads_ad_group',
                    'adform_order',
                    'adform_line_item',
                    'linkedin_ads_campaign_group',
                    'linkedin_ads_campaign'
                  ].includes(item.type) && displayItemValue}

                  <i
                    className={'fas fa-pen '}
                    style={{
                      fontSize: '8px',
                      // color: 'grey',
                      padding: '4px 0 4px 2px',
                      cursor: 'pointer',
                      visibility: 'hidden'
                    }}
                    // onClick={onClickHandler}
                  ></i>
                  {/* {isNaN(itemValue) ? itemValue : itemValue.toFixed(2)} */}
                </span>
              </p>
              <p
                style={{
                  fontSize: '8px',
                  color: '#bcbcbc',
                  visibility: 'hidden'
                }}
              >
                {' '}
                test
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default React.memo(MathEvalItem);
