import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

import { FilterContext } from '../../context/FilterContext';
import { ColumnContext } from '../../context/ColumnContext';

import Tooltip from '@mui/material/Tooltip';

function FlightViewButton() {
  const { statusFilter, setStatusFilter, filters, setFilters } =
    useContext(FilterContext);
  const { columns, setColumns } = useContext(ColumnContext);
  const [previousColumns, setPreviousColumns] = useState([]);
  // console.log('statusFilter123: ', statusFilter);

  // console.log('columns: ', columns);
  const [isFlightView, toggleIsFlightView] = useState(false);
  const onClickFlightViewHandler = () => {
    if (isFlightView) {
      toggleIsFlightView(false);

      setStatusFilter('All but removed');
      setColumns([...previousColumns]);
      setFilters(filters.filter((str) => str !== 'filter_by_data_source'));
    } else {
      toggleIsFlightView(true);

      setStatusFilter('All but completed and removed');

      setPreviousColumns([...columns]);
      setColumns([
        'warnings',
        'status',
        'checkedAt',
        'path',
        // 'members',
        // 'allowImpressionsBeforeStartDate',
        'startDate',
        'endDate',
        // 'allowImpressionsAfterEndDate',
        'budget',
        // 'budgetCurrency',
        // 'allowMissingBudget',
        'calcDailyBudget',
        'calcDailyBudgetYesterday',
        'cost',
        'costYesterday',
        'pace',
        'calcPaceYesterday',
        'gantt'
      ]);
      if (!filters.includes('filter_by_data_source')) {
        setFilters([...filters, 'filter_by_data_source']);
      }
    }
  };
  // console.log('previousColumns: ', previousColumns);

  useEffect(() => {
    if (!filters.includes('filter_by_data_source') && isFlightView) {
      toggleIsFlightView(false);
      if (statusFilter !== 'All but removed') {
        setStatusFilter('All but removed');
      }

      setColumns([...previousColumns]);
      setFilters(filters.filter((str) => str !== 'filter_by_data_source'));
    }
    if (isFlightView && statusFilter !== 'All but completed and removed') {
      setFilters(filters.filter((str) => str !== 'filter_by_data_source'));
      toggleIsFlightView(false);
    }
  }, [filters, statusFilter, isFlightView]);

  // console.log('isFlightView: ', isFlightView);
  return (
    <Tooltip title="Core delivery mode (will apply data source filter and load all activities except completed and removed)">
      <Button
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          minHeight: 0,
          minWidth: 0,
          backgroundColor: isFlightView ? '#3e8ed0' : null
        }}
        onClick={onClickFlightViewHandler}
      >
        <FlightTakeoffIcon
          style={{
            color: isFlightView ? '#FFFFFF' : null
          }}
          // className="hover-grey"
          // style={{ cursor: 'pointer' }}
          // onClick={onClickRefreshHandler}
        />
      </Button>
    </Tooltip>
  );
}

export default FlightViewButton;
