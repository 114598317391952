const mongoose = require('mongoose');

const UserSchema = new mongoose.Schema(
  {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true,
      unique: true
    },
    isEmailVerified: Boolean,
    emailVerifiedAt: Date,
    emailVerificationCode: Number,

    phoneNumber: String,
    isPhoneNumberVerified: Boolean,
    phoneNumberVerifiedAt: Date,
    phoneNumberVerificationCode: String,
    phoneNumberVerificationCodeSentAt: Date,
    unverifiedPhoneNumber: String,

    password: {
      type: String
      // required: true
    },
    role: {
      type: String
    },
    avatar: {
      type: String
    },
    status: String,

    timeZone: String,
    isSmsWarningsDelayedNighttime: Boolean,
    isSmsWarningsDelayedOnWeekends: Boolean
  },
  { timestamps: true }
);

UserSchema.virtual('name').get(function () {
  return this.firstName + ' ' + this.lastName;
});

module.exports = User = mongoose.model('User', UserSchema);
