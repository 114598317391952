import {
  generateWeeks,
  getWeekNumber,
  getDayAsString,
  generateMonths
} from './../roitable_functions/date_functions';

import { prepareObject } from './graph-dialog/preparedObject';

// import getFastViewDataStartDate from './../roitable_functions/getFastViewDataStartDate';

import {
  allEvalFields,
  allEvalFieldsExceptTotal,
  plannedEvalFields,
  sumEvalFields
} from '../fields';

const prepDataFunction = (
  item,
  range2,
  time,
  columns,
  columnDictionary,
  startDateForLastDaysData,
  tomorrow,
  listOfData
) => {
  // console.log('prepDataFunction>item: ', item);
  // console.log('123startDateForLastDaysData: ', startDateForLastDaysData);
  let prepared_data = [];
  let previous_prepared_object;
  let i = 0;

  let dateRange = range2;

  const accountIds =
    item.type === 'summary' ? item.accountIds : [item.accountId];
  // console.log('range2: ', range2);

  // let tomorrow = new Date();
  // tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
  if (window.location.href.includes('fast')) {
    // const fastViewDataStartDate = getFastViewDataStartDate();
    dateRange = range2.filter(
      (date) =>
        new Date(date) > new Date(startDateForLastDaysData) &&
        new Date(date) < new Date(tomorrow)
    );
  }

  let total_list_of_propagation_data = item.total_list_of_propagation_data;

  if (listOfData?.length) {
    const accounts = listOfData.filter(
      (doc) =>
        accountIds.includes(doc.accountId) && doc.documentType === 'account'
    );
    // console.log('prepDataFunction>accounts: ', accounts);
    if (accounts) {
      let newTotalRows = [];

      for (let account of accounts) {
        let newData = account.total_list_of_propagation_data.filter(
          (obj) => obj.cost
        );
        newData = newData.map((obj) => ({
          date_start: obj.date_start,
          totalCost: obj.cost
        }));

        newTotalRows = [...newTotalRows, ...newData];
      }

      // console.log('newTotalRows: ', newTotalRows);

      total_list_of_propagation_data = [
        ...total_list_of_propagation_data,
        ...newTotalRows
      ];
    }
  }
  // if (item.documentType === 'account') {
  //   total_list_of_propagation_data = item.total_list_of_propagation_data.map(
  //     (obj) => ({
  //       ...obj,
  //       totalCost: obj.cost
  //     })
  //   );
  // }
  switch (time) {
    case 'day':
      i = 0;

      for (let date of dateRange) {
        const prepared_object = {
          id: new Date(date).toISOString().split('T')[0],
          // cost: 0,
          // trp: 0,
          // spots: 0,
          // impressions: 0,
          // clicks: 0,
          // action01Count: 0,
          // action02Count: 0,
          // action03Count: 0,
          // action01Value: 0,
          // action02Value: 0,
          // action03Value: 0,

          // totalCost: 0,
          // totaltrp: 0,
          // totalSpots: 0,
          // totalImpressions: 0,
          // totalClicks: 0,
          // totalAction01Count: 0,
          // totalAction02Count: 0,
          // totalAction03Count: 0,
          // totalAction01Value: 0,
          // totalAction02Value: 0,
          // totalAction03Value: 0,
          date: new Date(date).toISOString().split('T')[0]
        };

        for (let field of allEvalFieldsExceptTotal) {
          prepared_object[field] = 0;
          prepared_object[
            'total' + field[0].toUpperCase() + field.substring(1)
          ] = 0;
        }

        for (let data_obj of total_list_of_propagation_data || []) {
          if (
            window.location.href.includes('fast-table') &&
            (new Date(+data_obj.date_start) <=
              new Date(startDateForLastDaysData) ||
              new Date(+data_obj.date_start) > new Date(tomorrow))
          ) {
            continue;
          }
          if (
            data_obj.date_start &&
            new Date(parseInt(data_obj.date_start))
              .toISOString()
              .split('T')[0] === date
          ) {
            for (let field of [...plannedEvalFields, ...sumEvalFields]) {
              prepared_object[field] =
                prepared_object[field] + (data_obj[field] || 0);
              prepared_object[
                'total' + field[0].toUpperCase() + field.substring(1)
              ] =
                prepared_object[
                  'total' + field[0].toUpperCase() + field.substring(1)
                ] +
                (data_obj[
                  'total' + field[0].toUpperCase() + field.substring(1)
                ] || 0);
            }
            // prepared_object.cost = prepared_object.cost + (data_obj.cost || 0);
            // prepared_object.trp =
            //   prepared_object.trp +
            //   (data_obj.trp || 0);

            // prepared_object.spots =
            //   prepared_object.spots + (data_obj.spots || 0);

            // prepared_object.impressions =
            //   prepared_object.impressions + (data_obj.impressions || 0);
            // prepared_object.clicks =
            //   prepared_object.clicks + (data_obj.clicks || 0);

            // prepared_object.action01Count =
            //   prepared_object.action01Count + (data_obj.action01Count || 0);
            // prepared_object.action02Count =
            //   prepared_object.action02Count + (data_obj.action02Count || 0);
            // prepared_object.action03Count =
            //   prepared_object.action03Count + (data_obj.action03Count || 0);

            // prepared_object.action01Value =
            //   prepared_object.action01Value + (data_obj.action01Value || 0);
            // prepared_object.action02Value =
            //   prepared_object.action02Value + (data_obj.action02Value || 0);
            // prepared_object.action03Value =
            //   prepared_object.action03Value + (data_obj.action03Value || 0);

            // prepared_object.totalCost =
            //   prepared_object.totalCost + (data_obj.totalCost || 0);

            // prepared_object.totaltrp =
            //   prepared_object.totaltrp +
            //   (data_obj.totaltrp || 0);

            // prepared_object.totalSpots =
            //   prepared_object.totalSpots + (data_obj.totalSpots || 0);

            // prepared_object.totalImpressions =
            //   prepared_object.totalImpressions +
            //   (data_obj.totalImpressions || 0);
            // prepared_object.totalClicks =
            //   prepared_object.totalClicks + (data_obj.totalClicks || 0);

            // prepared_object.totalAction01Count =
            //   prepared_object.totalAction01Count +
            //   (data_obj.totalAction01Count || 0);
            // prepared_object.totalAction02Count =
            //   prepared_object.totalAction02Count +
            //   (data_obj.totalAction02Count || 0);
            // prepared_object.totalAction03Count =
            //   prepared_object.totalAction03Count +
            //   (data_obj.totalAction03Count || 0);

            // prepared_object.totalAction01Value =
            //   prepared_object.totalAction01Value +
            //   (data_obj.totalAction01Value || 0);
            // prepared_object.totalAction02Value =
            //   prepared_object.totalAction02Value +
            //   (data_obj.totalAction02Value || 0);
            // prepared_object.totalAction03Value =
            //   prepared_object.totalAction03Value +
            //   (data_obj.totalAction03Value || 0);
          }
        }

        // if(prepared_object.cost){

        const new_prepared_object = prepareObject(
          prepared_object,
          previous_prepared_object,
          i,
          columns,
          columnDictionary
        );

        prepared_data.push(new_prepared_object);

        previous_prepared_object = new_prepared_object;
        i++;
      }
      break;

    case 'week':
      const listOfWeeks = generateWeeks(dateRange);

      previous_prepared_object = null;
      // i = 0;

      for (let week of Object.keys(listOfWeeks)) {
        const prepared_object = {
          id: week,
          // cost: 0,
          // clicks: 0,
          // trp: 0,
          // spots: 0,
          // impressions: 0,
          // action01Count: 0,
          // action02Count: 0,
          // action03Count: 0,
          // action01Value: 0,
          // action02Value: 0,
          // action03Value: 0,

          // totalCost: 0,
          // totaltrp: 0,
          // totalSpots: 0,
          // totalImpressions: 0,
          // totalClicks: 0,
          // totalAction01Count: 0,
          // totalAction02Count: 0,
          // totalAction03Count: 0,
          // totalAction01Value: 0,
          // totalAction02Value: 0,
          // totalAction03Value: 0,

          date: week
        };

        for (let field of allEvalFieldsExceptTotal) {
          prepared_object[field] = 0;
          prepared_object[
            'total' + field[0].toUpperCase() + field.substring(1)
          ] = 0;
        }

        for (let data_obj of total_list_of_propagation_data || []) {
          if (
            window.location.href.includes('fast-table') &&
            (new Date(+data_obj.date_start) <=
              new Date(startDateForLastDaysData) ||
              new Date(+data_obj.date_start) > new Date(tomorrow))
          ) {
            continue;
          }
          const yearAndNumber = getWeekNumber(
            new Date(parseInt(data_obj.date_start))
          );

          const yearAndNumberString = yearAndNumber[0] + '-' + yearAndNumber[1];

          if (yearAndNumberString === week) {
            for (let field of [...plannedEvalFields, ...sumEvalFields]) {
              prepared_object[field] =
                prepared_object[field] + (data_obj[field] || 0);
              prepared_object[
                'total' + field[0].toUpperCase() + field.substring(1)
              ] =
                prepared_object[
                  'total' + field[0].toUpperCase() + field.substring(1)
                ] +
                (data_obj[
                  'total' + field[0].toUpperCase() + field.substring(1)
                ] || 0);
            }

            // prepared_object.cost = prepared_object.cost + (data_obj.cost || 0);

            // prepared_object.trp =
            //   prepared_object.trp +
            //   (data_obj.trp || 0);

            // prepared_object.spots =
            //   prepared_object.spots + (data_obj.spots || 0);

            // prepared_object.impressions =
            //   prepared_object.impressions + (data_obj.impressions || 0);
            // prepared_object.clicks =
            //   prepared_object.clicks + (data_obj.clicks || 0);

            // prepared_object.action01Count =
            //   prepared_object.action01Count + (data_obj.action01Count || 0);
            // prepared_object.action02Count =
            //   prepared_object.action02Count + (data_obj.action02Count || 0);
            // prepared_object.action03Count =
            //   prepared_object.action03Count + (data_obj.action03Count || 0);

            // prepared_object.action01Value =
            //   prepared_object.action01Value + (data_obj.action01Value || 0);
            // prepared_object.action02Value =
            //   prepared_object.action02Value + (data_obj.action02Value || 0);
            // prepared_object.action03Value =
            //   prepared_object.action03Value + (data_obj.action03Value || 0);

            // prepared_object.totalCost =
            //   prepared_object.totalCost + (data_obj.totalCost || 0);

            // prepared_object.totaltrp =
            //   prepared_object.totaltrp +
            //   (data_obj.totaltrp || 0);

            // prepared_object.totalSpots =
            //   prepared_object.totalSpots + (data_obj.totalSpots || 0);

            // prepared_object.totalImpressions =
            //   prepared_object.totalImpressions +
            //   (data_obj.totalImpressions || 0);
            // prepared_object.totalClicks =
            //   prepared_object.totalClicks + (data_obj.totalClicks || 0);

            // prepared_object.totalAction01Count =
            //   prepared_object.totalAction01Count +
            //   (data_obj.totalAction01Count || 0);
            // prepared_object.totalAction02Count =
            //   prepared_object.totalAction02Count +
            //   (data_obj.totalAction02Count || 0);
            // prepared_object.totalAction03Count =
            //   prepared_object.totalAction03Count +
            //   (data_obj.totalAction03Count || 0);

            // prepared_object.totalAction01Value =
            //   prepared_object.totalAction01Value +
            //   (data_obj.totalAction01Value || 0);
            // prepared_object.totalAction02Value =
            //   prepared_object.totalAction02Value +
            //   (data_obj.totalAction02Value || 0);
            // prepared_object.totalAction03Value =
            //   prepared_object.totalAction03Value +
            //   (data_obj.totalAction03Value || 0);
          }
        }

        const new_prepared_object = prepareObject(
          prepared_object,
          previous_prepared_object,
          i,
          columns,
          columnDictionary
        );

        prepared_data.push(new_prepared_object);

        previous_prepared_object = new_prepared_object;
        i++;
      }
      break;
    case 'month':
      // const listOfWeeks = generateWeeks(dateRange);
      const listOfYearAndMonths = generateMonths(dateRange);

      for (let month of Object.keys(listOfYearAndMonths)) {
        const prepared_object = {
          id: month,
          // cost: 0,
          // trp: 0,
          // spots: 0,
          // impressions: 0,
          // clicks: 0,
          // action01Count: 0,
          // action02Count: 0,
          // action03Count: 0,
          // action01Value: 0,
          // action02Value: 0,
          // action03Value: 0,

          // totalCost: 0,
          // totaltrp: 0,
          // totalSpots: 0,
          // totalImpressions: 0,
          // totalClicks: 0,
          // totalAction01Count: 0,
          // totalAction02Count: 0,
          // totalAction03Count: 0,
          // totalAction01Value: 0,
          // totalAction02Value: 0,
          // totalAction03Value: 0,

          date: month
        };

        for (let field of allEvalFieldsExceptTotal) {
          prepared_object[field] = 0;
          prepared_object[
            'total' + field[0].toUpperCase() + field.substring(1)
          ] = 0;
        }
        for (let data_obj of total_list_of_propagation_data || []) {
          if (
            window.location.href.includes('fast-table') &&
            (new Date(+data_obj.date_start) <=
              new Date(startDateForLastDaysData) ||
              new Date(+data_obj.date_start) > new Date(tomorrow))
          ) {
            continue;
          }
          // console.log('data_obj.date_start: ', data_obj.date_start);
          let dateString = '';
          try {
            dateString = new Date(parseInt(data_obj.date_start))
              .toISOString()
              .split('T')[0];
          } catch (error) {
            console.log('data_obj.date_start: ', data_obj.date_start);
            continue;
          }

          const dateStringArray = dateString.split('-');
          const year = dateStringArray[0];
          const monthNumber = dateStringArray[1];

          const yearAndMonthString = year + '-' + monthNumber;

          if (yearAndMonthString === month) {
            for (let field of [...plannedEvalFields, ...sumEvalFields]) {
              prepared_object[field] =
                prepared_object[field] + (data_obj[field] || 0);
              prepared_object[
                'total' + field[0].toUpperCase() + field.substring(1)
              ] =
                prepared_object[
                  'total' + field[0].toUpperCase() + field.substring(1)
                ] +
                (data_obj[
                  'total' + field[0].toUpperCase() + field.substring(1)
                ] || 0);
            }
            // prepared_object.cost = prepared_object.cost + (data_obj.cost || 0);

            // prepared_object.trp =
            //   prepared_object.trp +
            //   (data_obj.trp || 0);

            // prepared_object.spots =
            //   prepared_object.spots + (data_obj.spots || 0);

            // prepared_object.impressions =
            //   prepared_object.impressions + (data_obj.impressions || 0);
            // prepared_object.clicks =
            //   prepared_object.clicks + (data_obj.clicks || 0);

            // prepared_object.action01Count =
            //   prepared_object.action01Count + (data_obj.action01Count || 0);
            // prepared_object.action02Count =
            //   prepared_object.action02Count + (data_obj.action02Count || 0);
            // prepared_object.action03Count =
            //   prepared_object.action03Count + (data_obj.action03Count || 0);

            // prepared_object.action01Value =
            //   prepared_object.action01Value + (data_obj.action01Value || 0);
            // prepared_object.action02Value =
            //   prepared_object.action02Value + (data_obj.action02Value || 0);
            // prepared_object.action03Value =
            //   prepared_object.action03Value + (data_obj.action03Value || 0);

            // prepared_object.totalCost =
            //   prepared_object.totalCost + (data_obj.totalCost || 0);

            // prepared_object.totaltrp =
            //   prepared_object.totaltrp +
            //   (data_obj.totaltrp || 0);

            // prepared_object.totalSpots =
            //   prepared_object.totalSpots + (data_obj.totalSpots || 0);

            // prepared_object.totalImpressions =
            //   prepared_object.totalImpressions +
            //   (data_obj.totalImpressions || 0);
            // prepared_object.totalClicks =
            //   prepared_object.totalClicks + (data_obj.totalClicks || 0);

            // prepared_object.totalAction01Count =
            //   prepared_object.totalAction01Count +
            //   (data_obj.totalAction01Count || 0);
            // prepared_object.totalAction02Count =
            //   prepared_object.totalAction02Count +
            //   (data_obj.totalAction02Count || 0);
            // prepared_object.totalAction03Count =
            //   prepared_object.totalAction03Count +
            //   (data_obj.totalAction03Count || 0);

            // prepared_object.totalAction01Value =
            //   prepared_object.totalAction01Value +
            //   (data_obj.totalAction01Value || 0);
            // prepared_object.totalAction02Value =
            //   prepared_object.totalAction02Value +
            //   (data_obj.totalAction02Value || 0);
            // prepared_object.totalAction03Value =
            //   prepared_object.totalAction03Value +
            //   (data_obj.totalAction03Value || 0);
            // prepared_object.date_start = (new Date(date)).toISOString().split('T')[0];
          }
        }

        const new_prepared_object = prepareObject(
          prepared_object,
          previous_prepared_object,
          i,
          columns,
          columnDictionary
        );

        prepared_data.push(new_prepared_object);

        previous_prepared_object = new_prepared_object;
        i++;
      }
  }

  if (prepared_data[0]?.date_start) {
    prepared_data[0].date_start = prepared_data[0].date_start + '*';
  }

  if (prepared_data[prepared_data.length - 1]?.date_start) {
    prepared_data[prepared_data.length - 1].date_start =
      prepared_data[prepared_data.length - 1].date_start + '*';
  }

  return prepared_data;
};

export default prepDataFunction;
