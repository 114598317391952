import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

function valueLabelFormat(value) {
  const [coefficient, exponent] = value
    .toExponential()
    .split('e')
    .map((item) => Number(item));
  return value;
}

export default function NonLinearSlider({
  ganttWidth,
  setGanttWidth,
  sliderColor
}) {
  //   const [value, setValue] = React.useState(21);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    setGanttWidth(newValue);
  };

  return (
    <div>
      {/* <Typography id="non-linear-slider" gutterBottom>
        Temperature range
      </Typography> */}
      <Slider
        size="small"
        style={{ maxWidth: '200px', color: sliderColor }}
        value={ganttWidth}
        min={3}
        step={1}
        max={60}
        scale={(x) => x * 1}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="auto"
        // aria-labelledby="non-linear-slider"
      />
    </div>
  );
}
