import React, { useState } from 'react';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

import CommentIcon from '@mui/icons-material/Comment';

import { styled, useTheme } from '@mui/material/styles';

import CardMenu from './CardMenu';

import ReactionBar from './ReactionBar';
import ChildCommentField from './ChildCommentField';
import ChildComments from './ChildComments';

import { useInView } from 'react-intersection-observer';

function NewsFeedItemWrapper({ children, commentObj, item, key }) {
  const theme = useTheme();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const [showTextField, toggleShowTextField] = useState(false);

  let icon = <div></div>;

  switch (commentObj.type) {
    case 'comment':
      icon = (
        <CommentIcon
          style={{
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.3)'
                : '#36529c87'
          }}
        />
      );
      break;
    case 'historyEvent':
      icon = (
        <ChangeHistoryIcon
          style={{
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.3)'
                : '#36529c87'
          }}
        />
      );
      break;
    case 'poke':
      icon = (
        <i
          style={{
            fontSize: '20px',
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.3)'
                : '#36529c87'
          }}
          className="fas fa-hand-point-right"
        ></i>
      );
      break;
  }

  return (
    <div key={key} style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        className="newsfeed-item-icon"
        style={{
          // display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginRight: '20px'
        }}
      >
        {icon}
      </div>
      <div
        ref={ref}
        style={{
          //   display: 'flex',
          //   flexDirection: 'row',
          //   justifyContent: 'center',
          //   alignItems: 'center',
          background:
            theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.1)'
              : 'rgba(255,255,255,0.9)',
          backdropFilter: inView && 'blur(10px)',
          padding: '5px 20px 25px 20px',
          borderRadius: '5px',
          width: '100%',
          margin: '3px',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              //   fontSize: '11px',
              //   backgroundColor: commentObj.accountColor || '#a72683',
              //   color: 'white',
              //   padding: '3px 7px',
              //   // margin: '2px 0px 10px 0px',
              //   borderRadius: '5px',
              //   width: 'fit-content',
              //   maxWidth: '200px',
              //   textOverflow: 'ellipsis',
              display: 'flex',
              flexDirection: 'row',
              columnGap: '10px',

              position: 'relative',
              top: '-15px'
              // marginLeft: '60px'
              // fontWeight: 'bold'
            }}
          >
            <div
              style={{
                fontSize: '11px',
                backgroundColor: commentObj.accountColor || '#a72683',
                color: 'white',
                padding: '3px 7px',
                // margin: '2px 0px 10px 0px',
                borderRadius: '5px',
                width: 'fit-content',
                maxWidth: '200px',
                height: 'fit-content',
                textOverflow: 'ellipsis'
              }}
            >
              <span>{commentObj.accountName}</span>
            </div>
            {commentObj?.labels?.map((obj, index) => (
              <div
                key={index}
                style={{
                  fontSize: '11px',
                  backgroundColor: obj.color || '#a72683',
                  color: 'white',
                  padding: '3px 7px',
                  // margin: '2px 0px 10px 0px',
                  borderRadius: '5px',
                  width: 'fit-content',
                  maxWidth: '200px',
                  height: 'fit-content',
                  textOverflow: 'ellipsis'
                }}
              >
                <span>{obj.name}</span>
              </div>
            ))}
          </div>
          <div
            style={{
              height: '22.5px',
              position: 'relative',
              right: '-10px'
            }}
          >
            <CardMenu
              commentObj={commentObj}
              item={{ ...commentObj, _id: commentObj.documentId }}
              showTextField={showTextField}
              toggleShowTextField={toggleShowTextField}
            />
          </div>
        </div>
        {children}

        {/* <Comment
        key={commentObj._id}
        commentObj={commentObj}
        item={{ ...commentObj, _id: commentObj.documentId }}
        currentManagerAccountId={currentManagerAccountId}
        onClickPathHandler={onClickPathHandler}
      /> */}
        {/* </div> */}
        {Boolean(commentObj?.reactions?.length) && (
          <div
            style={{
              padding: '5px 60px',
              // background: 'red',
              // height: '50px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
              // alignItems: 'end'
            }}
          >
            <ReactionBar reactions={commentObj.reactions} />
          </div>
        )}
        {Boolean(commentObj?.childComments?.length) && (
          <ChildComments commentObj={commentObj} />
        )}
        {showTextField && (
          <ChildCommentField
            commentObj={commentObj}
            item={{ ...commentObj, _id: commentObj.documentId }}
            showTextField={showTextField}
            toggleShowTextField={toggleShowTextField}
          />
        )}
      </div>
    </div>
  );
}

export default NewsFeedItemWrapper;
