// import * as React from 'react';
// import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TimeboxListAutoComplete from './TimeboxListAutoComplete';

import React, { useState, useContext, useEffect } from 'react';

import { useTheme } from '@emotion/react';

import { DataContext } from '../../../../../context/DataContext';

import { AccountContext } from '../../../../../context/AccountContext';

import { useLazyQuery } from '@apollo/client';
import { userTimeboxListsQuery } from '../../../../../graphql/queries';

import { AuthContext } from '../../../../../context/AuthContext';

function UserSelectPopover() {
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    selectedTimeboxList,
    setSelectedTimeboxList,
    userTimeboxLists,
    setUserTimeboxLists
  } = useContext(DataContext);

  const { user } = useContext(AuthContext);

  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const variables = {
    input: {
      managerAccountId: currentManagerAccount._id
    }
  };

  const [loadTimeboxLists, { called, loading, data, refetch }] = useLazyQuery(
    userTimeboxListsQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
    }
  );

  // console.log('TimeboxListAutoComplete>data: ', data);

  useEffect(() => {
    loadTimeboxLists();
  }, []);

  const options = data?.userTimeboxLists || [];

  useEffect(() => {
    if (options.length > 0 && !selectedTimeboxList) {
      const usersTimeboxList = options.filter(
        (option) => option?.assignedToUserId === user?._id
      )[0];
      setSelectedTimeboxList(usersTimeboxList || options[0]);
      setUserTimeboxLists(options);
    }
  }, [options]);

  return (
    <div
      style={{
        position: 'relative',
        zIndex: '5000',
        width: '220px',
        display: 'flex',
        flexDirection: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onClick={(e) => {
        // e.preventDefault();
        // e.stopPropagation();
      }}
    >
      <Button
        onClick={() => setOpen(!open)}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '14px',
          height: '40px',
          width: '190px',
          fontSize: '12px',
          minWidth: '10px'
          // width: '220px'
          // background: 'red'
        }}
      >
        {selectedTimeboxList?.name}
      </Button>

      {open && (
        <div
          onBlur={() => {
            console.log('debug39>onBlur');
            setOpen(false);
          }}
          style={{
            position: 'absolute',
            // background: 'red',
            background:
              theme?.palette?.mode === 'dark'
                ? 'rgba(0,0,0,0.6)'
                : 'rgba(255,255,255,0.7)',
            border:
              '1px solid' + theme?.palette?.mode === 'dark'
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(255,255,255,1)',

            backdropFilter: 'blur(10px)',
            padding: '10px',
            borderRadius: '5px',
            // height: '220px',
            width: '220px',
            top: '20px',
            left: '0px'
          }}
        >
          <TimeboxListAutoComplete
          // selectedTimeboxList={selectedTimeboxList}
          // setSelectedTimeboxList={setSelectedTimeboxList}
          />
          {/* UserSelectPopover */}
        </div>
      )}
    </div>
  );
}

export default UserSelectPopover;
