import React, { useState, useEffect } from 'react';

import './style.css';

import readXlsxFile from 'read-excel-file';
import { readSheetNames } from 'read-excel-file';

import { SnackbarProvider, useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import Resizer from 'react-image-file-resizer';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// drag drop file component
const resizeFile = (
  file, // Is the file of the image which will resized.
  maxWidth, // Is the maxWidth of the resized new image.
  maxHeight, // Is the maxHeight of the resized new image.
  compressFormat, // Is the compressFormat of the resized new image.
  quality, // Is the quality of the resized new image.
  rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  responseUriFunc, // Is the callBack function of the resized new image URI.
  outputType, // Is the output type of the resized new image.
  minWidth, // Is the minWidth of the resized new image.
  minHeight // Is the minHeight of the resized new image.
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      maxWidth, // Is the maxWidth of the resized new image.
      maxHeight, // Is the maxHeight of the resized new image.
      compressFormat, // Is the compressFormat of the resized new image.
      quality, // Is the quality of the resized new image.
      rotation, // Is the degree of clockwise rotation to apply to uploaded image.
      (uri) => {
        resolve(uri);
      }, // Is the callBack function of the resized new image URI.
      outputType, // Is the output type of the resized new image.
      minWidth, // Is the minWidth of the resized new image.
      minHeight // Is the minHeight of the resized new image.
    );
  });

function DragDropFile({ reportAssets, setReportAssets, toggleFileUploaded }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  const [newAssets, setNewAssets] = useState([]);

  const parseFiles = async (files) => {
    const newAssetObjects = [];
    //   var files = e.target.files,

    for (let file of files) {
      if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
        console.log('file type not supported');

        enqueueSnackbar('FILE TYPE NOT SUPPORTED. PLEASE CONTACT SUPPORT', {
          variant: 'error'
        });

        return;
      }
    }
    for (let file of files) {
      // console.log('file123: ', file['file']);
      // const thumbnailImage = await resizeFile(
      //   file,
      //   300,
      //   300,
      //   'PNG',
      //   20,
      //   0,
      //   () => {},
      //   'base64'
      // );

      // const resizedFile = await resizeFile(
      //   file,
      //   1920,
      //   1920,
      //   file.type, //'PNG',
      //   100,
      //   0,
      //   () => {},
      //   'file'
      // );

      if (file?.size > 3000000) {
        console.log('file.size too big>file.size: ', file.size);
        enqueueSnackbar('FILE TOO HEAVY. MAX WEIGHT 3mb.', {
          variant: 'error'
        });
        return;
      }
      // console.log('debug2543>image:', image);

      // console.log('debug7456>file: ', file);

      const newAssetObject = {};

      const img = new Image();
      img.src = URL.createObjectURL(file);
      // img.src = URL.createObjectURL(file);
      img.onload = async function () {
        // console.log('img.naturalHeight: ', img.naturalHeight);
        // setImgNaturalHeight(this.naturalHeight);
        // setImgNaturalWidth(this.naturalWidth);

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        var dataUri = canvas.toDataURL('image/png');

        console.log('debug7456>dataURL: ', dataUri);

        newAssetObject.imageDataUri = dataUri;
        newAssetObject.imageNaturalAspectRatio =
          img.naturalWidth / img.naturalHeight;
        newAssetObject.imageNaturalHeight = img.naturalHeight;
        newAssetObject.imageNaturalWidth = img.naturalWidth;

        newAssetObject.lastModified = String(file.lastModified);
        newAssetObject.lastModifiedDate = file.lastModifiedDate;
        newAssetObject.name = file.name;
        newAssetObject.fileName = file.name;
        newAssetObject.size = file.size;
        newAssetObject.type = file.type;

        newAssetObject.id = uuidv4();
        // newAssetObject.thumbnailImageDataUri = thumbnailImage;
        // await setTimeout(() => {
        //   setNewAssets([...newAssets, newAssetObject]);
        // }, '2000');
        // await setNewAssets([...newAssets, newAssetObject]);
        // console.log('debug7456>newAssetObject: ', newAssetObject);
        newAssetObject.file = file;
        newAssetObjects.push(newAssetObject);
      };

      // const files = e.dataTransfer.files;
      // console.log('files: ', files);
      // return;

      // for (let file of files) {
      let reader = new FileReader();

      reader.onload = async () => {
        // console.log('reader.result: ', reader.result);
        const binaryStr = reader.result;

        // setImageUrl(binaryStr);
        // updateWidget(copyOfWidget, widgetIndex);

        // setDragActive(false);

        // console.log('debug7456>binaryStr: ', binaryStr);
        // console.log('newAssetObjects: ', newAssetObjects);
        setReportAssets(newAssetObjects);
      };

      await reader.readAsDataURL(file);
      // await reader.readAsDataURL(file);
      // newAssetObjects.push(newAssetObject);

      // setReportAssets([...(reportAssets || []), newAssetObject]);
    }
    // var f = files[0];
    // var sheets = {};

    // console.log('debug7456>newAssetObjects:', newAssetObjects);

    console.log('after delay');
    // const newAssetObjects_ = await JSON.parse(JSON.stringify(newAssetObjects));
    // // console.log('newAssetObjects_: ', newAssetObjects_);
    // await setReportAssets(newAssetObjects);

    // setExcelData(sheets);
    await toggleFileUploaded(true);
  };

  // console.log('newAssets: ', newAssets);

  // useEffect(() => {
  //   setReportAssets(newAssets);
  // }, [newAssets]);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = e.dataTransfer.files;
      parseFiles(files);
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    // console.log('handleChange>e: ', e);
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const files = e.target.files;
      // handleFiles(e.target.files);

      parseFiles(files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={(e) => {
          console.log('test>e: ', e);
          e.stopPropagation();
          e.preventDefault();
          handleChange(e);
        }}
        onClick={(e) => e.stopPropagation()}
      />
      <div
        style={{
          position: 'relative',
          display: 'flex',
          height: '350px',
          width: '400px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        id="drag-file-element"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={(e) => handleDrop(e)}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          onClick={(e) => {
            onButtonClick(e);
          }}
          style={{
            cursor: 'pointer',
            // background: 'red',
            height: '250px',
            width: '250px',
            minWidth: '100px',
            minHeight: '50px',
            border: '1px dashed lightgrey',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px'
          }}
        >
          DROP IMAGE HERE <FileUploadIcon />
        </div>
      </div>
    </>
    // {dragActive && (
    //   <div
    //     id="drag-file-element"
    //     onDragEnter={handleDrag}
    //     onDragLeave={handleDrag}
    //     onDragOver={handleDrag}
    //     onDrop={handleDrop}
    //   ></div>
    // )}
  );
}
export default DragDropFile;
