import React, { useContext } from 'react';

import Button from '@mui/material/Button';

import { useMutation } from '@apollo/client';
import { addDocumentMutation } from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';

import { MutationContext } from '../../../../../context/MutationContext';

function AddTaskButton({ item }) {
  const { currentManagerAccount, currentManagerAccountId } =
    useContext(AccountContext);
  // const [addDocument] = useMutation(addDocumentMutation);

  const { addDocument } = useContext(MutationContext);

  const onClickCreateHandler = () => {
    // let addDocumentInput = {
    //   action: 'CREATE_DOCUMENT',
    //   managerAccountId: currentManagerAccount._id,
    //   // documentType: 'container',
    //   documentType: 'subTask',
    //   newDocumentAccountAccessLevel: 1,
    //   checkedDocumentId: item._id
    //   // document: {}
    // };

    // addDocumentInput = {
    //   ...addDocumentInput,
    //   level: item.level,
    //   accountId: item.accountId,
    //   document: {
    //     name: 'New subtask'
    //   }
    // };

    let addDocumentInput = {
      managerAccountId: currentManagerAccountId,
      accountId: item.accountId,
      checkedDocumentId: item._id,
      level: item.level,
      newDocuments: [{ documentType: 'subTask', name: 'New subtask' }]
    };
    console.log('AddTaskButton>addDocumentInput: ', addDocumentInput);

    addDocument({ variables: { input: { ...addDocumentInput } } });
  };

  return (
    <Button
      onClick={onClickCreateHandler}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: '5px',
        backgroundColor: 'rgba(150, 150, 150, 0.1)',
        padding: '10px',
        margin: '10px 0px',
        // cursor: 'pointer',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      + Add subtask
    </Button>
  );
}

export default AddTaskButton;
