import React, { useContext } from 'react';

import ActionTypeItem from './ActionTypeItem';

import { AccountContext } from '../../../../context/AccountContext';

import { useTheme } from '@emotion/react';

const itemKeyToNameDict = {
  action01: 'Action 01',
  action02: 'Action 02',
  action03: 'Action 03'
};

function BulkEditActionSettings({
  item,
  itemKey,
  document,
  setDocument,
  disabled
}) {
  const theme = useTheme();
  const { currentManagerAccountId } = useContext(AccountContext);
  return (
    <div
      style={{
        // background: 'rgba(150,150,150,0.1)',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        width: '400px'
      }}
    >
      {/* <span>Bulk Edit Action Settings</span> */}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            color: disabled
              ? theme.palette.mode === 'dark'
                ? 'rgb(177 177 181)'
                : 'rgba(160,160,160,0.9)'
              : undefined
          }}
        >
          <span>{itemKeyToNameDict[itemKey] || itemKey}</span>
        </div>
        <div
          style={{
            color: disabled
              ? theme.palette.mode === 'dark'
                ? 'rgb(177 177 181)'
                : 'rgba(160,160,160,0.9)'
              : undefined,
            maxWidth: '250px',
            fontSize: '12px'
          }}
        >
          {document[itemKey]?.name || ''}
        </div>
        <ActionTypeItem
          disabled={disabled}
          currentManagerAccountId={currentManagerAccountId}
          // updateDocument={updateDocument}
          item={item}
          // itemStyle={itemStyle}
          // keyForMap={item._id + '_' + itemKey + '_' + index}
          // index={index}
          itemKey={itemKey}
          itemValue={item[itemKey]}
          // rowNumber={rowNumber}
          textAlignRight={true}
          // checked={checked}
          popperPosition={'right'}
          document={document}
          setDocument={setDocument}
        />
      </div>
    </div>
  );
}

export default BulkEditActionSettings;
