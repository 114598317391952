import React, { useContext, useState, useEffect } from 'react';
import TimeboxAutoTagKeyword from './TimeboxAutoTagKeyword';

import { AccountContext } from '../../../../../context/AccountContext';

import { useLazyQuery } from '@apollo/client';
import { timeboxAutoTaggingKeywordsQuery } from './../../../../../graphql/queries';

import AddButton from './AddButton';
function TimeboxAutoTaggingKeywords({
  selectedAccountId,
  stylesStoredInReduxManagerAccountSettings
}) {
  const [refetchCounter, setRefetchCounter] = useState(0);
  const { currentManagerAccount } = useContext(AccountContext);

  const variables = {
    input: {
      managerAccountId: currentManagerAccount._id,
      accountId: selectedAccountId
    }
  };
  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(timeboxAutoTaggingKeywordsQuery, {
    variables,

    fetchPolicy: 'no-cache',

    onCompleted: () => {
      console.log('RoiTable>Query completed.');
    },
    onError: (error) => {
      console.log('error: ', error);
    }
  });

  useEffect(() => {
    // console.log('data: ', data);
  }, [data]);

  useEffect(() => {
    if (refetchCounter) {
      refetch();
    }
  }, [refetchCounter]);

  useEffect(() => {
    if (currentManagerAccount._id) {
      getData();
    }
  }, [currentManagerAccount._id]);

  return (
    <div
      style={{
        // display:'grid',
        // gridTemplateRows: '70px auto',
        margin: '3vw auto',
        width: '95%',
        // background: 'red',

        // maxWidth: maxWidth,
        // minWidth: minWidth
        maxWidth: '760px'
        // minWidth: '90%'
      }}
    >
      <div
        style={{
          // gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
          // display: 'grid',
          // gridRowStart: 1,
          // gridRowEnd: 5,
          // minWidth: '600px',
          width: '100%'
          // height: '100%',
          // display: 'inline-block'
          // justifyContent: "flex-start"
        }}
      >
        <div
          style={{
            padding: '25px 30px 25px 30px',
            fontSize: '16px',
            // minWidth: '700px',
            // width: '100%',
            // margin:'50px 30px 0px 30px'
            // margin: '100px auto',
            display: 'grid',
            // gridTemplateRows: '45px repeat(auto-fill, minmax(45x, 45px))',
            // background: 'rgba(255,255,255,0.05',
            background:
              stylesStoredInReduxManagerAccountSettings.childCardBackgroundColor,
            borderRadius: '5px',
            height: 'fit-content'
          }}
        >
          <div
            style={{
              display: 'grid',
              gridRowStart: 1,
              gridRowEnd: 1,
              gridTemplateColumns: '50% 50%',
              borderRadius: '5px',
              height: '100%'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '700px',
                padding: '0px 17px',
                // gridColumnStart: 1,
                // gridColumnEnd: 1,
                marginBottom: '5px'
              }}
            >
              <span>Timebox auto tagging keywords (case insensitive):</span>
              <AddButton
                selectedAccountId={selectedAccountId}
                refetch={refetch}
              />
            </div>
          </div>
          <div>
            {data?.timeboxAutoTaggingKeywords?.map(
              (timeboxAutoTaggingKeyword) => (
                <TimeboxAutoTagKeyword
                  selectedAccountId={selectedAccountId}
                  timeboxAutoTaggingKeyword={timeboxAutoTaggingKeyword}
                  refetch={refetch}
                  stylesStoredInReduxManagerAccountSettings={
                    stylesStoredInReduxManagerAccountSettings
                  }
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeboxAutoTaggingKeywords;
