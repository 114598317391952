import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';

import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import EditIcon from '@mui/icons-material/Edit';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { addCommentMutation } from './../../../../../graphql/queries';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import CommentToolTip from './CommentToolTip';

import { styled, useTheme } from '@mui/material/styles';

import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

//##############################################

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

// #####################################################

const createNameAbb = (name) => {
  let nameAbb = '';

  const nameParts = name.split(' ');

  for (let i = 0; i < nameParts.length; i++) {
    nameAbb = nameAbb + nameParts[i][0];
  }
  return nameAbb;
};

function Comment({ user, item, commentObj, currentManagerAccountId }) {
  const navigate = useNavigate();
  // user._id === commentObj.user.userId
  const theme = useTheme();

  const [edit, toggleEdit] = useState(false);
  const [input, setInput] = useState(
    commentObj.text[commentObj.text.length - 1].text
  );

  useEffect(() => {
    setInput(commentObj.text[commentObj.text.length - 1].text);
  }, [commentObj]);
  console.log('commentObj: ', commentObj);

  return (
    <div
      key={commentObj._id}
      style={{
        // borderRadius: '5px',
        // border: '1px solid grey',
        // margin: '0px 20px 20px 20px',
        // padding: '10px',
        display: 'grid',
        gridTemplateColumns: '30px auto',
        gridTemplateRows: '20px auto auto'
      }}
    >
      <div
        style={{
          display: 'grid',
          gridColumnStart: 1,
          gridColumnEnd: 1,
          gridRowStart: 1,
          gridRowEnd: 2
        }}
      >
        <Avatar
          style={{
            height: '35px',
            width: '35px',
            fontSize: '12px',
            color: 'white',
            background: `
    linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
    linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
    linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)
    `
          }}
        >
          {commentObj.user &&
            commentObj.user.name &&
            createNameAbb(commentObj.user.name)}
        </Avatar>
      </div>
      <div
        style={{
          // display: 'grid',
          gridColumnStart: 2,
          gridColumnEnd: 2,
          gridRowStart: 1,
          gridRowEnd: 1,
          margin: '0px 0px 0px 20px',
          padding: '0px 0px 0px 10px'
          // color: 'rgba(150,150,150,1)',
          // fontStyle: 'italic'
          // color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'grey'
          // color:
          //   theme.palette.mode === 'dark'
          //     ? 'rgba(255,255,255,1)'
          //     : 'rgba(54,82,156,1)'
        }}
      >
        <span>
          {commentObj.user && commentObj.user.name && commentObj.user.name}
        </span>
        <span
          style={{
            marginLeft: '15px',
            fontSize: '10px',
            // color:
            //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'grey'
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,0.8)'
          }}
        >
          {commentObj.date &&
            new Date(parseInt(commentObj.date)).toLocaleString()}
        </span>
        <span
          style={{
            marginLeft: '15px',
            // fontSize: '10px',
            // color:
            //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'grey'
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,0.8)'
          }}
        >
          {/* <CommentToolTip commentVersions={commentObj.text} /> */}
        </span>
        <span
          style={{
            marginLeft: '15px',
            // fontSize: '10px',
            // color:
            //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'grey'
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,0.8)'
          }}
        ></span>
      </div>

      <div
        style={{
          // display: 'grid',
          gridColumnStart: 2,
          gridColumnEnd: 2,
          gridRowStart: 2,
          gridRowEnd: 2,
          margin: '0px 0px 0px 20px',
          padding: '0px 0px 0px 10px'
        }}
        // onClick={() => {
        //   navigate(
        //     `/fast-table/`,
        //     {
        //       state: {
        //         manager_account_id: currentManagerAccountId,
        //         account_ids: commentObj.accountId,
        //         document_ids: commentObj.documentId
        //       }
        //     }
        //     // `/fast-table/?manager_account_id=${currentManagerAccountId}&account_ids=${commentObj.accountId}&document_ids=${commentObj.documentId}&start_date=${commentObj.startDate}&end_date=${commentObj.endDate}`
        //   );
        //   // <a href="${process.env.CLIENTHOST}/fast-table/?manager_account_id=${managerAccount_._id}&account_ids=${document.accountId}&document_ids=${document.documentId}&start_date=${datePickerStartDate}&end_date=${datePickerEndDate}">NexusTable - ${managerAccount_.name}</a>
        // }}
      >
        <span
          style={{
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,1)',
            fontSize: '12px'
          }}
        >
          <span> / </span>
          {(item?.interactivePath || []).map((obj, index) => {
            return (
              <>
                <a
                  href={`${window.location.origin}/fast-table/?manager_account_id=${currentManagerAccountId}&account_ids=${commentObj.accountId}&document_ids=${obj._id}&start_date=${commentObj.startDate}&end_date=${commentObj.endDate}`}
                >
                  <span
                    key={index + '_1'}
                    className="hover-grey"
                    style={{
                      cursor: 'pointer'
                      // textDecoration: 'underline'
                    }}
                    // onClick={() => onClickHandler(obj)}
                  >
                    {obj.name}
                  </span>
                </a>
                <span key={index + '_2'}> / </span>
              </>
            );
          })}
        </span>
        {/* <a
          href={`${window.location.origin}/fast-table/?manager_account_id=${currentManagerAccountId}&account_ids=${commentObj.accountId}&document_ids=${commentObj.documentId}&start_date=${commentObj.startDate}&end_date=${commentObj.endDate}`}
        >
          <span
            style={{
              // color:
              //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'grey',
              color:
                theme.palette.mode === 'dark'
                  ? 'rgba(255,255,255,1)'
                  : 'rgba(54,82,156,0.8)',
              fontSize: '12px'
            }}
          >
            {commentObj.path}
          </span>
        </a> */}
      </div>

      <div
        style={{
          display: 'grid',
          gridColumnStart: 2,
          gridColumnEnd: 2,
          gridRowStart: 3,
          gridRowEnd: 3,
          borderRadius: '5px',
          // border: '1px solid grey',
          margin: '0px 20px 0px 20px',
          padding: '10px'
        }}
      >
        <p
          style={{
            whiteSpace: 'pre-line',
            fontSize: '12px',
            fontStyle: 'italic',
            // color: 'rgba(150,150,150,1)'
            // color:
            //   theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'grey'
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,1)'
                : 'rgba(54,82,156,0.7)'
          }}
        >
          <Linkify componentDecorator={componentDecorator}>
            {commentObj.text[commentObj.text.length - 1].text}
          </Linkify>
        </p>
      </div>
    </div>
  );
}

// export default Comment;

Comment.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired,
  // style: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,

  // stylesStoredInRedux: state.style,
  user: state.auth.user
});

export default connect(mapStateToProps, {})(Comment);
