import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
// import { useQuery } from '@apollo/client';

import Spinner from './../../layout/SpinnerSmall';

import TextField from '@mui/material/TextField';

import SelectIncludeExcludeFilter from './SelectIncludeExcludeFilter';
import { useTheme } from '@emotion/react';

// import { facebookAdsAccountsQuery } from './../../../../../../../graphql/queries';

function CheckList({
  // filterSearch,

  list,
  checked,
  setChecked,

  isLoading,
  toggleIsLoading
}) {
  const theme = useTheme();
  // const filterSearch = '';

  const [filterSearch, setFilterSearch] = useState('');

  // const [isLoading, toggleIsLoading] = useState(true);

  const [skip, toggleSkip] = useState(true);

  const [listOfData, setListOfData] = useState([]);

  const [containsString, setContainsString] = useState('CONTAINS');

  useEffect(() => {
    if (list) {
      setListOfData(list);
      toggleIsLoading(false);
    }
  }, [list]);

  //   let listOfData = dummyList;
  const filterField = 'Al';

  let listOfData2 = listOfData.filter((obj) => {
    if (filterSearch) {
      if (containsString === 'CONTAINS') {
        if (
          (obj?.name &&
            obj?.name?.toLowerCase().includes(filterSearch?.toLowerCase())) ||
          (obj?.id &&
            obj?.id?.toLowerCase()?.includes(filterSearch?.toLowerCase())) ||
          obj?.subTitle?.toLowerCase()?.includes(filterSearch?.toLowerCase())
        ) {
          return true;
        }
      }

      if (containsString === 'DOESNOTCONTAIN') {
        if (
          (obj?.name &&
            obj?.name?.toLowerCase().includes(filterSearch?.toLowerCase())) ||
          (obj?.id &&
            obj?.id?.toLowerCase()?.includes(filterSearch?.toLowerCase())) ||
          obj?.subTitle?.toLowerCase()?.includes(filterSearch?.toLowerCase())
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  });

  // const [checked, setChecked] = useState([]);
  console.log('checked: ', checked);

  const onClickHandler = (e, id) => {
    if (checked.includes(id)) {
      const filteredChecked = checked.filter((checkedId) => checkedId !== id);
      setChecked(filteredChecked);
    } else {
      setChecked([...checked, id]);
    }
  };

  const onClickSelectFilteredHandler = () => {
    const filteredIds = listOfData2.map((obj) => obj.id);
    const idsToBeAdded = [];
    for (let filteredId of filteredIds) {
      if (!checked.includes(filteredId)) {
        idsToBeAdded.push(filteredId);
      }
    }
    setChecked([...checked, ...idsToBeAdded]);
  };

  const onClickDeselectFilteredHandler = () => {
    const filteredIds = listOfData2.map((obj) => obj.id);
    const newChecked = [];
    for (let checkedId of checked) {
      if (!filteredIds.includes(checkedId)) {
        newChecked.push(checkedId);
      }
    }
    setChecked(newChecked);
  };

  let i = 0;
  const accountList = listOfData2.map((obj, index) => {
    let permissions = '';

    let subTitle = '';
    if (obj.status) {
      subTitle = `status: ${obj.status}, ` + subTitle;
    }
    if (obj.id) {
      subTitle = subTitle + `id: ${obj.id}`;
    }

    if (obj.startDate) {
      if (subTitle) {
        subTitle = subTitle + ', ';
      }
      let startDate = '';
      try {
        startDate = obj.startDate;
      } catch (error) {}
      subTitle = subTitle + `Start date: ${startDate}`;
    }

    if (obj.endDate) {
      if (subTitle) {
        subTitle = subTitle + ', ';
      }
      let endDate = '';
      try {
        endDate = obj.endDate;
      } catch (error) {}
      subTitle = subTitle + `End date: ${endDate}`;
    }

    obj.subTitle = subTitle;

    i++;
    return (
      <Button
        className="text-transform-unset"
        value={obj._id}
        key={index}
        style={{
          gridRowStart: i,
          gridRowEnd: i,
          height: '100%',
          justifyContent: 'flex-start',
          padding: '7px 8px',
          // margin: '0px 0px',
          // background:
          //   theme.palette.mode === 'dark'
          //     ? 'rgba(255,255,255,0.02)'
          //     : 'rgba(0,0,50,0.02)',
          // background: checked.includes(obj.id)
          //   ? 'rgba(255,255,255,0.03)'
          //   : theme.palette.mode === 'dark'
          //   ? 'rgba(255,255,255,0.01)'
          //   : 'rgba(0,0,50,0.01)',
          background:
            checked.includes(obj.id) &&
            (theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.05)'
              : 'rgba(0,0,50,0.01)'),
          borderBottom: '1px solid rgba(255,255,255,0.05)'
          // marginBottom: '5px'
        }}
        sx={{
          ':hover': {
            backgroundImage:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.05)'
                : 'rgba(0,0,50,0.05)'
            // color: 'white'
          }
        }}
        // color="primary"
        onClick={(e) => onClickHandler(e, obj.id)}
      >
        <Checkbox
          checked={checked.includes(obj.id)}
          // onChange={(e)=>handleChange(e)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <div style={{ display: 'grid' }}>
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.name}
          </div>
          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              fontSize: '10px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {subTitle}
          </div>
        </div>
      </Button>
    );
  });

  return (
    <div
      style={{
        display: 'grid',
        gridRowStart: 2,
        gridRowEnd: 2,
        gridTemplateRows: '80px auto'
        // overflowY: 'scroll',
        // maxHeight: '400px',
        // height: '300px'
      }}
    >
      <div
        style={{
          display: 'grid',
          gridRow: 1,
          gridTemplateColumns: '170px auto'
        }}
      >
        <div style={{ gridColumn: 1 }}>
          <SelectIncludeExcludeFilter
            state={containsString}
            setState={setContainsString}
          />
        </div>
        <div style={{ gridColumn: 2 }}>
          <TextField
            variant="standard"
            style={{ width: '100%' }}
            // id="standard-basic"
            label="Search"
            value={filterSearch}
            onChange={(e) => setFilterSearch(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridRow: 2,
          height: '30px',
          gridTemplateColumns: '120px 120px auto'
        }}
      >
        <div style={{ gridColumn: 1, gridRow: 1 }}>
          <span
            className="hover-grey"
            style={{ padding: '5px 0px 10px 0px', cursor: 'pointer' }}
            onClick={onClickSelectFilteredHandler}
          >
            Select filtered
          </span>
        </div>
        <div style={{ gridColumn: 2, gridRow: 1 }}>
          <span
            className="hover-grey"
            style={{ padding: '5px 0px 10px 0px', cursor: 'pointer' }}
            onClick={onClickDeselectFilteredHandler}
          >
            Deselect filtered
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridRow: 3,
          // gridRowStart: 3,
          // gridRowEnd: 3,
          gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
          overflowY: 'scroll',
          maxHeight: '400px',
          height: '350px'
        }}
      >
        {isLoading ? (
          <div style={{ marginTop: '100px' }}>
            <Spinner />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',

              borderRadius: '5px',
              background:
                theme.palette.mode === 'dark'
                  ? 'rgba(255,255,255,0.01)'
                  : 'rgba(0,0,50,0.01)',
              height: 'fit-content'
            }}
          >
            {accountList}
          </div>
        )}
      </div>
    </div>
  );
}

export default CheckList;
