import React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
// import { makeStyles } from '@mui/styles';
import { withStyles, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { deepOrange, deepPurple } from '@mui/material/colors';
// import LetterAvatar from './LetterAvatar';

import Tooltip from '@mui/material/Tooltip';

import { styled, useTheme } from '@mui/material/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     '& > *': {
//       margin: theme.spacing(0)
//     }
//   },
//   orange: {
//     color: theme.palette.getContrastText(deepOrange[500]),
//     // backgroundColor: deepOrange[500],
//     // backgroundColor: 'grey',
//     // backgroundColor: 'rgba(177,48,141, 0.9)',
//     background: `
//     linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
//     linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
//     linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)

//     `,
//     border: '1px solid rgba(207,78,171, 1)',
//     fontSize: '8px',
//     height: '25px',
//     width: '25px'
//   },
//   purple: {
//     color: theme.palette.getContrastText(deepPurple[500]),
//     backgroundColor: deepPurple[500]
//   }
// }));

function CardAvatar({ item }) {
  const theme = useTheme();
  // const classes = useStyles();
  const members = item['members'];
  if (!members) {
    return <></>;
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fafafa',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }))(Tooltip);

  const key = '_id';

  const arrayUniqueByKeymembers = [
    ...new Map(members.map((obj) => [obj[key], obj])).values()
  ];

  let users = [];
  const listOfTransflerListItems = [];
  if (members) {
    for (let member of arrayUniqueByKeymembers) {
      let abb = '';
      let name = '';
      let email = '';
      let transflerListItem = '';
      if (member.firstName && member.lastName) {
        try {
          // const nameSplit = itemValue[i].name.split(' ');

          // for (let x = 0; x < nameSplit.length; x++) {
          //   abb = abb + nameSplit[x][0];
          // }
          abb = member.firstName[0] + member.lastName[0];
          name = member.firstName + ' ' + member.lastName;
          email = member.email;

          transflerListItem = name + ' (' + email + ')';
          listOfTransflerListItems.push(transflerListItem);
        } catch (error) {}
      } else {
        name = 'unknown user';
        email = '';
        abb = '?';
      }

      users.push({ ...member, name, abb, email, transflerListItem });
    }
  }

  return (
    <AvatarGroup max={4}>
      {users &&
        users.map((obj) => (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{obj.name}</Typography>
                {/* <em>{"And here's"}</em> <b>{'some'}</b>{' '}
            <u>{'amazing content'}</u>. {"It's very engaging. Right?"} */}
                {obj.email}
              </React.Fragment>
            }
          >
            <Avatar
              style={{
                // color: theme.palette.getContrastText(deepOrange[500]),
                // backgroundColor: deepOrange[500],
                // backgroundColor: 'grey',
                // backgroundColor: 'rgba(177,48,141, 0.9)',
                background: `
    linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
    linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
    linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)

    `,
                // border: '1px solid rgba(207,78,171, 1)',
                fontSize: '8px',
                height: '25px',
                width: '25px',
                border:
                  theme?.palette?.mode === 'dark' &&
                  '1px solid rgba(255,255,255,0.3)',
                color: 'white'
              }}
              // style={{ border: '1px solid rgba(207,78,171, 0.9)' }}
              // className={classes.orange}
            >
              {obj.abb}
            </Avatar>
          </HtmlTooltip>
        ))}
      {/* <Avatar className={classes.orange}>+</Avatar> */}
    </AvatarGroup>
  );
}

export default CardAvatar;
