import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({ filter, updateSFilter, documentType }) {
  const handleChange = (event) => {
    updateSFilter({ operator: event.target.value });
    // setOperator(event.target.value);
  };

  let operatorOptions = [];

  switch (true) {
    case filter?.field === 'campaignAdvertisingChannelType':
    case filter?.field === 'campaignAdvertisingChannelSubType':
    case filter?.field === 'campaignType':
    case filter?.field === 'subType':
    // operatorOptions = [
    //   { name: 'IN', value: 'IN' },
    //   { name: 'NOT IN', value: 'NOT_IN' }
    // ];
    // break;

    case filter?.field === 'campaignName':
      operatorOptions = [
        { name: 'Contains', value: 'CONTAINS' },
        { name: 'Does not contain', value: 'DOES_NOT_CONTAIN' }
      ];
      break;
  }

  // console.log('filter: ', filter);
  return (
    <Box sx={{ minWidth: 125, marginRight: '5px' }}>
      <FormControl size="small" fullWidth>
        <InputLabel size="small" id="demo-simple-select-label">
          Operator
        </InputLabel>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter.operator}
          label="Operator"
          onChange={handleChange}
        >
          {operatorOptions.map((obj) => (
            <MenuItem value={obj.value}>{obj.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
