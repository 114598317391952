import React, { useContext } from 'react';

import { useTheme } from '@emotion/react';

import { ReportBuilderContext } from '../../../../../../context/ReportBuilderContext';

import AxisMenuMetric from './AxisMenuMetric';

function LeftAndRightAxis({ widget }) {
  const theme = useTheme();

  const {
    currentlyDraggingWidget
    // setCurrentlyDraggingWidget,
    // isAxisMenuOpen,
    // toggleIsAxisSettingsOpen,
    // updateWidget
  } = useContext(ReportBuilderContext);

  return (
    <div
      onDrop={(e) => {
        e.preventDefault();
        // e.stopPropagation();
      }}
      style={{
        position: 'absolute',
        borderRadius: '5px',
        width: '100%',
        height: '100%',
        // background: 'rgba(255,255,255,0.7)',
        background:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(to top, rgba(50, 50, 58, 0.7), rgb(65, 65, 77))'
            : 'rgba(255,255,255,0.7)',
        backdropFilter: 'blur(10px)',
        display: 'flex',
        flexDirection: 'row'
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          border: '2px solid rgba(150,150,150,0.3)',
          padding: '10px',
          background:
            ['metrics'].includes(currentlyDraggingWidget?.category) &&
            'rgba(255,255,255,0.1)'
          // background: 'rgba(255,255,0,0.7)'
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px'
          }}
        >
          Left axis
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          {widget.metrics
            .filter((metricObj) => metricObj.axis === 'leftAxisId')
            .map((metricObj) => (
              <AxisMenuMetric widget={widget} metricObj={metricObj} />
            ))}
        </div>
      </div>

      <div
        style={{
          borderRadius: '0px 5px 5px 0px',
          width: '100%',
          height: '100%',
          border: '2px solid rgba(150,150,150,0.3)',
          padding: '10px',
          background:
            ['metrics'].includes(currentlyDraggingWidget?.category) &&
            'rgba(255,255,255,0.1)'

          // background: 'rgba(255,0,0,0.7)'
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px',
            textAlign: 'end'
          }}
        >
          Right axis
        </div>
        <div
          id={widget.id + '_' + 'rightAxisId'}
          style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
        >
          {widget.metrics
            .filter((metricObj) => metricObj.axis === 'rightAxisId')
            .map((metricObj) => (
              <AxisMenuMetric widget={widget} metricObj={metricObj} />
            ))}
        </div>
        {/* <div
            style={{
              width: '100%',
              height: '100%',
              background: 'red',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '30px',
              fontWeight: 'bold',
              color: 'rgba(150,150,150,0.3)'
            }}
          >
            <span>+</span>
          </div> */}
      </div>
    </div>
  );
}

export default LeftAndRightAxis;
