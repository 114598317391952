// import { pieChart } from './pieChart';

import { calcWidget } from './calcWidget';

export const calcWidgets = (
  isReportBuilderOpen,
  reportPage,
  widgets,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData
) => {
  const newWidgets = [];
  for (let widget of widgets) {
    const copyOfWidget = calcWidget(
      isReportBuilderOpen,
      reportPage,
      widget,
      listOfFilteredDataSourceRows,
      aggregatedDateRange,
      startDateForLastDaysData
    );

    newWidgets.push(copyOfWidget);
  }

  return newWidgets;
};
