import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { ClickAwayListener } from '@mui/base';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
// import { makeStyles } from '@mui/styles';

import { addDocumentMutation } from '../../../../../graphql/queries';

import { useMutation } from '@apollo/client';

import { MutationContext } from '../../../../../context/MutationContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex'
//   },
//   paper: {
//     marginRight: theme.spacing(2)
//   }
// }));

export default function MenuListComposition({
  children,
  typeItemAnchorRef,
  setTypeItemAnchorRef,
  typeItemOpen,
  setTypeItemOpen,
  open,
  setOpen,
  typeItemToggledItem,
  setTypeItemToggledItem,
  currentManagerAccountId,
  typeItemTemplateMenuOpen,
  setTypeItemTemplateMenuOpen
}) {
  // const classes = useStyles();
  //   const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // const [addDocument] = useMutation(addDocumentMutation);

  const { addDocument } = useContext(MutationContext);

  //   const [typeItemAnchorRef, setTypeItemAnchorRef] = useState(null);

  //   useEffect(() => {
  //     setTypeItemAnchorRef(anchorRef);
  //   }, [anchorRef]);

  // console.log('typeItemAnchorRef: ', typeItemAnchorRef);

  const handleToggle = () => {
    console.log('toggle');
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setTypeItemToggledItem(null);
    setTypeItemAnchorRef(null);

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  //   return children;

  const handleClickMenuItem = (e, value, documentType) => {
    switch (value) {
      case 'new_folder':
        // let addDocumentInput = {
        //   action: 'CREATE_DOCUMENT',
        //   managerAccountId: currentManagerAccountId,
        //   // documentType: 'container',
        //   documentType: type,
        //   newDocumentAccountAccessLevel: 1,
        //   checkedDocumentId: typeItemToggledItem._id
        //   // document: {}
        // };

        let name = 'new document';
        switch (documentType) {
          case 'folder':
            name = 'New Folder';
            break;
          case 'manualDataEntry':
            name = 'New Manual Input';
            break;
          case 'customForm':
            name = 'Custom Form';
            break;
          case 'task':
            name = 'New task';
            break;
          case 'subTask':
            name = 'New subtask';
            break;

          case 'taxonomy':
            name = 'New Taxonomy';
            break;
        }

        // addDocumentInput = {
        //   ...addDocumentInput,
        //   level: typeItemToggledItem.level,
        //   accountId: typeItemToggledItem.accountId,
        //   document: {
        //     name,
        //     type
        //   }
        // };

        let addDocumentInput = {
          managerAccountId: currentManagerAccountId,
          accountId: typeItemToggledItem.accountId,
          checkedDocumentId: typeItemToggledItem._id,
          level: typeItemToggledItem.level,
          newDocuments: [{ documentType, name }]
        };

        addDocument({ variables: { input: { ...addDocumentInput } } });

        break;
      case 'new_template':
        setTypeItemTemplateMenuOpen(true);
        break;
    }
  };

  return (
    <div
    // className={classes.root}
    >
      <div ref={anchorRef} onClick={handleToggle}>
        <Popper
          style={{ zIndex: '5000' }}
          open={open}
          anchorEl={typeItemAnchorRef?.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) =>
                        handleClickMenuItem(e, 'new_folder', 'folder')
                      }
                    >
                      New Folder
                    </MenuItem>
                    {typeItemToggledItem?.type === 'folder' && (
                      <MenuItem
                        onClick={(e) =>
                          handleClickMenuItem(e, 'new_template', 'template')
                        }
                      >
                        New Template
                      </MenuItem>
                    )}
                    {/* <MenuItem
                      onClick={(e) =>
                        handleClickMenuItem(e, 'add_data_source', 'dataSource')
                      }
                    >
                      Add Data Source
                    </MenuItem> */}
                    {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
