import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function VariantButtonGroup({
  componentIndex,
  field,
  fields,
  setFields
}) {
  const copyOfFields = [...fields];
  const copyOfCurrentField = { ...fields[componentIndex] };

  const onClickHandler = (value) => {
    copyOfCurrentField.timeIncrement = value;
    copyOfFields.splice(componentIndex, 1, copyOfCurrentField);

    setFields(copyOfFields);

    // if()
  };

  return (
    <ButtonGroup
      size="small"
      variant="outlined"
      aria-label="outlined button group"
    >
      <Button
        style={{
          backgroundColor:
            copyOfCurrentField.timeIncrement === 'daily' &&
            'rgba(150,150,150,00.5)'
        }}
        // style={{
        //   fontSize: '10px',
        //   fontWeight: copyOfMetric.yAxisOrientation === 'left' && 'bold',
        //   textDecoration:
        //     copyOfMetric.yAxisOrientation === 'left' && 'underline',
        //   padding: '3px'
        // }}
        onClick={() => onClickHandler('daily')}
      >
        Daily
      </Button>
      <Button
        style={{
          backgroundColor:
            copyOfCurrentField.timeIncrement === 'weekly' &&
            'rgba(150,150,150,00.5)'
        }}
        // style={{
        //   fontSize: '10px',
        //   padding: '3px',
        //   fontWeight: copyOfMetric.yAxisOrientation === 'right' && 'bold',
        //   textDecoration:
        //     copyOfMetric.yAxisOrientation === 'right' && 'underline'
        // }}
        onClick={() => onClickHandler('weekly')}
      >
        Weekly
      </Button>
      <Button
        style={{
          backgroundColor:
            copyOfCurrentField.timeIncrement === 'monthly' &&
            'rgba(150,150,150,00.5)'
        }}
        // style={{
        //   fontSize: '10px',
        //   padding: '3px',
        //   fontWeight: copyOfMetric.yAxisOrientation === 'right' && 'bold',
        //   textDecoration:
        //     copyOfMetric.yAxisOrientation === 'right' && 'underline'
        // }}
        onClick={() => onClickHandler('monthly')}
      >
        Montly
      </Button>
    </ButtonGroup>
  );
}
