import React, { useEffect, useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useQuery } from '@apollo/client';
import { DataSourcesQuery } from './../../../../../../graphql/queries';

import { AccountContext } from './../../../../../../context/AccountContext';

export default function ComboBox({ item, templateType, setTemplateType }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const accountId = item?.accountId;
  const variables = {
    dataSourcesInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccount._id,
      accountId
    }
  };

  const { data } = useQuery(DataSourcesQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: false
  });

  console.log('SelectTemplateType>data: ', data);

  const [listOfDataSources, setListOfDataSources] = useState([]);

  useEffect(() => {
    let mappedListOfDataSources = [];
    // mappedListOfDataSources =
    //   data?.dataSources?.map((obj) => {
    //     var dataSourceType = '';
    //     switch (obj.dataSourceRef) {
    //       case 'GoogleAdsDataSource':
    //         dataSourceType = 'Google Ads';
    //         break;
    //       case 'CM360DataSource':
    //         dataSourceType = 'CM360';
    //         break;
    //       case 'DV360DataSource':
    //         dataSourceType = 'DV360';
    //         break;
    //       case 'FacebookAdsDataSource':
    //         dataSourceType = 'Facebook Ads';
    //         break;
    //     }

    //     return {
    //       ...obj,
    //       title: `${dataSourceType} - ${obj.name} - ${obj.dataSourceAccountId}`
    //     };
    //   }) || [];

    // mappedListOfDataSources =
    //   mappedListOfDataSources?.filter(
    //     (obj) => obj.dataSourceRef !== 'GoogleAnalytics4DataSource'
    //   ) || [];

    mappedListOfDataSources.unshift({
      documentType: 'folder',
      title: 'Folder',
      name: 'new folder template',
      template: true,
      status: 'DRAFT'
    });
    mappedListOfDataSources.unshift({
      documentType: 'manualDataEntry',
      title: 'Manual Data Entry',
      name: 'new manual data entry template',
      template: true,
      status: 'DRAFT'
    });

    if (mappedListOfDataSources) {
      setListOfDataSources(mappedListOfDataSources);
    }
  }, [data]);

  const defaultProps = {
    options: listOfDataSources && listOfDataSources,
    getOptionLabel: (option) => option.title
  };

  return (
    <Autocomplete
      {...defaultProps}
      value={templateType}
      onChange={(event, newValue) => {
        setTemplateType(newValue);
      }}
      disablePortal
      id="combo-box-demo"
      //   options={top100Films}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          variant="standard"
          label="Template type"
        />
      )}
    />
  );
}
