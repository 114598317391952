import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import { DateRangePicker } from 'react-date-range';
import { Calendar } from 'react-date-range';

// import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './calendarPicker.css';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import formatDateYYYYMMDD from './../../../roitable_functions/formatDateYYYYMMDD';

// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(2)
//   }
// }));

// function formatDate(yourDate1) {
//   let yourDate = new Date(yourDate1);
//   const offset = yourDate.getTimezoneOffset();
//   yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
//   return yourDate.toISOString().split('T')[0];
// }

const SimplePopover = ({
  stylesStoredInRedux,
  item,
  itemKey,
  setSelectedDate,
  inputValue,
  setInputValue,
  onSubmitHandler
}) => {
  //######################
  const [date, setDate] = useState(new Date(item[itemKey]));

  const handleSelect = (date1) => {
    const newD = formatDateYYYYMMDD(date1);

    setInputValue(newD);

    // date1.setHours(5);
    // setInputValue(date1.toISOString().split('T')[0]);
  };

  const handleOnBlur = () => {
    // setSelectedDateStart(startDate.toISOString().split('T')[0]);
    // setSelectedDateStop(endDate.toISOString().split('T')[0]);
  };

  const selectionRange = {
    stylesStoredInRedux,
    // startDate: startDate,
    // endDate: endDate,
    key: 'selection',
    showMonthAndYearPickers: true
    // color: 'rgba(255,255,255,0.3)',
    // rangeColors: 'rgba(255,255,255,1)'
  };

  let dateRangePickerClassName;
  let buttonStyle = {};

  switch (stylesStoredInRedux.styleName) {
    case 'light':
      dateRangePickerClassName = 'light-background';
      break;
    case 'dark':
      dateRangePickerClassName = 'dark-background';
      buttonStyle = { backgroundColor: '#333', color: 'white' };
      selectionRange.color = 'rgba(255,255,255,0.3)';
      selectionRange.rangeColors = 'rgba(255,255,255,1)';
      break;
  }

  //######################

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.preventDefault();

    onSubmitHandler(e);

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="dark" style={{ display: 'inline', padding: '3px 5px' }}>
      {/* <Button
        style={buttonStyle}
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        {selectedDateStart + ' - ' + selectedDateStop}
      </Button> */}
      <CalendarTodayIcon
        style={{ fontSize: '12px', marginLeft: '1px', marginBottom: '1px' }}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {/* <Typography className={classes.typography}>
          The content of the Popover.
        </Typography> */}
        <Calendar
          // scroll={{ enabled: true }}
          className={dateRangePickerClassName}
          //   ranges={[selectionRange]}
          //   onChange={handleSelect}
          date={new Date(inputValue)}
          onChange={handleSelect}
        />
      </Popover>
    </div>
  );
};

// export default React.memo(SimplePopover);

SimplePopover.propTypes = {};

const mapStateToProps = (state) => ({
  stylesStoredInRedux: state.style
});

export default connect(mapStateToProps, {})(React.memo(SimplePopover));
