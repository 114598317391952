import axios from 'axios';
import { setAlert } from './alert';
import { getManagerAccounts } from './manageraccount';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  CLEAR_MANAGER_ACCOUNT
} from './types';

// Load User

let REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

if (window.location.host === 'nexustable-dev.herokuapp.com') {
  REACT_APP_BACKEND_URL = 'https://nexustable-dev.herokuapp.com/api';
}

export const loadUser = () => async (dispatch) => {
  try {
    const res = await axios.get(REACT_APP_BACKEND_URL + '/auth');
    if (!res.data) {
      delete axios.defaults.headers.common['x-auth-token'];
      localStorage.removeItem('token');
    }

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    delete axios.defaults.headers.common['x-auth-token'];
    localStorage.removeItem('token');

    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Register User
export const register =
  ({ name, email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = JSON.stringify({ name, email, password });

    try {
      const res = await axios.post(
        REACT_APP_BACKEND_URL + '/users',
        body,
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: REGISTER_FAIL
      });
    }
  };

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(REACT_APP_BACKEND_URL + '/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
    dispatch(getManagerAccounts());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

export const loginGoogle = (access_token) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ access_token });

  try {
    const res = await axios.post(REACT_APP_BACKEND_URL + '/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
    dispatch(getManagerAccounts());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: CLEAR_MANAGER_ACCOUNT });
  dispatch({ type: LOGOUT });

  localStorage.removeItem('token');
};
