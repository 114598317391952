// import * as React from 'react';
// import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import UserAutoComplete from './UserAutoComplete';

import React, { useState } from 'react';

import { useTheme } from '@emotion/react';

function UserSelectPopover({ selectedUser, setSelectedUser }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        position: 'relative',
        zIndex: '5000',
        width: '220px',
        display: 'flex',
        flexDirection: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onClick={(e) => {
        // e.preventDefault();
        // e.stopPropagation();
      }}
    >
      <Button
        onClick={() => setOpen(!open)}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '14px',
          height: '14px',
          fontSize: '12px',
          minWidth: '10px'
          // width: '220px'
          // background: 'red'
        }}
      >
        {selectedUser?.name}
      </Button>

      {open && (
        <div
          onBlur={() => {
            console.log('debug39>onBlur');
            setOpen(false);
          }}
          style={{
            position: 'absolute',
            // background: 'red',
            background:
              theme?.palette?.mode === 'dark'
                ? 'rgba(0,0,0,0.6)'
                : 'rgba(255,255,255,0.7)',
            border:
              '1px solid' + theme?.palette?.mode === 'dark'
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(255,255,255,1)',

            backdropFilter: 'blur(10px)',
            padding: '10px',
            borderRadius: '5px',
            // height: '220px',
            width: '220px',
            top: '20px',
            left: '0px'
          }}
        >
          <UserAutoComplete
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
          {/* UserSelectPopover */}
        </div>
      )}
    </div>
  );
}

export default UserSelectPopover;
