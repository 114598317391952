import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function BasicMenu({ documentType, addFilterHandler }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (field) => {
    addFilterHandler(field);
    setAnchorEl(null);
  };

  let _menuOptions = [];

  switch (documentType) {
    case 'googleAds':
      _menuOptions = [
        {
          name: 'Campaign Name',
          value: 'campaignName'
          // type: 'STRING',
          // operator: 'CONTAINS'
        },
        {
          name: 'Advertising Channel Type',
          value: 'campaignAdvertisingChannelType'
          // type: 'ARRAY',
          // operator: 'IN'
        },
        {
          name: 'Advertising Channel Sub Type',
          value: 'campaignAdvertisingChannelSubType'
          // type: 'ARRAY',
          // operator: 'IN'
        }
      ];
      break;
    case 'microsoftAdvertising':
      _menuOptions = [
        {
          name: 'Campaign Name',
          value: 'campaignName'
          // type: 'STRING',
          // operator: 'CONTAINS'
        },
        {
          name: 'Campaign Type',
          value: 'campaignType'
          // type: 'ARRAY',
          // operator: 'IN'
        },
        {
          name: 'Sub Type',
          value: 'subType'
          // type: 'ARRAY',
          // operator: 'IN'
        }
      ];
      break;

    case 'appleSearchAds':
      _menuOptions = [
        {
          name: 'Campaign Name',
          value: 'campaignName'
          // type: 'STRING',
          // operator: 'CONTAINS'
        }
        // {
        //   name: 'Campaign Type',
        //   value: 'campaignType'
        //   // type: 'ARRAY',
        //   // operator: 'IN'
        // },
        // {
        //   name: 'Sub Type',
        //   value: 'subType'
        //   // type: 'ARRAY',
        //   // operator: 'IN'
        // }
      ];
      break;
  }

  return (
    <div>
      <Button
        size="small"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Add filter +
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {_menuOptions.map((obj) => (
          <MenuItem
            style={{ fontSize: '14px' }}
            onClick={() => handleClose(obj.value)}
          >
            {obj.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
