import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Comment from './../../item-components/body/comment/Comment';
import HistoryEvent from './../../item-components/body/comment/HistoryEvent';
import Poke from './../../item-components/body/comment/Poke';

import Comments from './../../item-components/body/comment/Comments';
import CardMenu from './../../../news-feed/CardMenu';

import './../../../news-feed/news-feed.css';

import AddComment from './AddComment';
// import {
//   useQuery,
//   useLazyQuery,
//   useMutation,
//   useSubscription
// } from '@apollo/client';

// import {
//   newsFeedQuery,
//   commentAddedSubscription
// } from './../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';
import { AuthContext } from '../../../../context/AuthContext';

import { styled, useTheme } from '@mui/material/styles';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

import CommentIcon from '@mui/icons-material/Comment';

import Spinner from './../../../layout/SpinnerSmall';

import { FilterContext } from '../../../../context/FilterContext';

// import ReactionBar from './ReactionBar';

import NewsFeedItemWrapper from './../../../news-feed/NewsFeedItemWrapper';

import { DataContext } from '../../../../context/DataContext';

// import Comment from './../tableV4/item-components/body/comment/Comment';

function NewsFeed({ newsFeedItems, loading, item }) {
  const navigate = useNavigate();
  const { currentManagerAccountId, currentAccountIds, setCurrentAccountIds } =
    useContext(AccountContext);

  const { filters, setFilters, filterBySelection, setFilterBySelection } =
    useContext(FilterContext);

  const { listOfData } = useContext(DataContext);

  const theme = useTheme();

  console.log('currentAccountIds: ', currentAccountIds);
  // const variables = {
  //   input: {
  //     managerAccountId: currentManagerAccountId,
  //     accountIds: currentAccountIds,
  //     includeHistory: true
  //   }
  // };
  // //   const { data } = useQuery(newsFeedQuery, {
  // //     variables,
  // //     fetchPolicy: 'no-cache',
  // //     skip: false
  // //   });

  // const [
  //   getData,
  //   { loading, error, data, refetch, client, startPolling, stopPolling }
  // ] = useLazyQuery(newsFeedQuery, {
  //   variables,

  //   fetchPolicy: 'no-cache',

  //   onCompleted: () => {
  //     console.log('RoiTable>Query completed.');
  //   },
  //   onError: (error) => {
  //     console.log('error: ', error);
  //   }
  // });

  // const [rerender, setRerender] = useState(0);

  // useSubscription(commentAddedSubscription, {
  //   variables: {
  //     input: { screen: 'NEWSFEED', accountIds: currentAccountIds }
  //   },
  //   // skip: subscriptionSkip,
  //   onSubscriptionData: async ({ client, subscriptionData }) => {
  //     let scrollToBot = false;

  //     if (
  //       messagesEndRef.current.scrollTop >
  //       messagesEndRef?.current?.scrollHeight - 1000
  //     ) {
  //       scrollToBot = true;
  //     }
  //     console.log('subscriptionData: ', subscriptionData);
  //     let newComments;
  //     const copyOfData = [...data.newsFeedItems];
  //     // const copyOfComments = [...data.comments]

  //     const commentObj = subscriptionData.data.commentAdded.comment;

  //     //   console.log(
  //     //     'messagesEndRef.current.scrollTop: ',
  //     //     messagesEndRef.current.scrollTop
  //     //   );
  //     //   console.log(
  //     //     'messagesEndRef?.current?.scrollHeight: ',
  //     //     messagesEndRef?.current?.scrollHeight
  //     //   );
  //     //   console.log('commentAddedSubscription>commentObj: ', commentObj);

  //     // const rowInRawArrayOfData = rawArrayOfData.filter(
  //     //   (rawRow) => rawRow._id === commentObj.documentId
  //     // );

  //     // if (rowInRawArrayOfData && rowInRawArrayOfData[0]) {
  //     //   commentObj.path = rowInRawArrayOfData[0].path;
  //     //   commentObj.interactivePath = rowInRawArrayOfData[0].interactivePath;
  //     // }

  //     switch (subscriptionData.data.commentAdded.action) {
  //       case 'add_comment':

  //       case 'add_history_event':
  //         newComments = [
  //           ...data.newsFeedItems,
  //           commentObj
  //           // subscriptionData.data.commentAdded.comment
  //         ];
  //         break;

  //       case 'add_child_comment':
  //         const parentCommentIndex = data.newsFeedItems.findIndex((comment) => {
  //           return (
  //             // comment._id === subscriptionData.data.commentAdded.comment._id
  //             comment._id === commentObj.parentDocumentId
  //           );
  //         });

  //         const copyOfObject = { ...data.newsFeedItems[parentCommentIndex] };
  //         copyOfObject.childComments.push(commentObj);

  //         console.log('parentCommentIndex: ', parentCommentIndex);

  //         copyOfData.splice(
  //           parentCommentIndex,
  //           1,
  //           copyOfObject
  //           // subscriptionData.data.commentAdded.comment
  //         );

  //         newComments = copyOfData;

  //         console.log('newComments: ', newComments);
  //         break;

  //       case 'edit_comment':
  //         // case 'reaction':
  //         const commentIndex = data.newsFeedItems.findIndex((comment) => {
  //           return (
  //             // comment._id === subscriptionData.data.commentAdded.comment._id
  //             comment._id === commentObj._id
  //           );
  //         });

  //         copyOfData.splice(
  //           commentIndex,
  //           1,
  //           commentObj
  //           // subscriptionData.data.commentAdded.comment
  //         );

  //         newComments = copyOfData;

  //         break;

  //       case 'reaction':
  //         console.log('commentObj: ', commentObj);
  //         const reactionIndex = data.newsFeedItems.findIndex((comment) => {
  //           return (
  //             // comment._id === subscriptionData.data.commentAdded.comment._id
  //             comment._id === commentObj._id
  //           );
  //         });

  //         copyOfData.splice(
  //           reactionIndex,
  //           1,
  //           {
  //             ...data.newsFeedItems[reactionIndex],
  //             reactions: commentObj.reactions
  //           }
  //           // subscriptionData.data.commentAdded.comment
  //         );

  //         newComments = copyOfData;

  //         break;
  //     }

  //     data.newsFeedItems = newComments;

  //     setRerender(rerender + 1);
  //     if (scrollToBot) {
  //       scrollToBottom();
  //     }
  //   }
  // });

  // useEffect(() => {
  //   if (
  //     variables?.input?.managerAccountId &&
  //     variables?.input?.accountIds?.length > 0
  //     // variables?.accountsInput?.accountId?.length > 0
  //   ) {
  //     console.log('running get data 65555');
  //     const { data, loading } = getData();
  //     // console.log('running get data>data: ', data);
  //   }
  // }, []);
  useEffect(() => {
    scrollToBottom();
  }, [newsFeedItems]);

  // console.log('NewsFeed>data: ', data);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    if (messagesEndRef?.current?.scrollHeight)
      messagesEndRef.current.scrollTop = messagesEndRef?.current?.scrollHeight;
  };

  //   console.log('data: ', data);

  const onClickPathHandler = (obj, accountId) => {
    // console.log('Card>obj: ', obj);
    // console.log('filters: ', filters);
    setFilterBySelection([obj]);
    if (!filters.includes('filter_by_selection')) {
      setFilters([...filters, 'filter_by_selection']);
    }
    setCurrentAccountIds([accountId]);
    navigate(`/table?manager_account_id=${currentManagerAccountId}`);
  };

  const comments = newsFeedItems?.map((commentObj) => {
    if (listOfData) {
      const rowInRawArrayOfData = listOfData.filter(
        (rawRow) => rawRow._id === commentObj.documentId
      );

      if (rowInRawArrayOfData && rowInRawArrayOfData[0]) {
        commentObj.path = rowInRawArrayOfData[0].path;
        commentObj.interactivePath = rowInRawArrayOfData[0].interactivePath;
      }
    }

    switch (commentObj.type) {
      case 'poke':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <Poke
              key={commentObj._id}
              commentObj={commentObj}
              item={commentObj}
              currentManagerAccountId={currentManagerAccountId}
            />
          </NewsFeedItemWrapper>
        );
        break;

      case 'comment':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <Comment
              key={commentObj._id}
              commentObj={commentObj}
              item={{ ...commentObj, _id: commentObj.documentId }}
              currentManagerAccountId={currentManagerAccountId}
              onClickPathHandler={onClickPathHandler}
            />
          </NewsFeedItemWrapper>
        );
        break;

      case 'historyEvent':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <HistoryEvent
              key={commentObj._id}
              commentObj={commentObj}
              item={{ ...commentObj, _id: commentObj.documentId }}
              currentManagerAccountId={currentManagerAccountId}
              onClickPathHandler={onClickPathHandler}
            />
          </NewsFeedItemWrapper>
        );
        break;
    }
  });

  if (loading) {
    return (
      <div
        style={{
          width: '800px',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
        // height: 'calc(100vh - 64px)'
      }}
    >
      <div
        ref={messagesEndRef}
        style={{
          width: '60%',
          minWidth: '800px',
          maxWidth: '1100px',
          //   background: 'rgba(255,255,255,0.9)',
          //   marginTop: '50px',
          // maxHeight: '85vh',
          maxHeight: '740px',
          overflowY: 'scroll',
          borderRadius: '5px',
          //   padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '15px',
          paddingTop: '10px',
          overflowX: 'hidden',
          maxHeight: '80vh'
        }}
      >
        {comments}
        <AddComment scrollToBottom={scrollToBottom} item={item} />
        {/* <Comments
          comments={comments}
          item={{}}
          currentManagerAccountId={currentManagerAccountId}
        /> */}
      </div>
    </div>
  );
}

export default NewsFeed;
