import React, { useContext } from 'react';
import ArticleIcon from '@mui/icons-material/Article';

import { DialogContext } from '../../../../context/DialogContext';

import Tooltip from '@mui/material/Tooltip';

function InfoModalIcon({ item, currentManagerAccountId }) {
  const { infoModalDocumentOpen, setInfoModalDocumentOpen, setItemId } =
    useContext(DialogContext);
  return (
    <Tooltip title="General info and comments">
      <ArticleIcon
        onClick={() => {
          setItemId(item._id);
          setInfoModalDocumentOpen(true);
        }}
        className="hover-light-grey"
        style={{
          fontSize: '12px',
          cursor: 'pointer',
          marginRight: '4px'
        }}
      />
    </Tooltip>
  );
}

export default InfoModalIcon;
