import React, { useContext, useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import { AccountContext } from '../../../../../context/AccountContext';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useMutation } from '@apollo/client';
import { addDocumentMutation } from '../../../../../graphql/queries';

import StatusMenu from './StatusMenu';

import BasicDatePicker from './BasicDatePicker';

import Members from './members/Members';

import Tooltip from '@mui/material/Tooltip';
import { Checkbox } from '@mui/material';

import { MutationContext } from '../../../../../context/MutationContext';

export default function BasicPopover({
  stylesStoredInRedux,
  status,
  account,
  setAccount
}) {
  const { currentManagerAccount, currentAccounts } = useContext(AccountContext);

  // console.log('currentAccounts: ', currentAccounts);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const [addDocument] = useMutation(addDocumentMutation);

  const { addDocument } = useContext(MutationContext);

  const [personal, setPersonal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setName('');
    // setStatus('DRAFT');
    setStartDate(null);
    setEndDate(null);
    setMembers([]);

    // setAccount(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // const [account, setAccount] = React.useState(null);

  const [name, setName] = useState('');

  // const [status, setStatus] = useState('DRAFT');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (currentAccounts && !account) {
      setAccount(currentAccounts[0]);
    }
  }, [currentAccounts, open]);

  const defaultProps = {
    options: currentAccounts,
    getOptionLabel: (option) => option.name
  };

  const onClickCreateHandler = () => {
    // let addDocumentInput = {
    //   action: 'CREATE_DOCUMENT',
    //   managerAccountId: currentManagerAccount._id,
    //   // documentType: 'container',
    //   documentType: 'task',
    //   newDocumentAccountAccessLevel: 1,
    //   checkedDocumentId: account.globalTasksId
    //   // document: {}
    // };

    // addDocumentInput = {
    //   ...addDocumentInput,
    //   level: 2,
    //   accountId: account._id,
    //   document: {
    //     name,
    //     status,
    //     startDate,
    //     endDate,
    //     members,
    //     personal
    //   }
    // };

    let addDocumentInput = {
      managerAccountId: currentManagerAccount._id,
      accountId: account._id,
      checkedDocumentId: account.globalTasksId,
      level: 2,
      newDocuments: [
        {
          documentType: 'task',
          name,
          status,
          startDate,
          endDate,
          members,
          personal
        }
      ]
    };

    if (startDate) {
      addDocumentInput.newDocuments[0].startDate = startDate;
    }
    if (endDate) {
      addDocumentInput.newDocuments[0].endDate = endDate;
    }
    console.log('CreateTodoButton>addDocumentInput: ', addDocumentInput);

    addDocument({ variables: { input: { ...addDocumentInput } } });

    handleClose();
  };

  return (
    <div>
      <Tooltip title="Create task">
        <Button
          onClick={handleClick}
          style={{
            // backgroundColor: 'rgba(0,0,0,0)',
            height: '20px',
            width: '20px',
            maxWidth: '20px',
            minWidth: '20px',
            padding: '5px',
            color:
              stylesStoredInRedux?.styleName === 'light' ? '#a7268d' : 'white'
          }}
          size="small"
          // sx={{ width: '20px' }}
          // variant="contained"
        >
          <AddIcon />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left'
        // }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div
          style={{
            width: '300px',
            maxHeight: '600px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start'
          }}
        >
          <div
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              margin: '0px 0px 10px 0px'
            }}
          >
            Create Task
          </div>
          <div style={{ width: '100%' }}>
            <Autocomplete
              size="small"
              {...defaultProps}
              id="controlled-demo"
              value={account}
              onChange={(event, newValue) => {
                setAccount(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, style: { fontSize: 12 } }} // font size of input text
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: { fontSize: 12 }
                  }} // font size of input label
                  label="Account"
                  variant="standard"
                  size="small"
                />
              )}
            />
          </div>
          <div style={{ margin: '15px 0px', width: '100%' }}>
            <TextField
              inputProps={{ style: { fontSize: 12 } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
              style={{ width: '100%' }}
              id="standard-size-small"
              variant="standard"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="small"
              placeholder="New task"
            />
          </div>
          {/* <div style={{ margin: '35px 0px 0px 0px' }}>
            <StatusMenu status={status} setStatus={setStatus} showText={true} />
          </div> */}

          <div
            style={{
              margin: '10px 0px 0px 0px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              transform: 'scale(0.72)',
              width: '360px',
              left: '-50px',
              position: 'relative'
            }}
          >
            <div style={{ width: '170px' }}>
              <BasicDatePicker
                value={startDate}
                setValue={setStartDate}
                label={'Start Date'}
                size="small"
              />
            </div>
            <div style={{ width: '170px' }}>
              <BasicDatePicker
                value={endDate}
                setValue={setEndDate}
                label={'End Date'}
                size="small"
              />
            </div>
          </div>
          <div style={{ margin: '20px 0px 20px 0px', width: '100%' }}>
            <Members
              accountId={account?._id}
              members={members}
              setMembers={setMembers}
            />
          </div>
          <div style={{ fontSize: '12px' }}>
            Personal{' '}
            <Checkbox
              value={personal}
              onChange={() => setPersonal(!personal)}
              size="small"
            />
          </div>
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              width: '100%'
            }}
          >
            <Button
              size="small"
              onClick={handleClose}
              style={{ fontSize: '12px' }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={onClickCreateHandler}
              style={{ fontSize: '12px' }}
            >
              Create
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
