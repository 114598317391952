import React, { useState, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import EditIcon from '@mui/icons-material/Edit';

import { useMutation } from '@apollo/client';
import { updateLabelMutation } from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';
import { TextField } from '@mui/material';

import { SnackbarProvider, useSnackbar } from 'notistack';

export default function BasicPopover({ label, refetchLabels }) {
  const { currentManagerAccount } = useContext(AccountContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [value, setValue] = useState(label.name);

  const [updateLabel] = useMutation(updateLabelMutation);

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSave = async () => {
    if (value === label.name) {
      setAnchorEl(null);
      return;
    }

    if (value.length > 15) {
      enqueueSnackbar('Label name must be below 15 characters.', {
        // variant: 'error',
        // persist: true
      });
      return;
    }

    const input = {
      managerAccountId: currentManagerAccount._id,
      accountId: label.accountId,
      labelId: label._id,
      name: value
    };

    try {
      const res = await updateLabel({ variables: { input } });
      if (res?.data?.updateLabel?.response === 'Success') {
        refetchLabels();
        setAnchorEl(null);
      }
    } catch (error) {
      console.log('CreateLabel>error');
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <EditIcon
        style={{ position: 'relative', top: '2px', width: '20px' }}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div
          style={{ padding: '15px', display: 'flex', flexDirection: 'column' }}
        >
          <div>
            <TextField
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{ width: '200px' }}
              id="standard-basic"
              label="Label name"
              variant="standard"
            />
          </div>
          <div
            style={{
              marginTop: '15px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={() => handleClose()} size="small">
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              variant="contained"
              size="small"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
