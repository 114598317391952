import React, { useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';

import WidgetOption from './WidgetOption';

import SaveIcon from '@mui/icons-material/Save';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import Checkbox from '@mui/material/Checkbox';

import { Button } from '@mui/material';

function ListWidgetMenu({
  setValueIsEdited,
  originalWidgetCopy,
  displayedOptions,
  valueIsEdited,
  setDisplaySaveButton,
  inputRef,
  textInput,
  setTextInput,
  widgetIndex,
  options,
  displayOptions,
  setOptions,
  editMode,
  widget,
  updateWidget,
  toggleEdit
}) {
  const [isDraggingOption, setIsDraggingOption] = useState(false);
  const theme = useTheme();

  const onClickRemoveOptionHandler = (e, optionIndex) => {
    const copyOfOptions = [...options];
    copyOfOptions.splice(optionIndex, 1);
    // setOptions(copyOfOptions);
    updateWidget({ ...widget, options: copyOfOptions }, widgetIndex);
  };
  // const options = [
  //   { type: 'STRING', value: 'branding' },
  //   { type: 'STRING', value: 'prospecting' },
  //   { type: 'STRING', value: 'retargeting' }
  // ];

  // useEffect(() => {
  //   setOptions([...(widget?.options || [])]);
  // }, [widget.options]);

  const onClickAddOptionsHandler = () => {
    setDisplaySaveButton(true);
    setOptions([...options, { type: 'STRING', value: 'New option' }]);
  };
  console.log('options: ', options);

  const setWidgetOption = (e, index, newWidgetOption) => {
    const copyOfOptions = [...options];
    copyOfOptions.splice(index, 1, newWidgetOption);
    setOptions(copyOfOptions);
  };

  const dropOnField = (e, value) => {
    console.log('debug76>dropOnField');
    // toggleGrabbing(false);
    setIsDraggingOption(false);
    console.log('value: ', value);
    e.preventDefault();
    e.stopPropagation();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const droppedItem = JSON.parse(droppedItemString);
    // console.log('debug>droppedItem: ', droppedItem);
    // console.log(
    //   'debug>droppedItem.originRowIndex:',
    //   droppedItem.originRowIndex
    // );
    // console.log('debug>rowIndex: ', rowIndex);
    // console.log(
    //   'debug>droppedItem.originRowIndex !== 0: ',
    //   droppedItem.originRowIndex !== 0
    // );
    // if (
    //   (droppedItem.originRowIndex || droppedItem.originRowIndex === 0) &&
    //   droppedItem.originRowIndex !== rowIndex
    // ) {
    //   return;
    // }
    let copyOfOptions = [...options];

    console.log('droppedItem.origin: ', droppedItem.origin);
    // if (droppedItem.origin === 'item_widgets') {
    console.log('droppedItem.index: ', droppedItem.originIndex);
    copyOfOptions.splice(droppedItem.originIndex, 1);

    console.log('copyOfOptions: ', copyOfOptions);
    // }
    setOptions([...copyOfOptions, droppedItem.value]);

    const droppedItemElement = document.getElementById(droppedItem);
  };

  return (
    <div
      onDrop={dropOnField}
      // onBlur={(e) => {
      //   e.stopPropagation();
      // }}
      style={{
        marginTop: '8px',
        position: 'fixed',
        width: '200px',
        // height: displaySaveButton ? '225px' : '200px',
        height: '200px',
        // border: '1px solid white',
        overflow: 'hidden',

        zIndex: '1000',
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgba(87,76,126,0.9)'
            : 'rgba(255,255,255,0.8)',
        borderRadius: '5px',
        // overflow: 'hidden',
        backdropFilter: 'blur(10px)',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <div
          style={{
            width: '100%',
            height: '20px',
            // background: '#bcbcbc',
            background: `
      linear-gradient(17deg, rgba(54,82,156,.2), rgba(54,82,156,0) 70.71%),
      linear-gradient(336deg, rgba(167,38,131,.2), rgba(167,38,131,0) 70.71%)

      `,
            backdropFilter: 'blur(10px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            padding: '0 5px'
          }}
        >
          <div>
            <span style={{ fontSize: '10px' }}>Dynamic</span>
            <Checkbox
              size="small"
              style={{
                fontSize: '10px',
                transform: 'scale(0.6)',
                left: '-8px'
              }}
            />
            {/* <input type="checkbox" /> */}
          </div>
          <div>
            <span style={{ fontSize: '10px' }}>Optional</span>
            <Checkbox
              size="small"
              style={{
                fontSize: '10px',
                transform: 'scale(0.6)',
                left: '-8px'
              }}
            />
            {/* <input type="checkbox" /> */}
          </div>
          <CloseIcon
            style={{ fontSize: '12px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              updateWidget(
                { ...widget, ['value']: textInput || originalWidgetCopy.value },
                widgetIndex
              );

              if (!textInput) {
                setValueIsEdited(false);
              }

              toggleEdit(false);
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            maxHeight: '160px'
          }}
        >
          {displayedOptions?.map((option, optionIndex) => (
            <WidgetOption
              widgetIndex={widgetIndex}
              isDraggingOption={isDraggingOption}
              setIsDraggingOption={setIsDraggingOption}
              editMode={editMode}
              onClickRemoveOptionHandler={onClickRemoveOptionHandler}
              option={option}
              options={options}
              setOptions={setOptions}
              optionIndex={optionIndex}
              textInput={textInput}
              setTextInput={setTextInput}
              setWidgetOption={setWidgetOption}
              inputRef={inputRef}
              toggleEdit={toggleEdit}
              widget={widget}
              updateWidget={updateWidget}
            />
          ))}
        </div>
      </div>

      {editMode && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              width: '100%',
              height: '20px',
              background:
                theme?.palette?.mode === 'dark'
                  ? 'rgba(255,255,255,0.1)'
                  : 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              color: theme?.palette?.mode === 'dark' ? 'white' : 'black'
            }}
            onClick={onClickAddOptionsHandler}
            onMouseDown={(e) => {
              e.preventDefault();
              // e.stopPropagation();
            }}
          >
            + Add
          </div>
          {/* {displaySaveButton && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              size="small"
              style={{ width: '100%', fontSize: '10px' }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              style={{
                width: '100%',
                background: 'rgba(0,255,0,0.3)',
                fontSize: '10px'
              }}
            >
              OK
            </Button>
          </div>
        )} */}
        </div>
      )}
    </div>
  );
}

export default ListWidgetMenu;
