import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectConjunction({ input, setInput }) {
  const [conjunction, setConjunction] = React.useState('OR');

  const handleChange = (event) => {
    setInput({ ...input, conjunction: event.target.value });
  };

  useEffect(() => {
    setConjunction(input?.conjunction);
  }, [input?.conjunction]);

  // console.log('input?.conjunction: ', input?.conjunction);
  return (
    <Box sx={{ minWidth: 70 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={conjunction}
          // label="Age"
          onChange={handleChange}
        >
          <MenuItem value={'AND'}>AND</MenuItem>
          <MenuItem value={'OR'}>OR</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
