import React, { useEffect, useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {
  nexusTemplateDocumentsQuery,
  UpdateAccountToDataSourceSettingsMutation
} from './../../../graphql/queries';
import { useLazyQuery, useMutation } from '@apollo/client';

import { DialogContext } from './../../../context/DialogContext';

import { Button } from '@mui/material';

import { useSnackbar } from 'notistack';

const dataSourceRefToDocumentTypeDict = {
  GoogleAdsDataSource: 'googleAds',
  DV360DataSource: 'dv360',
  FacebookAdsDataSource: 'facebookAds',
  SnapchatAdsDataSource: 'snapchatAds',
  TikTokAdsDataSource: 'tikTokAds',
  PinterestAdsDataSource: 'pinterestAds',
  ReadpeakDataSource: 'readpeak',
  StrossleDataSource: 'strossle',
  AmazonDspDataSource: 'amazonDsp',
  TaboolaAdsDataSource: 'taboolaAds',
  MicrosoftAdvertisingDataSource: 'microsoftAdvertising',
  MicrosoftInvestDataSource: 'microsoftInvest',
  AppleSearchAdsDataSource: 'appleSearchAds',
  LinkedInAdsDataSource: 'linkedInAds',
  CM360DataSource: 'cm360',
  AdformDataSource: 'adform'
};

export default function ComboBox({
  managerAccountId,
  accountId,
  value,
  setValue,
  dataSource
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { editDialogOpen } = useContext(DialogContext);

  const [loadTemplateDocuments, { called, loading, data }] = useLazyQuery(
    nexusTemplateDocumentsQuery,
    {
      variables: {
        input: {
          managerAccountId,
          accountId,
          dataSourceAccountId: dataSource.dataSourceAccountId,
          documentType:
            dataSourceRefToDocumentTypeDict[dataSource.dataSourceRef]
        }
      },
      fetchPolicy: 'no-cache'
    }
  );
  console.log('dataSource: ', dataSource);

  const [updateAccountToDataSourceSettingsMutation] = useMutation(
    UpdateAccountToDataSourceSettingsMutation
  );

  useEffect(() => {
    if (managerAccountId && accountId) {
      loadTemplateDocuments();
    }
  }, [managerAccountId, accountId, editDialogOpen]);

  const templateTypeDict = {
    manualDataEntry: 'Manual Data Entry'
  };

  const [nexusTemplateDocuments, setNexusTemplateDocuments] = useState([]);

  useEffect(() => {
    if (data?.nexusTemplateDocuments) {
      const nexusTemplateDocuments_ = data?.nexusTemplateDocuments.map(
        (obj) => {
          const newObj = { ...obj };
          newObj.name =
            `${templateTypeDict[obj.documentType] || obj.documentType} - ` +
            obj.name;
          return newObj;
        }
      );
      setNexusTemplateDocuments([
        { name: 'None', _id: null },
        ...nexusTemplateDocuments_
      ]);

      if (dataSource.defaultNexusDocumentTemplateId) {
        const defaultDoc = nexusTemplateDocuments_.filter(
          (obj) => obj._id === dataSource.defaultNexusDocumentTemplateId
        )[0];
        if (defaultDoc) {
          setValue(defaultDoc);
        } else {
          setValue({
            name: 'DEFAULT TEMPLATE DOCUMENT MISSING',
            _id: null,
            string: 'MISSING'
          });
        }
      } else {
        setValue({ name: 'None', _id: null });
      }
    }
  }, [data]);

  const onClickSaveAsDefaultHandler = async () => {
    const input = {
      managerAccountId,
      accountId,
      templateDocumentId: value?._id,
      accountToDataSourceRelationId: dataSource.accountToDataSourceRelationId,
      updateKeys: ['defaultNexusDocumentTemplateId']
    };

    try {
      const res = await updateAccountToDataSourceSettingsMutation({
        variables: { input }
      });

      console.log('res: ', res);
      if (res.data.updateAccountToDataSourceSettings.response === 'success') {
        enqueueSnackbar('Saved', {
          // variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar('Error', {
        variant: 'error'
      });
    }
  };
  // let nexusTemplateDocuments = data?.nexusTemplateDocuments || [];
  // if (nexusTemplateDocuments) {
  //   nexusTemplateDocuments = nexusTemplateDocuments.map((obj) => {
  //     const newObj = { ...obj };
  //     newObj.name =
  //       `${templateTypeDict[obj.documentType] || obj.documentType} - ` +
  //       obj.name;
  //     return newObj;
  //   });
  // }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={nexusTemplateDocuments}
        getOptionLabel={(option) => option.name}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{ width: '100%' }}
        size="small"
        renderInput={(params) => (
          <TextField variant="standard" {...params} label="Template" />
        )}
      />
      <Button
        size="small"
        style={{ width: '150px', marginLeft: '20px', height: '31px' }}
        onClick={onClickSaveAsDefaultHandler}
      >
        Save as Default
      </Button>
    </div>
  );
}
