import React, { useState, useEffect } from 'react';

import SimpleBarChart from './SimpleBarChart';

import TwoLevelPieChart from './TwoLevelPieChart';

function Charts({
  columns,
  filteredArrayOfData,
  isExpanded,
  rawArrayOfData,
  account,
  setAccount,
  hideSelected
}) {
  const [statusCount, setStatusCount] = useState([]);
  const [completedCount, setCompletedCount] = useState([]);

  const [displayFilteredData, setDisplayFilteredData] = useState([]);

  useEffect(() => {
    const filteredColumns = filteredArrayOfData.filter(
      (row) =>
        !hideSelected.includes(row.documentType) &&
        !row.template &&
        [
          'folder',
          'manualDataEntry',
          'customForm',
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'microsoft_invest_insertion_order',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group',
          'task',
          'taxonomy'
        ].includes(row.type)
    );
    setDisplayFilteredData(filteredColumns);
  }, [hideSelected, filteredArrayOfData]);

  useEffect(() => {
    console.log('Charts>columns: ', columns);
    const newStatusCount = [];
    for (let column of columns) {
      let statusCount = 0;
      for (let row of displayFilteredData) {
        if (row.status === column.string) {
          statusCount++;
        }
      }
      if (statusCount) {
        newStatusCount.push({
          name: column.name,
          value: statusCount,
          share: statusCount / displayFilteredData.length
        });
      }
    }
    setStatusCount(newStatusCount);
    console.log('Charts>columns: ', columns);

    const statusCompleted = displayFilteredData.filter(
      (row) => row.status === 'COMPLETED'
    );
    console.log('statusCompleted: ', statusCompleted.length);
    setCompletedCount([
      {
        name: 'COMPLETED',
        value: statusCompleted.length,
        share: statusCompleted / displayFilteredData.length
      },
      {
        name: 'NOT COMPLETED',
        value: displayFilteredData.length - statusCompleted.length
      }
    ]);
  }, [displayFilteredData]);
  console.log('Charts>statusCount: ', statusCount);

  return (
    <div
      style={{
        width: '95vw',
        // height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        height: '100%'
        // alignContent: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}
      >
        <div
          className="SimpleBarChart"
          style={{ width: '600px', height: '400px' }}
        >
          <SimpleBarChart data={statusCount} />
        </div>
        <div
          style={{
            width: '600px',
            height: '400px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <TwoLevelPieChart
            data01={completedCount}
            data02={statusCount}
            totalCount={displayFilteredData.length}
          />
        </div>
      </div>
    </div>
  );
}

export default Charts;
