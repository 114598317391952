import React from 'react';

import { connect } from 'react-redux';

import CardAvatar from './members/CardAvatar';
import MembersPopover from './members/MembersPopover';

import StatusMenu from './../../../item-components/body/StatusMenu';

import Tooltip from '@mui/material/Tooltip';

import TextInput from './TextInput';

function SubTask({ item, currentManagerAccountId, stylesStoredInRedux }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: '5px',
        backgroundColor: 'rgba(150, 150, 150, 0.1)',
        padding: '10px',
        margin: '10px 0px'
      }}
    >
      <TextInput
        // fieldTitle="Name"
        // currentManagerAccount={currentManagerAccountId}
        itemKey={'name'}
        item={item}
        stylesStoredInRedux={stylesStoredInRedux}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          width: '375px'
        }}
      >
        <div style={{ width: '150px', textAlign: 'center', fontSize: '10px' }}>
          {item.startDate && <span>{item.startDate}</span>} —{' '}
          {item.endDate && <span>{item.endDate}</span>}
        </div>
        <MembersPopover item={item} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            minWidth: '100px'
          }}
        >
          <StatusMenu
            currentManagerAccountId={currentManagerAccountId}
            item={item}
            showText={true}
          />
        </div>
      </div>
    </div>
  );
}

// export default SubTask;
SubTask.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(SubTask);
