import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import GetAppIcon from '@mui/icons-material/GetApp';

import DataExportExcel from './DataExportExcel';
// import ExportPDF from './ExportPDF';
// import ExportIMG from './ExportIMG';

import { InterfaceContext } from '../../../../context/InterfaceContext';

export default function BasicMenu({
  arrayOfData,
  selectedDateStart,
  selectedDateStop,
  summaryRow,
  setSummaryRow,
  range2 = { range2 }
}) {
  const { setExportMode } = useContext(InterfaceContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setExportMode(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // setExportMode(false);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{ minWidth: '40px' }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <GetAppIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <DataExportExcel
          handleClose={handleClose}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          summaryRow={summaryRow}
          range2={range2}
          arrayOfData={arrayOfData}
        />
        {/* <ExportIMG handleClose={handleClose} />
        <ExportPDF handleClose={handleClose} /> */}
      </Menu>
    </div>
  );
}
