import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  ReferenceDot,
  Tooltip,
  CartesianGrid,
  Legend,
  Brush,
  ErrorBar,
  AreaChart,
  Area,
  Label,
  LabelList
} from 'recharts';

import { getDayAsString } from '../../../../roitable_functions/date_functions';

import LineChartPopover from './line-chart-popover/LineChartPopover';

function LineChart1({
  componentIndex,
  prepared_data,
  field,
  // leftYAxisMetrics,
  // rightYAxisMetrics,
  time,
  isExpanded,
  columnDictionary,
  fields,
  setFields
}) {
  // ##### popover #####

  const [anchorPosition, setAnchorPosition] = React.useState({
    top: null,
    left: null
  });

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // ###################

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          style={{ fontSize: '12px' }}
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomLabel = (props) => {
    const { x, y, stroke, metric } = props;
    return (
      // <g>
      //   {/* <rect
      //     x={props.viewBox.x}
      //     y={props.viewBox.y}
      //     fill="#aaa"
      //     width={50}
      //     height={30}
      //   /> */}
      //   <text
      //     style={{ fontSize: '12px', lineBreak: 'anywhere' }}
      //     x={props.viewBox.x}
      //     y={props.viewBox.y}
      //     fill="#111"
      //     dy={-20}
      //     dx={30}
      //   >
      //     {props.metric.dataKey}
      //   </text>
      // </g>
      <g
      // transform={`translate(${x},${y})`}
      >
        <text
          style={{
            fontSize: '12px',
            lineBreak: 'anywhere'
          }}
          x={props.viewBox.x}
          y={props.viewBox.y}
          fill="#111"
          dy={-20}
          dx={30}
        >
          <tspan textAnchor="middle" x="50">
            Page
          </tspan>
          <tspan textAnchor="middle" x="80" dy="20">
            {props.metric.dataKey}
          </tspan>
        </text>
      </g>
    );
  };

  // class CustomizedAxisTick extends PureComponent {
  //     render() {
  //       const {
  //         x, y, stroke, payload,
  //       } = this.props;

  //       return (
  //         <g transform={`translate(${x},${y})`}>
  //           <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
  //         </g>
  //       );
  //     }
  //   }

  const data = prepared_data;

  const listOfColors = [
    '#1784a6',
    // '#00adb5',
    // '#b24393',
    // '#4bacfd',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#7ED321',
    '#BD10E0'
  ];

  function CustomTooltip({ payload, label, active }) {
    if (active) {
      switch (time) {
        case 'day':
          return (
            // <div className="custom-tooltip">
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,0.9',
                padding: '10px',
                borderRadius: '5px',
                color: 'black',
                fontSize: '14px'
              }}
            >
              <p className="label">{`${label}`}</p>
              <p className="intro">{getDayAsString(label)}</p>

              {payload &&
                payload.map((obj) => {
                  return (
                    <p className="label">
                      <span style={{ color: obj.stroke }}>- </span>
                      {`${obj.dataKey}: ${obj.value.toLocaleString()}`}
                    </p>
                  );
                })}

              {/* <p className="label">{`${payload[0].value.toLocaleString()}`}</p> */}

              {/* <p className="desc">Anything you want can be displayed here.</p> */}
            </div>
          );
        case 'week':
          return (
            // <div className="custom-tooltip">
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,0.9',
                padding: '10px',
                borderRadius: '5px',
                color: 'black',
                fontSize: '14px'
              }}
            >
              <p className="label">{`Week ${label.split('-')[1]}`}</p>
              {/* <p className="label">{`${payload[0].value.toLocaleString()}`}</p> */}
              {payload &&
                payload.map((obj) => {
                  return (
                    <p className="label">
                      <span style={{ color: obj.stroke }}>- </span>
                      {`${obj.dataKey}: ${obj.value.toLocaleString()}`}
                    </p>
                  );
                })}

              {/* <p className="desc">Anything you want can be displayed here.</p> */}
            </div>
          );
        case 'month':
          return (
            // <div className="custom-tooltip">
            <div
              style={{
                backgroundColor: 'rgba(255,255,255,0.9',
                padding: '10px',
                borderRadius: '5px',
                color: 'black',
                fontSize: '14px'
              }}
            >
              <p className="label">{`${label}`}</p>
              {/* <p className="label">{`${payload[0].value.toLocaleString()}`}</p> */}
              {payload &&
                payload.map((obj) => {
                  return (
                    <p className="label">
                      <span style={{ color: obj.stroke }}>- </span>
                      {`${obj.dataKey}: ${obj.value.toLocaleString()}`}
                    </p>
                  );
                })}

              {/* <p className="desc">Anything you want can be displayed here.</p> */}
            </div>
          );
      }
    }

    return null;
  }
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const onClickHandler = (e) => {
    var isRightMB;
    var isLeftMB;
    e = e || window.event;

    if ('which' in e) {
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = e.which == 3;
      isLeftMB = e.which == 1;
    } else if ('button' in e) {
      // IE, Opera
      isRightMB = e.button == 2;
      isLeftMB = e.button == 0;
    }

    if (isRightMB) {
      setAnchorPosition({ top: e.pageY, left: e.pageX });
    }
  };

  return (
    <div
      style={{
        color: 'grey',
        // height: isExpanded ? vh / 2 : 450
        marginBottom: '10px',
        userSelect: 'none'
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
      // onClick={(e) => onClickHandler(e)}
      onMouseDown={onClickHandler}
    >
      <LineChart
        width={isExpanded ? vw : 870}
        height={isExpanded ? vh / 1.5 : 300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 40 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="rgba(150,150,150,0.3)" />
        <XAxis dataKey="date" tick={<CustomizedAxisTick />} />

        {field?.metrics?.map((metric) => (
          <YAxis
            scale="auto"
            style={{ fontSize: '12px' }}
            yAxisId={columnDictionary[metric.dataKey]}
            orientation={metric.yAxisOrientation}
            //padding={{ left: 50, right: 50 }}
            width={70}
            hide={metric.hideYAxis}
            // label={<CustomLabel metric={metric} />}
          >
            <Label
              style={{
                // textAnchor: 'start',
                fontWeight: 'bold',
                fontSize: '10px',

                fill: 'black'
              }}
              offset={15}
              position="top"
              // angle={270}
              value={columnDictionary[metric.dataKey].slice(0, 12) + '.'}
            />
            {/* 
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '12px',
                // position: 'relative',
                // padding: '10px',
                // margin: '10px',

                fill: 'black'
              }}
              offset={10}
              position="insideLeft"
              angle={270}
              value={metric.dataKey}
            /> */}
          </YAxis>
        ))}
        {/* <YAxis yAxisId="left" />
        <YAxis yAxisId="right" />
        <YAxis yAxisId="right" orientation="right" /> */}
        {/* <Tooltip /> */}
        <Tooltip content={<CustomTooltip />} />
        <Legend
          wrapperStyle={{
            fontSize: '12px',
            paddingLeft:
              field.metrics.filter(
                (metric) =>
                  (metric.yAxisOrientation === 'left' && !metric.hideYAxis) ||
                  (!metric.yAxisOrientation && !metric.hideYAxis)
              ).length *
                70 +
              'px',
            paddingRight:
              field.metrics.filter(
                (metric) =>
                  metric.yAxisOrientation === 'right' && !metric.hideYAxis
              ).length *
                70 +
              'px'
          }}
          verticalAlign="top"
          align="center"
          height={20}
          // width={400}
          iconSize={12}
        />
        {/* <Line yAxisId="left" type="monotone" dataKey="cost" stroke="#00adb5" /> */}
        {field.metrics.map((metric) => {
          let color = listOfColors.shift();
          if (!color) {
            // color = '#00adb5';
            color = '#1784a6';
          }
          return (
            <Line
              yAxisId={columnDictionary[metric.dataKey]}
              type="monotone"
              // dataKey={metric}
              dataKey={columnDictionary[metric.dataKey]}
              stroke={color}
            />
          );
        })}

        {/* {rightYAxisMetrics.map((metric) => {
          let color = listOfColors.shift();
          if (!color) {
            // color = '#00adb5';
            color = '#1784a6';
          }
          return (
            <Line
              yAxisId="right"
              type="monotone"
              // dataKey={metric}
              dataKey={columnDictionary[metric]}
              stroke={color}
            />
          );
        })} */}
        {/* {rightYAxisMetrics.map((metric)=>(<Line yAxisId="right" type="monotone" dataKey={metric} stroke={listOfColors.shift()} />))} */}
        {/* <Line yAxisId="right" type="monotone" dataKey="cost" stroke="#00adb5" /> */}
      </LineChart>
      <LineChartPopover
        anchorPosition={anchorPosition}
        setAnchorPosition={setAnchorPosition}
        field={field}
        fields={fields}
        setFields={setFields}
        componentIndex={componentIndex}
        columnDictionary={columnDictionary}
      />
    </div>
  );
}

export default LineChart1;
