import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';

// import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import TextField from '@mui/material/TextField';

import AddMenu from './add-account/AddMenu';

import { useLazyQuery } from '@apollo/client';
import { managerAccountAccountListQuery } from '../../../../graphql/queries';

import CreateAccountModal from './add-account/CreateAccountModal';

// import AddUserButton from './invite-user/AddUserButton';
import AddAccountButton from './add-account/AddAccountButton2';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AccountContext } from '../../../../context/AccountContext';
import { AuthContext } from '../../../../context/AuthContext';

function AccountList({
  selectedAccountId,
  setSelectedAccountId,
  stylesStoredInReduxManagerAccountSettings
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');

  const variables = {
    managerAccountAccountListInput: {
      managerAccountId: currentManagerAccount?._id
    }
  };

  const [loadAccounts, { called, loading, data, refetch }] = useLazyQuery(
    managerAccountAccountListQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    loadAccounts();
  }, []);

  let listOfAccounts =
    data && data.managerAccountAccountList
      ? data.managerAccountAccountList
      : [];

  useEffect(() => {
    if (!selectedAccountId) {
      const firstAccountInList =
        data?.managerAccountAccountList &&
        data.managerAccountAccountList[0] &&
        data.managerAccountAccountList[0]._id
          ? data.managerAccountAccountList[0]._id
          : '';

      setSelectedAccountId(firstAccountInList);
    }
  }, [listOfAccounts, data]);

  listOfAccounts = listOfAccounts.filter((obj) => {
    if (
      (obj.name &&
        obj.name.toLowerCase().includes(filterSearch.toLowerCase())) ||
      (obj.email &&
        obj.email.toLowerCase().includes(filterSearch.toLowerCase()))
    ) {
      return true;
    }
  });

  const onClickHandler = (e, id) => {
    setSelectedAccountId(id);
  };

  listOfAccounts.sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  let i = 0;
  let userButtons = listOfAccounts.map((obj, index) => {
    i++;
    return (
      <Button
        key={obj._id}
        style={{
          gridRowStart: i,
          gridRowEnd: i,
          height: '60px',
          width: '250px',
          justifyContent: 'flex-start',
          background:
            selectedAccountId === obj._id &&
            stylesStoredInReduxManagerAccountSettings.selectedListButton
        }}
        color="primary"
        onClick={(e) => onClickHandler(e, obj._id)}
      >
        <div
          style={{
            display: 'grid',
            color: stylesStoredInReduxManagerAccountSettings.color
          }}
        >
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.name}
          </div>
          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              fontSize: '10px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj?._id}
          </div>
        </div>
      </Button>
    );
  });

  const onChangeHandler = (e) => {
    setFilterSearch(e.target.value);
  };

  return (
    <div
      style={{
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridTemplateRows: '50px auto'
      }}
    >
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 1,
          height: '100%',
          verticalAlign: 'bottom',
          fontSize: '16px',
          padding: '18px 10px'
        }}
      >
        <AccountBalanceIcon style={{ verticalAlign: 'bottom' }} /> Accounts
      </div>

      <div
        style={{
          overflow: 'hidden',
          display: 'grid',
          gridRowStart: 2,
          gridRowEnd: 2,
          gridTemplateRows: '70px auto',
          height: 'calc(100vh - 143.78px)',
          backgroundColor:
            stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
          backdropFilter: 'blur(10px)',
          margin: '4px',
          borderRadius: '5px',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
      >
        <div
          style={{
            gridRowStart: 1,
            gridRowEnd: 1,
            height: '100%',
            justifyContent: 'flex-start',
            backgroundColor:
              stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
            padding: '5px 30px',
            fontSize: '16px'
          }}
        >
          <div style={{ display: 'grid', gridTemplateColumns: '145px 132px' }}>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 1 }}>
              <TextField
                style={{
                  display: 'grid',
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  width: '90%'
                }}
                id="standard-basic"
                label="Search"
                variant="standard"
                value={filterSearch}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridColumnStart: 2,
                gridColumnEnd: 2,
                paddingTop: '15px',
                width: '32px'
              }}
            >
              <AddAccountButton setOpen={setOpenAddUserModal} />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridRowStart: 2,
            gridRowEnd: 2,
            gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
            padding: '25px 20px',
            width: '290px',
            overflowX: 'hidden',
            overflowY: 'scroll'
          }}
        >
          {userButtons}
        </div>
      </div>
      <CreateAccountModal
        refetchAccountList={refetch}
        open={openAddUserModal}
        setOpen={setOpenAddUserModal}
        currentManagerAccount={currentManagerAccount}
      />
    </div>
  );
}

// export default AccountList

AccountList.propTypes = {
  // currentManagerAccount: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired,
  stylesStoredInReduxManagerAccountSettings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // user: state.auth.user,
  stylesStoredInReduxManagerAccountSettings: state.style.ManagerAccountSettings
});

export default connect(mapStateToProps, {})(AccountList);
