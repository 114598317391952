import { documentTypeToShortNameDict } from './../../components/tableV4/report-builder-2/report-builder-content/dictionary';

import formatDateYYYYMMDD from '../../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../components/tableV4/fields';

import { prepDataObject } from '../../components/tableV4/report-builder-2/report-builder-content/widgets/prepDataObject';

export const lineChart = (
  widget,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData
) => {
  const key = widget.metrics?.[0] || 'cost';
  const dataSourceData = listOfFilteredDataSourceRows.filter(
    (row) =>
      [
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group',
        'manualDataEntry'
      ].includes(row.type) &&
      (row.endDate >= formatDateYYYYMMDD(startDateForLastDaysData) ||
        !row.endDate)
  );

  let widgetGroupByKey = '';

  switch (widget.dimensions[0]) {
    case 'documentType':
      widgetGroupByKey = 'documentType';
      break;
    case 'name':
      widgetGroupByKey = 'name';
      break;
  }

  let tempGroupBy = {};
  // console.log('widget.dimensions: ', widget.dimensions);

  // console.log('widget.dimensions.length: ', widget.dimensions.length);
  if (widget.dimensions.length) {
    tempGroupBy = Object.groupBy(
      dataSourceData,
      (row) => row[widgetGroupByKey]
    );
  } else {
    tempGroupBy = { default: dataSourceData };
  }

  // console.log('PieChartWidget>testGroupBy: ', testGroupBy);
  // console.log('tempGroupBy: ', tempGroupBy);

  const dimensionKeys = Object.keys(tempGroupBy);

  // console.log('dimensionKeys: ', dimensionKeys);
  // console.log('tempGroupBy: ', tempGroupBy);
  // console.log('widget.metrics111: ', widget.metrics);

  const allSeries = [];
  let intervalRange_ = [];

  // console.log('debug9567>row155>intervalRange_: ', intervalRange_);
  // console.log('debug9567>row155>widget.interval: ', widget.interval);
  switch (widget.interval) {
    case 'month':
      const formattedDates = aggregatedDateRange.map((date) =>
        formatDateYYYYMMDD(date)
      );
      intervalRange_ = Object.groupBy(formattedDates, (date) =>
        date.slice(0, 7)
      );
      intervalRange_ = Object.keys(intervalRange_);

      if (new Date(formattedDates[0]).getDate() !== 1) {
        intervalRange_.shift();
      }
      // console.log('debug9567>row164>intervalRange_: ', intervalRange_);
      break;
    default:
      intervalRange_ = aggregatedDateRange.map((date) =>
        formatDateYYYYMMDD(date)
      );
  }

  // console.log('debug9567>row167>intervalRange_: ', intervalRange_);

  // setIntervalRange(intervalRange_);
  // aggregatedDateRange;

  for (let dimensionKey of dimensionKeys) {
    // data source
    // console.log('debug2233>row103>dimensionKey: ', dimensionKey);
    let newSeriesData = [];

    let allPropagationData = [];
    for (let row of tempGroupBy[dimensionKey]) {
      allPropagationData = [
        ...allPropagationData,
        ...(row?.total_list_of_propagation_data || [])
      ];
    }

    for (let row of allPropagationData) {
      let formattedDate = formatDateYYYYMMDD(row.date_start);
      row.date = formattedDate;
      row.month = formattedDate.slice(0, 7);
    }
    const dimensionsDate = Object.groupBy(
      allPropagationData,
      (obj) => obj[widget.interval]
    );

    // switch (widget.interval) {
    //   case 'month':
    //     intervalRange = Object.keys(dimensionsDate);
    //   default:
    //     intervalRange = aggregatedDateRange;
    // }
    // console.log('intervalRange_: ', intervalRange_);
    for (let intervalUnit of intervalRange_) {
      let sumObject = {};

      for (let dataObj of dimensionsDate?.[intervalUnit] || []) {
        for (let metric of [...sumEvalFields, ...plannedEvalFields]) {
          // console.log('dataObj[metric]: ', dataObj[metric]);
          sumObject[metric] = (sumObject[metric] || 0) + (dataObj[metric] || 0);
        }
      }

      let newValue = null;

      sumObject = prepDataObject(sumObject);
      // sumObject.date = date;
      // sumObject.month = new Date(date).getMonth() + 1;
      sumObject.intervalUnit = intervalUnit;

      newValue = sumObject[widget.metrics[0]];
      newSeriesData.push(sumObject);
    }
    // console.log('dimensionsDate: ', dimensionsDate);
    // console.log('newSeriesData11: ', newSeriesData);

    // switch (true) {
    //   case widget.interval === 'month':
    //     const intervalGroupBy = Object.groupBy(
    //       newSeriesData,
    //       (obj) => obj.month
    //     );
    //     console.log('intervalGroupBy: ', intervalGroupBy);
    //     break;
    // }
    allSeries.push({
      label:
        documentTypeToShortNameDict[dimensionKey] ||
        (dimensionKey !== 'default' ? dimensionKey : undefined),
      data: (newSeriesData || []).map((obj) => obj[key]), //Math.round(obj?.[key])),
      showMark: false
    });

    // for (let row of tempGroupBy[dimensionKey]) {
    //   const dimensionsDate = Object.groupBy(
    //     row?.total_list_of_propagation_data || [],
    //     (obj) => formatDateYYYYMMDD(obj.date_start)
    //   );

    //   for (let date of aggregatedDateRange) {
    //     console.log('date111: ', date);
    //     let sumObject = {};
    //     console.log('dimensionsDate[date]: ', dimensionsDate[date]);
    //     for (let dataObj of dimensionsDate[date]) {
    //       for (let metric of widget.metrics) {
    //         console.log('dataObj[metric]: ', dataObj[metric]);
    //         sumObject[metric] += dataObj[metric] || 0;
    //       }
    //     }
    //     newSeriesData.push(sumObject);
    //   }
    //   console.log('dimensionsDate: ', dimensionsDate);
    //   console.log(' newSeriesData: ', newSeriesData);
    //   allSeries.push({ data: newSeriesData });
    // }
  }

  // console.log('allSeries: ', allSeries);

  // const seriesWithData = allSeries.filter((serie) =>
  //   serie.data.some((val) => Boolean(val))
  // );
  // console.log('seriesWithData: ', seriesWithData);
  // setSeries(allSeries);
  //   let dimensionsValue = 0;

  //   for (let row of testGroupBy[dimensionKey]) {
  //     dimensionsValue += row[key];
  //   }
  //   let seriesData = {
  //     // label: documentTypeToNameDict[dimensionKey],
  //     value: dimensionsValue
  //   };

  //   switch (widgetGroupByKey) {
  //     case 'documentType':
  //       seriesData.label = documentTypeToNameDict[dimensionKey];
  //       break;

  //     case 'name':
  //       seriesData.label =
  //         dimensionKey.slice(0, 20) + (dimensionKey.length > 20 ? '...' : '');
  //       break;
  //   }
  //   newSeriesData.push(seriesData);
  // }

  // let totalValue = 0;
  // for (let row of newSeriesData) {
  //   totalValue += row?.value || null;
  // }

  // for (let row of newSeriesData) {
  //   row.share = Math.round((row?.value / totalValue) * 100);
  //   row.value = Math.round(row?.value);
  // }
  // console.log('totalValue: ', totalValue);
  // setSeriesData(newSeriesData);

  let _interval = [];
  switch (widget.interval) {
    case 'month':
      _interval = intervalRange_;
      break;
    default:
      // let dates_ = widget.dates?.map((date) => new Date(date));

      // console.log('row313>intervalRange_: ', intervalRange_);
      _interval = intervalRange_;
  }

  // console.log('_interval: ', _interval);

  // console.log('debug9933>allSeries: ', allSeries);
  let copyOfWidget = { ...widget };
  copyOfWidget = {
    ...copyOfWidget,
    data: [...allSeries],
    dates: [..._interval]
  };
  return copyOfWidget;
};
