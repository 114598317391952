import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import DragDropFile from './DragAndDropInput';

import DataEditor from './DataEditor';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog() {
  const [open, setOpen] = React.useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const [excelData, setExcelData] = useState({});

  const [fileUploaded, toggleFileUploaded] = useState(false);
  console.log('excelData: ', excelData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const headerText = 'Upload excel';

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <UploadFileIcon />{' '}
        <span style={{ marginLeft: '5px' }}>Upload Excel</span>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={isExpanded}
        // paperFullWidth={true}
        // paper={{ height: '90hw', width: '1000px' }}
        // maxWidth="lg"
        maxWidth="80%"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              // display: 'grid',
              // gridTemplateColumns: 'auto 40px 40px 30px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1
              }}
            >
              <span style={{ paddingLeft: '7px' }}>
                <UploadFileIcon
                  style={{
                    verticalAlign: 'text-bottom',
                    paddingRight: '5px'
                  }}
                />{' '}
                {headerText}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '65px'
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 2,
                  gridColumnEnd: 2,
                  alignItems: 'end'
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    right: '0px',
                    textAlign: 'right'
                    // paddingRight: '25px'
                  }}
                >
                  <span
                    style={{ width: '50px' }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? (
                      <FullscreenExitIcon className="hover-light-grey" />
                    ) : (
                      <FullscreenIcon className="hover-light-grey" />
                    )}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 3,
                  gridColumnEnd: 3,
                  alignItems: 'end'
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    right: '0px',
                    textAlign: 'right'
                    // paddingRight: '25px'
                  }}
                >
                  <span style={{ width: '50px' }} onClick={handleClose}>
                    <CloseIcon className="hover-light-grey" />
                  </span>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1,
                gridRowStart: 2,
                gridRowEnd: 2
              }}
            >
              <span style={{ paddingLeft: '50px', fontSize: '12px' }}>
                {item?.path}
              </span>
            </div> */}
          </div>
        </DialogTitle>
        <DialogContent>
          {fileUploaded ? (
            <DataEditor excelData={excelData} setExcelData={setExcelData} />
          ) : (
            <DragDropFile
              excelData={excelData}
              setExcelData={setExcelData}
              toggleFileUploaded={toggleFileUploaded}
            />
          )}

          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Upload</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
