import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import PreparedMenuItem from './PreparedMenuItem';

import { AccountContext } from '../../../context/AccountContext';
import { DialogContext } from '../../../context/DialogContext';
import { ColumnContext } from '../../../context/ColumnContext';

import { columnArray, defaultColumnSets } from './columns';

import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import { Tooltip } from '@mui/material';

export default function ColumnMenu({
  //   currentManagerAccountId,
  left,
  setLeft,
  right,
  setRight,
  setOpenSimpleModal,
  user
  // columnData
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  const { columnDialogOpen, setColumnDialogOpen } = useContext(DialogContext);
  const { columns, setColumns, columnData, refetchColumns } =
    useContext(ColumnContext);

  const currentManagerAccountId = currentManagerAccount?._id;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [listOfCustomColumns, setListOfCustomColumns] = useState([]);
  const [
    listOfManagerAccountColumnSetsMenuItems,
    setListOfManagerAccountColumnSetsMenuItems
  ] = useState([]);
  const [listOfUserColumnSetsMenuItems, setListOfUserColumnSetsMenuItems] =
    useState([]);

  const [listOfColumns, setListOfColumns] = useState(defaultColumnSets);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    const allColumns = columnArray;

    const right = [...listOfColumns, ...listOfCustomColumns].filter(
      (column) => column._id === event.target.id
    );
    if (right[0]?.columns) {
      setColumns(right[0].columns);
    }

    setAnchorEl(null);
  };

  useEffect(() => {
    if (columnData && columnData.customColumnSets) {
      setListOfCustomColumns(columnData.customColumnSets);
    }
  }, [columnData]);

  useEffect(() => {
    const listOfManagerAccountColumnSets = listOfCustomColumns.filter(
      (obj) => obj.savedTo === 'managerAccount'
    );

    const listOfManagerAccountColumnSetsMenuItems_ =
      listOfManagerAccountColumnSets.map((obj) => {
        return (
          <PreparedMenuItem
            key={obj._id}
            id={obj._id}
            name={obj.name}
            currentManagerAccountId={currentManagerAccountId}
            savedTo={obj.savedTo}
            handleClose={handleClose}
          />
        );
      });

    setListOfManagerAccountColumnSetsMenuItems(
      listOfManagerAccountColumnSetsMenuItems_
    );

    const listOfUserColumnSets = listOfCustomColumns.filter(
      (obj) => obj.savedTo === 'user'
    );

    const listOfUserColumnSetsMenuItems_ = listOfUserColumnSets.map((obj) => {
      return (
        <PreparedMenuItem
          key={obj._id}
          id={obj._id}
          name={obj.name}
          currentManagerAccountId={currentManagerAccountId}
          savedTo={obj.savedTo}
          handleClose={handleClose}
        />
      );
    });

    setListOfUserColumnSetsMenuItems(listOfUserColumnSetsMenuItems_);
  }, [listOfCustomColumns, columnData]);

  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Tooltip title="Column menu">
        <Button
          variant="standard"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            minHeight: 0,
            minWidth: 0
          }}
          onClick={handleClick}
        >
          <ViewColumnIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Typography
          // className={classes.dividerFullWidth}
          style={{
            marginTop: '10px',
            paddingLeft: '15px',
            textAlign: 'left',
            fontSize: '10px'
          }}
          color="textSecondary"
          display="block"
          variant="caption"
        >
          Generic
        </Typography>
        {listOfColumns.map((obj) => (
          <MenuItem key={obj._id} id={obj._id} onClick={handleClose}>
            {obj.name}
          </MenuItem>
        ))}

        {listOfManagerAccountColumnSetsMenuItems.length > 0 && (
          <div>
            <Divider />
            <Typography
              // className={classes.dividerFullWidth}
              style={{
                marginTop: '10px',
                paddingLeft: '15px',
                textAlign: 'left',
                fontSize: '10px'
              }}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              Manager Account
            </Typography>
          </div>
        )}
        {listOfManagerAccountColumnSetsMenuItems}

        {listOfUserColumnSetsMenuItems.length > 0 && (
          <div>
            <Divider />
            <Typography
              // className={classes.dividerFullWidth}
              style={{
                marginTop: '10px',
                paddingLeft: '15px',
                textAlign: 'left',
                fontSize: '10px'
              }}
              color="textSecondary"
              display="block"
              variant="caption"
            >
              Personal
            </Typography>
          </div>
        )}
        {listOfUserColumnSetsMenuItems}

        <Divider />
        <MenuItem
          id="column_setup_custom_column"
          onClick={() => {
            // setOpenSimpleModal(true);
            setColumnDialogOpen(true);

            setAnchorEl(null);
            // handleClose
          }}
        >
          Customize Columns
        </MenuItem>
      </Menu>
    </div>
  );
}
