import React, { useContext } from 'react';

import TimeboxerButton from '../timeboxer/TimeboxerButton';
import ReportBuilderButton from '../report-builder-2/ReportBuilderButton';
import { AuthContext } from '../../../context/AuthContext';

import { AccountContext } from '../../../context/AccountContext';

function BottomRightCornerMenu() {
  const { user } = useContext(AuthContext);

  const { currentManagerAccountId } = useContext(AccountContext);
  return (
    <div
      style={{
        // background: 'red',
        width: 'fit-content',
        height: '60px',
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        zIndex: '16000'
      }}
    >
      {(['system_admin', 'debug'].includes(user?.role) ||
        [
          '6167f2a56a44185b71fd0bcc',
          '66e7333fa976fd8950ae68dc',
          '640b425a1b1064af10bc2918',
          '640b3de89db4bd2b100bdf58',
          '66e1a3501188a63754cd3589',
          '66e976a8778badeab921ae8d',
          '66ea75bf1a4b7ff95b496d78',
          '66ead8ae25ee8b2d1b7bd074',
          '66ebd3cdc56a6642023f8849',
          '66d05d25ad446b4a554a888d'
        ].includes(user?._id) ||
        ['614329354f4e19814d47eeaa'].includes(currentManagerAccountId)) && (
        <ReportBuilderButton />
      )}
      <TimeboxerButton />
    </div>
  );
}

export default BottomRightCornerMenu;
