import React, { useContext, useState, useEffect, useRef } from 'react';
import { DialogContext } from '../../../context/DialogContext';

import { AuthContext } from '../../../context/AuthContext';
import { AccountContext } from '../../../context/AccountContext';
import { MutationContext } from '../../../context/MutationContext';
import { InterfaceContext } from '../../../context/InterfaceContext';

import { DataContext } from '../../../context/DataContext';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Button } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';

import { useTheme } from '@emotion/react';

import formatDateYYYYMMDD from '../roitable_functions/formatDateYYYYMMDD';

import TimeboxerMenu from './menu/TimeboxerMenu';

import AssessmentIcon from '@mui/icons-material/Assessment';

import QueueIcon from '@mui/icons-material/Queue';

import ReportBuilderContextMenu from './context-menu/ReportBuilderContextMenu';

import ReportBuilderPageList from './report-builder-list/ReportBuilderPageList';

import ExportMenu from './export/ExportMenu';

import ZoomPopper from './ZoomPopper';

import { ReportBuilderContext } from '../../../context/ReportBuilderContext';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import { timeboxesQuery } from '../../../graphql/queries';

import ReportBuilderContent from './report-builder-content/ReportBuilderContent';

import SaveTemplatePopper from './SaveTemplatePopper';

import LayoutMenu from './LayoutMenu';

import CollectionsIcon from '@mui/icons-material/Collections';
import ColorPaletteMenu from './color-palette-menu/ColorPaletteMenu';

function ReportBuilder() {
  const pollTime = 5 * 60 * 1000;
  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] =
    React.useState(null);

  const [
    widgetIdThatTriggeredContextMenu,
    setWidgetIdThatTriggeredContextMenu
  ] = useState(null);

  const ref = useRef();

  const [isDragging, setIsDragging] = useState(false);
  const { zoom, setZoom } = useContext(ReportBuilderContext);
  // const [zoom, setZoom] = useState(1);

  // const [selectedUser, setSelectedUser] = useState(null);

  const timeboxerRef = useRef();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const {
    isReportBuilderOpen,
    isReportBuilderPageListOpen,
    setIsReportBuilderPageListOpen,

    reportBuilderDialogOpen,
    setReportBuilderDialogOpen,
    setTimeboxViewDialogType
  } = useContext(DialogContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    selectedDateStartTimebox,
    setSelectedDateStartTimebox,
    selectedDateStopTimebox,
    setSelectedDateStopTimebox,
    // selectedUserTimeboxer: selectedUser,
    // setSelectedUserTimeboxer: setSelectedUser,
    // timeboxRange: dates,
    windowSize,
    setWindowSize
  } = useContext(InterfaceContext);
  // console.log('dates: ', dates);

  const [isTimeboxesMoved, setIsTimeboxesMoved] = useState(false);

  const [displayTimebox, setDisplayTimebox] = useState({});

  const {
    addTimeboxes,
    updateTimeboxes,
    refetchTimeboxesCounter,
    setRefetchTimeboxesCounter
  } = useContext(MutationContext);

  const { isLoading, setIsLoading, timeboxes, setTimeboxes } =
    useContext(DataContext);

  // const [timeboxes, setTimeboxes] = useState([]);

  // const [refetchTimeboxesCounter2, setRefetchTimeboxesCounter2] = useState(0);

  const [mouseDownWidgetIds, setMouseDownWidgetIds] = useState([]);

  useEffect(() => {
    // console.log('windowSize: ', windowSize);

    switch (true) {
      case windowSize[0] < 1200:
        setZoom(0.4);
        break;
      case windowSize[0] < 1400:
        setZoom(0.4);
        break;
      case windowSize[0] < 1600:
        setZoom(0.6);
        break;

      case windowSize[0] < 2400:
        setZoom(0.8);
        break;
      default:
        setZoom(1);
    }
    switch (true) {
      case 800 < reportBuilderWidth && windowSize[0] < 1200:
        setReportBuilderWidth(800);
        // console.log('debug54>case 800 < windowSize[0] && windowSize[0] < 1200');

        break;
      case 1000 < reportBuilderWidth && windowSize[0] < 1400:
        setReportBuilderWidth(1000);
        // console.log(
        //   'debug54>case 1000 < windowSize[0] && windowSize[0] < 1400: ',
        //   windowSize[0]
        // );
        break;
      case 1200 < reportBuilderWidth && windowSize[0] < 1600:
        setReportBuilderWidth(1200);

        break;

      case 2000 < reportBuilderWidth && windowSize[0] < 2400:
        setReportBuilderWidth(2000);

        break;
      // default:
      //   setZoom(1);
    }
  }, [windowSize]);

  // useEffect(() => {
  //   if (!selectedUser) {
  //     setSelectedUser(user);
  //   }
  // }, [user]);

  // ################## data #######################
  // console.log('selectedDateStartTimebox: ', selectedDateStartTimebox);

  // const variables = {
  //   input: {
  //     managerAccountId: currentManagerAccount._id,
  //     userIds: [selectedUser?._id]
  //     // selectedDateStartTimebox: String(startDateTime.getTime()),
  //     // selectedDateStopTimebox: String(endDateTime.getTime())
  //   }
  // };

  // const [
  //   getData,
  //   { loading, error, data, refetch, client, startPolling, stopPolling }
  // ] = useLazyQuery(timeboxesQuery, {
  //   variables,

  //   fetchPolicy: 'no-cache',

  //   onCompleted: () => {
  //     console.log('RoiTable>Query completed.');
  //   },
  //   onError: (error) => {
  //     console.log('error: ', error);
  //   }
  // });

  // useEffect(() => {
  //   // console.log('data: ', data);
  // }, [data]);

  // useEffect(() => {
  //   if (refetchTimeboxesCounter) {
  //     refetch();
  //   }
  // }, [refetchTimeboxesCounter]);

  // useEffect(() => {
  //   if (currentManagerAccount._id && isReportBuilderOpen && selectedUser) {
  //     getData();
  //     startPolling(pollTime);
  //   }
  // }, [currentManagerAccount._id, selectedUser, isReportBuilderOpen]);

  // useEffect(() => {
  //   // console.log('debug49>test>data: ', data);
  //   if (data?.timeboxes && selectedUser) {
  //     // console.log('debug49>test1');
  //     const timeboxes_ = data?.timeboxes.map((timebox) => ({
  //       ...timebox,
  //       startDateTime: new Date(+timebox.startDateTime),
  //       endDateTime: new Date(+timebox.endDateTime),
  //       deadline: timebox.deadline && new Date(+timebox.deadline),
  //       duration: (timebox?.endDateTime - timebox?.startDateTime) / 60000
  //     }));
  //     setTimeboxes(timeboxes_);
  //     // setBackupOfTimeboxes_(
  //     //   timeboxes_.map((timebox) => ({
  //     //     ...timebox
  //     //   }))
  //     // );
  //     // console.log('Timeboxer>timeboxes>data: ', data);
  //   }
  // }, [data, refetchTimeboxesCounter2, selectedUser]);
  // console.log('timeboxes: ', timeboxes);
  // console.log('Timeboxer>timeboxes: ', timeboxes);
  // ###############################################

  let timeSteps = 5;
  let minimumDuration = 5;

  const today = new Date();

  // const [dates, setDates] = useState([]);

  // useEffect(() => {
  //   const start = moment(selectedDateStartTimebox, 'YYYY-MM-DD');
  //   const end = moment(selectedDateStopTimebox, 'YYYY-MM-DD');
  //   const range = moment.range(start, end);
  //   setDates(range);
  // }, [selectedDateStartTimebox, selectedDateStopTimebox]);

  useEffect(() => {
    if (!isReportBuilderOpen || isLoading) {
      return;
    }
    // console.log('ref.current.scrollTop: ', ref.current.scrollTop);
    const now = new Date();
    ref.current.scrollTop =
      (Number(now.getHours()) * 60 + Number(now.getMinutes())) * zoom - 300;
  }, [isReportBuilderOpen, isLoading]);

  // const dates = [
  //   new Date('2024-02-25'),
  //   new Date('2024-02-26'),
  //   new Date('2024-02-27'),
  //   new Date('2024-02-28'),
  //   new Date('2024-02-29')
  // ];

  // const numDays = dates?.length || 0;

  const [reportBuilderWidth, setReportBuilderWidth] = useState(312);

  // const [reportBuilderPageListRight, setReportBuilderPageListRight] =
  //   useState(0);
  useEffect(() => {
    // let width =
    //   12 + numDays * 300 > (window?.innerWidth || 0) - 900
    //     ? (window?.innerWidth || 0) - 900
    //     : 12 + numDays * 300;
    let width = 1200;

    if (width > 1509) {
      width = 1509;
    }
    // setReportBuilderPageListRight(width);
    setReportBuilderWidth(width);
  }, []);
  // console.log('reportBuilderPageListRight: ', reportBuilderPageListRight);

  var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

  document.addEventListener('mousemove', function (e) {
    //adjust warning column
    // document.querySelector('#timeboxlist') &&
    //   setReportBuilderPageListRight(document.querySelector('#timeboxlist')?.offsetWidth);

    //resize column
    // console.log('Timeboxer>curCol: ', curCol);
    if (curCol) {
      var diffX = pageX - e.pageX;

      if (curColWidth + diffX < 2400) {
        curCol.style.width = curColWidth + diffX + 'px';
        setReportBuilderWidth(curColWidth + diffX);
        // setReportBuilderPageListRight(curColWidth + diffX);
      }
    }
  });

  document.addEventListener('mouseup', function (e) {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  });

  const colHandler = (e) => {
    curCol = e.target.parentElement;
    nxtCol = curCol.nextElementSibling;
    pageX = e.pageX;
    curColWidth = curCol?.offsetWidth;
    // if (!curCol.style.minWidth) {
    //   curCol.style.minWidth = curColWidth + 2 + 'px';
    // }

    if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
  };
  const colHandlerOnRelease = (e) => {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {!isLoading && (
        <>
          <ReportBuilderPageList
            // selectedUser={selectedUser}
            // numDays={numDays}
            right={reportBuilderWidth}
          />
          <div
            // onMouseDown={(e) => colHandler(e)}

            id="timeboxer"
            className={
              isReportBuilderOpen
                ? 'isReportBuilderOpenTrue'
                : 'isReportBuilderOpenFalse'
            }
            onContextMenu={(e) => e.preventDefault()}
            ref={timeboxerRef}
            style={{
              // display: 'flex',
              flexDirection: 'column',
              // visibility: isReportBuilderOpen ? 'visible' : 'hidden',
              border:
                theme?.palette?.mode === 'dark' &&
                '1px solid rgba(255,255,255,0.05)',
              width: reportBuilderWidth ? reportBuilderWidth + 'px' : 'auto',
              // width: 9 + numDays * 300 + 'px',
              // maxWidth: (window?.innerWidth || 0) - 600 + 'px',
              height: '100%',
              // background:
              //   theme?.palette?.mode === 'dark'
              //     ? 'rgb(32 32 41 / 60%)' // 'rgba(255,255,255,0.1)'
              //     : 'rgba(255,255,255,0.3)',
              backdropFilter: 'blur(10px)',
              // background: 'red',
              position: 'absolute',
              // right: '0px',
              // top: '-700px',
              top: '0px',
              zIndex: '15000',
              borderRadius: '5px',
              // overflowX: 'scroll',
              // padding: '10px',
              boxShadow:
                theme?.palette?.mode === 'dark'
                  ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' // '0px 3px 1px -2px rgb(127 127 255 / 20%), 0px 2px 2px 0px rgb(127 127 255 / 14%), 0px 1px 5px 0px rgb(127 127 255 / 12%)' //'0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
                  : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'

              // boxShadow:
              //   '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
            }}
            // onMouseMove={updateMousePosition}
            // //   onMouseDown={onMouseDownHandler}
            // onMouseDown={(e) => {
            //   // e.preventDefault();
            //   e.stopPropagation();
            //   onMouseDownHandler(e, null, 'create');
            // }}
            // onMouseUp={(e) => {
            //   e.stopPropagation();
            //   // e.preventDefault();
            //   onMouseUpHandler(e, 'timeboxer', null);
            // }}
          >
            <div
              onMouseDown={colHandler}
              onMouseUp={(e) => colHandlerOnRelease(e)}
              className="hover-col-resize"
              style={{
                width: '5px',
                height: '100%',
                // background: 'red',
                position: 'absolute',
                left: '0px',
                cursor: 'col-resize',
                zIndex: '22000'
              }}
            ></div>
            <div
              style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: '30px',
                background:
                  theme?.palette?.mode === 'dark'
                    ? 'rgb(28 28 41 / 40%)' // 'rgba(255,255,255,0.1)'
                    : 'rgba(255,255,255,0.5)',
                zIndex: '20000'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'absolute'
                }}
              >
                <Button
                  style={{
                    minWidth: '25px',
                    width: '25px',
                    minHeight: '25px',
                    height: '25px'
                  }}
                >
                  <QueueIcon
                    style={{ fontSize: '16px' }}
                    onClick={() =>
                      setIsReportBuilderPageListOpen(
                        !isReportBuilderPageListOpen
                      )
                    }
                  />
                </Button>

                <>
                  <Button
                    style={{
                      minWidth: '25px',
                      width: '25px',
                      minHeight: '25px',
                      height: '25px'
                    }}
                  >
                    <CollectionsIcon
                      style={{ fontSize: '16px' }}
                      onClick={() => {
                        // setTimeboxViewDialogType('USERS');
                        setReportBuilderDialogOpen(true);
                      }}
                    />
                  </Button>
                </>

                <SaveTemplatePopper />
                {['system_admin'].includes(user.role) && <LayoutMenu />}
              </div>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                  // marginTop: '5px'
                }}
              >
                <ColorPaletteMenu />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  position: 'absolute',
                  right: '0px',
                  columnGap: '5px',
                  paddingRight: '10px'
                }}
              >
                <ZoomPopper zoom={zoom} setZoom={setZoom} />
                <ExportMenu zoom={zoom} setZoom={setZoom} />
                {['system_admin'].includes(user.role) && <TimeboxerMenu />}

                {/* <Button
                  style={{
                    minWidth: '20px',
                    width: '20px',
                    minHeight: '20px',
                    height: '20px',
                    visibility: 'hidden'
                  }}
                ></Button> */}
              </div>
            </div>

            <div
              ref={ref}
              style={{
                // overflowX: 'scroll',

                // height: '100%',
                height: 'calc(100% - 35px)',
                // border: '1px solid red',
                background:
                  theme?.palette?.mode === 'dark'
                    ? 'rgb(28 28 41 / 40%)' // 'rgba(255,255,255,0.1)'
                    : 'rgba(255,255,255,0.3)'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: 1 * 300 + 'px',
                  position: 'sticky',
                  top: '0px',
                  zIndex: '20000'
                }}
              >
                {/* {dates?.map((date) => (
                  <TimeboxHead date={date} />
                ))} */}
              </div>
              {/* <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  // top: '50px',
                  position: 'relative'
                  // overflow: 'scroll'
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    borderTop:
                      theme?.palette?.mode === 'dark'
                        ? '5px dotted rgba(255,255,255,0.2)'
                        : '5px dotted rgba(48,48,48,0.3)',
                    // borderStyle: 'dotted',
                    height: '1px',
                    // width: '100%',
                    width: (dates?.length || 0) * 300 + 'px',
                    top:
                      (new Date().getHours() * 60 + new Date().getMinutes()) *
                        zoom +
                      'px'
                  }}
                ></div>
                {dates?.map((date) => (
                  <TimeboxerDay
                    scrollElementRef={ref}
                    date={date}
                    timeboxIdThatTriggeredContextMenu={
                      timeboxIdThatTriggeredContextMenu
                    }
                    setTimeboxIdThatTriggeredContextMenu={
                      setTimeboxIdThatTriggeredContextMenu
                    }
                  />
                ))}
              </div> */}
              {/* <TimeboxList selectedUser={selectedUser} numDays={numDays} /> */}

              {isReportBuilderOpen && (
                <>
                  <ReportBuilderContent zoom={zoom} setZoom={setZoom} />
                  <ReportBuilderContextMenu
                    mouseDownWidgetIds={mouseDownWidgetIds}
                    contextMenuAnchorPosition={contextMenuAnchorPosition}
                    setContextMenuAnchorPosition={setContextMenuAnchorPosition}
                    widgetIdThatTriggeredContextMenu={
                      widgetIdThatTriggeredContextMenu
                    }
                    setWidgetIdThatTriggeredContextMenu={
                      setWidgetIdThatTriggeredContextMenu
                    }
                    timeboxes={timeboxes}
                    // selectedUser={selectedUser}
                  />
                </>
              )}
              {/* <TimeboxerViewDialog /> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ReportBuilder;
