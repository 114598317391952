import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ value, setValue }) {
  const handleChange = (newValue) => {
    console.log('newValue: ', newValue);
    setValue(newValue.map((obj) => obj.value));
  };
  return (
    <Autocomplete
      size="small"
      value={options.filter((obj) => (value || []).includes(obj.value))}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select allowed budget types"
          // placeholder="Favorites"
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const options = [
  { title: 'Daily Budget', value: 'DAILY' },
  { title: 'Lifetime Budget', value: 'LIFETIME' }
];
