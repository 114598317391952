import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import EditIcon from '@mui/icons-material/Edit';

import PhoneNumberInput from './PhoneNumberInput';
import VerifyPhoneNumberInput from './VerifyPhoneNumber';

import Fab from '@mui/material/Fab';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({
  refetchUserInfo,
  userInfoAndAccounts
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setView('SEND_VERIFIATION_CODE');
    setOpen(false);
  };

  const [view, setView] = useState('SEND_VERIFIATION_CODE');

  let DisplayComponent = <div></div>;
  let dialogTitle = '';
  switch (true) {
    case view === 'SEND_VERIFIATION_CODE':
      DisplayComponent = PhoneNumberInput;
      dialogTitle = 'Enter the new phone number';
      break;

    case view === 'VERIFY_PHONE_NUMBER':
      DisplayComponent = VerifyPhoneNumberInput;
      dialogTitle = 'Enter verification code';
      break;
  }

  return (
    <React.Fragment>
      {/* <Button
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
        style={{
          width: '32px',
          minWidth: '32px',
          height: '32px',
          minHeight: '32px'
        }}
      >
        <EditIcon />
      </Button> */}
      <Fab
        onClick={handleClickOpen}
        aria-label="edit"
        style={{
          marginLeft: '20px',
          color: 'white',

          // margin: theme.spacing(1),
          borderRadius: '5px',
          height: '20px',
          width: '37px',
          backgroundColor: userInfoAndAccounts?.phoneNumber
            ? 'rgba(150, 150, 150, 1)'
            : 'rgb(0, 181, 8)'
        }}
      >
        <EditIcon style={{ color: 'white' }} />
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          {/* <PhoneNumberInput /> */}
          <DisplayComponent
            view={view}
            setView={setView}
            handleClose={handleClose}
            refetchUserInfo={refetchUserInfo}
            userInfoAndAccounts={userInfoAndAccounts}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
