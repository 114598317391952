import React, { Fragment, useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

// icons

import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import GraphContent from './GraphContent';

import { DialogContext } from '../../../../context/DialogContext';

import { useTheme } from '@emotion/react';

function PaperComponent(props) {
  const isExpanded = false;
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const GraphDialog = ({
  open,
  setOpen,
  range2,
  columns,
  rawArrayOfData,
  selectedDateStart,
  selectedDateStop,
  item: summaryItem
}) => {
  const theme = useTheme();
  const { itemId, setItemId, itemKey, setItemKey } = useContext(DialogContext);

  const item =
    summaryItem || rawArrayOfData.filter((obj) => obj._id === itemId)[0] || {};

  const [isExpanded, setIsExpanded] = useState(false);
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setItemKey(null);
    setItemId(null);
    setOpen(false);
  };

  //   // ##################################################################################
  //   // ##################################################################################
  //   // ##################################################################################

  //   const [filteredArrayOfData, setFilteredArrayOfData] = useState([]);

  //   useEffect(() => {
  //     const newArray = [];

  //     for (let dataRow of arrayOfData) {
  //       for (let rawDataRow of rawArrayOfData) {
  //         if (
  //           rawDataRow['level_' + dataRow.level + '_container_id'] ===
  //             dataRow._id &&
  //           !newArray.map((obj) => obj._id).includes(rawDataRow._id)
  //         ) {
  //           newArray.push(rawDataRow);
  //         }
  //       }
  //     }

  //     setFilteredArrayOfData(newArray);

  //   }, [arrayOfData, rawArrayOfData]);

  //   // ##################################################################################
  //   // ##################################################################################
  //   // ##################################################################################

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={isExpanded}
        PaperProps={{
          style: {
            backgroundColor:
              theme?.palette?.mode === 'dark' && 'rgba(15,15,26,1)'
          }
        }}
        // paperFullWidth={true}
        // paper={{ height: '90hw', width: '1000px' }}
        // maxWidth="lg"
        maxWidth="xl"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              // display: 'grid',
              // gridTemplateColumns: 'auto 40px 40px 30px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{ display: 'grid', gridColumnStart: 1, gridColumnEnd: 1 }}
            >
              <span style={{ paddingLeft: '7px' }}>
                <ViewWeekIcon
                  style={{ verticalAlign: 'text-bottom', paddingRight: '5px' }}
                />{' '}
                {item?.name ? item?.name : 'Kanban'}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '65px'
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 2,
                  gridColumnEnd: 2,
                  alignItems: 'end'
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    right: '0px',
                    textAlign: 'right'
                    // paddingRight: '25px'
                  }}
                >
                  <span
                    style={{ width: '50px' }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? (
                      <FullscreenExitIcon className="hover-light-grey" />
                    ) : (
                      <FullscreenIcon className="hover-light-grey" />
                    )}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 3,
                  gridColumnEnd: 3,
                  alignItems: 'end'
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    right: '0px',
                    textAlign: 'right'
                    // paddingRight: '25px'
                  }}
                >
                  <span style={{ width: '50px' }} onClick={handleClose}>
                    <CloseIcon className="hover-light-grey" />
                  </span>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1,
                gridRowStart: 2,
                gridRowEnd: 2
              }}
            >
              <span style={{ paddingLeft: '50px', fontSize: '12px' }}>
                {item?.path}
              </span>
            </div> */}
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
          <GraphContent
            item={item}
            isExpanded={isExpanded}
            range2={range2}
            // filteredArrayOfData={filteredArrayOfData}
            rawArrayOfData={rawArrayOfData}
            selectedDateStart={selectedDateStart}
            selectedDateStop={selectedDateStop}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GraphDialog;
