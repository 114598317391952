import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_readpeak_campaign_data_aggregated = (
  readpeakCampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2,
  startDateForLastDaysData,
  startDateForLastDaysDataMinusOneDay,
  tomorrow,
  dayAfterTomorrow
) => {
  // console.log('readpeakCampaign?.action01: ', readpeakCampaign?.action01);
  const action01DataSource = readpeakCampaign?.action01?.dataSource;
  const action02DataSource = readpeakCampaign?.action02?.dataSource;
  const action03DataSource = readpeakCampaign?.action03?.dataSource;

  const action01DataSourceIsReadpeak = action01DataSource === 'readpeak';
  const action02DataSourceIsReadpeak = action02DataSource === 'readpeak';
  const action03DataSourceIsReadpeak = action03DataSource === 'readpeak';

  const action01Type = readpeakCampaign?.action01?.readpeak?.type;

  const action02Type = readpeakCampaign?.action02?.readpeak?.type;

  const action03Type = readpeakCampaign?.action03?.readpeak?.type;

  const action01Id = readpeakCampaign?.action01?.readpeak?.id;

  const action02Id = readpeakCampaign?.action02?.readpeak?.id;

  const action03Id = readpeakCampaign?.action03?.readpeak?.id;

  // const action01Name = readpeakCampaign?.action01?.readpeak?.name;

  // const action02Name = readpeakCampaign?.action02?.readpeak?.name;

  // const action03Name = readpeakCampaign?.action03?.readpeak?.name;

  // const actionWindowDict = {
  //   default: 'externalWebsiteConversions',
  //   postClick: 'externalWebsitePostClickConversions',
  //   postView: 'externalWebsitePostViewConversions'
  // };

  // const action01AdInteraction =
  //   readpeakCampaign?.action01?.readpeak?.adInteraction;
  // const action02AdInteraction =
  //   readpeakCampaign?.action02?.readpeak?.adInteraction;
  // const action03AdInteraction =
  //   readpeakCampaign?.action03?.readpeak?.adInteraction;

  // console.log('action01AdInteraction: ', action01AdInteraction);

  const campaignGroup = {
    ...readpeakCampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'readpeak_campaign'
  };

  // campaignGroup.action01.name = action01Name;
  // campaignGroup.action02.name = action02Name;
  // campaignGroup.action03.name = action03Name;

  const runCostFormula = campaignGroup.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  let action01ConversionData = [];

  let objectToPush = {
    date_start: startDateForLastDaysDataMinusOneDay.getTime()
  };
  let pushObj = false;
  for (let date of range2) {
    // let pushObj = false;
    let dailyPushObject = false;
    let dailyObjectToPush = {};

    if (new Date(date) >= new Date(startDateForLastDaysData)) {
      if (readpeakCampaign?.readpeakadreportbasicdata?.length > 0) {
        for (let readpeakBasicDataObj of readpeakCampaign?.readpeakadreportbasicdata) {
          const campaignGroupDate = new Date(+readpeakBasicDataObj.date)
            .toISOString()
            .split('T')[0];
          if (
            campaignGroupDate === new Date(date).toISOString().split('T')[0]
          ) {
            dailyPushObject = true;
            dailyObjectToPush = {
              ...dailyObjectToPush,
              cost: readpeakBasicDataObj.cost || null,
              impressions: readpeakBasicDataObj.impressions || null,
              measurableImpressions: readpeakBasicDataObj.impressions || null,
              viewableImpressions:
                readpeakBasicDataObj.visible_impressions || null,

              clicks: +readpeakBasicDataObj.clicks || null,

              date_start: new Date(+readpeakBasicDataObj.date).getTime(),
              date_stop: new Date(+readpeakBasicDataObj.date).getTime(),

              accountId: campaignBaseValues.accountId
            };
          }
        }
      }

      for (let actionNumber of ['01', '02', '03']) {
        if (
          readpeakCampaign?.['action' + actionNumber]?.dataSource === 'readpeak'
        ) {
          switch (action01Type) {
            case 'conversions':
              if (readpeakCampaign?.readpeakadreportbasicdata?.length > 0) {
                for (let readpeakBasicDataObj of readpeakCampaign?.readpeakadreportbasicdata) {
                  const campaignGroupDate = new Date(+readpeakBasicDataObj.date)
                    .toISOString()
                    .split('T')[0];
                  if (
                    campaignGroupDate ===
                    new Date(date).toISOString().split('T')[0]
                  ) {
                    dailyObjectToPush[`action${actionNumber}Count`] =
                      (dailyObjectToPush?.[`action${actionNumber}Count`] || 0) +
                      (readpeakBasicDataObj.conversions || null);
                    dailyObjectToPush[`action${actionNumber}Value`] =
                      (dailyObjectToPush?.[`action${actionNumber}Value`] || 0) +
                      (readpeakBasicDataObj.revenue || null);
                  }
                }
              }

              break;

            default:
              if (readpeakCampaign?.readpeakadreportactiondata?.length > 0) {
                for (let conversionObj of readpeakCampaign?.readpeakadreportactiondata) {
                  if (
                    // conversionObj?.segments?.conversionAction === action01Type &&
                    conversionObj.goalId ===
                      readpeakCampaign?.['action' + actionNumber]?.readpeak
                        ?.id &&
                    new Date(+conversionObj.date)
                      .toISOString()
                      .split('T')[0] === date
                  ) {
                    dailyObjectToPush[`action${actionNumber}Count`] =
                      (dailyObjectToPush?.[`action${actionNumber}Count`] || 0) +
                      (conversionObj.conversions || null);
                    dailyObjectToPush[`action${actionNumber}Value`] =
                      (dailyObjectToPush?.[`action${actionNumber}Value`] || 0) +
                      (conversionObj.revenue || null);
                  }
                }
              }
          }
        }
      }

      if (dailyPushObject) {
        listOfPropagationData.push(dailyObjectToPush);
      }
    } else {
      if (readpeakCampaign?.readpeakadreportbasicdata?.length > 0) {
        for (let readpeakBasicDataObj of readpeakCampaign?.readpeakadreportbasicdata) {
          const campaignGroupDate = new Date(+readpeakBasicDataObj.date)
            .toISOString()
            .split('T')[0];
          if (campaignGroupDate === date) {
            pushObj = true;
            objectToPush = {
              ...objectToPush,

              cost:
                (objectToPush.cost || 0) + readpeakBasicDataObj.cost || null,
              impressions:
                (objectToPush.impressions || 0) +
                  readpeakBasicDataObj.impressions || null,
              measurableImpressions:
                (objectToPush.measurableImpressions || 0) +
                  readpeakBasicDataObj.impressions || null,
              viewableImpressions:
                (objectToPush.viewableImpressions || 0) +
                  readpeakBasicDataObj.visible_impressions || null,

              clicks:
                (objectToPush.clicks || 0) + readpeakBasicDataObj.clicks ||
                null,

              accountId: campaignBaseValues.accountId
            };
          }
        }
      }

      for (let actionNumber of ['01', '02', '03']) {
        if (
          readpeakCampaign?.['action' + actionNumber]?.dataSource === 'readpeak'
        ) {
          switch (action01Type) {
            case 'conversions':
              if (readpeakCampaign?.readpeakadreportbasicdata?.length > 0) {
                for (let readpeakBasicDataObj of readpeakCampaign?.readpeakadreportbasicdata) {
                  const campaignGroupDate = new Date(+readpeakBasicDataObj.date)
                    .toISOString()
                    .split('T')[0];
                  if (
                    campaignGroupDate ===
                    new Date(date).toISOString().split('T')[0]
                  ) {
                    objectToPush[`action${actionNumber}Count`] =
                      (objectToPush?.[`action${actionNumber}Count`] || 0) +
                      (readpeakBasicDataObj.conversions || null);
                    objectToPush[`action${actionNumber}Value`] =
                      (objectToPush?.[`action${actionNumber}Value`] || 0) +
                      (readpeakBasicDataObj.revenue || null);
                  }
                }
              }

              break;

            default:
              if (readpeakCampaign?.readpeakadreportactiondata?.length > 0) {
                for (let conversionObj of readpeakCampaign?.readpeakadreportactiondata) {
                  if (
                    // conversionObj?.segments?.conversionAction === action01Type &&
                    conversionObj.goalId ===
                      readpeakCampaign?.['action' + actionNumber]?.readpeak
                        ?.id &&
                    new Date(+conversionObj.date)
                      .toISOString()
                      .split('T')[0] === date
                  ) {
                    objectToPush[`action${actionNumber}Count`] =
                      (objectToPush?.[`action${actionNumber}Count`] || 0) +
                      (conversionObj.conversions || null);
                    objectToPush[`action${actionNumber}Value`] =
                      (objectToPush?.[`action${actionNumber}Value`] || 0) +
                      (conversionObj.revenue || null);
                  }
                }
              }
          }
        }
      }
    }
  }

  if (pushObj) {
    listOfPropagationData.push(objectToPush);
  }

  campaignGroup.total_list_of_aggregated_propagation_data = [
    {
      costLifetime: readpeakCampaign?.totaldata?.cost,
      impressionsLifetime: readpeakCampaign?.totaldata?.impressions
    }
  ];

  campaignGroup.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaignGroup
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_readpeak_campaign_data_aggregated;
