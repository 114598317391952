import React, { Fragment, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/SpinnerSmall';

// import { makeStyles } from '@mui/styles';

import AcUnitIcon from '@mui/icons-material/AcUnit';

import CloseIcon from '@mui/icons-material/Close';

import Button from '@mui/material/Button';

// uncomment

import Table from './Table';

import ExportMenu from './export/ExportMenu';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import ColumnMenu from './column-menu/ColumnMenu';

// import arrayOfData from './dummyData';

import ReactDateRange from './ReactDateRange';

import EditDialog from './bulk-edit-menu/EditDialog';
import CopyDialog from './copy-paste-menu/CopyDialog';

import EmailPokeDialog from './email-poke-menu/EmailPokeDialog';

import AlertDialog from './AlertDialog';

import EmailIcon from '@mui/icons-material/Email';

import EditMenu from './edit-menu/EditMenu';

import {
  allEvalFields,
  sumEvalFields,
  nonSumEvalFields,
  plannedEvalFields,
  allEvalFieldsExceptTotal,
  aggregatedFields
} from './fields';

// import Dialogs from './Dialogs';

// end of uncomment

// ################## imports regarding data ################################
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import {
  tableDataQuery2,
  documentAddedSubscription,
  documentUpdatedSubscription,
  refetchDataSubscription,
  addDocumentMutation,
  updateDocumentMutation,
  // tableDataQuery
  customColumnSetsQuery
} from '../../graphql/queries';

import { AuthContext } from '../../context/AuthContext';
import { AccountContext } from '../../context/AccountContext';
import { ColumnContext } from '../../context/ColumnContext';

import { DataContext } from '../../context/DataContext';
import { InterfaceContext } from '../../context/InterfaceContext';

import { SnackbarProvider, useSnackbar } from 'notistack';

import evaluateFormula from './roitable_functions/evaluateFormula';

import calcFields from './roitable_functions/calcFields';
import calcFields2 from './roitable_functions/calcFields2';

import DataLevelSwitch from './DataLevelSwitch';

import EditIcon from '@mui/icons-material/Edit';

// Context

import { FilterContext } from '../../context/FilterContext';

import QuickCreate from './QuickCreate';

import StatusFilterMenu from './StatusFilterMenu';
import FilterMenu from './FilterMenu';

import DisplayMenu from './report/views/DisplayMenu';

import FlightViewButton from './FlightViewButton';

// RoiTable functions

import parse_fb_campaign_data from './roitable_functions/parse_fb_campaign_data';
import parse_fb_data from './roitable_functions/parse_fb_data';

import parse_google_ads_campaign_data from './roitable_functions/parse_google_ads_campaign_data';
import parse_google_ads_data from './roitable_functions/parse_google_ads_data';

import parse_cm360_campaign_data from './roitable_functions/parse_cm360_campaign_data';

import parse_dv360_insertion_order_data from './roitable_functions/parse_dv360_insertion_order_data';
import parse_dv360_line_item_data from './roitable_functions/parse_dv360_line_item_data';

import parse_snapchat_ads_campaign_data from './roitable_functions/parse_snapchat_ads_campaign_data';
import parse_snapchat_ads_ad_squad_data from './roitable_functions/parse_snapchat_ads_ad_squad_data';

import parse_tiktok_ads_campaign_data from './roitable_functions/parse_tiktok_ads_campaign_data';

import parse_adform_order_data from './roitable_functions/parse_adform_order_data';

import formatDate from './roitable_functions/formatDate';
import ColumnDialog from './column-menu/ColumnDialog';
import FieldDialog from './report/info/field-menu/FieldDialog';

import { DialogContext } from '../../context/DialogContext';
import FilterComponent from './FilterComponent';

import { columnArray as allColumns } from './column-menu/columns';

import {
  // atan2,
  // chain,
  // derivative,
  // e,
  // evaluate,
  // log,
  // pi,
  // pow,
  // round,
  // sqrt
  compile
} from 'mathjs';

// const abc = {};
// abc['hej'] = compile('cost');
// console.log('abc23423: ', abc['hej'].evaluate({ cost: 5000 }));

// ######################################################################################

// const useStyles = makeStyles((theme) => ({
//   button: {
//     margin: theme.spacing(1)
//   }
// }));

const getDates = (startDate, endDate) => {
  let dates = [];

  //to avoid modifying the original date
  const theDate = new Date(startDate);
  const theEndDate = new Date(endDate);
  theDate.setHours(5);
  theEndDate.setHours(5);
  while (theDate <= theEndDate) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }

  // if(dates[dates.length-1] !== theEndDate){
  //   dates.push(theEndDate);
  // }

  return dates;
};

// first and last date

// var todaysDate = new Date();
// var firstDayOfMonth = new Date(
//   todaysDate.getFullYear(),
//   todaysDate.getMonth(),
//   1
// );
// firstDayOfMonth.setHours(5);

// var lastDayOfMonth = new Date(
//   todaysDate.getFullYear(),
//   todaysDate.getMonth() + 1,
//   0
// );
// lastDayOfMonth.setHours(5);

// const default_date_start_first_date_as_string = firstDayOfMonth
//   .toISOString()
//   .split('T')[0];

// const default_date_stop_last_date_of_month_as_string = lastDayOfMonth
//   .toISOString()
//   .split('T')[0];

// const default_date_start = new Date();
// default_date_start.setDate(default_date_start.getDate() - 35);
// const default_date_start_as_string = default_date_start
//   .toISOString()
//   .split('T')[0];

// const default_date_stop = new Date();
// const default_date_stop = new Date();
// default_date_stop.setDate(default_date_stop.getDate() + 14);
// const default_date_stop_as_string = default_date_stop
//   .toISOString()
//   .split('T')[0];

const rowBaseKV = (document, documentType, source) => {
  const formulaObjectToSpread = {};

  for (let formulaKey of allEvalFields) {
    formulaObjectToSpread[formulaKey + 'Formula'] = document[formulaKey];
    formulaObjectToSpread[formulaKey + 'Sparkline'] = [];
    formulaObjectToSpread[formulaKey] = null;
    formulaObjectToSpread[formulaKey + 'Today'] = null;
    formulaObjectToSpread[formulaKey + 'Yesterday'] = null;
    formulaObjectToSpread[formulaKey + 'SumChild'] = null;
  }

  const spreadObject = {};
  for (let key of Object.keys(document)) {
    if (['nexusDocuments'].includes(key)) {
      continue;
    } else if (!allEvalFields.includes(key)) {
      spreadObject[key] = document[key];
    } else {
      spreadObject[key] = null;
    }
  }

  return {
    // ...document,
    ...spreadObject,
    ...formulaObjectToSpread,

    // _id: document._id,

    childDocumentIds: [],

    warnings: [],
    subWarnings: [],
    snackbarWarnings: [],
    highestWarningLevel: null,

    startDate: document.startDate,
    endDate: document.endDate,
    type: document.type ? document.type : documentType,

    budgetUntillNow: null,

    source,
    interactivePath: [],
    childRows: [],
    total_list_of_propagation_data: [],
    total_list_of_aggregated_propagation_data: [],
    totalData: [],
    total_list_of_propagation_data_row: [],
    _members: document._members || [],
    members: document.members || [],
    _labels: document._labels || [],
    labels: document.labels || []
  };
};

const RoiTable = ({
  // currentManagerAccount,
  // currentAccountIds,
  stylesStoredInRedux
  // user
}) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const {
    currentManagerAccount,
    currentAccountIds,
    currentAccounts,
    subscriptionSkip,
    setSubscriptionSkip
  } = useContext(AccountContext);

  const {
    selectedDateStart,
    setSelectedDateStart,
    selectedDateStop,
    setSelectedDateStop,
    range,
    setRange,
    range2,
    setRange2,
    isAdvancedModeOn,
    copiedDocumentIds,
    setCopiedDocumentIds,
    copiedDocumentsAccountId,
    setCopiedDocumentsAccountId
  } = useContext(InterfaceContext);

  const {
    statusFilter,
    setStatusFilter,
    // filterBySelection,
    setFilterBySelection,
    filters,
    setFilters,
    // filterByMemberId,
    // setFilterByMemberId,

    // filterByLabelId,
    // setFilterByLabelId,

    // filterByString,
    // setFilterByString,
    // filterByName,
    // setFilterByName,
    // filterByDataSourceName,
    // setFilterByDataSourceName,
    // filterByDataSource,
    // setFilterByDataSource,
    checkedBoxes,
    setCheckedBoxes
  } = useContext(FilterContext);

  const { refetchColumns } = useContext(ColumnContext);

  const {
    columnDialogOpen,
    editDialogOpen,
    setEditDialogOpen,
    emailPokeDialogOpen,
    setEmailPokeDialogOpen,
    fieldDialogOpen,
    copyDialogOpen
  } = useContext(DialogContext);

  const { listOfData, setListOfData } = useContext(DataContext);

  const [dataLevel, setDataLevel] = useState('campaign');

  const [visColumns, setVisColumns] = useState([]);
  const [arrayOfData, setArrayOfData] = useState([]);

  const [names, setNames] = useState([]);

  // const [subscriptionSkip, setSubscriptionSkip] = useState(true);

  const [allSnackbarWarnings, setAllSnackbarWarnings] = useState([]);

  // const [copiedDocumentIds, setCopiedDocumentIds] = useState([]);
  // const [copiedDocumentsAccountId, setCopiedDocumentsAccountId] = useState([]);

  let startOfToday = new Date();
  startOfToday.setHours(0);
  startOfToday.setMinutes(0);

  const action = (key) => (
    <>
      {/* <button onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
        Alert
      </button> */}
      <Button
        style={{ maxWidth: '40px', minWidth: '40px', width: '40px' }}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon />
      </Button>
    </>
  );

  useEffect(() => {
    for (let snackbarWarning of allSnackbarWarnings) {
      enqueueSnackbar(snackbarWarning.message, {
        variant: snackbarWarning.variant,
        action
        // persist: true
      });
    }
  }, [allSnackbarWarnings]);

  // useEffect(() => {
  //   if (
  //     currentManagerAccount?._id &&
  //     currentAccountIds?.length > 0 &&
  //     user?._id
  //   ) {
  //     setSubscriptionSkip(false);
  //   }
  // }, [currentManagerAccount, currentAccountIds]);

  const [summaryRow, setSummaryRow] = useState({
    cost: null,
    impressions: null,
    trp: null,
    spots: null
  });

  const [totalListOfPropagationData, setTotalListOfPropagationData] = useState(
    []
  );

  const [totalListOfPropagationBasicData, setTotalListOfPropagationBasicData] =
    useState([]);

  const [subscriptionUpdatesFrozen, setSubscriptionUpdatesFrozen] =
    useState(false);

  // #############################################################

  const [variables, setVariables] = useState({});
  useEffect(() => {
    setSubscriptionCallStack([]);

    let variables = {
      accountsInput: {
        managerAccountId: currentManagerAccount._id,
        accountId: currentAccountIds || []
      },
      nexusDocumentsQueryInput: {
        selectedDateStart,
        selectedDateStop,
        statusFilter: statusFilter,
        dataLevel
      },
      adAccountCampaignsInput: { selectedDateStart, selectedDateStop }
    };

    if (!variables?.accountsInput.managerAccountId) {
      return;
    }

    if (['system_admin', 'debug'].includes(user.role)) {
      console.log(
        'debug>RoiTable>row 242>currentAccountIds: ',
        currentAccountIds
      );
      console.log('debug>RoiTable>row 243>variables 65555: ', variables);
    }

    setVariables(variables);
  }, [
    currentManagerAccount?._id,
    currentAccountIds,
    selectedDateStart,
    selectedDateStop,
    dataLevel,
    statusFilter
  ]);

  // if (['system_admin', 'debug'].includes(user.role)) {
  //   console.log(
  //     'debug>RoiTable>row 242>currentAccountIds: ',
  //     currentAccountIds
  //   );
  //   console.log('debug>RoiTable>row 243>variables: ', variables);
  // }

  const [skip, toggleSkip] = useState(false);

  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(tableDataQuery2, {
    variables,

    fetchPolicy: 'no-cache',

    onCompleted: () => {
      console.log('RoiTable>Query completed.');
    },
    onError: (error) => {
      console.log('error: ', error);
    }
  });

  useEffect(() => {
    if (
      variables?.accountsInput?.managerAccountId &&
      selectedDateStart &&
      selectedDateStop
      // variables?.accountsInput?.accountId?.length > 0
    ) {
      console.log('running get data 65555');
      const data = getData();
      // console.log('running get data>data: ', data);
    }
  }, [variables]);

  // console.log(' after running get data>data 65555: ', data);
  // useEffect(() => {
  //   console.log('65555: data: ', data);
  // }, [data]);

  // const { data: columnData, refetch: refetchColumns } = useQuery(
  //   customColumnSetsQuery,
  //   {
  //     variables: {
  //       customColumnSetsInput: { managerAccountId: currentManagerAccount._id }
  //     },
  //     fetchPolicy: 'no-cache',
  //     // skip: false
  //     // skip: currentManagerAccount._id ? skip : true
  //     skip:
  //       (currentAccountIds.length > 1 && dataLevel === 'campaign') ||
  //       currentManagerAccount._id
  //         ? skip
  //         : true
  //   }
  // );

  if (['system_admin', 'debug'].includes(user.role)) {
    console.log('debug>RoiTable>row 293>data: ', data);
  }

  const [addDocument] = useMutation(addDocumentMutation);
  const [updateDocument] = useMutation(updateDocumentMutation);

  const [rerender, setRerender] = useState(0);
  const [updateAccountId, setUpdateAccountId] = useState('');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  if (window.WSDisconnectWarning && !loading) {
    enqueueSnackbar(window.WSDisconnectWarning, {
      variant: 'error',
      persist: true,
      preventDuplicate: true,
      action
    });

    // return console.log('Unallowed characters were used.');
  }

  // ################################################################

  useSubscription(refetchDataSubscription, {
    variables: {
      refetchDataInput: {
        currentManagerAccountId: currentManagerAccount._id,
        currentAccountIds,
        subscriptionUpdatesFrozen
      }
    },
    // skip: subscriptionSkip,
    onSubscriptionData: async ({ client, subscriptionData }) => {
      if (subscriptionUpdatesFrozen) {
        return;
      }
      //clear subscription callstacks

      const refetchAction =
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.refetchData &&
        subscriptionData.data.refetchData.action;

      switch (refetchAction) {
        case 'REFETCH_CUSTOM_COLUMN_SET':
          // console.log('refetchColumns()');
          refetchColumns();
          break;
        default:
          setSubscriptionCallStack([]);
          refetch();
      }
    }
  });

  // #################################################################

  useSubscription(documentAddedSubscription, {
    variables: {
      documentUpdatedInput: {
        currentAccountIds: currentAccountIds,
        selectedDateStart,
        selectedDateStop,
        dataLevel,
        subscriptionUpdatesFrozen
      }
    },
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    // skip: subscriptionSkip,

    onSubscriptionData: async ({ client, subscriptionData }) => {
      if (subscriptionUpdatesFrozen) {
        return;
      }
      setSubscriptionCallStack([
        ...subscriptionCallStack,
        { subscriptionType: 'documentAddedSubscription', subscriptionData }
      ]);

      if (['system_admin', 'debug'].includes(user.role)) {
        console.log(
          'debug>RoiTable>row 400>documentAddedSubscription>subscriptionData: ',
          subscriptionData
        );
      }
    }
  });

  const documentAdded = (subscriptionData) => {
    try {
      if (data.accounts.length <= 0) {
        return;
      }

      const documentAdded = subscriptionData.data.documentAdded;

      const {
        checkedDocumentId,
        level,
        newNexusDocument,
        documentType,
        updateKey,
        updatedContainer,
        action
      } = subscriptionData.data.documentAdded;

      let preparedDocument = {
        ...newNexusDocument,
        nexusDocuments: [],
        childDocumentIds: []
      };

      const parentFolder = listOfData.filter(
        (obj) => obj._id == checkedDocumentId
      );
      if (!parentFolder) {
        console.log('error sdfam234');
        return;
      }

      const {
        level_1_container_id: level1ContainerId,
        level_2_container_id: level2ContainerId,
        level_3_container_id: level3ContainerId,
        level_4_container_id: level4ContainerId,
        level_5_container_id: level5ContainerId,
        level_6_container_id: level6ContainerId,
        level_7_container_id: level7ContainerId,
        level_8_container_id: level8ContainerId,
        level_9_container_id: level9ContainerId,
        level_10_container_id: level10ContainerId
      } = parentFolder[0];

      // const copyOfAccounts = [...data.accounts];

      const accounts = data.accounts;

      // console.log('subscriptionData123: ', subscriptionData);

      let level_1_container;
      let level_2_container;
      let level_3_container;
      let level_4_container;
      let level_5_container;
      let level_6_container;
      let level_7_container;
      let level_8_container;
      let level_9_container;
      let level_10_container;

      let level_1_container_index;
      let level_2_container_index;
      let level_3_container_index;
      let level_4_container_index;
      let level_5_container_index;
      let level_6_container_index;
      let level_7_container_index;
      let level_8_container_index;
      let level_9_container_index;
      let level_10_container_index;
      let level_1_container_updated;

      let snackbarMessage;
      let snackbarVariant = 'success';

      const getContainerToBeChanged = (
        parentNexusDocument,
        childNexusDocumentId
      ) => {
        const nexusDocumentToBeChanged =
          parentNexusDocument.nexusDocuments.filter((obj) => {
            return obj._id === childNexusDocumentId;
          })[0];
        return nexusDocumentToBeChanged;
      };

      const getContainertoBeChangedIndex = (
        parentNexusDocument,
        childNexusDocumentId
      ) => {
        const nexusDocumentToBeChangedIndex =
          parentNexusDocument.nexusDocuments.findIndex((obj) => {
            return obj._id === childNexusDocumentId;
          });
        return nexusDocumentToBeChangedIndex;
      };

      if (level >= 1) {
        level_1_container = accounts.filter((obj) => {
          return obj._id === level1ContainerId;
        })[0];
        level_1_container_index = accounts.findIndex((obj) => {
          return obj._id === level1ContainerId;
        });
        if (level_1_container_index === -1) {
          console.log('level_1_container_index===-1');
          return;
        }
      }

      if (level >= 2) {
        level_2_container = getContainerToBeChanged(
          level_1_container,
          level2ContainerId
        );

        level_2_container_index = getContainertoBeChangedIndex(
          level_1_container,
          level2ContainerId
        );
        if (level_2_container_index === -1) {
          console.log('level_2_container_index===-1');
          return;
        }
      }

      if (level >= 3) {
        level_3_container = getContainerToBeChanged(
          level_2_container,
          level3ContainerId
        );

        level_3_container_index = getContainertoBeChangedIndex(
          level_2_container,
          level3ContainerId
        );
        if (level_3_container_index === -1) {
          console.log('level_3_container_index===-1');
          return;
        }
      }

      if (level >= 4) {
        level_4_container = getContainerToBeChanged(
          level_3_container,
          level4ContainerId
        );

        level_4_container_index = getContainertoBeChangedIndex(
          level_3_container,
          level4ContainerId
        );
        if (level_4_container_index === -1) {
          console.log('level_4_container_index===-1');
          return;
        }
      }

      if (level >= 5) {
        level_5_container = getContainerToBeChanged(
          level_4_container,
          level5ContainerId
        );

        level_5_container_index = getContainertoBeChangedIndex(
          level_4_container,
          level5ContainerId
        );

        if (level_5_container_index === -1) {
          console.log('level_5_container_index===-1');
          return;
        }
      }

      if (level >= 6) {
        level_6_container = getContainerToBeChanged(
          level_5_container,
          level6ContainerId
        );

        level_6_container_index = getContainertoBeChangedIndex(
          level_5_container,
          level6ContainerId
        );

        if (level_6_container_index === -1) {
          console.log('level_6_container_index===-1');
          return;
        }
      }

      if (level >= 7) {
        level_7_container = getContainerToBeChanged(
          level_6_container,
          level7ContainerId
        );

        level_7_container_index = getContainertoBeChangedIndex(
          level_6_container,
          level7ContainerId
        );

        if (level_7_container_index === -1) {
          console.log('level_7_container_index===-1');
          return;
        }
      }

      if (level >= 8) {
        level_8_container = getContainerToBeChanged(
          level_7_container,
          level8ContainerId
        );

        level_8_container_index = getContainertoBeChangedIndex(
          level_7_container,
          level8ContainerId
        );

        if (level_8_container_index === -1) {
          console.log('level_8_container_index===-1');
          return;
        }
      }

      if (level >= 9) {
        level_9_container = getContainerToBeChanged(
          level_8_container,
          level9ContainerId
        );

        level_9_container_index = getContainertoBeChangedIndex(
          level_8_container,
          level9ContainerId
        );

        if (level_9_container_index === -1) {
          console.log('level_9_container_index===-1');
          return;
        }
      }

      if (level >= 10) {
        level_10_container = getContainerToBeChanged(
          level_9_container,
          level10ContainerId
        );

        level_10_container_index = getContainertoBeChangedIndex(
          level_9_container,
          level10ContainerId
        );

        if (level_10_container_index === -1) {
          console.log('level_10_container_index===-1');
          return;
        }
      }

      let containerToEdit;

      switch (level) {
        case 1:
          containerToEdit = level_1_container;
          break;
        case 2:
          containerToEdit = level_2_container;
          break;
        case 3:
          containerToEdit = level_3_container;
          break;
        case 4:
          containerToEdit = level_4_container;
          break;
        case 5:
          containerToEdit = level_5_container;
          break;
        case 6:
          containerToEdit = level_6_container;
          break;
        case 7:
          containerToEdit = level_7_container;
          break;
        case 8:
          containerToEdit = level_8_container;
          break;
        case 9:
          containerToEdit = level_9_container;
          break;
        case 10:
          containerToEdit = level_10_container;
          break;
      }

      const duplicates = containerToEdit.nexusDocuments.filter((obj) => {
        return obj._id === preparedDocument._id;
      });

      if (duplicates.length === 0) {
        containerToEdit.nexusDocuments.push(preparedDocument);
      } else {
        return;
      }

      switch (documentType) {
        case 'folder':
          snackbarMessage = 'New Folder Created';
          break;
        case 'task':
          snackbarMessage = 'New Task Created';
          break;
        case 'subTask':
          snackbarMessage = 'New subtask Created';
          break;
        case 'taxonomy':
          snackbarMessage = 'New taxonomy Created';
          break;
        default:
          snackbarMessage = 'New document';
      }

      if (level >= 10) {
        level_9_container.nexusDocuments.splice(
          level_10_container_index,
          1,
          level_10_container
        );
      }

      if (level >= 9) {
        level_8_container.nexusDocuments.splice(
          level_9_container_index,
          1,
          level_9_container
        );
      }

      if (level >= 8) {
        level_7_container.nexusDocuments.splice(
          level_8_container_index,
          1,
          level_8_container
        );
      }

      if (level >= 7) {
        level_6_container.nexusDocuments.splice(
          level_7_container_index,
          1,
          level_7_container
        );
      }

      if (level >= 6) {
        level_5_container.nexusDocuments.splice(
          level_6_container_index,
          1,
          level_6_container
        );
      }

      if (level >= 5) {
        level_4_container.nexusDocuments.splice(
          level_5_container_index,
          1,
          level_5_container
        );
      }

      if (level >= 4) {
        level_3_container.nexusDocuments.splice(
          level_4_container_index,
          1,
          level_4_container
        );
      }

      if (level >= 3) {
        level_2_container.nexusDocuments.splice(
          level_3_container_index,
          1,
          level_3_container
        );
      }
      if (level >= 2) {
        level_1_container.nexusDocuments.splice(
          level_2_container_index,
          1,
          level_2_container
        );
      }

      // const copyOfData = { ...data, accounts: accounts };

      // const copyOfData = { ...data };

      if (level >= 1) {
        data.accounts.splice(level_1_container_index, 1, level_1_container);
        // }
      }

      // ################### test ########################

      // const copyOfData = { ...data, accounts: accounts };

      // console.log('documentAdded>copyOfData: ', copyOfData);

      // client.writeQuery({
      //   query: tableDataQuery2,
      //   data: copyOfData,
      //   variables
      // });

      // ###################### test ######################

      // setRerender(rerender + 1);

      // // ##################################

      const addToParentChildRows = (rowToAdd, rowToEdit) => {
        const parentRowIndex = copyOfListOfData.findIndex((row) => {
          return (
            row._id ===
            rowToEdit[
              'level_' + (rowToEdit.level - 1).toString() + '_container_id'
            ]
          );
        });

        const parentRow = copyOfListOfData.filter((row) => {
          return (
            row._id ===
            rowToEdit[
              'level_' + (rowToEdit.level - 1).toString() + '_container_id'
            ]
          );
        })[0];

        // console.log('RoiTable123>parentRow: ', parentRow);
        // console.log('RoiTable123>newRow: ', rowToAdd);
        if (parentRow.ganttBarDisplaySetting === 'CHILDREN') {
          // const childIndex = parentRow.childRows.findIndex(
          //   (childRow) => childRow._id === updatedRow._id
          // );
          parentRow.childRows.push(rowToAdd);

          addToParentChildRows(rowToAdd, parentRow);

          // console.log('RoiTable123>childIndex: ', childIndex);

          // updateParentChildRows(updatedRow, parentRow);
        }
      };
      const copyOfListOfData = [...listOfData];
      // console.log('copyOfListOfData: ', copyOfListOfData);

      // console.log('preparedDocument: ', preparedDocument);

      const spreadDoc = rowBaseKV(preparedDocument, documentType, '');

      switch (documentType) {
        case 'folder':
        case 'manualDataEntry':
        case 'customForm':
          // case 'googleAds':
          const newRow = {
            ...spreadDoc,
            // ...rowBaseKVs,
            isSet: preparedDocument.isSet,
            template: preparedDocument.template,
            checkedAt: preparedDocument.checkedAt,
            personal: preparedDocument.personal,
            fields: preparedDocument.fields,
            _id: preparedDocument._id,
            childDocumentIds: [],
            name: preparedDocument.name,
            status: preparedDocument.status,
            statusUpdatedAt: preparedDocument.statusUpdatedAt,
            type: documentType,
            level: level + 1,
            accountAccessLevel: 1,
            accountId: level1ContainerId,
            level_1_container_id: level1ContainerId,
            level_2_container_id:
              level + 1 === 2 ? preparedDocument._id : level2ContainerId,
            level_3_container_id:
              level + 1 === 3 ? preparedDocument._id : level3ContainerId,
            level_4_container_id:
              level + 1 === 4 ? preparedDocument._id : level4ContainerId,
            level_5_container_id:
              level + 1 === 5 ? preparedDocument._id : level5ContainerId,
            level_6_container_id:
              level + 1 === 6 ? preparedDocument._id : level6ContainerId,
            level_7_container_id:
              level + 1 === 7 ? preparedDocument._id : level7ContainerId,
            level_8_container_id:
              level + 1 === 8 ? preparedDocument._id : level8ContainerId,
            level_9_container_id:
              level + 1 === 9 ? preparedDocument._id : level9ContainerId,
            level_10_container_id:
              level + 1 === 10 ? preparedDocument._id : level10ContainerId,
            warnings: [],
            subWarnings: [],
            snackbarWarnings: [],
            childRows: [],
            documentType,
            members: preparedDocument.members,
            labels: [],
            totalData: [],
            total_list_of_aggregated_propagation_data: [],
            total_list_of_propagation_data: [],
            total_list_of_propagation_data_row: [],
            style: preparedDocument.style,
            path: parentFolder[0].path + preparedDocument.name + ' / ',
            // interactivePath: [...parentFolder[0].path, {name: preparedDocument.name, _id: preparedDocument.name}]
            interactivePath: [
              ...parentFolder[0].interactivePath,
              { _id: preparedDocument._id, name: preparedDocument.name }
            ],
            plannedGrossFormula: preparedDocument.plannedGross,
            plannedNetFormula: preparedDocument.plannedNet,
            plannedNetNetFormula: preparedDocument.plannedNetNet,
            plannedCtcFormula: preparedDocument.plannedCtc,

            plannedTrpFormula: preparedDocument.plannedTrp,
            plannedCppFormula: preparedDocument.plannedCpp,
            plannedTechFeeFormula: preparedDocument.plannedTechFee,
            plannedAdOpsFeeFormula: preparedDocument.plannedAdOpsFee,
            plannedAdServingCostFormula: preparedDocument.plannedAdServingCost,
            plannedFeePercentFormula: preparedDocument.plannedFeePercent,

            budgetFormula: preparedDocument.budget,
            costFormula: preparedDocument.cost,
            impressionsFormula: preparedDocument.impressions,
            clicksFormula: preparedDocument.clicks
          };

          copyOfListOfData.push(newRow);
          addToParentChildRows(newRow, newRow);
          setListOfData(copyOfListOfData);
          break;
        default:
          setUpdateAccountId(level1ContainerId);
          setRerender(rerender + 1);
      }

      // // ##################################

      enqueueSnackbar(snackbarMessage, {
        variant: snackbarVariant
      });
    } catch (error) {}
  };

  const [subscriptionCallStack, setSubscriptionCallStack] = useState([]);

  if (['system_admin', 'debug'].includes(user.role)) {
    console.log(
      'debug>RoiTable>row 989>subscriptionCallStack: ',
      subscriptionCallStack
    );
  }
  // useEffect(() => {
  //   if (!subscriptionSkip) {
  //     // console.log('subscriptionSkip: ', subscriptionSkip);
  //     console.log('documentUpdated>variables: ', {
  //       documentUpdatedInput: {
  //         currentAccountIds: () => currentAccounts.map((obj) => obj._id),
  //         selectedDateStart,
  //         selectedDateStop,
  //         dataLevel,
  //         subscriptionUpdatesFrozen,
  //         isAuthenticated,
  //         subscriptionSkip
  //       }
  //     });
  //   }
  // }, [subscriptionSkip]);

  const {
    data: documentUpdatedData,
    loading: documentUpdatedLoading,
    error: documentUpdatedError
  } = useSubscription(documentUpdatedSubscription, {
    variables: {
      documentUpdatedInput: {
        currentAccountIds: currentAccountIds,
        selectedDateStart,
        selectedDateStop,
        dataLevel,
        subscriptionUpdatesFrozen
      }
    }, //currentAccountIds, selectedDateStart, selectedDateStop.
    shouldResubscribe: true,
    // skip: currentAccountIds.length > 0,
    // skip: subscriptionSkip,
    fetchPolicy: 'no-cache',
    // currentManagerAccount?._id && currentAccountIds.length > 0 ? false : true,
    onSubscriptionData: async ({ client, subscriptionData }) => {
      if (['system_admin', 'debug'].includes(user.role)) {
        console.log(
          'debug>RoiTable>row 898>documentUpdatedSubscription>subscriptionData: ',
          subscriptionData
        );
      }

      if (subscriptionUpdatesFrozen) {
        return;
      }

      setSubscriptionCallStack([
        ...subscriptionCallStack,
        { subscriptionType: 'documentUpdatedSubscription', subscriptionData }
      ]);
    }
  });

  const [doneCalculating, toggleDoneCalculating] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    // client

    if (!loading && subscriptionCallStack.length > 0) {
      // loop changes

      for (let i = 0; i < subscriptionCallStack.length; i++) {
        if (doneCalculating) {
          switch (subscriptionCallStack[i].subscriptionType) {
            case 'documentAddedSubscription':
              documentAdded(subscriptionCallStack[i].subscriptionData);
              break;
            case 'documentUpdatedSubscription':
              documentUpdated(subscriptionCallStack[i].subscriptionData);
              break;
          }

          const copyOfSubscriptionCallStack = [...subscriptionCallStack];
          copyOfSubscriptionCallStack.splice(i, 1);
          setSubscriptionCallStack(copyOfSubscriptionCallStack);
        }
      }
    }
  }, [loading, subscriptionCallStack, doneCalculating]);

  const documentUpdated = async (subscriptionData) => {
    try {
      if (data.accounts.length <= 0) {
        return;
      }
      // let tableDataBeforeUpdate = [...tableData];
      // let updatedTableData = [...tableData];

      const documentUpdatedData = subscriptionData.data.documentUpdated;

      const {
        accountId,
        // level,
        documentId,
        // newDocument,
        // documentType,

        updateKey,
        updateKeys,
        updatedNexusDocument,
        action
      } = subscriptionData.data.documentUpdated;

      if (action === 'show_new_version_available') {
        enqueueSnackbar(
          'Please reload the page. Data out of sync. A new version is available.',
          {
            variant: 'error',
            persist: true
          }
        );
      }

      const parentFolder = listOfData.filter((obj) => obj._id == documentId);
      if (!parentFolder) {
        console.log('error sdfam234');
        return;
      }

      const {
        level_1_container_id: level1ContainerId,
        level_2_container_id: level2ContainerId,
        level_3_container_id: level3ContainerId,
        level_4_container_id: level4ContainerId,
        level_5_container_id: level5ContainerId,
        level_6_container_id: level6ContainerId,
        level_7_container_id: level7ContainerId,
        level_8_container_id: level8ContainerId,
        level_9_container_id: level9ContainerId,
        level_10_container_id: level10ContainerId,
        level,
        documentType
      } = parentFolder[0];

      const accounts = data.accounts;

      let level_1_container;
      let level_2_container;
      let level_3_container;
      let level_4_container;
      let level_5_container;
      let level_6_container;
      let level_7_container;
      let level_8_container;
      let level_9_container;
      let level_10_container;

      let level_1_container_index;
      let level_2_container_index;
      let level_3_container_index;
      let level_4_container_index;
      let level_5_container_index;
      let level_6_container_index;
      let level_7_container_index;
      let level_8_container_index;
      let level_9_container_index;
      let level_10_container_index;

      let level_1_container_updated;
      let level_2_container_updated;
      let level_3_container_updated;
      let level_4_container_updated;
      let level_5_container_updated;
      let level_6_container_updated;
      let level_7_container_updated;
      let level_8_container_updated;
      let level_9_container_updated;
      let level_10_container_updated;

      let snackbarMessage;
      let snackbarVariant = 'success';

      const getContainerToBeChanged = (supContainer, subContainerId) => {
        const containerToBeChanged = supContainer.nexusDocuments.filter(
          (obj) => {
            return obj._id === subContainerId;
          }
        )[0];

        return containerToBeChanged;
      };

      const getContainertoBeChangedIndex = (
        supContainer,
        subContainerId,
        documentType,
        finalObject,
        objectToBeChangedId
      ) => {
        let containerToBeChangedIndex;

        if (finalObject) {
          containerToBeChangedIndex = supContainer.nexusDocuments.findIndex(
            (obj) => {
              return obj._id === objectToBeChangedId;
            }
          );
        } else {
          containerToBeChangedIndex = supContainer.nexusDocuments.findIndex(
            (obj) => {
              return obj._id === subContainerId;
            }
          );
        }

        if (containerToBeChangedIndex === -1) {
          // console.log('containerToBeChangedIndex === -1');
          enqueueSnackbar(
            'Please reload the page. Data out of sync. A new version is available2.',
            {
              variant: 'error',
              persist: true
            }
          );

          setSubscriptionCallStack([]);
          console.log('refetch 798');
          refetch();
        }

        // console.log('containerToBeChangedIndex: ', containerToBeChangedIndex);

        return containerToBeChangedIndex;
      };

      let objectToBeChangedId = updatedNexusDocument._id;

      // console.log('documentUpdated>Row: 1001');
      // console.log('objectToBeChangedId: ', objectToBeChangedId);

      if (level >= 1) {
        level_1_container_index = accounts.findIndex((obj) => {
          return obj._id === level1ContainerId;
        });

        if (level_1_container_index === -1) {
          enqueueSnackbar(
            'Please reload the page. Data out of sync. A new version is available.',
            {
              variant: 'error',
              persist: true
            }
          );

          setSubscriptionCallStack([]);
          // console.log('refetch 834');
          refetch();

          return;
        }

        level_1_container = accounts.filter((obj) => {
          return obj._id === level1ContainerId;
        })[0];
      }

      if (level >= 2) {
        level_2_container_index = getContainertoBeChangedIndex(
          level_1_container,
          level2ContainerId,
          documentType,
          level === 2,
          objectToBeChangedId
        );

        if (level_2_container_index === -1) {
          return;
        }

        level_2_container = getContainerToBeChanged(
          level_1_container,
          level2ContainerId
        );
      }

      if (level >= 3) {
        level_3_container_index = getContainertoBeChangedIndex(
          level_2_container,
          level3ContainerId,
          documentType,
          level === 3,
          objectToBeChangedId
        );

        if (level_3_container_index === -1) {
          return;
        }

        level_3_container = getContainerToBeChanged(
          level_2_container,
          level3ContainerId
        );
      }

      if (level >= 4) {
        level_4_container_index = getContainertoBeChangedIndex(
          level_3_container,
          level4ContainerId,
          documentType,
          level === 4,
          objectToBeChangedId
        );

        if (level_4_container_index === -1) {
          return;
        }

        level_4_container = getContainerToBeChanged(
          level_3_container,
          level4ContainerId
        );
      }

      if (level >= 5) {
        level_5_container_index = getContainertoBeChangedIndex(
          level_4_container,
          level5ContainerId,
          documentType,
          level === 5,
          objectToBeChangedId
        );

        if (level_5_container_index === -1) {
          return;
        }

        level_5_container = getContainerToBeChanged(
          level_4_container,
          level5ContainerId
        );
      }

      if (level >= 6) {
        level_6_container_index = getContainertoBeChangedIndex(
          level_5_container,
          level6ContainerId,
          documentType,
          level === 6,
          objectToBeChangedId
        );

        if (level_6_container_index === -1) {
          return;
        }

        level_6_container = getContainerToBeChanged(
          level_5_container,
          level6ContainerId
        );
      }

      if (level >= 7) {
        level_7_container_index = getContainertoBeChangedIndex(
          level_6_container,
          level7ContainerId,
          documentType,
          level === 7,
          objectToBeChangedId
        );

        if (level_7_container_index === -1) {
          return;
        }

        level_7_container = getContainerToBeChanged(
          level_6_container,
          level7ContainerId
        );
      }

      if (level >= 8) {
        level_8_container_index = getContainertoBeChangedIndex(
          level_7_container,
          level8ContainerId,
          documentType,
          level === 8,
          objectToBeChangedId
        );

        if (level_8_container_index === -1) {
          return;
        }

        level_8_container = getContainerToBeChanged(
          level_7_container,
          level8ContainerId
        );
      }

      if (level >= 9) {
        level_9_container_index = getContainertoBeChangedIndex(
          level_8_container,
          level9ContainerId,
          documentType,
          level === 9,
          objectToBeChangedId
        );

        if (level_9_container_index === -1) {
          return;
        }

        level_9_container = getContainerToBeChanged(
          level_8_container,
          level9ContainerId
        );
      }

      if (level >= 10) {
        level_10_container_index = getContainertoBeChangedIndex(
          level_9_container,
          level10ContainerId,
          documentType,
          level === 10,
          objectToBeChangedId
        );

        if (level_10_container_index === -1) {
          return;
        }

        level_10_container = getContainerToBeChanged(
          level_9_container,
          level10ContainerId
        );
      }

      // console.log('documentUpdated>Row: 1198');

      const createUpdatedContainer = async (
        supContainer,
        subContainerIndex,
        subContainer,
        updatedNexusDocument,
        updateKey,
        documentType
      ) => {
        let updatedContainer = {};
        let copyOfContainers = [...supContainer.nexusDocuments];
        console.log('RoiTable>debug>row 1509> updateKey', updateKey);
        if (updateKey === 'startDate_endDate') {
          updatedContainer = {
            ...subContainer,
            startDate: updatedNexusDocument['startDate'],
            endDate: updatedNexusDocument['endDate']
          };
        } else {
          updatedContainer = {
            ...subContainer
          };
          for (let updatek of updateKeys) {
            updatedContainer[updatek] = updatedNexusDocument[updatek];
          }
        }

        await copyOfContainers.splice(subContainerIndex, 1, updatedContainer);

        const container_updated = {
          ...supContainer,
          nexusDocuments: copyOfContainers
        };

        return container_updated;
      };

      switch (level) {
        case 1:
          switch (documentType) {
            case 'account':
              level_1_container_updated = {
                ...level_1_container
                // [updateKey]: updatedNexusDocument[updateKey]
              };

              for (let updatek of updateKeys) {
                level_1_container_updated[updatek] =
                  updatedNexusDocument[updatek];
              }
          }
          break;
        case 2:
          level_1_container_updated = await createUpdatedContainer(
            level_1_container,
            level_2_container_index,
            level_2_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );

          break;
        case 3:
          level_2_container_updated = await createUpdatedContainer(
            level_2_container,
            level_3_container_index,
            level_3_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );

          break;
        case 4:
          level_3_container_updated = await createUpdatedContainer(
            level_3_container,
            level_4_container_index,
            level_4_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );
          //     break;
          // }
          break;
        case 5:
          level_4_container_updated = await createUpdatedContainer(
            level_4_container,
            level_5_container_index,
            level_5_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );

          break;
        case 6:
          level_5_container_updated = await createUpdatedContainer(
            level_5_container,
            level_6_container_index,
            level_6_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );

          break;
        case 7:
          level_6_container_updated = await createUpdatedContainer(
            level_6_container,
            level_7_container_index,
            level_7_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );

          break;
        case 8:
          level_7_container_updated = await createUpdatedContainer(
            level_7_container,
            level_8_container_index,
            level_8_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );

          break;
        case 9:
          level_8_container_updated = await createUpdatedContainer(
            level_8_container,
            level_9_container_index,
            level_9_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );

          break;
        case 10:
          level_9_container_updated = await createUpdatedContainer(
            level_9_container,
            level_10_container_index,
            level_10_container,
            updatedNexusDocument,
            updateKey,
            documentType
          );

          break;
      }

      if (level > 10) {
        level_8_container.nexusDocuments.splice(
          level_9_container_index,
          1,
          level_9_container
        );
      }

      if (level === 10) {
        level_8_container.nexusDocuments.splice(
          level_9_container_index,
          1,
          level_9_container_updated
        );
      }

      if (level > 9) {
        level_7_container.nexusDocuments.splice(
          level_8_container_index,
          1,
          level_8_container
        );
      }

      if (level === 9) {
        level_7_container.nexusDocuments.splice(
          level_8_container_index,
          1,
          level_8_container_updated
        );
      }

      if (level > 8) {
        level_6_container.nexusDocuments.splice(
          level_7_container_index,
          1,
          level_7_container
        );
      }

      if (level === 8) {
        level_6_container.nexusDocuments.splice(
          level_7_container_index,
          1,
          level_7_container_updated
        );
      }

      if (level > 7) {
        level_5_container.nexusDocuments.splice(
          level_6_container_index,
          1,
          level_6_container
        );
      }

      if (level === 7) {
        level_5_container.nexusDocuments.splice(
          level_6_container_index,
          1,
          level_6_container_updated
        );
      }

      if (level > 6) {
        level_4_container.nexusDocuments.splice(
          level_5_container_index,
          1,
          level_5_container
        );
      }

      if (level === 6) {
        level_4_container.nexusDocuments.splice(
          level_5_container_index,
          1,
          level_5_container_updated
        );
      }

      if (level > 5) {
        level_3_container.nexusDocuments.splice(
          level_4_container_index,
          1,
          level_4_container
        );
      }

      if (level === 5) {
        level_3_container.nexusDocuments.splice(
          level_4_container_index,
          1,
          level_4_container_updated
        );
      }

      if (level > 4) {
        level_2_container.nexusDocuments.splice(
          level_3_container_index,
          1,
          level_3_container
        );
      }

      if (level === 4) {
        level_2_container.nexusDocuments.splice(
          level_3_container_index,
          1,
          level_3_container_updated
        );
      }

      if (level > 3) {
        level_1_container.nexusDocuments.splice(
          level_2_container_index,
          1,
          level_2_container
        );
      }

      if (level === 3) {
        const replacedDocumentLevel2 = level_1_container.nexusDocuments.splice(
          level_2_container_index,
          1,
          level_2_container_updated
        );
        if (replacedDocumentLevel2._id !== updatedNexusDocument.id) {
          return;
        }
      }

      // const copyOfData = { ...data };

      if (level > 2) {
        data.accounts.splice(level_1_container_index, 1, level_1_container);
      }

      if (level === 2) {
        data.accounts.splice(
          level_1_container_index,
          1,
          level_1_container_updated
        );
      }

      if (level === 1) {
        data.accounts.splice(
          level_1_container_index,
          1,
          level_1_container_updated
        );
      }

      // console.log('updatedDocument>copyOfData: ', copyOfData);

      // client.writeQuery({
      //   query: tableDataQuery2,
      //   data: copyOfData,
      //   variables
      // });

      // setUpdateAccountId(accountId);
      // setRerender(rerender + 1);

      // ##################################

      // get index of row.
      const rowIndex = listOfData.findIndex((row) => {
        return row._id === updatedNexusDocument._id;
      });

      // console.log('rowIndex: ', rowIndex);

      const oldRow = listOfData.filter((row) => {
        return row._id === updatedNexusDocument._id;
      })[0];

      // get closest childrows

      const updatedListOfData = [...listOfData];
      // console.log('updateKey: ', updateKey);

      if (['system_admin', 'debug'].includes(user.role)) {
        console.log(
          'debug>RoiTable>row 1919>updatedListOfData: ',
          updatedListOfData
        );
      }

      let updatedRow = {};

      // setUpdateAccountId(accountId);
      // setRerender(rerender + 1);
      const updateParentChildRows = (updatedRow, queryRow) => {
        for (let row1 of updatedListOfData) {
          const childIndex = row1.childRows.findIndex(
            (childRow) => String(childRow._id) === String(updatedRow._id)
          );

          if (childIndex >= 0) {
            console.log('RoiTable123>childIndex: ', childIndex);
            row1.childRows[childIndex] = updatedRow;
          }
        }
      };

      if (updateKey) {
        console.log('Roitable>updateOne>true');
        // updatekey will be null/undefined if updating multiple keys at the same time.
        switch (documentType) {
          case 'folder':
          case 'task':
          case 'subTask':
          case 'taxonomy':
            switch (updateKey) {
              case 'ganttBarDisplaySetting':
                setUpdateAccountId(accountId);
                setRerender(rerender + 1);
                return '';

              case 'name':
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey]
                };

                for (let pathItem of updatedRow.interactivePath) {
                  if (pathItem._id === updatedNexusDocument._id) {
                    pathItem.name = updatedNexusDocument.name;
                  }
                }

                // const updatedListOfData = [...listOfData];

                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);

                break;
              case 'generalDescription':

              case 'buyingTypeDescription':
              case 'creativeDescription':
              case 'formatDescription':
              case 'channelDescription':
              case 'audienceDataDescription':

              case 'targetGroupDescription':
              case 'placementDescription':
              case 'ganttBarDisplaySetting':
              case 'goalDescription':

              case 'showSubTasksOnCard':
              case 'allowMissingBudget':
              case 'allowMissingClicks':
              case 'allowImpressionsBeforeStartDate':
              case 'allowImpressionsAfterEndDate':

              case 'budgetCurrency':

              case 'comments':
              case 'ganttBarColor':
                // case 'status':
                // case 'action01':
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey]
                };

                // const updatedListOfData = [...listOfData];
                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);
                break;

              case 'checkedAt':
                console.log('wooohoooo!');
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey]
                };

                updatedRow.daysSinceCheckedAt =
                  updatedRow?.checkedAt &&
                  differenceInDaysFloor(
                    new Date(updatedRow.checkedAt),
                    new Date()
                  );

                // console.log('updatedRow: ', updatedRow);

                // calc number of days.

                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);
                break;

              case 'status':
                console.log('traaaaala');
                if (
                  oldRow.status === 'REMOVED' ||
                  updatedNexusDocument.status === 'REMOVED'
                ) {
                  setUpdateAccountId(accountId);
                  setRerender(rerender + 1);
                  break;
                }
                // case 'action01':
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey],
                  statusUpdatedAt: updatedNexusDocument.statusUpdatedAt
                };

                if (updatedNexusDocument.status === 'ACTIVE') {
                  console.log('tralalalalala');
                  updatedRow.checkedAt = updatedNexusDocument.checkedAt;

                  updatedRow.daysSinceCheckedAt =
                    updatedRow?.checkedAt &&
                    differenceInDaysFloor(
                      new Date(updatedRow.checkedAt),
                      new Date()
                    );
                }

                // const updatedListOfData = [...listOfData];
                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);
                break;

              case 'action01':
              case 'action02':
              case 'action03':
              case 'members':
              case 'labels':
                setRerender(rerender + 1);
                // console.log('refetch 1400');
                // refetch();
                break;
              // updatedRow = {...oldRow, [updateKey]: updatedDocument[updateKey]};

              // // const updatedListOfData = [...listOfData];
              // updatedListOfData.splice(rowIndex, 1, updatedRow);
              // setListOfData(updatedListOfData);

              case 'budgetZZZ':
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey]
                };

                const parentRowIndex = updatedListOfData.findIndex((row) => {
                  return (
                    row._id ===
                    updatedRow[
                      'level_' +
                        (updatedRow.level - 1).toString() +
                        '_container_id'
                    ]
                  );
                });

                const parentRow = updatedListOfData.filter((row) => {
                  return (
                    row._id ===
                    updatedRow[
                      'level_' +
                        (updatedRow.level - 1).toString() +
                        '_container_id'
                    ]
                  );
                })[0];

                const copyOfParentRow = { ...parentRow };
                copyOfParentRow.budgetSumChild =
                  copyOfParentRow.budgetSumChild -
                  (oldRow.budget || null) +
                  updatedRow.budget;

                updatedListOfData.splice(parentRowIndex, 1, copyOfParentRow);

                try {
                  if (updatedRow.startDate && updatedRow.endDate) {
                    updatedRow.numberOfDays =
                      differenceInDays(
                        updatedRow.startDate,
                        updatedRow.endDate
                      ) + 1;

                    updatedRow.numberOfDaysLeft = numberOfDaysLeft(
                      updatedRow.startDate,
                      updatedRow.endDate
                    );

                    updatedRow.numberOfDays =
                      differenceInDays(
                        updatedRow.startDate,
                        updatedRow.endDate
                      ) + 1;
                  }

                  if (updatedRow.budget && updatedRow.numberOfDays) {
                    updatedRow.averageDailyBudget =
                      updatedRow.budget / updatedRow.numberOfDays;
                  }
                } catch (error) {}

                try {
                  if (
                    updatedRow.budget &&
                    updatedRow.startDate &&
                    updatedRow.endDate
                    // new Date(updatedRow.startDate) >=
                    //   new Date(selectedDateStart) &&
                    // new Date(updatedRow.endDate) <= new Date(selectedDateStop)
                    // ||
                    // new Date(selectedDateStop) >= new Date()
                  ) {
                    // updatedRow.pace = pace(
                    //   updatedRow,
                    //   updatedRow.startDate,
                    //   updatedRow.endDate,
                    //   updatedRow.budget,
                    //   updatedRow.cost || 0
                    // );
                    updatedRow.pace =
                      updatedRow.budgetUntillNow &&
                      updatedRow.cost &&
                      Math.round(
                        (updatedRow.cost / updatedRow.budgetUntillNow) * 100
                      );

                    updatedRow.calcDailyBudget = calcDailyBudget(
                      updatedRow.budget,
                      (updatedRow.cost || 0) - (updatedRow.costToday || 0),
                      // updatedRow.numberOfDays,
                      updatedRow.numberOfDaysLeft
                    );

                    const numberOfDaysLeftYesterday =
                      differenceInDaysFloor(new Date(), updatedRow.endDate) + 3;

                    updatedRow.numberOfDaysLeftYesterday =
                      numberOfDaysLeftYesterday > 0
                        ? numberOfDaysLeftYesterday
                        : 0;

                    updatedRow.calcDailyBudgetYesterday = calcDailyBudget(
                      updatedRow.budget,
                      (updatedRow.cost || 0) -
                        (updatedRow.costToday || 0) -
                        (updatedRow.costYesterday || 0),
                      // updatedRow.numberOfDays,
                      updatedRow.numberOfDaysLeftYesterday
                    );
                    // updatedRow.calcDailyBudgetYesterday =
                    //   (updatedRow.budget -
                    //     (updatedRow.cost || 0) -
                    //     (updatedRow.costToday || 0) -
                    //     (updatedRow.costYesterday || 0)) /
                    //   updatedRow.numberOfDaysLeftYesterday;
                  } else if (
                    updatedRow.budget &&
                    updatedRow.startDate &&
                    updatedRow.endDate
                  ) {
                    updatedRow.pace = updatedRow.budget ? '?' : '';
                    updatedRow.calcDailyBudget = '?';
                  }
                } catch (error) {}
                // update pace.
                // update calculated budget.

                // const updatedListOfData = [...listOfData];
                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);
                break;
              case 'startDateZZZ':
              case 'endDateZZZ':
                const newDate =
                  updatedNexusDocument[updateKey] === null
                    ? null
                    : new Date(parseInt(updatedNexusDocument[updateKey]))
                        .toISOString()
                        .split('T')[0];

                updatedRow = { ...oldRow, [updateKey]: newDate };

                try {
                  if (updatedRow.startDate && updatedRow.endDate) {
                    updatedRow.numberOfDays =
                      differenceInDays(
                        updatedRow.startDate,
                        updatedRow.endDate
                      ) + 1;

                    updatedRow.numberOfDaysLeft = numberOfDaysLeft(
                      updatedRow.startDate,
                      updatedRow.endDate
                    );

                    updatedRow.numberOfDays =
                      differenceInDays(
                        updatedRow.startDate,
                        updatedRow.endDate
                      ) + 1;
                  } else {
                    updatedRow.numberOfDays = null;
                  }

                  if (updatedRow.budget && updatedRow.numberOfDays) {
                    updatedRow.averageDailyBudget =
                      updatedRow.budget / updatedRow.numberOfDays;
                  } else {
                    updatedRow.averageDailyBudget = null;
                  }
                } catch (error) {}

                try {
                  if (
                    updatedRow.budget &&
                    updatedRow.startDate &&
                    updatedRow.endDate
                    // &&
                    // (new Date(updatedRow.startDate)>= new Date(selectedDateStart) && (new Date(updatedRow.endDate)<= new Date(selectedDateStop)))
                  ) {
                    if (
                      new Date(updatedRow.startDate) >=
                        new Date(selectedDateStart) &&
                      new Date(updatedRow.endDate) <= new Date(selectedDateStop)
                    ) {
                      updatedRow.pace = pace(
                        updatedRow,
                        updatedRow.startDate,
                        updatedRow.endDate,
                        updatedRow.budget,
                        updatedRow.cost
                      );

                      updatedRow.calcDailyBudget = calcDailyBudget(
                        updatedRow.budget,
                        (updatedRow.cost || 0) - (updatedRow.costToday || 0),
                        // updatedRow.numberOfDays,
                        updatedRow.numberOfDaysLeft
                      );

                      const numberOfDaysLeftYesterday =
                        differenceInDaysFloor(new Date(), updatedRow.endDate) +
                        3;

                      updatedRow.numberOfDaysLeftYesterday =
                        numberOfDaysLeftYesterday > 0
                          ? numberOfDaysLeftYesterday
                          : 0;

                      updatedRow.calcDailyBudgetYesterday = calcDailyBudget(
                        updatedRow.budget,
                        (updatedRow.cost || 0) -
                          (updatedRow.costToday || 0) -
                          (updatedRow.costYesterday || 0),
                        // updatedRow.numberOfDays,
                        updatedRow.numberOfDaysLeftYesterday
                      );

                      // updatedRow.calcDailyBudgetYesterday =
                      //   (updatedRow.budget -
                      //     (updatedRow.cost || 0) -
                      //     (updatedRow.costToday || 0) -
                      //     (updatedRow.costYesterday || 0)) /
                      //   updatedRow.numberOfDaysLeftYesterday;
                    } else {
                      updatedRow.pace = updatedRow.budget ? '?' : '';
                      updatedRow.calcDailyBudget = updatedRow.budget ? '?' : '';
                    }
                  } else {
                    updatedRow.pace = null;
                    updatedRow.calcDailyBudget = null;
                  }
                } catch (error) {}

                // update warnings.

                // if startDate/endDate is outside datepicker then rerender.

                // const updatedListOfData = [...listOfData];
                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);

                if (['system_admin', 'debug'].includes(user.role)) {
                  console.log(
                    'debug>RoiTable>row 2162>updatedListOfData: ',
                    updatedListOfData
                  );
                }
                break;
              default:
                setUpdateAccountId(accountId);
                setRerender(rerender + 1);
                return '';
            }

            break;
          case 'facebookAds':
          case 'googleAds':
          case 'cm360':
          case 'dv360':
          case 'snapchatAds':
          case 'tikTokAds':
          case 'adform':
          case 'linkedInAds':

          case 'manualDataEntry':
          case 'customForm':
            switch (updateKey) {
              case 'members':
              case 'labels':
                setRerender(rerender + 1);

                break;

              case 'name':

              case 'buyingTypeDescription':
              case 'creativeDescription':
              case 'formatDescription':
              case 'channelDescription':
              case 'audienceDataDescription':
              case 'targetGroupDescription':
              case 'placementDescription':
              case 'ganttBarDisplaySetting':
              case 'generalDescription':
              case 'goalDescription':

              case 'showSubTasksOnCard':
              case 'allowMissingBudget':
              case 'allowMissingClicks':
              case 'allowImpressionsBeforeStartDate':
              case 'allowImpressionsAfterEndDate':

              case 'budgetCurrency':
              // case 'members':
              case 'comments':
              case 'ganttBarColor':
                // case 'status':
                // case 'action01':
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey]
                };

                // const updatedListOfData = [...listOfData];
                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);
                break;

              case 'checkedAt':
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey]
                };

                updatedRow.daysSinceCheckedAt =
                  updatedRow?.checkedAt &&
                  differenceInDaysFloor(
                    new Date(updatedRow.checkedAt),
                    new Date()
                  );
                // const updatedListOfData = [...listOfData];
                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);
                break;

              case 'status':
                if (
                  oldRow.status === 'REMOVED' ||
                  updatedNexusDocument.status === 'REMOVED'
                ) {
                  console.log('status>rerender: ', true);
                  setUpdateAccountId(accountId);
                  setRerender(rerender + 1);
                  break;
                }
                // case 'action01':
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey],
                  statusUpdatedAt: updatedNexusDocument.statusUpdatedAt
                };

                if (updatedNexusDocument.status === 'ACTIVE') {
                  console.log('tralalalalala');
                  updatedRow.checkedAt = updatedNexusDocument.checkedAt;

                  updatedRow.daysSinceCheckedAt =
                    updatedRow?.checkedAt &&
                    differenceInDaysFloor(
                      new Date(updatedRow.checkedAt),
                      new Date()
                    );
                }

                // const updatedListOfData = [...listOfData];
                updatedListOfData.splice(rowIndex, 1, updatedRow);
                setListOfData(updatedListOfData);
                break;

              case 'budgetZZZ':
                updatedRow = {
                  ...oldRow,
                  [updateKey]: updatedNexusDocument[updateKey]
                };

                const parentRowIndex = updatedListOfData.findIndex((row) => {
                  return (
                    row._id ===
                    updatedRow[
                      'level_' +
                        (updatedRow.level - 1).toString() +
                        '_container_id'
                    ]
                  );
                });

                const parentRow = updatedListOfData.filter((row) => {
                  return (
                    row._id ===
                    updatedRow[
                      'level_' +
                        (updatedRow.level - 1).toString() +
                        '_container_id'
                    ]
                  );
                })[0];

                const copyOfParentRow = { ...parentRow };
                copyOfParentRow.budgetSumChild =
                  copyOfParentRow.budgetSumChild -
                  (oldRow.budget || null) +
                  updatedRow.budget;

                updatedListOfData.splice(parentRowIndex, 1, copyOfParentRow);

                try {
                  if (updatedRow.startDate && updatedRow.endDate) {
                    updatedRow.numberOfDays =
                      differenceInDays(
                        updatedRow.startDate,
                        updatedRow.endDate
                      ) + 1;

                    updatedRow.numberOfDaysLeft = numberOfDaysLeft(
                      updatedRow.startDate,
                      updatedRow.endDate
                    );

                    updatedRow.numberOfDays =
                      differenceInDays(
                        updatedRow.startDate,
                        updatedRow.endDate
                      ) + 1;
                  }

                  if (updatedRow.budget && updatedRow.numberOfDays) {
                    updatedRow.averageDailyBudget =
                      updatedRow.budget / updatedRow.numberOfDays;
                  }
                } catch (error) {}

                try {
                  if (
                    updatedRow.budget &&
                    updatedRow.startDate &&
                    updatedRow.endDate &&
                    new Date(updatedRow.startDate) >=
                      new Date(selectedDateStart) &&
                    new Date(updatedRow.endDate) <= new Date(selectedDateStop)
                  ) {
                    updatedRow.pace = pace(
                      updatedRow,
                      updatedRow.startDate,
                      updatedRow.endDate,
                      updatedRow.budget,
                      updatedRow.cost || 0
                    );

                    updatedRow.calcDailyBudget = calcDailyBudget(
                      updatedRow.budget,
                      (updatedRow.cost || 0) - (updatedRow.costToday || 0),
                      // updatedRow.numberOfDays,
                      updatedRow.numberOfDaysLeft
                    );

                    const numberOfDaysLeftYesterday =
                      differenceInDaysFloor(new Date(), updatedRow.endDate) + 3;

                    updatedRow.numberOfDaysLeftYesterday =
                      numberOfDaysLeftYesterday > 0
                        ? numberOfDaysLeftYesterday
                        : 0;

                    updatedRow.calcDailyBudgetYesterday = calcDailyBudget(
                      updatedRow.budget,
                      (updatedRow.cost || 0) -
                        (updatedRow.costToday || 0) -
                        (updatedRow.costYesterday || 0),
                      // updatedRow.numberOfDays,
                      updatedRow.numberOfDaysLeftYesterday
                    );
                    // updatedRow.calcDailyBudgetYesterday =
                    //   (updatedRow.budget -
                    //     (updatedRow.cost || 0) -
                    //     (updatedRow.costToday || 0) -
                    //     (updatedRow.costYesterday || 0)) /
                    //   updatedRow.numberOfDaysLeftYesterday;
                  } else if (
                    updatedRow.budget &&
                    updatedRow.startDate &&
                    updatedRow.endDate
                  ) {
                    updatedRow.pace = updatedRow.budget ? '?' : '';
                    updatedRow.calcDailyBudget = '?';
                  }
                } catch (error) {}

                updatedListOfData.splice(rowIndex, 1, updatedRow);
                updateParentChildRows(updatedRow);
                setListOfData(updatedListOfData);
                break;

              default:
                setUpdateAccountId(accountId);
                setRerender(rerender + 1);
                return '';
            }

            break;
          default:
            setUpdateAccountId(accountId);
            setRerender(rerender + 1);
            return '';
        }
      } else {
        // console.log('Roitable>updateOne>false');
        // console.log('Roitable>updateOne>data: ', data);
        setUpdateAccountId(accountId);
        setRerender(rerender + 1);
        return '';
      }
    } catch (error) {}
  };

  // #################################################################
  // #################################################################
  // #################################################################

  const differenceInDays = (start, stop) => {
    var date1 = new Date(start);
    var date2 = new Date(stop);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days;
  };

  const differenceInDaysFloor = (start, stop) => {
    // var date1 = new Date(start);
    // var date2 = new Date(stop);
    // var Difference_In_Time = date2.getTime() - date1.getTime();
    // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(start);
    const secondDate = new Date(stop);

    const diffDays = Math.floor((secondDate - firstDate) / oneDay);

    return diffDays;
  };

  const pace = (item, start, stop, budget, totalCost) => {
    // console.log('start: ', start);
    var date1 = new Date(start);
    var date2 = new Date(stop);
    date2.setHours(23, 59, 59, 0);
    var total_difference_in_time = date2.getTime() - date1.getTime();
    var totalNumberOfDays = total_difference_in_time / (1000 * 3600 * 24);

    var date1_ = new Date(start);
    var date2_ = new Date();

    if (['cm360', 'dv360'].includes(item.documentType)) {
      // console.log('item.documentType123: ', item.documentType);
      date2_.setDate(date2_.getDate() - 1);
    }
    var time_since_start = date2_.getTime() - date1_.getTime();
    var days_since_start = time_since_start / (1000 * 3600 * 24);

    const unitBudget = budget / total_difference_in_time;

    const should_have_spent =
      unitBudget *
      (time_since_start >= total_difference_in_time
        ? total_difference_in_time
        : time_since_start);
    const pace = totalCost / should_have_spent;

    // console.log('Math.round(pace * 100): ', Math.round(pace * 100));
    return Math.round(pace * 100);
    // return Math.round((pace * 100 + Number.EPSILON) * 100) / 100;
  };

  const numberOfDaysLeft = (today, endDate) => {
    const numDaysLeft =
      differenceInDays(new Date().toISOString().split('T')[0], endDate) + 1;
    return numDaysLeft > 0 ? numDaysLeft : 0;
  };

  const calcDailyBudget = (
    budget,
    totalCost,
    // numberOfDays,
    numberOfDaysLeft
  ) => {
    if (!budget || budget <= 0) {
      return '';
    }

    const cost = totalCost ? totalCost : null;
    // const daysLeft =
    //   numberOfDaysLeft > numberOfDays ? numberOfDays : numberOfDaysLeft;

    if (numberOfDaysLeft <= 0) {
      return '';
    }
    const dailyBudgetLeft = (budget - cost) / numberOfDaysLeft;

    return dailyBudgetLeft;
  };

  const rowCalculations = (supRow, subRow) => {
    const warningMessages = [];

    let highestWarningLevel = 0;

    let warningLevel = 0;
    const fieldsToBeManuallyCalculated = [];
    const fieldNotToBeManuallyCalculated = [];

    const compiledExpressions = {};
    // console.log('subRow65: ', subRow);
    for (let field of allEvalFieldsExceptTotal) {
      // if (subRow[field + 'Formula']) {
      // console.log("subRow[field + 'Formula']: ", subRow[field + 'Formula']);
      let expression = '0';

      const expressionField = subRow[field + 'Formula'] || '';

      if (expressionField !== '=' + field && expressionField[0] === '=') {
        try {
          compiledExpressions[field] = compile(expressionField.substring(1));
        } catch (error) {}
      } else {
        compiledExpressions[field] = false;
      }
    }

    if (!subRow || (!supRow && subRow.type !== 'account')) {
      return;
    } else {
      // console.log('no supRow: ', supRow);
    }

    // add info for parent gantt bars

    if (subRow.ganttBarDisplaySetting === 'CHILDREN') {
      supRow.childRows = [...supRow.childRows, ...subRow.childRows];
    } else {
      const cleanedSubRow = {};
      for (let key in subRow) {
        if (
          ![
            'action01googleanalytics4data',
            'action02googleanalytics4data',
            'action03googleanalytics4data',
            'totalAction01googleanalytics4data',
            'totalAction02googleanalytics4data',
            'totalAction03googleanalytics4data',
            'facebookadsadsets',
            'snapchatadscampaigndata',
            'tiktokadscampaigndata',
            'googleadsadgroups',
            'googleadsadgroupconversions',
            'cm360basic',
            'cm360conversions',
            'dv360basic',
            'dv360conversions',
            'dv360lineiteminfo',
            'snapchatadsadsquadinfo',
            'tiktokadsadgroupinfo',
            'adformcampaigninfo',
            'adformbasicdata',
            'nexusDocuments',
            'childRows',
            'total_list_of_propagation_data',
            'total_list_of_aggregated_propagation_data',
            'total_list_of_propagation_data_row'
          ].includes(key) &&
          !key.includes('Sparkline')
        ) {
          cleanedSubRow[key] = subRow[key];
        }
      }
      supRow.childRows.push(cleanedSubRow);
    }

    // console.log('supRow: ', supRow);
    supRow.childDocumentIds = [
      ...supRow.childDocumentIds,
      subRow._id,
      ...subRow.childDocumentIds
    ];

    // console.log('supRow.childDocumentIds: ', supRow.childDocumentIds);

    const snackbarWarnings = [];

    // ################################# handel total data ##################################################################

    // // ################################# manual data entry ##################################################################

    // ######################################## handle GA4 actions #################################################################

    const googleAnalytics4PropagationData = [];

    if (
      subRow?.action01?.dataSource === 'googleAnalytics4' &&
      subRow.action01googleanalytics4data
    ) {
      for (let ga4Object of subRow.action01googleanalytics4data) {
        googleAnalytics4PropagationData.push({
          eventName: ga4Object.eventName,
          source: ga4Object.source,
          date_start: ga4Object.date,
          action01Count: ga4Object.eventCount,
          action01Value: ga4Object.eventValue
        });
      }
    }

    if (
      subRow?.action02?.dataSource === 'googleAnalytics4' &&
      subRow.action02googleanalytics4data
    ) {
      for (let ga4Object of subRow.action02googleanalytics4data) {
        googleAnalytics4PropagationData.push({
          eventName: ga4Object.eventName,
          source: ga4Object.source,
          date_start: ga4Object.date,
          action02Count: ga4Object.eventCount,
          action02Value: ga4Object.eventValue
        });
        // console.log('ga4Object: ', ga4Object);
      }
    }

    if (
      subRow?.action03?.dataSource === 'googleAnalytics4' &&
      subRow.action03googleanalytics4data
    ) {
      for (let ga4Object of subRow.action03googleanalytics4data) {
        googleAnalytics4PropagationData.push({
          eventName: ga4Object.eventName,
          source: ga4Object.source,
          date_start: ga4Object.date,
          action03Count: ga4Object.eventCount,
          action03Value: ga4Object.eventValue
        });
        // console.log('ga4Object: ', ga4Object);
      }
    }

    if (
      ![
        'account',
        'folder',
        'facebook_ads_ad_set',
        'google_ads_ad_group',
        'dv360_line_item'
      ].includes(subRow.type)
    ) {
      subRow.total_list_of_propagation_data = [
        ...subRow.total_list_of_propagation_data,
        ...googleAnalytics4PropagationData
      ];
    }

    // #################################################### testcalc #########################################################################

    for (let formulaField of allEvalFields) {
      // let regExpression =
      //   '([0-9]+)[+]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[+]{1}(.+)([0-9]+)|([0-9]+)[-]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[-]{1}(.+)([0-9]+)|^[=]*[0-9]+$';

      let regExpression =
        '([0-9]+)[+]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[+]{1}(.+)([0-9]+)|([0-9]+)[-]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[-]{1}(.+)([0-9]+)|^[=]*[0-9/*+-.]+$';
      // '([0-9]+)[+]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[+]{1}(.+)([0-9]+)|([0-9]+)[-]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[-]{1}(.+)([0-9]+)|^[=]*[0-9]+$';

      const regExName = new RegExp(regExpression);
      const regExNameResult = regExName.exec(subRow[formulaField + 'Formula']);

      if (regExNameResult) {
        fieldsToBeManuallyCalculated.push(formulaField);
      } else {
        fieldNotToBeManuallyCalculated.push(formulaField);
      }
    }

    const dataObject = {};
    for (let field of allEvalFields) {
      dataObject[field] = null;
    }

    // for (let date of range2) {
    const dailyDataObject = {};
    for (let field of allEvalFields) {
      dailyDataObject[field] = null;
    }

    for (let object of subRow.total_list_of_aggregated_propagation_data) {
      for (let field of aggregatedFields) {
        console.log('1234field: ', field);
        console.log('1234object[field]: ', object[field]);
        dataObject[field] = (dataObject[field] || 0) + (object[field] || 0);
        console.log('1234row 2877>dataObject: ', dataObject);
      }
    }

    for (let object of subRow.total_list_of_propagation_data) {
      for (let field of allEvalFields) {
        dailyDataObject[field] =
          dailyDataObject[field] + (object[field] || null);
      }

      // console.log('object: ', object);
    }

    for (let field of [...allEvalFieldsExceptTotal]) {
      dataObject[field] = dataObject[field] + (dailyDataObject[field] || null);
    }

    let dStartDStopUpUntillTodayRate = 0;
    let dStart = null;
    let dStop = null;
    let dStopUpUntillToday = null;

    const shareOfTodaysHours = new Date().getHours() / 24;
    // console.log('fieldsToBeManuallyCalculated: ', fieldsToBeManuallyCalculated);
    if (subRow.startDate && subRow.endDate) {
      // console.log('manual data entry calculations');
      subRow.numberOfDays =
        differenceInDays(subRow.startDate, subRow.endDate) + 1;
      // console.log('subRow.numberOfDays: ', subRow.numberOfDays);
      subRow.numberOfDaysLeft = numberOfDaysLeft(
        subRow.startDate,
        subRow.endDate
      );

      if (fieldsToBeManuallyCalculated.length > 0) {
        dStart =
          new Date(subRow.startDate) >= new Date(selectedDateStart)
            ? subRow.startDate
            : selectedDateStart;
        dStop =
          new Date(subRow.endDate) <= new Date(selectedDateStop)
            ? subRow.endDate
            : selectedDateStop;

        dStopUpUntillToday = new Date(dStop) > new Date() ? new Date() : dStop;

        const dStartDStopUpUntillTodayRange = getDates(
          dStart,
          dStopUpUntillToday
        );

        // const dStartDStopRange = getDates(dStart, dStop);

        // let dStartDStopRate = differenceInDays(dStart, dStop) + 1;

        // console.log('dStartDStopRange: ', dStartDStopRange.length);

        for (let date of dStartDStopUpUntillTodayRange) {
          if (
            new Date(date).toISOString().split('T')[0] ===
            new Date().toISOString().split('T')[0]
          ) {
            // console.log('TODAY!');
            // const shareOfTodaysHours = new Date().getHours() / 24;
            dStartDStopUpUntillTodayRate =
              dStartDStopUpUntillTodayRate + shareOfTodaysHours;
          } else {
            dStartDStopUpUntillTodayRate = dStartDStopUpUntillTodayRate + 1;
          }
        }

        subRow.campaign_days_within_date_selected =
          differenceInDays(dStart, dStop) + 1;
      }
    }

    const generateScope = (formulaField) => {
      const newObj = {};
      for (let field of allEvalFieldsExceptTotal) {
        newObj[field] = dataObject[field] || 0;
      }

      for (let field of fieldsToBeManuallyCalculated) {
        if (!plannedEvalFields.includes(field)) {
          newObj[field] =
            ((dataObject[field] || 0) * dStartDStopUpUntillTodayRate) /
            subRow.numberOfDays;
        } else {
          // do something here
          newObj[field] =
            ((dataObject[field] || 0) *
              subRow.campaign_days_within_date_selected) /
            subRow.numberOfDays;
        }
      }
      switch (formulaField) {
        // case 'plannedGross':
        // case 'plannedNet':
        // case 'plannedNetNet':
        // case 'plannedCtc':
        // case 'budget':
        //   return {
        //     budget: dataObject.budget || null,
        //     plannedGross: dataObject.plannedGross || null,
        //     plannedNet: dataObject.plannedNet || null,
        //     plannedNetNet: dataObject.plannedNetNet || null,
        //     plannedCtc: dataObject.plannedCtc || null
        //   };
        //   break;

        // case 'plannedTrp':
        // case 'plannedTechFee':
        // case 'plannedAdOpsFee':
        // case 'plannedAdServingCost':
        // case 'plannedFeePercent':

        // case 'cost':
        // case 'universe':
        // case 'trp':
        // case 'spots':
        // case 'impressions':
        // case 'clicks':
        // case 'action01Count':
        // case 'action02Count':
        // case 'action03Count':
        // case 'action01Value':
        // case 'action02Value':
        // case 'action03Value':
        //   return dataObject;
        //   // return {
        //   //   impressions: dataObject.impressions || 0,
        //   //   cost: dataObject.cost || 0,
        //   //   [formulaField]: dataObject[formulaField] || 0
        //   // };
        //   break;
        default:
          return newObj;
      }
    };

    const fieldsNotDoneCalculating = [...allEvalFieldsExceptTotal];

    const calcWarnings2 = { stackOverFlow: false, formulaErrors: [] };

    const calculatedFields = dataObject;

    calcFields(
      compiledExpressions,
      allEvalFieldsExceptTotal,
      calculatedFields,
      fieldsNotDoneCalculating,
      generateScope,
      subRow,
      [],
      calcWarnings2
    );

    for (let field of fieldNotToBeManuallyCalculated) {
      subRow[field] = calculatedFields[field];
      // supRow[field+'Raw'] = supRow[field] + subRow[field];
    }

    if (
      subRow.startDate &&
      subRow.endDate &&
      fieldsToBeManuallyCalculated.length > 0
    ) {
      for (let fieldToBeManuallyCalculated of fieldsToBeManuallyCalculated) {
        subRow[fieldToBeManuallyCalculated + 'Raw'] =
          calculatedFields[fieldToBeManuallyCalculated];

        let rate = 0;

        // console.log('plannedEvalFields: ', plannedEvalFields);
        if (plannedEvalFields.includes(fieldToBeManuallyCalculated)) {
          rate = subRow.campaign_days_within_date_selected; //dStartDStopRate;
          // console.log('rate: ', rate);
        } else {
          rate = dStartDStopUpUntillTodayRate;
          // console.log('rate: ', rate);
        }
        subRow[fieldToBeManuallyCalculated] =
          (calculatedFields[fieldToBeManuallyCalculated] /
            subRow.numberOfDays) *
          rate;
        // supRow[fieldToBeManuallyCalculated] =
        //   (calculatedFields[fieldToBeManuallyCalculated] /
        //     subRow.numberOfDays) *
        //   rate;
      }

      // for (let field of fieldNotToBeManuallyCalculated) {
      //   subRow[field] = calculatedFields[field];
      //   // supRow[field+'Raw'] = supRow[field] + subRow[field];
      // }
      // subRow.costRaw = calculatedFields.cost;
      // subRow.cost =
      //   (calculatedFields.cost / subRow.numberOfDays) * dStartDStopRate;
      //   supRow.cost =
      //   (calculatedFields.cost / subRow.numberOfDays) * dStartDStopRate;

      // subRow.impressionsRaw = calculatedFields.impressions;
      // subRow.impressions =
      //   (calculatedFields.impressions / subRow.numberOfDays) * dStartDStopRate;

      // supRow.impressions =
      //   (calculatedFields.impressions / subRow.numberOfDays) * dStartDStopRate;
      // }

      // ################################# manual data entry ##################################################################

      // if (subRow.startDate && subRow.endDate) {

      const dataPerDay = {};

      for (let field of fieldsToBeManuallyCalculated) {
        dataPerDay[field] = subRow[field]
          ? subRow[field + 'Raw'] / subRow.numberOfDays
          : null;
      }

      const tempDateRange = getDates(dStart, dStop);
      const tempDateRangeUpUntillToday = getDates(dStart, dStopUpUntillToday);
      const dailyData = [];

      // up untill today
      for (let date of tempDateRangeUpUntillToday) {
        // console.log('monitor>date: ', date);

        const dailyObject = {
          date_start: date.getTime()
        };

        for (let field of allEvalFieldsExceptTotal) {
          dailyObject[field] = null;
        }

        if (
          new Date(date).toISOString().split('T')[0] ===
          new Date().toISOString().split('T')[0]
        ) {
          // console.log('TODAY!');
          const shareOfTodaysHours = new Date().getHours() / 24;

          for (let field of fieldsToBeManuallyCalculated) {
            if ([...sumEvalFields, ...nonSumEvalFields].includes(field)) {
              dailyObject[field] = dataPerDay[field] * shareOfTodaysHours;
            }
          }
        } else {
          for (let field of fieldsToBeManuallyCalculated) {
            if ([...sumEvalFields, ...nonSumEvalFields].includes(field)) {
              dailyObject[field] = dataPerDay[field];
            }
          }
        }

        dailyData.push(dailyObject);
      }

      // all days (planned and budget)

      for (let date of tempDateRange) {
        // console.log('monitor>date: ', date);

        const dailyObject = {
          date_start: date.getTime()
        };

        for (let field of allEvalFieldsExceptTotal) {
          dailyObject[field] = null;
        }

        for (let field of fieldsToBeManuallyCalculated) {
          if (plannedEvalFields.includes(field)) {
            dailyObject[field] = dataPerDay[field];
          }
        }

        dailyData.push(dailyObject);
      }

      subRow.total_list_of_propagation_data = [
        ...(subRow.total_list_of_propagation_data || []).map((row) => {
          const newRow = { ...row };
          for (let field of fieldsToBeManuallyCalculated) {
            newRow[field] = null;
          }
          return newRow;
        }),
        ...dailyData
      ];
      subRow.total_list_of_propagation_data_row = [
        ...subRow.total_list_of_propagation_data_row,
        ...dailyData
      ];
    } else if (fieldsToBeManuallyCalculated.length > 0 && !subRow.template) {
      let warningMessage = 'WARNING MISSING START DATE OR END DATE!';
      warningLevel = 5;
      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      // warningMessage = `WARNING CIRCULAR DEPENDENCIES! DO NOT TRUST THE DATA!`;

      warningMessages.push({
        warningLevel,
        message: <span style={{ color: 'red' }}>{warningMessage}</span>,
        rawMessage: warningMessage
      });

      snackbarWarnings.push({
        message: `${warningMessage} --> ${subRow.path}`,
        variant: 'error',
        warningLevel
      });
    }

    // ############################ handle metrics that are not TOTAL ##############################################################

    // supRow.total_list_of_propagation_data = [
    //   ...supRow.total_list_of_propagation_data,
    //   ...subRow.total_list_of_propagation_data
    //   // ...googleAnalytics4PropagationData
    // ];
    let propDataToCalculate = [];

    let totalDataArray = [];

    // propDataToCalculate = [...subRow.total_list_of_propagation_data];
    propDataToCalculate = subRow.total_list_of_propagation_data.map((obj) => ({
      ...obj
    }));

    // ###############################################################################################################

    const generateScope1 = (formulaField, obj) => {
      const newObj = {};
      for (let field of allEvalFieldsExceptTotal) {
        newObj[field] = obj[field] || 0;
      }
      switch (formulaField) {
        // case 'plannedGross':
        // case 'plannedNet':
        // case 'plannedNetNet':
        // case 'plannedCtc':
        // case 'budget':
        //   return {
        //     budget: obj.budget || null,
        //     plannedGross: obj.plannedGross || null,
        //     plannedNet: obj.plannedNet || null,
        //     plannedNetNet: obj.plannedNetNet || null,
        //     plannedCtc: obj.plannedCtc || null,
        //     [formulaField + 'Sum']: obj[formulaField + 'Sum'] || 0
        //   };
        //   break;

        // case 'plannedTrp':
        // case 'plannedTechFee':
        // case 'plannedAdOpsFee':
        // case 'plannedAdServingCost':
        // case 'plannedFeePercent':

        // case 'cost':
        // case 'universe':
        // case 'trp':
        // case 'spots':
        // case 'impressions':
        // case 'clicks':
        // case 'action01Count':
        // case 'action02Count':
        // case 'action03Count':
        // case 'action01Value':
        // case 'action02Value':
        // case 'action03Value':
        //   return {
        //     impressions: obj.impressions || 0,
        //     // costSum: subRow.costSum,
        //     cost: obj.cost || 0,
        //     [formulaField]: obj[formulaField] || 0
        //     // [formulaField + 'Sum']: obj[formulaField + 'Sum'] || 0
        //   };
        //   break;
        default:
          // return {};
          return newObj;
      }
    };

    const calculatedPropList = [];
    const calcWarnings = { stackOverFlow: false, formulaErrors: [] };
    // console.log('propDataToCalculate: ', propDataToCalculate);
    const fieldsNotDoneCalculating3 = [...allEvalFieldsExceptTotal];
    calcFields2(
      propDataToCalculate,
      calculatedPropList,
      compiledExpressions,
      allEvalFieldsExceptTotal,
      calculatedFields,
      fieldsNotDoneCalculating3,
      generateScope1,
      subRow,
      fieldsToBeManuallyCalculated,
      calcWarnings
    );

    // for (let rowObj of propDataToCalculate) {
    //   const fieldsNotDoneCalculating3 = [...allEvalFieldsExceptTotal];
    //   const calculatedFields = { ...rowObj };

    //   calcFields(
    //     compiledExpressions,
    //     allEvalFieldsExceptTotal,
    //     calculatedFields,
    //     fieldsNotDoneCalculating3,
    //     generateScope1,
    //     subRow,
    //     fieldsToBeManuallyCalculated,
    //     calcWarnings
    //   );

    //   let addToPropList = false;

    //   for (let field of allEvalFields) {
    //     if (calculatedFields[field]) {
    //       addToPropList = true;
    //     }
    //   }

    //   console.log('addToPropList: ', addToPropList);

    //   if (addToPropList) {
    //     calculatedPropList.push(calculatedFields);
    //     // calculatedPropList.push({ ...rowObj, ...calculatedFields });
    //   }
    // }
    if (calcWarnings.stackOverFlow || calcWarnings2.stackOverFlow) {
      let warningMessage =
        'WARNING CIRCULAR DEPENDENCIES! DO NOT TRUST THE DATA!';
      warningLevel = 5;
      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      warningMessage = `WARNING CIRCULAR DEPENDENCIES! DO NOT TRUST THE DATA!`;

      warningMessages.push({
        warningLevel,
        message: <span style={{ color: 'red' }}>{warningMessage}</span>,
        rawMessage: warningMessage
      });

      snackbarWarnings.push({
        message: `${warningMessage} --> ${subRow.path}`,
        variant: 'error',
        warningLevel
      });
    }

    // console.log('calcWarnings: ', calcWarnings);
    if (calcWarnings2?.formulaErrors?.length > 0) {
      console.log('WAAAARNING');
      let warningMessage = 'Formula error:';
      for (let errorField of calcWarnings2?.formulaErrors || []) {
        warningMessage = warningMessage + ' ' + errorField;
      }
      warningLevel = 5;
      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      warningMessages.push({
        warningLevel,
        message: <span style={{ color: 'red' }}>{warningMessage}</span>,
        rawMessage: warningMessage
      });

      snackbarWarnings.push({
        message: `${warningMessage} --> ${subRow.path}`,
        variant: 'error',
        warningLevel
      });
    }

    // ###############################################################################################################
    const aggregatedDailyDataToPropagate = [];

    const beginningOfToday = new Date();
    beginningOfToday.setHours(0, 0, 0, 0);

    const todayAsString = new Date().toISOString().split('T')[0];

    // shareOfTodaysHours

    for (let date of range2) {
      const dateAsString = date.toISOString().split('T')[0];
      const dailyDataObject = {
        date_start: Math.floor(new Date(date).getTime())
      };

      for (let field of allEvalFields) {
        dailyDataObject[field] = null;
      }

      // for (let object of subRow.total_list_of_propagation_data) {
      for (let object of propDataToCalculate) {
        // console.log('object: ', object);

        if (
          new Date(parseInt(object.date_start)).toISOString().split('T')[0] ===
          dateAsString
        ) {
          for (let field of allEvalFieldsExceptTotal) {
            dailyDataObject[field] =
              dailyDataObject[field] + (object[field] || null);
          }

          if (date < beginningOfToday) {
            subRow.budgetUntillNow = subRow.budgetUntillNow + object.budget;
          } else if (todayAsString === dateAsString) {
            subRow.budgetUntillNow =
              subRow.budgetUntillNow + object.budget * shareOfTodaysHours;
          }
        }
      }

      for (let field of allEvalFieldsExceptTotal) {
        // try {
        subRow[field + 'Sparkline'].push(dailyDataObject[field] || 0);
        // } catch (error) {
        //   console.log('error>field: ', field);
        // }
        // subRow[field + 'Sparkline'].push(dailyDataObject[field] || 0);
      }

      aggregatedDailyDataToPropagate.push(dailyDataObject);
    }

    subRow.total_list_of_propagation_data = aggregatedDailyDataToPropagate;

    if (!subRow.template) {
      supRow.total_list_of_propagation_data = [
        ...supRow.total_list_of_propagation_data,
        // ...aggregatedDailyDataToPropagate
        ...subRow.total_list_of_propagation_data

        // ...googleAnalytics4PropagationData
      ];

      supRow.total_list_of_aggregated_propagation_data = [
        ...supRow.total_list_of_aggregated_propagation_data,
        ...subRow.total_list_of_aggregated_propagation_data
      ];
    }

    const yesterday = new Date();

    yesterday.setDate(yesterday.getDate() - 1);
    for (let object of subRow.total_list_of_propagation_data) {
      if (
        new Date(+object.date_start).toISOString().split('T')[0] ===
        yesterday.toISOString().split('T')[0]
      ) {
        // console.log('Yesterday!');
        subRow.costYesterday = (subRow.costYesterday || null) + object.cost;
        subRow.impressionsYesterday =
          (subRow.impressionsYesterday || null) + object.impressions;

        subRow.budgetYesterday =
          (subRow.budgetYesterday || null) + object.budget;
      }
      if (
        new Date(+object.date_start).toISOString().split('T')[0] ===
        new Date().toISOString().split('T')[0]
      ) {
        // console.log('TODAY!');
        subRow.costToday = (subRow.costToday || null) + object.cost;
        subRow.impressionsToday =
          (subRow.impressionsToday || null) + object.impressions;

        subRow.budgetToday = (subRow.budgetToday || null) + object.budget;
      }
    }
    // }

    // }

    // #############################################################################################################################

    // if (subRow.budget && subRow.numberOfDays) {
    //   subRow.averageDailyBudget = subRow.budget / subRow.numberOfDays;
    // }

    if (!subRow.template) {
      for (let itemKey of allEvalFieldsExceptTotal) {
        supRow[itemKey + 'SumChild'] =
          supRow[itemKey + 'SumChild'] +
          (subRow[itemKey] && typeof subRow[itemKey] === 'number'
            ? subRow[itemKey]
            : null);

        // supRow[itemKey] = supRow[itemKey + 'SumChild'];
      }
    }

    if (
      // new Date(subRow.startDate) >= new Date(selectedDateStart) &&
      // new Date(subRow.endDate) <= new Date(selectedDateStop) &&
      ![
        'account',
        'globalTasks',
        'task',
        'subTask',
        'taxonomy',
        'facebook_ads_ad_set',
        'google_ads_ad_group',
        'dv360_line_item'
      ].includes(subRow.type)
    ) {
      subRow.daysSinceStatusUpdate =
        subRow?.statusUpdatedAt &&
        differenceInDaysFloor(new Date(+subRow.statusUpdatedAt), new Date());

      subRow.daysSinceCheckedAt =
        subRow?.checkedAt &&
        differenceInDaysFloor(new Date(+subRow.checkedAt), new Date());

      subRow.linearPace =
        subRow.budget &&
        subRow.cost &&
        subRow.startDate &&
        subRow.endDate &&
        pace(
          subRow,
          subRow.startDate,
          subRow.endDate,
          subRow.budget,
          subRow.cost
        );

      subRow.pace =
        subRow.budgetUntillNow &&
        subRow.cost &&
        Math.round((subRow.cost / subRow.budgetUntillNow) * 100);

      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop)
      ) {
        subRow.calcDailyBudget =
          subRow.budget &&
          // subRow.cost &&
          calcDailyBudget(
            subRow.budget,
            subRow.cost - (subRow.costToday || 0),
            // subRow.numberOfDays,
            subRow.numberOfDaysLeft
          );

        const numberOfDaysLeftYesterday =
          differenceInDaysFloor(startOfToday, subRow.endDate) + 2;

        subRow.numberOfDaysLeftYesterday =
          numberOfDaysLeftYesterday > 0 ? numberOfDaysLeftYesterday : 0;

        subRow.calcDailyBudgetYesterday = calcDailyBudget(
          subRow.budget,
          (subRow.cost || 0) -
            (subRow.costToday || 0) -
            (subRow.costYesterday || 0),
          // updatedRow.numberOfDays,
          // subRow.numberOfDaysLeft + 1
          subRow.numberOfDaysLeftYesterday
        );
        // subRow.calcDailyBudgetYesterday =
        //   (subRow.budget -
        //     (subRow.cost || 0) -
        //     (subRow.costToday || 0) -
        //     (subRow.costYesterday || 0)) /
        //   subRow.numberOfDaysLeftYesterday;
        // console.log(
        //   'subRow.numberOfDaysLeftYesterday: ',
        //   subRow.numberOfDaysLeftYesterday
        // );

        subRow.calcPaceYesterday =
          subRow?.calcDailyBudgetYesterday && subRow?.costYesterday
            ? Math.round(
                (subRow?.costYesterday / subRow?.calcDailyBudgetYesterday) * 100
              )
            : '';

        subRow.paceYesterday =
          subRow?.budgetYesterday && subRow?.costYesterday
            ? Math.round(
                (subRow?.costYesterday / subRow?.budgetYesterday) * 100
              )
            : '';

        // if (['63920522554c8cb8eee6671e'].includes(subRow._id)) {
        //   console.log(
        //     'test55>subRow.calcDailyBudgetYesterday: ',
        //     subRow.calcDailyBudgetYesterday
        //   );

        //   console.log(
        //     'test55>subRow.subRow.subRow.numberOfDaysLeftYesterday: ',
        //     subRow.numberOfDaysLeftYesterday
        //   );
        // }
        // console.log(
        //   'test 77>subRow.numberOfDaysLeftYesterday: ',
        //   subRow.numberOfDaysLeftYesterday
        // );
        if (
          subRow.numberOfDaysLeftYesterday < 4 &&
          subRow.endDate &&
          new Date(subRow.endDate).setHours(23, 59, 59, 59) >
            new Date().setHours(23, 59, 59, 59) &&
          ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status)
        ) {
          const costUntillYesterday =
            (subRow.cost || 0) -
            (subRow.costToday || 0) -
            (subRow.costYesterday || 0);

          const predictedEndCost =
            costUntillYesterday +
            subRow.costYesterday * subRow.numberOfDaysLeftYesterday;

          // console.log('test 77>predictedEndCost: ', predictedEndCost);
          // console.log('test 77>subRow.budget: ', subRow.budget);
          if (subRow.budget && predictedEndCost > subRow.budget + 100) {
            const roundedCostYesterday = (
              Math.round((subRow.costYesterday + Number.EPSILON) * 100) / 100
            ).toLocaleString();
            const predictedOverSpend = predictedEndCost - subRow.budget;
            const roundedPredictedOverSpend = (
              Math.round((predictedOverSpend + Number.EPSILON) * 100) / 100
            ).toLocaleString();
            let warningMessage = `If you continue to spend like yesteday (${roundedCostYesterday}) you will overspend by ${roundedPredictedOverSpend}`;
            warningLevel = 4;
            highestWarningLevel =
              highestWarningLevel < warningLevel
                ? warningLevel
                : highestWarningLevel;

            warningMessages.push({
              warningLevel,
              message: <span style={{ color: 'red' }}>{warningMessage}</span>,
              rawMessage: warningMessage
            });
          }

          if (subRow.budget && predictedEndCost + 100 < subRow.budget) {
            const roundedCostYesterday = (
              Math.round((subRow.costYesterday + Number.EPSILON) * 100) / 100
            ).toLocaleString();
            const predictedUnderSpend = subRow.budget - predictedEndCost;
            const roundedPredictedUnderSpend = (
              Math.round((predictedUnderSpend + Number.EPSILON) * 100) / 100
            ).toLocaleString();
            let warningMessage = `If you continue to spend like yesteday (${roundedCostYesterday}) you will underspend by ${roundedPredictedUnderSpend}`;
            warningLevel = 4;
            highestWarningLevel =
              highestWarningLevel < warningLevel
                ? warningLevel
                : highestWarningLevel;

            warningMessages.push({
              warningLevel,
              message: <span style={{ color: 'blue' }}>{warningMessage}</span>,
              rawMessage: warningMessage
            });
          }
        }
      }
    } else if (
      ![
        'account',
        'globalTasks',
        'task',
        'subTask',
        'taxonomy',
        'facebook_ads_ad_set',
        'google_ads_ad_group',
        'dv360_line_item'
      ].includes(subRow.type)
    ) {
      for (let field of allEvalFieldsExceptTotal) {
        subRow[field + 'SumChild'] = '?';
      }
      // subRow.pace =
      //   subRow.budget && subRow.startDate && subRow.endDate ? '?' : '';
      subRow.calcDailyBudget =
        subRow.budget && subRow.startDate && subRow.endDate ? '?' : '';
    } else {
      for (let field of allEvalFieldsExceptTotal) {
        subRow[field + 'SumChild'] = '';
      }
      subRow.pace = '';
      subRow.calcDailyBudget = '';
    }

    // ########## warnings #############

    // const warningMessages = [];

    // let highestWarningLevel = 0;

    // let warningLevel = 0;
    // ######### start date passed and status not active ###########
    // console.log('subRow.type: ', subRow.type);
    if (
      ([
        'folder',
        'task',
        'subTask',
        'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(subRow.type) ||
        subRow.isSet) &&
      !subRow.isSetChild
    ) {
      if (!['folder', 'task', 'taxonomy'].includes(subRow.type)) {
        if (
          subRow.impressionsAfterEndDate &&
          !subRow.allowImpressionsAfterEndDate
        ) {
          let warningMessage = 'IMPRESSIONS AFTER END DATE';
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessage = `IMPRESSIONS AFTER END DATE`;

          warningMessages.push({
            warningLevel,
            message: <span style={{ color: 'red' }}>{warningMessage}</span>,
            rawMessage: warningMessage
          });

          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'error',
          //   warningLevel
          // });
        }

        if (
          subRow.impressionsBeforeStartDate &&
          !subRow.allowImpressionsBeforeStartDate
        ) {
          let warningMessage = 'IMPRESSIONS AFTER END DATE';
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessage = `IMPRESSIONS AFTER END DATE`;

          warningMessages.push({
            warningLevel,
            message: <span style={{ color: 'red' }}>{warningMessage}</span>,
            rawMessage: warningMessage
          });

          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'error',
          //   warningLevel
          // });
        }
      }

      // const roundedCalcDailyBudget = Math.round(subRow.calcDailyBudget);
      const roundedCalcDailyBudget = (
        Math.round((subRow.calcDailyBudget + Number.EPSILON) * 100) / 100
      ).toLocaleString();

      const roundedCalcDailyBudgetYesterday = (
        Math.round((subRow.calcDailyBudgetYesterday + Number.EPSILON) * 100) /
        100
      ).toLocaleString();

      const roundedBudgetYesterday = (
        Math.round((subRow.budgetYesterday + Number.EPSILON) * 100) / 100
      ).toLocaleString();

      const roundedBudgetUntillNow = (
        Math.round((subRow.budgetUntillNow + Number.EPSILON) * 100) / 100
      ).toLocaleString();

      const roundedCostYesterday = (
        Math.round((subRow.costYesterday + Number.EPSILON) * 100) / 100
      ).toLocaleString();
      const roundedCost = (
        Math.round((subRow.cost + Number.EPSILON) * 100) / 100
      ).toLocaleString();

      // Math.round(subRow.budgetUntillNow);
      // ############# PACE ####################
      if (
        !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
        subRow.pace &&
        subRow.pace > 120
      ) {
        let warningMessage = `Overpace! ${subRow.pace}% (${roundedCost} / ${roundedBudgetUntillNow})`;
        warningLevel = 4;
        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;

        warningMessages.push({
          warningLevel,
          message: <span style={{ color: 'red' }}>{warningMessage}</span>,
          rawMessage: warningMessage
        });
      }

      if (
        !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
        subRow.pace &&
        subRow.pace > 200
      ) {
        let warningMessage = `OVERPACE! ${subRow.pace}% (${roundedCost} / ${roundedBudgetUntillNow})`;

        // warningMessages.push({
        //   message: <span style={{ color: 'red' }}>{warningMessage}</span>
        // });

        // enqueueSnackbar(
        //   `${warningMessage} --> ${subRow.path}`,
        //   {
        //     variant: 'error',
        //     // persist: true
        //   }
        // );

        snackbarWarnings.push({
          message: `${warningMessage} --> ${subRow.path}`,
          variant: 'error',
          warningLevel
        });
      }

      if (
        ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status) &&
        subRow.cost &&
        subRow.budget &&
        subRow.pace < 80
      ) {
        warningLevel = 4;
        let warningMessage = `Underpace! ${subRow.pace}% (${roundedCost} / ${roundedBudgetUntillNow})`;
        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;
        warningMessages.push({
          message: <span style={{ color: 'blue' }}>{warningMessage}</span>,
          rawMessage: `Underpace! ${subRow.pace}%`
        });
      }

      // ################### CALCULATED PACE YESTERDAY ###########################

      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop) &&
        subRow.budgetFormula !== '=budget'
      ) {
        if (
          !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
          subRow.calcPaceYesterday &&
          subRow.calcPaceYesterday > 120 &&
          subRow.calcDailyBudgetYesterday > 0
        ) {
          let warningMessage = `Overpace yesterday! (calculated) ${subRow.calcPaceYesterday}% (${roundedCostYesterday} / ${roundedCalcDailyBudgetYesterday})`;
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            warningLevel,
            message: <span style={{ color: 'red' }}>{warningMessage}</span>,
            rawMessage: warningMessage
          });
        }

        if (
          !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
          subRow.calcPaceYesterday &&
          subRow.calcPaceYesterday > 200
        ) {
          let warningMessage = `Overpace yesterday! (calculated) ${subRow.calcPaceYesterday}% (${roundedCostYesterday} / ${roundedCalcDailyBudgetYesterday})`;

          // warningMessages.push({
          //   message: <span style={{ color: 'red' }}>{warningMessage}</span>
          // });

          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     variant: 'error',
          //     // persist: true
          //   }
          // );

          snackbarWarnings.push({
            message: `${warningMessage} --> ${subRow.path}`,
            variant: 'error',
            warningLevel
          });
        }

        if (
          ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status) &&
          subRow.costYesterday &&
          subRow.calcDailyBudget &&
          subRow.calcPaceYesterday < 80 &&
          subRow.calcDailyBudgetYesterday > 0
        ) {
          warningLevel = 4;
          let warningMessage = `Underpace Yesterday (calculated) ${subRow.calcPaceYesterday}% (${roundedCostYesterday} / ${roundedCalcDailyBudgetYesterday})`;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;
          warningMessages.push({
            message: <span style={{ color: 'blue' }}>{warningMessage}</span>,
            rawMessage: `Underpace Yesterday (calculated) ${subRow.calcPaceYesterday}% (${roundedCostYesterday} / ${roundedCalcDailyBudgetYesterday})`
          });
        }
      } else {
        if (
          !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
          subRow.paceYesterday &&
          subRow.paceYesterday > 120 &&
          subRow.budgetYesterday > 0
        ) {
          let warningMessage = `Overpace yesterday! ${subRow.paceYesterday}% (${roundedCostYesterday} / ${roundedBudgetYesterday})`;
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            warningLevel,
            message: <span style={{ color: 'red' }}>{warningMessage}</span>,
            rawMessage: warningMessage
          });
        }

        if (
          !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
          subRow.paceYesterday &&
          subRow.paceYesterday > 200
        ) {
          let warningMessage = `Overpace yesterday! ${subRow.paceYesterday}% (${roundedCostYesterday} / ${roundedBudgetYesterday})`;

          // warningMessages.push({
          //   message: <span style={{ color: 'red' }}>{warningMessage}</span>
          // });

          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     variant: 'error',
          //     // persist: true
          //   }
          // );

          snackbarWarnings.push({
            message: `${warningMessage} --> ${subRow.path}`,
            variant: 'error',
            warningLevel
          });
        }

        if (
          ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status) &&
          subRow.costYesterday &&
          subRow.calcDailyBudget &&
          subRow.paceYesterday < 80 &&
          subRow.calcDailyBudgetYesterday > 0
        ) {
          warningLevel = 4;
          let warningMessage = `Underpace Yesterday ${subRow.paceYesterday}% (${roundedCostYesterday} / ${roundedBudgetYesterday})`;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;
          warningMessages.push({
            message: <span style={{ color: 'blue' }}>{warningMessage}</span>,
            rawMessage: `Underpace Yesterday ${subRow.paceYesterday}% (${roundedCostYesterday} / ${roundedBudgetYesterday})`
          });
        }
      }

      // Overcost
      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop) &&
        !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
        subRow.cost &&
        subRow.budget
        //  &&
        // Math.round(subRow.cost) > Math.round(subRow.budget)
      ) {
        if (Math.round(subRow.cost) > Math.round(subRow.budget)) {
          let warningMessage = 'OVERSPEND!';
          warningLevel = 5;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessage = `OVERSPEND! ${subRow.cost.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })} of ${subRow.budget.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}`;

          warningMessages.push({
            warningLevel,
            message: <span style={{ color: 'red' }}>{warningMessage}</span>,
            rawMessage: warningMessage
          });

          snackbarWarnings.push({
            message: `${warningMessage} --> ${subRow.path}`,
            variant: 'error',
            warningLevel
          });

          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     variant: 'error',
          //     // persist: true
          //   }
          // );
        }
      }

      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop)
      ) {
        // // Overcost
        // if (
        //   !['CANCELED',' COMPLETED'].includes(subRow.status) &&
        //   subRow.cost &&
        //   subRow.budget &&
        //   subRow.cost > subRow.budget
        // ) {
        //   let warningMessage = 'OVERSPEND!';
        //     warningMessage = `OVERSPEND! ${subRow.cost.toFixed(2)} of ${
        //       subRow.budget
        //     }`;
        //   warningMessages.push({
        //     message: <span style={{ color: 'red' }}>{warningMessage}</span>
        //   });
        // }
        // // over pace
        // if (
        //   ['ACTIVE', 'DRAFT', 'PLANNED'].includes(subRow.status) &&
        //   (subRow.pace && subRow.pace>1.2)
        // ) {
        //   let warningMessage = 'OVERPACE!';
        //     warningMessage = `OVERPACE! ${subRow.pace}%`;
        //   warningMessages.push({
        //     message: <span style={{ color: 'red' }}>{warningMessage}</span>
        //   });
        // }
      }

      // if ((subRow.startDate && subRow.startDate <= new Date().toISOString().split('T')[0]) && !['ACTIVE', 'PAUSED', 'CANCELED', 'COMPLETED'].includes(subRow.status) ) {
      //   warningMessages.push({
      //     message:
      //       'Start date is today but status is set to "' + subRow.status + '".'
      //   });
      // }

      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop) &&
        !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
        subRow.budget &&
        subRow.budgetSumChild &&
        Math.round(subRow.budgetSumChild) > Math.round(subRow.budget)
      ) {
        let warningMessage = `The sum of the children's budgets exceeds the parent's budget (${(
          Math.round((subRow.budgetSumChild + Number.EPSILON) * 100) / 100
        ).toLocaleString()} / ${(
          Math.round((subRow.budget + Number.EPSILON) * 100) / 100
        ).toLocaleString()})`;

        warningLevel = 4;

        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;

        warningMessages.push({
          warningLevel,
          message: warningMessage,
          rawMessage: warningMessage
        });

        snackbarWarnings.push({
          message: `${warningMessage} --> ${subRow.path}`,
          variant: 'error',
          warningLevel
        });
      }

      var date_start_plus_1 = new Date(subRow.startDate);
      date_start_plus_1.setDate(date_start_plus_1.getDate() + 1);

      if (
        subRow.startDate &&
        new Date(subRow.startDate).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0)
      ) {
        if (
          ![
            'ACTIVE',
            'PAUSED',
            'CANCELED',
            'BUDDYCHECK',
            'DOUBLECHECK',
            'COMPLETED',
            'REMOVED'
          ].includes(subRow.status)
        ) {
          const warningMessage =
            'Start date passed but status is set to "' + subRow.status + '".';
          warningLevel = 2;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            warningLevel,
            message: warningMessage,
            rawMessage: warningMessage
          });

          // if more than 1 day passed since start date
          if (new Date() >= date_start_plus_1) {
            // snackbarWarnings.push({
            //   message: `${warningMessage} --> ${subRow.path}`,
            //   variant: 'standard',
            //   warningLevel
            // });
          }
        }
      }

      if (
        subRow.endDate &&
        new Date(subRow.endDate).setHours(23, 59, 59, 59) <
          new Date().setHours(23, 59, 59, 59)
      ) {
        if (
          !['PAUSED', 'CANCELED', 'COMPLETED', 'REMOVED'].includes(
            subRow.status
          )
        ) {
          const warningMessage =
            'End date passed but status is set to "' + subRow.status + '".';

          warningLevel = 2;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            warningLevel,
            message: warningMessage,
            rawMessage: warningMessage
          });
          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     // variant: 'error',
          //     // persist: true
          //   }
          // );
          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'standard',
          //   warningLevel
          // });
        }
      }

      // ######### day since start > 1 and no cost ###########

      // var date_start_plus_1 = new Date(subRow.startDate);
      // date_start_plus_1.setDate(date_start_plus_1.getDate() + 1);
      if (
        new Date(selectedDateStart) <= new Date(subRow.startDate) &&
        new Date() >= date_start_plus_1 &&
        subRow.budget &&
        !subRow.cost &&
        !['PAUSED', 'CANCELED', 'COMPLETED', 'REMOVED'].includes(subRow.status)
      ) {
        const warningMessage =
          'Start date passed by one day with ' +
          subRow.cost +
          '/' +
          Math.round(subRow.budget) +
          ' cost.';

        warningLevel = 3;
        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;

        warningMessages.push({
          warningLevel,
          message: warningMessage,
          rawMessage: warningMessage
        });

        if (
          [
            // 'folder',
            // 'manualDataEntry',
            'facebook_ads_campaign',
            'google_ads_campaign',
            'cm360_campaign',
            'dv360_insertion_order',
            'snapchat_ads_campaign',
            'tiktok_ads_campaign',
            'adform_order',
            'linkedin_ads_campaign_group'
          ].includes(subRow.type)
        ) {
          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     // variant: 'error',
          //     // persist: true
          //   }
          // );
          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'standard',
          //   warningLevel
          // });
        }
      }

      if (
        [
          // 'folder',
          // 'manualDataEntry',
          'facebook_ads_campaign',
          'google_ads_campaign',
          // 'cm360_campaign',
          // 'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group'
        ].includes(subRow.type) &&
        ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status)
      ) {
        // if (!subRow.costYesterday) {
        //   const warningMessage = 'No cost yesterday';

        //   warningLevel = 3;
        //   highestWarningLevel =
        //     highestWarningLevel < warningLevel
        //       ? warningLevel
        //       : highestWarningLevel;

        //   warningMessages.push({
        //     warningLevel,
        //     message: warningMessage,
        //     rawMessage: warningMessage
        //   });
        // }

        // if (!subRow.costToday) {
        //   const warningMessage = 'No cost today';

        //   warningLevel = 3;
        //   highestWarningLevel =
        //     highestWarningLevel < warningLevel
        //       ? warningLevel
        //       : highestWarningLevel;

        //   warningMessages.push({
        //     warningLevel,
        //     message: warningMessage,
        //     rawMessage: warningMessage
        //   });
        // }

        const yesterday_ = new Date();

        yesterday_.setDate(yesterday_.getDate() - 1);

        if (
          !subRow.impressionsYesterday &&
          new Date(selectedDateStart).toISOString().split('T')[0] <=
            yesterday_.toISOString().split('T')[0] &&
          yesterday_.toISOString().split('T')[0] <=
            new Date(selectedDateStop).toISOString().split('T')[0] &&
          (!subRow.startDate ||
            new Date(subRow.startDate).toISOString().split('T')[0] <=
              yesterday_.toISOString().split('T')[0]) &&
          (!subRow.endDate ||
            new Date(subRow.endDate).toISOString().split('T')[0] >=
              yesterday_.toISOString().split('T')[0])
        ) {
          const warningMessage = 'No impressions yesterday';

          warningLevel = 3;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            warningLevel,
            message: <span style={{ color: 'red' }}>{warningMessage}</span>,
            rawMessage: warningMessage
          });
        }

        const today_ = new Date();

        if (
          !subRow.impressionsToday &&
          new Date(selectedDateStart).toISOString().split('T')[0] <=
            today_.toISOString().split('T')[0] &&
          today_.toISOString().split('T')[0] <=
            new Date(selectedDateStop).toISOString().split('T')[0] &&
          (!subRow.startDate ||
            new Date(subRow.startDate).toISOString().split('T')[0] <=
              today_.toISOString().split('T')[0]) &&
          (!subRow.endDate ||
            new Date(subRow.endDate).toISOString().split('T')[0] >=
              today_.toISOString().split('T')[0])
        ) {
          const warningMessage = 'No impressions today';

          warningLevel = 3;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            warningLevel,
            message: <span style={{ color: 'red' }}>{warningMessage}</span>,
            rawMessage: warningMessage
          });
        }
      }
    }

    if (
      subRow.type === 'manualDataEntry' &&
      (subRow.cost ||
        subRow.trp ||
        subRow.spots ||
        subRow.impressions ||
        subRow.clicks ||
        subRow.action01Count ||
        subRow.action02Count ||
        subRow.action03Count ||
        subRow.action01Value ||
        subRow.action02Value ||
        subRow.action03Value)
    ) {
      if (!subRow.startDate || !subRow.endDate) {
        warningLevel = 3;
        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;

        const warningMessage = `Start date and/or end date is not set. This might cause manual data input to not count the values correctly.`;

        warningMessages.push({
          warningLevel,
          message: warningMessage,
          rawMessage: warningMessage
        });
      }
    }

    subRow.highestWarningLevel = highestWarningLevel;

    if (warningMessages.length > 0) {
      subRow.warnings = [
        ...subRow.warnings,
        {
          path: subRow.path,
          name: subRow.name,
          messages: warningMessages,
          highestWarningLevel
        }
      ];
    }

    // ######### subWarnings ##########

    supRow.snackbarWarnings = [
      ...supRow.snackbarWarnings,
      ...subRow.snackbarWarnings,
      ...snackbarWarnings
    ];

    supRow.subWarnings = [
      ...supRow.subWarnings,
      ...subRow.warnings,
      ...subRow.subWarnings
    ];

    // subRow.warnings = [...subRow.warnings, ...warningMessages];
  };

  // ######################################## checkboxes ########################################################

  // const [checkedBoxes, setCheckedBoxes] = useState([]);

  useEffect(() => {
    setCheckedBoxes([]);
  }, [currentAccountIds]);

  // ######################################### v2 ################################################################

  // const [listOfData, setListOfData] = useState([]);

  function unique(arr) {
    var u = {},
      a = [];
    for (var i = 0, l = arr.length; i < l; ++i) {
      if (!u.hasOwnProperty(arr[i])) {
        a.push(arr[i]);
        u[arr[i]] = 1;
      }
    }
    return a;
  }
  const [dataSourceNames, setDataSourceNames] = useState([]);

  useEffect(() => {
    // names
    const allNames = listOfData.map((row) => row.name);
    const uniqueNames = unique(allNames);
    setNames(uniqueNames);

    //dataSourceNames

    // const allDataSourceNames = listOfData.map(row=>{row.name});

    var reduced = listOfData.reduce(function (filtered, row) {
      if (
        [
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group'
        ].includes(row.type)
      ) {
        filtered.push(row.name);
      }
      return filtered;
    }, []);

    const uniqueDataSourceNames = unique(reduced);
    setDataSourceNames(uniqueDataSourceNames);
  }, [listOfData]);

  // let total_list_of_propagation_data = [];

  // ###################################################################################################

  const prepareContainerIdsAndNames = (
    prepParentContainerRow,
    nexusDocument,
    level,
    id,
    name,
    type,
    style
  ) => {
    const prepContainerRow = {
      _id: id,
      level,
      isSetChild: Boolean(
        prepParentContainerRow.isSet || prepParentContainerRow.isSetChild
      ),
      accountName: prepParentContainerRow.accountName,
      accountId: prepParentContainerRow.accountId,
      accountColor: prepParentContainerRow.accountColor,
      name: nexusDocument.name,
      totalData: prepParentContainerRow.totalData,
      action01googleanalytics4data:
        nexusDocument.action01googleanalytics4data || [],
      action02googleanalytics4data:
        nexusDocument.action02googleanalytics4data || [],
      action03googleanalytics4data:
        nexusDocument.action03googleanalytics4data || [],
      comments: nexusDocument.comments,

      action01Type: nexusDocument?.action01?.type,
      action02Type: nexusDocument?.action02?.type,
      action03Type: nexusDocument?.action03?.type,

      action01Name: nexusDocument?.action01?.name,
      action02Name: nexusDocument?.action02?.name,
      action03Name: nexusDocument?.action03?.name,

      _members: [
        ...(prepParentContainerRow._members || []),
        ...(nexusDocument.members || [])
      ],

      _labels: [
        ...(prepParentContainerRow._labels || []),
        ...(nexusDocument.labels || [])
      ]
    };

    const rawIntRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const intRange = [];

    for (let num of rawIntRange) {
      if (num <= level) {
        intRange.push(num);
      }
    }

    for (let num of intRange) {
      prepContainerRow['level_' + num + '_container_id'] =
        prepParentContainerRow['level_' + num + '_container_id'];
      prepContainerRow['level_' + num + '_container_name'] =
        prepParentContainerRow['level_' + num + '_container_name'];
    }

    prepContainerRow['level_' + level + '_container_id'] = id;
    prepContainerRow['level_' + level + '_container_name'] = name;

    // ############# generate path #############
    let path = ' / ';
    let interactivePath = [];

    prepContainerRow.interactivePath = [
      ...prepParentContainerRow.interactivePath,
      {
        type: prepContainerRow.type,
        documentType: prepContainerRow.documentType,
        _id: prepContainerRow._id,
        name: prepContainerRow.name,
        level: prepContainerRow.level,
        accountId: prepContainerRow.accountId,
        level_1_container_id: prepContainerRow.level_1_container_id,
        level_2_container_id: prepContainerRow.level_2_container_id,
        level_3_container_id: prepContainerRow.level_3_container_id,
        level_4_container_id: prepContainerRow.level_4_container_id,
        level_5_container_id: prepContainerRow.level_5_container_id,
        level_6_container_id: prepContainerRow.level_6_container_id,
        level_7_container_id: prepContainerRow.level_7_container_id,
        level_8_container_id: prepContainerRow.level_8_container_id,
        level_9_container_id: prepContainerRow.level_9_container_id,
        level_10_container_id: prepContainerRow.level_10_container_id,
        level_11_container_id: prepContainerRow.level_11_container_id,
        level_12_container_id: prepContainerRow.level_12_container_id
      }
    ];
    for (let num of intRange) {
      const addToPath = prepContainerRow['level_' + num + '_container_name']
        ? prepContainerRow['level_' + num + '_container_name'] + ' / '
        : 'Data source' + ' / ';

      // not add path to ad groups and adsets. This will be added when parsing the data.
      if (
        [
          'facebook_ads_ad_set',
          'google_ads_ad_group',
          'dv360_line_item',
          'snapchat_ads_ad_squad',
          'tiktok_ads_ad_group',
          'adform_line_item'
        ].includes(type) &&
        level === num
      ) {
        path = path;
      } else {
        path = path + addToPath;
      }
    }

    prepContainerRow.path = path;
    prepContainerRow.style = style;

    return prepContainerRow;
  };

  const prepareDataSourceNexusDocument = (nexusDocument) => {
    const preparedNexusDocument = { ...nexusDocument };

    return preparedNexusDocument;
  };

  const prepareDataList = (
    prepGrandParentContainerRow,
    parentNexusDocument,
    level,
    accountName
  ) => {
    if (parentNexusDocument.status !== 'REMOVED' || statusFilter === 'All') {
      // console.log('parentNexusDocument: ', parentNexusDocument);
      const rowBaseKVs = rowBaseKV(
        parentNexusDocument,
        parentNexusDocument.documentType,
        ''
      );

      const preparedContainerAndIds = prepareContainerIdsAndNames(
        prepGrandParentContainerRow,
        parentNexusDocument,
        level,
        parentNexusDocument._id,
        parentNexusDocument.name,
        parentNexusDocument.documentType,
        // 'folder',
        parentNexusDocument.style
      );
      let prepParentContainerRow = {
        ...rowBaseKVs,
        ...preparedContainerAndIds
      };

      //if facebook

      if (parentNexusDocument.nexusDocuments) {
        for (let nexusDocument of parentNexusDocument.nexusDocuments) {
          if (nexusDocument.status !== 'REMOVED' || statusFilter === 'All') {
            let campaignBaseValues;
            let adsetBaseValues;
            // console.log('nexusDocument: ', nexusDocument);
            const documentType = nexusDocument.documentType;
            const isSet = nexusDocument.isSet;

            switch (true) {
              case documentType === 'totalActionsDocument':
                const totalActionsDocumentKVs = rowBaseKV(
                  nexusDocument,
                  'manualDataEntry',
                  'manual_input'
                );
                // manualDataEntryKV(nexusDocument);

                const totalActionsDocumentPreparedDocument =
                  prepareContainerIdsAndNames(
                    prepParentContainerRow,
                    nexusDocument,
                    level + 1,
                    nexusDocument._id,
                    nexusDocument.name,
                    'totalActionsDocument',
                    nexusDocument.style
                  );

                const totalActionsDocumentManualDataEntry = {
                  ...totalActionsDocumentKVs,
                  ...totalActionsDocumentPreparedDocument
                };

                // rowCalculations(
                //   prepContainerRow,
                //   totalActionsDocumentManualDataEntry
                // );

                listOfData2.push(totalActionsDocumentManualDataEntry);
                break;
              case documentType === 'folder':
              case documentType === 'task':
              case documentType === 'subTask':
              case documentType === 'taxonomy':
              case isSet === true:
                const prepChildContainerRow = prepareDataList(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1
                );

                // console.log('prepChildContainerRow: ', prepChildContainerRow);

                rowCalculations(prepParentContainerRow, prepChildContainerRow);

                break;
              case documentType === 'facebookAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'facebook_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'facebook_ads_ad_set'
                );

                if (dataLevel === 'campaign') {
                  const {
                    prepared_campaign: prepared_campaign_fb
                    // prepared_listOfAdsets: prepared_listOfAdsets_fb
                  } = parse_fb_campaign_data(
                    rowBaseKV(nexusDocument, 'facebookAds', 'facebook_ads'),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2
                  );

                  listOfData2.push(prepared_campaign_fb);
                  // listOfData2 = [...listOfData2, ...prepared_listOfAdsets_fb];

                  rowCalculations(prepParentContainerRow, prepared_campaign_fb);
                } else {
                  const {
                    prepared_campaign: prepared_campaign_fb,
                    prepared_listOfAdsets: prepared_listOfAdsets_fb
                  } = parse_fb_data(
                    rowBaseKV(nexusDocument, 'facebookAds', 'facebook_ads'),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2
                  );

                  listOfData2.push(prepared_campaign_fb);
                  listOfData2 = [...listOfData2, ...prepared_listOfAdsets_fb];

                  rowCalculations(prepParentContainerRow, prepared_campaign_fb);
                }

                break;
              case documentType === 'googleAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  prepareDataSourceNexusDocument(nexusDocument),
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'google_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'google_ads_ad_group',
                  nexusDocument.style
                );

                // console.log('before parse_google_ads_data');

                if (
                  dataLevel === 'adset' &&
                  ['SEARCH', 'SHOPPING', 'VIDEO'].includes(
                    nexusDocument.campaignType
                  )
                ) {
                  // console.log('dataLevel: ', dataLevel);
                  const {
                    prepared_campaign: prepared_campaign_google_ads,
                    prepared_listOfAdsets: prepared_listOfAdsets_google_ads
                  } = parse_google_ads_data(
                    rowBaseKV(nexusDocument, 'googleAds', 'google_ads'),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2
                  );

                  listOfData2.push(prepared_campaign_google_ads);
                  listOfData2 = [
                    ...listOfData2,
                    ...prepared_listOfAdsets_google_ads
                  ];
                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_google_ads
                  );
                } else {
                  console.log('preparing google ads campaign document');
                  const { prepared_campaign: prepared_campaign_google_ads } =
                    parse_google_ads_campaign_data(
                      rowBaseKV(nexusDocument, 'googleAds', 'google_ads'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2
                    );

                  listOfData2.push(prepared_campaign_google_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_google_ads
                  // ];
                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_google_ads
                  );
                }

                break;

              case documentType === 'cm360':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'cm360_campaign',
                  nexusDocument.style
                );

                // adsetBaseValues = prepareContainerIdsAndNames(
                //   campaignBaseValues,
                //   nexusDocument,
                //   level + 2,
                //   nexusDocument._id,
                //   nexusDocument.name,
                //   'google_ads_ad_group',
                //   nexusDocument.style
                // );

                // console.log('before parse_google_ads_data');

                if (true) {
                  const { prepared_campaign: prepared_campaign_cm360 } =
                    parse_cm360_campaign_data(
                      rowBaseKV(nexusDocument, 'cm360', 'cm360'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2
                    );

                  listOfData2.push(prepared_campaign_cm360);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_google_ads
                  // ];
                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_cm360
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_google_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_google_ads
                  // } = parse_google_ads_data(
                  //   nexusDocument,
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_google_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_google_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_google_ads
                  // );
                }

                break;

              case documentType === 'dv360':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'dv360_insertion_order',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'dv360_line_item',
                  nexusDocument.style
                );

                // console.log('before parse_google_ads_data');

                if (dataLevel === 'campaign') {
                  const { prepared_campaign: prepared_campaign_dv360 } =
                    parse_dv360_insertion_order_data(
                      rowBaseKV(nexusDocument, 'dv360', 'dv360'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2
                    );

                  listOfData2.push(prepared_campaign_dv360);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_google_ads
                  // ];
                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_dv360
                  );
                } else {
                  const {
                    prepared_campaign: prepared_campaign_dv360,
                    prepared_listOfAdsets: prepared_listOfAdsets_dv360
                  } = parse_dv360_line_item_data(
                    rowBaseKV(nexusDocument, 'dv360', 'dv360'),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2
                  );

                  listOfData2.push(prepared_campaign_dv360);
                  listOfData2 = [
                    ...listOfData2,
                    ...prepared_listOfAdsets_dv360
                  ];

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_dv360
                  );
                }

                break;

              case documentType === 'snapchatAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'snapchat_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'snapchat_ads_ad_squad',
                  nexusDocument.style
                );

                // console.log('before parse_google_ads_data');

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_snapchat_ads } =
                    parse_snapchat_ads_campaign_data(
                      rowBaseKV(nexusDocument, 'snapchatAds', 'snapchatAds'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2
                    );

                  listOfData2.push(prepared_campaign_snapchat_ads);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_snapchat_ads
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_snapchat_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_snapchat_ads
                  // } = parse_snapchat_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'snapchatAds', 'snapchatAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_snapchat_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_snapchat_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_snapchat_ads
                  // );
                }

                break;

              case documentType === 'tikTokAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'tiktok_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'tiktok_ads_ad_group',
                  nexusDocument.style
                );

                // console.log('before parse_google_ads_data');

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_snapchat_ads } =
                    parse_tiktok_ads_campaign_data(
                      rowBaseKV(nexusDocument, 'tikTokAds', 'tikTokAds'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2
                    );

                  listOfData2.push(prepared_campaign_snapchat_ads);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_snapchat_ads
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_snapchat_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_snapchat_ads
                  // } = parse_snapchat_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'snapchatAds', 'snapchatAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_snapchat_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_snapchat_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_snapchat_ads
                  // );
                }

                break;

              case documentType === 'adform':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'adform_order',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'adform_line_item',
                  nexusDocument.style
                );

                // console.log('before parse_google_ads_data');

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_adform } =
                    parse_adform_order_data(
                      rowBaseKV(nexusDocument, 'adform', 'adform'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2
                    );

                  listOfData2.push(prepared_campaign_adform);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_adform
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_snapchat_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_snapchat_ads
                  // } = parse_snapchat_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'snapchatAds', 'snapchatAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_snapchat_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_snapchat_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_snapchat_ads
                  // );
                }

                break;
              case documentType === 'manualDataEntry':
                const manualDataEntryKVs = rowBaseKV(
                  nexusDocument,
                  'manualDataEntry',
                  'manual_input'
                );
                // manualDataEntryKV(nexusDocument);

                const preparedDocument = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'manualDataEntry',
                  nexusDocument.style
                );

                const manualDataEntry = {
                  ...manualDataEntryKVs,
                  ...preparedDocument,
                  dataSourceData: true
                };

                rowCalculations(prepParentContainerRow, manualDataEntry);

                listOfData2.push(manualDataEntry);
                break;

              case documentType === 'customForm':
                const customFormKVs = rowBaseKV(
                  nexusDocument,
                  'customForm',
                  'customForm'
                );

                const preparedCustomFormDocument = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'customForm',
                  nexusDocument.style
                );

                const customForm = {
                  ...customFormKVs,
                  ...preparedCustomFormDocument,
                  dataSourceData: true
                };

                rowCalculations(prepParentContainerRow, customForm);

                listOfData2.push(customForm);
                break;
            }
          }
        }
      }

      listOfData2.push(prepParentContainerRow);
      return prepParentContainerRow;
      // }
    }
  };

  // #################################################################################################
  let listOfData2 = [];
  useEffect(() => {
    // const t0 = performance.now();
    // console.log(`Performance time start.`);
    // console.log('running calculations>data: ', data);
    listOfData2 = [];

    const listOfAccounts = [];
    const listOfTotalData = [];
    let allTotalActionsDocuments = [];
    if (data) {
      const t0 = performance.now();
      console.log(`Performance time start.`);
      // console.log('running calculations>data2: ', data);
      toggleDoneCalculating(false);

      let accounts = [];
      // console.log('RoiTable>row4626>updateAccountId: ', updateAccountId);
      if (updateAccountId) {
        accounts = (data?.accounts || []).filter((account) => {
          return account._id === updateAccountId;
        });
      } else {
        accounts = data?.accounts || [];
      }

      for (let account of accounts) {
        const formulaObjectToSpread = {};

        for (let formulaKey of allEvalFields) {
          formulaObjectToSpread[formulaKey + 'Formula'] = account[formulaKey];
          formulaObjectToSpread[formulaKey + 'Sparkline'] = [];
          formulaObjectToSpread[formulaKey] = null;
          formulaObjectToSpread[formulaKey + 'Today'] = null;
          formulaObjectToSpread[formulaKey + 'Yesterday'] = null;
          formulaObjectToSpread[formulaKey + 'SumChild'] = null;
        }

        // const spreadObject = {};
        // for (let key of Object.keys(account)) {
        //   if (!allEvalFields.includes(key)) {
        //     spreadObject[key] = account[key];
        //   } else {
        //     spreadObject[key] = null;
        //   }
        // }

        const spreadObject = {};
        for (let key of Object.keys(account)) {
          if (['nexusDocuments'].includes(key)) {
            continue;
          } else if (!allEvalFields.includes(key)) {
            spreadObject[key] = account[key];
          } else {
            spreadObject[key] = null;
          }
        }

        const accountRow = {
          ...formulaObjectToSpread,
          ...spreadObject,
          accountColor:
            account?.style?.gantt?.bar?.backgroundColor || 'rgb(49, 80, 161)',
          level: 1,
          warnings: [],
          accountAccessLevel: account.accountAccessLevel,
          level_1_container_id: account._id,
          level_1_container_name: account.name,
          _id: account._id,
          childDocumentIds: [],
          accountId: account._id,
          accountName: account.name,
          name: account.name,
          status: account.status,
          snackbarWarnings: [],
          warning: [],
          subWarnings: [],
          budgetUntillNow: null,

          childRows: [],
          documentType: 'account',
          type: 'account',
          budget: null,
          action01: account.action01,
          action02: account.action02,
          action03: account.action03,
          totalAction01: account.totalAction01,
          totalAction02: account.totalAction02,
          totalAction03: account.totalAction03,
          style: account.style,

          visible: true,
          path: ' / ' + account.name + ' / ',
          interactivePath: [
            {
              _id: account._id,
              level_1_container_id: account._id,
              name: account.name,
              level_1_container_name: account.name,
              level: 1
            }
          ],
          total_list_of_propagation_data: [],
          total_list_of_aggregated_propagation_data: []
        };

        // calculate total actions

        const totalActionsDocuments = account.nexusDocuments.filter(
          (nexusDocument) =>
            nexusDocument.documentType === 'totalActionsDocument'
        );

        const totalData = [];

        // console.log('totalActionsDocuments: ', totalActionsDocuments);

        for (let totalActionsDocument of totalActionsDocuments) {
          allTotalActionsDocuments.push({
            ...totalActionsDocument,
            accountId: account._id
          });
          let totalDataObject = {};

          let totalAction01 = {};
          let totalAction02 = {};
          let totalAction03 = {};

          let totalAction01DataSource =
            totalActionsDocument?.totalAction01?.dataSource;
          let totalAction02DataSource =
            totalActionsDocument?.totalAction02?.dataSource;
          let totalAction03DataSource =
            totalActionsDocument?.totalAction03?.dataSource;

          totalDataObject = {
            totalActionsDocumentId: totalActionsDocument._id,
            totalData: [],
            accountId: account._id
          };

          let testCount = 0;
          let testValue = 0;

          const googleAnalytics4PropagationData = [];

          if (
            totalActionsDocument?.totalAction01?.dataSource ===
              'googleAnalytics4' &&
            totalActionsDocument.totalAction01googleanalytics4data
          ) {
            for (let ga4Object of totalActionsDocument.totalAction01googleanalytics4data) {
              totalDataObject.totalData.push({
                eventName: ga4Object.eventName,
                source: ga4Object.source,
                date_start: ga4Object.date,
                totalAction01Count: ga4Object.eventCount,
                totalAction01Value: ga4Object.eventValue
              });
            }
          }

          if (
            totalActionsDocument?.totalAction02?.dataSource ===
              'googleAnalytics4' &&
            totalActionsDocument.totalAction02googleanalytics4data
          ) {
            for (let ga4Object of totalActionsDocument.totalAction02googleanalytics4data) {
              totalDataObject.totalData.push({
                eventName: ga4Object.eventName,
                source: ga4Object.source,
                date_start: ga4Object.date,
                totalAction02Count: ga4Object.eventCount,
                totalAction02Value: ga4Object.eventValue
              });
            }
          }

          if (
            totalActionsDocument?.totalAction03?.dataSource ===
              'googleAnalytics4' &&
            totalActionsDocument.totalAction03googleanalytics4data
          ) {
            for (let ga4Object of totalActionsDocument.totalAction03googleanalytics4data) {
              totalDataObject.totalData.push({
                accountId: account._id,
                eventName: ga4Object.eventName,
                source: ga4Object.source,
                date_start: ga4Object.date,
                totalAction03Count: ga4Object.eventCount,
                totalAction03Value: ga4Object.eventValue
              });
            }
          }

          listOfTotalData.push(totalDataObject);
          totalData.push(totalDataObject);
        }

        if (account.nexusDocuments) {
          for (let nexusDocument of account.nexusDocuments) {
            if (nexusDocument.status !== 'REMOVED' || statusFilter === 'All') {
              let prepChildContainerRow = prepareDataList(
                accountRow,
                nexusDocument,
                2,
                account.name
              );

              rowCalculations(accountRow, prepChildContainerRow);
            }
          }
        }

        rowCalculations(
          {
            total_list_of_propagation_data: [],
            total_list_of_aggregated_propagation_data: [],
            totalData,
            subWarnings: [],
            snackbarWarnings: [],
            childDocumentIds: [],
            childRows: []
          },
          accountRow
        );

        listOfAccounts.push(accountRow);
        listOfData2.push(accountRow);
      }

      let totalSnackbarWarnings = [];

      let summary_total_list_of_propagation_data = [];
      let basicTotalDataTotal = [];
      for (let account of listOfAccounts) {
        totalSnackbarWarnings = [
          ...totalSnackbarWarnings,
          ...account.snackbarWarnings
        ];
        const basicTotalData = [];
        const enrichedAccount = {
          // clicksSparkline: [],
          // ctrSparkline: [],
          // cpcSparkline: [],
          // costSparkline: [],
          // impressionsSparkline: [],

          totalClicksSparkline: [],
          totalCtrSparkline: [],
          totalCpcSparkline: [],
          totalCostSparkline: [],
          totaltrpSparkline: [],
          totalSpotsSparkline: [],
          totalImpressionsSparkline: [],

          totalAction01CountSparkline: [],
          totalAction01ValueSparkline: [],
          totalAction01CpaSparkline: [],
          totalAction01RoasSparkline: [],
          totalaction01CvrSparkline: [],
          totalAction01VpaSparkline: [],

          totalAction02CountSparkline: [],
          totalAction02ValueSparkline: [],
          totalAction02CpaSparkline: [],
          totalAction02RoasSparkline: [],
          totalaction02CvrSparkline: [],
          totalAction02VpaSparkline: [],

          totalAction03CountSparkline: [],
          totalAction03ValueSparkline: [],
          totalAction03CpaSparkline: [],
          totalAction03RoasSparkline: [],
          totalaction03CvrSparkline: [],
          totalAction03VpaSparkline: [],

          cpmSparkline: [],
          vCpmSparkline: [],

          // totalCost: account.cost,
          totalCost: null,
          totaltrp: null,
          totalSpots: null,
          totalImpressions: null,
          totalClicks: null,

          cost: account.cost,
          totalAction01Count: null,
          totalAction02Count: null,
          totalAction03Count: null,

          totalAction01Value: null,
          totalAction02Value: null,
          totalAction03Value: null,

          propData: []
        };

        // #################################
        for (let date of range2) {
          let cost = null;
          let trp = null;
          let spots = null;
          let impressions = null;
          let clicks = null;
          let action01Count = null;
          let action02Count = null;
          let action03Count = null;
          let action01Value = null;
          let action02Value = null;
          let action03Value = null;

          let totalAction01Count = null;
          let totalAction02Count = null;
          let totalAction03Count = null;
          let totalAction01Value = null;
          let totalAction02Value = null;
          let totalAction03Value = null;

          // for (let object of subRow.total_list_of_propagation_data) {
          for (let object of [
            // ...totalData.totalData,
            ...account.total_list_of_propagation_data
          ]) {
            // console.log('object: ', object);

            if (
              new Date(parseInt(object.date_start))
                .toISOString()
                .split('T')[0] === date.toISOString().split('T')[0]
            ) {
              cost = cost + (object.cost || null);
              trp = trp + (object.trp || null);

              spots = spots + (object.spots || null);

              impressions = impressions + (object.impressions || null);
              clicks = clicks + (object.clicks || null);
            }
          }

          // basicTotalData.push({
          //   accountId: account._id,
          //   date_start: date.getTime(),
          //   totalCost: cost || null,
          //   totaltrp: trp || null,

          //   totalSpots: spots || null,

          //   totalImpressions: impressions || null,
          //   totalClicks: clicks || null
          // });

          // basicTotalDataTotal.push({
          //   accountId: account._id,
          //   date_start: date.getTime(),
          //   totalCost: cost || null,
          //   totaltrp: trp || null,

          //   totalSpots: spots || null,

          //   totalImpressions: impressions || null,
          //   totalClicks: clicks || null
          // });

          if (cost || trp || spots || impressions || clicks) {
            basicTotalData.push({
              accountId: account._id,
              // date_start: date.getTime(),
              totalCost: cost || null,
              totaltrp: trp || null,

              totalSpots: spots || null,

              totalImpressions: impressions || null,
              totalClicks: clicks || null
            });

            basicTotalDataTotal.push({
              accountId: account._id,
              // date_start: date.getTime(),
              totalCost: cost || null,
              totaltrp: trp || null,

              totalSpots: spots || null,

              totalImpressions: impressions || null,
              totalClicks: clicks || null
            });
          }
        }

        // ########################

        // let totalAction01Count = 0;
        // let enrichedAccount = {};
        for (let totalActionsDoc of allTotalActionsDocuments) {
          if (totalActionsDoc.accountId === account._id) {
            for (let totalData of listOfTotalData) {
              if (totalActionsDoc._id === totalData.totalActionsDocumentId) {
                enrichedAccount.propData = [
                  ...enrichedAccount.propData,
                  ...totalData.totalData
                ];
                // for (let tData of totalData.totalData) {
                //   console.log('tData: ', tData);

                //   enrichedAccount.totalAction01Count =
                //     enrichedAccount.totalAction01Count +
                //     tData.totalAction01Count;

                // }

                // ######################################################################
                // ######################################################################
                // ######################################################################
                // ######################################################################

                for (let date of range2) {
                  let cost = null;
                  let trp = null;
                  let spots = null;
                  let impressions = null;
                  let clicks = null;
                  let action01Count = null;
                  let action02Count = null;
                  let action03Count = null;
                  let action01Value = null;
                  let action02Value = null;
                  let action03Value = null;

                  let totalAction01Count = null;
                  let totalAction02Count = null;
                  let totalAction03Count = null;
                  let totalAction01Value = null;
                  let totalAction02Value = null;
                  let totalAction03Value = null;

                  // for (let object of subRow.total_list_of_propagation_data) {
                  for (let object of [
                    ...totalData.totalData,
                    ...account.total_list_of_propagation_data
                  ]) {
                    // console.log('object: ', object);

                    if (
                      new Date(parseInt(object.date_start))
                        .toISOString()
                        .split('T')[0] === date.toISOString().split('T')[0]
                    ) {
                      cost = cost + (object.cost || null);
                      trp = trp + (object.trp || null);

                      spots = spots + (object.spots || null);

                      impressions = impressions + (object.impressions || null);
                      clicks = clicks + (object.clicks || null);

                      totalAction01Count =
                        totalAction01Count +
                        (object.totalAction01Count || null);

                      totalAction02Count =
                        totalAction02Count +
                        (object.totalAction02Count || null);

                      totalAction03Count =
                        totalAction03Count +
                        (object.totalAction03Count || null);

                      totalAction01Value =
                        totalAction01Value +
                        (object.totalAction01Value || null);

                      totalAction02Value =
                        totalAction02Value +
                        (object.totalAction02Value || null);

                      totalAction03Value =
                        totalAction03Value +
                        (object.totalAction03Value || null);

                      // totalAction01Count =
                      //   totalAction01Count + (object.totalAction01Count || 0);
                    }
                  }

                  // basicTotalData.push({
                  //   accountId: account._id,
                  //   date_start: date.getTime(),
                  //   totalCost: cost || null,
                  //   totalImpressions: impressions || null,
                  //   totalClicks: clicks || null
                  // });

                  // basicTotalDataTotal.push({
                  //   accountId: account._id,
                  //   date_start: date.getTime(),
                  //   totalCost: cost || null,
                  //   totalImpressions: impressions || null,
                  //   totalClicks: clicks || null
                  // });
                  // enrichedAccount.clicksSparkline.push(clicks);
                  // enrichedAccount.ctrSparkline.push(
                  //   impressions && clicks ? clicks / impressions : 0
                  // );
                  enrichedAccount.totalaction01CvrSparkline.push(
                    clicks && totalAction01Count
                      ? totalAction01Count / clicks
                      : 0
                  );
                  enrichedAccount.totalaction02CvrSparkline.push(
                    clicks && totalAction02Count
                      ? totalAction02Count / clicks
                      : 0
                  );
                  enrichedAccount.totalaction03CvrSparkline.push(
                    clicks && totalAction03Count
                      ? totalAction03Count / clicks
                      : 0
                  );

                  // enrichedAccount.cpcSparkline.push(
                  //   cost && clicks ? cost / clicks.toFixed(2) : 0
                  // );

                  enrichedAccount.totalCostSparkline.push(cost);
                  enrichedAccount.totaltrpSparkline.push(trp);

                  enrichedAccount.totalSpotsSparkline.push(spots);

                  enrichedAccount.totalImpressionsSparkline.push(impressions);
                  enrichedAccount.totalClicksSparkline.push(clicks);
                  // enrichedAccount.impressionsSparkline.push(impressions);
                  // enrichedAccount.cpmSparkline.push(
                  //   impressions ? (cost || 0) / (impressions / 1000) : 0
                  // );

                  enrichedAccount.totalAction01CountSparkline.push(
                    totalAction01Count
                  );
                  enrichedAccount.totalAction01ValueSparkline.push(
                    totalAction01Value
                  );
                  enrichedAccount.totalAction01VpaSparkline.push(
                    totalAction01Count && totalAction01Value
                      ? totalAction01Value / totalAction01Count
                      : 0
                  );

                  enrichedAccount.totalAction02CountSparkline.push(
                    totalAction02Count
                  );
                  enrichedAccount.totalAction02ValueSparkline.push(
                    totalAction02Value
                  );
                  enrichedAccount.totalAction02VpaSparkline.push(
                    totalAction02Count && totalAction02Value
                      ? totalAction02Value / totalAction02Count
                      : 0
                  );

                  enrichedAccount.totalAction03CountSparkline.push(
                    totalAction03Count
                  );
                  enrichedAccount.totalAction03ValueSparkline.push(
                    totalAction03Value
                  );
                  enrichedAccount.totalAction03VpaSparkline.push(
                    totalAction03Count && totalAction03Value
                      ? totalAction03Value / totalAction03Count
                      : 0
                  );

                  enrichedAccount.totalAction01CpaSparkline.push(
                    totalAction01Count ? cost / totalAction01Count : 0
                  );

                  enrichedAccount.totalAction02CpaSparkline.push(
                    totalAction02Count ? cost / totalAction02Count : 0
                  );

                  enrichedAccount.totalAction03CpaSparkline.push(
                    totalAction03Count ? cost / totalAction03Count : 0
                  );

                  enrichedAccount.totalAction01RoasSparkline.push(
                    cost ? totalAction01Value / cost : 0
                  );

                  enrichedAccount.totalAction02RoasSparkline.push(
                    cost ? totalAction02Value / cost : 0
                  );

                  enrichedAccount.totalAction03RoasSparkline.push(
                    cost ? totalAction03Value / cost : 0
                  );

                  // enrichedAccount.clicks = enrichedAccount.clicks + clicks;
                  enrichedAccount.totalCost =
                    enrichedAccount.totalCost + (cost || null);

                  enrichedAccount.totaltrp =
                    enrichedAccount.totaltrp + (trp || null);

                  enrichedAccount.totalSpots =
                    enrichedAccount.totalSpots + (spots || null);

                  enrichedAccount.totalImpressions =
                    enrichedAccount.totalImpressions + (impressions || null);
                  enrichedAccount.totalClicks =
                    enrichedAccount.totalClicks + (clicks || null);

                  enrichedAccount.totalAction01Count =
                    enrichedAccount.totalAction01Count + totalAction01Count;
                  enrichedAccount.totalAction02Count =
                    enrichedAccount.totalAction02Count + totalAction02Count;
                  enrichedAccount.totalAction03Count =
                    enrichedAccount.totalAction03Count + totalAction03Count;
                  enrichedAccount.totalAction01Value =
                    enrichedAccount.totalAction01Value + totalAction01Value;
                  enrichedAccount.totalAction02Value =
                    enrichedAccount.totalAction02Value + totalAction02Value;
                  enrichedAccount.totalAction03Value =
                    enrichedAccount.totalAction03Value + totalAction03Value;

                  // enrichedAccount.totalAction01Count =
                  //   enrichedAccount.totalAction01Count + totalAction01Count;
                  // enrichedAccount.totalAction02Count =
                  //   enrichedAccount.totalAction02Count + totalAction02Count;
                  // enrichedAccount.totalAction03Count =
                  //   enrichedAccount.totalAction03Count + totalAction03Count;
                  // enrichedAccount.totalAction01Value =
                  //   enrichedAccount.totalAction01Value + totalAction01Value;
                  // enrichedAccount.totalAction02Value =
                  //   enrichedAccount.totalAction02Value + totalAction02Value;
                  // enrichedAccount.totalAction03Value =
                  //   enrichedAccount.totalAction03Value + totalAction03Value;
                }

                // ######################################################################
                // ######################################################################
                // ######################################################################
                // ######################################################################
              }

              // console.log('hejhopp totalData: ', totalData);
            }
          }
          // console.log('totalActionsDoc: ', totalActionsDoc);
        }

        enrichedAccount.totalAction01Roas = enrichedAccount.totalCost
          ? (enrichedAccount.totalAction01Value || 0) /
            enrichedAccount.totalCost
          : null;

        enrichedAccount.totalAction02Roas = enrichedAccount.totalCost
          ? (enrichedAccount.totalAction02Value || 0) /
            enrichedAccount.totalCost
          : null;

        enrichedAccount.totalAction03Roas = enrichedAccount.totalCost
          ? (enrichedAccount.totalA03Value || 0) / enrichedAccount.totalCost
          : null;

        enrichedAccount.totalAction01Cpa =
          enrichedAccount.totalCost && enrichedAccount.totalAction01Count
            ? enrichedAccount.totalCost / enrichedAccount.totalAction01Count
            : null;

        enrichedAccount.totalAction02Cpa =
          enrichedAccount.totalCost && enrichedAccount.totalAction02Count
            ? enrichedAccount.totalCost / enrichedAccount.totalAction02Count
            : null;

        enrichedAccount.totalAction03Cpa =
          enrichedAccount.totalCost && enrichedAccount.totalAction03Count
            ? enrichedAccount.totalCost / enrichedAccount.totalAction03Count
            : null;

        enrichedAccount.totalAction01Vpa =
          enrichedAccount.totalAction01Count &&
          enrichedAccount.totalAction01Value
            ? enrichedAccount.totalAction01Value /
              enrichedAccount.totalAction01Count
            : null;

        enrichedAccount.totalAction02Vpa =
          enrichedAccount.totalAction02Count &&
          enrichedAccount.totalAction02Value
            ? enrichedAccount.totalAction02Value /
              enrichedAccount.totalAction02Count
            : null;

        enrichedAccount.totalAction03Vpa =
          enrichedAccount.totalAction03Count &&
          enrichedAccount.totalAction03Value
            ? enrichedAccount.totalAction03Value /
              enrichedAccount.totalAction03Count
            : null;

        // Round

        enrichedAccount.totalCost = enrichedAccount.totalCost
          ? Math.round((enrichedAccount.totalCost + Number.EPSILON) * 100) / 100
          : null;

        enrichedAccount.totaltrp = enrichedAccount.totaltrp
          ? Math.round((enrichedAccount.totaltrp + Number.EPSILON) * 100) / 100
          : null;

        enrichedAccount.totalSpots = enrichedAccount.totalSpots
          ? Math.round((enrichedAccount.totalSpots + Number.EPSILON) * 100) /
            100
          : null;

        enrichedAccount.totalImpressions = enrichedAccount.totalImpressions
          ? Math.round(
              (enrichedAccount.totalImpressions + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalClicks = enrichedAccount.totalClicks
          ? Math.round((enrichedAccount.totalClicks + Number.EPSILON) * 100) /
            100
          : null;

        enrichedAccount.totalAction01Count = enrichedAccount.totalAction01Count
          ? Math.round(
              (enrichedAccount.totalAction01Count + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Count = enrichedAccount.totalAction02Count
          ? Math.round(
              (enrichedAccount.totalAction02Count + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Count = enrichedAccount.totalAction03Count
          ? Math.round(
              (enrichedAccount.totalAction03Count + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction01Value = enrichedAccount.totalAction01Value
          ? Math.round(
              (enrichedAccount.totalAction01Value + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Value = enrichedAccount.totalAction02Value
          ? Math.round(
              (enrichedAccount.totalAction02Value + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Value = enrichedAccount.totalAction03Value
          ? Math.round(
              (enrichedAccount.totalAction03Value + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction01Roas = enrichedAccount.totalAction01Roas
          ? Math.round(
              (enrichedAccount.totalAction01Roas + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Roas = enrichedAccount.totalAction02Roas
          ? Math.round(
              (enrichedAccount.totalAction02Roas + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Roas = enrichedAccount.totalAction03Roas
          ? Math.round(
              (enrichedAccount.totalAction03Roas + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction01Cpa = enrichedAccount.totalAction01Cpa
          ? Math.round(
              (enrichedAccount.totalAction01Cpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Cpa = enrichedAccount.totalAction02Cpa
          ? Math.round(
              (enrichedAccount.totalAction02Cpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Cpa = enrichedAccount.totalAction03Cpa
          ? Math.round(
              (enrichedAccount.totalAction03Cpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction01Vpa = enrichedAccount.totalAction01Vpa
          ? Math.round(
              (enrichedAccount.totalAction01Vpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Vpa = enrichedAccount.totalAction02Vpa
          ? Math.round(
              (enrichedAccount.totalAction02Vpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Vpa = enrichedAccount.totalAction03Vpa
          ? Math.round(
              (enrichedAccount.totalAction03Vpa + Number.EPSILON) * 100
            ) / 100
          : null;

        summary_total_list_of_propagation_data = [
          ...summary_total_list_of_propagation_data,
          ...enrichedAccount.propData
          // ...basicTotalData
        ];

        const newPropDataArray = [
          ...enrichedAccount.propData,
          ...basicTotalData
        ];

        const cleaned_subRow_total_list_of_propagation_data = [];

        for (let date of range2) {
          const dateObject = { date_start: new Date(date).getTime() };

          for (let object of newPropDataArray) {
            if (
              object.date_start &&
              new Date(parseInt(object.date_start))
                .toISOString()
                .split('T')[0] === date.toISOString().split('T')[0]
            ) {
              for (let key in object) {
                if (
                  !['accountId', 'date_start', 'eventName', 'source'].includes(
                    key
                  )
                ) {
                  dateObject[key] = (dateObject[key] || 0) + (object[key] || 0);
                }
              }
            }
          }
          cleaned_subRow_total_list_of_propagation_data.push(dateObject);
        }

        // row.total_list_of_propagation_data =
        //   cleaned_subRow_total_list_of_propagation_data;

        // console.log('enrichedAccount: ', enrichedAccount);
        for (let row of listOfData2) {
          if (
            row.accountId === account._id &&
            row.documentType !== 'totalActionsDocument'
          ) {
            row.totalAction01 = account.totalAction01;
            row.totalAction02 = account.totalAction02;
            row.totalAction03 = account.totalAction03;

            row.totalCost = enrichedAccount.totalCost;

            row.totaltrp = enrichedAccount.totaltrp;

            row.totalSpots = enrichedAccount.totalSpots;

            row.totalImpressions = enrichedAccount.totalImpressions;
            row.totalClicks = enrichedAccount.totalClicks;

            row.totalAction01Count = enrichedAccount.totalAction01Count;
            row.totalAction02Count = enrichedAccount.totalAction02Count;
            row.totalAction03Count = enrichedAccount.totalAction03Count;

            row.totalAction01Value = enrichedAccount.totalAction01Value;
            row.totalAction02Value = enrichedAccount.totalAction02Value;
            row.totalAction03Value = enrichedAccount.totalAction03Value;

            row.totalAction01Roas = enrichedAccount.totalAction01Roas;
            row.totalAction02Roas = enrichedAccount.totalAction02Roas;
            row.totalAction03Roas = enrichedAccount.totalAction03Roas;

            row.totalAction01Cpa = enrichedAccount.totalAction01Cpa;
            row.totalAction02Cpa = enrichedAccount.totalAction02Cpa;
            row.totalAction03Cpa = enrichedAccount.totalAction03Cpa;

            row.totalAction01Vpa = enrichedAccount.totalAction01Vpa;
            row.totalAction02Vpa = enrichedAccount.totalAction02Vpa;
            row.totalAction03Vpa = enrichedAccount.totalAction03Vpa;

            row.totalCostSparkline = enrichedAccount.totalCostSparkline;

            row.totaltrpSparkline = enrichedAccount.totaltrpSparkline;

            row.totalSpotsSparkline = enrichedAccount.totalSpotsSparkline;

            row.totalImpressionsSparkline =
              enrichedAccount.totalImpressionsSparkline;
            row.totalClicksSparkline = enrichedAccount.totalClicksSparkline;

            row.totalAction01CountSparkline =
              enrichedAccount.totalAction01CountSparkline;

            row.totalAction02CountSparkline =
              enrichedAccount.totalAction02CountSparkline;

            row.totalAction03CountSparkline =
              enrichedAccount.totalAction03CountSparkline;

            row.totalAction01ValueSparkline =
              enrichedAccount.totalAction01ValueSparkline;

            row.totalAction02ValueSparkline =
              enrichedAccount.totalAction02ValueSparkline;

            row.totalAction03ValueSparkline =
              enrichedAccount.totalAction03ValueSparkline;

            row.totalAction01CpaSparkline =
              enrichedAccount.totalAction01CpaSparkline;
            row.totalAction02CpaSparkline =
              enrichedAccount.totalAction02CpaSparkline;
            row.totalAction03CpaSparkline =
              enrichedAccount.totalAction03CpaSparkline;

            row.totalAction01RoasSparkline =
              enrichedAccount.totalAction01RoasSparkline;
            row.totalAction02RoasSparkline =
              enrichedAccount.totalAction02RoasSparkline;
            row.totalAction03RoasSparkline =
              enrichedAccount.totalAction03RoasSparkline;

            row.total_list_of_propagation_data_row = [
              ...row.total_list_of_propagation_data
              // ...basicTotalData
            ];
            row.total_list_of_propagation_data = [
              ...row.total_list_of_propagation_data,
              ...cleaned_subRow_total_list_of_propagation_data
              // ...enrichedAccount.propData,
              // ...basicTotalData
            ];
          }
        }
      }

      // console.log('totalSnackbarWarnings: ', totalSnackbarWarnings);
      if (allSnackbarWarnings.length === 0) {
        setAllSnackbarWarnings(totalSnackbarWarnings);
      }
      setTotalListOfPropagationData(summary_total_list_of_propagation_data);
      setTotalListOfPropagationBasicData(basicTotalDataTotal);
      // console.log('basicTotalDataTotal: ', basicTotalDataTotal);

      if (updateAccountId && data && !loading) {
        const newListOfData = [...listOfData];
        const indexOfUpdateAccount = newListOfData.findIndex((row) => {
          return row.accountId === updateAccountId;
        });
        const filterednewListOfData = newListOfData.filter((row) => {
          return row.accountId !== updateAccountId;
        });

        filterednewListOfData.splice(indexOfUpdateAccount, 0, ...listOfData2);

        if (['system_admin', 'debug'].includes(user.role)) {
          console.log(
            'debug>RoiTable>row 5607>filterednewListOfData: ',
            filterednewListOfData
          );
        }

        if (['system_admin', 'debug'].includes(user.role)) {
          console.log(
            'debug>RoiTable>row 5611>updateAccountId: ',
            updateAccountId
          );
        }

        setListOfData([...filterednewListOfData]);

        setUpdateAccountId('');

        toggleDoneCalculating(true);
        setShowSpinner(false);
      } else if (data && !loading) {
        if (['system_admin', 'debug'].includes(user.role)) {
          console.log(
            'debug>RoiTable>row 5623>updateAccountId: ',
            updateAccountId
          );
        }

        if (['system_admin', 'debug'].includes(user.role)) {
          console.log('debug>RoiTable>row 5627>listOfData2: ', listOfData2);
        }

        setListOfData(listOfData2);
        toggleDoneCalculating(true);
        setShowSpinner(false);
      }
      const t1 = performance.now();

      console.log(`Performance time end t1 - t0: ${t1 - t0} milliseconds.`);
    }

    // const t1 = performance.now();

    // console.log(`Performance time end t1 - t0: ${t1 - t0} milliseconds.`);
    return;
  }, [data, rerender, statusFilter]);

  // console.log('listOfData: ', listOfData);
  // #############################################################

  // const classes = useStyles();

  const [openSimpleModal, setOpenSimpleModal] = React.useState(false);

  const [right, setRight] = React.useState([
    // 'type',
    // 'name',
    'warnings',
    'status',
    // 'notifications',
    'path',
    'comments',
    'members',
    'labels',
    'startDate',
    'endDate',
    'numberOfDays',
    // 'plannedGross',
    // 'plannedNet',
    // 'plannedNetNet',
    // 'plannedCtc',
    // 'plannedTrp',
    // 'plannedTechFee',
    // 'plannedAdOpsFee',
    // 'plannedAdServingCost',
    // 'plannedFeePercent',
    'generalDescription',
    // "buyingTypeDescription",
    // "creativeDescription",
    // "formatDescription",
    // "channelDescription",
    // "audienceDataDescription",
    // 'targetGroupDescription',
    // 'placementDescription',
    'goalDescription',
    'budget',
    'budgetCurrency',
    'calcDailyBudget',
    'cost',
    // 'costYesterday',
    'pace',
    // 'pace_bar',
    // 'trp',
    'impressions',
    'cpm',
    'vCpm',
    'clicks',
    'ctr',
    'viewableRate',
    'vcr',
    'searchImpressionShare',
    'cpc',
    'action01',
    'action01Count',
    'action01Cvr',
    'action01Cpa',
    'action01Value',
    'action01Roas',
    'action01Vpa',

    // 'action02',
    // 'action02Count',
    // 'action02Cvr',
    // 'action02Cpa',
    // 'action02Value',
    // 'action02Roas',
    // 'action02Vpa',

    // 'action03',
    // 'action03Count',
    // 'action03Cvr',
    // 'action03Cpa',
    // 'action03Value',
    // 'action03Roas',
    // 'action03Vpa',

    'ganttBarColor',
    'gantt'
  ]);

  const [left, setLeft] = React.useState(
    allColumns.filter((col) => !right.includes(col)).sort()
  );

  const onClickRefreshHandler = () => {
    console.log('refetch');
    refetch();
  };

  const onClickFreezeHandler = () => {
    console.log('refetch');

    if (subscriptionUpdatesFrozen) {
      enqueueSnackbar('Unfreeze and refetching data', {
        variant: 'default'
        // persist: true
      });
      console.log('unfreeze andrefetch');
      refetch();
    } else {
      enqueueSnackbar('Live updates frozen', {
        variant: 'default'
        // persist: true
      });
    }
    setSubscriptionUpdatesFrozen(!subscriptionUpdatesFrozen);
  };

  const onClickEditHandler = (e) => {
    setEditDialogOpen(true);
  };

  // if (!data) {
  //   return (
  //     <div
  //       style={{ position: 'absolut', textAlign: 'center', marginTop: '15%' }}
  //     >
  //       <div style={{ padding: '50px' }}>
  //         <Spinner />
  //       </div>
  //       <span>Loading Data...</span>
  //     </div>
  //   );
  // }
  // if (!doneCalculating) {
  //   return (
  //     <div
  //       style={{ position: 'absolut', textAlign: 'center', marginTop: '15%' }}
  //     >
  //       <div style={{ padding: '50px' }}>
  //         <Spinner />
  //       </div>
  //       <span>Processing Data...</span>
  //     </div>
  //   );
  // }

  // if (!data || !doneCalculating) {
  //   setShowSpinner(true);
  // }

  // console.log('filterBySelection: ', filterBySelection);

  if (['system_admin', 'debug'].includes(user.role)) {
    console.log(
      'debug>RoiTable>row5901>listOfData.length: ',
      listOfData.length
    );
    console.log('debug>RoiTable>row 5953>listOfData: ', listOfData);
  }

  return (
    <div
      style={{
        padding: '0 15px 0 15px'
      }}
    >
      <div
        // className="underlined"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',

          borderRadius: '5px'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gridColumnStart: 1,
              gridColumnEnd: 1,
              // backgroundColor: 'blue',
              padding: '1rem 0px',
              marginRight: '15px'
            }}
          >
            <QuickCreate
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              addDocument={addDocument}
              checkedBoxes={checkedBoxes}
              currentManagerAccountId={currentManagerAccount._id}
            />
          </div>
          {isAdvancedModeOn && (
            <>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 2,
                  gridColumnEnd: 2,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  // marginLeft: '15px',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <EditMenu
                  setEditDialogOpen={setEditDialogOpen}
                  copiedDocumentIds={copiedDocumentIds}
                  setCopiedDocumentIds={setCopiedDocumentIds}
                  copiedDocumentsAccountId={copiedDocumentsAccountId}
                  setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
                  enqueueSnackbar={enqueueSnackbar}
                  checkedBoxes={checkedBoxes}
                  selectedDateStart={selectedDateStart}
                  selectedDateStop={selectedDateStop}
                />
                {/* <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: 0,
                    minWidth: 0
                    // backgroundColor: '#3e8ed0'
                  }}
                  onClick={onClickEditHandler}
                >
                  <EditIcon
                  // style={{ color: '#FFFFFF' }}
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  />
                </Button> */}

                {editDialogOpen && (
                  <EditDialog
                    checkedBoxes={checkedBoxes}
                    currentManagerAccountId={currentManagerAccount._id}
                    // open={editModalOpen}
                    // setOpen={(e) => setEditModalOpen(e)}
                  />
                )}

                {copyDialogOpen && (
                  <CopyDialog
                    checkedBoxes={checkedBoxes}
                    currentManagerAccountId={currentManagerAccount._id}
                    copiedDocumentIds={copiedDocumentIds}
                    setCopiedDocumentIds={setCopiedDocumentIds}
                    copiedDocumentsAccountId={copiedDocumentsAccountId}
                    setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
                    // open={editModalOpen}
                    // setOpen={(e) => setEditModalOpen(e)}
                  />
                )}
              </div>

              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 2,
                  gridColumnEnd: 2,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  // marginLeft: '15px',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: '36px',
                    minWidth: 0,
                    cursor: 'pointer'
                    // backgroundColor: '#3e8ed0'
                  }}
                  onClick={() => setEmailPokeDialogOpen(true)}
                >
                  <i
                    style={{ fontSize: '20px' }}
                    className="fas fa-hand-point-right"
                  ></i>
                  {/* <EmailIcon
                  // style={{ color: '#FFFFFF' }}
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  /> */}
                </Button>

                {emailPokeDialogOpen && (
                  <EmailPokeDialog
                    checkedBoxes={checkedBoxes}
                    currentManagerAccountId={currentManagerAccount._id}
                    // open={editModalOpen}
                    // setOpen={(e) => setEditModalOpen(e)}
                  />
                )}
              </div>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  gridColumnStart: 3,
                  gridColumnEnd: 3,
                  // backgroundColor: 'blue',
                  textAlign: 'left',
                  padding: '1rem 0px'
                }}
              >
                <StatusFilterMenu
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  gridColumnStart: 4,
                  gridColumnEnd: 4,
                  // backgroundColor: 'blue',
                  textAlign: 'left',
                  padding: '1rem 0px'
                }}
              >
                <DisplayMenu view="gantt" />
              </div>
            </>
          )}{' '}
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gridColumnStart: 5,
              gridColumnEnd: 5,
              // backgroundColor: 'blue',
              textAlign: 'left',
              padding: '1rem 0px'
            }}
          >
            <FlightViewButton
              // setFilterBySelection={setFilterBySelection}
              // checkedBoxes={checkedBoxes}
              // setCheckedBoxes={setCheckedBoxes}
              // setFilters={setFilters}
              // filters={filters}
              enqueueSnackbar={enqueueSnackbar}
            />
          </div>
          <div
            style={{
              display: 'grid',
              justifyContent: 'center',
              gridColumnStart: 6,
              gridColumnEnd: 6,
              // backgroundColor: 'blue',
              textAlign: 'left',
              padding: '1rem 0px'
            }}
          >
            <FilterMenu
              setFilterBySelection={setFilterBySelection}
              checkedBoxes={checkedBoxes}
              setCheckedBoxes={setCheckedBoxes}
              setFilters={setFilters}
              filters={filters}
              enqueueSnackbar={enqueueSnackbar}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gridColumnStart: 7,
              gridColumnEnd: 7,
              // backgroundColor: 'blue',
              textAlign: 'left',
              padding: '0.91rem',
              justifyContent: 'left'
            }}
          >
            <FilterComponent
              setCheckedBoxes={setCheckedBoxes}
              // setFilters={setFilters}
              // setFilterBySelection={setFilterBySelection}
              // setFilterByDataSource={setFilterByDataSource}
              // filterByDataSource={filterByDataSource}
              names={names}
              dataSourceNames={dataSourceNames}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {isAdvancedModeOn && (
            <div
              style={{
                display: 'grid',
                //   border: '1px solid rgba(200, 200, 200, 0.5)',
                //   height: '53px',
                borderRadius: '5px',
                // width: '200px',
                gridColumnStart: 8,
                gridColumnEnd: 8,

                textAlign: 'right',
                // padding: '1.4rem 1rem 1rem 1rem',
                marginTop: 'auto',
                marginBottom: 'auto',
                justifyContent: 'center'
                // backgroundColor: 'blue'
              }}
            >
              <DataLevelSwitch
                dataLevel={dataLevel}
                setDataLevel={setDataLevel}
                currentAccountIds={currentAccountIds}
              />
            </div>
          )}
          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 9,
              gridColumnEnd: 9,

              textAlign: 'right',
              // padding: '1.4rem 1rem 1rem 1rem',
              marginTop: 'auto',
              marginBottom: 'auto',
              justifyContent: 'center'
              // backgroundColor: 'blue'
            }}
          >
            {/* uncomment */}
            <ExportMenu
              className="hover-grey"
              columns={right}
              arrayOfData={arrayOfData}
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              summaryRow={summaryRow}
              setSummaryRow={setSummaryRow}
              range2={range2}
              // style={{ cursor: 'pointer' }}
              // onClick={onClickRefreshHandler}
            />
          </div>
          {isAdvancedModeOn && (
            <>
              <div
                style={{
                  display: 'grid',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 10,
                  gridColumnEnd: 10,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: 0,
                    minWidth: 0,
                    backgroundColor: subscriptionUpdatesFrozen
                      ? '#3e8ed0'
                      : null
                  }}
                  onClick={onClickFreezeHandler}
                >
                  <AcUnitIcon
                    style={{
                      color: subscriptionUpdatesFrozen ? '#FFFFFF' : null
                    }}
                    // className="hover-grey"
                    // style={{ cursor: 'pointer' }}
                    // onClick={onClickRefreshHandler}
                  />
                </Button>
              </div>
              <div
                style={{
                  display: 'grid',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 11,
                  gridColumnEnd: 11,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: 0,
                    minWidth: 0
                  }}
                  onClick={onClickRefreshHandler}
                >
                  <RefreshRoundedIcon
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  />
                </Button>
              </div>
            </>
          )}

          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 12,
              gridColumnEnd: 12,

              textAlign: 'right',
              // padding: '1rem',
              marginTop: 'auto',
              marginBottom: 'auto'
              // backgroundColor: 'blue'
            }}
          >
            {/* uncomment */}
            <ColumnMenu
              currentManagerAccountId={currentManagerAccount._id}
              left={left}
              setLeft={setLeft}
              right={right}
              setRight={setRight}
              setOpenSimpleModal={setOpenSimpleModal}
              user={user}
              // columnData={columnData}
            />
            {columnDialogOpen && <ColumnDialog />}

            {/* uncomment */}
            {/* <DraggableDialog
            currentManagerAccountId={currentManagerAccount._id}
            open={openSimpleModal}
            setOpen={setOpenSimpleModal}
            style={{ zIndex: '2000' }}
            left={left}
            setLeft={setLeft}
            right={right}
            setRight={setRight}
          /> */}
          </div>
          {/* <div
          style={{
            display: 'grid',
            //   border: '1px solid rgba(200, 200, 200, 0.5)',
            //   height: '53px',
            borderRadius: '5px',
            // width: '200px',
            gridColumnStart: 22,
            gridColumnEnd: 23,

            textAlign: 'right',
            // padding: '1rem',
            marginTop: 'auto',
            marginBottom: 'auto'
            // backgroundColor: 'blue'
          }}
        >
          <DatePicker
            stylesStoredInRedux={{ stylesStoredInRedux }}
            selectedDate={selectedDateStart}
            handleDateChange={handleDateStartChange}
          />
        </div> */}
          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 13,
              gridColumnEnd: 13,

              textAlign: 'center',
              // padding: '1rem',
              marginTop: 'auto',
              marginBottom: 'auto'
              // backgroundColor: 'blue'
            }}
          >
            <ReactDateRange
              stylesStoredInRedux={stylesStoredInRedux}
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              setSelectedDateStart={setSelectedDateStart}
              setSelectedDateStop={setSelectedDateStop}
              currentAccountIds={currentAccountIds}
              enqueueSnackbar={enqueueSnackbar}
              setShowSpinner={setShowSpinner}
            />
            {/* <DatePicker
            stylesStoredInRedux={{ stylesStoredInRedux }}
            selectedDate={selectedDateStop}
            handleDateChange={handleDateStopChange}
          /> */}
          </div>
        </div>
      </div>

      {/* uncomment */}

      <Table
        aggregatedMode={false}
        dataLevel={dataLevel}
        summaryRow={summaryRow}
        totalListOfPropagationData={totalListOfPropagationData}
        totalListOfPropagationBasicData={totalListOfPropagationBasicData}
        setSummaryRow={setSummaryRow}
        arrayOfData={arrayOfData}
        setArrayOfData={setArrayOfData}
        visColumns={visColumns}
        setVisColumns={setVisColumns}
        enqueueSnackbar={enqueueSnackbar}
        // filterBySelection={filterBySelection}
        // setFilterBySelection={setFilterBySelection}
        addDocument={addDocument}
        updateDocument={updateDocument}
        currentManagerAccountId={currentManagerAccount._id}
        // changeTableData={changeTableData}
        stylesStoredInRedux={stylesStoredInRedux}
        // openRows={openRows}
        // setOpenRows={setOpenRows}
        range={range}
        range2={range2}
        rawArrayOfData={listOfData}
        columns={right}
        setColumns={setRight}
        selectedDateStart={selectedDateStart}
        selectedDateStop={selectedDateStop}
        // checkedBoxes={checkedBoxes}
        // setCheckedBoxes={setCheckedBoxes}
        // filters={filters}
        // setFilters={setFilters}
        // filterByMemberId={filterByMemberId}
        // filterByName={filterByName}
        // filterByDataSourceName={filterByDataSourceName}
        // filterByDataSource={filterByDataSource}
        refetch={refetch}
        setSubscriptionCallStack={setSubscriptionCallStack}
        currentAccountIds={currentAccountIds}
        names={names}
        dataSourceNames={dataSourceNames}
        subscriptionSkip={subscriptionSkip}
        copiedDocumentIds={copiedDocumentIds}
        setCopiedDocumentIds={setCopiedDocumentIds}
        copiedDocumentsAccountId={copiedDocumentsAccountId}
        setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
      />
      {/* <AlertDialog /> */}
      {(loading || showSpinner || !doneCalculating || !data) && (
        <div
          style={{
            display: 'block',
            position: 'fixed',
            // backgroundColor: 'red',
            height: '100px',
            width: '100px',
            zIndex: 3000,
            bottom: 0,
            right: '30px'
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

RoiTable.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired,
  // style: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(RoiTable);
