import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import DeleteIcon from '@mui/icons-material/Delete';

import Fab from '@mui/material/Fab';

import RemoveUserPhoneNumberButton from './RemovePhoneNumberButton';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({
  refetchUserInfo,
  userInfoAndAccounts
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let DisplayComponent = <div></div>;
  let dialogTitle = 'Are you sure you want to remove your phone number?';

  return (
    <React.Fragment>
      {/* <Button
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
        style={{
          width: '32px',
          minWidth: '32px',
          height: '32px',
          minHeight: '32px'
        }}
      >
        <EditIcon />
      </Button> */}

      <Fab
        onClick={handleClickOpen}
        aria-label="edit"
        style={{
          marginLeft: '20px',
          color: 'white',

          // margin: theme.spacing(1),
          borderRadius: '5px',
          height: '20px',
          width: '37px',
          backgroundColor: 'rgba(181, 0, 8, 1)'
        }}
      >
        <DeleteIcon style={{ color: 'white' }} />
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
              marginTop: '20px'
              // height: '60px'
            }}
          >
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
            {/* <PhoneNumberInput /> */}
            {/* <Fab
              onClick={handleClickOpen}
              aria-label="edit"
              style={{
                color: 'white',

                // margin: theme.spacing(1),
                borderRadius: '5px',
                height: '40px',
                width: '120px',
                backgroundColor: 'rgba(181, 0, 8, 1)'
              }}
            >
              <DeleteIcon style={{ color: 'white' }} /> Remove
            </Fab> */}
            {userInfoAndAccounts?.emergencyContact && (
              <div
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: '40px'
                }}
              >
                IF YOU REMOVE YOUR PHONE NUMBER YOU WILL CEASE TO BE AN
                EMERGENCY CONTACT!
              </div>
            )}
            <RemoveUserPhoneNumberButton
              refetchUserInfo={refetchUserInfo}
              handleClose={handleClose}
              userInfoAndAccounts={userInfoAndAccounts}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
