import {
  //   GET_ACCOUNT,
  //   GET_ACCOUNTS,
  //   ACCOUNT_ERROR,
  UPDATE_ACCOUNT,
  REFETCH_USERS_ACCOUNT_LIST
  //   CLEAR_ACCOUNT,
  //   ACCOUNT_DELETED
} from '../actions/types';

const initialState = {
  accounts: [],
  currentAccountIds: [],
  loading: true,
  refetchUsersAccountListCount: 0,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // case GET_ACCOUNT:
    case UPDATE_ACCOUNT:
      return {
        ...state,
        currentAccountIds: payload,
        loading: false
      };

    case REFETCH_USERS_ACCOUNT_LIST:
      return {
        ...state,
        refetchUsersAccountListCount: state.refetchUsersAccountListCount + 1
      };

    // case GET_ACCOUNTS:
    //   return {
    //     ...state,
    //     accounts: payload,
    //     loading: false
    //   };
    // case ACCOUNT_ERROR:
    //   return {
    //     ...state,
    //     error: payload,
    //     loading: false,
    //     profile: null
    //   };
    // case CLEAR_ACCOUNT:
    //   return {
    //     ...state,
    //     currentAccountIds: {},
    //     accounts: [],
    //     loading: false
    //   };
    // case GET_REPOS:
    //   return {
    //     ...state,
    //     repos: payload,
    //     loading: false
    //   };
    default:
      return state;
  }
}
