import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { addUserMutation } from './../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import SelectTimeZone from './../../../accounts/accountlist/add-account/SelectTimeZone';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ height: '440px', width: '400px' }} />
    </Draggable>
  );
}

export default function DraggableDialog({
  open,
  setOpen,
  refetchUserList,
  currentManagerAccount
}) {
  //   const [open, setOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('admin');

  const [timeZone, setTimeZone] = useState('Europe/Stockholm');

  const [addUser, { called }] = useMutation(addUserMutation);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = async () => {
    const addUserInput = {
      managerAccountId: currentManagerAccount._id,
      firstName,
      lastName,
      email: email.toLowerCase(),
      timeZone
    };

    // input validation

    const regExFirstName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ _,.-]*$');
    const regExFirstNameResult = regExFirstName.exec(firstName);
    if (!regExFirstNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used in first name. Only a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    const regExLastName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ _,.-]*$');
    const regExLastNameResult = regExLastName.exec(lastName);
    if (!regExLastNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used in last name. Only a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    if (firstName.length > 100) {
      enqueueSnackbar(
        'First name too long. ' + firstName.length + '/100 used',
        {
          variant: 'error'
        }
      );

      return console.log('name too long.');
    }

    if (lastName.length > 100) {
      enqueueSnackbar('Last name too long. ' + lastName.length + '/100 used', {
        variant: 'error'
      });

      return console.log('name too long.');
    }

    if (firstName.length < 2) {
      enqueueSnackbar('First name too short', {
        variant: 'error'
      });

      return console.log('name too short.');
    }

    if (lastName.length < 2) {
      enqueueSnackbar('Last name too short', {
        variant: 'error'
      });

      return console.log('name too short.');
    }

    const emailReg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regExEmail = new RegExp(emailReg);
    const regExEmailResult = regExEmail.exec(email);
    if (!regExEmailResult) {
      enqueueSnackbar('Invalid email', {
        variant: 'error'
      });

      return console.log('Invalid email');
    }

    const res = await addUser({ variables: { input: addUserInput } });

    if (
      res &&
      res.data &&
      res.data.addUser &&
      res.data.addUser.response === 'success'
    ) {
      enqueueSnackbar('User created', {
        variant: 'success'
      });
      refetchUserList();
    }

    setFirstName('');
    setLastName('');
    setEmail('');

    setOpen(false);
  };

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setOpen(false);
  };

  const onChangeHandler = (e, field) => {
    switch (field) {
      case 'firstName':
        setFirstName(e.target.value);
        break;
      case 'lastName':
        setLastName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
    }
  };

  const content = (
    <div>
      <form noValidate autoComplete="off">
        <div
          style={{
            display: 'grid',
            gridTemplateRows: '60px 60px 60px 60px 60px'
          }}
        >
          <div style={{ display: 'grid', gridRowStart: 1, gridRowEnd: 1 }}>
            <TextField
              value={firstName}
              onChange={(e) => onChangeHandler(e, 'firstName')}
              variant="standard"
              label="First name"
            />
          </div>
          <div style={{ display: 'grid', gridRowStart: 2, gridRowEnd: 2 }}>
            <TextField
              value={lastName}
              onChange={(e) => onChangeHandler(e, 'lastName')}
              variant="standard"
              label="Last name"
            />
          </div>
          <div style={{ display: 'grid', gridRowStart: 3, gridRowEnd: 3 }}>
            <TextField
              value={email}
              onChange={(e) => onChangeHandler(e, 'email')}
              variant="standard"
              label="Email"
            />
          </div>
          <div style={{ display: 'grid', gridRowStart: 4, gridRowEnd: 4 }}>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Role
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={role}
                onChange={setRole}
              >
                <MenuItem value={'admin'}>Admin</MenuItem>
                {/* <MenuItem value={'user'}>User</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: 'grid', gridRowStart: 5, gridRowEnd: 5 }}>
            <SelectTimeZone timeZone={timeZone} setTimeZone={setTimeZone} />
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <PersonAddIcon
            style={{
              verticalAlign: 'text-bottom',
              paddingBottom: '1px',
              fontSize: '24px'
            }}
          />{' '}
          <span style={{ marginLeft: '3px' }}>Add User</span>
        </DialogTitle>
        <DialogContent>
          {content}
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            // color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            // color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
