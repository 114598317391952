import React, { useState, useEffect, useContext, useRef } from 'react';

import { updateDocumentMutation } from '../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { useMutation } from '@apollo/client';

import Linkify from 'react-linkify';

import { AccountContext } from '../../../../context/AccountContext';

import { MutationContext } from '../../../../context/MutationContext';

import { Button } from '@mui/material';

function GeneralDescriptionField2({
  item,
  // currentManagerAccount,
  stylesStoredInRedux
}) {
  const [textHeight, setTextHeight] = useState('65px');

  const textEl = useRef(null);

  const { currentManagerAccount } = useContext(AccountContext);

  const [input, setInput] = useState('');

  const [edit, setEdit] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // const [updateDocument] = useMutation(updateDocumentMutation);
  const { updateDocument } = useContext(MutationContext);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  useEffect(() => {
    setInput(item.generalDescription);
  }, [item]);

  const onCancelHandler = () => {
    setInput(item.generalDescription);
    setEdit(false);
  };

  const onSaveHandler = async () => {
    const documentInput = {
      managerAccountId: currentManagerAccount._id,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          generalDescription: input
        }
      ],
      updateKeys: ['generalDescription']
    };

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });

      const response = res?.data?.updateDocument?.response;
      // const success = true;

      if (response === 'Success') {
        enqueueSnackbar('Saved', {
          variant: 'success'
        });
        setEdit(false);
      } else {
        enqueueSnackbar('Error', {
          variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar('Error', {
        variant: 'error'
      });
    }
  };

  const clickOnEditHandler = () => {
    const inputHeight = textEl?.current?.offsetHeight || '65px';

    setTextHeight(inputHeight);

    setEdit(true);
  };

  return (
    // <div
    //   style={{
    //     width: '90%',
    //     height: '400px',
    //     padding: '10px',
    //     margin: '50px',
    //     borderRadius: '5px',
    //     backgroundColor: 'rgba(150,150,150,0.1)'
    //   }}
    // >
    //   General Description
    // </div>

    <div
      style={
        {
          // padding: '10px',
          // margin: '50px'
        }
      }
    >
      <div
        style={{ padding: '10px 0px', fontWeight: 'bold', fontSize: '14px' }}
      >
        General Description
      </div>

      {/* <span style={{ lineBreak: 'anywhere' }}>
                {commentObj.text[commentObj.text.length - 1].text}
              </span> */}

      {edit ? (
        <textarea
          style={{
            width: '100%',
            border: 'none',
            height: textHeight,
            minHeight: '65px',
            maxHeight: '400px',
            padding: '20px',
            //   margin: '50px',
            borderRadius: '5px',
            // backgroundColor: 'rgba(150,150,150,0.1)',
            backgroundColor: 'rgba(150, 150, 150, 0.14)',
            resize: 'vertical',
            outline: 'none',
            caretColor:
              stylesStoredInRedux?.styleName === 'light' ? 'black' : 'white',
            color:
              stylesStoredInRedux?.styleName === 'light' ? 'black' : 'white',
            fontSize: '16px',
            fontFamily: 'Raleway, sans-serif',
            lineHeight: '1.43',
            letterSpacing: '0.01071em'
            // whiteSpace: 'pre-line',
            // lineBreak: 'anywhere',
            // wordBreak: 'break-word'
          }}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          //   onClick={() => setEdit(true)}
          readOnly={!edit}
        />
      ) : (
        <div
          ref={textEl}
          className="hover-backgroundcolor-grey"
          style={{
            width: '100%',
            border: 'none',
            minHeight: '65px',
            maxHeight: '400px',
            padding: '20px',
            //   margin: '50px',
            borderRadius: '5px',
            // backgroundColor: 'rgba(150,150,150,0.1)',
            wordBreak: 'break-word',
            // whiteSpace: 'pre-line',
            fontFamily: 'Raleway, sans-serif',
            fontSize: '16px',
            lineHeight: '1.43',
            letterSpacing: '0.01071em',
            cursor: 'pointer',
            overflowY: 'scroll'
          }}
          onClick={() => clickOnEditHandler()}
        >
          <Linkify componentDecorator={componentDecorator}>
            <span
              style={{
                whiteSpace: 'pre-wrap'
                // lineBreak: 'anywhere',
                // wordBreak: 'break-word'
              }}
            >
              {item.generalDescription}
            </span>
          </Linkify>
        </div>
      )}

      {edit ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            flexDirection: 'row',
            fontSize: '14px'
            // height: '30px'
          }}
        >
          <Button
            size="small"
            variant="outlined"
            className="hover-grey"
            style={{ margin: '5px', cursor: 'pointer' }}
            onClick={onCancelHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            className="hover-grey"
            style={{ margin: '5px', cursor: 'pointer' }}
            onClick={onSaveHandler}
          >
            Save
          </Button>
        </div>
      ) : (
        <div style={{ height: '41px' }}></div>
      )}
    </div>
  );
}

export default GeneralDescriptionField2;
