import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     width: '93%'
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

const windowOptions = [
  { type: 'default', name: 'Default' },
  { type: 'view', name: 'View Through' },
  { type: 'click', name: 'Click Through' }
];

export default function SimpleSelect({
  label,
  // menuItems,
  // value,
  // setValue,
  item,
  itemKey,
  action,
  setAction
  // default_value
}) {
  // const classes = useStyles();
  // const [age, setAge] = React.useState('');

  const menuItems = windowOptions;

  const default_value = item[itemKey]?.cm360?.attributionType;

  let defaultAttributionWindow = windowOptions.filter(
    (obj) => default_value === obj.type
  )[0];

  const defaultAttributionWindowName =
    defaultAttributionWindow?.name || 'Default';

  const defaultAttributionWindowType =
    defaultAttributionWindow?.type || 'default';

  const [input, setInput] = useState('');

  const handleChange = (event) => {
    let selectedItem = menuItems.filter(
      (obj) => event.target.value === obj.type
    )[0];

    setInput(selectedItem && selectedItem.type ? selectedItem.type : null);
    // setValue(selectedItem);

    const cm360Options = action.cm360 ? action.cm360 : {};

    setAction({
      ...action,
      cm360: {
        ...cm360Options,
        attributionType: selectedItem?.type
      }
    });
  };

  useEffect(() => {
    if (!input) {
      // let selectedItem = menuItems.filter(
      //   (obj) => default_value === obj.name
      // )[0];

      setInput(defaultAttributionWindowType);
      // setValue(selectedItem);
    }
  }, [default_value, defaultAttributionWindowType, menuItems]);

  return (
    <div>
      <FormControl
        style={{
          margin: '10px',
          minWidth: 120,
          width: '93%'
        }}
        // className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          variant="standard"
          style={{ fontSize: '14px' }}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={input}
          onChange={handleChange}
        >
          {/* <MenuItem style={{ fontSize: '14px' }} value="">
            <em>None</em>
          </MenuItem> */}
          {menuItems.map((menuItem, i) => (
            <MenuItem
              key={i}
              style={{ fontSize: '14px' }}
              value={menuItem.type}
            >
              {menuItem.name}
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Some important helper text</FormHelperText> */}
      </FormControl>
    </div>
  );
}
