import React, { useContext } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

export default function RoasPercentSwitch({}) {
  //   const [checked, setChecked] = React.useState(true);

  const {
    rightClickMenuToggledItemKey,
    displayColumnsAsPercent,
    setDisplayColumnsAsPercent
  } = useContext(InterfaceContext);
  const handleChange = (event) => {
    setDisplayColumnsAsPercent(rightClickMenuToggledItemKey);
    // setChecked(event.target.checked);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        cursor: 'pointer'

        // width: '100px'
      }}
      onClick={handleChange}
    >
      <Switch
        size="small"
        checked={displayColumnsAsPercent.includes(rightClickMenuToggledItemKey)}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <span style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '5px' }}>
        Display in %
      </span>
    </div>
  );
}
