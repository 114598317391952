import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_adform_order_data = (
  adformCampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = adformCampaign?.action01?.dataSource;
  const action02DataSource = adformCampaign?.action02?.dataSource;
  const action03DataSource = adformCampaign?.action03?.dataSource;

  const action01DataSourceIsAdform = action01DataSource === 'adform';
  const action02DataSourceIsAdform = action02DataSource === 'adform';
  const action03DataSourceIsAdform = action03DataSource === 'adform';

  const action01Type = adformCampaign?.action01?.adform?.type;

  const action02Type = adformCampaign?.action02?.adform?.type;

  const action03Type = adformCampaign?.action03?.adform?.type;

  const action01Name = adformCampaign?.action01?.adform?.name;

  const action02Name = adformCampaign?.action02?.adform?.name;

  const action03Name = adformCampaign?.action03?.adform?.name;

  const actionTypeDict = {
    conversionsAll: 'salesAll',
    conversions: 'sales'
  };

  const action01AttributionType =
    adformCampaign?.action01?.adform?.attributionType;
  const action02AttributionType =
    adformCampaign?.action02?.adform?.attributionType;
  const action03AttributionType =
    adformCampaign?.action03?.adform?.attributionType;

  const campaign = {
    ...adformCampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'adform_order'
  };

  campaign.action01.name = action01Name;
  campaign.action02.name = action02Name;
  campaign.action03.name = action03Name;

  const runCostFormula = campaign.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  let action01ConversionData = [];

  if (action01DataSourceIsAdform) {
    action01ConversionData = campaign?.adformconversiondata?.filter(
      (obj) => obj.nexusActionType === 'action01'
    );
  }

  let action02ConversionData = [];

  if (action02DataSourceIsAdform) {
    action02ConversionData = campaign?.adformconversiondata?.filter(
      (obj) => obj.nexusActionType === 'action02'
    );
  }

  let action03ConversionData = [];

  if (action03DataSourceIsAdform) {
    action03ConversionData = campaign?.adformconversiondata?.filter(
      (obj) => obj.nexusActionType === 'action03'
    );
  }

  for (let date of range2) {
    let objectToPush = {};

    let pushObj = false;

    // for (let adgroup_object of adformCampaign.adformbasicdata) {

    //   // metrics total

    //   let action01Count = null;
    //   let action02Count = null;
    //   let action03Count = null;

    //   let action01Value = null;
    //   let action02Value = null;
    //   let action03Value = null;

    //   let outbound_clicks = null;
    // }

    // console.log('date: ', date);

    if (adformCampaign?.adformbasicdata?.length > 0) {
      for (let adformBasicDataObj of adformCampaign?.adformbasicdata) {
        // console.log(
        //   "adformBasicDataObj.date.split('T') ===new Date(date).toISOString().split('T')[0]: ",
        //   adformBasicDataObj.date.split('T') ===
        //     new Date(date).toISOString().split('T')[0]
        // );
        // console.log(
        //   "4455>adformBasicDataObj.date.split('T'): ",
        //   adformBasicDataObj.date.split('T')
        // );
        // console.log(
        //   "4455>new Date(date).toISOString().split('T')[0]: ",
        //   new Date(date).toISOString().split('T')[0]
        // );
        if (
          new Date(+adformBasicDataObj.date).toISOString().split('T')[0] ===
          date
        ) {
          pushObj = true;
          objectToPush = {
            ...objectToPush,
            date_start: new Date(
              new Date(+adformBasicDataObj.date).toISOString().split('T')[0]
            ).getTime(),
            // date_stop: adgroup_object.end_time,
            cost: (objectToPush.cost || 0) + adformBasicDataObj.cost || null,
            impressions:
              (objectToPush.impressions || 0) +
                adformBasicDataObj.impressions || null,
            clicks:
              (objectToPush.clicks || 0) + adformBasicDataObj.clicks || null,
            // action01Type,
            // action01Count,
            // action01Value,

            // action02Type,
            // action02Count,
            // action02Value,

            // action03Type,
            // action03Count,
            // action03Value,

            accountId: campaignBaseValues.accountId
          };
        }
      }
    }

    if (action01ConversionData?.length > 0) {
      for (let adformConversionDataObj of action01ConversionData) {
        if (
          new Date(+adformConversionDataObj.date)
            .toISOString()
            .split('T')[0] === date
        ) {
          pushObj = true;
          objectToPush = {
            ...objectToPush,
            date_start: new Date(
              new Date(+adformConversionDataObj.date)
                .toISOString()
                .split('T')[0]
            ).getTime(),
            action01Count:
              (objectToPush.action01Count || 0) +
              (adformConversionDataObj[action01Type] || 0),
            action01Value:
              (objectToPush.action01Value || 0) +
              (adformConversionDataObj[actionTypeDict[action01Type]] || 0),

            // action02Type,
            // action02Count,
            // action02Value,

            // action03Type,
            // action03Count,
            // action03Value,

            accountId: campaignBaseValues.accountId
          };
        }
      }
    }

    if (action02ConversionData?.length > 0) {
      for (let adformConversionDataObj of action02ConversionData) {
        if (
          new Date(+adformConversionDataObj.date)
            .toISOString()
            .split('T')[0] === date
        ) {
          pushObj = true;
          objectToPush = {
            ...objectToPush,
            date_start: new Date(
              new Date(+adformConversionDataObj.date)
                .toISOString()
                .split('T')[0]
            ).getTime(),
            action02Count:
              (objectToPush.action02Count || 0) +
              (adformConversionDataObj[action02Type] || 0),
            action02Value:
              (objectToPush.action02Value || 0) +
              (adformConversionDataObj[actionTypeDict[action02Type]] || 0),

            accountId: campaignBaseValues.accountId
          };
        }
      }
    }

    if (action03ConversionData?.length > 0) {
      for (let adformConversionDataObj of action03ConversionData) {
        if (
          new Date(+adformConversionDataObj.date)
            .toISOString()
            .split('T')[0] === date
        ) {
          pushObj = true;
          objectToPush = {
            ...objectToPush,
            date_start: new Date(
              new Date(+adformConversionDataObj.date)
                .toISOString()
                .split('T')[0]
            ).getTime(),
            action03Count:
              (objectToPush.action03Count || 0) +
              (adformConversionDataObj[action03Type] || 0),
            action03Value:
              (objectToPush.action03Value || 0) +
              (adformConversionDataObj[actionTypeDict[action03Type]] || 0),

            accountId: campaignBaseValues.accountId
          };
        }
      }
    }

    if (pushObj) {
      // console.log('objectToPush: ', objectToPush);
      listOfPropagationData.push(objectToPush);
    }
  }

  // if (action01DataSourceIsAdform) {
  //   for (let action01ConversionObj of action01ConversionData) {
  //     console.log(
  //       "14433>action01ConversionObj.date.split('T')[0]: ",
  //       action01ConversionObj.date.split('T')[0]
  //     );
  //     console.log(
  //       "14433>new Date(adgroup_object.date).toISOString().split('T')[0]: ",
  //       new Date(adgroup_object.date).toISOString().split('T')[0]
  //     );
  //     if (
  //       action01ConversionObj.date.split('T')[0] ===
  //       new Date(adgroup_object.date).toISOString().split('T')[0]
  //     ) {
  //       action01Count =
  //         action01Count + action01ConversionObj[action01Type];
  //       action01Value =
  //         action01Value +
  //         action01ConversionObj[actionTypeDict[action01Type]];
  //     }
  //   }
  // }

  // console.log('adgroup_object: ', adgroup_object);
  // listOfPropagationData.push({
  //   ...adgroup_object,
  //   // ...calculatedFields,
  //   date_start: new Date(adgroup_object.date.split('T')[0]).getTime(),
  //   // date_stop: adgroup_object.end_time,
  //   cost: adgroup_object.cost || null,
  //   impressions: adgroup_object.impressions || null,
  //   clicks: adgroup_object.clicks || null,
  //   action01Type,
  //   action01Count,
  //   action01Value,

  //   action02Type,
  //   action02Count,
  //   action02Value,

  //   action03Type,
  //   action03Count,
  //   action03Value,

  //   accountId: campaignBaseValues.accountId
  // });

  campaign.total_list_of_aggregated_propagation_data = [
    {
      costLifetime: adformCampaign?.totaldata?.cost,
      impressionsLifetime: adformCampaign?.totaldata?.impressions
    }
  ];

  campaign.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaign
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_adform_order_data;
