import React, { Fragment } from 'react';

const Deprecated = () => {
  console.log('render NotFound');
  return (
    <Fragment>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1500
        }}
      >
        <h1 className="x-large text-primary">
          <i className="fas fa-exclamation-triangle" /> Deprecated
        </h1>
        <p className="large">Sorry, this page is deprecated</p>
      </div>
    </Fragment>
  );
};

export default Deprecated;
