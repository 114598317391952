import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import post from './post';
import managerAccount from './manageraccount';
import account from './account';
import style from './style';

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  managerAccount,
  account,
  style
});
