import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

import moment from 'moment';

const parse_tiktok_ads_campaign_data = (
  tikTokAdsCampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = tikTokAdsCampaign?.action01?.dataSource;
  const action02DataSource = tikTokAdsCampaign?.action02?.dataSource;
  const action03DataSource = tikTokAdsCampaign?.action03?.dataSource;

  const action01DataSourceIsTikTokAds = action01DataSource === 'tikTokAds';
  const action02DataSourceIsTikTokAds = action02DataSource === 'tikTokAds';
  const action03DataSourceIsTikTokAds = action03DataSource === 'tikTokAds';

  const action01Type = tikTokAdsCampaign?.action01?.tikTokAds?.type;

  const action02Type = tikTokAdsCampaign?.action02?.tikTokAds?.type;

  const action03Type = tikTokAdsCampaign?.action03?.tikTokAds?.type;

  const action01Name = tikTokAdsCampaign?.action01?.tikTokAds?.name;

  const action02Name = tikTokAdsCampaign?.action02?.tikTokAds?.name;

  const action03Name = tikTokAdsCampaign?.action03?.tikTokAds?.name;

  const action01AttributionType =
    tikTokAdsCampaign?.action01?.tikTokAds?.attributionType;
  const action02AttributionType =
    tikTokAdsCampaign?.action02?.tikTokAds?.attributionType;
  const action03AttributionType =
    tikTokAdsCampaign?.action03?.tikTokAds?.attributionType;

  const campaign = {
    ...tikTokAdsCampaign,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'tiktok_ads_campaign'
  };

  campaign.action01.name = action01Name;
  campaign.action02.name = action02Name;
  campaign.action03.name = action03Name;

  const runCostFormula = campaign.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (tikTokAdsCampaign?.tiktokadscampaigndata?.length > 0) {
    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adgroup_object of tikTokAdsCampaign.tiktokadscampaigndata) {
      // console.log('heeeeeej');
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      let outbound_clicks = null;

      if (action01DataSourceIsTikTokAds) {
        action01Count =
          action01Count + (parseFloat(adgroup_object[action01Type]) || null);

        if (['purchase'].includes(action01Type)) {
          action01Value =
            action01Value +
            (parseFloat(adgroup_object['total_' + action01Type + '_value']) ||
              null);
        }
        // action01Value =
        //   action01Value +
        //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
        //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      }

      if (action02DataSourceIsTikTokAds) {
        action02Count =
          action02Count + (parseFloat(adgroup_object[action02Type]) || null);

        if (['purchase'].includes(action02Type)) {
          action02Value =
            action02Value +
            (parseFloat(adgroup_object['total_' + action02Type + '_value']) ||
              null);
        }
        // action01Value =
        //   action01Value +
        //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
        //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      }

      if (action03DataSourceIsTikTokAds) {
        action03Count =
          action03Count + (parseFloat(adgroup_object[action03Type]) || null);

        if (['purchase'].includes(action03Type)) {
          action03Value =
            action03Value +
            (parseFloat(adgroup_object['total_' + action03Type + '_value']) ||
              null);
        }

        // action01Value =
        //   action01Value +
        //   (adgroup_conversion_object.cm360PostViewRevenue || null) +
        //   (adgroup_conversion_object.cm360PostClickRevenue || null);
      }

      // if (action02DataSourceIsDV360) {
      //   switch (action02Type) {
      //     case 'conversions':
      //       action02Count =
      //         action02Count + (adgroup_object.metrics.conversions || null);
      //       action02Value =
      //         action02Value + (adgroup_object.metrics.conversionsValue || null);
      //       break;

      //     default:
      //       if (snapchatAdsCampaign && snapchatAdsCampaign.dv360conversions) {
      //         for (let adgroup_conversion_object of snapchatAdsCampaign.dv360conversions) {
      //           if (
      //             adgroup_conversion_object?.floodlightActivityId &&
      //             adgroup_conversion_object?.floodlightActivityId ===
      //               action02ActivityId &&
      //             adgroup_conversion_object.date === adgroup_object.date
      //           ) {
      //             switch (action02AttributionType) {
      //               case 'default':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.totalConversions || null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null) +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //               case 'view':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.postViewConversions || null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null);
      //                 break;
      //               case 'click':
      //                 action02Count =
      //                   action02Count +
      //                   (adgroup_conversion_object.postClickConversions ||
      //                     null);
      //                 action02Value =
      //                   action02Value +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //             }
      //           }
      //         }
      //       }
      //   }
      // }

      // if (action03DataSourceIsDV360) {
      //   switch (action03Type) {
      //     case 'conversions':
      //       action03Count =
      //         action03Count + (adgroup_object.metrics.conversions || null);
      //       action03Value =
      //         action03Value + (adgroup_object.metrics.conversionsValue || null);
      //       break;

      //     default:
      //       if (snapchatAdsCampaign && snapchatAdsCampaign.dv360conversions) {
      //         for (let adgroup_conversion_object of snapchatAdsCampaign.dv360conversions) {
      //           if (
      //             adgroup_conversion_object?.floodlightActivityId &&
      //             adgroup_conversion_object?.floodlightActivityId ===
      //               action03ActivityId &&
      //             adgroup_conversion_object.date === adgroup_object.date
      //           ) {
      //             switch (action03AttributionType) {
      //               case 'default':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.totalConversions || null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null) +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //               case 'view':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.postViewConversions || null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostViewRevenue ||
      //                     null);
      //                 break;
      //               case 'click':
      //                 action03Count =
      //                   action03Count +
      //                   (adgroup_conversion_object.postClickConversions ||
      //                     null);
      //                 action03Value =
      //                   action03Value +
      //                   (adgroup_conversion_object.cm360PostClickRevenue ||
      //                     null);
      //                 break;
      //             }
      //           }
      //         }
      //       }
      //   }
      // }

      // console.log('adgroup_object.start_time: ', adgroup_object.start_time);

      // const date_start = new Date(parseInt(adgroup_object.start_time));

      // const dateStartAsUTCString = date_start.toUTCString();

      // var clearUTCDate = moment(date_start).utcOffset(0, true).unix() * 1000;
      // console.log('clearUTCDate: ', clearUTCDate);

      // console.log('adgroup_object.dateString: ', adgroup_object.dateString);
      listOfPropagationData.push({
        ...adgroup_object,
        // ...calculatedFields,
        date_start: new Date(adgroup_object.dateString).getTime(),
        // date_stop: adgroup_object.end_time,
        cost: adgroup_object.spend ? parseFloat(adgroup_object.spend) : null,
        impressions: parseInt(adgroup_object.impressions) || null,
        clicks: parseInt(adgroup_object.clicks) || null,
        action01Type,
        action01Count,
        action01Value,

        action02Type,
        action02Count,
        action02Value,

        action03Type,
        action03Count,
        action03Value,

        accountId: campaignBaseValues.accountId
      });
    }
  }

  campaign.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaign
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_tiktok_ads_campaign_data;
